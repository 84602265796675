/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import T from 'i18n-react';
import styled from 'styled-components';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsEqual from 'lodash/isEqual';
import { PrimaryButton, StyledFormItem } from 'styles/commonComponents.styles';
import * as SwappEditor from 'store/editor';
import { FORM_INPUT_TYPE } from 'utils/helpers/testFitContainerHelprs';
import { getBuildingAreaSummaryTableData } from 'utils/model/feasibilityReportModel';
import FeasibilityTargetForm from './FeasibilityTargetForm';

const FeasibilityTargetFormViewerContainerWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  padding-left: 22px;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`;

const FormWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  overflow-y: auto;
  overflow-x: hidden;
`;

let debounceTimer = null;

const noop = () => {};

const FeasibilityTargetFormViewerContainer = (props) => {
  const { width, selectedObject, masses } = props;
  const [form] = Form.useForm();
  const [myFormData, setMyFormData] = useState(form.getFieldsValue());
  const dispatch = useDispatch();

  // Mount
  useEffect(() => {
    clearTimeout(debounceTimer);
  }, []);

  // unMount
  useEffect(() => () => {
    clearTimeout(debounceTimer);
  }, []);

  useEffect(() => {
    if (!Array.isArray(selectedObject)) {
      return;
    }
    const massIds = selectedObject?.filter((mass) => mass.type === 'Mass').map((mass) => mass.type + mass.id);
    let selectedFormData;
    let massOfForm;
    const totalAreasArray = [];

    for (let i = 0; i < massIds.length; i++) {
      const mass = masses[massIds[i]];
      const massAreasArry = getBuildingAreaSummaryTableData(mass.massDto);
      const massTotalAreas = massAreasArry.find((item) => item.NAME === 'Total');
      totalAreasArray.push(massTotalAreas);
      // Save only the first mass with form data to show in the form
      if (mass?.swpBuilding?.stringified_form_data && !selectedFormData) {
        selectedFormData = JSON.parse(mass?.swpBuilding?.stringified_form_data);
        massOfForm = mass;
      }
    }

    const totalValues = totalAreasArray.reduce((a, b) => ({ GIA: a.GIA + b.GIA, NIA: a.NIA + b.NIA }), { GIA: 0, NIA: 0 });

    if (!selectedFormData) {
      selectedFormData = form.getFieldsValue();
    }

    // We want some data of the form to be filled wth "real" data coming from the masses itsef,
    // rather then the stringified form data that was saved when the mass was generated:
    if (massOfForm) {
      const floors = massOfForm.massDto.floor_groups.reduce((a, b) => a + b.count, 0);
      selectedFormData.lotData[0].targetStories = floors;
    }

    selectedFormData.GIA = totalValues.GIA;
    selectedFormData.NIA = totalValues.NIA;
    selectedFormData.NIA_GIA = Math.round((totalValues.NIA / totalValues.GIA) * 100);

    form.setFieldsValue(selectedFormData);
    setMyFormData(selectedFormData);
  }, [selectedObject]);

  const handleOnChange = () => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    debounceTimer = setTimeout(() => {
      const currentFormData = form.getFieldsValue();
      if (lodashIsEmpty(currentFormData) || lodashIsEqual(currentFormData, myFormData)) {
        return;
      }

      setMyFormData(currentFormData);
    }, 700);
  };

  const onFormSubmitted = (fd) => {
    if (!fd.inputToggle) {
      fd.inputToggle = FORM_INPUT_TYPE.PERCENTAGE_TARGET;
    }

    const ids = selectedObject.map((e) => e.id);
    const operation = { name: 'APPLY_REQUIREMENTS', parameters: { stringified_form_data: JSON.stringify(fd), mass_indexes: ids } };
    dispatch(SwappEditor.addOperations([operation]));
  };

  return (
    <FeasibilityTargetFormViewerContainerWrapper width={width}>
      <Form
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        name="FeasibilityViewerTargetForm"
        form={form}
        onFinish={onFormSubmitted}
        onFinishFailed={noop}
        onFieldsChange={handleOnChange}
      >
        <FormWrapper>
          <FeasibilityTargetForm width={width} form={form} formData={myFormData} handleOnChange={handleOnChange} isViewer />
        </FormWrapper>

        <StyledFormItem>
          <PrimaryButton center marginTop={20} marginBottom={20} width={300}>
            {T.translate('UPDATE')}
          </PrimaryButton>
        </StyledFormItem>
      </Form>
    </FeasibilityTargetFormViewerContainerWrapper>
  );
};

FeasibilityTargetFormViewerContainer.propTypes = {
  width: PropTypes.number,
  selectedObject: PropTypes.any,
  masses: PropTypes.array,
};

export default React.memo(FeasibilityTargetFormViewerContainer);
