import React, { useState } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import threeD from 'styles/static/icons/comon/threeD';
import twoD from 'styles/static/icons/comon/twoD';
import { ForgeDisplayToggleWrapper, IconWrapper, Separator } from './ForgeDisplayToggle.styles';

const ForgeDisplayToggle = (props) => {
  const { onSelected } = props;
  const [isTwoD, useIsTwoD] = useState(true);

  const toggleValue = (update, value) => {
    if (value === isTwoD) {
      return;
    }

    update(value);
    onSelected(value);
  };

  return (
    <ForgeDisplayToggleWrapper>
      <IconWrapper selected={isTwoD} onClick={() => toggleValue(useIsTwoD, true)}>
        <Tooltip title={T.translate('FORGE_DISPLAY_TOGGLE_2D')}>
          <Icon component={twoD} />
        </Tooltip>
      </IconWrapper>
      <Separator />
      <IconWrapper selected={!isTwoD} onClick={() => toggleValue(useIsTwoD, false)}>
        <Tooltip title={T.translate('FORGE_DISPLAY_TOGGLE_3D')}>
          <Icon component={threeD} />
        </Tooltip>
      </IconWrapper>
    </ForgeDisplayToggleWrapper>
  );
};

ForgeDisplayToggle.propTypes = {
  onSelected: PropTypes.func,
};

export default ForgeDisplayToggle;
