/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import lodashDebounce from 'lodash/debounce';
import { usePrevious } from 'utils/hooks';
import { changeCursor } from 'utils/swappViewer/helpers/SelectionHelpers';
import { vecLerp, vecDistance } from 'utils/algorithms/algorithmHelpers';
import { blockBoundarySymbol, lotBoundarySymbol, clickedSymbol } from './ArcGisSymbols';
import { GRAPHIC_LAYERS, ESPG_PROJECTION_WKID, ITM_PROJECTION_WKID, ZOOM_LEVELS } from './ArcGisConsts';
import {
  createPolygonGraphic,
  createGraphicLayer,
  wktToEsri,
  getEsriModules,
  createSketchWidget,
  getViewBoundsToFetch,
  createTextGraphic,
  getExtentAsPolygon,
} from './ArcGisMapHelpers';
import ArcGisMapImpl from './ArcGisMapImpl';
import { getBlocksInBounds, getParcellsInBounds } from './ArcGisProxy';

export const MAP_CLICK = 'MAP_CLICK';
const inputWkid = ESPG_PROJECTION_WKID;
let outputWkid = ESPG_PROJECTION_WKID;

// This Component responsible for handeling layers on top of the map like the sketch tool, and muncipality data
const ArcGisMap = forwardRef(({
  onLotClicked,
  searchedBlock,
  searchedLotId,
  onPolygonDrawUpdated,
  isBlockLotVisible,
  isShiftPressed,
  selectedLots,
  onLoadingStateChange,
}, viewRef) => {
  const map = useRef(null);
  const lotsLayer = useRef(null);
  const blocksLayer = useRef(null);
  const sketchLayer = useRef(null);
  const lotsLabelsGraphicLayer = useRef(null);
  const blocksLabelsGraphicLayer = useRef(null);
  const baseMapLayerRef = useRef(null);
  const sketchRef = useRef(null);
  const sketchLables = useRef([]);
  const sketchAreaLable = useRef(null);
  const lotsFetchedDataMultipolygon = useRef(null);
  const blocksFetchedDataMultipolygon = useRef(null);

  const prevSelectedLots = usePrevious(selectedLots);

  const createGraphicLayers = async () => {
    // TODO:  All refs should be switched to useState:
    lotsLayer.current = createGraphicLayer(map.current, GRAPHIC_LAYERS.LOTS, outputWkid);
    blocksLayer.current = createGraphicLayer(map.current, GRAPHIC_LAYERS.BLOCKS, outputWkid);
    sketchLayer.current = createGraphicLayer(map.current, GRAPHIC_LAYERS.SKETCH);
    lotsLabelsGraphicLayer.current = createGraphicLayer(map.current, GRAPHIC_LAYERS.LOTS_LABELS);
    blocksLabelsGraphicLayer.current = createGraphicLayer(map.current, GRAPHIC_LAYERS.BLOCKS_LABELS);
  };

  useEffect(() => {
    if (lotsLayer.current) {
      lotsLayer.current.visible = isBlockLotVisible;
      blocksLayer.current.visible = isBlockLotVisible;
      lotsLabelsGraphicLayer.current.visible = isBlockLotVisible;
      blocksLabelsGraphicLayer.current.visible = isBlockLotVisible;
    }
  }, [isBlockLotVisible]);

  const drawGraphics = async (list, graphicLayer, wkid, symbol, labelGraphicLayer, key) => {
    if (!list) {
      return;
    }
    const isLot = graphicLayer === lotsLayer.current;
    const type = isLot ? GRAPHIC_LAYERS.LOTS : GRAPHIC_LAYERS.BLOCKS;
    list.forEach(async (feature) => {
      const { geometry: wktGeom, ...attributes } = feature;
      const geometry = wktToEsri(wktGeom);
      const graphic = createPolygonGraphic(attributes, geometry, { wkid }, type, symbol);
      const textGraphic = createTextGraphic(graphic.geometry.centroid, attributes[key], isLot);
      const { items } = graphicLayer.graphics;
      if (!items.includes(graphic)) {
        graphicLayer.add(graphic);
      }
      if (!labelGraphicLayer?.graphics.items.includes(textGraphic)) {
        labelGraphicLayer.add(textGraphic);
      }
    });
  };

  const handleData = async () => {
    if (!viewRef.current || !viewRef.current.extent || !isBlockLotVisible) {
      return;
    }

    if (viewRef.current?.zoom <= 12.5) {
      return;
    }

    const viewExtentAsPolygon = getExtentAsPolygon(viewRef.current.extent);
    const { geometryEngine } = getEsriModules();

    let bounds;
    let fetchDataPolygon = viewRef.current.zoom > ZOOM_LEVELS.SMALL_ROAD ? lotsFetchedDataMultipolygon.current : blocksFetchedDataMultipolygon.current;
    if (!fetchDataPolygon) {
      fetchDataPolygon = viewExtentAsPolygon;
      bounds = getViewBoundsToFetch(viewExtentAsPolygon);
    } else {
      const differencePolygon = geometryEngine.difference(viewExtentAsPolygon, fetchDataPolygon);
      if (!differencePolygon) {
        console.log('No difference lots');
        return;
      }
      fetchDataPolygon = geometryEngine.union(fetchDataPolygon, differencePolygon);
      bounds = getViewBoundsToFetch(differencePolygon);
    }

    onLoadingStateChange(true);
    if (viewRef.current.zoom > ZOOM_LEVELS.SMALL_ROAD) {
      outputWkid = ESPG_PROJECTION_WKID;
      const lots = await getParcellsInBounds(bounds, { inputWkid, outputWkid });
      drawGraphics(lots, lotsLayer.current, outputWkid, lotBoundarySymbol, lotsLabelsGraphicLayer.current, 'parcel');
    } else {
      outputWkid = ITM_PROJECTION_WKID; // untill we will upload an ESPG data set to postgis
      const blocks = await getBlocksInBounds(bounds, { inputWkid, outputWkid });
      drawGraphics(blocks, blocksLayer.current, outputWkid, blockBoundarySymbol, blocksLabelsGraphicLayer.current, 'blockid');
    }
    onLoadingStateChange(false);
  };

  const layersVisibilityHandler = () => {
    if (viewRef.current.zoom > ZOOM_LEVELS.SMALL_ROAD) {
      lotsLayer.current.visible = isBlockLotVisible;
    } else {
      lotsLayer.current.visible = false;
    }

    if (viewRef.current.zoom >= ZOOM_LEVELS.STREET_BLOCK) {
      lotsLabelsGraphicLayer.current.visible = isBlockLotVisible;
    } else {
      lotsLabelsGraphicLayer.current.visible = false;
    }

    if (viewRef.current.zoom > ZOOM_LEVELS.TOWN) {
      blocksLayer.current.visible = isBlockLotVisible;
    } else {
      blocksLayer.current.visible = false;
    }

    if (viewRef.current.zoom > 14.5) {
      blocksLabelsGraphicLayer.current.visible = isBlockLotVisible;
    } else {
      blocksLabelsGraphicLayer.current.visible = false;
    }
  };

  const debouncedGetData = React.useCallback(lodashDebounce(handleData, 1000), [isBlockLotVisible]);
  const debouncedVisibility = React.useCallback(lodashDebounce(layersVisibilityHandler, 200), [isBlockLotVisible]);

  useEffect(() => {
    handleData();
  }, [searchedBlock]);

  const findObjectByTypes = (results, name) => results?.find((res) => res?.graphic?.swappType === name);
  const findSketchItem = (results) => results?.find((res) => sketchLayer.current.graphics.items.includes(res.graphic));

  // This useEffect is handeling the visual symbols of lots selection
  useEffect(() => {
    prevSelectedLots?.forEach((lotGraphic) => {
      lotGraphic.symbol = lotBoundarySymbol;
    });
    selectedLots.forEach((lotGraphic) => {
      lotGraphic.symbol = clickedSymbol;
    });
  }, [selectedLots, isShiftPressed, prevSelectedLots]);

  const findBlockLot = (block, lotId) => {
    for (let i = 0; i < lotsLayer.current.graphics.items.length; i++) {
      const lotGraphic = lotsLayer.current.graphics.items[i];
      const { attributes } = lotGraphic;
      const { blockid, parcel } = attributes;
      if (blockid === block.id && parcel === lotId) {
        return lotGraphic;
      }
    }
  };

  useEffect(() => {
    if (searchedLotId && searchedBlock) {
      const lot = findBlockLot(searchedBlock, searchedLotId);
      if (lot) {
        viewRef.current.goTo({
          target: lot.geometry.centroid,
        });
        onLotClicked(lot);
      } else {
        onLotClicked({ error: 'Lot not found', lotId: searchedLotId });
      }
    }
  }, [searchedLotId, searchedBlock]);

  const onMapClicked = (event) => viewRef.current?.hitTest(event).then((response) => {
    const { results } = response;

    const sketchGraphic = findSketchItem(results);
    if (sketchGraphic) {
      sketchRef.current.viewModel.update(sketchGraphic.graphic);
      onLotClicked(null);
      return;
    }

    if (sketchLayer.current.graphics.items.length > 0) {
      return;
    }

    if (lotsLayer.current.visible) {
      const lotResult = findObjectByTypes(results, GRAPHIC_LAYERS.LOTS);
      if (lotResult?.graphic) {
        onLotClicked(lotResult.graphic);
      }
    }
  });

  // eslint-disable-next-line no-unused-vars
  const handleLotHover = (graphic) => {
    // changeCursor('pointer');
  };

  const onPointerMove = (event) => viewRef.current.hitTest(event).then((response) => {
    const { results } = response;
    const lotResult = findObjectByTypes(results, GRAPHIC_LAYERS.LOTS);
    if (lotResult?.graphic) {
      handleLotHover(lotResult.graphic);
      return;
    }
    changeCursor('default');
  });

  const getGraphicFromEvent = (event) => {
    let graphic;
    if (event.graphic) {
      graphic = event.graphic;
    } else if (event.graphics) {
      [graphic] = event.graphics;
      return graphic;
    }
  };

  const onSketchUpdated = async (event) => {
    const graphic = getGraphicFromEvent(event);
    if (!graphic?.geometry) {
      return;
    }

    const { webMercatorUtils, geometryEngine } = getEsriModules();
    const newGeometry = webMercatorUtils.webMercatorToGeographic(graphic?.geometry);

    const isReselectingPolygon = event.type === 'update' && event.state === 'start';
    const isStartingNewPolygon = event.type === 'create' && event.state === 'start';
    const shouldUpdatePolygon = event.type === 'update' && event?.toolEventInfo?.type.indexOf('stop') > -1;
    if (isStartingNewPolygon) {
      // Remove previous polygons (only one at a time)
      sketchLayer.current.graphics.items.forEach((element) => {
        if (element !== event.graphic) {
          sketchLayer.current.remove(element);
        }
      });
    }

    // Area label:
    let area = geometryEngine.geodesicArea(graphic.geometry);
    area = `${area.toFixed(2)} m²`;
    if (!sketchAreaLable.current) {
      sketchAreaLable.current = createTextGraphic(graphic?.geometry.centroid, area);
      if (sketchLayer.current) {
        sketchLayer.current.add(sketchAreaLable.current);
      }
    } else {
      sketchAreaLable.current.geometry = graphic?.geometry.centroid;
      sketchAreaLable.current.symbol.text = area;
    }

    // Lengthes labels
    sketchLayer.current.removeMany(sketchLables.current);
    sketchLables.current = [];
    const poly = graphic?.geometry?.rings[0];
    newGeometry.rings[0].forEach(async (point, index) => {
      const planePoint = poly[index];
      const nextPlanePoint = poly[(index + 1) % poly.length];
      if (planePoint[0] !== nextPlanePoint[0] || planePoint[1] !== nextPlanePoint[1]) {
        const midPoint = vecLerp(planePoint, nextPlanePoint, 0.5);
        const distance = vecDistance(planePoint, nextPlanePoint);
        const webMercatorMidPoint = webMercatorUtils.webMercatorToGeographic({ type: 'point', x: midPoint[0], y: midPoint[1] });
        const textGraphic = createTextGraphic(webMercatorMidPoint, `${distance.toFixed(2)} m`);
        if (textGraphic) {
          sketchLables.current.push(textGraphic);
          sketchLayer.current.add(textGraphic);
        }
      }
    });

    if (event.state === 'complete' || isReselectingPolygon || shouldUpdatePolygon) {
      if (event.aborted) {
        sketchLayer.current.removeMany(sketchLables.current);
        sketchLables.current = [];
        sketchLayer.current.remove(sketchAreaLable.current);
        sketchAreaLable.current = null;
      }
      if (graphic?.geometry?.rings[0]) {
        onPolygonDrawUpdated(newGeometry);
      }
    }
  };

  const onMapviewchanged = (e) => {
    debouncedVisibility();
    debouncedGetData(e);
  };

  const onMapReady = async () => {
    createGraphicLayers();
    sketchRef.current = createSketchWidget(viewRef.current, sketchLayer.current, onSketchUpdated, lotsLayer.current);
    viewRef.current.ui.move('zoom', 'top-right');
    debouncedGetData();
  };

  return (
    <>
      <ArcGisMapImpl
        ref={viewRef}
        mapRef={map}
        baseMapLayerRef={baseMapLayerRef}
        onPointerMove={onPointerMove}
        onMapReady={onMapReady}
        onMapViewChanged={onMapviewchanged}
        onMapClicked={onMapClicked}
      />
    </>
  );
});

ArcGisMap.propTypes = {
  onLotClicked: PropTypes.any,
  isBlockLotVisible: PropTypes.bool,
  searchedBlock: PropTypes.any,
  searchedLotId: PropTypes.any,
  onPolygonDrawUpdated: PropTypes.func,
  onLoadingStateChange: PropTypes.func,
  isShiftPressed: PropTypes.bool,
  selectedLots: PropTypes.array,
};

export default ArcGisMap;
