export const API_AUTH_ROUTES = {
  signUp: '/user',
  login: '/auth/login',
  userProfile: '/user/me',
  forgotPassword: '/reset-password/forgot-password',
  resetPassword: '/reset-password',
  newProject: '/project',
  projectProfile: '/project-profile',
  approveProfile: '/approve-profile',
  newProfile: '/new-profile',
  myProjects: '/project/my',
  default: '/default',
  shareKey: '/share-key',
  shareKeyGet: '/shared?share-key',
  analyse: '/analyse',
  generateReport: '/generate-report',
  generateEnergyAnalysis: '/generate-energy-analysis',
  generateHBEnergyAnalysis: '/generate-hb-energy-analysis',
  generateOcbParking: '/generate-ocb-parking',
  exportFile: '/export-file',
  transformMasses: '/transform-masses',
  editResult: '/edit-result',
  editResultPreview: '/edit-result-preview',
  beginEditSession: '/begin-edit-session',
  exchangeRates: '/user/currency/exchange-rates',
  generateSiteData: 'generate-site-data',
};

export const updateProjectProfile = (id) => `${API_AUTH_ROUTES.projectProfile}/${id}`;
