/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import T from 'i18n-react';
import { extend } from 'react-three-fiber';
import * as meshline from 'threejs-meshline';
import { message, Modal, Tabs } from 'antd';
import { shallowEqual, useDispatch, useSelector, useStore } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import lodashValues from 'lodash/values';
import lodashSortBy from 'lodash/sortBy';
import lodashGet from 'lodash/get';
import { unwrapResult } from '@reduxjs/toolkit';
import lodashFindLastIndex from 'lodash/findLastIndex';
import {
  PrimaryButton,
  UiButtonsWrapper,
  ViewerUiPanelWrapper,
} from 'styles/commonComponents.styles';
import { FEASIBILITY_EDITOR_BUTTON_KEYS, FEASIBILITY_MASS_TYPES, GROUND_FLOOR_TYPES } from 'constants/feasibilityConts';
import { SKETCH_TOOL_ACTIVATED, SKETCH_PARK_ACTIVATED } from 'constants/featureFlags';
import { currentThemeSelector, useOverridableSetting } from 'store/userSettings';
import { setIsParkingFormOn } from 'store/parkingForm';
import downloadPdfSmallIcon from 'styles/static/icons/comon/downloadPdfSmallIcon';
import { downloadUrl } from 'utils/helpers/generalHelpers';
import SwappViewerActionButton from 'utils/swappViewer/components/ui/SwappViewerActionButton';
import EditPanelContainer from 'utils/swappViewer/components/ui/EditPanelContainer';
import CameraProjectionToggleButton from 'utils/swappViewer//components/ui/CameraProjectionToggleButton';
import FeasibilityResultData from 'components/feasibility/studies/result/program/ProgramMetaData';
import ViewerUiPanel from 'utils/swappViewer/components/ui/ViewerUiPanel';
import { ExitModalContentWrapper } from 'utils/swappViewer/FeasibilityViewerContainer.styles';
import { getPolygonArea } from 'utils/algorithms/algorithmHelpers';
import { changeCursor } from 'utils/swappViewer/helpers/SelectionHelpers';
import { isStoryUtilityStory } from '@swapp/swappcommonjs/dist/utils/swpProjectModel';
import BuildingStoryUI from 'utils/swappViewer/components/Stories/BuildingStoryUI';
import { getBuildingStoriesData, isStoryGroupARetailFloor, isStoryGroupAnOpenGroundFloor } from 'utils/model/feasibilityDataExtractors';
import { useBeginFinishedEditContext } from 'utils/hooks/reactContexts';
import FeasibilityTargetFormViewerContainer from 'components/feasibility/studies/form/FeasibilityTargetFormViewerContainer';
import { StyledTabs } from 'components/common/ResultSplitView.srtyles';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { selectStory } from 'store/BuildingStoriesStore';
import * as SwappEditor from '../../../../../store/editor';
import icons from '../../../../../styles/static/icons/viewerIcons';

extend(meshline);

const getBuildingsTransform = (store) => store.getState().editor.massTransformer.transforms;

const isFloorSelectable = (swpProject, storyGroup) => {
  if (!swpProject) {
    return true;
  }
  if (!storyGroup.swpId) {
    return true;
  }
  const swpBuildingStory = swpProject.getObjectByGuid(storyGroup.swpId);
  const isUtilityStory = isStoryUtilityStory(swpBuildingStory);
  return !isUtilityStory;
};

const ORTHOGRAPHIC_EDITOR_TYPES = [
  FEASIBILITY_EDITOR_BUTTON_KEYS.TRANSFORM,
  FEASIBILITY_EDITOR_BUTTON_KEYS.PARKING,
  FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_BUILDING,
  FEASIBILITY_EDITOR_BUTTON_KEYS.EDIT_BUILDING,
  FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_SURFACE_PARKING,
  FEASIBILITY_EDITOR_BUTTON_KEYS.EDIT_SURFACE_PARKING,
  FEASIBILITY_EDITOR_BUTTON_KEYS.WAITING_FOR_SKETCH,
];

const { TabPane } = Tabs;

const TAB_NAMES = {
  DATA: 'DATA',
  FORM: 'FORM',
};

const ProgramViewerUi = (props) => {
  const { buildingInfo, profileId, isOrthographic, setIsOrthographic, isMetadataPanelClose,
    isEditModeOn, legendKey, isSketchParkingOn, isEditorInTransformMode, isIlStandard, beginEditing,
    isBuildingStoriesOn, swappProject: currentProject, addTransformOperationIfNeeded } = props;

  const operations = [...useSelector((state) => state.editor.operations)];
  const { selectedObject, editorType, initialTypology } = useSelector(({ editor }) => ({ selectedObject: editor.selectedObject, editorType: editor.editorType, initialTypology: editor.initialTypology, sketchPoints: editor.sketchPoints }), shallowEqual);
  const numSelectedObjects = SwappEditor.numSelectedObjects(selectedObject);
  const dispatch = useDispatch();
  const numOperations = operations.length;
  const store = useStore(); // We need the store for getBuildingsTransform. We don't useSelector becuase we don't want to rerender
  const currentTheme = useSelector(currentThemeSelector);
  const profile = lodashGet(currentProject, 'projectProfiles', []).find((item) => item.id === profileId);
  const siteData = lodashGet(currentProject, 'site.siteData');
  const history = useHistory();
  const [copiedSelectedObjects, setCopiedSelectedObjects] = useState();
  const [currentTab, setCurrentTab] = useState(TAB_NAMES.DATA);
  const isParkingSelected = selectedObject && selectedObject.type === 'surfaceParking';
  const { swpProject } = buildingInfo;
  const numMasses = Object.keys(buildingInfo.masses).length;
  const pdfUrl = lodashGet(profile, 'result.pdfUrl');
  const isEmptySite = numMasses === 0;
  const locationData = parseLocationUrl(history.location);
  const isSketchCentric = useOverridableSetting('isSketchCentric', false);
  const isSketchEditOn = editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.EDIT_BUILDING;

  useEffect(() => () => {
    dispatch(SwappEditor.stopEditing());
    dispatch(setIsParkingFormOn(false));
  }, []);

  useEffect(() => {
    if (isEmptySite) {
      beginEditing();
    }
  }, [isEmptySite]);

  const creatMassActionHotkeys = (key, action, deps = []) => useHotkeys(key, (e) => {
    if (isOrthographic) {
      e.preventDefault();
      massActionClickHandler(action);
    }
  }, undefined, [selectedObject, isOrthographic, ...deps]);

  const escAction = () => {
    setIsOrthographic(true);
    dispatch(SwappEditor.setEditorType(FEASIBILITY_EDITOR_BUTTON_KEYS.TRANSFORM));
    dispatch(SwappEditor.editMassInSketch(null));
    if (selectedObject) {
      dispatch(SwappEditor.selectObject(null));
    }
  };

  const beginFinishedEditContext = useBeginFinishedEditContext();
  useHotkeys('esc', (e) => {
    e.preventDefault();
    if (editorType) {
      // Tmp - to detect if sketch tool is active
      if (editorType.includes('CREATE_') || editorType.includes('EDIT_')) {
        Modal.confirm({
          title: 'You are about to cancel your sketch',
          content: 'Your work will not be saved',
          cancelText: 'Cancel',
          onOk: () => escAction(),
          onCancel: () => {},
        });
      } else {
        dispatch(SwappEditor.stopEditing());
        setIsOrthographic(false);
      }
    }
  }, undefined, [selectedObject, editorType]);

  useHotkeys('ctrl+c, cmd+c', (e) => {
    if (isOrthographic) {
      e.preventDefault();
      setCopiedSelectedObjects(selectedObject);
    }
  }, undefined, [selectedObject, isOrthographic]);

  creatMassActionHotkeys('delete', FEASIBILITY_EDITOR_BUTTON_KEYS.DELETE);
  creatMassActionHotkeys('backspace', FEASIBILITY_EDITOR_BUTTON_KEYS.DELETE);

  creatMassActionHotkeys('ctrl+d, cmd+d', FEASIBILITY_EDITOR_BUTTON_KEYS.DUPLICATE);
  creatMassActionHotkeys('ctrl+m, cmd+m', FEASIBILITY_EDITOR_BUTTON_KEYS.MIRROR);
  creatMassActionHotkeys('ctrl+v, cmd+v', FEASIBILITY_EDITOR_BUTTON_KEYS.DUPLICATE, [copiedSelectedObjects]);

  const handleEditorTypeChanged = (id) => {
    if (id === editorType) {
      return;
    }

    setIsOrthographic(ORTHOGRAPHIC_EDITOR_TYPES.includes(id));
    const newOperations = [];
    addTransformOperationIfNeeded(newOperations);
    if (newOperations.length > 0) {
      dispatch(SwappEditor.addOperationsAsync({ operations: newOperations, buildingInfo }));
    }

    // When starting to edit floors, we would like the highest floor to be selected by default
    if (id === FEASIBILITY_EDITOR_BUTTON_KEYS.FLOOR && isEditModeOn) {
      const buildingData = buildingInfo.masses?.Mass0;
      if (buildingData) {
        const { storyGroups } = getBuildingStoriesData(buildingData);
        const floorGroupToUse = lodashFindLastIndex(storyGroups, (sg) => isFloorSelectable(swpProject, sg));

        const highestFloorGroup = storyGroups[floorGroupToUse];
        dispatch(SwappEditor.selectObject({ floorGroupIndex: floorGroupToUse, floorIndex: highestFloorGroup.numStories - 1, massIndex: 0, type: 'Floor' }));
      }
    }
  };

  useEffect(() => {
    // If the user switches to 3D mode in "Transform" mode, leave the editor mode.
    if (isEditModeOn && editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.TRANSFORM && !isOrthographic && !isSketchParkingOn) {
      handleEditorTypeChanged('');
    }
  }, [editorType, isOrthographic]);

  // If the user switches to 2D in edit mode, switch to "Transform" mode
  if (isEditModeOn && isOrthographic && !ORTHOGRAPHIC_EDITOR_TYPES.includes(editorType)) {
    dispatch(SwappEditor.setEditorType(FEASIBILITY_EDITOR_BUTTON_KEYS.TRANSFORM));
    handleEditorTypeChanged(FEASIBILITY_EDITOR_BUTTON_KEYS.TRANSFORM);
    changeCursor('default');
  }

  useEffect(() => {
    // Switch to orthographic view when editing a parking
    if (isEditModeOn && !isOrthographic && editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.EDIT_SURFACE_PARKING) {
      setIsOrthographic(true);
    }
  }, [editorType]);

  useEffect(() => {
    if (!isOrthographic && editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.EDIT_SURFACE_PARKING) {
      dispatch(SwappEditor.setEditorType(FEASIBILITY_EDITOR_BUTTON_KEYS.TRANSFORM));
    }
  }, [isOrthographic]);

  useEffect(() => {
    dispatch(setIsParkingFormOn(false));
    dispatch(selectStory(null));
    setIsOrthographic(false);
  }, [profile?.id]);

  const finishEditing = (isSave) => {
    addTransformOperationIfNeeded(operations);

    if (operations.length === 0) {
      dispatch(SwappEditor.stopEditing());
      setIsOrthographic(false);

      if (isEmptySite) {
        history.push(`/${locationData.stage}/${locationData.projectId}/${locationData.stageTab}`);
      }

      return;
    }

    const handleSaveButtonClicked = (createCopy) => {
      dispatch(SwappEditor.saveResult({ saveAsCopy: createCopy, history }));
      Modal.destroyAll();
    };

    Modal.confirm({
      title: T.translate('FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_TITLE'),
      content: (
        <>
          {T.translate('FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_CONTENT')}
          {isSave && (
            <ExitModalContentWrapper>
              {/* ============== Save As ============== */}
              <PrimaryButton
                theme={currentTheme}
                fontSize="medium"
                width={129}
                height={32}
                onClick={() => handleSaveButtonClicked(true)}
              >
                {T.translate('FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_SAVE_AS_BUTTON')}
              </PrimaryButton>

              {/* ============== Save ============== */}
              <PrimaryButton
                disabled={initialTypology === 'EMPTY'}
                theme={currentTheme}
                fontSize="medium"
                marginLeft={12}
                width={75}
                height={32}
                onClick={() => handleSaveButtonClicked(false)}
              >
                {T.translate('FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_SAVE_BUTTON')}
              </PrimaryButton>
            </ExitModalContentWrapper>
          )}
        </>),
      width: '450px',
      okText: T.translate('FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_OK_BUTTON'), // Discard
      cancelText: T.translate('FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_CANCEL_BUTTON'), // Continue editing
      onOk() {
        dispatch(SwappEditor.stopEditing());
        setIsOrthographic(false);
      },
    });
  };

  const finishEditingIfValid = (isSave) => {
    setCurrentTab(TAB_NAMES.DATA);
    const isGettingData = !SwappEditor.isEditorResultSyncedWithServerSelector(store.getState());

    if (isGettingData) {
      message.info(T.translate('FEASIBILITY_EDITOR_OVERLAY_ERROR_SERVER_PROCESSING'));
      return;
    }

    if (isEmptySite && initialTypology === 'EMPTY') {
      dispatch(SwappEditor.stopEditing());
      setIsOrthographic(false);
    }

    const buildingsTransform = getBuildingsTransform(store);
    const sortedBuildingsTransform = lodashSortBy(lodashValues(buildingsTransform), ['key']);
    const isBuildingsValid = sortedBuildingsTransform.every((building) => building.isValid === true);
    if (isBuildingsValid) {
      finishEditing(isSave);
    } else if (isSave) {
      Modal.confirm({
        title: T.translate('FEASIBILITY_EDITOR_OVERLAY_MODAL_ERROR_TITLE'),
        content: T.translate('FEASIBILITY_EDITOR_OVERLAY_MODAL_ERROR_CONTENT'),
        cancelText: T.translate('FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_CANCEL_BUTTON'),
        onOk: () => finishEditing(isSave),
      });
    } else {
      dispatch(SwappEditor.stopEditing());
      setIsOrthographic(false);
    }
  };

  beginFinishedEditContext.setFinishEditing(finishEditingIfValid);
  beginFinishedEditContext.setBeginEditing(beginEditing);

  const massActionClickHandler = async (id) => {
    const newOperations = [];
    addTransformOperationIfNeeded(newOperations);
    // we sort so we can send the masses order my mass from large to small so the BE can handle the request
    let sortedSelectedObject = lodashSortBy(selectedObject, ['id']).reverse();
    let postOperationSelectedObject = sortedSelectedObject;

    if (id === FEASIBILITY_EDITOR_BUTTON_KEYS.DELETE) {
      setCopiedSelectedObjects(null);
      if (isParkingSelected) {
        const lots = buildingInfo.parkingInfo.lots ? [...buildingInfo.parkingInfo.lots] : [];
        lots.splice(selectedObject.id, 1);
        const operation = { name: 'SET_PARKING_BOUNDARY', parameters: { lots } };
        newOperations.push(operation);
      } else {
        sortedSelectedObject.forEach((mass) => newOperations.push({ name: 'DELETE_MASS', parameters: { mass_index: mass.id } }));
        postOperationSelectedObject = null;
      }
    }
    if (id === FEASIBILITY_EDITOR_BUTTON_KEYS.MIRROR) {
      newOperations.push({ name: 'MIRROR_MASSING', parameters: { mass_indices: sortedSelectedObject.map((m) => m.id), mirror_lengthwise: true } });
    }
    if (id === FEASIBILITY_EDITOR_BUTTON_KEYS.DUPLICATE) {
      sortedSelectedObject = lodashSortBy(copiedSelectedObjects || selectedObject, ['id']).reverse();
      postOperationSelectedObject = sortedSelectedObject;
      const maxOffset = Math.max(...sortedSelectedObject.map((mass) => {
        const { polygon } = Object.values(buildingInfo.masses).find((m) => m.massIndex === mass.id);
        const polygonArea = getPolygonArea(polygon);
        return Math.sqrt(polygonArea) / 1.5;
      }));

      sortedSelectedObject.forEach((mass) => {
        newOperations.push({ name: 'DUPLICATE_MASSING', parameters: { mass_index: mass.id, delta_x: maxOffset, delta_y: maxOffset, delta_angle: 0 } });
      });
    }
    if (newOperations.length > 0) {
      const resultAction = await dispatch(SwappEditor.addOperationsAsync({ operations: newOperations, buildingInfo }));
      try {
        unwrapResult(resultAction);
        if (postOperationSelectedObject !== sortedSelectedObject) {
          dispatch(SwappEditor.selectObject(postOperationSelectedObject));
        }
        // eslint-disable-next-line no-empty
      } catch (err) { }
    }
  };

  const floorActionClickHandler = async (id) => {
    const { massIndex, floorGroupIndex, floorIndex } = selectedObject;
    const buildingData = Object.values(buildingInfo.masses).find((m) => m.massIndex === massIndex);
    let operation = null;
    let selectedObjectAfterAction = null;
    const { storyGroups } = getBuildingStoriesData(buildingData);
    const { type } = buildingData;
    if (id === FEASIBILITY_EDITOR_BUTTON_KEYS.GROUND_FLOOR_TYPE) {
      const floorTypeOperations = {
        [GROUND_FLOOR_TYPES.RETAIL]: { name: 'SET_RETAIL_FLOOR', parameters: { mass_index: massIndex } },
        [GROUND_FLOOR_TYPES.OPEN_GROUND_FLOOR]: { name: 'SET_OPEN_GROUND_FLOOR', parameters: { mass_index: massIndex, num_floors: 1, floor_height: 3.3 } },
        [GROUND_FLOOR_TYPES.NA]: { name: 'SET_OPEN_GROUND_FLOOR', parameters: { mass_index: massIndex, num_floors: 1, floor_height: 3.3 } },
      };
      operation = floorTypeOperations[buildingInfo.groundFloorType || GROUND_FLOOR_TYPES.OPEN_GROUND_FLOOR];
      selectedObjectAfterAction = { ...selectedObject, floorGroupIndex: 0, floorIndex: 0 };
    } else {
      let floorGroupIndexToModify = floorGroupIndex;
      if (type === FEASIBILITY_MASS_TYPES.PARKING && floorGroupIndex === 1) {
        // When editing a parking building, we always want the top floor to be unique (no columns) and we modify
        // the main (repeating) floors.
        floorGroupIndexToModify = 0;
      }
      let numFloorsInFloorGroup = storyGroups[floorGroupIndexToModify].numStories;
      selectedObjectAfterAction = selectedObject;
      if (id === FEASIBILITY_EDITOR_BUTTON_KEYS.FLOOR_DELETE) {
        numFloorsInFloorGroup -= 1;
        if (numFloorsInFloorGroup === 0) {
          selectedObjectAfterAction = null;
          const newGroupIndex = floorGroupIndexToModify - 1;
          const newIndex = storyGroups[newGroupIndex]?.numStories - 1;
          if (!isNaN(newIndex) && newIndex >= 0) {
            selectedObjectAfterAction = { ...selectedObject, floorIndex: newIndex, floorGroupIndex: newGroupIndex };
          } else {
            selectedObjectAfterAction = null;
          }
        } else if (numFloorsInFloorGroup === floorIndex) {
          selectedObjectAfterAction = { ...selectedObject, floorIndex: numFloorsInFloorGroup - 1 };
        }
        if (floorGroupIndex !== floorGroupIndexToModify) {
          selectedObjectAfterAction = selectedObject;
        }
      }
      if (id === FEASIBILITY_EDITOR_BUTTON_KEYS.FLOOR_DUPLICATE) {
        numFloorsInFloorGroup += 1;
      }
      const parameters = { mass_index: massIndex, floor_group_index: floorGroupIndexToModify, new_floor_group_count: numFloorsInFloorGroup };
      operation = { name: 'MODIFY_FLOOR_GROUP_COUNT', parameters };
    }

    const resultAction = await dispatch(SwappEditor.addOperationsAsync({ operations: [operation], buildingInfo, selectedObjectAfterAction }));
    try {
      unwrapResult(resultAction);
      // eslint-disable-next-line no-empty
    } catch (err) { }
  };

  const handleCreateActionClicked = async (id) => {
    const newOperations = [];
    addTransformOperationIfNeeded(newOperations);
    if (id === FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_PARKING || id === FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_UNDERGROUND_PARKING) {
      const isUnderground = (id === FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_UNDERGROUND_PARKING);
      const parameters = {
        num_of_floors: isUnderground ? 1 : 3,
        floor_path: [
          [-25, -15],
          [25, -15],
          [25, 15],
          [-25, 15],
        ],
        is_underground: isUnderground,
      };
      newOperations.push({ name: 'CREATE_PARKING_BUILDING', parameters });
      const resultAction = await dispatch(SwappEditor.addOperationsAsync({ operations: newOperations, buildingInfo }));
      try {
        unwrapResult(resultAction);
        dispatch(SwappEditor.setEditorType(FEASIBILITY_EDITOR_BUTTON_KEYS.TRANSFORM));
        // eslint-disable-next-line no-empty
      } catch (err) { }
    } else if (id === FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_BUILDING) {
      handleEditorTypeChanged(FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_BUILDING);
      dispatch(SwappEditor.setEditorType(FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_BUILDING));
    } else if (id === FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_SURFACE_PARKING) {
      dispatch(SwappEditor.setEditorType(FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_SURFACE_PARKING));
    }
  };
  const newOperationsCheck = [];
  addTransformOperationIfNeeded(newOperationsCheck);
  const hasPendingTransformOperation = newOperationsCheck.length > 0;
  const canUndo = numOperations > 0 || hasPendingTransformOperation;

  useHotkeys('ctrl+z', (e) => {
    e.preventDefault();
    handleUndoRequested();
  }, undefined, [hasPendingTransformOperation]);

  const handleUndoRequested = () => {
    if (hasPendingTransformOperation) {
      // If there is a pending transform operation that was not sent to the server, "undo" is actually clearing it.
      dispatch(SwappEditor.markOperationsDirty());
      dispatch(SwappEditor.undoLastOperation()); // TODO - ask @Gat about hasPendingTransformOperation and remove `undoLastOperation`
    } else {
      dispatch(SwappEditor.undoLastOperation());
    }
  };

  const isMassSelected = numSelectedObjects >= 1 && lodashGet(selectedObject, '[0].type') === 'Mass';

  const isParkingDisabled = lodashGet(currentProject, 'site.siteData.flags.disableCustomParking', false);

  const checkFloorDeleteDisabaling = (fgs) => {
    const nonOpenGroundFloor = fgs.filter((fg) => !isStoryGroupAnOpenGroundFloor(fg));
    const nonOpenGroundFloorCount = nonOpenGroundFloor.reduce((n, { count }) => n + count, 0);
    if (nonOpenGroundFloorCount === 1) {
      return true;
    }
    return false;
  };

  const isFloorSelected = lodashGet(selectedObject, 'type') === 'Floor';
  let isDeleteButtonDisabled = (!isFloorSelected) || isEmptySite;
  let canCreateOpenGroundFloor = false;
  if (isFloorSelected) {
    const selectedObjectMass = Object.values(buildingInfo.masses).find((m) => m.massIndex === selectedObject.massIndex);
    const type = lodashGet(selectedObjectMass, 'type');

    const { storyGroups } = getBuildingStoriesData(selectedObjectMass);
    isDeleteButtonDisabled = isDeleteButtonDisabled || checkFloorDeleteDisabaling(storyGroups);
    const isBottomFloorSelected = isFloorSelected && selectedObject.floorGroupIndex === 0 && selectedObject.floorIndex === 0;
    if (isBottomFloorSelected) {
      if (storyGroups && type) {
        const hasOpenGroundFloor = isStoryGroupAnOpenGroundFloor(storyGroups[0]) || isStoryGroupARetailFloor(storyGroups[0]);
        canCreateOpenGroundFloor = (type === FEASIBILITY_MASS_TYPES.APARTMENTS) && !hasOpenGroundFloor;
      }
    }
  }
  //
  const buttonsArray = [
    [
      { id: FEASIBILITY_EDITOR_BUTTON_KEYS.TRANSFORM, name: T.translate('FEASIBILITY_EDITOR_OVERLAY_SELECT'), icon: icons.transformIcon, handleClick: handleEditorTypeChanged, selectable: !isEditorInTransformMode || isSketchEditOn },
      { id: FEASIBILITY_EDITOR_BUTTON_KEYS.MIRROR, name: T.translate('FEASIBILITY_EDITOR_OVERLAY_MIRROR'), icon: icons.mirrorIcon, handleClick: massActionClickHandler, disabled: !isMassSelected || !isOrthographic || isSketchEditOn },
      { id: FEASIBILITY_EDITOR_BUTTON_KEYS.DUPLICATE, name: T.translate('FEASIBILITY_EDITOR_OVERLAY_DUPLICATE'), icon: icons.duplicateIcon, handleClick: massActionClickHandler, disabled: !isMassSelected || !isOrthographic || isSketchEditOn },
      { id: FEASIBILITY_EDITOR_BUTTON_KEYS.DELETE, name: T.translate('FEASIBILITY_EDITOR_OVERLAY_DELETE'), icon: icons.deleteIcon, handleClick: massActionClickHandler, disabled: (!isMassSelected && !isParkingSelected) || !isOrthographic || isSketchEditOn },
    ],
    [
      SKETCH_TOOL_ACTIVATED ? { id: FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_BUILDING, name: T.translate('FEASIBILITY_EDITOR_OVERLAY_BUILDING'), icon: icons.createIcon, handleClick: handleCreateActionClicked, disabled: isIlStandard || isSketchEditOn } : {},
      {
        id: FEASIBILITY_EDITOR_BUTTON_KEYS.PARKING,
        name: T.translate('FEASIBILITY_EDITOR_OVERLAY_PARKING'),
        icon: icons.createParkingIcon,
        handleClick: handleEditorTypeChanged,
        selectable: true,
        disabled: isSketchEditOn,
        subButtons: [
          { id: FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_PARKING, name: T.translate('FEASIBILITY_EDITOR_OVERLAY_PRECAST_PARKING'), icon: icons.precastParkingIcon, handleClick: handleCreateActionClicked, disabled: isParkingDisabled },
          { id: FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_UNDERGROUND_PARKING, name: T.translate('FEASIBILITY_EDITOR_OVERLAY_UNDERGROUND_PARKING'), icon: icons.bgParkingIcon, handleClick: handleCreateActionClicked, disabled: isParkingDisabled },
          SKETCH_PARK_ACTIVATED ? { id: FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_SURFACE_PARKING, name: T.translate('FEASIBILITY_EDITOR_OVERLAY_SURFACE_PARKING'), icon: icons.surfaceParkingIcon, handleClick: handleCreateActionClicked, disabled: false } : {},
        ],
      },
      {
        id: FEASIBILITY_EDITOR_BUTTON_KEYS.FLOOR,
        name: T.translate('FEASIBILITY_EDITOR_OVERLAY_FLOOR'),
        handleClick: handleEditorTypeChanged,
        selectable: true,
        icon: icons.floorIcon,
        disabled: isEmptySite || isSketchEditOn,
        subButtons: [
          { id: FEASIBILITY_EDITOR_BUTTON_KEYS.FLOOR_DUPLICATE, name: T.translate('FEASIBILITY_EDITOR_OVERLAY_DUPLICATE'), icon: icons.duplicateFloorIcon, handleClick: floorActionClickHandler, disabled: (!isFloorSelected) || isEmptySite },
          { id: FEASIBILITY_EDITOR_BUTTON_KEYS.FLOOR_DELETE, name: T.translate('FEASIBILITY_EDITOR_OVERLAY_DELETE'), icon: icons.deleteFloorIcon, handleClick: floorActionClickHandler, disabled: isDeleteButtonDisabled },
          {
            id: FEASIBILITY_EDITOR_BUTTON_KEYS.GROUND_FLOOR_TYPE,
            name: T.translate(buildingInfo.groundFloorType === GROUND_FLOOR_TYPES.RETAIL ? 'FEASIBILITY_EDITOR_OVERLAY_RETAIL' : 'FEASIBILITY_EDITOR_OVERLAY_OPEN_GF'),
            icon: icons.openGFloorIcon,
            handleClick: floorActionClickHandler,
            disabled: (!canCreateOpenGroundFloor) || isEmptySite,
            hidden: isIlStandard,
          },
        ],
      },
      { id: FEASIBILITY_EDITOR_BUTTON_KEYS.SETBACK, name: T.translate('FEASIBILITY_EDITOR_OVERLAY_SETBACK'), icon: icons.setbackIcon, handleClick: handleEditorTypeChanged, selectable: true, disabled: isEmptySite || isSketchEditOn, hidden: isIlStandard },
    ],
    [
      { id: FEASIBILITY_EDITOR_BUTTON_KEYS.UNDO, name: T.translate('FEASIBILITY_EDITOR_OVERLAY_UNDO'), icon: icons.undoIcon, disabled: !canUndo, handleClick: () => handleUndoRequested() },
    ],
  ];

  return (
    <>
      <ViewerUiPanelWrapper position="top-left">
        {/* ========= metadata panel ========= */}
        <ViewerUiPanel isClose={isMetadataPanelClose} width={500}>
          {isEditModeOn && isSketchCentric && (
            <StyledTabs defaultActiveKey={currentTab} animated={{ inkBar: false, tabPane: false }} style={{ margin: '0 0 6px 180px' }} onChange={(key) => setCurrentTab(key)}>
              <TabPane tab={T.translate('DATA')} key={TAB_NAMES.DATA} />
              <TabPane tab={T.translate('FORM')} key={TAB_NAMES.FORM} />
            </StyledTabs>
          )}
          {currentTab === TAB_NAMES.DATA && <FeasibilityResultData profile={profile} siteData={siteData} legendKey={legendKey} isEmptySite={isEmptySite} />}
          {currentTab === TAB_NAMES.FORM && isSketchCentric && <FeasibilityTargetFormViewerContainer width={500} selectedObject={selectedObject} masses={buildingInfo.masses} />}
        </ViewerUiPanel>

        {/* ========= Ui Buttons ========= */}
        <UiButtonsWrapper>
          {/* ========= toggle Orthographic ========= */}
          <CameraProjectionToggleButton
            toggle={() => setIsOrthographic(!isOrthographic)}
            selected={!isOrthographic}
            marginTop={10}
          />

          {/* ============== PDF Download ============== */}
          <SwappViewerActionButton
            icon={downloadPdfSmallIcon}
            isHidden={isEditModeOn || !pdfUrl}
            marginTop={10}
            onClick={() => downloadUrl(pdfUrl)}
          />
        </UiButtonsWrapper>
      </ViewerUiPanelWrapper>

      {/* ============== Building stories ============== */}
      {isBuildingStoriesOn && (
        <ViewerUiPanelWrapper position="bottom-right" fitContent>
          <ViewerUiPanel width={150} isRight>
            <BuildingStoryUI swpProject={lodashGet(buildingInfo, 'swpProject')} setIsOrthographic={setIsOrthographic} />
          </ViewerUiPanel>
        </ViewerUiPanelWrapper>
      )}

      {isEditModeOn && <EditPanelContainer offset={isMetadataPanelClose ? 0 : 50} landscape buttonsArray={buttonsArray} />}
    </>
  );
};

ProgramViewerUi.propTypes = {
  buildingInfo: PropTypes.object,
  profileId: PropTypes.number,
  setIsOrthographic: PropTypes.func,
  isOrthographic: PropTypes.bool,
  isEditModeOn: PropTypes.bool,
  isMetadataPanelClose: PropTypes.bool,
  isIlStandard: PropTypes.bool,
  isSketchParkingOn: PropTypes.bool,
  isBuildingStoriesOn: PropTypes.bool,
  isEditorInTransformMode: PropTypes.bool,
  beginEditing: PropTypes.func,
  addTransformOperationIfNeeded: PropTypes.func,
  legendKey: PropTypes.string,
  swappProject: PropTypes.object,
};

export default ProgramViewerUi;
