import React, { useState } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { Select, Modal } from 'antd';
import Geocode from 'react-geocode';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { StyledInput, StyledSelect } from 'styles/commonComponents.styles';
import { GENERATE_SWAPP_FORM } from 'constants/fieldNames';
import { PROJECT_STAGES, PROJECT_STATUS } from 'constants/profileConsts';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import MapComponent from './MapComponent';
import UploadThumbnail from './UploadThumbnail';
import { LocationWrapper, InputWrapper, InputData, FormItem } from './ProjectInfoForm.styles';
import { REACT_APP_GOOGLE_MAP_KEY } from '../../../config';
import PlanUpload from './PlanUpload';

Geocode.setApiKey(REACT_APP_GOOGLE_MAP_KEY);
Geocode.enableDebug();

const { Option } = Select;
const { confirm } = Modal;

const ProjectInfoForm = (props) => {
  const { form, isEdit, sitePolygonLatLon, form: { setFieldsValue }, currentStatus, handleThumbnailUpload, handleSitePolygonUpdated, showPlanUpload, updateStatus, hasErrors, handleFormEdit, rootStageKey, history } = props;
  const [address, setAddress] = useState(null);
  const isFeasibility = rootStageKey === PROJECT_STAGES[UI_AUTHORIZE_PATH.FEASIBILITY_STUDY];

  const handleChange = (changeAddress) => {
    setAddress(changeAddress);
  };

  const addressFromGeocode = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const addressResponse = response.results[0].formatted_address;
        setAddress(addressResponse);
      },
      (error) => {
        console.error(error);
      },
    );
  };

  const handleSelect = (selectedAddress) => {
    geocodeByAddress(selectedAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        addressFromGeocode(latLng.lat, latLng.lng);
        setFieldsValue({ [GENERATE_SWAPP_FORM.LOCATION]: latLng });
        if (handleFormEdit) {
          handleFormEdit();
        }
      })
      .catch((error) => console.error(T.translate('ERROR'), error));
  };

  const showArchiveConfirm = (value) => {
    confirm({
      width: 450,
      title: T.translate('SET_LOCATION_ARCHIVE_MODAL_TITLE'),
      content: T.translate('SET_LOCATION_ARCHIVE_MODAL_DESCRIPTION'),
      cancelText: T.translate('SET_LOCATION_ARCHIVE_MODAL_CANCEL'),
      okText: T.translate('SET_LOCATION_ARCHIVE_MODAL_OK'),
      okType: 'danger',
      onOk() { updateStatus(value); },
      onCancel() { setFieldsValue({ [GENERATE_SWAPP_FORM.STATUS]: currentStatus }); },
    });
  };

  const showDeleteConfirm = (value) => {
    confirm({
      width: 450,
      title: T.translate('SET_LOCATION_DELETE_MODAL_TITLE'),
      cancelText: T.translate('SET_LOCATION_ARCHIVE_MODAL_CANCEL'),
      okText: T.translate('SET_LOCATION_ARCHIVE_MODAL_OK'),
      okType: 'danger',
      onOk: () => {
        updateStatus(value);
        history.push('/');
      },
      onCancel: () => setFieldsValue({ [GENERATE_SWAPP_FORM.STATUS]: currentStatus }),
    });
  };

  const handleStatusChange = (value) => {
    if (value === PROJECT_STATUS.ARCHIVED) {
      showArchiveConfirm(value);
    } else if (value === PROJECT_STATUS.DELETE) {
      showDeleteConfirm(value);
    } else {
      updateStatus(value);
    }
  };

  const renderStatus = () => {
    if (!updateStatus) {
      return;
    }

    return (
      <FormItem
        name={GENERATE_SWAPP_FORM.STATUS}
        width={250}
        label={T.translate('SET_LOCATION_STATUS')}
      >
        <StyledSelect onChange={handleStatusChange}>
          <Option value={PROJECT_STATUS.OPEN}>{T.translate(`STATUS.${PROJECT_STATUS.OPEN}`)}</Option>
          <Option value={PROJECT_STATUS.REPOSITORY}>{T.translate(`STATUS.${PROJECT_STATUS.REPOSITORY}`)}</Option>
          <Option value={PROJECT_STATUS.ARCHIVED}>{T.translate(`STATUS.${PROJECT_STATUS.ARCHIVED}`)}</Option>
          <Option value={PROJECT_STATUS.DELETE}>{T.translate(`STATUS.${PROJECT_STATUS.DELETE}`)}</Option>
        </StyledSelect>
      </FormItem>
    );
  };

  const renderFloor = () => {
    if (isFeasibility) {
      return renderStatus();
    }

    return (
      <FormItem
        name={GENERATE_SWAPP_FORM.FLOOR}
        label={T.translate('SET_LOCATION_FLOOR_LABEL')}
        rules={[{ required: true, message: T.translate('SET_LOCATION_FLOOR_ERROR') }]}
      >
        <StyledInput placeholder={T.translate('SET_LOCATION_FLOOR_PLACEHOLDER')} disabled={isEdit && isFeasibility} />
      </FormItem>
    );
  };

  return (
    <LocationWrapper>
      <InputWrapper>
        {/* =============== Thumbnail =============== */}
        <FormItem valuePropName="uploadedImageUrl" name={GENERATE_SWAPP_FORM.THUMBNAIL} noStyle>
          <UploadThumbnail onUpload={handleThumbnailUpload} />
        </FormItem>

        <InputData>
          {/* =============== name =============== */}
          <FormItem
            name={GENERATE_SWAPP_FORM.PROJECT_NAME}
            label={T.translate('SET_LOCATION_PROJECT_NAME_LABEL')}
            rules={[{ required: true, message: T.translate('SET_LOCATION_PROJECT_NAME_ERROR') }]}
          >
            <StyledInput placeholder={T.translate('SET_LOCATION_PROJECT_NAME_PLACEHOLDER')} />
          </FormItem>

          {/* =============== Floor =============== */}
          {renderFloor()}
        </InputData>
      </InputWrapper>

      {/* =============== Project Status =============== */}
      {!isFeasibility && renderStatus()}

      {/* =============== file upload =============== */}
      {showPlanUpload && <PlanUpload hasErrors={hasErrors} multiFile />}

      {/* =============== Map =============== */}
      <MapComponent isEdit={isEdit} sitePolygonLatLon={sitePolygonLatLon} form={form} handleSelect={handleSelect} handleChange={handleChange} address={address} handleSitePolygonUpdated={handleSitePolygonUpdated} isFeasibility={isFeasibility} />

    </LocationWrapper>
  );
};

ProjectInfoForm.propTypes = {
  form: PropTypes.object.isRequired,
  setFieldsValue: PropTypes.func,
  handleThumbnailUpload: PropTypes.func,
  handleFormEdit: PropTypes.func,
  updateStatus: PropTypes.func,
  showPlanUpload: PropTypes.bool,
  isEdit: PropTypes.bool,
  hasErrors: PropTypes.object,
  rootStageKey: PropTypes.string,
  currentStatus: PropTypes.string,
  history: PropTypes.object,
  handleSitePolygonUpdated: PropTypes.func,
  sitePolygonLatLon: PropTypes.array,
};

export default ProjectInfoForm;
