import React from 'react';

export default () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 31H9V39H20V31Z" stroke="currentcolor" strokeLinejoin="round" />
    <path d="M23 9H9V28H23V9Z" stroke="currentcolor" strokeLinejoin="round" />
    <path d="M42 18H30V28H42V18Z" stroke="currentcolor" strokeLinejoin="round" />
    <path d="M35 32H23V42H35V32Z" stroke="currentcolor" strokeLinejoin="round" />
  </svg>
);
