import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashIsEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

const PlanIndexListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.font.size.large};
  margin-bottom: 15px;
`;

const IndexItem = styled.div`
  display: flex;
  margin-bottom: 7px;
  line-height: 1.3;
`;

const IndexItemColorBox = styled.div`
  margin-right: 7px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  width: 15px;
  height: 15px;
`;

const IndexItemText = styled.div`
  font-size: ${({ theme }) => theme.font.size.medium};
`;

const PlanIndexList = (props) => {
  const { data, type } = props;

  if (lodashIsEmpty(data)) {
    return null;
  }

  return (
    <PlanIndexListWrapper>
      <Title>{T.translate(type)}</Title>
      {data.map((item) => (
        <IndexItem key={item.key}>
          <IndexItemColorBox color={item.color} />
          <IndexItemText>{item.name || T.translate('UNTITLED')}</IndexItemText>
        </IndexItem>
      ))}
    </PlanIndexListWrapper>
  );
};

PlanIndexList.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string,
};

export default PlanIndexList;
