import * as actionTypes from '../actions/actionTypes';

const initialState = {
  currentUser: null,
  error: null,
};

export const UserReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.FILL_USER:
      return { ...state, currentUser: { ...payload } };
    case actionTypes.FILL_USER_ERROR:
      return { ...state, currentUser: null, error: { payload } };
    case actionTypes.REMOVE_USER:
      return { ...state, currentUser: null };
    default:
      return state;
  }
};
