import { useSelector, shallowEqual } from 'react-redux';

// General selectors
export const useAllMarkups = () => useSelector((state) => getAllMarkups(state));
const getAllMarkups = ({ markups }) => markups.markups;

export const useSelectedMarkupsIds = () => useSelector((state) => selectedMarkupSelector(state));

export const useMarkupsConfigColor = (defColor) => useSelector((state) => getConfigColor(state) || defColor);
const getConfigColor = ({ markups }) => markups.color;

export const useMarkupsConfigLineWidth = () => useSelector((state) => getConfigLineWidth(state));
const getConfigLineWidth = ({ markups }) => markups.lineWidth;

export const useMarkupsConfigFontSize = () => useSelector((state) => getConfigFontSize(state));
const getConfigFontSize = ({ markups }) => markups.fontSize;

export const useMarkupsConfigTool = () => useSelector((state) => getSelectedTool(state));
const getSelectedTool = ({ markups }) => markups.selectedTool;

export const useMarkupsSubType = () => useSelector((state) => getSelectedSubType(state));
const getSelectedSubType = ({ markups }) => markups.subType;

export const useTextMarkupEditable = () => useSelector((state) => getTextMarkupEditable(state));
const getTextMarkupEditable = ({ markups }) => markups.textMarkupEditable;

// By ID Selectors
export const useMarkupById = (id) => useSelector((state) => getMarkupById(state, id), shallowEqual);
const getMarkupById = ({ markups }, id) => markups.markups[id];

export const useMarkupTransform = (id) => useSelector((state) => getMarkupTransformByIdSelector(state, id));
const getMarkupTransformByIdSelector = ({ markups }, id) => markups.markups[id]?.transform;

export const useMarkupAttachmentId = (id) => useSelector((state) => getMarkupAttachmentIdByIdSelector(state, id));
const getMarkupAttachmentIdByIdSelector = ({ markups }, id) => markups.markups[id]?.attachmentId;

export const useMarkupPoints = (id) => useSelector((state) => getMarkupPointsIdSelector(state, id));
const getMarkupPointsIdSelector = ({ markups }, id) => markups.markups[id].transform.points;

export const useMarkupBeingCreatedId = () => useSelector((state) => isMarkupBeingCreated(state));
const isMarkupBeingCreated = ({ markups }) => markups.markupIncreation;

export const useIsMarkupSelected = (id) => {
  const isSelected = useSelector((state) => isMarkupSelectedSelector(state, id));
  return isSelected;
};
const isMarkupSelectedSelector = ({ markups }, id) => markups.selectedMarkups.includes(id);

export const selectedMarkupSelector = ({ markups }) => markups.selectedMarkups;

export const useIsMarkupDragged = () => useSelector((state) => draggedMarkupSelector(state));
const draggedMarkupSelector = ({ markups }) => markups.dragged;

export const useTextMarkupHeight = (id) => useSelector((state) => getTextMarkupHeightSelector(state, id));
const getTextMarkupHeightSelector = ({ markups }, id) => markups.markups[id]?.height;

export const useMarkupColor = (id) => useSelector((state) => getMarkupColorSelector(state, id));
const getMarkupColorSelector = ({ markups }, id) => markups.markups[id]?.color;

export const useMarkupLineWidth = (id) => useSelector((state) => getMarkupWidthSelector(state, id));
const getMarkupWidthSelector = ({ markups }, id) => markups.markups[id]?.lineWidth;

export const useMarkupText = (id) => useSelector((state) => getMarkupTextSelector(state, id));
const getMarkupTextSelector = ({ markups }, id) => markups.markups[id]?.text;

export const useMarkupFontSize = (id) => useSelector((state) => getMarkupFontSizeSelector(state, id));
const getMarkupFontSizeSelector = ({ markups }, id) => markups.markups[id]?.fontSize;
