import styled from 'styled-components';
import Icon from '@ant-design/icons';

export const LoginContainer = styled.div`
  display: flex;
  height: 100vh;
`;

export const LeftPanel = styled.div`
  min-width: 484px;
  background-color: ${({ theme }) => theme.colors.gray_01};
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const SwappLogo = styled(Icon)`
  margin: 0 auto;
  font-size: 260px;
  color: ${({ theme }) => theme.colors.textColor};
`;

export const RightPanel = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
`;

export const RightPanelFormWrapper = styled.div`
  width: ${({ theme }) => theme.other.authFormWidth};
  margin: 0 auto;
  
  .ant-form-item label{
    ::before {
      content: '';
    }
  }
`;
