import demoStandardIcon from './demoStandardIcon';
import dormitoriesStandardIcon from './dormitoriesStandardIcon';
import ilStandardIcon from './ilStandardIcon';
import prefabStandardIcon from './prefabStandardIcon';
import ryanStandardIcon from './ryanStandardIcon';
import ukStandardIcon from './ukStandardIcon';

export default {
  demoStandardIcon,
  dormitoriesStandardIcon,
  ilStandardIcon,
  prefabStandardIcon,
  ryanStandardIcon,
  ukStandardIcon,
};
