import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { SnippetsOutlined } from '@ant-design/icons';
import Notification from '../common/Notification';
import { PrimaryButton } from '../../styles/commonComponents.styles';

const EmptyProjectList = ({ toggleModal }) => (
  <div style={{ margin: '200px' }}>
    <Notification iconComponent={<SnippetsOutlined />} hints={T.translate('EMPTY_PROJECT_LIST_HINTS')} notification={T.translate('EMPTY_PROJECT_LIST_NOTIFICATION')} />
    <PrimaryButton center width={360} onClick={toggleModal}>{T.translate('EMPTY_PROJECT_LIST_BUTTON')}</PrimaryButton>
  </div>
);

EmptyProjectList.propTypes = {
  toggleModal: PropTypes.func,
};

export default EmptyProjectList;
