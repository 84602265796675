import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ModalBackground = styled.div`
  background-color: rgba(0, 0, 0, .4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: ${({ isModalOpen }) => (isModalOpen ? 1 : 0)};
  transition: all .3s ease-in-out;
  pointer-events: ${({ isModalOpen }) => (isModalOpen ? 'auto' : 'none')}
`;

const ModalBody = styled.div`  border-radius: ${({ theme }) => theme.other.borderRadius};
  background-color: ${({ theme }) => theme.colors.gray_01};
  width: ${({ isModalOpen, width }) => {
    if (!isModalOpen) {
      return 0;
    }
    return width ? `${width}px` : '700px';
  }};
  height: ${({ isModalOpen, height }) => {
    if (!isModalOpen) {
      return 0;
    }
    return height ? `${height}px` : '850px';
  }};
  max-height: 85%;
  max-width: 85%;
  margin: 0 auto;
  z-index: 12;
  position: relative;
  box-shadow: 0 4px 19px rgba(0, 0, 0, 0.5);
  pointer-events: ${({ isModalOpen }) => (isModalOpen ? 'auto' : 'none')}
  transition: all .3s ease-in-out;
  line-height: 1.2;
  overflow: hidden;
`;

const Modal = (props) => {
  const { toggleModal, isModalOpen, children, width, height } = props;

  return (
    <ModalBackground isModalOpen={isModalOpen} onClick={toggleModal}>
      <ModalBody width={width} height={height} isModalOpen={isModalOpen} onClick={(e) => e.stopPropagation()}>
        {children}
      </ModalBody>
    </ModalBackground>
  );
};

Modal.propTypes = {
  toggleModal: PropTypes.func,
  isModalOpen: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
};

export default Modal;
