/* eslint-disable prefer-destructuring */
import { createSlice, current } from '@reduxjs/toolkit';
import { getPointsCenter, rotatePolygon } from 'utils/algorithms/algorithmHelpers';
import { MARKUPS_COLORS, MARKUPS_TOOLS_NAME } from 'constants/markupsConts';
import SwpRoot from '@swapp/swappcommonjs/dist/swpProject/SwpRoot';
import { getTextWithPressedKey } from './textFieldHelper';

const initialState = {
  markups: {},
  isMarkupsOpen: false,
  selectedMarkups: [],
  selectedTool: null,
  subtype: null,
  color: MARKUPS_COLORS.RED,
  lineWidth: 0.5,
  fontSize: 1,
  dragged: false,
  markupIncreation: null,
  textMarkupEditable: null,
};

const markupsSlice = createSlice({
  name: 'markups',
  initialState,
  reducers: {
    initialize(state, action) {
      state.markups = action.payload;
    },
    clearMarkups(state) {
      state.isMarkupsOpen = !state.isMarkupsOpen;
      state.markups = {};
    },
    addMarkup(state, action) {
      const { type, point, dimensions = [10, 10], attachmentId, subType } = action.payload;
      const id = SwpRoot.generateGuid();
      const newMarkup = {
        type,
        id,
        color: state.color,
        lineWidth: state.lineWidth,
        attachmentId,
        subType,
        dimensions,
      };
      switch (type) {
        case MARKUPS_TOOLS_NAME.ARROW:
        case MARKUPS_TOOLS_NAME.SECTION:
          newMarkup.transform = {
            points: Array(2).fill([...point]),
          };
          state.markupIncreation = newMarkup.id;
          break;
        case MARKUPS_TOOLS_NAME.CLOUD:
          newMarkup.transform = {
            points: Array(4).fill([...point]),
            rotation: 0,
          };
          state.markupIncreation = newMarkup.id;
          break;
        case MARKUPS_TOOLS_NAME.SCOPE_BOX:
          const halfDistanceX = dimensions[0] / 2;
          const halfDistanceY = dimensions[1] / 2;
          newMarkup.transform = {
            points: [
              [point[0] - halfDistanceX, point[1] - halfDistanceY],
              [point[0] - halfDistanceX, point[1] + halfDistanceY],
              [point[0] + halfDistanceX, point[1] + halfDistanceY],
              [point[0] + halfDistanceX, point[1] - halfDistanceY],
            ],
            rotation: 0,
          };
          break;
        case MARKUPS_TOOLS_NAME.TEXT:
          newMarkup.transform = {
            points: [
              [...point],
              [point[0] + state.fontSize * 10, point[1]],
            ] };
          newMarkup.fontSize = state.fontSize;
          newMarkup.height = 0;
          newMarkup.text = '';
          state.textMarkupEditable = newMarkup.id;
          // When a text markup is created, it is selected
          state.selectedMarkups = [newMarkup.id];
          break;
        default:
          break;
      }
      state.markups[newMarkup.id] = newMarkup;
    },
    duplicateMarkup(state, action) {
      const { id } = action.payload;
      const origMarkup = current(state.markups[id]);
      const newMarkup = JSON.parse(JSON.stringify(origMarkup));
      newMarkup.id = SwpRoot.generateGuid();
      newMarkup.transform.points.forEach((point) => {
        point[0] += 4;
        point[1] += 4;
      });
      state.markups[newMarkup.id] = newMarkup;
      state.selectedMarkups = [newMarkup.id];
    },
    setMarkupInCreation(state, action) {
      const { id } = action.payload;
      state.markupIncreation = id;
    },
    setTextMarkupEditable(state, action) {
      const { id } = action.payload;
      state.textMarkupEditable = id;
    },
    updateMarkupInCreation(state, action) {
      const { point, id } = action.payload;
      if (!id || !point) {
        return;
      }
      switch (state.markups[id].type) {
        case MARKUPS_TOOLS_NAME.ARROW:
        case MARKUPS_TOOLS_NAME.SECTION:
          state.markups[id].transform.points[1] = point;
          break;
        case MARKUPS_TOOLS_NAME.CLOUD:
          state.markups[id].transform.points[2] = point;
          state.markups[id].transform.points[1][1] = point[1];
          state.markups[id].transform.points[3][0] = point[0];
          break;
        default:
          break;
      }
    },
    deleteMarkup(state, action) {
      const { id } = action.payload;
      delete state.markups[id];
      state.selectedMarkups = [];
      state.markupIncreation = null;
    },
    selectMarkup(state, action) {
      const { id } = action.payload;
      state.selectedMarkups = id ? [id] : [];
      state.markupIncreation = null;
      if (id && state.markups[id]) {
        state.selectedTool = state.markups[id].type;
        state.subType = state.markups[id].subType;
      }
    },
    selectTool(state, action) {
      const { tool, subType } = action.payload;
      state.selectedTool = tool;
      state.subType = subType;
      state.markupIncreation = null;
    },
    changeColor(state, action) {
      const { color } = action.payload;
      state.color = color;
      state.markupIncreation = null;
      state.selectedMarkups.forEach((markupId) => {
        state.markups[markupId].color = color;
      });
    },
    changeLineWidth(state, action) {
      const { lineWidth } = action.payload;
      state.lineWidth = lineWidth;
      state.markupIncreation = null;
      state.selectedMarkups.forEach((markupId) => {
        state.markups[markupId].lineWidth = lineWidth;
      });
    },
    changeFontSize(state, action) {
      const { increase, decrease, fontSize } = action.payload;
      state.markupIncreation = null;
      if (fontSize) {
        state.fontSize = fontSize;
      } else if (increase) {
        state.selectedMarkups.forEach((markupId) => {
          state.markups[markupId].fontSize += 0.5;
        });
      } else if (decrease) {
        state.selectedMarkups.forEach((markupId) => {
          state.markups[markupId].fontSize -= 0.5;
        });
      } else {
        console.warn('No value was assaigned to changeFontSize, try: increase, decrease or fontSize');
      }
      if (state.selectedMarkups[0]) {
        state.fontSize = state.markups[state.selectedMarkups[0]].fontSize;
      }
    },
    setIsDragged(state, action) {
      const { isDragged } = action.payload;
      state.dragged = isDragged;
    },
    updateMerkupText(state, action) {
      const { id, key } = action.payload;
      if (!state.markups[id]) {
        return;
      }
      const newText = getTextWithPressedKey(state.markups[id].text, key);
      state.markups[id].text = newText;
    },
    updateMerkupTextHeight(state, action) {
      const { id, height } = action.payload;
      state.markups[id].height = height;
    },
    changePoints(state, action) {
      const { id, points, isDragged } = action.payload;
      if (isDragged) {
        state.dragged = isDragged;
      }
      state.markups[id].transform.points = points;
    },
    rotate(state, action) {
      const { id, deltaAngle, isDragged } = action.payload;
      if (isDragged) {
        state.dragged = isDragged;
      }
      const { transform } = state.markups[id];
      const { rotation, points } = transform;
      const center = getPointsCenter(points);
      const newPoints = rotatePolygon(points, center, deltaAngle);
      const newRot = rotation + deltaAngle;
      state.markups[id].transform = {
        ...transform,
        rotation: newRot,
        points: newPoints,
      };
    },
  },
});

export const {
  initialize,
  changePoints,
  rotate,
  addMarkup,
  clearMarkups,
  resetCenter,
  selectMarkup,
  setIsDragged,
  updateMerkupText,
  selectTool,
  changeColor,
  changeLineWidth,
  changeFontSize,
  updateMarkupInCreation,
  setMarkupInCreation,
  deleteMarkup,
  duplicateMarkup,
  setTextMarkupEditable,
  updateMerkupTextHeight,
} = markupsSlice.actions;

export default markupsSlice.reducer;
