import React from 'react';
import T from 'i18n-react';
import PropTypes from 'prop-types';
import EmptyMessage from 'components/common/EmptyMessage';
import Line from './components/Line';
import EnergyDistributionCharts from './components/EnergyDistributionCharts';

const SingleAnalyisResult = ({ data }) => {
  if (!data) {
    return (
      <EmptyMessage description={T.translate('No data available')} />
    );
  }
  const energyDistributionData = [{
    data: [
      data.energyLoadDistribution.cooling,
      data.energyLoadDistribution.heating,
      data.energyLoadDistribution.lighting,
      data.energyLoadDistribution.equipment,
      data.energyLoadDistribution.pump,
      data.energyLoadDistribution.fans,
      data.energyLoadDistribution.hotWater,
    ],
  }];

  return (
    <div>
      <b>Annual Energy Use</b>
      {/* <div>(kWh)</div> */}
      <Line label="Gas" value={data.annualEnergyUseGas} />
      <Line label="Electricity" value={data.annualEnergyUseElectricity} />
      <Line label="Total" value={data.annualEnergyUseTotal} />
      <div>
        <b>Energy Use Intensity</b>
        <div>
          {data.buildingEui}
        </div>
      </div>
      <Line label="Net Internal Area" value={data.netInternalArea} />
      <EnergyDistributionCharts data={energyDistributionData} width="410" />
    </div>
  );
};

SingleAnalyisResult.propTypes = {
  data: PropTypes.object,
};

export default SingleAnalyisResult;
