import { combineReducers } from 'redux';
import { AuthReducer } from './auth';
import { UserReducer } from './user';
import { UserProjectsReducer } from './swappProfiles';
import { SwappProfileReducer } from './swappProfile';
import { profileTemplatesReducer } from './swappProfileTemplates';
import { feasibilityReducer } from './feasibility/reducers/reduser';
import editorReducer from './editor';
import userSettingsReducer from './userSettings';
import sketchToolReducer from './sketchToolStore';
import threeDWalkthroughReducer from './threeDWalkthrough';
import markupsReducer from './markups/markups';
import parkingFormRducer from './parkingForm';
import buildingStoriesReducer from './BuildingStoriesStore';
import testFitReducer from './testFit';
import viewsReducer from './views';

export default combineReducers({
  auth: AuthReducer,
  user: UserReducer,
  userProjects: UserProjectsReducer,
  swappProfile: SwappProfileReducer,
  swappProfileTemplates: profileTemplatesReducer,
  feasibility: feasibilityReducer,
  editor: editorReducer,
  userSettings: userSettingsReducer,
  sketchTool: sketchToolReducer,
  threeDWalkthrough: threeDWalkthroughReducer,
  markups: markupsReducer,
  buildingStories: buildingStoriesReducer,
  parkingForm: parkingFormRducer,
  testFit: testFitReducer,
  views: viewsReducer,
});
