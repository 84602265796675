import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import {
  FormWithLabelWrapper,
  HeaderWrapper,
  FormArea,
  StyledIcon,
  SubTitleText,
  TitleText,
  Separator,
  ChildrenWrapper,
  SuffixWrapper,
} from './FormWithLabel.styles';

const FormWithLabel = (props) => {
  const { title, subtitle, width, children, loading = false, hidden, iconComponent, explore, marginBottom, borderLess, disableAnimation, customHeader, small, suffix } = props;
  const isOnlyTitle = subtitle && iconComponent;
  return (
    <FormWithLabelWrapper disableAnimation={disableAnimation} isLoading={loading} isHidden={hidden}>
      <FormArea marginBottom={marginBottom} explore={explore} width={width} borderLess={borderLess} layout="horizontal">
        <HeaderWrapper>
          {customHeader || (
            <>
              <div>
                {iconComponent ? <StyledIcon component={iconComponent} /> : null}
              </div>
              <div>
                {title ? <TitleText small={small} marginBottom={iconComponent ? isOnlyTitle : 2}>{title}</TitleText> : null}
                {subtitle ? <SubTitleText>{subtitle}</SubTitleText> : null}
              </div>
            </>
          )}
          <SuffixWrapper>
            {suffix}
          </SuffixWrapper>
        </HeaderWrapper>
        {isOnlyTitle && !customHeader && <Separator />}
        {loading && <Skeleton active />}
        <ChildrenWrapper hidden={loading}>
          {children}
        </ChildrenWrapper>
      </FormArea>
    </FormWithLabelWrapper>
  );
};

FormWithLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  customHeader: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  width: PropTypes.number,
  loading: PropTypes.bool,
  hidden: PropTypes.bool,
  explore: PropTypes.bool,
  iconComponent: PropTypes.func,
  marginBottom: PropTypes.number,
  disableAnimation: PropTypes.bool,
  borderLess: PropTypes.bool,
  small: PropTypes.bool,
  suffix: PropTypes.object,
};

export default FormWithLabel;
