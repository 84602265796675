import React from 'react';

export default () => (
  <svg width="32" height="32" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.6468 25.819H14.2322V24.9247L17.621 21.1593C18.1231 20.59 18.4683 20.1283 18.6565 19.7742C18.8493 19.4156 18.9456 19.0458 18.9456 18.6648C18.9456 18.1538 18.791 17.7346 18.4817 17.4074C18.1724 17.0802 17.76 16.9166 17.2445 16.9166C16.6259 16.9166 16.144 17.0936 15.7989 17.4477C15.4582 17.7974 15.2879 18.286 15.2879 18.9136H14.0439C14.0439 18.0126 14.3331 17.2841 14.9113 16.7283C15.4941 16.1725 16.2718 15.8945 17.2445 15.8945C18.1545 15.8945 18.8739 16.1343 19.4029 16.614C19.9318 17.0891 20.1963 17.7234 20.1963 18.5168C20.1963 19.4806 19.5822 20.6281 18.3539 21.9595L15.7316 24.8037H20.6468V25.819Z" fill="currentcolor" />
    <path d="M22.2874 25.819V16.029H25.0509C25.9026 16.029 26.6557 16.2173 27.3101 16.5938C27.9646 16.9704 28.4689 17.506 28.823 18.2008C29.1816 18.8956 29.3632 19.6935 29.3677 20.5945V21.2198C29.3677 22.1433 29.1883 22.9524 28.8297 23.6472C28.4756 24.342 27.9668 24.8754 27.3034 25.2474C26.6445 25.6195 25.8757 25.81 24.9971 25.819H22.2874ZM23.5784 17.0914V24.7633H24.9366C25.9318 24.7633 26.705 24.454 27.2564 23.8354C27.8122 23.2168 28.0901 22.336 28.0901 21.1929V20.6214C28.0901 19.5097 27.8279 18.6468 27.3034 18.0327C26.7834 17.4141 26.0438 17.1003 25.0845 17.0914H23.5784Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M33.8525 7.45809H8.60659V33.6537H33.8525V7.45809ZM7.45905 6.31055V34.8012H35V6.31055H7.45905Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0H1.64959V1.14754H1.14754V1.64959H0V0ZM5.95287 1.14754H3.80123V0H5.95287V1.14754ZM10.2561 1.14754H8.10451V0H10.2561V1.14754ZM14.5594 1.14754H12.4078V0H14.5594V1.14754ZM18.8627 1.14754H16.7111V0H18.8627V1.14754ZM23.166 1.14754H21.0143V0H23.166V1.14754ZM27.4693 1.14754H25.3176V0H27.4693V1.14754ZM31.7725 1.14754H29.6209V0H31.7725V1.14754ZM35 1.14754H33.9242V0H35V1.14754ZM1.14754 3.80123V5.95287H0V3.80123H1.14754ZM1.14754 8.10451V10.2561H0V8.10451H1.14754ZM1.14754 12.4078V14.5594H0V12.4078H1.14754ZM1.14754 16.7111V18.8627H0V16.7111H1.14754ZM1.14754 21.0143V23.166H0V21.0143H1.14754ZM1.14754 25.3176V27.4693H0V25.3176H1.14754ZM1.14754 29.6209V31.7725H0V29.6209H1.14754ZM1.14754 33.9242V35H0V33.9242H1.14754Z" fill="currentcolor" />
  </svg>
);
