import React, { useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useThree } from 'react-three-fiber';
import { Circle } from '@react-three/drei';
import { useDrag } from 'react-use-gesture';
import * as THREE from 'three';
import { currentThemeSelector } from 'store/userSettings';
import { EDIT_TYPES, MassTransformer } from 'store/editor';
import lodashIncludes from 'lodash/includes';
import rotationIcon from '../../../styles/static/icons/feasibilityIcons/setup/rotateIcon.png';
import {
  extendRectanglePolygon,
  getPolygonLongestEdge,
  getRectanglePolygonCenter,
  projectWorldToScreenPosition,
  vecAngle,
  vecLerp,
  vecMoveTowards,
  vecSub,
  vecTo3d,
  vecAdd,
  vecLength,
  normalizeRad,
} from '../../algorithms/algorithmHelpers';
import { getRenderPropsByName } from '../helpers/FeasibilityRenderProps';
import { notifyDragFinished } from '../helpers/ThreeHelpers';
import { changeCursor } from '../helpers/SelectionHelpers';

const MassGroupRotator = () => {
  const { camera, gl } = useThree();
  const rotateRect = useSelector((state) => state.editor.massTransformer.transformRect, shallowEqual);
  const deltaAngle = useSelector((state) => state.editor.massTransformer.deltaAngle);
  const isMoving = useSelector((state) => state.editor.massTransformer.isMoving);
  const rotationAnchor = useRef(null);
  const isEditModeOn = useSelector(({ editor }) => lodashIncludes([EDIT_TYPES.TEST_FIT, EDIT_TYPES.FEASIBILITY], editor.editSessionType));
  const currentTheme = useSelector(currentThemeSelector);
  const dispatch = useDispatch();
  const massProps = getRenderPropsByName('massRotator');

  const rotateProps = useDrag(({ event, buttons, xy, first, previous, args: [obbCenter] }) => {
    event.stopPropagation(); // This makes the click / drag exclusive to this object, preventing multiple move with one click
    const obbCenterTransformed = obbCenter;
    const obbCenterScreenPos = projectWorldToScreenPosition(gl, vecTo3d(obbCenterTransformed), camera);

    if (buttons === 1) {
      const startingAngle = vecAngle(vecSub(previous, obbCenterScreenPos));
      const currentAngle = vecAngle(vecSub(xy, obbCenterScreenPos));
      // The - in deltaAngle is because in screen space, positive Y is down and in 3d space, positive Y is up, so it flips angles.
      const deltaAng = -(currentAngle - startingAngle);
      if (!first && deltaAng !== 0) {
        dispatch(MassTransformer.rotateSelection(deltaAng));
      }
    }
    if (buttons === 0) {
      notifyDragFinished();
    }
  });

  if (rotateRect.length === 0 || !isEditModeOn) {
    return null;
  }

  const obb = extendRectanglePolygon(rotateRect, 2);
  const longestEdge = getPolygonLongestEdge(obb);
  let longestEdgeCenter = vecLerp(longestEdge[0], longestEdge[1], 0.5);
  const obbCenter = getRectanglePolygonCenter(obb);

  // When starting to rotate we check for the longest edge to set the rotating circle position.
  // After that, change the it's position based on the deltaAngle.
  // (Because the longest edge can change and be the opposite one, causing the circle to "jump" tot he other side).
  if (deltaAngle) {
    const diff = vecSub(obbCenter, rotationAnchor.current);
    const currentAngle = normalizeRad(vecAngle(diff));
    const length = vecLength(diff);
    const newAng = currentAngle + deltaAngle;
    const dir = [-Math.cos(newAng), -Math.sin(newAng)];
    rotationAnchor.current = vecMoveTowards(obbCenter, vecAdd(obbCenter, dir), length);
    longestEdgeCenter = vecMoveTowards(rotationAnchor.current, obbCenter, 5);
  } else {
    rotationAnchor.current = vecMoveTowards(longestEdgeCenter, obbCenter, -5);
  }

  return (
    <group>
      <group position={[0, 0, 251]}>
        {/* ======== renders the Bounding Box ======== */}
        <mesh {...massProps}>
          <meshLine attach="geometry" vertices={obb.map((v) => new THREE.Vector3(...v))} />
          <meshLineMaterial attach="material" color={currentTheme.colors.primaryColor} lineWidth={0.5} />
        </mesh>

        {/* ======== controls the rotation ======== */}
        {!isMoving && (
        <group>
          <Circle
            {...rotateProps(obbCenter)}
            position={rotationAnchor.current}
            args={[1.5, 22]}
            onPointerOver={() => changeCursor(`url(${rotationIcon}), auto`)}
            onPointerOut={() => changeCursor('default')}
            {...massProps}
          >
            <meshBasicMaterial attach="material" color={currentTheme.colors.primaryColor} />
          </Circle>
          <mesh {...massProps}>
            <meshLine attach="geometry" vertices={[vecTo3d(longestEdgeCenter), vecTo3d(rotationAnchor.current)].map((v) => new THREE.Vector3(...v))} />
            <meshLineMaterial attach="material" color={currentTheme.colors.primaryColor} lineWidth={0.5} />
          </mesh>
        </group>
        )}
      </group>
    </group>
  );
};

export default React.memo(MassGroupRotator);
