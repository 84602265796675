import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import Icon from '@ant-design/icons';

const Text = styled.div`
  margin-top: 2px;
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme, isSelected, disabled }) => {
    if (disabled) {
      return theme.colors.gray_05;
    }

    return isSelected ? theme.colors.primaryColor : theme.colors.gray_09;
  }};
`;

const ButtonWrapper = styled.div`
  min-width: ${({ landscape }) => (landscape ? '39px' : '50px')};
  margin: ${({ landscape }) => (landscape ? '0 5px 0 10px' : '0 0 10px 0')}; // Nur said don't touch! thanks:)
  cursor: ${({ onClick }) => (onClick && 'pointer')};
  color: ${({ theme, isSelected, disabled }) => {
    if (disabled) {
      return theme.colors.gray_05;
    }

    return isSelected ? theme.colors.primaryColor : theme.colors.gray_09;
  }};

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    color: ${({ theme, disabled }) => (!disabled && theme.colors.primaryColorHover)} !important;
    
    ${Text} {
      color: ${({ theme, disabled }) => (!disabled && theme.colors.primaryColorHover)} !important;
    }
  }
`;

const StyledIcon = styled(Icon)`
  display: block;
  margin: 0 auto;
  height: 22px;
  width: 22px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const EditPanelButton = (props) => {
  const { id, name, tooltipText, selectedButton, setSelectedButton, handleClick, icon, isSelected, disabled, selectable, landscape } = props;
  const isButtonSelected = isSelected || selectedButton === id;

  const onHandleClick = () => {
    if (selectable) {
      setSelectedButton(isButtonSelected ? '' : id);
    }
    if (handleClick) {
      handleClick(id);
    }
  };

  return (
    <Tooltip title={tooltipText} mouseEnterDelay={1}>
      <ButtonWrapper
        landscape={landscape}
        onClick={() => (!disabled && onHandleClick())}
        disabled={disabled}
        isSelected={isButtonSelected}
      >
        <StyledIcon disabled={disabled} component={icon} />
        <Text disabled={disabled} isSelected={isButtonSelected}>{name}</Text>
      </ButtonWrapper>
    </Tooltip>
  );
};

EditPanelButton.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  tooltipText: PropTypes.string,
  selectedButton: PropTypes.string,
  setSelectedButton: PropTypes.func,
  handleClick: PropTypes.func,
  icon: PropTypes.func,
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  selectable: PropTypes.bool,
  landscape: PropTypes.bool,
};

export default EditPanelButton;
