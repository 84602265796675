import React, { useRef, useState } from 'react';
import * as THREE from 'three';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import { Box, Flex } from '@react-three/flex';
import { useThree, useFrame } from 'react-three-fiber';
import lodashIsisEqual from 'lodash/isEqual';
import { fitToView } from 'utils/helpers/threeHelpers';
import { useSwappThree } from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { currentThemeSelector } from 'store/userSettings';
import Floor from './Floor';
import { limitNumberOfThreeJSFramesWaitingToBeRendered } from '../../helpers/ThreeHelpers';
import * as SwappEditor from '../../../../store/editor';

const flexBoxWidth = 400;
const flexBoxHeight = 600;

const GRID_HELPER_HEIGHT = -20;

let threeFrameNumber = 0;

const TestFitSceneSetter = (props) => {
  const { plan, isOrthographic, visibleBounds, excludeFloorIndex, hideGrid, profileId } = props;

  const [totalFlexWrapperSize, setTotalFlexWrapperSize] = useState(null);
  const [, forceUpdate] = useState({});
  const currentTheme = useSelector(currentThemeSelector);
  const { camera, size, scene } = useThree();
  const { cameraController } = useSwappThree();
  const groupRef = useRef();
  const gridRef = useRef();
  const dispatch = useDispatch();

  // This trick allows exteral software (browser automation) to know how many frames were rendered.
  // We use this to know when the page finished loading.
  useFrame(() => {
    limitNumberOfThreeJSFramesWaitingToBeRendered(scene);
    threeFrameNumber++;
    window.threeFrameNumber = threeFrameNumber;
  });

  if (!plan) {
    return null;
  }

  const floorsToRender = (excludeFloorIndex === null || excludeFloorIndex === undefined || Number.isNaN(excludeFloorIndex))
    ? plan.floors
    : [plan.floors[excludeFloorIndex]];

  const handleFitToView = (isForcedUpdate) => {
    const boundingBox = new THREE.Box3().setFromObject(groupRef.current);

    // position the camera:
    fitToView(boundingBox, camera, size, cameraController, { visibleBounds });

    // position the grid helper:
    const center = boundingBox.getCenter();
    gridRef.current.position.set(center.x, center.y, GRID_HELPER_HEIGHT);

    if (isForcedUpdate) {
      forceUpdate({});
    }
  };

  const handleWrapperSizeChange = (currentSize) => {
    if (lodashIsisEqual(currentSize, totalFlexWrapperSize)) {
      return;
    }

    if (groupRef.current) {
      handleFitToView();
    }
    setTotalFlexWrapperSize(currentSize);
  };

  return (
    <group name="TestFitSceneSetter">
      <gridHelper
        args={
          [
            400,
            100,
            hideGrid ? currentTheme.colors.backgroundColor : currentTheme.colors.gray_04,
            hideGrid ? currentTheme.colors.backgroundColor : currentTheme.colors.gray_03,
          ]
        }
        rotation={[Math.PI / 2, 0, 0]}
        ref={gridRef}
        name="grid"
        onClick={(e) => {
          // this is a workaround, when pressing on a DndRoom.js e.stopPropagation(); doesn't work
          const isFirstIntersectionsIsMe = lodashGet(e, 'intersections[0].object.type') === 'GridHelper';
          if (isFirstIntersectionsIsMe) {
            dispatch(SwappEditor.selectObject(null));
          }
        }}
      />
      <group position={[0, 0, 0]} name="testFitWrapper" ref={groupRef}>
        <Flex
          onReflow={(width, height) => handleWrapperSizeChange({ width, height })}
          flexDirection="row"
          flexWrap="wrap"
          size={[flexBoxWidth, flexBoxHeight, 0]}
        >
          {floorsToRender.map((floor, index) => (
            <Box centerAnchor margin={15} key={floor.id}>
              <Floor isOrthographic={isOrthographic} isOnlyOneFloor={floorsToRender.length === 1} floor={floor} floorIndex={excludeFloorIndex || index} handleFitToView={handleFitToView} profileId={profileId} />
            </Box>
          ))}
        </Flex>
      </group>
    </group>
  );
};

TestFitSceneSetter.propTypes = {
  plan: PropTypes.object,
  isOrthographic: PropTypes.bool,
  hideGrid: PropTypes.bool,
  visibleBounds: PropTypes.object,
  excludeFloorIndex: PropTypes.number,
  profileId: PropTypes.number,
};

export default TestFitSceneSetter;
