import styled from 'styled-components';
import { StyledLink } from '../../styles/commonComponents.styles';

export const Title = styled.div`
  justify-content: center;
  display: flex;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.textColor};
  font-weight: ${({ theme }) => theme.font.weight.bold}; 
  font-size: ${({ theme }) => theme.font.size.xxLarge};
`;

export const SubTitle = styled.div`
  justify-content: center;
  display: flex;
  text-align: center;
  margin-bottom: 30px;
`;

export const BackLink = styled(StyledLink)`
  display: flex;
  justify-content: center;
`;
