import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tooltip } from 'antd';
import T from 'i18n-react';
import lodashInRange from 'lodash/inRange';
import lodashIsEmpty from 'lodash/isEmpty';
import {
  StyledFormItem,
  RowText,
  SmallStyledTable,
  StyledTab,
  TotalRowText,
  StyledInputNumber,
  DeleteIcon,
} from 'styles/commonComponents.styles';
import { AREA_TARGET_FORM } from 'constants/fieldNames';
import { uuidv4 } from 'utils/helpers/uuidv4';
import { updateRow, deleteRow, updateTable } from 'utils/helpers/tableHelpers';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { sqfToSqmText } from 'utils/helpers/unitsHelpers';
import { FORM_INPUT_TYPE } from 'utils/helpers/testFitContainerHelprs';
import { getTestFitStandards } from 'utils/helpers/parseTestFitStandards';
import { useOverridableSetting } from 'store/userSettings';
import Dropdown from '../../../common/Dropdown';
import AddNewRowButton from '../../../common/AddNewRowButton';
import deleteIcon from '../../../../styles/static/icons/comon/deleteIcon';
import FromToNumInput from '../../../common/FromToNumInput';

const { TabPane } = Tabs;

const AreaTargetFormLsf = (props) => {
  const { form, form: { setFieldsValue }, disabled, formData, handleOnChange, isImperial } = props;
  const isRoomCount = formData[AREA_TARGET_FORM.INPUT_TOGGLE] === FORM_INPUT_TYPE.ROOM_COUNT;
  const testFitStandard = useOverridableSetting('testFitStandard', 'DEFAULT');
  const officeTypes = useMemo(() => getTestFitStandards().standards[testFitStandard].roomDefinitions.LSF, [testFitStandard]);
  const officeTypesObject = {
    [AREA_TARGET_FORM.LSF_ROOMS_FIELD]: officeTypes.filter((room) => room.subCategory === 'LSFA'),
    [AREA_TARGET_FORM.LSF_B_ROOMS_FIELD]: officeTypes.filter((room) => room.subCategory === 'LSFB'),
  };

  // update table when LSF changes
  useEffect(() => {
    updateTable(handleOnChange, form, AREA_TARGET_FORM.LSF_ROOMS_FIELD, updateEstimations);
    updateTable(handleOnChange, form, AREA_TARGET_FORM.LSF_B_ROOMS_FIELD, updateEstimations);
  }, [formData[AREA_TARGET_FORM.TOTAL_LSF_AVERAGE_FIELD], formData[AREA_TARGET_FORM.TOTAL_LSF_FIELD]]);

  useEffect(() => {
    if (formData[AREA_TARGET_FORM.LSF_ROOMS_FIELD] && formData[AREA_TARGET_FORM.TOTAL_LSF_AVERAGE_FIELD]) {
      updateTotal();
    }
  }, [formData[AREA_TARGET_FORM.TOTAL_LSF_AVERAGE_FIELD], formData[AREA_TARGET_FORM.LSF_ROOMS_FIELD], formData[AREA_TARGET_FORM.LSF_B_ROOMS_FIELD]]);

  const getOfficeByKey = (key) => {
    const unitIndex = officeTypes.findIndex((office) => office.key === key);
    return officeTypes[!unitIndex || unitIndex === -1 ? 0 : unitIndex];
  };

  const newRoomTemplateByKey = (officeKey) => ({
    key: uuidv4(),
    roomType: officeTypesObject[officeKey][0]?.key,
    otherText: '',
    officeSize: officeTypesObject[officeKey][0]?.defaultRange,
    target: 1,
    numberOfRooms: 1,
    numberOfMembers: 0,
    estimateSF: 0,
  });

  const columns = (officeKey) => ([
    {
      title: T.translate('AREA_TARGET_FORM_LSF_OFFICE_TYPE'),
      dataIndex: 'roomType',
      key: 'roomType',
      width: 120,
      align: 'center',
      render: (key, rowData) => (
        <Dropdown
          dropDownData={officeTypesObject[officeKey]}
          selectedKey={key}
          onChange={(value) => handleOfficeTypeChange(value, rowData, officeKey)}
          inputValue={rowData.otherText}
          onInputChange={(e) => handleOfficeTypeFreeTextChange(e, rowData, officeKey)}
        />
      ),
    },
    {
      title: T.translate('AREA_TARGET_FORM_LSF_OFFICE_SIZE'),
      dataIndex: 'officeSize',
      key: 'officeSize',
      align: 'center',
      width: 100,
      render: (officeRange, rowData) => (
        <FromToNumInput
          value={officeRange}
          defaultValue={getOfficeByKey(rowData.roomType).defaultRange}
          range={getOfficeByKey(rowData.roomType).minMaxRange}
          overwriteMax={5000}
          onChange={(e) => handleRoomSizeChange(e, rowData, officeKey)}
          step={getOfficeByKey(rowData.roomType).step}
        />
      ),
    },
    {
      title: T.translate(isRoomCount ? 'AREA_TARGET_FORM_LSF_PERCENTAGE' : 'AREA_TARGET_FORM_LSF_TARGET'),
      dataIndex: 'target',
      key: 'target',
      width: 50,
      align: 'center',
      render: (target, rowData) => {
        if (isRoomCount) {
          return numberWithComma(target, { suffix: '%', fixed: 0 });
        }
        return (
          <StyledInputNumber
            formatter={(value) => `${Number(value).toFixed(0)}%`}
            parser={(value) => value.replace('%', '')}
            min={1}
            max={100}
            height={25}
            onChange={(e) => handleTargetChange(e, rowData, officeKey)}
            value={target}
          />
        );
      },
    },
    {
      title: `${T.translate('AREA_TARGET_FORM_LSF_ESTIMATE_ROOMS')} ${isRoomCount ? '' : T.translate('AREA_TARGET_FORM_LSF_ESTIMATE')}`,
      dataIndex: 'numberOfRooms',
      key: 'numberOfRooms',
      width: 80,
      align: 'center',
      render: (number, rowData) => {
        if (isRoomCount) {
          return (
            <StyledInputNumber
              min={1}
              height={25}
              parser={(value) => value.replace('.', '')}
              onChange={(e) => handleOfficeCountChange(e, rowData, officeKey)}
              value={number}
            />
          );
        }
        return numberWithComma(number);
      },
    },
    {
      title: T.translate('AREA_TARGET_FORM_LSF_ESTIMATE_MEMBERS'),
      dataIndex: 'numberOfMembers',
      key: 'numberOfMembers',
      width: 60,
      align: 'center',
      render: (number) => numberWithComma(number),
    },
    {
      title: `${T.translate(isImperial ? 'SQF' : 'SQM')} ${T.translate('AREA_TARGET_FORM_LSF_ESTIMATE')}`,
      dataIndex: 'estimateSF',
      key: 'estimateSF',
      width: 30,
      align: 'center',
      render: (number) => sqfToSqmText(number, isImperial),
    },
    {
      dataIndex: 'delete',
      width: 20,
      align: 'center',
      render: (text, rowData) => (
        <DeleteIcon component={deleteIcon} onClick={() => deleteRow(handleOnChange, rowData.key, form, officeKey)} />
      ),
    },
  ]);

  const totalColumns = [
    { dataIndex: 'roomType', key: 'roomType', width: 130 },
    { dataIndex: 'target', key: 'target', width: 40, align: 'center', render: (target) => renderTotalTargetField(Math.round(target)) },
    { dataIndex: 'numberOfRooms', key: 'numberOfRooms', width: 40, align: 'center', render: (number) => numberWithComma(number) },
    { dataIndex: 'numberOfMembers', key: 'numberOfMembers', width: 30, align: 'center', render: (number) => numberWithComma(number) },
    { dataIndex: 'estimateSF', key: 'estimateSF', width: 30, align: 'center', render: (total) => renderTotalField(total) },
    { dataIndex: 'delete', width: 1 },
  ];

  const renderTotalTargetField = (target) => (
    <Tooltip title={T.translate('AREA_TARGET_FORM_LSF_TOTAL_TOOLTIP')} placement="bottom">
      <RowText success={target === 100} error={target > 100} warning={lodashInRange(target, 1, 100)}>{numberWithComma(target, { fixed: 0, suffix: '%' })}</RowText>
    </Tooltip>
  );

  const renderTotalField = (total) => (
    <TotalRowText error={total > formData[AREA_TARGET_FORM.TOTAL_LSF_AVERAGE_FIELD]}>
      {sqfToSqmText(total, isImperial)}
    </TotalRowText>
  );

  const addNewRow = (officeKey) => {
    const newRowData = newRoomTemplateByKey(officeKey);

    const currentData = formData[officeKey] || [];

    setFieldsValue({ [officeKey]: [...currentData, newRowData] });
    handleOnChange();
  };

  const handleTargetChange = (e, rowData, officeKey) => {
    const newValues = {
      target: e,
    };

    updateRow(handleOnChange, rowData, officeKey, form, newValues, updateEstimations);
  };

  const handleOfficeCountChange = (e, rowData, officeKey) => {
    const newValues = {
      numberOfRooms: e,
    };

    updateRow(handleOnChange, rowData, officeKey, form, newValues, updateEstimations);
  };

  const handleRoomSizeChange = (e, rowData, officeKey) => {
    const newValues = {
      officeSize: e,
    };

    updateRow(handleOnChange, rowData, officeKey, form, newValues, updateEstimations);
  };

  const handleOfficeTypeFreeTextChange = (e, rowData, officeKey) => {
    const newValues = {
      otherText: e.target.value,
    };

    updateRow(handleOnChange, rowData, officeKey, form, newValues);
  };

  const handleOfficeTypeChange = (key, rowData, officeKey) => {
    const newValues = {
      roomType: key,
      otherText: '',
      officeSize: getOfficeByKey(key).defaultRange,
    };

    updateRow(handleOnChange, rowData, officeKey, form, newValues, updateEstimations);
  };

  const updateEstimations = (data) => {
    const lsf = formData[AREA_TARGET_FORM.TOTAL_LSF_AVERAGE_FIELD];
    const totalLsf = formData[AREA_TARGET_FORM.TOTAL_LSF_FIELD];
    const numberOfSeatsInPreset = getOfficeByKey(data.roomType).seats;

    const averageRange = (data.officeSize[0] + data.officeSize[1]) / 2;
    const estimateSF = isRoomCount ? data.numberOfRooms * averageRange : Math.round((data.target / 100) * Number(lsf));
    const estimateNumberOfRooms = isRoomCount ? data.numberOfRooms : Math.round(estimateSF / averageRange);
    const target = isRoomCount ? (estimateSF * 100) / (totalLsf[0].estimateSF || estimateSF) || 0 : data.target;
    const numberOfRooms = target && !estimateNumberOfRooms ? 1 : estimateNumberOfRooms;
    const seats = numberOfSeatsInPreset ? Math.round(numberOfRooms * numberOfSeatsInPreset) : estimateSF / 40;

    return {
      target,
      estimateSF,
      numberOfRooms,
      numberOfMembers: Math.round(seats),
    };
  };

  const updateTotal = () => {
    const lsfAData = formData[AREA_TARGET_FORM.LSF_ROOMS_FIELD] || [];
    const lsfBData = formData[AREA_TARGET_FORM.LSF_B_ROOMS_FIELD] || [];
    const lsfData = [...lsfAData, ...lsfBData];

    const target = lsfData.reduce((total, current) => ({ target: Number(total.target) + Number(current.target) }), { target: 0 });
    const numberOfRooms = lsfData.reduce((total, current) => ({ numberOfRooms: Number(total.numberOfRooms) + Number(current.numberOfRooms) }), { numberOfRooms: 0 });
    const numberOfMembers = lsfData.reduce((total, current) => ({ numberOfMembers: Number(total.numberOfMembers) + Number(current.numberOfMembers) }), { numberOfMembers: 0 });
    const estimateSF = lsfData.reduce((total, current) => ({ estimateSF: Number(total.estimateSF) + Number(current.estimateSF) }), { estimateSF: 0 });

    setFieldsValue({ [AREA_TARGET_FORM.TOTAL_LSF_FIELD]: [
      {
        key: 'TOTAL',
        roomType: T.translate('AREA_TARGET_FORM_LSF_TOTAL'),
        officeSize: '',
        target: isRoomCount ? 100 : target.target || 0,
        numberOfRooms: numberOfRooms.numberOfRooms,
        numberOfMembers: numberOfMembers.numberOfMembers,
        estimateSF: Math.round(target.target) === 100 ? formData[AREA_TARGET_FORM.TOTAL_LSF_AVERAGE_FIELD] : Math.floor(estimateSF.estimateSF),
      },
    ],
    });
  };

  const isTotalValid = (rule, value) => {
    if (!value || lodashInRange(value[0].target, 99, 101)) {
      return Promise.resolve();
    }
    return Promise.reject(T.translate('AREA_TARGET_FORM_LSF_TOTAL_TOOLTIP'));
  };

  return (
    <>
      {/* ========= Tabs ========= */}
      <StyledFormItem name={AREA_TARGET_FORM.INPUT_TOGGLE} valuePropName="activeKey">
        <StyledTab size="small">
          <TabPane tab={T.translate('AREA_TARGET_FORM_RSF_LSF_LSF_PERCENTAGE_TARGET')} key={FORM_INPUT_TYPE.PERCENTAGE_TARGET} />
          <TabPane tab={T.translate('AREA_TARGET_FORM_RSF_LSF_LSF_ROOM_COUNT')} key={FORM_INPUT_TYPE.ROOM_COUNT} />
        </StyledTab>
      </StyledFormItem>

      {/* ========= Table A ========= */}
      <StyledFormItem name={AREA_TARGET_FORM.LSF_ROOMS_FIELD} valuePropName="dataSource">
        <SmallStyledTable loading={disabled} pagination={false} columns={columns(AREA_TARGET_FORM.LSF_ROOMS_FIELD)} />
      </StyledFormItem>
      <AddNewRowButton disabled={disabled} onClick={() => addNewRow(AREA_TARGET_FORM.LSF_ROOMS_FIELD)} text={T.translate('AREA_TARGET_FORM_LSF_ADD_OFFICE_BUTTON')} />

      {/* ========= Table B ========= */}
      {!lodashIsEmpty(officeTypesObject[AREA_TARGET_FORM.LSF_B_ROOMS_FIELD]) && (
        <>
          <StyledFormItem name={AREA_TARGET_FORM.LSF_B_ROOMS_FIELD} valuePropName="dataSource">
            <SmallStyledTable loading={disabled} pagination={false} showHeader={false} columns={columns(AREA_TARGET_FORM.LSF_B_ROOMS_FIELD)} />
          </StyledFormItem>
          <AddNewRowButton disabled={disabled} onClick={() => addNewRow(AREA_TARGET_FORM.LSF_B_ROOMS_FIELD)} text={T.translate('AREA_TARGET_FORM_LSF_ADD_OFFICE_BUTTON')} />
        </>
      )}

      {/* ========= TOTAL ========= */}
      <StyledFormItem name={AREA_TARGET_FORM.TOTAL_LSF_FIELD} rules={[{ validator: isTotalValid }]} valuePropName="dataSource">
        <SmallStyledTable
          loading={disabled}
          pagination={false}
          columns={totalColumns}
          showHeader={false}
          rowClassName={(row) => row.key === 'TOTAL' && 'ant-table-total-row'}
        />
      </StyledFormItem>
    </>
  );
};

AreaTargetFormLsf.propTypes = {
  form: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  isImperial: PropTypes.bool,
};

export default React.memo(AreaTargetFormLsf);
