import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';

import { AREA_TARGET_FORM } from 'constants/fieldNames';
import { inchesToMeters } from 'utils/helpers/unitsHelpers';
import { useOverridableSetting } from 'store/userSettings';
import { getCirculationData } from 'utils/helpers/parseTestFitStandards';
import ImperialMetricInput from '../../../common/ImperialMetricInput';
import { FormItem } from './AreaTargetFormContainer.styles';

const formItemWidth = 250;

const AreaTargetFormCirculation = (props) => {
  const { disabled, isImperial } = props;
  const testFitStandard = useOverridableSetting('testFitStandard', 'DEFAULT');
  const testFitOfficeTypes = getCirculationData(testFitStandard);

  const formItemLayout = {
    labelCol: { span: 13 },
    wrapperCol: { span: 8, offset: 1 },
  };

  const renderTitleWithUnit = (title) => `${T.translate(title)} (${isImperial ? T.translate('INCHES') : T.translate('METERS')})`;

  return (
    <React.Fragment>
      <FormItem
        name={AREA_TARGET_FORM.MAIN_AISLES}
        width={formItemWidth}
        disabled={disabled}
        initialValue={testFitOfficeTypes?.defaultMainAisleWidth || 62}
        {...formItemLayout}
        label={renderTitleWithUnit('AREA_TARGET_FORM_CIRCULATION_MAIN_AISLES')}
      >
        <ImperialMetricInput
          isImperial={isImperial}
          disabled={disabled}
          min={0}
          unit={inchesToMeters}
        />
      </FormItem>
      <FormItem
        name={AREA_TARGET_FORM.SECONDARY}
        width={formItemWidth}
        disabled={disabled}
        initialValue={testFitOfficeTypes?.defaultSecondaryAisleWidth || 53}
        {...formItemLayout}
        label={renderTitleWithUnit('AREA_TARGET_FORM_CIRCULATION_SECONDARY')}
      >
        <ImperialMetricInput
          isImperial={isImperial}
          disabled={disabled}
          min={0}
          unit={inchesToMeters}
        />
      </FormItem>
    </React.Fragment>
  );
};

AreaTargetFormCirculation.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isImperial: PropTypes.bool,
};

export default React.memo(AreaTargetFormCirculation);
