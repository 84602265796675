import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import TestFitViewerContainer from 'utils/swappViewer/testFit/TestFitViewerContainer';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { swappProfileResultsSelector } from 'store/swappProfile';
import lodashFlattenDepth from 'lodash/flattenDepth';
import * as SwappEditor from 'store/editor';
import { setRoomFilter } from 'store/testFit';
import { ROOM_FILTER_TYPES, TEST_FIT_EDITOR_BUTTON_KEYS } from 'constants/testFitConsts';
import { EDIT_TYPES } from 'store/editor';
import ProgramUi from './ProgramUi';

const ProgramContainerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const ProgramContainer = () => {
  const locationData = parseLocationUrl(window.location);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const swappProfileResults = useSelector((state) => swappProfileResultsSelector(state, locationData.profileId, isImperial), shallowEqual);
  const [visibleBounds, setVisibleBounds] = useState(); // Some parts of the canvas are hidden by other elements

  const dispatch = useDispatch();
  const testFitViewerContainerUiRef = useRef();
  const containerWrapperRef = useRef();

  const plan = swappProfileResults.multiBoundaryFile;
  const totalTiles = lodashFlattenDepth(plan.floors.map((floor) => floor?.tubes.map((tube) => tube.tiles)), 2)?.length;

  const getBoundingClientRect = (element) => {
    const { top, right, bottom, left, width, height, x, y } = element?.getBoundingClientRect();
    return { top, right, bottom, left, width, height, x, y };
  };

  const updateBounding = () => {
    if (!testFitViewerContainerUiRef.current || !containerWrapperRef.current) {
      return;
    }

    // This function only deals with left side menu, It is possible more menus will cover parts of the canvas
    // And then a robust generic solution should be written.
    const leftMenuBoundingBox = getBoundingClientRect(testFitViewerContainerUiRef.current);
    const mainBoundingBox = getBoundingClientRect(containerWrapperRef.current);
    const offsetToRemove = leftMenuBoundingBox.right;
    mainBoundingBox.left += offsetToRemove;
    mainBoundingBox.width -= offsetToRemove;
    setVisibleBounds(mainBoundingBox);
  };

  useEffect(() => {
    dispatch(SwappEditor.selectObject(null));
  }, [plan]);
  // unmount

  useEffect(() => () => {
    handleEditorTypeChanged(false);
  }, []);

  useEffect(() => {
    handleEditorTypeChanged(false);
  }, [locationData.profileId]);

  useEffect(() => {
    updateBounding();
  }, [testFitViewerContainerUiRef.current, containerWrapperRef.current]);

  const handleEditorTypeChanged = useCallback((isEditorEnabled) => {
    if (isEditorEnabled) {
      dispatch(setRoomFilter(ROOM_FILTER_TYPES.ROOMS));
      dispatch(SwappEditor.startEditing({ result: swappProfileResults.originalData, profileId: locationData.profileId, editSessionType: EDIT_TYPES.TEST_FIT, editorType: TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_ROOMS }));
    } else {
      dispatch(SwappEditor.stopEditing());
    }
  }, [swappProfileResults]);

  return (
    <ProgramContainerWrapper ref={containerWrapperRef}>
      {/* ============= ui ============= */}
      <ProgramUi
        isOrthographic
        ref={testFitViewerContainerUiRef}
        onEditModeChanged={handleEditorTypeChanged}
        hasTiles={!!totalTiles}
        onPanelWidthAnimationEnded={updateBounding}
      />

      {/* ============= Viewer ============= */}
      <TestFitViewerContainer plan={swappProfileResults?.multiBoundaryFile} profileId={locationData.profileId} visibleBounds={visibleBounds} />
    </ProgramContainerWrapper>
  );
};

export default ProgramContainer;
