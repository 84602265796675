import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { swappProjectSelector } from 'store/swappProfile';
import { getProfileIdPath, isSharedUrl } from 'utils/helpers/navigationHelpers';
import { getSwappProjectAction } from 'store/swappProfile/actions/swappProfileActions';
import FeasibilityTargetFormContainer from './form/FeasibilityTargetFormContainer';

const StudiesPage = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const profileIdPath = getProfileIdPath(history.location);
  const swappProject = useSelector(swappProjectSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSharedUrl()) {
      return;
    }

    if (!swappProject || swappProject.id !== Number(match.params.projectId)) {
      dispatch(getSwappProjectAction(match.params.projectId));
    }
  }, [swappProject, match.params.projectId]);

  return (
    <Switch>
      <Route path={`${match.path}${profileIdPath}`} component={FeasibilityTargetFormContainer} />
      <Redirect to={`${match.path}${profileIdPath}`} />
    </Switch>
  );
};

export default StudiesPage;
