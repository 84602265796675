import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import lodashIsEmpty from 'lodash/isEmpty';
import { ExtrudeGeometry } from 'three';
import { a, useSpring } from 'react-spring/three';

const ExtrudeMesh = (props) => {
  const { extrudeDepth, color, envelope, holes, meshProps, receiveShadow, opacity = 1, transparent, castShadow = true, unlit, disableAnimation } = props;

  if (lodashIsEmpty(envelope)) {
    return null;
  }

  const matProps = useMemo(() => ({
    color: color || 'grey',
    opacity,
  }), [color, opacity]);

  const springProps = useSpring({
    ...matProps,
    config: { duration: 250, tension: 215, friction: 12 },
  });

  const extrudeSettings = useMemo(() => ({
    steps: 1,
    depth: extrudeDepth || 20,
    bevelEnabled: false,
    bevelThickness: 1,
    bevelSize: 1,
    bevelOffset: 0,
    bevelSegments: 1,
  }), [extrudeDepth]);

  const geom = useMemo(() => {
    const shape = new THREE.Shape((envelope).filter((e) => e).map((v) => new THREE.Vector2(...v)));
    if (!lodashIsEmpty(holes)) {
      holes.forEach((hole) => {
        if (!lodashIsEmpty(hole)) {
          const currHole = new THREE.Shape(hole.map((v) => new THREE.Vector2(...v)));
          shape.holes.push(currHole);
        }
      });
    }
    const extrudeGeom = new ExtrudeGeometry(shape, extrudeSettings);
    if (envelope[0].length === 3) {
      // This is a polygon with a 3D embedding. We assume that no coordinate has a repeat [X,Y] coord with a different Z,
      // and move accordingly.
      const heightLookup = {};
      envelope.forEach((p) => {
        const pStr = `${p[0].toFixed(2)}_${p[1].toFixed(2)}`;
        // eslint-disable-next-line prefer-destructuring
        heightLookup[pStr] = p[2];
      });
      extrudeGeom.vertices.forEach((v) => {
        const vStr = `${v.x.toFixed(2)}_${v.y.toFixed(2)}`;
        if (vStr in heightLookup) {
          v.z += heightLookup[vStr];
        }
      });
    }
    return extrudeGeom;
  }, [envelope, extrudeDepth, color]);

  return (
    <mesh castShadow={castShadow} receiveShadow={receiveShadow} {...meshProps} geometry={geom}>
      {unlit
        ? <a.meshBasicMaterial attach="material" {...(disableAnimation ? matProps : springProps)} transparent={transparent} />
        : <a.meshStandardMaterial attach="material" {...(disableAnimation ? matProps : springProps)} transparent={transparent} />}
    </mesh>
  );
};

ExtrudeMesh.propTypes = {
  envelope: PropTypes.array,
  extrudeDepth: PropTypes.number,
  color: PropTypes.string,
  holes: PropTypes.array,
  meshProps: PropTypes.object,
  receiveShadow: PropTypes.bool,
  transparent: PropTypes.bool,
  castShadow: PropTypes.bool,
  opacity: PropTypes.number,
  unlit: PropTypes.bool,
  disableAnimation: PropTypes.bool,
};

export default ExtrudeMesh;
