import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import lodashIncludes from 'lodash/includes';
import { useSelector } from 'react-redux';
import { StyledSubMenu, StyledMenu, FormSubMenuWrapper } from 'styles/commonComponents.styles';
import { STANDARDS } from 'constants/feasibilityConts';
import { swappProjectSelector } from 'store/swappProfile/selectors';
import icons from 'styles/static/icons/feasibilityIcons/study';
import { FEASIBILITY_TARGET_FORM } from 'constants/fieldNames';
import StyledSubMenuTitle from '../../../common/StyledSubMenuTitle';
import FeasibilityParameterForm from './FeasibilityParameterForm';
import FeasibilityBuildingTypologiesForm from './FeasibilityBuildingTypologiesForm';
import FeasibilityBuildingRightsForm from './FeasibilityBuildingRightsForm';
import FeasibilityDesignRequirementsForm from './FeasibilityDesignRequirementsForm';
import FeasibilityLotInfoForm from './FeasibilityLotInfoForm';
import FeasibilitySitesForm from './FeasibilitySitesForm';
import FeasibilityCommunalSpaceForm from './FeasibilityCommunalSpaceForm';
import FeasibilityApartmentStandardForm from './FeasibilityApartmentStandardForm';
import FeasibilityDesignStandard from './FeasibilityDesignStandard';
import FeasibilityMechanicalElectricPlumbingForm from './FeasibilityMechanicalElectricPlumbingForm';

const FeasibilityTargetForm = (props) => {
  const { width, form, formData, handleOnChange, isViewer } = props;

  const swappProject = useSelector(swappProjectSelector);
  const profileIndex = lodashGet(swappProject, 'projectProfiles', []).findIndex((profile) => !profile.parentProfileId);
  const parentProfile = lodashGet(swappProject, `projectProfiles[${profileIndex}]`);
  const siteData = lodashGet(swappProject, 'site.siteData');
  const lotInfo = lodashGet(siteData, 'lotInfo');
  const unitType = lodashGet(formData, `${FEASIBILITY_TARGET_FORM.APARTMENTS_STANDARD}[0]`);
  const standardHiddenFormList = lodashGet(STANDARDS[unitType], 'formsToHide', []);
  const standardAdditionalFormList = lodashGet(STANDARDS[unitType], 'formsToShow', []);

  return (
    <StyledMenu
      mode="inline"
      forceSubMenuRender
      defaultOpenKeys={['LOT_INFO', 'APARTMENT_STANDARD', 'BUILDING_RIGHTS', 'BUILDING_TYPOLOGIES', 'DESIGN_PRIORITY', 'SITES', 'COMMUNAL_SPACE', 'DESIGN_REQUIREMENTS', 'MECHANICAL_ELECTRIC_PLUMBING', 'DESIGN_STANDARD']}
    >
      {/* ============ Lot Info ============ */}
      <FeasibilityLotInfoForm data={lotInfo} lotArea={parentProfile?.profileData?.siteData?.area} />

      {/* ============ Apartment Standard ============ */}
      <StyledSubMenu key="APARTMENT_STANDARD" title={<StyledSubMenuTitle icon={icons.apartmentStandardIcon} title={T.translate('FEASIBILITY_TARGET_FORM_CONTAINER_APARTMENT_STANDARD')} />}>
        <FormSubMenuWrapper>
          <FeasibilityApartmentStandardForm width={width} form={form} formData={formData} handleOnChange={handleOnChange} unitType={unitType} />
        </FormSubMenuWrapper>
      </StyledSubMenu>

      {/* ============ Design Standard ============ */}
      {lodashIncludes(standardAdditionalFormList, 'DESIGN_STANDARD') && (
      <StyledSubMenu key="DESIGN_STANDARD" title={(<StyledSubMenuTitle icon={icons.designPriorityIcon} title={T.translate('FEASIBILITY_TARGET_FORM_CONTAINER_DESIGN_STANDARD')} />)}>
        <FormSubMenuWrapper>
          <FeasibilityDesignStandard width={width} form={form} formData={formData} handleOnChange={handleOnChange} unitType={unitType} />
        </FormSubMenuWrapper>
      </StyledSubMenu>
      )}

      {/* ============ Building Rights ============ */}
      <StyledSubMenu key="BUILDING_RIGHTS" title={<StyledSubMenuTitle icon={icons.buildingRightsIcon} title={T.translate('FEASIBILITY_TARGET_FORM_CONTAINER_BUILDING_RIGHTS')} />}>
        <FormSubMenuWrapper>
          <FeasibilityBuildingRightsForm width={width} form={form} formData={formData} handleOnChange={handleOnChange} unitType={unitType} isViewer={isViewer} />
        </FormSubMenuWrapper>
      </StyledSubMenu>

      {/* ============ Sites ============ */}
      <StyledSubMenu key="SITES" title={<StyledSubMenuTitle icon={icons.siteIcon} title={T.translate(isViewer ? 'FEASIBILITY_TARGET_FORM_CONTAINER_BUILDING_PROPERTIES' : 'FEASIBILITY_TARGET_FORM_CONTAINER_SITES')} />}>
        <FormSubMenuWrapper>
          <FeasibilitySitesForm width={width} form={form} formData={formData} handleOnChange={handleOnChange} unitType={unitType} isViewer={isViewer} />
        </FormSubMenuWrapper>
      </StyledSubMenu>

      {/* ============ Communal Space ============ */}
      <StyledSubMenu key="COMMUNAL_SPACE" title={<StyledSubMenuTitle icon={icons.communalSpaceIcon} title={T.translate('FEASIBILITY_TARGET_FORM_CONTAINER_COMMUNAL_SPACE')} />}>
        <FormSubMenuWrapper>
          <FeasibilityCommunalSpaceForm width={width} form={form} formData={formData} handleOnChange={handleOnChange} unitType={unitType} />
        </FormSubMenuWrapper>
      </StyledSubMenu>

      {/* ============ Design Priority ============ */}
      <StyledSubMenu key="DESIGN_PRIORITY" isHidden={lodashIncludes(standardHiddenFormList, 'DESIGN_PRIORITY')} title={<StyledSubMenuTitle icon={icons.designPriorityIcon} title={T.translate('FEASIBILITY_TARGET_FORM_CONTAINER_DESIGN_PRIORITY')} />}>
        <FormSubMenuWrapper>
          <FeasibilityParameterForm width={width} form={form} formData={formData} handleOnChange={handleOnChange} unitType={unitType} />
        </FormSubMenuWrapper>
      </StyledSubMenu>

      {/* ============ Building Typologies ============ */}
      <StyledSubMenu key="BUILDING_TYPOLOGIES" isHidden={isViewer || lodashIncludes(standardHiddenFormList, 'BUILDING_TYPOLOGIES')} title={<StyledSubMenuTitle icon={icons.buildingTypologiesIcon} title={T.translate('FEASIBILITY_TARGET_FORM_CONTAINER_BUILDING_TYPOLOGIES')} />}>
        <FormSubMenuWrapper>
          <FeasibilityBuildingTypologiesForm width={width} form={form} formData={formData} handleOnChange={handleOnChange} unitType={unitType} />
        </FormSubMenuWrapper>
      </StyledSubMenu>

      {/* ============ Design Requirements ============ */}
      <StyledSubMenu key="DESIGN_REQUIREMENTS" isHidden={lodashIncludes(standardHiddenFormList, 'DESIGN_REQUIREMENTS')} title={<StyledSubMenuTitle icon={icons.designRequirementsIcon} title={T.translate('FEASIBILITY_TARGET_FORM_CONTAINER_DESIGN_REQUIREMENTS')} />}>
        <FormSubMenuWrapper>
          <FeasibilityDesignRequirementsForm width={width} form={form} formData={formData} handleOnChange={handleOnChange} unitType={unitType} />
        </FormSubMenuWrapper>
      </StyledSubMenu>

      {/* ============ Mechanical | Electric | Plumbing ============ */}
      {lodashIncludes(standardAdditionalFormList, 'MECHANICAL_ELECTRIC_PLUMBING') && (
      <StyledSubMenu key="MECHANICAL_ELECTRIC_PLUMBING" title={(<StyledSubMenuTitle icon={icons.mechanicalElectricPlumbingIcon} title={T.translate('FEASIBILITY_TARGET_FORM_CONTAINER_MECHANICAL_ELECTRIC_PLUMBING')} />)}>
        <FormSubMenuWrapper>
          <FeasibilityMechanicalElectricPlumbingForm width={width} form={form} formData={formData} handleOnChange={handleOnChange} unitType={unitType} />
        </FormSubMenuWrapper>
      </StyledSubMenu>
      )}
    </StyledMenu>
  );
};

FeasibilityTargetForm.propTypes = {
  width: PropTypes.number,
  form: PropTypes.object,
  formData: PropTypes.object,
  handleOnChange: PropTypes.func,
  isViewer: PropTypes.bool,
};

export default React.memo(FeasibilityTargetForm);
