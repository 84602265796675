import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

export const SwappThreeContext = createContext(null);

export const SwappThreeContextProvider = ({ children }) => {
  const [cameraController, setDefaultController] = useState();

  return (
    <SwappThreeContext.Provider value={{ cameraController, setDefaultController }}>
      {children}
    </SwappThreeContext.Provider>
  );
};

export const useSwappThree = () => useContext(SwappThreeContext);

SwappThreeContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
};
