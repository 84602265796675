import React from 'react';

export default () => (
  <svg width="170" height="30" viewBox="0 0 170 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.2923 0H28.0769V3.66667H24.2923V0Z" fill="#E5B024" />
    <path d="M24.2923 6.11111H28.0769V9.77778H24.2923V6.11111Z" fill="#E5B024" />
    <path d="M30.6 6.11111H34.3846V9.77778H30.6V6.11111Z" fill="#E5B024" />
    <path d="M18.6154 6.11111H22.4V9.77778H18.6154V6.11111Z" fill="#E5B024" />
    <path d="M24.2923 12.2222H28.0769V15.8889H24.2923V12.2222Z" fill="#E5B024" />
    <path d="M30.6 12.2222H34.3846V15.8889H30.6V12.2222Z" fill="#E5B024" />
    <path d="M36.9077 12.2222H40.6923V15.8889H36.9077V12.2222Z" fill="#E5B024" />
    <path d="M18.6154 12.2222H22.4V15.8889H18.6154V12.2222Z" fill="#E5B024" />
    <path d="M12.3077 12.2222H16.0923V15.8889H12.3077V12.2222Z" fill="#E5B024" />
    <path d="M24.2923 18.3333H28.0769V22H24.2923V18.3333Z" fill="#E5B024" />
    <path d="M30.6 18.3333H34.3846V22H30.6V18.3333Z" fill="#E5B024" />
    <path d="M36.9077 18.3333H40.6923V22H36.9077V18.3333Z" fill="#E5B024" />
    <path d="M43.2154 18.3333H47V22H43.2154V18.3333Z" fill="#E5B024" />
    <path d="M18.6154 18.3333H22.4V22H18.6154V18.3333Z" fill="#E5B024" />
    <path d="M12.3077 18.3333H16.0923V22H12.3077V18.3333Z" fill="#E5B024" />
    <path d="M6 18.3333H9.78462V22H6V18.3333Z" fill="#E5B024" />
    <path d="M71.0582 7.29869H51.7V11.4804H58.46V29.9994H63.9909V11.4804H71.0582V7.29869Z" fill="#393A3C" />
    <path d="M73.5164 29.9994V7.29869H78.4327V29.9994H73.5164Z" fill="#393A3C" />
    <path d="M109.775 7.29869H104.244V29.9994H109.775V20.4412H118.378V29.9994H123.909V7.29869H118.378V15.6621H109.775V7.29869Z" fill="#393A3C" />
    <path fillRule="evenodd" clipRule="evenodd" d="M94.1036 7.29869C96.5618 7.89608 100.914 10.515 101.785 14.4679C102.707 18.6496 102.372 21.9074 100.249 25.5196C99.02 27.6104 96.4591 28.9175 93.1818 29.9994H82.12L82.4273 7.29869H94.1036ZM87.6509 25.8183V11.4804H92.26C92.26 11.4804 95.2292 13.0719 96.2545 15.0653C97.1764 16.8575 97.2527 19.6713 96.2545 21.9353C95.3327 24.0261 93.0794 25.3205 91.6455 25.8183H87.6509Z" fill="#393A3C" />
    <path fillRule="evenodd" clipRule="evenodd" d="M126.06 29.9994L134.049 7.29869H140.195L148.798 29.9994H143.267L141.424 25.2203H133.127L131.284 29.9994H126.06ZM137.122 12.6752L140.195 21.0386H134.049L137.122 12.6752Z" fill="#393A3C" />
    <path fillRule="evenodd" clipRule="evenodd" d="M150.949 29.9994H156.48V21.636H158.324L163.24 29.9994H170L163.855 21.0386C166.005 19.5449 167.235 18.0523 167.849 16.5588C168.464 15.0653 168.467 13.5465 167.849 11.7791C166.948 9.20058 166.005 8.19445 162.625 7H150.949V29.9994ZM156.48 11.1815V18.0514H160.475C160.475 18.0514 161.704 17.7527 162.318 16.2593C162.773 15.1548 162.87 13.7102 162.011 12.6749C160.695 11.0892 158.528 10.8828 156.48 11.1815Z" fill="#393A3C" />
    <path d="M1 25.8183H52.6218V30H1V25.8183Z" fill="#393A3C" />
  </svg>
);
