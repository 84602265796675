import React from 'react';
import PropTypes from 'prop-types';
import lodashIsEmpty from 'lodash/isEmpty';
import MeshLine from '../../components/MeshLine';

const DisplayObject = (props) => {
  const { lines, lineThickness = 0.03, lineColor = 'black' } = props;
  if (lodashIsEmpty(lines)) {
    return null;
  }

  return (
    <group position={[0, 0, 60]} name="displayObject">
      {lines.map((line, lineIndex) => (
        <MeshLine
          key={lineIndex}
          vertices={[...line]}
          lineWidth={lineThickness}
          height={2}
          color={lineColor}
        />
      ))}
    </group>
  );
};

DisplayObject.propTypes = {
  lines: PropTypes.array,
  lineThickness: PropTypes.number,
  lineColor: PropTypes.string,
};

export default React.memo(DisplayObject);
