import styled, { keyframes } from 'styled-components';
import Icon from '@ant-design/icons';
import { Popover } from 'antd';

const spawnAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ResultSplitViewCardWrapper = styled.div`
  position: relative;
  max-width: 300px;
  width: 100%;
  height: fit-content;
  min-height: ${({ height }) => `${height}px`};
  background-color: ${({ theme }) => theme.colors.gray_01};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 8px 5px rgba(0, 0, 0, 0.08);
  cursor: ${({ isLoading }) => (isLoading ? 'default' : 'pointer')};
  animation: ${spawnAnimation} 1.5s ease;

  &:hover {
    opacity: 0.9;
  }
`;

export const ResultCardData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 330px;
  overflow: hidden;
  padding: 10px;
  flex: 1;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 7px;
  left: ${({ isRight }) => (isRight ? 'unset' : '7px')};
  right: ${({ isRight }) => (isRight ? '7px' : 'unset')};
  display: flex;
  flex-direction: column;
`;

export const ResultCardImageWrapper = styled.div`
  width: 100%;
  height: 150px;
  max-height: 150px;
  min-height: 150px;
  overflow: hidden;
  background-color: ${({ theme }) => `${theme.colors.gray_03}`}; // the color of the ground if the thumbnail has transparent parts
  color: ${({ theme }) => theme.colors.gray_06};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ResultCardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
`;

export const LoadingWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  width: 27px;
  height: 27px;
  background-color: ${({ theme }) => theme.colors.primaryColor};
  border-radius: 50%;
  box-shadow: 0 0 8px 5px rgba(0, 0, 0, 0.15);
  
  span {
    position: absolute;
    top: 3px;
    left: 3px;
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.font.size.small};
  color: ${({ theme }) => theme.colors.gray_07};
`;

export const InfoIcon = styled(Icon)`
  font-size: 14px;
  cursor: pointer;
  height: 100%;
  padding: 9px 0 0 5px;
  color: ${({ theme }) => theme.colors.gray_07};

  &:hover {
    color: ${({ theme }) => theme.colors.primaryColor};
  }
`;

export const ScoreWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primaryColor};
  color: ${({ theme }) => theme.colors.white};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: ${({ theme }) => theme.font.size.small};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 7px;
`;

export const StyledIcon = styled(Icon)`
  transform: scale(0.7);
`;

export const StyledSkeletonImage = styled.div`
  width: 100%;
  min-height: 150px;
  background-color: ${({ theme }) => `${theme.colors.gray_03}`};
`;

export const StyledStep = styled.div`
  padding-top: 15px;
  color: ${({ theme }) => theme.colors.gray_06};
  font-weight: ${({ theme }) => theme.font.weight.light};
  font-size: ${({ theme }) => theme.font.size.large};
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1;
`;

export const StyledStepText = styled.div`
  height: 20px;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray_03};
  line-height: 2.4 !important;
  margin-bottom: 10px;
`;

export const IconsWrapper = styled.div`
  display: flex;
`;

export const DataCellValue = styled.div`
  font-weight: ${({ theme, bold }) => (bold && theme.font.weight.bold)};
  color: ${({ theme, bold }) => (bold && theme.colors.textColor)};
  flex: 1;
  text-align: ${({ align }) => align};
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledPopover = styled(Popover)`
  .ant-popover-inner-content {
    padding: 7px;
  }
`;

export const OptionsWrapper = styled.div`
`;

export const OptionsItem = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 7px;
  font-size: ${({ theme }) => theme.font.size.medium};
  
  &:last-child {
    margin-bottom: 0;
  }
  
  &:hover {
    color: ${({ theme }) => theme.colors.primaryColor};
  }
`;

export const OptionsIcon = styled.div`
  margin-right: 5px;
`;

const margin = 10;
const marginHover = 14;

export const CreateNewProfileCardWrapper = styled.div`
  min-height: ${({ height, isUploading }) => `${height - ((isUploading ? marginHover : margin) * 2)}px`};
  padding: 10px;
  margin: ${({ isUploading }) => `${isUploading ? marginHover : margin}px`};
  border: 1px dashed ${({ theme, isUploading }) => (isUploading ? theme.colors.gray_04 : theme.colors.gray_06)};
  background-color: ${({ theme, isUploading }) => (isUploading ? `${theme.colors.primaryColorDisabled}10` : 'unset')};;
  border-radius: ${({ theme }) => theme.other.borderRadius};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in-out;
`;

export const CreateNewProfileCardItem = styled.div`
  margin-bottom: 7px;
  color: ${({ theme }) => theme.colors.textColor};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.xxLarge};
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  height: ${({ isHidden }) => (isHidden ? '0px' : '32px')};
  transition: all .4s ease-in-out;
`;

const uploadingAnimation = keyframes`
  0% {
    background-position: top center;
  }
  50% {
    background-position: bottom center;
  }
  100% {
    background-position: top center;
  }
`;

export const CreateNewProfileCardIcon = styled.div`
  width: 55px;
  height: 70px;
  background: ${({ theme, isDragOver, isUploading }) => {
    if (isDragOver) {
      return theme.colors.primaryColorHover;
    }

    if (isUploading) {
      return 'linear-gradient(#72197a, #1e1a7c)';
    }

    return theme.colors.gray_06;
  }};
  background-size: 100% 400%;
  background-position: center center;
  border-radius: ${({ theme }) => theme.other.borderRadius};
  margin-bottom: 12px;
  position: relative;
  transition: all .4s ease-in-out;
  animation: ${uploadingAnimation} 4s ease infinite;

  &:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    width: 55px;
    height: 70px;
    background: ${({ theme, isDragOver, isUploading }) => {
    if (isDragOver) {
      return theme.colors.primaryColorHover;
    }

    if (isUploading) {
      return 'linear-gradient(#b928c7, #342dd1)';
    }

    return theme.colors.gray_04;
  }};
    background-size: 400% 400%;
    border-radius: ${({ theme }) => theme.other.borderRadius};
    margin-bottom: 7px;
    transition: all .4s ease-in-out;
    animation: ${uploadingAnimation} 4s ease infinite;
  }
`;
