import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';

const PlaneMesh = forwardRef(({ vertices, receiveShadow, color = 'lightgray', opacity = 1, transparent = true, unlit = true, ...props }, ref) => {
  const faces = THREE.ShapeUtils.triangulateShape(
    vertices.map((v) => new THREE.Vector2(...v)),
    [],
  );

  return (
    <mesh ref={ref} receiveShadow={receiveShadow} {...props}>
      <geometry
        attach="geometry"
        vertices={vertices.map((v) => new THREE.Vector3(...v))}
        faces={faces.map((f) => new THREE.Face3(...f, new THREE.Vector3(0, 0, 1)))}
      />
      {unlit
        ? <meshBasicMaterial attach="material" color={color} transparent={transparent} opacity={opacity} />
        : <meshStandardMaterial attach="material" color={color} transparent={transparent} opacity={opacity} />}
    </mesh>
  );
});

PlaneMesh.propTypes = {
  vertices: PropTypes.array,
  receiveShadow: PropTypes.bool,
  color: PropTypes.string,
  opacity: PropTypes.number,
  transparent: PropTypes.bool,
  unlit: PropTypes.bool,
};

export default PlaneMesh;
