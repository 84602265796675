import React from 'react';
import PropTypes from 'prop-types';
import { useTexture, Plane } from '@react-three/drei';

const TexturedPlane = React.forwardRef(({ size, image, position, minFilter, opacity, ...props }, ref) => {
  const texture = useTexture(image);

  if (minFilter) {
    texture.minFilter = minFilter;
  }

  return (
    <Plane
      ref={ref}
      args={[1, 1]}
      scale={[size, size]}
      position={position}
      {...props}
    >
      <meshStandardMaterial attach="material" map={texture} transparent opacity={opacity} />
    </Plane>
  );
});

TexturedPlane.propTypes = {
  image: PropTypes.string,
  size: PropTypes.number,
  minFilter: PropTypes.number,
  opacity: PropTypes.number,
  position: PropTypes.any,
};

export default React.memo(TexturedPlane);
