import React from 'react';

export default () => (
  <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.3176 33.2537L21.6725 23.5543" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M21.6725 23.5543L23.1065 23.1265C24.4885 22.7143 25.9818 22.931 27.1896 23.7191V23.7191C28.5776 24.6248 29.4144 26.1701 29.4144 27.8274V28.188C29.4144 30.0113 28.5223 31.7192 27.0258 32.7608L26.3176 33.2537" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.4467 34.6009L13.1564 39.4506" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.1564 39.4506L12.6937 37.7597C12.3299 36.4302 12.5244 35.0099 13.2324 33.8273V33.8273C14.1457 32.3016 15.8035 31.3677 17.5817 31.3677V31.3677C19.3201 31.3677 20.9484 32.2545 21.884 33.7197L22.4467 34.6009" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M23.737 38.6423L28.3822 48.3417" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M28.3822 48.3417L26.9482 48.7694C25.5662 49.1817 24.0729 48.9649 22.8651 48.1768V48.1768C21.4771 47.2711 20.6403 45.7258 20.6403 44.0685V43.7079C20.6403 41.8846 21.5324 40.1767 23.0289 39.1351L23.737 38.6422" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M27.608 37.2951L36.8983 32.4454" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M36.8983 32.4454L37.361 34.1363C37.7248 35.4658 37.5303 36.8861 36.8223 38.0687V38.0687C35.909 39.5944 34.2512 40.5283 32.473 40.5283V40.5283C30.7346 40.5283 29.1062 39.6415 28.1706 38.1763L27.608 37.2951" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.5112 45.1085H8.76926V23.5543H1.54346L25.2854 2L36.1241 11.6306L49.5435 23.5543H41.8015V45.1085H33.0273" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
