import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIncludes from 'lodash/includes';
import lodashValues from 'lodash/values';
import lodashFlatten from 'lodash/flatten';
import { Tooltip, Affix, Form } from 'antd';
import { useRouteMatch } from 'react-router';
import { handleOnChange } from 'utils/helpers/feasibilityReportHelpers';
import { setSelectedRowKeysAction, clearSelectedRowKeysAction } from 'store/feasibility/actions/actions';
import { StyledExploreTable, StyledMenu, StyledSubMenu } from 'styles/commonComponents.styles';
import { feasibilityResultModel, SELECTION_CATEGORY_TYPES, FEASIBILITY_SELECTION_CATEGORIES, useActiveProfileBuildingInfo } from 'utils/model/feasibilityResultModel';
import { BUILDING_INFO_TYPES } from 'constants/feasibilityConts';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { isEditorResultSyncedWithServerSelector } from 'store/editor';
import { loadingSelector, useProfileResult } from 'store/swappProfile/selectors/swappSelectors';
import icons from 'styles/static/icons/feasibilityIcons/study';
import EmptyMessage from 'components/common/EmptyMessage';
import {
  TableCell,
  BoldCell,
  ResultData,
  ColorPill,
  LoaderWrapper,
} from '../../form/FeasibilityTargetFormContainer.Styles';
// import FeasibilityResultDataForm from './FeasibilityResultDataForm';
import FeasibilityEfficiencyScore from './FeasibilityEfficiencyScore';
import LoadingSpinnerSmall from '../../../../common/LoadingSpinnerSmall';
import StyledSubMenuTitle from '../../../../common/StyledSubMenuTitle';

const ProgramMetaData = (props) => {
  const { profile, siteData, legendKey, isEmptySite } = props;
  const result = useProfileResult(profile);
  const match = useRouteMatch();
  const wrapperRaf = useRef();
  const dispatch = useDispatch();
  const isLoading = useSelector(loadingSelector);
  // const user = useSelector(userSelector);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));
  const isGettingData = !useSelector(isEditorResultSyncedWithServerSelector);
  const lotArea = lodashGet(siteData, 'lotInfo.area');
  const buildingInfo = useActiveProfileBuildingInfo();
  const data = useMemo(() => feasibilityResultModel({ ...result, lotArea }, isImperial, buildingInfo), [result, lotArea, isImperial]);
  const siteVariant = lodashGet(siteData, 'variants', []).find((variant) => variant.key === lodashGet(data, 'siteId'));
  const { selectedRowKeys } = useSelector((state) => state.feasibility);
  const isInEdit = useSelector((state) => state.editor.profileId);
  // const hideFinancialByProjectId = lodashIncludes(lodashGet(user, 'settings.flags.hideFinancialByProjectIdList'), Number(match.params.projectId));

  const [form] = Form.useForm();

  // unmount
  useEffect(() => () => {
    dispatch(clearSelectedRowKeysAction());
  }, []);

  useEffect(() => {
    dispatch(clearSelectedRowKeysAction());
  }, [legendKey]);

  const buildingInfoColumns = [
    {
      dataIndex: 'colorPill',
      key: 'colorPill',
      width: 10,
      render: (color) => color && <ColorPill color={color} />,
    },
    {
      dataIndex: 'name',
      key: 'name',
      render: (value, rowData) => (
        <Tooltip
          title={rowData.percentageOfAllUnits
            ? `${value} - ${T.translate('FEASIBILITY_RESULT_DATA_APT_MIX')} ${rowData.percentageOfAllUnits}`
            : `${rowData.toolTip ? `${value} - ${rowData.toolTip}` : ''}`}
          placement="topRight"
        >
          <TableCell>{value}</TableCell>
          <BoldCell>{rowData.unitCount || ''}</BoldCell>
        </Tooltip>
      ),
    },
    {
      dataIndex: 'percentage',
      key: 'percentage',
      align: 'center',
      render: (value, rowData) => <Tooltip title={rowData.percentageTooltip}><BoldCell>{value}</BoldCell></Tooltip>,
    },
    {
      dataIndex: 'value',
      key: 'value',
      align: 'center',
      width: 120,
      render: (value, rowData) => (
        <Tooltip title={T.translate(rowData.valueTooltip)}>
          <span>
            <BoldCell>{rowData.type === BUILDING_INFO_TYPES.UNIT && !value && rowData.unitsOf ? rowData.unitsOf : value}</BoldCell>
            {rowData.type === BUILDING_INFO_TYPES.SCORE ? '/100' : ''}
          </span>
        </Tooltip>
      ),
    },
  ];

  const getSiteVariant = () => {
    if (!lodashIsEmpty(siteVariant)) {
      return {
        name: 'Site',
        percentage: '',
        value: siteVariant.value,
      };
    }

    return null;
  };

  const tableOptions = {
    showHeader: false,
    pagination: false,
    columns: buildingInfoColumns,
    onRow: (e) => ({ onClick: () => {
      onRowClick(e);
    } }),
    rowClassName: (row) => `
      ${lodashIncludes(lodashFlatten(lodashValues(selectedRowKeys)), row.key) && 'ant-table-row-selected'}
      ${row.colorPill && ' ant-table-row-selectable'}
      ${row.highlight && ' ant-table-total-row'}
      ${row.key === 'TOTAL' && ' ant-table-total-row'}
    `,
  };

  const onRowClick = (rowData) => {
    if (!rowData.colorPill) {
      return;
    }

    const newDict = { ...selectedRowKeys };
    const { key } = rowData;
    const selectionCategory = lodashGet(rowData, 'selectionCategory',
      lodashGet(FEASIBILITY_SELECTION_CATEGORIES, key, SELECTION_CATEGORY_TYPES.UNIT_TYPE));
    if (!(selectionCategory in newDict)) {
      newDict[selectionCategory] = [key];
    } else {
      let typeList = newDict[selectionCategory];
      if (lodashIncludes(typeList, key)) {
        typeList = typeList.filter((k) => k !== key);
        if (typeList.length > 0) {
          newDict[selectionCategory] = typeList;
        } else {
          delete newDict[selectionCategory];
        }
      } else {
        typeList.push(rowData.key);
      }
    }

    dispatch(setSelectedRowKeysAction(newDict));
  };

  const handleChange = () => {
    handleOnChange(dispatch, { profile, formInstance: form, isLoading, settingsType: 'feasibilityResult' });
  };

  if (isEmptySite) {
    return <EmptyMessage description={T.translate('FEASIBILITY_RESULT_NO_DATA')} />;
  }

  return (
    <ResultData isInEdit={!!isInEdit} ref={wrapperRaf}>
      <Form name="feasibilityResultDataForm" form={form} onFieldsChange={handleChange}>
        {isGettingData && !!isInEdit && <Affix target={() => wrapperRaf.current} offsetTop={0}><LoaderWrapper><LoadingSpinnerSmall /></LoaderWrapper></Affix>}
        <StyledMenu
          mode="inline"
          defaultOpenKeys={['EFFICIENCY_SCORE', 'BUILDING_INFO_A', 'BUILDING_INFO_B', 'COMMUNAL_SPACE', 'ENHANCED_APARTMENTS', 'APARTMENT_ORIENTATION']}
          overflow
          forceSubMenuRender
        >
          {/* ========= EFFICIENCY SCORE ========= */}
          {!lodashIsEmpty(lodashGet(data, 'efficiencyScoreTable')) && (
            <StyledSubMenu
              key="EFFICIENCY_SCORE"
              padding={10}
              title={(
                <StyledSubMenuTitle
                  icon={icons.efficiencyScoreIcon}
                  title={T.translate('FEASIBILITY_RESULT_DATA_EFFICIENCY_SCORE')}
                />
  )}
            >
              <FeasibilityEfficiencyScore data={lodashGet(data, 'efficiencyScoreTable')} />
            </StyledSubMenu>
          )}

          {/* ========= BUILDING INFO A ========= */}
          <StyledSubMenu
            key="BUILDING_INFO_A"
            padding={10}
            title={<StyledSubMenuTitle icon={icons.areaSummaryIcon} title={T.translate('FEASIBILITY_RESULT_DATA_AREA_SUMMARY')} />}
          >
            <StyledExploreTable {...tableOptions} dataSource={[getSiteVariant(), ...lodashGet(data, 'buildingInfoATable', [])].filter((e) => e)} />
          </StyledSubMenu>

          {/* ========= BUILDING INFO B ========= */}
          <StyledSubMenu
            key="BUILDING_INFO_B"
            padding={10}
            title={<StyledSubMenuTitle icon={icons.unitsYieldIcon} title={T.translate('FEASIBILITY_RESULT_DATA_UNITS_COUNT')} />}
          >
            <StyledExploreTable {...tableOptions} dataSource={lodashGet(data, 'buildingInfoBTable')} />
          </StyledSubMenu>

          {/* ========= Communal Space ========= */}
          {!lodashIsEmpty(lodashGet(data, 'communalSpaceInfoTable')) && (
            <StyledSubMenu
              key="COMMUNAL_SPACE"
              padding={10}
              title={<StyledSubMenuTitle icon={icons.communalSpaceIcon} title={T.translate('FEASIBILITY_RESULT_DATA_COMMUNAL_SPACE')} />}
            >
              <StyledExploreTable {...tableOptions} dataSource={lodashGet(data, 'communalSpaceInfoTable')} />
            </StyledSubMenu>
          )}

          {/* ========= ENHANCED APARTMENTS ========= */}
          {!lodashIsEmpty(lodashGet(data, 'enhancedApartmentsTable')) && (
            <StyledSubMenu
              key="ENHANCED_APARTMENTS"
              padding={10}
              title={<StyledSubMenuTitle icon={icons.enhancedApartmentsIcon} title={T.translate('FEASIBILITY_RESULT_DATA_ENHANCED_APARTMENTS')} />}
            >
              <StyledExploreTable {...tableOptions} dataSource={lodashGet(data, 'enhancedApartmentsTable')} />
            </StyledSubMenu>
          )}

          {/* ========= APARTMENT ORIENTATION ========= */}
          {!lodashIsEmpty(lodashGet(data, 'apartmentOrientationTable')) && (
            <StyledSubMenu
              key="APARTMENT_ORIENTATION"
              padding={10}
              title={<StyledSubMenuTitle icon={icons.lotInfoIcon} title={T.translate('FEASIBILITY_RESULT_DATA_APARTMENT_ORIENTATION')} />}
            >
              <StyledExploreTable {...tableOptions} dataSource={lodashGet(data, 'apartmentOrientationTable')} />
            </StyledSubMenu>
          )}

          {/* ========= HVAC Data ========= */}
          {/* {!lodashIsEmpty(lodashGet(data, 'hvacData')) && ( */}
          {/*  <StyledSubMenu */}
          {/*    key="HVAC_DATA" */}
          {/*    padding={10} */}
          {/*    title={<StyledSubMenuTitle icon={icons.hvacDesignIcon} title={T.translate('FEASIBILITY_TARGET_FORM_CARD_TYPE_HVAC')} />} */}
          {/*  > */}
          {/*    <StyledExploreTable {...tableOptions} dataSource={lodashGet(data, 'hvacData')} /> */}
          {/*  </StyledSubMenu> */}
          {/* )} */}

          {/* ========= FORM ========= */}
          {/* {!hideFinancialByProjectId && <FeasibilityResultDataForm disabled={isSharedUrl()} profile={profile} data={lodashGet(data, 'formData')} handleChange={handleChange} form={form} />} */}
        </StyledMenu>
      </Form>
    </ResultData>
  );
};

ProgramMetaData.propTypes = {
  profile: PropTypes.object,
  siteData: PropTypes.object,
  legendKey: PropTypes.string,
  isEmptySite: PropTypes.bool,
};

export default React.memo(ProgramMetaData);
