import styled from 'styled-components';
import { Dropdown, Menu } from 'antd';
import { StyledInput } from 'styles/commonComponents.styles';
import Icon from '@ant-design/icons';

const maxWidth = 500;
const maxHeight = 195;

export const StyledMenu = styled(Menu)`
  max-height: ${maxHeight}px;
  min-width: 60px;
  max-width: ${maxWidth}px;
  overflow: hidden;
  left: -5px;
`;

export const MenuItem = styled(Menu.Item)`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  line-height: 1.4;
  color: ${({ theme, selected }) => (selected && theme.colors.primaryColor)};
  border-top: ${({ theme, separator }) => (separator ? `1px solid ${theme.colors.gray_02}` : 'none')};
  padding-top: ${({ separator }) => (separator ? '10px' : '0')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${maxWidth - 30}px;

  :hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.primaryColorHover};
  }
`;

export const StyledProjectsMenuInner = styled.div`
  overflow: auto;
  max-height: ${maxHeight}px;
  padding: 5px 0;
`;

export const StyledDropDown = styled(Dropdown)`
  width: ${({ other }) => (other || '100%')};
`;

export const DropDownWrapper = styled.div`
  color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_06 : theme.colors.textColor)};
  display: flex;
  justify-content: space-between;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  //height: 32px;
  cursor: ${({ disabled, permanent }) => (disabled || permanent ? 'default' : 'pointer')};
   // padding: ${({ other }) => (other ? '0 6px' : '8px 6px')};
  
  &:first-child {
    padding-left: 0;
  }
`;

export const DropDownInLineText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  line-height: 25px;
  text-overflow: ellipsis;
  max-width: ${({ width }) => (width ? `${width}px` : '140px')};
  //display: flex;
  flex-direction: column;
  justify-content: center;
  
  .anticon {
    margin: 0 5px;
  }
`;

export const DropDownInLineTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryColor} !important;
    
    svg {
      color: ${({ theme }) => theme.colors.primaryColor} !important;
    }
  }
`;

export const OtherInput = styled(StyledInput)`
  height: 23px !important;;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_06 : theme.colors.textColor)};
  background-color: ${({ theme }) => theme.colors.inputBackground};
  width: ${({ width }) => (width || '100%')};
  border: 1px solid ${({ theme }) => theme.colors.gray_04};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  padding: 4px 11px 4px 5px;
  box-shadow: none;
  
  .ant-input {
    color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_06 : theme.colors.textColor)};
    background-color: transparent !important;
    font-size: ${({ theme }) => theme.font.size.small} !important;
  }
  
  .anticon {
    color: ${({ theme }) => theme.colors.gray_05};
  }
`;

export const DropdownIconWrapper = styled.div`
  padding-top: ${({ isOther }) => (isOther ? '8px' : '0')};
  color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_06 : theme.colors.textColor)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  &:hover {
    color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_06 : theme.colors.primaryColorHover)};
  }
`;

export const DropdownIcon = styled(Icon)`
  margin-left: 10px;
  margin-right: 5px;
  //margin-top: 7px;
  display: ${({ disabled }) => (disabled ? 'none' : 'inline-block')};
`;
