import React, { useRef } from 'react';
import styled from 'styled-components';
import { useAnimationFrame } from 'utils/hooks';
import compassSvg from 'styles/static/img/feasibility/north.svg';
import { threeJsGlobals } from 'store/threeJsGlobals';

export const StyledImage = styled.img`
  width: 44px;
  height: 44px;
  margin-bottom: 4px;
  transform-origin: 22px 24px;
`;

export const StyledDiv = styled.div`
  width: 53px;
  height: 53px;
  border-radius: 50%;
  z-index: 10;
  background-color: rgba(255,255,255,0.0);
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;  
  border-style: dashed;
`;

const MAX_X_ROTATION = Math.PI / 3;

const CompassImage = () => {
  const ref = useRef();

  useAnimationFrame(() => {
    if (ref.current) {
      const rotation = threeJsGlobals.getCameraRotation();
      let rotX = rotation.x;
      if (Math.abs(rotX) > MAX_X_ROTATION) {
        rotX = Math.sign(rotX) * (MAX_X_ROTATION);
      }
      ref.current.style.transform = `rotateX(${rotX}rad) rotateZ(${rotation.z}rad)`;
    }
  });

  return (
    <StyledDiv>
      <StyledImage src={compassSvg} ref={ref} />
    </StyledDiv>
  );
};

CompassImage.propTypes = {};

export default React.memo(CompassImage);
