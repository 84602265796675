import React from 'react';

export default () => (
  <svg width="231" height="133" viewBox="0 0 231 133" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M175.41 1.04811C174.973 0.610854 174.334 0.536463 173.988 0.882471L154.175 20.6957C153.829 21.0418 152.795 22.5707 152.664 23.0745L150.618 30.0552C150.574 30.5857 150.973 30.9842 151.505 30.9421L158.522 28.9323C159.085 28.6051 160.573 27.7864 160.919 27.4404L180.732 7.62713C181.079 7.28111 181.004 6.6423 180.567 6.20506L175.41 1.04811Z" stroke="#B8B9C7" strokeMiterlimit="10" />
    <path d="M154.384 20.4878L161.129 27.2324" stroke="#B8B9C7" strokeMiterlimit="10" />
    <path d="M151.662 26.8857L154.805 30.1016" stroke="#B8B9C7" strokeMiterlimit="10" />
    <path d="M172.365 2.50192L179.11 9.24658" stroke="#B8B9C7" strokeMiterlimit="10" />
    <path d="M158.882 24.9868L176.867 7.00106" stroke="#B8B9C7" strokeMiterlimit="10" />
    <path d="M156.628 22.7368L174.614 4.75106" stroke="#B8B9C7" strokeMiterlimit="10" />
    <path d="M130.638 20.7188H1.43677V132H173.706V77.1542H229.563V57.68H154.448V112.923H20.911V39.7955H153.689" stroke="#DCDDE5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M229.563 67.2181H164.384V122.461H10.9751V30.6543H142.128" stroke="#B8B9C7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
