import React from 'react';

export default () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M38.0316 23.8209V12.909H12.9124V24.3165L20.3137 27.0088" stroke="currentcolor" strokeWidth="0.7" strokeMiterlimit="10" />
    <path d="M22.2208 27.6428C22.2444 27.3677 22.0405 27.1255 21.7654 27.1018C21.4903 27.0782 21.2482 27.282 21.2245 27.5572C21.2009 27.8323 21.4047 28.0745 21.6798 28.0981C21.9549 28.1217 22.1972 27.9179 22.2208 27.6428Z" fill="currentcolor" />
    <path d="M24.0431 28.3233C24.0667 28.0482 23.8629 27.806 23.5877 27.7824C23.3126 27.7587 23.0704 27.9627 23.0468 28.2377C23.0231 28.5128 23.227 28.755 23.5021 28.7786C23.7772 28.8023 24.0194 28.5984 24.0431 28.3233Z" fill="currentcolor" />
    <path d="M25.8697 29.0077C25.8934 28.7326 25.6895 28.4904 25.4144 28.4667C25.1393 28.4431 24.8971 28.6469 24.8734 28.9221C24.8498 29.1972 25.0538 29.4394 25.3288 29.463C25.6039 29.4866 25.8461 29.2827 25.8697 29.0077Z" fill="currentcolor" />
    <path d="M12.9124 27.0881V38H38.0316V26.5926L30.6304 23.8963" stroke="currentcolor" strokeWidth="0.7" strokeMiterlimit="10" />
    <path d="M29.9874 23.6228C30.011 23.3477 29.8072 23.1055 29.5321 23.0819C29.2569 23.0582 29.0148 23.2621 28.9911 23.5372C28.9675 23.8123 29.1714 24.0545 29.4464 24.0782C29.7216 24.1018 29.9638 23.8978 29.9874 23.6228Z" fill="currentcolor" />
    <path d="M28.1642 22.9423C28.1878 22.6673 27.9838 22.4249 27.7087 22.4013C27.4337 22.3777 27.1915 22.5816 27.1679 22.8568C27.1442 23.1318 27.3481 23.3739 27.6232 23.3976C27.8983 23.4212 28.1405 23.2174 28.1642 22.9423Z" fill="currentcolor" />
    <path d="M26.338 22.2598C26.3616 21.9847 26.1578 21.7425 25.8827 21.7188C25.6075 21.6952 25.3653 21.8991 25.3417 22.1742C25.3181 22.4493 25.5219 22.6915 25.797 22.7151C26.0722 22.7388 26.3143 22.5348 26.338 22.2598Z" fill="currentcolor" />
  </svg>
);
