import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import { Tabs, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { StyledTab } from 'styles/commonComponents.styles';
import { feasibilityResultModel, useActiveProfileBuildingInfo } from 'utils/model/feasibilityResultModel';
import { useProfileResult } from 'store/swappProfile/selectors/swappSelectors';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import styled from 'styled-components';
import HeatLegend from './HeatLegend';

const { TabPane } = Tabs;

const FeasibilityLegendPanelWrapper = styled.div`
  margin-bottom: 10px;
`;

const FeasibilityLegendPanel = (props) => {
  const { profile, updateLegendKey, legendKey } = props;
  const match = useRouteMatch();
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));

  const result = useProfileResult(profile);
  const buildingInfo = useActiveProfileBuildingInfo();
  const data = useMemo(() => feasibilityResultModel(result, isImperial, buildingInfo), [result, isImperial, buildingInfo]);

  // we filter "AREA_TYPE" for now
  const renderTabs = () => lodashGet(data, 'modelAnalysisArray', []).filter((model) => model.key !== 'AREA_TYPE').map((model) => (
    <TabPane tab={<Tooltip title={model.toolTip}>{model.name}</Tooltip>} key={model.key}>
      <HeatLegend activeKey={model.key} colorSets={lodashGet(data, 'legendColors')} />
    </TabPane>
  ));

  return (
    <FeasibilityLegendPanelWrapper>
      <StyledTab size="small" tabPosition="left" activeKey={legendKey} onChange={(key) => updateLegendKey(key)}>
        {renderTabs()}
      </StyledTab>
    </FeasibilityLegendPanelWrapper>
  );
};

FeasibilityLegendPanel.propTypes = {
  profile: PropTypes.object,
  updateLegendKey: PropTypes.func,
  legendKey: PropTypes.string,
};

export default FeasibilityLegendPanel;
