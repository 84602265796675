import styled from 'styled-components';

export const ReportPageWrapper = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - ${({ theme }) => `${theme.other.headerHeight}px`} - 48px); // 48px is the height of the action bar
`;

export const ReportPageContentWrapper = styled.div`
  padding: 40px 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: calc(100vh - ${({ theme }) => `${theme.other.headerHeight}px`} - 48px); // 48px is the height of the action bar
`;

export const StyledNavigationWrapper = styled.div`
  display: inline-block;
  width: 160px;
  min-width: 160px;
  overflow-y: auto;
  margin-left: 10px;
`;

export const StyledNavigationItemWrapper = styled.div`
  display: flex;
  color: ${({ theme, selected }) => (selected ? theme.colors.primaryColor : theme.colors.textColor)};
  margin-bottom: ${({ isSubPage }) => (!isSubPage ? '5px' : '0')};
  margin-top: ${({ isSubPage }) => (!isSubPage ? '5px' : '0')};

`;

export const StyledNavigationItem = styled.div`
  padding-left: ${({ isSubPage }) => (isSubPage ? '10px' : '0')};
  margin-left: ${({ isSubPage }) => (isSubPage ? '10px' : '0')};
  font-weight: ${({ theme, isSubPage }) => (!isSubPage && theme.font.weight.bold)};
  font-size: ${({ theme }) => theme.font.size.small};
  letter-spacing: 0.3px;
  border-left: ${({ theme, selected, isSubPage }) => (`2px solid ${selected && isSubPage ? theme.colors.primaryColor : theme.colors.gray_03}`)};
  border-color: ${({ isSubPage }) => (!isSubPage && 'transparent')};
`;
