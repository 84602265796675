import React from 'react';
import { useSelector } from 'react-redux';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import { useHistory } from 'react-router';
import lodashIsEmpty from 'lodash/isEmpty';
import { feasibilityReportSelector } from 'store/swappProfile/selectors';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { ContentColumnWrapper, ContentTitle, VerticalTitleContentWrapper,
  ContentImagesWrapper, ImageCardWrapper, ImageCardContent, ImageCard, StyledText,
} from '../ReportPages.styles';

const Phasing = () => {
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const reportData = useSelector((state) => feasibilityReportSelector(state, locationData.profileId));
  const phasingData = lodashGet(reportData, 'strategy.phasingData');

  if (lodashIsEmpty(phasingData)) {
    return null;
  }

  return (
    <VerticalTitleContentWrapper>
      <ContentColumnWrapper>
        <ContentTitle>{T.translate('Phasing Diagrams')}</ContentTitle>

        {/* ======== images ======== */}
        <ContentImagesWrapper width={520}>
          {phasingData.map((item, index) => (
            <ImageCardWrapper width={520} key={index}>
              <ImageCardContent>
                <StyledText bold marginBottom={7}>{T.translate(item.title)}</StyledText>
                <StyledText size="small">{T.translate(item.description)}</StyledText>
              </ImageCardContent>
              <ImageCard src={item.imageUrl} />
            </ImageCardWrapper>
          ))}
        </ContentImagesWrapper>
      </ContentColumnWrapper>
    </VerticalTitleContentWrapper>
  );
};

export default React.memo(Phasing);
