import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import T from 'i18n-react';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashGet from 'lodash/get';
import lodashSortBy from 'lodash/sortBy';
import Icon, { HeartFilled } from '@ant-design/icons';
import arrowIcon from 'styles/static/icons/comon/arrowIcon';
import { Overlay, PrimaryButton } from 'styles/commonComponents.styles';
import { swappProjectSelector } from 'store/swappProfile';
import { getShareKeyString, isSharedUrl, parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import { PROJECT_STAGES, PROFILE_STATUS } from 'constants/profileConsts';
import ResultSplitViewSidebar from 'components/common/ResultSplitViewSidebar';
import { BeginFinishedEditProvider } from 'utils/hooks/reactContexts';
import cornerIcon from 'styles/static/icons/comon/cornerIcon';
import { TAB_NAMES } from 'constants/globalConst';
import ResultSplitViewActionBar from 'components/common/ResultSplitViewActionBar';
import { useOverridableSetting } from 'store/userSettings';
import lodashIncludes from 'lodash/includes';
import { EDIT_TYPES } from 'store/editor';
import {
  ResultSplitViewWrapper,
  FormWrapper,
  FormButtonWrapper,
  FormContent,
  ResultContent,
  ResultContentWrapper,
  ResultWrapper,
  StyledIcon,
  ResultActionBar,
  CornerIcon,
} from './ResultSplitView.srtyles';
import Notification from './Notification';

const ResultSplitView = (props) => {
  const { width, children, handleUpdate, renderCard, sidebarButtons,
    loading, renderExpandData, disabled, filterTab } = props;
  const [currentTab, setCurrentTab] = useState(TAB_NAMES.RESULT);
  const history = useHistory();
  const swappProject = useSelector((state) => swappProjectSelector(state));
  const isEditModeOn = useSelector(({ editor }) => lodashIncludes([EDIT_TYPES.TEST_FIT, EDIT_TYPES.FEASIBILITY], editor.editSessionType));
  const locationData = parseLocationUrl(history.location);

  const currentOpenedProfileId = Number(locationData.profileId);
  const isExpandDataOpen = !!currentOpenedProfileId;
  const profileIndex = lodashGet(swappProject, 'projectProfiles', []).findIndex((profile) => !profile.parentProfileId);
  const parentProfile = lodashGet(swappProject, `projectProfiles[${profileIndex}]`);
  const parentProfileTimestamp = lodashGet(parentProfile, 'result.generatedTimestamp');
  const isUserReadOnly = useOverridableSetting('readOnly', false);
  const hasOcbProfileUpload = useOverridableSetting('hasOcbProfileUpload', false);
  const hideRequirementForm = useOverridableSetting('hideRequirementForm', false);
  const isProfileDisabled = lodashGet(parentProfile, 'profileData.isGenerateButtonDisabled') || isUserReadOnly;
  const isWaitingForResults = lodashGet(parentProfile, 'status') === PROFILE_STATUS.WAITING_FOR_DATA;
  const isShared = isSharedUrl();
  const shareKey = getShareKeyString();

  let resultProfiles = lodashGet(swappProject, 'projectProfiles', []).filter((profile) => profile.parentProfileId);
  if (isShared) {
    resultProfiles = resultProfiles.filter((profile) => profile.status !== PROFILE_STATUS.WAITING_FOR_DATA);
    if (lodashGet(parentProfile, 'stage') === PROJECT_STAGES[UI_AUTHORIZE_PATH.FEASIBILITY_STUDY]) {
      resultProfiles = resultProfiles.filter((profile) => !lodashIsEmpty(lodashGet(profile, 'result.singleOptionMassingOptions.massing_options[0].masses')));
    }
  }

  const sortedProfiles = lodashSortBy(resultProfiles, [(p) => lodashGet(p, 'result.study.typology') === 'EMPTY', 'id']);

  const getFilteredProfilesByType = (isResult, excludeLoading) => {
    let cards = [];
    if (isResult) {
      cards = sortedProfiles.filter((profile) => profile.stage === PROJECT_STAGES[UI_AUTHORIZE_PATH.ONE_CLICK_BUILDING] || lodashGet(profile, 'result.generatedTimestamp') === parentProfileTimestamp);
      if (isWaitingForResults) {
        cards.push({ loading: true });
      }
      if (excludeLoading || isShared) {
        cards = cards.filter((profile) => profile.status !== PROFILE_STATUS.WAITING_FOR_DATA);
      }
      if (hasOcbProfileUpload && lodashGet(parentProfile, 'stage') === PROJECT_STAGES[UI_AUTHORIZE_PATH.FEASIBILITY_STUDY] && lodashGet(parentProfile, 'status') !== PROFILE_STATUS.WAITING_FOR_DATA) {
        cards.push({ uploadCard: true });
      }
    } else {
      cards = sortedProfiles.filter((profile) => lodashGet(profile, 'profileData.isFavorite'));
    }

    return cards;
  };

  const handleProfileChange = (profileId) => {
    if (Number(currentOpenedProfileId) !== Number(profileId)) {
      history.push(`/${locationData.stage}/${locationData.projectId}/${locationData.stageTab}/${profileId}/${UI_AUTHORIZE_PATH.program}${shareKey}`);
    }
  };

  const renderResultCards = (isResult) => ((getFilteredProfilesByType(isResult)).reverse().map((profile) => renderCard(profile, handleProfileChange)));

  const tabData = {
    [TAB_NAMES.RESULT]: {
      render: renderResultCards(true),
      notificationIcon: <Icon component={arrowIcon} />,
      notificationTitle: 'RESULT_SPLIT_VIEW_RESULT_EMPTY_TITLE',
      notificationHint: 'RESULT_SPLIT_VIEW_RESULT_EMPTY_HINT',
    },
    [TAB_NAMES.FAVORITES]: {
      render: renderResultCards(false),
      notificationIcon: <HeartFilled />,
      notificationTitle: 'RESULT_SPLIT_VIEW_FAVORITE_EMPTY_TITLE',
    },
  };

  const renderResults = () => {
    if (isExpandDataOpen) {
      return renderExpandData;
    }

    if (lodashIsEmpty(tabData[currentTab].render)) {
      return (
        <Notification
          marginTop={200}
          iconComponent={tabData[currentTab].notificationIcon}
          notification={T.translate(tabData[currentTab].notificationTitle)}
          hints={T.translate(tabData[currentTab].notificationHint)}
        />
      );
    }

    return <ResultContent>{tabData[currentTab].render}</ResultContent>;
  };

  const renderForm = () => {
    if (isShared || !children || isExpandDataOpen || hideRequirementForm) {
      return;
    }

    return (
      <FormWrapper width={width}>
        {!isExpandDataOpen && <FormContent>{children}</FormContent>}
        {!isExpandDataOpen && (
          <FormButtonWrapper>
            <PrimaryButton disabled={loading || disabled || isProfileDisabled} onClick={(e) => handleUpdate(e)} height={32} width={200}>
              {loading ? <StyledIcon spin /> : T.translate('UPDATE')}
            </PrimaryButton>

          </FormButtonWrapper>
        )}
      </FormWrapper>
    );
  };

  return (
    <BeginFinishedEditProvider>
      <ResultSplitViewWrapper>
        {/* =========== form =========== */}
        {renderForm()}
        {/* =========== result =========== */}
        <ResultWrapper>
          {/* =========== action bar =========== */}
          <Overlay show={isEditModeOn} />
          <ResultActionBar>
            <ResultSplitViewActionBar
              isExpandDataOpen={isExpandDataOpen}
              isEditModeOn={isEditModeOn}
              getFilteredProfilesByType={getFilteredProfilesByType}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              filterTab={filterTab}
            />
            <CornerIcon component={cornerIcon} />
          </ResultActionBar>

          {/* =========== cards =========== */}
          <ResultContentWrapper>
            <ResultSplitViewSidebar buttons={sidebarButtons} hidden={!isExpandDataOpen || isEditModeOn} />
            {renderResults()}
          </ResultContentWrapper>
        </ResultWrapper>
      </ResultSplitViewWrapper>
    </BeginFinishedEditProvider>
  );
};

ResultSplitView.propTypes = {
  width: PropTypes.number,
  handleUpdate: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  filterTab: PropTypes.func,
  renderCard: PropTypes.func,
  sidebarButtons: PropTypes.array,
  renderExpandData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  children: PropTypes.any,
};

export default ResultSplitView;
