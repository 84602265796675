import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '@ant-design/icons';

const MarkupsButtonWrapper = styled.div`
  height: 45px;
  width: 40px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: 7px;
  margin-right: 3px;
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.primaryColor : theme.colors.gray_07)};
`;

const StyledIcon = styled(Icon)`
  display: block;
  margin: 0 auto;
  height: 32px;
  width: 32px;

  ${MarkupsButtonWrapper}:hover & {
    color: ${({ theme }) => theme.colors.primaryColorHover};
  }
`;

const ColorIcon = styled.div`
  margin: 7px auto 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: radial-gradient(${({ color }) => `50% 50% at 50% 50%, ${color} 0%, ${color} 80%, #FFFFFF 80%, #FFFFFF 100%`});
  border: 2px solid ${({ theme, isSelected }) => (isSelected ? theme.colors.primaryColor : 'transparent')};

  ${MarkupsButtonWrapper}:hover & {
    border: 2px solid ${({ theme }) => theme.colors.primaryColorHover};
  }
`;

const Text = styled.div`
  margin-top: 3px;
  text-transform: capitalize;
  font-size: 10px;
  text-align: center;
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.primaryColor : theme.colors.textColor)};
  
  ${MarkupsButtonWrapper}:hover & {
    color: ${({ theme }) => theme.colors.primaryColorHover};
  }
`;

const MarkupsButton = (props) => {
  const { name, isSelected, handleClick, color, icon } = props;

  return (
    <MarkupsButtonWrapper onClick={handleClick} isSelected={isSelected}>
      {color ? <ColorIcon color={color} isSelected={isSelected} /> : <StyledIcon component={icon} />}
      <Text isSelected={isSelected}>{name && name.toLowerCase()}</Text>
    </MarkupsButtonWrapper>
  );
};

MarkupsButton.propTypes = {
  name: PropTypes.string,
  handleClick: PropTypes.func,
  isSelected: PropTypes.bool,
  color: PropTypes.string,
  icon: PropTypes.func,
};

export default MarkupsButton;
