import React from 'react';

// currentcolor
export default () => (
  <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M69 32.5C69 29.0388 68.003 25.6554 66.135 22.7775C64.267 19.8997 61.612 17.6567 58.5056 16.3321C55.3993 15.0076 51.9812 14.661 48.6835 15.3363C45.3858 16.0115 42.3567 17.6782 39.9792 20.1256C37.6017 22.5731 35.9826 25.6913 35.3267 29.0859C34.6707 32.4806 35.0074 35.9993 36.2941 39.197C37.5807 42.3947 39.7597 45.1278 42.5553 47.0507C45.3509 48.9736 48.6377 50 52 50C56.5072 49.9949 60.8283 48.1495 64.0153 44.8687C67.2024 41.5879 68.995 37.1397 69 32.5V32.5ZM39.5263 32.5C39.5263 29.9604 40.2578 27.4778 41.6285 25.3662C42.9991 23.2545 44.9472 21.6087 47.2265 20.6368C49.5058 19.665 52.0138 19.4107 54.4335 19.9061C56.8532 20.4016 59.0758 21.6245 60.8203 23.4203C62.5648 25.2161 63.7528 27.5041 64.2341 29.9949C64.7154 32.4858 64.4683 35.0676 63.5242 37.4139C62.5801 39.7602 60.9813 41.7656 58.93 43.1766C56.8787 44.5875 54.4671 45.3406 52 45.3406C48.6929 45.3368 45.5223 43.9827 43.1838 41.5755C40.8454 39.1682 39.53 35.9044 39.5263 32.5V32.5Z" fill="currentcolor" />
    <path d="M50.4671 85.3978C50.8869 85.7853 51.4332 86 51.9997 86C52.5662 86 53.1125 85.7853 53.5323 85.3978C54.7763 84.249 84 56.9866 84 32.5825C83.9949 23.9393 80.6213 15.6519 74.6207 9.54202C68.62 3.43217 60.4836 0 52 0C43.5164 0 35.38 3.43217 29.3793 9.54202C23.3787 15.6519 20.0051 23.9393 20 32.5825C20 56.9866 49.2231 84.249 50.4671 85.3978ZM51.9997 4.63051C59.2737 4.63875 66.2475 7.58631 71.3911 12.8265C76.5347 18.0667 79.4279 25.1717 79.4362 32.5825C79.4362 51.9049 57.9842 74.533 51.9992 80.4688C46.0125 74.5358 24.5638 51.9237 24.5638 32.5825C24.572 25.1718 27.4652 18.0669 32.6087 12.8267C37.7521 7.58648 44.7258 4.63886 51.9997 4.63051V4.63051Z" fill="currentcolor" />
    <path d="M75.3231 70L74.0379 74.447C83.4726 77.1244 89.3333 81.3597 89.3333 85.4984C89.3333 92.0616 74.0015 99.3746 52 99.3746C29.9991 99.3746 14.6667 92.0616 14.6667 85.4984C14.6667 81.3591 20.5279 77.1244 29.9626 74.447L28.6775 70C16.808 73.3691 10 79.0182 10 85.4984C10 96.0461 28.056 104 52 104C75.944 104 94 96.0461 94 85.4984C94 79.0182 87.1925 73.3691 75.3231 70Z" fill="currentcolor" />
  </svg>
);
