import React from 'react';
import PropTypes from 'prop-types';
import Icon, { PlusOutlined } from '@ant-design/icons';
import T from 'i18n-react';
import { NewProjectImageWrapper, NewProjectContentWrapper, StyledCardWrapper } from './ProjectCards.styles';
import newProjectIcon from '../../styles/static/icons/comon/newProjectIcon';

const NewProjectCard = ({ disabled, toggleModal }) => (
  <StyledCardWrapper disabled={disabled} onClick={() => (disabled ? null : toggleModal())}>
    <NewProjectImageWrapper disabled={disabled}>
      <Icon component={newProjectIcon} />
    </NewProjectImageWrapper>
    <NewProjectContentWrapper disabled={disabled}>
      <PlusOutlined />
      {T.translate('FLOOR_LIST_NEW_FLOOR_BUTTON')}
    </NewProjectContentWrapper>
  </StyledCardWrapper>
);

NewProjectCard.propTypes = {
  disabled: PropTypes.bool,
  toggleModal: PropTypes.func,
};
export default NewProjectCard;
