import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import styled from 'styled-components';
import { AREA_TARGET_FORM } from 'constants/fieldNames';
import { TEST_FIT_SIMPLE_FORM } from 'constants/dropdownKeys';
import { renderDescriptionBullets } from 'utils/helpers/tableHelpers';
import { profileTemplatesSelector } from 'store/swappProfileTemplates';
import RadioText from 'components/common/RadioText';
import { FormItem } from './AreaTargetFormContainer.styles';

const AreaTargetFormOfficeTypeWrapper = styled.div`
  margin: 10px 0;
`;

const AreaTargetFormOfficeType = (props) => {
  const { disabled, formData, handleOnChange, isJustCreated, form: { setFieldsValue, resetFields } } = props;
  const profileTemplates = useSelector(profileTemplatesSelector);

  const typeArray = useMemo(() => [
    {
      key: TEST_FIT_SIMPLE_FORM.OFFICE_TYPES.HI_TECH,
      value: 'AREA_TARGET_FORM_SIMPLE_FORM_HI_TECH',
      description: renderDescriptionBullets([
        'AREA_TARGET_FORM_SIMPLE_FORM_HI_TECH_DESCRIPTION_A',
        'AREA_TARGET_FORM_SIMPLE_FORM_HI_TECH_DESCRIPTION_B',
        'AREA_TARGET_FORM_SIMPLE_FORM_HI_TECH_DESCRIPTION_C',
        'AREA_TARGET_FORM_SIMPLE_FORM_HI_TECH_DESCRIPTION_D',
      ]),
    },
    { key: TEST_FIT_SIMPLE_FORM.OFFICE_TYPES.COWORKING,
      value: 'AREA_TARGET_FORM_SIMPLE_FORM_COWORKING',
      description: renderDescriptionBullets([
        'AREA_TARGET_FORM_SIMPLE_FORM_COWORKING_DESCRIPTION_A',
        'AREA_TARGET_FORM_SIMPLE_FORM_COWORKING_DESCRIPTION_B',
        'AREA_TARGET_FORM_SIMPLE_FORM_COWORKING_DESCRIPTION_C',
        'AREA_TARGET_FORM_SIMPLE_FORM_COWORKING_DESCRIPTION_D',
      ]),
    },
    {
      key: TEST_FIT_SIMPLE_FORM.OFFICE_TYPES.FINANCE,
      value: 'AREA_TARGET_FORM_SIMPLE_FORM_FINANCE',
      description: renderDescriptionBullets([
        'AREA_TARGET_FORM_SIMPLE_FORM_FINANCE_DESCRIPTION_A',
        'AREA_TARGET_FORM_SIMPLE_FORM_FINANCE_DESCRIPTION_B',
        'AREA_TARGET_FORM_SIMPLE_FORM_FINANCE_DESCRIPTION_C',
        'AREA_TARGET_FORM_SIMPLE_FORM_FINANCE_DESCRIPTION_D',
      ]),
    },
    { key: TEST_FIT_SIMPLE_FORM.OFFICE_TYPES.BANKING,
      value: 'AREA_TARGET_FORM_SIMPLE_FORM_BANKING',
      description: renderDescriptionBullets([
        'AREA_TARGET_FORM_SIMPLE_FORM_BANKING_DESCRIPTION_A',
        'AREA_TARGET_FORM_SIMPLE_FORM_BANKING_DESCRIPTION_B',
        'AREA_TARGET_FORM_SIMPLE_FORM_BANKING_DESCRIPTION_C',
      ]),
    },
    { key: TEST_FIT_SIMPLE_FORM.OFFICE_TYPES.LAW,
      value: 'AREA_TARGET_FORM_SIMPLE_FORM_LAW',
      description: renderDescriptionBullets([
        'AREA_TARGET_FORM_SIMPLE_FORM_LAW_DESCRIPTION_A',
        'AREA_TARGET_FORM_SIMPLE_FORM_LAW_DESCRIPTION_B',
        'AREA_TARGET_FORM_SIMPLE_FORM_LAW_DESCRIPTION_C',
        'AREA_TARGET_FORM_SIMPLE_FORM_LAW_DESCRIPTION_D',
      ]),
    },
    { key: TEST_FIT_SIMPLE_FORM.OFFICE_TYPES.OPEN_SPACE,
      value: 'AREA_TARGET_FORM_SIMPLE_FORM_OPEN_SPACE',
      description: renderDescriptionBullets([
        'AREA_TARGET_FORM_SIMPLE_FORM_OPEN_SPACE_DESCRIPTION_A',
        'AREA_TARGET_FORM_SIMPLE_FORM_OPEN_SPACE_DESCRIPTION_B',
        'AREA_TARGET_FORM_SIMPLE_FORM_OPEN_SPACE_DESCRIPTION_C',
      ]),
    },
    { key: TEST_FIT_SIMPLE_FORM.OFFICE_TYPES.OTHER,
      value: 'AREA_TARGET_FORM_SIMPLE_FORM_OTHER',
      description: renderDescriptionBullets([
        'AREA_TARGET_FORM_SIMPLE_FORM_OTHER_DESCRIPTION_A',
      ]),
    },
  ], []);

  const setFormWithSelectedTemplate = (type) => {
    if (!type) {
      return;
    }
    // we dont want to reset this form data so we save the data before we run 'resetFields()'
    const totalArea = formData[AREA_TARGET_FORM.TOTAL_AREA_FIELD];
    const employeesNumber = formData[AREA_TARGET_FORM.NUMBER_OF_EMPLOYEES];
    const totalLsfAverageField = formData[AREA_TARGET_FORM.TOTAL_LSF_AVERAGE_FIELD];
    const template = type === TEST_FIT_SIMPLE_FORM.OFFICE_TYPES.OTHER
      ? {}
      : profileTemplates[`${type}_${TEST_FIT_SIMPLE_FORM.SPACE_EFFICIENCY.BASIC}`];
    const currentFormData = {
      ...template,
      [AREA_TARGET_FORM.TYPE]: type,
      [AREA_TARGET_FORM.TOTAL_AREA_FIELD]: totalArea || 25000,
      [AREA_TARGET_FORM.NUMBER_OF_EMPLOYEES]: employeesNumber || 0,
      [AREA_TARGET_FORM.TOTAL_LSF_AVERAGE_FIELD]: totalLsfAverageField || 0,
    };

    resetFields();
    setFieldsValue(currentFormData);
    handleOnChange();

    if (isJustCreated) {
      setTimeout(() => {
        resetFields();
        setFieldsValue(currentFormData);
        handleOnChange();
      }, 1000);
    }
  };

  const showWarningModal = (type) => {
    Modal.confirm({
      title: 'Resetting form data',
      content: 'Changing office types will reset form data',
      onOk: () => setFormWithSelectedTemplate(type),
    });
  };

  return (
    <AreaTargetFormOfficeTypeWrapper>
      <FormItem
        name={AREA_TARGET_FORM.TYPE}
        disabled={disabled}
        noStyle
      />
      <RadioText
        size={45}
        selected={formData[AREA_TARGET_FORM.TYPE]}
        disabled={disabled}
        data={typeArray}
        onChange={(type) => showWarningModal(type)}
      />
    </AreaTargetFormOfficeTypeWrapper>
  );
};

AreaTargetFormOfficeType.propTypes = {
  disabled: PropTypes.bool,
  formData: PropTypes.object,
  form: PropTypes.object,
  handleOnChange: PropTypes.func,
  isJustCreated: PropTypes.bool,
};

export default React.memo(AreaTargetFormOfficeType);
