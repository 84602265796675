import lodashGet from 'lodash/get';
import { shallowEqual, useSelector } from 'react-redux';
import { testFitModel } from 'utils/model/testFitModel';
import { feasibilityReportModel } from 'utils/model/feasibilityReportModel';
import { PROJECT_STAGES } from 'constants/profileConsts';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import { currentThemeSelector, overridableSettingSelector } from 'store/userSettings';

export const siteSelector = (state) => {
  if (!state) {
    return;
  }

  const { swappProfile } = state;
  return lodashGet(swappProfile, 'swappProject.site');
};

const getProfileById = (state, id) => {
  if (!id || !state) {
    return;
  }

  const { swappProfile } = state;

  if (lodashGet(swappProfile, 'swappProject.projectProfiles')) {
    const profileIndex = swappProfile.swappProject.projectProfiles.findIndex((profile) => profile.id === Number(id));
    return lodashGet(swappProfile, `swappProject.projectProfiles[${profileIndex}]`);
  }
};

const getProfileByIdB = (state, id) => { // TODO -  Quick fix, need to get tested and marge with getProfileById
  if (!id || !state) {
    return;
  }

  const { swappProject } = state;

  if (lodashGet(swappProject, 'projectProfiles')) {
    const profileIndex = swappProject.projectProfiles.findIndex((profile) => profile.id === Number(id));
    return lodashGet(swappProject, `projectProfiles[${profileIndex}]`);
  }
};

export const swappProjectSelector = ({ swappProfile }) => swappProfile.swappProject;
export const swappParentProfileSelector = ({ swappProfile }) => lodashGet(swappProfile, 'swappProject.projectProfiles').find((prof) => !prof.parentProfileId);

export const getProfileByIdSelector = (state, profileId) => getProfileById(state, profileId);

export const feasibilityReportSelector = (state, profileId, isImperial) => {
  const profile = getProfileById(state, profileId);
  const site = siteSelector(state);
  return feasibilityReportModel(profile, site, isImperial);
};

export const swappProfileProfileData = ({ swappProfile }, profileId) => {
  const profile = getProfileByIdB(swappProfile, profileId);
  if (profile) {
    return profile.profileData;
  }
};

export const getTestFitCustomFurniture = ({ swappProfile }, profileId) => {
  if (lodashGet(swappProfile, 'swappProject.projectProfiles')) {
    const profileIndex = swappProfile.swappProject.projectProfiles.findIndex((profile) => Number(profile.id) === Number(profileId));
    return lodashGet(swappProfile, `swappProject.projectProfiles[${profileIndex}].result.multiBoundaryFile.custom_furniture`, {});
  }
  return {};
};

// TODO: Do we want to add a projectId to know when to clear this cache dictionary?
const lastTestFitProfileInputs = {};
export const lastTestFitResults = {};

export const swappProfileResultsSelector = (state, profileId, isImperial) => {
  const { swappProfile, editor } = state;
  if (lodashGet(swappProfile, 'swappProject.projectProfiles')) {
    const currentTheme = currentThemeSelector(state);
    const testFitStandard = overridableSettingSelector(state, 'testFitStandard', 'DEFAULT');
    const profileIndex = swappProfile.swappProject.projectProfiles.findIndex((profile) => Number(profile.id) === Number(profileId));
    const profile = lodashGet(swappProfile, `swappProject.projectProfiles[${profileIndex}]`);
    const profileResult = (Number(editor.profileId) === Number(profileId)) ? editor?.result : profile?.result;
    if (profile) {
      switch (profile.stage) {
        case PROJECT_STAGES[UI_AUTHORIZE_PATH.TEST_FIT]:
          const profileInputs = [profileResult, isImperial];
          const cachedInput = lastTestFitProfileInputs[profileId];
          // we check if the profileResult is the same as the old one so we wont rerender test-fit
          if (!cachedInput || !shallowEqual(profileInputs, cachedInput)) {
            lastTestFitProfileInputs[profileId] = profileInputs;
            lastTestFitResults[profileId] = testFitModel(profileResult, isImperial, true, currentTheme, testFitStandard);
          }

          return lastTestFitResults[profileId];
        case PROJECT_STAGES[UI_AUTHORIZE_PATH.FEASIBILITY_STUDY]:
          return profileResult;
        default:
          return profileResult;
      }
    }
  }
};

export const loadingSelector = ({ swappProfile }) => swappProfile.loading;
export const activePollingProfileIdsSelector = ({ swappProfile }) => swappProfile.activePollingProfileIds;
export const shareableKeySelector = ({ swappProfile }) => swappProfile.shareableKey;

export const useProfileResult = (profile) => useSelector((state) => (state.editor.profileId === lodashGet(profile, 'id', -1) ? state.editor.result : lodashGet(profile, 'result')));
