import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import lodashSum from 'lodash/sum';
import lodashValues from 'lodash/values';
import { Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { StyledFormItem, StyledReportTable, SmallStyledTable } from 'styles/commonComponents.styles';
import { DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE, DEVELOPMENT_APPRAISAL_FORM } from 'constants/feasibilityReportConts';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { handleOnChange } from 'utils/helpers/feasibilityReportHelpers';
import { getProfileByIdSelector, loadingSelector } from 'store/swappProfile';
import { ALL_APARTMENT_TYPES_ARRAY } from 'constants/feasibilityConts';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { sqmToSqf } from 'utils/helpers/unitsHelpers';
import {
  TableBoxWrapper,
  BulletRowWrapper,
  Bullet,
  StyledText,
} from '../report/ReportPages.styles';
import CurrencyInput from '../../../../common/CurrencyInput';

const DevelopmentAppraisalBaseDataTable = (props) => {
  const { data, currentSelectedProfileId, formInstance, disabled } = props;
  const dispatch = useDispatch();
  const profile = useSelector((state) => getProfileByIdSelector(state, currentSelectedProfileId));
  const isLoading = useSelector(loadingSelector);
  const baseDataTable = formInstance && formInstance.getFieldValue(DEVELOPMENT_APPRAISAL_FORM.BASE_DATA_TABLE);
  const match = useRouteMatch();
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));
  const initialValues = {};
  // eslint-disable-next-line no-return-assign
  ALL_APARTMENT_TYPES_ARRAY.forEach((item) => initialValues[item.key] = item.unitCost);
  const [dataSource, setDataSource] = useState([]);

  // update table when state changes
  useEffect(() => {
    updateDataSource(setDataSource);
  }, [formInstance, baseDataTable, currentSelectedProfileId]);

  // updates the table and merges all of its parts
  const margeDataToTable = () => {
    if (!formInstance) {
      return [];
    }
    const modelData = lodashGet(data, 'baseDataTable', []);
    const inputs = {};
    const unitValues = {};

    const getAverage = (objectData) => lodashSum(lodashValues(objectData).filter((e) => e)) / lodashValues(objectData).filter((e) => e).length;

    // eslint-disable-next-line no-return-assign
    modelData.forEach((unit) => {
      const inputValue = lodashGet(baseDataTable, `[${unit[DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.KEY]}]`);

      inputs[unit[DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.KEY]] = inputValue;
      unitValues[unit[DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.KEY]] = inputValue * unit[DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.APARTMENT_AREA];
    });

    return modelData.map((unit) => {
      if (unit[DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.KEY] === 'TOTAL') {
        return {
          ...unit,
          [DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.SCALE]: getAverage(inputs),
          [DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.UNIT_VALUE]: getAverage(unitValues),
        };
      }

      return {
        ...unit,
        [DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.SCALE]: inputs[unit[DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.KEY]],
        [DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.UNIT_VALUE]: unitValues[unit[DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.KEY]],
      };
    });
  };

  const updateDataSource = (update) => {
    update(margeDataToTable());
  };

  const handleChange = () => {
    if (disabled) {
      return;
    }

    handleOnChange(dispatch, { formInstance, profile, isLoading, disabled });
    updateDataSource(setDataSource);
  };

  const columns = [
    {
      title: T.translate('Apt. Type'),
      dataIndex: DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.KEY,
      key: DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.KEY,
      width: 120,
      render: (value) => (value === 'TOTAL' ? T.translate('Total / Average') : T.translate(value)),
    },
    {
      title: T.translate('Total Units'),
      dataIndex: DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.NUM_OF_UNITS,
      key: DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.NUM_OF_UNITS,
      align: 'center',
      width: 70,
    },
    {
      title: `${T.translate('Apt.')} ${T.translate(isImperial ? 'SQF' : 'SQM')}`,
      dataIndex: DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.APARTMENT_AREA,
      key: DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.APARTMENT_AREA,
      align: 'center',
      width: 70,
      render: (value) => value && numberWithComma(isImperial ? sqmToSqf(value) : value, { fixed: isImperial ? 0 : 1 }),
    },
    {
      title: T.translate(isImperial ? 'SQF' : 'SQM'),
      dataIndex: DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.AREA,
      key: DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.AREA,
      align: 'center',
      width: 70,
      render: (value) => numberWithComma(isImperial ? sqmToSqf(value) : value, { fixed: isImperial ? 0 : 1 }),
    },
    {
      title: T.translate(`Sale per ${T.translate(isImperial ? 'SQF' : 'SQM')}`),
      dataIndex: DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.SCALE,
      key: DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.SCALE,
      align: 'center',
      width: 70,
      render: (target, rowData) => (
        <Tooltip title={rowData.key === 'TOTAL' ? T.translate('Average') : ''}>
          <CurrencyInput
            readOnly={disabled || rowData.key === 'TOTAL'}
            min={1}
            width={80}
            height={25}
            value={target}
            onChange={(e) => handleScaleChange(e, rowData[DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.KEY])}
          />
        </Tooltip>
      ),
    },
    {
      title: T.translate('Unit Value'),
      dataIndex: DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.UNIT_VALUE,
      key: DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.UNIT_VALUE,
      align: 'center',
      width: 70,
      render: (value, rowData) => (
        <Tooltip title={rowData.key === 'TOTAL' ? T.translate('Average') : ''}>
          <CurrencyInput value={value} readOnly />
        </Tooltip>
      ),
    },
  ];

  const handleScaleChange = (e, unitKey) => {
    const currentInput = formInstance.getFieldValue(DEVELOPMENT_APPRAISAL_FORM.BASE_DATA_TABLE) || {};
    formInstance.setFieldsValue({
      [DEVELOPMENT_APPRAISAL_FORM.BASE_DATA_TABLE]:
          {
            ...currentInput,
            [unitKey]: e,
          },
    });
    handleChange();
  };

  const getNumberByUnit = (number) => (numberWithComma(isImperial ? sqmToSqf(number) : number, { suffix: ` ${T.translate(isImperial ? 'SQF' : 'SQM')}`, fixed: isImperial ? 0 : 1 }));

  return (
    <TableBoxWrapper margin="10px 3px">
      <StyledReportTable
        pagination={false}
        columns={columns}
        dataSource={dataSource.filter((unit) => unit.key !== 'TOTAL')}
      />
      <SmallStyledTable pagination={false} columns={columns} showHeader={false} bold dataSource={dataSource.filter((unit) => unit.key === 'TOTAL')} />

      <BulletRowWrapper>
        <Bullet>
          <StyledText align="center" bold>{T.translate('Lot Area')}</StyledText>
          <StyledText align="center">{getNumberByUnit(lodashGet(data, 'lotArea'))}</StyledText>
        </Bullet>
        <Bullet>
          <StyledText align="center" bold>{T.translate('GIA')}</StyledText>
          <StyledText align="center">{getNumberByUnit(lodashGet(data, 'GIA'))}</StyledText>
        </Bullet>
        <Bullet>
          <StyledText align="center" bold>{T.translate('BCR')}</StyledText>
          <StyledText align="center">{getNumberByUnit(lodashGet(data, 'BCR'))}</StyledText>
        </Bullet>
        <Bullet>
          <StyledText align="center" bold>{T.translate('No. of Stories')}</StyledText>
          <StyledText align="center">{numberWithComma(lodashGet(data, 'numberOfStories'), { fixed: 0 })}</StyledText>
        </Bullet>
        <Bullet>
          <StyledText align="center" bold>{T.translate('No. of Masses')}</StyledText>
          <StyledText align="center">{numberWithComma(lodashGet(data, 'numberOfMasses'), { fixed: 0 })}</StyledText>
        </Bullet>
      </BulletRowWrapper>
      <StyledFormItem hidden noStyle name={DEVELOPMENT_APPRAISAL_FORM.BASE_DATA_TABLE} initialValue={initialValues} />
    </TableBoxWrapper>
  );
};

DevelopmentAppraisalBaseDataTable.propTypes = {
  data: PropTypes.object,
  formInstance: PropTypes.object,
  currentSelectedProfileId: PropTypes.number,
  disabled: PropTypes.bool,
};

export default DevelopmentAppraisalBaseDataTable;
