import React from 'react';
import PropTypes from 'prop-types';
import BarChart from 'components/common/barChart';
// import PieChart from 'components/common/PieChart';

const EnergyDistributionCharts = (props) => {
  const categories = ['Cooling', 'Heating', 'Lighting', 'Equipment', 'Pump', 'Fans', 'Hot Water'];
  const { data } = props;

  if (!data || data.length < 1) { return null; }

  return (
    <div>
      <BarChart data={data} width="410" categories={categories} yTitle="kWh/m2/yr" />
      {/* <PieChart data={data[0].data} width="410" labels={categories} /> */}
    </div>
  );
};

EnergyDistributionCharts.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default EnergyDistributionCharts;
