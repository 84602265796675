export const filledSymbol = {
  type: 'simple-fill',
  color: [0, 0, 255, 0.2],
  outline: {
    color: [16, 165, 245],
    width: 1,
  },
};

export const clickedSymbol = {
  type: 'simple-fill',
  // style: 'cross',
  color: [125, 165, 245, 0.6],
  outline: {
    color: [128, 128, 128],
    width: 1,
  },
};

export const blockBoundarySymbol = {
  type: 'simple-fill',
  color: [0, 0, 255, 0.0],
  outline: {
    color: [128, 128, 128],
    width: 1,
  },
};

export const blockFillSymbol = {
  type: 'simple-fill',
  color: [0, 0, 255, 0.1],
  outline: {
    color: [255, 25, 25],
    width: 1,
  },
};

export const lotBoundarySymbol = {
  type: 'simple-fill',
  color: [0, 0, 255, 0.0],
  outline: {
    color: [125, 165, 245],
    width: 1,
  },
};
