import React from 'react';

// currentcolor
export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.54416 21.886H1.62002L7.67563 20.8194C7.7143 20.8194 7.7143 20.8194 7.75149 20.7807H7.79017C7.82884 20.7421 7.90471 20.7049 7.9419 20.6662L22.7161 5.89201C22.9065 5.70161 22.9065 5.35949 22.7161 5.16908L17.7283 0.142803C17.5379 -0.047601 17.1957 -0.047601 17.0053 0.142803L14.9496 2.19856L2.22986 14.9168C2.19118 14.9554 2.15399 15.0313 2.11532 15.0685V15.1072C2.11532 15.1459 2.11532 15.1459 2.07664 15.183L1.01008 21.2371C0.971402 21.3889 1.04875 21.5793 1.16181 21.6938C1.27783 21.8098 1.39237 21.8857 1.5441 21.8857L1.54416 21.886ZM2.91568 16.3643L6.53335 19.9819L2.15406 20.7436L2.91568 16.3643ZM15.3308 3.30219L17.1203 5.09168L5.08598 17.0886L3.29648 15.2992L15.3308 3.30219ZM7.59999 19.5638L5.8105 17.8115L17.8059 5.81605L19.5954 7.60555L7.59999 19.5638ZM17.3479 1.24621L21.6514 5.54972L20.3186 6.88254L16.0539 2.57904L17.3479 1.24621Z" fill="currentcolor" />
    <path d="M12.778 22.6107H1.583C1.31673 22.6107 1.08765 22.8397 1.08765 23.106C1.08765 23.3723 1.31673 23.6014 1.583 23.6014H12.741C13.0073 23.6014 13.2364 23.3723 13.2364 23.106C13.2736 22.8383 13.046 22.6107 12.7783 22.6107H12.778Z" fill="currentcolor" />
    <path d="M23.0229 22.6107H15.444C15.1778 22.6107 14.9487 22.8397 14.9487 23.106C14.9487 23.3723 15.1777 23.6014 15.444 23.6014H23.0213C23.2876 23.6014 23.5167 23.3723 23.5167 23.106C23.5182 22.8383 23.2891 22.6107 23.0228 22.6107H23.0229Z" fill="currentcolor" />
  </svg>
);
