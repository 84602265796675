import React, { useMemo } from 'react';
import T from 'i18n-react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getUnitSystemByProjectIdSelector } from '../../../store/userSettings';
import { parseLocationUrl } from '../../../utils/helpers/navigationHelpers';
import { sqfToSqm } from '../../../utils/helpers/unitsHelpers';
import { numberWithComma } from '../../../utils/helpers/dataDisplay';
import { feasibilitySwpProjectModel } from '../../../utils/model/feasibilitySwpProjectModel';
import {
  FeasibilityResultCardWrapper, DataCellWrapper, Splitter, DataCell,
  DataCellItem, DataRow, DataRowItem, StyledProgress, InfoTotalWrapper, InfoWrapper,
} from './FeasibilityResultCard.styles';

const FeasibilitySwpProjectResultCard = (props) => {
  const { profile } = props;

  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const data = useMemo(() => feasibilitySwpProjectModel(profile.result.enrichedSwpProject, isImperial), [profile]);

  const areaOptions = useMemo(() => ({ fixed: isImperial ? 0 : 1, suffix: ` ${T.translate(isImperial ? 'SQF' : 'SQM')}` }), [isImperial]);
  const getImperialArea = (area) => (isImperial ? area : sqfToSqm(area));

  const renderUnits = (unit, isTotal) => (
    <DataRow key={unit.key}>
      <DataRowItem width={100} bold={isTotal}>{T.translate(unit.value)}</DataRowItem>
      <DataRowItem width={75} bold>{unit.unitAmount}</DataRowItem>
      <DataRowItem width={45}>
        <StyledProgress progressWidth={45} type="line" percent={Number(`${unit.percentage ? unit.percentage : 100}`.split('%')[0])} showInfo={false} />
      </DataRowItem>
      <DataRowItem width={50} align="right">{numberWithComma(unit.percentage, { fixed: 1, suffix: '%' })}</DataRowItem>
    </DataRow>
  );

  return (
    <FeasibilityResultCardWrapper>
      {/* =============== info Bar =============== */}
      <DataCellWrapper>
        {/* ======== Primary Area ======== */}
        <DataCell>
          <DataCellItem bold>{T.translate('Primary Area')}</DataCellItem>
          <DataCellItem>{numberWithComma(getImperialArea(data.storiesArea), areaOptions)}</DataCellItem>
        </DataCell>

        {/* ======== Stories ======== */}
        <DataCell>
          <DataCellItem bold>{T.translate('Stories')}</DataCellItem>
          <DataCellItem>{data.storiesAmount.join(' | ')}</DataCellItem>
        </DataCell>

        {/* ======== Core ======== */}
        <DataCell>
          <DataCellItem bold>{T.translate('Core')}</DataCellItem>
          <DataCellItem>{numberWithComma(getImperialArea(data.coresArea), areaOptions)}</DataCellItem>
        </DataCell>
      </DataCellWrapper>

      <Splitter />

      {/* =============== units =============== */}
      <InfoWrapper>
        {data.unitData.map((unit) => renderUnits(unit))}
      </InfoWrapper>

      {/* =============== Total =============== */}
      <InfoTotalWrapper>{renderUnits(data.unitDataTotal, true)}</InfoTotalWrapper>
    </FeasibilityResultCardWrapper>
  );
};

FeasibilitySwpProjectResultCard.propTypes = {
  profile: PropTypes.object,
};

export default FeasibilitySwpProjectResultCard;
