import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MarkupsButton from './MarkupsButton';

const MarkupsMultiButtonWrapper = styled.div`
  position: relative;
  height: 60px;
`;

const ButtonsBarWrapper = styled.div`
  position: absolute;
  right: 60px;
  top: -3px;
  display: flex;
  flex-direction: row-reverse;
  border-radius: ${({ theme }) => theme.other.borderRadius};
  background-color: ${({ theme }) => theme.colors.gray_01};
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.12);
  max-width: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-width .4s ease-in-out;

  ${MarkupsMultiButtonWrapper}:hover & {
    max-width: 700px;
    opacity: 1;
  }
`;

const ArrowLeft = styled.div`
  position: absolute;
  top: 7px;
  left: 3px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;

  border-right: 5px solid ${({ theme }) => theme.colors.gray_06};
`;

const MarkupsMultiButton = (props) => {
  const { name, children, color, icon } = props;

  return (
    <MarkupsMultiButtonWrapper>
      <ArrowLeft />
      <MarkupsButton name={name} icon={icon} color={color} />
      <ButtonsBarWrapper>
        {children}
      </ButtonsBarWrapper>
    </MarkupsMultiButtonWrapper>
  );
};

MarkupsMultiButton.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
};

export default MarkupsMultiButton;
