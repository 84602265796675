import moreIcons from 'styles/static/icons/viewerIcons';
import icons from 'styles/static/icons/markups';

export const MARKUPS_TOOLS_NAME = {
  ARROW: 'ARROW',
  CALLOUT: 'CALLOUT',
  TEXT: 'TEXT',
  CIRCLE: 'CIRCLE',
  CLOUD: 'CLOUD',
  FREEHAND: 'FREEHAND',
  HIGHLIGHT: 'HIGHLIGHT',
  POLYCLOUD: 'POLYCLOUD',
  POLYLINE: 'POLYLINE',
  RECTANGLE: 'RECTANGLE',
  SCOPE_BOX: 'SCOPE_BOX',
  SECTION: 'SECTION_LINE',
};

export const MARKUPS_TOOLS_ICONS = {
  [MARKUPS_TOOLS_NAME.ARROW]: icons.arrowIcon,
  [MARKUPS_TOOLS_NAME.CALLOUT]: icons.calloutIcon,
  [MARKUPS_TOOLS_NAME.TEXT]: icons.calloutIcon,
  [MARKUPS_TOOLS_NAME.CIRCLE]: icons.circleIcon,
  [MARKUPS_TOOLS_NAME.CLOUD]: icons.cloudIcon,
  [MARKUPS_TOOLS_NAME.FREEHAND]: icons.freehandIcon,
  [MARKUPS_TOOLS_NAME.HIGHLIGHT]: icons.highlightIcon,
  [MARKUPS_TOOLS_NAME.POLYCLOUD]: icons.polycloudIcon,
  [MARKUPS_TOOLS_NAME.POLYLINE]: icons.polylineIcon,
  [MARKUPS_TOOLS_NAME.RECTANGLE]: icons.rectangleIcon,
};

export const MAIN_TOOLS = [MARKUPS_TOOLS_NAME.ARROW, MARKUPS_TOOLS_NAME.CLOUD, MARKUPS_TOOLS_NAME.TEXT];
export const MAIN_TOOLS_OLD = [MARKUPS_TOOLS_NAME.ARROW, MARKUPS_TOOLS_NAME.CLOUD, MARKUPS_TOOLS_NAME.CALLOUT];
export const SECONDARY_TOOLS = [MARKUPS_TOOLS_NAME.POLYLINE, MARKUPS_TOOLS_NAME.POLYCLOUD, MARKUPS_TOOLS_NAME.RECTANGLE, MARKUPS_TOOLS_NAME.CIRCLE, MARKUPS_TOOLS_NAME.FREEHAND, MARKUPS_TOOLS_NAME.HIGHLIGHT];

export const MARKUPS_ATTRIBUTES_NAME = {
  WIDTH: 'stroke-width',
  COLOR: 'stroke-color',
  FONT_SIZE: 'font-size',
  FONT_FAMILY: 'font-family',
  FONT_WEIGHT: 'font-weight',
};

export const MARKUPS_COLORS = {
  RED: '#FF6969',
  BLUE: '#5772FF',
  GREEN: '#41ECC3',
  YELLOW: '#FFE34F',
};

export const MARKUPS_WIDTH = {
  THIN: 0.1,
  NORMAL: 0.3,
  THICK: 0.5,
};

export const MARKUPS_WIDTH_ICON = {
  [MARKUPS_WIDTH.THIN]: icons.thinIcon,
  [MARKUPS_WIDTH.NORMAL]: icons.normalIcon,
  [MARKUPS_WIDTH.THICK]: icons.thickIcon,
};

export const SCOPE_BOXES = [ // delete me, mock data
  {
    id: 'SCOPE_BOX_1',
    name: 'VIEWS_UI_SCOPE_BOX_SIZE_1',
    icon: moreIcons.scopeBoxIcon,
    dimensions: [10, 10],
  },
  {
    id: 'SCOPE_BOX_2',
    name: 'VIEWS_UI_SCOPE_BOX_SIZE_2',
    icon: moreIcons.scopeBoxIcon,
    dimensions: [20, 10],
  },
  {
    id: 'SCOPE_BOX_3',
    name: 'VIEWS_UI_SCOPE_BOX_SIZE_3',
    icon: moreIcons.scopeBoxIcon,
    dimensions: [30, 10],
  },
];
