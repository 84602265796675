import React from 'react';
import PropTypes from 'prop-types';
import { Plane, Text } from '@react-three/drei';
import lodashLast from 'lodash/last';
import lodashGet from 'lodash/get';
import { useSelector } from 'react-redux';
import { getUnitSystemByProjectIdSelector } from '../../../../store/userSettings';
import { parseLocationUrl } from '../../../helpers/navigationHelpers';
import { feetToMeters } from '../../../helpers/unitsHelpers';

const scale = 12; // dont change!
const rowHeight = 1;
const borderWidth = 0.05;
const numberOfRows = 2;
const darkColor = '#151515';
const lightColor = '#ffffff';
const data = {
  metric: [
    { value: 0, divideAmount: 0 },
    { value: 1, divideAmount: 2 },
    { value: 2, divideAmount: 1 },
    { value: 4, divideAmount: 1 },
    { value: 6, divideAmount: 1 },
  ],
  imperial: [
    { value: 0, divideAmount: 0 },
    { value: 5, divideAmount: 5 },
    { value: 10, divideAmount: 1 },
    { value: 15, divideAmount: 1 },
    { value: 20, divideAmount: 1 },
  ],
};

const isOdd = (number) => number % 2;

const FloorScale = (props) => {
  const { position } = props;
  const locationData = parseLocationUrl(window.location);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const getUnitSing = () => (isImperial ? '\'' : 'm');
  const marks = isImperial ? data.imperial : [...data.metric].map((mark) => ({ ...mark, value: mark.value * feetToMeters }));

  const renderRow = (rowIndex) => {
    let boxIndex = rowIndex;
    let lastPosition = 0;
    const boxes = [];

    marks.forEach((mark, index) => {
      if (!mark.divideAmount) {
        return;
      }

      for (let i = 1; i <= mark.divideAmount; i++) {
        const width = (marks[index].value - lodashGet(marks, `[${index - 1}].value`, 0)) / mark.divideAmount;

        boxes.push(
          <Plane key={boxIndex} args={[width, rowHeight]} position={[lastPosition + (width / 2), rowIndex, 0]}>
            <meshBasicMaterial attach="material" color={isOdd(boxIndex) ? darkColor : lightColor} />
          </Plane>,
        );
        boxIndex++;
        lastPosition += width;
      }
    });
    return boxes;
  };

  const renderText = () => marks.map((mark) => (
    <Text key={mark.value} textAlign="center" position={[mark.value, -(rowHeight + borderWidth), 0]} fontSize={1} color={darkColor}>
      {`${isImperial ? mark.value : mark.value / feetToMeters}${mark.value ? getUnitSing() : ''}`}
    </Text>
  ));

  const renderBorder = () => (
    <Plane args={[lodashLast(marks).value + borderWidth, (rowHeight * numberOfRows) + borderWidth]} position={[lodashLast(marks).value / 2, rowHeight / 2, -1]}>
      <meshBasicMaterial attach="material" color={darkColor} />
    </Plane>
  );

  const renderRows = () => {
    const rows = [];
    for (let i = 0; i < numberOfRows; i++) {
      rows.push(renderRow(i));
    }

    return rows;
  };

  return (
    <group position={[position[0] - (lodashLast(marks).value * scale), position[1] - 40, 250]} name="FloorScale" scale={[scale, scale, 1]}>
      {renderBorder()}
      {renderRows()}
      {renderText()}
    </group>
  );
};

FloorScale.propTypes = {
  position: PropTypes.array,
};

export default FloorScale;
