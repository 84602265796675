"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Type = exports.getTypeByProperty = exports.SwpClassMap = exports.registerClass = void 0;
require("reflect-metadata");
const TYPE = 'type';
function registerClass(swpClass, name) {
    exports.SwpClassMap[name || swpClass.name] = swpClass; // Right now not in use.
}
exports.registerClass = registerClass;
exports.SwpClassMap = {};
function getTypeByProperty(classInstance, propertyType) {
    const classFunction = Reflect.getMetadata(TYPE, classInstance, propertyType);
    if (isFunction(classFunction)) {
        return { arrayOf: classFunction };
    }
    return classFunction;
}
exports.getTypeByProperty = getTypeByProperty;
function isFunction(func) {
    return typeof func === 'function'
        && !/^class\s/.test(Function.prototype.toString.call(func)) && func.name != 'Array';
}
function Type(value) {
    return function (target, propertyKey, descriptor) {
        Reflect.defineMetadata(TYPE, value || 'string', target, propertyKey);
    };
}
exports.Type = Type;
