import styled from 'styled-components';

export const EditorRoomsPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  line-height: normal;
`;

export const GridTileWrapper = styled.div`
  width: 100%;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
`;

export const IconsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray_03};
  margin-bottom: 7px;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 80px;
  justify-content: flex-end;
`;

export const IconText = styled.div`
  color: ${({ theme }) => theme.colors.textColor};
  // font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  text-align: center;
`;

export const PanelContentWrapper = styled.div`
  height: auto;
  //overflow-x: hidden;
  //overflow-y: auto;
  //padding: 10px;
`;

export const ColorPill = styled.div`
  height: 20px;
  width: 5px;
  border-radius: ${({ theme }) => theme.other.borderRadius};
  background-color: ${({ color }) => color};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 0)};
  margin-right: 10px;
  margin-left: 10px;
`;

export const StyledNameWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledName = styled.div`
  max-width: 62px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${({ theme, bold }) => (bold && theme.font.weight.bold)};
`;

export const MenuRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 38px;
  font-size: ${({ theme }) => theme.font.size.medium};
  background-color: ${({ theme, isTitle, disabled, selected }) => {
    if (isTitle) {
      return 'transparent';
    }

    if (selected) {
      return `${theme.colors.primaryColor}22`;
    }

    return `${disabled ? theme.colors.gray_06 : theme.colors.gray_04}60`;
  }};
  padding-top: ${({ isTitle }) => (isTitle ? '5px' : '3px')};
  color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_06 : theme.colors.textColor)};
  line-height: 1.6;
  margin: 5px;
  border-radius: ${({ theme, isTitle }) => (isTitle ? 0 : theme.other.borderRadius)};
  border: 1px solid ${({ theme, selected }) => (selected ? theme.colors.success : 'transparent')};

  &:hover {
    background-color: ${({ theme, isTitle }) => (isTitle ? 'unset' : `${theme.colors.primaryColor}22`)};
    cursor: ${({ isTitle, disabled }) => (isTitle || !disabled ? 'pointer' : 'default')};
  }
`;

export const MenuItem = styled.div`
  width: ${({ width }) => (width ? `${width}px` : '40px')};
  display: flex;
  text-align: right;
  justify-content: flex-end;
  font-weight: ${({ theme, bold }) => (bold && theme.font.weight.bold)};

  &:first-child {
    text-align: left;
  }
`;
