/* eslint-disable no-restricted-globals */
import React, { useState, useMemo } from 'react';
import T from 'i18n-react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import lodashSortBy from 'lodash/sortBy';
import lodashLast from 'lodash/last';
import lodashIsFinite from 'lodash/isFinite';
import { StyledExploreTable, StyledFormItem, StyledSlider, StyledInputNumber } from 'styles/commonComponents.styles';
import { StyledText } from 'components/feasibility/studies/result/report/ReportPages.styles';
import CurrencyInput from 'components/common/CurrencyInput';
import { BUILDING_INFO_KEYS } from 'constants/feasibilityConts';
import { getRoofArea } from 'utils/helpers/feasibilityHelpers';
import { sqmToSqf } from 'utils/helpers/unitsHelpers';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { irrCalc } from 'utils/algorithms/economicFormulas';
import { useActiveProfileBuildingInfo } from 'utils/model/feasibilityResultModel';

import { Title, InputContainer, InputWrapper, InputTitle, InputTypeText } from './StyledComponents';

const pvRows = [
  { name: 'FEASIBILITY_RESULT_ENERGY_ANALYSIS_TILT', key: 'tilt' },
  { name: 'FEASIBILITY_RESULT_ENERGY_ANALYSIS_SIZE_OF_THE_SYSTEM', key: 'sizeOfTheSystem' },
  { name: 'FEASIBILITY_RESULT_ENERGY_ANALYSIS_ANNUAL_ENERGY_PRODUCTION', key: 'annualEnergyProduction' },
  { name: 'FEASIBILITY_RESULT_ENERGY_ANALYSIS_RENEWABLE_ENERGY', key: 'renewableEnergy' },
  { name: 'FEASIBILITY_RESULT_ENERGY_ANALYSIS_INITIAL_INVESTMENT', key: 'initialInvestment' },
  { name: 'FEASIBILITY_RESULT_ENERGY_ANALYSIS_RECOUP', key: 'recoup', style: 'ant-table-total-row' },
  { name: 'FEASIBILITY_RESULT_ENERGY_ANALYSIS_IRR', key: 'irr', style: 'ant-table-total-row' },
];

const pvColumns = [
  {
    title: '',
    dataIndex: 'NAME',
    key: 'NAME',
    align: 'left',
    width: 70,
    render: (value, rowData) => <StyledText size="small" total={rowData.style === 'ant-table-total-row'}>{T.translate(value)}</StyledText>,
  },
  {
    title: '',
    dataIndex: 'VALUE',
    key: 'VALUE',
    align: 'right',
    width: 40,
    render: (value, rowData) => <StyledText size="small" bold total={rowData.style === 'ant-table-total-row'}>{value}</StyledText>,
  },
];

const FeasibilityResultEnergyAnalysisPv = (props) => {
  const { profile, data, form, isImperial } = props;
  const [energyPvRoofCoverage, setEnergyPvRoofCoverage] = useState(50);
  const buildingInfo = useActiveProfileBuildingInfo();
  const roofArea = useMemo(() => getRoofArea(buildingInfo), [buildingInfo]);
  const roofAreaByEnergyPvRoofCoverage = roofArea * (energyPvRoofCoverage / 100);
  const pvData = lodashGet(profile, 'result.pvAnalysis');

  if (!pvData) {
    return null;
  }

  const pvTableData = pvRows.map((row) => {
    const sortedOptionsOptions = lodashSortBy(pvData.options, ['acAnnual']);
    const currentOption = lodashLast(sortedOptionsOptions); // the best option is last (has highest 'acAnnual')
    const amountOfPanels = Math.round(roofAreaByEnergyPvRoofCoverage / 2);
    const panelCapacity = form.getFieldValue(BUILDING_INFO_KEYS.ENERGY_PANEL_CAPACITY) || 0;
    const pvCost = form.getFieldValue(BUILDING_INFO_KEYS.ENERGY_PV_COST) || 0;
    const electricityCost = form.getFieldValue(BUILDING_INFO_KEYS.ENERGY_ELECTRIC_COST);
    const systemCapacity = (amountOfPanels * panelCapacity) / 1000;
    const initialInvestment = (systemCapacity * pvCost) * 1000;
    const annualEnergyProduction = (currentOption.acAnnual * systemCapacity) / currentOption.systemCapacity;
    const costSavings = annualEnergyProduction * electricityCost;
    const numberOfYears = 25;
    const annualEnergyUse = lodashGet(data, '[1].annualEnergyUseNumber', 1);
    // Irr values:
    const tmpArray = new Array(numberOfYears).fill(1);
    const irrValues = tmpArray.map((item, index) => -initialInvestment + index * costSavings);
    const irrVal = irrCalc(irrValues);

    let value = {};
    switch (row.key) {
      case 'tilt':
        value = ({
          VALUE: `${currentOption.tilt}°`,
        });
        break;
      case 'sizeOfTheSystem':
        value = ({
          VALUE: `${systemCapacity} kW`,
        });
        break;
      case 'annualEnergyProduction':
        value = ({
          VALUE: numberWithComma(annualEnergyProduction, { fixed: 0, suffix: ' kWh' }),
        });
        break;
      case 'renewableEnergy':
        value = ({
          VALUE: numberWithComma((annualEnergyProduction * 100) / annualEnergyUse, { fixed: 1, suffix: '%' }),
        });
        break;
      case 'initialInvestment':
        value = ({
          VALUE: <CurrencyInput value={initialInvestment} readOnly />,
        });
        break;
      case 'recoup':
        value = ({
          VALUE: numberWithComma(initialInvestment / costSavings, { fixed: 1, suffix: ' years' }),
        });
        break;
      case 'irr':
        value = ({
          VALUE: lodashIsFinite(irrVal) ? `${irrVal.toFixed(4)}%` : '',
        });
        break;
      default:
        break;
    }

    return ({
      NAME: row.name,
      style: row.style,
      ...value,
    });
  });

  return (
    <>
      <Title> {T.translate('FEASIBILITY_RESULT_ENERGY_ANALYSIS_PV_TITLE')}</Title>

      <InputContainer>
        {/* ================ PV Cost ================ */}
        <InputWrapper>
          <InputTitle>{T.translate('FEASIBILITY_RESULT_ENERGY_ANALYSIS_PV_COST')}</InputTitle>
          <StyledFormItem name={BUILDING_INFO_KEYS.ENERGY_PV_COST} width={100} initialValue={2.5} marginBottom={0.01}>
            <CurrencyInput
              min={0}
              height={22}
              step={0.1}
              decimals={1}
            />
          </StyledFormItem>
          <InputTypeText>{T.translate('FEASIBILITY_RESULT_ENERGY_ANALYSIS_ENERGY_UNIT_W')}</InputTypeText>
        </InputWrapper>

        {/* ================ Panel Capacity ================ */}
        <InputWrapper>
          <InputTitle>{T.translate('FEASIBILITY_RESULT_ENERGY_ANALYSIS_PANEL_CAPACITY')}</InputTitle>
          <StyledFormItem name={BUILDING_INFO_KEYS.ENERGY_PANEL_CAPACITY} width={100} initialValue={450} marginBottom={0.01}>
            <StyledInputNumber
              min={100}
              height={22}
              step={10}
            />
          </StyledFormItem>
          <InputTypeText>{T.translate('FEASIBILITY_RESULT_ENERGY_ANALYSIS_ENERGY_UNIT_W')}</InputTypeText>
        </InputWrapper>
      </InputContainer>

      {/* ================ PV Roof Coverage ================ */}
      <InputWrapper>
        <InputTitle>{T.translate('FEASIBILITY_RESULT_ENERGY_ANALYSIS_PV_ROOF_COVERAGE')}</InputTitle>
        <StyledFormItem name={BUILDING_INFO_KEYS.ENERGY_PV_ROOF_COVERAGE} width={200} initialValue={50} marginBottom={0.01}>
          <StyledSlider
            min={0}
            max={100}
            step={1}
            width={180}
            tooltipVisible={false}
            onChange={(e) => setEnergyPvRoofCoverage(e)}
          />
        </StyledFormItem>
        <InputTypeText>{`${energyPvRoofCoverage}% = ${numberWithComma(isImperial ? sqmToSqf(roofAreaByEnergyPvRoofCoverage) : roofAreaByEnergyPvRoofCoverage, { suffix: ` ${T.translate(isImperial ? 'SQF' : 'SQM')}`, fixed: 0 })}`}</InputTypeText>
      </InputWrapper>

      {/* ================ Table ================ */}
      <StyledExploreTable
        pagination={false}
        columns={pvColumns}
        dataSource={pvTableData}
        rowClassName={(row) => row.style}
      />
    </>
  );
};

FeasibilityResultEnergyAnalysisPv.propTypes = {
  profile: PropTypes.object,
  data: PropTypes.object,
  form: PropTypes.object,
  isImperial: PropTypes.bool,
};

export default FeasibilityResultEnergyAnalysisPv;
