import React, { useCallback, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import T from 'i18n-react';
import { Modal } from 'antd';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashGet from 'lodash/get';
import { useHistory } from 'react-router';
import { ROOM_TAGS, TEST_FIT_EDITOR_BUTTON_KEYS } from 'constants/testFitConsts';
import {
  PrimaryButton,
  UiButtonsWrapper,
  ViewerUiPanelWrapper,
  Overlay,
} from 'styles/commonComponents.styles';
import { addOperations, clearSelectedRowKeys, EDIT_TYPES } from 'store/editor';
import { currentThemeSelector, useOverridableSetting } from 'store/userSettings';
import TestFitEditPanelContainer from 'components/testFit/study/result/program/TestFitEditPanelContainer';
import LoadingSpinner from 'components/common/LoadingSpinner';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import EditPanelContainer from 'utils/swappViewer/components/ui/EditPanelContainer';
import * as SwappEditor from 'store/editor';
import icons from 'styles/static/icons/testFit/editor';
import ProfileDataPanel from 'components/testFit/study/result/program/ProfileDataPanel';
import { loadingSelector } from 'store/swappProfile';
import ViewerUiPanel from 'utils/swappViewer/components/ui/ViewerUiPanel';
import SwappViewerActionButton from 'utils/swappViewer/components/ui/SwappViewerActionButton';
import styled from 'styled-components';
import { useBeginFinishedEditContext } from 'utils/hooks/reactContexts';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import { useHotkeys } from 'react-hotkeys-hook';

const ExitModalContentWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;

const ProgramUi = forwardRef((props, ref) => {
  const { isHidden, onEditModeChanged, onPanelWidthAnimationEnded, hasTiles } = props;

  const locationData = parseLocationUrl(window.location);
  const isEditModeOn = useSelector(({ editor }) => editor.editSessionType === EDIT_TYPES.TEST_FIT);
  const selectedObject = useSelector(({ editor }) => editor.selectedObject);
  const selectedRowKeys = useSelector(({ editor }) => editor.selectedRowKeys);
  const selectedCategoryRowKeys = useSelector(({ editor }) => editor.selectedCategoryRowKeys);
  const selectedDepartmentRowKeys = useSelector(({ editor }) => editor.selectedDepartmentRowKeys);
  const operations = useSelector(({ editor }) => editor.operations);
  const isEditorLoading = useSelector(({ editor }) => editor.isLoading);
  const selectedItemsCount = useSelector(({ editor }) => editor.selectedHighlightedItems);
  const isProfileLoading = useSelector(loadingSelector);
  const currentTheme = useSelector(currentThemeSelector);
  const canUndo = operations.length >= 1;
  const dispatch = useDispatch();
  const history = useHistory();
  const isSelectedObjectHasLockTag = lodashGet(selectedObject, `tags[${ROOM_TAGS.LOCK}]`);
  const isSelectedObjectHasAdminLockTag = lodashGet(selectedObject, `tags[${ROOM_TAGS.ADMIN_LOCK}]`);
  const hideTiles = useOverridableSetting('hideTFTiles', false);

  const beginFinishedEditContext = useBeginFinishedEditContext();

  useEffect(() => {
    dispatch(clearSelectedRowKeys());
  }, [isEditModeOn]);

  const changeEditorType = useCallback((id) => {
    dispatch(SwappEditor.setEditorType(id));
  }, []);

  useHotkeys('shift+l', (e) => {
    e.preventDefault();
    addAdminLock();
  }, undefined, [selectedObject, isSelectedObjectHasAdminLockTag]);

  const addAdminLock = () => {
    if (selectedObject?.id) {
      console.log(isSelectedObjectHasAdminLockTag ? '🔓admin unlock' : '🔒admin lock');
      dispatch(SwappEditor.addOperations([{
        name: 'TEST_FIT_UPDATE_ROOMS_TAGS',
        parameters: { tag: ROOM_TAGS.ADMIN_LOCK, value: !isSelectedObjectHasAdminLockTag, room_ids: [selectedObject.id] },
      }]));
    }
  };

  const finishEditing = (isSAve) => {
    if (!canUndo) {
      onEditModeChanged(false);
      dispatch(SwappEditor.stopEditing());
      return;
    }

    const handleSaveButtonClicked = (createCopy) => {
      dispatch(SwappEditor.saveResult({ saveAsCopy: createCopy, history }));
      Modal.destroyAll();
    };

    Modal.confirm({
      title: T.translate('FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_TITLE'),
      content: (
        <>
          {T.translate('FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_CONTENT')}
          {isSAve && (
          <ExitModalContentWrapper>
            <PrimaryButton
              theme={currentTheme}
              fontSize="medium"
              width={129}
              height={32}
              onClick={() => handleSaveButtonClicked(true)}
            >
              {T.translate('FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_SAVE_AS_BUTTON')}
            </PrimaryButton>
            <PrimaryButton
              theme={currentTheme}
              fontSize="medium"
              marginLeft={12}
              width={75}
              height={32}
              onClick={() => handleSaveButtonClicked(false)}
            >
              {T.translate('FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_SAVE_BUTTON')}
            </PrimaryButton>
          </ExitModalContentWrapper>
          )}
        </>),
      width: '450px',
      okText: T.translate('FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_OK_BUTTON'),
      cancelText: T.translate('FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_CANCEL_BUTTON'),
      onOk() {
        onEditModeChanged(false);
        dispatch(SwappEditor.stopEditing());
      },
    });
  };

  const buttonsArray = [
    [
      {
        id: TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_ROOMS,
        name: T.translate('TEST_FIT_VIEWER_CONTAINER_UI_ROOM'),
        icon: icons.roomIconSmall,
        selectable: true,
        handleClick: changeEditorType,
        tooltipText: T.translate('TEST_FIT_VIEWER_CONTAINER_UI_ROOM_TOOLTIP'),
        subButtons: [
          {
            id: TEST_FIT_EDITOR_BUTTON_KEYS.SWAP,
            name: T.translate('TEST_FIT_VIEWER_CONTAINER_UI_SWAP'),
            icon: icons.swappRoomIcon,
            handleClick: () => dispatch(SwappEditor.setIsSelectedObjectInEdit(true)),
            hidden: lodashIsEmpty(selectedObject) || selectedObject?.isSelectedInUi || true,
            disabled: isSelectedObjectHasLockTag || isSelectedObjectHasAdminLockTag,
            tooltipText: T.translate('ROOM_ACTION_BUTTON_EDIT_ROOMS_TOOLTIP'),
          },
          {
            id: TEST_FIT_EDITOR_BUTTON_KEYS.DELETE,
            name: T.translate('TEST_FIT_VIEWER_CONTAINER_UI_UNASSIGN'),
            icon: icons.deleteRoomIcon,
            handleClick: () => dispatch(SwappEditor.addOperations([{ name: 'TEST_FIT_DEALLOCATE_ROOM', parameters: { room_id: selectedObject?.id } }])),
            hidden: lodashIsEmpty(selectedObject) || selectedObject?.isSelectedInUi,
            disabled: isSelectedObjectHasLockTag || isSelectedObjectHasAdminLockTag || selectedObject?.type === 'EMPTY',
            tooltipText: T.translate('ROOM_ACTION_BUTTON_DELETE_ROOMS_TOOLTIP'),
          },
          {
            id: TEST_FIT_EDITOR_BUTTON_KEYS.LOCK,
            name: T.translate(isSelectedObjectHasLockTag ? 'TEST_FIT_VIEWER_CONTAINER_UI_UNLOCK' : 'TEST_FIT_VIEWER_CONTAINER_UI_LOCK'),
            icon: isSelectedObjectHasLockTag ? icons.unlockIcon : icons.lockIcon,
            disabled: isSelectedObjectHasAdminLockTag,
            handleClick: () => {
              dispatch(SwappEditor.addOperations([{
                name: 'TEST_FIT_UPDATE_ROOMS_TAGS',
                parameters: { tag: ROOM_TAGS.LOCK, value: !isSelectedObjectHasLockTag, room_ids: [selectedObject?.id] },
              }]));
            },
            hidden: lodashIsEmpty(selectedObject) || selectedObject?.isSelectedInUi,
            tooltipText: T.translate(isSelectedObjectHasLockTag ? 'ROOM_ACTION_BUTTON_UNLOCK_ROOMS_TOOLTIP' : 'ROOM_ACTION_BUTTON_LOCK_ROOMS_TOOLTIP'),
          },
        ],
      },
      {
        id: TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_TILES,
        name: T.translate('TEST_FIT_VIEWER_CONTAINER_UI_TILES'),
        icon: icons.tilesIconSmall,
        selectable: true,
        handleClick: changeEditorType,
        hidden: !hasTiles || hideTiles,
        tooltipText: T.translate('TEST_FIT_VIEWER_CONTAINER_UI_TILES_TOOLTIP'),
        subButtons: [
          {
            id: TEST_FIT_EDITOR_BUTTON_KEYS.SWAP,
            name: T.translate('TEST_FIT_VIEWER_CONTAINER_UI_SWAP'),
            icon: icons.swappRoomIcon,
            handleClick: () => dispatch(SwappEditor.setIsSelectedObjectInEdit(true)),
            hidden: lodashIsEmpty(selectedObject) || selectedObject?.isSelectedInUi || true,
            tooltipText: T.translate('ROOM_ACTION_BUTTON_EDIT_TILES_TOOLTIP'),
          },
          {
            id: TEST_FIT_EDITOR_BUTTON_KEYS.DELETE,
            name: T.translate('TEST_FIT_VIEWER_CONTAINER_UI_UNASSIGN'),
            icon: icons.deleteRoomIcon,
            handleClick: () => dispatch(SwappEditor.addOperations([{ name: 'TEST_FIT_DEALLOCATE_TILES', parameters: { tile_ids: selectedObject.map((tile) => tile.id) } }])),
            hidden: lodashIsEmpty(selectedObject) || selectedObject?.isSelectedInUi,
            tooltipText: T.translate('ROOM_ACTION_BUTTON_DELETE_TILES_TOOLTIP'),
          },
        ],
      },
      {
        id: TEST_FIT_EDITOR_BUTTON_KEYS.DEPARTMENTS,
        name: T.translate('TEST_FIT_VIEWER_CONTAINER_UI_DEPARTMENTS'),
        icon: icons.departmentsIcon,
        selectable: true,
        handleClick: changeEditorType,
        tooltipText: T.translate('TEST_FIT_VIEWER_CONTAINER_UI_DEPARTMENTS_TOOLTIP'),
        subButtons: [
          {
            id: TEST_FIT_EDITOR_BUTTON_KEYS.SWAP,
            name: T.translate('TEST_FIT_VIEWER_CONTAINER_UI_SWAP'),
            icon: icons.swappRoomIcon,
            handleClick: () => dispatch(SwappEditor.setIsSelectedObjectInEdit(true)),
            hidden: lodashIsEmpty(selectedObject) || selectedObject?.isSelectedInUi || true,
            tooltipText: T.translate('ROOM_ACTION_BUTTON_EDIT_DEPARTMENTS_TOOLTIP'),
          },
          {
            id: TEST_FIT_EDITOR_BUTTON_KEYS.DELETE,
            name: T.translate('TEST_FIT_VIEWER_CONTAINER_UI_UNASSIGN'),
            icon: icons.deleteRoomIcon,
            handleClick: () => dispatch(addOperations([{ name: 'TEST_FIT_ASSIGN_ROOMS_TO_DEPARTMENT', parameters: { department_id: null, room_ids: Array.isArray(selectedObject) ? selectedObject.map((e) => e.id) : selectedObject.id }, localViewParameters: { selectedRooms: selectedObject } }])),
            hidden: lodashIsEmpty(selectedObject) || selectedObject?.isSelectedInUi,
            disabled: Array.isArray(selectedObject) ? lodashIsEmpty(selectedObject.filter((e) => e.departmentId)) : !selectedObject?.departmentId,
            tooltipText: T.translate('ROOM_ACTION_BUTTON_DELETE_DEPARTMENTS_TOOLTIP'),
          },
        ],
      },
    ],
    [
      {
        id: TEST_FIT_EDITOR_BUTTON_KEYS.UNDO,
        name: T.translate('TEST_FIT_VIEWER_CONTAINER_UI_UNDO'),
        icon: icons.undoIcon,
        disabled: !canUndo,
        handleClick: () => dispatch(SwappEditor.undoLastOperation()),
      },
    ],
  ];

  if (isHidden) {
    return null;
  }

  beginFinishedEditContext.setFinishEditing(finishEditing);
  beginFinishedEditContext.setBeginEditing(() => {
    history.push(`${UI_AUTHORIZE_PATH.program}`);
    onEditModeChanged(true);
  });

  return (
    <>
      {(isProfileLoading || isEditorLoading) && <LoadingSpinner />}
      <Overlay show={isEditModeOn} />
      {/* ========= edit panel ========= */}
      <ViewerUiPanelWrapper position="top-right">
        <ViewerUiPanel isHidden={!isEditModeOn} width={480} isRight>
          {isEditModeOn && <TestFitEditPanelContainer />}
        </ViewerUiPanel>
      </ViewerUiPanelWrapper>

      <ViewerUiPanelWrapper
        position="top-left"
        ref={ref}
        fitContent={isEditModeOn}
        onTransitionEnd={(e) => {
          if (onPanelWidthAnimationEnded && e.propertyName === 'width') {
            onPanelWidthAnimationEnded();
          }
        }}
      >
        {/* ========= metadata panel ========= */}
        <ViewerUiPanel isHidden={isEditModeOn} width={610}>
          <ProfileDataPanel profileId={locationData.profileId} />
        </ViewerUiPanel>

        <UiButtonsWrapper>
          {/* ============== Clear Filters ============== */}
          <SwappViewerActionButton
            icon={icons.clearFilterIcon}
            isHidden={lodashIsEmpty([...selectedRowKeys, ...selectedCategoryRowKeys, ...selectedDepartmentRowKeys])}
            marginTop={10}
            tabInfoText={selectedItemsCount.length}
            onClick={() => dispatch(clearSelectedRowKeys())}
          />

        </UiButtonsWrapper>
      </ViewerUiPanelWrapper>
      {isEditModeOn && <EditPanelContainer offset={-172} buttonsArray={buttonsArray} landscape />}
    </>
  );
});

ProgramUi.propTypes = {
  hasTiles: PropTypes.bool,
  onEditModeChanged: PropTypes.func,
  onPanelWidthAnimationEnded: PropTypes.func,
  isHidden: PropTypes.bool,
};

export default ProgramUi;
