import React, { useState } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { TreeSelect } from 'antd';
import Icon from '@ant-design/icons';
import styled from 'styled-components';
import dropdownIcon from '../../styles/static/icons/comon/dropdownIcon';

const { SHOW_PARENT } = TreeSelect;
const ALL = 'all';

const StyledTreeSelect = styled(TreeSelect)`
  width: 100%;
  
  .ant-select-selector {
    background-color: ${({ theme }) => theme.colors.inputBackground} !important;
  }

  .ant-select-selection-item {
    border-radius: ${({ theme }) => theme.other.borderRadius};
    background-color: ${({ theme }) => theme.colors.gray_04} !important;
  }
  
  .ant-select-selector {
    :hover, :focus, :focus-within {
      border-color: ${({ theme }) => theme.colors.gray_04} !important;
      box-shadow: none !important;
    }
  }
`;

const DropdownIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  color: ${({ theme }) => theme.colors.gray_06};
  pointer-events: none;
`;

const MultiDropdown = (props) => {
  const { data, maxTagCount, placeholder, onChange, defaultValue } = props;
  const [selectedValues, useSelectedValues] = useState(defaultValue || [ALL]);

  const treeData = [
    {
      title: T.translate('ALL'),
      value: ALL,
      key: ALL,
      children: data.map((item) => ({ title: item.title, value: item.value, key: item.value })),
    },
  ];

  const onSelect = (value, update) => {
    update(value);
    onChange(value[0] === ALL ? data.map((item) => item.value) : value);
  };

  const options = {
    treeData,
    maxTagCount,
    placeholder,
    treeCheckable: true,
    value: selectedValues,
    treeDefaultExpandAll: true,
    showCheckedStrategy: SHOW_PARENT,
    onChange: (value) => onSelect(value, useSelectedValues),
  };

  return (
    <React.Fragment>
      <DropdownIcon component={dropdownIcon} />
      <StyledTreeSelect {...options} />
    </React.Fragment>
  );
};

MultiDropdown.propTypes = {
  data: PropTypes.array.isRequired,
  maxTagCount: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.array,
};

export default MultiDropdown;
