import React from 'react';
import PropTypes from 'prop-types';
import { useThree } from 'react-three-fiber';
import { shallowEqual, useSelector } from 'react-redux';
import ExtrudeMesh from './ExtrudeMesh';
import MeshLine from './MeshLine';
import Ramp3d from './Ramp3d';
import { transformPolygonToBox } from '../../algorithms/algorithmHelpers';
import { selectionProps } from '../helpers/SelectionHelpers';
import { getStoryParkingUnits, getStoryHeight, getStoryEnvelope } from '../../model/feasibilityDataExtractors';
import { getRenderPropsByName } from '../helpers/FeasibilityRenderProps';

const offsetHeight = 0.1; // to fix z fighting
const meshLineHeight = 0.02;

const getSurroundingRectangle = () => {
  const surroundingRectangle = [[-0.5, -0.5],
    [-0.5, 0.5],
    [0.5, 0.5],
    [0.5, -0.5],
    [-0.5, -0.5]];

  return surroundingRectangle;
};

const getInnerSurroundingRectangle = () => {
  const innerSurroundingRectangle = [[-0.34, -0.5],
    [-0.34, 0.5],
    [0.34, 0.5],
    [0.34, -0.5],
    [-0.34, -0.5]];

  return innerSurroundingRectangle;
};

const getTwoWayUpArrow = () => {
  const upArrow = [[-0.34, 0],
    [0.34, 0.25],
    [-0.34, 0.5],
    [-0.34, 0]];

  return upArrow;
};

const getTwoWayDownArrow = () => {
  const downArrow = [[0.34, 0],
    [-0.34, -0.25],
    [0.34, -0.5],
    [0.34, 0]];

  return downArrow;
};

const getHelixUpArrow = () => {
  const upArrow = [[0, -0.5],
    [0.25, 0.5],
    [0.5, -0.5],
    [0, -0.5]];

  return upArrow;
};

const getHelixDownArrow = () => {
  const downArrow = [[0, 0.5],
    [-0.25, -0.5],
    [-0.5, 0.5],
    [0, 0.5]];

  return downArrow;
};

const getHelixSingleArrow = () => {
  const singleArrow = [[-0.5, -0.5],
    [0.5, 0],
    [-0.5, 0.5],
    [-0.5, -0.5]];

  return singleArrow;
};

const getSeperatingHorizontalLine = () => {
  const seperatingLine = [[0.34, 0],
    [-0.34, 0]];

  return seperatingLine;
};

const getSeperatingVerticalLine = () => {
  const seperatingLine = [[0, 0.5],
    [0, -0.5]];

  return seperatingLine;
};

const getRampHolePolygons = (boxPolygon) => {
  const holePattern = [[-0.34, -0.49],
    [-0.34, 0.49],
    [0.34, 0.49],
    [0.34, -0.49],
    [-0.34, -0.49]];
  const polygons = transformPolygonToBox(holePattern, boxPolygon);

  return polygons;
};

const getTwoWayRampArrowsPolygons = (boxPolygon) => {
  const polygons = [];
  polygons.push(transformPolygonToBox(getTwoWayUpArrow(), boxPolygon));
  polygons.push(transformPolygonToBox(getTwoWayDownArrow(), boxPolygon));
  polygons.push(transformPolygonToBox(getSurroundingRectangle(), boxPolygon));
  polygons.push(transformPolygonToBox(getInnerSurroundingRectangle(), boxPolygon));
  polygons.push(transformPolygonToBox(getSeperatingHorizontalLine(), boxPolygon));

  return polygons;
};

const getTwoWayHelixPolygons = (boxPolygon) => {
  const polygons = [];
  polygons.push(transformPolygonToBox(getSurroundingRectangle(), boxPolygon));
  polygons.push(transformPolygonToBox(getSeperatingVerticalLine(), boxPolygon));
  polygons.push(transformPolygonToBox(getHelixUpArrow(), boxPolygon));
  polygons.push(transformPolygonToBox(getHelixDownArrow(), boxPolygon));

  return polygons;
};

const getSingleHelixPolygons = (boxPolygon) => {
  const polygons = [];
  polygons.push(transformPolygonToBox(getSurroundingRectangle(), boxPolygon));
  polygons.push(transformPolygonToBox(getHelixSingleArrow(), boxPolygon));

  return polygons;
};

const ParkingMassFloor = (props) => {
  const { data, height, floorDepth = 0.4, selectionObject, onSelected, isTopFloor, floorHeight } = props;
  // TODO TECH DEBT : SwpProject refactoring
  const parkingUnits = getStoryParkingUnits(data);
  const { invalidate } = useThree();
  const selectedObject = useSelector(({ editor }) => editor.selectedObject);

  const isSelected = selectedObject && shallowEqual(selectedObject, selectionObject);
  const selectedColor = '#7777ff';
  const baseColor = isSelected ? selectedColor : '#bbbbbb';

  const selectionObjectWithColor = { ...selectionObject, color: baseColor };
  const onSelectedWrapper = () => onSelected(selectionObject);
  const floorSelectionProps = selectionProps(selectionObjectWithColor, !!selectionObject, selectedColor, invalidate, onSelectedWrapper);

  const holes = [];
  parkingUnits.forEach((parkingUnit) => {
    if (parkingUnit.type === 'ramp') holes.push(getRampHolePolygons(parkingUnit.polygon.boundary));
  });

  const envelope = getStoryEnvelope(data);
  const massProps = getRenderPropsByName('mass');

  return (
    <group position={[0, 0, height || 0]}>
      <ExtrudeMesh
        receiveShadow
        envelope={[...envelope, envelope[0]]}
        extrudeDepth={floorDepth}
        color={baseColor}
        holes={holes}
        meshProps={{
          ...floorSelectionProps,
          position: [0, 0, -(floorDepth + offsetHeight)],
          ...massProps,
        }}
      />
      {parkingUnits.map((parkingUnit, index) => {
        if (parkingUnit.type === 'ramp') {
          // const rampsPolygons = getRampArrowPolygons(parkingUnit.parking_polygon.boundary);
          return (
            <group key={`ramp_${index}`}>
              { !isTopFloor && <Ramp3d height={floorHeight} base={parkingUnit.polygon.boundary} color={baseColor} meshProps={massProps} /> }
              {/* {rampsPolygons.map((rampPolygon, rampIndex) => (
                <group key={`ramp_${rampIndex}`}>
                  <MeshLine vertices={rampPolygon} height={0.1} />
                </group>
              ))} */}
            </group>
          );
        }

        if (parkingUnit.parking_type === 'two_way_ramp') {
          const rampsPolygons = getTwoWayRampArrowsPolygons(parkingUnit.polygon.boundary);
          return (
            <group key={`two_way_ramp_${index}`}>
              {rampsPolygons.map((rampPolygon) => (
                <group key={index}>
                  <MeshLine vertices={rampPolygon} height={0.1} meshProps={massProps} />
                </group>
              ))}
            </group>
          );
        }

        if (parkingUnit.parking_type === 'two_way_helix') {
          const rampsPolygons = getTwoWayHelixPolygons(parkingUnit.polygon.boundary);
          return (
            <group key={`two_way_helix_${index}`}>
              {rampsPolygons.map((rampPolygon) => (
                <group key={index}>
                  <MeshLine vertices={rampPolygon} height={0.1} lineWidth={0.3} meshProps={massProps} />
                </group>
              ))}
            </group>
          );
        }

        if (parkingUnit.parking_type === 'single_helix') {
          const rampsPolygons = getSingleHelixPolygons(parkingUnit.polygon.boundary);
          return (
            <group key={`single_helix_${index}`}>
              {rampsPolygons.map((rampPolygon) => (
                <group key={index}>
                  <MeshLine vertices={rampPolygon} height={0.1} meshProps={massProps} />
                </group>
              ))}
            </group>
          );
        }

        if (parkingUnit.parking_type === 'column') {
          return <ExtrudeMesh key={`column_${index}`} receiveShadow envelope={[...parkingUnit.polygon.boundary, parkingUnit.polygon.boundary[0]]} extrudeDepth={getStoryHeight(data) - offsetHeight - 0.05} color="#707070" meshProps={massProps} />;
        }
        return <MeshLine key={`default_${index}`} vertices={parkingUnit.polygon.boundary} height={meshLineHeight} meshProps={massProps} />;
      })}
    </group>
  );
};

ParkingMassFloor.propTypes = {
  data: PropTypes.object,
  height: PropTypes.number,
  floorDepth: PropTypes.number,
  selectionObject: PropTypes.object,
  onSelected: PropTypes.func,
  floorHeight: PropTypes.number,
  isTopFloor: PropTypes.bool,
};

export default ParkingMassFloor;
