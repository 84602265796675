import React from 'react';

export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M36.7873 20.9308L20.0345 9.56006L3.40277 20.9308L20.5336 32.4567L36.7873 20.9308Z" stroke="currentcolor" strokeWidth="0.75" strokeLinejoin="round" />
    <path d="M2.08337 20.0953L0.524231 18.8755" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.9512 8.55921L17.3921 7.33936" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.30768 19.2876L18.1675 8.14745" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M23.0099 7.1791L21.4305 8.37256" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M39.6812 18.997L38.1018 20.1904" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.221 7.97375L38.891 19.3959" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
