import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import T from 'i18n-react';
import { confirmResetPasswordAction } from 'store/auth/actions/authActions';
import SetNewPasswordForm from './SetNewPasswordForm';
import { Title, SubTitle, BackLink } from './SetNewPasswordContainer.styles';
import { UI_UNAUTHORIZED_PATH } from '../../constants/routes/ui';

const SetNewPasswordContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleResetPasswordAction = () => {
    dispatch(confirmResetPasswordAction());
  };

  return (
    <React.Fragment>
      <Title>{T.translate('AUTH_FORM_PASSWORD_RESET')}</Title>
      <SubTitle>{T.translate('CONFIRM_PASSWORD_RESET_CONTAINER_CHOOSE_NEW_PASSWORD')}</SubTitle>
      <SetNewPasswordForm onSubmit={handleResetPasswordAction} location={history.location} history={history} />
      <BackLink to={UI_UNAUTHORIZED_PATH.login}>{T.translate('BACK')}</BackLink>
    </React.Fragment>
  );
};

export default SetNewPasswordContainer;
