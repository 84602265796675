import React from 'react';

// currentcolor
export default () => (
  <svg width="110" height="110" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.7543 21.0379C33.4328 21.0379 33.2491 21.0379 33.2032 21.0379L0.918485 20.9918C0.413318 20.9918 0 20.5766 0 20.0692C0 19.5618 0.413318 19.1466 0.918485 19.1466H33.2951C33.5706 19.1466 35.8209 19.285 37.1527 18.0857C37.8416 17.486 38.163 16.5634 38.163 15.4102C38.163 14.0725 37.4282 12.8731 36.3261 12.3657C35.3157 11.9044 34.1676 12.1351 33.2032 13.0115C32.5603 13.6112 32.147 14.6722 32.6062 15.4563C32.8817 15.9176 32.6981 16.4712 32.2847 16.7018C31.8255 16.9786 31.2744 16.7941 31.0448 16.3789C30.0804 14.7183 30.8152 12.7348 32.0092 11.6277C33.5247 10.29 35.4076 9.92092 37.1068 10.7051C38.8978 11.4893 40.0459 13.3806 40.0459 15.4102C40.0459 17.117 39.4948 18.5008 38.4386 19.4695C36.8772 20.8073 34.8565 21.0379 33.7543 21.0379Z" fill="currentcolor" />
    <path d="M22.0436 16.7479C21.8599 16.7479 21.6762 16.7479 21.5844 16.7479C21.5385 16.7479 21.4925 16.7479 21.4466 16.7479H8.31228C7.80712 16.7479 7.3938 16.3328 7.3938 15.8254C7.3938 15.318 7.80712 14.9028 8.31228 14.9028H21.4925C21.5844 14.9028 21.6303 14.9028 21.7222 14.9028C22.1355 14.9489 24.248 14.9951 25.4879 13.7957C26.1309 13.196 26.4523 12.2735 26.4523 11.1203C26.4523 9.78254 25.7176 8.5832 24.6154 8.07579C23.605 7.6145 22.4569 7.84514 21.4925 8.72158C20.8496 9.32125 20.4363 10.3822 20.8955 11.1664C21.1711 11.6277 20.9874 12.1812 20.574 12.4119C20.1148 12.6886 19.5637 12.5041 19.3341 12.089C18.3697 10.4283 19.1045 8.44481 20.3444 7.33773C21.8599 6 23.7428 5.63097 25.442 6.41516C27.2331 7.19934 28.3812 9.09061 28.3812 11.1664C28.3812 12.827 27.876 14.2109 26.8197 15.1796C25.2583 16.5173 23.1917 16.7479 22.0436 16.7479Z" fill="currentcolor" />
    <path d="M27.9679 34C26.7738 34 25.6716 33.5387 24.7072 32.6623C23.5132 31.5552 22.7784 29.5717 23.7428 27.911C24.0184 27.4497 24.5695 27.3114 24.9828 27.5881C25.442 27.8649 25.5798 28.4184 25.3043 28.8336C24.845 29.6178 25.2583 30.6787 25.9013 31.2784C26.8657 32.1549 27.9679 32.3855 29.0241 31.9242C30.1263 31.4168 30.8611 30.2175 30.8611 28.8797C30.8611 27.7265 30.5396 26.8039 29.8507 26.2043C28.473 24.9588 26.085 25.1433 26.085 25.1433H8.31228C7.80712 25.1433 7.3938 24.7282 7.3938 24.2208C7.3938 23.7133 7.80712 23.2982 8.31228 23.2982H25.9472C26.3146 23.2521 29.1619 23.1137 31.0907 24.8666C32.147 25.8352 32.6981 27.2191 32.6981 28.9259C32.6981 31.0016 31.5499 32.8468 29.7589 33.631C29.2078 33.8616 28.5649 34 27.9679 34Z" fill="currentcolor" />
  </svg>
);
