/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import styled from 'styled-components';
import FeasibilityViewerContainer from 'utils/swappViewer/FeasibilityViewerContainer';
import { FEASIBILITY_EDITOR_BUTTON_KEYS, MODEL_ANALYSIS_TYPES, STANDARDS } from 'constants/feasibilityConts';
import { shallowEqual, useDispatch, useSelector, useStore } from 'react-redux';
import lodashValues from 'lodash/values';
import { getProfileByIdSelector, swappProjectSelector } from 'store/swappProfile';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { useProfileResult } from 'store/swappProfile/selectors/swappSelectors';
import { selectStory } from 'store/BuildingStoriesStore';
import { useActiveProfileBuildingInfo } from 'utils/model/feasibilityResultModel';
import * as SwappEditor from 'store/editor';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import { useHistory } from 'react-router';
import { useOverridableSetting } from 'store/userSettings';
import { RAD_2_DEG } from 'utils/algorithms/algorithmHelpers';
import { EDIT_TYPES } from 'store/editor';
import ProgramViewerUi from './ProgramViewerUi';

const ProgramContainerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const ProgramContainer = () => {
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const swappProject = useSelector(swappProjectSelector, shallowEqual);
  const profile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const editorType = useSelector(({ editor }) => editor.editorType);
  const isEditModeOn = useSelector(({ editor }) => editor.profileId !== 0);
  const isSketchCentric = useOverridableSetting('isSketchCentric', false);
  const store = useStore(); // We need the store for getBuildingsTransform. We don't useSelector becuase we don't want to rerender

  const result = useProfileResult(profile);
  const dispatch = useDispatch();
  const [isOrthographic, setIsOrthographic] = useState(false);

  const buildingInfo = useActiveProfileBuildingInfo();
  const isBuildingStoriesOn = buildingInfo.swpProject && !editorType;

  // The standart can be derived by the requirements that were generated by the user in advance,
  // Or by the settings that were manually added to the user / parent_profile
  const resultStandart = result?.singleOptionMassingOptions?.requirements?.units_standard;
  const isIlStandard = resultStandart === STANDARDS.IL.key;

  // In order to provide good UX, we don't immediately send transform operations to the client <-> server loop.
  // Instead, we "inject" them into the operation list when we know its time. There is a delicate ping-pong between
  // the editor slice (containing the latest result and operation list) and the mass transformer slice.
  // See massTransformer.js for the other side of this.
  const addTransformOperationIfNeeded = (operationList) => {
    const buildingsTransform = store.getState().editor.massTransformer.transforms;
    // Sorting operation cause the string Mass11 be before Mass2. Without the sorting operation it works fine:
    // const sortedBuildingsTransform = lodashSortBy(lodashValues(buildingsTransform), ['key']);
    const sortedBuildingsTransform = lodashValues(buildingsTransform);
    // eslint-disable-next-line no-unused-vars
    const removeEmpty = (obj) => Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
    const filteredBuildingsTransform = sortedBuildingsTransform.map((building) => (
      {
        delta_x: building.delta_x,
        delta_y: building.delta_y,
        delta_angle: building.delta_angle * RAD_2_DEG,
        points: building.points,
      }
    ));
    const hasNontrivialTransform = Object.values(filteredBuildingsTransform).find((transform) => Object.values(transform).find((val) => val !== 0 && val !== null) !== undefined) !== undefined;
    if (hasNontrivialTransform) {
      operationList.push({ name: 'TRANSFORM', parameters: { mass_transforms: filteredBuildingsTransform.map(removeEmpty) } });
    }
  };

  const toggleOrthographic = React.useCallback((value) => {
    setIsOrthographic(value !== undefined ? value : !isOrthographic);
    dispatch(selectStory(value ? 1 : null));
  }, [isOrthographic]);

  const beginEditing = React.useCallback(() => {
    history.push(`${UI_AUTHORIZE_PATH.program}`);
    dispatch(SwappEditor.startEditing({ profileId: locationData.profileId, result, editSessionType: EDIT_TYPES.FEASIBILITY, editorType: FEASIBILITY_EDITOR_BUTTON_KEYS.TRANSFORM }));
    setIsOrthographic(true);
  }, [result, locationData.profileId]);

  const updateServerReducer = (type, { points, entrance, objecdID, outerPoints, massInSketch }) => {
    let operation;
    switch (type) {
      case 'parking':
        const { normal, position } = entrance;
        const lots = buildingInfo.parkingInfo.lots ? [...buildingInfo.parkingInfo.lots] : [];
        const params = { boundary: { boundary: points, holes: [] }, entrances: [{ normal, position }] };
        if (!isNaN(objecdID)) {
          lots[objecdID] = params;
        } else {
          lots.push(params);
        }
        operation = { name: 'SET_PARKING_BOUNDARY', parameters: { lots } };
        dispatch(SwappEditor.addOperations([operation]));
        dispatch(SwappEditor.setEditorType(''));
        break;
      case 'createMass':
        if (isSketchCentric) {
          operation = { name: 'ADD_EMPTY_MASS', parameters: { num_of_floors: 10, points, outer_points: outerPoints }, localViewParameters: { outerPoints } };
          dispatch(SwappEditor.setEditorType(''));
        } else {
          operation = { name: 'ADD_CUSTOM_MASS', parameters: { num_of_floors: 10, points }, localViewParameters: { outerPoints } };
        }
        dispatch(SwappEditor.addOperations([operation]));
        break;
      case 'editMass':
        // operation = { name: 'ADD_CUSTOM_MASS', parameters: { num_of_floors: floors, points }, localViewParameters: { outerPoints } };
        operation = { name: 'EDIT_MASS_SHAPE', parameters: { mass_index: massInSketch.massIndex, points, outer_points: outerPoints }, localViewParameters: { outerPoints, buildingInfo } };
        const operations = [operation];
        dispatch(SwappEditor.MassTransformer.resetMassTransform({ meshName: massInSketch.name }));
        addTransformOperationIfNeeded(operations);
        dispatch(SwappEditor.addOperations(operations));
        dispatch(SwappEditor.setEditorType(FEASIBILITY_EDITOR_BUTTON_KEYS.WAITING_FOR_SKETCH));
        break;
      case 'hint':
        operation = { name: 'CUSTOM_MASS_HINT', parameters: { points, num_of_floors: 1 } };
        dispatch(SwappEditor.addHints([operation]));
        break;
      default:
    }
  };

  return (
    <ProgramContainerWrapper>
      {/* ============ UI ============ */}
      <ProgramViewerUi
        beginEditing={beginEditing}
        isEditModeOn={isEditModeOn}
        buildingInfo={buildingInfo}
        isIlStandard={isIlStandard}
        swappProject={swappProject}
        isOrthographic={isOrthographic}
        profileId={locationData.profileId}
        setIsOrthographic={toggleOrthographic}
        isBuildingStoriesOn={isBuildingStoriesOn}
        isSketchToolOn={editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_BUILDING || editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.EDIT_BUILDING}
        isEditorInTransformMode={editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.TRANSFORM}
        isSketchParkingOn={editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_SURFACE_PARKING || editorType === 'EDIT_SURFACE_PARKING'}
        addTransformOperationIfNeeded={addTransformOperationIfNeeded}
      />

      {/* ============ viewer ============ */}
      <FeasibilityViewerContainer
        result={result}
        isOrthographic={isOrthographic}
        profileId={locationData.profileId}
        legendKey={MODEL_ANALYSIS_TYPES.AREA_TYPE}
        editorType={editorType || ''}
        updateServerReducer={updateServerReducer}
      />
    </ProgramContainerWrapper>
  );
};

export default ProgramContainer;
