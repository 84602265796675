import architectureIcon from './architectureIcon';
import hvacIcon from './hvacIcon';
import plumbingIcon from './plumbingIcon';
import structureIcon from './structureIcon';
import sheetsIcon from './sheetsIcon';
import finishedIcon from './finishedIcon';
import loadingIcon from './loadingIcon';

export default {
  architectureIcon,
  hvacIcon,
  plumbingIcon,
  structureIcon,
  sheetsIcon,
  finishedIcon,
  loadingIcon,
};
