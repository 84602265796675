import React from 'react';

export default () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="34" y="35.7593" width="10" height="8" fill="#5772ff" />
    <path d="M33.4406 44.5182C33.2 44.5182 33 44.3182 33 44.0776V16.5588C33 16.3182 33.2 16.1182 33.4406 16.1182C33.6813 16.1182 33.8813 16.3182 33.8813 16.5588V44.0792C33.8797 44.3183 33.6797 44.5183 33.4406 44.5183V44.5182Z" fill="currentColor" />
    <path d="M24.5998 44.5182C24.3592 44.5182 24.1592 44.3182 24.1592 44.0776V16.5588C24.1592 16.3182 24.3592 16.1182 24.5998 16.1182C24.8405 16.1182 25.0405 16.3182 25.0405 16.5588V44.0792C25.0795 44.3183 24.8795 44.5183 24.5998 44.5183V44.5182Z" fill="currentColor" />
    <path d="M27.4003 23.8797C27.28 23.8797 27.1597 23.8391 27.08 23.7594C26.9206 23.6 26.9206 23.2797 27.08 23.1188L29.1206 21.0781L31.1613 23.1188C31.3206 23.2781 31.3206 23.5984 31.1613 23.7594C31.0019 23.9188 30.6816 23.9188 30.5206 23.7594L29.1206 22.3594L27.7206 23.7594C27.6409 23.8391 27.5206 23.8797 27.4003 23.8797H27.4003Z" fill="currentColor" />
    <path d="M27.4003 28.5184C27.28 28.5184 27.1597 28.4778 27.08 28.3981C26.9206 28.2387 26.9206 27.9184 27.08 27.7574L29.1206 25.7168L31.1613 27.7574C31.3206 27.9168 31.3206 28.2371 31.1613 28.3981C31.0019 28.5575 30.6816 28.5575 30.5206 28.3981L29.1206 26.9981L27.7206 28.3981C27.6409 28.4793 27.5206 28.5184 27.4003 28.5184H27.4003Z" fill="currentColor" />
    <path d="M27.4003 33.1595C27.28 33.1595 27.1597 33.1189 27.08 33.0392C26.9206 32.8798 26.9206 32.5595 27.08 32.3985L29.1206 30.3579L31.1613 32.3985C31.3206 32.5579 31.3206 32.8782 31.1613 33.0392C31.0019 33.1986 30.6816 33.1986 30.5206 33.0392L29.1206 31.6392L27.7206 33.0392C27.6409 33.1189 27.5206 33.1595 27.4003 33.1595H27.4003Z" fill="currentColor" />
    <path d="M27.4003 37.8001C27.28 37.8001 27.1597 37.7595 27.08 37.6798C26.9206 37.5204 26.9206 37.2001 27.08 37.0392L29.1206 34.9985L31.1613 37.0392C31.3206 37.1985 31.3206 37.5189 31.1613 37.6798C31.0019 37.8392 30.6816 37.8392 30.5206 37.6798L29.1206 36.2798L27.7206 37.6798C27.6409 37.7595 27.5206 37.8001 27.4003 37.8001H27.4003Z" fill="currentColor" />
    <path d="M39.0002 30.4795C38.7596 30.4795 38.5596 30.2795 38.5596 30.0389V16.5593C38.5596 16.3187 38.7596 16.1187 39.0002 16.1187C39.2408 16.1187 39.4408 16.3187 39.4408 16.5593V30.0389C39.4408 30.2795 39.2408 30.4795 39.0002 30.4795Z" fill="currentColor" />
    <path d="M33.4408 13.7593C33.2002 13.7593 33.0002 13.5593 33.0002 13.3186V10.278L29.0408 6.31864L25.0815 10.278V13.3186C25.0815 13.5593 24.8815 13.7593 24.6408 13.7593C24.4002 13.7593 24.2002 13.5593 24.2002 13.3186V9.878L29.041 5L33.8818 9.8796V13.3202C33.8802 13.5593 33.6802 13.7593 33.4411 13.7593L33.4408 13.7593Z" fill="currentColor" />
    <path d="M21.6409 19.0389H15.5205V12.9185H39.2001L43.2797 16.9981L21.6405 16.9996L21.6409 19.0389ZM16.4409 18.1185H20.7613V16.0779H41.0817L38.802 13.7982L16.4412 13.7998L16.4409 18.1185Z" fill="currentColor" />
    <path d="M44.5595 44.5186C44.3189 44.5186 44.1189 44.3186 44.1189 44.078L44.1205 33.3188H39.4408V35.6781C39.4408 35.9187 39.2408 36.1188 39.0002 36.1188C38.7596 36.1188 38.5596 35.9188 38.5596 35.6781V32.3984L45.0004 32.4V44.0796C45.0004 44.3187 44.8004 44.5187 44.5597 44.5187L44.5595 44.5186Z" fill="currentColor" />
    <path d="M38.9998 33.3183H33.4406C33.2 33.3183 33 33.1183 33 32.8777C33 32.637 33.2 32.437 33.4406 32.437L38.9998 32.4386C39.2405 32.4386 39.4405 32.6386 39.4405 32.8792C39.4405 33.0792 39.2405 33.3183 38.9998 33.3183V33.3183Z" fill="currentColor" />
    <path d="M41.7595 33.3185C41.5188 33.3185 41.3188 33.1185 41.3188 32.8778V30.5185H36.6796V32.8778C36.6796 33.1185 36.4796 33.3185 36.239 33.3185C35.9984 33.3185 35.7983 33.1185 35.7983 32.8778L35.7999 29.5591H42.2795V32.8388C42.2404 33.0794 42.0404 33.3184 41.7592 33.3184L41.7595 33.3185Z" fill="currentColor" />
    <path d="M16.3204 44.0798H15.4001V29.2002C15.4001 28.0408 14.4798 27.1205 13.3204 27.1205L8 27.1189C6.84064 27.1189 5.92032 28.0392 5.92032 29.1986V44.0394H5V29.2002C5 27.5596 6.35936 26.2002 8 26.2002H13.2796C14.9202 26.2002 16.2796 27.5596 16.2796 29.2002V44.0798L16.3204 44.0798Z" fill="currentColor" />
    <path d="M13.4798 27.1186C13.2392 27.1186 13.0392 26.9186 13.0392 26.6779V23.8373C13.0392 22.517 11.9595 21.4373 10.6392 21.4373C9.31887 21.4373 8.23919 22.517 8.23919 23.8373V26.6779C8.23919 26.9186 8.03919 27.1186 7.79855 27.1186C7.55793 27.1186 7.35791 26.9186 7.35791 26.6779V23.8373C7.35791 22.0373 8.83759 20.5576 10.6376 20.5576C12.4376 20.5576 13.9173 22.0373 13.9173 23.8373V26.6779C13.9595 26.9186 13.7204 27.1186 13.4798 27.1186H13.4798Z" fill="currentColor" />
    <path d="M10.6408 21.3184C10.4002 21.3184 10.2002 21.1184 10.2002 20.8777V17.1184C10.2002 16.8778 10.4002 16.6777 10.6408 16.6777C10.8815 16.6777 11.0815 16.8777 11.0815 17.1184V20.8777C11.1205 21.1184 10.9205 21.3184 10.6408 21.3184Z" fill="currentColor" />
    <path d="M24.5204 35.8795C24.4001 35.8795 24.2798 35.8389 24.2001 35.7592L20.2001 31.9592L16.1593 35.7186C15.9593 35.8779 15.6797 35.8779 15.5187 35.6779C15.3593 35.4779 15.3593 35.1982 15.5593 35.0373L19.8797 30.9965C20.0391 30.8371 20.3204 30.8371 20.5204 30.9965L24.8408 35.0373C25.0408 35.1967 25.0408 35.517 24.8814 35.6779C24.7595 35.7998 24.6408 35.8795 24.5204 35.8795V35.8795Z" fill="currentColor" />
    <path d="M10.2002 31.3594H11.1205V32.4391H10.2002V31.3594Z" fill="currentColor" />
    <path d="M10.2002 35.9595H11.1205V37.0392H10.2002V35.9595Z" fill="currentColor" />
    <path d="M10.2002 40.5186H11.1205V41.5982H10.2002V40.5186Z" fill="currentColor" />
    <path d="M19.7207 36.7188H20.641V37.7984H19.7207V36.7188Z" fill="currentColor" />
    <path d="M19.7207 40.6387H20.641V41.7184H19.7207V40.6387Z" fill="currentColor" />
    <path d="M44.5589 36.2387H33.4402C33.1995 36.2387 32.9995 36.0387 32.9995 35.7981C32.9995 35.5574 33.1995 35.3574 33.4402 35.3574H44.5605C44.8012 35.3574 45.0012 35.5574 45.0012 35.7981C44.9996 36.0387 44.7996 36.2387 44.559 36.2387H44.5589Z" fill="currentColor" />
  </svg>

);
