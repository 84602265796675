import React from 'react';

export default () => (
  <svg width="154" height="40" viewBox="0 0 691 197" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M124.727 29.8485C113.64 29.8485 101.63 35.1549 97.0101 37.8081C91.0707 33.8267 81.1717 29.8485 71.2727 29.8485C56.3751 29.8485 48.1751 32.5017 41.5758 35.8182C47.5151 25.8687 61.3737 0 98.9899 0C134.626 0 146.505 22.5522 154.424 35.8182C149.145 33.8283 141.972 29.8485 124.727 29.8485Z" fill="#514F90" />
    <path d="M71.2727 167.152C82.3596 167.152 94.3704 161.845 98.9899 159.192C104.929 163.173 114.828 167.152 124.727 167.152C139.625 167.152 147.825 164.498 154.424 161.182C148.485 171.131 134.626 197 97.0101 197C61.3737 197 49.4949 174.448 41.5758 161.182C46.8552 163.172 54.0279 167.152 71.2727 167.152Z" fill="#514F90" />
    <path d="M166.303 125.364C166.303 114.22 161.024 102.148 158.384 97.5051C162.345 91.5354 166.303 81.5859 166.303 71.6364C166.303 56.6627 163.663 48.4209 160.364 41.7879C170.263 47.7576 196 61.6869 196 99.495C196 135.313 173.562 147.253 160.364 155.212C162.343 149.906 166.303 142.696 166.303 125.364Z" fill="#514F90" />
    <path d="M29.697 71.6364C29.697 82.7798 34.9764 94.8519 37.6162 99.495C33.655 105.465 29.697 115.414 29.697 125.364C29.6969 140.337 32.3367 148.579 35.6364 155.212C25.7374 149.242 -4.93276e-06 135.313 0 97.5051C4.67315e-06 61.6869 22.4377 49.7475 35.6364 41.7879C33.6566 47.0943 29.697 54.3036 29.697 71.6364Z" fill="#514F90" />
    <path d="M154.424 97.5051C154.424 128.277 129.605 153.222 98.9899 153.222C68.3744 153.222 43.5555 128.277 43.5555 97.5051C43.5555 66.7333 68.3744 41.7879 98.9899 41.7879C129.605 41.7879 154.424 66.7333 154.424 97.5051Z" fill="#514F90" />
    <path d="M237 67.5H240.5L240 79.5H256.5V84H240V119C240 119.167 240.3 120.4 241.5 124C242.7 127.6 251 128 251 128L249.5 132C238 132.5 235.833 125.333 235 121.5L234.5 84H224.5V81L233 79L237 67.5Z" fill="#514F90" />
    <path d="M362 81L361 85.5C356 83.5 343 81.6071 338.5 84.5C334 87.3929 327.5 90.5 327.5 106.5C327.5 122.531 337.5 127 343.5 128C349.5 129 356.5 127 362 126V130C354 132.5 339 135 329.5 126C320 117 321.426 105.418 323 96.5C324.765 86.5 335.5 80 342.5 79C350.435 77.8664 357.5 79.5 362 81Z" fill="#514F90" />
    <path d="M381 57.5H375.5V131.5H381C381.167 121.167 380.649 101.307 381 98.5C381.5 94.5 384 83 400 83C410 83 414 87 415 96V131.5H420C419.833 118.333 420.136 96.5351 420 94.5C419.5 87 415.5 78.4545 397.5 79C387.505 79.3029 383.5 84.8333 381 88V57.5Z" fill="#514F90" />
    <path d="M470 82L468 86.5C468 86.5 458 82.5 452 83C445.647 83.5294 437.5 85 437.5 90.5C437.5 98.0017 445.743 100.011 452.5 102.5C462 106 471.5 108.5 471 119C470.347 132.714 451 132 444.5 132C439.552 132 435.667 130.833 431.5 129.5V124C434.5 127.5 452.147 128.744 456.5 127.5C463.5 125.5 466 122.5 466 117.5C466 112.5 455.5 108 448 106C440.5 104 433.238 99.6232 432.5 91.5C432 86 437.5 79.5 448 79C456.5 78.5952 466.5 80 470 82Z" fill="#514F90" />
    <path fillRule="evenodd" clipRule="evenodd" d="M290 79C297.871 79 305.107 82.5 308.5 92C310.5 97.6 310 102.833 310 106.5H269.5C270 115.5 273.535 125.486 283 127C295.5 129 302.5 127.5 307.5 124.5V129C301.5 131.5 298.5 132 290.5 132C278.5 132 274.242 127.833 272.5 126.5C270 124.588 264.067 120.439 264.5 104C264.9 88.8 272 79 290 79ZM304.5 102H270C270.5 96.5 272 83 288 83C304 83 304 96 304.5 102Z" fill="#514F90" />
    <path fillRule="evenodd" clipRule="evenodd" d="M484.5 79.5H489.5L490.5 87C493.333 83.8333 496.789 79.5117 506 79C515 78.5 523.625 80.5 528 87.5C533 95.5 534.278 109.11 530 119.5C526.5 128 519.5 133 504 132C496.846 131.538 489.5 123.5 489.5 123.5V154.5H485L484.5 79.5ZM508.5 83C501.783 82.9596 493 84.5367 490.5 97.5V113C492 119 493 127.606 507.5 128C522 128.394 527.332 117.123 527 105.5C526.5 88 519.875 83.0684 508.5 83Z" fill="#514F90" />
    <path fillRule="evenodd" clipRule="evenodd" d="M550 87L548 82.5C553 80.5 564.591 77.9573 570.5 79C579 80.5 584 85.5 584.5 91.5C584.966 97.0949 584.667 120.5 584.5 131.5H581L579.5 122.5C576.667 126.167 570.5 132 560.5 132C553.189 132 544 129.5 543 122C541.738 112.531 542.352 107.042 556.5 103C563.5 101 570.5 101.5 580 101C580.167 95.3333 580 89.5 575 85C571.716 82.0444 559.5 81.5 550 87ZM569.5 126.5C572.09 125.676 577.5 121.5 579.5 114.5V105.5C579.5 105.5 566.2 105.6 561 106C554.5 106.5 547.5 110.5 548 118.5C548.565 127.538 558.5 130 569.5 126.5Z" fill="#514F90" />
    <path d="M636.5 81L635.5 85.5C630.5 83.5 617.5 81.6071 613 84.5C608.5 87.3929 602 90.5 602 106.5C602 122.531 612 127 618 128C624 129 631 127 636.5 126V130C628.5 132.5 613.5 135 604 126C594.5 117 595.926 105.418 597.5 96.5C599.265 86.5 610 80 617 79C624.935 77.8664 632 79.5 636.5 81Z" fill="#514F90" />
    <path fillRule="evenodd" clipRule="evenodd" d="M670.5 79C678.371 79 685.607 82.5 689 92C691 97.6 690.5 102.833 690.5 106.5H650C650.5 115.5 654.035 125.486 663.5 127C676 129 683 127.5 688 124.5V129C682 131.5 679 132 671 132C659 132 654.742 127.833 653 126.5C650.5 124.588 644.567 120.439 645 104C645.4 88.8 652.5 79 670.5 79ZM685 102H650.5C651 96.5 652.5 83 668.5 83C684.5 83 684.5 96 685 102Z" fill="#514F90" />
  </svg>
);
