import styled from 'styled-components';
import Icon from '@ant-design/icons';

export const ImageWithDescriptionWrapper = styled.div`    
  display: grid;
  place-items: center;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primaryColorHover} !important;
    
    div,
    svg {
      color: ${({ theme }) => theme.colors.primaryColorHover};
    }
  }
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.medium};
  margin-top: 7px;
  line-height: 1;
  width: 140px;
  text-align: center;
  color: ${({ theme, selected, disabled }) => {
    if (selected) {
      return disabled ? theme.colors.primaryColorDisabled : theme.colors.primaryColor;
    }
    return disabled ? theme.colors.gray_06 : theme.colors.textColor;
  }};
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.09);
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  height: ${({ size }) => (size ? `${size}px` : '200px')};
  min-width: ${({ size }) => (size ? `${size}px` : '200px')};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  border-width: 2px;
  border-style: solid;
  border-radius: ${({ theme }) => theme.other.borderRadius};
  border-color: ${({ theme, selected, disabled, bordered }) => {
    if (bordered) {
      if (selected) {
        return theme.colors.primaryColor;
      }
      return disabled ? theme.colors.primaryColorDisabled : theme.colors.gray_03;
    }
    return 'transparent';
  }};
  color: ${({ theme, selected, disabled }) => {
    if (selected) {
      return disabled ? theme.colors.primaryColorDisabled : theme.colors.primaryColor;
    }
    return disabled ? theme.colors.gray_06 : theme.colors.textColor;
  }};
`;

export const StyledImage = styled.img`
  filter: ${({ bordered, selected }) => (!bordered && selected) && 'invert(18%) sepia(86%) saturate(1995%) hue-rotate(217deg) brightness(101%) contrast(103%)'};
  height: ${({ size }) => (size ? `${size - 4}px` : '200px')};
  min-width: ${({ size }) => (size ? `${size - 4}px` : '200px')};
  border-radius: ${({ theme }) => theme.other.borderRadius};
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme, selected, disabled }) => {
    if (selected) {
      return disabled ? theme.colors.primaryColorDisabled : theme.colors.primaryColor;
    }
    return disabled ? theme.colors.gray_06 : theme.colors.textColor;
  }};
  
  svg {
    width: ${({ size }) => (size ? `${size}px` : 'unset')};
    height: ${({ size }) => (size ? `${size}px` : 'unset')};
  }
`;

export const InfoDescriptionWrapper = styled.div`
  display: flex;
  width: 350px;
  font-weight: ${({ theme }) => theme.font.weight.light};
`;

export const InfoDescriptionImage = styled.img`
  width: 120px;
  height: 80px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: ${({ theme }) => theme.other.borderRadius};
`;
