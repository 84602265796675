// import { polygonOffsetMaterialProps } from './ThreeHelpers';
import * as THREE from 'three';

const renderProps = {
  mass: {
    renderOrder: 1500,
  },
  massLines: {
    renderOrder: 2000,
  },
  massRotator: {
    renderOrder: 2000,
  },
  draggableMassPlane: {
    renderOrder: 2000,
  },
  terrain: {
    renderOrder: -50,
    materialProps: {
      color: new THREE.Color(1.1, 1.1, 1.1),
    },
  },
  surroundingBuildings: {
    renderOrder: 1250,
    materialProps: {
      color: '#f1f1f1',
    },
  },
  vegetation: {
    renderOrder: 800,
    materialProps: {
      // ...polygonOffsetMaterialProps(400),
    },
  },
  water: {
    renderOrder: 800,
    materialProps: {
      // ...polygonOffsetMaterialProps(400),
    },
  },
  roads: {
    renderOrder: 900,
    materialProps: {
      // ...polygonOffsetMaterialProps(40),
    },
  },
  parkingPolygon: {
    renderOrder: 1000,
    materialProps: {
      depthTest: false, // tmp - hack
    },
  },
  general: {
    renderOrder: 950,
    materialProps: {
      // ...polygonOffsetMaterialProps(300),
    },
  },
};

export const getRenderPropsByName = (name) => {
  if (renderProps[name]) {
    return renderProps[name];
  }

  if (name.includes('vegetation')) {
    return renderProps.vegetation;
  }

  if (name.includes('water')) {
    return renderProps.water;
  }

  if (name.includes('road') || name.includes('path') || name.includes('pedestrian')) {
    return renderProps.roads;
  }

  if (name.toLowerCase().includes('building')) {
    return renderProps.surroundingBuildings;
  }

  if (name.includes('Terrain')) {
    return renderProps.terrain;
  }

  if (name.includes('map_')) {
    return renderProps.general;
  }

  return {};
};
