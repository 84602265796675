import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedViewId: 0,
  cameraAngle: 0,
  cameraFov: 75,
};

const threeDWalkthroughSlice = createSlice({
  name: 'threeDWalkthrough',
  initialState,
  reducers: {
    setSelectedViewId(state, action) {
      state.selectedViewId = action.payload;
    },
    setCameraFov(state, action) {
      state.cameraFov = action.payload;
    },
    setCameraAngle(state, action) {
      const cameraAngle = action.payload;
      if (Math.abs(cameraAngle - state.cameraAngle) > Math.PI / 180) {
        state.cameraAngle = cameraAngle;
      }
    },
    resetToInitialState(state) {
      state.selectedViewId = 0;
      state.cameraAngle = 0;
      state.cameraFov = 75;
    },
  },
});

export const { setSelectedViewId, resetToInitialState, setCameraFov, setCameraAngle } = threeDWalkthroughSlice.actions;
export default threeDWalkthroughSlice.reducer;

// =========== Selectors =========== //
export const selectedViewIdSelector = ({ threeDWalkthrough }) => threeDWalkthrough.selectedViewId;
export const cameraAngleSelector = ({ threeDWalkthrough }) => threeDWalkthrough.cameraAngle;
export const cameraFovSelector = ({ threeDWalkthrough }) => threeDWalkthrough.cameraFov;
