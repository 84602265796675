import React from 'react';
import { Tooltip } from 'antd';
import lodashIncludes from 'lodash/includes';
import T from 'i18n-react';
import {
  CellsContainer,
  EllipsisText,
  HighlightCell,
  SecondaryHeaderCell,
} from 'styles/commonComponents.styles';
import { ColorPill } from 'components/feasibility/studies/form/FeasibilityTargetFormContainer.Styles';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { sqfToSqm, sqfToSqmText } from 'utils/helpers/unitsHelpers';

export const COLUMN_TYPES = {
  NAME: 'NAME',
  USER_REQUIREMENT: 'USER_REQUIREMENT',
  MODEL: 'MODEL',
};

export const getExploreData = (type, columnType, isImperial) => {
  const exploreData = {
    RSF: {
      [COLUMN_TYPES.NAME]: {
        children: [
          { title: () => <SecondaryHeaderCell highlight first />, dataIndex: 'color', key: 'color', width: 10, render: (color) => color && <ColorPill color={color} /> },
          { title: () => <SecondaryHeaderCell highlight />, dataIndex: 'name', key: 'name', width: 150, render: (value) => T.translate(value) },
        ],
      },
      [COLUMN_TYPES.MODEL]: {
        align: 'center',
        children: [
          {
            title: () => <SecondaryHeaderCell highlight>{T.translate('OVERALL_CONTAINER_RSF_COLUMN')}</SecondaryHeaderCell>,
            dataIndex: 'modelRatio',
            key: 'modelRatio',
            width: 170,
            align: 'center',
            render: (value, rowData) => (rowData.targetRatio
              ? <CellsContainer><HighlightCell>{value}</HighlightCell>/{rowData.targetRatio}</CellsContainer>
              : <HighlightCell status={rowData.modelRatioStatus}>{value}</HighlightCell>),
          },
          {
            title: () => <SecondaryHeaderCell highlight last>{T.translate(isImperial ? 'SQF' : 'SQM')}</SecondaryHeaderCell>,
            dataIndex: 'modelArea',
            key: 'modelArea',
            width: 100,
            align: 'center',
            render: (value) => <HighlightCell>{value}</HighlightCell>,
          },
        ],
      },
    },

    DEPARTMENTS: {
      [COLUMN_TYPES.NAME]: {
        children: [
          { title: () => <SecondaryHeaderCell highlight first />, dataIndex: 'color', key: 'color', width: 1, render: (color) => color && <ColorPill color={color} /> },
          { title: () => <SecondaryHeaderCell highlight />, dataIndex: 'name', key: 'name', render: (value) => (<Tooltip mouseEnterDelay={1} title={value}><EllipsisText width={100}>{value}</EllipsisText></Tooltip>) },
        ],
      },
      [COLUMN_TYPES.MODEL]: {
        align: 'center',
        children: [
          { title: () => <SecondaryHeaderCell highlight>{T.translate('OVERALL_CONTAINER_MEMBERS_NUM')}</SecondaryHeaderCell>, dataIndex: 'modelMembers', key: 'modelMembers', align: 'center', width: 90, render: (value) => <HighlightCell>{value}</HighlightCell> },
          { title: () => <SecondaryHeaderCell highlight>{T.translate('OVERALL_CONTAINER_ROOM_NUM')}</SecondaryHeaderCell>, dataIndex: 'modelRooms', key: 'modelRooms', align: 'center', width: 90, render: (value, rowData) => <HighlightCell>{`${numberWithComma(value)} / ${numberWithComma(rowData.targetRooms) || 0}`}</HighlightCell> },
          { title: () => <SecondaryHeaderCell highlight last>{T.translate(isImperial ? 'OVERALL_CONTAINER_SQFT_IN_MODEL' : 'OVERALL_CONTAINER_SQM_IN_MODEL')}</SecondaryHeaderCell>, dataIndex: 'modelArea', key: 'modelArea', align: 'center', width: 90, render: (value) => <HighlightCell>{sqfToSqmText(value, isImperial)}</HighlightCell> },
        ],
      },
    },

    LSF: {
      [COLUMN_TYPES.NAME]: {
        children: [
          { title: () => <SecondaryHeaderCell highlight first />, dataIndex: 'color', key: 'color', width: 10, render: (color) => color && <ColorPill color={color} /> },
          { title: () => <SecondaryHeaderCell highlight />, dataIndex: 'name', key: 'name', render: (value) => (<Tooltip mouseEnterDelay={1} title={value}><EllipsisText width={100}>{value}</EllipsisText></Tooltip>) },
        ],
      },
      [COLUMN_TYPES.MODEL]: {
        align: 'center',
        children: [
          { title: () => <SecondaryHeaderCell highlight>{T.translate('OVERALL_CONTAINER_TARGET_IN_MODAL')}</SecondaryHeaderCell>,
            dataIndex: 'modelRatio',
            key: 'modelRatio',
            align: 'center',
            width: 90,
            render: (value, rowData) => (rowData.targetRatio
              ? <CellsContainer>{`${value} / ${rowData.targetRatio} %`}</CellsContainer>
              : <HighlightCell status={rowData.modelRatioStatus}>{value}</HighlightCell>),
          },
          { title: () => <SecondaryHeaderCell highlight>{T.translate('OVERALL_CONTAINER_AVERAGE_ROOM_SIZE')}</SecondaryHeaderCell>, dataIndex: 'modelAverage', key: 'modelAverage', align: 'center', width: 70, render: (value) => <HighlightCell>{value}</HighlightCell> },
          { title: () => <SecondaryHeaderCell highlight>{T.translate('OVERALL_CONTAINER_MEMBERS_NUM')}</SecondaryHeaderCell>, dataIndex: 'modelMembers', key: 'modelMembers', align: 'center', width: 90, render: (value) => <HighlightCell>{value}</HighlightCell> },
          { title: () => <SecondaryHeaderCell highlight>{T.translate('OVERALL_CONTAINER_ROOM_NUM')}</SecondaryHeaderCell>,
            dataIndex: 'modelRooms',
            key: 'modelRooms',
            align: 'center',
            width: 90,
            render: (value, rowData) => <CellsContainer>{`${value} / ${rowData.targetRooms || 0}`}</CellsContainer>,
          },
          { title: () => <SecondaryHeaderCell highlight last>{T.translate(isImperial ? 'OVERALL_CONTAINER_SQFT_IN_MODEL' : 'OVERALL_CONTAINER_SQM_IN_MODEL')}</SecondaryHeaderCell>, dataIndex: 'modelArea', key: 'modelArea', align: 'center', width: 90, render: (value) => <HighlightCell>{value}</HighlightCell> },
        ],
      },
    },

    PSF: {
      [COLUMN_TYPES.NAME]: {
        children: [
          { title: () => <SecondaryHeaderCell highlight first />, dataIndex: 'color', key: 'color', width: 10, render: (color) => color && <ColorPill color={color} /> },
          { title: () => <SecondaryHeaderCell highlight />, dataIndex: 'name', key: 'name', render: (value) => (<Tooltip mouseEnterDelay={1} title={value}><EllipsisText width={170}>{value}</EllipsisText></Tooltip>) },
        ],
      },
      [COLUMN_TYPES.MODEL]: {
        align: 'center',
        children: [

          {
            title: () => <SecondaryHeaderCell highlight>{T.translate('OVERALL_CONTAINER_SEATS_NUM')}</SecondaryHeaderCell>,
            dataIndex: 'modelSeats',
            key: 'modelSeats',
            width: 90,
            align: 'center',
            render: (value, rowData) => value && <CellsContainer>{`${value} / ${rowData.targetSeats}`}</CellsContainer>,
          },
          {
            title: () => <SecondaryHeaderCell highlight>{T.translate('OVERALL_CONTAINER_ROOM_NUM')}</SecondaryHeaderCell>,
            dataIndex: 'modelRooms',
            key: 'modelRooms',
            width: 90,
            align: 'center',
            render: (value, rowData) => <CellsContainer>{`${value} / ${rowData.targetRooms || rowData.targetRatio || 0}`}</CellsContainer>,
          },
          {
            title: () => <SecondaryHeaderCell highlight last>{T.translate(isImperial ? 'OVERALL_CONTAINER_SQFT_IN_MODEL' : 'OVERALL_CONTAINER_SQM_IN_MODEL')}</SecondaryHeaderCell>,
            dataIndex: 'modelArea',
            key: 'modelArea',
            width: 90,
            align: 'center',
            render: (value) => <HighlightCell>{(value)}</HighlightCell>,
          },
        ],
      },
    },

    ASF: {
      [COLUMN_TYPES.NAME]: {
        children: [
          { title: () => <SecondaryHeaderCell highlight first />, dataIndex: 'color', key: 'color', width: 10, render: (color) => color && <ColorPill color={color} /> },
          { title: () => <SecondaryHeaderCell highlight />, dataIndex: 'name', key: 'name' },
        ],
      },
      [COLUMN_TYPES.MODEL]: {
        align: 'center',
        children: [
          {
            title: () => <SecondaryHeaderCell highlight last>{T.translate(isImperial ? 'SQF' : 'SQM')}{T.translate('OVERALL_CONTAINER_AREA_FSF')}</SecondaryHeaderCell>,
            dataIndex: 'modelArea',
            key: 'modelArea',
            width: 90,
            align: 'center',
            render: (value, rowData) => (rowData.targetArea ? <CellsContainer>{`${value} / ${rowData.targetArea}`}</CellsContainer> : value),
          },
        ],
      },
    },

    FSF: {
      [COLUMN_TYPES.NAME]: {
        children: [
          { title: () => <SecondaryHeaderCell highlight first />, dataIndex: 'color', key: 'color', width: 10, render: (color) => color && <ColorPill color={color} /> },
          { title: () => <SecondaryHeaderCell highlight />, dataIndex: 'name', key: 'name' },
        ],
      },
      [COLUMN_TYPES.MODEL]: {
        align: 'center',
        children: [
          {
            title: () => <SecondaryHeaderCell highlight>{T.translate('OVERALL_CONTAINER_ROOM_NUM')}</SecondaryHeaderCell>,
            dataIndex: 'modelRooms',
            key: 'modelRooms',
            width: 90,
            align: 'center',
            render: (value, rowData) => <CellsContainer>{`${value} / ${rowData.targetRooms}`}</CellsContainer>,
          },
          {
            title: () => <SecondaryHeaderCell highlight last>{T.translate(isImperial ? 'SQF' : 'SQM')}{T.translate('OVERALL_CONTAINER_AREA_FSF')}</SecondaryHeaderCell>,
            dataIndex: 'modelArea',
            key: 'modelArea',
            width: 90,
            align: 'center',
            render: (value, rowData) => <CellsContainer>{`${value} / ${rowData.targetArea}`}</CellsContainer>,
          },
        ],
      },
    },

    ROOMS: {
      [COLUMN_TYPES.NAME]: {
        children: [
          {
            title: () => <SecondaryHeaderCell highlight first />,
            dataIndex: 'color',
            key: 'color',
            width: 10,
            render: (color) => color && <ColorPill color={color} />,
          },
          {
            title: () => <SecondaryHeaderCell highlight>{T.translate('Category')}</SecondaryHeaderCell>,
            dataIndex: 'category',
            key: 'category',
            width: 70,
            align: 'left',
            render: (value) => <HighlightCell>{value}</HighlightCell>,
          },
          { title: () => <SecondaryHeaderCell highlight>{T.translate('Name')}</SecondaryHeaderCell>, width: 70, dataIndex: 'name', key: 'name' },
        ],
      },
      [COLUMN_TYPES.MODEL]: {
        align: 'center',
        children: [
          {
            title: () => <SecondaryHeaderCell highlight>{T.translate('Percentage')}</SecondaryHeaderCell>,
            dataIndex: 'areaPercentage',
            key: 'areaPercentage',
            width: 90,
            align: 'center',
            render: (value, rowData) => (
              <HighlightCell>
                {lodashIncludes(['LSF', 'PSF'], rowData.category)
                  ? numberWithComma(value, { suffix: '%', fixed: 2 })
                  : 0}
              </HighlightCell>
            ),
          },
          {
            title: () => <SecondaryHeaderCell highlight>{`Area ${T.translate(isImperial ? 'SQF' : 'SQM')}`}</SecondaryHeaderCell>,
            dataIndex: 'area',
            key: 'area',
            width: 90,
            align: 'center',
            render: (value) => <HighlightCell>{numberWithComma(isImperial ? value : sqfToSqm(value), { fixed: isImperial ? 0 : 1 })}</HighlightCell>,
          },
          {
            title: () => <SecondaryHeaderCell highlight>{T.translate('Seats')}</SecondaryHeaderCell>,
            dataIndex: 'seats',
            key: 'seats',
            width: 90,
            align: 'center',
            render: (value) => <HighlightCell>{value}</HighlightCell>,
          },
          // {
          //   title: () => <SecondaryHeaderCell highlight>{T.translate('hasWalls')}</SecondaryHeaderCell>,
          //   dataIndex: 'hasWalls',
          //   key: 'hasWalls',
          //   width: 90,
          //   align: 'center',
          //   render: (value) => <HighlightCell>{value ? 'true' : 'false'}</HighlightCell>,
          // },
          // {
          //   title: () => <SecondaryHeaderCell highlight>{T.translate('Department')}</SecondaryHeaderCell>,
          //   dataIndex: 'department',
          //   key: 'department',
          //   width: 90,
          //   align: 'center',
          //   render: (value) => <HighlightCell>{value?.departmentName}</HighlightCell>,
          // },
          {
            title: () => <SecondaryHeaderCell highlight last>{T.translate('Floor')}</SecondaryHeaderCell>,
            dataIndex: 'floor',
            key: 'floor',
            width: 90,
            align: 'center',
            render: (value) => <HighlightCell>{value}</HighlightCell>,
          },
        ],
      },
    },
  };

  return exploreData[type][columnType];
};
