import React from 'react';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { DATE_OPTIONS } from 'constants/dateConsts';
import { renderProjectName } from 'utils/helpers/dataDisplay';
import { DISPLAY_TYPES } from 'constants/testFitConsts';
import PageTemplate from './PageTemplate';
import {
  FirstPageContainer, FirstPageInfo, FirstPageInfoLine, FirstPageInfoText, FirstPageInfoTitle,
  FirstPageLogo, FirstPageLogoWrapper, PlanIndexListFlex, PlanIndexListWrapper,
} from './PdfExportContainer.styles';
import companyIcons from '../../styles/static/icons/companyIcons';
import FormWithLabel from '../common/FormWithLabel';
import icons from '../../styles/static/icons/testFit';
import ExploreDataContainer from '../testFit/study/result/program/ProfileDataTablesContainer';
import DonutChart from '../common/DonutChart';
import ProfileBathroomsDataPanel from '../testFit/study/result/program/ProfileBathroomsDataPanel';
import PlanIndexList from '../common/PlanIndexList';
import {
  getProfileByIdSelector,
  loadingSelector,
  swappProfileResultsSelector,
  swappProjectSelector,
} from '../../store/swappProfile';
import { userSelector } from '../../store/user';
import LoadingSpinner from '../common/LoadingSpinner';
import { getUnitSystemByProjectIdSelector } from '../../store/userSettings';

const dataViewWidth = 600;

const TestFitPdf = () => {
  const match = useRouteMatch();
  const swappProject = useSelector(swappProjectSelector);
  const isLoading = useSelector(loadingSelector);
  const user = useSelector(userSelector);
  const currentProfile = useSelector((state) => getProfileByIdSelector(state, match.params.profileId));
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));
  const swappProfileResults = useSelector((state) => swappProfileResultsSelector(state, match.params.profileId, isImperial));

  const parentProfile = lodashGet(swappProject, 'projectProfiles').find((profile) => !profile.parentProfileId);
  const projectPdfInfo = lodashGet(parentProfile, 'profileData.settings');
  const userPdfInfo = lodashGet(user, 'settings.flags') || lodashGet(swappProject, 'user.settings');

  const companyKey = lodashGet(projectPdfInfo, 'companyLogoName') || lodashGet(userPdfInfo, 'companyLogoName') || 'swapp';
  const companyColor = companyIcons[companyKey].color;

  if (isLoading || !swappProfileResults || !swappProject) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {/* ========= page 1, with company logo and project info ========= */}
      <PageTemplate id={0} companyColor={companyColor} companyKey={companyKey} swappProject={swappProject} hideHeader footerWithColor>
        <FirstPageContainer>
          <FirstPageLogoWrapper companyColor={companyColor}>
            <FirstPageLogo component={companyIcons[companyKey].big} />
          </FirstPageLogoWrapper>
          <FirstPageInfo>
            <FirstPageInfoTitle>{renderProjectName(swappProject.name)}</FirstPageInfoTitle>
            <FirstPageInfoLine companyColor={companyColor} />
            <FirstPageInfoText>{`${T.translate('PDF_EXPORT_TEST_FIT')} - ${currentProfile.name}`}</FirstPageInfoText>
            <FirstPageInfoText small marginTop={20}>{swappProject.locationName}</FirstPageInfoText>
            <FirstPageInfoText small>{new Date().toLocaleDateString('en-US', DATE_OPTIONS)}</FirstPageInfoText>
          </FirstPageInfo>
        </FirstPageContainer>
      </PageTemplate>

      {/* ========= page 2, with RSF, LSF, PSF ========= */}
      <PageTemplate id={1} companyColor={companyColor} companyKey={companyKey} swappProject={swappProject} center>
        {/* ========= RSF ========= */}
        <FormWithLabel disableAnimation explore iconComponent={icons.rsfIcon} width={dataViewWidth} title={T.translate('OVERALL_CONTAINER_RSF')} subtitle={T.translate('OVERALL_CONTAINER_RENTABLE')}>
          <ExploreDataContainer type="RSF" data={lodashGet(swappProfileResults, 'RSF')} />
          {lodashGet(swappProfileResults, 'RSF.chartData') && <DonutChart disableAnimation data={swappProfileResults.RSF.chartData} colorSetKey="RSF" width="410" />}
        </FormWithLabel>

        {/* ========= LSF ========= */}
        <FormWithLabel disableAnimation explore iconComponent={icons.lsfIcon} width={550} title={T.translate('OVERALL_CONTAINER_LSF')} subtitle={T.translate('OVERALL_CONTAINER_LEASABLE_AREA')}>
          <ExploreDataContainer displayTypeProp={DISPLAY_TYPES.LIST} type="LSF" data={lodashGet(swappProfileResults, 'LSF')} />
          {lodashGet(swappProfileResults, 'LSF.chartData') && <DonutChart disableAnimation data={swappProfileResults.LSF.chartData} colorSet={swappProfileResults.LSF.chartDataColors} width="410" />}
        </FormWithLabel>

        <div>
          {/* ========= PSF A ========= */}
          <FormWithLabel disableAnimation explore iconComponent={icons.psfIcon} width={dataViewWidth} title={T.translate('OVERALL_CONTAINER_PSF')} subtitle={T.translate('OVERALL_CONTAINER_PRODUCTIVE_AREA')}>
            <ExploreDataContainer displayTypeProp={DISPLAY_TYPES.LIST} type="PSF" data={lodashGet(swappProfileResults, 'PSF_A')} />
          </FormWithLabel>

          {/* ========= PSF B ========= */}
          {!lodashIsEmpty(lodashGet(swappProfileResults, 'PSF_B.tableData')) && (
          <FormWithLabel disableAnimation explore width={dataViewWidth}>
            <ExploreDataContainer
              displayTypeProp={DISPLAY_TYPES.LIST}
              type="PSF"
              hideHeader
              data={lodashGet(swappProfileResults, 'PSF_B')}
            />
          </FormWithLabel>
          )}
        </div>
      </PageTemplate>

      {/* ========= page 3, with ASF, FSF, BATHROOMS ========= */}
      <PageTemplate id={2} companyColor={companyColor} companyKey={companyKey} swappProject={swappProject} center>
        {/* ========= ASF ========= */}
        {!lodashIsEmpty(lodashGet(swappProfileResults, 'ASF.tableData')) && (
          <FormWithLabel disableAnimation explore iconComponent={icons.asfIcon} width={650} title={T.translate('OVERALL_CONTAINER_ASF')} subtitle={T.translate('OVERALL_CONTAINER_AMENITY_SPACES')}>
            <ExploreDataContainer displayTypeProp={DISPLAY_TYPES.LIST} type="ASF" data={lodashGet(swappProfileResults, 'ASF')} />
          </FormWithLabel>
        )}

        {/* ========= FSF ========= */}
        {!lodashIsEmpty(lodashGet(swappProfileResults, 'FSF.tableData')) && (
          <FormWithLabel disableAnimation explore iconComponent={icons.fsfIcon} width={dataViewWidth} title={T.translate('OVERALL_CONTAINER_FSF')} subtitle={T.translate('OVERALL_CONTAINER_FACILITY_SPACES')}>
            <ExploreDataContainer displayTypeProp={DISPLAY_TYPES.LIST} type="FSF" data={lodashGet(swappProfileResults, 'FSF')} />
          </FormWithLabel>
        )}

        {/* ========= BATHROOMS ========= */}
        <FormWithLabel disableAnimation explore iconComponent={icons.restroomIcon} width={dataViewWidth} title={T.translate('OVERALL_CONTAINER_BATHROOMS')} subtitle={T.translate('AREA_TARGET_FORM_CONTAINER_BATHROOMS_DESCRIPTION')}>
          <ProfileBathroomsDataPanel data={lodashGet(swappProfileResults, 'bathrooms')} />
        </FormWithLabel>
      </PageTemplate>

      {/* ========= Empty page for the plans. if you change id plz update 'emptyPageIndex' in 'embedPlans' */}
      <PageTemplate id={3} companyColor={companyColor} companyKey={companyKey} user={user} swappProject={swappProject}>
        <PlanIndexListWrapper>
          <PlanIndexListFlex>
            <PlanIndexList type="AREA_TARGET_FORM_CONTAINER_LSF" data={lodashGet(swappProfileResults, 'LSF.tableData').filter((e) => e.color)} />
            <PlanIndexList type="AREA_TARGET_FORM_CONTAINER_PSF" data={[...lodashGet(swappProfileResults, 'PSF_A.tableData'), ...lodashGet(swappProfileResults, 'PSF_B.tableData')].filter((e) => e.color)} />
          </PlanIndexListFlex>
          <PlanIndexListFlex>
            <PlanIndexList type="AREA_TARGET_FORM_CONTAINER_ASF" data={lodashGet(swappProfileResults, 'ASF.tableData').filter((e) => e.color)} />
            <PlanIndexList type="AREA_TARGET_FORM_CONTAINER_FSF" data={lodashGet(swappProfileResults, 'FSF.tableData').filter((e) => e.color)} />
          </PlanIndexListFlex>
        </PlanIndexListWrapper>
      </PageTemplate>

      {/* ========= departments legend ========= */}
      <PageTemplate id={4} companyColor={companyColor} companyKey={companyKey} user={user} swappProject={swappProject}>
        <PlanIndexListWrapper>
          <PlanIndexListFlex>
            <PlanIndexList type="AREA_TARGET_FORM_CONTAINER_LSF" data={lodashGet(swappProfileResults, 'departments', []).filter((e) => e.color)} />
          </PlanIndexListFlex>
        </PlanIndexListWrapper>
      </PageTemplate>
    </>
  );
};

export default TestFitPdf;
