import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import T from 'i18n-react';
import { useHistory } from 'react-router';
import { feasibilityReportSelector } from 'store/swappProfile/selectors';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import {
  ContentImage,
  ContentImageLabel,
  ContentImagesWithLabelWrapper,
  ContentImagesWrapper,
  ContentTitle,
  ContentWrapper,
  VerticalTitleContentWrapper,
  ContentColumnWrapper,
  StyledText,
  ContentRowWrapper,
  RowLegendWrapper,
  RowLegendColor,
} from '../ReportPages.styles';
import ReportTitleDropdown from '../ReportTitleDropdown';

const description = 'The images show the indicative direct sun exposure during the equinox and Solstice of 2021\n\nNote: 3D model and associated shadow and solar studies are indicative and subject to receiving accurate survey/Zmap with AOD levels.';
const legend = {
  existing: [
    { color: '#FCFC99', text: '0 hr' },
    { color: '#FFFF66' },
    { color: '#FFFF00' },
    { color: '#FFE100' },
    { color: '#FEC000' },
    { color: '#FE9E00' },
    { color: '#FC7800', text: '6 hr' },
    { color: '#F94800' },
    { color: '#E71000' },
    { color: '#C30800' },
    { color: '#9D0400' },
    { color: '#7A0200' },
    { color: '#5A0100', text: '12 hr', highlight: true },
  ],
  designsEffect: [
    { color: '#146FAB', text: '-6 hr' },
    { color: '#3483B7' },
    { color: '#5497C4' },
    { color: '#74ACD0' },
    { color: '#94C0DD' },
    { color: '#B4D4E9' },
    { color: '#D6D5D5', text: 'as is' },
    { color: '#E0B8CB' },
    { color: '#D8A6BE' },
    { color: '#D195B1' },
    { color: '#C983A4' },
    { color: '#C27297' },
    { color: '#BA608A', text: '+6 hr' },
  ],
};

const RightOfLight = () => {
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const reportData = useSelector((state) => feasibilityReportSelector(state, locationData.profileId));
  const rightOfLightData = lodashGet(reportData, 'environment.rightOfLightData');
  const [currentDateIndex, setCurrentDateIndex] = useState(0);

  if (lodashIsEmpty(rightOfLightData)) {
    return null;
  }

  const updateCurrentDateIndex = (key, update) => {
    update(key);
  };

  return (
    <VerticalTitleContentWrapper>
      <ContentTitle>{T.translate('Solar Study')}</ContentTitle>

      <ContentRowWrapper>
        <ContentColumnWrapper width={250}>

          {/* ======== dropdown ======== */}
          <ReportTitleDropdown
            options={rightOfLightData.map((item) => item.name)}
            handleChange={(key) => updateCurrentDateIndex(key, setCurrentDateIndex)}
            selectedIndex={currentDateIndex}
          />

          {/* ======== description ======== */}
          <StyledText size="xSmall">{T.translate(description)}</StyledText>
        </ContentColumnWrapper>

        {/* ======== images ======== //TODO - duplicate code, marge */}
        <ContentWrapper>
          <StyledText bold>{T.translate('Existing Sun Exposure')}</StyledText>
          <RowLegendWrapper>
            {legend.existing.map((item) => <RowLegendColor color={item.color} highlight={item.highlight}>{item.text}</RowLegendColor>)}
          </RowLegendWrapper>
          <ContentImagesWrapper width={415}>
            {rightOfLightData[currentDateIndex].existingImages.map((image, index) => (
              <ContentImagesWithLabelWrapper key={index}>
                <ContentImage src={image.url} width={415} height={280} background />
                {lodashGet(image, 'label') && <ContentImageLabel>{`0${index + 1} - ${image.label}`}</ContentImageLabel>}
              </ContentImagesWithLabelWrapper>
            ))}
          </ContentImagesWrapper>

          <StyledText bold>{T.translate('Design\'s Effect on Sun Exposure')}</StyledText>
          <RowLegendWrapper>
            {legend.designsEffect.map((item) => <RowLegendColor color={item.color} highlight={item.highlight}>{item.text}</RowLegendColor>)}
          </RowLegendWrapper>
          <ContentImagesWrapper width={415}>
            {rightOfLightData[currentDateIndex].images.map((image, index) => (
              <ContentImagesWithLabelWrapper key={index}>
                <ContentImage src={image.url} width={415} height={280} background />
                {lodashGet(image, 'label') && <ContentImageLabel>{`0${index + 1} - ${image.label}`}</ContentImageLabel>}
              </ContentImagesWithLabelWrapper>
            ))}
          </ContentImagesWrapper>
        </ContentWrapper>
      </ContentRowWrapper>
    </VerticalTitleContentWrapper>
  );
};

export default React.memo(RightOfLight);
