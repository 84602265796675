import React from 'react';

export default () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="16.2397" y="5.24023" width="29" height="19" fill="#5772ff" />
    <path fillRule="evenodd" clipRule="evenodd" d="M45.054 5C45.2948 5.04492 45.4834 5.2785 45.4798 5.52106V44.8584C45.4798 45.3238 45.2462 45.4783 44.7916 45.4783H5.52465C5.24974 45.4783 5.00179 45.2304 5 44.9573V16.6902C5 16.4171 5.24975 16.1692 5.52465 16.1692H15.8687V5.52295C15.8687 5.24984 16.1184 5.00189 16.3933 5.00189H45.0541L45.054 5ZM44.4323 6.04392H16.9182V44.436H24.4306V35.7466C24.4306 35.4735 24.6803 35.2255 24.9552 35.2255H36.3972C36.6721 35.2255 36.92 35.4735 36.9218 35.7466V44.436H44.4342L44.4323 6.04392ZM15.8704 17.2108H6.0489V44.436H15.8704V17.2108ZM35.8721 36.2677H25.4775V44.436H35.8721V36.2677Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M26.2741 18.2423C26.5634 18.2458 26.7951 18.483 26.7898 18.7705V21.6921C26.7934 21.8322 26.7394 21.967 26.6406 22.0676C26.5418 22.1682 26.407 22.2239 26.2669 22.2239C26.1249 22.2239 25.9902 22.1682 25.8932 22.0676C25.7943 21.967 25.7404 21.8322 25.7422 21.6921V18.7705C25.7404 18.6303 25.7961 18.4938 25.8968 18.395C25.9974 18.2943 26.1339 18.2404 26.2741 18.2422L26.2741 18.2423Z" fill="#F9F9F9" />
    <path fillRule="evenodd" clipRule="evenodd" d="M26.2741 10.4605C26.5634 10.4641 26.7951 10.7013 26.7898 10.9888V13.9104C26.7934 14.0505 26.7394 14.1853 26.6406 14.2859C26.5418 14.3865 26.407 14.4422 26.2669 14.4422C26.1249 14.4422 25.9902 14.3865 25.8932 14.2859C25.7943 14.1853 25.7404 14.0505 25.7422 13.9104V10.9888C25.7404 10.8486 25.7961 10.712 25.8968 10.6132C25.9974 10.5126 26.1339 10.4587 26.2741 10.4605L26.2741 10.4605Z" fill="#F9F9F9" />
    <path fillRule="evenodd" clipRule="evenodd" d="M26.2741 26.0245C26.5634 26.0281 26.7951 26.2652 26.7898 26.5527V29.4743C26.7934 29.6145 26.7394 29.7492 26.6406 29.8498C26.5418 29.9505 26.407 30.0062 26.2669 30.0062C26.1249 30.0062 25.9902 29.9505 25.8932 29.8498C25.7943 29.7492 25.7404 29.6145 25.7422 29.4743V26.5527C25.7404 26.4126 25.7961 26.276 25.8968 26.1772C25.9974 26.0766 26.1339 26.0227 26.2741 26.0245L26.2741 26.0245Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.8396 18.2423C22.1289 18.2458 22.3589 18.483 22.3553 18.7705V21.6921C22.3571 21.8322 22.3032 21.967 22.2062 22.0676C22.1074 22.1682 21.9726 22.2239 21.8307 22.2239C21.6905 22.2239 21.5557 22.1682 21.4569 22.0676C21.3581 21.967 21.3042 21.8322 21.3078 21.6921V18.7705C21.306 18.6303 21.3599 18.4938 21.4605 18.395C21.5611 18.2943 21.6977 18.2404 21.8396 18.2422V18.2423Z" fill="#F9F9F9" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.8396 10.4605C22.1289 10.4641 22.3589 10.7013 22.3553 10.9888V13.9104C22.3571 14.0505 22.3032 14.1853 22.2062 14.2859C22.1074 14.3865 21.9726 14.4422 21.8307 14.4422C21.6905 14.4422 21.5557 14.3865 21.4569 14.2859C21.3581 14.1853 21.3042 14.0505 21.3078 13.9104V10.9888C21.306 10.8486 21.3599 10.712 21.4605 10.6132C21.5611 10.5126 21.6977 10.4587 21.8396 10.4605V10.4605Z" fill="#F9F9F9" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.8396 26.0245C22.1289 26.0281 22.3589 26.2652 22.3553 26.5527V29.4743C22.3571 29.6145 22.3032 29.7492 22.2062 29.8498C22.1074 29.9505 21.9726 30.0062 21.8307 30.0062C21.6905 30.0062 21.5557 29.9505 21.4569 29.8498C21.3581 29.7492 21.3042 29.6145 21.3078 29.4743V26.5527C21.306 26.4126 21.3599 26.276 21.4605 26.1772C21.5611 26.0766 21.6977 26.0227 21.8396 26.0245V26.0245Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M30.6927 18.2423C30.9819 18.2458 31.2119 18.483 31.2083 18.7705V21.6921C31.2101 21.8322 31.1562 21.967 31.0574 22.0676C30.9604 22.1682 30.8256 22.2239 30.6837 22.2239C30.5435 22.2239 30.4088 22.1682 30.3099 22.0676C30.2111 21.967 30.1572 21.8322 30.1608 21.6921V18.7705C30.159 18.6303 30.2129 18.4938 30.3135 18.395C30.4142 18.2943 30.5507 18.2404 30.6927 18.2422L30.6927 18.2423Z" fill="#F9F9F9" />
    <path fillRule="evenodd" clipRule="evenodd" d="M30.6927 10.4605C30.9819 10.4641 31.2119 10.7013 31.2083 10.9888V13.9104C31.2101 14.0505 31.1562 14.1853 31.0574 14.2859C30.9604 14.3865 30.8256 14.4422 30.6837 14.4422C30.5435 14.4422 30.4088 14.3865 30.3099 14.2859C30.2111 14.1853 30.1572 14.0505 30.1608 13.9104V10.9888C30.159 10.8486 30.2129 10.712 30.3135 10.6132C30.4142 10.5126 30.5507 10.4587 30.6927 10.4605L30.6927 10.4605Z" fill="#F9F9F9" />
    <path fillRule="evenodd" clipRule="evenodd" d="M30.6927 26.0245C30.9819 26.0281 31.2119 26.2652 31.2083 26.5527V29.4743C31.2101 29.6145 31.1562 29.7492 31.0574 29.8498C30.9604 29.9505 30.8256 30.0062 30.6837 30.0062C30.5435 30.0062 30.4088 29.9505 30.3099 29.8498C30.2111 29.7492 30.1572 29.6145 30.1608 29.4743V26.5527C30.159 26.4126 30.2129 26.276 30.3135 26.1772C30.4142 26.0766 30.5507 26.0227 30.6927 26.0245L30.6927 26.0245Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M35.1092 18.2423C35.3985 18.2458 35.6284 18.483 35.6248 18.7705V21.6921C35.6284 21.8322 35.5745 21.967 35.4757 22.0676C35.3769 22.1682 35.2421 22.2239 35.1002 22.2239C34.96 22.2239 34.8253 22.1682 34.7265 22.0676C34.6276 21.967 34.5737 21.8322 34.5773 21.6921V18.7705C34.5755 18.6303 34.6312 18.4938 34.73 18.395C34.8307 18.2943 34.9672 18.2404 35.1092 18.2422V18.2423Z" fill="#F9F9F9" />
    <path fillRule="evenodd" clipRule="evenodd" d="M35.1092 10.4605C35.3985 10.4641 35.6284 10.7013 35.6248 10.9888V13.9104C35.6284 14.0505 35.5745 14.1853 35.4757 14.2859C35.3769 14.3865 35.2421 14.4422 35.1002 14.4422C34.96 14.4422 34.8253 14.3865 34.7265 14.2859C34.6276 14.1853 34.5737 14.0505 34.5773 13.9104V10.9888C34.5755 10.8486 34.6312 10.712 34.73 10.6132C34.8307 10.5126 34.9672 10.4587 35.1092 10.4605Z" fill="#F9F9F9" />
    <path fillRule="evenodd" clipRule="evenodd" d="M35.1092 26.0245C35.3985 26.0281 35.6284 26.2652 35.6248 26.5527V29.4743C35.6284 29.6145 35.5745 29.7492 35.4757 29.8498C35.3769 29.9505 35.2421 30.0062 35.1002 30.0062C34.96 30.0062 34.8253 29.9505 34.7265 29.8498C34.6276 29.7492 34.5737 29.6145 34.5773 29.4743V26.5527C34.5755 26.4126 34.6312 26.276 34.73 26.1772C34.8307 26.0766 34.9672 26.0227 35.1092 26.0245V26.0245Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M39.5255 18.2423C39.8148 18.2458 40.0466 18.483 40.0412 18.7705V21.6921C40.0448 21.8322 39.9909 21.967 39.8921 22.0676C39.7933 22.1682 39.6585 22.2239 39.5184 22.2239C39.3764 22.2239 39.2417 22.1682 39.1428 22.0676C39.0458 21.967 38.9919 21.8322 38.9937 21.6921V18.7705C38.9919 18.6303 39.0476 18.4938 39.1482 18.395C39.2488 18.2943 39.3836 18.2404 39.5255 18.2422V18.2423Z" fill="#F9F9F9" />
    <path fillRule="evenodd" clipRule="evenodd" d="M39.5255 10.4605C39.8148 10.4641 40.0466 10.7013 40.0412 10.9888V13.9104C40.0448 14.0505 39.9909 14.1853 39.8921 14.2859C39.7933 14.3865 39.6585 14.4422 39.5184 14.4422C39.3764 14.4422 39.2417 14.3865 39.1428 14.2859C39.0458 14.1853 38.9919 14.0505 38.9937 13.9104V10.9888C38.9919 10.8486 39.0476 10.712 39.1482 10.6132C39.2488 10.5126 39.3836 10.4587 39.5255 10.4605V10.4605Z" fill="#F9F9F9" />
    <path fillRule="evenodd" clipRule="evenodd" d="M39.5255 26.0245C39.8148 26.0281 40.0466 26.2652 40.0412 26.5527V29.4743C40.0448 29.6145 39.9909 29.7492 39.8921 29.8498C39.7933 29.9505 39.6585 30.0062 39.5184 30.0062C39.3764 30.0062 39.2417 29.9505 39.1428 29.8498C39.0458 29.7492 38.9919 29.6145 38.9937 29.4743V26.5527C38.9919 26.4126 39.0476 26.276 39.1482 26.1772C39.2488 26.0766 39.3836 26.0227 39.5255 26.0245V26.0245Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.66226 28.5738C8.95155 28.5792 9.18153 28.8164 9.17795 29.1038V32.0236C9.18154 32.1638 9.12763 32.3003 9.02881 32.401C8.92999 32.4998 8.79523 32.5573 8.65508 32.5573C8.51313 32.5573 8.37838 32.4998 8.27955 32.401C8.18252 32.3003 8.12862 32.1638 8.13041 32.0236V29.1038C8.12862 28.9619 8.18432 28.8271 8.28494 28.7265C8.38376 28.6277 8.52032 28.572 8.66226 28.5738V28.5738Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.66226 20.7921C8.95155 20.7974 9.18153 21.0346 9.17795 21.3221V24.2419C9.18154 24.382 9.12763 24.5186 9.02881 24.6192C8.92999 24.718 8.79523 24.7755 8.65508 24.7755C8.51313 24.7755 8.37838 24.718 8.27955 24.6192C8.18252 24.5186 8.12862 24.382 8.13041 24.2419V21.3221C8.12862 21.1801 8.18432 21.0454 8.28494 20.9448C8.38376 20.8459 8.52032 20.7902 8.66226 20.792V20.7921Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.66226 36.356C8.95155 36.3614 9.18153 36.5986 9.17795 36.886V39.8059C9.18154 39.946 9.12763 40.0826 9.02881 40.1832C8.92999 40.282 8.79523 40.3395 8.65508 40.3395C8.51313 40.3395 8.37838 40.282 8.27955 40.1832C8.18252 40.0826 8.12862 39.946 8.13041 39.8059V36.886C8.12862 36.7441 8.18432 36.6093 8.28494 36.5087C8.38376 36.4099 8.52032 36.3542 8.66226 36.356V36.356Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.079 28.5738C13.3683 28.5792 13.6001 28.8164 13.5947 29.1038V32.0236C13.5983 32.1638 13.5444 32.3003 13.4456 32.401C13.3468 32.4998 13.212 32.5573 13.0719 32.5573C12.9317 32.5573 12.7952 32.4998 12.6981 32.401C12.5993 32.3003 12.5454 32.1638 12.549 32.0236V29.1038C12.5454 28.9619 12.6011 28.8271 12.7017 28.7265C12.8023 28.6277 12.9389 28.572 13.079 28.5738V28.5738Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.079 20.7921C13.3683 20.7974 13.6001 21.0346 13.5947 21.3221V24.2419C13.5983 24.382 13.5444 24.5186 13.4456 24.6192C13.3468 24.718 13.212 24.7755 13.0719 24.7755C12.9317 24.7755 12.7952 24.718 12.6981 24.6192C12.5993 24.5186 12.5454 24.382 12.549 24.2419V21.3221C12.5454 21.1801 12.6011 21.0454 12.7017 20.9448C12.8023 20.8459 12.9389 20.7902 13.079 20.792V20.7921Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.079 36.356C13.3683 36.3614 13.6001 36.5986 13.5947 36.886V39.8059C13.5983 39.946 13.5444 40.0826 13.4456 40.1832C13.3468 40.282 13.212 40.3395 13.0719 40.3395C12.9317 40.3395 12.7952 40.282 12.6981 40.1832C12.5993 40.0826 12.5454 39.946 12.549 39.8059V36.886C12.5454 36.7441 12.6011 36.6093 12.7017 36.5087C12.8023 36.4099 12.9389 36.3542 13.079 36.356V36.356Z" fill="currentColor" />
  </svg>
);
