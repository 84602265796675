import styled from 'styled-components';
import Icon from '@ant-design/icons';

export const TestFitPdfExportContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Page = styled.div`
  min-width: ${({ width }) => `${width}px`};
  width: ${({ width }) => `${width}px`};
  min-height: ${({ height }) => `${height}px`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Header = styled.div`
  width: calc(100% - 80px);
  min-height: 85px;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray_07};
  margin: 40px;
`;

export const HeaderLogo = styled(Icon)`
  text-align: left;
    margin-bottom: 10px;
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.colors.textColor};
  font-size: ${({ theme }) => theme.font.size.large};
`;

export const Content = styled.div`
  width: 100%;
  display: ${({ center }) => (center ? 'flex' : 'block')};
  justify-content: center;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  min-height: 50px;
  padding: 10px;
  background: ${({ theme, companyColor, withColor }) => (withColor && `linear-gradient(90deg,
  transparent 0%,
  transparent 20%,
  ${companyColor || theme.colors.primaryColor} 20.01%,
  ${companyColor || theme.colors.primaryColor} 80%,
  transparent 80.01%,
  transparent 100%)`)};
`;

export const SwappLogoWrapper = styled.div`
  color: ${({ theme }) => theme.colors.textColor};
  display: flex;
  line-height: 2;
  margin-right: 25px;
`;

export const SwappLogo = styled(Icon)`
  margin: 0 10px;
`;

export const ContentFlex = styled.div`
  display: ${({ center }) => (center ? 'flex' : 'block')};
`;

export const FirstPageContainer = styled.div`
  width: calc(100% - 80px);
  height: 700px;
  margin: 40px;
  background-color: ${({ theme }) => theme.colors.gray_02};
`;

export const FirstPageLogoWrapper = styled.div`
  margin-left: 80%;
  width: 200px;
  height: 200px;
  background-color: ${({ theme, companyColor }) => (companyColor || theme.colors.primaryColor)};
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FirstPageLogo = styled(Icon)`
`;

export const FirstPageInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

export const FirstPageInfoTitle = styled.div`
  font-size: 50px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: 15px;
`;

export const FirstPageInfoLine = styled.div`
  height: 3px;
  width: 600px;
  background-color: ${({ theme, companyColor }) => (companyColor || theme.colors.primaryColor)};
  margin-bottom: 25px;
`;

export const FirstPageInfoText = styled.div`
  font-size: ${({ small }) => (small ? '20px' : '30px')};
  margin-top: ${({ marginTop }) => marginTop && `${marginTop}px`};
`;

export const PlanIndexListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  height: 900px;
`;

export const PlanIndexListFlex = styled.div`
  display: flex;
`;
