import React, { useMemo, useRef, useLayoutEffect, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  updateMerkupText,
  updateMerkupTextHeight,
} from 'store/markups';
import { Text } from '@react-three/drei';
import { getCaretAtPoint } from 'troika-three-text';
import { Z_INDEX } from './constants';
import TextFieldCaret from './TextFieldCaret';

export const FRAME_OFFSET = 2;
export const PADDING = 0.5;

const TextMarkupTextField = ({ id, markup, color, height, framePoints, isEditable, innerWidth, isViewOnly }) => {
  const dispatch = useDispatch();
  const textCanvasRef = useRef();
  const { fontSize, text } = markup;
  const [caretProps, setCaretProps] = useState({ x: 0, y: 0, height: 0 });

  const onKeyDown = useCallback((event) => {
    if (!isEditable || isViewOnly) {
      return;
    }
    const { key } = event;
    dispatch(updateMerkupText({ id, key }));
  }, [id, isEditable, text]);

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [isEditable, text]);

  const innerLeftTopPoint = useMemo(() => {
    const minX = Math.min(framePoints[0][0], framePoints[2][0]);
    const maxY = Math.max(framePoints[0][1], framePoints[2][1]);
    return [minX + FRAME_OFFSET, maxY - FRAME_OFFSET];
  }, [framePoints]);

  useLayoutEffect(() => {
    if (textCanvasRef.current && !isEditable) {
      textCanvasRef.current.addEventListener('synccomplete', () => {
        const bb = textCanvasRef.current.geometry.boundingBox;
        const newHeight = bb.max.y - bb.min.y;
        if (textCanvasRef.current.text) {
          setCaretProps(getCaretAtPoint(textCanvasRef.current.textRenderInfo, bb.max.x, bb.min.y));
        } else {
          setCaretProps({
            x: 0,
            y: -newHeight,
            height: newHeight,
          });
        }
        dispatch(updateMerkupTextHeight({ id, height: newHeight }));
      });
    }
  }, [text, height, textCanvasRef.current]);

  return (
    <Text
      ref={textCanvasRef}
      maxWidth={innerWidth - 2 * PADDING}
      position={[innerLeftTopPoint[0] + PADDING, innerLeftTopPoint[1] - PADDING, Z_INDEX.TEXT]}
      fontSize={fontSize}
      anchorX={0.0}
      anchorY="top"
      font="https://swappmedia.s3.amazonaws.com/static/fonts/Rubik-VariableFont_wght.ttf"
      overflowWrap="break-word"
      color={color}
    >
      {text}
      {isEditable && !isViewOnly && (
        <group position={[caretProps.x, caretProps.y]}>
          <TextFieldCaret
            height={caretProps.height}
            color={color}
            fontSize={fontSize}
          />
        </group>
      )}
    </Text>
  );
};

TextMarkupTextField.propTypes = {
  id: PropTypes.string,
  color: PropTypes.any,
  isEditable: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  framePoints: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  height: PropTypes.number,
  innerWidth: PropTypes.number,
  markup: PropTypes.object,
};

export default React.memo(TextMarkupTextField);
