import React from 'react';
import PropTypes, { number, string } from 'prop-types';
import * as THREE from 'three';
import { vecDistance, vecLerp } from '../../algorithms/algorithmHelpers';
import { arePointsCCW } from '../../algorithms/jstsHelpers';

const Ramp3d = (props) => {
  // https://codesandbox.io/s/react-three-fiber-custom-geometry-with-fragment-shader-material-vxswf?file=/src/index.js:2360-2374
  const { height, color = 'grey', meshProps } = props;
  let { base } = props;

  // TECH DEBT: There is a bug in the a/b/c/d geometry logic, that creates an assumption that the points have to be clockwise
  // in order for the display to work correctly. This workaround is pretty much harmless and
  const isCCW = arePointsCCW(base);
  if (isCCW) {
    base = [...base];
    base.reverse();
  }

  const [a, b] = vecDistance(base[0], base[1]) > vecDistance(base[1], base[2]) ? [base[0], base[1]] : [base[1], base[2]];
  const [c, d] = vecDistance(base[0], base[3]) > vecDistance(base[3], base[2]) ? [base[0], base[3]] : [base[3], base[2]];
  const cubeVertices = [
    [vecLerp(a, b, 0.16)[0], vecLerp(a, b, 0.16)[1], 0],
    [vecLerp(a, b, 0.3)[0], vecLerp(a, b, 0.3)[1], 0],
    [vecLerp(c, d, 0.3)[0], vecLerp(c, d, 0.3)[1], 0],
    [vecLerp(c, d, 0.16)[0], vecLerp(c, d, 0.16)[1], 0],
    [vecLerp(a, b, 0.84)[0], vecLerp(a, b, 0.84)[1], height],
    [vecLerp(a, b, 0.98)[0], vecLerp(a, b, 0.98)[1], height],
    [vecLerp(c, d, 0.98)[0], vecLerp(c, d, 0.98)[1], height],
    [vecLerp(c, d, 0.84)[0], vecLerp(c, d, 0.84)[1], height],
  ];

  const cubeFaces = [
    [1, 0, 4],
    [1, 4, 5],
    [2, 1, 5],
    [2, 5, 6],
    [0, 3, 4],
    [3, 7, 4],
    [3, 2, 7],
    [2, 6, 7],
  ];

  const vertices = cubeVertices.map((v) => new THREE.Vector3(...v));
  const faces = cubeFaces.map((f) => new THREE.Face3(...f));

  return (
    <mesh receiveShadow {...meshProps}>
      <geometry attach="geometry" vertices={vertices} faces={faces} onUpdate={(self) => self.computeFaceNormals()} />
      <meshBasicMaterial attach="material" color={color} />
    </mesh>
  );
};

Ramp3d.propTypes = {
  base: PropTypes.array,
  height: number,
  color: string,
  meshProps: PropTypes.object,
};

export default React.memo(Ramp3d);
