import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import PlacesAutocomplete from 'react-places-autocomplete';

import {
  withScriptjs, withGoogleMap, GoogleMap, Marker,
} from 'react-google-maps';
import { InputPrefixIcon, StyledInput } from 'styles/commonComponents.styles';
import { GENERATE_SWAPP_FORM } from 'constants/fieldNames';
import { REACT_APP_GOOGLE_MAP_KEY } from '../../../config';

import {
  SearchResult,
  MapElement,
  TestfitContainerElement,
  Suggestion,
  LocationFormItemTestFit, FormItem,
} from './ProjectInfoForm.styles';
import GoogleMapsPolygon from './GoogleMapsPolygon';

const MapComponent = (props) => {
  const { address, isEdit, sitePolygonLatLon, isFeasibility, handleChange, handleSelect, form: { setFieldsValue, getFieldValue } } = props;

  useEffect(() => {
    setFieldsValue({ [GENERATE_SWAPP_FORM.LOCATION_NAME]: address });
  }, [address, setFieldsValue]);

  const searchLocation = (getInputProps, suggestions, getSuggestionItemProps, loading) => {
    const extraProps = getInputProps({ placeholder: T.translate('MAP_COMPONENT_SEARCH_PLACEHOLDER') });

    return (
      <React.Fragment>
        <FormItem
          noStyle
          name={GENERATE_SWAPP_FORM.LOCATION_NAME}
          rules={[{ required: true, message: T.translate('MAP_COMPONENT_SEARCH_ERROR') }]}
        >
          <StyledInput
            prefix={<InputPrefixIcon />}
            {...extraProps}
          />
        </FormItem>
        <SearchResult>
          {loading && <Suggestion>{T.translate('LOADING')}</Suggestion>}
          {suggestions.map((suggestion) => (
            <Suggestion active={suggestion.active} {...getSuggestionItemProps(suggestion)}>
              <span>{suggestion.description}</span>
            </Suggestion>
          ))}
        </SearchResult>
      </React.Fragment>
    );
  };

  const location = getFieldValue(GENERATE_SWAPP_FORM.LOCATION) || { lat: 40.7127753, lng: -74.0059728 };

  return (
    <React.Fragment>
      <PlacesAutocomplete
        value={address || getFieldValue(GENERATE_SWAPP_FORM.LOCATION_NAME) || ''}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <LocationFormItemTestFit label={T.translate('MAP_COMPONENT_LOCATION_LABEL')} disabled>
            {searchLocation(getInputProps, suggestions, getSuggestionItemProps, loading)}
          </LocationFormItemTestFit>
        )}
      </PlacesAutocomplete>
      <FormItem noStyle name={GENERATE_SWAPP_FORM.LOCATION}>
        <GoogleMap defaultZoom={17} center={location}>
          <Marker position={location} />
          {isEdit && isFeasibility && sitePolygonLatLon && (
          <GoogleMapsPolygon
            points={sitePolygonLatLon}
            setPoints={() => {}}
            isEdit={isEdit}
          />
          )}
        </GoogleMap>
      </FormItem>
    </React.Fragment>
  );
};

MapComponent.propTypes = {
  address: PropTypes.string,
  form: PropTypes.object,
  setFieldsValue: PropTypes.func,
  handleChange: PropTypes.func,
  handleSelect: PropTypes.func,
  isEdit: PropTypes.bool,
  isFeasibility: PropTypes.bool,
  sitePolygonLatLon: PropTypes.array,
};

export default compose(
  connect(
    () => ({
      googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`,
      loadingElement: <MapElement />,
      containerElement: <TestfitContainerElement />,
      mapElement: <MapElement />,
      center: { lat: 25.03, lng: 121.6 },
    }),
    null,
  ),
  withScriptjs,
  withGoogleMap,
)(MapComponent);
