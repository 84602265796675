import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import BuildContainer from './result/build/BuildContainer';
import DesignContainer from './result/design/DesignContainer';
import FinancialContainer from './result/financial/FinancialContainer';
import ProgramContainer from './result/program/ProgramContainer';
import ExportContainer from './result/export/ExportContainer';
import MarkupsContainer from './result/markups/MarkupsContainer';
import OldViewerContainer from './oldResult/OldViewerContainer';

const { program, design, financial, build, exportFiles, markups } = UI_AUTHORIZE_PATH;

const StudiesRoute = (props) => {
  const { isNewViewer } = props;
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/${program}`} component={isNewViewer ? ProgramContainer : OldViewerContainer} />
      <Route path={`${match.path}/${design}`} component={DesignContainer} />
      <Route path={`${match.path}/${financial}`} component={FinancialContainer} />
      <Route path={`${match.path}/${build}`} component={BuildContainer} />
      <Route path={`${match.path}/${exportFiles}`} component={ExportContainer} />
      <Route path={`${match.path}/${markups}`} component={MarkupsContainer} />
      <Redirect to={`${match.path}/${program}`} />
    </Switch>
  );
};

StudiesRoute.propTypes = {
  isNewViewer: PropTypes.bool,
};

export default StudiesRoute;
