import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import projectCardTestIcon from 'styles/static/icons/comon/projectCardTestIcon';
import projectCardFeasibilityIcon from 'styles/static/icons/comon/projectCardFeasibilityIcon';

export const CONTACT_MAIL = 'Info@swapp.net';
export const CONTACT_MEETING = 'https://meetings.hubspot.com/roy-waxman';

export const projectionByStage = {
  [UI_AUTHORIZE_PATH.TEST_FIT]: projectCardTestIcon,
  [UI_AUTHORIZE_PATH.FEASIBILITY_STUDY]: projectCardFeasibilityIcon,
};

export const TAB_NAMES = {
  RESULT: 'result',
  FAVORITES: 'favorites',
};
