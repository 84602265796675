import React from 'react';
import T from 'i18n-react';
import styled from 'styled-components';
import lodashGet from 'lodash/get';
import { shallowEqual, useSelector } from 'react-redux';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { SecondaryButton, ViewerUiPanelWrapper } from 'styles/commonComponents.styles';
import ViewerUiPanel from 'utils/swappViewer/components/ui/ViewerUiPanel';
import TestFitViewerContainer from 'utils/swappViewer/testFit/TestFitViewerContainer';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { getProfileByIdSelector, swappProfileResultsSelector } from 'store/swappProfile';
import PdfDownloadButtonNew from 'components/pdf/PdfDownloadButtonNew';
import { downloadUrl } from 'utils/helpers/generalHelpers';
import DownloadCsv from './DownloadCsv';

const ExportContainerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const UiWrapper = styled.div`
  padding: 0 5px 0 27px;
  display: flex;
`;

const ExportContainer = () => {
  const locationData = parseLocationUrl(window.location);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const swappProfileResults = useSelector((state) => swappProfileResultsSelector(state, locationData.profileId, isImperial), shallowEqual);
  const profile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));

  const dwgUrl = lodashGet(profile, 'result.dwgFile');

  return (
    <ExportContainerWrapper>
      {/* ============= UI ============= */}
      <ViewerUiPanelWrapper position="top-left" fitContent>
        <ViewerUiPanel width={dwgUrl ? 330 : 230} notCollapsible>
          <UiWrapper>
            {/* ============== PDF ============== */}
            <PdfDownloadButtonNew profileId={locationData.profileId} />

            {/* ============== CSV ============== */}
            <DownloadCsv />

            {/* ============== DWG ============== */}
            {dwgUrl && (
              <SecondaryButton onClick={() => downloadUrl(dwgUrl)} width={90}>
                {T.translate('DWG')}
              </SecondaryButton>
            )}

          </UiWrapper>
        </ViewerUiPanel>
      </ViewerUiPanelWrapper>

      {/* ============= Viewer ============= */}
      <TestFitViewerContainer plan={swappProfileResults?.multiBoundaryFile} profileId={locationData.profileId} />
    </ExportContainerWrapper>
  );
};

export default ExportContainer;
