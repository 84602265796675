import en from './en';
import engb from './en-gb';
import pt from './pt';
import enCushman from './en-cushman';
import enCommonDesk from './en-commonDesk';

export default {
  en,
  'en-gb': { ...en, ...engb },
  'en-cushman': { ...en, ...enCushman },
  'en-commonDesk': { ...en, ...enCommonDesk },
  pt: { ...en, ...pt },
};
