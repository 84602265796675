import React, { forwardRef, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ForgeViewer from 'utils/forgeViewer/ForgeViewer';
import { ResultMetadataView } from 'styles/commonComponents.styles';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import DownloadCsv from 'components/testFit/study/result/export/DownloadCsv';
import ForgeDisplayToggle from './ForgeDisplayToggle';
import { ForgeWrapper, DisplayToggleWrapper, ContentWrapper } from './ForgeSplitViewContainer.styles';
import PdfDownloadButton from '../../../pdf/PdfDownloadButton';
import LoadingSpinner from '../../../common/LoadingSpinner';
import MarkupsButton from '../../../common/MarkupsButton';
import TestFitPdf from '../../../pdf/TestFitPdf';

const ForgeSplitViewContainer = forwardRef((props, ref) => {
  const { children, urn, threeDUrn, dataViewWidth = 400, projectId, hideType, markups, isExternal } = props;
  const forge = useRef();
  const [currentUrn, useCurrentUrn] = useState(urn);
  const [isPdfGenerating, useIsPdfGenerating] = useState(false);
  const [isMarkupsOpen, useIsMarkupsOpen] = useState(false);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, projectId));

  useEffect(() => {
    if (urn) {
      toggleUrn(useCurrentUrn, urn);
    }
    toggleIsMarkupsOpen(useIsMarkupsOpen, true);
  }, [urn]);

  if (ref) {
    ref.current = forge;
  }

  const toggleUrn = (update, value) => {
    update(value ? urn : threeDUrn);
  };

  const toggleIsPdfGenerating = (value, update) => {
    update(value);
  };

  const toggleIsMarkupsOpen = (update, forceClose) => {
    if (forge && forge.current && forge.current.hasViewerDoneLoading()) {
      forge.current.toggleMarkups(forceClose ? false : !isMarkupsOpen);
      update(forceClose ? false : !isMarkupsOpen);
    }
  };
  const renderViewer = () => (
    <ForgeWrapper>
      <DisplayToggleWrapper>
        {!isMarkupsOpen && threeDUrn && <ForgeDisplayToggle onSelected={(e) => toggleUrn(useCurrentUrn, e)} />}
        {!isMarkupsOpen && (
          <PdfDownloadButton
            content={<TestFitPdf isImperial={isImperial} />}
            onStart={() => toggleIsPdfGenerating(true, useIsPdfGenerating)}
            onFinish={() => toggleIsPdfGenerating(false, useIsPdfGenerating)}
          />
        )}
        {!isMarkupsOpen && <DownloadCsv isForgeIcon />}
        {markups && !isMarkupsOpen && currentUrn === urn && <MarkupsButton onSelected={() => toggleIsMarkupsOpen(useIsMarkupsOpen)} />}
      </DisplayToggleWrapper>
      {currentUrn && !isPdfGenerating
        ? (
          <ForgeViewer
            isExternal={isExternal}
            toggleIsMarkupsOpen={() => toggleIsMarkupsOpen(useIsMarkupsOpen)}
            hideType={hideType}
            heightOffset={50}
            isImperial={isImperial}
            ref={forge}
            projectId={projectId}
            urn={currentUrn}
            backgroundColor={[245, 245, 250, 245, 245, 250]}
          />
        )
        : <LoadingSpinner />}
    </ForgeWrapper>
  );

  return (
    <>
      <ContentWrapper>
        <ResultMetadataView width={dataViewWidth}>
          {children}
        </ResultMetadataView>
        {renderViewer()}
      </ContentWrapper>
    </>
  );
});

ForgeSplitViewContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  dataViewWidth: PropTypes.number,
  urn: PropTypes.string,
  threeDUrn: PropTypes.string,
  projectId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  hideType: PropTypes.string,
  markups: PropTypes.bool,
  isExternal: PropTypes.bool,
};

export default ForgeSplitViewContainer;
