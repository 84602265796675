import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import lodashIncludes from 'lodash/includes';
import lodashIsEqual from 'lodash/isEqual';
import lodashKeys from 'lodash/keys';
import { message } from 'antd';
import { FEASIBILITY_TARGET_FORM } from 'constants/fieldNames';
import { TARGET_STORIES_TYPES, STANDARDS, GROUND_FLOOR_TYPES } from 'constants/feasibilityConts';
import MultiImageSelect from '../../../common/MultiImageSelect';
import { FormItem } from './FeasibilityTargetFormContainer.Styles';
import FeasibilitySitesItem from './FeasibilitySitesItem';

const defaultLotInfo = [
  {
    key: 0,
    id: 0,
    lotName: '',
    targetStories: 10,
    targetStoriesType: TARGET_STORIES_TYPES.MAX_STORIES,
    openTerrain: 0,
    setbackLine: 4,
    groundFloorType: GROUND_FLOOR_TYPES.NA,
    visible: true,
  },
];

const FeasibilitySitesForm = (props) => {
  const { siteData, disabled, formData, form, handleOnChange, isViewer } = props;
  const variants = lodashGet(siteData, 'variants');
  const lots = lodashGet(siteData, 'lots');
  const formSiteId = lodashGet(formData, `[${FEASIBILITY_TARGET_FORM.SITE_ID}][0]`);
  const unitType = lodashGet(formData, `${FEASIBILITY_TARGET_FORM.APARTMENTS_STANDARD}[0]`);

  useEffect(() => { // deselect `NUM_OF_MASSES` typology if variant `isMultilot` true
    if (variants) {
      const selectedVariantIndex = variants.findIndex((variant) => variant.key === formSiteId);
      const buildingTypologies = formData[FEASIBILITY_TARGET_FORM.BUILDING_TYPOLOGIES];
      if (lodashGet(variants, `[${selectedVariantIndex}].isMultilot`) && lodashIncludes(buildingTypologies, STANDARDS.PREFAB.buildingTypologies.NUM_OF_MASSES.key)) {
        const filteredBuildingTypologies = buildingTypologies
          .filter((typology) => typology !== STANDARDS.PREFAB.buildingTypologies.NUM_OF_MASSES.key);

        message.warning(T.translate('FEASIBILITY_SITES_FORM_MIXED_TYPOLOGIES'), 7);
        form.setFieldsValue({
          [FEASIBILITY_TARGET_FORM.BUILDING_TYPOLOGIES]: [...filteredBuildingTypologies],
        });
        handleOnChange();
      }
    }
  }, [formSiteId, variants]);

  useEffect(() => {
    if (variants) {
      if (lodashIsEqual(form.getFieldValue(FEASIBILITY_TARGET_FORM.LOT_DATA), defaultLotInfo) && lodashGet(variants, '[0].lots') && !lodashGet(variants, '[0].id')) {
        renderLotData(variants[0]);
      }
    }
  }, [variants]);

  const renderLotData = (currentVariant) => {
    const lotInfo = lots.map((lot, index) => {
      const currentLotValues = form.getFieldValue(FEASIBILITY_TARGET_FORM.LOT_DATA);

      return (
        {
          id: index,
          key: lot.key,
          visible: lodashIncludes(lodashKeys(currentVariant.lots), lot.key),
          lotName: T.translate(lot.name),
          lotImage: lodashGet(lot, 'image'),
          openTerrain: lodashGet(currentLotValues, `[${index}].openTerrain`, defaultLotInfo[0].openTerrain),
          targetStories: lodashGet(currentLotValues, `[${index}].targetStories`, defaultLotInfo[0].targetStories),
          targetStoriesType: lodashGet(currentLotValues, `[${index}].targetStoriesType`, defaultLotInfo[0].targetStoriesType),
          setbackLine: lodashGet(currentLotValues, `[${index}].setbackLine`, defaultLotInfo[0].setbackLine),
          groundFloorType: GROUND_FLOOR_TYPES.NA,
        }
      );
    });
    form.setFieldsValue({ [FEASIBILITY_TARGET_FORM.LOT_DATA]: lotInfo });
    handleOnChange();
  };

  const updateLotData = (siteId) => {
    if (!lots) {
      return;
    }

    const currentVariant = variants[variants.findIndex((variant) => variant.key === siteId)];
    renderLotData(currentVariant);
  };

  return (
    <>
      {lodashGet(variants, '[0].key') && (
        <FormItem name={FEASIBILITY_TARGET_FORM.SITE_ID} valuePropName="selected" initialValue={[variants[0].key]}>
          <MultiImageSelect
            size={60}
            data={variants}
            disabled={disabled}
            min={1}
            max={1}
            countText={T.translate('FEASIBILITY_SITES_FORM_SELECT')}
            onChange={(list) => updateLotData(list[0])}
          />
        </FormItem>
      )}
      <FormItem name={FEASIBILITY_TARGET_FORM.LOT_DATA} valuePropName="sites" initialValue={defaultLotInfo}>
        <FeasibilitySitesItem unitType={unitType} isViewer={isViewer} />
      </FormItem>
    </>
  );
};

FeasibilitySitesForm.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object,
  formData: PropTypes.object,
  siteData: PropTypes.object,
  handleOnChange: PropTypes.func,
  isViewer: PropTypes.bool,
};

export default React.memo(FeasibilitySitesForm);
