import React from 'react';

export default () => (
  <svg width="170" height="110" viewBox="0 0 170 110" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.2923 40H28.0769V43.6667H24.2923V40Z" fill="#393A3C" />
    <path d="M24.2923 46.1111H28.0769V49.7778H24.2923V46.1111Z" fill="#393A3C" />
    <path d="M30.6 46.1111H34.3846V49.7778H30.6V46.1111Z" fill="#393A3C" />
    <path d="M18.6154 46.1111H22.4V49.7778H18.6154V46.1111Z" fill="#393A3C" />
    <path d="M24.2923 52.2222H28.0769V55.8889H24.2923V52.2222Z" fill="#393A3C" />
    <path d="M30.6 52.2222H34.3846V55.8889H30.6V52.2222Z" fill="#393A3C" />
    <path d="M36.9077 52.2222H40.6923V55.8889H36.9077V52.2222Z" fill="#393A3C" />
    <path d="M18.6154 52.2222H22.4V55.8889H18.6154V52.2222Z" fill="#393A3C" />
    <path d="M12.3077 52.2222H16.0923V55.8889H12.3077V52.2222Z" fill="#393A3C" />
    <path d="M24.2923 58.3333H28.0769V62H24.2923V58.3333Z" fill="#393A3C" />
    <path d="M30.6 58.3333H34.3846V62H30.6V58.3333Z" fill="#393A3C" />
    <path d="M36.9077 58.3333H40.6923V62H36.9077V58.3333Z" fill="#393A3C" />
    <path d="M43.2154 58.3333H47V62H43.2154V58.3333Z" fill="#393A3C" />
    <path d="M18.6154 58.3333H22.4V62H18.6154V58.3333Z" fill="#393A3C" />
    <path d="M12.3077 58.3333H16.0923V62H12.3077V58.3333Z" fill="#393A3C" />
    <path d="M6 58.3333H9.78462V62H6V58.3333Z" fill="#393A3C" />
    <path d="M71.0582 47.2987H51.7V51.4804H58.46V69.9994H63.9909V51.4804H71.0582V47.2987Z" fill="#393A3C" />
    <path d="M73.5164 69.9994V47.2987H78.4327V69.9994H73.5164Z" fill="#393A3C" />
    <path d="M109.775 47.2987H104.244V69.9994H109.775V60.4412H118.378V69.9994H123.909V47.2987H118.378V55.6621H109.775V47.2987Z" fill="#393A3C" />
    <path fillRule="evenodd" clipRule="evenodd" d="M94.1036 47.2987C96.5618 47.8961 100.914 50.515 101.785 54.4679C102.707 58.6496 102.372 61.9074 100.249 65.5196C99.02 67.6104 96.4591 68.9175 93.1818 69.9994H82.12L82.4273 47.2987H94.1036ZM87.6509 65.8183V51.4804H92.26C92.26 51.4804 95.2292 53.0719 96.2545 55.0653C97.1764 56.8575 97.2527 59.6713 96.2545 61.9353C95.3327 64.0261 93.0794 65.3205 91.6455 65.8183H87.6509Z" fill="#393A3C" />
    <path fillRule="evenodd" clipRule="evenodd" d="M126.06 69.9994L134.049 47.2987H140.195L148.798 69.9994H143.267L141.424 65.2203H133.127L131.284 69.9994H126.06ZM137.122 52.6752L140.195 61.0386H134.049L137.122 52.6752Z" fill="#393A3C" />
    <path fillRule="evenodd" clipRule="evenodd" d="M150.949 69.9994H156.48V61.636H158.324L163.24 69.9994H170L163.855 61.0386C166.005 59.5449 167.235 58.0523 167.849 56.5588C168.464 55.0653 168.467 53.5465 167.849 51.7791C166.948 49.2006 166.005 48.1945 162.625 47H150.949V69.9994ZM156.48 51.1815V58.0514H160.475C160.475 58.0514 161.704 57.7527 162.318 56.2593C162.773 55.1548 162.87 53.7102 162.011 52.6749C160.695 51.0892 158.528 50.8828 156.48 51.1815Z" fill="#393A3C" />
    <path d="M1 65.8183H52.6218V70H1V65.8183Z" fill="#393A3C" />
  </svg>

);
