const KEYS_TO_IGNORE = [
  'Shift',
  'Control',
  'Alt',
  'Escape',
  'Tab',
  'CapsLock',
  'Meta',
  'Start',
];

for (let i = 1; i < 16; i++) {
  KEYS_TO_IGNORE.push(`F${i}`);
}

export const getTextWithPressedKey = (text, key) => {
  const newText = text;
  if (key === 'Backspace') {
    return newText.slice(0, -1);
  } if (key === 'Enter') {
    return `${newText}\n`;
  }

  if (KEYS_TO_IGNORE.includes(key)) {
    return text;
  }

  if (key.length > 1) {
    return text;
  }

  return newText + key;
};
