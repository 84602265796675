import React from 'react';

export default () => (
  <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="12" y="1" width="27" height="6" stroke="currentcolor" strokeWidth="1.5" strokeLinejoin="round" />
    <rect x="39" y="49" width="27" height="6" transform="rotate(-180 39 49)" stroke="currentcolor" strokeWidth="1.5" strokeLinejoin="round" />
    <rect x="17" y="7" width="17" height="5" rx="1" stroke="currentcolor" strokeWidth="1.5" strokeLinejoin="round" />
    <rect x="34" y="43" width="17" height="5" rx="1" transform="rotate(-180 34 43)" stroke="currentcolor" strokeWidth="1.5" strokeLinejoin="round" />
    <path d="M32 12V37.5" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19 12V37.5" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M28 15L28 35" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M23 15L23 35" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
