import React from 'react';
import { useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import T from 'i18n-react';
import { useHistory } from 'react-router';
import { feasibilityReportSelector } from 'store/swappProfile/selectors';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import {
  ContentColumnWrapper, ContentImage, ContentImageLabel,
  BulletStyledText, ContentTitle, ContentWrapper,
  VerticalTitleContentWrapper, ContentImagesWithLabelWrapper, ContentRowWrapper, StyledText,
} from '../ReportPages.styles';

const ExistingSite = () => {
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const reportData = useSelector((state) => feasibilityReportSelector(state, locationData.profileId));
  const existingSiteData = lodashGet(reportData, 'site.existingSiteData');
  const descriptionBullets = lodashGet(existingSiteData, 'descriptionBullets', []);

  return (
    <VerticalTitleContentWrapper id="site/existing-site">
      <ContentTitle>{T.translate('Existing Site')}</ContentTitle>
      <ContentRowWrapper>
        <ContentColumnWrapper width={244}>

          {/* ======== description ======== */}
          <StyledText size="small" marginBottom={10}>{T.translate(existingSiteData.description)}</StyledText>
          {descriptionBullets.map((row, index) => <BulletStyledText key={index} size="small">{T.translate(row)}</BulletStyledText>)}

        </ContentColumnWrapper>

        {/* ======== images ======== */}
        <ContentWrapper width={800}>
          <ContentImagesWithLabelWrapper>
            <ContentImage src={existingSiteData.imageUrl} width={800} height={500} />
            {lodashGet(existingSiteData, 'imageLabel') && <ContentImageLabel>{existingSiteData.imageLabel}</ContentImageLabel>}
          </ContentImagesWithLabelWrapper>
        </ContentWrapper>
      </ContentRowWrapper>
    </VerticalTitleContentWrapper>
  );
};

export default React.memo(ExistingSite);
