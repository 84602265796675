import React from 'react';

// currentcolor
export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.5 22H0.5" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" />
    <path d="M2 22V9H18V22" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 21.5V16H15.5V21.5" stroke="currentcolor" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="4" y="11" width="2" height="3" stroke="currentcolor" strokeWidth="0.6" strokeLinejoin="round" />
    <rect x="8" y="11" width="2" height="3" stroke="currentcolor" strokeWidth="0.6" strokeLinejoin="round" />
    <rect x="12" y="11" width="3.5" height="3" stroke="currentcolor" strokeWidth="0.6" strokeLinejoin="round" />
    <rect x="4" y="16" width="2" height="3" stroke="currentcolor" strokeWidth="0.6" strokeLinejoin="round" />
    <rect x="8" y="16" width="2" height="3" stroke="currentcolor" strokeWidth="0.6" strokeLinejoin="round" />
    <path d="M18 14H22.5V21.5" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.5 16H20.5" stroke="currentcolor" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.5 19H20.5" stroke="currentcolor" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.2198 9.04929C14.5355 8.43929 14.3482 7.68249 13.748 7.29105C13.5434 7.1573 13.3075 7.07901 13.0577 7.05944C12.853 6.39072 12.2147 5.89815 11.4515 5.89815C10.6883 5.89815 10.05 6.39072 9.84535 7.05944C9.59905 7.07901 9.35969 7.1573 9.15501 7.29105C8.55487 7.68249 8.36754 8.43603 8.68322 9.04929H6.64944V6.81805C6.76739 6.84415 6.88534 6.8572 7.01022 6.8572C7.89483 6.8572 8.6164 6.18195 8.6164 5.35013C8.6164 4.63248 8.08216 4.03226 7.36407 3.87894C7.39529 3.75825 7.41263 3.63429 7.41263 3.51033C7.41263 2.67851 6.69107 2 5.80646 2C4.92186 2 4.20376 2.67851 4.20376 3.51033C4.20376 3.63429 4.22111 3.75825 4.25233 3.87894C3.5377 4.029 3 4.63248 3 5.35339C3 6.18521 3.71809 6.86046 4.6027 6.86046C4.72759 6.86046 4.84901 6.84415 4.96695 6.82131V9.05256" stroke="currentcolor" strokeWidth="0.6" strokeLinejoin="round" />
    <path d="M22.25 13.75C22.1728 13.4871 22.0395 13.252 21.8605 13.0767C21.6149 12.8376 21.3132 12.7341 21.0114 12.7898C20.9202 12.8058 20.8289 12.8376 20.7447 12.8815C20.4675 12.4034 20.0044 12.1444 19.5342 12.2241C19.4254 12.244 19.3202 12.2799 19.2184 12.3357C18.9623 11.993 18.6149 11.7859 18.25 11.75" stroke="currentcolor" strokeWidth="0.6" strokeLinejoin="round" />
  </svg>
);
