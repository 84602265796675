import React from 'react';
import { useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import T from 'i18n-react';
import { useHistory } from 'react-router';
import lodashIsEmpty from 'lodash/isEmpty';
import { feasibilityReportSelector } from 'store/swappProfile/selectors';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import {
  ContentColumnWrapper, ContentImage, ContentImageLabel,
  BulletStyledText, ContentTitle, ContentWrapper,
  VerticalTitleContentWrapper, ContentImagesWithLabelWrapper, ContentRowWrapper, StyledText,
} from '../ReportPages.styles';

const Floods = () => {
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const reportData = useSelector((state) => feasibilityReportSelector(state, locationData.profileId));
  const floodsData = lodashGet(reportData, 'site.floodsData');

  if (lodashIsEmpty(floodsData)) {
    return null;
  }

  return floodsData.map((item) => (
    <VerticalTitleContentWrapper marginBottom={20}>
      <ContentTitle>{T.translate(item.title)}</ContentTitle>
      <ContentRowWrapper>
        <ContentColumnWrapper width={244}>

          {/* ======== description ======== */}
          <StyledText size="small" marginBottom={10}>{T.translate(item.description)}</StyledText>
          {lodashGet(item, 'descriptionBullets', []).map((row, index) => <BulletStyledText key={index} size="small">{T.translate(row)}</BulletStyledText>)}

        </ContentColumnWrapper>

        {/* ======== images ======== */}
        <ContentWrapper width={800}>
          <ContentImagesWithLabelWrapper>
            <ContentImage src={item.imageUrl} width={800} />
            {lodashGet(item, 'imageLabel') && <ContentImageLabel>{item.imageLabel}</ContentImageLabel>}
          </ContentImagesWithLabelWrapper>
        </ContentWrapper>
      </ContentRowWrapper>
    </VerticalTitleContentWrapper>
  ));
};

export default React.memo(Floods);
