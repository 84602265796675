import arrangingApartmentsMixtureIcon from './arrangingApartmentsMixtureIcon';
import calculatingBuildingRightsIcon from './calculatingBuildingRightsIcon';
import checkingTypologiesIcon from './checkingTypologiesIcon';
import optimizingResultsIcon from './optimizingResultsIcon';
import placingOnSiteIcon from './placingOnSiteIcon';
import parkingIcon from './parkingIcon';
import skyViewIcon from './skyViewIcon';
import sunViewIcon from './sunViewIcon';
import viewIcon from './viewIcon';
import windViewIcon from './windViewIcon';
import testFitIcon from './testFitIcon';
import emptyCardIcon from './emptyCardIcon';

export default {
  arrangingApartmentsMixtureIcon,
  calculatingBuildingRightsIcon,
  checkingTypologiesIcon,
  optimizingResultsIcon,
  placingOnSiteIcon,
  parkingIcon,
  skyViewIcon,
  sunViewIcon,
  viewIcon,
  windViewIcon,
  testFitIcon,
  emptyCardIcon,
};
