import styled from 'styled-components';
import { StyledLink } from '../../styles/commonComponents.styles';

export const LoginTitle = styled.div`
  justify-content: center;
  display: flex;
  margin-bottom: 46px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.textColor};
  font-weight: ${({ theme }) => theme.font.weight.bold}; 
  font-size: ${({ theme }) => theme.font.size.xxLarge};
`;

export const ForgotPasswordLink = styled(StyledLink)`
  display: flex;
  justify-content: center;
`;
