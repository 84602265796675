import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PropTypes from 'prop-types';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import StudiesPage from '../components/feasibility/studies/StudiesPage';

const { root, studies } = UI_AUTHORIZE_PATH;

const FeasibilityPage = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:projectId/${studies}`} component={StudiesPage} />
      <Route path={`${match.url}/${studies}`} component={StudiesPage} />
      <Redirect to={root} />
    </Switch>
  );
};

FeasibilityPage.propTypes = {
  match: PropTypes.object,
};

export default FeasibilityPage;
