export const AUTHORIZATION_FORM = {
  EMAIL: 'email',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword',
};

export const AREA_TARGET_FORM = {
  TYPE: 'type',
  OTHER_TYPE: 'otherType',
  NUMBER_OF_EMPLOYEES: 'numberOfEmployees',
  SPACE_EFFICIENCY: 'SpaceEfficiency',
  TOTAL_AREA_FIELD: 'totalAreaField',
  LSF_RSF_FIELD: 'lsfRsfField',
  TOTAL_LSF_AVERAGE_FIELD: 'totalLsfAverageField',
  TOTAL_LSF_FIELD: 'totalLsfField',
  LSF_ROOMS_FIELD: 'lsfRoomField',
  LSF_B_ROOMS_FIELD: 'lsfBRoomField',
  PSF_A_FIELD: 'psfAField',
  PSF_B_FIELD: 'psfBField',
  ASF_FIELD: 'asfField',
  FSF_FIELD: 'fsfField',
  MALE_BATHROOMS: 'maleBathrooms',
  FEMALE_BATHROOMS: 'femaleBathrooms',
  REQUIRED_MALE_BATHROOMS: 'requiredMaleBathrooms',
  REQUIRED_FEMALE_BATHROOMS: 'requiredFemaleBathrooms',
  MAIN_AISLES: 'mainAisles',
  SECONDARY: 'secondary',
  POSITIONING: 'positioning',
  DEPARTMENTS: 'departments',
  NAME: 'name',
  TIME_STAMP: 'lastTimeStamp',
  COMMENTS: 'comments',
  INPUT_TOGGLE: 'inputToggle',
  STANDARD: 'standard',
};

export const GENERATE_SWAPP_FORM = {
  PROJECT_NAME: 'name',
  STATUS: 'status',
  FLOOR: 'floor',
  PREFERRED_FLOORS: 'preferredFloors',
  BLOCK_LOT: 'blockLotIds',
  LOCATION: 'location',
  LOCATION_NAME: 'locationName',
  SITE_POLYGON: 'site_polygon',
  LAT: 'lat',
  LNG: 'lng',
  FILES: 'files',
  THUMBNAIL: 'image',
  ROOT_STAGE_KEY: 'rootStage',
};

export const FEASIBILITY_TARGET_FORM = {
  TOTAL_AREA_FIELD: 'totalAreaField',
  NUMBER_OF_APARTMENTS: 'numberOfApartments',
  SPACE_EFFICIENCY: 'SpaceEfficiency',
  TYPE: 'type',
  OTHER_TYPE: 'otherType',
  ZONING: 'zoning',
  FAR: 'FAR',
  BUILDABLE_AREA: 'buildableArea',
  CIRCULATION: 'circulation',
  APARTMENTS: 'apartments',
  TOTAL_APARTMENTS: 'totalApartments',
  CONSTRUCTION: 'construction',
  MARKETABILITY: 'marketability',
  SUSTAINABILITY: 'sustainability',
  ARCHITECTURE: 'architecture',
  ENERGY_EFFICIENCY: 'energyEfficiency',
  ENVIRONMENTAL: 'environmental',
  WELLBEING: 'wellbeing',
  AMENITY: 'amenity',
  AMENITY_TOTAL: 'amenityTotal',
  BUILDING_TYPOLOGIES: 'buildingTypologies',
  APARTMENTS_STANDARD: 'apartmentsStandard',
  SITE_ID: 'siteId',
  LOT_DATA: 'lotData',
  GIA: 'GIA',
  NIA: 'NIA',
  NIA_GIA: 'NIA_GIA',
  IS_NIA_LAST_ACTIVE: 'IS_NIA_LAST_ACTIVE',
  CIRCULATION_AREA: 'circulationArea',
  CIRCULATION_PERCENTAGE: 'circulationPercentage',
  INPUT_TOGGLE: 'inputToggle',
  SPRINKLERS: 'sprinklers',
  FLEXIBLE: 'flexible',
  DEAD_END_CORRIDOR: 'deadEndCorridor',
  NATURAL_LIGHT_CORRIDORS: 'naturalLightCorridors',
  SINGLE_LOAD_CORRIDOR: 'singleLoadCorridor',
  COMMUNAL_SPACE: 'communalSpace',
  TOTAL_COMMUNAL_SPACE: 'totalCommunalSpace',
  MULTIPLE_FLOOR_GROUP: 'multipleFloorGroup',
  PRIMARY_AREA: 'primaryArea',
  COMMON_AREA: 'commonArea',
  BALCONY_AREA: 'balconyArea',
  BALCONY_TYPE: 'balconyType',
  IL_NUMBER_OF_APARTMENTS: 'ilNumberOfApartments',
  IS_IL_NUMBER_OF_APARTMENTS: 'isIlNumberOfApartments',
  MIN_FLOOR_HEIGHT: 'minFloorHeight',
  MAX_CONSTRUCTION_HEIGHT: 'maxConstructionHeight',
  IS_MAX_CONSTRUCTION_HEIGHT: 'isMaxConstructionHeight',
  IL_HVAC: 'ilHvac',
  DESIGN_STANDARD: 'designStandard',
};
