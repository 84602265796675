import React from 'react';

// currentcolor
export default () => (
  <svg width="110" height="110" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M37.1845 40H2.81553C1.26214 40 0 38.7379 0 37.1845V2.81553C0 1.26214 1.26214 0 2.81553 0H37.1845C38.7379 0 40 1.26214 40 2.81553V37.1845C40 38.7379 38.7379 40 37.1845 40ZM2.81553 2.81553V37.2816H37.1845V2.81553H2.81553Z" fill="currentcolor" />
    <path d="M20.3883 24.1748H10.8738V6.50485H20.3883C22.7184 6.50485 24.9514 7.37864 26.6019 9.12621C28.2524 10.7767 29.2233 13.0097 29.2233 15.3398C29.2233 17.6699 28.2524 20 26.6019 21.5534C24.8544 23.2039 22.7184 24.1748 20.3883 24.1748ZM13.5922 21.3592H20.3883C21.9417 21.3592 23.4951 20.6796 24.6602 19.5146C25.8252 18.3495 26.4078 16.7961 26.4078 15.2427C26.4078 11.9417 23.6893 9.2233 20.3883 9.2233H13.5922V21.3592Z" fill="currentcolor" />
    <path d="M13.6893 7.86407H10.8738V32.1359H13.6893V7.86407Z" fill="currentcolor" />
  </svg>
);
