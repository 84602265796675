import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import { useHistory } from 'react-router';
import { feasibilityReportSelector } from 'store/swappProfile/selectors';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import {
  VerticalTitleContentWrapper,
  ContentWrapper,
  ContentImage,
  ContentColumnWrapper,
  StyledText,
  ContentImagesWrapper,
  ContentImagesWithLabelWrapper,
  ContentImageLabel,
  ContentRowWrapper, ContentTitle,
} from '../ReportPages.styles';
import ReportTitleDropdown from '../ReportTitleDropdown';

const Surrounding = () => {
  const [currentStreetIndex, setCurrentStreetIndex] = useState(0);
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const reportData = useSelector((state) => feasibilityReportSelector(state, locationData.profileId));
  const surroundingData = lodashGet(reportData, 'site.surroundingData');

  if (!surroundingData) {
    return null;
  }

  const currentAreaData = surroundingData[currentStreetIndex];
  const updateCurrentStreetIndex = (key, update) => {
    update(key);
  };

  return (
    <VerticalTitleContentWrapper id="site/surrounding">
      <ContentTitle>{T.translate('Surrounding')}</ContentTitle>
      <ContentRowWrapper>
        <ContentColumnWrapper width={260}>

          {/* ======== dropdown ======== */}
          <ReportTitleDropdown
            options={surroundingData.map((item) => item.areaName)}
            handleChange={(key) => updateCurrentStreetIndex(key, setCurrentStreetIndex)}
            selectedIndex={currentStreetIndex}
          />

          {/* ======== description ======== */}
          <StyledText size="small" marginBottom={10}>{T.translate(currentAreaData.description)}</StyledText>
        </ContentColumnWrapper>

        {/* ======== images ======== */}
        <ContentWrapper>
          <ContentImagesWrapper width={410}>
            {currentAreaData.images.map((image, index) => (
              <ContentImagesWithLabelWrapper key={index}>
                <ContentImage src={image.url} width={410} height={360} />
                {lodashGet(image, 'label') && <ContentImageLabel>{image.label}</ContentImageLabel>}
              </ContentImagesWithLabelWrapper>
            ))}
          </ContentImagesWrapper>
        </ContentWrapper>
      </ContentRowWrapper>

    </VerticalTitleContentWrapper>
  );
};

export default React.memo(Surrounding);
