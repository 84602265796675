import React, { useState } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashIsEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import Icon from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import dropdownThinIcon from 'styles/static/icons/comon/dropdownThinIcon';

const DropdownIcon = styled(Icon)`
  margin-left: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledMenu = styled(Menu)`
  max-width: 600px !important;
  top: 0;
`;

const ContentTitle = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.xLarge};
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
`;

const MenuItem = styled(Menu.Item)`
  color: ${({ theme, selected }) => (selected ? theme.colors.primaryColor : theme.colors.textColor)};

  :hover {
    background-color: ${({ theme }) => theme.colors.gray_01};
    color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_06 : theme.colors.primaryColorHover)};
  }
`;

const StyledDropdown = styled(Dropdown)`
  display: flex;
  color: ${({ theme }) => theme.colors.primaryColor};
  font-size: ${({ theme }) => theme.font.size.xLarge};
  cursor: pointer;
`;

const ReportTitleDropdown = (props) => {
  const { options, selectedIndex, handleChange } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const menu = (
    <StyledMenu onClick={(key) => handleChange((Number(key.key)))}>
      {options.map((item, index) => (
        <MenuItem key={index} selected={selectedIndex === index}>
          {item}
        </MenuItem>
      ))}
    </StyledMenu>
  );

  const updateIsDropdownOpen = (e, update) => {
    update(e);
  };

  if (lodashIsEmpty(options)) {
    return null;
  }

  if (options.length === 1) {
    return <ContentTitle>{options[selectedIndex]}</ContentTitle>;
  }

  return (
    <StyledDropdown
      overlay={menu}
      onVisibleChange={(e) => updateIsDropdownOpen(e, setIsDropdownOpen)}
      isDropdownOpen={isDropdownOpen}
    >
      <ContentTitle>
        {T.translate(options[selectedIndex])}
        <DropdownIcon component={dropdownThinIcon} />
      </ContentTitle>
    </StyledDropdown>
  );
};

ReportTitleDropdown.propTypes = {
  options: PropTypes.array,
  selectedIndex: PropTypes.number,
  handleChange: PropTypes.func,
};

export default ReportTitleDropdown;
