import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { transformPolygonToBox2 } from 'utils/algorithms/algorithmHelpers';
import { getTestFitCustomFurniture } from 'store/swappProfile';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import MeshLine from '../../components/MeshLine';

const CustomFurniture = (props) => {
  const { item, lineThickness = 0.05, lineColor = 'black', profileId } = props;

  const locationData = parseLocationUrl(window.location);
  const customFurnitureList = useSelector((state) => getTestFitCustomFurniture(state, profileId || locationData.profileId));
  let mirrorX = item.mirrored;
  if (item.isCCW) {
    // When modelling custom furniture in autodesk, the "non clearance side" is from top left to top right - clockwise orientation
    // Therefore, if the item is ccw (which should be the case now), the non clearance side is also ccw, so the first point in the
    // point list is the top right corner, not the top left corner.
    // so we have to flip the mirror flag for the transform polygon to box to work correctly.
    mirrorX = !mirrorX;
  }
  return (
    <group position={[0, 0, 60]} name="customFurniture">
      {/* ================== render lines ================== */}
      {customFurnitureList[item.furnitureType]?.map((line, lineIndex) => (
        <MeshLine
          key={lineIndex}
          vertices={transformPolygonToBox2(line, (item.boundingBox), true, mirrorX, true)}
          lineWidth={lineThickness}
          height={2}
          color={lineColor}
        />
      ))}
    </group>
  );
};

CustomFurniture.propTypes = {
  item: PropTypes.object,
  lineThickness: PropTypes.number,
  lineColor: PropTypes.string,
  profileId: PropTypes.number,
};

export default React.memo(CustomFurniture);
