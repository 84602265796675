import React from 'react';
import styled from 'styled-components';
import { StyledInputNumber } from '../../styles/commonComponents.styles';

export const FromToNumInputWrapper = styled.div`
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => (width ? `${width}px` : '97%')};
  border: 1px solid ${({ theme }) => theme.colors.gray_04};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  display: flex;
  background-color: ${({ theme }) => theme.colors.inputBackground};
  justify-content: space-between;
  margin-right: 5px;
  position: relative;
  overflow: hidden;
`;

export const StyledInput = styled(({ first, bold, height, ...props }) => <StyledInputNumber {...props} />)`
  -webkit-appearance: none;
  width: inherit;
  border: 0 !important;
  background-color: ${({ theme }) => theme.colors.inputBackground};
  box-shadow: none;
  height: ${({ height }) => `${height}px`};


  .ant-input-number-input-wrap {
    height: ${({ height }) => `${height}px`};
  }
  
  .ant-input-number-input {
    height: ${({ height }) => `${height}px`};
    text-align: ${({ first }) => (first ? 'right' : 'left')};
    font-weight: ${({ theme, bold }) => (bold ? theme.font.weight.bold : theme.font.weight.normal)} !important;
    padding: 0;
    color: ${({ theme }) => theme.colors.textColor} !important;
  }

  .ant-input-number-handler-wrap {
    height: ${({ height }) => `${height}px`};
    left: ${({ first }) => (first ? 0 : 'unset')};
    right: ${({ first }) => (first ? 'unset' : 0)};
    width: 12px;
    border: 0;
    border-radius: ${({ theme, first }) => (first ? `${theme.other.borderRadius} 0 0 ${theme.other.borderRadius}` : `0 ${theme.other.borderRadius} ${theme.other.borderRadius} 0`)};
    background-color: ${({ theme }) => theme.colors.inputBackground};

    .ant-input-number-handler {
      &:hover, &:focus {
        color: ${({ theme }) => theme.colors.primaryColor} !important;
        fill: ${({ theme }) => theme.colors.primaryColor} !important;
      }
    }


    .ant-input-number-handler-down-inner,
    .ant-input-number-handler-up-inner {
      width: 6px;
      
      &:hover {
        color: ${({ theme }) => theme.colors.primaryColor} !important;
      }
    }
  }
  
  :hover,
  :focus {
    border: 0;
    box-shadow: none;
    background-color: ${({ theme }) => theme.colors.inputBackground};
  }
`;

export const Spacer = styled.div`
  margin: 0 5px;
  height: 13px;
  width: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray_07};
`;

export const TargetBar = styled.div`
  height: 5px;
  width: 50%;
  position: absolute;
  bottom: 0;
  display: flex;
  background-color: ${({ theme }) => theme.colors.gray_05};
  justify-content: ${({ first }) => (first ? 'flex-end' : 'unset')};
  left: ${({ first }) => (first ? 'unset' : '50%')};
`;

export const TargetBarLeft = styled.div`
  position: absolute;
  height: 100%;
  width: ${({ percentage }) => `${percentage}%`};
  background-color: ${({ theme }) => theme.colors.primaryColor};
`;

export const TargetBarRight = styled.div`
  position: absolute;
  height: 100%;
  width: ${({ percentage }) => `${percentage}%`};
  background-color: ${({ theme }) => theme.colors.primaryColor};
`;
