import React from 'react';
// import { Sphere } from '@react-three/drei'; // For debuging

const Lights = () => (
  <group>
    <ambientLight intensity={0.75} />
    <directionalLight
      castShadow
      intensity={0.3}
      position={[-50 * 1.5, -120 * 1.5, 128 * 1.5]} // was [20, 20, 20]
      shadow-mapSize-width={512}
      shadow-mapSize-height={512}
      shadow-camera-far={700}
      shadow-camera-left={-700}
      shadow-camera-right={700}
      shadow-camera-top={100}
      shadow-camera-bottom={-100}
      lookAt={[0, 0, 0]}
    />
  </group>
);

export default Lights;
