import React, { useState } from 'react';
import T from 'i18n-react';
import PropTypes from 'prop-types';
import { STANDARDS } from 'constants/feasibilityConts';
import { Radio, Space, Image, Popover } from 'antd';
import lodashGet from 'lodash/get';
import styled from 'styled-components';
import { FEASIBILITY_TARGET_FORM } from 'constants/fieldNames';
import { StyledFormItem, StyledRadio } from 'styles/commonComponents.styles';

const StyledSpace = styled(Space)`
  .ant-space-item {
    position: relative;
  }

  .ant-radio {
    bottom: 7px;
    top: unset;
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;

  .ant-image {
    margin: 0 auto;
  }
  
  .ant-image-mask {
    border-radius: ${({ theme }) => theme.other.borderRadius};
  }
`;

const StyledImage = styled(Image)`
  border-radius: ${({ theme }) => theme.other.borderRadius};
  object-fit: cover;
  box-shadow: 0 2px 15px rgba(0, 0, 0, .4);
  margin: 0 auto;
`;

const DataTitle = styled.div`
  text-align: center;
  margin-top: 5px;
  color: ${({ theme, selected }) => (selected ? theme.colors.primaryColor : theme.colors.textColor)};
  font-size: ${({ theme }) => theme.font.size.large};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

const imageSize = 100;

const data = {
  [STANDARDS.IL.key]: [
    {
      key: 'BASIC',
      title: 'FEASIBILITY_DESIGN_STANDARD_BASIC',
      description: 'FEASIBILITY_DESIGN_STANDARD_BASIC_DESCRIPTION',
      preview: 'https://swappmedia.s3.amazonaws.com/static/feasibility/basicStandardPreview.jpg',
      images: [
        'https://swappmedia.s3.amazonaws.com/static/feasibility/IS_basic_Icon.jpg',
        'https://swappmedia.s3.amazonaws.com/static/feasibility/IS_basic_C1_day.jpg',
        'https://swappmedia.s3.amazonaws.com/static/feasibility/IS_basic_C2_day.jpg',
        'https://swappmedia.s3.amazonaws.com/static/feasibility/IS_basic_C2_night.jpg',
      ],
    },
    {
      key: 'HIGH_END',
      title: 'FEASIBILITY_DESIGN_STANDARD_HIGH_END',
      description: 'FEASIBILITY_DESIGN_STANDARD_HIGH_END_DESCRIPTION',
      preview: 'https://swappmedia.s3.amazonaws.com/static/feasibility/highEndStandardPreview.jpg',
      images: [
        'https://swappmedia.s3.amazonaws.com/static/feasibility/HighEnd_Icon.jpg',
        'https://swappmedia.s3.amazonaws.com/static/feasibility/HighEnd_C1_evening.jpg',
        'https://swappmedia.s3.amazonaws.com/static/feasibility/HighEnd_C2_evening.jpg',
        'https://swappmedia.s3.amazonaws.com/static/feasibility/HighEnd_C2_night.jpg',
      ],
    },
  ],
};

const FeasibilityDesignStandard = (props) => {
  const { formData } = props;
  const unitType = lodashGet(formData, `${FEASIBILITY_TARGET_FORM.APARTMENTS_STANDARD}[0]`);
  const [selectedOption, setSelectedOption] = useState(formData[FEASIBILITY_TARGET_FORM.DESIGN_STANDARD]);
  const [isImagePreviewVisible, setIsImagePreviewVisible] = useState({});

  const renderRadioItem = (item) => (
    <StyledRadio key={item.key} value={item.key} absolute>
      <Popover mouseEnterDelay={0.3} placement="topLeft" title={T.translate(item.title)} content={T.translate(item.description)}>
        <RadioWrapper>
          <StyledImage
            selected={selectedOption === item.key}
            preview={{ visible: false }}
            width={imageSize}
            height={imageSize}
            src={item.images[0]}
            onClick={(e) => {
              e.preventDefault();
              setIsImagePreviewVisible({ [item.key]: true });
            }}
          />
          <div style={{ display: 'none' }}>
            <Image.PreviewGroup preview={{ visible: isImagePreviewVisible[item.key], onVisibleChange: () => setIsImagePreviewVisible({ [item.key]: false }) }}>
              {item.images.map((image, index) => <Image key={`${index}_${image}`} src={image} />)}
            </Image.PreviewGroup>
          </div>
          <DataTitle selected={selectedOption === item.key}>{T.translate(item.title)}</DataTitle>
        </RadioWrapper>
      </Popover>
    </StyledRadio>
  );

  return (
    <StyledFormItem name={FEASIBILITY_TARGET_FORM.DESIGN_STANDARD} initialValue={data[unitType][0].key}>
      <Radio.Group onChange={(e) => setSelectedOption(e.target.value)}>
        <StyledSpace direction="horizontal">
          {data[unitType]?.map((item) => renderRadioItem(item))}
        </StyledSpace>
      </Radio.Group>
    </StyledFormItem>
  );
};

FeasibilityDesignStandard.propTypes = {
  formData: PropTypes.object,
};

export default FeasibilityDesignStandard;
