import React, { useState, useEffect } from 'react';
import T from 'i18n-react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { StyledExploreTable, StyledFormItem } from 'styles/commonComponents.styles';
import { StyledText } from 'components/feasibility/studies/result/report/ReportPages.styles';
import CurrencyInput from 'components/common/CurrencyInput';
import { BUILDING_INFO_KEYS } from 'constants/feasibilityConts';
import FeasibilityResultEnergyAnalysisPv from './FeasibilityResultEnergyAnalysisPv';

const InputContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray_05};
`;

const InputWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : 0)};
`;

const InputTitle = styled.div`
  margin-right: 10px;
  max-width: 80px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 2.3;
`;

const RowTitle = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.xBold};
`;

export const InputTypeText = styled.div`
  font-size: ${({ theme }) => theme.font.size.small};
  line-height: 3.3;
`;

const rows = [
  { name: 'FEASIBILITY_RESULT_ENERGY_ANALYSIS_ANNUAL_ENERGY_USE', key: 'annualEnergyUse', style: 'ant-table-total-row' },
  { name: 'FEASIBILITY_RESULT_ENERGY_ANALYSIS_ENERGY_USE_INTENSITY', key: 'energyUseIntensity', style: 'ant-table-total-row' },
  { name: 'FEASIBILITY_RESULT_ENERGY_ANALYSIS_COOLING', key: 'cooling', style: 'table-sub-category' },
  { name: 'FEASIBILITY_RESULT_ENERGY_ANALYSIS_HEATING', key: 'heating', style: 'table-sub-category' },
  { name: 'FEASIBILITY_RESULT_ENERGY_ANALYSIS_LIGHTING', key: 'lighting', style: 'table-sub-category' },
  { name: 'FEASIBILITY_RESULT_ENERGY_ANALYSIS_EQUIPMENT', key: 'equipment', style: 'table-sub-category' },
  { name: 'FEASIBILITY_RESULT_ENERGY_ANALYSIS_PUMP', key: 'pumps', style: 'table-sub-category' },
  { name: 'FEASIBILITY_RESULT_ENERGY_ANALYSIS_FANS', key: 'fans', style: 'table-sub-category' },
  { name: 'FEASIBILITY_RESULT_ENERGY_ANALYSIS_HOT_WATER', key: 'hotWater', style: 'table-sub-category' },
  { name: 'FEASIBILITY_RESULT_ENERGY_ANALYSIS_ANNUAL_ENERGY_COST', key: 'annualEnergyCost', style: 'ant-table-total-row' },
];

const FeasibilityResultEnergyAnalysis = (props) => {
  const { profile, data, form, isImperial } = props;
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (!lodashIsEmpty(data)) {
      getDataSource();
    }
  },
  [data, isImperial, lodashGet(profile, 'id')]);

  if (lodashIsEmpty(data)) {
    return null;
  }

  const columns = [
    {
      title: '',
      dataIndex: 'NAME',
      key: 'NAME',
      align: 'left',
      render: (value, rowData) => (
        <StyledText size="small" total={rowData.style === 'ant-table-total-row'}>
          {`${rowData.style === 'table-sub-category' ? ' -' : ''} ${T.translate(value)}`}
        </StyledText>
      ),
    },
    ...data.map((scenario, index) => (
      {
        title: (
          <Tooltip title={`WWR ${(scenario.windowToWallRatio * 100).toFixed(0)}%`}>
            <RowTitle>{`${T.translate('FEASIBILITY_RESULT_ENERGY_ANALYSIS_SCENARIO')} ${index + 1}`}</RowTitle>
          </Tooltip>
        ),
        dataIndex: `SCENARIO${index}`,
        key: `SCENARIO${index}`,
        align: 'left',
        width: 120,
        render: (value, rowData) => (rowData.isCurrency
          ? <CurrencyInput value={value} height={22} readOnly />
          : <StyledText size="small" total={rowData.style === 'ant-table-total-row'}>{value}</StyledText>),
      }
    )),
  ];

  const arrayToObject = (array) => array.reduce((x, y) => ({ ...x, ...y }), {});

  const getDataByKey = (key) => arrayToObject(data.map((scenario, index) => ({ [`SCENARIO${index}`]: lodashGet(scenario, key) })));

  const getAnnualEnergyCost = (prices) => arrayToObject(data.map((scenario, index) => {
    const gasPrice = prices[BUILDING_INFO_KEYS.ENERGY_GAS_COST] || form.getFieldValue(BUILDING_INFO_KEYS.ENERGY_GAS_COST);
    const electricPrice = prices[BUILDING_INFO_KEYS.ENERGY_ELECTRIC_COST] || form.getFieldValue(BUILDING_INFO_KEYS.ENERGY_ELECTRIC_COST);
    return ({
      [`SCENARIO${index}`]: (scenario.annualEnergyCostGas * gasPrice) + (scenario.annualEnergyCostElectric * electricPrice),
    });
  }));

  const getDataSource = (value, field) => {
    setDataSource(
      rows.map((row) => (row.key === 'annualEnergyCost'
        ? { NAME: row.name, style: row.style, ...getAnnualEnergyCost({ [field]: value }), isCurrency: true }
        : { NAME: row.name, style: row.style, ...getDataByKey(row.key) }
      )),
    );
  };

  return (
    <>
      <InputContainer>
        {/* ================ Gas Cost ================ */}
        <InputWrapper>
          <InputTitle>{T.translate('FEASIBILITY_RESULT_ENERGY_ANALYSIS_GAS_COST')}</InputTitle>
          <StyledFormItem name={BUILDING_INFO_KEYS.ENERGY_GAS_COST} initialValue={0.02} width={100} marginBottom={0.01}>
            <CurrencyInput
              min={0.001}
              height={22}
              step={0.01}
              decimals={2}
              onChange={(e) => getDataSource(e, BUILDING_INFO_KEYS.ENERGY_GAS_COST)}
            />
          </StyledFormItem>
          <InputTypeText>{T.translate('FEASIBILITY_RESULT_ENERGY_ANALYSIS_ENERGY_UNIT')}</InputTypeText>
        </InputWrapper>

        {/* ================ Electric Cost ================ */}
        <InputWrapper marginLeft={10}>
          <InputTitle>{T.translate('FEASIBILITY_RESULT_ENERGY_ANALYSIS_ELECTRIC_COST')}</InputTitle>
          <StyledFormItem name={BUILDING_INFO_KEYS.ENERGY_ELECTRIC_COST} initialValue={0.17} width={100} marginBottom={0.01}>
            <CurrencyInput
              min={0.001}
              height={22}
              step={0.01}
              decimals={2}
              onChange={(e) => getDataSource(e, BUILDING_INFO_KEYS.ENERGY_ELECTRIC_COST)}
            />
          </StyledFormItem>
          <InputTypeText>{T.translate('FEASIBILITY_RESULT_ENERGY_ANALYSIS_ENERGY_UNIT')}</InputTypeText>
        </InputWrapper>
      </InputContainer>

      {/* ================ Table ================ */}
      <StyledExploreTable
        pagination={false}
        columns={columns}
        dataSource={dataSource}
        rowClassName={(row) => row.style}
      />
      <FeasibilityResultEnergyAnalysisPv form={form} data={data} profile={profile} isImperial={isImperial} />
    </>
  );
};

FeasibilityResultEnergyAnalysis.propTypes = {
  profile: PropTypes.object,
  data: PropTypes.object,
  form: PropTypes.object,
  isImperial: PropTypes.bool,
};

export default React.memo(FeasibilityResultEnergyAnalysis);
