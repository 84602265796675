import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import { SmallStyledTable, StyledFormItem, StyledInputNumber, DeleteIcon } from 'styles/commonComponents.styles';
import { AREA_TARGET_FORM } from 'constants/fieldNames';
import { uuidv4 } from 'utils/helpers/uuidv4';
import { deleteRow, updateRow } from 'utils/helpers/tableHelpers';
import { sqfToSqmText } from 'utils/helpers/unitsHelpers';
import { useOverridableSetting } from 'store/userSettings';
import { getTestFitStandards } from 'utils/helpers/parseTestFitStandards';
import ImperialMetricInput from '../../../common/ImperialMetricInput';
import deleteIcon from '../../../../styles/static/icons/comon/deleteIcon';
import AddNewRowButton from '../../../common/AddNewRowButton';
import Dropdown from '../../../common/Dropdown';
import { TotalSeparator } from './AreaTargetFormContainer.styles';

const AreaTargetFormFsf = (props) => {
  const { form, form: { setFieldsValue }, disabled, formData, handleOnChange, isImperial } = props;
  const testFitStandard = useOverridableSetting('testFitStandard', 'DEFAULT');
  const roomTypes = useMemo(() => getTestFitStandards().standards[testFitStandard].roomDefinitions.FSF, [testFitStandard]);

  const [totalAsfData, useTotalAsfData] = useState([
    {
      key: '0',
      roomType: T.translate('AREA_TARGET_FORM_PSF_TOTAL'),
      numberOfRooms: 0,
      sqFt: '',
      totalSqFt: 0,
    },
  ]);

  // update total
  useEffect(() => {
    if (formData[AREA_TARGET_FORM.FSF_FIELD]) {
      updateTotal(useTotalAsfData);
    }
  }, [formData[AREA_TARGET_FORM.FSF_FIELD]]);

  const columns = [
    {
      title: T.translate('AREA_TARGET_FORM_FSF_ROOM_TYPE'),
      dataIndex: 'roomType',
      key: 'roomType',
      width: 250,
      align: 'center',
      render: (key, rowData) => (
        <Dropdown
          dropDownData={roomTypes}
          selectedKey={key}
          onChange={(value) => handleRoomTypeChange(value, rowData)}
          inputValue={rowData.otherText}
          onInputChange={(e) => handleRoomTypeFreeTextChange(e, rowData)}
        />
      ),
    },
    {
      title: T.translate('AREA_TARGET_FORM_FSF_NUMBER_OF_ROOMS'),
      dataIndex: 'numberOfRooms',
      key: 'numberOfRooms',
      width: 160,
      align: 'center',
      render: (numberOfRooms, rowData) => (
        <StyledInputNumber
          width="60px"
          onChange={(e) => handleNumberOfRoomsChange(e, rowData)}
          min={1}
          height={25}
          parser={(value) => value.replace('.', '')}
          value={numberOfRooms}
        />
      ),
    },
    {
      title: T.translate('AREA_TARGET_FORM_FSF_ROOM_SIZE'),
      dataIndex: 'sqFt',
      key: 'sqFt',
      width: 160,
      align: 'center',
      render: (area, rowData) => (
        <ImperialMetricInput
          height={25}
          isImperial={isImperial}
          width="100px"
          onChange={(e) => handleSqFtChange(e, rowData)}
          min={isImperial ? 10 : 1}
          max={isImperial ? 5000 : 500}
          value={area}
        />
      ),
    },
    {
      title: T.translate(isImperial ? 'SQF' : 'SQM'),
      dataIndex: 'totalSqFt',
      key: 'totalSqFt',
      width: 100,
      align: 'center',
      render: (number) => sqfToSqmText(number, isImperial),
    },
    {
      dataIndex: 'dammy',
      key: 'dammy',
    },
    {
      dataIndex: 'delete',
      width: 50,
      align: 'center',
      render: (text, rowData) => (
        <DeleteIcon component={deleteIcon} onClick={() => deleteRow(handleOnChange, rowData.key, form, AREA_TARGET_FORM.FSF_FIELD)} />
      ),
    },
  ];

  const totalColumns = [
    { dataIndex: 'roomType', key: 'roomType', width: 400 },
    { dataIndex: 'numberOfRooms', key: 'numberOfRooms', width: 160, align: 'center' },
    { dataIndex: 'sqFt', key: 'sqFt', width: 250, align: 'center' },
    { dataIndex: 'totalSqFt', key: 'totalSqFt', width: 100, align: 'center', render: (number) => sqfToSqmText(number, isImperial) },
    { dataIndex: 'dammy', key: 'dammy', width: 2 },
    { dataIndex: 'delete', width: 2 },
  ];

  const addNewRow = () => {
    const newRowData = {
      key: uuidv4(),
      roomType: roomTypes[0].key,
      otherText: '',
      numberOfRooms: 1,
      sqFt: lodashGet(roomTypes, '[0].defaultRange[0]', 10),
      totalSqFt: 10,
    };

    const currentData = formData[AREA_TARGET_FORM.FSF_FIELD] || [];

    setFieldsValue({ [AREA_TARGET_FORM.FSF_FIELD]: [...currentData, newRowData] });
    handleOnChange();
  };

  const handleNumberOfRoomsChange = (e, rowData) => {
    const newValues = {
      numberOfRooms: e,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.FSF_FIELD, form, newValues, updateEstimations);
  };

  const handleSqFtChange = (e, rowData) => {
    const newValues = {
      sqFt: e,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.FSF_FIELD, form, newValues, updateEstimations);
  };

  const handleRoomTypeChange = (value, rowData) => {
    const newValues = {
      roomType: value,
      otherText: '',
      sqFt: lodashGet(roomTypes, `[${roomTypes.findIndex((unit) => unit.key === value)}].defaultRange[0]`, 10),
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.FSF_FIELD, form, newValues, updateEstimations);
  };

  const handleRoomTypeFreeTextChange = (e, rowData) => {
    const newValues = {
      otherText: e.target.value,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.FSF_FIELD, form, newValues, updateEstimations);
  };

  const updateEstimations = (data) => ({ totalSqFt: data.numberOfRooms * data.sqFt || 0 });

  const updateTotal = (updateTotalFsf) => {
    const fsfData = formData[AREA_TARGET_FORM.FSF_FIELD];
    const totalSqFt = fsfData.reduce((total, current) => ({ totalSqFt: Number(total.totalSqFt) + Number(current.totalSqFt) }), { totalSqFt: 0 });
    const numberOfRooms = fsfData.reduce((total, current) => ({ numberOfRooms: Number(total.numberOfRooms) + Number(current.numberOfRooms) }), { numberOfRooms: 0 });

    updateTotalFsf([
      {
        key: 'TOTAL',
        roomType: T.translate('AREA_TARGET_FORM_PSF_TOTAL'),
        numberOfRooms: numberOfRooms.numberOfRooms,
        sqFt: '',
        totalSqFt: totalSqFt.totalSqFt,
      },
    ]);
  };

  return (
    <React.Fragment>
      <StyledFormItem name={AREA_TARGET_FORM.FSF_FIELD} valuePropName="dataSource">
        <SmallStyledTable loading={disabled} pagination={false} columns={columns} />
      </StyledFormItem>
      <AddNewRowButton disabled={disabled} onClick={addNewRow} text={T.translate('AREA_TARGET_FORM_PSF_ADD_ROOM_BUTTON')} />
      <TotalSeparator disabled={disabled} />
      <SmallStyledTable loading={disabled} pagination={false} columns={totalColumns} dataSource={totalAsfData} showHeader={false} rowClassName={(row) => row.key === 'TOTAL' && 'ant-table-total-row'} />
    </React.Fragment>
  );
};

AreaTargetFormFsf.propTypes = {
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  isImperial: PropTypes.bool,
};

export default React.memo(AreaTargetFormFsf);
