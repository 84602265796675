export const GET_SWAPP_PROFILE_REQUEST = 'GET_SWAPP_PROFILE_REQUEST';
export const GET_SWAPP_PROFILE_ERROR = 'GET_SWAPP_PROFILE_ERROR';
export const GET_SWAPP_PROFILE_SUCCESS = 'GET_SWAPP_PROFILE_SUCCESS';

export const GENERATE_REVIT_FILE_REQUEST = 'GENERATE_REVIT_FILE_REQUEST';
export const GENERATE_REVIT_FILE_SUCCESS = 'GENERATE_REVIT_FILE_SUCCESS';
export const GENERATE_REVIT_FILE_ERROR = 'GENERATE_REVIT_FILE_ERROR';

export const GENERATE_REPORT_REQUEST = 'GENERATE_REPORT_REQUEST';
export const GENERATE_REPORT_SUCCESS = 'GENERATE_REPORT_SUCCESS';
export const GENERATE_REPORT_ERROR = 'GENERATE_REPORT_ERROR';

export const GENERATE_ENERGY_ANALYSIS_REQUEST = 'GENERATE_ENERGY_ANALYSIS_REQUEST';
export const GENERATE_ENERGY_ANALYSIS_SUCCESS = 'GENERATE_ENERGY_ANALYSIS_SUCCESS';
export const GENERATE_ENERGY_ANALYSIS_ERROR = 'GENERATE_ENERGY_ANALYSIS_ERROR';

export const GENERATE_HB_ENERGY_ANALYSIS_REQUEST = 'GENERATE_HB_ENERGY_ANALYSIS_REQUEST';
export const GENERATE_HB_ENERGY_ANALYSIS_SUCCESS = 'GENERATE_HB_ENERGY_ANALYSIS_SUCCESS';
export const GENERATE_HB_ENERGY_ANALYSIS_ERROR = 'GENERATE_HB_ENERGY_ANALYSIS_ERROR';

export const GENERATE_OCB_PARKING_REQUEST = 'GENERATE_OCB_PARKING_REQUEST';
export const GENERATE_OCB_PARKING_SUCCESS = 'GENERATE_OCB_PARKING_SUCCESS';
export const GENERATE_OCB_PARKING_ERROR = 'GENERATE_OCB_PARKING_ERROR';

export const POLL_SWAPP_PROFILE_REQUEST = 'POLL_SWAPP_PROFILE_REQUEST';
export const POLL_SWAPP_PROFILE_SUCCESS = 'POLL_SWAPP_PROFILE_SUCCESS';
export const POLL_SWAPP_PROFILE_ERROR = 'POLL_SWAPP_PROFILE_ERROR';

export const SET_SWAPP_PROFILE_STAGE_REQUEST = 'SET_SWAPP_PROFILE_STAGE_REQUEST';
export const SET_SWAPP_PROFILE_STAGE_ERROR = 'SET_SWAPP_PROFILE_STAGE_ERROR';
export const SET_SWAPP_PROFILE_STAGE_SUCCESS = 'SET_SWAPP_PROFILE_STAGE_SUCCESS';

export const CLEAR_SWAPP_PROFILE = 'CLEAR_SWAPP_PROFILE';

export const CREATE_NEW_PROJECT_REQUEST = 'CREATE_NEW_PROJECT_REQUEST';
export const CREATE_NEW_PROJECT_ERROR = 'CREATE_NEW_PROJECT_ERROR';
export const CREATE_NEW_PROJECT_SUCCESS = 'CREATE_NEW_PROJECT_SUCCESS';

export const EDIT_PROJECT_REQUEST = 'EDIT_PROJECT_REQUEST';
export const EDIT_PROJECT_ERROR = 'EDIT_PROJECT_ERROR';
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS';

export const SUBMIT_PROFILE_REQUEST = 'SUBMIT_PROFILE_REQUEST';
export const SUBMIT_PROFILE_ERROR = 'SUBMIT_PROFILE_ERROR';
export const SUBMIT_PROFILE_SUCCESS = 'SUBMIT_PROFILE_SUCCESS';

export const SUBMIT_UPDATE_PROFILE_REQUEST = 'SUBMIT_UPDATE_PROFILE_REQUEST';
export const SUBMIT_UPDATE_PROFILE_SUCCESS = 'SUBMIT_UPDATE_PROFILE_SUCCESS';
export const SUBMIT_UPDATE_PROFILE_ERROR = 'SUBMIT_UPDATE_PROFILE_ERROR';

export const SUBMIT_WELLBEING_ANALYSIS_REQUEST = 'SUBMIT_WELLBEING_ANALYSIS_REQUEST';
export const SUBMIT_WELLBEING_ANALYSIS_SUCCESS = 'SUBMIT_WELLBEING_ANALYSIS_SUCCESS';
export const SUBMIT_WELLBEING_ANALYSIS_ERROR = 'SUBMIT_WELLBEING_ANALYSIS_ERROR';

export const CREATE_PROFILE_REQUEST = 'CREATE_PROFILE_REQUEST';
export const CREATE_PROFILE_ERROR = 'CREATE_PROFILE_ERROR';
export const CREATE_PROFILE_SUCCESS = 'CREATE_PROFILE_SUCCESS';

export const DELETE_PROFILE_REQUEST = 'DELETE_PROFILE_REQUEST';
export const DELETE_PROFILE_ERROR = 'DELETE_PROFILE_ERROR';
export const DELETE_PROFILE_SUCCESS = 'DELETE_PROFILE_SUCCESS';

export const UPDATE_PROJECT_PROFILE_REQUEST = 'UPDATE_PROJECT_PROFILE_REQUEST';
export const UPDATE_PROJECT_PROFILE_ERROR = 'UPDATE_PROJECT_PROFILE_ERROR';
export const UPDATE_PROJECT_PROFILE_SUCCESS = 'UPDATE_PROJECT_PROFILE_SUCCESS';

export const GET_SHAREABLE_LINK_REQUEST = 'GET_SHAREABLE_LINK_REQUEST';
export const GET_SHAREABLE_LINK_ERROR = 'GET_SHAREABLE_LINK_ERROR';
export const GET_SHAREABLE_LINK_SUCCESS = 'GET_SHAREABLE_LINK_SUCCESS';

export const GET_PROJECT_FROM_SHAREABLE_LINK_REQUEST = 'GET_PROJECT_FROM_SHAREABLE_LINK_REQUEST';
export const GET_PROJECT_FROM_SHAREABLE_LINK_ERROR = 'GET_PROJECT_FROM_SHAREABLE_LINK_ERROR';
export const GET_PROJECT_FROM_SHAREABLE_LINK_SUCCESS = 'GET_PROJECT_FROM_SHAREABLE_LINK_SUCCESS';

export const CHANGE_MASSES_TRANSFORM_REQUEST = 'CHANGE_MASSES_TRANSFORM_REQUEST';
export const CHANGE_MASSES_TRANSFORM_ERROR = 'CHANGE_MASSES_TRANSFORM_ERROR';
export const CHANGE_MASSES_TRANSFORM_SUCCESS = 'CHANGE_MASSES_TRANSFORM_SUCCESS';

export const EDIT_RESULT_REQUEST = 'EDIT_RESULT_REQUEST';
export const EDIT_RESULT_ERROR = 'EDIT_RESULT_ERROR';
export const EDIT_RESULT_SUCCESS = 'EDIT_RESULT_SUCCESS';

export const CLEAR_ACTIVE_POLLING_PROFILE_ID = 'CLEAR_ACTIVE_POLLING_PROFILE_ID';
export const GENERATE_SITE_DATA = 'GENERATE_SITE_DATA';
