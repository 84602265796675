export const saveToLocalStorage = (key, value) => {
  const currentDataFromKey = getFromLocalStorage(key);
  const newDataToSave = { ...currentDataFromKey, ...value };

  localStorage.setItem(key, JSON.stringify(newDataToSave));
};

export const getFromLocalStorage = (key) => {
  const localStorageKey = localStorage.getItem(key);

  if (localStorageKey) {
    return JSON.parse(localStorageKey);
  }
  return null;
};

export const setFromLocalStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value));
