import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import { Circle } from '@react-three/drei';
import PolyLines from './PolyLines';

const BufferedOutline = ({ bufferedOutline, isPolygon, color }) => (
  <>
    {bufferedOutline.length > 1 && (
    <PolyLines
      points={bufferedOutline}
      color={color}
      width={0.75}
      height={-1}
    />
    )}
    {
      // The circles are drawn to make a smooth look in the corner between two lines.
        !isPolygon && bufferedOutline.map((v, id) => {
          const p1 = new THREE.Vector3(v.x, v.y, -1);
          return (
            <Fragment key={`c${id}`}>
              <Circle
                key={`c${id}`}
                args={[0.3, 10]}
                position={p1}
              >
                <meshStandardMaterial attach="material" color={color} />
              </Circle>
            </Fragment>
          );
        })
      }
  </>
);

BufferedOutline.propTypes = {
  isPolygon: PropTypes.bool,
  bufferedOutline: PropTypes.array,
  color: PropTypes.string,
  // bufferedFill: PropTypes.array,
};

export default React.memo(BufferedOutline);
