import React, { useEffect } from 'react';
import lodashKeys from 'lodash/keys';
import lodashIncludes from 'lodash/includes';
import lodashIsEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import styled from 'styled-components';
import {
  setCurrentCurrency,
  currentCurrencySelector,
  currencyTransferRatesSelector,
  getExchangeRates,
  useOverridableSetting,
} from 'store/userSettings';
import { supportedCurrencies } from 'constants/currencies';
import { currentCurrency } from 'constants/localStorageKeys';
import { setFromLocalStorage, getFromLocalStorage } from 'utils/helpers/localStoragehelpers';
import { swappProjectSelector } from 'store/swappProfile';
import Dropdown from '../common/Dropdown';

const SelectCurrencyWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 15px;
  font-size: ${({ theme }) => theme.font.size.large};
  margin-top: 2px;
`;

const CurrencySwitcher = () => {
  const dispatch = useDispatch();
  const currentSelectedObject = useSelector(currentCurrencySelector, shallowEqual);
  const currencyTransferRatesObject = useSelector(currencyTransferRatesSelector);
  const swappProject = useSelector(swappProjectSelector);
  const localStorageObject = getFromLocalStorage(currentCurrency);
  const userDefaultCurrency = useOverridableSetting('defaultCurrency', 'USD');
  const currentSelectedKey = localStorageObject || userDefaultCurrency;
  // onmount call rates API
  useEffect(() => {
    dispatch(getExchangeRates());
  }, []);

  // get settings from localStorage and set them in store
  useEffect(() => {
    if (!lodashIsEmpty(currencyTransferRatesObject)) {
      const currencyTransferRate = getCurrencyTransferRate(currentSelectedKey);
      dispatch(setCurrentCurrency({ currentCurrencyKey: currentSelectedKey, currencyTransferRate }));
    }
  }, [currencyTransferRatesObject]);

  const getCurrencyTransferRate = (key) => {
    const filteredTransferRates = {};
    // eslint-disable-next-line no-return-assign
    lodashKeys(currencyTransferRatesObject).forEach((currencyKey) => {
      if (lodashIncludes(lodashKeys(supportedCurrencies), currencyKey)) {
        filteredTransferRates[currencyKey] = currencyTransferRatesObject[currencyKey];
      }
    });

    // the rates call are `USD` based so if the user default is not `USD` the list will be update to the User default
    const transferRates = {};
    // eslint-disable-next-line no-return-assign
    lodashKeys(filteredTransferRates).forEach((currencyKey) => (
      transferRates[currencyKey] = filteredTransferRates[currencyKey] / filteredTransferRates[userDefaultCurrency]
    ));

    return transferRates[key];
  };

  const handleChange = (value) => {
    setFromLocalStorage(currentCurrency, value);
    const currencyTransferRate = getCurrencyTransferRate(value);
    dispatch(setCurrentCurrency({ currentCurrencyKey: value, currencyTransferRate }));
  };

  const renderSelectOptions = () => lodashKeys(supportedCurrencies).map((keyName) => {
    const currentOption = supportedCurrencies[keyName];

    if (!currentOption) {
      return null;
    }

    return { key: currentOption.name, value: `${currentOption.symbol} ${currentOption.name}` };
  });

  if (lodashIsEmpty(currencyTransferRatesObject) || lodashIsEmpty(swappProject)) {
    return null;
  }

  return (
    <SelectCurrencyWrapper>
      <Dropdown
        defaultValue={currentSelectedKey}
        selectedKey={currentSelectedObject.currentCurrencyKey}
        dropDownData={renderSelectOptions()}
        onChange={handleChange}
        trigger="hover"
      />
    </SelectCurrencyWrapper>
  );
};

export default CurrencySwitcher;
