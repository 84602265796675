import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.375" y="6.375" width="19.8152" height="14.25" rx="1.625" stroke="currentcolor" strokeWidth="0.75" />
    <path d="M4.05172 4.97501H20.63V4.22501H4.05172V4.97501ZM22.255 6.60001V18.1621H23.005V6.60001H22.255ZM22.255 18.1621C22.255 18.7491 21.7791 19.225 21.1921 19.225V19.975C22.1934 19.975 23.005 19.1634 23.005 18.1621H22.255ZM20.63 4.97501C21.5275 4.97501 22.255 5.70254 22.255 6.60001H23.005C23.005 5.28833 21.9417 4.22501 20.63 4.22501V4.97501ZM4.05172 4.22501C2.98762 4.22501 2.125 5.08763 2.125 6.15173H2.875C2.875 5.50184 3.40184 4.97501 4.05172 4.97501V4.22501Z" fill="currentcolor" />
    <path d="M1.46741 17L7.98021 13.3115C8.81253 12.8401 9.86212 13.0241 10.4845 13.7504L13.269 17M14.5544 18.5L13.269 17M21.0978 16.5L18.7558 14.3078C17.9745 13.5764 16.7558 13.5897 15.9906 14.3381L13.269 17" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.4496 10.25C16.4496 11.2773 15.5945 12.125 14.521 12.125C13.4475 12.125 12.5924 11.2773 12.5924 10.25C12.5924 9.22274 13.4475 8.375 14.521 8.375C15.5945 8.375 16.4496 9.22274 16.4496 10.25Z" stroke="currentcolor" strokeWidth="0.75" />
  </svg>
);
