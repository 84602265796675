import { mergeProjectsData } from 'utils/helpers/storeHelpers';
import { setIn } from 'utils/helpers/immutableHelpers';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  swappProject: null,
  loading: false,
  activePollingProfileIds: [],
};

export const SwappProfileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.CLEAR_SWAPP_PROFILE:
      return { ...state, swappProject: null };
    case actionTypes.GET_SWAPP_PROFILE_REQUEST:
    case actionTypes.SET_SWAPP_PROFILE_STAGE_REQUEST:
    case actionTypes.EDIT_PROJECT_REQUEST:
    case actionTypes.CREATE_PROFILE_REQUEST:
    case actionTypes.SUBMIT_PROFILE_REQUEST:
    case actionTypes.GET_PROJECT_FROM_SHAREABLE_LINK_REQUEST:
    case actionTypes.DELETE_PROFILE_REQUEST:
    case actionTypes.GENERATE_REVIT_FILE_REQUEST:
    case actionTypes.GENERATE_ENERGY_ANALYSIS_REQUEST:
    case actionTypes.GENERATE_OCB_PARKING_REQUEST:
    case actionTypes.EDIT_RESULT_REQUEST:
      return { ...state, error: null, loading: !payload?.disableLoading };
    case actionTypes.GET_SWAPP_PROFILE_ERROR:
    case actionTypes.SET_SWAPP_PROFILE_STAGE_ERROR:
    case actionTypes.EDIT_PROJECT_ERROR:
    case actionTypes.UPDATE_PROJECT_PROFILE_ERROR:
    case actionTypes.CREATE_PROFILE_ERROR:
    case actionTypes.SUBMIT_PROFILE_ERROR:
    case actionTypes.GET_PROJECT_FROM_SHAREABLE_LINK_ERROR:
    case actionTypes.DELETE_PROFILE_ERROR:
    case actionTypes.GENERATE_REVIT_FILE_ERROR:
      return { ...state, error: payload, loading: false };
    case actionTypes.GET_SWAPP_PROFILE_SUCCESS:
    case actionTypes.EDIT_PROJECT_SUCCESS:
    case actionTypes.GET_PROJECT_FROM_SHAREABLE_LINK_SUCCESS:
    case actionTypes.POLL_SWAPP_PROFILE_SUCCESS:
      const newProjectData = mergeProjectsData(state.swappProject, payload);
      return { ...state, loading: false, swappProject: newProjectData };
    case actionTypes.SUBMIT_UPDATE_PROFILE_SUCCESS:
    case actionTypes.SUBMIT_WELLBEING_ANALYSIS_SUCCESS:
    case actionTypes.GENERATE_REPORT_SUCCESS:
    case actionTypes.GENERATE_REVIT_FILE_SUCCESS: {
      const profileIndex = state.swappProject.projectProfiles.findIndex((profile) => profile.id === payload.id);
      const newSwappProject = setIn(`projectProfiles[${profileIndex}]`, payload, state.swappProject);

      return {
        ...state,
        swappProject: newSwappProject,
        activePollingProfileIds: [...state.activePollingProfileIds, payload.id],
      };
    }
    case actionTypes.CLEAR_ACTIVE_POLLING_PROFILE_ID:
      const filteredIds = [...state.activePollingProfileIds].filter((id) => id !== payload);
      return { ...state, activePollingProfileIds: [...filteredIds] };
    case actionTypes.POLL_SWAPP_PROFILE_REQUEST:
      return { ...state, activePollingProfileIds: [...state.activePollingProfileIds, Number(payload)] };
    case actionTypes.UPDATE_PROJECT_PROFILE_REQUEST:
    {
      if (!state.swappProject || !payload) {
        return { ...state };
      }

      const profileIndex = state.swappProject.projectProfiles.findIndex((profile) => profile.id === Number(payload.id));
      if (profileIndex === -1) {
        return { ...state };
      }
      const isDataArrayOrObject = Array.isArray(payload.data) || typeof payload.data === 'object';
      let newSwappProject;
      if (isDataArrayOrObject) {
        const newProfileSnippet = { ...state.swappProject.projectProfiles[profileIndex][payload.key], ...payload.data };
        newSwappProject = setIn(`projectProfiles[${profileIndex}].${payload.key}`, newProfileSnippet, state.swappProject);
      } else {
        newSwappProject = setIn(`projectProfiles[${profileIndex}].${payload.key}`, payload.data, state.swappProject);
      }

      return {
        ...state,
        swappProject: newSwappProject,
      };
    }
    case actionTypes.SUBMIT_PROFILE_SUCCESS:
      const currentProfileIndex = state.swappProject.projectProfiles.findIndex((profile) => profile.id === Number(payload.id));
      const newestSwappProject = setIn(`projectProfiles[${currentProfileIndex}]`, payload, state.swappProject);

      return { ...state, loading: false, swappProject: newestSwappProject };
    case actionTypes.CREATE_PROFILE_SUCCESS:
    case actionTypes.SET_SWAPP_PROFILE_STAGE_SUCCESS:
      const numProfiles = state.swappProject.projectProfiles.length;
      const newSwappProject2 = setIn(`projectProfiles[${numProfiles}]`, payload, state.swappProject);
      return {
        ...state,
        loading: false,
        swappProject: newSwappProject2,
      };
    case actionTypes.GET_SHAREABLE_LINK_SUCCESS:
      return { ...state, shareableKey: payload };
    case actionTypes.DELETE_PROFILE_SUCCESS:
      const filteredProfiles = state.swappProject.projectProfiles.filter((profile) => profile.id !== payload);

      return { ...state, loading: false, swappProject: { ...state.swappProject, projectProfiles: filteredProfiles } };
    case actionTypes.EDIT_RESULT_SUCCESS:
    {
      const profileId = payload.id;
      const { swappProject } = state;

      let profileIndex = swappProject.projectProfiles.findIndex((profile) => profile.id === Number(profileId));
      if (profileIndex === -1) {
        profileIndex = swappProject.projectProfiles.length;
      }
      const newSwappProject = setIn(`projectProfiles[${profileIndex}]`, payload, state.swappProject);

      return {
        ...state,
        loading: false,
        swappProject: newSwappProject,
      };
    }
    default:
      return state;
  }
};
