import React from 'react';
import { useDispatch } from 'react-redux';
import T from 'i18n-react';
import { StyledFormItem } from 'styles/commonComponents.styles';
import { UI_UNAUTHORIZED_PATH } from 'constants/routes/ui';
import { requestToResetPasswordAction } from 'store/auth/actions/authActions';
import PasswordResetForm from './PasswordResetForm';
import { Title, SubTitle, BackLink } from './PasswordResetContainer.styles';

const PasswordResetContainer = () => {
  const dispatch = useDispatch();

  const resetPasswordAction = () => {
    dispatch(requestToResetPasswordAction());
  };

  return (
    <React.Fragment>
      <Title>{T.translate('AUTH_FORM_PASSWORD_RESET')}</Title>
      <SubTitle>{T.translate('PASSWORD_RESET_CONTAINER_VIA_MAIL')}</SubTitle>
      <PasswordResetForm onSubmit={resetPasswordAction} />
      <StyledFormItem>
        <BackLink to={UI_UNAUTHORIZED_PATH.login}>{T.translate('BACK')}</BackLink>
      </StyledFormItem>
    </React.Fragment>
  );
};

export default PasswordResetContainer;
