import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNewMarkupsData } from 'utils/model/projectProfileData';
import { isSharedUrl } from 'utils/helpers/navigationHelpers';
import { updateProjectProfileAction } from 'store/swappProfile/actions/swappProfileActions';
import lodashIsEqual from 'lodash/isEqual';
import { getProfileByIdSelector } from 'store/swappProfile';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import DrawingContainer from './DrawingContainer';

let debounceTimer = null;

const Markups = (props) => {
  const { isViewOnly, profileId } = props;
  const dispatch = useDispatch();
  const profile = useSelector((state) => getProfileByIdSelector(state, profileId));
  const serverMarkups = lodashGet(profile, 'markups.markups', {});

  // mount
  useEffect(() => {
    clearTimeout(debounceTimer);
  }, []);

  // unmount
  useEffect(() => () => {
    clearTimeout(debounceTimer);
  }, []);

  const handleSave = (markups) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    if (lodashIsEqual(markups, serverMarkups) || lodashIsEmpty(markups) || isSharedUrl()) {
      return;
    }

    debounceTimer = setTimeout(() => {
      dispatch(updateProjectProfileAction(profileId, setNewMarkupsData(markups)));
    }, [1000]);
  };

  return <DrawingContainer isViewOnly={isViewOnly} profileId={profileId} handleOnChange={handleSave} serverData={serverMarkups} />;
};

Markups.propTypes = {
  isViewOnly: PropTypes.bool,
  profileId: PropTypes.number,
};

export default React.memo(Markups);
