import React from 'react';

// currentcolor
export default () => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M31.2121 28.4568C31.0766 28.4568 30.9502 28.3705 30.904 28.235L27.2682 17.3258L23.6312 28.2356C23.5746 28.4052 23.3923 28.4963 23.2215 28.4404C23.0519 28.3833 22.9602 28.1997 23.0167 28.0302L26.9607 16.1983C27.0488 15.9339 27.4876 15.9339 27.5752 16.1983L31.5192 28.0302C31.5757 28.1997 31.4839 28.3833 31.3144 28.4404C31.2809 28.452 31.2457 28.4568 31.2129 28.4568L31.2121 28.4568Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M23.631 28.2356C23.5745 28.4052 23.3922 28.4963 23.2214 28.4404C23.0518 28.3833 22.96 28.1997 23.0166 28.0302L26.9606 16.1983C27.0487 15.9339 27.4875 15.9339 27.575 16.1983L31.5191 28.0302C31.5756 28.1997 31.4838 28.3833 31.3142 28.4404C31.2808 28.452 31.2455 28.4568 31.2127 28.4568C31.0772 28.4568 30.95 28.3705 30.9038 28.235L27.2681 17.3258L23.631 28.2356ZM27.268 17.9582L23.8208 28.2989C23.7291 28.5738 23.4336 28.7203 23.1591 28.6305L23.1575 28.63C22.884 28.5378 22.7353 28.2415 22.8268 27.9669L26.7708 16.135C26.8502 15.8969 27.079 15.8 27.268 15.8C27.457 15.8 27.6858 15.8969 27.7648 16.1353M27.7648 16.1353L31.7088 27.9669C31.8002 28.2413 31.6519 28.5373 31.3787 28.6297C31.3216 28.6494 31.2642 28.6568 31.2127 28.6568H31.2098C30.991 28.6559 30.7886 28.5167 30.7145 28.2995L30.7141 28.2982L27.268 17.9582" fill="currentcolor" />
    <path d="M29.7046 25.1347H24.5183C24.3396 25.1347 24.1943 24.9889 24.1943 24.8108C24.1943 24.6327 24.3396 24.4868 24.5183 24.4868H29.7046C29.8826 24.4868 30.0285 24.6327 30.0285 24.8108C30.0285 24.9889 29.8832 25.1347 29.7046 25.1347Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M29.7044 25.3347H24.5181C24.2287 25.3347 23.9941 25.0991 23.9941 24.8108C23.9941 24.5225 24.2287 24.2868 24.5181 24.2868H29.7044C29.9929 24.2868 30.2283 24.5222 30.2283 24.8108C30.2283 25.0991 29.9938 25.3347 29.7044 25.3347ZM24.5181 25.1347H29.7044C29.8831 25.1347 30.0283 24.9889 30.0283 24.8108C30.0283 24.6327 29.8824 24.4868 29.7044 24.4868H24.5181C24.3394 24.4868 24.1941 24.6327 24.1941 24.8108C24.1941 24.9889 24.3394 25.1347 24.5181 25.1347Z" fill="currentcolor" />
    <path d="M20.3094 29.1889C20.0898 29.1889 19.8849 29.049 19.81 28.8293L13.9175 11.1487L8.02292 28.8303C7.93131 29.1051 7.63578 29.2529 7.359 29.1623C7.08418 29.0697 6.93542 28.7722 7.02704 28.4974L13.4191 9.32137C13.562 8.89288 14.2732 8.89288 14.415 9.32137L20.8071 28.4974C20.8987 28.7722 20.75 29.0697 20.4751 29.1623C20.421 29.181 20.3638 29.1889 20.3106 29.1889L20.3094 29.1889Z" fill="currentcolor" />
    <path d="M17.865 23.8047H9.4596C9.17 23.8047 8.93457 23.5683 8.93457 23.2797C8.93457 22.9911 9.17 22.7547 9.4596 22.7547H17.865C18.1536 22.7547 18.3901 22.9911 18.3901 23.2797C18.3901 23.5683 18.1546 23.8047 17.865 23.8047Z" fill="currentcolor" />
  </svg>
);
