import lodashGet from 'lodash/get';
import lodashIncludes from 'lodash/includes';
import { PURE_SWPPROJECT_RENDERING } from 'constants/featureFlags';
import { FloorGroupType } from './feasibilityResultModel';
import { allUnitKeyTypes } from '../../constants/feasibilityConts';

// This class was created in order to gradually migrate from MassingOptions to SwpProject
// The strategy was to refactor all uses of data inside MassingOptionDTO to APIs of this file, and then
// to fulfill the same APIs via SwpProject objects

export const getMassStartingHeight = (buildingData) => {
  if (PURE_SWPPROJECT_RENDERING && buildingData.swpBuilding) {
    return 0;
  }
  return lodashGet(buildingData.massDto, 'height_offset', 0);
};

export const getBuildingStoriesData = (buildingData) => {
  if (PURE_SWPPROJECT_RENDERING && buildingData.swpBuilding) {
    return null;
  }
  const floorDatas = [];
  const floorGroupsDatas = [];
  let currentHeight = getMassStartingHeight(buildingData);
  const floorGroups = buildingData.massDto.floor_groups.filter((fg) => fg.count > 0);
  let floorNum = 0;
  floorGroups.forEach((floorGroupDto, index) => {
    const floorHeight = floorGroupDto.floor_height;
    for (let i = 0; i < floorGroupDto.count; i++) {
      floorDatas.push({
        floorGroupDto,
        startHeight: currentHeight,
        floorNum,
        floorGroupIndex: index,
      });

      if (i === 0) {
        const floorGroupData = {
          startHeight: currentHeight,
          numStories: floorGroupDto.count,
          typicalStory: floorDatas[floorDatas.length - 1],
          firstStoryIndex: floorDatas.length - 1,
          height: floorGroupDto.count * floorHeight,
          floorGroupDto,
          swpId: floorGroupDto.created_from,
        };
        floorGroupsDatas.push(floorGroupData);
      }

      currentHeight += floorHeight;
      floorNum++;
    }
  });
  return { stories: floorDatas, storyGroups: floorGroupsDatas };
};

export const getStoryHeight = (storyData) => {
  if (PURE_SWPPROJECT_RENDERING && storyData.swpBuildingStory) {
    return null;
  }
  return storyData.floorGroupDto.floor_height;
};

export const getStoryParkingUnits = (storyData) => {
  if (PURE_SWPPROJECT_RENDERING && storyData.swpBuildingStory) {
    return null;
  }
  return (storyData.floorGroupDto.parking_units || []).map(getParkingUnitData);
};

// Units is non-parking units in this case
export const getStoryUnits = (storyData) => {
  if (PURE_SWPPROJECT_RENDERING && storyData.swpBuildingStory) {
    return null;
  }
  return storyData.floorGroupDto.units.map(getUnitData);
};

export const getStoryEnvelope = (storyData) => {
  if (PURE_SWPPROJECT_RENDERING && storyData.swpBuildingStory) {
    return null;
  }
  return storyData.floorGroupDto.envelope;
};

export const isBuildingUnderground = (buildingData) => {
  if (PURE_SWPPROJECT_RENDERING && buildingData.swpBuilding) {
    return false;
  }
  return isMassUnderground(buildingData.massDto);
};

export const getLastIndexOfStoryGroupWithUnits = (storyGroups) => {
  if (!storyGroups[0].floorGroupDto) {
    return 0;
  }
  return getLastIndexOfFloorGroupWithUnits(storyGroups.map((storyGroup) => storyGroup.floorGroupDto));
};

export const isStoryGroupAnOpenGroundFloor = (storyGroup) => {
  if (!storyGroup.floorGroupDto) {
    return false;
  }
  return isFloorGroupAnOpenGroundFloor(storyGroup.floorGroupDto);
};

export const isStoryGroupARetailFloor = (storyGroup) => {
  if (!storyGroup.floorGroupDto) {
    return false;
  }
  return isFloorGroupAnRetailFloor(storyGroup.floorGroupDto);
};

export const isBuildingSupported = (buildingData) => {
  if (PURE_SWPPROJECT_RENDERING && buildingData.swpBuilding) {
    return true;
  }
  return isMassDTOSupported(buildingData.massDto);
};

export const getBuildingPivotPoint = (buildingData) => {
  if (PURE_SWPPROJECT_RENDERING && buildingData.swpBuilding) {
    return null;
  }
  return buildingData.massDto.pivot_point;
};

// TODO TECH DEBT REFACTOR remove export
export const isMassDTOSupported = (massDto) => {
  if (!massDto) {
    return false;
  }

  const hasUnitWithFacade = massDto.floor_groups.some((floorGroup) => floorGroup.units.some((unit) => unit.facades !== undefined));

  // If no units with facade, this is before the information that this module requires was added to the DTO
  if (!hasUnitWithFacade) {
    return false;
  }

  return true;
};

export const getStoryType = (storyData) => {
  if (PURE_SWPPROJECT_RENDERING && storyData.swpBuildingStory) {
    return storyData.swpBuildingStory.subtype;
  }
  return storyData.floorGroupDto.type;
};

const isMassUnderground = (massDto) => (getMassHeight(massDto) + lodashGet(massDto, 'height_offset', 0)) < 1e-3 || lodashGet(getMassFirstFloorGroup(massDto), 'type') === 'below_ground_parking';

const getMassRealFloorGroups = (massDto) => massDto.floor_groups.filter((fg) => fg.count > 0);

const getMassFirstFloorGroup = (massDto) => getMassRealFloorGroups(massDto)[0];

const getMassHeight = (massDto) => (massDto.floor_groups.map((floorGroup) => floorGroup.floor_height * floorGroup.count)).reduce((a, b) => a + b, 0);

const isFloorGroupAnOpenGroundFloor = (floorGroupDTO) => [FloorGroupType.OPEN_GROUND_FLOOR, FloorGroupType.COMMUNAL_OPEN_GROUND_FLOOR].includes(floorGroupDTO.type);

const isFloorGroupAnRetailFloor = (floorGroupDTO) => [FloorGroupType.RETAIL_GROUND_FLOOR, FloorGroupType.COMMUNAL_RETAIL_GROUND_FLOOR].includes(floorGroupDTO.type);

const getLastIndexOfFloorGroupWithUnits = (floorGroups = []) => floorGroups.map((floorGroup) => lodashIncludes(floorGroup.units.map((unit) => lodashIncludes(allUnitKeyTypes, unit.name)), true)).lastIndexOf(true);

const getUnitData = (unit) => {
  if (unit.points) {
    // This is a MassUnitDTO
    return {
      polygon: { boundary: unit.points, holes: unit.holes },
      facades: unit.facades,
      name: unit.name,
      aspects: unit.aspects,
      displayName: unit.display_name,
      modifications: unit.modifications,
      tags: unit.tags,
      swpId: unit.created_from,
      numAspects: unit.aspects,
    };
  }
  // This is a SwpProduct
  return null;
};

const getParkingUnitData = (parkingUnit) => {
  if (parkingUnit.parking_polygon) {
    return {
      type: parkingUnit.type,
      polygon: parkingUnit.parking_polygon, // Contains boundary and holes
    };
  }
  return null;
};
