import {
  getMyFloorsErrorActionCreator,
  getMyFloorsRequestActionCreator,
  getMyFloorsSuccessActionCreator,
} from './swappProfilesActionsCreators';
import { API_AUTH_ROUTES } from '../../../constants/routes/api';

export const getMyFloorAction = () => async (dispatch, _, agent) => {
  dispatch(getMyFloorsRequestActionCreator());

  try {
    const { data } = await agent.get(`${API_AUTH_ROUTES.myProjects}`);
    dispatch(getMyFloorsSuccessActionCreator(data));
  } catch (e) {
    dispatch(getMyFloorsErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};
