import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import lodashIncludes from 'lodash/includes';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { metricDivideValue } from '../../utils/helpers/unitsHelpers';
import { inverseLerp } from '../../utils/algorithms/algorithmHelpers';
import { FromToNumInputWrapper, StyledInput, TargetBarRight, TargetBar, TargetBarLeft, Spacer } from './FromToNumInput.styels';
import { getUnitSystemByProjectIdSelector } from '../../store/userSettings';
import { numberWithComma } from '../../utils/helpers/dataDisplay';

export const unitTypes = {
  imperial: 'imperial',
  metric: 'metric',
};

const FromToNumInput = (props) => {
  const { value, defaultValue, range, onChange, step = 1, width, enableSlider, overwriteMax, unitType = unitTypes.imperial } = props;
  const match = useRouteMatch();
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));
  // if `unitType` is not `FromToNumInput.unitTypes.imperial` then we need to flip the bool so the value/range will be in metric
  const isImperialByUnitType = unitType === unitTypes.imperial ? isImperial : !isImperial;

  const otherUnitTypeInCalc = (array) => array.map((item) => (unitType === unitTypes.imperial ? item / metricDivideValue : item * metricDivideValue));
  const otherUnitTypeOutCalc = (array) => array.map((item) => (unitType === unitTypes.imperial ? item * metricDivideValue : item / metricDivideValue));
  const defaultRangeValue = value || defaultValue;
  const [currentValue, setCurrentValue] = useState(isImperialByUnitType ? defaultRangeValue : otherUnitTypeInCalc(defaultRangeValue));

  const min = isImperialByUnitType ? range[0] : otherUnitTypeInCalc(range)[0];
  const max = isImperialByUnitType ? range[1] : otherUnitTypeInCalc(range)[1];
  const middle = (max + min) / 2;
  const getCurrentStep = () => {
    if (unitType === unitTypes.imperial) {
      return isImperialByUnitType ? step : step / 10;
    }
    return isImperialByUnitType ? step : step * 10;
  };
  const getFixed = () => {
    if (unitType === unitTypes.imperial) {
      return isImperialByUnitType ? 0 : 1;
    }
    return isImperialByUnitType ? 1 : 0;
  };

  useEffect(() => {
    setCurrentValue(isImperialByUnitType ? value : otherUnitTypeInCalc(value));
  }, [value, isImperialByUnitType]);

  const handleOnChange = (inputValue, isFirst) => {
    const newValue = [...currentValue];

    if (enableSlider) {
      newValue[isFirst ? 0 : 1] = inputValue;
    } else if (isFirst) {
      newValue[0] = inputValue;
      newValue[1] = inputValue >= currentValue[1] ? inputValue : currentValue[1];
    } else {
      newValue[0] = inputValue <= currentValue[0] ? inputValue : currentValue[0];
      newValue[1] = inputValue;
    }

    onChange(isImperialByUnitType ? newValue : otherUnitTypeOutCalc(newValue));
  };

  const inputOptions = {
    height: 24,
    max: overwriteMax || max,
    min: enableSlider ? middle : min,
    step: getCurrentStep(),
    formatter: (e) => numberWithComma(e, { fixed: getFixed() }),
  };

  return (
    <FromToNumInputWrapper width={width} height={25}>
      <StyledInput
        first
        {...inputOptions}
        bold={lodashIncludes([min, inputOptions.max], currentValue[0])}
        value={Number(currentValue[0])}
        onChange={(e) => handleOnChange(e, true)}
      />
      <Spacer />
      <StyledInput
        {...inputOptions}
        bold={lodashIncludes([min, inputOptions.max], currentValue[1])}
        value={Number(currentValue[1])}
        onChange={(e) => handleOnChange(e)}
      />

      {/* ============= scale ============= */}
      {enableSlider && (
        <>
          <TargetBar first>
            <TargetBarLeft percentage={inverseLerp(middle, min, Number(currentValue[0])) * 100} />
          </TargetBar>
          <TargetBar>
            <TargetBarRight percentage={inverseLerp(middle, max, Number(currentValue[1])) * 100} />
          </TargetBar>
        </>
      )}
    </FromToNumInputWrapper>
  );
};

FromToNumInput.propTypes = {
  value: PropTypes.array,
  defaultValue: PropTypes.array,
  range: PropTypes.array,
  isImperial: PropTypes.bool,
  enableSlider: PropTypes.bool,
  onChange: PropTypes.func,
  step: PropTypes.number,
  width: PropTypes.number,
  unitType: PropTypes.string,
  overwriteMax: PropTypes.number, // can't work with enableSlider enabled!
};

export default memo(FromToNumInput);
