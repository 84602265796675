import React from 'react';
import PropTypes from 'prop-types';
import lodashIsString from 'lodash/isString';
import T from 'i18n-react';
import { Popover } from 'antd';
import { ImageWithDescriptionWrapper, Title, ImageWrapper, StyledIcon,
  StyledImage, InfoDescriptionWrapper, InfoDescriptionImage } from './ImageWithDescription.styles';

const ImageWithDescription = (props) => {
  const { image, value, description, selected, UID, onChange, disabled, size, bordered, descriptionImage } = props;

  const content = (
    <InfoDescriptionWrapper>
      {descriptionImage && <InfoDescriptionImage src={descriptionImage} />}
      {lodashIsString(description) ? T.translate(description) : description }
    </InfoDescriptionWrapper>
  );

  const renderImage = () => (
    <ImageWrapper disabled={disabled} size={size} selected={selected} bordered={bordered}>{typeof image === 'function'
      ? <StyledIcon size={size} component={image} selected={selected} disabled={disabled} />
      : <StyledImage src={image} size={size} selected={selected} bordered={bordered} />}
    </ImageWrapper>
  );

  return (
    <ImageWithDescriptionWrapper size={size} onClick={disabled ? null : () => onChange(UID)}>
      {description
        ? <Popover mouseEnterDelay={0.3} placement="topLeft" title={T.translate(value)} content={content}>{renderImage()} </Popover>
        : renderImage()}
      <Title selected={selected} disabled={disabled}>{T.translate(value)}</Title>
    </ImageWithDescriptionWrapper>
  );
};

ImageWithDescription.propTypes = {
  UID: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  image: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]),
  bordered: PropTypes.bool,
  value: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.number,
  descriptionImage: PropTypes.string,
};

export default ImageWithDescription;
