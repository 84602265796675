import lodashJoin from 'lodash/join';
import lodashPullAll from 'lodash/pullAll';
import lodashPullAt from 'lodash/pullAt';
import lodashDrop from 'lodash/drop';
import lodashUniq from 'lodash/uniq';
import lodashValues from 'lodash/values';
import lodashIncludes from 'lodash/includes';
import lodashIsEmpty from 'lodash/isEmpty';

export const compareVertexAdjacency = (poly1, poly2) => {
  const joinPointsInVertexInPolygon = (poly) => poly.map((vertex) => lodashJoin(vertex.map((point) => Number(point.toFixed(3)))));

  const filteredVertexes = lodashPullAll(
    joinPointsInVertexInPolygon(poly1),
    joinPointsInVertexInPolygon(poly2),
  );

  return !!(filteredVertexes.length && filteredVertexes.length <= 4);
};

export const splitPolygonsToGroupsByAdjacency = (list) => { // list is an array of objects that must include { id: <any>, points: <array of vertices> }
  const splitListByTubeId = {};
  list.forEach((item) => {
    if (!splitListByTubeId[item.tubeId]) {
      splitListByTubeId[item.tubeId] = [];
    }
    splitListByTubeId[item.tubeId].push(item);
  });
  const splitListByTubeIdArray = lodashValues(splitListByTubeId);

  const groups = [[splitListByTubeIdArray[0]]];
  const groupedTileIds = [splitListByTubeIdArray[0].id];
  let currentGroupIndex = 0;

  splitListByTubeIdArray.forEach((tubeList) => {
    tubeList.forEach((item) => {
      if (!lodashIncludes(groupedTileIds, item.id)) {
        groups[currentGroupIndex] = [item];
        groupedTileIds.push(item.id);
      }

      tubeList.forEach((otherItem) => {
        if (lodashIncludes(groupedTileIds, otherItem.id) || lodashIsEmpty(groups[currentGroupIndex])) {
          return;
        }

        groups[currentGroupIndex].forEach((itemInGroup) => {
          if (compareVertexAdjacency(itemInGroup.points, otherItem.points)) {
            groups[currentGroupIndex].push(otherItem);
            groupedTileIds.push(otherItem.id);
          }
        });
      });
      currentGroupIndex += 1;
    });
  });

  return groups.filter((e) => e);
};

export const areAllRoomsClose = (rooms) => {
  const goodRooms = [rooms[0]];
  const roomsToInsert = [...lodashDrop(rooms)];

  while (goodRooms.length < rooms.length) {
    let foundGoodRoomThisRound = false;
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const roomIndex in roomsToInsert) {
      // eslint-disable-next-line no-restricted-syntax
      for (const goodRoomIndex in goodRooms) {
        if (compareVertexAdjacency(goodRooms[goodRoomIndex].points, roomsToInsert[roomIndex].points)) {
          goodRooms.push(roomsToInsert[roomIndex]);
          lodashPullAt(roomsToInsert, [roomIndex]);
          foundGoodRoomThisRound = true;
          break;
        }
      }
    }
    if (!foundGoodRoomThisRound) {
      return false;
    }
  }

  return true;
};

export const isInTheSameTube = (rooms) => lodashUniq(rooms.map((room) => room.tubeId)).length === 1;
