import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import T from 'i18n-react';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashGet from 'lodash/get';
import { useHistory } from 'react-router';
import { feasibilityReportSelector } from 'store/swappProfile/selectors';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import {
  VerticalTitleContentWrapper,
  ContentWrapper,
  ContentImage,
  ContentColumnWrapper,
  ContentRowWrapper,
  ContentTitle, StyledText,
} from '../ReportPages.styles';
import ReportTitleDropdown from '../ReportTitleDropdown';

const description = 'Note: these typical apartment layouts are sale apartments taken from another Swapp project for indicative purposes.';

const Apartments = () => {
  const [currentApartmentIndex, setCurrentApartmentIndex] = useState(0);
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const reportData = useSelector((state) => feasibilityReportSelector(state, locationData.profileId));
  const apartmentsData = lodashGet(reportData, 'apartmentsData');

  const currentApartmentData = apartmentsData[currentApartmentIndex];

  const updateCurrentApartmentIndex = (key, update) => {
    update(key);
  };

  if (lodashIsEmpty(apartmentsData)) {
    return null;
  }

  return (
    <VerticalTitleContentWrapper>
      <ContentTitle>{T.translate('Typical Apartments')}</ContentTitle>
      <ContentRowWrapper>
        {/* ======== dropdown ======== */}
        <ContentColumnWrapper width={450}>
          <ReportTitleDropdown
            options={apartmentsData.map((item) => item.name)}
            handleChange={(key) => updateCurrentApartmentIndex(key, setCurrentApartmentIndex)}
            selectedIndex={currentApartmentIndex}
          />

          {/* ======== description ======== */}
          <StyledText size="xSmall">{T.translate(description)}</StyledText>
        </ContentColumnWrapper>

        {/* ======== image ======== */}
        <ContentWrapper>
          <ContentImage src={currentApartmentData.url} width={820} height={580} />
        </ContentWrapper>
      </ContentRowWrapper>
    </VerticalTitleContentWrapper>
  );
};

export default React.memo(Apartments);
