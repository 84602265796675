import React from 'react';

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.86549 6.6511V9H1V3H3.42067C4.13891 3 4.70087 3.16758 5.10657 3.50275C5.51528 3.83791 5.71963 4.28159 5.71963 4.83379C5.71963 5.41621 5.51978 5.86538 5.1201 6.18132C4.72341 6.49451 4.15393 6.6511 3.41165 6.6511H1.86549ZM1.86549 6.00412H3.42067C3.88347 6.00412 4.23808 5.90522 4.4845 5.70742C4.73093 5.50687 4.85414 5.21841 4.85414 4.84203C4.85414 4.48489 4.73093 4.19918 4.4845 3.98489C4.23808 3.7706 3.89999 3.65934 3.47025 3.6511H1.86549V6.00412Z" fill="currentcolor" />
    <path d="M7.8498 9V3H9.70249C10.2735 3 10.7783 3.11538 11.2171 3.34615C11.6559 3.57692 11.9939 3.90522 12.2313 4.33104C12.4718 4.75687 12.5935 5.24588 12.5965 5.79808V6.18132C12.5965 6.74725 12.4763 7.24313 12.2359 7.66896C11.9984 8.09478 11.6574 8.4217 11.2126 8.64973C10.7708 8.87775 10.2554 8.99451 9.66643 9H7.8498ZM8.71529 3.6511V8.35302H9.62586C10.293 8.35302 10.8114 8.16346 11.181 7.78434C11.5537 7.40522 11.74 6.86538 11.74 6.16484V5.81456C11.74 5.13324 11.5642 4.6044 11.2126 4.22802C10.864 3.8489 10.3681 3.65659 9.72503 3.6511H8.71529Z" fill="currentcolor" />
    <path d="M18.5537 6.35027H15.7995V9H14.934V3H19V3.6511H15.7995V5.7033H18.5537V6.35027Z" fill="currentcolor" />
    <path d="M1.86549 6.6511V9H1V3H3.42067C4.13891 3 4.70087 3.16758 5.10657 3.50275C5.51528 3.83791 5.71963 4.28159 5.71963 4.83379C5.71963 5.41621 5.51978 5.86538 5.1201 6.18132C4.72341 6.49451 4.15393 6.6511 3.41165 6.6511H1.86549ZM1.86549 6.00412H3.42067C3.88347 6.00412 4.23808 5.90522 4.4845 5.70742C4.73093 5.50687 4.85414 5.21841 4.85414 4.84203C4.85414 4.48489 4.73093 4.19918 4.4845 3.98489C4.23808 3.7706 3.89999 3.65934 3.47025 3.6511H1.86549V6.00412Z" stroke="currentcolor" strokeWidth="0.5" strokeLinejoin="round" />
    <path d="M7.8498 9V3H9.70249C10.2735 3 10.7783 3.11538 11.2171 3.34615C11.6559 3.57692 11.9939 3.90522 12.2313 4.33104C12.4718 4.75687 12.5935 5.24588 12.5965 5.79808V6.18132C12.5965 6.74725 12.4763 7.24313 12.2359 7.66896C11.9984 8.09478 11.6574 8.4217 11.2126 8.64973C10.7708 8.87775 10.2554 8.99451 9.66643 9H7.8498ZM8.71529 3.6511V8.35302H9.62586C10.293 8.35302 10.8114 8.16346 11.181 7.78434C11.5537 7.40522 11.74 6.86538 11.74 6.16484V5.81456C11.74 5.13324 11.5642 4.6044 11.2126 4.22802C10.864 3.8489 10.3681 3.65659 9.72503 3.6511H8.71529Z" stroke="currentcolor" strokeWidth="0.5" strokeLinejoin="round" />
    <path d="M18.5537 6.35027H15.7995V9H14.934V3H19V3.6511H15.7995V5.7033H18.5537V6.35027Z" stroke="currentcolor" strokeWidth="0.5" strokeLinejoin="round" />
    <path d="M10 12.5V18.5M10 18.5L6.5 15.4099M10 18.5L13.5 15.4099" stroke="currentcolor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
