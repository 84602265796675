import React from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const sideMarge = 0;
const AddNewRowButtonWrapper = styled.button`
  width: calc(100% - ${`${sideMarge * 2}px`});
  height: 28px;
  text-align: left;
  padding-left: 10px;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 0)};
  margin-bottom: ${({ small }) => (small ? '10px' : '20px')};
  margin-left: ${`${sideMarge}px`};
  margin-right: ${`${sideMarge}px`};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  background-color: transparent;
  border: dashed 1.5px ${({ theme, disabled }) => (disabled ? theme.colors.gray_02 : theme.colors.primaryColor)};
  color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_02 : theme.colors.primaryColor)};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  
  :hover {
    color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_02 : theme.colors.primaryColorHover)};
    border-color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_02 : theme.colors.primaryColorHover)};
  }
  
  :focus {
    outline: unset;
	}
`;

const AddNewRowButton = (props) => {
  const { disabled, text, onClick, small, marginTop } = props;

  return <AddNewRowButtonWrapper marginTop={marginTop} small={small} type="button" disabled={disabled} onClick={onClick}><PlusOutlined /> {text}</AddNewRowButtonWrapper>;
};

AddNewRowButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  marginTop: PropTypes.number,
};

export default AddNewRowButton;
