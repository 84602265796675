import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import BuildContainer from './result/build/BuildContainer';
import DesignContainer from './result/design/DesignContainer';
import EnergyContainer from './result/energy/EnergyContainer';
import HbEnergyContainer from './result/hbEnergy/EnergyContainer';
import EnvironmentContainer from './result/environment/EnvironmentContainer';
import FinancialContainer from './result/financial/FinancialContainer';
import ParkingContainer from './result/parking/ParkingContainer';
import ProgramContainer from './result/program/ProgramContainer';
import ReportContainer from './result/report/ReportContainer';
import ViewsContainer from './result/viewes/ViewsContainer';

const { program, environment, energy, hbenergy, parking, financial, report, design, build, views } = UI_AUTHORIZE_PATH;

const FeasibilityStudiesRoute = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/${program}`} component={ProgramContainer} />
      <Route path={`${match.path}/${environment}`} component={EnvironmentContainer} />
      <Route path={`${match.path}/${energy}`} component={EnergyContainer} />
      <Route path={`${match.path}/${hbenergy}`} component={HbEnergyContainer} />
      <Route path={`${match.path}/${parking}`} component={ParkingContainer} />
      <Route path={`${match.path}/${financial}`} component={FinancialContainer} />
      <Route path={`${match.path}/${report}`} component={ReportContainer} />
      <Route path={`${match.path}/${design}`} component={DesignContainer} />
      <Route path={`${match.path}/${build}`} component={BuildContainer} />
      <Route path={`${match.path}/${views}`} component={ViewsContainer} />
      <Redirect to={`${match.path}/${program}`} />
    </Switch>
  );
};

export default FeasibilityStudiesRoute;
