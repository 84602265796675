import { actionCreator } from 'utils/helpers';
// eslint-disable-next-line import/no-cycle
import * as actionTypes from './actionTypes';

export const getSwappErrorActionCreator = actionCreator(actionTypes.GET_SWAPP_PROFILE_ERROR);
export const getSwappSuccessActionCreator = actionCreator(actionTypes.GET_SWAPP_PROFILE_SUCCESS);
export const getSwappRequestActionCreator = actionCreator(actionTypes.GET_SWAPP_PROFILE_REQUEST);

export const pollSwappErrorActionCreator = actionCreator(actionTypes.POLL_SWAPP_PROFILE_ERROR);
export const pollSwappSuccessActionCreator = actionCreator(actionTypes.POLL_SWAPP_PROFILE_SUCCESS);
export const pollSwappRequestActionCreator = actionCreator(actionTypes.POLL_SWAPP_PROFILE_REQUEST);

export const setSwappProfileStageErrorActionCreator = actionCreator(actionTypes.SET_SWAPP_PROFILE_STAGE_ERROR);
export const setSwappProfileStageSuccessActionCreator = actionCreator(actionTypes.SET_SWAPP_PROFILE_STAGE_SUCCESS);
export const setSwappProfileStageRequestActionCreator = actionCreator(actionTypes.SET_SWAPP_PROFILE_STAGE_REQUEST);

export const clearSwappProfileActionCreator = actionCreator(actionTypes.CLEAR_SWAPP_PROFILE);

export const newProjectRequestActionCreator = actionCreator(actionTypes.CREATE_NEW_PROJECT_REQUEST);
export const newProjectSuccessActionCreator = actionCreator(actionTypes.CREATE_NEW_PROJECT_SUCCESS);
export const newProjectErrorActionCreator = actionCreator(actionTypes.CREATE_NEW_PROJECT_ERROR);

export const editProjectRequestActionCreator = actionCreator(actionTypes.EDIT_PROJECT_REQUEST);
export const editProjectSuccessActionCreator = actionCreator(actionTypes.EDIT_PROJECT_SUCCESS);
export const editProjectErrorActionCreator = actionCreator(actionTypes.EDIT_PROJECT_ERROR);

export const updateProjectProfileErrorActionCreator = actionCreator(actionTypes.UPDATE_PROJECT_PROFILE_ERROR);
export const updateProjectProfileSuccessActionCreator = actionCreator(actionTypes.UPDATE_PROJECT_PROFILE_SUCCESS);
export const updateProjectProfileRequestActionCreator = actionCreator(actionTypes.UPDATE_PROJECT_PROFILE_REQUEST);

export const submitProfileErrorActionCreator = actionCreator(actionTypes.SUBMIT_PROFILE_ERROR);
export const submitProfileSuccessActionCreator = actionCreator(actionTypes.SUBMIT_PROFILE_SUCCESS);
export const submitProfileRequestActionCreator = actionCreator(actionTypes.SUBMIT_PROFILE_REQUEST);

export const submitUpdateProfileRequestActionCreator = actionCreator(actionTypes.SUBMIT_UPDATE_PROFILE_REQUEST);
export const submitUpdateProfileSuccessActionCreator = actionCreator(actionTypes.SUBMIT_UPDATE_PROFILE_SUCCESS);
export const submitUpdateProfileErrorActionCreator = actionCreator(actionTypes.SUBMIT_UPDATE_PROFILE_ERROR);

export const submitWellbeingAnalysisRequestActionCreator = actionCreator(actionTypes.SUBMIT_WELLBEING_ANALYSIS_REQUEST);
export const submitWellbeingAnalysisSuccessActionCreator = actionCreator(actionTypes.SUBMIT_WELLBEING_ANALYSIS_SUCCESS);
export const submitWellbeingAnalysisErrorActionCreator = actionCreator(actionTypes.SUBMIT_WELLBEING_ANALYSIS_ERROR);

export const generateReportRequestActionCreator = actionCreator(actionTypes.GENERATE_REPORT_REQUEST);
export const generateReportSuccessActionCreator = actionCreator(actionTypes.GENERATE_REPORT_SUCCESS);
export const generateReportErrorActionCreator = actionCreator(actionTypes.GENERATE_REPORT_ERROR);

export const generateEnergyAnalysisRequestActionCreator = actionCreator(actionTypes.GENERATE_ENERGY_ANALYSIS_REQUEST);
export const generateEnergyAnalysisSuccessActionCreator = actionCreator(actionTypes.GENERATE_ENERGY_ANALYSIS_SUCCESS);
export const generateEnergyAnalysisErrorActionCreator = actionCreator(actionTypes.GENERATE_ENERGY_ANALYSIS_ERROR);

export const generateHbEnergyAnalysisRequestActionCreator = actionCreator(actionTypes.GENERATE_HB_ENERGY_ANALYSIS_REQUEST);
export const generateHbEnergyAnalysisSuccessActionCreator = actionCreator(actionTypes.GENERATE_HB_ENERGY_ANALYSIS_SUCCESS);
export const generateHbEnergyAnalysisErrorActionCreator = actionCreator(actionTypes.GENERATE_HB_ENERGY_ANALYSIS_ERROR);

export const generateOcbParkingRequestActionCreator = actionCreator(actionTypes.GENERATE_OCB_PARKING_REQUEST);
export const generateOcbParkingSuccessActionCreator = actionCreator(actionTypes.GENERATE_OCB_PARKING_SUCCESS);
export const generateOcbParkingErrorActionCreator = actionCreator(actionTypes.GENERATE_OCB_PARKING_ERROR);

export const generateRevitFileRequestActionCreator = actionCreator(actionTypes.GENERATE_REVIT_FILE_REQUEST);
export const generateRevitFileSuccessActionCreator = actionCreator(actionTypes.GENERATE_REVIT_FILE_SUCCESS);
export const generateRevitFileErrorActionCreator = actionCreator(actionTypes.GENERATE_REVIT_FILE_ERROR);

export const createProfileRequestActionCreator = actionCreator(actionTypes.CREATE_PROFILE_REQUEST);
export const createProfileErrorActionCreator = actionCreator(actionTypes.CREATE_PROFILE_ERROR);
export const createProfileSuccessActionCreator = actionCreator(actionTypes.CREATE_PROFILE_SUCCESS);

export const getShareableLinkRequestActionCreator = actionCreator(actionTypes.GET_SHAREABLE_LINK_REQUEST);
export const getShareableLinkErrorActionCreator = actionCreator(actionTypes.GET_SHAREABLE_LINK_ERROR);
export const getShareableLinkSuccessActionCreator = actionCreator(actionTypes.GET_SHAREABLE_LINK_SUCCESS);

export const getProjectFromShareableLinkRequestActionCreator = actionCreator(actionTypes.GET_PROJECT_FROM_SHAREABLE_LINK_REQUEST);
export const getProjectFromShareableLinkErrorActionCreator = actionCreator(actionTypes.GET_PROJECT_FROM_SHAREABLE_LINK_ERROR);
export const getProjectFromShareableLinkSuccessActionCreator = actionCreator(actionTypes.GET_PROJECT_FROM_SHAREABLE_LINK_SUCCESS);

export const deleteProfileRequestActionCreator = actionCreator(actionTypes.DELETE_PROFILE_REQUEST);
export const deleteProfileErrorActionCreator = actionCreator(actionTypes.DELETE_PROFILE_ERROR);
export const deleteProfileSuccessActionCreator = actionCreator(actionTypes.DELETE_PROFILE_SUCCESS);

export const changeMassesTransformRequestActionCreator = actionCreator(actionTypes.CHANGE_MASSES_TRANSFORM_REQUEST);
export const changeMassesTransformErrorActionCreator = actionCreator(actionTypes.CHANGE_MASSES_TRANSFORM_ERROR);
export const changeMassesTransformSuccessActionCreator = actionCreator(actionTypes.CHANGE_MASSES_TRANSFORM_SUCCESS);

export const editResultRequestActionCreator = actionCreator(actionTypes.EDIT_RESULT_REQUEST);
export const editResultErrorActionCreator = actionCreator(actionTypes.EDIT_RESULT_ERROR);
export const editResultSuccessActionCreator = actionCreator(actionTypes.EDIT_RESULT_SUCCESS);

export const clearActivePollingProfileIdActionCreator = actionCreator(actionTypes.CLEAR_ACTIVE_POLLING_PROFILE_ID);
