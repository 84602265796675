import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import * as THREE from 'three';
import { Text } from '@react-three/drei';
import { direction } from 'direction';
import T from 'i18n-react';
import { useSelector } from 'react-redux';
import { currentThemeSelector } from 'store/userSettings';
import FloorScale from '../testFit/components/FloorScale';
import { polygonIterEdges } from '../../algorithms/algorithmHelpers';

const OriginalPlan = (props) => {
  const { floor, url, color, floorIndex = 0, isOrthographic, handleFitToView, isOnlyOneFloor } = props;
  const [jsonData, setJsonData] = useState(null);
  const [vertices, setVertices] = useState([]);
  const [texts, setTexts] = useState([]);
  const [scalePosition, setScalePosition] = useState(null);
  const [namePosition, setNamePosition] = useState('');
  const originalPlanRef = useRef();
  const currentTheme = useSelector(currentThemeSelector);

  // when URL changes clear the last plan
  useEffect(() => {
    setVertices([]);
  }, [url]);

  // load the url data then parse it
  useEffect(
    () => fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setJsonData(data);
      })
      .catch((err) => {
        throw err;
      }),
    [url],
  );

  // setting up the data to vertices
  useEffect(() => {
    if (jsonData) {
      const segmentPoints = [];
      lodashGet(jsonData, `floors[${floorIndex}].original_plan`, []).map((path) => {
        polygonIterEdges(path, (p1, p2) => { segmentPoints.push(p1); segmentPoints.push(p2); });
      });
      setVertices(segmentPoints.map((p) => new THREE.Vector3(...p)));
    }
  }, [jsonData]);

  useEffect(() => {
    const originalPlanMetaData = lodashGet(jsonData, `floors[${floorIndex}].original_plan_metadata`, {});
    const originalPlanMetaDataTextDatas = lodashGet(originalPlanMetaData, 'text_datas', []);
    setTexts(originalPlanMetaDataTextDatas);
  }, [jsonData]);

  useEffect(() => {
    if (vertices.length) {
      handleFitToView(true);
    }
  }, [vertices.length]);

  useEffect(() => {
    if (originalPlanRef?.current?.geometry?.boundingBox?.max) {
      setScalePosition([originalPlanRef.current.geometry.boundingBox.max.x, originalPlanRef.current.geometry.boundingBox.min.y]);
      setNamePosition([originalPlanRef.current.geometry.boundingBox.min.x + 120, originalPlanRef.current.geometry.boundingBox.max.y + 40]);
    }
  }, [originalPlanRef?.current?.geometry?.boundingBox?.max, vertices.length]);

  if (!url || lodashIsEmpty(vertices)) {
    return null;
  }

  const renderFloorName = () => {
    if (!namePosition) {
      return;
    }

    if (isOnlyOneFloor && lodashIsEmpty(floor.name)) {
      return;
    }

    return (
      <Text font="https://swappmedia.s3.amazonaws.com/static/fonts/Rubik-VariableFont_wght.ttf" position={namePosition} fontSize={70} color={currentTheme.colors.black}>
        {lodashIsEmpty(floor.name)
          ? `${T.translate('EDITOR_ROOMS_PANEL_FLOOR')} ${floorIndex + 1 <= 9 ? `0${floorIndex + 1}` : floorIndex + 1}`
          : floor.name}
      </Text>
    );
  };

  return (
    <group key={vertices.length} position={[0, 0, isOrthographic ? 150 : 1]}>
      {/* ============== original plan lines ============== */}
      <lineSegments key="originalPlan" ref={originalPlanRef}>
        <geometry attach="geometry" vertices={vertices} />
        <lineBasicMaterial attach="material" color={color || '#888888'} />
      </lineSegments>

      {/* ============== texts ============== */}
      <group position={[0, 0, 70]} name="floorTextGroup">
        {texts && texts.map((item, index) => (
          <Text key={`${item.text}_${index}`} name={`${item.text}_${index}`} font="https://swappmedia.s3.amazonaws.com/static/fonts/Rubik-VariableFont_wght.ttf" position={item.location} fontSize={13} color="black" textAlign="center">
            {direction(item.text) === 'rtl' ? item.text.split('').reverse().join('') : item.text}
          </Text>
        ))}
      </group>

      {/* ============== scale ============== */}
      {scalePosition && <FloorScale position={scalePosition} />}

      {/* ============== floor name/number ============== */}
      {renderFloorName()}
    </group>
  );
};

OriginalPlan.propTypes = {
  url: PropTypes.string,
  color: PropTypes.string,
  floorIndex: PropTypes.number,
  isOrthographic: PropTypes.bool,
  isOnlyOneFloor: PropTypes.bool,
  handleFitToView: PropTypes.func,
  floor: PropTypes.object,
};

export default React.memo(OriginalPlan);
