import { operation, geom, algorithm, util } from 'jsts';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';

// Like jstsPoly.buffer(distance), but will use straight perpendicular lines rather than curves or diagonals
export const bufferStraight = (jstsPoly, distance) => {
  const bufParams = new operation.buffer.BufferParameters(2,
    operation.buffer.BufferParameters.CAP_FLAT,
    operation.buffer.BufferParameters.JOIN_MITRE,
    10);

  const bufferOp = new operation.buffer.BufferOp(jstsPoly, bufParams);
  const resultPoly = bufferOp.getResultGeometry(distance);
  return resultPoly;
};

export const morphologyOpen = (jstsPoly, distance) => {
  const eroded = jstsPoly.buffer(-distance);
  return eroded.buffer(distance);
};

export const morphologyClose = (jstsPoly, distance) => {
  const dilated = jstsPoly.buffer(distance);
  return dilated.buffer(-distance);
};

const pointsToRing = (polygon) => {
  const jstsPolygonArray = polygon.map((v) => new geom.Coordinate(...v, 0));
  if (jstsPolygonArray[0] !== jstsPolygonArray[-1]) {
    jstsPolygonArray.push(jstsPolygonArray[0]);
  }

  return new geom.GeometryFactory().createLinearRing(jstsPolygonArray);
};

export const pointsToJSTSPolygon = (envelope, holes = []) => {
  const parsedHoles = holes.map((hole) => pointsToRing(hole)).filter((e) => e);
  return new geom.GeometryFactory().createPolygon(pointsToRing(envelope), parsedHoles);
};

export const operationDifferenceWithGeomCollection = (originalPoly, multiPolygon) => {
  if (!multiPolygon) {
    return null;
  }
  let cuttedPoly = originalPoly;
  // eslint-disable-next-line no-underscore-dangle
  multiPolygon._geometries.forEach((otherPolygon) => {
    // difference operation is supported with GeometryCollections
    cuttedPoly = cuttedPoly.difference(otherPolygon);
  });
  return cuttedPoly;
};

export const pointsToJSTSMultiPolygon = (points) => {
  const polys = points.map((element) => pointsToJSTSPolygon(element));
  return new geom.GeometryFactory().createMultiPolygon(polys);
};

export const extendPolygon = (polygon, extendBy = 1) => {
  if (lodashIsEmpty(polygon)) {
    return [];
  }

  const roomPolygon = pointsToJSTSPolygon(polygon);
  const bufferedRoomPolygon = roomPolygon.buffer(extendBy, -2);
  const externalLine = lodashGet(bufferedRoomPolygon, '_shell._points._coordinates', []);
  return externalLine.map((v) => [v.x, v.y]);
};

export const getPolygonCentroid = (polygon) => {
  const roomPolygon = pointsToJSTSPolygon(polygon);
  const roomCentroid = roomPolygon.getCentroid();
  return [lodashGet(roomCentroid, '_coordinates._coordinates.[0].x'), lodashGet(roomCentroid, '_coordinates._coordinates.[0].y'), 0];
};

export const getPointsConvexHull = (points) => {
  const jstsPoints = points.map((v) => new geom.Coordinate(...v));
  const convexHullAlgo = new algorithm.ConvexHull(jstsPoints, new geom.GeometryFactory());

  // Next 4 lines replaces # convexHullAlgo.getConvexHull(); #
  // We do it because of an optimistaion inside that function that causes a stack overflow
  const sortedPts = convexHullAlgo.preSort(util.UniqueCoordinateArrayFilter.filterCoordinates(jstsPoints));
  const cHS = convexHullAlgo.grahamScan(sortedPts);
  const cH = convexHullAlgo.toCoordinateArray(cHS);
  const result = convexHullAlgo.lineOrPolygon(cH);
  return result.getCoordinates().map((coord) => [coord.x, coord.y]);
};

export const arePointsCCW = (points) => {
  const jstsPoints = points.map((v) => new geom.Coordinate(...v));
  const isCCW = algorithm.Orientation.isCCW(jstsPoints);
  return isCCW;
};

export const nearestPoint = (geom1, geom2) => operation.distance.DistanceOp.nearestPoints(geom1, geom2);
