import SwpCorridor from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpCorridor';
import SwpApartmentPart from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpApartmentPart';
import SwpBalcony from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpBalcony';
import SwpApartment from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpApartment';
import SwpColumn from '@swapp/swappcommonjs/dist/swpProject/physicalProducts/SwpColumn';
import SwpBuildingStory from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpBuildingStory';
import SwpSpatialProduct from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpSpatialProduct';
import SwpParkingStall from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpParkingStall';
import SwpParkingLane from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpParkingLane';
import SwpTube from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpTube';
import SwpStoryCore from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpStoryCore';
import SwpRamp from '@swapp/swappcommonjs/dist/swpProject/SwpRamp';

const SwpSpatialProductColors = {
  ELEVATORS_CORE: '#ede9b2',
  SHAFT: '#d2d2d2',
  STAIRCASE_CORE: '#ede988',
  MECHANICAL_ROOM: '#d2d2d2',
  LOBBY_LOUNGE: '#e9bbff',
  MAILROOM: '#deb1ff',
};

const getSwpSpatialProductColor = (swpAppartment) => {
  const variantColor = SwpSpatialProductColors[swpAppartment.variant];
  if (variantColor) {
    return variantColor;
  }
  return '#ede9b2';
};

const getSwpApartmentColor = (swpAppartment) => {
  if (swpAppartment.name === 'A') {
    return '#8AB8FF';
  }
  if (swpAppartment.name === 'B') {
    return '#527CE9';
  }
  if (swpAppartment.name === 'C') {
    return '#3465BF';
  }
  return '#3465BF';
};

export const CONFIG = new Map([
  [SwpApartment, { children: [SwpApartmentPart, SwpBalcony], stroke: true, fill: false, fillColor: '', strokeColor: '#000000', strokeStyle: 'solid', strokeWidth: 0.1 }],
  [SwpApartmentPart, { children: [SwpColumn], stroke: false, fill: true, fillColor: getSwpApartmentColor, strokeColor: '#000000', strokeStyle: 'solid', strokeWidth: 0.1 }],
  [SwpBalcony, { stroke: true, fill: true, fillColor: '#BCE8FF', strokeColor: '#000000', strokeStyle: 'solid', strokeWidth: 0.1 }],
  [SwpCorridor, { stroke: true, fill: true, fillColor: '#d2d2d2', strokeColor: '#000000', strokeStyle: 'solid', strokeWidth: 0.1 }],
  [SwpParkingStall, { stroke: true, fill: true, fillColor: '#cccccc', strokeColor: '#424242', strokeStyle: 'solid', strokeWidth: 0.1 }],
  [SwpParkingLane, { stroke: true, fill: false, fillColor: '#cccccc', strokeColor: '#424242', strokeStyle: 'solid', strokeWidth: 0.1 }],
  [SwpRamp, { stroke: true, fill: false, fillColor: '#cccccc', strokeColor: '#424242', strokeStyle: 'solid', strokeWidth: 0.2 }],
  [SwpBuildingStory, { children: [SwpCorridor, SwpApartment], stroke: false, fill: true, fillColor: '#bdbdbd', strokeColor: '#424242', strokeStyle: 'solid', strokeWidth: 0.2, fillZHeight: 0.1 }],
  [SwpSpatialProduct, { children: [SwpCorridor, SwpApartment], stroke: true, fill: true, fillColor: getSwpSpatialProductColor, strokeColor: '#000000', strokeStyle: 'solid', strokeWidth: 0.1 }],
  [SwpColumn, { stroke: true, fill: true, fillColor: '#7e7e7e', strokeColor: '#424242', strokeStyle: 'solid', strokeWidth: 0.1 }],
  [SwpTube, { stroke: false, fill: false, children: [] }],
  [SwpStoryCore, { children: [], stroke: true, fill: false, fillColor: '', strokeColor: '#000000', strokeStyle: 'solid', strokeWidth: 0.1 }],
]);
