import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, message } from 'antd';
import lodashDebounce from 'lodash/debounce';
import lodashGet from 'lodash/get';
import T from 'i18n-react';
import lodashIncludes from 'lodash/includes';
import { GENERATE_SWAPP_FORM } from 'constants/fieldNames';
import { PROJECT_STAGES, PROJECT_STATUS } from 'constants/profileConsts';
import { useComDidMount } from 'utils/hooks';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import { renderProjectName } from 'utils/helpers/dataDisplay';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useHistory, useRouteMatch } from 'react-router';
import { editProjectAction, getSwappProjectAction } from 'store/swappProfile/actions/swappProfileActions';
import { StyledForm } from './ProjectInfoForm.styles';
import ProjectInfoForm from './ProjectInfoForm';
import { loadingSelector, swappProjectSelector } from '../../../store/swappProfile/selectors';
import FormWithLabel from '../../common/FormWithLabel';
import LoadingSpinner from '../../common/LoadingSpinner';

const BackText = styled.div`
  font-size: ${({ theme }) => theme.font.size.small};
  margin-left: 3px;
`;

const ActionBarIconWrapper = styled.div`
  display: flex;
  position: absolute;
  top: -5px;
  left: 15px;
  font-size: ${({ theme }) => theme.font.size.large};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &:hover {
    color: ${({ theme }) => theme.colors.primaryColor};
  }
  
  svg {
    cursor: pointer;
  }
`;

const ProjectInfoContainer = () => {
  const swappProject = useSelector(swappProjectSelector);
  const loading = useSelector(loadingSelector);
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [currentStatus, useCurrentStatus] = useState();
  const rootProfile = lodashGet(swappProject, 'projectProfiles', [])
    .find((profile) => (
      lodashIncludes(`${PROJECT_STAGES[UI_AUTHORIZE_PATH.TEST_FIT]} ${PROJECT_STAGES[UI_AUTHORIZE_PATH.FEASIBILITY_STUDY]}`, profile.stage)
    ));
  const rootStageKey = lodashGet(rootProfile, 'stage');
  const sitePolygonLatLon = rootProfile?.profileData?.siteData?.sitePolygon;

  useComDidMount(() => {
    if (swappProject && swappProject.id === match.params.projectId) {
      return;
    }
    dispatch(getSwappProjectAction(match.params.projectId));
  });

  useEffect(() => {
    if (swappProject && !loading) {
      updateStatus(swappProject.status, useCurrentStatus);
      const currentProjectInfo = {
        [GENERATE_SWAPP_FORM.PROJECT_NAME]: renderProjectName(swappProject.name, true),
        [GENERATE_SWAPP_FORM.STATUS]: swappProject.status,
        [GENERATE_SWAPP_FORM.THUMBNAIL]: swappProject.imageUrl,
        [GENERATE_SWAPP_FORM.FLOOR]: swappProject.floor,
        [GENERATE_SWAPP_FORM.PREFERRED_FLOORS]: swappProject.floor,
        [GENERATE_SWAPP_FORM.LOCATION_NAME]: swappProject.locationName,
        [GENERATE_SWAPP_FORM.LOCATION]: { lat: swappProject.lat, lng: swappProject.lng },
      };
      form.setFieldsValue(currentProjectInfo);
    }
  }, [swappProject, rootStageKey]);

  const updateStatus = (value, update) => {
    update(value);
  };

  const handleThumbnailUpload = (thumbnail) => {
    const formData = new FormData();
    formData.append(GENERATE_SWAPP_FORM.THUMBNAIL, thumbnail.file);
    dispatch(editProjectAction(match.params.projectId, formData))
      .catch((error) => message.error(error));
  };

  const handleStatusUpdate = (status) => {
    updateStatus(status, useCurrentStatus);
    const formData = new FormData();
    formData.append(GENERATE_SWAPP_FORM.STATUS, status);
    dispatch(editProjectAction(match.params.projectId, formData))
      .catch((error) => message.error(error));
  };

  const handleFormEdit = useCallback(
    lodashDebounce(() => {
      form.validateFields()
        .then((values) => {
          if ((swappProject[GENERATE_SWAPP_FORM.LOCATION_NAME] !== values.locationName) && (swappProject.lat === values?.location?.lat)) {
            return;
          }

          const formData = new FormData();
          formData.append(GENERATE_SWAPP_FORM.LOCATION_NAME, values[GENERATE_SWAPP_FORM.LOCATION_NAME]);
          if (values[GENERATE_SWAPP_FORM.LOCATION]) {
            formData.append(GENERATE_SWAPP_FORM.LAT, values[GENERATE_SWAPP_FORM.LOCATION][GENERATE_SWAPP_FORM.LAT]);
            formData.append(GENERATE_SWAPP_FORM.LNG, values[GENERATE_SWAPP_FORM.LOCATION][GENERATE_SWAPP_FORM.LNG]);
          }
          formData.append(GENERATE_SWAPP_FORM.PROJECT_NAME, values[GENERATE_SWAPP_FORM.PROJECT_NAME]);

          if (rootStageKey === PROJECT_STAGES[UI_AUTHORIZE_PATH.TEST_FIT]) {
            formData.append(GENERATE_SWAPP_FORM.FLOOR, values[GENERATE_SWAPP_FORM.FLOOR]);
          }

          dispatch(editProjectAction(match.params.projectId, formData))
            .catch((error) => message.error(error));
        });
    }, 1200), [swappProject],
  );

  return (
    <StyledForm
      form={form}
      style={{ position: 'relative' }}
      name="ProjectInfo"
      onValuesChange={handleFormEdit}
      initialValues={{ [GENERATE_SWAPP_FORM.STATUS]: PROJECT_STATUS.OPEN }}
    >
      <ActionBarIconWrapper marginRight={18} onClick={() => history.goBack()}>
        <ArrowLeftOutlined />
        <BackText>{T.translate('BACK')}</BackText>
      </ActionBarIconWrapper>
      {loading && <LoadingSpinner />}
      <FormWithLabel
        title={T.translate('NAVIGATION_PROJECT_INFO')}
        width={700}
      >
        <ProjectInfoForm
          handleFormEdit={handleFormEdit}
          updateStatus={handleStatusUpdate}
          history={history}
          currentStatus={currentStatus}
          handleThumbnailUpload={handleThumbnailUpload}
          form={form}
          rootStageKey={rootStageKey}
          sitePolygonLatLon={sitePolygonLatLon}
          isEdit
        />
      </FormWithLabel>
    </StyledForm>
  );
};

export default ProjectInfoContainer;
