import React from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingSmallSpinnerWrapper = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 22px;
  height: 22px;
  background-color: ${({ theme }) => theme.colors.primaryColor};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
`;

const IconWrapper = styled.span`
  position: absolute;
  top: 0;
  left: 4px;
`;

const LoadingSpinnerSmall = () => (
  <LoadingSmallSpinnerWrapper>
    <IconWrapper>
      <LoadingOutlined spin />
    </IconWrapper>
  </LoadingSmallSpinnerWrapper>
);

export default LoadingSpinnerSmall;
