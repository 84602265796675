import * as THREE from 'three';
import { extend } from 'react-three-fiber';

export default class FadeMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      uniforms: {
        texture0: { type: 't', value: undefined },
        texture1: { type: 't', value: undefined },
        lerp: { type: 'f', value: 0.0 },
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4( position, 1.0 );
        }`,
      fragmentShader: `
        varying vec2 vUv;
        uniform sampler2D texture0;
        uniform sampler2D texture1;
        uniform float lerp;

        void main() {
          gl_FragColor = vec4( mix( texture2D(texture0, vUv).xyz, texture2D(texture1, vUv).xyz, lerp ), 1. );
        }`,
    });
  }
}

// register element in r3f (<fade />)
extend({ FadeMaterial });
