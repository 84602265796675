/* eslint-disable no-undef */
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import lodashIncludes from 'lodash/includes';
import { Input, InputNumber, Form, Table, Radio, Select, Tabs, Switch, Menu, Slider, Upload } from 'antd';
import Icon, { SearchOutlined } from '@ant-design/icons';
import { COLOR_BY_STAGE } from 'constants/profileConsts';

const { SubMenu } = Menu;
const { Dragger } = Upload;
const cellMargin = '2px';

export const PrimaryButton = styled.button`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  height: ${({ height }) => (height ? `${height}px` : '42px')};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  margin: ${({ center }) => (center ? '0 auto' : '0')};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '')};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : '')};
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}px` : '')};
  background-color: ${({ disabled, theme }) => (disabled ? theme.colors.gray_03 : theme.colors.primaryColor)};
  color: ${({ theme }) => theme.colors.white};
  border: unset;
  z-index: ${({ zIndex }) => (zIndex || 1)};
  padding: ${({ padding }) => padding || '15px auto'};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	font-weight: ${({ theme, bold }) => (bold ? theme.font.weight.bold : theme.font.weight.light)};
  font-size: ${({ theme, fontSize = 'large' }) => theme.font.size[fontSize]};
  :focus {
			outline: unset;
	}
	:hover {
	    background-color: 	${({ disabled, theme }) => (disabled ? theme.colors.gray_03 : theme.colors.primaryColorHover)};
	}
`;

export const SecondaryButton = styled(PrimaryButton)`
  color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_04 : theme.colors.primaryColor)};
  background-color: ${({ theme }) => theme.colors.gray_01};
  font-weight: ${({ theme, bold }) => (bold ? theme.font.weight.bold : theme.font.weight.normal)};
  border: 2px solid ${({ theme, disabled }) => (disabled ? theme.colors.gray_04 : theme.colors.primaryColor)}
  
  :hover {
    color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_04 : theme.colors.primaryColorHover)};
    background-color: ${({ theme, disabled }) => (disabled ? 'unset' : theme.colors.white)};
    border-color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_04 : theme.colors.primaryColorHover)};
  }
`;

export const StyledLink = styled(NavLink)`
  font-size: ${({ theme }) => theme.font.weight.normal};
  color: ${({ theme }) => theme.colors.textColor};
  
  :hover {
    color: ${({ theme }) => theme.colors.primaryColor};
  }
`;

const StyledInputNumberBeforeEvents = styled(InputNumber)` 
	height: ${({ height }) => (height ? `${height}px` : '32px')};
  font-size: ${({ theme }) => theme.font.size.small} !important;
  width:${({ width }) => (width ? `${width}px` : '100%')};
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}px` : 0)} !important;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 0)};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  border: ${({ theme, disabled, borderUnset }) => {
    if (disabled) {
      return 0;
    }
    if (borderUnset) {
      return '1px solid unset';
    }
    return disabled ? `1px solid ${theme.colors.gray_06}` : `1px solid ${theme.colors.gray_04}`;
  }
};
  cursor: ${({ disabled }) => (!disabled || 'default')};
  background-color: ${({ theme, disabled }) => (disabled ? `${theme.colors.primaryColor}22` : theme.colors.inputBackground)};
  color: ${({ theme, disabled, total }) => {
    if (total) {
      return theme.colors.white;
    }
    return disabled ? theme.colors.gray_07 : theme.colors.textColor;
  }};

  :focus,
  :focus-within,
  :hover {
    border-color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_06 : theme.colors.gray_04)};
    box-shadow: none !important;
  }

  .ant-input-number-input-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  
  .ant-input-number-input {
    cursor: ${({ disabled }) => (!disabled || 'default')};
    height: unset;
    text-align: right;
    padding: 0 8px;
  }

  .ant-input-number-handler-wrap {
    left: ${({ right }) => (right ? 'unset' : 0)};
    right: ${({ right }) => (right ? 0 : 'unset')};
    width: 12px;
    border: 0;
    background-color: ${({ theme }) => theme.colors.inputBackground};
    border-radius: ${({ theme, right }) => (right ? `0 ${theme.other.borderRadius} ${theme.other.borderRadius} 0` : `${theme.other.borderRadius} 0 0 ${theme.other.borderRadius}`)};

    .ant-input-number-handler {
      &:hover, &:focus {
        color: ${({ theme }) => theme.colors.primaryColor} !important;
        fill: ${({ theme }) => theme.colors.primaryColor} !important;
      }
    }


    .ant-input-number-handler-down-inner,
    .ant-input-number-handler-up-inner {
      width: 6px;

      &:hover {
        color: ${({ theme }) => theme.colors.primaryColor} !important;
      }
    }
  }
`;

export const StyledInputNumber = forwardRef((props, ref) => (
  <StyledInputNumberBeforeEvents
    ref={ref}
    onKeyDown={(e) => e.stopPropagation()}
    onKeyUp={(e) => e.stopPropagation()}
    {...props}
  />
));

export const StyledInput = styled(Input)`
  height: ${({ height }) => (height ? `${height}px` : '32px')};
  font-size: ${({ theme }) => theme.font.size.small};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  border: solid 1px ${({ hasError, theme }) => (hasError ? theme.colors.danger : theme.colors.gray_04)};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_07 : theme.colors.textColor)} !important;
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_03 : theme.colors.inputBackground)} !important;

  .ant-input {
    background-color: transparent !important;
  }

  &:focus,
  &:focus-within,
  &:hover {
    border-color: ${({ theme }) => theme.colors.gray_04} !important;
    box-shadow: none !important;
  }
`;

export const StyledSelect = styled(Select)` 
  border-radius: ${({ theme }) => theme.other.borderRadius};
  width: ${({ width }) => (width ? `${width}px` : '100%')} !important;
  height: ${({ height }) => (height ? `${height}px` : 'unset')};
  
  &:hover {
    border-color: ${({ theme }) => theme.colors.primaryColor};
  }
  .ant-select-selector {
    background-color: ${({ theme }) => theme.colors.inputBackground} !important;
    border-radius: ${({ theme }) => theme.other.borderRadius} !important;
    border: 1px solid ${({ theme }) => theme.colors.gray_04} !important;
    height: ${({ height }) => (height ? `${height}px` : 'unset')} !important;
  }
  
  .ant-select-selection-item {
    color: ${({ theme }) => theme.colors.textColor} !important;
    line-height: ${({ height }) => (height ? `${height}px` : '30px')} !important;
    border-radius: ${({ theme }) => theme.other.borderRadius};
    font-size: ${({ theme }) => theme.font.size.small};
  }
  
  .ant-select-selection-item-remove{
    color: ${({ theme }) => theme.colors.gray_01};
    line-height: 2.2;
  }
  
  .ant-select-selector {
    &:focus,
    &:focus-within,
    &:hover {
      border-color: ${({ theme }) => theme.colors.gray_04} !important;
      box-shadow: none !important;
    }
  }
`;

export const StyledFormItem = styled(({ hideError, theme, hidden, width, marginBottom, ...props }) => <Form.Item {...props} />)` 
  ${(p = props) => css`
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '16px')};
  width: ${p.width ? `${p.width}px` : '100%'};
  display: ${p.hidden ? 'none' : 'inherit'};
  
  .ant-form-item-label label, .ant-form-item-label{
    line-height: 1;
    display: flex;
    font-size: ${p.theme.font.size.inputLabel};
  }
  
  .ant-form-item-explain, .ant-form-item-extra {
    height: 0;
    min-height: 0;
    display: ${p.hideError && 'none'};
  }
  
  :last-child {
    margin: 0;
  }
  `} 
`;

export const StyledRadio = styled(Radio)`
  display: flex;
  line-height: 30px;
  
  .ant-radio {
    position: ${({ absolute }) => (absolute && 'absolute')};
  }
  
  span.ant-radio + * {
    font-size: ${({ theme }) => theme.font.size.small};
  }
  
  .ant-radio-checked .ant-radio-inner {
    border-color: ${({ theme }) => theme.colors.primaryColor};
  } 
  
  .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${({ theme }) => theme.colors.primaryColor} !important;
    box-shadow: none !important;
  }

  .ant-radio-inner::after {
    background-color: ${({ theme }) => theme.colors.primaryColor};
  }
`;

export const StyledInputAuth = styled(StyledInput)`
  height: 42px;
`;

export const InputPrefixIcon = styled(SearchOutlined)`
  color: #bfbfbf;
`;

export const ResultMetadataView = styled.div`
  min-width: ${({ width }) => `${width}px`};
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-x: hidden;
  overflow-y: auto;

  transition: width .45s linear;
`;

export const ColorPill = styled.div`
  width: ${({ width }) => (width ? `${width}px` : '5px')};
  height: ${({ height }) => (height ? `${height}px` : '18px')};
  margin: ${({ margin }) => (margin || '0 8px 0 0')};
  border-radius: 5px;
  background-color: ${({ theme, stage }) => theme.colors[COLOR_BY_STAGE[stage]]};
`;

const tableSeparation = 4;
const tableBorderRadius = 7;

export const StyledExploreTable = styled(Table)`
  background-color: transparent !important;
  font-weight: ${({ theme, bold }) => (bold && theme.font.weight.bold)};
  font-size: ${({ theme }) => theme.font.size.small};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};

  tbody tr {
    .ant-table-cell {
      padding: 0;
      height: ${({ cellHeight }) => (cellHeight ? `${cellHeight + 8}px` : 'unset')};
      background-color: transparent; !important;
      position: relative;
      border-style: solid;
      border-width: ${tableSeparation}px 0 ${tableSeparation}px 0;
      border-color: transparent;
      z-index: 1;
      
      &:after {
        position: absolute;
        z-index: -1;
        content: " ";
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: ${({ theme }) => `${theme.colors.gray_04}60`};
      }

      &:first-child {
        padding-left: 10px !important;
        
        &:after {
          border-radius: ${tableBorderRadius}px 0 0 ${tableBorderRadius}px;
        }
      }

      &:last-child {
        padding-right: 10px !important;

        &:after {
          border-radius: 0 ${tableBorderRadius}px ${tableBorderRadius}px 0;
        }
      }
    }
  }
  
  .ant-table {
    background-color: transparent !important;
  }

  .ant-table-row-expand-icon {
    display: none;
  }

  .ant-table-row-selectable {
    cursor: pointer;
  }

  .ant-table-total-row {
    &:hover {
      .ant-table-cell {
        color: ${({ theme }) => theme.colors.white} !important;
        background-color: unset;

        &:after {
          background-color: ${({ theme }) => theme.colors.primaryColorHover} !important;
        }
      }
    }

    .ant-table-cell {
      padding: 3px 0 !important;
      background-color: unset;
      color: ${({ theme }) => theme.colors.white} !important;

      &:after {
        background-color: ${({ theme }) => theme.colors.primaryColorHover} !important;
      }
    }
  }

  .ant-table-sub-total-row {
    &:hover {
      .ant-table-cell {
        color: ${({ theme }) => theme.colors.white} !important;
        background-color: unset;

        &:after {
          background-color: ${({ theme }) => theme.colors.primaryColorDisabled} !important;
        }
      }
    }

    .ant-table-cell {
      padding: 2px 0 !important;
      background-color: unset;
      color: ${({ theme }) => theme.colors.white} !important;

      &:after {
        background-color: ${({ theme }) => theme.colors.primaryColorDisabled} !important;
      }
    }
  }

  .ant-table-row-selected {
    .ant-table-cell {
      background-color: unset;

      &:after {
        background-color: ${({ theme }) => `${theme.colors.primaryColor}55`};
      }
    }

    &:hover {
      .ant-table-cell {
        background-color: unset;

        &:after {
          background-color: ${({ theme }) => `${theme.colors.primaryColor}40`};
        }
      }
    }
 }
  
  .table-sub-category {
    margin-right: 10px;
    .ant-table-cell {
      background-color: ${({ theme }) => `${theme.colors.gray_03}40`};

      &:first-child {
        padding-left: 18px !important;
      }
    }
  }

  .ant-table-cell {
    padding: 0;
  }
  
  .ant-table-thead > tr > th {
    font-weight: ${({ theme }) => theme.font.weight.bold} !important;
    background-color: transparent;
  }

  .ant-table-header-column {
    height: 100%;

    div {
      height: 100%;
    }
  }

  .ant-table-row-cell-break-word {
    height: 27px;
  }

  .ant-spin-dot {
    display: none;
  }

  .ant-table-thead {
    background-color: transparent;
    font-size: ${({ theme }) => theme.font.size.small};

    th {
      padding: 0;
      border-bottom: 0;

      .ant-table-header-column {
        width: 100%;
        height: 100%;
      }
    }
  }

  .ant-table-tbody {
    background-color: transparent !important;
    font-size: ${({ theme }) => theme.font.size.small};

    td {
      padding: 0;
        //border-bottom: ${({ hideBorder }) => (hideBorder ? 0 : `1px solid ${({ theme }) => theme.colors.gray_03}`)};
    }
  }

  // overwrite the hover effect for ANTD table
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background-color: unset;
    &:after{
      background-color: ${({ theme, bold }) => (bold ? `${theme.colors.gray_04}60` : `${theme.colors.primaryColor}12`)};
    }
  }
`;

export const StyledReportTable = styled(Table)`
  font-weight: ${({ theme, bold }) => (bold && theme.font.weight.bold)};
  font-size: ${({ theme }) => theme.font.size.small};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
  .ant-table-row-expand-icon {
    color: ${({ theme }) => theme.colors.primaryColor}
  }
  .ant-spin-dot {
    display: none;
  }

  .ant-table-row {
    background-color: ${({ theme }) => theme.colors.gray_01};
  }
    
  .ant-table-sub-row {
    background-color: ${({ theme }) => theme.colors.gray_03};
  }
    
  .ant-table-total-row {
    font-weight: 600;

    &:hover {
      .ant-table-cell {
          background-color: ${({ theme }) => theme.colors.primaryColor} !important;
        }
      }
    
    .ant-table-cell {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.primaryColor};

      &:first-child {
        padding-left: 10px;
        border-radius: ${({ theme }) => `${theme.other.borderRadius} 0 0 ${theme.other.borderRadius}`};
      }
      &:last-child {
        border-radius: ${({ theme }) => `0 ${theme.other.borderRadius} ${theme.other.borderRadius} 0`};
      }
    }
  }
    
  .ant-table-thead {
    background-color: transparent;
    font-size: ${({ theme }) => theme.font.size.small};
    th {
      padding: 0;
      border-bottom: 0;
      
      .ant-table-header-column {
        width: 100%;
        height: 100%;
      }
    }
  }
  .ant-table-tbody {
    font-size: ${({ theme }) => theme.font.size.small};


     td {
        padding: 2px 0;
        font-weight: ${({ bold }) => (bold && 800)};
        
        //border-bottom: ${({ theme, hasColor }) => (hasColor ? `8px solid ${theme.colors.gray_01}` : `1px solid ${theme.colors.gray_03}`)};
     }
  }
  // overwrite the hover effect for ANTD table
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background-color: unset;
  }
`;

export const NormalCell = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //height: ${({ big }) => (big ? '36px' : '29px')};
  margin: 0 0 0 ${cellMargin};
  // color: ${({ theme, status }) => ((status === 'min' && theme.colors.warning) || (status === 'max' && theme.colors.success))};
  font-weight: ${({ status }) => (status && 800)};
`;

export const HighlightCell = styled(NormalCell)`
  text-align: center;
  padding: 2px 0;
`;

export const CellsContainer = styled.div`
  display: flex;
  justify-content: center;
  
  div {
    margin: 0;
    padding: 0;
  }
`;

export const MainHeaderCell = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryColorHover};
  padding: 2px 0;
  letter-spacing: 1px;
  margin: ${cellMargin} 0 ${cellMargin} ${({ first }) => (first ? 0 : cellMargin)};
  color: ${({ theme }) => theme.colors.gray_01};
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: ${({ theme, first, last, lastRound }) => {
    if (first) {
      return `${theme.other.borderRadius} 0 0 ${theme.other.borderRadius}`;
    }
    if (last) {
      return `0 ${theme.other.borderRadius} 0 0`;
    }
    if (lastRound) {
      return `0 ${theme.other.borderRadius} ${theme.other.borderRadius} 0`;
    }
  }};
`;

export const SecondaryHeaderCell = styled.div`
  background-color: ${({ theme, highlight }) => (highlight ? theme.colors.primaryColorDisabled : 'transparent')};
  padding: 2px 0;
  text-align: ${({ left }) => (left || 'center')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 ${cellMargin} ${cellMargin};
  min-height: 42px;
  border-radius: ${({ theme, first, last, single }) => {
    if (first) {
      return `${theme.other.borderRadius} 0 0 ${theme.other.borderRadius}`;
    }
    if (last) {
      return `0 ${theme.other.borderRadius} ${theme.other.borderRadius} 0`;
    }
    if (single) {
      return `0 0 ${theme.other.borderRadius} ${theme.other.borderRadius}`;
    }
  }};
`;

export const EllipsisText = styled.div`
  display: list-item;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${({ width }) => (width ? `${width}px` : '145px')};
`;

export const StyledTab = styled(({ fontSize, width, padding, tabPadding, ...props }) => <Tabs {...props} />)`
  width: auto;
  padding: ${({ padding }) => (padding || '0')};
  overflow: initial;
  height: ${({ height }) => (height ? `${height}px` : 'unset')};

  .ant-tabs-tab {
    max-width: ${({ width }) => (width ? `${width}px` : '100%')};;
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '14px')} !important;
    color: ${({ theme }) => theme.colors.textColor};
    padding: ${({ tabPadding }) => (tabPadding || 'unset')} ;

    &:hover {
      color: ${({ theme }) => theme.colors.primaryColor} !important;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.colors.primaryColor} !important;
  }
  .ant-tabs-ink-bar {
    background-color: ${({ theme }) => theme.colors.primaryColor};
  }
`;

export const SimpleFormBoxTitle = styled.div`
  font-size: ${({ theme }) => theme.font.size.xxLarge};
  font-weight: ${({ theme }) => theme.font.weight.light};
  margin-bottom: 10px;
  
`;

export const SimpleFormBoxSeparator = styled.div`
  width: 1px;
  height: 110px;
  margin: 30px 40px 0;
  background-color: ${({ theme }) => theme.colors.gray_02}
`;

export const DeleteIcon = styled(Icon)`
  margin-top: 4px;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_03 : theme.colors.textColor)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  
  svg {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
  
  :hover {
    color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_03 : theme.colors.primaryColorHover)};
  }
`;

export const StyledSwitch = styled(Switch)`
  line-height: 1;
  //margin-left: 20px;
`;

export const MarkedInput = styled(StyledInput)`
  border-color: ${({ theme }) => theme.colors.primaryColor};
  color: ${({ theme }) => theme.colors.primaryColor};
  margin-left: ${({ marginleft }) => (marginleft && `${marginleft}px`)} ;
`;

export const RowText = styled.div`
  cursor: ${({ success }) => (success ? '' : 'pointer')};
  
  :after {
    content: '*';
    color: ${({ theme, error }) => (error && theme.colors.danger)};
    color: ${({ theme, success }) => (success && theme.colors.success)};
    color: ${({ theme, warning }) => (warning && theme.colors.danger)};
    font-size: ${({ theme }) => theme.font.size.large};
  }
`;

export const TotalRowText = styled.div`
  color: ${({ theme, error }) => (error && theme.colors.danger)};
  cursor: pointer;
`;

export const StyledMenu = styled(({ overflow, ...props }) => <Menu {...props} />)`
  background-color: transparent;
  //height: 100%;
`;

export const StyledSubMenu = styled(({ padding, ...props }) => <SubMenu {...props} />)`
  width: 100%;
  color: ${({ theme }) => theme.colors.textColor};
  padding: 0 !important;
  border-radius: ${({ theme }) => theme.other.borderRadius};
  z-index: 5;
  margin-bottom: 15px;
  box-shadow: ${({ hideShadow }) => (hideShadow ? 0 : '0 4px 20px rgba(0, 0, 0, 0.11)')};
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  
  .ant-menu-sub {
    padding: ${({ padding }) => (padding ? `${padding}px` : 0)} !important;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.textColor};
  }

  .ant-menu-submenu-title {
    padding: 0 !important;
    margin: 0 !important;
    height: 40px !important;
    line-height: 2.3 !important;
    
    .ant-menu-submenu-arrow {
      right: 12px;

      :before,
      :after {
         background: ${({ theme }) => theme.colors.gray_10} !important;
      }
    }

    &:hover {
      background-color: transparent !important;
    }
  }
`;

export const FormSubMenuWrapper = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
`;

export const SmallStyledTable = styled(StyledExploreTable)`
  font-weight: ${({ theme, total }) => (total && theme.font.weight.bold)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 0)};

  .ant-table-cell {
    padding: ${({ total }) => (total ? '4px 10px' : 0)} !important;
    color: ${({ theme, total }) => (total ? theme.colors.white : theme.colors.textColor)};
    
    &:after {
      background-color: ${({ theme, total }) => (total && theme.colors.primaryColor)} !important;
    }
  }
  
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background-color: unset !important;
  }
`;

export const ViewerUiWrapper = styled.div`
  background-color: rgba(251, 253, 255, 0.85);
  border-radius: ${({ theme }) => theme.other.borderRadius};
  backdrop-filter: blur(15px);
  width: ${({ isOpen, width = 345 }) => (isOpen ? `${width}px` : 0)};
  overflow-x: hidden;
  flex: 1;
  max-height: ${({ height }) => (height ? `${height}px` : '100%')};
  padding: ${({ isOpen }) => (isOpen ? '15px' : 0)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
  transition: width .6s ease-in-out;
  overflow-y: hidden;
`;

export const ViewerUiPanelWrapper = styled.div`
  position: absolute;
  z-index: 4;
  top: ${({ position }) => (lodashIncludes(position, 'top') ? 0 : 'unset')};
  bottom: ${({ position }) => (lodashIncludes(position, 'bottom') ? 0 : 'unset')};
  left: ${({ position }) => (lodashIncludes(position, 'left') ? 0 : 'unset')};
  right: ${({ position }) => (lodashIncludes(position, 'right') ? 0 : 'unset')};
  display: flex;
  margin: ${({ margin }) => (margin || 0)};
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};
  align-items ${({ isColumn }) => (isColumn ? 'flex-end' : 'stertch')};
  height: ${({ height, fitContent }) => {
    if (fitContent) {
      return 'fitContent';
    }
    return height ? `${height}px` : '100%';
  }};
  max-height: 100%;
`;

export const UiButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7px 4px 4px 4px;
`;

export const Overlay = styled.div`
  background-color: rgba(0, 0, 0, .25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ theme }) => `${theme.other.headerHeight}px`};
  z-index: 3;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')}
  
  transition: all .3s ease-in-out;
`;

export const StyledSlider = styled(Slider)`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  padding: ${({ padding }) => (padding ? `${padding}px` : 'unset')};

  .ant-slider-track {
    background-color: ${({ theme, disabled, isInvalid }) => {
    if (isInvalid) {
      return theme.colors.danger;
    }

    return disabled ? theme.colors.gray_06 : theme.colors.primaryColor;
  }} !important;
  }

  .ant-slider-dot {
    background-color: ${({ theme }) => theme.colors.gray_02};
    border-color: ${({ theme }) => theme.colors.gray_02};
  }
  
  .ant-slider-handle {
    border-color: ${({ theme, disabled, isInvalid }) => {
    if (isInvalid) {
      return theme.colors.danger;
    }

    return disabled ? theme.colors.gray_06 : theme.colors.primaryColor;
  }} !important;
    background-color: ${({ theme }) => theme.colors.gray_02};
  }
  
  .ant-slider-rail {
    background-color: ${({ theme }) => theme.colors.gray_02};
  }
  
  .ant-slider-mark-text {
    color: ${({ theme }) => theme.colors.gray_10};
  }
`;

export const MailLink = styled.a`
  color: #1C2BF2;

  margin-left: 5px;
  
  :hover {
    color: #5773FF;
  }
`;

export const StyledDragger = styled(Dragger)`
  border: 0 !important;
  background: unset !important;

  .ant-upload-btn {
    padding: 0 !important;
  }
`;
