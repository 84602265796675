import styled from 'styled-components';
import Icon from '@ant-design/icons';

export const NoSupportWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.gray_01};
`;

export const SwappLogo = styled(Icon)`
  margin: 0 auto;
  font-size: 260px;
  color: ${({ theme }) => theme.colors.textColor};
`;

export const Text = styled.div`
  margin: 0 auto;
  font-size: ${({ theme }) => theme.font.size.xLarge};
  justify-content: center;
  display: flex;
  text-align: center;
  max-width: 40%;
`;
