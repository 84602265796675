import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashSortedUniq from 'lodash/sortedUniq';
import lodashMapValues from 'lodash/mapValues';
import lodashKeys from 'lodash/keys';
import lodashIncludes from 'lodash/includes';
import lodashSortBy from 'lodash/sortBy';
import lodashFilter from 'lodash/filter';
import T from 'i18n-react';
import moment from 'moment';

import {
  BUILDING_INFO_KEYS,
  STANDARDS,
  APARTMENT_TYPES,
  NIA_UNIT_TYPES,
  COMMUNAL_SPACES,
  UNIT_SORT_LIST,
  MODEL_ANALYSIS_TYPES,
  ALL_APARTMENT_TYPES_ARRAY,
} from 'constants/feasibilityConts';
import { legendColors } from 'constants/feasibilityReportConts';

import { getPolygonArea } from '../algorithms/algorithmHelpers';
import { sqmToSqf } from '../helpers/unitsHelpers';

const isUnitApartment = (unit) => APARTMENT_TYPES.includes(unit.name);
const isUnitCommunal = (unit) => {
  const communalListWithoutCores = Object.values(COMMUNAL_SPACES).filter((e) => !e.includes('CORE'));
  return communalListWithoutCores.includes(unit.name);
};

const addTotalFieldToObject = (obj) => {
  obj.TOTAL = Object.values(obj).reduce((a, b) => a + b, 0);
};

const getBuildingFloorDivisionTableData = (massDto) => {
  const floorDivisionTableData = [];
  const apartmentCounts = {};

  const floorGroups = massDto.floor_groups.filter((fg) => fg.count > 0);
  floorGroups.forEach((floorGroup) => {
    const apartments = floorGroup.units.filter(isUnitApartment);
    const unitSummary = apartments.reduce((map, unit) => {
      const unitType = unit.name;
      map[unitType] = (map[unitType] || 0) + 1;
      apartmentCounts[unitType] = (apartmentCounts[unitType] || 0) + floorGroup.count;
      return map;
    }, Object.create(null));
    unitSummary.DUAL_ASPECT = apartments.filter((unit) => unit.aspects > 1).length;
    unitSummary.TOTAL = apartments.length;
    for (let i = 0; i < floorGroup.count; i++) {
      floorDivisionTableData.push({
        ...unitSummary,
        NAME: `${T.translate('Floor')} ${floorDivisionTableData.length}`,
      });
    }
  });

  return { floorDivisionTableData, apartmentCounts };
};

const getBuildingOccupancyTableData = (apartmentCounts, isImperial) => {
  const occupancyCounts = lodashMapValues(apartmentCounts, (apartmentCount, apartmentName) => apartmentCount * ALL_APARTMENT_TYPES_ARRAY[ALL_APARTMENT_TYPES_ARRAY.findIndex((item) => item.key === apartmentName)].occupancies);
  addTotalFieldToObject(occupancyCounts);
  const cycleSpaces = lodashMapValues(apartmentCounts, (apartmentCount) => (isImperial ? sqmToSqf(apartmentCount * 2) : apartmentCount * 2));
  addTotalFieldToObject(cycleSpaces);
  const wasteStorage = lodashMapValues(apartmentCounts, (apartmentCount) => (isImperial ? sqmToSqf(Math.round(apartmentCount / 2)) : Math.round(apartmentCount / 2)));
  addTotalFieldToObject(wasteStorage);

  return [
    {
      NAME: T.translate('Occupancy (People)'),
      ...occupancyCounts,
    },
    {
      NAME: `${T.translate('Cycle spaces required')} (${T.translate(isImperial ? 'SQF' : 'SQM')})`,
      ...cycleSpaces,
    },
    {
      NAME: `${T.translate('Waste storage')} (${T.translate(isImperial ? 'SQF' : 'SQM')})`,
      ...wasteStorage,
    },
  ];
};

export const getBuildingAreaSummaryTableData = (massDto) => {
  if (massDto === undefined) {
    return [];
  }

  const floorGroups = massDto.floor_groups.filter((fg) => fg.count > 0);
  const floorBuildingAreaSummaryTableData = [];
  const gia = getPolygonArea(floorGroups[0].envelope);
  let totalNia = 0;
  let totalGia = 0;

  floorGroups.forEach((floorGroup) => {
    const nia = floorGroup.units.filter((unit) => NIA_UNIT_TYPES.includes(unit.name)).map((unit) => getPolygonArea(unit.points)).reduce((a, b) => a + b, 0);
    const niaToGia = nia / gia;
    for (let i = 0; i < floorGroup.count; i++) {
      floorBuildingAreaSummaryTableData.push({
        NAME: `${T.translate('Floor')} ${floorBuildingAreaSummaryTableData.length}`,
        [BUILDING_INFO_KEYS.GIA]: Math.round(gia),
        [BUILDING_INFO_KEYS.NIA]: Math.round(nia),
        [BUILDING_INFO_KEYS.NIA_TO_GIA]: `${Math.round(niaToGia * 100)}%`,
      });
      totalNia += nia;
      totalGia += gia;
    }
  });

  floorBuildingAreaSummaryTableData.push({
    NAME: T.translate('Total'),
    [BUILDING_INFO_KEYS.GIA]: Math.round(totalGia),
    [BUILDING_INFO_KEYS.NIA]: Math.round(totalNia),
    [BUILDING_INFO_KEYS.NIA_TO_GIA]: `${Math.round((totalNia / totalGia) * 100)}%`,
  });
  return floorBuildingAreaSummaryTableData;
};

// Recieve an array of similary-formatted objects, iterate through all of the recursively
// and return one object with the sum of the numbers of the equally-formatted fields
// [{"a": [{"b": 1}]}, {"a": [{"b": 3}]}] -> {"a": [{"b": 4}]}
export const mergeObjectListRecursively = (objects) => {
  if (!Array.isArray(objects) || objects.length === 0) {
    return [];
  }
  const firstObject = objects[0];
  if (objects.length === 1) {
    return firstObject;
  }
  if (Array.isArray(firstObject)) {
    // Merging arrays
    const maxArrayLength = objects.reduce((m, arr) => Math.max(m, arr.length), 0);
    const merged = [];
    for (let i = 0; i < maxArrayLength; i++) {
      const entries = objects.map((obj) => obj[i]).filter((obj) => obj !== undefined);
      merged.push(mergeObjectListRecursively(entries));
    }
    return merged;
  }
  if (typeof firstObject === 'object') {
    // Merging objects (dictionaries)
    const keyNames = new Set();
    objects.forEach((obj) => Object.keys(obj).forEach((keyName) => keyNames.add(keyName)));
    const merged = {};
    keyNames.forEach((keyName) => {
      const entries = objects.map((obj) => obj[keyName]).filter((obj) => obj !== undefined);
      merged[keyName] = mergeObjectListRecursively(entries);
    });
    return merged;
  }
  if (typeof firstObject === 'number') {
    return objects.reduce((a, b) => a + b, 0);
  }
  return firstObject;
};

const forEachRecursive = (object, callback) => {
  callback(object);
  if (Array.isArray(object)) {
    object.forEach((subObject) => forEachRecursive(subObject, callback));
  } else if (typeof object === 'object') {
    Object.values(object).forEach((subObject) => forEachRecursive(subObject, callback));
  }
};

const getBuildingApartmentMixData = (massDto, index, isImperial) => {
  const buildingName = `${T.translate('Building')} ${index + 1}`;
  const { floorDivisionTableData, apartmentCounts } = getBuildingFloorDivisionTableData(massDto);
  const occupancyTableData = getBuildingOccupancyTableData(apartmentCounts, isImperial);
  const areaSummaryTableData = getBuildingAreaSummaryTableData(massDto);
  return { buildingName, floorDivisionTableData, occupancyTableData, areaSummaryTableData }; //
};

export const getApartmentsMix = (profile, isImperial) => {
  const buildingsImages = lodashGet(profile, 'result.reportData.designData.apartmentsMixData.renders');
  const massingOption = lodashGet(profile, 'result.singleOptionMassingOptions.massing_options[0]');
  if (!massingOption) {
    return [];
  }

  const buildingMixData = massingOption.masses.map(
    (massDto, index) => getBuildingApartmentMixData(massDto, index, isImperial),
  );
  if (buildingMixData.length > 1) {
    const totalMixData = mergeObjectListRecursively(buildingMixData);
    forEachRecursive(totalMixData, (obj) => {
      if (typeof obj === 'object' && !Array.isArray(obj)) {
        if (BUILDING_INFO_KEYS.NIA_TO_GIA in obj) {
          const niaToGia = obj[BUILDING_INFO_KEYS.NIA] / obj[BUILDING_INFO_KEYS.GIA];
          obj[BUILDING_INFO_KEYS.NIA_TO_GIA] = `${Math.round(niaToGia * 100)}%`;
        }
      }
    });
    totalMixData.buildingName = 'TOTAL';
    buildingMixData.push(totalMixData);
  }

  return {
    buildings: buildingMixData,
    buildingsImages: [...buildingsImages, { name: 'TOTAL', url: lodashGet(profile, 'result.reportData.designData.apartmentsMixData.allColored') }],
  };
};

const getCommunalRoomSummaries = (massingOption) => {
  const communalRoomSummaries = { TOTAL: {} };
  const buildingNames = [];

  massingOption.masses.forEach((massDto, massIndex) => {
    const buildingName = `${massIndex + 1}`;
    buildingNames.push(buildingName);
    communalRoomSummaries.TOTAL[buildingName] = { FLOOR: '', ROOMS: 0, AREA: 0, TYPE: 'TOTAL' };
    const buildingTotalSummary = communalRoomSummaries.TOTAL[buildingName];
    let currentFloor = 0;
    const floorGroups = massDto.floor_groups.filter((fg) => fg.count > 0);
    floorGroups.forEach((floorGroup) => {
      const floorName = `${T.translate('Floor')} ${currentFloor}`;
      floorGroup.units.filter(isUnitCommunal).forEach((unit) => {
        const unitArea = Math.round(getPolygonArea(unit.points));
        const unitName = unit.name;
        if (!(unitName in communalRoomSummaries)) {
          communalRoomSummaries[unitName] = {};
        }
        const communalRoomSummary = communalRoomSummaries[unitName];
        if (!(buildingName in communalRoomSummary)) {
          communalRoomSummary[buildingName] = { FLOOR: floorName, ROOMS: 1, AREA: unitArea };
        } else {
          communalRoomSummary[buildingName].ROOMS += 1;
          communalRoomSummary[buildingName].AREA += unitArea;
        }
        buildingTotalSummary.ROOMS += 1;
        buildingTotalSummary.AREA += unitArea;
      });
      currentFloor += floorGroup.count;
    });
  });
  return { communalRoomSummaries, buildingNames };
};

export const getCommunalData = (profile) => {
  const massingOption = lodashGet(profile, 'result.singleOptionMassingOptions.massing_options[0]');
  if (!massingOption) {
    return;
  }

  const { communalRoomSummaries, buildingNames } = getCommunalRoomSummaries(massingOption);

  const communalRoomNames = Object.keys(communalRoomSummaries);
  communalRoomNames.splice(communalRoomNames.indexOf('TOTAL'), 1);
  communalRoomNames.push('TOTAL');

  const unitInfo = massingOption.metadata.building_info.unit_info;
  const namesTableData = communalRoomNames.map((name) => (name === 'TOTAL'
    ? { NAME: T.translate('Total'), TYPE: 'TOTAL' }
    : { NAME: name.startsWith('OTHER') ? lodashGet(unitInfo, `${name}.display_name`, 'Other') : T.translate(name) }));

  const blocksData = buildingNames.map((buildingName) => communalRoomNames.map((roomName) => {
    if (buildingName in communalRoomSummaries[roomName]) {
      return { ...communalRoomSummaries[roomName][buildingName] };
    }
    return { TYPE: 'EMPTY' };
  }));

  let totalData;
  if (buildingNames.length > 1) {
    totalData = communalRoomNames.map((roomName) => Object.values(communalRoomSummaries[roomName]).reduce((summary1, summary2) => ({
      ...summary1,
      ROOMS: summary1.ROOMS + summary2.ROOMS,
      AREA: summary1.AREA + summary2.AREA,
    })));
  }
  //

  buildingNames.forEach((buildingName, index) => { blocksData[index][0].BLOCK_NAME = buildingName; });

  const communalImageUrl = lodashGet(profile, 'result.reportData.designData.communalData.communalScreenshot.url');

  return {
    communalImageUrl,
    namesTableData,
    blocksData,
    totalData,
  };
};
//
export const getDevelopmentAppraisalData = () => {
  console.log('getDevelopmentAppraisalData');
};

export const getWellbeingData = (profile) => {
  if (!profile) {
    return {};
  }

  const buildingsImages = lodashGet(profile, 'result.reportData.designData.apartmentsMixData.renders');
  const massingOption = lodashGet(profile, 'result.singleOptionMassingOptions.massing_options[0]');
  const massWellbeingSummaries = lodashGet(profile, 'result.reportData.environmentData.massWellbeingSummaries');

  if (lodashIsEmpty(massingOption) || lodashIsEmpty(massWellbeingSummaries)) {
    return {};
  }

  const buildWellbeingRow = (apartmentCountObj, rowName, totalsObject) => {
    Object.keys(apartmentCountObj).forEach((key) => apartmentCountObj[key] === undefined && delete apartmentCountObj[key]);
    Object.keys(apartmentCountObj).forEach((key) => APARTMENT_TYPES.includes(key) === false && delete apartmentCountObj[key]);
    let row = {
      ...apartmentCountObj,
      TOTAL: Object.values(apartmentCountObj).reduce((a, b) => a + b, 0),
    };
    if (totalsObject !== undefined) {
      row = lodashMapValues(row, (val, key) => ({ value: val, total: totalsObject[key] }));
    }

    row.NAME = rowName;
    return row;
  };

  const wellbeingCategoryTranslations = {
    vsc: T.translate('Sufficient VSC'),
    wind: T.translate('Sufficient Wind'),
    views: T.translate('Sufficient View'),
    sun: T.translate('Sufficient Daylight'),
  };

  const directionCategoryTranslations = {
    NORTH: T.translate('Facing North'),
    EAST: T.translate('Facing East'),
    WEST: T.translate('Facing West'),
    SOUTH: T.translate('Facing South'),
  };

  const buildings = [];
  for (let massIndex = 0; massIndex < massingOption.masses.length; massIndex++) {
    const buildingObject = { buildingName: `${T.translate('Building')} ${massIndex + 1}` };

    const massDto = massingOption.masses[massIndex];
    const { unitSummaries } = massWellbeingSummaries[massIndex];

    const unitTypeTotals = buildWellbeingRow(lodashMapValues(unitSummaries, (summary) => summary.numApartments), 'ApartmentCounts', undefined);
    const wellbeingCategories = ['sun', 'views', 'wind', 'vsc'];
    buildingObject.wellbeingData = wellbeingCategories.map((category) => buildWellbeingRow(
      lodashMapValues(unitSummaries, (unitAnalysis) => unitAnalysis.sufficientAnalysisCounts[category]), wellbeingCategoryTranslations[category], unitTypeTotals,
    ));

    const directionCounts = ['NORTH', 'EAST', 'WEST', 'SOUTH'];
    buildingObject.apartmentDirectionData = directionCounts.map((category) => buildWellbeingRow(
      lodashMapValues(unitSummaries, (unitAnalysis) => unitAnalysis.directionCounts[category]), directionCategoryTranslations[category], unitTypeTotals,
    ));

    // const apartmentNames = allApartmentNames.filter((name) => name in unitSummaries);
    const groundFloorApartments = {};
    const floorGroups = massDto.floor_groups.filter((fg) => fg.count > 0);
    floorGroups[0].units.forEach((unit) => {
      groundFloorApartments[unit.name] = (groundFloorApartments[unit.name] || 0) + 1;
    });
    const topFloorApartments = {};
    floorGroups[floorGroups.length - 1].units.forEach((unit) => {
      topFloorApartments[unit.name] = (topFloorApartments[unit.name] || 0) + 1;
    });
    const dualAspectApartments = {};
    floorGroups.forEach((floorGroup) => floorGroup.units.forEach((unit) => {
      if (unit.aspects > 1) {
        dualAspectApartments[unit.name] = (dualAspectApartments[unit.name] || 0) + floorGroup.count;
      }
    }));
    buildingObject.enhanceApartmentData = [
      buildWellbeingRow(groundFloorApartments, T.translate('Garden Apartments'), unitTypeTotals),
      buildWellbeingRow(topFloorApartments, T.translate('Roof Apartments'), unitTypeTotals),
    ];

    buildingObject.wellbeingData.push(buildWellbeingRow(dualAspectApartments, T.translate('Dual aspect'), unitTypeTotals));

    buildings.push(buildingObject);
  }

  if (buildings.length > 1) {
    const totalBuilding = mergeObjectListRecursively(buildings);
    totalBuilding.buildingName = 'TOTAL';
    buildings.push(totalBuilding);
  }

  return {
    buildings,
    buildingsImages: [...buildingsImages, { name: 'TOTAL', url: lodashGet(profile, 'result.reportData.designData.apartmentsMixData.allColored') }],
  };
};

const getPhasingData = (profile, site) => {
  const phasing = lodashGet(site, 'siteData.report.strategy.phasing');

  if (!phasing) {
    return;
  }

  const siteKey = lodashGet(profile, 'result.singleOptionMassingOptions.massing_options[0].metadata.building_info.site_id');
  const siteIndex = lodashGet(site, 'siteData.variants', []).findIndex((item) => item.key === siteKey);

  return phasing[siteIndex || 0];
};

const getSummaryData = (profile, siteSummary) => ({
  ...siteSummary,
  imageUrl: lodashGet(profile, 'result.reportData.summaryData.renderUrl'),
});

const getPlansData = (profile) => {
  const buildingsImages = lodashGet(profile, 'result.reportData.designData.apartmentsMixData.renders');
  const plansData = lodashGet(profile, 'result.reportData.designData.plansData');
  const buildings = lodashGet(plansData, 'floorsRenders', []).map((item, index) => ({
    name: T.translate(`${T.translate('Building')} ${index + 1}`),
    images: [
      { url: plansData.groundFloorRender.url, label: T.translate(`feasibility_report_plans_${plansData.groundFloorRender.name}`) },
      ...item.map((image) => ({ ...image, label: T.translate(`feasibility_report_plans_${image.name}`) })),
    ],
  }));

  return {
    buildings,
    buildingsImages,
  };
};

const getShadowData = (profile, site) => {
  if (!profile || !site) {
    return;
  }

  const monthNames = {
    3: T.translate('Spring Equinox'),
    6: T.translate('Summer Solstice'),
    9: T.translate('Autumn Equinox'),
    12: T.translate('Winter solstice'),
  };

  const resultShadowArray = lodashGet(profile, 'result.reportData.environmentData.shadowsData.renders', []);
  const siteShadowArray = lodashGet(site, 'siteData.report.environment.shadows.renders', []);
  const allUniqMonths = lodashSortedUniq(resultShadowArray.map((item) => item.date.month));

  const getRendersUrl = (renders, month, isExisting) => renders.filter((render) => render.date.month === month)
    .map((render) => ({
      url: render.url,
      label: `${isExisting ? T.translate('Existing Condition') : T.translate('Proposed')} - ${moment().month(render.date.month - 1).format('MMM')} ${render.date.day} ${moment().hour(render.date.hour).format('hA')}`,
    }));

  if (lodashIsEmpty(allUniqMonths)) {
    return null;
  }

  return allUniqMonths.map((month) => ({ month, name: monthNames[month], images: [...getRendersUrl(siteShadowArray, month, true), ...getRendersUrl(resultShadowArray, month)] }));
};

const getRightOfLightDataData = (profile, site) => {
  if (!profile || !site) {
    return;
  }

  const dateNames = {
    sun_spring_equinox: T.translate('Spring Equinox'),
    sun_summer_solstice: T.translate('Summer Solstice'),
    sun_autumn_equinox: T.translate('Autumn Equinox'),
    sun_winter_solstice: T.translate('Winter Solstice'),
    sun_yearly: T.translate('Yearly'),
  };

  const resultRendersArray = lodashGet(profile, 'result.reportData.environmentData.sunData.renders', []);
  const siteRendersArray = lodashGet(site, 'siteData.report.environment.rightOfLight.renders', []);
  const allUniqMonths = lodashSortedUniq(resultRendersArray.map((item) => item.name));

  const getRendersUrl = (renders, date, isExisting) => renders.filter((render) => render.name === date)
    .map((render) => ({
      url: render.url,
      label: `${isExisting ? T.translate('Existing Condition') : T.translate('Proposed')} ${dateNames[date]}`,
    }));

  if (lodashIsEmpty(allUniqMonths)) {
    return null;
  }

  return allUniqMonths.map((date) => ({ name: dateNames[date], existingImages: getRendersUrl(siteRendersArray, date, true), images: getRendersUrl(resultRendersArray, date) }));
};

const getApartmentsData = (profile) => {
  const availableUnits = lodashGet(profile, 'result.singleOptionMassingOptions.massing_options[0].metadata.building_info.unit_info');
  const units = [ // units that has apartments renders
    ...lodashKeys(STANDARDS.PREFAB.units),
    ...lodashKeys(STANDARDS.UK.units),
    ...lodashKeys(STANDARDS.IL.units),
  ];

  return units.map((unit) => ({
    key: unit, name: T.translate(unit), url: `https://swappmedia.s3.amazonaws.com/static/feasibility/apartmentStandards/apartments/${unit}.png`,
  })).filter((unit) => lodashIncludes(lodashKeys(availableUnits), unit.key));
};

const getDesignStreetViewData = (profile, site) => {
  const profileData = lodashGet(profile, 'result.reportData.designData.streetViewData');
  const siteData = lodashGet(site, 'siteData.report.design.streetView');

  return {
    ...profileData,
    ...siteData,
  };
};

const getSectionData = (profile, site) => {
  const profileData = lodashGet(profile, 'result.reportData.designData.sectionData');
  const siteData = lodashGet(site, 'siteData.report.design.section');

  return {
    ...profileData,
    ...siteData,
  };
};

const getUnitColors = (profile) => {
  const unitsData = lodashGet(profile, 'result.singleOptionMassingOptions.massing_options[0].metadata.building_info.unit_info', {});
  const units = [];
  Object.keys(unitsData).forEach((key) => {
    if (lodashGet(unitsData[key], 'colors')) {
      units.push({
        key,
        ...unitsData[key],
      });
    }
  });
  const hasColors = !!units.find((unit) => unit.colors);
  let colorSets = [];

  if (hasColors) {
    const filteredUnits = lodashFilter(units, (unit) => lodashIncludes(APARTMENT_TYPES, unit.key));
    const sortedUnits = lodashSortBy(filteredUnits, [(unit) => UNIT_SORT_LIST[unit.ke]]);

    colorSets = [
      ...sortedUnits.map((unit) => ({ colors: [unit.colors[0]], label: T.translate(unit.key) })),
      ...legendColors[MODEL_ANALYSIS_TYPES.AREA_TYPE],
    ];
  } else {
    colorSets = [
      { colors: ['#D45AFF', '#EAAEFF'], label: 'Studio' },
      { colors: ['#61DF93', '#B1F4CC'], label: '1 Bed' },
      { colors: ['#6B8DFF', '#A6BAFF'], label: '2 Bed' },
      { colors: ['#36D8CE', '#B5FAF6'], label: '3 Bed' },
      { colors: ['#FE7F49', '#FEA37C'], label: '4 Bed' },
      { colors: ['#F36084', '#FD8EA9'], label: '4 Bed Large' },
      ...legendColors[MODEL_ANALYSIS_TYPES.AREA_TYPE],
    ];
  }

  return colorSets;
};

// const getUnitArea = (massingOption, unitType) => {
//   let result = 0;
//   try {
//     massingOption.masses.forEach((mass) => {
//       mass.floor_groups.forEach((floorGroup) => {
//         floorGroup.units.forEach((unit) => {
//           if (unit.name === unitType) {
//             result = Math.floor(getPolygonArea(unit.points));
//             throw Error();
//           }
//         });
//       });
//     });
//   } catch (err) {
//     // Syntactic sugar for nested loop stopping
//   }
//
//   return result;
// };

export const feasibilityReportModel = (profile, site, isImperial) => {
  if (!profile || !site) {
    return;
  }

  const summaryPageData = getSummaryData(profile, lodashGet(site, 'siteData.report.summary'));
  const siteAerialViewData = lodashGet(site, 'siteData.report.site.aerialView');
  const siteExistingStoreData = lodashGet(site, 'siteData.report.site.existingStore');
  const siteLocationData = lodashGet(site, 'siteData.report.site.siteLocation');
  const existingSiteData = lodashGet(site, 'siteData.report.site.existingSite');
  const floodsData = lodashGet(site, 'siteData.report.site.floods');
  const streetViewData = lodashGet(site, 'siteData.report.site.streetView');
  const surroundingData = lodashGet(site, 'siteData.report.site.surrounding');

  const siteConstraintsData = lodashGet(site, 'siteData.report.strategy.siteConstraints');
  const opportunitiesData = lodashGet(site, 'siteData.report.strategy.opportunities');
  const phasingData = getPhasingData(profile, site);

  const apartmentsMixData = getApartmentsMix(profile, isImperial);
  const communalData = getCommunalData(profile);
  const plansData = getPlansData(profile);
  const sectionData = getSectionData(profile, site);
  const designStreetViewData = getDesignStreetViewData(profile, site);

  const wellbeingData = getWellbeingData(profile);

  const shadowData = getShadowData(profile, site);
  const rightOfLightData = getRightOfLightDataData(profile, site);

  const apartmentsData = getApartmentsData(profile);

  const unitsColors = getUnitColors(profile);

  return {
    unitsColors,
    summaryPageData,
    wellbeingData,
    apartmentsData,
    site: {
      summaryPageData,
      siteAerialViewData,
      siteExistingStoreData,
      siteLocationData,
      existingSiteData,
      streetViewData,
      surroundingData,
      floodsData,
    },
    strategy: {
      siteConstraintsData,
      opportunitiesData,
      phasingData,
    },
    design: {
      apartmentsMixData,
      communalData,
      plansData,
      sectionData,
      designStreetViewData,
    },
    environment: {
      shadowData,
      rightOfLightData,
    },
  };
};
