import { createSlice } from '@reduxjs/toolkit';
import { DISPLAY_TYPES, ROOM_FILTER_TYPES } from 'constants/testFitConsts';

const initialState = {
  roomsFilter: ROOM_FILTER_TYPES.ROOMS,
  displayType: DISPLAY_TYPES.GRID,
  isRoomDragActive: false,
  isMultiClickActive: false,
  multiClickStatus: false,
};

const testFitSlice = createSlice({
  name: 'testFit',
  initialState,
  reducers: {
    setRoomFilter(state, action) {
      state.roomsFilter = action.payload;
    },
    setMultiClick(state, action) {
      const { active, isSelected } = action.payload;
      state.isMultiClickActive = active;
      state.multiClickStatus = !isSelected;
    },
    setDisplayType(state, action) {
      state.displayType = action.payload;
    },
    setIsRoomDragActive(state, action) {
      if (state.isRoomDragActive !== action.payload) {
        state.isRoomDragActive = action.payload;
      }
    },
  },
});

export const { setRoomFilter, setDisplayType, setIsRoomDragActive, setMultiClick } = testFitSlice.actions;
export default testFitSlice.reducer;

// =========== Selectors =========== //
export const testFitRoomsFilterSelector = ({ testFit }) => testFit.roomsFilter;
export const testFitDisplayTypeSelector = ({ testFit }) => testFit.displayType;
export const isRoomDragActiveSelector = ({ testFit }) => testFit.isRoomDragActive;
export const isMultiClickActiveSelector = ({ testFit }) => testFit.isMultiClickActive;
export const multiClickStatusSelector = ({ testFit }) => testFit.multiClickStatus;
