import React from 'react';
import T from 'i18n-react';
import EditorRoomsPanel from 'components/testFit/study/result/program/EditorRoomsPanel';
import styled from 'styled-components';
import EditDepartmentsPanel from 'components/testFit/study/result/program/EditDepartmentsPanel';
import { TEST_FIT_EDITOR_BUTTON_KEYS } from 'constants/testFitConsts';
import { useSelector } from 'react-redux';
import icons from 'styles/static/icons/testFit/editor';
import Icon from '@ant-design/icons';

const TestFitEditPanelContainerWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px 15px 0 15px;
`;

export const FormSubMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  font-size: ${({ theme }) => theme.font.size.xxLarge};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: 25px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 10px;
`;

const editorHeaderByType = {
  [TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_ROOMS]: { text: 'TEST_FIT_VIEWER_CONTAINER_UI_ROOM_TOOLTIP', icon: icons.roomIconSmall },
  [TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_TILES]: { text: 'TEST_FIT_VIEWER_CONTAINER_UI_TILES_TOOLTIP', icon: icons.tilesIconSmall },
  [TEST_FIT_EDITOR_BUTTON_KEYS.DEPARTMENTS]: { text: 'TEST_FIT_VIEWER_CONTAINER_UI_DEPARTMENTS_TOOLTIP', icon: icons.departmentsIcon },
};

const TestFitEditPanelContainer = () => {
  const editorType = useSelector(({ editor }) => editor.editorType);
  const showRoomPanel = editorType === TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_ROOMS || editorType === TEST_FIT_EDITOR_BUTTON_KEYS.SELECT_TILES;
  const showDepartmentsPanel = editorType === TEST_FIT_EDITOR_BUTTON_KEYS.DEPARTMENTS;

  return (
    <TestFitEditPanelContainerWrapper>
      <Header>
        <StyledIcon component={editorHeaderByType[editorType].icon} />
        {T.translate(editorHeaderByType[editorType].text)}
      </Header>

      {/* ========= Rooms ========= */}
      {showRoomPanel && (
        <FormSubMenuWrapper>
          <EditorRoomsPanel openTabs />
        </FormSubMenuWrapper>
      )}

      {/* ========= Departments ========= */}
      {showDepartmentsPanel && (
        <FormSubMenuWrapper>
          <EditDepartmentsPanel />
        </FormSubMenuWrapper>
      )}
    </TestFitEditPanelContainerWrapper>
  );
};

export default TestFitEditPanelContainer;
