import styled from 'styled-components';
import { StyledFormItem, StyledInput } from '../../styles/commonComponents.styles';

export const Header = styled.div`
  display: flex;
  height: 61px;
  margin-bottom: 40px;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: ${({ theme }) => theme.other.borderRadius};
  margin-right: 45px;
`;

export const NewProjectButtonCircle = styled.div`
  width: 50px;
  height: 50px;
  overflow: hidden;
  //transform: translateX(50%) translateY(50%) rotate(-45deg);
  transform: translateX(50%) translateY(50%);
  transform-origin: center;
  // border-radius: ${({ isHidden }) => (isHidden ? '80% 0 55% 50% / 55% 0 80% 50%' : '50%')};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primaryColor};
  color: ${({ theme }) => theme.colors.white};
  position: fixed;
  bottom: ${({ isHidden }) => (isHidden ? '20px' : '50px')};
  right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: ${({ isHidden }) => (isHidden ? 'default' : 'pointer')};
  z-index: 2;
  box-shadow: 0 2px 15px rgba(0, 0, 0, .5);
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  transition: all .6s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryColorHover};
  }
  
  svg {
    width: ${({ isHidden }) => (isHidden ? 0 : '50px')};
    height: ${({ isHidden }) => (isHidden ? 0 : '50px')};
    transition: all .3s ease-in-out;
  }
`;

export const ButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const FormItem = styled(StyledFormItem)`
  margin-bottom: 0;
  margin-right: 15px;
  position: relative;
  display: block;
  
  .ant-form-item-label label, .ant-form-item-label{
    color: ${({ theme }) => theme.colors.gray_09};
  }
`;

export const SearchInput = styled(StyledInput)`
  width: 100%;
  height: 34px;
  background-color: ${({ theme }) => theme.colors.gray_01};
  
  &:focus-within, &:focus, &:hover {
    box-shadow: none;
    border-color: ${({ theme }) => theme.colors.gray_04} !important;
  }

  .ant-input {
    border: 0;
    box-shadow: none;
  }
`;

export const FloorListWrapper = styled.div`
  padding: 10px 25px 25px 25px;
`;

export const StageOption = styled.div`
  display: flex;
`;

export const ModalWrapper = styled.div`
  padding: 25px;
`;

export const ModalTitle = styled.div`
  font-size: 30px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: 12px;
  text-align: center;
`;

export const ModalButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 80px;
  cursor: pointer;

  &:last-child {
    margin: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primaryColorHover};
  }
`;

export const ModalButton = styled.div`
  height: 100px;
  width: 100px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ModalButtonText = styled.div`
  font-size: ${({ theme }) => theme.font.size.xLarge};
  font-weight: ${({ theme }) => theme.font.weight.light};
  text-align: center;
`;
