import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Html } from '@react-three/drei';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { currentThemeSelector } from 'store/userSettings';
import { useThree, useFrame } from 'react-three-fiber';

const StyledHtml = (props) => {
  const { children, fixed, rotate } = props;
  const currentTheme = useSelector(currentThemeSelector);
  const { camera } = useThree();
  const htmlRef = useRef();

  useFrame(() => {
    if (!htmlRef.current) {
      return;
    }
    if (!fixed) {
      htmlRef.current.parentElement.style.willChange = 'transform';
      htmlRef.current.style.willChange = 'transform';
      const currentTransform3d = htmlRef.current.parentElement.style.transform.split(' scale')[0];
      htmlRef.current.parentElement.style.transform = `${currentTransform3d} scale(${camera.zoom / 10})`;
      htmlRef.current.style.transform = 'translate3d(0, -50%, 0px)';
    }
  });

  return (
    <Html {...props} center ref={htmlRef}>
      <ThemeProvider theme={currentTheme}>
        <div style={{ transform: `rotate(${rotate || 0}deg)` }}>
          {children}
        </div>
      </ThemeProvider>
    </Html>
  );
};

StyledHtml.propTypes = {
  fixed: PropTypes.bool,
  rotate: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
};

export default StyledHtml;
