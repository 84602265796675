import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useDrag } from 'react-use-gesture';
import * as THREE from 'three';
import { useThree } from 'react-three-fiber';
import { notifyDragFinished } from '../helpers/ThreeHelpers';
import { changeCursor } from '../helpers/SelectionHelpers';

const CLICK_TIME = 300;

const DragObject = (props) => {
  const { uuid, children, onPointerOut, point, onPointMoved, onPointerDown, onRightClickUp, onFinishDrag, onDoubleClick, onHover, active } = props;

  const position = [...point, 1];
  const mesh = useRef();
  const downTime = useRef();
  const lastButton = useRef();
  const { camera } = useThree();
  const tmpCursor = useRef();

  const bindDrag = useDrag(
    ({ delta: [mx, my], event }) => {
      if (!active) {
        return;
      }
      if (event) event.stopPropagation();
      if (mesh.current) {
        // The object could be rotated. Therefore we need to answer the question:
        // "what movement in local coordinates would cause the object to move in global coordinates like the mouse delta?"
        // This is achieved by multiplying the mouse movement by the inverse of the global rotation.
        if (event.type === 'pointerdown' && onPointerDown) {
          onPointerDown(uuid);
        }
        if (event.buttons === 1) {
          const globalQuaternion = new THREE.Quaternion();
          mesh.current.getWorldQuaternion(globalQuaternion);
          globalQuaternion.invert();
          const deltaVector = new THREE.Vector3(mx / camera.zoom, -my / camera.zoom, 0);
          deltaVector.applyQuaternion(globalQuaternion);
          mx = deltaVector.x;
          my = deltaVector.y;
          const newPoint = [point[0] + mx, point[1] + my];
          if (onPointMoved) {
            onPointMoved(newPoint, uuid, [mx, my]);
          }
        }
        if (event.type === 'pointerup') {
          if (onRightClickUp && new Date() - downTime.current < CLICK_TIME && event.button === 2) {
            onRightClickUp(uuid);
            return;
          }
          if (onFinishDrag) {
            onFinishDrag(event, uuid);
          }
          notifyDragFinished();
        }
      }
      lastButton.current = event.button;
      downTime.current = new Date();
    },
    { pointerEvents: true },
  );

  return (
    <group
      ref={mesh}
      position={position}
      {...bindDrag()}
      onPointerOver={(e) => {
        if (active) {
          e.stopPropagation();
          tmpCursor.current = document.body.style.cursor;
          changeCursor('all-scroll');
          if (onHover) {
            onHover();
          }
        }
      }}
      onPointerOut={(e) => {
        if (active) {
          changeCursor(tmpCursor.current);
          if (onPointerOut) {
            onPointerOut(e);
          }
        }
      }}
      onDoubleClick={onDoubleClick}
    >
      {children}
    </group>
  );
};

DragObject.propTypes = {
  uuid: PropTypes.number,
  color: PropTypes.string,
  point: PropTypes.array,
  onPointMoved: PropTypes.func,
  onRightClickUp: PropTypes.func,
  onFinishDrag: PropTypes.func,
  onPointerDown: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onHover: PropTypes.func,
  children: PropTypes.object,
  onPointerOut: PropTypes.func,
  active: PropTypes.bool,
};

export default React.memo(DragObject);
