import React from 'react';

// currentcolor
export default () => (
  <svg width="110" height="110" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29.4488 11.3464C29.1863 11.3464 28.9239 11.2418 28.7139 11.0327C28.5039 10.8235 28.3989 10.5621 28.3989 10.3006C28.3989 10.0392 28.5039 9.77777 28.7139 9.56862L32.7034 5.59476C33.1233 5.17646 33.7533 5.17646 34.1732 5.59476C34.3832 5.80391 34.4882 6.06535 34.4882 6.32679C34.4882 6.58823 34.3832 6.84966 34.1732 7.05881L30.1837 11.0327C29.9737 11.2418 29.7113 11.3464 29.4488 11.3464Z" fill="currentcolor" />
    <path d="M20.0526 40C19.4751 40 19.0027 39.5294 19.0027 38.9542V33.3595C19.0027 32.7843 19.4751 32.3137 20.0526 32.3137C20.63 32.3137 21.1024 32.7843 21.1024 33.3595V38.9542C21.1024 39.5294 20.63 40 20.0526 40Z" fill="currentcolor" />
    <path d="M20.0524 30C14.4331 30 9.88184 25.4666 9.88184 19.8693C9.88184 14.2719 14.4331 9.73856 20.0524 9.73856C25.6718 9.73856 30.223 14.2719 30.223 19.8693C30.223 25.4666 25.6718 30 20.0524 30ZM20.0524 11.5889C15.4548 11.5889 11.7395 15.2896 11.7395 19.8693C11.7395 24.4489 15.4548 28.1496 20.0524 28.1496C24.6501 28.1496 28.3654 24.4489 28.3654 19.8693C28.3654 15.2896 24.6501 11.5889 20.0524 11.5889Z" fill="currentcolor" />
    <path d="M10.6561 11.5556C10.3936 11.5556 10.1311 11.451 9.92116 11.2418L5.98415 7.32026C5.5642 6.90196 5.5642 6.27451 5.98415 5.85621C6.4041 5.43791 7.03402 5.43791 7.45397 5.85621L11.4435 9.83006C11.8634 10.2484 11.8634 10.8758 11.4435 11.2941C11.2335 11.451 10.971 11.5556 10.6561 11.5556Z" fill="currentcolor" />
    <path d="M20.0526 7.68628C19.4751 7.68628 19.0027 7.21569 19.0027 6.64052V1.04575C19.0027 0.470588 19.4751 0 20.0526 0C20.63 0 21.1024 0.470588 21.1024 1.04575V6.64052C21.1024 7.21569 20.63 7.68628 20.0526 7.68628Z" fill="currentcolor" />
    <path d="M6.66667 20.915H1.04987C0.472441 20.915 0 20.4444 0 19.8693C0 19.2941 0.472441 18.8235 1.04987 18.8235H6.66667C7.24409 18.8235 7.71654 19.2941 7.71654 19.8693C7.71654 20.4444 7.24409 20.915 6.66667 20.915Z" fill="currentcolor" />
    <path d="M33.6483 34.4575C33.3858 34.4575 33.1233 34.3529 32.9134 34.1438L28.9238 30.1699C28.5039 29.7516 28.5039 29.1242 28.9238 28.7059C29.3438 28.2876 29.9737 28.2876 30.3937 28.7059L34.3832 32.6797C34.8031 33.098 34.8031 33.7255 34.3832 34.1438C34.2257 34.3529 33.9632 34.4575 33.6483 34.4575Z" fill="currentcolor" />
    <path d="M38.9501 20.915H33.3333C32.7559 20.915 32.2834 20.4444 32.2834 19.8693C32.2834 19.2941 32.7559 18.8235 33.3333 18.8235H38.9501C39.5275 18.8235 40 19.2941 40 19.8693C40 20.4444 39.5275 20.915 38.9501 20.915Z" fill="currentcolor" />
    <path d="M6.45661 34.2484C6.19414 34.2484 5.93167 34.1438 5.7217 33.9346C5.30175 33.5163 5.30175 32.8889 5.7217 32.4706L9.7112 28.4967C10.1311 28.0784 10.7611 28.0784 11.181 28.4967C11.601 28.915 11.601 29.5425 11.181 29.9608L7.19152 33.9346C7.03403 34.0915 6.77157 34.2484 6.45661 34.2484Z" fill="currentcolor" />
  </svg>
);
