import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashIsArray from 'lodash/isArray';
import styled from 'styled-components';
import { message } from 'antd';
import ImageWithDescription from './ImageWithDescription';
import images from '../../styles/static/img/feasibility';

export const MultiImageSelectWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  margin-bottom: 10px;
`;

export const Text = styled.div`
  margin-left: 10px;
  font-weight: ${({ theme }) => theme.font.weight.bold}; 
  color: ${({ theme }) => theme.colors.textColor}
`;

const MultiImageSelect = forwardRef((props, ref) => {
  const { data, onChange, disabled, size, spaced, bordered, selected, min = 0, max = props.data.length, countText } = props;

  const newSelectedItems = lodashIsArray(selected) ? [...selected] : [selected];

  const showInfoMessage = (text) => {
    message.info({ content: text, key: 'multiImageSelect' });
  };
  const handleOnSelect = (key) => {
    const keyIndex = newSelectedItems.indexOf(key);

    if (keyIndex !== -1) {
      if (newSelectedItems.length <= min) {
        showInfoMessage(T.translate('MULTI_IMAGE_SELECT_CANT_SELECT_LESS', { context: { min } }));
        return;
      }

      newSelectedItems.splice(keyIndex, 1);
    } else if (newSelectedItems.length !== max) {
      newSelectedItems.push(key);
    } else if (max === 1) {
      newSelectedItems.splice(keyIndex, 1);
      newSelectedItems.push(key);
    } else {
      showInfoMessage(T.translate('MULTI_IMAGE_SELECT_CANT_SELECT_MORE', { context: { max } }));
    }

    onChange(newSelectedItems);
  };

  return (
    <div ref={ref}>
      <Text>{`${countText || T.translate('MULTI_IMAGE_SELECT_SELECT_TEXT')} ${max === 1 ? '' : `(${newSelectedItems ? newSelectedItems.length : 0}/${max})`}`}</Text>
      <MultiImageSelectWrapper spaced={spaced}>
        {data.map((item) => (
          <ImageWithDescription
            onChange={(key) => handleOnSelect(key)}
            size={size}
            bordered={bordered}
            key={item.key}
            disabled={item.disabled || disabled}
            UID={item.key}
            image={item.image || images.campus}
            value={item.value}
            selected={newSelectedItems && !!newSelectedItems.find((imageKey) => imageKey === item.key)}
            description={item.description}
            descriptionImage={item.descriptionImage}
          />
        ))}
      </MultiImageSelectWrapper>
    </div>
  );
});

MultiImageSelect.propTypes = {
  data: PropTypes.array,
  selected: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  spaced: PropTypes.bool,
  bordered: PropTypes.bool,
  countText: PropTypes.string,
};

export default MultiImageSelect;
