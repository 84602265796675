import styled from 'styled-components';
import { Canvas } from 'react-three-fiber';

export const TestFitViewerContainerWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

export const FormSubMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: ${({ theme }) => theme.other.borderRadius};
`;

export const StyledCanvas = styled(Canvas)`
  height: 100%;
  width: 100%;
  box-shadow: ${({ theme, active }) => `0px 0px ${active ? 10 : 0}px 0px ${theme.colors.primaryColor}`};
  border-radius: ${({ radius }) => (radius ? `${radius}px` : 0)};
  z-index: 2;

  canvas {
    &:focus {
      outline: 0;
    }
  }
`;

export const WalkStyledCanvas = styled(Canvas)`
  height: 100%;
  width: 100%;
  border: 0;
  border-radius: ${({ radius }) => (radius ? `${radius}px` : 0)};
  z-index: 2;

  canvas {
    &:focus {
      outline: 0;
    }
  }
`;
