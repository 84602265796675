import lodashIsEmpty from 'lodash/isEmpty';
import lodashStartsWith from 'lodash/startsWith';
import lodashIsFinite from 'lodash/isFinite';
import lodashGet from 'lodash/get';
import { PROJECT_STAGES } from 'constants/profileConsts';
import {
  UI_AUTHORIZE_PATH,
  UI_AUTHORIZE_PATH_SIDEBAR,
  UI_AUTHORIZE_PATH_STAGES,
  UI_AUTHORIZE_PATH_STAGES_TABS, UI_AUTHORIZE_PATH_SUB_SIDEBAR,
} from '../../constants/routes/ui';

const tabByStage = {
  [PROJECT_STAGES[UI_AUTHORIZE_PATH.FEASIBILITY_STUDY]]: UI_AUTHORIZE_PATH.studies,
  [PROJECT_STAGES[UI_AUTHORIZE_PATH.TEST_FIT]]: UI_AUTHORIZE_PATH.studies,
  [PROJECT_STAGES[UI_AUTHORIZE_PATH.PROJECT_SETTINGS]]: UI_AUTHORIZE_PATH.setup,
};

export const navigateToMostRelevant = (project, history) => { // TODO - delete, irrelevant
  const parentProfile = lodashGet(project, 'projectProfiles', []).find((item) => !item.parentProfileId);
  const stageKey = parentProfile.stage;
  const tab = tabByStage[stageKey];

  history.push(`/${UI_AUTHORIZE_PATH[stageKey]}/${project.id}/${tab}`);
};

export const isProfileInProject = (project, profileId, history) => {
  if (!project) {
    return;
  }

  const profileIndex = lodashGet(project, 'projectProfiles', []).findIndex((profile) => profile.id === Number(profileId));
  if (profileIndex === -1) {
    history.push(UI_AUTHORIZE_PATH.root);
  }
};

export const parseLocationUrl = (location) => {
  if (!location) {
    return {};
  }

  const locationData = {};
  const locationPathname = location.pathname.split('/');
  locationPathname.shift();
  locationPathname.forEach((item, index) => {
    if (lodashIsEmpty(item)) {
      return;
    }
    // looking for stage
    UI_AUTHORIZE_PATH_STAGES.forEach((stageString) => {
      if (lodashStartsWith(stageString, item)) locationData.stage = item;
    });
    // looking for stageTab
    UI_AUTHORIZE_PATH_STAGES_TABS.forEach((stageString) => {
      if (lodashStartsWith(stageString, item)) locationData.stageTab = item;
    });
    // looking for Tab-SideBar
    UI_AUTHORIZE_PATH_SIDEBAR.forEach((stageString) => {
      if (lodashStartsWith(stageString, item)) locationData.tabSideBar = item;
    });
    // looking for Tab-sub-SideBar
    UI_AUTHORIZE_PATH_SUB_SIDEBAR.forEach((stageString) => {
      if (lodashStartsWith(stageString, item)) locationData.tabSubSideBar = item;
    });
    // looking for project ID
    if (lodashIsFinite(Number(item)) && index === 1) locationData.projectId = Number(item);
    // looking for profile ID
    if (lodashIsFinite(Number(item)) && index > 1) locationData.profileId = Number(item);
  });

  return locationData;
};

export const getProfileIdPath = (location) => {
  if (!location.pathname) {
    return '';
  }

  const parseLocation = parseLocationUrl(location);

  return parseLocation.profileId ? '/:profileId' : '';
};

export const getUrlParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
};

export const isSharedUrl = () => !!getUrlParams()['share-key'];

export const getShareKeyString = () => (isSharedUrl() ? `?share-key=${getUrlParams()['share-key']}` : '');
