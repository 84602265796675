import styled from 'styled-components';

export const SingUpTitle = styled.div`
  justify-content: center;
  display: flex;
  margin-bottom: 46px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.textColor};
  font-weight: ${({ theme }) => theme.font.weight.bold}; 
  font-size: ${({ theme }) => theme.font.size.xxLarge};
`;

export const BrakeLine = styled.div`
  width: 256px;
  height: 1px;
  margin: 40px auto 50px auto;
  background-color: ${({ theme }) => theme.colors.gray_06};
`;
