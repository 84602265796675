import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsNumber from 'lodash/isNumber';
import { TestFitInputNumber } from './AreaTargetFormContainer.styles';
import { StyledFormItem, MarkedInput, SmallStyledTable, DeleteIcon } from '../../../../styles/commonComponents.styles';
import AddNewRowButton from '../../../common/AddNewRowButton';
import { AREA_TARGET_FORM } from '../../../../constants/fieldNames';
import Dropdown from '../../../common/Dropdown';
import { uuidv4 } from '../../../../utils/helpers/uuidv4';
import { useComDidMount } from '../../../../utils/hooks';
import { deleteRow, updateRow, getRoomTypes } from '../../../../utils/helpers/tableHelpers';
import { TEST_FIT_OFFICE_TYPES } from '../../../../constants/dropdownKeys';
import deleteIcon from '../../../../styles/static/icons/comon/deleteIcon';
import minusIcon from '../../../../styles/static/icons/comon/minusIcon';

const fieldsToGet = [AREA_TARGET_FORM.LSF_ROOMS_FIELD, AREA_TARGET_FORM.PSF_A_FIELD, AREA_TARGET_FORM.PSF_B_FIELD];

const AreaTargetFormDepartments = (props) => {
  const { formData, form, form: { setFieldsValue }, disabled, handleOnChange } = props;
  const dropdownNoneOptions = { key: TEST_FIT_OFFICE_TYPES.NONE, value: 'AREA_TARGET_FORM_DEPARTMENTS_SELECT_ROOM_TYPE' };

  const [roomTypes, setRoomTypes] = useState([dropdownNoneOptions]);

  useComDidMount(() => {
    if (lodashIsEmpty(formData[AREA_TARGET_FORM.DEPARTMENTS])) {
      addNewRow();
    }
  });

  useEffect(() => {
    updateRoomTypeList(getRoomTypes(formData, fieldsToGet, dropdownNoneOptions), setRoomTypes);
  }, [
    formData[AREA_TARGET_FORM.LSF_ROOMS_FIELD], formData[AREA_TARGET_FORM.PSF_A_FIELD], formData[AREA_TARGET_FORM.PSF_B_FIELD],
    formData[AREA_TARGET_FORM.ASF_FIELD], formData[AREA_TARGET_FORM.FSF_FIELD],
  ]);

  const updateRoomTypeList = (roomsArray, updateRoomTypes) => {
    updateRoomTypes(roomsArray);
  };

  const columns = [
    {
      title: T.translate('AREA_TARGET_FORM_DEPARTMENTS_OFFICE_DEPARTMENT_NAME'),
      dataIndex: 'departmentName',
      key: 'departmentName',
      width: 200,
      render: (key, rowData) => (
        key !== null ? (
          <MarkedInput
            onChange={(e) => handleDepartmentNameChange(e, rowData)}
            value={key}
          />
        ) : null
      ),
    },
    {
      title: T.translate('AREA_TARGET_FORM_DEPARTMENTS_OFFICE_Departments_NUMBER'),
      dataIndex: 'numberOfDepartments',
      key: 'numberOfDepartments',
      width: 140,
      align: 'center',
      render: (key, rowData) => (
        key !== null ? (
          <TestFitInputNumber
            width="60px"
            onChange={(e) => handleNumberOfDepartmentsChange(e, rowData)}
            min={0}
            max={20}
            value={key}
          />
        ) : null
      ),
    },
    {
      title: T.translate('AREA_TARGET_FORM_DEPARTMENTS_OFFICE_ROOM_TYPE'),
      dataIndex: 'roomType',
      key: 'roomType',
      width: 200,
      align: 'center',
      render: (key, rowData, index) => (key !== null ? (
        <Dropdown
          dropDownData={roomTypes}
          selectedKey={key}
          onChange={(value) => handleRoomTypeChange(value, rowData, index)}
        />
      ) : null),
    },
    {
      title: T.translate('AREA_TARGET_FORM_DEPARTMENTS_OFFICE_ROOMS_NUMBER'),
      dataIndex: 'numberOfRooms',
      key: 'numberOfRooms',
      width: 100,
      align: 'center',
      render: (key, rowData) => (
        key !== null ? (
          <TestFitInputNumber
            width="60px"
            onChange={(e) => handleNumberOfRoomsChange(e, rowData)}
            min={0}
            max={20}
            disabled={rowData.roomType === TEST_FIT_OFFICE_TYPES.NONE}
            value={key}
          />
        ) : null
      ),
    },
    {
      dataIndex: 'delete',
      width: 50,
      align: 'center',
      render: (key, rowData, index) => (
        rowData.roomType === TEST_FIT_OFFICE_TYPES.NONE || (!rowData.isChild && !index)
          ? null
          : <DeleteIcon component={rowData.isChild ? minusIcon : deleteIcon} onClick={() => deleteRow(handleOnChange, rowData.key, form, AREA_TARGET_FORM.DEPARTMENTS, rowData.isChild)} />
      ),
    },
  ];

  const addNewRow = () => {
    const newRowData = {
      key: uuidv4(),
      departmentName: '',
      numberOfDepartments: 0,
      roomType: null,
      numberOfRooms: null,
      children: [
        {
          key: uuidv4(),
          departmentName: null,
          isChild: true,
          numberOfDepartments: null,
          roomType: TEST_FIT_OFFICE_TYPES.NONE,
          numberOfRooms: 0,
        },
      ],
    };

    const currentData = formData[AREA_TARGET_FORM.DEPARTMENTS] || [];

    setFieldsValue({ [AREA_TARGET_FORM.DEPARTMENTS]: [...currentData, newRowData] });
    handleOnChange();
  };

  const addNewChildRow = (rowIndex) => {
    const currentData = formData[AREA_TARGET_FORM.DEPARTMENTS];
    const newRowData = {
      key: uuidv4(),
      isChild: true,
      departmentName: null,
      numberOfDepartments: null,
      roomType: TEST_FIT_OFFICE_TYPES.NONE,
      numberOfRooms: 0,
    };

    currentData[rowIndex].children = [...currentData[rowIndex].children, newRowData];
    setFieldsValue({ [AREA_TARGET_FORM.DEPARTMENTS]: currentData });
    handleOnChange();
  };

  const handleNumberOfRoomsChange = (e, rowData) => {
    if (!lodashIsNumber(e)) {
      return;
    }

    const newValues = {
      numberOfRooms: e,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.DEPARTMENTS, form, newValues);
  };

  const handleRoomTypeChange = (key, rowData, index) => {
    const newValues = {
      roomType: key,
    };

    const rowIndex = formData[AREA_TARGET_FORM.DEPARTMENTS].findIndex((row) => row.children && row.children.find((children) => children.key === rowData.key));
    if (lodashGet(formData, `[${AREA_TARGET_FORM.DEPARTMENTS}][${rowIndex}].children`, []).length - 1 === index && key !== TEST_FIT_OFFICE_TYPES.NONE) {
      addNewChildRow(rowIndex);
    }
    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.DEPARTMENTS, form, newValues);
  };

  const handleDepartmentNameChange = (e, rowData) => {
    const newValues = {
      departmentName: e.target.value,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.DEPARTMENTS, form, newValues);
  };

  const handleNumberOfDepartmentsChange = (e, rowData) => {
    if (!lodashIsNumber(e)) {
      return;
    }
    const newValues = {
      numberOfDepartments: e,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.DEPARTMENTS, form, newValues);
  };

  return (
    <React.Fragment>
      <StyledFormItem name={AREA_TARGET_FORM.DEPARTMENTS} valuePropName="dataSource">
        <SmallStyledTable
          loading={disabled}
          expandedRowKeys={lodashGet(formData, `[${AREA_TARGET_FORM.DEPARTMENTS}]`, []).map((item) => item.key)}
          pagination={false}
          columns={columns}
        />
      </StyledFormItem>
      <AddNewRowButton disabled={disabled} onClick={addNewRow} text={T.translate('AREA_TARGET_FORM_DEPARTMENTS_OFFICE_BUTTON')} />
    </React.Fragment>
  );
};

AreaTargetFormDepartments.propTypes = {
  form: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

export default React.memo(AreaTargetFormDepartments);
