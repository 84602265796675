import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal, Tooltip } from 'antd';
import T from 'i18n-react';
import Icon from '@ant-design/icons';
import lodashGet from 'lodash/get';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import shareIcon from '../../styles/static/icons/comon/shareIcon';
import { shareableKeySelector, swappProjectSelector } from '../../store/swappProfile/selectors';
import { isSharedUrl, parseLocationUrl } from '../../utils/helpers/navigationHelpers';

const ShareWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  margin: 2px 15px 0 ${({ small }) => (small ? 0 : '15px')};
  color: ${({ theme }) => theme.colors.HeaderText};
  
  .anticon {
    cursor: pointer;
  
    &:hover {
      color: ${({ theme }) => theme.colors.primaryColor};
    }
  }
`;

const ShareContentText = styled.div`
  font-size: ${({ theme }) => theme.font.size.small};
  margin-bottom: 20px;
`;

const StyledTooltip = styled(Tooltip)`
  cursor: pointer !important;
`;

const ShareButton = (props) => {
  const { children, profileId, small } = props;
  const history = useHistory();
  const shareableKey = useSelector(shareableKeySelector);
  const swappProject = useSelector(swappProjectSelector);
  const locationData = parseLocationUrl(history.location);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const profileIndex = lodashGet(swappProject, 'projectProfiles', []).findIndex((profile) => profile.id === profileId || profile.id === locationData.profileId);
  const profileName = lodashGet(swappProject, `projectProfiles[${profileIndex}].name`);
  const projectName = lodashGet(swappProject, 'name', '');
  const shareName = profileName || `${projectName} ${T.translate('SHARE_BUTTON_PROJECT')}`;
  const shareLinkInput = useRef(null);
  const isShared = isSharedUrl();

  if (isShared || !locationData.projectId || !swappProject || locationData.tabSideBar === 'project-settings') {
    return null;
  }

  const copyToClipboard = () => {
    shareLinkInput.current.select();
    document.execCommand('copy');
  };

  const toggleIsShareModalOpen = (update) => {
    update(!isShareModalOpen);
  };

  return (
    <ShareWrapper small={small}>
      {/* =============== Icon =============== */}
      <Tooltip title={T.translate('SHARE_BUTTON_TITLE', { context: { shareName } })}>
        <div onClick={() => toggleIsShareModalOpen(setIsShareModalOpen)} aria-hidden="true">
          {children || <Icon component={shareIcon} />}
        </div>
      </Tooltip>

      {/* =============== Modal =============== */}
      <Modal
        title={T.translate('SHARE_BUTTON_TITLE', { context: { shareName } })}
        visible={isShareModalOpen}
        cancelText=" "
        width={600}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => toggleIsShareModalOpen(setIsShareModalOpen)}
        onCancel={() => toggleIsShareModalOpen(setIsShareModalOpen)}
      >
        {(!shareableKey)
          ? (
            <ShareContentText>
              {T.translate('SHARE_BUTTON_ERROR', { context: { shareName } })}
            </ShareContentText>
          )
          : (
            <React.Fragment>
              <ShareContentText>
                {T.translate('SHARE_BUTTON_TEXT', { context: { shareName } })}
              </ShareContentText>
              <StyledTooltip onClick={copyToClipboard} trigger="click" title={T.translate('SHARE_BUTTON_LINK_COPIED')}>
                <Input
                  ref={shareLinkInput}
                  addonAfter={<StyledTooltip onClick={copyToClipboard} trigger="click" title={T.translate('SHARE_BUTTON_LINK_COPIED')}>{T.translate('SHARE_BUTTON_COPY_LINK')}</StyledTooltip>}
                  value={`${window.location.href}${profileId ? `/${profileId}` : ''}?share-key=${shareableKey}`}
                />
              </StyledTooltip>
            </React.Fragment>
          )}
      </Modal>
    </ShareWrapper>
  );
};

ShareButton.propTypes = {
  profileId: PropTypes.number,
  small: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
};

export default ShareButton;
