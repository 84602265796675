export const ARCGIS_TOKEN = 'AAPKf6ba994a20a24c3eb98f0a3c50bfec659rmi46Wa7qTETg-ytBM4qGla1v_1IiHxOf9IEjy2ugPf6GanQ4IXgn0ALO22MvBZ';
export const GEOCODING_URL = `https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer?token=${ARCGIS_TOKEN}`;

// export const BLOCKS_TLV_URL = 'https://gisn.tel-aviv.gov.il/arcgis/rest/services/IView2/MapServer/525/query?where=1%3D1&outFields=*&f=json';

export const ESPG_PROJECTION_WKID = 4326;
export const ITM_PROJECTION_WKID = 2039;

export const TOPO_VECTOR_MAP_ID = '7dc6cea0b1764a1f9af2e679f642f0f5';

export const DEFAULT_CENTER = [34.79041, 32.09024];

export const GRAPHIC_LAYERS = {
  LOTS: 'Lots (Parcells)',
  BLOCKS: 'Blocks',
  SKETCH: 'Sketch',
  LOTS_LABELS: 'Lots Labels',
  BLOCKS_LABELS: 'Blocks Labels',
};

// https://developers.arcgis.com/documentation/mapping-apis-and-services/reference/zoom-levels-and-scale/
export const ZOOM_LEVELS = {
  STREET_INTERSECTION: 19,
  ADRESS: 18,
  STREET_BLOCK: 17,
  STREET: 16,
  SMALL_ROAD: 15,
  DEFAULT: 14,
  VILLAGE: 13,
  TOWN: 12,
  CITY: 11,
  LARGE_CITY: 10,
  LARGE_METROPOLITAN_AREA: 9,
};
