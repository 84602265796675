export default {
  // AreaTargetFormContainer
  'LSF:RSF': 'Designated Workspaces',
  'LSF:USF': 'Useable Designated Workspaces',
  'PSF:RSF': 'Conference & Collaboration',
  'ASF:RSF': 'Amenity',
  'FSF:RSF': 'Support',

  AREA_TARGET_FORM_CONTAINER_RSF_LSF: 'Rentable / Designated Workspaces',
  AREA_TARGET_FORM_CONTAINER_RATABLE_LEASABLE: 'Rentable / Designated Workspaces',
  AREA_TARGET_FORM_CONTAINER_LSF: 'Designated Workspaces',
  AREA_TARGET_FORM_CONTAINER_LEASABLE_AREA: '',
  AREA_TARGET_FORM_CONTAINER_PSF: 'Conference & Collaboration',
  AREA_TARGET_FORM_CONTAINER_PRODUCTIVE_AREA: '',
  AREA_TARGET_FORM_CONTAINER_ASF: 'Amenity',
  AREA_TARGET_FORM_CONTAINER_AMENITY_SPACES: '',
  AREA_TARGET_FORM_CONTAINER_FSF: 'Support',
  AREA_TARGET_FORM_CONTAINER_FACILITY_SPACES: '',
  AREA_TARGET_FORM_CONTAINER_BATHROOMS: 'Restrooms',
  AREA_TARGET_FORM_CONTAINER_BATHROOMS_DESCRIPTION: '',
  AREA_TARGET_FORM_CONTAINER_MODAL_WARNING_PSF: 'Conference & Collaboration area was not assigned',
  AREA_TARGET_FORM_CONTAINER_MODAL_WARNING_ASF: 'Amenity area was not assigned',
  AREA_TARGET_FORM_CONTAINER_MODAL_WARNING_FSF: 'Support area was not assigned',

  // AreaTargetFormPsf
  AREA_TARGET_FORM_PSF_ERROR: 'It is advised that Conference & Collaboration total area estimated area does not exceed 20% of the total building area',

  // AreaTargetFormAsf
  AREA_TARGET_FORM_ASF_PER_MEMBER: 'Amenity per member',
  AREA_TARGET_FORM_ASF_ERROR: 'It is advised that Amenity total area estimated area does not exceed 20% of the total building area',

  // OverallContainer
  OVERALL_CONTAINER_RSF: 'Rentable',
  OVERALL_CONTAINER_TOTAL_RSF_AREA: 'Total Rentable Area',
  OVERALL_CONTAINER_TOTAL_USF_AREA: 'Total Useable Area',
  OVERALL_CONTAINER_RENTABLE: '',
  OVERALL_CONTAINER_LSF: 'Designated Workspaces',
  OVERALL_CONTAINER_LEASABLE_AREA: '',
  OVERALL_CONTAINER_PSF: 'Conference & Collaboration',
  OVERALL_CONTAINER_PRODUCTIVE_AREA: '',
  OVERALL_CONTAINER_ASF: 'Amenity',
  OVERALL_CONTAINER_AMENITY_SPACES: '',
  OVERALL_CONTAINER_FSF: 'Support',
  OVERALL_CONTAINER_FACILITY_SPACES: '',
  OVERALL_CONTAINER_ASF_PER_MEMBER: 'Amenity per member',

  // AreaTargetFormRsfLsf
  AREA_TARGET_FORM_RSF_LSF_RSF_LSF_LABEL: 'Designated Workspaces / Rentable',
  AREA_TARGET_FORM_RSF_LSF_LSF_TOTAL_LABEL: 'Designated Workspaces:',

  // AreaTargetFormLsf
  AREA_TARGET_FORM_LSF_TOTAL_TOOLTIP: 'Total Designated Workspaces target must be 100%',
};
