import React, { useMemo, useEffect } from 'react';
import T from 'i18n-react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import { useHistory } from 'react-router';
import lodashIsEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'components/common/Dropdown';
import InfoBox from 'components/common/InfoBox';
import icons from 'styles/static/icons/viewerIcons';
import { selectStory } from 'store/BuildingStoriesStore';
import { selectedMassSelector, setSelectedMass } from 'store/views';
import { ViewerUiPanelWrapper } from 'styles/commonComponents.styles';
import ViewerUiPanel from 'utils/swappViewer/components/ui/ViewerUiPanel';
import { isSharedUrl, parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { useActiveProfileBuildingInfo } from 'utils/model/feasibilityResultModel';
import EditPanelContainer from 'utils/swappViewer/components/ui/EditPanelContainer';
import BuildingStoryUI from 'utils/swappViewer/components/Stories/BuildingStoryUI';
import { deleteMarkup, duplicateMarkup, selectTool } from 'store/markups';
import { MARKUPS_TOOLS_NAME, SCOPE_BOXES } from 'constants/markupsConts';
import { selectedMarkupSelector } from 'store/markups/markupsSelectors';
import { useHotkeys } from 'react-hotkeys-hook';
import * as SwappEditor from 'store/editor';

const ViewsUi = (props) => {
  const { hidden } = props;
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const buildingInfo = useActiveProfileBuildingInfo();
  const selectedMass = useSelector(selectedMassSelector);
  const selectedMarkups = useSelector(selectedMarkupSelector);
  const operations = useSelector(({ editor }) => editor.operations) || [];
  const dispatch = useDispatch();

  const dropDownData = useMemo(() => (
    Object.values(lodashGet(buildingInfo, 'masses', {})).map((mass, index) => (
      {
        value: `${T.translate('VIEWS_UI_BUILDING')} ${mass.massIndex + 1}`,
        // key: mass.massDto.created_from,
        key: lodashGet(mass, 'swpBuilding.id') || lodashGet(mass, 'massDto.created_from', `key_${index}`),
      }
    ))
  ), [buildingInfo]);

  useEffect(() => {
    dispatch(selectStory(0));
    dispatch(selectTool({ tool: MARKUPS_TOOLS_NAME.SECTION }));
  }, []);

  useEffect(() => {
    dispatch(setSelectedMass(null)); // clear selection on profile change
    dispatch(selectStory(0));
  }, [locationData.profileId]);

  useEffect(() => {
    if (!lodashIsEmpty(dropDownData) && !selectedMass) {
      dispatch(setSelectedMass(dropDownData[0].key)); // set the first item as dropdown default
    }
  }, [dropDownData, selectedMass]);

  useEffect(() => () => {
    dispatch(selectStory(null));
    dispatch(setSelectedMass(null)); // clear selection unmount
  }, []);

  if (hidden || isSharedUrl()) {
    return null;
  }

  useHotkeys('delete', () => {
    handleDelete();
  }, undefined, [selectedMarkups]);

  useHotkeys('ctrl+d', (e) => {
    e.preventDefault();
    handleDuplicate();
  }, undefined, [selectedMarkups]);

  useHotkeys('ctrl+z', (e) => {
    e.preventDefault();
    handleUndo();
  }, undefined, [operations]);

  const handleSectionClicked = () => {
    dispatch(selectTool({ tool: MARKUPS_TOOLS_NAME.SECTION }));
  };

  const handleScopeBoxClicked = (subType) => {
    dispatch(selectTool({ tool: MARKUPS_TOOLS_NAME.SCOPE_BOX, subType }));
  };

  const handleDuplicate = () => {
    dispatch(duplicateMarkup({ id: selectedMarkups[0] }));
  };

  const handleDelete = () => {
    dispatch(deleteMarkup({ id: selectedMarkups[0] }));
  };

  const handleUndo = () => {
    dispatch(SwappEditor.undoLastOperation());
  };

  const buttonsArray = [
    [
      {
        id: 'SECTION_LINE',
        name: T.translate('VIEWS_UI_SECTION'),
        icon: icons.sectionLineIcon,
        handleClick: handleSectionClicked,
        selectable: true,
      },
      {
        id: 'SCOPE_BOX',
        name: T.translate('VIEWS_UI_SCOPE_BOX'),
        icon: icons.scopeBoxIcon,
        selectable: true,
        subButtons: SCOPE_BOXES.map((item) => (
          {
            id: item.id,
            name: T.translate(item.name),
            icon: item.icon,
            handleClick: () => handleScopeBoxClicked(item.id),
            selectable: true,
          }
        )),
      },
    ],
    [
      {
        id: 'DUPLICATE',
        name: T.translate('VIEWS_UI_DUPLICATE'),
        tooltipText: T.translate('VIEWS_UI_DUPLICATE_TOOLTIP'),
        icon: icons.duplicateIcon,
        handleClick: handleDuplicate,
        disabled: selectedMarkups?.length === 0,
      },
      {
        id: 'DELETE',
        name: T.translate('VIEWS_UI_DELETE'),
        tooltipText: T.translate('VIEWS_UI_DELETE_TOOLTIP'),
        icon: icons.deleteIcon,
        handleClick: handleDelete,
        disabled: selectedMarkups?.length === 0,
      },
      {
        id: 'UNDO',
        name: T.translate('VIEWS_UI_UNDO'),
        tooltipText: T.translate('VIEWS_UI_UNDO_TOOLTIP'),
        icon: icons.undoIcon,
        disabled: operations?.length === 0,
        handleClick: handleUndo,
        hidden: true, // hide for V1
      },
    ],
  ];

  return (
    <>
      {/* ============== Mass dropdown ============== */}
      <ViewerUiPanelWrapper fitContent isColumn>
        <ViewerUiPanel width={250} notCollapsible>
          <InfoBox
            width={235}
            marginTop={15}
            marginBottom={15}
            marginLeft={15}
            marginRight={15}
            data={{
              title: T.translate('VIEWS_UI_BUILDINGS'),
              leftInfo: lodashIsEmpty(dropDownData) ? '' : <Dropdown dropDownData={dropDownData} selectedKey={selectedMass} onChange={(key) => dispatch(setSelectedMass(key))} />,
            }}
          />
        </ViewerUiPanel>
      </ViewerUiPanelWrapper>

      {/* ============== Edit buttons ============== */}
      <EditPanelContainer offset={30} landscape buttonsArray={buttonsArray} />

      {/* ============== Building stories ============== */}
      {buildingInfo.swpProject && (
        <ViewerUiPanelWrapper position="bottom-right" fitContent>
          <ViewerUiPanel width={150} isRight>
            <BuildingStoryUI swpProject={lodashGet(buildingInfo, 'swpProject')} />
          </ViewerUiPanel>
        </ViewerUiPanelWrapper>
      )}
    </>
  );
};

ViewsUi.propTypes = {
  hidden: PropTypes.bool,
};

export default ViewsUi;
