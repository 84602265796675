import React from 'react';

// currentcolor
export default () => (
  <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.4945 4.62731H15.0488V3.68726C15.0488 2.74722 14.3016 2 13.3615 2L10.3736 2.00092C9.43356 2.00092 8.68634 2.74816 8.68634 3.68819V4.62823L3.48156 4.62731C3.2168 4.62731 3 4.8441 3 5.10887C3 5.37363 3.21679 5.59043 3.48156 5.59043H4.68637L5.74633 19.5675C5.81829 20.5555 6.66238 21.3507 7.67439 21.3507H16.0848C17.0968 21.3507 17.94 20.5795 18.0129 19.5675L19.0729 5.59043H20.5184C20.7832 5.59043 21 5.37364 21 5.10887C21.0009 4.8441 20.7601 4.62731 20.4945 4.62731ZM9.65036 3.68726C9.65036 3.27766 9.96401 2.96402 10.3736 2.96402H13.3856C13.7952 2.96402 14.1089 3.27766 14.1089 3.68726V4.62731H9.65036V3.68726ZM17.025 19.4724C16.977 19.9788 16.5674 20.3644 16.061 20.3644L7.67439 20.3635C7.16794 20.3635 6.75834 19.9779 6.71037 19.4714L5.6504 5.56643H18.1089L17.025 19.4724Z" fill="currentcolor" />
    <path d="M11.8668 8.0741C11.6021 8.0741 11.3853 8.29089 11.3853 8.55566V17.4476C11.3853 17.7124 11.602 17.9292 11.8668 17.9292C12.1316 17.9292 12.3484 17.7124 12.3484 17.4476L12.3493 8.55566C12.3493 8.29089 12.1325 8.0741 11.8668 8.0741H11.8668Z" fill="currentcolor" />
    <path d="M14.5183 8.53111L14.0367 17.4231C14.0127 17.6879 14.2295 17.9047 14.4943 17.9296H14.5183C14.783 17.9296 14.9758 17.7367 14.9998 17.472L15.4814 8.58C15.5054 8.31524 15.2886 8.09844 15.0238 8.07355C14.7591 8.07355 14.5423 8.26635 14.5183 8.53111H14.5183Z" fill="currentcolor" />
    <path d="M8.71025 8.0741C8.44549 8.09808 8.25269 8.31486 8.25269 8.58055L8.73424 17.4725C8.75823 17.7373 8.95104 17.9301 9.2158 17.9301H9.23979C9.50455 17.9061 9.69735 17.6893 9.69735 17.4237L9.2158 8.53166C9.19273 8.2669 8.97595 8.0741 8.71026 8.0741H8.71025Z" fill="currentcolor" />
  </svg>
);
