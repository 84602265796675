/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable radix */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useThree, useFrame } from 'react-three-fiber';
import { Html } from '@react-three/drei';
import * as THREE from 'three';
import Point3D from './Point3D';
import './FadeMaterial';
import { usePrevious } from '../../utils/hooks';
import { threeJsGlobals } from '../../store/threeJsGlobals';

const SPHERE_RADIUS = 500;
const DISSOLVE_TIME_SECONDS = 0.5;
const MIN_FOV = 40;
const MAX_FOV = 75;
const MOUSE_SENSETIVITY = 0.25;

const HTMLStyle = {
  willChange: 'transform', // Important for improving performance
};

const SphereComponent = ({ dumpingFactor = 0.1, selectedViewId, textures, lowResTextures, neighbors, onViewChanged }) => {
  const { camera } = useThree();
  const sphereRef = useRef();
  const isPointerDown = useRef(false);
  const htmlElementsRefs = useRef({});
  const matRef = useRef();

  const prevSelectedViewId = usePrevious(selectedViewId);
  const isChangingTexture = useRef(false);

  const destCamera = useRef({
    rotationX: 0,
    rotationY: 0,
    fov: camera.fov,
  });

  useEffect(() => {
    if (sphereRef.curent) {
      camera.position.set(0, 0, 0);
    }
  }, [camera, sphereRef.curent]);

  useEffect(() => {
    matRef.current.uniforms.texture0.value = textures[1] || lowResTextures[1];
    matRef.current.uniforms.texture1.value = textures[0] || lowResTextures[0];
    matRef.current.uniforms.lerp.value = 1;
  }, [matRef.curent]);

  useEffect(() => {
    if (!isNaN(prevSelectedViewId) && prevSelectedViewId !== selectedViewId) {
      if (isChangingTexture.current) {
        return;
      }

      isChangingTexture.current = true;
      matRef.current.uniforms.texture0.value = textures[prevSelectedViewId] || lowResTextures[prevSelectedViewId];
      matRef.current.uniforms.texture1.value = textures[selectedViewId] || lowResTextures[selectedViewId];
      matRef.current.uniforms.lerp.value = 0;
    }
  }, [prevSelectedViewId, selectedViewId]);

  const handleTextureChange = (dt) => {
    if (!matRef.current) {
      return;
    }

    if (isChangingTexture.current) {
      matRef.current.uniforms.lerp.value += (dt / DISSOLVE_TIME_SECONDS);
      if (matRef.current.uniforms.lerp.value > 1) {
        matRef.current.uniforms.lerp.value = 1;
        isChangingTexture.current = false;
      }
    }
  };

  const handleMovement = () => {
    if (!sphereRef.current) {
      return;
    }

    sphereRef.current.rotation.order = 'YXZ';
    sphereRef.current.rotation.y += (destCamera.current.rotationY - sphereRef.current.rotation.y) * dumpingFactor;
    sphereRef.current.rotation.x += (destCamera.current.rotationX - sphereRef.current.rotation.x) * dumpingFactor;
    sphereRef.current.rotation.z = Math.PI / 2;

    // So MiniMap can access this variable without using react-redux each frame
    threeJsGlobals.setWalkThroughCameraAngle(-sphereRef.current.rotation.x * (180 / Math.PI));

    // zoom
    camera.fov += (destCamera.current.fov - camera.fov) * dumpingFactor;
    camera.updateProjectionMatrix();
  };

  useFrame((gl, dt) => {
    handleMovement();
    handleTextureChange(dt);
  });

  const onPointerDown = useCallback(() => {
    isPointerDown.current = true;
  }, []);

  const onPointerUp = useCallback(() => {
    isPointerDown.current = false;
  }, []);

  const onPointerMove = useCallback((event) => {
    if (!isPointerDown.current) {
      return;
    }

    const { movementX, movementY, distance } = event;

    destCamera.current.rotationY -= Math.atan2(movementY, distance * (1 - MOUSE_SENSETIVITY));
    destCamera.current.rotationX += Math.atan2(movementX, distance * (1 - MOUSE_SENSETIVITY));
    destCamera.current.rotationY = Math.max(-Math.PI / 2, Math.min(Math.PI / 2, destCamera.current.rotationY));
  }, []);

  const onWheel = useCallback((event) => {
    const { deltaY } = event;
    destCamera.current.fov += 5 * Math.sign(deltaY);
    destCamera.current.fov = Math.max(MIN_FOV, Math.min(MAX_FOV, destCamera.current.fov));
  }, []);

  // Apply style attribute for improving performance on parent Element of the HTML drei component
  // (which is not accesible otherwise)
  useEffect(() => {
    if (htmlElementsRefs.current) {
      for (let index in htmlElementsRefs.current) {
        if (htmlElementsRefs.current[index] && htmlElementsRefs.current[index].parentElement) {
          htmlElementsRefs.current[index].parentElement.style.willChange = 'transform';
        }
      }
    }
  }, [htmlElementsRefs.current[0]]);

  const renderNeighbors = () => {
    htmlElementsRefs.current = {};
    return neighbors.map((neighbor, index) => { // neighbors are sorted by distance
      const x = Math.sin(neighbor.angle) * (SPHERE_RADIUS);
      const y = -Math.cos(neighbor.angle) * (SPHERE_RADIUS);
      const scale = Math.max(0.1, Math.min(1.0, SPHERE_RADIUS / neighbor.distance));
      return (
        <Html
          ref={(el) => {
            if (el) {
              htmlElementsRefs.current[index] = el;
            }
          }}
          key={index}
          position={[x, 0, y]}
          style={HTMLStyle}
        >
          <Point3D id={neighbor.id} onButtonClicked={onViewChanged} scale={scale} isInLineOfSight={neighbor.isInLineOfSight} />
        </Html>
      );
    });
  };

  return (
    <mesh
      ref={sphereRef}
      onWheel={onWheel}
      onPointerUp={onPointerUp}
      onPointerDown={onPointerDown}
      onPointerMove={onPointerMove}
      scale={[1, 1, -1]}
    >
      <sphereGeometry args={[SPHERE_RADIUS, 64, 64]} position={[0, 0, 0]} />
      <fadeMaterial ref={matRef} attach="material" side={THREE.DoubleSide} />
      {renderNeighbors()}
    </mesh>
  );
};

SphereComponent.propTypes = {
  dumpingFactor: PropTypes.number,
  textures: PropTypes.array,
  lowResTextures: PropTypes.array,
  neighbors: PropTypes.array,
  selectedViewId: PropTypes.number,
  onViewChanged: PropTypes.func,
};

export default React.memo(SphereComponent);
