import React from 'react';

export default () => (
  <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="16" y="29" width="14" height="11" rx="2" stroke="currentcolor" strokeWidth="1.5" strokeLinejoin="round" />
    <path d="M20.5 40V49H26.5V40" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20.5 29V14.5C20.5 11.7386 18.2614 9.5 15.5 9.5H12" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M26.5 29V13.5C26.5 7.97715 22.0228 3.5 16.5 3.5H12" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M38.5 13L38.5 26.5C38.5 29.2614 36.2614 31.5 33.5 31.5L30 31.5" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M44.5 13L44.5 27.5C44.5 33.0228 40.0228 37.5 34.5 37.5L30 37.5" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="3" y="1" width="5" height="11" rx="1" stroke="currentcolor" strokeWidth="1.5" strokeLinejoin="round" />
    <rect x="36" y="13" width="8" height="11" rx="1" transform="rotate(-90 36 13)" stroke="currentcolor" strokeWidth="1.5" strokeLinejoin="round" />
    <rect x="8" y="3" width="4" height="7" stroke="currentcolor" strokeWidth="1.5" strokeLinejoin="round" />
    <rect x="38" y="5" width="4" height="7" transform="rotate(-90 38 5)" stroke="currentcolor" strokeWidth="1.5" strokeLinejoin="round" />
  </svg>
);
