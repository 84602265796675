import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import T from 'i18n-react';
import { PrimaryButton, StyledSlider } from 'styles/commonComponents.styles';
import { FormRow, StyledSliderWrapper, StyledSliderPercentage } from './styledComponents';

export const WwrSlider = ({ value, setValue }) => (
  <FormRow>
    <StyledSliderWrapper>
      <StyledSlider
        min={0.1}
        max={0.9}
        step={0.1}
        width={170}
        padding={0}
        tooltipVisible={false}
        value={value}
        onChange={setValue}
      />
      <StyledSliderPercentage>{`${value}%`}</StyledSliderPercentage>
    </StyledSliderWrapper>
  </FormRow>
);

WwrSlider.propTypes = {
  value: PropTypes.number,
  setValue: PropTypes.func,
};

export const GenerateBtn = ({ isLoading, clickHandler }) => (
  <PrimaryButton
    marginTop={30}
    marginBottom={0}
    disabled={isLoading}
    onClick={clickHandler}
    center
    width={200}
  >{isLoading ? <LoadingOutlined spin /> : T.translate('GENERATE')}
  </PrimaryButton>
);

GenerateBtn.propTypes = {
  isLoading: PropTypes.bool,
  clickHandler: PropTypes.func,
};
