import React, { useMemo } from 'react';
import styled from 'styled-components';
import lodashGet from 'lodash/get';
import { updateActiveProfile } from 'store/activeProfile';
import { MODEL_ANALYSIS_TYPES } from 'constants/feasibilityConts';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { getProfileByIdSelector } from 'store/swappProfile';
import { useProfileResult } from 'store/swappProfile/selectors/swappSelectors';
import { feasibilityResultModel, useActiveProfileBuildingInfo } from 'utils/model/feasibilityResultModel';
import ViewerUiPanel from 'utils/swappViewer/components/ui/ViewerUiPanel';
import FeasibilityViewerContainer from 'utils/swappViewer/FeasibilityViewerContainer';
import { ViewerUiPanelWrapper } from 'styles/commonComponents.styles';
import { useSelector } from 'react-redux';
import FeasibilityResultEnergyAnalysis from './FeasibilityResultEnergyAnalysis';
import RequirementsForm from './form/EnergyRequirementsForm';

const EnergyContainerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const EnergyContainer = () => {
  const locationData = parseLocationUrl(window.location);

  const profile = useSelector(
    (state) => getProfileByIdSelector(state, locationData.profileId),
  );
  const isImperial = useSelector(
    (state) => getUnitSystemByProjectIdSelector(state, locationData.projectId),
  );

  const result = useProfileResult(profile);
  updateActiveProfile(locationData.profileId, result);
  const buildingInfo = useActiveProfileBuildingInfo();

  const data = useMemo(() => feasibilityResultModel(
    result, isImperial, buildingInfo,
  ),
  [result, isImperial]);

  return (
    <EnergyContainerWrapper>
      <ViewerUiPanelWrapper position="top-left" isColumn fitContent>
        <ViewerUiPanel width={550}>
          <RequirementsForm />
        </ViewerUiPanel>
      </ViewerUiPanelWrapper>

      <ViewerUiPanelWrapper style={{ paddingTop: '100px' }} position="top-right">
        <ViewerUiPanel width={480} isRight>
          <FeasibilityResultEnergyAnalysis
            profile={profile}
            isImperial={isImperial}
            data={lodashGet(data, 'hbEnergyAnalysisData')}
          />
        </ViewerUiPanel>
      </ViewerUiPanelWrapper>

      <FeasibilityViewerContainer
        result={result}
        profileId={locationData.profileId}
        legendKey={MODEL_ANALYSIS_TYPES.AREA_TYPE}
      />
    </EnergyContainerWrapper>
  );
};

export default EnergyContainer;
