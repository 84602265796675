import React from 'react';
import { LoadingContainer, StyledIcon } from './LoadingSpinner.styles';

const LoadingSpinner = () => (
  <LoadingContainer>
    <StyledIcon spin />
  </LoadingContainer>
);

export default LoadingSpinner;
