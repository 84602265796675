import styled from 'styled-components';

export const StyledForgeViewer = styled.div`
  position: absolute;
  border-radius: ${({ theme, bordered }) => (bordered && theme.other.borderRadius)};
  width: 100% !important;
  height: ${({ theme, height, heightOffset }) => (height ? '100%' : `calc(100vh - ${theme.other.headerHeight + heightOffset}px - 1px)`)} !important;

  .forge-spinner {
    img {
      display: none;
    }
  }
  
  .adsk-viewing-viewer {
    border-radius: ${({ theme, bordered }) => (bordered && theme.other.borderRadius)};
    width: 100% !important;
    height: ${({ theme, height, heightOffset }) => (height ? '100%' : `calc(100vh - ${theme.other.headerHeight + heightOffset}px - 1px)`)} !important;

    .progressbg {
      height: 8px !important;
      width: 100% !important;
      top: 0;
      left: 0;
      bottom: unset;
      border: 0;
      background-color: transparent;
    
      .progressfg {
        height: 8px !important;
        background: ${({ theme }) => theme.colors.primaryColor} !important;
        border-radius: 0;
      }
    }
    
    .selector-drag-point, .selector-rotate-point {
      width: 8px !important;
      height: 8px !important;
    }
  }

  .ForgeViewer .viewer {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .adsk-control, .adsk-toolbar {
    display: ${({ hideUi }) => (hideUi ? 'none' : 'block')};
  }
  
  .adsk-viewing-viewer .adsk-control-group {
    color: ${({ theme }) => theme.colors.gray_09} !important;
  }

  .adsk-viewing-viewer.light-theme .adsk-icon-documentModels {
    filter: brightness(0.4) sepia(1) hue-rotate(190deg) saturate(5);

    &:hover {
      filter: brightness(0.6) sepia(1) hue-rotate(190deg) saturate(5);
    }
  }

  .adsk-viewing-viewer.light-theme .active .adsk-icon-documentModels {
    filter: brightness(0.6) sepia(1) hue-rotate(190deg) saturate(5) !important;
  }

  .adsk-button:hover {
    color: ${({ theme }) => theme.colors.primaryColor} !important;
    border-color: ${({ theme }) => theme.colors.gray_01} !important;
  }

  .adsk-button.active {
    color: ${({ theme }) => theme.colors.primaryColor} !important;
    box-shadow: unset !important;
  }

  .adsk-viewing-viewer .adsk-label-button label,
  .adsk-viewing-viewer .switch input:checked+.slider:before{
    background-color: ${({ theme }) => theme.colors.primaryColor} !important;
  }

  // menus over right
  .viewer-document-browser {
    position: ${({ sheetView }) => (sheetView ? 'absolute !important' : 'unset')} ;
    top: ${({ sheetView }) => (sheetView ? '0 !important' : 'unset')};
    right: ${({ sheetView }) => (sheetView ? '0 !important' : 'unset')};
    left: unset !important;
    height: ${({ sheetView }) => (sheetView ? '100% !important' : 'unset')};
    max-height: ${({ sheetView }) => (sheetView ? '100% !important' : 'unset')};
    border-radius: ${({ sheetView }) => (sheetView ? '0 !important' : 'unset')};
    box-shadow: ${({ sheetView }) => (sheetView ? 'none !important' : 'unset')};
    border-left: ${({ theme, sheetView }) => (sheetView ? `1px solid ${theme.colors.gray_04} !important` : 'unset')};

    .docking-panel-title, .docking-panel-footer {
      display: ${({ sheetView }) => (sheetView ? 'none !important' : 'block')};
    }
    .docking-panel-scroll.right {
      top: ${({ sheetView }) => (sheetView ? '40px !important' : 'unset')};
      height: ${({ sheetView }) => (sheetView ? '100% ' : 'unset')};
    }
    .docking-panel-container-solid-color-a {
      background-color: ${({ theme, sheetView }) => (sheetView ? `${theme.colors.gray_01} !important` : 'unset')};
    }
  }
  
  .docking-panel-title, .docking-panel-footer {
    background-color: ${({ theme }) => theme.colors.primaryColor} !important;
    color: ${({ theme }) => theme.colors.gray_01} !important;
    border: 0 !important;
  }
  
  .viewer-ext-docbrowser-thumbnail-selected {
    border-color: ${({ theme }) => theme.colors.primaryColor} !important;
    border-width: 1px !important;
  }
  .viewer-ext-docbrowser-thumbnail:hover>div {
    opacity: .8;
  }
  .viewer-ext-docbrowser-thumbnail-selected>div {
    background-color: ${({ theme }) => theme.colors.primaryColor} !important;
    color: ${({ theme }) => theme.colors.gray_01} !important;
  }
    .viewer-ext-docbrowser-thumbnail>div {
    height: 30px !important;
    bottom: 0 !important;
    line-height: 2.4 !important;
    border-radius: ${({ theme }) => `0 0 ${theme.other.borderRadius} ${theme.other.borderRadius}`};
    background-color: ${({ theme }) => theme.colors.gray_02};
  }

  .adsk-viewing-viewer.light-theme .adsk-searchbox input.search-box:focus {
    border-color: ${({ theme }) => theme.colors.primaryColor} !important;
  }
  .viewer-ext-docbrowser-thumbnail {
    width: 300px !important;
    margin-bottom: 20px;
    border-radius: ${({ theme }) => theme.other.borderRadius};
    border-color: ${({ theme }) => theme.colors.gray_06};
    background-color: ${({ theme }) => theme.colors.gray_01} !important;
    
    :first-child {
      margin-top: 40px;
    }
    
    img {
      outline: 2px solid white;
      outline-offset: -2px;
    }
  }
  
  .adsk-viewing-viewer.light-theme .adsk-searchbox .search-box-icon.focused {
    filter: brightness(0.6) sepia(1) hue-rotate(190deg) saturate(5);
  }

  .adsk-viewing-viewer .viewer-settings-panel.docking-panel .settings-tabs .tabselected span {
    color: ${({ theme }) => theme.colors.primaryColor} !important;
    border-color: ${({ theme }) => theme.colors.primaryColor} !important;
  }
  
  .adsk-viewing-viewer.light-theme .viewer-settings-panel.docking-panel .settings-tabs span:hover,
  .adsk-viewing-viewer.light-theme .treeview leaf.selected-ex>lmvheader>label {
      color: ${({ theme }) => theme.colors.primaryColor} !important;
  }

  .adsk-viewing-viewer.light-theme .treeview .leaf>lmvheader:hover,
  .adsk-viewing-viewer.light-theme .treeview .group>lmvheader:hover,
  .adsk-viewing-viewer.light-theme .treeview group>lmvheader:hover,
  .adsk-viewing-viewer.light-theme .treeview leaf>lmvheader:hover {
    background:${({ theme }) => `${theme.colors.primaryColor}20`} !important;
  }

  .adsk-viewing-viewer .docking-panel-close, .adsk-viewing-viewer .docking-panel-footer-resizer {
    filter: brightness(3) !important;
  }
`;
