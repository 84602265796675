import lodashCloneDeep from 'lodash/cloneDeep';
import * as actionTypes from '../actions/actionTypes';
import {
  EDIT_PROJECT_SUCCESS,
  CREATE_NEW_PROJECT_SUCCESS,
  CREATE_NEW_PROJECT_REQUEST,
  CREATE_NEW_PROJECT_ERROR,
} from '../../swappProfile/actions/actionTypes';

const initialState = {
  userProjects: null,
};

export const UserProjectsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_MY_FLOORS_REQUEST:
      return { ...state, error: null, loading: true };
    case CREATE_NEW_PROJECT_REQUEST:
      return { ...state, error: null, loading: true, inProgress: true };
    case actionTypes.GET_MY_FLOORS_ERROR:
    case CREATE_NEW_PROJECT_ERROR:
      return { ...state, error: payload, loading: false, inProgress: false };
    case actionTypes.GET_MY_FLOORS_SUCCESS:
      return { ...state, userProjects: payload, loading: false };
    case CREATE_NEW_PROJECT_SUCCESS:
      return { ...state, userProjects: [...state.userProjects, payload], loading: false, inProgress: false };
    case EDIT_PROJECT_SUCCESS:
      const newUserProjects = lodashCloneDeep(state.userProjects);
      const projectIndex = newUserProjects.findIndex((project) => project.id === payload.id);
      newUserProjects[projectIndex] = payload;
      return { ...state, loading: false, userProjects: newUserProjects };
    default:
      return state;
  }
};
