import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popover } from 'antd';
import T from 'i18n-react';
import lodashIsString from 'lodash/isString';

const RadioTextWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.09);
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  height: ${({ size }) => (size ? `${size}px` : '200px')};
  min-width: ${({ size }) => (size ? `${size}px` : '200px')};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.large};
  border-width: 2px;
  border-style: solid;
  border-radius: ${({ theme }) => theme.other.borderRadius};
  border-color: ${({ theme, selected, disabled, bordered }) => {
    if (bordered) {
      if (selected) {
        return theme.colors.primaryColor;
      }
      return disabled ? theme.colors.primaryColorDisabled : theme.colors.gray_03;
    }
    return 'transparent';
  }};
  color: ${({ theme, selected, disabled }) => {
    if (selected) {
      return disabled ? theme.colors.primaryColorDisabled : theme.colors.primaryColor;
    }
    return disabled ? theme.colors.gray_06 : theme.colors.textColor;
  }};
`;

const InfoDescriptionWrapper = styled.div`
  display: flex;
  width: 350px;
  font-weight: ${({ theme }) => theme.font.weight.light};
`;

const InfoDescriptionImage = styled.img`
  width: 120px;
  height: 80px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: ${({ theme }) => theme.other.borderRadius};
`;

const RadioText = forwardRef((props, ref) => {
  const { data, selected, initialValue, onChange, disabled, size, spaced, bordered } = props;
  const selectedValue = selected || initialValue;

  const content = (item) => (
    <InfoDescriptionWrapper>
      {item.descriptionImage && <InfoDescriptionImage src={item.descriptionImage} />}
      {lodashIsString(item.description) ? T.translate(item.description) : item.description }
    </InfoDescriptionWrapper>
  );

  const renderText = (item) => (
    <TextWrapper
      key={item.key}
      onClick={disabled ? null : () => onChange(item.key)}
      size={size}
      bordered={bordered}
      disabled={disabled}
      selected={selectedValue === item.key}
    >
      {T.translate(item.value)}
    </TextWrapper>
  );

  return (
    <RadioTextWrapper spaced={spaced} ref={ref}>
      {data.map((item) => (item.description
        ? <Popover mouseEnterDelay={0.3} key={item.key} placement="topLeft" title={T.translate(item.value)} content={() => content(item)}>{renderText(item)}</Popover>
        : renderText(item)
      ))}
    </RadioTextWrapper>
  );
});

RadioText.propTypes = {
  data: PropTypes.array,
  selected: PropTypes.string,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.number,
  spaced: PropTypes.bool,
  bordered: PropTypes.bool,
};

export default RadioText;
