import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import lodashSum from 'lodash/sum';
import lodashIsEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { StyledFormItem, StyledInputNumber } from 'styles/commonComponents.styles';
import { DEVELOPMENT_APPRAISAL_FORM } from 'constants/feasibilityReportConts';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { handleOnChange } from 'utils/helpers/feasibilityReportHelpers';
import { getProfileByIdSelector, loadingSelector } from 'store/swappProfile';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import CurrencyInput from '../../../../common/CurrencyInput';
import { ContentTitle, ReportMainColumnDataTextWrapper, StyledText, TableBoxWrapper, ReportMainColumnDataText } from '../report/ReportPages.styles';

const ROW_TYPES = {
  CURRENCY: 'CURRENCY',
  PERCENTAGE: 'PERCENTAGE',
  TOTAL: 'TOTAL',
};

let debounceTimer = null;
const cellsMargin = 10;

const DevelopmentExpenditureTable = (props) => {
  const { data, formData, formInstance, currentSelectedProfileId, disabled } = props;

  const dispatch = useDispatch();
  const profile = useSelector((state) => getProfileByIdSelector(state, currentSelectedProfileId));
  const isLoading = useSelector(loadingSelector);
  const match = useRouteMatch();
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));

  const modelData = lodashGet(data, 'baseDataTable', []);
  const lotArea = lodashGet(data, 'lotArea', []);
  const BCR = lodashGet(data, 'BCR', []);

  const [tableData, setTableData] = useState([]);
  const isTableDataInitialize = !lodashIsEmpty(tableData) && lodashGet(formData, `[${DEVELOPMENT_APPRAISAL_FORM.BUILD_COSTS}]`);
  const totalRevenue = lodashGet(formData, `[${DEVELOPMENT_APPRAISAL_FORM.TOTAL_REVENUE}]`);

  useEffect(() => {
    updateValues();
  }, [isTableDataInitialize, formData, currentSelectedProfileId]);

  // // unmount
  // useEffect(() => () => {
  //   clearTimeout(debounceTimer);
  // }, []);
  //
  // useEffect(() => {
  //   clearTimeout(debounceTimer);
  // }, [currentSelectedProfileId]);

  const updateValues = () => {
    if (lodashIsEmpty(formData) || !formInstance) {
      clearTimeout(debounceTimer);
      return;
    }

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    debounceTimer = setTimeout(() => {
      const currentFormData = formInstance.getFieldsValue();

      const totalBaseDataTableRow = modelData.find((unit) => unit.key === 'TOTAL');

      const buildCostsValue = currentFormData[DEVELOPMENT_APPRAISAL_FORM.BUILD_COSTS] * totalBaseDataTableRow.area;

      const buildCosts = buildCostsValue;
      const landscape = currentFormData[DEVELOPMENT_APPRAISAL_FORM.LANDSCAPE] * (lotArea - BCR);
      const otherAbnormals = (currentFormData[DEVELOPMENT_APPRAISAL_FORM.OTHER_ABNORMALS] / 100) * buildCostsValue;
      const employersAgent = (currentFormData[DEVELOPMENT_APPRAISAL_FORM.EMPLOYERS_AGENT] / 100) * buildCostsValue;
      const planningFees = (currentFormData[DEVELOPMENT_APPRAISAL_FORM.PLANNING_FEES] / 100) * buildCostsValue;
      const buildCostsTotal = lodashSum([buildCosts, landscape, planningFees, otherAbnormals, employersAgent]);

      const S106 = (currentFormData[DEVELOPMENT_APPRAISAL_FORM.S106] * 100) / buildCostsValue;
      const cil = currentFormData[DEVELOPMENT_APPRAISAL_FORM.CIL] * totalBaseDataTableRow.numOfUnits;
      const s106CilTotal = lodashSum([currentFormData[DEVELOPMENT_APPRAISAL_FORM.S106], cil]);

      const developmentManagementFee = (currentFormData[DEVELOPMENT_APPRAISAL_FORM.DEVELOPMENT_MANAGEMENT_FEE] / 100) * totalRevenue;
      const salesMarketing = (currentFormData[DEVELOPMENT_APPRAISAL_FORM.SALES_MARKETING] / 100) * totalRevenue;
      const devCostsTotal = lodashSum([developmentManagementFee, salesMarketing, currentFormData[DEVELOPMENT_APPRAISAL_FORM.CONVEYANCING_FEES], currentFormData[DEVELOPMENT_APPRAISAL_FORM.HISTORICAL_COSTS]]);

      const landSalesMarketing = (currentFormData[DEVELOPMENT_APPRAISAL_FORM.LAND_SALES_MARKETING] / 100) * currentFormData[DEVELOPMENT_APPRAISAL_FORM.LAND_VALUE];
      const landConveyancingFees = (currentFormData[DEVELOPMENT_APPRAISAL_FORM.LAND_CONVEYANCING_FEES] / 100) * currentFormData[DEVELOPMENT_APPRAISAL_FORM.LAND_VALUE];
      const landCostsTotal = lodashSum([currentFormData[DEVELOPMENT_APPRAISAL_FORM.LAND_VALUE], landSalesMarketing, landConveyancingFees]);

      const contingency = (currentFormData[DEVELOPMENT_APPRAISAL_FORM.CONTINGENCY] / 100) * buildCostsTotal;
      const contingencyTotal = lodashSum([contingency]);

      const totalCosts = lodashSum([buildCostsTotal, s106CilTotal, devCostsTotal, landCostsTotal, contingencyTotal]);
      formInstance.setFieldsValue({ [DEVELOPMENT_APPRAISAL_FORM.TOTAL_COSTS]: totalCosts });
      if (!disabled) {
        handleOnChange(dispatch, { formInstance, profile, isLoading, disabled });
      }

      setTableData([
        {
          name: T.translate('Build Costs'),
          rows: [
            {
              name: `${T.translate('Build Costs per')} ${T.translate(isImperial ? 'SQF' : 'SQM')}`,
              field: DEVELOPMENT_APPRAISAL_FORM.BUILD_COSTS,
              value: buildCosts,
              initialValue: 3500,
              type: ROW_TYPES.CURRENCY,
              type2: ROW_TYPES.CURRENCY,
              unitSystem: CurrencyInput.unitSystemKey.sqmToSqf,
            },
            {
              name: `${T.translate('Landscape & External Works per')} ${T.translate(isImperial ? 'SQF' : 'SQM')}`,
              field: DEVELOPMENT_APPRAISAL_FORM.LANDSCAPE,
              value: landscape,
              initialValue: 500,
              type: ROW_TYPES.CURRENCY,
              type2: ROW_TYPES.CURRENCY,
              unitSystem: CurrencyInput.unitSystemKey.sqmToSqf,
            },
            {
              name: T.translate('Pre-Contract Planning Fees'),
              field: DEVELOPMENT_APPRAISAL_FORM.PLANNING_FEES,
              initialValue: 2,
              type: ROW_TYPES.PERCENTAGE,
              type2: ROW_TYPES.CURRENCY,
              value: planningFees,
            },
            {
              name: T.translate('Other Abnormals'),
              field: DEVELOPMENT_APPRAISAL_FORM.OTHER_ABNORMALS,
              value: otherAbnormals,
              initialValue: 4,
              type: ROW_TYPES.PERCENTAGE,
              type2: ROW_TYPES.CURRENCY,
            },
            {
              name: T.translate('Employers Agent'),
              field: DEVELOPMENT_APPRAISAL_FORM.EMPLOYERS_AGENT,
              value: employersAgent,
              initialValue: 2,
              type: ROW_TYPES.PERCENTAGE,
              type2: ROW_TYPES.CURRENCY,
            },
            { value: buildCostsTotal, type: ROW_TYPES.TOTAL },
          ],
        },
        {
          name: T.translate('S106 / CIL'),
          rows: [
            {
              name: T.translate('S106 Contributions'),
              field2: DEVELOPMENT_APPRAISAL_FORM.S106,
              initialValue2: 150000,
              type2: ROW_TYPES.CURRENCY,
              value: S106,
              valueType: ROW_TYPES.PERCENTAGE,
            },
            {
              name: T.translate('CIL'),
              field: DEVELOPMENT_APPRAISAL_FORM.CIL,
              value: cil,
              initialValue: 12000,
              type: ROW_TYPES.CURRENCY,
              type2: ROW_TYPES.CURRENCY,
            },
            { value: s106CilTotal, type: ROW_TYPES.TOTAL },
          ],
        },
        {
          name: T.translate('Dev Costs'),
          rows: [
            {
              name: T.translate('Development Management Fee'),
              field: DEVELOPMENT_APPRAISAL_FORM.DEVELOPMENT_MANAGEMENT_FEE,
              value: developmentManagementFee,
              initialValue: 3,
              type: ROW_TYPES.PERCENTAGE,
              type2: ROW_TYPES.CURRENCY,
            },
            {
              name: T.translate('Sales & Marketing'),
              field: DEVELOPMENT_APPRAISAL_FORM.SALES_MARKETING,
              value: salesMarketing,
              initialValue: 4,
              type: ROW_TYPES.PERCENTAGE,
              type2: ROW_TYPES.CURRENCY,
            },
            {
              name: T.translate('Legal & Conveyancing Fees'),
              field: DEVELOPMENT_APPRAISAL_FORM.CONVEYANCING_FEES,
              initialValue: 50000,
              type: ROW_TYPES.CURRENCY,
            },
            {
              name: T.translate('Historical Costs'),
              field: DEVELOPMENT_APPRAISAL_FORM.HISTORICAL_COSTS,
              initialValue: 1000000,
              type: ROW_TYPES.CURRENCY,
            },
            { value: devCostsTotal, type: ROW_TYPES.TOTAL },
          ],
        },
        {
          name: T.translate('Land Costs'),
          rows: [
            {
              name: T.translate('Land Value'),
              field: DEVELOPMENT_APPRAISAL_FORM.LAND_VALUE,
              initialValue: 4000000,
              type: ROW_TYPES.CURRENCY,
            },
            {
              name: T.translate('Sales & Marketing'),
              field: DEVELOPMENT_APPRAISAL_FORM.LAND_SALES_MARKETING,
              value: landSalesMarketing,
              initialValue: 4.8,
              type: ROW_TYPES.PERCENTAGE,
              type2: ROW_TYPES.CURRENCY,
            },
            {
              name: T.translate('Legal & Conveyancing Fees'),
              field: DEVELOPMENT_APPRAISAL_FORM.LAND_CONVEYANCING_FEES,
              value: landConveyancingFees,
              initialValue: 1,
              type: ROW_TYPES.PERCENTAGE,
              type2: ROW_TYPES.CURRENCY,
            },
            { value: landCostsTotal, type: ROW_TYPES.TOTAL },
          ],
        },
        {
          name: T.translate('Other'),
          rows: [
            {
              name: T.translate('Contingency'),
              field: DEVELOPMENT_APPRAISAL_FORM.CONTINGENCY,
              value: contingency,
              initialValue: 5,
              type: ROW_TYPES.PERCENTAGE,
              type2: ROW_TYPES.CURRENCY,
            },
            { value: contingencyTotal, type: ROW_TYPES.TOTAL },
          ],
        },
        {
          name: T.translate('Total'),
          rows: [
            { name: T.translate('Total Costs'), value: totalCosts, total: true, bold: true, type2: ROW_TYPES.CURRENCY },
          ],
        },
      ]);
    }, 800);
  };

  const renderRow = (row, index) => {
    const cellWidth = 95;
    const baseInputOptions = { width: cellWidth, height: 22, min: 0, marginTop: 4, onChange: () => updateValues(setTableData) };
    const inputOptions = {
      [ROW_TYPES.CURRENCY]: { ...baseInputOptions },
      [ROW_TYPES.PERCENTAGE]: { ...baseInputOptions, max: 100, formatter: (input) => numberWithComma(input, { suffix: '%', fixed: 1 }) },
    };

    if (row.type === ROW_TYPES.TOTAL) {
      return (
        <ReportMainColumnDataTextWrapper key={index}>
          <StyledText>{T.translate(' ')}</StyledText>
          <ReportMainColumnDataText>
            <StyledText highlight bold total width={cellWidth} align="right" marginRight={cellsMargin}>{T.translate('Sub-Total')}</StyledText>
            <StyledText highlight bold total width={cellWidth} align="right">
              <CurrencyInput value={row.value} readOnly />
            </StyledText>
          </ReportMainColumnDataText>
        </ReportMainColumnDataTextWrapper>
      );
    }

    return (
      <ReportMainColumnDataTextWrapper key={index} underLine={!row.bold} bold={row.bold} marginBottom={2}>
        <StyledText bold={row.bold} total={row.total}>{T.translate(row.name)}</StyledText>
        <ReportMainColumnDataText>
          {row.field && (
            <StyledFormItem noStyle width={cellWidth} name={row.field} initialValue={row.initialValue} marginRight={5}>
              {row.type === ROW_TYPES.CURRENCY
                ? <CurrencyInput total={row.total} {...baseInputOptions} unitSystemKey={row.unitSystem} readOnly={disabled} />
                : <StyledInputNumber {...inputOptions[row.type]} max={100} disabled={disabled} />}
            </StyledFormItem>
          )}
          {row.value && (
          <StyledText bold={row.bold} width={cellWidth} total={row.total} align="right" marginRight={row.field2 ? cellsMargin + 4 : 0}>
            {row.type2 === ROW_TYPES.CURRENCY
              ? <CurrencyInput value={row.value} readOnly />
              : numberWithComma(row.value, { suffix: '%', fixed: 1 })}
          </StyledText>
          )}
          {row.field2 && (
            <StyledFormItem noStyle width={cellWidth} total={row.total} name={row.field2} initialValue={row.initialValue2}>
              {row.type2 === ROW_TYPES.CURRENCY
                ? <CurrencyInput {...baseInputOptions} unitSystemKey={row.unitSystem} readOnly={disabled} />
                : <StyledInputNumber {...inputOptions[row.type2]} max={100} disabled={disabled} />}
            </StyledFormItem>
          )}
        </ReportMainColumnDataText>
      </ReportMainColumnDataTextWrapper>
    );
  };

  return (
    <>
      {tableData.map((table) => (
        <TableBoxWrapper margin="10px 5px" key={table.name}>
          <ContentTitle>{T.translate(table.name)}</ContentTitle>
          {table.rows.map((row, index) => renderRow(row, index))}
        </TableBoxWrapper>
      ))}
      <StyledFormItem hidden noStyle name={DEVELOPMENT_APPRAISAL_FORM.TOTAL_COSTS} initialValue={0} />
    </>
  );
};

DevelopmentExpenditureTable.propTypes = {
  formInstance: PropTypes.object,
  data: PropTypes.object,
  formData: PropTypes.object,
  currentSelectedProfileId: PropTypes.number,
  disabled: PropTypes.bool,
};

export default DevelopmentExpenditureTable;
