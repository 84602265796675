import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashGet from 'lodash/get';
import { List, Popover, Tabs, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import { userSelector } from 'store/user';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import {
  getShareKeyString,
  parseLocationUrl,
  navigateToMostRelevant,
} from 'utils/helpers/navigationHelpers';
import { STAGES_TABS } from 'constants/navigationConsts';
import { ColorPill, SecondaryButton, StyledTab } from 'styles/commonComponents.styles';
import { PROJECT_STATUS } from 'constants/profileConsts';
import { userProjectsSelector } from 'store/swappProfiles';
import { swappProjectSelector } from 'store/swappProfile';
import { renderProjectName } from 'utils/helpers/dataDisplay';
import { projectionByStage } from 'constants/globalConst';
import { useHistory } from 'react-router';
import { clearSwappProjectAction, getShareableLinkAction } from 'store/swappProfile/actions/swappProfileActions';
import { logoutAction } from 'store/auth/actions/authActions';
import userIcon from '../../styles/static/icons/comon/userIcon';
import {
  HeaderWrapper,
  AvatarWrapper,
  SwappLogo,
  SwappLogoWrapper,
  HeaderNavigationWrapper,
  ProjectDropdown,
  HeaderNavigation,
  HeaderNavigationTop,
  StageSeparator,
  DropdownIcon,
  StyledProjectsMenu,
  SettingsIcon,
  UserIcon,
  ProjectDropdownWrapper,
  ProjectDropdownText,
  StyledProjectMenuItemWrapper,
  StyledProjectMenuItemImage,
  StyledProjectMenuItemText,
  StyledProjectMenuItemImageWrapper,
  StyledProjectMenuItemStage,
  StyledProjectMenuItemData,
  StyledProjectsMenuInner,
} from './Header.styles';
import swappLogo from '../../styles/static/icons/comon/swappLogo';
import companyIcons from '../../styles/static/icons/companyIcons';
import dropdownThinIcon from '../../styles/static/icons/comon/dropdownThinIcon';
import ImperialMetricToggle from './ImperialMetricToggle';
import CurrencySwitcher from './CurrencySwitcher';
import ShareButton from './ShareButton';

const { TabPane } = Tabs;

const PopoverContent = ({ logout, user }) => (
  <List>
    <List.Item>
      <span>{user.email}</span>
    </List.Item>
    <List.Item>
      <SecondaryButton height={32} onClick={logout} center>{T.translate('HEADER_AVATAR_LOG_OUT')}</SecondaryButton>
    </List.Item>
  </List>
);

PopoverContent.propTypes = {
  logout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const Header = (props) => {
  const { shared } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const shareKey = getShareKeyString();
  const user = useSelector(userSelector);
  const locationData = parseLocationUrl(history.location);
  const swappProject = useSelector(swappProjectSelector);
  const userProjects = useSelector(userProjectsSelector, shallowEqual);
  const getProjectIndex = (projectId) => userProjects.findIndex((project) => project.id === projectId);
  const currentProjectIndex = getProjectIndex(locationData.projectId);
  const customCompanyIcon = lodashGet(companyIcons, `[${lodashGet(user, 'settings.whitelabel.icon')}].header`);

  useEffect(() => {
    if (locationData.projectId && !shared) {
      dispatch(getShareableLinkAction(locationData.projectId));
    }
  }, [locationData.projectId]);

  const handleProjectSelect = (key) => {
    const [userProject] = userProjects.filter((item) => item.id === Number(key));
    navigateToMostRelevant(userProject, history);
    dispatch(clearSwappProjectAction());
  };

  const renderProjectsDropdown = () => {
    if (shared && !lodashIsEmpty(swappProject)) {
      return renderProjectName(lodashGet(swappProject, 'name', ''));
    }

    if (lodashIsEmpty(userProjects)) {
      return T.translate('LOADING');
    }

    const filteredProjectsList = userProjects.filter((project) => project.status !== PROJECT_STATUS.ARCHIVED).reverse();

    const projectDropdownMenu = (
      <StyledProjectsMenu>
        <StyledProjectsMenuInner>
          {filteredProjectsList.map((project) => (
            <StyledProjectMenuItemWrapper selected={project.id === locationData.projectId} key={project.id} onClick={() => handleProjectSelect(project.id)}>

              {/* =========== image =========== */}
              <StyledProjectMenuItemImageWrapper>
                {project.imageUrl
                  ? <StyledProjectMenuItemImage src={project.imageUrl} />
                  : <Icon component={projectionByStage[UI_AUTHORIZE_PATH[project.stage]]} />}
              </StyledProjectMenuItemImageWrapper>

              {/* =========== data =========== */}
              <StyledProjectMenuItemData>
                {/* =========== title =========== */}
                <StyledProjectMenuItemText selected={project.id === locationData.projectId}>
                  {renderProjectName(project.name)}
                </StyledProjectMenuItemText>

                {/* =========== stage =========== */}
                <StyledProjectMenuItemStage>
                  <ColorPill margin="0 5px 0 0" stage={UI_AUTHORIZE_PATH[project.stage]} />
                  {`${T.translate(UI_AUTHORIZE_PATH[project.stage])}`}
                </StyledProjectMenuItemStage>
              </StyledProjectMenuItemData>
            </StyledProjectMenuItemWrapper>
          ))}
        </StyledProjectsMenuInner>
      </StyledProjectsMenu>
    );

    const disabled = userProjects.length === 1;

    return (
      <ProjectDropdown disabled={disabled} overlay={projectDropdownMenu} trigger={['hover']}>
        <span>
          <ProjectDropdownText>{`${renderProjectName(userProjects[currentProjectIndex].name)}`}</ProjectDropdownText>
          <DropdownIcon marginTop={7} disabled={disabled} component={dropdownThinIcon} />
        </span>
      </ProjectDropdown>
    );
  };

  const getStage = () => {
    if (locationData.stage === UI_AUTHORIZE_PATH.FEASIBILITY_STUDY) {
      return locationData.stage;
    }
    return shared ? `${locationData.stage}-share` : locationData.stage;
  };

  const getTabsToRender = () => {
    let list = locationData.projectId && STAGES_TABS[getStage()];

    // if (locationData.stage === UI_AUTHORIZE_PATH.TEST_FIT) {
    //   list = list.filter((tab) => !(tab.key === UI_AUTHORIZE_PATH.compare && lodashIncludes(newTestFitProjectIds, Number(locationData.projectId))));
    // }

    // for now filters report tab if the site object dont have `hasReport` as true
    if (locationData.stage === UI_AUTHORIZE_PATH.FEASIBILITY_STUDY) {
      list = list.filter((tab) => !(tab.key === UI_AUTHORIZE_PATH.report && !lodashGet(swappProject, 'site.siteData.hasReport')));
    }

    return list;
  };

  const renderNavigation = () => {
    if (!locationData.projectId) {
      return null;
    }

    return (
      <HeaderNavigationTop>
        <HeaderNavigation>

          <StageSeparator />
          {/* =========== projects dropdown =========== */}
          <ProjectDropdownWrapper>
            {renderProjectsDropdown()}
          </ProjectDropdownWrapper>

          <StageSeparator />
          {/* =========== tabs =========== */}
          <StyledTab
            height={40}
            fontSize={16}
            tabPadding="10px 0 15px 0"
            activeKey={locationData.stageTab}
            onChange={(key) => history.push(`/${locationData.stage}/${locationData.projectId}/${key}${shareKey}`)}
          >
            {getTabsToRender().map((tab) => (
              <TabPane tab={T.translate(tab.value)} key={tab.key} />
            ))}
          </StyledTab>
        </HeaderNavigation>
      </HeaderNavigationTop>
    );
  };

  const renderUser = () => {
    if (shared) {
      return;
    }

    return (
      <AvatarWrapper>
        <Popover content={<PopoverContent logout={() => dispatch(logoutAction(history))} user={user} />} placement="bottomLeft" trigger="hover">
          <UserIcon component={userIcon} />
          {(user.email).split('@')[0].split('.').join(' ')}
        </Popover>
      </AvatarWrapper>
    );
  };

  const renderLogo = () => {
    const logo = (
      <SwappLogoWrapper>
        <SwappLogo component={customCompanyIcon || swappLogo} />
      </SwappLogoWrapper>
    );

    return shared ? logo : <Link to={UI_AUTHORIZE_PATH.root}>{logo}</Link>;
  };

  return (
    <HeaderWrapper>
      <HeaderNavigationWrapper>
        {/* =========== Logo =========== */}
        {renderLogo()}

        {/* =========== Navigation =========== */}
        {renderNavigation()}
      </HeaderNavigationWrapper>

      <HeaderNavigationWrapper>
        <CurrencySwitcher />
        <ImperialMetricToggle />
        {locationData.projectId && !shared && (
          <Tooltip title="Project settings">
            <SettingsIcon onClick={() => history.push(`/${UI_AUTHORIZE_PATH.PROJECT_SETTINGS}/${locationData.projectId}/${UI_AUTHORIZE_PATH.setup}`)} />
          </Tooltip>
        )}
        <ShareButton />
        {renderUser()}
      </HeaderNavigationWrapper>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  shared: PropTypes.bool,
};

export default Header;
