import React from 'react';

// currentcolor
export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 0.5V23.5H14.5204L23.5 14.8208V5.4434L14.5204 0.5H0.5Z" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.2959 11.7453V21.7642H13.0714V11.7453H2.2959Z" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.2959 3.80188V10.0094H17.0714V3.80188H2.2959Z" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
