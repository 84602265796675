import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 0)};
`;

export const InputTitle = styled.div`
  margin-right: 10px;
  max-width: 110px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 2.3;
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.large};
  margin: 40px 0 20px;
`;

export const InputTypeText = styled.div`
  font-size: ${({ theme }) => theme.font.size.small};
  line-height: 3.3;
`;
