import React from 'react';

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.512 10.7321L3.51197 2.01709C2.53381 1.66189 1.5 2.38635 1.5 3.42701V26.5171C1.5 27.5807 2.57616 28.3064 3.56218 27.9078L27.5622 18.2056C28.129 17.9765 28.5 17.4263 28.5 16.815V12.142C28.5 11.511 28.1051 10.9474 27.512 10.7321Z" fill="url(#paint0_linear_753_750)" stroke="url(#paint1_linear_753_750)" strokeLinejoin="round" />
    <defs>
      <linearGradient id="paint0_linear_753_750" x1="27.7074" y1="33.1841" x2="2.51999" y2="33.1841" gradientUnits="userSpaceOnUse">
        <stop offset="0.200624" stopColor="currentcolor" />
        <stop offset="1" stopColor="currentcolor" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint1_linear_753_750" x1="28" y1="22.3667" x2="2.44827" y2="22.3667" gradientUnits="userSpaceOnUse">
        <stop offset="0.223958" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
