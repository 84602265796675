import buildingIcon from './buildingIcon';
import cdIcon from './cdIcon';
import designIcon from './designIcon';
import energyIcon from './energyIcon';
import envIcon from './envIcon';
import financialIcon from './financialIcon';
import parkingIcon from './parkingIcon';
import reportIcon from './reportIcon';
import exportIcon from './exportIcon';
import markupsIcon from './markupsIcon';
import programIcon from './programIcon';

export default {
  buildingIcon,
  cdIcon,
  designIcon,
  envIcon,
  financialIcon,
  parkingIcon,
  energyIcon,
  reportIcon,
  exportIcon,
  markupsIcon,
  programIcon,
};
