import React from 'react';
import { ClockCircleOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons';
import { UI_AUTHORIZE_PATH } from './routes/ui';

export const PROFILE_STATUS = {
  WAITING_FOR_SUBMISSION: 'WAITING_FOR_SUBMISSION',
  WAITING_FOR_DATA: 'WAITING_FOR_DATA',
  WAITING_FOR_EXTRA_DATA: 'WAITING_FOR_EXTRA_DATA',
  READY: 'READY',
  APPROVED: 'APPROVED',
};

export const PROJECT_STATUS = {
  OPEN: 'OPEN',
  ARCHIVED: 'ARCHIVED',
  REPOSITORY: 'REPOSITORY',
  DELETE: 'DELETE', // TODO - change from 'DELETE' to 'DELETED', also in `project-status.enum.ts` in the BE
};

export const PROJECT_STAGES = {
  [UI_AUTHORIZE_PATH.TEST_FIT]: 'TEST_FIT',
  [UI_AUTHORIZE_PATH.FEASIBILITY_STUDY]: 'FEASIBILITY_STUDY',
  [UI_AUTHORIZE_PATH.PROJECT_SETTINGS]: 'PROJECT_SETTINGS',
  [UI_AUTHORIZE_PATH.ONE_CLICK_BUILDING]: 'ONE_CLICK_BUILDING',
};

export const COLOR_BY_STAGE = {
  [UI_AUTHORIZE_PATH.TEST_FIT]: 'testFit',
  [UI_AUTHORIZE_PATH.FEASIBILITY_STUDY]: 'feasibilityStudy',
  [UI_AUTHORIZE_PATH.PROJECT_SETTINGS]: 'projectSettings',
};

export const ICON_BY_STATUS = {
  [PROFILE_STATUS.WAITING_FOR_DATA]: <ClockCircleOutlined />,
  [PROFILE_STATUS.WAITING_FOR_SUBMISSION]: <EditOutlined />,
  [PROFILE_STATUS.READY]: <CheckOutlined />,
  [PROFILE_STATUS.APPROVED]: <CheckOutlined />,
};

export const STATUS_ORDER = {
  [PROFILE_STATUS.APPROVED]: 0,
  [PROFILE_STATUS.READY]: 1,
  [PROFILE_STATUS.WAITING_FOR_DATA]: 2,
  [PROFILE_STATUS.WAITING_FOR_SUBMISSION]: 3,
};
