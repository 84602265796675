import * as $ from 'jquery';

import { convertjQueryTableToCSV } from '../../helpers/tableToCsvConverter';

// Given a string of a CSV table, this function will search for columns that look like 'XXX model / target'
// and replace them with three columns - 'XXX model', 'XXX target' and 'XXX model - target'
const splitModelTargetColumns = (tableCsv) => {
  const rows = tableCsv.split('\n');
  if (rows.length < 2) {
    return tableCsv;
  }
  const headlineColumns = rows[0].split(',');
  const newHeadlineColumns = [];
  const modifiedColumnIndices = [];
  const columnSuffixToSplit = 'model / target"';
  // Find the column headers that should be split, prepare the new header row and mark the indices for row processing
  headlineColumns.forEach((column, columnIndex) => {
    if (column.endsWith(columnSuffixToSplit)) {
      const prefix = column.substring(0, column.length - columnSuffixToSplit.length);
      modifiedColumnIndices.push(columnIndex);
      newHeadlineColumns.push(`${prefix} model"`);
      newHeadlineColumns.push(`${prefix} target"`);
      newHeadlineColumns.push(`${prefix} model - target"`);
    } else {
      newHeadlineColumns.push(column);
    }
  });
  const newRows = [newHeadlineColumns.join(',')];

  // Process the rows by splitting the values that match the modified headers
  rows.slice(1).forEach((oldRow) => {
    const newRow = [];
    const splitRow = oldRow.split(','); // TODO: What about escaping? Do we want to add csv lib?
    splitRow.forEach((data, columnIdx) => {
      if (modifiedColumnIndices.includes(columnIdx)) {
        const isPercentageColumn = data.includes('%');
        const suffix = isPercentageColumn ? '%' : '';
        const parts = data.replaceAll(' ', '').replaceAll('"', '').replaceAll('%', '').split('/');
        if (parts.length > 0 && parts[0].length > 0) {
          const model = parseFloat(parts[0]);

          newRow.push(`${model.toString()}${suffix}`);
          if (parts.length > 1) {
            // This is a MODEL / TARGET format, put values in all three fields
            const target = parseFloat(parts[1]);
            newRow.push(`${target.toString()}${suffix}`);
            newRow.push(`${(model - target).toFixed(2).toString()}${suffix}`);
          } else {
            // This is a MODEL format, put one value and skip two
            newRow.push('');
            newRow.push('');
          }
        } else {
          // This is an empty field, skip three values
          newRow.push('');
          newRow.push('');
          newRow.push('');
        }
      } else {
        newRow.push(data);
      }
    });
    newRows.push(newRow.join(','));
  });
  return newRows.join('\n');
};

export const extractTestFitResultsCSV = async () => {
  const elementName = 'ProfileDataPanel';
  const jquery = $;
  const csvs = [];
  const submenus = jquery(`.${elementName}`);
  for (let subMenuIdx = 0; subMenuIdx < submenus.length; subMenuIdx++) {
    const submenu = submenus[subMenuIdx];
    const headlineString = submenu.children[0].innerText.replace('\n', ' ');
    csvs.push(headlineString);
    let tables = jquery(submenu).find('table');
    if (tables.length === 0) {
      const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
      await sleep(50);
      tables = jquery(submenu).find('table');
    }
    const submenuCsvTables = [];
    tables.each((tableIdx, table) => {
      let tableCsv = convertjQueryTableToCSV(jquery(table));
      if (tableIdx === 0) {
        tableCsv = tableCsv.split('\n').slice(1).join('\n');
      }
      submenuCsvTables.push(tableCsv);
    });
    const submenuCsvTable = splitModelTargetColumns(submenuCsvTables.join('\n'));
    csvs.push(submenuCsvTable);
    csvs.push('\n\n');
  }
  return csvs.join('\n');
};
