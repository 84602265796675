import activateIcon from './activateIcon';
import arrowIcon from './arrowIcon';
import calloutIcon from './calloutIcon';
import circleIcon from './circleIcon';
import closeIcon from './closeIcon';
import cloudIcon from './cloudIcon';
import deleteIcon from './deleteIcon';
import duplicateIcon from './duplicateIcon';
import fontSizeIcon from './fontSizeIcon';
import fontSizeBigIcon from './fontSizeBigIcon';
import fontSizeSmallIcon from './fontSizeSmallIcon';
import freehandIcon from './freehandIcon';
import highlightIcon from './highlightIcon';
import normalIcon from './normalIcon';
import openIcon from './openIcon';
import polylineIcon from './polylineIcon';
import polycloudIcon from './polycloudIcon';
import rectangleIcon from './rectangleIcon';
import revisionIcon from './revisionIcon';
import revisionBigIcon from './revisionBigIcon';
import thickIcon from './thickIcon';
import thinIcon from './thinIcon';
import undoIcon from './undoIcon';

export default {
  activateIcon,
  arrowIcon,
  calloutIcon,
  circleIcon,
  closeIcon,
  cloudIcon,
  deleteIcon,
  duplicateIcon,
  fontSizeIcon,
  fontSizeBigIcon,
  fontSizeSmallIcon,
  freehandIcon,
  highlightIcon,
  normalIcon,
  openIcon,
  polylineIcon,
  polycloudIcon,
  rectangleIcon,
  revisionIcon,
  revisionBigIcon,
  thickIcon,
  thinIcon,
  undoIcon,
};
