/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
// createNextState is immer's produce function: https://immerjs.github.io/immer/produce
import { createNextState } from '@reduxjs/toolkit';
import { getPolygonCentroid } from 'utils/algorithms/algorithmHelpers';
// import { getBuildingStoriesData } from 'utils/model/feasibilityDataExtractors';

const getCustomBuilding = () => ({ floor_groups: [
  {
    type: 'typical_floor',
    count: 10,
    units: [
      {
        name: 'STUDIO',
        key: 'STUDIO_first-0',
        variant: 'medium',
        side: 0,
        points: [],
        holes: [],
        aspects: 2,
        modifications: {},
        facades: [],
      },
    ],
    unit_summaries: [
      {
        name: 'STUDIO',
        count: 1,
        side: 0,
      },
    ],
    parking_units: [],
    envelope: [],
    envelope_holes: [],
    floor_height: 3.33,
    back_to_back_wall_length: 0,
  },
],
floor_group_templates: [],
height_offset: 27.78710368942943,
pivot_point: [],
});

const getNewMassingOption = (outerPoints, floorCount) => {
  const customBuilding = getCustomBuilding();
  customBuilding.floor_groups[0].envelope = outerPoints;
  customBuilding.floor_groups[0].units[0].points = outerPoints;
  customBuilding.floor_groups[0].count = floorCount;
  customBuilding.pivot_point = getPolygonCentroid(outerPoints);
  return customBuilding;
};

export const addEmptyMass = (operationName, operationParameters, resultObject, extraParams) => {
  const { outerPoints } = extraParams;
  const newMass = getNewMassingOption(outerPoints, 25);
  return createNextState(resultObject, (draft) => {
    draft.singleOptionMassingOptions.massing_options[0].masses.push(newMass);
  });
};

// eslint-disable-next-line no-unused-vars
export const editMass = (operationName, operationParameters, resultObject, extraParams) => {
  // const { outerPoints, buildingInfo } = extraParams;
  // const { mass_index } = operationParameters;
  // return createNextState(resultObject, (draft) => {
  //   const { swpBuilding } = buildingInfo.masses[`Mass${mass_index}`];
  //   // If it is not an empty mass - do not show local preview
  //   if (swpBuilding?.stringified_form_data) {
  //     return null;
  //   }
  //   // const floorGroups = draft.singleOptionMassingOptions.massing_options[0].masses[mass_index].floor_groups;
  //   // const floorNum = floorGroups.reduce((sum, current) => current.count + sum, 0);
  //   const newMass = getNewMassingOption(outerPoints, 10);
  //   draft.singleOptionMassingOptions.massing_options[0].masses.splice(mass_index, 1, newMass);
  // });
  return null;
};
