// Implementation note: for simplicity, we maintain a global state in this class
// It could have been a class or a react context, but we aim to separate concerns
import { io } from 'socket.io-client';
import lodashIsEmpty from 'lodash/isEmpty';
import { API_AUTH_ROUTES } from 'constants/routes/api';

let ws = null;
let isRequestActive = false;
let pendingOperations = [];
let pendingReset = false;
let pendingHints = null;
let resultArrivedHandler = null;
let profileArrivedHandler = null;
let errorHandler = null;
let isEditSessionStoped = false;

const cleanOperations = (operations) => operations.map((operation) => {
  // const { groupId, ...newOperation } = operation;
  // return newOperation;
  const { localViewParameters, ...newOperation } = operation;
  if (newOperation.groupId) {
    delete newOperation.groupId;
    return newOperation;
  }
  return newOperation;
});

export const beginEditSession = async (agent, profileId, onResultArrived, onConnectionFailed, onProfileArrived) => {
  resultArrivedHandler = onResultArrived;
  profileArrivedHandler = onProfileArrived;
  errorHandler = onConnectionFailed;

  agent.post(`${API_AUTH_ROUTES.projectProfile}/${profileId}${API_AUTH_ROUTES.beginEditSession}`, {}).then((response) => {
    const url = response.data;
    const opts = { transports: ['websocket'], reconnection: false };
    ws = io(url, opts);
    if (!ws) {
      errorHandler({ message: 'connection failed' });
      return;
    }

    ws.on('disconnect', () => {
      // Inform the disconnection only when it wasn't initiated by the user
      if (!isEditSessionStoped) {
        errorHandler({ message: 'disconnected' });
      }
    });

    isEditSessionStoped = false;
    ws.connect();
  }).catch((err) => {
    errorHandler(err);
  });
};

export const endEditSession = () => {
  isEditSessionStoped = true;
  if (ws !== null) {
    ws.close();
  }
  ws = null;
  isRequestActive = false;
  pendingOperations = [];
  pendingReset = false;
  resultArrivedHandler = null;
};

export const addOperationsToEditSession = (operations, reset) => {
  const newOperations = cleanOperations(operations);

  pendingHints = null; // We assume that any new operation that arrives invalidates any previously requested hints
  if (isRequestActive) {
    // We are still waiting for results to arrive. We will wait until it does, and then send all waiting ones as one chunk
    pendingReset = reset;
    if (reset) {
      pendingOperations = [];
    }
    newOperations.forEach((op) => pendingOperations.push(op));
  } else if (newOperations) {
    sendOperationsToServer(newOperations, reset);
  }
  console.log(newOperations);
};

export const addHintsToEditSession = (hints) => {
  if (isRequestActive) {
    // Hints, contrary to operations, don't stack, they overwrite.
    // TODO: Perhaps we need to overwrite by type and not globally?
    pendingHints = hints;
  } else {
    sendHintsToServer(hints);
  }
};

const sendOperationsToServer = (operations, reset) => {
  const newOperations = cleanOperations(operations);

  if (!ws) {
    errorHandler({ message: 'edit_result_preview' });
    return;
  }
  ws.emit('edit_result_preview', { operations: newOperations, reset }, handleResultReceived);
  isRequestActive = true;
  pendingOperations = [];
  pendingReset = false;
};

const sendHintsToServer = (hints) => {
  if (!ws) {
    errorHandler({ message: 'edit_result_hints' });
    return;
  }
  ws.emit('edit_result_hints', { hints }, handleHintSendingCompleted);
  pendingHints = null;
  isRequestActive = true;
};

const handleResultReceived = (response) => {
  isRequestActive = false;
  if (pendingOperations.length > 0) {
    // If there are pending operations, we do not report the intermediate result back to the user
    // This means that the user can safely assume that when they receive a result, it is up to date.
    sendOperationsToServer(pendingOperations, pendingReset);
  } else if (response.success) {
    resultArrivedHandler(response.result);
    if (pendingHints != null) {
      sendHintsToServer(pendingHints);
    }
  } else {
    // TODO: This log will also show on production. Do we want to limit it to DEV only?
    if (response.manual_params) {
      console.log(response.manual_params);
    }
    // TODO: response.error will exist. Do we want to do something with it?
    resultArrivedHandler(null);
  }
};

const handleHintSendingCompleted = (response) => {
  if (!response?.success) {
    // console.log('Hint sending failed');
  }
  isRequestActive = false;
  if (pendingHints != null) {
    sendHintsToServer(pendingHints);
  } else if (pendingOperations?.length > 0) {
    sendOperationsToServer(pendingOperations, pendingReset);
  }
};

const handleProfileReceived = (response) => {
  if (!response?.success) {
    errorHandler({ message: 'Could not save profile' });
    return;
  }
  if (!lodashIsEmpty(response.result)) { // we expect this to happen when continueEditing=null
    profileArrivedHandler(response.result);
  }
};

export const saveEditSessionResult = (saveAsCopy, continueEditing = false) => {
  if (!ws) {
    errorHandler({ message: 'edit_result_save' });
    return;
  }
  ws.emit('edit_result_save', { continue_editing: continueEditing, save_as_copy: saveAsCopy }, handleProfileReceived);
};
