/* eslint-disable no-underscore-dangle */
/* eslint-disable no-return-assign */

let _walkThroughCameraAngle = 0;
let _compassAngle = 0;
const _viewersGlobalData = {};

export const threeJsGlobals = {
  setWalkThroughCameraAngle: (val) => _walkThroughCameraAngle = val,
  getWalkThroughCameraAngle: () => _walkThroughCameraAngle,

  setCameraRotation: (val) => _compassAngle = val,
  getCameraRotation: () => _compassAngle,

  addCameraAndController: ({ viewerId, camera, controller, invalidate }) => _viewersGlobalData[viewerId] = { camera, controller, invalidate },
  getCameraAndControllerById: (id) => _viewersGlobalData[id],
};

Object.freeze(threeJsGlobals);
