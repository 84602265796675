import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import styled from 'styled-components';
import { AREA_TARGET_FORM } from 'constants/fieldNames';
import { FORM_INPUT_TYPE } from 'utils/helpers/testFitContainerHelprs';
import { ColumnFormItem, TestFitInputNumber } from './AreaTargetFormContainer.styles';
import ImperialMetricInput from '../../../common/ImperialMetricInput';

const formItemWidth = 250;

const AreaTargetFormRsfLsfWrapper = styled.div`
  display: flex;
`;

const AreaTargetFormRsfLsf = (props) => {
  const { disabled, form: { setFieldsValue, getFieldsValue }, handleOnChange, isImperial } = props;
  const formData = getFieldsValue();
  const isRoomCount = formData[AREA_TARGET_FORM.INPUT_TOGGLE] === FORM_INPUT_TYPE.ROOM_COUNT;

  useEffect(() => {
    if (isRoomCount) {
      handleChangeRoomCount();
    } else {
      handleChangePercentageTarget();
    }

    if (!formData[AREA_TARGET_FORM.INPUT_TOGGLE]) {
      setFieldsValue({ [AREA_TARGET_FORM.INPUT_TOGGLE]: FORM_INPUT_TYPE.PERCENTAGE_TARGET });
      handleOnChange();
    }
  }, [
    formData[AREA_TARGET_FORM.LSF_RSF_FIELD],
    formData[AREA_TARGET_FORM.TOTAL_AREA_FIELD],
    formData[AREA_TARGET_FORM.TYPE],
    formData[AREA_TARGET_FORM.TOTAL_LSF_FIELD],
    formData[AREA_TARGET_FORM.INPUT_TOGGLE],
  ]);

  const handleChangePercentageTarget = () => {
    if (!formData[AREA_TARGET_FORM.LSF_RSF_FIELD]) {
      setFieldsValue({ [AREA_TARGET_FORM.TOTAL_LSF_AVERAGE_FIELD]: 0 });
      handleOnChange();
    }

    const totalArea = formData[AREA_TARGET_FORM.TOTAL_AREA_FIELD];
    const lsfRsf = formData[AREA_TARGET_FORM.LSF_RSF_FIELD];

    setFieldsValue({
      [AREA_TARGET_FORM.TOTAL_LSF_AVERAGE_FIELD]: totalArea && lsfRsf ? Math.round(Number(totalArea) * (Number(lsfRsf) / 100)) : 0,
    });
    handleOnChange();
  };

  const handleChangeRoomCount = () => {
    const totalLsf = lodashGet(formData, `[${AREA_TARGET_FORM.TOTAL_LSF_FIELD}][0].estimateSF`, 0);
    const totalArea = formData[AREA_TARGET_FORM.TOTAL_AREA_FIELD];

    setFieldsValue({
      [AREA_TARGET_FORM.LSF_RSF_FIELD]: Math.round((totalLsf * 100) / totalArea),
      [AREA_TARGET_FORM.TOTAL_LSF_AVERAGE_FIELD]: totalLsf,
    });
    handleOnChange();
  };

  return (
    <AreaTargetFormRsfLsfWrapper>
      {/* ========= LSF Percentage ========= */}
      <ColumnFormItem
        name={AREA_TARGET_FORM.LSF_RSF_FIELD}
        width={formItemWidth}
        disabled={disabled}
        label={T.translate('AREA_TARGET_FORM_RSF_LSF_RSF_LSF_LABEL')}
        rules={[{ required: true, type: 'number', min: 1, max: 100, message: T.translate('AREA_TARGET_FORM_RSF_LSF_RSF_LSF_ERROR') }]}
      >
        <TestFitInputNumber
          disabled={disabled || isRoomCount}
          min={1}
          max={100}
          width={100}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace('%', '')}
        />
      </ColumnFormItem>

      {/* ========= LSF Area ========= */}
      <ColumnFormItem
        width={formItemWidth}
        name={AREA_TARGET_FORM.TOTAL_LSF_AVERAGE_FIELD}
        disabled={disabled}
        label={T.translate('AREA_TARGET_FORM_RSF_LSF_LSF_TOTAL_LABEL')}
      >
        <ImperialMetricInput width={100} isImperial={isImperial} disabled />
      </ColumnFormItem>
    </AreaTargetFormRsfLsfWrapper>
  );
};

AreaTargetFormRsfLsf.propTypes = {
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  isImperial: PropTypes.bool,
  handleOnChange: PropTypes.func,
};

export default React.memo(AreaTargetFormRsfLsf);
