import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { Skeleton, Tooltip, Form } from 'antd';
import Icon from '@ant-design/icons';
import lodashDebounce from 'lodash/debounce';
import lodashGet from 'lodash/get';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { updateProjectProfileAction } from 'store/swappProfile/actions/swappProfileActions';
import { loadingSelector as loadingProject, swappProjectSelector } from '../../store/swappProfile/selectors';
import EditIcon from '../../styles/static/icons/comon/editIcon';
import { NameFormItem, NameInput, NameLoadingWrapper, NameWrapper, ReadOnlyName } from './ProfileRename.styles';

const fieldName = 'name';

const renameTextByStage = {
  [UI_AUTHORIZE_PATH.TEST_FIT]: 'PROFILE_RENAME_RENAME_LAYOUT',
  [UI_AUTHORIZE_PATH.FEASIBILITY_STUDY]: 'PROFILE_RENAME_RENAME_RESULT',
};

const ProfileRename = (props) => {
  const { profileId, width, borderless, small, disabled, hidden } = props;
  const history = useHistory();
  const match = useRouteMatch();
  const swappProject = useSelector(swappProjectSelector);
  const isLoadingProject = useSelector(loadingProject);
  const dispatch = useDispatch();

  const locationData = parseLocationUrl(history.location);
  const [form] = Form.useForm();
  const currentProfileId = profileId || Number(match.params.profileId);
  const profileIndex = lodashGet(swappProject, 'projectProfiles', []).findIndex((profile) => profile.id === currentProfileId);
  const { stage } = locationData;
  const profileName = lodashGet(swappProject, `projectProfiles[${profileIndex}].name`);

  useEffect(() => {
    form.setFieldsValue({ [fieldName]: profileName });
  }, [profileIndex, profileId]);

  const handleNameChange = useCallback(
    lodashDebounce(() => {
      form.validateFields()
        .then((values) => {
          dispatch(updateProjectProfileAction(currentProfileId, { [fieldName]: values[fieldName] }))
            .catch(() => {
              const currentName = form.getFieldValue(fieldName);
              form.setFields([{
                name: fieldName,
                errors: [T.translate('PROFILE_RENAME_ERROR_NAME_TAKEN', { context: { currentName } })],
              }]);
            });
        });
    }, 1000), [match, currentProfileId],
  );

  if (isLoadingProject) {
    return <NameLoadingWrapper><Skeleton active paragraph={{ rows: 0 }} /></NameLoadingWrapper>;
  }

  if (disabled) {
    return <ReadOnlyName small={small}>{profileName}</ReadOnlyName>;
  }

  if (hidden) {
    return null;
  }

  return (
    <NameWrapper width={width}>
      <Tooltip title={T.translate(renameTextByStage[stage])} placement="bottom" mouseEnterDelay={1}>
        <Form form={form} name="layoutName" initialValues={{ [fieldName]: lodashGet(swappProject, `projectProfiles[${profileIndex}].name`) }}>
          <NameFormItem name={fieldName} rules={[{ required: true, message: T.translate('PROFILE_RENAME_ERROR_EMPTY') }]}>
            <NameInput
              onKeyDown={(e) => e.stopPropagation()}
              onKeyUp={(e) => e.stopPropagation()}
              small={small}
              borderless={borderless}
              prefix={!borderless && <Icon component={EditIcon} />}
              placeholder={T.translate('PROFILE_RENAME_PLACEHOLDER')}
              onChange={handleNameChange}
            />
          </NameFormItem>
        </Form>
      </Tooltip>
    </NameWrapper>
  );
};

ProfileRename.propTypes = {
  profileId: PropTypes.number,
  width: PropTypes.number,
  borderless: PropTypes.bool,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
};

export default ProfileRename;
