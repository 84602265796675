import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getProfileByIdSelector } from 'store/swappProfile';
import { useProfileResult } from 'store/swappProfile/selectors/swappSelectors';
import { MODEL_ANALYSIS_TYPES } from 'constants/feasibilityConts';
import { updateActiveProfile } from 'store/activeProfile';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import FeasibilityViewerContainer from 'utils/swappViewer/FeasibilityViewerContainer';

export const FeasibilityResultContainerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const ViewerContainer = (props) => {
  const { ui } = props;
  const locationData = parseLocationUrl(window.location);
  const profile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const result = useProfileResult(profile);
  updateActiveProfile(locationData.profileId, result);

  return (
    <FeasibilityResultContainerWrapper>
      {ui || null}
      <FeasibilityViewerContainer
        legendKey={MODEL_ANALYSIS_TYPES.AREA_TYPE}
        profileId={locationData.profileId}
        result={result}
      />
    </FeasibilityResultContainerWrapper>
  );
};

ViewerContainer.propTypes = {
  ui: PropTypes.array,
};

export default ViewerContainer;
