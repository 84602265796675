import styled from 'styled-components';

export const Title = styled.div`
  font-size: ${({ theme }) => theme.font.size.large};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: 20px;
  text-transform: uppercase;
`;

export const EnergyRequirementsFormWrapper = styled.div`
  overflow-y: auto;
  height: 100%;
  padding-left: 18px;
  display: flex;
  flex-direction: column;
`;

export const FormWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
`;

export const FormHeader = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-top: 15px;
`;
