import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Form, message } from 'antd';
import T from 'i18n-react';
import lodashIsEmpty from 'lodash/isEmpty';
import { inProgressSelector } from 'store/swappProfiles/selectors';
import { GENERATE_SWAPP_FORM } from 'constants/fieldNames';
import { PROJECT_STAGES } from 'constants/profileConsts';
import { PrimaryButton } from 'styles/commonComponents.styles';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import { setProjectSiteData } from 'utils/model/projectProfileData';
import {
  createProjectAction,
  generateSiteData,
  updateProjectProfileAction,
} from 'store/swappProfile/actions/swappProfileActions';
import { StyledForm } from './ProjectInfoForm.styles';
import ProjectInfoForm from './ProjectInfoForm';
import newProjectPageIcon from '../../../styles/static/icons/comon/newProjectPageIcon';
import FormWithLabel from '../../common/FormWithLabel';
import LoadingProgress from '../../common/LoadingProgress';

const { studies, program } = UI_AUTHORIZE_PATH;

const GenerateSwappContainer = () => {
  const [form] = Form.useForm();
  const inProgress = useSelector(inProgressSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDone, useIsDone] = useState(false);
  const [hasErrors, useHasErrors] = useState();

  const handleSubmit = (updateIsDone) => {
    form.validateFields()
      .then((values) => {
        const formData = new FormData();

        values[GENERATE_SWAPP_FORM.FILES]?.forEach((file) => {
          formData.append('files', file.originFileObj, file.name);
        });

        formData.append(GENERATE_SWAPP_FORM.THUMBNAIL, values[GENERATE_SWAPP_FORM.THUMBNAIL]);
        formData.append(GENERATE_SWAPP_FORM.LOCATION_NAME, values[GENERATE_SWAPP_FORM.LOCATION_NAME]);
        if (values[GENERATE_SWAPP_FORM.LOCATION]) {
          formData.append(GENERATE_SWAPP_FORM.LAT, values[GENERATE_SWAPP_FORM.LOCATION][GENERATE_SWAPP_FORM.LAT]);
          formData.append(GENERATE_SWAPP_FORM.LNG, values[GENERATE_SWAPP_FORM.LOCATION][GENERATE_SWAPP_FORM.LNG]);
        }

        formData.append(GENERATE_SWAPP_FORM.PROJECT_NAME, values[GENERATE_SWAPP_FORM.PROJECT_NAME]);
        formData.append(GENERATE_SWAPP_FORM.ROOT_STAGE_KEY, PROJECT_STAGES[UI_AUTHORIZE_PATH.TEST_FIT]);

        dispatch(createProjectAction(formData, history))
          .then((data) => {
            updateIsDone(true);
            setTimeout(() => { // set time out to show off the check mark in the progress component
              history.push({
                pathname: `/${UI_AUTHORIZE_PATH.TEST_FIT}/${data.id}/${studies}/${program}`,
                state: { isJustCreated: true },
              });
              message.success(T.translate('SET_LOCATION_SELECT_SWAPP_CREATED'));
              dispatch(updateProjectProfileAction(data.projectProfiles[0].id, setProjectSiteData(form.getFieldValue()[GENERATE_SWAPP_FORM.SITE_POLYGON])))
                .then(() => {
                  dispatch(generateSiteData(data.projectProfiles[0].id));
                });
            }, 400);
          })
          .catch((error) => message.error(error));
      })
      .catch(() => {
        const errorsToCheck = {
          [GENERATE_SWAPP_FORM.FILES]: !lodashIsEmpty(form.getFieldError(GENERATE_SWAPP_FORM.FILES)),
          [GENERATE_SWAPP_FORM.LOCATION_NAME]: !lodashIsEmpty(form.getFieldError(GENERATE_SWAPP_FORM.LOCATION_NAME)),
        };
        updateHasErrors(errorsToCheck, useHasErrors);
      });
  };

  const handleThumbnailUpload = (file) => {
    form.setFieldsValue({ [GENERATE_SWAPP_FORM.THUMBNAIL]: file.file });
  };

  const handleSitePolygonUpdated = (polygon) => {
    form.setFieldsValue({ [GENERATE_SWAPP_FORM.SITE_POLYGON]: polygon });
  };

  const updateHasErrors = (errorsInfo, setHasErrors) => {
    setHasErrors(errorsInfo);
  };

  return (
    <StyledForm form={form} name="generateSwappForm" initialValues={{ [GENERATE_SWAPP_FORM.BLOCK_LOT]: [{ key: 1, block: '', lot: [] }] }}>
      {(inProgress || isDone) && <LoadingProgress isDone={isDone} text={T.translate('GENERATE_SWAPP_CONTAINER_UPLOADING_FILES')} />}
      <FormWithLabel
        width={700}
        iconComponent={newProjectPageIcon}
        title={T.translate('GENERATE_SWAPP_CONTAINER_TITLE')}
        subtitle={T.translate('GENERATE_SWAPP_CONTAINER_SUBTITLE')}
      >
        <ProjectInfoForm hasErrors={hasErrors} showPlanUpload handleThumbnailUpload={handleThumbnailUpload} handleSitePolygonUpdated={handleSitePolygonUpdated} form={form} rootStageKey={PROJECT_STAGES[UI_AUTHORIZE_PATH.TEST_FIT]} />
      </FormWithLabel>
      <PrimaryButton center width={240} marginBottom={18} onClick={() => handleSubmit(useIsDone)}>{T.translate('PLAN_UPLOAD_NEXT_BUTTON')}</PrimaryButton>
    </StyledForm>
  );
};

export default GenerateSwappContainer;
