import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import styled from 'styled-components';
import { Radio, Space, Switch, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { GENERATE_SWAPP_FORM } from 'constants/fieldNames';
import { StyledRadio } from 'styles/commonComponents.styles';
import InputFormItem from '../InputFormItem';
import { SearchResult, Suggestion, FormItem, SelfServeFormWrapper, SelfServeLeftSide, SelfServeMiddle } from '../ProjectInfoForm.styles';
import { GEOCODING_URL } from './ArcGisConsts';
import UploadThumbnail from '../UploadThumbnail';
import { getEsriModules } from './ArcGisMapHelpers';

const DivWrapper = styled.div`
  margin: 8px;
  marginLeft: 0px;
  display: flex; 
  gap: 12px;
`;

const SEARCH_TYPES = {
  BLOCK: 'BLOCK',
  ADDRESS: 'ADDRESS',
};

const NewProjectForm = ({
  handleThumbnailUpload,
  onNameChanged,
  onBlockLotVisibilitySwitchChanged,
  searchType,
  onSearchTypeClicked,
  onSearchBlockSuggestionSelected,
  center,
  onLotTermSelected,
  isFetchingData,
}) => {
  const [searchBlockSuggestions, setSearchBlockSuggestions] = useState([]);
  const [searchLotSuggestions, setSearchLotSuggestions] = useState([]);
  const [isBlockSuggestionsVisible, setIsBlockSuggestionsVisible] = useState(true);
  const [isLotSuggestionsVisible, setIsLotSuggestionsVisible] = useState(true);
  const isSearchByAddress = searchType === NewProjectForm.SEARCH_TYPES.ADDRESS;

  const onSearchTermChanged = async (text) => {
    setIsBlockSuggestionsVisible(true);
    if (isSearchByAddress) {
      const { locator } = getEsriModules();
      locator.suggestLocations(GEOCODING_URL, { text, location: center }).then((results) => {
        setSearchBlockSuggestions(results);
      });
    } else if (text.length > 3) {
      setSearchBlockSuggestions([{ text }]);
    }
  };

  useEffect(() => {
    setIsBlockSuggestionsVisible(false);
    setSearchBlockSuggestions([]);
  }, [searchType]);

  const labelRef = useRef();

  useEffect(() => {
    // Hack to set position of a hidden div
    if (labelRef.current?.parentElement) {
      labelRef.current.parentElement.style.position = 'absolute';
    }
  }, [labelRef.current]);

  const onLotTermChanged = (text) => {
    setSearchLotSuggestions([{ text }]);
  };

  return (
    <>
      <SelfServeFormWrapper
        label={T.translate('MAP_COMPONENT_LOCATION_LABEL')}
        ref={labelRef}
      >
        <SelfServeLeftSide>
          <FormItem
            valuePropName="uploadedImageUrl"
            name={GENERATE_SWAPP_FORM.THUMBNAIL}
            noStyle
          >
            <UploadThumbnail onUpload={handleThumbnailUpload} />
          </FormItem>
        </SelfServeLeftSide>
        <SelfServeMiddle>
          <InputFormItem
            label={T.translate('SET_LOCATION_PROJECT_NAME_LABEL')}
            onChange={onNameChanged}
            placeholder={T.translate('SET_LOCATION_PROJECT_NAME_PLACEHOLDER')}
            name={GENERATE_SWAPP_FORM.PROJECT_NAME}
            requiredMessage={T.translate('SET_LOCATION_PROJECT_NAME_ERROR')}
          />
          <DivWrapper>
            <div>{T.translate('NEW_PROJECT_SHOW_BLOCKS_AND_LOTS')}</div>
            <Switch defaultChecked onChange={(e) => onBlockLotVisibilitySwitchChanged(e)} />
          </DivWrapper>
          <DivWrapper>
            <div style={{ lineHeight: '30px' }}>{T.translate('NEW_PROJECT_SEARCH_FOR')}</div>
            <Radio.Group value={searchType} onChange={onSearchTypeClicked}>
              <Space direction="horizontal">
                <StyledRadio value={SEARCH_TYPES.ADDRESS}>{T.translate('NEW_PROJECT_ADRESS')}</StyledRadio>
                <StyledRadio value={SEARCH_TYPES.BLOCK}>{T.translate('NEW_PROJECT_BLOCK')}</StyledRadio>
              </Space>
            </Radio.Group>
          </DivWrapper>
          <InputFormItem
            label={isSearchByAddress ? T.translate('NEW_PROJECT_ADRESS') : T.translate('NEW_PROJECT_BLOCK')}
            onChange={onSearchTermChanged}
            onFocus={() => setIsBlockSuggestionsVisible(true)}
            placeholder={T.translate('NEW_PROJECT_SEARCH_BY', { context: isSearchByAddress ? T.translate('NEW_PROJECT_ADRESS_LOWER_CASE') : T.translate('NEW_PROJECT_BLOCK_LOWER_CASE') })}
            name="search"
            type={!isSearchByAddress && 'number'}
          />
          {isBlockSuggestionsVisible && (
          <SearchResult style={{ zIndex: 99 }}>
            {searchBlockSuggestions.map((suggestion, i) => (
              <Suggestion
                key={i}
                onClick={() => {
                  setIsBlockSuggestionsVisible(false);
                  onSearchBlockSuggestionSelected(suggestion);
                }}
              >
                <span>{suggestion.text}</span>
              </Suggestion>
            ))}
          </SearchResult>
          )}
          {
            !isSearchByAddress && (
            <InputFormItem
              label={T.translate('NEW_PROJECT_LOTS')}
              onChange={onLotTermChanged}
              onFocus={() => setIsLotSuggestionsVisible(true)}
              placeholder={T.translate('NEW_PROJECT_SEARCH_A_LOT')}
              name="lot"
              type={!isSearchByAddress && 'number'}
            />
            )
          }
          {!isSearchByAddress && isLotSuggestionsVisible && (
          <SearchResult style={{ zIndex: 99 }}>
            {searchLotSuggestions.map((suggestion, i) => (
              <Suggestion
                key={i}
                onClick={() => {
                  setIsLotSuggestionsVisible(false);
                  onLotTermSelected(suggestion.text);
                }}
              >
                <span>{suggestion.text}</span>
              </Suggestion>
            ))}
          </SearchResult>
          )}
        </SelfServeMiddle>
        <Spin spinning={isFetchingData} indicator={<LoadingOutlined spin />} />
      </SelfServeFormWrapper>
    </>
  );
};

NewProjectForm.SEARCH_TYPES = SEARCH_TYPES;

NewProjectForm.propTypes = {
  handleThumbnailUpload: PropTypes.func,
  onNameChanged: PropTypes.func,
  onBlockLotVisibilitySwitchChanged: PropTypes.func,
  onSearchTypeClicked: PropTypes.func,
  onLotTermSelected: PropTypes.func,
  searchType: PropTypes.string,
  onSearchBlockSuggestionSelected: PropTypes.func,
  center: PropTypes.any,
  isFetchingData: PropTypes.bool,
};

export default NewProjectForm;
