import React from 'react';
import T from 'i18n-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import Icon, { LoadingOutlined } from '@ant-design/icons';

const SwappViewerActionButtonWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme, selected, isSave }) => {
    if (isSave) {
      return theme.colors.gray_07;
    }

    return selected ? theme.colors.primaryColor : theme.colors.gray_07;
  }};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  //border: ${({ theme, border }) => border && `1px solid ${theme.colors.primaryColor}`};
  font-size: 22px;
  overflow: hidden;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.22);
  cursor: ${({ isLoading, disabled }) => (!isLoading && !disabled && 'pointer')};
  margin-left: ${({ marginLeft }) => (marginLeft && `${marginLeft}px`)};
  margin-top: ${({ marginTop }) => (marginTop && `${marginTop}px`)};

  .anticon-loading {
    color: ${({ theme }) => theme.colors.primaryColor} !important;
  }
    
  .anticon {
    position: absolute;
    top: 5px;
    left: 5px;

    &:hover {
      color: ${({ theme, disabled }) => (disabled ? 'unset' : theme.colors.primaryColor)};
    }
    
    svg {
      max-width: 20px;
      max-height: 20px;
    }
  }
`;

const InfoTab = styled.div`
  position: relative;
  height: 30px;
  top: -30px;
  left: 15px;
  border-radius: 0 15px 15px 0;
  padding: 0 15px 0 19px;
  line-height: 30px;
  z-index: -1;
  background-color: ${({ theme }) => `${theme.colors.primaryColor}65`};
  color: ${({ theme }) => theme.colors.white};
`;

const SwappViewerActionButton = (props) => {
  const { icon, isLoading, onClick, selected, marginLeft, marginTop, disabled, isSave, isHidden, tabInfoText, tooltipText } = props;

  if (isHidden) {
    return null;
  }

  return (
    <>
      <Tooltip title={T.translate(tooltipText)} mouseEnterDelay={1.2}>
        <SwappViewerActionButtonWrapper
          isLoading={isLoading}
          selected={selected}
          marginLeft={marginLeft}
          marginTop={marginTop}
          border={tabInfoText}
          isSave={isSave}
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (disabled) {
              return;
            }
            onClick();
          }}
        >
          {isLoading ? <LoadingOutlined spin /> : <Icon component={icon} />}
        </SwappViewerActionButtonWrapper>
      </Tooltip>
      {tabInfoText && <InfoTab>{tabInfoText}</InfoTab>}
    </>
  );
};

SwappViewerActionButton.propTypes = {
  icon: PropTypes.func,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  isSave: PropTypes.bool,
  marginLeft: PropTypes.number,
  marginTop: PropTypes.number,
  isHidden: PropTypes.bool,
  tooltipText: PropTypes.string,
  tabInfoText: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array,
    PropTypes.string,
  ]),
};

export default SwappViewerActionButton;
