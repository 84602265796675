import apartmentChartIcon from './apartmentChartIcon';
import apartmentTypeIcon from './apartmentTypeIcon';
import apartmentFacingIcon from './apartmentFacingIcon';
import lightAndClimateIcon from './lightAndClimateIcon';
import studiesIcon from './studiesIcon';
import apartmentIcon from './apartmentIcon';
import efficiencyIcon from './efficiencyIcon';
import parameterIcon from './parameterIcon';
import emptyLotCardIcon from './emptyLotCardIcon';
import treeIcon from './treeIcon';

export default {
  apartmentChartIcon,
  apartmentTypeIcon,
  apartmentFacingIcon,
  lightAndClimateIcon,
  studiesIcon,
  apartmentIcon,
  efficiencyIcon,
  parameterIcon,
  emptyLotCardIcon,
  treeIcon,
};
