import React from 'react';
import PropTypes from 'prop-types';
import { buildLocalToGlobalMatrix, transformGeometry } from '@swapp/swappcommonjs/dist/utils/swpProjectModel';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils';
import SwpApartment from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpApartment';
import { Text } from '@react-three/drei';
import { useSelector } from 'react-redux';
import { selectedStorySelector } from 'store/BuildingStoriesStore';
import { getRampArrowPolygons } from 'utils/algorithms/geometryBuilders';
import * as THREE from 'three';
import SwpRamp from '@swapp/swappcommonjs/dist/swpProject/SwpRamp';
import SwpApartmentPart from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpApartmentPart';
import SwpBalcony from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpBalcony';
import SwpColumn from '@swapp/swappcommonjs/dist/swpProject/physicalProducts/SwpColumn';
import { selectedMassSelector } from 'store/views';
import { CONFIG } from './StoryRenderConfig';
import { addStroke, addFill, addAppartmentLabel, getFloorByFloorNumber } from './helpers';

const BuildingStory = ({ swpBuilding }) => {
  const selectedStory = useSelector(selectedStorySelector);
  const selectedMass = useSelector(selectedMassSelector);

  if (!swpBuilding) {
    return null;
  }

  const isSelected = selectedMass === swpBuilding.id;
  const isAreaNotSelected = !!selectedMass && !isSelected;
  const flatGeometries = [];
  const strokes = [];
  const labels = [];

  const prepareBuidingStoryToRender = (swpProduct) => {
    if (!swpProduct) {
      return;
    }

    // if we render underground parking we want to render the first floor but only its core
    if (selectedStory < 0
        && (
          swpProduct instanceof SwpApartment
            || swpProduct instanceof SwpApartmentPart
            || swpProduct instanceof SwpBalcony
        )) {
      return;
    }

    // we want to show SwpColumn only in underground levels
    if (selectedStory >= 0 && swpProduct instanceof SwpColumn) {
      return;
    }

    const matrix = buildLocalToGlobalMatrix(swpProduct);
    const config = CONFIG.get(swpProduct.constructor);

    if (!config) {
      return;
    }

    const { stroke, fill, fillColor, strokeColor, strokeWidth, fillZHeight } = config;
    const functionalFillColor = typeof fillColor === 'function' ? fillColor(swpProduct) : fillColor;

    if (fill) {
      addFill(matrix, swpProduct.geom?.data, { color: functionalFillColor, height: fillZHeight }, flatGeometries);
    }

    if (swpProduct instanceof SwpApartment) {
      addAppartmentLabel(matrix, swpProduct.geom?.data, swpProduct, labels);
    }

    if (swpProduct instanceof SwpRamp) {
      const transformedData = transformGeometry(matrix, swpProduct.geom?.data);
      const rampBoundary = transformedData.boundary.data.map((e) => e.data);
      const rampPolygon = getRampArrowPolygons(rampBoundary);

      if (rampPolygon) {
        rampPolygon.map((line) => strokes.push({
          boundary: line.map((element) => new THREE.Vector3(...element)),
          color: strokeColor || 'black',
          width: strokeWidth || 0.2,
        }));
      }
    } else if (stroke) {
      addStroke(matrix, swpProduct.geom?.data, { color: strokeColor, width: strokeWidth }, strokes);
    }

    swpProduct.childObjects?.forEach((child) => {
      prepareBuidingStoryToRender(child);
    });
  };
  const swpBuildingStory = getFloorByFloorNumber(swpBuilding, selectedStory);

  prepareBuidingStoryToRender(swpBuildingStory);

  if (!flatGeometries.length) {
    return null;
  }

  const renderAreas = () => (
    <mesh name="floor" geometry={mergedFlatGeometry}>
      {isAreaNotSelected
        ? <meshBasicMaterial name="solidMaterial" color="grey" transparent opacity={0.5} />
        : <meshPhongMaterial attach="material" vertexColors={THREE.VertexColors} />}
    </mesh>
  );

  const renderStrokes = () => (
    <group position={[0, 0, 20]}>
      {strokes.map((stroke) => (
        <mesh>
          <meshLine attach="geometry" vertices={stroke.boundary} />
          <meshLineMaterial attach="material" color={stroke.color} lineWidth={stroke.width} />
        </mesh>
      ))}
    </group>
  );

  const renderLabels = () => labels.map(({ string, pos }) => (
    <group position={[0, 0, 55]}>
      <Text
        position={[...pos, 1]}
        fontSize={0.6}
        letterSpacing={0.05}
        color="black"
        font="Roboto"
        outlineWidth={0.05}
        outlineColor="white"
      >
        {string}
      </Text>
    </group>

  ));

  const mergedFlatGeometry = BufferGeometryUtils.mergeBufferGeometries(flatGeometries);

  return (
    <>
      <group position={[0, 0, 150]}>
        {renderAreas()}
        {renderStrokes()}
        {renderLabels()}
      </group>
    </>

  );
};

BuildingStory.propTypes = {
  swpBuilding: PropTypes.object,
};

export default React.memo(BuildingStory);
