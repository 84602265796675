import React, { useEffect, useMemo } from 'react';
import { Form } from 'antd';
import styled from 'styled-components';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { handleOnChange } from 'utils/helpers/feasibilityReportHelpers';
import { getProfileByIdSelector, loadingSelector } from 'store/swappProfile';
import { useProfileResult } from 'store/swappProfile/selectors/swappSelectors';
import { feasibilityResultModel, useActiveProfileBuildingInfo } from 'utils/model/feasibilityResultModel';
import { ViewerUiPanelWrapper } from 'styles/commonComponents.styles';
import FeasibilityViewerContainer from 'utils/swappViewer/FeasibilityViewerContainer';
import { MODEL_ANALYSIS_TYPES } from 'constants/feasibilityConts';
import ViewerUiPanel from 'utils/swappViewer/components/ui/ViewerUiPanel';
import FeasibilityResultEnergyAnalysis from './FeasibilityResultEnergyAnalysis';
import GenerateEnergyAnalysis from './GenerateEnergyAnalysis';

const EnergyContainerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const EnergyContainer = () => {
  const locationData = parseLocationUrl(window.location);

  const profile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const isLoading = useSelector(loadingSelector);
  const dispatch = useDispatch();

  const formData = lodashGet(profile, 'profileData.feasibilityEnergyForm');

  const [form] = Form.useForm();
  const result = useProfileResult(profile);
  const buildingInfo = useActiveProfileBuildingInfo();

  const data = useMemo(() => feasibilityResultModel(result, isImperial, buildingInfo), [result, isImperial]);

  // set form data from BE
  // we added window.location.href to deps se when the user press back in the browser we make sure to load the data from formData
  useEffect(() => {
    if (profile) {
      if (!lodashIsEmpty(formData) && !isLoading) {
        form.setFieldsValue(formData);
      }
    }
  }, [formData, window.location.href]);

  const handleChange = () => {
    handleOnChange(dispatch, { profile, formInstance: form, isLoading, settingsType: 'feasibilityEnergy' });
  };

  return (
    <EnergyContainerWrapper>
      <ViewerUiPanelWrapper position="top-left" fitContent>
        <ViewerUiPanel width={600}>
          <div style={{ margin: '0 0 15px 15px', overflow: 'auto', height: '100%', paddingRight: '10px' }}>
            <Form name="feasibilityEnergyForm" form={form} onFieldsChange={handleChange}>
              <GenerateEnergyAnalysis profileId={locationData.profileId} form={form} handleChange={handleChange} formData={formData} />
              <FeasibilityResultEnergyAnalysis profile={profile} isImperial={isImperial} data={lodashGet(data, 'energyAnalysisData')} form={form} />
            </Form>
          </div>
        </ViewerUiPanel>
      </ViewerUiPanelWrapper>

      {/* ============ viewer ============ */}
      <FeasibilityViewerContainer
        result={result}
        isOrthographic={false}
        profileId={locationData.profileId}
        legendKey={MODEL_ANALYSIS_TYPES.AREA_TYPE}
      />
    </EnergyContainerWrapper>
  );
};

export default EnergyContainer;
