import React from 'react';
import { Route, Switch } from 'react-router';
import PropTypes from 'prop-types';
import { UI_AUTHORIZE_PATH } from '../constants/routes/ui';
import StudyPage from '../components/testFit/study/StudyPage';

const { studies } = UI_AUTHORIZE_PATH;

const TestFitPage = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:projectId/${studies}`} component={StudyPage} />
    </Switch>
  );
};

TestFitPage.propTypes = {
  match: PropTypes.object,
};

export default TestFitPage;
