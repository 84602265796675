import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedStory: null,
};

const buildingStoriesSlice = createSlice({
  name: 'buildingStories',
  initialState,
  reducers: {
    selectStory(state, action) {
      state.selectedStory = action.payload;
    },
  },
});

export const { selectStory } = buildingStoriesSlice.actions;
export default buildingStoriesSlice.reducer;

// =========== Selectors =========== //
export const selectedStorySelector = ({ buildingStories }) => buildingStories.selectedStory;
