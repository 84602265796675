import React from 'react';

// currentcolor
export default () => (
  <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 12.5H13.5M13.5 12.5L12 11M13.5 12.5L12 14" stroke="currentcolor" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 9.125H9M1 12.625H9M1 16.125H9M1 6H9V19.5H1V6Z" stroke="currentcolor" strokeWidth="0.75" />
    <path d="M15 9.125H23M15 12.625H23M15 16.125H23M15 6H23V19.5H15V6Z" stroke="currentcolor" strokeWidth="0.75" />
  </svg>
);
