import React from 'react';

// currentcolor
export default () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.375" y="16.235" width="19.25" height="4.53" stroke="currentcolor" strokeWidth="0.75" strokeDasharray="1.5 2" />
    <rect x="1.375" y="6.995" width="19.25" height="4.53" stroke="currentcolor" strokeWidth="0.75" strokeDasharray="1.5 2" />
    <rect x="1.375" y="2.375" width="19.25" height="4.53" stroke="currentcolor" strokeWidth="0.75" strokeDasharray="1.5 2" />
    <rect x="1.375" y="11.475" width="19.25" height="4.85" stroke="currentcolor" strokeWidth="0.75" />
  </svg>
);
