import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import lodashGet from 'lodash/get';
import lodashIncludes from 'lodash/includes';
import lodashIsEmpty from 'lodash/isEmpty';
import Icon, { LoadingOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { Carousel, message, Skeleton, Tooltip, Modal } from 'antd';
import { isSharedUrl, parseLocationUrl } from 'utils/helpers/navigationHelpers';
import ReportCardIndicator from 'components/feasibility/studies/result/report/ReportCardIndicator';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import { PROJECT_STAGES, PROFILE_STATUS } from 'constants/profileConsts';
import { DATE_OPTIONS } from 'constants/dateConsts';
import { WAITING_FOR_KEYS } from 'constants/feasibilityConts';
import {
  createNewProfileAction,
  deleteProfileAction,
  pollSwappProjectAction,
} from 'store/swappProfile/actions/swappProfileActions';
import ResultSplitViewUploadCard from 'components/common/ResultSplitViewUploadCard';
import FavoriteButton from './FavoriteButton';
import progressIcons from '../../styles/static/icons/feasibilityIcons/progressSteps';
import { ResultSplitViewCardWrapper, ResultCardData, ResultCardImage, LoadingWrapper, ScoreWrapper,
  ButtonWrapper, StyledSkeletonImage, DateWrapper, InfoIcon, StyledStep, StyledIcon, CardHeader,
  OptionsWrapper, OptionsItem, OptionsIcon, StyledPopover, StyledStepText, IconsWrapper, ResultCardImageWrapper,
} from './ResultSplitViewCard.styles';
import { activePollingProfileIdsSelector, siteSelector } from '../../store/swappProfile/selectors';
import ProfileRename from './ProfileRename';
import infoIcon from '../../styles/static/icons/comon/infoIcon';
import moreInfoIcon from '../../styles/static/icons/comon/moreInfoIcon';
import shareIcon from '../../styles/static/icons/comon/shareIcon';
import ShareButton from '../core/ShareButton';

const processSteps = [
  { label: 'FEASIBILITY_TARGET_FORM_CONTAINER_STEP_ONE', icon: progressIcons.calculatingBuildingRightsIcon },
  { label: 'FEASIBILITY_TARGET_FORM_CONTAINER_STEP_TWO', icon: progressIcons.arrangingApartmentsMixtureIcon },
  { label: 'FEASIBILITY_TARGET_FORM_CONTAINER_STEP_THREE', icon: progressIcons.checkingTypologiesIcon },
  { label: 'FEASIBILITY_TARGET_FORM_CONTAINER_STEP_FOUR', icon: progressIcons.placingOnSiteIcon },
  { label: 'FEASIBILITY_TARGET_FORM_CONTAINER_STEP_FIVE', icon: progressIcons.optimizingResultsIcon },
];

const reprocessSteps = [
  { label: 'FEASIBILITY_TARGET_FORM_SKY_STEP', icon: progressIcons.skyViewIcon },
  { label: 'FEASIBILITY_TARGET_FORM_SUN_STEP', icon: progressIcons.sunViewIcon },
  { label: 'FEASIBILITY_TARGET_FORM_VIEW_STEP', icon: progressIcons.viewIcon },
  { label: 'FEASIBILITY_TARGET_FORM_WIND_STEP', icon: progressIcons.windViewIcon },
  { label: 'FEASIBILITY_TARGET_FORM_PARKING_STEP', icon: progressIcons.parkingIcon },
];

const testFitSteps = [
  { label: 'FEASIBILITY_TARGET_FORM_REQUEST_GENERATED', icon: progressIcons.testFitIcon },
];

const testFitSelfServeSteps = [
  { label: 'FEASIBILITY_TARGET_FORM_CONTAINER_TF_STEP_ONE', icon: progressIcons.calculatingBuildingRightsIcon },
  { label: 'FEASIBILITY_TARGET_FORM_CONTAINER_TF_STEP_TWO', icon: progressIcons.arrangingApartmentsMixtureIcon },
  { label: 'FEASIBILITY_TARGET_FORM_CONTAINER_TF_STEP_THREE', icon: progressIcons.optimizingResultsIcon },
];

const ResultSplitViewCard = (props) => {
  const { body, score, profile, onClick, height, hideFavoriteButton, thumbnailURL } = props;
  const activePollingProfileIds = useSelector(activePollingProfileIdsSelector);
  const dispatch = useDispatch();
  const site = useSelector(siteSelector);
  const history = useHistory();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const locationData = parseLocationUrl(history.location);
  const isWaitingForAnalysis = lodashGet(profile, `result.waitingFor[${WAITING_FOR_KEYS.ANALYSIS}]`);
  const isLoadingCard = profile.loading || (lodashGet(profile, 'status') === PROFILE_STATUS.WAITING_FOR_DATA);
  const isUploadCard = profile.uploadCard;
  const isLoading = lodashIncludes(activePollingProfileIds, profile.id);
  const createdDate = new Date(profile.createdAt).toLocaleDateString('en-US', DATE_OPTIONS);
  const lastModifiedDate = new Date(profile.updatedAt).toLocaleDateString('en-US', DATE_OPTIONS);
  const hasMasses = !!profile.result?.singleOptionMassingOptions?.massing_options[0]?.masses?.length;

  useEffect(() => {
    if (!isLoading && lodashIncludes([PROFILE_STATUS.WAITING_FOR_DATA, PROFILE_STATUS.WAITING_FOR_EXTRA_DATA], lodashGet(profile, 'status')) && !isSharedUrl()) {
      dispatch(pollSwappProjectAction(locationData.projectId, profile.id, history));
    }
  }, [isLoading, profile]);

  const renderSkeletonImage = () => {
    let iconSet = [];

    if (locationData.stage === UI_AUTHORIZE_PATH.FEASIBILITY_STUDY) {
      iconSet = lodashGet(profile, 'status') === PROFILE_STATUS.WAITING_FOR_DATA ? reprocessSteps : processSteps;
    } else {
      iconSet = site?.id ? testFitSelfServeSteps : testFitSteps;
    }

    return (
      <Tooltip mouseEnterDelay={5} title={profile.id}>
        <StyledSkeletonImage>
          <Carousel autoplay dots={false}>
            {iconSet.map((item, index) => (
              <StyledStep key={index}>
                <StyledIcon component={item.icon} />
                <StyledStepText>{T.translate(item.label)}</StyledStepText>
              </StyledStep>
            ))}
          </Carousel>
        </StyledSkeletonImage>
      </Tooltip>
    );
  };

  const renderImage = () => {
    if (isLoadingCard) {
      return renderSkeletonImage();
    }
    const imageSrc = thumbnailURL || lodashGet(profile, 'result.thumbnailURL');
    return (
      <ResultCardImageWrapper>
        {imageSrc
          ? <ResultCardImage src={imageSrc} />
          : <StyledIcon component={progressIcons.emptyCardIcon} />}
      </ResultCardImageWrapper>
    );
  };

  const renderHeader = () => (
    <CardHeader onClick={(e) => e.stopPropagation()}>
      <ProfileRename profileId={profile.id} width={220} borderless small disabled={isLoadingCard || hideFavoriteButton} />
      <IconsWrapper>
        {renderCardInfo()}
        {renderOptions()}
      </IconsWrapper>
    </CardHeader>
  );

  const renderCardInfo = () => {
    if (isLoadingCard) {
      return null;
    }

    return (
      <StyledPopover
        trigger="click"
        content={(
          <>
            <DateWrapper>{`${T.translate('SWAPP_CART_LIST_CREATED')}: ${createdDate}`}</DateWrapper>
            <DateWrapper>{`${T.translate('SWAPP_CART_LIST_LAST_MODIFIED')}: ${lastModifiedDate}`}</DateWrapper>
          </>
            )}
      >
        <InfoIcon component={infoIcon} />
      </StyledPopover>
    );
  };

  const renderOptions = () => {
    if (isLoadingCard || hideFavoriteButton || isSharedUrl()) {
      return null;
    }

    const handleDuplicate = () => {
      setIsPopoverOpen(false);
      dispatch(createNewProfileAction({ projectId: locationData.projectId, profileId: profile.id, stage: PROJECT_STAGES[locationData.stage], isDuplicate: true }))
        .catch((error) => message.error(error));
    };

    const deleteLayoutModal = () => {
      Modal.confirm({
        title: T.translate('SWAPP_CART_LIST_MODAL_DELETE_PROFILE', { context: locationData.stage === UI_AUTHORIZE_PATH.TEST_FIT ? 'Layout' : 'Option' }),
        onOk() {
          dispatch(deleteProfileAction(profile.id))
            .catch((error) => message.error(error));
        },
      });
    };

    const options = [
      { key: 'DUPLICATE', name: 'DUPLICATE', icon: <CopyOutlined />, action: () => handleDuplicate() },
      { key: 'DELETE', name: 'DELETE', icon: <DeleteOutlined />, action: () => deleteLayoutModal() },
    ];

    const renderOptionItem = (option) => (
      <OptionsItem key={option.key} onClick={option.action && option.action}>
        <OptionsIcon>{option.icon}</OptionsIcon>
        {T.translate(option.name)}
      </OptionsItem>
    );

    const renderedOptions = options.map((option) => renderOptionItem(option));
    renderedOptions.push(<ShareButton small profileId={profile.id}>{renderOptionItem({ key: 'SHARE', name: 'SHARE', icon: <Icon component={shareIcon} /> })}</ShareButton>);

    return (
      <OptionsWrapper>
        <StyledPopover
          placement="bottomLeft"
          visible={isPopoverOpen}
          onVisibleChange={() => setIsPopoverOpen(!isPopoverOpen)}
          content={renderedOptions}
          trigger="click"
        >
          <InfoIcon component={moreInfoIcon} />
        </StyledPopover>
      </OptionsWrapper>
    );
  };

  if (isUploadCard) {
    return <ResultSplitViewUploadCard height={height} />;
  }

  return (
    <ResultSplitViewCardWrapper key={profile?.id} height={height} isLoading={isLoadingCard} onClick={() => profile.status !== PROFILE_STATUS.WAITING_FOR_DATA && onClick(profile.id)}>
      {/* ============ left buttons ============ */}
      <ButtonWrapper>
        {isLoadingCard || hideFavoriteButton ? null : <FavoriteButton profileId={Number(profile.id)} />}
        {score ? <ScoreWrapper>{score.toFixed(1)}</ScoreWrapper> : null}
        <ReportCardIndicator profileId={Number(profile.id)} hidden={lodashIsEmpty(lodashGet(profile, 'result.reportData'))} />
      </ButtonWrapper>

      {/* ============ right buttons ============ */}
      <ButtonWrapper isRight>
        {isWaitingForAnalysis && hasMasses && <LoadingWrapper><LoadingOutlined spin /></LoadingWrapper>}
      </ButtonWrapper>

      {/* ============ image ============ */}
      {renderImage()}

      {/* ============ card body ============ */}
      <ResultCardData>
        {/* ============ header ============ */}
        {renderHeader()}

        {/* ============ card Data ============ */}
        {body && !isLoadingCard && body}
        {isLoadingCard && <Skeleton active={site?.id} paragraph={{ rows: Math.floor((height - 260) / 36) }} />}
      </ResultCardData>
    </ResultSplitViewCardWrapper>
  );
};

ResultSplitViewCard.propTypes = {
  profile: PropTypes.object,
  height: PropTypes.number,
  onClick: PropTypes.func,
  thumbnailURL: PropTypes.string,
  hideFavoriteButton: PropTypes.bool,
  score: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  body: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
};

export default ResultSplitViewCard;
