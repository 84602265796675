import React from 'react';

// currentcolor
export default () => (
  <svg width="32" height="32" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="9.5" y="10.5" width="19" height="16" rx="1.5" stroke="currentcolor" />
    <g clipPath="url(#clip0)">
      <path d="M21.3614 22.2933H17.0068" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.1846 22.1117V14.4911" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.8134 15.852V14.4005H15.5557V15.852" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect x="12.3887" y="12.6406" width="11.6123" height="11.6123" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
