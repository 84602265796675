import React from 'react';

// currentcolor
export default () => (
  <svg width="32" height="32" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29.2257 10.4548H22.9998V9.38008C22.9998 8.30535 22.1455 7.45107 21.0708 7.45107L17.6547 7.45212C16.58 7.45212 15.7257 8.30643 15.7257 9.38113V10.4559L9.77517 10.4548C9.47247 10.4548 9.22461 10.7027 9.22461 11.0054C9.22461 11.3081 9.47246 11.5559 9.77517 11.5559H11.1526L12.3644 27.5356C12.4467 28.6652 13.4117 29.5743 14.5687 29.5743H24.1843C25.3413 29.5743 26.3052 28.6926 26.3886 27.5356L27.6004 11.5559H29.2531C29.5558 11.5559 29.8037 11.3081 29.8037 11.0054C29.8047 10.7027 29.5294 10.4548 29.2257 10.4548ZM16.8278 9.38008C16.8278 8.91179 17.1864 8.55321 17.6547 8.55321H21.0983C21.5666 8.55321 21.9252 8.91179 21.9252 9.38008V10.4548H16.8278V9.38008ZM25.2591 27.4269C25.2043 28.0059 24.736 28.4468 24.157 28.4468L14.5687 28.4457C13.9897 28.4457 13.5214 28.0048 13.4666 27.4258L12.2548 11.5285H26.4983L25.2591 27.4269Z" fill="currentcolor" />
    <path d="M19.3621 14.3952C19.0594 14.3952 18.8115 14.6431 18.8115 14.9458V25.1118C18.8115 25.4145 19.0594 25.6624 19.3621 25.6624C19.6648 25.6624 19.9126 25.4145 19.9126 25.1118L19.9137 14.9458C19.9137 14.6431 19.6658 14.3952 19.3621 14.3952H19.3621Z" fill="currentcolor" />
    <path d="M22.3935 14.9182L21.8429 25.0842C21.8155 25.3869 22.0633 25.6348 22.366 25.6632H22.3934C22.6961 25.6632 22.9166 25.4428 22.944 25.1401L23.4946 14.9741C23.522 14.6714 23.2741 14.4235 22.9714 14.3951C22.6687 14.3951 22.4209 14.6155 22.3935 14.9182H22.3935Z" fill="currentcolor" />
    <path d="M15.7536 14.3952C15.4509 14.4226 15.2305 14.6705 15.2305 14.9742L15.781 25.1403C15.8084 25.443 16.0289 25.6634 16.3316 25.6634H16.359C16.6617 25.636 16.8821 25.3881 16.8821 25.0844L16.3316 14.9183C16.3052 14.6156 16.0574 14.3952 15.7536 14.3952H15.7536Z" fill="currentcolor" />
  </svg>
);
