import React from 'react';

export default () => (
  <svg width="95" height="95" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M88.1194 11C88.619 11.0932 89.0105 11.5779 89.0031 12.0813V93.7136C89.0031 94.6793 88.5183 95 87.575 95H6.08875C5.51826 95 5.00372 94.4854 5 93.9187V35.2593C5 34.6926 5.51829 34.178 6.08875 34.178H27.5544V12.0852C27.5544 11.5185 28.0727 11.0039 28.6432 11.0039H88.1196L88.1194 11ZM86.8292 13.1663H29.7324V92.837H45.322V74.8048C45.322 74.238 45.8403 73.7235 46.4107 73.7235H70.1549C70.7254 73.7235 71.2399 74.238 71.2437 74.8048V92.837H86.8332L86.8292 13.1663ZM27.558 36.3397H7.17666V92.837H27.558V36.3397ZM69.0652 75.8863H47.4945V92.837H69.0652V75.8863Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M49.1467 38.4799C49.7471 38.4873 50.228 38.9795 50.2169 39.5761V45.6389C50.2243 45.9298 50.1125 46.2094 49.9074 46.4182C49.7023 46.627 49.4227 46.7426 49.1318 46.7426C48.8373 46.7426 48.5576 46.627 48.3563 46.4182C48.1512 46.2094 48.0393 45.9298 48.0431 45.6389V39.5761C48.0393 39.2852 48.1549 39.0018 48.3637 38.7968C48.5725 38.588 48.8559 38.4761 49.1467 38.4799Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M49.1467 22.3314C49.7471 22.3389 50.228 22.8311 50.2169 23.4276V29.4905C50.2243 29.7813 50.1125 30.061 49.9074 30.2698C49.7023 30.4786 49.4227 30.5942 49.1318 30.5942C48.8373 30.5942 48.5576 30.4786 48.3563 30.2698C48.1512 30.061 48.0393 29.7813 48.0431 29.4905V23.4276C48.0393 23.1368 48.1549 22.8534 48.3637 22.6483C48.5725 22.4395 48.8559 22.3277 49.1467 22.3314Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M49.1467 54.6298C49.7471 54.6372 50.228 55.1294 50.2169 55.726V61.7888C50.2243 62.0797 50.1125 62.3593 49.9074 62.5681C49.7023 62.7769 49.4227 62.8925 49.1318 62.8925C48.8373 62.8925 48.5576 62.7769 48.3563 62.5681C48.1512 62.3593 48.0393 62.0797 48.0431 61.7888V55.726C48.0393 55.4351 48.1549 55.1517 48.3637 54.9467C48.5725 54.7379 48.8559 54.626 49.1467 54.6298Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M39.9449 38.4799C40.5452 38.4873 41.0224 38.9795 41.015 39.5761V45.6389C41.0187 45.9298 40.9069 46.2094 40.7055 46.4182C40.5004 46.627 40.2208 46.7426 39.9262 46.7426C39.6354 46.7426 39.3557 46.627 39.1507 46.4182C38.9456 46.2094 38.8337 45.9298 38.8412 45.6389V39.5761C38.8374 39.2852 38.9493 39.0018 39.1581 38.7968C39.3669 38.588 39.6503 38.4761 39.9449 38.4799Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M39.9449 22.3314C40.5452 22.3389 41.0224 22.8311 41.015 23.4276V29.4905C41.0187 29.7813 40.9069 30.061 40.7055 30.2698C40.5004 30.4786 40.2208 30.5942 39.9262 30.5942C39.6354 30.5942 39.3557 30.4786 39.1507 30.2698C38.9456 30.061 38.8337 29.7813 38.8412 29.4905V23.4276C38.8374 23.1368 38.9493 22.8534 39.1581 22.6483C39.3669 22.4395 39.6503 22.3277 39.9449 22.3314Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M39.9449 54.6298C40.5452 54.6372 41.0224 55.1294 41.015 55.726V61.7888C41.0187 62.0797 40.9069 62.3593 40.7055 62.5681C40.5004 62.7769 40.2208 62.8925 39.9262 62.8925C39.6354 62.8925 39.3557 62.7769 39.1507 62.5681C38.9456 62.3593 38.8337 62.0797 38.8412 61.7888V55.726C38.8374 55.4351 38.9493 55.1517 39.1581 54.9467C39.3669 54.7379 39.6503 54.626 39.9449 54.6298Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M58.3169 38.4799C58.9172 38.4873 59.3945 38.9795 59.3871 39.5761V45.6389C59.3908 45.9298 59.2789 46.2094 59.0739 46.4182C58.8725 46.627 58.5928 46.7426 58.2983 46.7426C58.0074 46.7426 57.7278 46.627 57.5227 46.4182C57.3177 46.2094 57.2058 45.9298 57.2132 45.6389V39.5761C57.2095 39.2852 57.3214 39.0018 57.5302 38.7968C57.739 38.588 58.0224 38.4761 58.3169 38.4799Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M58.3169 22.3314C58.9172 22.3389 59.3945 22.8311 59.3871 23.4276V29.4905C59.3908 29.7813 59.2789 30.061 59.0739 30.2698C58.8725 30.4786 58.5928 30.5942 58.2983 30.5942C58.0074 30.5942 57.7278 30.4786 57.5227 30.2698C57.3177 30.061 57.2058 29.7813 57.2132 29.4905V23.4276C57.2095 23.1368 57.3214 22.8534 57.5302 22.6483C57.739 22.4395 58.0224 22.3277 58.3169 22.3314Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M58.3169 54.6298C58.9172 54.6372 59.3945 55.1294 59.3871 55.726V61.7888C59.3908 62.0797 59.2789 62.3593 59.0739 62.5681C58.8725 62.7769 58.5928 62.8925 58.2983 62.8925C58.0074 62.8925 57.7278 62.7769 57.5227 62.5681C57.3177 62.3593 57.2058 62.0797 57.2132 61.7888V55.726C57.2095 55.4351 57.3214 55.1517 57.5302 54.9467C57.739 54.7379 58.0224 54.626 58.3169 54.6298Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M67.4817 38.4799C68.082 38.4873 68.5593 38.9795 68.5519 39.5761V45.6389C68.5593 45.9298 68.4474 46.2094 68.2424 46.4182C68.0373 46.627 67.7576 46.7426 67.4631 46.7426C67.1722 46.7426 66.8926 46.627 66.6875 46.4182C66.4824 46.2094 66.3706 45.9298 66.378 45.6389V39.5761C66.3743 39.2852 66.4899 39.0018 66.695 38.7968C66.9038 38.588 67.1872 38.4761 67.4817 38.4799Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M67.4817 22.3314C68.082 22.3389 68.5593 22.8311 68.5519 23.4276V29.4905C68.5593 29.7813 68.4474 30.061 68.2424 30.2698C68.0373 30.4786 67.7576 30.5942 67.4631 30.5942C67.1722 30.5942 66.8926 30.4786 66.6875 30.2698C66.4824 30.061 66.3706 29.7813 66.378 29.4905V23.4276C66.3743 23.1368 66.4899 22.8534 66.695 22.6483C66.9038 22.4395 67.1872 22.3277 67.4817 22.3314Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M67.4817 54.6298C68.082 54.6372 68.5593 55.1294 68.5519 55.726V61.7888C68.5593 62.0797 68.4474 62.3593 68.2424 62.5681C68.0373 62.7769 67.7576 62.8925 67.4631 62.8925C67.1722 62.8925 66.8926 62.7769 66.6875 62.5681C66.4824 62.3593 66.3706 62.0797 66.378 61.7888V55.726C66.3743 55.4351 66.4899 55.1517 66.695 54.9467C66.9038 54.7379 67.1872 54.626 67.4817 54.6298Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M76.6463 38.4799C77.2466 38.4873 77.7276 38.9795 77.7164 39.5761V45.6389C77.7239 45.9298 77.612 46.2094 77.4069 46.4182C77.2018 46.627 76.9222 46.7426 76.6313 46.7426C76.3368 46.7426 76.0571 46.627 75.8521 46.4182C75.6507 46.2094 75.5388 45.9298 75.5426 45.6389V39.5761C75.5388 39.2852 75.6544 39.0018 75.8632 38.7968C76.072 38.588 76.3517 38.4761 76.6463 38.4799Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M76.6463 22.3314C77.2466 22.3389 77.7276 22.8311 77.7164 23.4276V29.4905C77.7239 29.7813 77.612 30.061 77.4069 30.2698C77.2018 30.4786 76.9222 30.5942 76.6313 30.5942C76.3368 30.5942 76.0571 30.4786 75.8521 30.2698C75.6507 30.061 75.5388 29.7813 75.5426 29.4905V23.4276C75.5388 23.1368 75.6544 22.8534 75.8632 22.6483C76.072 22.4395 76.3517 22.3277 76.6463 22.3314Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M76.6463 54.6298C77.2466 54.6372 77.7276 55.1294 77.7164 55.726V61.7888C77.7239 62.0797 77.612 62.3593 77.4069 62.5681C77.2018 62.7769 76.9222 62.8925 76.6313 62.8925C76.3368 62.8925 76.0571 62.7769 75.8521 62.5681C75.6507 62.3593 75.5388 62.0797 75.5426 61.7888V55.726C75.5388 55.4351 75.6544 55.1517 75.8632 54.9467C76.072 54.7379 76.3517 54.626 76.6463 54.6298Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.5989 59.9193C13.1992 59.9305 13.6765 60.4227 13.669 61.0192V67.0784C13.6765 67.3692 13.5646 67.6526 13.3595 67.8614C13.1545 68.0665 12.8748 68.1858 12.584 68.1858C12.2894 68.1858 12.0098 68.0665 11.8047 67.8614C11.6033 67.6526 11.4915 67.3692 11.4952 67.0784V61.0192C11.4915 60.7247 11.6071 60.445 11.8159 60.2362C12.0209 60.0311 12.3043 59.9156 12.5989 59.9193Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.5989 43.7709C13.1992 43.7821 13.6765 44.2742 13.669 44.8708V50.9299C13.6765 51.2208 13.5646 51.5041 13.3595 51.7129C13.1545 51.918 12.8748 52.0373 12.584 52.0373C12.2894 52.0373 12.0098 51.918 11.8047 51.7129C11.6033 51.5041 11.4915 51.2208 11.4952 50.9299V44.8708C11.4915 44.5762 11.6071 44.2966 11.8159 44.0878C12.0209 43.8827 12.3043 43.7671 12.5989 43.7709Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.5989 76.0699C13.1992 76.0811 13.6765 76.5733 13.669 77.1699V83.229C13.6765 83.5198 13.5646 83.8032 13.3595 84.012C13.1545 84.2171 12.8748 84.3364 12.584 84.3364C12.2894 84.3364 12.0098 84.2171 11.8047 84.012C11.6033 83.8032 11.4915 83.5198 11.4952 83.229V77.1699C11.4915 76.8753 11.6071 76.5957 11.8159 76.3868C12.0209 76.1818 12.3043 76.0662 12.5989 76.0699Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.7653 59.9193C22.3657 59.9305 22.8466 60.4227 22.8355 61.0192V67.0784C22.8429 67.3692 22.7311 67.6526 22.526 67.8614C22.3209 68.0665 22.0413 68.1858 21.7504 68.1858C21.4596 68.1858 21.1762 68.0665 20.9749 67.8614C20.7698 67.6526 20.6579 67.3692 20.6654 67.0784V61.0192C20.6579 60.7247 20.7735 60.445 20.9823 60.2362C21.1911 60.0311 21.4745 59.9156 21.7653 59.9193Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.7653 43.7709C22.3657 43.7821 22.8466 44.2742 22.8355 44.8708V50.9299C22.8429 51.2208 22.7311 51.5041 22.526 51.7129C22.3209 51.918 22.0413 52.0373 21.7504 52.0373C21.4596 52.0373 21.1762 51.918 20.9749 51.7129C20.7698 51.5041 20.6579 51.2208 20.6654 50.9299V44.8708C20.6579 44.5762 20.7735 44.2966 20.9823 44.0878C21.1911 43.8827 21.4745 43.7671 21.7653 43.7709Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.7653 76.0699C22.3657 76.0811 22.8466 76.5733 22.8355 77.1699V83.229C22.8429 83.5198 22.7311 83.8032 22.526 84.012C22.3209 84.2171 22.0413 84.3364 21.7504 84.3364C21.4596 84.3364 21.1762 84.2171 20.9749 84.012C20.7698 83.8032 20.6579 83.5198 20.6654 83.229V77.1699C20.6579 76.8753 20.7735 76.5957 20.9823 76.3868C21.1911 76.1818 21.4745 76.0662 21.7653 76.0699Z" fill="currentcolor" />
  </svg>
);
