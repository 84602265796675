import React from 'react';
import PropTypes from 'prop-types';
import MeshLine from './MeshLine';
import { getRampArrowPolygons, getTwoWayRampArrowsPolygons } from '../../algorithms/geometryBuilders';
import { getBuildingStoriesData, getMassStartingHeight, getStoryHeight, getStoryParkingUnits, getStoryEnvelope } from '../../model/feasibilityDataExtractors';

const UndergroundParkingMass = (props) => {
  const { buildingData } = props;
  const rampsPolygons = [];
  const { stories } = getBuildingStoriesData(buildingData);
  const heightOffset = getMassStartingHeight(buildingData);
  const sumHeights = stories.reduce((sum, story) => sum + getStoryHeight(story), 0);

  const parkingUnits = getStoryParkingUnits(stories.slice(-1)[0]);
  parkingUnits.forEach((parkingUnit) => {
    if (parkingUnit.type === 'ramp') {
      rampsPolygons.push(...getRampArrowPolygons(parkingUnit.polygon.boundary));
    } else if (parkingUnit.type === 'ramp') {
      rampsPolygons.push(...getTwoWayRampArrowsPolygons(parkingUnit.polygon.boundary));
    }
  });

  return (
    <group position={[0, 0, (heightOffset + sumHeights)]}>
      <MeshLine key={123} vertices={getStoryEnvelope(stories[0])} height={0.1} isDashed />
      {rampsPolygons.map((rampPolygon, index) => (
        <group key={index}>
          <MeshLine key={index} vertices={rampPolygon} height={0.1} />
        </group>
      ))}
    </group>
  );
};

UndergroundParkingMass.propTypes = {
  buildingData: PropTypes.object,
};

export default UndergroundParkingMass;
