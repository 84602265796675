import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import lodashGet from 'lodash/get';
import { Tooltip } from 'antd';
import { swappProfileResultsSelector } from 'store/swappProfile';
import { currentThemeSelector, getUnitSystemByProjectIdSelector } from 'store/userSettings';

export const RsfRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray_03};
  margin-bottom: 3px;
  padding-bottom: 3px;
  line-height: normal;
  font-size: ${({ theme }) => theme.font.size.medium};

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }
`;

export const RowName = styled.div`
  display: flex;
`;

export const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
  line-height: normal;
`;

export const ColorPill = styled.div`
  height: 20px;
  width: 5px;
  border-radius: ${({ theme }) => theme.other.borderRadius};
  background-color: ${({ color }) => color};
  margin-right: 5px;
`;

const Cell = styled.div`
  min-width: 50px;
  text-align: right;
  color: ${({ theme, highlight }) => (highlight && theme.colors.success)}
`;

const EditorRsfPanel = (props) => {
  const { profileId } = props;
  const match = useRouteMatch();
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));
  const swappProfileResults = useSelector((state) => swappProfileResultsSelector(state, profileId || match.params.profileId, isImperial));
  const currentTheme = useSelector(currentThemeSelector);
  const rsfData = lodashGet(swappProfileResults, 'RSF.tableData');

  const sectionArray = useMemo(() => [
    { key: 'LSF', label: 'LSF:RSF', color: currentTheme.colors.layers.LSF.MAIN },
    { key: 'PSF', label: 'PSF:RSF', color: currentTheme.colors.layers.PSF.MAIN },
    { key: 'ASF', label: 'ASF:RSF', color: currentTheme.colors.layers.ASF.MAIN },
    { key: 'FSF', label: 'FSF:RSF', color: currentTheme.colors.layers.FSF.MAIN },
  ], [currentTheme]);

  if (!rsfData) {
    return null;
  }

  return sectionArray.map((section) => {
    const currentItem = rsfData.find((item) => item.name === section.label);
    return (
      <RsfRow key={section.key}>
        <RowName>
          <ColorPill color={section.color} />
          <Title><Tooltip mouseEnterDelay={0.5} title={T.translate(section.label)}>{T.translate(section.label)}</Tooltip></Title>
        </RowName>
        <Cell highlight={lodashGet(currentItem, 'modelRatio') > lodashGet(currentItem, 'targetRatio')}>{lodashGet(currentItem, 'modelRatio', '0%')}</Cell>
        <Cell>{`${lodashGet(currentItem, 'modelArea', 0)} ${T.translate(isImperial ? 'SQF' : 'SQM')}`}</Cell>
      </RsfRow>
    );
  });
};

EditorRsfPanel.propTypes = {
  profileId: PropTypes.number,
};

export default EditorRsfPanel;
