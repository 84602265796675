import React from 'react';
import PropTypes from 'prop-types';
// import T from 'i18n-react';
import { FormItem } from './ProjectInfoForm.styles';
import { StyledInput } from '../../../styles/commonComponents.styles';

const InputFormItem = ({ onChange, placeholder, label, name, requiredMessage, type, onFocus }) => (
  <FormItem
    name={name}
    label={label}
    rules={requiredMessage && [
      {
        required: !!requiredMessage,
        message: requiredMessage,
      },
    ]}
    style={{ marginBottom: '14px' }}
  >
    <StyledInput
      type={type || undefined}
      onChange={(e) => onChange(e.target.value)}
      onFocus={onFocus}
      placeholder={placeholder}
    />
  </FormItem>
);

InputFormItem.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  requiredMessage: PropTypes.string,
  type: PropTypes.any,
};

export default InputFormItem;
