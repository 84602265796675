import React from 'react';

// currentcolor
export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.57143 20C3.94025 20 3.42857 19.4834 3.42857 18.8462V16.5385H1.14286C0.511675 16.5385 0 16.0219 0 15.3846V6.15385C0 5.51659 0.511675 5 1.14286 5H19.4286C20.0598 5 20.5714 5.51659 20.5714 6.15385V8.46154H22.8571C23.4883 8.46154 24 8.97813 24 9.61538V18.8462C24 19.4834 23.4883 20 22.8571 20H4.57143ZM4.28571 18.8462C4.28571 19.0055 4.41363 19.1346 4.57143 19.1346H22.8571C23.0149 19.1346 23.1429 19.0055 23.1429 18.8462V9.61538C23.1429 9.45607 23.0149 9.32692 22.8571 9.32692H20.5714V10.4808C20.7292 10.4808 20.8571 10.6099 20.8571 10.7692V11.5459H21.7143C21.8721 11.5459 22 11.675 22 11.8343V16.6272C22 16.7865 21.8721 16.9157 21.7143 16.9157H20.8571V17.6923C20.8571 17.8516 20.7292 17.9808 20.5714 17.9808H6.85714C6.69935 17.9808 6.57143 17.8516 6.57143 17.6923V16.9157H5.71429C5.55649 16.9157 5.42857 16.7865 5.42857 16.6272V16.5385H4.28571V18.8462ZM7.14286 16.6272V17.4038H20.2857V16.6272C20.2857 16.4679 20.4136 16.3388 20.5714 16.3388H21.4286V12.1228H20.5714V15.3846C20.5714 16.0219 20.0598 16.5385 19.4286 16.5385H7.12908C7.13802 16.5664 7.14286 16.5963 7.14286 16.6272ZM19.4286 5.86538H1.14286C0.985062 5.86538 0.857143 5.99453 0.857143 6.15385V15.3846C0.857143 15.5439 0.985062 15.6731 1.14286 15.6731H19.4286C19.5864 15.6731 19.7143 15.5439 19.7143 15.3846V6.15385C19.7143 5.99453 19.5864 5.86538 19.4286 5.86538Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3.42857 7.01923C3.27078 7.01923 3.14286 7.14838 3.14286 7.30769V8.08432H2.28571C2.12792 8.08432 2 8.21347 2 8.37278V13.1657C2 13.325 2.12792 13.4541 2.28571 13.4541H3.14286V14.2308C3.14286 14.3901 3.27078 14.5192 3.42857 14.5192H17.1429C17.3007 14.5192 17.4286 14.3901 17.4286 14.2308V13.4541H18.2857C18.4435 13.4541 18.5714 13.325 18.5714 13.1657V8.46154V8.37278C18.5714 8.21347 18.4435 8.08432 18.2857 8.08432H17.4286V7.30769C17.4286 7.14838 17.3007 7.01923 17.1429 7.01923H3.42857ZM3.71429 7.59615V8.37278C3.71429 8.53209 3.58637 8.66124 3.42857 8.66124H2.57143V12.8772H3.42857C3.58637 12.8772 3.71429 13.0064 3.71429 13.1657V13.9423H16.8571V13.1657C16.8571 13.0064 16.9851 12.8772 17.1429 12.8772H18V8.66124H17.1429C17.0157 8.66124 16.908 8.57742 16.8709 8.46154C16.862 8.43358 16.8571 8.40375 16.8571 8.37278V7.59615H3.71429Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.01768 10.4808C8.00601 10.5753 8 10.6715 8 10.7692C8 10.8669 8.00601 10.9632 8.01768 11.0577C8.15828 12.1961 9.12011 13.0769 10.2857 13.0769C11.4513 13.0769 12.4131 12.1961 12.5537 11.0577C12.5654 10.9632 12.5714 10.8669 12.5714 10.7692C12.5714 10.6715 12.5654 10.5753 12.5537 10.4808C12.5002 10.0474 12.3276 9.65128 12.0701 9.32692C11.6512 8.79935 11.0075 8.46154 10.2857 8.46154C9.56389 8.46154 8.92021 8.79935 8.50132 9.32692C8.24379 9.65128 8.07121 10.0474 8.01768 10.4808ZM8.57143 10.7692C8.57143 10.6709 8.57954 10.5746 8.59513 10.4808C8.67503 9.99996 8.95127 9.58637 9.33777 9.32692C9.60929 9.14466 9.93522 9.03846 10.2857 9.03846C10.6362 9.03846 10.9621 9.14466 11.2337 9.32692C11.6202 9.58637 11.8964 9.99996 11.9763 10.4808C11.9919 10.5746 12 10.6709 12 10.7692C12 10.8675 11.9919 10.9639 11.9763 11.0577C11.8403 11.8763 11.1351 12.5 10.2857 12.5C9.43629 12.5 8.73115 11.8763 8.59513 11.0577C8.57954 10.9639 8.57143 10.8675 8.57143 10.7692Z" fill="currentcolor" />
  </svg>
);
