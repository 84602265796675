import React, { useMemo, Suspense } from 'react';
import PropTypes from 'prop-types';
import { useGLTF } from '@react-three/drei';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIncludes from 'lodash/includes';
import lodashFlattenDeep from 'lodash/flattenDeep';
import CustomMesh from './CustomMesh';
import { getRenderPropsByName } from '../helpers/FeasibilityRenderProps';

const yUpMatrix = [
  1, 0, 0, 0,
  0, 0, -1, 0,
  0, 1, 0, 0,
  0, 0, 0, 1,
];

const zUpMatrix = [
  1, 0, 0, 0,
  0, 1, 0, 0,
  0, 0, 1, 0,
  0, 0, 0, 1,
];

const EnvMesh = (props) => {
  const { url, hideSurroundingBuildings, castReceiveShadow, onClick } = props;
  const obj = useGLTF(url, true);

  const generator = lodashGet(obj, 'asset.generator');
  const isTrimesh = generator.includes('trimesh');
  const isSwappEnvAxis = generator === 'ShapeDiverGltfV2Writer' || generator.includes('Blender') || isTrimesh;

  const scene = useMemo(() => {
    if (isTrimesh) {
      return obj.scene.clone(true).children[0];
    }
    return obj.scene.clone(true);
  }, [obj]);

  const mainGroupProps = {
    matrix: isSwappEnvAxis ? yUpMatrix : zUpMatrix, // mainGroupWorldMatrix
    matrixAutoUpdate: false,
    matrixWorldNeedsUpdate: false,
    onClick,
    name: 'Environment',
  };

  const getOldFormatMesh = (currentObject) => {
    if (lodashIsEmpty(lodashGet(currentObject, 'children'))) {
      return;
    }
    return currentObject.children.map((child) => {
      if (child.type === 'Group' || child.type === 'Object3D') {
        return getOldFormatMesh(child);
      }
      if (child.type === 'Mesh') {
        return child;
      }
    });
  };

  const renderScene = () => {
    if (isSwappEnvAxis) {
      const allMeshes = lodashFlattenDeep((lodashGet(scene, 'children', []).map((item) => {
        if (item.type === 'Mesh') {
          return item;
        }
        if (item.type === 'Group') {
          return item.children.map((mesh) => ({ ...mesh, rotation: item.rotation, quaternion: item.quaternion }));
        }
      })), 1).filter((e) => e);

      return (
        allMeshes.map((child) => {
          if (lodashIncludes(child.name, 'SurroundingBuildings') && hideSurroundingBuildings) {
            return null;
          }

          child.geometry.computeVertexNormals();

          const { materialProps, renderOrder } = getRenderPropsByName(child.name);
          const usePhongeMaterial = child.name === 'Terrain';

          return (
            <CustomMesh
              key={child.name}
              child={child}
              receiveShadow={castReceiveShadow}
              castShadow={castReceiveShadow}
              materialProps={materialProps}
              renderOrder={renderOrder}
              usePhongeMaterial={usePhongeMaterial}
            />
          );
        })
      );
    }

    return (
      lodashFlattenDeep(getOldFormatMesh(scene)).filter((e) => e).map((child, index) => ( // TODO - delete and not support this format
        <CustomMesh
          key={index}
          child={child}
          receiveShadow={castReceiveShadow}
          castShadow={castReceiveShadow}
        />
      ))
    );
  };

  return (
    <Suspense fallback={null}>
      <group {...mainGroupProps}>
        {renderScene()}
      </group>
    </Suspense>
  );
};

EnvMesh.propTypes = {
  url: PropTypes.string,
  hideSurroundingBuildings: PropTypes.bool,
  castReceiveShadow: PropTypes.bool,
  onClick: PropTypes.func,
};

export default EnvMesh;
