export default {
  // FeasibilityTargetFormContainer
  FEASIBILITY_TARGET_FORM_CONTAINER_BUILDING_RIGHTS: 'Building Design Targets',

  // FeasibilityBuildingRightsForm
  FEASIBILITY_BUILDING_RIGHTS_FORM_PERCENTAGE_TARGET: 'Percentage Target',

  // FeasibilityTargetFormContainer
  FEASIBILITY_TARGET_FORM_CONTAINER_MECHANICAL_ELECTRIC_PLUMBING: 'Building Services',
};
