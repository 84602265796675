import React from 'react';
import 'antd/dist/antd.css';
import { useDispatch, useSelector } from 'react-redux';
import T from 'i18n-react';
import lodashIncludes from 'lodash/includes';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { useComDidMount } from 'utils/hooks';
import { GlobalStyle } from 'styles/landing.styles';
import { isSharedUrl } from 'utils/helpers/navigationHelpers';
import { userSelector } from 'store/user';
import { currentThemeSelector, useOverridableSetting } from 'store/userSettings';
import EmptyMessage from 'components/common/EmptyMessage';
import { getUserProfileAction, authCheckedAction } from 'store/auth/actions/authActions';
import { isAuthCheckedSelector, isErrorSelector, isLoadingSelector } from './store/auth';
import { UnAuthorizedRoutes } from './routes';
import AuthorizeRoutes from './routes/authorizeRoutes';
import ShareRoutes from './routes/ShareRoutes';
import LoadingSpinner from './components/common/LoadingSpinner';
import NoSupport from './components/common/NoSupport';
import languages from './locale';

const App = () => {
  const languageKey = useOverridableSetting('languageKey', 'en');
  const currentTheme = useSelector(currentThemeSelector);
  const user = useSelector(userSelector);
  const isAuthChecked = useSelector(isAuthCheckedSelector);
  const isError = useSelector(isErrorSelector);
  const isLoading = useSelector(isLoadingSelector);
  const dispatch = useDispatch();

  const isAuthorized = !!user;

  useComDidMount(() => {
    T.setTexts(languages[languageKey]);
    const accessToken = localStorage.getItem('token');
    if (isAuthChecked) {
      return;
    }
    if (!accessToken || isError) {
      dispatch(authCheckedAction());
      return;
    }
    dispatch(getUserProfileAction());
  });

  const renderChunk = () => {
    if (isMobile) {
      return <NoSupport />;
    }

    if (isLoading) {
      return <LoadingSpinner />;
    }

    if (isSharedUrl() && !lodashIncludes(window.location.search, 'resetToken')) {
      return <ShareRoutes />;
    }

    return isAuthorized ? <AuthorizeRoutes /> : <UnAuthorizedRoutes />;
  };

  return (
    <ThemeProvider theme={currentTheme}>
      <ConfigProvider renderEmpty={() => <EmptyMessage />}>
        <GlobalStyle />
        {renderChunk()}
      </ConfigProvider>
    </ThemeProvider>
  );
};

export default App;
