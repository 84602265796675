import * as actionTypes from '../actions/actionTypes';

const initialState = {
  selectedRowKeys: [],
};

export const feasibilityReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_SELECTED_ROW_KEYS:
      return { ...state, selectedRowKeys: payload };
    case actionTypes.CLEAR_SELECTED_ROW_KEYS:
      return { ...state, selectedRowKeys: [] };
    default:
      return state;
  }
};
