import React from 'react';
import T from 'i18n-react';
import { SwappLogo, NoSupportWrapper, Text } from './NoSupport.Styles';
import { ReactComponent as SwappLogoIcon } from '../../styles/static/icons/comon/swappLogoWithName.svg';

const NoSupport = () => (
  <NoSupportWrapper>
    <SwappLogo component={SwappLogoIcon} />
    <Text>{T.translate('NO_MOBILE_SUPPORT')}</Text>
  </NoSupportWrapper>
);

export default NoSupport;
