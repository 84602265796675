/* eslint-disable camelcase */
// createNextState is immer's produce function: https://immerjs.github.io/immer/produce
import { createNextState } from '@reduxjs/toolkit';
import { lastTestFitResults } from 'store/swappProfile/selectors/swappSelectors';
import { geom } from 'jsts';
import { parseId } from './editOperationsHelpers';
import { pointsToJSTSMultiPolygon, operationDifferenceWithGeomCollection } from './jstsHelpers';

const getRoomWidth = (room, tube) => {
  let maxVal = Number.MIN_VALUE;
  let minVal = Number.MAX_VALUE;
  const jstsCoords = tube.principalSegment.map((v) => new geom.Coordinate(...v, 0));
  const lineSegment = new geom.LineSegment(...jstsCoords);
  for (let i = 0; i < room.points.length; i++) {
    const p = new geom.Coordinate(...room.points[i], 0);
    const pointProjected = lineSegment.project(p);
    const distToOrigin = jstsCoords[0].distance(pointProjected);
    if (distToOrigin > maxVal) {
      maxVal = distToOrigin;
    }
    if (distToOrigin < minVal) {
      minVal = distToOrigin;
    }
  }
  return maxVal - minVal;
};

const getRoomPoly = (currentX, width, tube) => {
  const jstsCoords = tube.principalSegment.map((v) => new geom.Coordinate(...v, 0));
  const lineSegment = new geom.LineSegment(...jstsCoords);
  const length = lineSegment.getLength();
  const firstPoint = lineSegment.pointAlong(currentX / length);
  firstPoint.z = 0;
  const secondPoint = lineSegment.pointAlong((currentX + width) / length);
  secondPoint.z = 0;
  const c = Math.cos(tube.tubeAngle);
  const s = Math.sin(tube.tubeAngle);
  const { depth } = tube.size;
  const fourthPoint = new geom.Coordinate(firstPoint.x - depth * s, firstPoint.y + depth * c, 0);
  const thirdPoint = new geom.Coordinate(secondPoint.x - depth * s, secondPoint.y + depth * c, 0);
  const jstsPolygon = new geom.GeometryFactory().createPolygon([firstPoint, secondPoint, thirdPoint, fourthPoint, firstPoint]);
  return jstsPolygon;
};

export const reorderRooms = (operationName, operationParameters, resultObject, extraParams) => {
  const { profileId } = extraParams;
  const { tube_id, rooms_order } = operationParameters;
  const { floor: floorId, tube: tubeId } = parseId(tube_id);
  const { multiBoundaryFile } = lastTestFitResults[profileId];
  const floor = multiBoundaryFile.floors[floorId];
  const tube = floor.tubes[tubeId];
  const tubeRooms = tube.rooms;

  let currentX = 0;
  const multiPolygon = pointsToJSTSMultiPolygon(floor.structures);

  const changedRooms = [];
  for (let i = 0; i < rooms_order.length; i++) {
    const currentRoom = tubeRooms[rooms_order[i]];
    const width = getRoomWidth(currentRoom, tube);
    const roomPolygon = getRoomPoly(currentX, width, tube);
    const cuttedRoomPolygon = operationDifferenceWithGeomCollection(roomPolygon, multiPolygon);
    const newRoom = structuredClone(currentRoom);
    newRoom.points = cuttedRoomPolygon.getBoundary().getCoordinates().map((coord) => [coord.x, coord.y]);
    newRoom.inner_points = newRoom.points;
    newRoom.localPreview = true;
    newRoom.department_id = newRoom.department_id || newRoom.departmentId;
    changedRooms.push(newRoom);
    currentX += width;
  }

  return createNextState(resultObject, (draft) => {
    for (let i = draft.multiBoundaryFile.floors[floorId].rooms.length - 1; i >= 0; i--) {
      const room = draft.multiBoundaryFile.floors[floorId].rooms[i];
      for (let j = 0; j < rooms_order.length; j++) {
        const currentRoom = tubeRooms[rooms_order[j]];
        if (JSON.stringify(currentRoom.points) === JSON.stringify(room.points)) {
          draft.multiBoundaryFile.floors[floorId].rooms.splice(i, 1, changedRooms[j]);
        }
      }
    }
  });
};
