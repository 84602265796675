import React, { useState, useEffect, useMemo } from 'react';
import T from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import lodashSum from 'lodash/sum';
import { Radio, message } from 'antd';
import lodashInRange from 'lodash/inRange';
import lodashIsNumber from 'lodash/isNumber';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsEqual from 'lodash/isEqual';
import lodashGet from 'lodash/get';
import styled from 'styled-components';
import { PrimaryButton, StyledExploreTable, StyledInputNumber, StyledSlider } from 'styles/commonComponents.styles';
import { BUILDING_INFO_KEYS, WAITING_FOR_KEYS } from 'constants/feasibilityConts';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { getProfileByIdSelector } from 'store/swappProfile';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { feasibilityResultModel, useActiveProfileBuildingInfo } from 'utils/model/feasibilityResultModel';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { getPolygonArea } from 'utils/algorithms/algorithmHelpers';
import { sqmToSqf } from 'utils/helpers/unitsHelpers';
import {
  edgeSelector,
  setParkingEntranceMode,
  parkingFormModeSelector,
  setStateFromBe,
} from 'store/parkingForm';
import {
  generateOcbParking,
  pollSwappProjectAction,
  updateProjectProfileAction,
} from 'store/swappProfile/actions/swappProfileActions';
import { setFeasibilityParkingFormData } from 'utils/model/projectProfileData';
import lodashIncludes from 'lodash/includes';
import { activePollingProfileIdsSelector } from 'store/swappProfile/selectors';
import { PROFILE_STATUS } from 'constants/profileConsts';

const FeasibilityParkingFormWrapper = styled.div`
  overflow-y: auto;
  height: 100%;
  padding-left: 18px;
`;

const StallsPerApartmentTitle = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray_05};
  margin-bottom: 5px;
  padding-bottom: 5px;
`;

const FormHeader = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-top: 15px;
`;

const FormSubHeader = styled.div`
  margin-bottom: 12px;
  font-size: ${({ theme }) => theme.font.size.small};
`;

const BelowGradeRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const BelowGradeTitle = styled.div`
  width: 155px;
`;

const TableCell = styled.div`
  text-align: right;
  padding-right: 10px;
`;

const StyledSliderWrapper = styled.div`
  display: flex;
  width: 260px;
`;

const StyledSliderPercentage = styled.div`
  margin: 0 7px;
`;

const StyledSliderStalls = styled.div`
  width: 60px;
  text-align: right;
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.font.size.large};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: 20px;
  text-transform: uppercase;
`;

let debounceTimer = null;

const FeasibilityParkingForm = () => {
  const history = useHistory();
  const buildingInfo = useActiveProfileBuildingInfo();
  const locationData = parseLocationUrl(window.location);
  const profile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const data = useMemo(() => feasibilityResultModel(profile.result, isImperial, buildingInfo), [profile.result, isImperial]);
  const profileUnits = lodashGet(data, 'buildingInfoBTable');
  // const siteArea = useMemo(() => getPolygonArea(buildingInfo.sitePolygon.polygon), [buildingInfo]);
  const siteArea = useMemo(() => getPolygonArea(profile.result.sitePolygon.boundary), [profile]);
  const parkingFormMode = useSelector(parkingFormModeSelector);
  const selectedEdges = useSelector(edgeSelector);
  const parkingFormState = useSelector(({ parkingForm }) => parkingForm);
  const activePollingProfileIds = useSelector(activePollingProfileIdsSelector);
  const dispatch = useDispatch();
  const parkingFormDataFromProfile = lodashGet(profile, 'profileData.feasibilityParkingForm');
  const isProfileLoading = lodashGet(profile, 'status') === PROFILE_STATUS.WAITING_FOR_EXTRA_DATA;
  const isWaitingFor = lodashGet(profile, `result.waitingFor[${WAITING_FOR_KEYS.OCB_PARKING}]`);
  const isLoading = isProfileLoading && isWaitingFor;

  const [dataSource, setDataSource] = useState(parkingFormDataFromProfile?.dataSource || []);
  const [belowGradeMaxLevels, setBelowGradeMaxLevels] = useState(parkingFormDataFromProfile?.belowGradeMaxLevels || 1);
  const [belowGradePercentageTarget, setBelowGradePercentageTarget] = useState(parkingFormDataFromProfile?.belowGradePercentageTarget || 50);
  const [rainwaterInfiltrationAreaPercentage, setRainwaterInfiltrationAreaPercentage] = useState(parkingFormDataFromProfile?.rainwaterInfiltrationAreaPercentage || 15);
  const [belowGradeStallsTarget, setBelowGradeStallsTarget] = useState(parkingFormDataFromProfile?.belowGradeStallsTarget || 0);
  const rainwaterInfiltrationArea = siteArea * (rainwaterInfiltrationAreaPercentage / 100);

  // set form from BE
  useEffect(() => {
    if (!lodashIsEmpty(parkingFormDataFromProfile)) {
      setDataSource(parkingFormDataFromProfile?.dataSource);
      setBelowGradeMaxLevels(parkingFormDataFromProfile?.belowGradeMaxLevels);
      setBelowGradePercentageTarget(parkingFormDataFromProfile?.belowGradePercentageTarget);
      setRainwaterInfiltrationAreaPercentage(parkingFormDataFromProfile?.rainwaterInfiltrationAreaPercentage);
      setBelowGradeStallsTarget(parkingFormDataFromProfile?.belowGradeStallsTarget);
      dispatch(setStateFromBe(parkingFormDataFromProfile?.parkingFormState));
    }
  }, [profile.id]);
  // update the total amount of car stalls for "Percentage target" calc
  useEffect(() => {
    const totalUnits = lodashSum(dataSource.filter((item) => item.isUnit || item.isGuests || item.isAccessible).map((unit) => unit.stalls));

    setBelowGradeStallsTarget(Math.round(totalUnits * (belowGradePercentageTarget / 100)));
  }, [belowGradePercentageTarget, dataSource]);

  // when profileUnits is available creating the table data
  useEffect(() => {
    if (lodashIsEmpty(profileUnits)) {
      return;
    }

    const totalUnits = lodashGet(profileUnits.find((unit) => unit.key === BUILDING_INFO_KEYS.TOTAL_APARTMENTS), 'model', 1);
    // add a row for each unit
    const tableData = profileUnits.map((unit) => {
      const unitData = dataSource.find((item) => item.key === unit.key);
      if (unit.key === BUILDING_INFO_KEYS.TOTAL_APARTMENTS || unit.key === BUILDING_INFO_KEYS.NON_TYPICAL_APARTMENTS) {
        return;
      }
      return ({
        key: unit.key, isUnit: true, name: unit.key, min: 1, max: 2, target: unitData?.target || 1, apartmentsAmount: unit.model, stalls: unit.model,
      });
    }).filter((e) => e);

    // add additional rows to the table
    const additionalTableData = [
      { key: 'GUESTS', isGuests: true, name: 'GENERATE_PARKING_FORM_GUESTS', min: 0, max: 1, target: 0, apartmentsAmount: '', stalls: dataSource.find((item) => item.key === 'GUESTS')?.stalls || 0 },
      { key: 'ACCESSIBLE', isAccessible: true, name: 'GENERATE_PARKING_FORM_ACCESSIBLE', min: '', max: '', target: '', apartmentsAmount: '', stalls: 3 },
      { key: 'TALL_ACCESSIBLE', isAccessible: true, name: 'GENERATE_PARKING_FORM_TALL_ACCESSIBLE', max: '', target: '', apartmentsAmount: '', stalls: 2 },
      { key: 'TOTAL', name: 'GENERATE_PARKING_FORM_TOTAL', min: 0, max: 0, target: '', apartmentsAmount: totalUnits, stalls: 0 },
      { key: 'MOTORCYCLE', isTwoWheeled: true, name: 'GENERATE_PARKING_FORM_MOTORCYCLE', min: 0.1, max: 0.2, target: dataSource.find((item) => item.key === 'MOTORCYCLE')?.target || 0.1, apartmentsAmount: totalUnits, stalls: totalUnits * 0.1 },
      { key: 'BICYCLE', isTwoWheeled: true, name: 'GENERATE_PARKING_FORM_BICYCLE', min: 1, max: '', target: dataSource.find((item) => item.key === 'BICYCLE')?.target || 1, apartmentsAmount: totalUnits, stalls: totalUnits },
    ];

    setDataSource(setTotal([...tableData, ...additionalTableData]));
  }, [profileUnits]);

  // save user data to server
  useEffect(() => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    debounceTimer = setTimeout(() => {
      handleChange();
    }, 800);
  }, [
    dataSource,
    belowGradeMaxLevels,
    belowGradePercentageTarget,
    rainwaterInfiltrationAreaPercentage,
    belowGradeStallsTarget,
    parkingFormMode,
    parkingFormState,
  ]);

  useEffect(() => {
    if (isLoading && locationData.profileId && !lodashIncludes(activePollingProfileIds, locationData.profileId)) {
      dispatch(pollSwappProjectAction(locationData.projectId, locationData.profileId, history, WAITING_FOR_KEYS.COVE_TOOL));
    }
  }, [isLoading, locationData.profileId, activePollingProfileIds]);

  // calcs te total + accessible rows (only for the first time)
  const setTotal = (tableData) => {
    const currentDataSource = [...tableData];
    const totalRowIndex = currentDataSource.findIndex((row) => row.key === 'TOTAL');
    const accessibleRowIndex = currentDataSource.findIndex((row) => row.key === 'ACCESSIBLE');
    const tallAccessibleRowIndex = currentDataSource.findIndex((row) => row.key === 'TALL_ACCESSIBLE');

    currentDataSource[accessibleRowIndex] = calcAccessible(currentDataSource, currentDataSource[accessibleRowIndex]);
    currentDataSource[tallAccessibleRowIndex] = calcAccessible(currentDataSource, currentDataSource[tallAccessibleRowIndex]);
    currentDataSource[totalRowIndex] = calcTableTotal(currentDataSource, currentDataSource[totalRowIndex]);
    return currentDataSource;
  };

  const handleFormChanges = (field, key, value) => {
    const currentDataSource = [...dataSource];
    const rowIndex = currentDataSource.findIndex((row) => row.key === key);
    const totalRowIndex = currentDataSource.findIndex((row) => row.key === 'TOTAL');
    const accessibleRowIndex = currentDataSource.findIndex((row) => row.key === 'ACCESSIBLE');
    const tallAccessibleRowIndex = currentDataSource.findIndex((row) => row.key === 'TALL_ACCESSIBLE');
    currentDataSource[rowIndex][field] = value;

    if (field === 'target') {
      currentDataSource[rowIndex].stalls = value * currentDataSource[rowIndex].apartmentsAmount;
    }

    if (field === 'stalls') {
      const apartmentsAmount = lodashSum(currentDataSource.filter((row) => row.isUnit).map((unit) => unit.apartmentsAmount));
      currentDataSource[rowIndex].target = value / apartmentsAmount;
      currentDataSource[rowIndex].MaxApartmentsGuestsAmount = apartmentsAmount;
    }

    currentDataSource[accessibleRowIndex] = calcAccessible(currentDataSource, currentDataSource[accessibleRowIndex]);
    currentDataSource[tallAccessibleRowIndex] = calcAccessible(currentDataSource, currentDataSource[tallAccessibleRowIndex]);
    currentDataSource[totalRowIndex] = calcTableTotal(currentDataSource, currentDataSource[totalRowIndex]);

    setDataSource(currentDataSource);
  };

  const calcTableTotal = (rows, row) => {
    const units = rows.filter((item) => item.isUnit || item.isGuests || item.isAccessible);

    return ({
      ...row,
      min: lodashSum(units.map((unit) => unit.min)),
      max: lodashSum(units.map((unit) => unit.max)),
      apartmentsAmount: lodashSum(units.map((unit) => unit.apartmentsAmount)),
      stalls: lodashSum(units.map((unit) => unit.stalls)),
      target: '',
    });
  };

  const calcAccessible = (units, row) => {
    const totalUnits = lodashSum(units.filter((item) => item.isUnit || item.isGuests).map((unit) => unit.stalls));

    const ranges = {
      ACCESSIBLE: [[0, 0, 25], [1, 25, 50], [2, 50, 75], [3, 75, 100], [4, 100]],
      TALL_ACCESSIBLE: [[1, 0, 25], [1, 25, 50], [1, 50, 75], [1, 75, 100], [1, 100]],
    };

    let stalls = 0;
    for (let i = 0; i < ranges[row.key].length; i++) {
      const item = ranges[row.key][i];
      if (item[2] ? (lodashInRange(totalUnits, item[1], item[2])) : (totalUnits >= item[1])) {
        // eslint-disable-next-line prefer-destructuring
        stalls = item[0];
        break;
      }
    }

    return ({
      ...row,
      stalls,
    });
  };

  const columns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: (value) => T.translate(value),
    },
    {
      title: <StallsPerApartmentTitle>{T.translate('GENERATE_PARKING_FORM_STALLS_PER_APARTMENT')}</StallsPerApartmentTitle>,
      dataIndex: 'stallsPerApartment',
      key: 'stallsPerApartment',
      align: 'center',
      children: [
        {
          title: T.translate('GENERATE_PARKING_FORM_MIN'),
          dataIndex: 'min',
          key: 'min',
          align: 'center',
          width: 65,
        },
        {
          title: T.translate('GENERATE_PARKING_FORM_MAX'),
          dataIndex: 'max',
          key: 'max',
          align: 'center',
          width: 65,
        },
        {
          title: T.translate('GENERATE_PARKING_FORM_TARGET'),
          dataIndex: 'target',
          key: 'target',
          align: 'center',
          width: 65,
          render: (value, rowData) => (rowData.isUnit || rowData.isTwoWheeled
            ? (
              <StyledInputNumber
                onChange={(e) => handleFormChanges('target', rowData.key, e)}
                min={rowData.min}
                max={rowData.max}
                defaultValue={1}
                step={0.1}
                disabled={rowData.key === 'MOTORCYCLE' && rowData.apartmentsAmount <= 10}
                height={26}
                value={value}
              />
            )
            : <TableCell>{lodashIsNumber(value) ? numberWithComma(value, { fixed: 1 }) : ''}</TableCell>
          ),
        },
      ],
    },
    {
      title: '',
      dataIndex: 'apartmentsAmountTitle',
      key: 'apartmentsAmountTitle',
      align: 'center',
      width: 65,
      children: [
        {
          title: T.translate('GENERATE_PARKING_FORM_APARTMENTS_AMOUNT_TITLE'),
          dataIndex: 'apartmentsAmount',
          key: 'apartmentsAmount',
          align: 'center',
          width: 65,
        },
      ],
    },
    {
      title: '',
      dataIndex: 'stallsTitle',
      key: 'stallsTitle',
      align: 'center',
      width: 65,
      children: [
        {
          title: T.translate('GENERATE_PARKING_FORM_STALLS_TITLE'),
          dataIndex: 'stalls',
          key: 'stalls',
          align: 'center',
          width: 65,
          render: (value, rowData) => (rowData.isGuests
            ? <StyledInputNumber onChange={(e) => handleFormChanges('stalls', rowData.key, e)} min={0} max={rowData.MaxApartmentsGuestsAmount} defaultValue={1} height={26} value={value} />
            : <TableCell>{numberWithComma(Math.round(value), { fixed: 1 })}</TableCell>
          ),
        },
      ],
    },
  ];

  const handleParkingGenerate = () => {
    if (isLoading) {
      return;
    }
    if (lodashIsEmpty(selectedEdges)) {
      message.warning(T.translate('GENERATE_PARKING_FORM_WARNING_MESSAGE'));
      return;
    }
    const params = {
      formData: {
        dataSource,
        belowGradeMaxLevels,
        belowGradePercentageTarget,
        rainwaterInfiltrationAreaPercentage,
        entrances: Object.values(selectedEdges).map((item) => ({ segment: item.map((e) => [e[0], e[1]]) })),
      },
    };

    dispatch(generateOcbParking(params, locationData.projectId, locationData.profileId, history));
  };

  const handleChange = () => {
    const parkingDataForm = {
      dataSource,
      belowGradeMaxLevels,
      belowGradePercentageTarget,
      rainwaterInfiltrationAreaPercentage,
      belowGradeStallsTarget,
      parkingFormMode,
      parkingFormState,
    };

    if (lodashIsEqual(parkingFormDataFromProfile, parkingDataForm) || lodashIsEmpty(profile)) {
      return;
    }

    dispatch(updateProjectProfileAction(profile.id, setFeasibilityParkingFormData(parkingDataForm)))
      .catch((err) => message.error(err));
  };

  return (
    <FeasibilityParkingFormWrapper>
      <Title>{T.translate('Parking')}</Title>
      {/* ================ Table ================ */}
      <StyledExploreTable
        pagination={false}
        columns={columns}
        cellHeight={26}
        dataSource={dataSource}
        rowClassName={(row) => row.key === 'TOTAL' && 'ant-table-total-row'}
      />

      {/* ================ Below Grade ================ */}
      <FormHeader>{T.translate('GENERATE_PARKING_FORM_BELOW_GRADE')}</FormHeader>

      {/* ================ Maximum levels ================ */}
      <BelowGradeRow>
        <BelowGradeTitle>{T.translate('GENERATE_PARKING_FORM_MAXIMUM_LEVELS')}</BelowGradeTitle>
        <StyledInputNumber onChange={(e) => setBelowGradeMaxLevels(e)} min={0} max={1} defaultValue={1} height={25} width={50} value={belowGradeMaxLevels} />
      </BelowGradeRow>

      {/* ================ Percentage target ================ */}
      <BelowGradeRow>
        <BelowGradeTitle>{T.translate('GENERATE_PARKING_FORM_PERCENTAGE_TARGET')}</BelowGradeTitle>
        <StyledSliderWrapper>
          <StyledSlider
            defaultValue={50}
            min={0}
            max={100}
            step={1}
            width={200}
            padding={0}
            tooltipVisible={false}
            value={belowGradePercentageTarget}
            onChange={(e) => setBelowGradePercentageTarget(e)}
          />
          <StyledSliderPercentage>{`${belowGradePercentageTarget}%`}</StyledSliderPercentage>
        </StyledSliderWrapper>
        <StyledSliderStalls>{`${belowGradeStallsTarget} ${T.translate('GENERATE_PARKING_FORM_STALLS')}`}</StyledSliderStalls>
      </BelowGradeRow>

      {/* ================ Rainwater infiltration area ================ */}
      <BelowGradeRow>
        <BelowGradeTitle>{T.translate('GENERATE_PARKING_FORM_RAINWATER_INFILTRATION_AREA')}</BelowGradeTitle>
        <StyledSliderWrapper>
          <StyledSlider
            defaultValue={50}
            min={0}
            max={100}
            step={1}
            width={200}
            padding={0}
            tooltipVisible={false}
            value={rainwaterInfiltrationAreaPercentage}
            onChange={(e) => setRainwaterInfiltrationAreaPercentage(e)}
          />
          <StyledSliderPercentage>{`${rainwaterInfiltrationAreaPercentage}%`}</StyledSliderPercentage>
        </StyledSliderWrapper>
        <StyledSliderStalls>
          {`${numberWithComma(isImperial ? sqmToSqf(rainwaterInfiltrationArea) : rainwaterInfiltrationArea, { fixed: 0, suffix: ` ${T.translate(isImperial ? 'SQF' : 'SQM')}` })}`}
        </StyledSliderStalls>
      </BelowGradeRow>

      {/* ================ Entrance ================ */}
      <FormHeader>{T.translate('GENERATE_PARKING_FORM_LOCATE_ENTRANCE')}</FormHeader>
      <FormSubHeader>{T.translate('GENERATE_PARKING_FORM_LOCATE_ENTRANCE_DESCRIPTION')}</FormSubHeader>
      <Radio.Group defaultValue={parkingFormMode} value={parkingFormMode} buttonStyle="solid" onChange={(e) => dispatch(setParkingEntranceMode(e.target.value))}>
        <Radio.Button key="a" value="Edge">
          {T.translate('GENERATE_PARKING_FORM_CHOOSE_EDGE')}
        </Radio.Button>
        <Radio.Button key="b" value="Position">
          {T.translate('GENERATE_PARKING_FORM_CHOOSE_LOCATION')}
        </Radio.Button>
      </Radio.Group>

      {/* ================ Generate ================ */}
      <PrimaryButton marginTop={30} marginBottom={0} disabled={isLoading} onClick={handleParkingGenerate} center width={200}>{isLoading ? T.translate('LOADING') : T.translate('GENERATE_PARKING_GENERATE')}</PrimaryButton>
    </FeasibilityParkingFormWrapper>
  );
};

export default FeasibilityParkingForm;
