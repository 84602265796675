import React from 'react';
import PropTypes from 'prop-types';
import { pointToThreeVector3 } from '../../helpers/threeHelpers';
import { vecLength, vecSub } from '../../algorithms/algorithmHelpers';

const DashedLine = ({ from, to, color, width = 1, dashSize = 1, zIndex = 0, meshProps }) => {
  const length = vecLength(vecSub(from, to));
  const dashProps = { dashArray: dashSize / length, dashRatio: 0.3, transparent: true };

  return (
    <mesh position={[0, 0, zIndex]} {...meshProps}>
      <meshLine attach="geometry" vertices={[pointToThreeVector3(from), pointToThreeVector3(to)]} />
      <meshLineMaterial attach="material" color={color} lineWidth={width} {...dashProps} />
    </mesh>
  );
};

DashedLine.propTypes = {
  from: PropTypes.array,
  to: PropTypes.array,
  color: PropTypes.string,
  width: PropTypes.number,
  dashSize: PropTypes.number,
  zIndex: PropTypes.number,
  meshProps: PropTypes.object,
};

export default React.memo(DashedLine);
