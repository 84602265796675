import { generateBuildingsData } from '../../../components/feasibility/studies/result/hbEnergy/mockData';

// for testing
const GENERATE_MOCK_DATA_ON_NULL = false;

function roundResults(obj) {
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'object') {
      roundResults(value);
    } else {
      obj[key] = Math.floor(obj[key]);
    }
  });

  return obj;
}

export const getHbEnergyAnalysisData = (data) => {
  let { hbEnergyAnalysis } = data;

  // temp - allow for a quick way to get reportUrls without effecting the UI
  try {
    window.reportUrls = hbEnergyAnalysis.reportUrls;
  // eslint-disable-next-line no-empty
  } catch (e) { }
  if (!hbEnergyAnalysis || Object.keys(hbEnergyAnalysis) === 0) {
    if (GENERATE_MOCK_DATA_ON_NULL) {
      hbEnergyAnalysis = generateBuildingsData();
    } else {
      return null;
    }
  }

  const { projectSummary } = hbEnergyAnalysis;

  const summary = {};
  summary.annualEnergyUseGas = projectSummary.annualEnergyUseGas;
  summary.annualEnergyUseElectricity = projectSummary.annualEnergyUseElectricity;
  summary.annualEnergyUseTotal = projectSummary.annualEnergyUseTotal;
  summary.buildingEui = projectSummary.buildingEui;
  summary.buildingConditionedAreaEui = projectSummary.buildingConditionedAreaEui;
  summary.netInternalArea = projectSummary.netInternalArea;
  summary.netConditionedInternalArea = projectSummary.netConditionedInternalArea;

  summary.energyLoadDistribution = {
    cooling: projectSummary.energyLoadDistribution.cooling,
    heating: projectSummary.energyLoadDistribution.heating,
    lighting: projectSummary.energyLoadDistribution.lighting,
    equipment: projectSummary.energyLoadDistribution.equipment,
    pump: projectSummary.energyLoadDistribution.pump,
    fans: projectSummary.energyLoadDistribution.fans,
    hotWater: projectSummary.energyLoadDistribution.hotWater,
  };

  return roundResults(summary);
};
