import React, { useMemo, useState } from 'react';
import T from 'i18n-react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashFlatten from 'lodash/flatten';
import lodashIncludes from 'lodash/includes';
import { Link, Element } from 'react-scroll';

import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { PAGES_TYPES, FEASIBILITY_STUDY_REPORT_SIDEBAR } from 'constants/feasibilityReportConts';
import {
  feasibilityReportSelector,
  loadingSelector,
  swappProjectSelector,
} from 'store/swappProfile/selectors';
import {
  ReportPageContentWrapper,
  ReportPageWrapper,
  StyledNavigationWrapper,
  StyledNavigationItemWrapper,
  StyledNavigationItem,
} from './ReportOnePageContainer.styles';
import LoadingSpinner from '../../../../common/LoadingSpinner';

import Summary from './summary/Summary';
import Apartments from './apartments/Apartments';
import SiteLocation from './site/SiteLocation';
import ExistingSite from './site/ExistingSite';
import Floods from './site/Floods';
import AerialView from './site/AerialView';
import ExistingStore from './site/ExistingStore';
import StreetView from './site/StreetView';
import Surrounding from './site/Surrounding';
import SiteConstraints from './strategy/SiteConstraints';
import Opportunities from './strategy/Opportunities';
import Phasing from './strategy/Phasing';
import Overall from './design/Overall';
import ApartmentsMix from './design/ApartmentsMix';
import Communal from './design/Communal';
import Plans from './design/Plans';
import Section from './design/Section';
import DesignStreetView from './design/StreetView';
import Wellbeing from './environment/Wellbeing';
import Shadows from './environment/Shadows';
import RightOfLight from './environment/RightOfLight';

const ReportOnePageContainer = () => {
  const history = useHistory();
  const swappProject = useSelector(swappProjectSelector);
  const isLoading = useSelector(loadingSelector);
  const locationData = parseLocationUrl(history.location);
  const reportData = useSelector((state) => feasibilityReportSelector(state, locationData.profileId));
  const [visiblePage, setVisiblePage] = useState(PAGES_TYPES.summary);

  let pages = [];
  useMemo(() => {
    pages = lodashFlatten(FEASIBILITY_STUDY_REPORT_SIDEBAR.map((page) => {
      if (page.resultObjectPath && reportData && !reportData[page.resultObjectPath]) {
        return;
      }

      const subItems = [];
      if (page.subItems) {
        page.subItems.forEach((subPage) => {
          if (subPage.resultObjectPath && !lodashGet(reportData, `[${page.resultObjectMainPath}][${subPage.resultObjectPath}]`)) {
            return;
          }

          return subItems.push({ key: subPage.key, pageKey: page.key, isSubPage: true, value: subPage.value });
        });
      }
      return [{ key: page.key, isSubPage: false, value: page.value, subItemsKeys: subItems.map((e) => e.key) }, ...subItems];
    })).filter((e) => e);
  }, [reportData]);

  if (isLoading || !swappProject) {
    return <LoadingSpinner />;
  }

  // @ts-ignore
  // @ts-ignore
  return (
    <ReportPageWrapper>
      <StyledNavigationWrapper>
        {pages.map((page) => (
          <Link
            key={page.key}
            to={lodashIsEmpty(page.subItemsKeys) ? page.key : page.subItemsKeys[0]}
            containerId="containerElement"
            spy
            smooth
            duration={500}
            onSetActive={(a) => a !== visiblePage && setVisiblePage(a)}
          >
            <StyledNavigationItemWrapper isSubPage={page.isSubPage} selected={visiblePage === page.key || lodashIncludes(page.subItemsKeys, visiblePage)}>
              <StyledNavigationItem selected={visiblePage === page.key} isSubPage={page.isSubPage}>
                {T.translate(page.value)}
              </StyledNavigationItem>
            </StyledNavigationItemWrapper>
          </Link>
        ))}
      </StyledNavigationWrapper>
      <ReportPageContentWrapper id="containerElement">
        {/* ========== Summary ========== */}
        <Element name={PAGES_TYPES.summary}>
          <Summary />
        </Element>

        {/* ========== Site ========== */}
        <Element name={PAGES_TYPES.siteLocation}>
          <SiteLocation />
        </Element>
        <Element name={PAGES_TYPES.existingSite}>
          <ExistingSite />
        </Element>
        <Element name={PAGES_TYPES.floods}>
          <Floods />
        </Element>
        <Element name={PAGES_TYPES.aerialView}>
          <AerialView />
        </Element>
        <Element name={PAGES_TYPES.existingStore}>
          <ExistingStore />
        </Element>
        <Element name={PAGES_TYPES.streetView}>
          <StreetView />
        </Element>
        <Element name={PAGES_TYPES.surrounding}>
          <Surrounding />
        </Element>

        {/* ========== Strategy ========== */}
        <Element name={PAGES_TYPES.siteConstraints}>
          <SiteConstraints />
        </Element>
        <Element name={PAGES_TYPES.opportunities}>
          <Opportunities />
        </Element>
        <Element name={PAGES_TYPES.phasing}>
          <Phasing />
        </Element>

        {/* ========== Design ========== */}
        <Element name={PAGES_TYPES.overallFe}>
          <Overall />
        </Element>
        <Element name={PAGES_TYPES.apartmentsMix}>
          <ApartmentsMix />
        </Element>
        <Element name={PAGES_TYPES.communal}>
          <Communal />
        </Element>
        <Element name={PAGES_TYPES.plansFe}>
          <Plans />
        </Element>
        <Element name={PAGES_TYPES.section}>
          <Section />
        </Element>
        <Element name={PAGES_TYPES.designStreetView}>
          <DesignStreetView />
        </Element>

        {/* ========== Environment ========== */}
        <Element name={PAGES_TYPES.wellbeing}>
          <Wellbeing />
        </Element>
        <Element name={PAGES_TYPES.shadows}>
          <Shadows />
        </Element>
        <Element name={PAGES_TYPES.rightOfLight}>
          <RightOfLight />
        </Element>

        {/* ========== Apartments ========== */}
        <Element name={PAGES_TYPES.apartments}>
          <Apartments />
        </Element>
      </ReportPageContentWrapper>
    </ReportPageWrapper>
  );
};

export default ReportOnePageContainer;
