import React from 'react';
import PropTypes from 'prop-types';
// import Icon from '@ant-design/icons';
import styled from 'styled-components';
import { ColorPill } from 'components/common/BuildingCode.styles';

const StyledSubMenuTitleWrapper = styled.div`
  display: flex;
  cursor: ${({ collapsed }) => collapsed && 'default'} !important;
  align-items: center;
  padding-left: 12px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.textColor};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.large};
  margin-left: 6px;
  margin-top: 0;
  text-transform: uppercase;
`;

const SubTitle = styled.div`
  color: ${({ theme }) => theme.colors.gray_09};
  margin-left: 5px;
`;

// const StyledSubMenuIcon = styled.div`
//   color: ${({ theme }) => theme.colors.textColor};
//   width: 24px;
//   height: 24px;

//   svg {
//     width: 24px;
//     height: 24px;
//   }
// `;

const StyledSubMenuTitle = (props) => {
  const { title, subTitle, color } = props;

  return (
    <StyledSubMenuTitleWrapper>
      {color && <ColorPill color={color} />}
      {/* <StyledSubMenuIcon> */}
      {/*  {icon && <Icon component={icon} />} */}
      {/* </StyledSubMenuIcon> */}
      <Title>{title}</Title>
      {subTitle && <SubTitle>{`- ${subTitle}`}</SubTitle>}
    </StyledSubMenuTitleWrapper>
  );
};

StyledSubMenuTitle.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  color: PropTypes.string,
  // icon: PropTypes.func,
};

export default React.memo(StyledSubMenuTitle);
