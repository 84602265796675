import React from 'react';

// currentcolor
export default () => (
  <svg width="32" height="32" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29.3197 28.546L11.5056 11.5456L12.1546 10.8656L29.9686 27.866L29.3197 28.546Z" fill="currentcolor" />
    <path d="M15.5995 10.372C15.675 10.4441 15.7027 10.5644 15.664 10.7026C15.5989 10.9298 15.3709 11.1208 15.157 11.1248L12.0992 11.2063C12.021 11.2078 11.9537 11.2324 11.917 11.2709C11.8789 11.3108 11.8588 11.3779 11.8587 11.4564L11.9055 14.5338C11.9093 14.7481 11.7291 14.9847 11.5043 15.0595C11.2805 15.1351 11.0958 15.0223 11.091 14.8072L11.0442 11.7298C11.0391 11.4001 11.1793 11.0618 11.4265 10.8028C11.6736 10.5438 12.0047 10.3903 12.3352 10.3809L15.393 10.2993C15.4788 10.2974 15.5497 10.3246 15.5995 10.372Z" fill="currentcolor" />
    <path d="M17.6526 10.7651L11.6605 17.044L9.19349 8.68952L17.6526 10.7651Z" fill="currentcolor" />
    <path d="M17.4076 10.5313L12.1569 16.0333C11.9155 16.2864 11.6549 16.5307 11.4278 16.7974L11.4171 16.8086C11.6017 16.8541 11.7864 16.8997 11.9743 16.9448C11.8916 16.668 11.8106 16.39 11.7279 16.1133C11.5318 15.4486 11.3342 14.7825 11.1385 14.1182C10.9002 13.3129 10.6623 12.5079 10.4252 11.7044C10.2209 11.0105 10.0148 10.3185 9.81199 9.626C9.7123 9.28734 9.63092 8.93943 9.51136 8.60913C9.50973 8.60461 9.50811 8.60009 9.50617 8.59527L9.11281 9.00746C9.39313 9.07719 9.67463 9.14507 9.95495 9.2148C10.628 9.37959 11.3026 9.54584 11.9754 9.71034C12.7909 9.9107 13.6061 10.1107 14.4198 10.31C15.1225 10.4817 15.8234 10.6552 16.5246 10.8255C16.8676 10.9093 17.2105 11.0055 17.5544 11.0783C17.559 11.0797 17.5636 11.0811 17.5685 11.0828C17.7406 11.1253 17.9245 11.0092 17.9658 10.8383C18.0085 10.6629 17.8937 10.4838 17.7213 10.441C17.441 10.3713 17.1595 10.3034 16.8792 10.2337C16.2061 10.0689 15.5315 9.90263 14.8587 9.73814C14.0432 9.53778 13.228 9.33773 12.4143 9.13846C11.7116 8.96676 11.0107 8.79323 10.3095 8.62299C9.96651 8.53923 9.62357 8.44299 9.27965 8.37019C9.27506 8.36878 9.27047 8.36736 9.26557 8.36565C9.02593 8.30743 8.80285 8.54119 8.8722 8.77784C8.95495 9.0546 9.03591 9.33262 9.11866 9.60938C9.31472 10.274 9.51231 10.9401 9.70805 11.6045C9.9463 12.4098 10.1842 13.2147 10.4213 14.0182C10.6256 14.7121 10.8317 15.4042 11.0345 16.0966C11.1342 16.4353 11.2261 16.7784 11.3352 17.1135C11.3368 17.118 11.3384 17.1226 11.3404 17.1274C11.4098 17.3671 11.7228 17.4467 11.8976 17.2636L17.1483 11.7616C17.3912 11.507 17.6423 11.2601 17.8774 10.9975L17.8881 10.9863C18.0065 10.8623 18.0121 10.6366 17.8772 10.5197C17.739 10.4038 17.5352 10.3977 17.4076 10.5313Z" fill="currentcolor" />
  </svg>
);
