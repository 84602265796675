import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router';
import { shallowEqual, useSelector } from 'react-redux';
import { metricDivideValue, inchesToMeters } from 'utils/helpers/unitsHelpers';
import { StyledInputNumber } from 'styles/commonComponents.styles';
import { supportedCurrencies } from 'constants/currencies';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { currentCurrencySelector, getUnitSystemByProjectIdSelector } from 'store/userSettings';

let debounceTimer = null;

const CurrencyInput = forwardRef(({ onChange, value, decimals, unitSystemKey, readOnly, ...props }, ref) => {
  const match = useRouteMatch();
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));
  const currentSelectedObject = useSelector(currentCurrencySelector, shallowEqual);
  const [stringValue, useStringValue] = useState({ value, isImperial });
  const { currencyTransferRate, currentCurrencyKey } = currentSelectedObject;

  const unitSystem = {
    SQM_TO_SQF: metricDivideValue,
    METER_TO_INCH: inchesToMeters,
  };

  const currentUnitSystem = unitSystem[unitSystemKey] || 1;

  useEffect(() => {
    const transformValue = value * currencyTransferRate;

    if (unitSystemKey) {
      handleUpdate(isImperial ? transformValue / currentUnitSystem : transformValue);
    } else {
      handleUpdate(transformValue || 0);
    }
  }, [value, currencyTransferRate]);

  useEffect(() => {
    if (unitSystemKey) {
      if (stringValue.isImperial && !isImperial) {
        handleUpdate(Number(stringValue.value * currentUnitSystem));
      }
      if (!stringValue.isImperial && isImperial) {
        handleUpdate(Number(stringValue.value / currentUnitSystem));
      }
    }
  }, [isImperial]);

  const handleUpdate = (currentValue) => {
    useStringValue({ value: currentValue, isImperial });
  };

  const handleChange = (currentValue) => {
    handleUpdate(currentValue);

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    debounceTimer = setTimeout(() => {
      onChange(isImperial ? ((currentValue / currencyTransferRate) * currentUnitSystem) : currentValue / currencyTransferRate);
    }, [300]);
  };

  const renderValue = (currentValue) => numberWithComma(currentValue, { prefix: supportedCurrencies[currentCurrencyKey].symbol, fixed: decimals || 1 });

  return readOnly
    ? renderValue(stringValue.value)
    : (
      <StyledInputNumber
        ref={ref}
        min={0}
        {...props}
        value={stringValue.value}
        formatter={(currentValue) => renderValue(currentValue)}
        onChange={(currentValue) => handleChange(currentValue, useStringValue)}
      />
    );
});

CurrencyInput.unitSystemKey = {
  sqmToSqf: 'SQM_TO_SQF',
  meterToInch: 'METER_TO_INCH',
};

CurrencyInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  decimals: PropTypes.number,
  unitSystemKey: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default CurrencyInput;
