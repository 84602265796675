import React from 'react';
import { useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import T from 'i18n-react';
import { useHistory, useRouteMatch } from 'react-router';
import { feasibilityReportSelector } from 'store/swappProfile/selectors';
import { StyledReportTable } from 'styles/commonComponents.styles';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { sqmToSqf } from 'utils/helpers/unitsHelpers';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import {
  ContentColumnWrapper,
  ContentRowWrapper,
  ContentTitle,
  VerticalTitleContentWrapper,
  TableBoxWrapper,
  TableBoxHeader,
  TableTotalRowText,
  TableTEmptyRowText,
  ContentImage,
} from '../ReportPages.styles';

const Communal = () => {
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const reportData = useSelector((state) => feasibilityReportSelector(state, locationData.profileId));
  const communalData = lodashGet(reportData, 'design.communalData');
  const match = useRouteMatch();
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));

  const render = (value, rowData) => {
    switch (lodashGet(rowData, 'TYPE')) {
      case 'TOTAL':
        return <TableTotalRowText>{value}</TableTotalRowText>;
      case 'EMPTY':
        return <TableTEmptyRowText />;
      default:
        return value;
    }
  };

  const nameColumns = [
    {
      title: T.translate('Type'),
      dataIndex: 'NAME',
      key: 'NAME',
      render,
    },
  ];

  const blockColumns = [
    {
      title: T.translate('Floor'),
      dataIndex: 'FLOOR',
      key: 'FLOOR',
      align: 'center',
      width: 70,
      render,
    },
    {
      title: T.translate('Rooms'),
      dataIndex: 'ROOMS',
      key: 'ROOMS',
      align: 'center',
      width: 70,
      render,
    },
    {
      title: T.translate(isImperial ? 'SQF' : 'SQM'),
      dataIndex: 'AREA',
      key: 'AREA',
      align: 'center',
      width: 70,
      render: (value, rowData) => render(numberWithComma(isImperial ? sqmToSqf(value) : value, { fixed: isImperial ? 0 : 1 }), rowData),
    },
  ];

  const totalColumns = [
    {
      title: T.translate('Rooms'),
      dataIndex: 'ROOMS',
      key: 'ROOMS',
      align: 'center',
      width: 70,
      render,
    },
    {
      title: T.translate(isImperial ? 'SQF' : 'SQM'),
      dataIndex: 'AREA',
      key: 'AREA',
      align: 'center',
      width: 70,
      render: (value, rowData) => render(numberWithComma(isImperial ? sqmToSqf(value) : value, { fixed: isImperial ? 0 : 1 }), rowData),
    },
  ];

  const totalData = lodashGet(communalData, 'totalData');
  return (
    <VerticalTitleContentWrapper>
      <ContentTitle>{T.translate('Communal')}</ContentTitle>
      <ContentRowWrapper>
        {/* ======== thumbnail ======== */}
        <ContentColumnWrapper marginRight={25} width={220}>
          <ContentImage src={communalData.communalImageUrl} width={176} height={138} />
        </ContentColumnWrapper>

        <ContentColumnWrapper marginLeft={15}>
          <TableBoxWrapper>
            <ContentRowWrapper>

              {/* ======== names table ======== */}
              <ContentColumnWrapper>
                <TableBoxHeader hide />
                <StyledReportTable
                  pagination={false}
                  columns={nameColumns}
                  dataSource={lodashGet(communalData, 'namesTableData')}
                />
              </ContentColumnWrapper>

              {/* ======== Buildings Table ======== */}
              {lodashGet(communalData, 'blocksData').map((blockData, index) => (
                <ContentColumnWrapper key={index}>
                  <TableBoxHeader>{`${T.translate('Building')} ${blockData[0].BLOCK_NAME}`}</TableBoxHeader>
                  <StyledReportTable
                    pagination={false}
                    columns={blockColumns}
                    dataSource={blockData}
                  />
                </ContentColumnWrapper>
              ))}

              {/* ======== Total Table ======== */}
              { totalData && (
              <ContentColumnWrapper>
                <TableBoxHeader>{T.translate('Total')}</TableBoxHeader>
                <StyledReportTable
                  pagination={false}
                  columns={totalColumns}
                  dataSource={totalData}
                />
              </ContentColumnWrapper>
              ) }

            </ContentRowWrapper>
          </TableBoxWrapper>
        </ContentColumnWrapper>
      </ContentRowWrapper>
    </VerticalTitleContentWrapper>
  );
};

export default React.memo(Communal);
