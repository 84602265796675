import React from 'react';
import { useDispatch } from 'react-redux';
import T from 'i18n-react';
import { message } from 'antd';
import { UI_UNAUTHORIZED_PATH } from 'constants/routes/ui';
import { logMessage, LOG_LEVELS } from 'utils/helpers/logger';
import { loginAction } from 'store/auth/actions/authActions';
import LoginForm from './LoginForm';
import { LoginTitle, ForgotPasswordLink } from './LoginContainer.styles';

const { passwordReset } = UI_UNAUTHORIZED_PATH;

const LoginContainer = () => {
  const dispatch = useDispatch();

  const handleLogin = (data) => {
    dispatch(loginAction(data))
      .catch(() => {
        logMessage(LOG_LEVELS.INFO, 'Failed login', { email: data.email });
        message.error(T.translate('AUTH_FORM_LOG_IN_ERROR'));
      });
  };

  return (
    <React.Fragment>
      <LoginTitle>{T.translate('AUTH_FORM_LOG_IN')}</LoginTitle>
      <LoginForm onSubmit={handleLogin} />
      <ForgotPasswordLink to={passwordReset}>{T.translate('LOGIN_CONTAINER_FORGOT_PASSWORD')}</ForgotPasswordLink>
    </React.Fragment>
  );
};

export default LoginContainer;
