import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Plane } from '@react-three/drei';
import { useThree } from 'react-three-fiber';
import { useDrag } from 'react-use-gesture';
import { rotatePolygon } from 'utils/algorithms/algorithmHelpers';
import { setIsDragged } from 'store/markups';
import DashedLine from '../components/DashedLine';
import { notifyDragFinished } from '../helpers/ThreeHelpers';
import { changeCursor } from '../helpers/SelectionHelpers';
import { Z_INDEX } from './constants';

const MarkupFrame = ({ points, center, rotation, onFrameMoved, onClick, onPointerMissed, onPointerUp, isViewOnly }) => {
  const { camera } = useThree();
  const dispatch = useDispatch();

  const [width, height] = useMemo(() => {
    const unrotatedPoints = rotatePolygon(points, center, -rotation);
    return [
      Math.abs(unrotatedPoints[2][0] - unrotatedPoints[0][0]),
      Math.abs(unrotatedPoints[2][1] - unrotatedPoints[0][1])];
  }, [points]);

  const bindDrag = useDrag(
    ({ delta: [mx, my], event }) => {
      if (event) event.stopPropagation();
      if (event.buttons === 1) {
        mx /= camera.zoom;
        my /= camera.zoom;
        onFrameMoved(event, [mx, -my]);
        dispatch(setIsDragged({ isDragged: true }));
      }
      if (event.type === 'pointerup') {
        notifyDragFinished();
        if (onPointerUp) {
          onPointerUp(event);
        }
        dispatch(setIsDragged({ isDragged: false }));
      }
    },
    { pointerEvents: true },
  );

  const onPointerOver = useCallback((e) => {
    if (isViewOnly) {
      return;
    }
    e.stopPropagation();
    changeCursor('all-scroll');
  }, []);

  const onPointerOut = useCallback(() => {
    if (isViewOnly) {
      return;
    }
    changeCursor('default');
  }, []);

  return (
    <>
      {points.map((p, index) => {
        const from = [...p, 0];
        const to = [...points[(index + 1) % points.length], 0];
        return (
          <DashedLine
            key={index}
            from={from}
            to={to}
            width={0.2}
            dashSize={0.4}
            color="blue"
            zIndex={Z_INDEX.DASHED_FRAME}
          />
        );
      })}
      <Plane
        args={[width, height]}
        position={[...center, Z_INDEX.BACKGROUND]}
        rotation={[0, 0, rotation]}
        {...bindDrag()}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        onClick={onClick}
        onPointerMissed={onPointerMissed}
        // onPointerMove={(e) => console.log('move > ', e)}
      >
        <meshBasicMaterial attach="material" color="blue" transparent opacity={0.1} />
      </Plane>
    </>
  );
};

MarkupFrame.propTypes = {
  points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  center: PropTypes.arrayOf(PropTypes.number),
  rotation: PropTypes.number,
  onFrameMoved: PropTypes.func,
  onClick: PropTypes.func,
  onPointerMissed: PropTypes.func,
  onPointerUp: PropTypes.func,
  isViewOnly: PropTypes.bool,
};

export default React.memo(MarkupFrame);
