import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@react-three/drei';
import Icon from '@ant-design/icons';
import { RAD_2_DEG } from 'utils/algorithms/algorithmHelpers';
import { useSelector } from 'react-redux';
import { currentThemeSelector } from 'store/userSettings';
import icons from 'styles/static/icons/testFit/editor';
import StyledHtml from '../../components/StyledHtml';
import MeshLine from '../../components/MeshLine';
import ExtrudeMesh from '../../components/ExtrudeMesh';

const DndRoom = (props) => {
  const { tube, room, roomPosition } = props;

  const selectedObject = useSelector(({ editor }) => editor.selectedObject);
  const currentTheme = useSelector(currentThemeSelector);
  const { isFirst, isLast } = roomPosition;
  const [hovered, setHover] = useState(false);
  const selected = selectedObject?.id === room.id;
  const vertices = [[0, 0], [room.roomSize, 0], [room.roomSize, -tube.size.depth], [0, -tube.size.depth], [0, 0]];
  const onPointer = (value) => {
    setHover(value);
  };

  return (
    <group name="DndRoomGroup">
      {/* ============== room mesh ============== */}
      <ExtrudeMesh
        unlit
        meshProps={{
          onPointerOver: () => onPointer(true),
          onPointerOut: () => onPointer(false),
          onClick: (e) => room.handleRoomClicked(e),
          name: 'DndRoom',
        }}
        receiveShadow
        envelope={vertices}
        color={selected ? currentTheme.colors.selected3dObject : room.color}
        extrudeDepth={4}
        opacity={hovered ? 0.94 : 1}
      />
      <MeshLine
        vertices={vertices}
        color={selected ? currentTheme.colors.white : currentTheme.colors.gray_08}
        lineWidth={selected ? 0.4 : 0.1}
        height={selected ? 6 : 5}
      />

      {/* ============== Room Name ============== */}
      <Text
        name="DndRoomText"
        textAlign="center"
        position={[room.roomSize / 2, -tube.size.depth / 2, 10]}
        fontSize={16}
        color={selected ? currentTheme.colors.white : currentTheme.colors.black}
        rotation={[0, 0, -tube.tubeAngle]}
      >
        {room.name}
      </Text>

      {/* ============== Arrows ============== */}
      {selected && (
      <group>
        {!isLast && (
        <StyledHtml fixed position={[room.roomSize + 20, -tube.size.depth / 2, 10]} rotate={-(tube.tubeAngle * RAD_2_DEG)}>
          <Icon component={icons.dragIcon} style={{ color: currentTheme.colors.white }} />
        </StyledHtml>
        )}
        {!isFirst && (
        <StyledHtml fixed position={[-20, -tube.size.depth / 2, 10]} rotate={-(tube.tubeAngle * RAD_2_DEG)}>
          <Icon component={icons.dragIcon2} style={{ color: currentTheme.colors.white }} />
        </StyledHtml>
        )}
      </group>
      )}
    </group>
  );
};

DndRoom.propTypes = {
  tube: PropTypes.object,
  room: PropTypes.object,
  roomPosition: PropTypes.object,
};

export default DndRoom;
