import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { loadingSelector, swappProfileResultsSelector } from 'store/swappProfile';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import LoadingSpinner from '../../../common/LoadingSpinner';
import ForgeSplitViewContainer from './ForgeSplitViewContainer';
import ProfileDataPanel from '../result/program/ProfileDataPanel';

const dataViewWidth = 600;

const OldViewerContainer = () => {
  const locationData = parseLocationUrl(window.location);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const swappProfileResults = useSelector((state) => swappProfileResultsSelector(state, locationData.profileId, isImperial));
  const isLoading = useSelector(loadingSelector);
  const forge = useRef();

  if (isLoading || !swappProfileResults) {
    return <LoadingSpinner />;
  }

  return (
    <ForgeSplitViewContainer
      markups
      ref={forge}
      dataViewWidth={dataViewWidth}
      projectId={locationData.projectId}
      profileId={locationData.profileId}
      result={swappProfileResults}
      urn={lodashGet(swappProfileResults, 'forgeURN')}
      threeDUrn={lodashGet(swappProfileResults, 'threeDURN')}
    >
      <ProfileDataPanel profileId={locationData.profileId} />
    </ForgeSplitViewContainer>
  );
};

export default OldViewerContainer;
