import React from 'react';

export default () => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <rect y="-1" width="40" height="40" />
      <path d="M20.9488 34.8976C24.6486 34.8976 28.1962 33.4276 30.8125 30.8125C33.4275 28.1962 34.8976 24.6486 34.8976 20.9488C34.8976 17.249 33.4276 13.7014 30.8125 11.0851C28.1962 8.47011 24.6486 7 20.9488 7C17.249 7 13.7014 8.47002 11.0851 11.0851C8.47011 13.7014 7 17.249 7 20.9488C7.00375 24.6477 8.47501 28.1926 11.0899 30.8077C13.7048 33.4227 17.2499 34.8941 20.9488 34.8976ZM20.9488 8.28C24.3088 8.28 27.5312 9.61501 29.9075 11.9901C32.2825 14.3663 33.6176 17.5888 33.6176 20.9488C33.6176 24.3088 32.2826 27.5312 29.9075 29.9075C27.5313 32.2825 24.3088 33.6176 20.9488 33.6176C17.5888 33.6176 14.3664 32.2826 11.9901 29.9075C9.61507 27.5313 8.28 24.3088 8.28 20.9488C8.28375 17.5901 9.61875 14.3699 11.9936 11.9939C14.3699 9.61891 17.5898 8.28384 20.9485 8.28032L20.9488 8.28Z" fill="currentcolor" />
      <path d="M14.5488 27.9887C14.6613 27.9862 14.7726 27.9549 14.8688 27.8962L22.8688 23.0923C22.9613 23.0373 23.0376 22.9611 23.0926 22.8686L27.8926 14.8875C28.0413 14.6362 28.0013 14.3162 27.7951 14.1087C27.5876 13.9025 27.2676 13.8625 27.0163 14.0112L19.0288 18.8048C18.9376 18.8586 18.8626 18.9348 18.8076 19.0248L14.0076 27.0059C13.8826 27.2034 13.8751 27.4522 13.9876 27.6572C14.1001 27.861 14.3151 27.9885 14.5488 27.9885L14.5488 27.9887ZM19.8189 19.8188L25.4676 16.43L22.0788 22.0786L16.4301 25.4674L19.8189 19.8188Z" fill="currentcolor" />
      <path d="M30.5489 22.1116C31.0189 22.1129 31.4439 21.8316 31.6239 21.3966C31.8051 20.9629 31.7064 20.4629 31.3751 20.1304C31.0426 19.7979 30.5426 19.6979 30.1089 19.8766C29.6739 20.0566 29.3901 20.4804 29.3901 20.9504C29.3901 21.5904 29.9089 22.1104 30.5489 22.1116L30.5489 22.1116ZM30.5489 20.8316C30.5989 20.8304 30.6439 20.8591 30.6639 20.9054C30.6839 20.9504 30.6739 21.0029 30.6389 21.0379C30.6051 21.0741 30.5526 21.0854 30.5064 21.0666C30.4601 21.0479 30.4301 21.0029 30.4301 20.9541C30.4301 20.8879 30.4826 20.8341 30.5489 20.8316H30.5489Z" fill="currentcolor" />
      <path d="M11.3489 22.1116C11.8189 22.1129 12.2439 21.8316 12.4239 21.3966C12.6052 20.9629 12.5064 20.4629 12.1752 20.1304C11.8427 19.7979 11.3427 19.6979 10.9089 19.8766C10.4739 20.0566 10.1902 20.4804 10.1902 20.9504C10.1902 21.5904 10.7089 22.1104 11.3489 22.1116L11.3489 22.1116ZM11.3489 20.8316C11.3989 20.8304 11.4439 20.8591 11.4639 20.9054C11.4839 20.9504 11.4739 21.0029 11.4389 21.0379C11.4052 21.0741 11.3527 21.0854 11.3064 21.0666C11.2602 21.0479 11.2302 21.0029 11.2302 20.9541C11.2302 20.8879 11.2827 20.8341 11.3489 20.8316H11.3489Z" fill="currentcolor" />
      <path d="M20.9489 29.3906C20.4789 29.3906 20.0552 29.6744 19.8752 30.1094C19.6964 30.5432 19.7964 31.0432 20.1289 31.3756C20.4614 31.7069 20.9614 31.8057 21.3951 31.6244C21.8302 31.4444 22.1114 31.0194 22.1101 30.5494C22.1089 29.9094 21.5889 29.3906 20.9489 29.3906L20.9489 29.3906ZM20.9489 30.6706C20.8989 30.6706 20.8552 30.6406 20.8364 30.5956C20.8177 30.5494 20.8289 30.4969 20.8639 30.4619C20.9002 30.4281 20.9527 30.4181 20.9977 30.4381C21.0427 30.4581 21.0714 30.5031 21.0702 30.5531C21.0689 30.6181 21.0152 30.6706 20.9489 30.6706L20.9489 30.6706Z" fill="currentcolor" />
      <path d="M20.9488 12.5101C21.4188 12.5113 21.8438 12.2301 22.0238 11.7951C22.205 11.3613 22.1063 10.8613 21.775 10.5288C21.4425 10.1963 20.9425 10.0963 20.5088 10.2751C20.0738 10.4551 19.79 10.8788 19.79 11.3488C19.79 11.9888 20.3088 12.5088 20.9488 12.5101L20.9488 12.5101ZM20.9488 11.2301C20.9988 11.2288 21.0438 11.2576 21.0638 11.3038C21.0838 11.3488 21.0738 11.4013 21.0388 11.4363C21.005 11.4726 20.9525 11.4838 20.9063 11.4651C20.86 11.4463 20.83 11.4013 20.83 11.3526C20.83 11.2863 20.8825 11.2326 20.9488 11.2301L20.9488 11.2301Z" fill="currentcolor" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="41" height="41" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
