import React from 'react';
import PropTypes from 'prop-types';
import chairPolygon from '../../polygons/chairPolygon';
import MeshLine from '../../components/MeshLine';
import { transformPolygonToBox2 } from '../../../algorithms/algorithmHelpers';

const Desk = (props) => {
  const { desk, lineThickness = 0.05, lineColor = 'black' } = props;

  return (
    <group position={[0, 0, 60]} name="desk">
      <MeshLine vertices={desk.tablePolygon} color={lineColor} lineWidth={lineThickness} height={2} />
      {chairPolygon.map((line, lineIndex) => (
        <MeshLine
          key={lineIndex}
          vertices={transformPolygonToBox2(line, desk.chairPolygon, true, false, true)}
          lineWidth={lineThickness}
          height={2}
          color={lineColor}
        />
      ))}
    </group>
  );
};

Desk.propTypes = {
  desk: PropTypes.object,
  lineThickness: PropTypes.number,
  lineColor: PropTypes.string,
};

export default React.memo(Desk);
