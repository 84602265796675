import lodashSortBy from 'lodash/sortBy';

export const mergeProjectsData = (existingProjectData, newProjectData) => {
  const projectProfiles = lodashSortBy(newProjectData.projectProfiles, ['status', 'id']);
  if (existingProjectData) {
    // If we already have this project in the store, only update projectProfiles that were updated (greater updatedAt timestamp).
    // This will cause profiles that havent been changed to keep their reference in the store, allowing us to memoize their reference.
    const projectIdToIndexAndTimestamp = Object.fromEntries(projectProfiles.map((profile, profileIdx) => [profile.id, { idx: profileIdx, timestamp: profile.updatedAt }]));
    existingProjectData.projectProfiles.forEach((existingProfile) => {
      if (existingProfile.id in projectIdToIndexAndTimestamp && existingProfile.updatedAt === projectIdToIndexAndTimestamp[existingProfile.id].timestamp) {
        projectProfiles[projectIdToIndexAndTimestamp[existingProfile.id].idx] = existingProfile;
      }
    });
  }
  const mergedProjectData = { ...newProjectData, projectProfiles };
  return mergedProjectData;
};
