import React from 'react';

export default () => (
  <svg width="168" height="170" viewBox="0 0 168 170" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 85.3947V119.553H7.22142V79.9492L0 85.3947Z" fill="white" />
    <path d="M13.9449 75.2464V119.553H21.1663V69.8009L13.9449 75.2464Z" fill="white" />
    <path d="M27.8897 64.3554V119.552H35.1111V58.9099L27.8897 64.3554Z" fill="white" />
    <path d="M42.3325 32.1777V119.552H49.5539V26.7322L42.3325 32.1777Z" fill="white" />
    <path d="M56.2773 21.7818V119.552H63.4988V16.3363L56.2773 21.7818Z" fill="white" />
    <path d="M70.2222 10.8908V119.552H77.4436V5.44532L70.2222 10.8908Z" fill="white" />
    <path d="M120.025 26.1288V119.553H85.1631V0L120.025 26.1288Z" fill="white" />
    <path d="M162.357 80.0883V119.552H127.495V53.9594L162.357 80.0883Z" fill="white" />
    <path d="M51.8546 153.576H48.5292V169.897H51.8546V165.042L53.9329 162.873L59.1287 169.897H63.2854L56.2191 160.497L62.9737 153.576H58.7131L51.8546 160.704V153.576Z" fill="white" />
    <path d="M79.8082 153.576H67.546V169.897H79.8082V166.901H70.9753V163.183H78.6651V160.084H70.9753V156.675H79.8082V153.576Z" fill="white" />
    <path d="M85.2119 153.576H97.6819V156.675H88.7451V160.497H96.5388V163.493H88.7451V169.897H85.2119V153.576Z" fill="white" />
    <path d="M106.307 153.576H102.878V169.897H106.307V153.576Z" fill="white" />
    <path d="M133.325 153.576H129.896V169.897H141.639V166.695H133.325V153.576Z" fill="white" />
    <path d="M20.6795 169.897L26.3949 153.576H22.6539L18.9129 165.042L14.8601 153.576H11.5348L7.68985 165.042L3.74101 153.576H0L5.92327 169.897H9.45644L13.3014 158.638L17.0424 169.897H20.6795Z" fill="white" />
    <path d="M60.2718 131.058H56.7386V147.379H60.2718V140.768H67.2343V147.379H70.6635V131.058H67.2343V137.669H60.2718V131.058Z" fill="white" />
    <path d="M76.7946 131.058V147.379H80.2239V136.843L84.9001 143.867L89.7842 136.843V147.379H93.1096V131.058H89.2646L84.9001 137.979L80.6395 131.058H76.7946Z" fill="white" />
    <path d="M119.089 131.058V147.379H122.518V137.049L130.416 147.379H133.637V131.058H130.104V141.491L122.31 131.058H119.089Z" fill="white" />
    <path d="M124.285 153.576H112.022V169.897H124.285V166.901H115.452V163.183H123.142V160.084H115.452V156.675H124.285V153.576Z" fill="white" />
    <path d="M146.211 154.233V153.56C146.211 153.56 151.484 153.382 153.718 153.535C158.882 153.881 162.143 158.008 161.513 162.883C160.997 166.832 157.695 169.756 153.31 169.949C151.128 170.041 146.753 169.98 146.753 169.98H146.249C146.224 164.731 146.222 159.482 146.211 154.233ZM149.74 166.709C149.74 166.709 152.51 166.852 153.63 166.709C156.112 166.393 157.783 164.493 157.855 162.043C157.938 159.271 156.468 157.228 153.903 156.775C152.779 156.576 149.74 156.775 149.74 156.775C149.708 160.085 149.708 163.4 149.74 166.709Z" fill="white" />
    <path d="M148.364 137.856C148.007 137.306 147.639 136.834 147.376 136.319C146.378 134.343 147.292 132.005 149.392 131.233C151.707 130.388 154.379 131.513 154.9 133.999C155.238 135.601 154.712 136.962 153.415 137.965L152.849 138.475C152.849 138.475 154.369 140.151 154.95 140.824C155.526 140.171 156.053 139.576 156.629 138.923C157.349 139.679 158.049 140.416 158.784 141.188C158.178 141.874 157.547 142.545 156.972 143.256C158.205 144.593 159.389 145.983 160.555 147.379C159.199 147.379 156.603 147.379 156.603 147.379C156.603 147.379 155.459 146.038 154.95 145.521C154.95 145.521 154.344 145.988 154.026 146.209C152.064 147.555 150.003 147.978 147.828 146.769C144.684 145.025 144.028 140.785 147.515 138.372C147.778 138.195 148.056 138.043 148.364 137.856ZM150.47 144.691C151.856 144.691 153.097 143.143 153.097 143.143C152.198 142.07 151.28 141.011 150.366 139.949C149.909 139.949 149.124 140.686 148.935 140.898C148.21 141.615 147.997 142.445 148.335 143.256C148.692 144.121 149.546 144.696 150.47 144.691ZM151.126 133.641C150.361 133.631 149.7 134.211 149.909 134.874C150.088 135.449 150.957 136.427 150.957 136.427C150.957 136.427 152.01 135.842 152.238 135.282C152.571 134.481 152.005 133.646 151.126 133.641Z" fill="white" />
    <path d="M38.9688 144.949C39.6723 144.128 40.3256 143.362 40.989 142.591C41.6523 142.991 42.2604 143.411 42.9187 143.738C44.2805 144.415 45.7127 144.736 47.2253 144.267C47.9641 144.034 48.3711 143.5 48.3761 142.838C48.3761 142.289 48.1249 141.854 47.6324 141.647C46.8434 141.316 46.0243 141.049 45.2152 140.767C44.0996 140.381 42.9388 140.095 41.8784 139.6C40.0191 138.735 39.4713 137.223 39.6573 135.389C39.9236 132.744 41.8734 131.088 44.577 130.959C47.0193 130.748 50.6979 132.803 50.6979 132.803L49.5621 135.413C48.6827 135.008 47.8686 134.583 47.0193 134.252C46.1901 133.925 45.3207 133.792 44.4262 134.014C43.6725 134.202 43.1498 134.677 43.0744 135.28C42.989 135.957 43.3006 136.56 44.0946 136.852C45.4062 137.331 46.763 137.697 48.0947 138.132C49.0746 138.448 50.0093 138.859 50.743 139.6C52.532 141.404 52.0999 144.924 49.8887 146.392C48.5018 147.317 46.9288 147.564 45.2906 147.46C43.18 147.322 41.2402 146.689 39.5467 145.408C39.3708 145.275 39.205 145.137 38.9688 144.949Z" fill="white" />
    <path d="M20.2638 130.955L23.6643 130.975C23.6643 130.975 23.6643 137.183 23.6643 139.989C23.6643 140.921 23.7704 141.833 24.2504 142.655C25.0588 144.043 26.3625 144.378 27.8429 144.233C29.2324 144.098 30.2279 143.381 30.6573 142.033C30.819 141.527 30.91 140.976 30.915 140.445C30.9447 137.323 30.9151 130.955 30.9151 130.955H34.3459C34.3459 130.955 34.3965 137.574 34.3965 141.081C34.2752 144.298 32.1732 146.783 28.9697 147.32C26.9284 147.66 24.9275 147.51 23.1034 146.438C21.1934 145.315 20.4053 143.166 20.2638 141.081C20.2637 137.574 20.2638 130.955 20.2638 130.955Z" fill="white" />
    <path d="M15.4265 133.28C14.7067 134.124 14.0018 134.953 13.297 135.782C10.5827 133.166 7.21361 133.847 5.54904 135.782C3.84948 137.76 4.00444 141.115 5.89895 142.975C7.60851 144.653 11.0626 145.097 13.507 142.408C14.2368 143.158 14.9616 143.903 15.6914 144.653C14.0568 146.755 10.3728 148.171 6.75872 147.135C3.21464 146.123 0.910245 143.222 0.735291 139.462C0.580331 136.078 2.71477 132.816 5.89395 131.588C9.22808 130.3 13.497 131.035 15.4265 133.28Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M97.3701 147.379L104.333 131.058H107.658L114.62 147.379H111.087L109.424 143.636H102.358L100.799 147.379H97.3701ZM105.891 135.293L103.709 140.561H108.177L105.891 135.293Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M26.9144 169.897L33.8769 153.576H37.2022L44.1646 169.897H40.6315L38.9688 166.154H31.9025L30.3437 169.897H26.9144ZM35.4357 157.811L33.2534 163.079H37.7218L35.4357 157.811Z" fill="white" />
  </svg>
);
