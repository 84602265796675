import T from 'i18n-react';
import { numberWithComma } from './dataDisplay';

export const metricDivideValue = 10.7639104;
export const btu = 3.41;
export const inchesToMeters = 39.37;
export const feetToMeters = 3.2808399;

export const sqmToSqf = (value) => (value ? value * metricDivideValue : '');

export const sqfToSqm = (value) => (value ? value / metricDivideValue : '');

export const kwhSqmToKBtuToSqf = (value) => ((value * 317.2) / 1000);

export const kwhSqmToBtuToSqf = (value) => (value * 317.2);

export const roomSizeToSQM = (roomSize, isImperial) => {
  const splitRoom = `${roomSize || 0}`.split('-');
  const divideBy = 10;

  if (splitRoom.length === 2 && splitRoom[0] === splitRoom[1]) {
    return isImperial ? splitRoom[0] : splitRoom[0] / divideBy;
  }

  return (
    isImperial
      ? roomSize
      : splitRoom.map((item) => Number(item) / 10).join('-')
  );
};

export const metersToFitAndInches = (meters) => {
  const realFeet = (meters * inchesToMeters) / 12;
  const feet = Math.floor(realFeet);
  const inches = Math.round((realFeet - feet) * 12);
  return `${feet}'-${inches}''`;
};

export const sqfToSqmText = (value, isImperial, showUnit) => (
  numberWithComma(
    isImperial ? value : sqfToSqm(value),
    {
      suffix: showUnit ? ` ${T.translate(isImperial ? 'SQF' : 'SQM')}` : '',
      fixed: isImperial ? 0 : 1 },
  )
);

export const sqmToSqfText = (value, isImperial, showUnit) => (
  numberWithComma(
    !isImperial ? value : sqmToSqf(value),
    {
      suffix: showUnit ? ` ${T.translate(isImperial ? 'SQF' : 'SQM')}` : '',
      fixed: isImperial ? 0 : 1 },
  )
);

export const getLsfModalArea = (modelArea, modelRooms, isImperial) => {
  if (modelArea) {
    const modelAverageNum = modelArea / modelRooms;

    return isImperial
      ? numberWithComma(modelAverageNum, { suffix: '', fixed: 0 })
      : numberWithComma(sqfToSqm(modelAverageNum), { suffix: '', fixed: 1 });
  }
  return 0;
};
