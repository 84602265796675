import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { selectedViewIdSelector, setSelectedViewId } from '../../store/threeDWalkthrough';
import icons from '../../styles/static/icons/testFit/editor';
import { threeJsGlobals } from '../../store/threeJsGlobals';

const pointSize = 15;

const MiniMapWrapper = styled.div`
  position: relative;
  width: ${({ containerWidth }) => `${containerWidth}px`};
  height: ${({ containerHeight }) => `${containerHeight}px`};
`;

const MiniMapImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${({ img }) => img});
  background-size: contain;
`;

const Point = styled.div`
  position: absolute;
  z-index: 1;
  width: ${pointSize}px;
  height: ${pointSize}px;
  border-radius: 50%;
  top: ${({ position }) => `${position[1]}%`};
  left: ${({ position }) => `${position[0]}%`};
  transform: translateX(-50%) translateY(-50%);
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.primaryColor : theme.colors.gray_06)};
  border: 2px solid ${({ theme }) => theme.colors.white};
  cursor: pointer;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryColor};
  }
`;

const fovSize = 30;

const StyledFovIndicator = styled(Icon)`
  position: absolute;
  top: ${({ position }) => `${position[1]}%`};
  left: calc(${({ position }) => `${position[0]}%`} - ${`${fovSize / 2}px`});
  transform-origin: right center;
  transform: translateX(-50%) translateY(-50%);
  color: ${({ theme }) => theme.colors.primaryColor};
`;

const MiniMap = (props) => {
  const { data, size = 250 } = props;

  const selectedViewId = useSelector(selectedViewIdSelector);
  const dispatch = useDispatch();

  const requestRef = React.useRef();
  const fovIconRef = React.useRef();

  const animate = () => {
    requestRef.current = requestAnimationFrame(animate);
    if (fovIconRef.current) {
      fovIconRef.current.style.transform = `translateX(-50%) translateY(-50%) rotate(${threeJsGlobals.getWalkThroughCameraAngle()}deg)`;
    }
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  const containerSize = [size, size];
  const widthInCm = data.bottomRightAnchorPoint[0] - data.topLeftAnchorPoint[0];
  const heightInCm = data.bottomRightAnchorPoint[1] - data.topLeftAnchorPoint[1];
  const sizeInCm = [widthInCm, heightInCm];

  const getPointPercentage = (position = []) => position.map((val, index) => ((val - data.topLeftAnchorPoint[index]) / sizeInCm[index]) * 100);

  const renderMap = () => useMemo(() => (<MiniMapImage img={data.topdownRenderURL} />), [data]);

  const renderPoints = () => useMemo(() => data.views.map((view) => (
    <Point
      onClick={() => dispatch(setSelectedViewId(view.id))}
      isSelected={Number(view.id) === Number(selectedViewId)}
      position={getPointPercentage(view.position)}
    />
  )), [selectedViewId, data]);

  return (
    <TransformWrapper>
      <TransformComponent>
        <MiniMapWrapper containerHeight={containerSize[1]} containerWidth={containerSize[0]}>
          {renderMap()}
          {renderPoints()}
          <StyledFovIndicator
            ref={fovIconRef}
            position={getPointPercentage(data.views[selectedViewId]?.position)}
            component={icons.fovIndicatorIcon}
          />
        </MiniMapWrapper>
      </TransformComponent>
    </TransformWrapper>
  );
};

MiniMap.propTypes = {
  data: PropTypes.object,
  size: PropTypes.number,
};

export default MiniMap;
