import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  changePoints,
  selectMarkup,
  useIsMarkupDragged,
  useIsMarkupSelected,
} from 'store/markups';
import { vecAdd, vecSub, vecMoveTowards, getPointsCenter, vecNormalize } from 'utils/algorithms/algorithmHelpers';
import { Plane } from '@react-three/drei';
import { shadeHexColor } from 'utils/helpers/threeHelpers';
import MarkupFrame from './MarkupFrame';
import TansformPoint from './TansformPoint';
import TextMarkupTextField, { FRAME_OFFSET, PADDING } from './TextMarkupTextField';
import { Z_INDEX } from './constants';
import { changeCursor } from '../helpers/SelectionHelpers';

const MIN_TEXT_WIDTH = 10;
const BORDER_WIDTH = 0.25;

const TextMarkup = ({ id, markup, isViewOnly }) => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const { color, height, transform } = markup;
  const { points } = transform;
  const center = useMemo(() => getPointsCenter(points), [points]);
  const lastClicked = useRef();
  const lastMoveTimeStamp = useRef();
  const isAnyMarkupBeingDragged = useIsMarkupDragged();
  const [isEditable, setIsEditable] = useState(false);
  const isSelected = useIsMarkupSelected(id);

  const framePoints = useMemo(() => {
    if (!transform) {
      return null;
    }
    const dir = vecSub(points[0], points[1]);
    const norm = vecNormalize(dir);
    const perpen = [norm[1], -norm[0]];
    const distToEdge = (height / 2) + FRAME_OFFSET + PADDING;
    return [
      vecMoveTowards(points[0], vecAdd(points[0], perpen), distToEdge),
      vecMoveTowards(points[1], vecAdd(points[1], perpen), distToEdge),
      vecMoveTowards(points[1], vecAdd(points[1], perpen), -distToEdge),
      vecMoveTowards(points[0], vecAdd(points[0], perpen), -distToEdge),
    ];
  }, [points, height]);

  const innerWidth = useMemo(() => Math.abs(framePoints[1][0] - framePoints[0][0]) - (2 * FRAME_OFFSET), [framePoints]);
  const innerHeight = useMemo(() => Math.abs(framePoints[2][1] - framePoints[1][1]) - (2 * FRAME_OFFSET), [framePoints]);

  const onPointMoved = (event, pointIndex, newPoint) => {
    lastMoveTimeStamp.current = event.timeStamp;
    event.stopPropagation();
    const newPoints = JSON.parse(JSON.stringify(points));
    if (Math.abs(newPoints[+!pointIndex][0] - newPoint[0]) >= MIN_TEXT_WIDTH) {
      // eslint-disable-next-line prefer-destructuring
      newPoints[pointIndex][0] = newPoint[0];
      dispatch(changePoints({ id, points: newPoints, isDragged: true }));
    }
  };

  const onFrameMoved = useCallback((event, delta) => {
    if (!transform) {
      return null;
    }
    const newPoints = points.map((point) => vecAdd(point, delta));
    dispatch(changePoints({ id, points: newPoints }));
  }, [points]);

  const onClick = (e) => {
    if (isSelected) {
      if (!isEditable) {
        // It means double-click
        if (e.timeStamp - lastClicked.current < 300) {
          setIsEditable(true);
        }
      }
    }
    lastClicked.current = e.timeStamp;
  };

  const onPointerOver = useCallback((event) => {
    if (!transform || isViewOnly) {
      return null;
    }
    if (!isSelected && !isAnyMarkupBeingDragged) {
      event.stopPropagation();
      changeCursor('pointer');
      setIsHovered(true);
    }
  }, [points, isSelected, isAnyMarkupBeingDragged, setIsHovered]);

  const onPointerOut = useCallback(() => {
    if (!transform || isViewOnly) {
      return null;
    }
    if (!isSelected && !isAnyMarkupBeingDragged) {
      changeCursor('crosshair');
      setIsHovered(false);
    }
  }, [points, isSelected, isAnyMarkupBeingDragged, setIsHovered]);

  const onPointerUp = (e) => {
    if (isViewOnly || !transform) {
      return;
    }
    e.stopPropagation();
    if (!isSelected) {
      dispatch(selectMarkup({ id }));
    }
  };

  useEffect(() => {
    if (!isSelected) {
      setIsEditable(false);
    } else if (height === 0) {
      // If height === 0 it means it was just created and should be editable
      setIsEditable(true);
    }
  }, [isSelected]);

  // const onDoubleClick = useCallback(() => {
  //   console.log('dclick');
  //   setIsEditable(true);
  // }, []);

  if (!transform) {
    return null;
  }

  return (
    <>
      <TextMarkupTextField
        framePoints={framePoints}
        height={height}
        id={id}
        isEditable={isEditable}
        color={isHovered ? shadeHexColor(color, -0.2) : color}
        points={points}
        markup={markup}
        innerWidth={innerWidth}
        isViewOnly={isViewOnly}
      />
      <Plane
        name="textPlane"
        args={[innerWidth + (2 * PADDING), innerHeight + (2 * PADDING)]}
        position={[center[0], center[1], Z_INDEX.GRAPHICS]}
      >
        <meshBasicMaterial attach="material" color="white" />
      </Plane>
      <Plane
        onPointerUp={onPointerUp}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        name="textBorder"
        args={[innerWidth + (2 * (BORDER_WIDTH + PADDING)), innerHeight + (2 * (BORDER_WIDTH + PADDING))]}
        position={[center[0], center[1], Z_INDEX.TEXT_BORDER]}
      >
        <meshBasicMaterial attach="material" color={(isHovered || isEditable) ? shadeHexColor(color, -0.2) : color} />
      </Plane>
      {isSelected && (
      <MarkupFrame
        points={framePoints}
        center={center}
        rotation={0}
        onFrameMoved={onFrameMoved}
        onClick={onClick}
      />
      )}
      {isSelected && points.map((p, index) => (
        <TansformPoint
          key={index}
          pointIndex={index}
          position={[...p, 20]}
          onPointMoved={onPointMoved}
          center={center}
          onPointerUp={(event) => {
            lastMoveTimeStamp.current = event.timeStamp;
          }}
          cursor="crosshair"
        />
      ))}
    </>
  );
};

TextMarkup.propTypes = {
  id: PropTypes.string,
  markup: PropTypes.object,
  isViewOnly: PropTypes.bool,
};

export default React.memo(TextMarkup);
