import React from 'react';

// currentcolor
export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="18.3333" height="3.66667" transform="matrix(0 -1 -1 0 20.5669 20.4333)" stroke="currentcolor" strokeWidth="0.75" strokeLinejoin="round" />
    <rect width="13.2" height="3.66667" transform="matrix(0 -1 -1 0 13.9001 20.4333)" stroke="currentcolor" strokeWidth="0.75" strokeLinejoin="round" />
    <rect width="9.53333" height="3.66667" transform="matrix(0 -1 -1 0 7.2334 20.4333)" stroke="currentcolor" strokeWidth="0.75" strokeLinejoin="round" />
    <path d="M23 23H2C1.44772 23 1 22.5523 1 22V1" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
