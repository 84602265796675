import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useDrag } from 'react-use-gesture';
import { projectPointToPolygon } from './sketchHelpers';
import { notifyDragFinished } from '../../helpers/ThreeHelpers';
import { changeCursor } from '../../helpers/SelectionHelpers';
import * as SketchToolReducer from '../../../../store/sketchToolStore';
import images from '../../../../styles/static/img/feasibility';
import TexturedPlane from '../TexturedPlane';

const PLANE_SIZE = 5;

const ParkingEntrance = (props) => {
  const { position, angle, points, interactiveParkingCallback, globalPointInProgress } = props;
  const dispatch = useDispatch();
  const setParkingEntrancePosition = (p) => dispatch(SketchToolReducer.setParkingEntrancePosition(p));

  const mesh = useRef();

  const bindDrag = useDrag(
    ({ event }) => {
      changeCursor('all-scroll');
      //  event.stopPropagation();
      const object = mesh.current || event.object;

      if (object) {
        if (event.buttons === 1) {
          setParkingEntrancePosition(projectPointToPolygon(globalPointInProgress || [object.position.x, object.position.y], points, ParkingEntrance.PLANE_SIZE));
          // onEntranceMoved(event, [object.position.x, object.position.y], pop);
        }
        if (event.type === 'pointerup') {
          notifyDragFinished();
        }
      }
    },
    { pointerEvents: true },
  );

  return (
    <TexturedPlane
      {...props}
      ref={mesh}
      {...bindDrag()}
      onPointerOver={() => {
        changeCursor('all-scroll');
        interactiveParkingCallback(true);
      }}
      onPointerOut={() => interactiveParkingCallback(false)}
      args={[2, 1]}
      image={images.parkingEntrance}
      size={PLANE_SIZE}
      position={[...position, 25]}
      rotation={[0, 0, angle]}
    />
  );
};

ParkingEntrance.propTypes = {
  position: PropTypes.array,
  onEntranceMoved: PropTypes.func,
  texture: PropTypes.any,
  interactiveParkingCallback: PropTypes.func,
  angle: PropTypes.number,
  globalPointInProgress: PropTypes.array,
  points: PropTypes.array,
};

ParkingEntrance.PLANE_SIZE = PLANE_SIZE;

export default React.memo(ParkingEntrance);
