/* eslint-disable no-unused-expressions */
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Form, message } from 'antd';
import T from 'i18n-react';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashGet from 'lodash/get';
import { GENERATE_SWAPP_FORM } from 'constants/fieldNames';
import { PROJECT_STAGES } from 'constants/profileConsts';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import { setProjectSiteData } from 'utils/model/projectProfileData';
import { userSelector } from 'store/user';
import { useKeyPress } from 'utils/hooks';
import { PrimaryButton } from 'styles/commonComponents.styles';
import {
  createProjectAction,
  generateSiteData,
  updateProjectProfileAction,
} from 'store/swappProfile/actions/swappProfileActions';
import ArcGisMap from './ArcGis/ArcGisMap';
import { GEOCODING_URL, ITM_PROJECTION_WKID, ZOOM_LEVELS } from './ArcGis/ArcGisConsts';
import { wktToEsriCenter, getUnionedEsriPolygon, getEsriModules, getSitePolygonProperties } from './ArcGis/ArcGisMapHelpers';
import { getBlockData } from './ArcGis/ArcGisProxy';
import NewProjectForm from './ArcGis/NewProjectForm';

const { studies, program } = UI_AUTHORIZE_PATH;

const FeasibilitySiteGenerator = () => {
  const [searchType, setSearchType] = useState(NewProjectForm.SEARCH_TYPES.ADDRESS);
  const user = useSelector(userSelector);
  const isShiftPressed = useKeyPress('Shift');
  const isUsingSelfServeFeasibility = lodashGet(user, 'settings.defaultFeasibilitySiteId', 0) === -1;
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isBlockLotVisible, setIsBlockLotVisible] = useState(true);
  const [selectedLots, setSelectedLots] = useState([]);
  const [searchedBlock, setSearchedBlock] = useState();
  const [searchedLotId, setSearchedLotId] = useState(null);
  const [currentPolygon, setCurrentPolygon] = useState();
  const [isFetchingData, setIsFetchingData] = useState(true);

  const mapViewRef = useRef();

  const isValidSitePolygon = (withErrors = true) => {
    if (!currentPolygon && !selectedLots.length) {
      withErrors && message.error(T.translate('NEW_PROJECT_ERROR_NO_POLYGON'));
      return;
    }

    if (currentPolygon) {
      if (currentPolygon.rings[0].length < 3) {
        message.error(T.translate('NEW_PROJECT_ERROR_NO_POLYGON'));
        return false;
      }
      if (currentPolygon.rings.length > 1) {
        withErrors && message.error(T.translate('NEW_PROJECT_ERROR_MULTIPOLYGON'));
        return false;
      }
      if (currentPolygon.rings.length === 1) {
        return true;
      }
      withErrors && message.error(T.translate('NEW_PROJECT_ERROR_UNABLE_TO_GENERATE_POLYGON'));
      return false;
    }
  };

  const onBlockLotVisibilitySwitchChanged = (isChecked) => {
    setIsBlockLotVisible(isChecked);
  };

  const logErros = () => {
    const errorsToCheck = {
      [GENERATE_SWAPP_FORM.FILES]: !lodashIsEmpty(form.getFieldError(GENERATE_SWAPP_FORM.FILES)),
      [GENERATE_SWAPP_FORM.LOCATION_NAME]: !lodashIsEmpty(form.getFieldError(GENERATE_SWAPP_FORM.LOCATION_NAME)),
      [GENERATE_SWAPP_FORM.LOCATION]: !lodashIsEmpty(form.getFieldError(GENERATE_SWAPP_FORM.LOCATION)),
    };
    console.error(errorsToCheck);
  };

  const handleSubmit = () => {
    form.validateFields()
      .then(async (values) => {
        const formData = new FormData();

        // Pops UI message if not a valid polygon
        if (!isValidSitePolygon()) {
          return;
        }

        const { area, centroid, coordinates } = await getSitePolygonProperties(currentPolygon);

        formData.append(GENERATE_SWAPP_FORM.THUMBNAIL, values[GENERATE_SWAPP_FORM.THUMBNAIL]);

        if (values.search) {
          formData.append(GENERATE_SWAPP_FORM.LOCATION_NAME, values.search);
        }

        formData.append(GENERATE_SWAPP_FORM.LAT, centroid.latitude);
        formData.append(GENERATE_SWAPP_FORM.LNG, centroid.longitude);
        formData.append(GENERATE_SWAPP_FORM.PROJECT_NAME, values[GENERATE_SWAPP_FORM.PROJECT_NAME]);
        formData.append(GENERATE_SWAPP_FORM.ROOT_STAGE_KEY, PROJECT_STAGES[UI_AUTHORIZE_PATH.FEASIBILITY_STUDY]);

        dispatch(createProjectAction(formData, history))
          .then((data) => {
            setTimeout(() => { // set time out to show off the check mark in the progress component
              history.push({
                pathname: `/${UI_AUTHORIZE_PATH.FEASIBILITY_STUDY}/${data.id}/${studies}/${program}`,
                state: { isJustCreated: true },
              });
              message.success(T.translate('SET_LOCATION_SELECT_SWAPP_CREATED'));
              if (isUsingSelfServeFeasibility) {
                const blockLotData = selectedLots.map((lot) => ({ lot: lot.attributes.parcel, block: lot.attributes.blockid }));
                dispatch(updateProjectProfileAction(data.projectProfiles[0].id, setProjectSiteData(coordinates, area, blockLotData)))
                  .then(() => {
                    dispatch(generateSiteData(data.projectProfiles[0].id));
                  });
              }
            }, 400);
          }).catch((error) => message.error(error));
      }).catch(() => logErros());
  };

  const handleThumbnailUpload = (file) => {
    form.setFieldsValue({ [GENERATE_SWAPP_FORM.THUMBNAIL]: file.file });
  };

  const handleNameChanged = (value) => {
    form.setFieldsValue({ [GENERATE_SWAPP_FORM.PROJECT_NAME]: value });
  };

  const onLotClicked = async (lot) => {
    if (lot?.error) {
      message.error(T.translate('NEW_LOCATION_SELECT_LOT_NOT_FOUND', { context: { block: searchedBlock.id, lot: lot.lotId } }));
      return;
    }
    if (!lot) {
      setSelectedLots([]);
      return;
    }
    let newSelectedLots;
    if (isShiftPressed) {
      if (selectedLots.includes(lot)) {
        newSelectedLots = selectedLots.filter((item) => item !== lot);
      } else {
        newSelectedLots = [...selectedLots, lot];
      }
    } else {
      newSelectedLots = [lot];
    }

    setSelectedLots(newSelectedLots);

    if (newSelectedLots.length) {
      const unionedPolygon = getUnionedEsriPolygon(newSelectedLots);
      setCurrentPolygon(unionedPolygon);
    }
  };

  const updateSketchedPolygon = async (geometry) => {
    if (geometry?.rings?.length) {
      setCurrentPolygon(geometry);
    }
    setSelectedLots([]);
  };

  const onSearchBlockSuggestionSelected = async (suggestion) => {
    form.setFieldsValue({ search: suggestion.text });
    if (searchType === NewProjectForm.SEARCH_TYPES.ADDRESS) {
      const { locator } = await getEsriModules();
      locator.addressToLocations(GEOCODING_URL, { magicKey: suggestion.magicKey }).then((results) => {
        if (results[0]?.extent) {
          mapViewRef.current.goTo(results[0]?.extent).then(() => setSearchedBlock({ magicKey: suggestion.magicKey }));
        }
      });
    } else if (searchType === NewProjectForm.SEARCH_TYPES.BLOCK) {
      const blockId = suggestion.text;
      const blockdata = await getBlockData(blockId);
      if (blockdata) {
        blockdata.wkid = ITM_PROJECTION_WKID;
        // eslint-disable-next-line prefer-destructuring
        const target = wktToEsriCenter(blockdata?.geometry);
        blockdata.geometry = blockdata.itm_geometry;
        mapViewRef.current.goTo({
          center: target,
          zoom: ZOOM_LEVELS.STREET_BLOCK,
        }).then(() => setSearchedBlock(blockdata));
      } else {
        message.error(T.translate('SET_LOCATION_SELECT_BLOCK_NOT_FOUND'));
      }
    }
  };

  const onSearchTypeClicked = ({ target }) => {
    setSearchType(target?.value);
  };

  const onLotTermSelected = (lotId) => {
    setSearchedLotId(lotId);
  };

  return (
    <>
      <Form form={form} name="generateSwappForm">
        <NewProjectForm
          handleThumbnailUpload={handleThumbnailUpload}
          onSearchBlockSuggestionSelected={onSearchBlockSuggestionSelected}
          onLotTermSelected={onLotTermSelected}
          onNameChanged={handleNameChanged}
          searchType={searchType}
          searchedBlock={searchedBlock}
          onSearchTypeClicked={onSearchTypeClicked}
          center={mapViewRef.current?.center}
          onBlockLotVisibilitySwitchChanged={onBlockLotVisibilitySwitchChanged}
          isFetchingData={isFetchingData}
        />
      </Form>
      <ArcGisMap
        onLotClicked={onLotClicked}
        ref={mapViewRef}
        searchedBlock={searchedBlock}
        searchedLotId={searchedLotId}
        onPolygonDrawUpdated={updateSketchedPolygon}
        onLoadingStateChange={setIsFetchingData}
        isBlockLotVisible={isBlockLotVisible}
        isShiftPressed={isShiftPressed}
        selectedLots={selectedLots}
      />
      <div style={{ position: 'absolute', left: '50vw', bottom: '0.3vh', transform: 'translateY(0%) translateX(-50%)' }}>
        <PrimaryButton
          center
          width={240}
          marginBottom={18}
          onClick={handleSubmit}
        >{T.translate('PLAN_UPLOAD_NEXT_BUTTON')}
        </PrimaryButton>
      </div>
    </>
  );
};

export default FeasibilitySiteGenerator;
