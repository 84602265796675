import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { Page, Header, HeaderLogo, HeaderText, Content,
  ContentFlex, Footer, SwappLogo, SwappLogoWrapper,
} from './PdfExportContainer.styles';
import swappLogo from '../../styles/static/icons/comon/swappLogo';
import { renderProjectName } from '../../utils/helpers/dataDisplay';
import companyIcons from '../../styles/static/icons/companyIcons';
import { pageWidthInPx, pageHeightInPx, pdfPageIdName } from '../../constants/pdfConsts';
import { getProfileByIdSelector } from '../../store/swappProfile';

const PageTemplate = (props) => {
  const { children, swappProject, id, hideHeader, footerWithColor, center, companyKey, companyColor } = props;
  const match = useRouteMatch();
  const profile = useSelector((state) => getProfileByIdSelector(state, match.params.profileId));

  return (
    <Page id={`${pdfPageIdName}${id}`} width={pageWidthInPx} height={pageHeightInPx}>
      {!hideHeader && (
      <Header>
        <HeaderLogo component={companyIcons[companyKey].small} />
        <HeaderText>
          {`${T.translate('PDF_EXPORT_PROJECT')}: 
          ${renderProjectName(swappProject.name)} - ${profile.name}`}
        </HeaderText>
      </Header>
      )}
      <Content center={center}>
        <ContentFlex center={center}>
          {children}
        </ContentFlex>
      </Content>
      <Footer withColor={footerWithColor} companyColor={companyColor}>
        <SwappLogoWrapper>
          {T.translate('PDF_EXPORT_POWERED_BY')}
          <SwappLogo component={swappLogo} />
        </SwappLogoWrapper>
      </Footer>
    </Page>
  );
};

PageTemplate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  swappProject: PropTypes.object,
  id: PropTypes.number,
  hideHeader: PropTypes.bool,
  footerWithColor: PropTypes.bool,
  center: PropTypes.bool,
  companyColor: PropTypes.string,
  companyKey: PropTypes.string,
};

export default PageTemplate;
