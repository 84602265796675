import { useRef, useEffect } from 'react';
import { useKeyPress } from './useKeyPress';

export const useHistory = (setState, { useKeyboardshortcut }) => {
  const undoList = useRef([]);
  const index = useRef(-1);

  const isCtrlPressed = useKeyboardshortcut && useKeyPress('Control');
  const isZButtonPressed = useKeyboardshortcut && useKeyPress('z');

  useEffect(() => {
    if (isCtrlPressed && isZButtonPressed) {
      undo();
    }
  }, [isCtrlPressed, isZButtonPressed]);

  const updateHistory = (state) => {
    index.current++;
    undoList.current[index.current] = state;
  };

  const undo = () => {
    index.current--;
    const prevState = undoList.current[index.current] || [];
    undoList.current.length = Math.max(index.current, 0);
    setState(prevState);
  };

  const setStateWithHistory = (state) => {
    updateHistory(state);
    setState(state);
  };

  return { setStateWithHistory, updateHistory, undo };
};
