import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import T from 'i18n-react';
import {
  getProfileByIdSelector,
  loadingSelector,
} from 'store/swappProfile/selectors';
import { handleOnChange } from 'utils/helpers/feasibilityReportHelpers';
import { isSharedUrl, parseLocationUrl } from 'utils/helpers/navigationHelpers';
import PropTypes from 'prop-types';
import {
  ContentColumnWrapper,
  ContentRowWrapper,
  ContentTitle,
  VerticalTitleContentWrapper,
} from '../report/ReportPages.styles';
import ConstructionCostTable from './ConstructionCostTable';
import ConstructionCostBar from './ConstructionCostBar';

const ConstructionCost = (props) => {
  const { data, form, formData } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const locationData = parseLocationUrl(history.location);
  const profile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const isLoading = useSelector(loadingSelector);

  const disabled = isSharedUrl();
  const onChange = () => {
    if (form) {
      handleOnChange(dispatch, { profile, formInstance: form, isLoading, disabled });
    }
  };

  const options = { data, formData, formInstance: form, onChange, disabled };

  return (
    <VerticalTitleContentWrapper marginBottom={45}>
      <ContentTitle>{T.translate('Construction Cost')}</ContentTitle>
      <ContentRowWrapper>
        <ContentColumnWrapper marginRight={15} width={360}>
          <ConstructionCostBar {...options} />
        </ContentColumnWrapper>
        <ContentColumnWrapper width={820} scroll>
          <ConstructionCostTable {...options} />
        </ContentColumnWrapper>
      </ContentRowWrapper>
    </VerticalTitleContentWrapper>
  );
};

ConstructionCost.propTypes = {
  data: PropTypes.object,
  form: PropTypes.object,
  formData: PropTypes.object,
};
export default React.memo(ConstructionCost);
