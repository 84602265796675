import hinesSmall from './hinesSmall';
import hinesBig from './hinesBig';
import swappSmall from './swappSmall';
import swappBig from './swappBig';
import techSpaceBig from './techSpaceBig';
import techSpaceSmall from './techSpaceSmall';
import tidharBig from './tidharBig';
import tidharSmall from './tidharSmall';
import menkitiBig from './menkitiBig';
import menkitiSmall from './menkitiSmall';
import collidaIcon from './collidaIcon';
import cushmanSmall from './cushmanSmall';
import cushmanBig from './cushmanBig';
import cbreBig from './cbreBig';
import cbreSmall from './cbreSmall';
import commondeskSmall from './commondeskSmall';
import cbreSmcommondeskBigall from './commondeskBig';
import tishmanSpeyerSmall from './tishmanSpeyerSmall';
import tishmanSpeyerBig from './tishmanSpeyerBig';
import mindspaceSmall from './mindspaceSmall';
import mindspaceBig from './mindspaceBig';

export default {
  hines: {
    small: hinesSmall,
    big: hinesBig,
    color: '#B70021',
  },
  swapp: {
    small: swappSmall,
    big: swappBig,
    color: '#5772FF',
  },
  techSpace: {
    small: techSpaceSmall,
    big: techSpaceBig,
    color: '#514F90',
  },
  tidhar: {
    small: tidharSmall,
    big: tidharBig,
    color: '#E5B024',
  },
  menkiti: {
    small: menkitiSmall,
    big: menkitiBig,
    color: '#AF2227',
  },
  cushman: {
    small: cushmanSmall,
    big: cushmanBig,
    color: '#AF2227',
  },
  commonDesk: {
    small: commondeskSmall,
    big: cbreSmcommondeskBigall,
    color: '#314049',
  },
  cbre: {
    small: cbreSmall,
    big: cbreBig,
    color: '#006A4D',
  },
  tishmanSpeyer: {
    small: tishmanSpeyerSmall,
    big: tishmanSpeyerBig,
    color: '#808080',
  },
  collida: {
    header: collidaIcon,
    color: '#FF6814',
  },
  mindspace: {
    small: mindspaceSmall,
    big: mindspaceBig,
    color: '#000000',
  },
};
