import React, { useState } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { Tooltip } from 'antd';
import Icon, { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { shallowEqual, useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { useHistory } from 'react-router';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import PdfExportContainer from './PdfExportContainer';
import { loadingSelector, swappProfileResultsSelector, swappProjectSelector } from '../../store/swappProfile';
import LoadingSpinner from '../common/LoadingSpinner';
import downloadPdfIcon from '../../styles/static/icons/comon/downloadPdfIcon';

const PdfDownloadButtonWrapper = styled.div`
  cursor: ${({ isPdfGenerating }) => (isPdfGenerating ? 'wait' : 'pointer')};
  background-color: ${({ theme }) => theme.colors.gray_01};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  height: 50px;
  width: 50px;
  padding: 9px 9px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.12);
  margin-right: 10px;
  position: relative;
  
  svg {
    font-size: 25px;
    color: ${({ theme, isPdfGenerating }) => (isPdfGenerating ? theme.colors.primaryColor : theme.colors.gray_09)};
  }
  
  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.primaryColor};
    }
  }
`;

const PdfContentWrapper = styled.div`
  width: 1px;
  height: 1px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.gray_01};
  position: absolute;
`;

const PdfDownloadButton = (props) => {
  const { onStart, onFinish, content } = props;
  const [isPdfGenerating, useIsPdfGenerating] = useState(false);
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const swappProfileResults = useSelector((state) => swappProfileResultsSelector(state, locationData.profileId, isImperial), shallowEqual);
  const swappProject = useSelector(swappProjectSelector);
  const isLoading = useSelector(loadingSelector);

  if (isLoading || !swappProfileResults || !swappProject) {
    return <LoadingSpinner />;
  }

  if (!lodashGet(swappProfileResults, 'pdfURL')) {
    return null;
  }

  const toggleIsPdfGenerating = (value, update) => {
    update(value);
  };

  const handleOnStart = (update) => {
    if (onStart) {
      onStart();
    }
    toggleIsPdfGenerating(true, update);
  };

  const handleOnFinish = (update) => {
    toggleIsPdfGenerating(false, update);
    if (onFinish) {
      onFinish();
    }
  };

  return (
    <React.Fragment>
      <PdfDownloadButtonWrapper onClick={() => (isPdfGenerating ? null : handleOnStart(useIsPdfGenerating))} isPdfGenerating={isPdfGenerating}>
        <Tooltip title={T.translate('PDF_EXPORT_TOOLTIP')}>
          {isPdfGenerating ? <LoadingOutlined spin /> : <Icon component={downloadPdfIcon} />}
        </Tooltip>
      </PdfDownloadButtonWrapper>
      <PdfContentWrapper>
        {isPdfGenerating && (
        <PdfExportContainer
          isImperial={isImperial}
          onPdfDoneLoading={() => handleOnFinish(useIsPdfGenerating)}
          swappProfileResults={swappProfileResults}
          swappProject={swappProject}
        >
          {content}
        </PdfExportContainer>
        )}
      </PdfContentWrapper>
    </React.Fragment>
  );
};

PdfDownloadButton.propTypes = {
  onStart: PropTypes.func,
  onFinish: PropTypes.func,
  content: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
};

export default PdfDownloadButton;
