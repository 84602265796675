import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTyeps from 'prop-types';
import { CheckCircleOutlined } from '@ant-design/icons';
import T from 'i18n-react';
import Notification from '../common/Notification';
import { StyledFormItem, PrimaryButton } from '../../styles/commonComponents.styles';

const SuccessNotification = (props) => {
  const { history, route, hints, notification, buttonText } = props;
  const redirectToMainPage = () => history.push(route);

  return (
    <React.Fragment>
      <Notification iconComponent={<CheckCircleOutlined />} hints={hints} notification={notification} />
      <StyledFormItem>
        <PrimaryButton onClick={redirectToMainPage}>
          {buttonText || T.translate('AUTH_FORM_CONTINUE')}
        </PrimaryButton>
      </StyledFormItem>
    </React.Fragment>
  );
};

SuccessNotification.propTypes = {
  history: PropTyeps.object,
  route: PropTyeps.string,
  hints: PropTyeps.string,
  notification: PropTyeps.string,
  buttonText: PropTyeps.string,
};

export default withRouter(SuccessNotification);
