import activateIcon from './activateIcon';
import threeDIcon from './threeDIcon';
import closeIcon from './closeIcon';
import deleteIcon from './deleteIcon';
import duplicateIcon from './duplicateIcon';
import transformIcon from './transformIcon';
import undoIcon from './undoIcon';
import setbackIcon from './setbackIcon';
import floorIcon from './floorIcon';
import mirrorIcon from './mirrorIcon';
import duplicateFloorIcon from './duplicateFloorIcon';
import deleteFloorIcon from './deleteFloorIcon';
import openGFloorIcon from './openGFloorIcon';
import createIcon from './createIcon';
import parkingIcon from './parkingIcon';
import bgParkingIcon from './bgParkingIcon';
import saveIcon from './saveIcon';
import precastParkingIcon from './precastParkingIcon';
import surfaceParkingIcon from './surfaceParkingIcon';
import createParkingIcon from './createParkingIcon';
import revitIcon from './revitIcon';
import drawPolygonIcon from './drawPolygonIcon';
import clearPolygonIcon from './clearPolygonIcon';
import sectionLineIcon from './sectionLineIcon';
import scopeBoxIcon from './scopeBoxIcon';
import renderIcon from './renderIcon';

export default {
  activateIcon,
  threeDIcon,
  closeIcon,
  deleteIcon,
  duplicateIcon,
  transformIcon,
  undoIcon,
  setbackIcon,
  floorIcon,
  duplicateFloorIcon,
  deleteFloorIcon,
  openGFloorIcon,
  mirrorIcon,
  createIcon,
  parkingIcon,
  bgParkingIcon,
  createParkingIcon,
  precastParkingIcon,
  surfaceParkingIcon,
  saveIcon,
  revitIcon,
  drawPolygonIcon,
  clearPolygonIcon,
  sectionLineIcon,
  scopeBoxIcon,
  renderIcon,
};
