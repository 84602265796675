import React, { useState, useEffect, useMemo } from 'react';
import T from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import lodashGet from 'lodash/get';
import lodashIncludes from 'lodash/includes';
import { message } from 'antd';
import { WAITING_FOR_KEYS } from 'constants/feasibilityConts';

import { activePollingProfileIdsSelector } from 'store/swappProfile/selectors';
import { getProfileByIdSelector } from 'store/swappProfile';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import {
  generateHbEnergyAnalysis,
  pollSwappProjectAction,
  updateProjectProfileAction,
} from 'store/swappProfile/actions/swappProfileActions';

import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { feasibilityResultModel, useActiveProfileBuildingInfo } from 'utils/model/feasibilityResultModel';
import { setHbEnergyFormData } from 'utils/model/projectProfileData';

import { PROFILE_STATUS } from 'constants/profileConsts';

import Dropdown from 'components/common/Dropdown';
import InfoBox from 'components/common/InfoBox';
import { GenerateBtn, WwrSlider } from './components/formComponents';
import { EnergyRequirementsFormWrapper, FormWrapper, Title } from './form.styles';
import { formInputsData } from './components/values';

const DEBOUNCE_SAVE_DATA_MS = 800;

const DEFAULT_REQUIREMENTS = {
  wwr: formInputsData.wwr.defaultValue,
  vintage: formInputsData.vintage.defaultValue,
  hvacSystem: formInputsData.hvacSystem.defaultValue,
  buildingProgram: formInputsData.buildingProgram.defaultValue,
  sensibleHeatRecovery: formInputsData.sensibleHeatRecovery.defaultValue,
  latentHeatRecovery: formInputsData.latentHeatRecovery.defaultValue,
  constructionType: formInputsData.constructionType.defaultValue,
  lightingWattsPerArea: formInputsData.lightingWattsPerArea.defaultValue,
  equipmentWattsPerArea: formInputsData.equipmentWattsPerArea.defaultValue,
  serviceHotWater: formInputsData.serviceHotWater.defaultValue,
};

const EnergyRequirementsForm = () => {
  let debounceTimer = null;
  const history = useHistory();
  const dispatch = useDispatch();

  const buildingInfo = useActiveProfileBuildingInfo();
  const locationData = parseLocationUrl(window.location);
  const profile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));

  const modelData = useMemo(() => feasibilityResultModel(profile.result, isImperial, buildingInfo), [profile.result, isImperial]);
  // eslint-disable-next-line no-unused-vars
  const profileUnits = lodashGet(modelData, 'buildingInfoBTable');

  const activePollingProfileIds = useSelector(activePollingProfileIdsSelector);
  const formData = lodashGet(profile, 'profileData.hbEnergyAnalysisDataForm');

  const isProfileLoading = lodashGet(profile, 'status') === PROFILE_STATUS.WAITING_FOR_EXTRA_DATA;
  const isWaitingFor = lodashGet(profile, `result.waitingFor[${WAITING_FOR_KEYS.HB_ENERGY}]`);
  const isLoading = isProfileLoading && isWaitingFor;

  const [currentFormData, setCurrentFormData] = useState({ ...DEFAULT_REQUIREMENTS, ...formData });

  const updateFormData = (partialFormData) => {
    setCurrentFormData({ ...currentFormData, ...partialFormData });
  };

  useEffect(() => {
    if (isLoading && locationData.profileId && !lodashIncludes(activePollingProfileIds, locationData.profileId)) {
      dispatch(pollSwappProjectAction(locationData.projectId, locationData.profileId, history, WAITING_FOR_KEYS.HB_ENERGY));
    }
  }, [isLoading, locationData.profileId, activePollingProfileIds]);

  const generateEnergySimulationHandler = () => {
    if (isLoading) return;
    dispatch(generateHbEnergyAnalysis({ formData: currentFormData }, locationData.projectId, locationData.profileId, history));
  };

  const handleChange = () => {
    const data = {
      ...currentFormData,
    };
    dispatch(updateProjectProfileAction(profile.id, setHbEnergyFormData(data)))
      .catch((err) => message.error(err));
  };

  // save user data to server
  useEffect(() => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    debounceTimer = setTimeout(() => {
      handleChange();
    }, DEBOUNCE_SAVE_DATA_MS);
  }, [currentFormData]);

  const formItems = [
    {
      title: T.translate('Window To Wall Ratio'),
      leftInfo: <WwrSlider value={currentFormData.wwr} setValue={(d) => { updateFormData({ wwr: d }); }} />,
    },
    {
      title: T.translate('Building Code (Vintage)'),
      leftInfo: (<Dropdown
        dropDownData={formInputsData.vintage.data}
        selectedKey={currentFormData.vintage}
        onChange={(d) => { updateFormData({ vintage: d }); }}
      />),
    },
    {
      title: T.translate('HVAC System'),
      leftInfo: (<Dropdown
        dropDownData={formInputsData.hvacSystem.data}
        selectedKey={currentFormData.hvacSystem}
        onChange={(d) => { updateFormData({ hvacSystem: d }); }}
      />),
    },
    {
      title: T.translate('Building Program'),
      leftInfo: (<Dropdown
        dropDownData={formInputsData.buildingProgram.data}
        selectedKey={currentFormData.buildingProgram}
        onChange={(d) => { updateFormData({ buildingProgram: d }); }}
      />),
    },
    {
      title: T.translate('sensible Heat Recovery'),
      leftInfo: (<Dropdown
        dropDownData={formInputsData.sensibleHeatRecovery.data}
        selectedKey={currentFormData.sensibleHeatRecovery}
        onChange={(d) => { updateFormData({ sensibleHeatRecovery: d }); }}
      />),
    },
    {
      title: T.translate('latent Heat Recovery'),
      leftInfo: (<Dropdown
        dropDownData={formInputsData.latentHeatRecovery.data}
        selectedKey={currentFormData.latentHeatRecovery}
        onChange={(d) => { updateFormData({ latentHeatRecovery: d }); }}
      />),
    },
    {
      title: T.translate('Construction type'),
      leftInfo: (<Dropdown
        dropDownData={formInputsData.constructionType.data}
        selectedKey={currentFormData.constructionType}
        onChange={(d) => { updateFormData({ constructionType: d }); }}
      />),
    },
    {
      title: T.translate('Lighting Watts Per Area'),
      leftInfo: (<Dropdown
        dropDownData={formInputsData.lightingWattsPerArea.data}
        selectedKey={currentFormData.lightingWattsPerArea}
        onChange={(d) => { updateFormData({ lightingWattsPerArea: d }); }}
      />),
    },
    {
      title: T.translate('Equipment Watts Per Area'),
      leftInfo: (<Dropdown
        dropDownData={formInputsData.equipmentWattsPerArea.data}
        selectedKey={currentFormData.equipmentWattsPerArea}
        onChange={(d) => { updateFormData({ equipmentWattsPerArea: d }); }}
      />),
    },
    {
      title: T.translate('Service Hot Water'),
      leftInfo: (<Dropdown
        dropDownData={formInputsData.serviceHotWater.data}
        selectedKey={currentFormData.serviceHotWater}
        onChange={(d) => { updateFormData({ serviceHotWater: d }); }}
      />),
    },
  ];

  return (
    <EnergyRequirementsFormWrapper>
      <Title>{T.translate('Energy Model Inputs')}</Title>

      <FormWrapper>
        {formItems.map((item) => (
          <InfoBox
            width={250}
            data={item}
          />
        ))}
      </FormWrapper>

      <GenerateBtn isLoading={isLoading} clickHandler={generateEnergySimulationHandler} />
    </EnergyRequirementsFormWrapper>
  );
};

export default EnergyRequirementsForm;
