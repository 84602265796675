import { getTestFitColors } from 'utils/helpers/parseTestFitStandards';

export const THEME_KEYS = {
  DEFAULT: 'DEFAULT',
  DARK: 'DARK',
};

const defaultTheme = {
  colors: {
    primaryColor: '#1C2BF2',
    primaryColorHover: '#5773FF',
    primaryColorDisabled: '#82B0FF',

    danger: '#ff3636',
    warning: '#faad14',
    success: '#5ac725',

    testFit: '#82C7AA',
    feasibilityStudy: '#76348c',
    projectSettings: '#4a79ac',

    selected3dObject: '#5772ff',
    white: '#FFFFFF',
    black: '#44465A',
    backgroundColor: '#EDF0F5',
    textColor: '#262A53',
    inputBackground: 'rgba(255, 255, 255, 0.3)',

    gray_01: '#F9F9F9',
    gray_02: '#F1F1F1',
    gray_03: '#EAEAEA',
    gray_04: '#E0E0E0',
    gray_05: '#CCCCCC',
    gray_06: '#C2C2C2',
    gray_07: '#B8B8B8',
    gray_08: '#ADADAD',
    gray_09: '#7A7A7A',
    gray_10: '#3D3D3D',

    headerBackground: '#FFFFFF',
    HeaderText: '#171717',

    layers: {
      ...getTestFitColors().DEFAULT,
      EMPTY: {
        MAIN: '#dedede',
      },
      OTHER: {
        leftover: '#d2d2d2',
        disabled: '#ffffff',
      },
    },
  },

  font: {
    size: {
      xSmall: '11px',
      small: '12px',
      medium: '14px',
      large: '16px',
      xLarge: '18px',
      xxLarge: '20px',
      inputLabel: '12px',
    },
    weight: {
      light: '300',
      normal: '400',
      bold: '600',
      xBold: '700',
    },
  },
  other: {
    borderRadius: '7px',
    authFormWidth: '360px',
    headerHeight: 60,
    shadow: '0px 7px 8px 0px rgba(20,20,20,0.15)',
  },
};

const darkTheme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    backgroundColor: '#1a1d21',

    gray_01: '#2b2b31',
    gray_02: '#4b4d55',
    gray_03: '#61606f',
    gray_04: '#9797af',
    gray_05: '#a3a2bb',
    gray_06: '#a8a9c7',
    gray_07: '#cacad6',
    gray_08: '#dcdde5',
    gray_09: '#e8e9ee',
    gray_10: '#f6f6f9',

    sidebarBackground: '#212121',
    sidebarSelectedBackground: '#4e5051',
    sidebarText: '#b5b5b5',
    sidebarTextSelected: '#ffffff',
    sidebarSelectedBar: '#d3d8db',

    headerBackground: '#313035',
    HeaderText: '#d6d5d5',

  },
};

const defaultThemes = {
  [THEME_KEYS.DEFAULT]: defaultTheme,
  [THEME_KEYS.DARK]: darkTheme,
};

const standardThemes = () => {
  const currentStandardThemes = getTestFitColors();
  const standardKeys = Object.keys(currentStandardThemes).filter((key) => key !== 'DEFAULT');

  const addedStandardThemes = {};
  // eslint-disable-next-line no-return-assign
  standardKeys.forEach((standardKey) => addedStandardThemes[standardKey] = {
    ...defaultTheme,
    colors: {
      ...defaultTheme.colors,
      layers: {
        ...defaultTheme.colors.layers,
        ...currentStandardThemes[standardKey],
      },
    },
  });

  return addedStandardThemes;
};

export const themes = {
  ...defaultThemes,
  ...standardThemes(),
};
