import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router';
import { shallowEqual, useSelector } from 'react-redux';
import T from 'i18n-react';
import lodashSum from 'lodash/sum';
import lodashGet from 'lodash/get';
import { Tooltip } from 'antd';
import { StyledFormItem, StyledInputNumber, StyledReportTable } from 'styles/commonComponents.styles';
import { CONSTRUCTION_COST_TABLE, CONSTRUCTION_COST_FORM } from 'constants/feasibilityReportConts';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { supportedCurrencies } from 'constants/currencies';
import { currentCurrencySelector, getUnitSystemByProjectIdSelector } from 'store/userSettings';
import CurrencyInput from '../../../../common/CurrencyInput';
import { TableBoxWrapper, TableTotalCell, TableHeaderSelectableCell, ClickableTableCell } from '../report/ReportPages.styles';

const ROW_STYLE_TYPES = {
  TOTAL: 'TOTAL',
  SUB_ITEM: 'SUB_ITEM',
};

const tableStructure = [
  { code: 1, key: 'SUBSTRUCTURE', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Substructure', initialValue: 3 },
  {
    code: 2,
    key: 'Superstructure',
    [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Superstructure',
    children: [
      { key: 'FRAME', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Frame', initialValue: 10.5 },
      { key: 'BALCONIES', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Balconies', initialValue: 2 },
      { key: 'ROOF', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Roof', initialValue: 2 },
      { key: 'STAIRS_AND_RAMPS', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Stairs and Ramps', initialValue: 1.5 },
      { key: 'EXTERNAL_WALLS', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'External Walls', initialValue: 9 },
      { key: 'WINDOWS_AND_EXTERNAL_DOORS', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Windows and External Doors', initialValue: 6 },
      { key: 'INTERNAL_WALLS_AND_PARTITIONS', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Internal Walls and Partitions', initialValue: 3.5 },
      { key: 'INTERNAL_DOORS', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Internal Doors', initialValue: 2.5 },
    ],
  },
  {
    code: 3,
    key: 'Internal Finishes',
    [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Internal Finishes',
    children: [
      { key: 'WALL_FINISHES', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Wall Finishes', initialValue: 1 },
      { key: 'FLOOR_FINISHES', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Floor Finishes', initialValue: 2.7 },
      { key: 'CEILING_FINISHES', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Ceiling Finishes', initialValue: 1.8 },
    ],
  },
  { code: 4, key: 'FFNE', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'FF&E', initialValue: 2 },
  { code: 5, key: 'SERVICES', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Services', initialValue: 20 },
  { code: 6, key: 'PREFABRICATED_BUILDINGS_UNITS', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Prefabricated Buildings & Units', initialValue: 0 },
  { code: 7, key: 'WORK_TO_EXISTING_BUILDING', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Work to existing Building', initialValue: 0 },
  { code: 8, key: 'EXTERNAL_WORKS', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'External Works', initialValue: 7.5 },
  { key: 'SUB_TOTAL', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Sub-Total: Building Works', type: ROW_STYLE_TYPES.TOTAL },
  { code: 9, key: 'MAIN_CONTRACTORS_PRELIMINARIES', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Main Contractor\'s Preliminaries', initialValue: 13.5 },
  { code: 10, key: 'FEES', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Fees', initialValue: 6 },
  { code: 11, key: 'RISKS', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Risks', initialValue: 0 },
  { code: 12, key: 'FIXED_PRICE', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Fixed Price', initialValue: 0 },
  { code: 13, key: 'MAIN_CONTRACTORS', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Main Contractor\'s OH&P', initialValue: 5.5 },
  { code: 14, key: 'OTHER_COSTS', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Other Costs', initialValue: 0 },
  { key: 'PROJECT_TOTAL', [CONSTRUCTION_COST_TABLE.ELEMENT]: 'Project Total (Excluding VAT)', type: ROW_STYLE_TYPES.TOTAL },
];

const ConstructionCostTable = (props) => {
  const { data, formInstance, onChange, disabled } = props;
  const [tableDataSource, setTableDataSource] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState(CONSTRUCTION_COST_TABLE.PERCENTAGE_OF_TOTAL);

  const match = useRouteMatch();
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));
  const currentSelectedObject = useSelector(currentCurrencySelector, shallowEqual);

  const GIA = lodashGet(data, 'GIA', 0);
  const NIA = lodashGet(data, 'NIA', 0);
  const constructionCostValueAreaRatio = (formInstance && formInstance.getFieldValue(CONSTRUCTION_COST_FORM.CONSTRUCTION_COST_VALUE_NIA_RATIO)) || 0;
  const totalConstruction = NIA * constructionCostValueAreaRatio;

  useEffect(() => {
    updateTableDataSource(setTableDataSource);
  }, [formInstance, constructionCostValueAreaRatio, onChange]);

  const handleChange = () => {
    onChange();
    updateTableDataSource(setTableDataSource);
  };

  const updateSelectedHeader = (value, update) => {
    update(value);
  };

  const updateTableDataSource = (update) => {
    const tableWithoutTotal = tableStructure.map((item) => {
      const currentInput = (formInstance && formInstance.getFieldValue(CONSTRUCTION_COST_FORM.CONSTRUCTION_COST_TABLE_VALUES)) || {};
      let children;
      if (item.children) { // set children rows
        children = item.children.map((subItem, subItemIndex) => {
          const percentage = currentInput[subItem.key] ?? subItem.initialValue;
          const totalAreaRatio = (percentage / 100) * totalConstruction;
          const valueAreaRatio = (totalAreaRatio / GIA);

          return {
            ...subItem,
            type: ROW_STYLE_TYPES.SUB_ITEM,
            [CONSTRUCTION_COST_TABLE.ELEMENT]: T.translate(subItem[CONSTRUCTION_COST_TABLE.ELEMENT]),
            [CONSTRUCTION_COST_TABLE.CODE]: `${item.code}.${subItemIndex + 1}`,
            [CONSTRUCTION_COST_TABLE.TOTAL_AREA_RATIO]: totalAreaRatio || 0,
            [CONSTRUCTION_COST_TABLE.VALUE_AREA_RATIO]: valueAreaRatio || 0,
            [CONSTRUCTION_COST_TABLE.PERCENTAGE_OF_TOTAL]: percentage,
          };
        });
      }

      const childrenTotalPercentage = children && lodashSum(children.map((child) => child[CONSTRUCTION_COST_TABLE.PERCENTAGE_OF_TOTAL]));
      const childrenTotalTotalAreaRatio = children && lodashSum(children.map((child) => child[CONSTRUCTION_COST_TABLE.TOTAL_AREA_RATIO]));
      const childrenTotalValueAreaRatio = children && lodashSum(children.map((child) => child[CONSTRUCTION_COST_TABLE.VALUE_AREA_RATIO]));

      const percentage = childrenTotalPercentage || (currentInput[item.key] ?? item.initialValue);
      const totalAreaRatio = childrenTotalTotalAreaRatio || ((percentage / 100) * totalConstruction);
      const valueAreaRatio = childrenTotalValueAreaRatio || (totalAreaRatio / GIA);

      return { // set common rows
        ...item,
        children,
        [CONSTRUCTION_COST_TABLE.ELEMENT]: T.translate(item[CONSTRUCTION_COST_TABLE.ELEMENT]),
        [CONSTRUCTION_COST_TABLE.CODE]: item.code,
        [CONSTRUCTION_COST_TABLE.TOTAL_AREA_RATIO]: totalAreaRatio || 0,
        [CONSTRUCTION_COST_TABLE.VALUE_AREA_RATIO]: valueAreaRatio || 0,
        [CONSTRUCTION_COST_TABLE.PERCENTAGE_OF_TOTAL]: percentage,
      };
    });

    const tableWithTotal = tableWithoutTotal.map((item, index) => { // set Total rows
      if (item.type === ROW_STYLE_TYPES.TOTAL) {
        const relevantRows = [...tableWithoutTotal].slice(0, index); // takes all info up to this row
        const percentage = lodashSum(relevantRows.map((row) => row[CONSTRUCTION_COST_TABLE.PERCENTAGE_OF_TOTAL]));
        const totalAreaRatio = lodashSum(relevantRows.map((row) => row[CONSTRUCTION_COST_TABLE.TOTAL_AREA_RATIO]));
        const valueAreaRatio = lodashSum(relevantRows.map((row) => row[CONSTRUCTION_COST_TABLE.VALUE_AREA_RATIO]));

        return {
          ...item,
          [CONSTRUCTION_COST_TABLE.VALUE_AREA_RATIO]: valueAreaRatio,
          [CONSTRUCTION_COST_TABLE.TOTAL_AREA_RATIO]: totalAreaRatio,
          [CONSTRUCTION_COST_TABLE.PERCENTAGE_OF_TOTAL]: percentage,
        };
      }

      return item;
    });

    update(tableWithTotal);
  };

  const renderPercentageOfTotal = (target, rowData) => {
    if (rowData.type === ROW_STYLE_TYPES.TOTAL) {
      return (
        <TableTotalCell hideColor={rowData.key !== 'PROJECT_TOTAL'} value={Math.round(target)} target={100}>
          {numberWithComma(target, { suffix: '%', fixed: 1 })}
        </TableTotalCell>
      );
    }

    return (selectedHeader === CONSTRUCTION_COST_TABLE.PERCENTAGE_OF_TOTAL && !disabled
      ? (
        <StyledInputNumber
          formatter={(value) => numberWithComma(value, { suffix: '%', fixed: 1 })}
          min={0}
          max={100}
          width={80}
          height={25}
          disabled={rowData.children}
          value={target}
          onChange={(e) => handlePercentageOfTotalChange(e, rowData[CONSTRUCTION_COST_TABLE.KEY])}
        />
      )
      : numberWithComma(target, { suffix: '%', fixed: 1 })
    );
  };

  const renderValueAreaRatio = (target, rowData) => {
    if (rowData.type === ROW_STYLE_TYPES.TOTAL) {
      return (
        <TableTotalCell hideColor={rowData.key !== 'PROJECT_TOTAL'}>
          <CurrencyInput value={target} readOnly unitSystemKey={CurrencyInput.unitSystemKey.sqmToSqf} />
        </TableTotalCell>
      );
    }

    return (selectedHeader === CONSTRUCTION_COST_TABLE.VALUE_AREA_RATIO
      ? (
        <CurrencyInput
          width={80}
          height={25}
          step={10}
          disabled={rowData.children}
          value={target}
          readOnly={disabled}
          unitSystemKey={CurrencyInput.unitSystemKey.sqmToSqf}
          onChange={(e) => handleValueAreaRatioChange(e, rowData[CONSTRUCTION_COST_TABLE.KEY])}
        />
      )
      : <CurrencyInput value={target} readOnly unitSystemKey={CurrencyInput.unitSystemKey.sqmToSqf} />
    );
  };

  const renderTotalAreaRatio = (target, rowData) => {
    if (rowData.type === ROW_STYLE_TYPES.TOTAL) {
      return (
        <TableTotalCell
          hideColor={rowData.key !== 'PROJECT_TOTAL'}
          value={Math.round(rowData[CONSTRUCTION_COST_TABLE.PERCENTAGE_OF_TOTAL]) === 100 ? totalConstruction : target}
          target={totalConstruction}
        >
          <CurrencyInput value={target} readOnly />
        </TableTotalCell>
      );
    }

    return (selectedHeader === CONSTRUCTION_COST_TABLE.TOTAL_AREA_RATIO
      ? (
        <CurrencyInput
          width={120}
          height={25}
          step={10000}
          disabled={rowData.children}
          readOnly={disabled}
          value={target}
          onChange={(e) => handleTotalAreaRatioChange(e, rowData[CONSTRUCTION_COST_TABLE.KEY])}
        />
      )
      : <CurrencyInput value={target} readOnly />
    );
  };

  const columns = [
    {
      dataIndex: CONSTRUCTION_COST_TABLE.CODE,
      key: CONSTRUCTION_COST_TABLE.CODE,
      width: 70,
    },
    {
      title: T.translate('Element'),
      dataIndex: CONSTRUCTION_COST_TABLE.ELEMENT,
      key: CONSTRUCTION_COST_TABLE.ELEMENT,
      align: 'left',
      render: (value) => (value === 'TOTAL' ? T.translate('Total / Average') : T.translate(value)),
    },
    {
      title: (
        <Tooltip title={T.translate('Per GIA')}>
          <TableHeaderSelectableCell
            selected={selectedHeader === CONSTRUCTION_COST_TABLE.VALUE_AREA_RATIO}
            onClick={() => updateSelectedHeader(CONSTRUCTION_COST_TABLE.VALUE_AREA_RATIO, setSelectedHeader)}
          >
            {`${supportedCurrencies[currentSelectedObject.currentCurrencyKey].symbol} / ${T.translate(isImperial ? 'SQF' : 'SQM')}`}
          </TableHeaderSelectableCell>
        </Tooltip>),
      dataIndex: CONSTRUCTION_COST_TABLE.VALUE_AREA_RATIO,
      key: CONSTRUCTION_COST_TABLE.VALUE_AREA_RATIO,
      align: 'right',
      render: (target, rowData) => (
        <ClickableTableCell onClick={() => updateSelectedHeader(CONSTRUCTION_COST_TABLE.VALUE_AREA_RATIO, setSelectedHeader)}>
          {renderValueAreaRatio(target, rowData)}
        </ClickableTableCell>
      ),
    },
    {
      title: (
        <TableHeaderSelectableCell
          selected={selectedHeader === CONSTRUCTION_COST_TABLE.TOTAL_AREA_RATIO}
          onClick={() => updateSelectedHeader(CONSTRUCTION_COST_TABLE.TOTAL_AREA_RATIO, setSelectedHeader)}
        >
          {`${T.translate('Total')} / ${supportedCurrencies[currentSelectedObject.currentCurrencyKey].symbol}`}
        </TableHeaderSelectableCell>
      ),
      dataIndex: CONSTRUCTION_COST_TABLE.TOTAL_AREA_RATIO,
      key: CONSTRUCTION_COST_TABLE.TOTAL_AREA_RATIO,
      align: 'right',
      width: 200,
      render: (target, rowData) => (
        <ClickableTableCell onClick={() => updateSelectedHeader(CONSTRUCTION_COST_TABLE.TOTAL_AREA_RATIO, setSelectedHeader)}>
          {renderTotalAreaRatio(target, rowData)}
        </ClickableTableCell>
      ),
    },
    {
      align: 'right',
      width: 20,
    },
    {
      title: (
        <TableHeaderSelectableCell
          selected={selectedHeader === CONSTRUCTION_COST_TABLE.PERCENTAGE_OF_TOTAL}
          onClick={() => updateSelectedHeader(CONSTRUCTION_COST_TABLE.PERCENTAGE_OF_TOTAL, setSelectedHeader)}
        >
          {T.translate('% of Total')}
        </TableHeaderSelectableCell>
      ),
      dataIndex: CONSTRUCTION_COST_TABLE.PERCENTAGE_OF_TOTAL,
      key: CONSTRUCTION_COST_TABLE.PERCENTAGE_OF_TOTAL,
      align: 'right',
      width: 120,
      render: (target, rowData) => (
        <ClickableTableCell onClick={() => updateSelectedHeader(CONSTRUCTION_COST_TABLE.PERCENTAGE_OF_TOTAL, setSelectedHeader)}>
          {renderPercentageOfTotal(target, rowData)}
        </ClickableTableCell>
      ),
    },
    {
      align: 'right',
      width: 10,
    },
  ];

  const handlePercentageOfTotalChange = (e, unitKey) => {
    const currentInput = formInstance.getFieldValue(CONSTRUCTION_COST_FORM.CONSTRUCTION_COST_TABLE_VALUES) || {};
    formInstance.setFieldsValue({
      [CONSTRUCTION_COST_FORM.CONSTRUCTION_COST_TABLE_VALUES]:
          {
            ...currentInput,
            [unitKey]: e,
          },
    });
    handleChange();
  };

  const handleValueAreaRatioChange = (e, unitKey) => {
    const currentInput = formInstance.getFieldValue(CONSTRUCTION_COST_FORM.CONSTRUCTION_COST_TABLE_VALUES) || {};
    const totalAreaRatio = e * GIA;
    const percentage = (totalAreaRatio / totalConstruction) * 100;
    formInstance.setFieldsValue({
      [CONSTRUCTION_COST_FORM.CONSTRUCTION_COST_TABLE_VALUES]:
          {
            ...currentInput,
            [unitKey]: percentage,
          },
    });
    handleChange();
  };

  const handleTotalAreaRatioChange = (e, unitKey) => {
    const currentInput = formInstance.getFieldValue(CONSTRUCTION_COST_FORM.CONSTRUCTION_COST_TABLE_VALUES) || {};
    const percentage = (e * 100) / totalConstruction;
    formInstance.setFieldsValue({
      [CONSTRUCTION_COST_FORM.CONSTRUCTION_COST_TABLE_VALUES]:
          {
            ...currentInput,
            [unitKey]: percentage,
          },
    });
    handleChange();
  };

  const renderRowClass = (rowData) => {
    switch (rowData.type) {
      case ROW_STYLE_TYPES.TOTAL:
        return 'ant-table-total-row';
      case ROW_STYLE_TYPES.SUB_ITEM:
        return 'ant-table-sub-row';
      default:
        return '';
    }
  };

  return (
    <TableBoxWrapper margin="10px 3px">
      <StyledReportTable
        pagination={false}
        columns={columns}
        dataSource={tableDataSource}
        rowClassName={renderRowClass}
      />
      <StyledFormItem hidden noStyle name={CONSTRUCTION_COST_FORM.CONSTRUCTION_COST_TABLE_VALUES} initialValue={{}} />
    </TableBoxWrapper>
  );
};

ConstructionCostTable.propTypes = {
  data: PropTypes.object,
  formInstance: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ConstructionCostTable;
