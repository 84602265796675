import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import T from 'i18n-react';
import icons from '../../../../styles/static/icons/viewerIcons';
import SwappViewerActionButton from './SwappViewerActionButton';

const CameraProjectionToggleButton = (props) => {
  const { toggle, selected, marginLeft, marginTop, isHidden } = props;

  if (isHidden) {
    return null;
  }

  return (
    <Tooltip title={T.translate('FEASIBILITY_VIEWER_CONTAINER_TWO_D_TOGGLE_TOOLTIP')} mouseEnterDelay={0.5}>
      <SwappViewerActionButton onClick={toggle} icon={icons.threeDIcon} selected={selected} marginLeft={marginLeft} marginTop={marginTop} />
    </Tooltip>
  );
};

CameraProjectionToggleButton.propTypes = {
  toggle: PropTypes.func,
  selected: PropTypes.bool,
  isHidden: PropTypes.bool,
  marginLeft: PropTypes.number,
  marginTop: PropTypes.number,
};

export default CameraProjectionToggleButton;
