import { io } from 'jsts';
import { loadModules } from 'esri-loader';
import { pointsToJSTSPolygon } from 'utils/algorithms/jstsHelpers';
import { ESPG_PROJECTION_WKID } from './ArcGisConsts';

const graphicsCaching = {};
const geoJSONWritter = new io.GeoJSONWriter();
const geoJSONReader = new io.GeoJSONReader();
const wktReader = new io.WKTReader();

export const createPolygonGraphic = (attributes, geometry, spatialReference, type, symbol) => {
  const key = Object.values(attributes).join();
  if (graphicsCaching[key]) {
    return graphicsCaching[key];
  }
  const { Graphic } = getEsriModules();
  const { wkid } = attributes;
  const polygon = {
    ...geometry,
    spatialReference: wkid ? { wkid } : spatialReference,
    type: 'polygon',
  };

  const polygonGraphic = new Graphic({
    geometry: polygon,
    symbol,
    spatialReference: wkid ? { wkid } : spatialReference,
    attributes,
    swappType: type,
  });

  graphicsCaching[key] = polygonGraphic;
  return polygonGraphic;
};

export const createTextGraphic = (point, text, isLot) => {
  if (!point) {
    console.log('createTextGraphic: point is null');
  }
  const { Graphic } = getEsriModules();
  const textGraphic = new Graphic({
    geometry: point,
    symbol: {
      type: 'text', // autocasts as new SimpleMarkerSymbol()
      color: isLot ? 'blue' : 'black',
      text,
      outline: { // autocasts as new SimpleLineSymbol()
        width: 0.5,
        color: isLot ? 'blue' : 'black',
      },
      font: {
        size: isLot ? 9 : 12,
        family: 'Arial',
      },
    },
  });
  return textGraphic;
};

export const createGraphicLayer = (map, name, wkid) => {
  const { GraphicsLayer } = getEsriModules();
  const layer = new GraphicsLayer({
    title: name,
    name,
    elevationInfo: { mode: 'on-the-ground' },
    spatialReference: wkid && { wkid },
  });
  map.add(layer);
  return layer;
};

export const wktToEsri = (wktGeom) => {
  if (!wktGeom) {
    return null;
  }
  const jstsGeometry = wktReader.read(wktGeom);
  const geometry = geoJSONWritter.write(jstsGeometry);
  // eslint-disable-next-line prefer-destructuring
  geometry.rings = geometry.coordinates[0];
  geometry.type = 'polygon';
  delete geometry.coordinates;
  return geometry;
};

export const colorArrayToEsriColor = (array) => ({ r: array[0], g: array[1], b: array[2] });

export const getUnionedEsriPolygon = (list) => {
  const { geometryEngine } = getEsriModules();
  const polys = list.map((graphic) => graphic.geometry);
  const unioned = geometryEngine.union(polys);
  return unioned;
};

export const esriPolygonToJsts = (esriPolygon) => {
  if (!esriPolygon) {
    return null;
  }
  esriPolygon.type = 'Polygon';
  esriPolygon.coordinates = esriPolygon.rings;
  const jstsGeometry = geoJSONReader.read(esriPolygon);
  return jstsGeometry;
};

export const wktToEsriCenter = (wktGeom) => {
  if (!wktGeom) {
    return null;
  }
  const jstsGeometry = wktReader.read(wktGeom);
  const center = jstsGeometry.getCentroid().getCoordinates().map((coord) => [coord.x, coord.y]);
  return center[0];
};

export const getMapViewBounds = (mapView) => {
  const { webMercatorUtils } = getEsriModules();
  const minLatLng = webMercatorUtils.xyToLngLat(mapView.extent.xmin, mapView.extent.ymin);
  const maxLatLng = webMercatorUtils.xyToLngLat(mapView.extent.xmax, mapView.extent.ymax);
  const min = { lat: minLatLng[1], lng: minLatLng[0] };
  const max = { lat: maxLatLng[1], lng: maxLatLng[0] };
  return { min, max };
};

export const createSketchWidget = (view, layer, onSketchUpdated, featureLayer) => {
  const { Sketch } = getEsriModules();
  const sketchWidget = new Sketch({
    layer,
    view,
    creationMode: 'update',
    snappingOptions: {
      enabled: true,
      featureSources: [{ layer: featureLayer, enabled: true }],
    },
    visibleElements: {
      createTools: {
        circle: false,
        polyline: false,
        point: false,
        rectangle: true,
        polygon: true,
      },
      selectionTools: {
        'rectangle-selection': false,
        'lasso-selection': false,
      },
      snappingControlsElements: {
        header: true,
        layerList: true,
      },
      settingsMenu: true,
    },
  });

  view.ui.add(sketchWidget, 'top-right');
  sketchWidget.on('create', onSketchUpdated);
  sketchWidget.on('update', onSketchUpdated);

  return sketchWidget;
};

export const getSitePolygonProperties = (currentPolygon) => {
  const coordinates = currentPolygon.rings[0].map((coord) => ({ lat: coord[1], lng: coord[0] }));
  const { centroid } = currentPolygon;
  const { webMercatorUtils } = getEsriModules();
  const webMercatorGeometry = webMercatorUtils.geographicToWebMercator(currentPolygon);
  const jstsPolygon = pointsToJSTSPolygon(webMercatorGeometry.rings[0]);
  const area = jstsPolygon.getArea();
  return { area, coordinates, centroid };
};

export const getViewBoundsToFetch = (view) => {
  const { extent } = view;
  const { xmin, ymin, xmax, ymax } = extent;
  const { webMercatorUtils } = getEsriModules();
  const minLatLng = webMercatorUtils.xyToLngLat(xmin, ymin);
  const maxLatLng = webMercatorUtils.xyToLngLat(xmax, ymax);
  const min = { lat: minLatLng[1], lng: minLatLng[0] };
  const max = { lat: maxLatLng[1], lng: maxLatLng[0] };

  return {
    min,
    max,
  };
};

export const getExtentAsPolygon = (extent) => {
  const { xmin, ymin, xmax, ymax } = extent;
  const { Polygon } = getEsriModules();

  const polygon = new Polygon({ spatialRefference: { wkid: 4326 } });
  polygon.addRing([[xmin, ymin], [xmax, ymin], [xmax, ymax], [xmin, ymax], [xmin, ymin]]);

  return polygon;
};

let ESRI_MODULS = {};

export const getEsriModules = () => ESRI_MODULS;
export const loadEsriModules = async () => {
  ESRI_MODULS = await getEsriModulesAsync();
};

export const getDistanceBetweenCoordinates = async (p1, p2) => {
  const { geodesicUtils } = await loadGeometryService();
  const dist = geodesicUtils.geodesicDistance({ ...p1,
    spatialReference: {
      wkid: ESPG_PROJECTION_WKID,
    } }, { ...p2,
    spatialReference: {
      wkid: ESPG_PROJECTION_WKID,
    } });
  return dist;
};

export const pointFromDistance = async (p1, distance, azimuth) => {
  const { geodesicUtils } = await loadGeometryService();
  const newPoint = geodesicUtils.pointFromDistance({ ...p1,
    spatialReference: {
      wkid: ESPG_PROJECTION_WKID,
    } }, distance, azimuth);
  return newPoint;
};

const loadGeometryService = async () => {
  const [
    geodesicUtils,
  ] = await loadModules([
    'esri/geometry/support/geodesicUtils',
  ]);
  return {
    geodesicUtils,
  };
};

export const getEsriModulesAsync = async () => {
  const [
    GraphicsLayer,
    Graphic,
    webMercatorUtils,
    Map,
    MapView,
    Draw,
    geometryEngine,
    VectorTileLayer,
    Basemap,
    Polygon,
    SimpleFillSymbol,
    Sketch,
    SpatialReference,
    locator,
    SceneView,
    SketchViewModel,
    watchUtils,
    esriConfig,
  ] = await loadModules([
    'esri/layers/GraphicsLayer',
    'esri/Graphic',
    'esri/geometry/support/webMercatorUtils',
    'esri/Map',
    'esri/views/MapView',
    'esri/views/draw/Draw',
    'esri/geometry/geometryEngine',
    'esri/layers/VectorTileLayer',
    'esri/Basemap',
    'esri/geometry/Polygon',
    'esri/symbols/SimpleFillSymbol',
    'esri/widgets/Sketch',
    'esri/geometry/SpatialReference',
    'esri/rest/locator',
    'esri/views/SceneView',
    'esri/widgets/Sketch/SketchViewModel',
    'esri/core/watchUtils',
    'esri/config',
  ]);
  return {
    GraphicsLayer,
    Graphic,
    webMercatorUtils,
    Map,
    MapView,
    Draw,
    geometryEngine,
    VectorTileLayer,
    Basemap,
    Polygon,
    SimpleFillSymbol,
    Sketch,
    SpatialReference,
    locator,
    SceneView,
    SketchViewModel,
    watchUtils,
    esriConfig,
  };
};
