import React from 'react';

// currentcolor
export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 1.5C0.5 0.947715 0.947715 0.5 1.5 0.5H22.5C23.0523 0.5 23.5 0.947715 23.5 1.5V22.5C23.5 23.0523 23.0523 23.5 22.5 23.5H1.5C0.947715 23.5 0.5 23.0523 0.5 22.5V1.5Z" stroke="currentcolor" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M12 4H21.2308V10.4615H12V4Z" stroke="currentcolor" strokeWidth="0.7" strokeLinejoin="round" />
    <path d="M3 4H9V14H15V20H3V4Z" stroke="currentcolor" strokeWidth="0.7" strokeLinejoin="round" />
  </svg>
);
