import {
  loginErrorActionCreator,
  loginRequestActionCreator,
  loginSuccessActionCreator,
  signUpErrorActionCreator,
  signUpRequestActionCreator,
  signUpSuccessActionCreator,
  logoutActionCreator,
  resetPassErrorActionCreator,
  resetPassRequestActionCreator,
  resetPassSuccessActionCreator,
  confirmPassErrorActionCreator,
  confirmPassRequestActionCreator,
  confirmPassSuccessActionCreator,
  authCheckedActionCreator,
} from './authActionsCreators';
import { UserActionsCriators } from '../../user';
import { API_AUTH_ROUTES } from '../../../constants/routes/api';
import { UI_AUTHORIZE_PATH, UI_UNAUTHORIZED_PATH } from '../../../constants/routes/ui';

const {
  fillUserErrorActionCreator,
  fillUserActionCreator,
  removeUserActionCreator,
} = UserActionsCriators;

const setTokenToStorage = (token) => localStorage.setItem('token', token);
const setUserToStorage = (token) => localStorage.setItem('user', token);
const removeTokenFromStorage = () => localStorage.removeItem('token');
const removeUserFromStorage = () => localStorage.removeItem('user');

const parseError = (err) => {
  const { data } = err.response;
  return data.message;
};

export const signUpAction = (payload, history) => async (dispatch, _, agent) => {
  dispatch(signUpRequestActionCreator());

  try {
    const { data } = await agent.post(`${API_AUTH_ROUTES.signUp}`, payload);

    dispatch(signUpSuccessActionCreator());
    dispatch(fillUserActionCreator(data));
    setUserToStorage(JSON.stringify(data));

    const { email, password } = payload;
    const { data: { accessToken } } = await agent.post(`${API_AUTH_ROUTES.login}`, { email, password });
    setTokenToStorage(accessToken);
    dispatch(loginSuccessActionCreator());
    history.push(UI_AUTHORIZE_PATH.successNotification);
  } catch (e) {
    removeUserFromStorage();
    dispatch(signUpErrorActionCreator(e));
    dispatch(fillUserErrorActionCreator());
    return Promise.reject(e.response.data.message.message[0].constraints.isEmail);
  }
};

export const getUserProfileAction = () => async (dispatch, _, agent) => {
  dispatch(loginRequestActionCreator());

  try {
    const { data } = await agent.get(`${API_AUTH_ROUTES.userProfile}`);
    dispatch(fillUserActionCreator(data));
    dispatch(loginSuccessActionCreator());
  } catch (e) {
    dispatch(loginErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};

export const loginAction = (payload) => async (dispatch, _, agent) => {
  dispatch(loginRequestActionCreator());

  try {
    const { data: { accessToken } } = await agent.post(`${API_AUTH_ROUTES.login}`, payload);
    setTokenToStorage(accessToken);
    getUserProfileAction(accessToken)(dispatch, _, agent);
  } catch (e) {
    removeUserFromStorage();
    dispatch(loginErrorActionCreator(e));
    return Promise.reject(parseError(e));
  }
};

export const logoutAction = (history) => async (dispatch) => {
  removeTokenFromStorage();
  removeUserFromStorage();
  dispatch(logoutActionCreator());
  dispatch(removeUserActionCreator());
  history.replace('/');
};

export const authCheckedAction = () => (dispatch) => {
  dispatch(authCheckedActionCreator());
};

export const requestToResetPasswordAction = (payload) => async (dispatch, _, agent) => {
  dispatch(resetPassRequestActionCreator());

  try {
    await agent.post(`${API_AUTH_ROUTES.forgotPassword}`, payload);

    dispatch(resetPassSuccessActionCreator());
    return Promise.resolve();
  } catch (e) {
    dispatch(resetPassErrorActionCreator(e));
    return Promise.reject(parseError(e));
  }
};

export const confirmResetPasswordAction = (payload, history) => async (dispatch, _, agent) => {
  dispatch(confirmPassRequestActionCreator());

  try {
    await agent.post(`${API_AUTH_ROUTES.resetPassword}`, payload);

    dispatch(confirmPassSuccessActionCreator());
    history.push(UI_UNAUTHORIZED_PATH.successResetNotification);
  } catch (e) {
    dispatch(confirmPassErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};
