import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import SignUpPage from '../pages/SignUpPage';
import PasswordReset from '../pages/PasswordResetPage';
import SetNewPasswordPage from '../pages/SetNewPasswordPage';
import SuccessPasswordResetPage from '../pages/SuccessPasswordResetPage';
import { UI_UNAUTHORIZED_PATH } from '../constants/routes/ui';
import { LoginContainer, LeftPanel, RightPanel, SwappLogo, RightPanelFormWrapper } from './unAuthorizedRoutes.styles';
import { ReactComponent as SwappLogoIcon } from '../styles/static/icons/comon/swappLogoWithName.svg';

const {
  signUp, login, passwordReset, passwordResetConfirm, successResetNotification,
} = UI_UNAUTHORIZED_PATH;

export const UnAuthorizedRoutes = () => (
  <LoginContainer>
    <LeftPanel>
      <SwappLogo component={SwappLogoIcon} />
    </LeftPanel>
    <RightPanel>
      <RightPanelFormWrapper>
        <Switch>
          <Route exact path={login} component={LoginPage} />
          <Route exact path={signUp} component={SignUpPage} />
          <Route exact path={passwordReset} component={PasswordReset} />
          <Route path={passwordResetConfirm} component={SetNewPasswordPage} />
          <Route path={successResetNotification} component={SuccessPasswordResetPage} />
          <Redirect to={login} />
        </Switch>
      </RightPanelFormWrapper>
    </RightPanel>
  </LoginContainer>
);
