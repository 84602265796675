import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import { STANDARDS } from 'constants/feasibilityConts';
import { FEASIBILITY_TARGET_FORM } from 'constants/fieldNames';
import { StyledSwitch } from 'styles/commonComponents.styles';
import { useOverridableSetting } from 'store/userSettings';
import { SwitchFormItemWrapper, SwitchTitle, SwitchFormItem } from './FeasibilityTargetFormContainer.Styles';

const FeasibilityDesignRequirementsForm = (props) => {
  const { disabled, formData, form } = props;
  const multipleFloorGroup = useOverridableSetting('multipleFloorGroup', false);
  const unitType = lodashGet(formData, `${FEASIBILITY_TARGET_FORM.APARTMENTS_STANDARD}[0]`);
  const formSettings = lodashGet(STANDARDS[unitType], 'formSettings');

  useEffect(() => {
    if (!lodashGet(formSettings, `[${FEASIBILITY_TARGET_FORM.FLEXIBLE}].visible`)) {
      form.setFieldsValue({ [FEASIBILITY_TARGET_FORM.FLEXIBLE]: true });
    }
  }, [formData[FEASIBILITY_TARGET_FORM.APARTMENTS_STANDARD]]);

  // if we change/add more items plz update FeasibilityApartmentStandardForm.js for 'UNIT_TYPES.IL'
  return (
    <>
      {/* ============FLEXIBLE============ */}
      <SwitchFormItemWrapper hidden={!lodashGet(formSettings, `[${FEASIBILITY_TARGET_FORM.FLEXIBLE}].visible`)}>
        <SwitchFormItem name={FEASIBILITY_TARGET_FORM.FLEXIBLE} valuePropName="checked" initialValue>
          <StyledSwitch size="small" disabled={disabled} />
        </SwitchFormItem>
        <SwitchTitle>{T.translate('FEASIBILITY_DESIGN_REQUIREMENTS_FORM_FLEXIBLE')}</SwitchTitle>
      </SwitchFormItemWrapper>

      {/* ============SPRINKLERS============ */}
      <SwitchFormItemWrapper>
        <SwitchFormItem name={FEASIBILITY_TARGET_FORM.SPRINKLERS} valuePropName="checked" initialValue>
          <StyledSwitch size="small" disabled={disabled} />
        </SwitchFormItem>
        <SwitchTitle>{T.translate('FEASIBILITY_DESIGN_REQUIREMENTS_FORM_SPRINKLERS')}</SwitchTitle>
      </SwitchFormItemWrapper>

      {/* ============DEAD_END_CORRIDOR============ */}
      <SwitchFormItemWrapper>
        <SwitchFormItem name={FEASIBILITY_TARGET_FORM.DEAD_END_CORRIDOR} valuePropName="checked" initialValue>
          <StyledSwitch size="small" disabled={disabled} />
        </SwitchFormItem>
        <SwitchTitle>{T.translate('FEASIBILITY_DESIGN_REQUIREMENTS_FORM_DEAD_END_CORRIDOR')}</SwitchTitle>
      </SwitchFormItemWrapper>

      {/* ============NATURAL_LIGHT============ */}
      <SwitchFormItemWrapper>
        <SwitchFormItem name={FEASIBILITY_TARGET_FORM.NATURAL_LIGHT_CORRIDORS} valuePropName="checked" initialValue={false}>
          <StyledSwitch size="small" disabled={disabled} />
        </SwitchFormItem>
        <SwitchTitle>{T.translate('FEASIBILITY_DESIGN_REQUIREMENTS_FORM_NATURAL_LIGHT_CORRIDORS')}</SwitchTitle>
      </SwitchFormItemWrapper>

      {/* ============Single load corridor============ */}
      <SwitchFormItemWrapper>
        <SwitchFormItem name={FEASIBILITY_TARGET_FORM.SINGLE_LOAD_CORRIDOR} valuePropName="checked" initialValue={false}>
          <StyledSwitch size="small" disabled={disabled} />
        </SwitchFormItem>
        <SwitchTitle>{T.translate('FEASIBILITY_DESIGN_REQUIREMENTS_FORM_SINGLE_LOAD_CORRIDOR')}</SwitchTitle>
      </SwitchFormItemWrapper>

      {/* ============multiple floor group============ */}
      {multipleFloorGroup && (
        <SwitchFormItemWrapper>
          <SwitchFormItem name={FEASIBILITY_TARGET_FORM.MULTIPLE_FLOOR_GROUP} valuePropName="checked" initialValue={false}>
            <StyledSwitch size="small" disabled={disabled} />
          </SwitchFormItem>
          <SwitchTitle>{T.translate('FEASIBILITY_DESIGN_REQUIREMENTS_FORM_MULTIPLE_FLOOR_GROUP')}</SwitchTitle>
        </SwitchFormItemWrapper>
      )}
    </>
  );
};

FeasibilityDesignRequirementsForm.propTypes = {
  disabled: PropTypes.bool,
  formData: PropTypes.object,
  form: PropTypes.object,
};

export default React.memo(FeasibilityDesignRequirementsForm);
