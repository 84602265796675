import React from 'react';

// currentcolor
export default () => (
  <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.0355 11.6456C15.8311 11.4489 15.8311 11.1217 16.0355 10.925L21.7608 5.41586C22.0784 5.1102 22.6075 5.33532 22.6075 5.77615V16.7945C22.6075 17.2353 22.0784 17.4605 21.7608 17.1548L16.0355 11.6456Z" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.79448 11.6456C7.9989 11.4489 7.9989 11.1217 7.79448 10.925L2.06918 5.41586C1.75152 5.1102 1.22249 5.33532 1.22249 5.77615L1.22249 16.7945C1.22249 17.2353 1.75152 17.4605 2.06918 17.1548L7.79448 11.6456Z" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
    <line x1="11.8099" y1="1.5" x2="11.8099" y2="22.2801" stroke="currentcolor" strokeMiterlimit="16" strokeLinecap="round" strokeDasharray="1 2" />
  </svg>
);
