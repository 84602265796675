import React from 'react';

// currentcolor
export default () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="22" height="22" fill="white" />
    <rect x="1" y="2" width="20" height="19" stroke="currentColor" strokeWidth="0.75" strokeLinejoin="round" />
    <path d="M10.9463 12.0142C10.0618 11.7599 9.41732 11.4484 9.0127 11.0796C8.61165 10.7072 8.41113 10.2489 8.41113 9.70459C8.41113 9.0887 8.65641 8.58024 9.14697 8.1792C9.64111 7.77458 10.2821 7.57227 11.0698 7.57227C11.6069 7.57227 12.085 7.67611 12.5039 7.88379C12.9264 8.09147 13.2523 8.37793 13.4814 8.74316C13.7142 9.1084 13.8306 9.50765 13.8306 9.94092H12.7939C12.7939 9.46826 12.6436 9.09766 12.3428 8.8291C12.042 8.55697 11.6177 8.4209 11.0698 8.4209C10.5614 8.4209 10.1639 8.53369 9.87744 8.75928C9.59456 8.98128 9.45312 9.29102 9.45312 9.68848C9.45312 10.0072 9.5874 10.2775 9.85596 10.4995C10.1281 10.7179 10.5882 10.9185 11.2363 11.1011C11.888 11.2837 12.3965 11.486 12.7617 11.708C13.1305 11.9264 13.4027 12.1825 13.5781 12.4761C13.7572 12.7697 13.8467 13.1152 13.8467 13.5127C13.8467 14.1465 13.5996 14.6549 13.1055 15.0381C12.6113 15.4176 11.9507 15.6074 11.1235 15.6074C10.5864 15.6074 10.0851 15.5054 9.61963 15.3013C9.15413 15.0936 8.79427 14.8107 8.54004 14.4526C8.28939 14.0946 8.16406 13.6882 8.16406 13.2334H9.20068C9.20068 13.7061 9.37435 14.0802 9.72168 14.356C10.0726 14.6281 10.5399 14.7642 11.1235 14.7642C11.6678 14.7642 12.085 14.6532 12.375 14.4312C12.665 14.2091 12.8101 13.9066 12.8101 13.5234C12.8101 13.1403 12.6758 12.8449 12.4072 12.6372C12.1387 12.4259 11.6517 12.2183 10.9463 12.0142Z" fill="currentColor" />
  </svg>

);
