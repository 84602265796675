import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { FormItem, StyledTextArea } from './AreaTargetFormContainer.styles';
import { AREA_TARGET_FORM } from '../../../../constants/fieldNames';

const AreaTargetFormComments = (props) => {
  const { disabled } = props;

  return (
    <FormItem name={AREA_TARGET_FORM.COMMENTS} disabled={disabled}>
      <StyledTextArea disabled={disabled} autoSize={{ minRows: 4, maxRows: 12 }} placeholder={T.translate('AREA_TARGET_FORM_COMMENTS_PLACEHOLDER')} />
    </FormItem>
  );
};

AreaTargetFormComments.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default React.memo(AreaTargetFormComments);
