import React from 'react';
import * as THREE from 'three';
import PropTypes from 'prop-types';
import { MeshLineRaycast } from 'threejs-meshline';
import { Circle } from '@react-three/drei';
import lodashIsEqual from 'lodash/isEqual';
import { useThree } from 'react-three-fiber';
import { decalProps } from '../helpers/ThreeHelpers';

const InteractableLines = ({ points, isClosed = false, color = 'white', width = 1.0, height = 1, renderOrder = 20, onPointerOver, onClick, onPointerOut, onPointerEnter, id, materialProps }) => {
  const vertices = points.flatMap((v) => {
    const x = v.x || v[0];
    const y = v.y || v[1];
    const z = v.z || v[2] || height;

    return [new THREE.Vector3(x, y, z), new THREE.Vector3(x, y, z)];
  });
  if (isClosed) {
    const z = height || points[0].z || points[0][2];
    vertices.push(new THREE.Vector3(...points[0], z), new THREE.Vector3(...points[0], z));
  }
  const { gl } = useThree();

  return (
    <>
      <mesh
        key="line"
        raycast={MeshLineRaycast}
        onPointerOver={onPointerOver}
        onClick={onClick && (() => onClick(id))}
        onPointerOut={onPointerOut && (() => onPointerOut(id))}
        onPointerEnter={onPointerEnter && (() => onPointerEnter(id))}
        {...decalProps(gl, 0.000001)}
        renderOrder={renderOrder}
      >
        <meshLine attach="geometry" vertices={vertices} />
        <meshLineMaterial attach="material" color={color} lineWidth={width} {...materialProps} />
      </mesh>
      {
      points.map((point, idx) => {
        const z = point.z || point[2] || height;
        return (
          <Circle
            renderOrder={52}
            key={`c${idx}`}
            args={[width / 2, 10]}
            position={[...point, z]}
          >
            <meshStandardMaterial attach="material" color={color} />
          </Circle>
        );
      })
    }
    </>
  );
};

const areEqual = ({ points: prevPoints, ...prev }, { points: nextPoints, ...next }) => {
  const isEqual = lodashIsEqual(prev, next);
  const isPointsEqual = JSON.stringify(prevPoints) === JSON.stringify(nextPoints);
  return (isEqual && isPointsEqual);
};

InteractableLines.propTypes = {
  points: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.object,
  ])),
  isClosed: PropTypes.bool,
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  id: PropTypes.number,
  renderOrder: PropTypes.number,
  materialProps: PropTypes.object,
  onPointerOver: PropTypes.func,
  onClick: PropTypes.func,
  onPointerOut: PropTypes.func,
  onPointerEnter: PropTypes.func,
};

export default React.memo(InteractableLines, areEqual);
