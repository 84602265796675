import styled from 'styled-components';

export const BuildingCodeWrapper = styled.div`
  display: flex;
  padding: 7px 0;
  cursor: pointer;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

export const ColorPillWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 15px;
`;

export const ColorPill = styled.div`
  height: 25px;
  width: 5px;
  border-radius: ${({ theme }) => theme.other.borderRadius};
  background-color: ${({ color }) => color};
`;

export const PropertiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PropertyTitle = styled.div`
  color: ${({ theme }) => theme.colors.gray_09};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Link = styled.a`
  display: flex;
`;

export const BuildingCodeTitleWrapper = styled.div`
  display: flex;
  padding: 7px 10px;
  font-weight: ${({ theme }) => theme.font.weight.bold}; 
  font-size: ${({ theme }) => theme.font.size.medium};
  color: ${({ theme }) => theme.colors.gray_01};
  background-color: ${({ color }) => color};
  
  margin-top: 10px;
`;
