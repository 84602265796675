import apartmentStandardIcon from './apartmentStandardIcon';
import buildingRightsIcon from './buildingRightsIcon';
import buildingTypologiesIcon from './buildingTypologiesIcon';
import communalSpaceIcon from './communalSpaceIcon';
import designPriorityIcon from './designPriorityIcon';
import designRequirementsIcon from './designRequirementsIcon';
import lotInfoIcon from './lotInfoIcon';
import mechanicalElectricPlumbingIcon from './mechanicalElectricPlumbingIcon';
import wellbeingAnalysisIcon from './wellbeingAnalysisIcon';
import siteIcon from './siteIcon';
import reportIcon from './reportIcon';
import reportFillIcon from './reportFillIcon';
import downloadIcon from './downloadIcon';
import apartmentMixtureIcon from './apartmentMixtureIcon';
import financialViabilityIcon from './financialViabilityIcon';
import hvacDesignIcon from './hvacDesignIcon';
import developmentIcon from './developmentIcon';
import efficiencyScoreIcon from './efficiencyscoreIcon';
import enhancedApartmentsIcon from './enhancedapartmentsIcon';
import parkingIcon from './parkingIcon';
import passiveHouseIcon from './passivehouseIcon';
import areaSummaryIcon from './areaSummaryIcon';
import unitsYieldIcon from './unitsYieldIcon';
import energyAnalysesIcon from './energyAnalysesIcon';

export default {
  apartmentStandardIcon,
  buildingRightsIcon,
  buildingTypologiesIcon,
  communalSpaceIcon,
  designPriorityIcon,
  designRequirementsIcon,
  lotInfoIcon,
  mechanicalElectricPlumbingIcon,
  wellbeingAnalysisIcon,
  reportIcon,
  reportFillIcon,
  siteIcon,
  downloadIcon,
  apartmentMixtureIcon,
  financialViabilityIcon,
  hvacDesignIcon,
  developmentIcon,
  efficiencyScoreIcon,
  enhancedApartmentsIcon,
  parkingIcon,
  passiveHouseIcon,
  areaSummaryIcon,
  unitsYieldIcon,
  energyAnalysesIcon,
};
