export const UI_UNAUTHORIZED_PATH = {
  signUp: '/sign-up',
  login: '/login',
  passwordReset: '/password-reset',
  passwordResetConfirm: '/password-reset-confirm',
  successResetNotification: '/success-reset-notification',
};

export const UI_AUTHORIZE_PATH = {
  root: '/',
  successNotification: '/success-notification',

  // Stages
  TEST_FIT: 'test',
  FEASIBILITY_STUDY: 'feasibility',
  ONE_CLICK_BUILDING: 'oneClickBuilding',
  PROJECT_SETTINGS: 'settings',
  thumbnail: 'thumbnail',

  // Stages Tabs
  setup: 'setup',
  studies: 'studies',
  compare: 'compare',

  // Sidebar
  projectInfo: 'project-settings',
  overall: 'overall',
  program: 'program',
  environment: 'environment',
  energy: 'energy',
  hbenergy: 'hbenergy',
  parking: 'parking',
  financial: 'financial',
  report: 'report',
  design: 'design',
  build: 'build',
  views: 'views',
  exportFiles: 'exportFiles',
  markups: 'markups',
};

export const UI_AUTHORIZE_PATH_STAGES = [
  UI_AUTHORIZE_PATH.TEST_FIT, UI_AUTHORIZE_PATH.FEASIBILITY_STUDY, UI_AUTHORIZE_PATH.PROJECT_SETTINGS, UI_AUTHORIZE_PATH.thumbnail,
];

export const UI_AUTHORIZE_PATH_STAGES_TABS = [
  UI_AUTHORIZE_PATH.setup, UI_AUTHORIZE_PATH.compare, UI_AUTHORIZE_PATH.studies,
];

export const UI_AUTHORIZE_PATH_SIDEBAR = [
  UI_AUTHORIZE_PATH.projectInfo, UI_AUTHORIZE_PATH.program, UI_AUTHORIZE_PATH.overall, UI_AUTHORIZE_PATH.report,
  UI_AUTHORIZE_PATH.environment, UI_AUTHORIZE_PATH.energy, UI_AUTHORIZE_PATH.hbenergy, UI_AUTHORIZE_PATH.parking, UI_AUTHORIZE_PATH.financial,
  UI_AUTHORIZE_PATH.design, UI_AUTHORIZE_PATH.build, UI_AUTHORIZE_PATH.views, UI_AUTHORIZE_PATH.exportFiles, UI_AUTHORIZE_PATH.markups,
];

export const UI_AUTHORIZE_PATH_SUB_SIDEBAR = [];
