import styled from 'styled-components';
import { Upload } from 'antd';

export const UploadButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  
  .anticon-plus {
    margin-bottom: 10px;
  }
`;

export const StyledUpload = styled(Upload)`
  .ant-upload-list-picture-card-container,
  .ant-upload-select-picture-card {
    width: 102px;
    height: 102px;
  }
  .anticon-eye {
    display: none; // this is done to forcefully remove the preview icon inside the thumbnail
  }


  .ant-upload-list-item-list-type-picture-card {
    border: 1px solid ${({ theme }) => theme.colors.gray_06};
    border-radius: ${({ theme }) => theme.other.borderRadius};

    &:hover {
      border-color: ${({ theme }) => theme.colors.primaryColor};
    }
  }
  
  .ant-upload-select-picture-card {
    margin-bottom: 0;
    border: 1px solid ${({ theme }) => theme.colors.gray_06};
    border-radius: ${({ theme }) => theme.other.borderRadius};

    &:hover {
      ${UploadButton} {
        color: ${({ theme }) => theme.colors.primaryColor};
      }
      border-color: ${({ theme }) => theme.colors.primaryColor};
    }
  }

  .ant-btn {
    min-width: 34px !important;
  }
  
  .anticon-delete {
   &:hover {
    color: ${({ theme }) => theme.colors.primaryColorHover} !important;
   }
  }
  
  .ant-upload-list-item-card-actions-btn {
    color: transparent !important;
    border-color: transparent !important;
  }
`;
