import icons from '../styles/static/icons/feasibilityIcons/report';
import { MODEL_ANALYSIS_TYPES } from './feasibilityConts';

export const legendIcons = {
  archaeological: { icon: icons.archaeologicalIcon, label: 'Special Archaeological Significance' },
  brownfieldSites: { icon: icons.brownfieldSitesIcon, label: 'Brownfield Sites' },
  buildingHeight: { icon: icons.buildingHeightIcon, label: 'Building height (no. of stories)' },
  fiveHundredMRadius: { icon: icons.fiveHundredMRadiusIcon, label: '500m Radius' },
  conservationArea: { icon: icons.conservationAreaIcon, label: 'Conservation Area - Moss Hall Crescent' },
  conservationBLDG: { icon: icons.conservationBLDGIcon, label: 'Conservation Listed Buildings' },
  education: { icon: icons.educationIcon, label: 'Education' },
  electricalStation: { icon: icons.electricalStationIcon, label: 'Electrical transformation sub-station (unknown if for the store only or locally)' },
  healthCare: { icon: icons.healthCareIcon, label: 'Healthcare' },
  highQualityDesign: { icon: icons.highQualityDesignIcon, label: 'High-quality design can enhance views and profitability at the site' },
  highway: { icon: icons.highwayIcon, label: 'Highway' },
  lightConstrains: { icon: icons.lightConstrainsIcon, label: 'Potential rights of light constraints' },
  lotBoundary: { icon: icons.lotBoundaryIcon, label: 'Site Boundary' },
  newAccess: { icon: icons.newAccessIcon, label: 'Potential new access into the site' },
  newSupermarketEntrance: { icon: icons.newSupermarketEntranceIcon, label: 'Re-position supermarket entrance to increased pedestrian customers' },
  parkingAccess: { icon: icons.parkingAccessIcon, label: 'Existing parking access' },
  parks: { icon: icons.parksIcon, label: 'Parks' },
  privateGreen: { icon: icons.privateGreenIcon, label: 'Open private green area' },
  openGreen: { icon: icons.privateGreenIcon, label: 'Open private green area. A double contrary lot style (front and rear) offering diverse kinds of habitation' },
  railLine: { icon: icons.railLineIcon, label: 'Rail line' },
  residentialHouses: { icon: icons.residentialHousesIcon, label: 'Residential Houses' },
  retailArea: { icon: icons.retailAreaIcon, label: 'Retail Area' },
  serviceAccess: { icon: icons.serviceAccessIcon, label: 'Service access to existing store, from residential street' },
  siteBoundary: { icon: icons.siteBoundaryIcon, label: 'Site Boundary' },
  southerlyAspect: { icon: icons.southerlyAspectIcon, label: 'Opportunity to utilize the southerly aspect' },
  sport: { icon: icons.sportIcon, label: 'Sport' },
  store: { icon: icons.storeIcon, label: 'Store' },
  strategicHousing: { icon: icons.strategicHousingIcon, label: 'Strategic Housing Land Availability' },
  supermarketEntrance: { icon: icons.supermarketEntranceIcon, label: 'Customer store entrance, not facing main street' },
  townCentres: { icon: icons.townCentresIcon, label: 'Local Town Centres' },
  undergroundStation: { icon: icons.undergroundStation, label: 'Underground Station' },
  urbanFrontage: { icon: icons.urbanFrontageIcon, label: 'Street frontage not utilised with no lighting or urban consideration' },
  improveFrontage: { icon: icons.urbanFrontageIcon, label: 'Improve frontage and urban connection' },
  landmark: { icon: icons.landmarkIcon, label: 'Landmark' },
  trainStation: { icon: icons.trainStationIcon, label: 'Train Station' },
  height: { icon: icons.heightIcon, label: 'Existing high building may allow new residential development higher than the surrounding houses' },
  publicBuilding: { icon: icons.publicBuildingIcon, label: 'Public Building' },
  retailOffices: { icon: icons.retailOfficesIcon, label: 'Retail/ Offices' },
  topographySlope: { icon: icons.topographySlopeIcon, label: 'Topography Slope' },
  attenuationTank: { icon: icons.attenuationTankIcon, label: 'Attenuation Tank location provides opportunity for shared outdoor space' },
  publicSewerAttenuation: { icon: icons.attenuationTankIcon, label: 'Public Sewer Attenuation Tank (Exact location and Depth TBC)' },
  floodDefences: { icon: icons.floodDefencesIcon, label: 'Flood Defences' },
  listedBuilding: { icon: icons.listedBuildingIcon, label: 'Listed Building or Archaeological Structure' },
  unobstructedRiver: { icon: icons.newSupermarketEntranceIcon, label: 'Unobstructed river views with potential restaurant of cafe spaces at GF' },
  pedestrianRoute: { icon: icons.pedestrianRouteIcon, label: 'Pedestrian Route' },
  publicCycleRoute: { icon: icons.publicCycleRouteIcon, label: 'public Cycle Route' },
};

export const legendColors = {
  [MODEL_ANALYSIS_TYPES.AREA_TYPE]: [
    { colors: ['#e9bbff'], label: 'Communal' },
    { colors: ['#ede9b2'], label: 'Elevator Cores' },
    { colors: ['#ede988'], label: 'Escape cores' },
    { colors: ['#cbc77e'], label: 'Shaft' },
    { colors: ['#d2d2d2'], label: 'Corridor' },
    { colors: ['#c4bbc4'], label: 'Retail' },
  ],
  [MODEL_ANALYSIS_TYPES.SUN_ANALYSIS]: [
    { colors: ['#FCFC99'], label: '0 hours' },
    { colors: ['#FFFF66'], label: '1 hour' },
    { colors: ['#FFFF00'], label: '2 hours' },
    { colors: ['#FFE100'], label: '3 hours' },
    { colors: ['#FEC000'], label: '4 hours' },
    { colors: ['#FE9E00'], label: '5 hours' },
    { colors: ['#FC7800'], label: '6 hours' },
    { colors: ['#F94800'], label: '7 hours' },
    { colors: ['#E71000'], label: '8 hours' },
    { colors: ['#C30800'], label: '9 hours' },
    { colors: ['#9D0400'], label: '10 hours' },
    { colors: ['#7A0200'], label: '11 hours' },
    { colors: ['#5A0100'], label: '12 hours' },
  ],
};

export const DEVELOPMENT_APPRAISAL_FORM = {
  SALES_UNITS: 'SALES_UNITS',
  AFFORDABLE_UNITS: 'AFFORDABLE_UNITS',
  AFFORDABLE_REDUCTION: 'AFFORDABLE_REDUCTION',
  BASE_DATA_TABLE: 'BASE_DATA_TABLE',
  GRANT_MONIES: 'GRANT_MONIES',
  OTHER: 'OTHER',
  TOTAL_REVENUE: 'TOTAL_REVENUE',
  TOTAL_COSTS: 'TOTAL_COSTS',
  INFLATION_ASSESSMENT: 'INFLATION_ASSESSMENT',
  BUILD_COSTS: 'BUILD_COSTS',
  LANDSCAPE: 'LANDSCAPE',
  PLANNING_FEES: 'PLANNING_FEES',
  PLANNING_FEES_COAST: 'PLANNING_FEES_COAST',
  OTHER_ABNORMALS: 'OTHER_ABNORMALS',
  EMPLOYERS_AGENT: 'EMPLOYERS_AGENT',
  DEVELOPMENT_MANAGEMENT_FEE: 'DEVELOPMENT_MANAGEMENT_FEE',
  SALES_MARKETING: 'SALES_MARKETING',
  CONVEYANCING_FEES: 'CONVEYANCING_FEES',
  HISTORICAL_COSTS: 'HISTORICAL_COSTS',
  S106: 'S106',
  CIL: 'CIL',
  LAND_VALUE: 'LAND_VALUE',
  LAND_SALES_MARKETING: 'LAND_SALES_MARKETING',
  LAND_CONVEYANCING_FEES: 'LAND_CONVEYANCING_FEES',
  CONTINGENCY: 'CONTINGENCY',
};

export const DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE = {
  KEY: 'key',
  NUM_OF_UNITS: 'numOfUnits',
  APARTMENT_AREA: 'apartmentArea',
  AREA: 'area',
  SCALE: 'scale',
  UNIT_VALUE: 'unitValue',
};

export const CONSTRUCTION_COST_FORM = {
  CONSTRUCTION_COST_TABLE_VALUES: 'CONSTRUCTION_COST_TABLE_VALUES',
  CONSTRUCTION_COST_VALUE_NIA_RATIO: 'CONSTRUCTION_COST_VALUE_NIA_RATIO',
  CONSTRUCTION_COST_VALUE_GIA_RATIO: 'CONSTRUCTION_COST_VALUE_GIA_RATIO',
};

export const CONSTRUCTION_COST_TABLE = {
  KEY: 'key',
  CODE: 'code',
  ELEMENT: 'element',
  TOTAL_AREA_RATIO: 'totalAreaRatio',
  VALUE_AREA_RATIO: 'valueAreaRatio',
  PERCENTAGE_OF_TOTAL: 'percentageOfTotal',
};

export const DEVELOPMENT_APPRAISAL_INCOME_TABLE = {
  KEY: 'key',
  NUM_OF_UNITS: 'numOfUnits',
  APARTMENT_AREA: 'apartmentArea',
  TOTAL_NIA: 'totalNia',
  UNIT_VALUE: 'unitValue',
  TOTAL_VALUE: 'totalValue',
  VALUE_AREA_RATIO: 'valueAreaRatio',
};

export const PAGES_TYPES = {
  summary: 'summary',
  site: 'site',
  siteLocation: 'site-location',
  floods: 'floods',
  existingSite: 'existing-site',
  aerialView: 'aerial-view',
  existingStore: 'existing-store',
  streetView: 'street-view',
  designStreetView: 'design-street-view',
  surrounding: 'surrounding',

  strategy: 'strategy',
  siteConstraints: 'site-constraints',
  opportunities: 'opportunities',
  phasing: 'phasing',

  designFe: 're-design',
  overallFe: 're-overall',
  apartmentsMix: 'apartment-mix',
  communal: 'communal',
  plansFe: 're-plans',
  section: 'section',

  wellbeing: 'wellbeing',

  environment: 'environment',
  shadows: 'shadows',
  rightOfLight: 'right-of-light',

  apartments: 'apartments',

  financial: 'financial',
  developmentAppraisal: 'development-appraisal',
  constructionCost: 'construction-cost',
};

const FEASIBILITY_STUDY_REPORT_SIDEBAR_SITE_SIDEBAR = [
  { key: PAGES_TYPES.siteLocation, value: 'NAVIGATION_SITE_LOCATION', resultObjectPath: 'siteLocationData' },
  { key: PAGES_TYPES.existingSite, value: 'NAVIGATION_EXISTING_SITE', resultObjectPath: 'existingSiteData' },
  { key: PAGES_TYPES.floods, value: 'NAVIGATION_FLOODS', resultObjectPath: 'floodsData' },
  { key: PAGES_TYPES.aerialView, value: 'NAVIGATION_AERIAL_VIEW', resultObjectPath: 'siteAerialViewData' },
  { key: PAGES_TYPES.existingStore, value: 'NAVIGATION_EXISTING_STORE', resultObjectPath: 'siteExistingStoreData' },
  { key: PAGES_TYPES.streetView, value: 'NAVIGATION_STREET_VIEW', resultObjectPath: 'streetViewData' },
  { key: PAGES_TYPES.surrounding, value: 'NAVIGATION_SURROUNDING', resultObjectPath: 'surroundingData' },
];

const FEASIBILITY_STUDY_REPORT_SIDEBAR_STRATEGY_SIDEBAR = [
  { key: PAGES_TYPES.siteConstraints, value: 'NAVIGATION_SITE_CONSTRAINTS', resultObjectPath: 'siteConstraintsData' },
  { key: PAGES_TYPES.opportunities, value: 'NAVIGATION_OPPORTUNITIES', resultObjectPath: 'opportunitiesData' },
  { key: PAGES_TYPES.phasing, value: 'NAVIGATION_PHASING', resultObjectPath: 'phasingData' },
];

const FEASIBILITY_STUDY_REPORT_SIDEBAR_DESIGN_SIDEBAR = [
  { key: PAGES_TYPES.overallFe, value: 'NAVIGATION_OVERALL_FE' },
  { key: PAGES_TYPES.apartmentsMix, value: 'NAVIGATION_APARTMENTS_MIX' },
  { key: PAGES_TYPES.communal, value: 'NAVIGATION_COMMUNAL' },
  { key: PAGES_TYPES.plansFe, value: 'NAVIGATION_PLANS_FE' },
  { key: PAGES_TYPES.section, value: 'NAVIGATION_SECTION' },
  { key: PAGES_TYPES.designStreetView, value: 'NAVIGATION_STREET_VIEW' },
];

const FEASIBILITY_STUDY_REPORT_SIDEBAR_ENVIRONMENT_SIDEBAR = [
  { key: PAGES_TYPES.wellbeing, value: 'NAVIGATION_WELLBEING' },
  { key: PAGES_TYPES.shadows, value: 'NAVIGATION_SHADOWS' },
  { key: PAGES_TYPES.rightOfLight, value: 'NAVIGATION_RIGHT_OF_LIGHT' },
];

// const FEASIBILITY_STUDY_REPORT_SIDEBAR_FINANCIAL_SIDEBAR = [
//   { key: PAGES_TYPES.developmentAppraisal, value: 'NAVIGATION_DEVELOPMENT_APPRAISAL' },
//   { key: PAGES_TYPES.constructionCost, value: 'NAVIGATION_CONSTRUCTION_COST' },
// ];

export const FEASIBILITY_STUDY_REPORT_SIDEBAR = [
  { key: PAGES_TYPES.summary, value: 'NAVIGATION_SUMMARY', resultObjectPath: 'summaryPageData' },
  { key: PAGES_TYPES.site, value: 'NAVIGATION_SITE', isSubPage: true, subItems: FEASIBILITY_STUDY_REPORT_SIDEBAR_SITE_SIDEBAR, resultObjectMainPath: 'site' },
  { key: PAGES_TYPES.strategy, value: 'NAVIGATION_STRATEGY', isSubPage: true, subItems: FEASIBILITY_STUDY_REPORT_SIDEBAR_STRATEGY_SIDEBAR, resultObjectMainPath: 'strategy' },
  { key: PAGES_TYPES.designFe, value: 'NAVIGATION_DESIGN', isSubPage: true, subItems: FEASIBILITY_STUDY_REPORT_SIDEBAR_DESIGN_SIDEBAR },
  { key: PAGES_TYPES.environment, value: 'NAVIGATION_ENVIRONMENT', isSubPage: true, subItems: FEASIBILITY_STUDY_REPORT_SIDEBAR_ENVIRONMENT_SIDEBAR },
  { key: PAGES_TYPES.apartments, value: 'NAVIGATION_APARTMENTS', resultObjectPath: 'apartmentsData' },
  // { key: PAGES_TYPES.financial, value: 'NAVIGATION_FINANCIAL', isSubPage: true, subItems: FEASIBILITY_STUDY_REPORT_SIDEBAR_FINANCIAL_SIDEBAR },
];
