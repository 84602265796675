import React from 'react';
import T from 'i18n-react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIncludes from 'lodash/includes';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import cornerIcon from 'styles/static/icons/comon/cornerIcon';
import { useOverridableSetting } from '../../store/userSettings';

const ResultSplitViewSidebarWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 80px;
  min-width: 80px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
  color: ${({ theme, selected }) => (selected && theme.colors.primaryColor)};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primaryColorHover};
  }
`;

const ButtonIcon = styled.div`
  margin: 0 auto 5px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonText = styled.div`
  font-size: ${({ theme }) => theme.font.size.xSmall};
  text-align: center;
  text-transform: uppercase;
`;

const CornerIcon = styled(Icon)`
  position: absolute;
  top: -1px;
  left: 79px;
  z-index: 4;
  color: ${({ theme }) => theme.colors.white};
`;

const RenderButton = (props) => {
  const { id, icon, text, hidden } = props;
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const selected = locationData?.tabSideBar === id;

  if (hidden) {
    return null;
  }

  return (
    <ButtonWrapper key={id} onClick={() => history.push(`${id}${history?.location?.search}`)} selected={selected}>
      <ButtonIcon><Icon component={icon} /></ButtonIcon>
      <ButtonText>{T.translate(text)}</ButtonText>
    </ButtonWrapper>
  );
};

RenderButton.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.func,
  text: PropTypes.string,
  hidden: PropTypes.bool,
};

const ResultSplitViewSidebar = (props) => {
  const { hidden, buttons } = props;
  const hiddenSidebarButtonList = useOverridableSetting('hiddenSidebarButtonList', []);

  if (hidden || lodashIsEmpty(buttons)) {
    return null;
  }

  const filteredButtons = [...buttons].filter((button) => !lodashIncludes(hiddenSidebarButtonList, button.id));

  return (
    <ResultSplitViewSidebarWrapper>
      {filteredButtons.map((info) => <RenderButton key={info.id} {...info} />)}
      <CornerIcon component={cornerIcon} />
    </ResultSplitViewSidebarWrapper>
  );
};

ResultSplitViewSidebar.propTypes = {
  buttons: PropTypes.array,
  hidden: PropTypes.bool,
};

export default ResultSplitViewSidebar;
