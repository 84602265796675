import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PropTypes from 'prop-types';
import { UI_AUTHORIZE_PATH } from '../../../constants/routes/ui';
import ProjectInfoContainer from './ProjectInfoContainer';

const { projectInfo } = UI_AUTHORIZE_PATH;

const SetUpPage = (props) => {
  const { match } = props;

  return (
    <Switch>
      <Route path={`${match.path}/${projectInfo}`} component={ProjectInfoContainer} />
      <Redirect to={`${match.path}/${projectInfo}`} />
    </Switch>
  );
};

SetUpPage.propTypes = {
  match: PropTypes.object,
};

export default SetUpPage;
