export const generateBuildingsData = (overrides = {}) => ({
  projectSummary: {
    annualEnergyUseGas: Math.floor(Math.random() * 100000),
    annualEnergyUseElectricity: Math.floor(Math.random() * 100000),
    annualEnergyUseTotal: Math.floor(Math.random() * 200000),
    buildingEui: Math.floor(Math.random() * 500),
    buildingConditionedAreaEui: Math.floor(Math.random() * 500),
    netInternalArea: Math.floor(Math.random() * 10000),
    netConditionedInternalArea: Math.floor(Math.random() * 10000),
    ...overrides,
    energyLoadDistribution: {
      cooling: Math.floor(Math.random() * 10000),
      heating: Math.floor(Math.random() * 10000),
      lighting: Math.floor(Math.random() * 10000),
      equipment: Math.floor(Math.random() * 10000),
      pump: Math.floor(Math.random() * 10000),
      fans: Math.floor(Math.random() * 10000),
      hotWater: Math.floor(Math.random() * 10000),
      ...overrides.energyLoadDistribution,
    },
  },
});
