/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable block-scoped-var */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-mixed-operators */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
import { difference, intersection, union } from 'polygon-clipping';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import * as THREE from 'three';
import CalcOmbb from './ombb-rotating-calipers/ombb';
import Vector from './ombb-rotating-calipers/vector';
import { getPointsConvexHull } from './jstsHelpers';

// https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
// eslint-disable-next-line
export const roundNumber = (num, precision) => Number(Math.round(num + 'e+' + precision) + 'e-' + precision);
export const clamp = (a, b = 0, c = 1) => Math.min(c, Math.max(b, a));

// Minimalist vector math
export const vecScale = (vec, scaleBy) => vec.map((element) => element * scaleBy);
export const vecAdd = (vec1, vec2) => vec1.map((element, i) => element + vec2[i]);
export const vecSub = (vec1, vec2) => vec1.map((element, i) => element - vec2[i]);
export const vecDot = (vec1, vec2) => vec1.map((element, i) => element * vec2[i]).reduce((a, b) => a + b);
export const vecDistance = (vec1, vec2) => vecLength(vecSub(vec1, vec2));
export const vecSqrLength = (vec) => vec.map((element) => element * element).reduce((a, b) => a + b);
export const vecLength = (vec) => Math.sqrt(vecSqrLength(vec));
export const vecTo3d = (vec) => [vec[0], vec[1], 0];
export const vecNormalize = (vec) => vecScale(vec, 1 / vecLength(vec));
export const vecExtend = (vec, extendBy) => vecAdd(vec, vecScale(vec, extendBy / vecLength(vec)));
export const vecMoveTowards = (vec1, vec2, amount) => vecAdd(vec1, vecScale(vecNormalize(vecSub(vec2, vec1)), amount));
export const vecLerp = (vec1, vec2, t) => vec1.map((element, i) => element * (1 - t) + vec2[i] * t);
export const inverseLerp = (min, max, value) => clamp((value - min) / (max - min));
export const vecAngle = (vec) => Math.atan2(vec[1], vec[0]); // Radians
export const vecTransform = (vec, transformTriplet, pivotPoint) => transformPolygon([vec], transformTriplet, pivotPoint)[0];
export const vecClone = (vec) => vec.map((element) => element);
export const vecFixed = (vec, fix = 10) => vec.map((element) => roundNumber(element, fix));
export const roundVecList = (array, fix) => array?.map((vec) => vecFixed(vec, fix));

export const extrudeEdge = (edge, direction, amount) => {
  const delta = vecScale(direction, amount);
  return [edge[0], edge[1], vecAdd(edge[1], delta), vecAdd(edge[0], delta), edge[0]];
};

export const RAD_2_DEG = 180 / Math.PI;
export const DEG_2_RAD = Math.PI / 180;

// Makes the angle to be 0 < angle < 360
export const normalizeAngle = (deg) => {
  while (deg < 0) deg += 360;
  while (deg >= 360) deg -= 360;
  return deg;
};

// Makes the angle to be 0 < angle < (Math.PI * 2)
export const normalizeRad = (rad) => {
  while (rad < 0) rad += Math.PI * 2;
  while (rad >= Math.PI * 2) rad -= Math.PI * 2;
  return rad;
};

export const vecIntersection = (p1, p2, p3, p4) => {
  const x1 = p1[0];
  const y1 = p1[1];
  const x2 = p2[0];
  const y2 = p2[1];

  const x3 = p3[0];
  const y3 = p3[1];
  const x4 = p4[0];
  const y4 = p4[1];

  const det = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);

  if (det === 0) {
    return false;
  }

  const x = ((x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4)) / det;
  const y = ((x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4)) / det;

  return [x, y];
};

export const polygonIterEdges = (poly, cb) => {
  let i;
  for (i = 0; i < poly.length - 1; i++) {
    cb(poly[i], poly[i + 1]);
  }
};
export const shorterVertexCoordinates = (polygon) => polygon.map((vertex) => vertex.map((coordinate) => Number(coordinate.toFixed(3))));

export const getIsBuildingOutOfLot = (lotPolygon, buildingPolygon) => {
  if (!lotPolygon || !buildingPolygon) {
    return;
  }

  const newVertex = difference(
    [shorterVertexCoordinates(lotPolygon)],
    [shorterVertexCoordinates(buildingPolygon)],
  );
  const newVertexLength = lodashGet(newVertex, [0], []).length;
  return newVertexLength === 1;
};

export const doPolygonsIntersect = (poly1, poly2) => {
  const newPolygons = intersection(
    [shorterVertexCoordinates(poly1)],
    [shorterVertexCoordinates(poly2)],
  );
  const numNewPolygons = lodashGet(newPolygons, [0], []).length;
  return numNewPolygons >= 1;
};

// https://gist.github.com/maxogden/574870
export const doPointInPoly = (poly, point) => {
  for (var c = false, i = -1, l = poly.length, j = l - 1; ++i < l; j = i) {
    ((poly[i][1] <= point[1] && point[1] < poly[j][1])
      || (poly[j][1] <= point[1] && point[1] < poly[i][1]))
      && point[0]
        < ((poly[j][0] - poly[i][0]) * (point[1] - poly[i][1]))
          / (poly[j][1] - poly[i][1])
          + poly[i][0]
      && (c = !c);
  }
  return c;
};

export const getPolygonsUnion = (polys) => {
  // Debugging note: enable the following line to log the inputs to the console, and potentially add them to the test suite.
  // console.log(JSON.stringify(polys));

  const shorterPolygons = polys.map((poly) => [shorterVertexCoordinates(poly)]);
  const unionPoly = union(...shorterPolygons);
  // We expect to receive one polygon with one part (not a multipolygon).
  if (unionPoly.length === 1 && unionPoly[0].length === 1) {
    return unionPoly[0][0];
  }
  // TODO: This shouldn't occur. Perhaps log a warning?
  return polys[0];
};

export const getOrientedBoundingBox = (polygonPoints) => {
  // We used to use CalcConvexHull from the same lib as CalcOmbb but it had infinite loop issues.
  // We therefore switched to jsts' convex hull, which returns the points in opposite order, so we reverse them.
  const convexHull = getPointsConvexHull(polygonPoints);
  const convexHullVector = convexHull.slice(0, -1).map(
    (vertex) => new Vector(vertex[0], vertex[1]),
  ).reverse();
  const obb = new CalcOmbb(convexHullVector);
  const obbPoints = obb.map((vector) => [vector.x, vector.y]);
  obbPoints.push(obbPoints[0]);
  return obbPoints;
};

export const getRectanglePolygonCenter = (rectPoints) => {
  const p1 = rectPoints[0];
  const p2 = rectPoints[2];
  return vecLerp(p1, p2, 0.5);
};

export const getPointsCenter = (points) => {
  if (lodashIsEmpty(points)) {
    return;
  }
  let centerPoint = [0, 0];
  points?.forEach((p) => {
    centerPoint = vecAdd(centerPoint, p);
  });
  centerPoint = vecScale(centerPoint, 1 / points.length);
  return centerPoint;
};

// Rotation is in radians
export const rotatePolygon = (polygonPoints, pivotPoint, rotation) => {
  const cos = Math.cos(rotation);
  const sin = Math.sin(rotation);
  return polygonPoints.map((vertex) => {
    const centeredVertex = [
      vertex[0] - pivotPoint[0],
      vertex[1] - pivotPoint[1],
    ];
    const rotatedVertex = [
      centeredVertex[0] * cos - centeredVertex[1] * sin,
      centeredVertex[1] * cos + centeredVertex[0] * sin,
    ];
    return [rotatedVertex[0] + pivotPoint[0], rotatedVertex[1] + pivotPoint[1]];
  });
};

export const extendRectanglePolygon = (rectPoints, extendBy) => {
  const v1 = rectPoints[0];
  const v2 = rectPoints[1];
  const v4 = rectPoints[3];
  // const ext1 = vecScale(vecNormalize(vecSub(v2, v1)), extendBy);
  // const ext2 = vecScale(vecNormalize(vecSub(v4, v1)), extendBy);

  const ext1 = vecSub(vecMoveTowards(v1, v2, extendBy), v1);
  const ext2 = vecSub(vecMoveTowards(v1, v4, extendBy), v1);

  const negExt1 = vecScale(ext1, -1);
  const negExt2 = vecScale(ext2, -1);

  const newV1 = vecAdd(vecAdd(v1, negExt1), negExt2);
  const newV2 = vecAdd(vecAdd(v2, ext1), negExt2);
  const newV4 = vecAdd(vecAdd(v4, negExt1), ext2);

  const newV3 = vecAdd(
    newV1,
    vecAdd(vecSub(newV2, newV1), vecSub(newV4, newV1)),
  );
  return [newV1, newV2, newV3, newV4, newV1];
};

export const translatePolygon = (polygonPoints, deltaX, deltaY) => polygonPoints.map((vertex) => [vertex[0] + deltaX, vertex[1] + deltaY]);

export const transformPolygon = (polygonPoints, transform, pivotPoint) => translatePolygon(
  rotatePolygon(polygonPoints, pivotPoint, transform[2]),
  transform[0],
  transform[1],
);

export const getPolygonLongestEdge = (polygon) => {
  let longestEdge;
  let longestEdgeSqrLength = 0;
  let longestEdgeCenterY = 0;
  polygonIterEdges(polygon, (v1, v2) => {
    const sqrLength = vecSqrLength(vecSub(v1, v2));
    const centerY = vecLerp(v1, v2, 0.5)[1];
    if (
      sqrLength > longestEdgeSqrLength
      || (sqrLength === longestEdgeCenterY && centerY < longestEdgeCenterY)
    ) {
      longestEdge = [v1, v2];
      longestEdgeSqrLength = sqrLength;
      longestEdgeCenterY = centerY;
    }
  });
  return longestEdge;
};

// Loosely based on https://stackoverflow.com/questions/27409074/converting-3d-position-to-2d-screen-position-r69
export const projectWorldToScreenPosition = (renderer, vec, camera) => {
  const vector = new THREE.Vector3(vec[0], vec[1], vec[2]);

  const { canvas } = renderer.getContext();
  const widthHalf = 0.5 * canvas.width;
  const heightHalf = 0.5 * canvas.height;

  vector.project(camera);

  vector.x = vector.x * widthHalf + widthHalf;
  vector.y = -(vector.y * heightHalf) + heightHalf;

  const domRect = canvas.getBoundingClientRect();

  return [domRect.x + vector.x, domRect.y + vector.y];
};

export const getPolygonArea = (vertices) => Math.abs(getPolygonSignedArea(vertices));

export const getPolygonSignedArea = (vertices) => {
  if (lodashIsEmpty(vertices)) {
    return;
  }

  // https://stackoverflow.com/questions/16285134/calculating-polygon-area adapted to modern js
  let total = 0;

  for (let i = 0, l = vertices.length; i < l; i++) {
    const addX = vertices[i][0];
    const addY = vertices[i === vertices.length - 1 ? 0 : i + 1][1];
    const subX = vertices[i === vertices.length - 1 ? 0 : i + 1][0];
    const subY = vertices[i][1];

    total += addX * addY * 0.5;
    total -= subX * subY * 0.5;
  }

  return total;
};

export const transformPolygonToBox = (polygonPoints, boxPolygonPoints) => {
  const is3D = boxPolygonPoints[0].length === 3;

  const points2D = is3D
    ? boxPolygonPoints.map((p) => [p[0], p[1]])
    : boxPolygonPoints;

  // strech polygonPoints (a 1X1 shape, centered around 0,0) fo fit a box
  let [p1, p3] = [points2D[0], points2D[2]];
  const p2 = points2D[1];

  if (vecDistance(p1, p2) < vecDistance(p2, p3)) {
    [p1, p3] = [p3, p1];
  }

  const boxCentroid = getRectanglePolygonCenter(points2D);

  const [length, width] = [vecDistance(p1, p2), vecDistance(p2, p3)];
  const lengthVector = vecSub(p2, p1);
  const theta = vecAngle(lengthVector);
  const shape = polygonPoints.map((point) => [
    point[0] * length,
    point[1] * width,
  ]);
  const result = transformPolygon(
    shape,
    [boxCentroid[0], boxCentroid[1], theta],
    [0, 0],
  );
  if (is3D) {
    // TODO: Do something smarter than max - like project to the two triangles defined by boxPolygonPoints
    const maxHeight = Math.max(...boxPolygonPoints.map((p) => p[2]));
    return result.map((p) => [p[0], p[1], maxHeight]);
  }
  return result;
};

export const transformPolygonToBox2 = (polygonPoints, boxPolygonPoints, ignoreAspectRatio, mirrorX, mirrorY) => {
  if (!boxPolygonPoints) {
    return polygonPoints;
  }
  const p1 = boxPolygonPoints[0];
  const d1 = vecSub(boxPolygonPoints[1], boxPolygonPoints[0]);
  const d2 = ignoreAspectRatio
    ? vecSub(boxPolygonPoints[3], boxPolygonPoints[0])
    : vecScale(
      vecNormalize(vecSub(boxPolygonPoints[3], boxPolygonPoints[0])),
      vecLength(d1),
    );

  const points = polygonPoints.map(([x, y]) => {
    x += 0.5;
    y += 0.5;
    if (mirrorX) {
      x = 1 - x;
    }
    if (mirrorY) {
      y = 1 - y;
    }
    return vecAdd(vecAdd(p1, vecScale(d1, x)), vecScale(d2, y));
  });
  return points;
};

export const projectPointToLine = (p, lineFrom, lineTo, customClamp) => {
  // Based on https://jsfiddle.net/soulwire/UA6H5/
  const atob = vecSub(lineTo, lineFrom);
  const atop = vecSub(p, lineFrom);
  const len = vecSqrLength(atob);
  let dot = vecDot(atob, atop);
  const t = customClamp ? Math.min(1, Math.max(0, dot / len)) : dot / len;

  dot = (lineTo[0] - lineFrom[0]) * (p[1] - lineFrom[1]) - (lineTo[1] - lineFrom[1]) * (p[0] - lineFrom[0]);

  return {
    point: [lineFrom[0] + atob[0] * t, lineFrom[1] + atob[1] * t],
    left: dot < 1,
    dot,
    t,
  };
};

export const getPolygonCentroid = (polygon) => {
  if (!polygon?.length) {
    return null;
  }
  // https://stackoverflow.com/questions/9692448/how-can-you-find-the-centroid-of-a-concave-irregular-polygon-in-javascript
  const poly = [...polygon];
  // Make sure the last point is equal to the first, and add it if not.
  if (
    polygon[0][0] !== polygon[polygon.length - 1][0]
    || polygon[0][1] !== polygon[polygon.length - 1][1]
  ) {
    poly.push(vecClone(polygon[0]));
  }
  let twicearea = 0;
  let x = 0;
  let y = 0;
  const nPts = poly.length;
  let p1;
  let p2;
  let f;
  for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
    p1 = poly[i];
    p2 = poly[j];
    f = p1[0] * p2[1] - p2[0] * p1[1];
    twicearea += f;
    x += (p1[0] + p2[0]) * f;
    y += (p1[1] + p2[1]) * f;
  }
  f = twicearea * 3;
  return [x / f, y / f];
};

export const getRectangleDimensions = (polygonPoints, toFixedNum) => {
  if (polygonPoints.length > 5) {
    // Not a rectangle, use bounding rectangle
    polygonPoints = getOrientedBoundingBox(polygonPoints);
  }

  const toFixed = (e) => Number(e.toFixed(toFixedNum));

  const x = vecDistance(polygonPoints[0], polygonPoints[1]);
  const y = vecDistance(polygonPoints[1], polygonPoints[2]);
  const dimensions = toFixedNum ? [toFixed(x), toFixed(y)] : [x, y];

  return dimensions;
};

export const getCosAngleBetweenVectors = (v1, v2) => vecDot(v1, v2) / (vecLength(v1) * vecLength(v2));
export const getCosAngleBetweenSegments = (seg1, seg2) => getCosAngleBetweenVectors(vecSub(seg1[1], seg1[0]), vecSub(seg2[1], seg2[0]));

export const getNearestPointIndex = (points, point) => {
  const distances = points.map((p) => vecSqrLength(vecSub(point, p)));
  const minDistance = Math.min(...distances);
  const minIndex = distances.indexOf(minDistance);
  return minIndex;
};
