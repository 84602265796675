import React from 'react';

export default () => (
  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="35" height="35" />
    <path d="M2.48063 2H32.5193C32.7853 2 32.9999 2.21468 32.9999 2.48063V32.5193C32.9999 32.7853 32.7853 32.9999 32.5193 32.9999H2.48063C2.21469 32.9999 2 32.7853 2 32.5193V2.48063C2 2.21469 2.21468 2 2.48063 2ZM32.0386 2.96126H2.9613V32.0386H32.0386V2.96126Z" fill="black" />
    <path d="M11.8433 32.5193C11.8433 32.7853 12.0579 33 12.3239 33C12.5898 33 12.8045 32.7853 12.8045 32.5193V22.5897C12.8045 22.3238 12.5898 22.1091 12.3239 22.1091C12.0579 22.1091 11.8433 22.3237 11.8433 22.5897V32.5193Z" fill="black" />
    <path d="M2.48063 19.3538C2.21469 19.3538 2 19.5684 2 19.8344C2 20.1003 2.21468 20.315 2.48063 20.315H8.67112C8.93706 20.315 9.15175 20.1003 9.15175 19.8344C9.15175 19.5684 8.93707 19.3538 8.67112 19.3538H2.48063Z" fill="black" />
    <path d="M17.2703 2.48069C17.2703 2.21475 17.0557 2.00006 16.7897 2.00006C16.5238 2.00006 16.3091 2.21474 16.3091 2.48069V8.06214C16.3091 8.32808 16.5238 8.54277 16.7897 8.54277C17.0557 8.54277 17.2703 8.32809 17.2703 8.06214V2.48069Z" fill="black" />
    <path d="M32.5194 19.3008C32.7853 19.3008 33 19.0861 33 18.8202C33 18.5542 32.7853 18.3396 32.5194 18.3396H17.3717V12.8332C17.3717 12.5672 17.157 12.3525 16.891 12.3525C16.6251 12.3525 16.4104 12.5672 16.4104 12.8332V18.8202C16.4104 19.0862 16.6251 19.3009 16.891 19.3009L32.5194 19.3008Z" fill="black" />
    <path d="M25.3406 26.5951C25.3406 26.861 25.5553 27.0757 25.8212 27.0757C26.0872 27.0757 26.3018 26.8611 26.3018 26.5951V18.8186C26.3018 18.5527 26.0872 18.338 25.8212 18.338C25.5553 18.338 25.3406 18.5527 25.3406 18.8186V26.5951Z" fill="black" />
  </svg>
);
