import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PropTypes from 'prop-types';
import { UI_AUTHORIZE_PATH } from '../constants/routes/ui';
import GenerateSwappContainer from '../components/projectSettings/setup/GenerateSwappContainer';
import FeasibilitySiteGenerator from '../components/projectSettings/setup/FeasibilitySiteGenerator';
import SetupPage from '../components/projectSettings/setup/SetupPage';

const { root, setup, FEASIBILITY_STUDY, TEST_FIT } = UI_AUTHORIZE_PATH;

const TestFitPage = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:projectId/${setup}`} component={SetupPage} />
      <Route path={`${match.url}/${FEASIBILITY_STUDY}`} component={FeasibilitySiteGenerator} />
      <Route path={`${match.url}/${TEST_FIT}`} component={GenerateSwappContainer} />
      <Redirect to={root} />
    </Switch>
  );
};

TestFitPage.propTypes = {
  match: PropTypes.object,
};

export default TestFitPage;
