import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { Select, Tooltip } from 'antd';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import lodashCloneDeep from 'lodash/cloneDeep';
import lodashIncludes from 'lodash/includes';
import { StyledInputNumber, StyledSelect } from 'styles/commonComponents.styles';
import { isValidURL, numberWithComma } from 'utils/helpers/dataDisplay';
import { STANDARDS, TARGET_STORIES_TYPES, GROUND_FLOOR_TYPES } from 'constants/feasibilityConts';
import { inchesToMeters } from 'utils/helpers/unitsHelpers';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { FEASIBILITY_TARGET_FORM } from 'constants/fieldNames';
import MetricImperialInput from '../../../common/MetricImperialInput';
import Dropdown from '../../../common/Dropdown';
import { FeasibilitySitesItemWrapper, SiteHeader,
  SiteBody, SiteImage, SiteDataWrapper, SiteDataItem, SiteDataItemLabel } from './FeasibilitySitesItem.styles';

const { Option } = Select;

const targetStoriesTypes = [
  { key: TARGET_STORIES_TYPES.MAX_STORIES, value: 'FEASIBILITY_SITES_FORM_MAX_STORIES' },
  { key: TARGET_STORIES_TYPES.TARGET_STORIES, value: 'FEASIBILITY_SITES_FORM_TARGET_STORIES' },
];

const OPEN_GROUND_FLOOR_OPTIONS = [
  { key: GROUND_FLOOR_TYPES.NA, value: 'FEASIBILITY_SITES_FORM_GROUND_FLOOR_TYPE_NA' },
  { key: GROUND_FLOOR_TYPES.OPEN_GROUND_FLOOR, value: 'FEASIBILITY_SITES_FORM_GROUND_FLOOR_TYPE_OPEN_GROUND_FLOOR' },
  { key: GROUND_FLOOR_TYPES.RETAIL, value: 'FEASIBILITY_SITES_FORM_GROUND_FLOOR_TYPE_RETAIL' },
];

const FeasibilitySitesItem = forwardRef((props, ref) => {
  const { sites, onChange, unitType, isViewer } = props;
  const match = useRouteMatch();
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));
  const openGroundFloorOptions = lodashGet(STANDARDS, `[${unitType}].formSettings[${FEASIBILITY_TARGET_FORM.LOT_DATA}].openGroundFloorOptions`, OPEN_GROUND_FLOOR_OPTIONS);

  useEffect(() => {
    updateCurrentSites(sites);
  }, [sites]);

  useEffect(() => {
    sites.forEach((lot, index) => {
      if (!lodashIncludes(openGroundFloorOptions.map((e) => e.key), lot.groundFloorType)) {
        handleChange(index, openGroundFloorOptions[0].key, 'groundFloorType');
      }
    });
  }, [unitType]);

  const updateCurrentSites = (value) => {
    onChange(value);
  };

  const handleChange = (index, value, field) => {
    const newLotData = lodashCloneDeep(sites);
    newLotData[index][field] = value;
    onChange(newLotData);
  };

  const inputNumberOptions = { min: 0, max: 100, width: 70, height: 25 };

  return (
    <div ref={ref}>
      {sites.map((lot, index) => (
        <FeasibilitySitesItemWrapper visible={lot.visible} key={lot.lotName}>
          {/* ============ Name ============ */}
          {lot.lotName && <SiteHeader>{lot.lotName}</SiteHeader>}
          <SiteBody>
            {/* ============ Image ============ */}
            {isValidURL(lot.lotImage) && <SiteImage src={lot.lotImage} />}
            <SiteDataWrapper>

              {/* ============ Target Stories ============ */}
              <SiteDataItem>
                {isViewer
                  ? <SiteDataItemLabel>{T.translate('FEASIBILITY_SITES_FORM_MAX_STORIES_COUNT')}</SiteDataItemLabel>
                  : (
                    <Dropdown
                      width={100}
                      dropDownData={targetStoriesTypes}
                      selectedKey={lot.targetStoriesType}
                      onChange={(key) => handleChange(index, key, 'targetStoriesType')}
                    />
                  )}
                <StyledInputNumber
                  {...inputNumberOptions}
                  value={lot.targetStories}
                  onChange={(e) => handleChange(index, e, 'targetStories')}
                />
              </SiteDataItem>

              {/* ============ Open Terrain ============ */}
              {!isViewer && (
                <SiteDataItem>
                  <SiteDataItemLabel>{T.translate('FEASIBILITY_SITES_FORM_OPEN_TERRAIN')}</SiteDataItemLabel>
                  <StyledInputNumber
                    {...inputNumberOptions}
                    value={lot.openTerrain}
                    formatter={(value) => numberWithComma(value, { suffix: '%', fixed: 0 })}
                    onChange={(e) => handleChange(index, e, 'openTerrain')}
                  />
                </SiteDataItem>
              )}

              {/* ============ Setback Line ============ */}
              {!isViewer && (
                <SiteDataItem>
                  <SiteDataItemLabel>{`${T.translate('FEASIBILITY_SITES_FORM_SETBACK_LINE')} (${T.translate(isImperial ? 'in' : 'm')})`}</SiteDataItemLabel>
                  <MetricImperialInput
                    {...inputNumberOptions}
                    value={lot.setbackLine}
                    unit={inchesToMeters}
                    isImperial={isImperial}
                    max={5000}
                    onChange={(e) => handleChange(index, e, 'setbackLine')}
                  />
                </SiteDataItem>
              )}

              {/* ============ Parking ============ */}
              <SiteDataItem>
                <SiteDataItemLabel>
                  <Tooltip title={T.translate('FEASIBILITY_SITES_FORM_OPEN_GROUND_FLOOR_TOOLTIP')}>
                    {T.translate('FEASIBILITY_SITES_FORM_GROUND_FLOOR_TYPE')}
                  </Tooltip>
                </SiteDataItemLabel>
                <StyledSelect
                  value={lot.groundFloorType}
                  defaultValue={GROUND_FLOOR_TYPES.NA}
                  onChange={(key) => handleChange(index, key, 'groundFloorType')}
                  height={25}
                  width={140}
                  disabled={openGroundFloorOptions.length <= 1}
                >
                  {openGroundFloorOptions.map((option) => (
                    <Option value={option.key}>{T.translate(option.value)}</Option>
                  ))}
                </StyledSelect>
              </SiteDataItem>
            </SiteDataWrapper>
          </SiteBody>
        </FeasibilitySitesItemWrapper>
      ))}
    </div>
  );
});

FeasibilitySitesItem.propTypes = {
  sites: PropTypes.array,
  onChange: PropTypes.func,
  unitType: PropTypes.string,
  isViewer: PropTypes.bool,
};

export default FeasibilitySitesItem;
