const PARSED_TYPES = [
  'room',
  'floor',
  'tube',
  'tile',
];

export const parseId = (id) => {
  const strings = id.split('_');
  const returnObj = {};
  PARSED_TYPES.forEach((type) => {
    returnObj[type] = strings[strings.indexOf(type) + 1];
  });
  return returnObj;
};

export const findRoomIndexInFloor = (room, floor) => {
  floor.rooms.forEach((floorRoom, index) => {
    if (JSON.stringify(floorRoom.points) === JSON.stringify(room.points)) {
      return index;
    }
  });
};

const propertiesToTransform = [
  'facades',
  'points',
  'holes',
  'envelope',
  'envelope_holes',
];

export const transformObject = (object, deltaX, deltaY) => {
  Object.keys(object).forEach((key) => {
    if (Array.isArray(object[key])) {
      if (propertiesToTransform.includes(key)) {
        object[key].forEach((point) => {
          if (Array.isArray(point[0])) {
            point.forEach((pp) => {
              pp[0] += deltaX;
              pp[1] += deltaY;
            });
          } else {
            point[0] += deltaX;
            point[1] += deltaY;
          }
        });
      } else {
        object[key].forEach((child) => {
          transformObject(child, deltaX, deltaY);
        });
      }
    }
  });
};
