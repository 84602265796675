import React from 'react';
import PropTypes from 'prop-types';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashGet from 'lodash/get';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { loadingSelector, swappProfileResultsSelector } from '../../store/swappProfile';
import { getUnitSystemByProjectIdSelector } from '../../store/userSettings';
import TestFitViewerContainer from '../../utils/swappViewer/testFit/TestFitViewerContainer';
import LoadingSpinner from '../common/LoadingSpinner';
import { getUrlParams } from '../../utils/helpers/navigationHelpers';

export const TestTitThumbnailPageWrapper = styled.div`
  position: relative;
  height: ${({ height }) => (height ? `${height}px` : '300px')}; // default is x2 of the thumbnail size
  width: ${({ width }) => (width ? `${width}px` : '600px')}; // default is x2 of the thumbnail size
  // background-color: ${({ theme }) => `${theme.colors.primaryColor}22`};
`;

const TestTitThumbnail = (props) => {
  const { match } = props;
  const { projectId, profileId } = match.params;
  const isLoading = useSelector(loadingSelector);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, projectId));
  const swappProfileResults = useSelector((state) => swappProfileResultsSelector(state, profileId, isImperial));
  const params = getUrlParams();

  if (isLoading || lodashIsEmpty(swappProfileResults) || lodashIsEmpty(lodashGet(swappProfileResults, 'multiBoundaryFile'))) {
    return <LoadingSpinner />;
  }

  return (
    <TestTitThumbnailPageWrapper width={params.width} height={params.height}>
      <TestFitViewerContainer plan={swappProfileResults?.multiBoundaryFile} profileId={profileId} hideGrid excludeFloorIndex={Number(params.floor)} />
    </TestTitThumbnailPageWrapper>
  );
};

TestTitThumbnail.propTypes = {
  match: PropTypes.object,
};

export default TestTitThumbnail;
