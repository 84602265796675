import styled from 'styled-components';

export const ForgeDisplayToggleWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray_01};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  height: 50px;
  width: 130px;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.12);
  margin-right: 10px;
`;

export const IconWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme, selected }) => (selected ? theme.colors.primaryColor : theme.colors.gray_09)};
  cursor: pointer;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primaryColor};
  }
`;

export const Separator = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray_06};
`;
