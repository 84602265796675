import React from 'react';
import { useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import T from 'i18n-react';
import { useHistory } from 'react-router';
import { feasibilityReportSelector } from 'store/swappProfile/selectors';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import {
  ContentImage,
  ContentImageLabel,
  ContentImagesWithLabelWrapper,
  ContentImagesWrapper,
  ContentTitle,
  ContentWrapper,
  VerticalTitleContentWrapper,
  ContentColumnWrapper,
  StyledText,
  ContentRowWrapper,
} from '../ReportPages.styles';

const ExistingStore = () => {
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const reportData = useSelector((state) => feasibilityReportSelector(state, locationData.profileId));
  const siteExistingStoreData = lodashGet(reportData, 'site.siteExistingStoreData');

  if (lodashIsEmpty(siteExistingStoreData)) {
    return null;
  }

  return (
    <>
      {/* ======== Top Part ======== */}
      <VerticalTitleContentWrapper id="site/existing-store">
        <ContentTitle>{T.translate('Existing Store')}</ContentTitle>

        <ContentRowWrapper>
          {/* ======== description ======== */}
          <ContentColumnWrapper width={180}>
            <StyledText size="xSmall">{T.translate(siteExistingStoreData.description)}</StyledText>
          </ContentColumnWrapper>

          {/* ======== images ======== */}
          <ContentWrapper>
            <ContentImagesWrapper width={435}>
              {siteExistingStoreData.images.map((image, index) => (
                <ContentImagesWithLabelWrapper key={index}>
                  <ContentImage src={image.url} width={435} height={230} />
                  {lodashGet(image, 'label') && <ContentImageLabel>{image.label}</ContentImageLabel>}
                </ContentImagesWithLabelWrapper>
              ))}
            </ContentImagesWrapper>
          </ContentWrapper>
        </ContentRowWrapper>
      </VerticalTitleContentWrapper>

      {/* ======== Bottom Part ======== */}
      <VerticalTitleContentWrapper>
        <ContentTitle>{T.translate('Existing Store Plan')}</ContentTitle>
        <ContentImage src={siteExistingStoreData.storePlanUrl} height={995} />
      </VerticalTitleContentWrapper>
    </>
  );
};

export default React.memo(ExistingStore);
