import React from 'react';

export default () => (
  <svg width="171" height="110" viewBox="0 0 467 299" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M259.227 29.8485C248.14 29.8485 236.13 35.1549 231.51 37.8081C225.571 33.8267 215.672 29.8485 205.773 29.8485C190.875 29.8485 182.675 32.5017 176.076 35.8182C182.015 25.8687 195.874 0 233.49 0C269.126 0 281.005 22.5522 288.924 35.8182C283.645 33.8283 276.472 29.8485 259.227 29.8485Z" fill="#DEDEDE" />
    <path d="M205.773 167.152C216.86 167.152 228.87 161.845 233.49 159.192C239.429 163.173 249.328 167.152 259.227 167.152C274.125 167.152 282.325 164.498 288.924 161.182C282.985 171.131 269.126 197 231.51 197C195.874 197 183.995 174.448 176.076 161.182C181.355 163.172 188.528 167.152 205.773 167.152Z" fill="#DEDEDE" />
    <path d="M300.803 125.364C300.803 114.22 295.524 102.148 292.884 97.5051C296.845 91.5354 300.803 81.5859 300.803 71.6364C300.803 56.6627 298.163 48.4209 294.864 41.7879C304.763 47.7576 330.5 61.6869 330.5 99.495C330.5 135.313 308.062 147.253 294.864 155.212C296.843 149.906 300.803 142.696 300.803 125.364Z" fill="#DEDEDE" />
    <path d="M164.197 71.6364C164.197 82.7798 169.476 94.8519 172.116 99.495C168.155 105.465 164.197 115.414 164.197 125.364C164.197 140.337 166.837 148.579 170.136 155.212C160.237 149.242 134.5 135.313 134.5 97.5051C134.5 61.6869 156.938 49.7475 170.136 41.7879C168.157 47.0943 164.197 54.3036 164.197 71.6364Z" fill="#DEDEDE" />
    <path d="M288.924 97.5051C288.924 128.277 264.105 153.222 233.49 153.222C202.874 153.222 178.056 128.277 178.056 97.5051C178.056 66.7333 202.874 41.7879 233.49 41.7879C264.105 41.7879 288.924 66.7333 288.924 97.5051Z" fill="#DEDEDE" />
    <path d="M13 211.5H16.5L16 223.5H32.5V228H16V263C16 263.167 16.3 264.4 17.5 268C18.7 271.6 27 272 27 272L25.5 276C14 276.5 11.8333 269.333 11 265.5L10.5 228H0.5V225L9 223L13 211.5Z" fill="#DEDEDE" />
    <path d="M138 225L137 229.5C132 227.5 119 225.607 114.5 228.5C110 231.393 103.5 234.5 103.5 250.5C103.5 266.531 113.5 271 119.5 272C125.5 273 132.5 271 138 270V274C130 276.5 115 279 105.5 270C96 261 97.4263 249.418 99 240.5C100.765 230.5 111.5 224 118.5 223C126.435 221.866 133.5 223.5 138 225Z" fill="#DEDEDE" />
    <path d="M157 201.5H151.5V275.5H157C157.167 265.167 156.649 245.307 157 242.5C157.5 238.5 160 227 176 227C186 227 190 231 191 240V275.5H196C195.833 262.333 196.136 240.535 196 238.5C195.5 231 191.5 222.455 173.5 223C163.505 223.303 159.5 228.833 157 232V201.5Z" fill="#DEDEDE" />
    <path d="M246 226L244 230.5C244 230.5 234 226.5 228 227C221.647 227.529 213.5 229 213.5 234.5C213.5 242.002 221.743 244.011 228.5 246.5C238 250 247.5 252.5 247 263C246.347 276.714 227 276 220.5 276C215.552 276 211.667 274.833 207.5 273.5V268C210.5 271.5 228.147 272.744 232.5 271.5C239.5 269.5 242 266.5 242 261.5C242 256.5 231.5 252 224 250C216.5 248 209.238 243.623 208.5 235.5C208 230 213.5 223.5 224 223C232.5 222.595 242.5 224 246 226Z" fill="#DEDEDE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M66 223C73.8713 223 81.1071 226.5 84.5 236C86.5 241.6 86 246.833 86 250.5H45.5C46 259.5 49.5346 269.486 59 271C71.5 273 78.5 271.5 83.5 268.5V273C77.5 275.5 74.5 276 66.5 276C54.5 276 50.2421 271.833 48.5 270.5C46 268.588 40.0674 264.439 40.5 248C40.9 232.8 48 223 66 223ZM80.5 246H46C46.5 240.5 48 227 64 227C80 227 80 240 80.5 246Z" fill="#DEDEDE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M260.5 223.5H265.5L266.5 231C269.333 227.833 272.789 223.512 282 223C291 222.5 299.625 224.5 304 231.5C309 239.5 310.278 253.11 306 263.5C302.5 272 295.5 277 280 276C272.846 275.538 265.5 267.5 265.5 267.5V298.5H261L260.5 223.5ZM284.5 227C277.783 226.96 269 228.537 266.5 241.5V257C268 263 269 271.606 283.5 272C298 272.394 303.332 261.123 303 249.5C302.5 232 295.875 227.068 284.5 227Z" fill="#DEDEDE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M326 231L324 226.5C329 224.5 340.591 221.957 346.5 223C355 224.5 360 229.5 360.5 235.5C360.966 241.095 360.667 264.5 360.5 275.5H357L355.5 266.5C352.667 270.167 346.5 276 336.5 276C329.189 276 320 273.5 319 266C317.738 256.531 318.352 251.042 332.5 247C339.5 245 346.5 245.5 356 245C356.167 239.333 356 233.5 351 229C347.716 226.044 335.5 225.5 326 231ZM345.5 270.5C348.09 269.676 353.5 265.5 355.5 258.5V249.5C355.5 249.5 342.2 249.6 337 250C330.5 250.5 323.5 254.5 324 262.5C324.565 271.538 334.5 274 345.5 270.5Z" fill="#DEDEDE" />
    <path d="M412.5 225L411.5 229.5C406.5 227.5 393.5 225.607 389 228.5C384.5 231.393 378 234.5 378 250.5C378 266.531 388 271 394 272C400 273 407 271 412.5 270V274C404.5 276.5 389.5 279 380 270C370.5 261 371.926 249.418 373.5 240.5C375.265 230.5 386 224 393 223C400.935 221.866 408 223.5 412.5 225Z" fill="#DEDEDE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M446.5 223C454.371 223 461.607 226.5 465 236C467 241.6 466.5 246.833 466.5 250.5H426C426.5 259.5 430.035 269.486 439.5 271C452 273 459 271.5 464 268.5V273C458 275.5 455 276 447 276C435 276 430.742 271.833 429 270.5C426.5 268.588 420.567 264.439 421 248C421.4 232.8 428.5 223 446.5 223ZM461 246H426.5C427 240.5 428.5 227 444.5 227C460.5 227 460.5 240 461 246Z" fill="#DEDEDE" />
  </svg>
);
