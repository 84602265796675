export const supportedCurrencies = {
  USD: {
    name: 'USD',
    symbol: '$',
  },
  EUR: {
    name: 'EUR',
    symbol: '€',
  },
  GBP: {
    name: 'GBP',
    symbol: '£',
  },
  ILS: {
    name: 'ILS',
    symbol: '₪',
  },
};
