import {
  getProfileTemplatesRequestActionCreator,
  getProfileTemplatesSuccessActionCreator,
  getProfileTemplatesErrorActionCreator,
} from './actionsCreators';
import { API_AUTH_ROUTES } from '../../../constants/routes/api';

export const getProfileTemplatesAction = () => async (dispatch, _, agent) => {
  dispatch(getProfileTemplatesRequestActionCreator());

  try {
    const { data } = await agent.get(`${API_AUTH_ROUTES.projectProfile}${API_AUTH_ROUTES.default}`);
    dispatch(getProfileTemplatesSuccessActionCreator(data));
  } catch (e) {
    dispatch(getProfileTemplatesErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};
