import React from 'react';
import styled from 'styled-components';
import Icon from '@ant-design/icons';
import { Progress } from 'antd';

export const FeasibilityResultCardWrapper = styled.div`
  height: 320px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const FeasibilityResultCardImage = styled.img`
  width: 100%;
  height: 150px;
  max-height: 150px;
  min-height: 150px;
  background-color: #d0d0d0; // the color of the ground if the thumbnail has transparent parts
  object-fit: cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const StyledIcon = styled(Icon)`
  transform: scale(0.7);
`;

export const FeasibilityResultCardData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const StyledProgress = styled(({ progressWidth, ...props }) => <Progress {...props} />)`
  width: ${({ progressWidth }) => (progressWidth ? `${progressWidth}px` : '100%')};
  .ant-progress-text {
    font-size: 10px;
    font-weight: ${({ theme }) => theme.font.weight.normal};
  }

  .ant-progress-success-bg, .ant-progress-bg {
    background-color: ${({ theme }) => theme.colors.primaryColor} !important;
  }

  .ant-progress-outer {
    .ant-progress-inner {
      background-color: ${({ theme }) => theme.colors.gray_03} !important;
    }
  }}
  
  .ant-progress-circle-path {
    stroke: ${({ theme }) => theme.colors.primaryColor} !important;
  }
  .ant-progress-circle-trail {
    stroke: ${({ theme }) => theme.colors.gray_03} !important;
  }

  .ant-progress-text {
    color: ${({ theme }) => theme.colors.textColor} !important;
  }
`;

export const DataCellWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 60px;
`;

export const DataCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 35px;
  width: 80px;
  color: ${({ theme }) => theme.colors.textColor};
  font-size: ${({ theme }) => theme.font.size.small};
  min-height: 17px;
  margin-bottom: 10px;
`;

export const DataCellItem = styled.div`
  font-weight: ${({ theme, bold }) => (bold ? theme.font.weight.xBold : theme.font.weight.normal)};
  font-size: ${({ theme }) => theme.font.size.small};
`;

export const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
  width: 100%;
`;

export const DataRowItem = styled.div`
  font-weight: ${({ theme, bold }) => (bold && theme.font.weight.bold)};
  text-align: ${({ align }) => align};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 190px;
  overflow: hidden;
  position: relative;
  
  :after {
    content: ' ';
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 30px;
    background: linear-gradient(0deg, rgba(249,249,249,1) 20%, rgba(249,249,249,0) 100%);
  }
`;

export const InfoTotalWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray_03};
  padding-top: 10px;
`;

export const ScoreWrapper = styled.div`
  display: flex;
  height: 26px;
  line-height: 1.9;
  justify-content: center;
  background-color: ${({ theme }) => `${theme.colors.primaryColor}22`};
`;

export const Splitter = styled.div`
  height: 1px;
  margin: 7px 0;
  background-color: ${({ theme }) => theme.colors.gray_03};
`;

export const EmptyCardWrapper = styled.div`
  display: flex;
  height: 330px;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0 44px 0;
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.large};
`;
