import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LoadingContainer, StyledProgress, Text } from './LoadingSpinner.styles';

const LoadingProgress = (props) => {
  const { seconds = 45, isDone, text } = props;
  const [percent, usePercent] = useState(0);
  const time = seconds * 10;

  useEffect(() => {
    progressAnimation(usePercent);
  }, [usePercent]);

  useEffect(() => {
    if (percent <= 98 && !isDone) {
      progressAnimation(usePercent);
    }
    if (isDone) {
      finishAnimation(usePercent);
    }
  }, [usePercent, isDone, percent]);

  const progressAnimation = (updatePercent) => {
    setTimeout(() => {
      updatePercent(percent + 1);
    }, time);
  };

  const finishAnimation = (updatePercent) => {
    updatePercent(100);
  };

  return (
    <LoadingContainer>
      <StyledProgress type="circle" percent={percent} />
      {text && <Text>{text}</Text>}
    </LoadingContainer>
  );
};

LoadingProgress.propTypes = {
  seconds: PropTypes.number,
  isDone: PropTypes.bool,
  text: PropTypes.string,
};

export default LoadingProgress;
