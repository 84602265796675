import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Progress } from 'antd';
import lodashIncludes from 'lodash/includes';
import { BUILDING_INFO_TYPES } from 'constants/feasibilityConts';

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 8px;
`;

const StyledProgress = styled(Progress)`
  .ant-progress-success-bg, .ant-progress-bg {
    background-color: ${({ theme }) => theme.colors.primaryColor};
  }
`;

const TextLabel = styled.div`
`;

const EfficiencyScoreWrapper = styled.div`
    padding-top: 10px;
    //padding-left: 5px;

    :first-child {
      padding-top: 7px;
    }

  .ant-progress-inner {
    background-color: ${({ theme }) => theme.colors.gray_04};
  }
`;

const FeasibilityEfficiencyScore = ({ data }) => {
  if (!data) {
    return null;
  }

  return data.map((item, index) => lodashIncludes([BUILDING_INFO_TYPES.ROW_PERCENTAGE, BUILDING_INFO_TYPES.SCORE], item.type) && (
    <EfficiencyScoreWrapper key={index}>
      <TextContainer>
        <TextLabel>
          {item.name}
        </TextLabel>
        <TextLabel>
          {`${item.value}/100%`}
        </TextLabel>
      </TextContainer>
      <StyledProgress percent={item.value} showInfo={false} />
    </EfficiencyScoreWrapper>
  ));
};

FeasibilityEfficiencyScore.propTypes = {
  data: PropTypes.array,
};

export default React.memo(FeasibilityEfficiencyScore);
