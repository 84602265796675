import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsEqual from 'lodash/isEqual';
import { message } from 'antd';
import lodashGet from 'lodash/get';
import { updateProjectProfileAction } from 'store/swappProfile/actions/swappProfileActions';
import {
  setReportData,
  setFeasibilityResultData,
  setTestFitResultData,
  setFeasibilityEnergyData, setFeasibilityParkingData,
} from '../model/projectProfileData';

let debounceTimer = null;

export const handleOnChange = (dispatch, props) => {
  const { profile, formInstance, isLoading, settingsType, disabled } = props;
  const formData = lodashGet(profile, 'profileData.reportForm', {});
  const currentFormData = formInstance.getFieldsValue();

  if (disabled) {
    return;
  }

  const updateSettingsByType = () => {
    switch (settingsType) {
      case 'report':
        return setReportData(currentFormData);
      case 'feasibilityResult':
        return setFeasibilityResultData(currentFormData);
      case 'feasibilityEnergy':
        return setFeasibilityEnergyData(currentFormData);
      case 'feasibilityParking':
        return setFeasibilityParkingData(currentFormData);
      case 'testFitResult':
        return setTestFitResultData(currentFormData);
      default:
        return setReportData(currentFormData);
    }
  };

  if (debounceTimer) {
    clearTimeout(debounceTimer);
  }

  debounceTimer = setTimeout(() => {
    if (lodashIsEmpty(currentFormData)
        || lodashIsEmpty(profile)
        || lodashIsEqual(currentFormData, formData)
        || isLoading
    ) {
      return;
    }

    dispatch(updateProjectProfileAction(profile.id, updateSettingsByType()))
      .catch((err) => message.error(err));
  }, 800);
};
