import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { EffectComposer, SSAO } from 'react-postprocessing';

// see https://vanruesc.github.io/postprocessing/public/docs/class/src/effects/SSAOEffect.js~SSAOEffect.html
const aoConfig = {
  high: {
    samples: 80,
    rings: 10,
    radius: 40,
    intensity: 150,
  },
  testFit: {
    samples: 36,
    rings: 14,
    radius: 5,
    intensity: 50,
  },
  low: {
    samples: 9,
    rings: 7,
    radius: 20,
    intensity: 30,
  },
};

const Post = ({ aoPreset }) => (
  <Suspense fallback={null}>
    <EffectComposer smaa>
      <SSAO {...aoConfig[aoPreset]} />
    </EffectComposer>
  </Suspense>
);

Post.aoPresetsTypes = {
  high: 'high',
  testFit: 'testFit',
  low: 'low',
};

Post.defaultProps = {
  aoPreset: Post.aoPresetsTypes.high,
};

Post.propTypes = {
  aoPreset: PropTypes.string,
};

export default Post;
