import React from 'react';
import PropTypes from 'prop-types';
import { StyledLine, Label, Value } from '../StyledComponents';

const Line = ({ label, value }) => (
  <StyledLine>
    <Label>{label}</Label>
    <Value>{value}</Value>
  </StyledLine>
);

Line.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

export default Line;
