import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import T from 'i18n-react';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashGet from 'lodash/get';
import { useHistory } from 'react-router';
import { feasibilityReportSelector } from 'store/swappProfile/selectors';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import {
  VerticalTitleContentWrapper,
  ContentWrapper,
  ContentImage,
  ContentColumnWrapper,
  ContentImagesWrapper,
  ContentRowWrapper,
  ContentImagesWithLabelWrapper,
  ContentImageLabel,
  ContentTitle,
  LegendWrapper,
  LegendColor,
  StyledText,
} from '../ReportPages.styles';
import ReportTitleDropdown from '../ReportTitleDropdown';

const Plans = () => {
  const [currentPlanIndex, setCurrentPlanIndex] = useState(0);
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const reportData = useSelector((state) => feasibilityReportSelector(state, locationData.profileId));
  const plansData = lodashGet(reportData, 'design.plansData');
  const unitsColors = lodashGet(reportData, 'unitsColors');
  const currentPlanData = plansData.buildings[currentPlanIndex];

  const updateCurrentPlanIndex = (key, update) => {
    update(key);
  };

  if (lodashIsEmpty(plansData)) {
    return null;
  }

  return (
    <VerticalTitleContentWrapper>
      <ContentTitle>{T.translate('Plans')}</ContentTitle>
      <ContentRowWrapper>
        <ContentColumnWrapper width={270}>
          {/* ======== buildings dropdown ======== */}
          <ReportTitleDropdown
            options={plansData.buildings.map((item) => item.name)}
            handleChange={(key) => updateCurrentPlanIndex(key, setCurrentPlanIndex)}
            selectedIndex={currentPlanIndex}
          />

          {/* ======== current building image ======== */}
          {lodashGet(plansData, `buildingsImages[${currentPlanIndex}]`) && <ContentImage src={plansData.buildingsImages[currentPlanIndex].url} width={208} height={208} marginBottom={10} />}

          {/* ======== legend ======== */}
          <ContentColumnWrapper hasColor>
            {unitsColors.map((item, index) => (
              <LegendWrapper key={index}>
                {item.colors.map((color) => <LegendColor color={color} key={color} />)}
                <StyledText middle>{item.label}</StyledText>
              </LegendWrapper>
            ))}
          </ContentColumnWrapper>
        </ContentColumnWrapper>

        {/* ======== images ======== */}
        <ContentWrapper>
          <ContentImagesWrapper width={400}>
            {lodashGet(currentPlanData, 'images', []).map((image, index) => (
              <ContentImagesWithLabelWrapper key={index}>
                <ContentImage src={image.url} width={400} />
                {lodashGet(image, 'label') && <ContentImageLabel>{image.label}</ContentImageLabel>}
              </ContentImagesWithLabelWrapper>
            ))}
          </ContentImagesWrapper>
        </ContentWrapper>
      </ContentRowWrapper>
    </VerticalTitleContentWrapper>
  );
};

export default React.memo(Plans);
