import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const InfoBoxWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.20);
  border-radius: ${({ theme }) => theme.other.borderRadius};
  background-color: ${({ theme, isTotal }) => (isTotal ? theme.colors.primaryColor : 'unset')};
  color: ${({ theme, isTotal }) => (isTotal ? theme.colors.white : 'unset')};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '60px')};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '')};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : '')};
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}px` : '')};
`;

const Row = styled.div`
  display: ${({ isOnlyLeft }) => (isOnlyLeft ? 'block' : 'flex')};
  justify-content: space-between;
`;

const Title = styled.div`
  // font-size: ${({ theme }) => theme.font.size.small};
  // font-weight: ${({ theme }) => theme.font.weight.bold};
`;

const Info = styled.div`
  font-size: ${({ theme }) => theme.font.size.large};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

const ColorPill = styled.div`
  height: 15px;
  width: 5px;
  margin-top: 4px;
  border-radius: 5px;
  background-color: ${({ color }) => color};
`;

const InfoBox = (props) => {
  const { data, isTotal, marginTop, marginBottom, marginLeft, marginRight, height, width } = props;
  const { title, color, leftInfo = '', rightInfo } = data;

  return (
    <InfoBoxWrapper
      height={height}
      width={width}
      isTotal={isTotal}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
    >
      <Row>
        <Title>{title}</Title>
        {color && <ColorPill color={color} />}
      </Row>
      <Row isOnlyLeft={!rightInfo}>
        <Info>{leftInfo}</Info>
        {rightInfo && <Info>{rightInfo}</Info>}
      </Row>
    </InfoBoxWrapper>
  );
};

InfoBox.propTypes = {
  data: PropTypes.object,
  isTotal: PropTypes.bool,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default InfoBox;
