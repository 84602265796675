import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import { Text } from '@react-three/drei';
import { useDispatch } from 'react-redux';
import PolyLines from './PolyLines';
import TexturedPlane from '../TexturedPlane';
// eslint-disable-next-line import/no-cycle
import EdgeDragger from './EdgeDragger';
import { WARNING_COLOR, MINIMUM_HANDLE_LENGTH, MINIMUM_MIDPOINTS_LENGTH, MINIMUM_TEXT_LENGTH } from './constants';
import { isWarningLengthByIndex, getColorBuyLengthAndId } from './sketchHelpers';
import { vecAngle, vecMoveTowards, vecAdd, vecScale } from '../../../algorithms/algorithmHelpers';
import { metersToFitAndInches } from '../../../helpers/unitsHelpers';
import images from '../../../../styles/static/img/feasibility';
import * as SketchToolReducer from '../../../../store/sketchToolStore';

// A component that renders the stuff that exist between every two points:
// The Text showing the length, the mid-point for creating new points, the edge dragers
// And the warning lines and points in case it is needed (when lines are too short)
const ItemsBetweenTwoPoints = ({
  points,
  isPolygon,
  isElementMoving,
  movingVector,
  middlePoints,
  isClosed,
  isImperial,
  onEdgeDrag,
  onEdgeDragFinished,
  onEdgeDraggerDown,
  onMidPointClicked,
  updateHistory,
}) => {
  const dispatch = useDispatch();
  return (
    <group position={[0, 0, 5]} key="middlePoints-group">
      {middlePoints.map((obj, id) => {
        const { length, point, normal, textPoint, prevPoint, nextPoint } = obj;
        const showHandles = length > MINIMUM_HANDLE_LENGTH && (!isElementMoving || movingVector === id);
        const showMidPoint = length > MINIMUM_MIDPOINTS_LENGTH && showHandles;
        const angle = vecAngle(normal);
        const textPosition = vecMoveTowards(textPoint, vecAdd(textPoint, normal), 2.5);
        let textAngle;
        let anchorX;
        if (angle < 0) {
          textAngle = angle + Math.PI / 2;
          anchorX = 'right';
        } else {
          textAngle = angle - Math.PI / 2;
          anchorX = 'left';
        }

        return (
          <Fragment key={`f${id}`}>
            {isWarningLengthByIndex(length, id, isClosed, points) && !isPolygon && (
            <PolyLines
              points={[prevPoint, nextPoint]}
              color={WARNING_COLOR}
              width={1.0}
              height={-1}
            />
            )}
            <TexturedPlane
              renderOrder={50}
              minFilter={THREE.NearestFilter}
              key={`c${id}`}
              onPointerDown={showMidPoint && ((e) => {
                e.stopPropagation();
                onMidPointClicked(e, id, point);
              })}
              image={images.middlePoint}
              size={3.5}
              position={point}
              rotation={[0, 0, angle]}
              visible={showMidPoint}
              onPointerOver={(e) => {
                e.stopPropagation();
                dispatch(SketchToolReducer.setPointInProgress(null));
              }}
            />
            );
            {!isPolygon && (
            <Text
              position={[...textPosition, 7]}
              rotation={[0, 0, textAngle]}
              fontSize={3}
              letterSpacing={0.05}
              color={getColorBuyLengthAndId(length, id, isClosed, points)}
              anchorX={anchorX}
              anchorY="middle"
              font="Roboto"
              outlineWidth={0.1}
              outlineColor={getColorBuyLengthAndId(length, id, isClosed, points)}
              visible={length >= MINIMUM_TEXT_LENGTH}
            >
              {isImperial ? metersToFitAndInches(length) : length.toFixed(1) }
            </Text>
            )}
            <EdgeDragger
              key="vr"
              uuid={id}
              normal={normal}
              middlePoint={point}
              onPointMoved={onEdgeDrag}
              onDragFinish={onEdgeDragFinished}
              onPointerDown={onEdgeDraggerDown}
              updateHistory={updateHistory}
              visible={showHandles}
              isPolygon={isPolygon}
              points={points}
            />
            <EdgeDragger
              key="vl"
              uuid={id}
              normal={vecScale(normal, -1)}
              middlePoint={point}
              onPointMoved={onEdgeDrag}
              onDragFinish={onEdgeDragFinished}
              onPointerDown={onEdgeDraggerDown}
              updateHistory={updateHistory}
              visible={showHandles}
              isPolygon={isPolygon}
              points={points}
            />
          </Fragment>
        );
      })}
    </group>
  );
};

ItemsBetweenTwoPoints.propTypes = {
  isPolygon: PropTypes.bool,
  isElementMoving: PropTypes.bool,
  movingVector: PropTypes.number,
  isClosed: PropTypes.bool,
  isImperial: PropTypes.bool,
  points: PropTypes.array,
  middlePoints: PropTypes.array,
  onEdgeDrag: PropTypes.func,
  onEdgeDragFinished: PropTypes.func,
  onEdgeDraggerDown: PropTypes.func,
  onMidPointClicked: PropTypes.func,
  updateHistory: PropTypes.func,
};

export default React.memo(ItemsBetweenTwoPoints);
