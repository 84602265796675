/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useDrag } from 'react-use-gesture';
import * as THREE from 'three';
import { useThree } from 'react-three-fiber';
import lodashIsEqual from 'lodash/isEqual';
import { vecAngle, vecClone, vecAdd, vecScale, vecNormalize, vecSub, vecDot, vecIntersection, projectPointToLine, vecDistance } from 'utils/algorithms/algorithmHelpers';
import { notifyDragFinished } from '../../helpers/ThreeHelpers';
import { changeCursor, changeToResizeCursorByAngle } from '../../helpers/SelectionHelpers';
import images from '../../../../styles/static/img/feasibility';
import { ParkingEntrance } from '.';
import TexturedPlane from '../TexturedPlane';
import * as SketchToolReducer from '../../../../store/sketchToolStore';
import { projectPointToPolygon } from './sketchHelpers';
import { NEW_EDGE_DOT_FACTOR, POINTS_CLOSING_DIST } from './constants';

const ARROW_DIST = 4;
const PLANE_SIZE = 4;

const EdgeDragger = (props) => {
  const { uuid, normal, middlePoint, visible, isPolygon, points, updateHistory } = props;
  const dispatch = useDispatch();
  const store = useStore();
  const { parkingEntrancePosition, isClosed } = store.getState().sketchTool;
  const setParkingEntrancePosition = (p) => dispatch(SketchToolReducer.setParkingEntrancePosition(p));
  const setMovingVector = (p) => dispatch(SketchToolReducer.setMovingVector(p));
  const setPoints = (p) => dispatch(SketchToolReducer.setPoints(p));
  const tmpPoints = useRef(null);

  const point = vecAdd(middlePoint, vecScale(normal, ARROW_DIST));
  const angle = vecAngle(normal);

  const position = [...point, 0];
  const mesh = useRef();
  const { camera } = useThree();
  const origPoint = useRef();
  const currentPoint = useRef();

  const onEdgeDragFinished = () => {
    const newPoints = [...points];
    // updateUndoList();
    updateHistory(newPoints);
    setMovingVector(undefined);

    if (parkingEntrancePosition && isPolygon) {
      setParkingEntrancePosition(projectPointToPolygon(parkingEntrancePosition?.position, newPoints, ParkingEntrance.PLANE_SIZE));
    }
    tmpPoints.current = null;
  };

  const onEdgeDraggerDown = (event, id) => {
    const prevPoint = points[id];
    const prevprevPoint = (isClosed && id === 0) ? points[points.length - 1] : points[id - 1];

    const nextPoint = isClosed ? points[(id + 1) % points.length] : points[(id + 1)];
    const nextnextPoint = isClosed ? points[(id + 2) % points.length] : points[(id + 2)];

    if (!prevPoint || !nextPoint) {
      return;
    }

    const lineVector = vecNormalize(vecSub(prevPoint, nextPoint));

    const prevVector = prevprevPoint && vecNormalize(vecSub(prevprevPoint, prevPoint));
    const nextVector = nextnextPoint && vecNormalize(vecSub(nextPoint, nextnextPoint));
    const dotPrev = prevVector && vecDot(prevVector, lineVector);
    const dotNext = nextVector && vecDot(nextVector, lineVector);
    setMovingVector(id);

    const newPoints = [...points];

    if (dotNext > NEW_EDGE_DOT_FACTOR) {
      newPoints.splice(id + 2, 0, vecClone(nextPoint));
    }

    if (dotPrev > NEW_EDGE_DOT_FACTOR) {
      newPoints.splice(id, 0, vecClone(prevPoint));
      setMovingVector(id + 1);
    }

    tmpPoints.current = newPoints;
  };

  const onEdgeDrag = (id, newPoint) => {
    let idToUse = id;
    const newPoints = [...tmpPoints.current];

    // If two points are almst identical, it is beacuse we created a new edge when using the vector handle.
    // It means we added the identical point after the one that was originally had this id, and in order to
    // get the expected behaivoiur, we should do it for the point that is after this id.
    const distToNextPoint = vecDistance(newPoints[id], newPoints[(id + 1) % newPoints.length]);
    if (distToNextPoint < POINTS_CLOSING_DIST) {
      idToUse++;
    }

    const prevPoint = newPoints[idToUse];
    const prevprevPoint = (isClosed && idToUse === 0) ? newPoints[newPoints.length - 1] : newPoints[idToUse - 1];

    const nextPoint = isClosed ? newPoints[(idToUse + 1) % newPoints.length] : newPoints[(idToUse + 1)];
    const nextnextPoint = isClosed ? newPoints[(idToUse + 2) % newPoints.length] : newPoints[(idToUse + 2)];

    const lineVector = vecNormalize(vecSub(prevPoint, nextPoint));
    const perpendicular = [-lineVector[1], lineVector[0]];

    const pointOnLine = projectPointToLine(newPoint, prevPoint, nextPoint, false);
    const origPointOnLine = projectPointToLine(origPoint.current, prevPoint, nextPoint, false);

    const newDelta = vecDistance(pointOnLine.point, newPoint);
    const origDelta = vecDistance(origPointOnLine.point, origPoint.current);
    const diffNewDelta = vecSub(pointOnLine.point, newPoint);
    const dot = -vecDot(diffNewDelta, perpendicular);
    const dist = (newDelta - origDelta) * Math.sign(dot);

    if (!dist) {
      return;
    }

    const movementVector = vecScale(perpendicular, dist);
    const newPrev = vecAdd(prevPoint, movementVector);
    const newNext = vecAdd(nextPoint, movementVector);
    const prevIntersection = prevprevPoint ? vecIntersection(newPrev, newNext, prevPoint, prevprevPoint) : newPrev;
    const nextIntersection = nextnextPoint ? vecIntersection(newPrev, newNext, nextPoint, nextnextPoint) : newNext;

    // If there is no prevIntersection / nextIntersection it is usually because the points are identical (creating a new edge)
    // In those cases we would like the point to change as it was at the end of the polyline (When there is no prevprevPoint / nextnextPoint)
    newPoints[idToUse] = prevIntersection || newPrev;

    if (isClosed) {
      newPoints[(idToUse + 1) % newPoints.length] = nextIntersection || newNext;
    } else {
      newPoints[idToUse + 1] = nextIntersection || newNext;
    }
    setPoints(newPoints);
  };

  const bindDrag = useDrag(
    ({ delta: [mx, my], event }) => {
      if (event) event.stopPropagation();
      const object = mesh.current || event.object;
      if (object) {
        if (!origPoint.current) {
          origPoint.current = vecClone(middlePoint);
          currentPoint.current = vecClone(middlePoint);
        }
        if (event.type === 'pointerdown') {
          if (event.button === 0) {
            onEdgeDraggerDown(event, uuid);
            return;
          }
        }
        if (event.buttons === 1) {
          const deltaVector = new THREE.Vector3(mx / camera.zoom, -my / camera.zoom, 0);
          mx = deltaVector.x;
          my = deltaVector.y;
          currentPoint.current[0] += mx;
          currentPoint.current[1] += my;
          onEdgeDrag(uuid, currentPoint.current, origPoint.current, [mx, my]);
        }
        if (event.buttons === 0) {
          origPoint.current = undefined;
          currentPoint.current = undefined;
          notifyDragFinished();
          onEdgeDragFinished(uuid);
        }
      }
    },
    { pointerEvents: true },
  );

  return (
    <TexturedPlane
      {...props}
      ref={mesh}
      {...bindDrag()}
      onPointerOver={(e) => {
        if (!visible) {
          return;
        }
        e.stopPropagation();
        changeToResizeCursorByAngle(angle);
        // changeCursor('all-scroll');
        dispatch(SketchToolReducer.setPointInProgress(null));
      }}
      onPointerOut={() => changeCursor('default')}
      size={PLANE_SIZE}
      position={position}
      rotation={[0, 0, angle - Math.PI / 2]}
      visible={visible}
      image={images.arrow}
      minFilter={THREE.NearestFilter}
    />
  );
};

const areEqual = ({ middlePoint: prevMiddlePoint, ...next }, { middlePoint: nextMiddlePoint, ...prev }) => {
  // For most of the props here a regular shalow check is enough, but a specific check is needed for those two other props.
  const isEqual = lodashIsEqual(next, prev);
  const isMidPointEq = prevMiddlePoint.x === nextMiddlePoint.x && prevMiddlePoint.y === nextMiddlePoint.y;

  return isEqual && isMidPointEq;
};

EdgeDragger.propTypes = {
  uuid: PropTypes.number,
  color: PropTypes.string,
  point: PropTypes.array,
  normal: PropTypes.any,
  middlePoint: PropTypes.array,
  visible: PropTypes.bool,
  isPolygon: PropTypes.bool,
  points: PropTypes.array,
  updateHistory: PropTypes.func,
};

export default React.memo(EdgeDragger, areEqual);
