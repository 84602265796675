import React from 'react';
import PropTypes from 'prop-types';
import lodashIncludes from 'lodash/includes';
import doorPolygon from '../../polygons/doorPolygon';
import MeshLine from '../../components/MeshLine';
import { transformPolygonToBox2 } from '../../../algorithms/algorithmHelpers';
import ExtrudeMesh from '../../components/ExtrudeMesh';

const Door = (props) => {
  const { door, lineThickness = 0.05, lineColor = 'black' } = props;
  const dashedLinesIndex = [0];

  return (
    <group position={[0, 0, 1]} name="door">
      {doorPolygon.map((line, lineIndex) => (
        <group key={lineIndex}>
          <MeshLine
            isDashed={lodashIncludes(dashedLinesIndex, lineIndex)}
            vertices={transformPolygonToBox2(line, door.boundingBox)}
            lineWidth={lodashIncludes(dashedLinesIndex, lineIndex) ? lineThickness / 2 : lineThickness}
            dashProperties={lineIndex === 0 ? { dashArray: 0.16, dashRatio: 0.3, dashOffset: 0.3 } : {}}
            color={lineColor}
          />
          {lineIndex === 4 && (
          <ExtrudeMesh
            extrudeDepth={1}
            envelope={transformPolygonToBox2(line, door.boundingBox)}
            unlit
            color="#ffffff"
          />
          )}
        </group>
      ))}
    </group>
  );
};

Door.propTypes = {
  door: PropTypes.object,
  lineThickness: PropTypes.number,
  lineColor: PropTypes.string,
};

export default React.memo(Door);
