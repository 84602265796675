import React from 'react';

// currentcolor
export default () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.75 7.25H20.25V11.2094H21V6.5H1V11.2094H1.75V7.25Z" fill="currentcolor" />
    <path d="M20.25 20.4594L1.75 20.4593L1.75 16.5L1 16.5L1 21.2093L21 21.2094L21 16.5L20.25 16.5L20.25 20.4594Z" fill="currentcolor" />
    <rect x="1.375" y="2.375" width="19.25" height="4.53" stroke="currentcolor" strokeWidth="0.75" />
    <rect x="1.375" y="11.475" width="19.25" height="4.85" stroke="currentcolor" strokeWidth="0.75" strokeDasharray="1.5 2" />
  </svg>
);
