import React from 'react';
import T from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { message, Modal } from 'antd';
import Icon from '@ant-design/icons';
import icons from 'styles/static/icons/testFit/editor';
import { setNewMarkupsData } from 'utils/model/projectProfileData';
import { PROJECT_STAGES } from 'constants/profileConsts';
import { isSharedUrl, parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { updateProjectProfileAction, createNewProfileAction } from 'store/swappProfile/actions/swappProfileActions';
import { PrimaryButton } from 'styles/commonComponents.styles';
import { useOverridableSetting } from 'store/userSettings';
import lodashIsEmpty from 'lodash/isEmpty';

const { confirm } = Modal;

const RevisionIcon = styled(Icon)`
  color: #5772ff !important;
`;

const EditMarkupsButton = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const markups = useSelector((state) => state?.markups?.markups);
  const locationData = parseLocationUrl(window.location);
  const isUserReadOnly = useOverridableSetting('readOnly', false) || isSharedUrl();

  const showRevisionModal = () => {
    confirm({
      title: T.translate('MARKUPS_CONTAINER_REVISION_MODAL_TITLE'),
      icon: <RevisionIcon component={icons.revisionIcon} />,
      okText: T.translate('MARKUPS_CONTAINER_REVISION_MODAL_OK_TEXT'),
      cancelText: T.translate('MARKUPS_CONTAINER_REVISION_MODAL_CANCEL_TEXT'),
      content: T.translate('MARKUPS_CONTAINER_REVISION_MODAL_CONTENT'),
      width: 500,
      onOk() {
        dispatch(updateProjectProfileAction(locationData.profileId, setNewMarkupsData(markups)))
          .then(() => {
            dispatch(createNewProfileAction({ projectId: locationData.projectId, profileId: locationData.profileId, history, stage: PROJECT_STAGES[locationData.stage], isChildProfile: true }))
              .catch((error) => message.error(error));
          });
      },
    });
  };

  return (
    <>
      <PrimaryButton marginLeft={10} disabled={isUserReadOnly || lodashIsEmpty(markups)} onClick={showRevisionModal} width={120}>
        {T.translate('MARKUPS_CONTAINER_SEND_BUTTON')}
      </PrimaryButton>
    </>

  );
};

export default EditMarkupsButton;
