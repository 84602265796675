import affordableHousing from './affordableHousing.jpeg';
import coLiving from './coLiving.jpg';
import dormitories from './dormitories.jpg';
import elderlyHome from './elderlyHome.jpg';
import multifamily from './multifamily.jpg';
import other from './other.jpg';
import smallApartments from './smallApartments.jpg';
import tight from './tight.png';
import comfort from './comfort.png';
import spacious from './spacious.png';
import arrow from './arrow.png';
import middlePoint from './middlePoint.png';
import pointer from './pointer.png';
import parkingEntrance from './parkingEntrance.png';
import northSvg from './north.svg';

export default {
  affordableHousing,
  coLiving,
  dormitories,
  elderlyHome,
  multifamily,
  other,
  smallApartments,
  tight,
  comfort,
  spacious,
  arrow,
  middlePoint,
  pointer,
  parkingEntrance,
  northSvg,
};
