import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Tooltip, Empty } from 'antd';
import Icon from '@ant-design/icons';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import { feasibilityResultCardModel } from 'utils/model/feasibilityResultModel';
import { CARD_INFO_TYPE } from 'constants/feasibilityConts';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { getAllFinancialData } from 'utils/helpers/feasibilityFinancialHelper';
import { getProfileByIdSelector } from 'store/swappProfile';
import {
  FeasibilityResultCardWrapper,
  DataCellWrapper,
  DataCell,
  DataCellItem,
  Splitter,
  EmptyCardWrapper,
  StyledProgress, InfoWrapper, DataRow, DataRowItem, InfoTotalWrapper,
} from './FeasibilityResultCard.styles';
import icons from '../../../styles/static/icons/feasibilityIcons';
import CurrencyInput from '../../common/CurrencyInput';

const FeasibilityResultCard = (props) => {
  const { profile, infoType } = props;
  const match = useRouteMatch();
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));
  const data = useMemo(() => feasibilityResultCardModel(profile.result, isImperial), [profile.result, isImperial]);
  const swappProfile = useSelector((state) => getProfileByIdSelector(state, profile.id));
  const isEmptyCard = !data || data.isEmptyLot;

  const renderBuildingInfo = (item) => {
    if (item.type === 'percentage' && item.value != null) {
      return (
        <StyledProgress
          type="circle"
          key={item.key}
          percent={Number(item.value.split('%')[0])}
          width={55}
          progressWidth={80}
          strokeWidth={10}
          format={(percent) => (
            <>
              <div style={{ fontSize: '8px' }}>{item.name}</div>
              <div>{`${Number(percent).toFixed(1)}%`}</div>
            </>
          )}
        />
      );
    }

    return (
      <DataCell key={item.key}>
        <DataCellItem bold>{item.name}</DataCellItem>
        <DataCellItem>{item.value}</DataCellItem>
      </DataCell>
    );
  };

  const renderUnits = (unit, isTotal) => (
    <DataRow key={unit.key}>
      <DataRowItem width={100} bold={isTotal}>{unit.name}</DataRowItem>
      <Tooltip title={`${T.translate('FEASIBILITY_RESULT_DATA_APT_MIX')} ${unit.percentageOfAllUnits}`} placement="topRight">
        <DataRowItem width={75} bold>{unit.unitsOf}</DataRowItem>
      </Tooltip>
      <DataRowItem width={45}>
        <StyledProgress progressWidth={45} type="line" percent={Number(`${unit.percentage ? unit.percentage : 100}`.split('%')[0])} showInfo={false} />
      </DataRowItem>
      <Tooltip title={T.translate('FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP')}>
        <DataRowItem width={50} align="right">{unit.percentage ? unit.percentage : '100%'}</DataRowItem>
      </Tooltip>
    </DataRow>
  );

  const renderFinancialInfo = () => {
    const financialData = getAllFinancialData(lodashGet(data, 'formData'), swappProfile);

    const totalCost = financialData.expenditure.find((item) => item.key === 'TOTAL').TOTAL_COST;
    const totalSaleRevenue = financialData.income.find((item) => item.key === 'SALE').TOTAL_COST;
    const totalRentRevenue = financialData.income.find((item) => item.key === 'RENT').TOTAL_COST * 12;
    const timeToProfit = financialData.income.find((item) => item.key === 'TOTAL').TOTAL_COST;

    const financialArray = [
      { key: 'TOTAL_COST', name: T.translate('FEASIBILITY_TARGET_FORM_CARD_FINANCIAL_TOTAL_COST'), value: <CurrencyInput value={totalCost} readOnly /> },
      { key: 'TOTAL_SALE_REVENUE', name: T.translate('FEASIBILITY_TARGET_FORM_CARD_FINANCIAL_TOTAL_SALE_REVENUE'), value: <CurrencyInput value={totalSaleRevenue} readOnly /> },
      { key: 'TOTAL_RENT_REVENUE', name: T.translate('FEASIBILITY_TARGET_FORM_CARD_FINANCIAL_TOTAL_RENT_REVENUE'), value: <CurrencyInput value={totalRentRevenue} readOnly /> },
      { key: 'TIME_TO_PROFIT', bold: true, name: T.translate('FEASIBILITY_TARGET_FORM_CARD_FINANCIAL_TIME_TO_PROFIT'), value: timeToProfit },
    ];

    return financialArray.map((item) => (
      <DataRow key={item.key} marginBottom={12}>
        <DataCellItem bold>{item.name}</DataCellItem>
        <DataCellItem bold={item.key === 'TIME_TO_PROFIT'}>{item.value}</DataCellItem>
      </DataRow>
    ));
  };

  const renderHVACDesignInfo = () => {
    const hvacData = lodashGet(data, 'hvacData');

    return (
      <DataCellWrapper>
        {lodashIsEmpty(hvacData)
          ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={T.translate('FEASIBILITY_TARGET_FORM_CARD_EMPTY_HVAC')} />
          : hvacData.map((item) => renderBuildingInfo(item))}
      </DataCellWrapper>
    );
  };

  const renderInfo = () => {
    switch (infoType) {
      case CARD_INFO_TYPE.APARTMENT_MIXTURE: {
        return (
          <>
            <InfoWrapper>
              {data.unitsInfo.map((item) => renderUnits(item))}
            </InfoWrapper>
            <InfoTotalWrapper>{data.totalUnitsInfo.map((item) => renderUnits(item, true))}</InfoTotalWrapper>
          </>
        );
      }
      case CARD_INFO_TYPE.FINANCIAL: {
        return <InfoWrapper>{renderFinancialInfo()}</InfoWrapper>;
      }
      case CARD_INFO_TYPE.HVAC: {
        return renderHVACDesignInfo();
      }
      default: {
        return null;
      }
    }
  };

  if (isEmptyCard) {
    return (
      <EmptyCardWrapper>
        <div>{T.translate('FEASIBILITY_TARGET_FORM_CARD_EMPTY_LOT')}</div>
        <Icon component={icons.emptyLotCardIcon} />
      </EmptyCardWrapper>
    );
  }

  return (
    <FeasibilityResultCardWrapper>
      <DataCellWrapper>
        {data.buildingInfoA.map((item) => renderBuildingInfo(item))}
      </DataCellWrapper>
      <DataCellWrapper>
        {data.buildingInfoB.map((item) => renderBuildingInfo(item))}
      </DataCellWrapper>
      <Splitter />
      {renderInfo()}
    </FeasibilityResultCardWrapper>
  );
};

FeasibilityResultCard.propTypes = {
  profile: PropTypes.object,
  infoType: PropTypes.string,
};

export default FeasibilityResultCard;
