import React from 'react';
import T from 'i18n-react';
import { SnippetsOutlined } from '@ant-design/icons';
import Notification from '../../common/Notification';

const WaitingGeneration = () => (
  <div style={{ margin: '230px' }}>
    <Notification iconComponent={<SnippetsOutlined />} hints={T.translate('LAYOUTS_MISSING_HINTS')} notification={T.translate('LAYOUTS_MISSING_NOTIFICATION')} />
  </div>
);

export default WaitingGeneration;
