import React from 'react';
import T from 'i18n-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import lodashGet from 'lodash/get';
import { shallowEqual, useSelector } from 'react-redux';
import { DISPLAY_TYPES } from 'constants/testFitConsts';
import { SecondaryButton } from 'styles/commonComponents.styles';
import { getProfileByIdSelector, swappProfileResultsSelector, swappProjectSelector } from 'store/swappProfile';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { downloadFileWithText } from 'utils/helpers/tableToCsvConverter';
import { extractTestFitResultsCSV } from 'utils/swappViewer/testFit/TestFitCsv';
import ExploreDataContainer from 'components/testFit/study/result/program/ProfileDataTablesContainer';
import ProfileBathroomsDataPanel from 'components/testFit/study/result/program/ProfileBathroomsDataPanel';
import Icon from '@ant-design/icons';
import downloadCsvIcon from 'styles/static/icons/comon/downloadCsvIcon';

const TablesWrapper = styled.div`
  display: none;
  
  // for QA:
  //display: block;
  //position: fixed;
  //top: 0;
  //left: 0;
  //z-index: 80;
  //background-color: white;
  //overflow-y: scroll;
  //height: 900px;
`;

const OldForgeButtonWrapper = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.gray_01};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  height: 50px;
  width: 50px;
  padding: 9px 9px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.12);
  margin-right: 10px;
  position: relative;

  svg {
    font-size: 25px;
    color: ${({ theme, isPdfGenerating }) => (isPdfGenerating ? theme.colors.primaryColor : theme.colors.gray_09)};
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.primaryColor};
    }
  }
`;

const downloadResultCsv = async (name) => {
  const csvString = await extractTestFitResultsCSV();
  downloadFileWithText(`${name}.csv`, csvString);
};

const DownloadCsv = (props) => {
  const { isForgeIcon } = props;

  const locationData = parseLocationUrl(window.location);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const swappProfileResults = useSelector((state) => swappProfileResultsSelector(state, locationData.profileId, isImperial), shallowEqual);
  const swappProject = useSelector(swappProjectSelector);
  const swappProfile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const name = `${swappProject?.name} - ${swappProfile?.name}`;

  return (
    <>
      <TablesWrapper>
        <div className="ProfileDataPanel">
          <div>{T.translate('OVERALL_CONTAINER_RSF')}</div>
          <ExploreDataContainer type="RSF" data={lodashGet(swappProfileResults, 'RSF')} />
        </div>
        <div className="ProfileDataPanel">
          <div>{T.translate('OVERALL_CONTAINER_LSF')}</div>
          <ExploreDataContainer displayTypeProp={DISPLAY_TYPES.LIST} type="LSF" data={lodashGet(swappProfileResults, 'LSF')} />
        </div>
        <div className="ProfileDataPanel">
          <div>{T.translate('OVERALL_CONTAINER_PSF')}</div>
          <ExploreDataContainer displayTypeProp={DISPLAY_TYPES.LIST} type="PSF" data={lodashGet(swappProfileResults, 'PSF_A')} />
          <ExploreDataContainer displayTypeProp={DISPLAY_TYPES.LIST} type="PSF" hideHeader data={lodashGet(swappProfileResults, 'PSF_B')} />
        </div>
        <div className="ProfileDataPanel">
          <div>{T.translate('OVERALL_CONTAINER_ASF')}</div>
          <ExploreDataContainer displayTypeProp={DISPLAY_TYPES.LIST} type="ASF" data={lodashGet(swappProfileResults, 'ASF')} />
        </div>
        <div className="ProfileDataPanel">
          <div>{T.translate('OVERALL_CONTAINER_FSF')}</div>
          <ExploreDataContainer displayTypeProp={DISPLAY_TYPES.LIST} type="FSF" data={lodashGet(swappProfileResults, 'FSF')} />
        </div>
        <div className="ProfileDataPanel">
          <div>{T.translate('OVERALL_CONTAINER_BATHROOMS')}</div>
          <ProfileBathroomsDataPanel data={lodashGet(swappProfileResults, 'bathrooms')} />
        </div>
        <div className="ProfileDataPanel">
          <div>{T.translate('OVERALL_CONTAINER_ROOMS')}</div>
          <ExploreDataContainer displayTypeProp={DISPLAY_TYPES.LIST} type="ROOMS" data={{ tableData: lodashGet(swappProfileResults, 'multiBoundaryFile.roomsDefinitions', []) }} />
        </div>
      </TablesWrapper>

      {isForgeIcon
        ? (
          <OldForgeButtonWrapper onClick={() => downloadResultCsv(name)}>
            <Icon component={downloadCsvIcon} />
          </OldForgeButtonWrapper>
        )
        : (
          <SecondaryButton onClick={() => downloadResultCsv(name)} width={90} marginRight={10}>
            {T.translate('CSV')}
          </SecondaryButton>
        )}
    </>
  );
};

DownloadCsv.propTypes = {
  isForgeIcon: PropTypes.bool,
};

export default DownloadCsv;
