import React, { useEffect, useMemo } from 'react';
import { Element } from 'react-scroll';
import { DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE, PAGES_TYPES } from 'constants/feasibilityReportConts';
import { ReportPageContentWrapper } from 'components/feasibility/studies/result/report/ReportOnePageContainer.styles';
import { handleOnChange } from 'utils/helpers/feasibilityReportHelpers';
import { Form } from 'antd';
import { isSharedUrl, parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileByIdSelector, loadingSelector } from 'store/swappProfile';
import lodashIsEmpty from 'lodash/isEmpty';
import LoadingSpinner from 'components/common/LoadingSpinner';
import lodashGet from 'lodash/get';
import { APARTMENT_TYPES } from 'constants/feasibilityConts';
import { getPolygonArea } from 'utils/algorithms/algorithmHelpers';
import { mergeObjectListRecursively } from 'utils/model/feasibilityReportModel';
import DevelopmentAppraisal from './DevelopmentAppraisal';
import ConstructionCost from './ConstructionCost';

const FinancialContainer = () => {
  const [form] = Form.useForm();
  const locationData = parseLocationUrl(window.location);
  const isLoading = useSelector(loadingSelector);
  const currentProfile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const dispatch = useDispatch();
  const formData = lodashGet(currentProfile, 'profileData.reportForm', {});
  const onUpdateProps = { currentSelectedProfileId: locationData.profileId, profile: currentProfile, formInstance: form, isLoading, disabled: isSharedUrl() };

  // setFormData
  useEffect(() => {
    if (!isLoading) {
      if (lodashIsEmpty(formData)) {
        form.resetFields();
      } else {
        form.resetFields();
        form.setFieldsValue(formData);
      }
    }
  }, [isLoading, locationData.profileId]);

  if (isLoading || !locationData.profileId) {
    return <LoadingSpinner />;
  }

  const getMassNumFloors = (massDto) => massDto.floor_groups.map((floorGroup) => floorGroup.count).reduce((a, b) => a + b, 0);

  const developmentAppraisalData = useMemo(() => {
    if (!currentProfile) { // delete me after
      return null;
    }

    const { buildingInfo } = currentProfile.result.study;
    const massingOption = currentProfile.result.singleOptionMassingOptions.massing_options[0];
    const baseDataTable = [];
    APARTMENT_TYPES.filter((aptType) => aptType in buildingInfo).forEach((aptType) => {
      const aptInfo = buildingInfo[aptType];

      baseDataTable.push({
        [DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.KEY]: aptType,
        [DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.NUM_OF_UNITS]: aptInfo.model,
        [DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.APARTMENT_AREA]: Math.round(aptInfo.value / aptInfo.model),
        [DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.AREA]: aptInfo.value,
      });
    });
    const total = mergeObjectListRecursively(baseDataTable);
    total[DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.KEY] = 'TOTAL';
    total[DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.APARTMENT_AREA] = '';
    baseDataTable.push(total);

    const lotArea = Math.floor(getPolygonArea(currentProfile.result.sitePolygon.boundary));
    const GIA = Math.floor(lodashGet(buildingInfo, 'GIA.value', 0));
    const BCR = Math.floor(lodashGet(buildingInfo, 'BCR.value', 0));
    const NIA = Math.floor(lodashGet(buildingInfo, 'NIA.value', 0));
    const totalUnits = Math.floor(buildingInfo.TOTAL_APARTMENTS.model);
    const floorCounts = massingOption.masses.map((mass) => getMassNumFloors(mass));
    const numberOfStories = Math.max(...floorCounts);
    const numberOfMasses = massingOption.masses.length;

    return {
      baseDataTable,
      lotArea,
      GIA,
      BCR,
      NIA,
      totalUnits,
      numberOfStories,
      numberOfMasses,
    };
  }, [locationData.profileId, isLoading]);

  return (
    <>
      <Form
        name="FeasibilityFinancialForm"
        form={form}
        style={{ width: '100%' }}
        onFieldsChange={() => handleOnChange(dispatch, onUpdateProps)}
      >
        <ReportPageContentWrapper id="containerElement">
          <Element name={PAGES_TYPES.developmentAppraisal}>
            <DevelopmentAppraisal data={developmentAppraisalData} form={form} formData={formData} />
          </Element>
          <Element name={PAGES_TYPES.constructionCost}>
            <ConstructionCost data={developmentAppraisalData} form={form} formData={formData} />
          </Element>
        </ReportPageContentWrapper>
      </Form>
    </>
  );
};

export default FinancialContainer;
