import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Affix } from 'antd';
import T from 'i18n-react';
import Dropdown from 'components/common/Dropdown';
import { contentRefContexts } from 'utils/hooks/reactContexts';
import lodashGet from 'lodash/get';
import ProfileDataPanel from 'components/testFit/study/result/program/ProfileDataPanel';
import { CompareItemWrapper, Title, ViewerWrapper } from './CompareItem.styles';

const CompareItem = (props) => {
  const { index, selectedProfilesList, profiles, handleClick, viewer } = props;
  const currentProfile = profiles.find((profile) => profile.id === selectedProfilesList[index]);
  const [isTitleSticky, useIsTitleSticky] = useState(false);
  const contentRef = useContext(contentRefContexts);

  return (
    <CompareItemWrapper>
      <Affix offsetTop={0} target={() => contentRef} onChange={(value) => useIsTitleSticky(value)}>
        <Title isSticky={isTitleSticky}>
          <Dropdown
            defaultValue={currentProfile?.id}
            selectedKey={currentProfile?.id}
            placeholderText={T.translate('OVERALL_CONTAINER_MODAL_PLEASE_SELECT')}
            dropDownData={profiles.map((profile) => ({ key: profile.id, value: profile.name }))}
            onChange={(key) => handleClick(index, Number(key))}
            trigger="hover"
            ellipsisWidth={530}
          />
        </Title>
      </Affix>
      <ViewerWrapper>
        {currentProfile && viewer(currentProfile)}
      </ViewerWrapper>
      <div>
        {currentProfile && <ProfileDataPanel profileId={lodashGet(currentProfile, 'id')} />}
      </div>
    </CompareItemWrapper>
  );
};

CompareItem.propTypes = {
  profiles: PropTypes.array,
  selectedProfilesList: PropTypes.array,
  index: PropTypes.number,
  handleClick: PropTypes.func,
  viewer: PropTypes.func,
};

export default React.memo(CompareItem);
