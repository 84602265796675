import React, { useEffect } from 'react';
import {
  useAllMarkups,
  selectMarkup,
  addMarkup,
  useMarkupsConfigTool,
  useMarkupsSubType,
  useSelectedMarkupsIds,
  useIsMarkupDragged,
  setIsDragged,
  useMarkupBeingCreatedId,
  updateMarkupInCreation,
  setMarkupInCreation,
  deleteMarkup,
  clearMarkups, initialize,
} from 'store/markups';
import { Plane } from '@react-three/drei';
import { useDispatch } from 'react-redux';
import { didJustFinishDragging } from 'utils/swappViewer/helpers/ThreeHelpers';
import { changeCursor } from 'utils/swappViewer/helpers/SelectionHelpers';
import { vecDistance } from 'utils/algorithms/algorithmHelpers';
import lodashIsEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { MARKUPS_TOOLS_NAME, SCOPE_BOXES } from 'constants/markupsConts';
import { ONE_MARKUP_LAYER, MARKUPS_LAYER_HEIGHT } from './constants';
import TextMarkup from './TextMarkup';
import ArrowMarkup from './ArrowMarkup';
import CloudMarkup from './CloudMarkup';
import SectionLine from './SectionLine';
import ScopeBoxMarkup from './ScopeBoxMarkup';

const MarkupsDictionaty = {
  [MARKUPS_TOOLS_NAME.CLOUD]: CloudMarkup,
  [MARKUPS_TOOLS_NAME.SCOPE_BOX]: ScopeBoxMarkup,
  [MARKUPS_TOOLS_NAME.ARROW]: ArrowMarkup,
  [MARKUPS_TOOLS_NAME.SECTION]: SectionLine,
  [MARKUPS_TOOLS_NAME.TEXT]: TextMarkup,
};

const MINIMUM_SIZE_NEW_MARKUP = 3;

const DrawingContainer = (props) => {
  const { isViewOnly, profileId, attachmentId, serverData = {}, handleOnChange } = props;
  const dispatch = useDispatch();
  const isDown = React.useRef();
  const startingNewMarkupPoint = React.useRef([0, 0]);

  const markups = useAllMarkups();
  const markupIds = Object.keys(markups);
  const currentSelectedTool = useMarkupsConfigTool();
  const currentSelectedSubType = useMarkupsSubType();
  const selectedMarkups = useSelectedMarkupsIds();
  const isAnyMarkupBeingDragged = useIsMarkupDragged();
  const markupInCreationID = useMarkupBeingCreatedId();

  // Moving the selected markup to be last - meaning on top
  if (selectedMarkups.length) {
    markupIds.splice(markupIds.indexOf(selectedMarkups[0]), 1);
    markupIds.push(selectedMarkups[0]);
  }

  // profile got loaded for the first time
  useEffect(() => {
    if (!lodashIsEmpty(serverData) && lodashIsEmpty(markups)) {
      dispatch(initialize(serverData));
    }
  }, [serverData]);

  // profile change
  useEffect(() => {
    dispatch(initialize(serverData));
  }, [profileId]);

  // markups changed
  useEffect(() => {
    handleOnChange(markups);
  }, [markups]);

  // unmount
  useEffect(() => () => {
    changeCursor('default');
    dispatch(clearMarkups());
  }, []);

  const renderBasePlane = () => (
    <Plane
      args={[1000, 1000]}
      position={[0, 0, -markupIds.length * ONE_MARKUP_LAYER]}
      onPointerMove={(event) => {
        if (isViewOnly) {
          return;
        }
        if (isDown.current) {
          if (markupInCreationID === 'new') {
            changeCursor('crosshair');
            const { point } = event;
            dispatch(addMarkup({
              type: currentSelectedTool,
              point: [point.x, point.y],
              subType: currentSelectedSubType,
              attachmentId,
            }));
          } else if (markupInCreationID) {
            const { point } = event;
            dispatch(updateMarkupInCreation({ id: markupInCreationID, point: [point.x, point.y] }));
          }
        } else {
          changeCursor('crosshair');
        }
      }}
      onPointerDown={(event) => {
        if (isViewOnly) {
          return;
        }
        const { point } = event;
        if (event.button !== 0) {
          return;
        }
        isDown.current = true;
        if (didJustFinishDragging()) {
          return;
        }
        if (selectedMarkups.length) {
          return;
        }
        if (currentSelectedTool !== MARKUPS_TOOLS_NAME.TEXT && currentSelectedTool !== MARKUPS_TOOLS_NAME.SCOPE_BOX) {
          startingNewMarkupPoint.current = [point.x, point.y];
          dispatch(setMarkupInCreation({ id: 'new' }));
        }
      }}
      onPointerUp={(event) => {
        if (isViewOnly) {
          return;
        }
        const { point } = event;
        isDown.current = false;
        if (event.button !== 0) {
          return;
        }
        if (didJustFinishDragging()) {
          return;
        }
        if (isAnyMarkupBeingDragged) {
          dispatch(setIsDragged({ isDragged: false }));
          return;
        }
        if (selectedMarkups.length) {
          dispatch(selectMarkup({ id: null }));
          changeCursor('crosshair');
        } else if (currentSelectedTool === MARKUPS_TOOLS_NAME.SCOPE_BOX) {
          dispatch(addMarkup({
            type: currentSelectedTool,
            point: [point.x, point.y],
            dimensions: SCOPE_BOXES.find((item) => item.id === currentSelectedSubType)?.dimensions,
            subType: currentSelectedSubType,
            attachmentId,
          }));
        } else if (markupInCreationID) {
          const dist = vecDistance(startingNewMarkupPoint.current, [point.x, point.y]);
          if (dist < MINIMUM_SIZE_NEW_MARKUP) {
            dispatch(deleteMarkup({ id: markupInCreationID }));
          }
          changeCursor('crosshair');
        } else if (currentSelectedTool === MARKUPS_TOOLS_NAME.TEXT) {
          dispatch(addMarkup({
            type: currentSelectedTool,
            point: [point.x, point.y],
            subType: currentSelectedSubType,
            attachmentId,
          }));
        } else {
          changeCursor('crosshair');
        }
      }}
    >
      <meshBasicMaterial attach="material" color="red" transparent opacity={0.0} />
    </Plane>
  );

  return (
    <group position={[0, 0, MARKUPS_LAYER_HEIGHT]} name="DrawingContainerGroup">
      {renderBasePlane()}
      {markupIds.map((id, index) => {
        if (markups[id]?.type) {
          const MarkupComponent = MarkupsDictionaty[markups[id].type];
          return (
            <group key={id} name={markups[id].type} position={[0, 0, index * ONE_MARKUP_LAYER]}>
              <MarkupComponent id={id} key={id} markup={markups[id]} isViewOnly={isViewOnly} />
            </group>
          );
        }
      })}
    </group>
  );
};

DrawingContainer.propTypes = {
  isViewOnly: PropTypes.bool,
  profileId: PropTypes.number,
  attachmentId: PropTypes.string,
  serverData: PropTypes.object,
  handleOnChange: PropTypes.func,
};

export default React.memo(DrawingContainer);
