import React from 'react';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ForgeViewer from 'utils/forgeViewer/ForgeViewer';
import EmptyMessage from 'components/common/EmptyMessage';
import { getProfileByIdSelector } from 'store/swappProfile';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { useProfileResult } from 'store/swappProfile/selectors/swappSelectors';
import { MailLink } from 'styles/commonComponents.styles';
import { CONTACT_MEETING } from 'constants/globalConst';

const BuildContainerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const BuildContainer = () => {
  const locationData = parseLocationUrl(window.location);
  const profile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const result = useProfileResult(profile);
  const forgeURN = lodashGet(result, 'threeDURN');

  if (!forgeURN) {
    const description = (
      <div>
        <span>{T.translate('Want to find out more about ‘Build’?')}</span>
        <MailLink href={CONTACT_MEETING} target="_blank" rel="noopener noreferrer">{T.translate('contact our sales ')}</MailLink>
        <span>{T.translate(' department for further information')}</span>
      </div>
    );

    return (
      <div style={{ marginTop: '230px', width: '100%' }}>
        <EmptyMessage description={description} />
      </div>
    );
  }

  return (
    <BuildContainerWrapper>
      {/* ============== viewer ============== */}
      <ForgeViewer
        sheetView
        sliceAmount={result.sliceAmount}
        isExternal={false}
        heightOffset={40}
        isImperial={isImperial}
        projectId={locationData.projectId}
        urn={forgeURN}
        backgroundColor={[242, 245, 250, 242, 245, 250]}
      />
    </BuildContainerWrapper>
  );
};

export default BuildContainer;
