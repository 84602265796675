import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashKeys from 'lodash/keys';
import styled from 'styled-components';
import { message, Modal } from 'antd';
import Icon, { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import lodashValues from 'lodash/values';
import { MARKUPS_TOOLS_ICONS, MARKUPS_WIDTH_ICON, MARKUPS_WIDTH, MARKUPS_COLORS,
  MARKUPS_ATTRIBUTES_NAME, MARKUPS_TOOLS_NAME, MAIN_TOOLS_OLD } from 'constants/markupsConts';
import { PrimaryButton, SecondaryButton } from 'styles/commonComponents.styles';
import { isSharedUrl } from 'utils/helpers/navigationHelpers';
import { PROJECT_STAGES } from 'constants/profileConsts';
import { swappProjectSelector } from 'store/swappProfile';
import { useOverridableSetting } from 'store/userSettings';
import { createNewProfileAction, updateProjectProfileAction } from 'store/swappProfile/actions/swappProfileActions';
import { setMarkupsData } from '../model/projectProfileData';
import icons from '../../styles/static/icons/markups';
import MarkupsMultiButton from './MarkupsMultiButton';
import MarkupsButton from './MarkupsButton';

const { confirm } = Modal;

const MarkupsContainerWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 60px;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

const MarkupsSection = styled.div`
  background-color: ${({ theme }) => theme.colors.gray_01};
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.other.borderRadius};
  padding: 5px;
  margin-bottom: 20px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.12);
`;

const MarkupsButtonsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: calc(50% - ${({ hasUser }) => (hasUser ? '330px' : '215px')});
  height: 60px;
  display: flex;
  padding-bottom: 10px;
  z-index: 2;
`;

const RevisionIcon = styled(Icon)`
  color: #5772ff !important;
`;

const MarkupsContainer = (props) => {
  const { markupsExtension, toggleIsMarkupsOpen, isMarkupsOpen } = props;
  const match = useRouteMatch();
  const history = useHistory();
  const [currentSelectedTool, useCurrentSelectedTool] = useState(MARKUPS_TOOLS_NAME.ARROW);
  const [selectedColor, useSelectedColor] = useState(MARKUPS_COLORS.RED);
  const [selectedWidth, useSelectedWidth] = useState(MARKUPS_WIDTH.NORMAL);
  const [selectedFontSize, useSelectedFontSize] = useState(2);
  const [isTextEnabled, useIsTextEnabled] = useState(false);
  const isUserReadOnly = useOverridableSetting('readOnly', false);
  const swappProject = useSelector(swappProjectSelector);
  const dispatch = useDispatch();

  const stage = match.url.split('/')[1];
  const profileIndex = lodashGet(swappProject, 'projectProfiles', []).findIndex((profile) => profile.id === Number(match.params.profileId));

  useEffect(() => {
    markupsExtension.addEventListener(window.Autodesk.Viewing.Extensions.Markups.Core.EVENT_MARKUP_SELECTED, (e) => updateSelected({
      tool: e.markup.type.toUpperCase(),
      width: e.markup.style['stroke-width'],
      color: e.markup.style['stroke-color'],
      fontSize: e.markup.style['font-size'] === 2 ? selectedFontSize : e.markup.style['font-size'],
    }, useCurrentSelectedTool, useSelectedWidth, useSelectedColor, useIsTextEnabled, useSelectedFontSize));
  }, []);

  useEffect(() => {
    if (isMarkupsOpen) {
      markupsExtension.leaveEditMode();
      const markupsData = lodashValues(lodashGet(swappProject, `projectProfiles[${profileIndex}].markups`, {}));
      markupsExtension.loadMarkups(markupsData[markupsData.length - 1], 'layer_0');
      markupsExtension.enterEditMode('layer_0');
      setStyle();
    }
  }, [isMarkupsOpen]);

  // componentWillUnmount
  useEffect(() => () => {
    markupsExtension.removeEventListener(window.Autodesk.Viewing.Extensions.Markups.Core.EVENT_MARKUP_SELECTED, () => updateSelected());
  }, []);

  const updateSelected = (options, updateTool, updateWidth, updateColor, updateIsTextEnabled, updateFontSize) => {
    updateTool(options.tool);
    updateWidth(options.width || MARKUPS_WIDTH.NORMAL);
    updateColor(options.color);
    updateFontSize(options.fontSize);
    updateIsTextEnabled(options.tool === MARKUPS_TOOLS_NAME.CALLOUT);
  };

  const showExitModal = () => {
    confirm({
      title: T.translate('MARKUPS_CONTAINER_EXIT_MODAL_TITLE'),
      icon: <ExclamationCircleOutlined />,
      okText: T.translate('MARKUPS_CONTAINER_EXIT_MODAL_OK_TEXT'),
      cancelText: T.translate('MARKUPS_CONTAINER_EXIT_MODAL_CANCEL_TEXT'),
      content: T.translate('MARKUPS_CONTAINER_EXIT_MODAL_CONTENT'),
      onOk() {
        dispatch(updateProjectProfileAction(match.params.profileId, setMarkupsData(markupsExtension.generateData())))
          .then(() => {
            markupsExtension.clear();
            markupsExtension.unloadMarkupsAllLayers();
            toggleIsMarkupsOpen();
          });
      },
      onCancel() {
        markupsExtension.clear();
        markupsExtension.unloadMarkupsAllLayers();
        toggleIsMarkupsOpen();
      },
    });
  };

  const showRevisionModal = () => {
    confirm({
      title: T.translate('MARKUPS_CONTAINER_REVISION_MODAL_TITLE'),
      icon: <RevisionIcon component={icons.revisionIcon} />,
      okText: T.translate('MARKUPS_CONTAINER_REVISION_MODAL_OK_TEXT'),
      cancelText: T.translate('MARKUPS_CONTAINER_REVISION_MODAL_CANCEL_TEXT'),
      content: T.translate('MARKUPS_CONTAINER_REVISION_MODAL_CONTENT'),
      width: 500,
      onOk() {
        dispatch(updateProjectProfileAction(match.params.profileId, setMarkupsData(markupsExtension.generateData())))
          .then(() => {
            dispatch(createNewProfileAction({ projectId: match.params.projectId, profileId: match.params.profileId, history, stage: PROJECT_STAGES[stage], isChildProfile: true }))
              .catch((error) => message.error(error));
          });
      },
    });
  };

  const MarkupsToolsList = {
    [MARKUPS_TOOLS_NAME.ARROW]: new window.Autodesk.Extensions.Markup.Core.EditModeArrow(markupsExtension),
    [MARKUPS_TOOLS_NAME.CALLOUT]: new window.Autodesk.Extensions.Markup.Core.EditModeCallout(markupsExtension),
    [MARKUPS_TOOLS_NAME.CIRCLE]: new window.Autodesk.Extensions.Markup.Core.EditModeCircle(markupsExtension),
    [MARKUPS_TOOLS_NAME.CLOUD]: new window.Autodesk.Extensions.Markup.Core.EditModeCloud(markupsExtension),
    [MARKUPS_TOOLS_NAME.FREEHAND]: new window.Autodesk.Extensions.Markup.Core.EditModeFreehand(markupsExtension),
    [MARKUPS_TOOLS_NAME.HIGHLIGHT]: new window.Autodesk.Extensions.Markup.Core.EditModeHighlight(markupsExtension),
    [MARKUPS_TOOLS_NAME.POLYCLOUD]: new window.Autodesk.Extensions.Markup.Core.EditModePolycloud(markupsExtension),
    [MARKUPS_TOOLS_NAME.POLYLINE]: new window.Autodesk.Extensions.Markup.Core.EditModePolyline(markupsExtension),
    [MARKUPS_TOOLS_NAME.RECTANGLE]: new window.Autodesk.Extensions.Markup.Core.EditModeRectangle(markupsExtension),
  };

  const changeAttributes = (attribute, value) => {
    const attributesStateFunction = {
      [MARKUPS_ATTRIBUTES_NAME.COLOR]: useSelectedColor,
      [MARKUPS_ATTRIBUTES_NAME.WIDTH]: useSelectedWidth,
      [MARKUPS_ATTRIBUTES_NAME.FONT_SIZE]: useSelectedFontSize,
    };

    attributesStateFunction[attribute](value);
    setStyle({ [attribute]: value });
  };

  const setStyle = (attributes) => {
    const defaultStyleObject = {
      [MARKUPS_ATTRIBUTES_NAME.COLOR]: selectedColor,
      [MARKUPS_ATTRIBUTES_NAME.WIDTH]: selectedWidth,
      [MARKUPS_ATTRIBUTES_NAME.FONT_SIZE]: selectedFontSize,
      [MARKUPS_ATTRIBUTES_NAME.FONT_FAMILY]: 'Roboto',
      [MARKUPS_ATTRIBUTES_NAME.FONT_WEIGHT]: 600,
    };

    const styleObject = { ...defaultStyleObject, ...attributes };

    markupsExtension.setStyle(styleObject);
  };

  const switchTool = (toolId, updateTool, updateTextButton) => {
    if (updateTool) {
      markupsExtension.changeEditMode(MarkupsToolsList[toolId]);
      updateTool(toolId);
    }
    let isTextButton = false;

    switch (toolId) {
      case MARKUPS_TOOLS_NAME.HIGHLIGHT: {
        const highlightStyle = {
          [MARKUPS_ATTRIBUTES_NAME.COLOR]: MARKUPS_COLORS.YELLOW,
          [MARKUPS_ATTRIBUTES_NAME.WIDTH]: MARKUPS_WIDTH.THICK,
        };
        setStyle(highlightStyle);
        break;
      }
      case MARKUPS_TOOLS_NAME.CALLOUT: {
        const calloutStyle = {
          [MARKUPS_ATTRIBUTES_NAME.WIDTH]: MARKUPS_WIDTH.NORMAL,
        };
        isTextButton = true;
        setStyle(calloutStyle);
        break;
      }
      default:
        isTextButton = false;
        setStyle();
        break;
    }
    if (updateTextButton) {
      updateTextButton(isTextButton);
    }
  };

  const deleteSelectedMarkup = () => {
    markupsExtension.deleteMarkup(markupsExtension.getSelection());
  };

  const duplicateSelectedMarkup = () => {
    markupsExtension.copy();
    markupsExtension.paste();
  };

  const renderToolButton = (list) => list.map((tool) => (
    <MarkupsButton
      key={tool}
      icon={MARKUPS_TOOLS_ICONS[tool]}
      name={T.translate(`MARKUPS_CONTAINER_${tool}`)}
      isSelected={currentSelectedTool === tool}
      handleClick={() => switchTool(tool, useCurrentSelectedTool, useIsTextEnabled)}
    />
  ));

  const renderColorsButton = () => lodashKeys(MARKUPS_COLORS).map((colorKey) => (
    <MarkupsButton
      key={colorKey}
      name={T.translate(`MARKUPS_CONTAINER_COLOR_${colorKey}`)}
      color={MARKUPS_COLORS[colorKey]}
      isSelected={MARKUPS_COLORS[colorKey] === selectedColor}
      handleClick={() => changeAttributes(MARKUPS_ATTRIBUTES_NAME.COLOR, MARKUPS_COLORS[colorKey])}
    />
  ));

  const renderWeightButton = () => {
    if (isTextEnabled) {
      return null;
    }

    const buttons = lodashKeys(MARKUPS_WIDTH).map((widthKey) => (
      <MarkupsButton
        key={widthKey}
        name={T.translate(`MARKUPS_CONTAINER_WIDTH_${widthKey}`)}
        icon={MARKUPS_WIDTH_ICON[MARKUPS_WIDTH[widthKey]]}
        isSelected={MARKUPS_WIDTH[widthKey] === selectedWidth}
        handleClick={() => changeAttributes(MARKUPS_ATTRIBUTES_NAME.WIDTH, MARKUPS_WIDTH[widthKey])}
      />
    ));

    return (
      <MarkupsMultiButton name={T.translate('MARKUPS_CONTAINER_WEIGHT')} icon={MARKUPS_WIDTH_ICON[selectedWidth]}>
        {buttons}
      </MarkupsMultiButton>
    );
  };

  const renderFontSizeButton = () => {
    if (!isTextEnabled) {
      return null;
    }

    return (
      <MarkupsMultiButton name={T.translate('MARKUPS_CONTAINER_FONT_SIZE')} icon={icons.fontSizeIcon}>
        <MarkupsButton
          name={T.translate('MARKUPS_CONTAINER_FONT_SIZE_BIG')}
          icon={icons.fontSizeBigIcon}
          handleClick={() => changeAttributes(MARKUPS_ATTRIBUTES_NAME.FONT_SIZE, (selectedFontSize + 0.7))}
        />
        <MarkupsButton
          name={T.translate('MARKUPS_CONTAINER_FONT_SIZE_SMALL')}
          icon={icons.fontSizeSmallIcon}
          handleClick={() => changeAttributes(MARKUPS_ATTRIBUTES_NAME.FONT_SIZE, (selectedFontSize <= 1.7 ? 1 : selectedFontSize - 0.7))}
        />
      </MarkupsMultiButton>
    );
  };

  if (!isMarkupsOpen) {
    return null;
  }

  return (
    <>
      <MarkupsContainerWrapper>
        <MarkupsSection>
          {/* ========= main tools ========= */}
          {renderToolButton(MAIN_TOOLS_OLD)}

          {/* ========= Shapes ========= */}
          {/* <MarkupsMultiButton */}
          {/*  name={T.translate('MARKUPS_CONTAINER_SHAPES')} */}
          {/*  icon={lodashIncludes(SECONDARY_TOOLS.join(' '), currentSelectedTool) */}
          {/*    ? MARKUPS_TOOLS_ICONS[currentSelectedTool] */}
          {/*    : MARKUPS_TOOLS_ICONS[MARKUPS_TOOLS_NAME.RECTANGLE]} */}
          {/* > */}
          {/*  {renderToolButton(SECONDARY_TOOLS)} */}
          {/* </MarkupsMultiButton> */}
        </MarkupsSection>

        <MarkupsSection>
          {/* ========= colors ========= */}
          <MarkupsMultiButton name={T.translate('MARKUPS_CONTAINER_COLORS')} color={selectedColor}>
            {renderColorsButton()}
          </MarkupsMultiButton>

          {/* ========= weight ========= */}
          {renderWeightButton()}

          {/* ========= font size ========= */}
          {renderFontSizeButton()}

          {/* ========= utils ========= */}
          <MarkupsButton name={T.translate('MARKUPS_CONTAINER_DELETE')} icon={icons.deleteIcon} handleClick={deleteSelectedMarkup} />
          <MarkupsButton name={T.translate('MARKUPS_CONTAINER_DUPLICATE')} icon={icons.duplicateIcon} handleClick={duplicateSelectedMarkup} />
          <MarkupsButton name={T.translate('MARKUPS_CONTAINER_UNDO')} icon={icons.undoIcon} handleClick={() => markupsExtension.undo()} />
        </MarkupsSection>
      </MarkupsContainerWrapper>
      <MarkupsButtonsWrapper hasUser={!isSharedUrl()}>
        {!isSharedUrl() && (
          <PrimaryButton width={240} disabled={isUserReadOnly} onClick={showRevisionModal}>{T.translate('MARKUPS_CONTAINER_REVISION_BUTTON')}</PrimaryButton>
        )}
        <SecondaryButton marginLeft={25} width={240} onClick={showExitModal}>{T.translate('MARKUPS_CONTAINER_EXIT_BUTTON')}</SecondaryButton>
      </MarkupsButtonsWrapper>
    </>
  );
};

MarkupsContainer.propTypes = {
  markupsExtension: PropTypes.object,
  toggleIsMarkupsOpen: PropTypes.func,
  isMarkupsOpen: PropTypes.bool,
};

export default MarkupsContainer;
