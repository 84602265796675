import React, { useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-use-gesture';
import lodashClamp from 'lodash/clamp';
import { useThree, useFrame } from 'react-three-fiber';
import * as THREE from 'three';
import { setIsRoomDragActive } from 'store/testFit';
import { useDispatch } from 'react-redux';
import { notifyDragFinished } from '../../helpers/ThreeHelpers';
import { changeCursor } from '../../helpers/SelectionHelpers';

const DRAG_MARGIN = 50;

const DnDComp = (props) => {
  const { position, onPointMoved, realIndex, children, handleClick, selected, tubeSize, childSize } = props;
  const [dragPos, setDragPos] = useState(null);
  const [destScale, setDestScale] = useState(1);
  const { camera } = useThree();
  const ref = useRef();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (ref.current) {
      [ref.current.position.x, ref.current.position.y] = position;
    }
  }, [ref]);

  const bind = useDrag(
    ({ delta: [mx, my], event, active }) => {
      // if (!selected) {
      //   return;
      // }
      dispatch(setIsRoomDragActive(active));
      if (active) {
        event.stopPropagation();
        setDestScale(1.1);
        changeCursor('grabbing');
        if (!selected) {
          handleClick(event);
        }
      } else {
        changeCursor('grab');
        setDragPos(null);
        setDestScale(1);
        notifyDragFinished();
      }
      if (ref.current) {
        if (event.buttons === 1) {
          const globalQuaternion = new THREE.Quaternion();
          ref.current.getWorldQuaternion(globalQuaternion);
          globalQuaternion.invert();
          const deltaVector = new THREE.Vector3(mx / camera.zoom, -my / camera.zoom, 0);
          deltaVector.applyQuaternion(globalQuaternion);
          const worldScale = ref.current.getWorldScale();
          mx = deltaVector.x / worldScale.x;
          my = deltaVector.y / worldScale.y;
          let newPoint;
          if (dragPos) {
            const { depth, length } = tubeSize;
            const x = lodashClamp(dragPos[0] + mx, -childSize - DRAG_MARGIN, length + DRAG_MARGIN);
            const y = lodashClamp(dragPos[1] + my, position[1] - (depth / 2), position[1] + (depth / 2));
            newPoint = [x, y];
          } else {
            newPoint = [position[0] + mx, position[1] + my];
          }
          setDragPos(newPoint);
          onPointMoved(newPoint, realIndex, [mx, my]);
        }
      }
    },
    { pointerEvents: true },
  );

  useFrame(() => {
    if (ref.current) {
      const curPos = ref.current.position;
      const destPos = dragPos || position;
      ref.current.position.x = curPos.x + (destPos[0] - curPos.x) / 2;
      ref.current.position.y = curPos.y + (destPos[1] - curPos.y) / 2;
      ref.current.position.z = dragPos ? 220 : 200;

      if (Math.abs(ref.current.scale.x - destScale) > 0.000001) {
        const sc = ref.current.scale.x + (destScale - ref.current.scale.x) / 2;
        ref.current.scale.set(sc, sc, sc);
      }
    }
  });

  const onPointerOver = () => {
    changeCursor('grab');
  };

  const onPointerOut = () => {
    changeCursor('default');
  };

  return (
    <group {...bind()} ref={ref} onPointerOver={onPointerOver} onPointerOut={onPointerOut} name="DnDCompGroup">
      {children}
    </group>
  );
};

DnDComp.propTypes = {
  selected: PropTypes.bool,
  handleClick: PropTypes.func,
  position: PropTypes.array,
  height: PropTypes.number,
  width: PropTypes.number,
  realIndex: PropTypes.number,
  color: PropTypes.any,
  onPointMoved: PropTypes.func,
  tubeSize: PropTypes.object,
  childSize: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
};

export default DnDComp;
