let lastDragFinishTime = 0;
// useDrag() causes stopPropagation to stop working (due to pointer capturing), which causes click events to be sent to other 3D components.
// All of our useDrag() implementations should call notifyDragFinished when a meaningful drag finishes, so our sensitive onClick() listeners
// can check didJustFinishDragging() to discard those events
export const notifyDragFinished = () => { lastDragFinishTime = performance.now(); };
export const didJustFinishDragging = () => (performance.now() - lastDragFinishTime) < 100;

export const findAncestor = (node, predicate) => {
  while (node) {
    if (predicate(node)) {
      return node;
    }
    node = node.parent;
  }
  return null;
};

// Decals are objects which add texture to existing geometry. We slightly change the depth range to give them a small advantage in Z-testing.
// We use depthRange instead of polygonOffset to also support lines.
// https://www.opengl.org/archives/resources/faq/technical/polygonoffset.htm
export const decalProps = (gl, offset = 0.0001, renderOrder) => ({
  renderOrder: renderOrder || 999,
  onBeforeRender: () => gl.getContext().depthRange(0.00000, 1 - offset),
  onAfterRender: () => gl.getContext().depthRange(offset, 1.0),
});

export const polygonOffsetMaterialProps = (factor) => ({
  polygonOffset: true,
  polygonOffsetFactor: -factor,
  // polygonOffsetUnits: 2,
  depthWrite: false,
});

// react-three-fiber internals hacking: Any time an object is added after the scene is ready (first frame rendered),
// invalidate() is called, which increases a counter of "how many frames are needed to render". This means, that if
// we create 1000 objects, not in the first frame, we will render 1000 frames even if invalidateFrameLoop is set to true.
// This function can be used in useFrame() to offset this, by setting the max number of pending frames to a number high
// enough to not cause side effects, but low enough to not have a significant performance penalty on the browser.
// See invalidate(state, frames) in web.js in react-three-fiber package.
export const limitNumberOfThreeJSFramesWaitingToBeRendered = (scene, maxFrames = 10) => {
  // eslint-disable-next-line no-underscore-dangle
  const numFramesPendingToBeRendered = scene.__state.current.frames;
  if (numFramesPendingToBeRendered > maxFrames) {
    // eslint-disable-next-line no-underscore-dangle
    scene.__state.current.frames = maxFrames;
  }
};
