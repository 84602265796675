import React, { useEffect } from 'react';
import styled from 'styled-components';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileByIdSelector } from 'store/swappProfile';
import { useProfileResult } from 'store/swappProfile/selectors/swappSelectors';
import { MODEL_ANALYSIS_TYPES } from 'constants/feasibilityConts';
import { ViewerUiPanelWrapper } from 'styles/commonComponents.styles';
import ViewerUiPanel from 'utils/swappViewer/components/ui/ViewerUiPanel';
import FeasibilityViewerContainer from 'utils/swappViewer/FeasibilityViewerContainer';
import { setIsParkingFormOn } from 'store/parkingForm';
import BuildingStoryUI from 'utils/swappViewer/components/Stories/BuildingStoryUI';
import { useActiveProfileBuildingInfo } from 'utils/model/feasibilityResultModel';
import { userSelector } from 'store/user';
import lodashGet from 'lodash/get';
import FeasibilityParkingForm from './FeasibilityParkingForm';
import ParkingFinancialForm from './ParkingFinancialForm';

const ParkingContainerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const ParkingContainer = () => {
  const locationData = parseLocationUrl(window.location);
  const profile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const result = useProfileResult(profile);
  const dispatch = useDispatch();
  const buildingInfo = useActiveProfileBuildingInfo();
  const user = useSelector(userSelector);
  const isEditor = lodashGet(user, 'settings.isEditor');

  // mount/umount // TODO - maybe delete bool from state
  useEffect(() => {
    dispatch(setIsParkingFormOn(true));
    return () => {
      dispatch(setIsParkingFormOn(false));
    };
  }, []);

  return (
    <ParkingContainerWrapper>
      {/* ============ UI ============ */}
      <ViewerUiPanelWrapper position="top-left" isColumn fitContent>
        <ViewerUiPanel width={550}>
          <ParkingFinancialForm />
        </ViewerUiPanel>
        {isEditor && (
          <ViewerUiPanel width={550}>
            <FeasibilityParkingForm />
          </ViewerUiPanel>
        )}
      </ViewerUiPanelWrapper>

      {/* ============== Building stories ============== */}
      {buildingInfo.swpProject && (
        <ViewerUiPanelWrapper position="bottom-right" fitContent>
          <ViewerUiPanel width={150} isRight>
            <BuildingStoryUI swpProject={lodashGet(buildingInfo, 'swpProject')} />
          </ViewerUiPanel>
        </ViewerUiPanelWrapper>
      )}

      {/* ============ viewer ============ */}
      <FeasibilityViewerContainer
        result={result}
        isOrthographic
        profileId={locationData.profileId}
        legendKey={MODEL_ANALYSIS_TYPES.AREA_TYPE}
      />
    </ParkingContainerWrapper>
  );
};

export default ParkingContainer;
