import React from 'react';

export default () => (
  <svg width="95" height="95" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M65.8563 95.9547C65.333 95.9547 64.8979 95.5248 64.8979 95.0075V35.8486C64.8979 35.3314 65.3329 34.9014 65.8563 34.9014C66.3797 34.9014 66.8147 35.3313 66.8147 35.8486V95.0109C66.8113 95.5248 66.3763 95.9548 65.8563 95.9548V95.9547Z" fill="currentcolor" />
    <path d="M46.6283 95.9547C46.1049 95.9547 45.6699 95.5248 45.6699 95.0075V35.8486C45.6699 35.3314 46.1049 34.9014 46.6283 34.9014C47.1517 34.9014 47.5866 35.3313 47.5866 35.8486V95.0109C47.6716 95.5248 47.2366 95.9548 46.6283 95.9548V95.9547Z" fill="currentcolor" />
    <path d="M52.7188 51.5872C52.4572 51.5872 52.1955 51.4999 52.0222 51.3286C51.6755 50.9859 51.6755 50.2974 52.0222 49.9513L56.4604 45.5645L60.8987 49.9513C61.2453 50.294 61.2453 50.9825 60.8987 51.3286C60.552 51.6712 59.8554 51.6712 59.5053 51.3286L56.4604 48.3189L53.4155 51.3286C53.2422 51.4999 52.9805 51.5872 52.7189 51.5872H52.7188Z" fill="currentcolor" />
    <path d="M52.7188 61.5589C52.4572 61.5589 52.1955 61.4716 52.0222 61.3002C51.6755 60.9576 51.6755 60.269 52.0222 59.923L56.4604 55.5361L60.8987 59.923C61.2453 60.2656 61.2453 60.9542 60.8987 61.3002C60.552 61.6429 59.8554 61.6429 59.5053 61.3002L56.4604 58.2906L53.4155 61.3002C53.2422 61.4749 52.9805 61.5589 52.7189 61.5589H52.7188Z" fill="currentcolor" />
    <path d="M52.7188 71.5359C52.4572 71.5359 52.1955 71.4486 52.0222 71.2773C51.6755 70.9347 51.6755 70.2461 52.0222 69.9001L56.4604 65.5132L60.8987 69.9001C61.2453 70.2427 61.2453 70.9313 60.8987 71.2773C60.552 71.6199 59.8554 71.6199 59.5053 71.2773L56.4604 68.2676L53.4155 71.2773C53.2422 71.4486 52.9805 71.5359 52.7189 71.5359H52.7188Z" fill="currentcolor" />
    <path d="M52.7188 81.5125C52.4572 81.5125 52.1955 81.4252 52.0222 81.2539C51.6755 80.9112 51.6755 80.2227 52.0222 79.8766L56.4604 75.4897L60.8987 79.8766C61.2453 80.2192 61.2453 80.9078 60.8987 81.2539C60.552 81.5965 59.8554 81.5965 59.5053 81.2539L56.4604 78.2442L53.4155 81.2539C53.2422 81.4252 52.9805 81.5125 52.7189 81.5125H52.7188Z" fill="currentcolor" />
    <path d="M77.9481 65.7748C77.4248 65.7748 76.9897 65.3448 76.9897 64.8275V35.8496C76.9897 35.3323 77.4247 34.9023 77.9481 34.9023C78.4715 34.9023 78.9065 35.3323 78.9065 35.8496V64.8275C78.9065 65.3448 78.4715 65.7748 77.9481 65.7748Z" fill="currentcolor" />
    <path d="M65.8565 29.8303C65.3332 29.8303 64.8982 29.4004 64.8982 28.8831V22.3464L56.2868 13.8348L47.6755 22.3464V28.8831C47.6755 29.4004 47.2405 29.8303 46.7172 29.8303C46.1938 29.8303 45.7588 29.4004 45.7588 28.8831V21.4865L56.2872 11L66.8156 21.49V28.8865C66.8122 29.4004 66.3772 29.8304 65.8572 29.8304L65.8565 29.8303Z" fill="currentcolor" />
    <path d="M40.1923 41.1808H26.8809V28.0234H78.3824L87.2552 36.7936L40.1914 36.797L40.1923 41.1808ZM28.8827 39.2024H38.2792V34.8155H82.4747L77.5166 29.9147L28.8834 29.9181L28.8827 39.2024Z" fill="currentcolor" />
    <path d="M90.0393 95.9548C89.5159 95.9548 89.0809 95.5249 89.0809 95.0076L89.0843 71.8779H78.9065V76.9499C78.9065 77.4672 78.4715 77.8972 77.9481 77.8972C77.4247 77.8972 76.9897 77.4673 76.9897 76.9499V69.8994L90.9981 69.9028V95.0111C90.9981 95.525 90.5631 95.955 90.0397 95.955L90.0393 95.9548Z" fill="currentcolor" />
    <path d="M77.9472 71.877H65.8563C65.333 71.877 64.8979 71.447 64.8979 70.9297C64.8979 70.4124 65.3329 69.9824 65.8563 69.9824L77.9472 69.9858C78.4705 69.9858 78.9056 70.4157 78.9056 70.9331C78.9056 71.363 78.4706 71.877 77.9472 71.877V71.877Z" fill="currentcolor" />
    <path d="M83.9494 71.8782C83.4261 71.8782 82.991 71.4483 82.991 70.9309V65.8589H72.9011V70.9309C72.9011 71.4482 72.4661 71.8782 71.9427 71.8782C71.4194 71.8782 70.9844 71.4483 70.9844 70.9309L70.9878 63.7964H85.0805V70.8469C84.9955 71.3642 84.5605 71.8781 83.9488 71.8781L83.9494 71.8782Z" fill="currentcolor" />
    <path d="M28.6211 95.012H26.6195V63.0244C26.6195 60.532 24.6178 58.5536 22.0963 58.5536L10.5248 58.5502C8.00327 58.5502 6.00163 60.5287 6.00163 63.021V94.9252H4V63.0245C4 59.4975 6.95651 56.5752 10.5248 56.5752H22.0076C25.5758 56.5752 28.5324 59.4975 28.5324 63.0245V95.012L28.6211 95.012Z" fill="currentcolor" />
    <path d="M22.4432 58.5498C21.9198 58.5498 21.4848 58.1198 21.4848 57.6025V51.4958C21.4848 48.6575 19.1366 46.3364 16.265 46.3364C13.3934 46.3364 11.0451 48.6575 11.0451 51.4958V57.6025C11.0451 58.1198 10.6102 58.5498 10.0868 58.5498C9.56344 58.5498 9.12842 58.1198 9.12842 57.6025V51.4958C9.12842 47.6263 12.3466 44.4453 16.2615 44.4453C20.1764 44.4453 23.3946 47.6263 23.3946 51.4958V57.6025C23.4863 58.1198 22.9664 58.5498 22.443 58.5498H22.4432Z" fill="currentcolor" />
    <path d="M16.2684 46.0807C15.7451 46.0807 15.3101 45.6508 15.3101 45.1335V37.0518C15.3101 36.5345 15.745 36.1045 16.2684 36.1045C16.7918 36.1045 17.2268 36.5344 17.2268 37.0518V45.1335C17.3117 45.6508 16.8768 46.0807 16.2684 46.0807Z" fill="currentcolor" />
    <path d="M46.4555 77.3832C46.1938 77.3832 45.9322 77.2959 45.7588 77.1245L37.0591 68.9555L28.2707 77.0372C27.8357 77.3798 27.2274 77.3798 26.8773 76.9498C26.5307 76.5199 26.5307 75.9186 26.9657 75.5726L36.3622 66.8859C36.7089 66.5433 37.3206 66.5433 37.7556 66.8859L47.1522 75.5726C47.5871 75.9152 47.5871 76.6038 47.2405 76.9498C46.9754 77.2119 46.7172 77.3832 46.4555 77.3832V77.3832Z" fill="currentcolor" />
    <path d="M15.3101 67.6665H17.3117V69.9876H15.3101V67.6665Z" fill="currentcolor" />
    <path d="M15.3101 77.5552H17.3117V79.8762H15.3101V77.5552Z" fill="currentcolor" />
    <path d="M15.3101 87.3564H17.3117V89.6775H15.3101V87.3564Z" fill="currentcolor" />
    <path d="M36.0161 79.1875H38.0177V81.5085H36.0161V79.1875Z" fill="currentcolor" />
    <path d="M36.0161 87.6147H38.0177V89.9358H36.0161V87.6147Z" fill="currentcolor" />
    <path d="M90.038 78.1553H65.8553C65.332 78.1553 64.897 77.7253 64.897 77.208C64.897 76.6907 65.332 76.2607 65.8553 76.2607H90.0414C90.5648 76.2607 90.9998 76.6907 90.9998 77.208C90.9964 77.7253 90.5614 78.1553 90.038 78.1553H90.038Z" fill="currentcolor" />
  </svg>
);
