import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import lodashIsEmpty from 'lodash/isEmpty';
import { Checkbox } from 'antd';
import { StyledFormItem, StyledInputNumber } from 'styles/commonComponents.styles';
import { AREA_TARGET_FORM, FEASIBILITY_TARGET_FORM } from 'constants/fieldNames';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { FORM_INPUT_TYPE } from 'utils/helpers/testFitContainerHelprs';
import { BALCONY_AREA_TYPES, ilsMmdAreaSize } from 'constants/feasibilityConts';
import { feetToMeters, inchesToMeters } from 'utils/helpers/unitsHelpers';
import Dropdown from 'components/common/Dropdown';
import MetricImperialInput from '../../../common/MetricImperialInput';
import { AreaWrapper, AreaRow, AreaItem } from './FeasibilityTargetFormContainer.Styles';

const inputWidth = 90;
const fieldTitleWidth = 240;

const FeasibilityBuildingRightsAreaFormIlStandard = (props) => {
  const { form, formData, handleOnChange, isViewer } = props;

  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const isRoomCount = formData[AREA_TARGET_FORM.INPUT_TOGGLE] === FORM_INPUT_TYPE.ROOM_COUNT;

  const BALCONY_OPTIONS = [
    { key: BALCONY_AREA_TYPES.PER_APARTMENT, value: T.translate('FEASIBILITY_BUILDING_RIGHTS_AREA_FORM_ILS_BALCONY_PER_APARTMENT', { context: { unitType: T.translate(isImperial ? 'SQF' : 'SQM') } }) },
    { key: BALCONY_AREA_TYPES.ENTIRE_PROJECT, value: T.translate('FEASIBILITY_BUILDING_RIGHTS_AREA_FORM_ILS_BALCONY_TOTAL_AREA', { context: { unitType: T.translate(isImperial ? 'SQF' : 'SQM') } }) },
  ];

  useEffect(() => {
    if (lodashIsEmpty(formData)) {
      return;
    }
    onAreaChange();
  }, [
    formData[FEASIBILITY_TARGET_FORM.TOTAL_APARTMENTS],
  ]);

  const onAreaChange = (key, value) => {
    const totalTable = lodashGet(form.getFieldValue(FEASIBILITY_TARGET_FORM.TOTAL_APARTMENTS), '[0]', {});
    const totalApartments = lodashGet(totalTable, 'numberOfApartment', 0);
    const estimateArea = lodashGet(totalTable, 'estimateSF', 0);

    const apartmentsTotalArea = ilsMmdAreaSize * totalApartments;
    const PRIMARY_AREA = key === FEASIBILITY_TARGET_FORM.PRIMARY_AREA ? value : form.getFieldValue(FEASIBILITY_TARGET_FORM.PRIMARY_AREA);
    const COMMON_AREA = key === FEASIBILITY_TARGET_FORM.COMMON_AREA ? value : form.getFieldValue(FEASIBILITY_TARGET_FORM.COMMON_AREA);
    const NIA = PRIMARY_AREA + apartmentsTotalArea;
    const GIA = NIA + COMMON_AREA;

    form.setFieldsValue({
      [FEASIBILITY_TARGET_FORM.NIA]: NIA,
      [FEASIBILITY_TARGET_FORM.GIA]: GIA,
      [FEASIBILITY_TARGET_FORM.PRIMARY_AREA]: isRoomCount ? (estimateArea - apartmentsTotalArea) : PRIMARY_AREA,
      [FEASIBILITY_TARGET_FORM.COMMON_AREA]: COMMON_AREA,
    });
    handleOnChange();
  };

  return (
    <AreaWrapper>
      {/* ============== PRIMARY_AREA ============== */}
      <AreaRow split>
        <AreaItem width={fieldTitleWidth}>
          {T.translate('FEASIBILITY_BUILDING_RIGHTS_AREA_FORM_ILS_PRIMARY_AREA', { context: { unitType: T.translate(isImperial ? 'SQF' : 'SQM') } })}
        </AreaItem>
        <AreaItem>
          <StyledFormItem name={FEASIBILITY_TARGET_FORM.PRIMARY_AREA} initialValue={10000}>
            <MetricImperialInput
              min={0}
              height={22}
              disabled={isRoomCount || isViewer}
              width={inputWidth}
              isImperial={isImperial}
              onChange={(value) => onAreaChange(FEASIBILITY_TARGET_FORM.PRIMARY_AREA, value)}
            />
          </StyledFormItem>
        </AreaItem>
      </AreaRow>

      {/* ============== COMMON_AREA ============== */}
      <AreaRow split>
        <AreaItem width={fieldTitleWidth}>
          {T.translate('FEASIBILITY_BUILDING_RIGHTS_AREA_FORM_ILS_COMMON_AREA', { context: { unitType: T.translate(isImperial ? 'SQF' : 'SQM') } })}
        </AreaItem>
        <AreaItem>
          <StyledFormItem name={FEASIBILITY_TARGET_FORM.COMMON_AREA} initialValue={6000}>
            <MetricImperialInput
              min={0}
              height={22}
              disabled={isViewer}
              width={inputWidth}
              isImperial={isImperial}
              onChange={(value) => onAreaChange(FEASIBILITY_TARGET_FORM.COMMON_AREA, value)}
            />
          </StyledFormItem>
        </AreaItem>
      </AreaRow>

      {/* ============== IL_NUMBER_OF_APARTMENTS ============== */}
      <AreaRow split>
        <AreaItem width={fieldTitleWidth}>{T.translate('FEASIBILITY_BUILDING_RIGHTS_AREA_FORM_ILS_NUMBER_OF_APARTMENTS')}</AreaItem>
        <AreaItem>
          <StyledFormItem valuePropName="checked" name={FEASIBILITY_TARGET_FORM.IS_IL_NUMBER_OF_APARTMENTS} initialValue={false}>
            <Checkbox style={{ marginRight: '10px' }} disabled={isViewer} />
          </StyledFormItem>
          <StyledFormItem name={FEASIBILITY_TARGET_FORM.IL_NUMBER_OF_APARTMENTS} initialValue={40}>
            <StyledInputNumber
              min={0}
              height={22}
              disabled={!formData[FEASIBILITY_TARGET_FORM.IS_IL_NUMBER_OF_APARTMENTS] || isViewer}
              width={inputWidth}
            />
          </StyledFormItem>
        </AreaItem>
      </AreaRow>

      {/* ============== MIN_FLOOR_HEIGHT ============== */}
      <AreaRow split>
        <AreaItem width={fieldTitleWidth}>
          {T.translate('FEASIBILITY_BUILDING_RIGHTS_AREA_FORM_ILS_MIN_FLOOR_HEIGHT', { context: { unitType: T.translate(isImperial ? 'inch' : 'm') } })}
        </AreaItem>
        <AreaItem>
          <StyledFormItem name={FEASIBILITY_TARGET_FORM.MIN_FLOOR_HEIGHT} initialValue={2.8}>
            <MetricImperialInput
              min={2.7}
              max={3.65}
              height={22}
              disabled={isViewer}
              decimals={{ metric: 2 }}
              unit={inchesToMeters}
              width={inputWidth}
              isImperial={isImperial}
            />
          </StyledFormItem>
        </AreaItem>
      </AreaRow>

      {/* ============== MAX_CONSTRUCTION_HEIGHT ============== */}
      <AreaRow split>
        <AreaItem width={fieldTitleWidth}>
          {T.translate('FEASIBILITY_BUILDING_RIGHTS_AREA_FORM_ILS_MAX_CONSTRUCTION_HEIGHT', { context: { unitType: T.translate(isImperial ? 'ft' : 'm') } })}
        </AreaItem>
        <AreaItem>
          <StyledFormItem valuePropName="checked" name={FEASIBILITY_TARGET_FORM.IS_MAX_CONSTRUCTION_HEIGHT} initialValue={false}>
            <Checkbox style={{ marginRight: '10px' }} disabled={isViewer} />
          </StyledFormItem>
          <StyledFormItem name={FEASIBILITY_TARGET_FORM.MAX_CONSTRUCTION_HEIGHT} initialValue={38}>
            <MetricImperialInput
              min={0}
              height={22}
              width={inputWidth}
              disabled={!formData[FEASIBILITY_TARGET_FORM.IS_MAX_CONSTRUCTION_HEIGHT] || isViewer}
              unit={feetToMeters}
              isImperial={isImperial}
            />
          </StyledFormItem>
        </AreaItem>
      </AreaRow>

      {/* ============== BALCONY_AREA ============== */}
      <AreaRow split>
        <AreaItem width={fieldTitleWidth}>
          <StyledFormItem valuePropName="selectedKey" name={FEASIBILITY_TARGET_FORM.BALCONY_TYPE} initialValue={BALCONY_AREA_TYPES.PER_APARTMENT}>
            <Dropdown dropDownData={BALCONY_OPTIONS} width={150} />
          </StyledFormItem>
        </AreaItem>
        <AreaItem>
          <StyledFormItem name={FEASIBILITY_TARGET_FORM.BALCONY_AREA} initialValue={12}>
            <MetricImperialInput
              min={0}
              height={22}
              width={inputWidth}
              disabled={isViewer}
              isImperial={isImperial}
              onChange={handleOnChange}
            />
          </StyledFormItem>
        </AreaItem>
      </AreaRow>
    </AreaWrapper>
  );
};

FeasibilityBuildingRightsAreaFormIlStandard.propTypes = {
  form: PropTypes.object,
  formData: PropTypes.object,
  handleOnChange: PropTypes.func,
  isViewer: PropTypes.bool,
};

export default React.memo(FeasibilityBuildingRightsAreaFormIlStandard);
