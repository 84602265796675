import React, { useState } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { PlusOutlined } from '@ant-design/icons';

import { StyledUpload, UploadButton } from './planUpload.styles';
import { GENERATE_SWAPP_FORM } from '../../../constants/fieldNames';
import { FormItem } from './ProjectInfoForm.styles';

const fileInputSettings = {
  name: 'file',
  accept: '.pdf, .png, .jpeg, .jpg, .dwg, .zip',
  listType: 'picture-card',
};

const PlanUpload = (props) => {
  const { hasErrors, multiFile, maxFiles = 20 } = props;
  const [files, setFiles] = useState([]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleChange = ({ file, fileList }) => {
    if (file.status === 'done') {
      setFiles(fileList);
    }
  };

  const uploadButton = (
    <UploadButton>
      <PlusOutlined />
      <div>{T.translate('PLAN_UPLOAD_BUTTON')}</div>
    </UploadButton>
  );

  const dragAndDrop = (
    <StyledUpload
      {...fileInputSettings}
      hasError={hasErrors && hasErrors[GENERATE_SWAPP_FORM.FILES]}
      customRequest={dummyRequest}
      onChange={handleChange}
      multiple={multiFile}
      listType="picture-card"
    >
      {files.length >= maxFiles ? null : uploadButton}
    </StyledUpload>
  );

  return (
    <FormItem
      name={GENERATE_SWAPP_FORM.FILES}
      getValueFromEvent={normFile}
      valuePropName="fileList"
      label={T.translate('PLAN_UPLOAD_START_TEXT')}
      rules={[{ required: true, message: T.translate('PLAN_UPLOAD_START_ERROR') }]}
    >
      {dragAndDrop}
    </FormItem>
  );
};

PlanUpload.propTypes = {
  hasErrors: PropTypes.object,
  multiFile: PropTypes.bool,
  maxFiles: PropTypes.number,
};

export default PlanUpload;
