import lodashFlatten from 'lodash/flatten';
import lodashGet from 'lodash/get';
import { getIsBuildingOutOfLot, getOrientedBoundingBox, getRectanglePolygonCenter, transformPolygon, doPolygonsIntersect, vecAdd, rotatePolygon, vecSub } from '../utils/algorithms/algorithmHelpers';
import { getBuildingPivotPoint, isBuildingUnderground } from '../utils/model/feasibilityDataExtractors';

export const massTransformerInitialState = {
  buildingsData: {}, // The buildings that are currently being transforms
  transforms: {}, // The transforms of each building
  selectedMasses: [], // The masses currently selected
  transformRect: [], // The rectangle that bounds the masses being moved
  transformPivotPoint: [0, 0], // The point that the masses rotate around
  deltaAngle: null,
  isMoving: false,
  changedSinceLastUpdate: false, // A flag that follows cases when a local transformation was done during a wait for aserver call.
};

export const transformEditorSliceSelector = (state) => state.transformEditor;
const sitePolygonSelector = (slice) => (slice.buildingsData.constructionLine?.polygon.length ? slice.buildingsData.constructionLine.polygon : slice.buildingsData.sitePolygon.polygon);

const getBuildingPolygon = (slice, meshName) => {
  const transformObj = slice.transforms[meshName];
  const buildingData = slice.buildingsData.masses[meshName];
  const basePolygon = transformObj.points || buildingData.polygon;
  const transformTriplet = [transformObj.delta_x, transformObj.delta_y, transformObj.delta_angle];
  return transformPolygon(basePolygon, transformTriplet, buildingData.pivotPoint);
};

const calculateTransformRect = (slice) => {
  if (slice.selectedMasses.length === 0) {
    slice.transformRect = [];
    slice.transformPivotPoint = [0, 0];
    return;
  }
  const polygons = slice.selectedMasses.map((massName) => getBuildingPolygon(slice, massName));
  const polygonPoints = lodashFlatten(polygons);
  const obb = getOrientedBoundingBox(polygonPoints);
  slice.transformRect = obb;
  slice.transformPivotPoint = getRectanglePolygonCenter(obb);

  updateCollisionsImpl(slice);
};

const updateCollisionsImpl = (state) => {
  state.selectedMasses.forEach((buildingName) => {
    const buildingData = state.buildingsData.masses[buildingName];
    if (!buildingData) {
      return; // After refresh after deleting mass, this may happen momentarily
    }
    const transformedPolygon = getBuildingPolygon(state, buildingName);
    const sitePolygon = sitePolygonSelector(state);
    const isOutOfLot = getIsBuildingOutOfLot(sitePolygon, transformedPolygon);
    const buildingTransform = state.transforms[buildingName];

    const isUnderground = isBuildingUnderground(buildingData);
    Object.keys(state.transforms).forEach((otherBuildingName) => {
      if (otherBuildingName !== buildingName) {
        const didIntersect = lodashGet(buildingTransform, `collisions.${otherBuildingName}`, false);
        let doIntersect = false;
        // Masses may be deleted during the lifecycle of this object. If mass does not exist, we do not intersect it.
        if (otherBuildingName in state.buildingsData.masses) {
          const areBuildingsInSameLevel = isUnderground === isBuildingUnderground(state.buildingsData.masses[otherBuildingName]);
          if (areBuildingsInSameLevel) {
            const otherPolygon = getBuildingPolygon(state, otherBuildingName);
            doIntersect = doPolygonsIntersect(transformedPolygon, otherPolygon);
          }
        }
        if (doIntersect !== didIntersect) {
          buildingTransform.collisions[otherBuildingName] = doIntersect;
          state.transforms[otherBuildingName].collisions[buildingName] = doIntersect;
        }
      }
    });
    buildingTransform.isOutOfLot = isOutOfLot;
  });

  Object.values(state.transforms).forEach((buildingTransform) => {
    const isValid = buildingTransform.isOutOfLot === false && !Object.values(buildingTransform.collisions).includes(true);
    buildingTransform.isValid = isValid;
  });
};

const getMassName = (slice, massIndex) => {
  const mass = Object.values(slice.buildingsData.masses).find((o) => o.massIndex === massIndex);
  return mass?.name;
};

export const massTransformerReducer = {
  initialize(editorSlice, action) {
    const state = editorSlice.massTransformer;
    const buildingsData = action.payload;
    state.buildingsData = buildingsData;
    const initialValues = {};
    const sitePolygon = sitePolygonSelector(state);
    Object.keys(buildingsData.masses).forEach((meshName) => {
      const isOutOfLot = getIsBuildingOutOfLot(sitePolygon, buildingsData.masses[meshName].polygon);
      initialValues[meshName] = { key: meshName, isValid: !isOutOfLot, isOutOfLot, delta_x: 0, delta_y: 0, delta_angle: 0, points: null, collisions: {} };
      // If something was changed during the server call, we want to save it's transformation.
      if (state.changedSinceLastUpdate) {
        const massTransform = state.transforms[meshName];
        if (massTransform) {
          initialValues[meshName] = massTransform;
        }
      }
    });
    state.transforms = initialValues;
    state.changedSinceLastUpdate = false;
    updateCollisionsImpl(state);
  },
  resetMassTransform(editorSlice, action) {
    const state = editorSlice.massTransformer;
    const { meshName } = action.payload;
    state.transforms[meshName].delta_x = 0;
    state.transforms[meshName].delta_y = 0;
    state.transforms[meshName].delta_angle = 0;

    state.changedSinceLastUpdate = false;
  },
  moveSelection(editorSlice, action) {
    const state = editorSlice.massTransformer;
    const { dx, dy } = action.payload;
    state.deltaAngle = null;
    state.selectedMasses.forEach((meshName) => {
      state.transforms[meshName].delta_x += dx;
      state.transforms[meshName].delta_y += dy;
    });
    calculateTransformRect(state);
    state.isMoving = true;
    state.changedSinceLastUpdate = true;
  },
  finnishMove(editorSlice) {
    const state = editorSlice.massTransformer;
    state.isMoving = false;
  },
  rotateSelection(editorSlice, action) {
    const state = editorSlice.massTransformer;
    const deltaAngle = action.payload;
    state.deltaAngle = deltaAngle;
    state.selectedMasses.forEach((meshName) => {
      const transform = state.transforms[meshName];
      const curPivotPosition = vecAdd(getBuildingPivotPoint(state.buildingsData.masses[meshName]), [transform.delta_x, transform.delta_y]);
      const newPivotPosition = rotatePolygon([curPivotPosition], state.transformPivotPoint, deltaAngle)[0];
      const pivotDelta = vecSub(newPivotPosition, curPivotPosition);
      transform.delta_angle += deltaAngle;
      transform.delta_x += pivotDelta[0];
      transform.delta_y += pivotDelta[1];
    });
    calculateTransformRect(state);
    state.changedSinceLastUpdate = true;
  },
  updateObjectPoints(editorSlice, action) {
    const state = editorSlice.massTransformer;
    state.deltaAngle = null;
    const points = action.payload;
    if (state.selectedMasses.length === 1) {
      state.transforms[state.selectedMasses[0]].points = points;
    }
    calculateTransformRect(state);
  },
  updateCollisions(editorSlice) {
    const state = editorSlice.massTransformer;
    updateCollisionsImpl(state);
  },
  updateSelection(editorSlice, action) {
    const state = editorSlice.massTransformer;
    const selectedObjects = action.payload;
    state.deltaAngle = null;
    if (Array.isArray(selectedObjects)) {
      const selected = selectedObjects.filter((o) => o.type === 'Mass').map((o) => getMassName(state, o.id)).filter((e) => e);
      if (!selected.length || selected[0]) {
        state.selectedMasses = selected;
      }
    } else {
      state.selectedMasses = [];
    }
    calculateTransformRect(state);
  },
  notifySentUpdateToServer(editorSlice) {
    // We use this flag in order to avoid resetting the transforms when a transform was changed during a server call.
    const state = editorSlice.massTransformer;
    state.changedSinceLastUpdate = false;
  },
};
