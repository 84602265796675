import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashIsFinite from 'lodash/isFinite';
import lodashIncludes from 'lodash/includes';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import { message } from 'antd';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import lodashKeys from 'lodash/keys';
import { StyledFormItem, SmallStyledTable, DeleteIcon } from 'styles/commonComponents.styles';
import { FEASIBILITY_TARGET_FORM } from 'constants/fieldNames';
import {
  ALL_COMMUNAL_SPACES_TYPES_ARRAY,
  COMMUNAL_SPACES_RATIO_TYPE,
  COMMUNAL_SPACES_SPREAD,
  STANDARDS,
} from 'constants/feasibilityConts';
import { deleteRow, updateRow, updateTable } from 'utils/helpers/tableHelpers';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import deleteIcon from 'styles/static/icons/comon/deleteIcon';
import { uuidv4 } from 'utils/helpers/uuidv4';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { sqmToSqf } from 'utils/helpers/unitsHelpers';
import Dropdown from '../../../common/Dropdown';
import AddNewRowButton from '../../../common/AddNewRowButton';
import MetricImperialInput from '../../../common/MetricImperialInput';

const maxTotalCommunalSpaceArea = 10000;

const spreadTypes = [
  { key: COMMUNAL_SPACES_SPREAD.PER_BUILDING, value: `FEASIBILITY_COMMUNAL_SPACE_FORM_${COMMUNAL_SPACES_SPREAD.PER_BUILDING}` },
  { key: COMMUNAL_SPACES_SPREAD.ENTIRE_PROJECT, value: `FEASIBILITY_COMMUNAL_SPACE_FORM_${COMMUNAL_SPACES_SPREAD.ENTIRE_PROJECT}` },
  { key: COMMUNAL_SPACES_SPREAD.PER_FLOOR, value: `FEASIBILITY_COMMUNAL_SPACE_FORM_${COMMUNAL_SPACES_SPREAD.PER_FLOOR}`, hidden: true }, // hide per floor for now
];

const FeasibilityCommunalSpaceForm = (props) => {
  const { form, formData, handleOnChange, disabled } = props;
  const unitType = lodashGet(formData, `${FEASIBILITY_TARGET_FORM.APARTMENTS_STANDARD}[0]`);
  const additionalUnitTypes = unitType && unitType !== STANDARDS.PREFAB.key
    ? lodashKeys(STANDARDS[unitType].communalSpaces).map((key) => STANDARDS[unitType].communalSpaces[key])
    : [];
  const allCommunalSpacesTypes = lodashKeys(STANDARDS.PREFAB.communalSpaces).map((key) => STANDARDS.PREFAB.communalSpaces[key]);
  const communalSpacesTypes = [...allCommunalSpacesTypes, ...additionalUnitTypes, STANDARDS.PREFAB.extraCommunalSpaces.OTHER];
  const match = useRouteMatch();
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));
  const currentCommunalSpaceData = form.getFieldValue(FEASIBILITY_TARGET_FORM.COMMUNAL_SPACE) || [];
  const selectedCommunalSpaceList = currentCommunalSpaceData.map((unit) => unit.communalSpaceType);

  useEffect(() => {
    if (lodashIsEmpty(formData)) {
      return;
    }
    updateTable(handleOnChange, form, FEASIBILITY_TARGET_FORM.COMMUNAL_SPACE, updateEstimations);
  }, [
    formData[FEASIBILITY_TARGET_FORM.TOTAL_APARTMENTS],
  ]);

  // update total
  useEffect(() => {
    if (lodashIsEmpty(formData) || lodashIsEmpty(formData[FEASIBILITY_TARGET_FORM.COMMUNAL_SPACE])) {
      return;
    }

    updateTotalEstimations();
  }, [formData[FEASIBILITY_TARGET_FORM.COMMUNAL_SPACE]]);

  useEffect(() => {
    const totalCommunalSpace = formData[FEASIBILITY_TARGET_FORM.TOTAL_COMMUNAL_SPACE];
    if (lodashGet(totalCommunalSpace, '[0].area', 0) > (isImperial ? sqmToSqf(maxTotalCommunalSpaceArea) : maxTotalCommunalSpaceArea)) {
      message.warning(T.translate('FEASIBILITY_COMMUNAL_SPACE_TOO_LARGE_WARNING'));
    }
  }, [lodashGet(formData[FEASIBILITY_TARGET_FORM.TOTAL_COMMUNAL_SPACE], '[0].area')]);

  const ratioTypes = [
    { key: COMMUNAL_SPACES_RATIO_TYPE.ROOM_AREA, value: `${T.translate(`FEASIBILITY_COMMUNAL_SPACE_FORM_${COMMUNAL_SPACES_RATIO_TYPE.ROOM_AREA}`)} ${T.translate(isImperial ? 'SQF' : 'SQM')}` },
    { key: COMMUNAL_SPACES_RATIO_TYPE.PER_APARTMENT, value: `${T.translate(isImperial ? 'SQF' : 'SQM')} ${T.translate(`FEASIBILITY_COMMUNAL_SPACE_FORM_${COMMUNAL_SPACES_RATIO_TYPE.PER_APARTMENT}`)}` },
  ];

  const communalSpacesColumns = [
    {
      title: T.translate('FEASIBILITY_COMMUNAL_SPACE_FORM_SPACE_TYPE'),
      dataIndex: 'communalSpaceType',
      key: 'communalSpaceType',
      align: 'center',
      width: 130,
      render: (key, rowData) => (
        <Dropdown
          dropDownData={communalSpacesTypes}
          selectedKey={key}
          permanent={lodashIncludes(rowData.disabledList, 'communalSpaceType')}
          selectedList={unitType === STANDARDS.DORMITORIES.key ? [STANDARDS.DORMITORIES.communalSpaces.SHARED_KITCHEN.key] : []}
          onChange={(value) => handleCommunalSpaceTypeChange(value, rowData)}
          inputValue={rowData.otherText}
          onInputChange={(e) => handleCommunalSpaceTypeFreeTextChange(e, rowData)}
        />
      ),
    },
    {
      title: T.translate('FEASIBILITY_COMMUNAL_SPACE_FORM_RATIO_TYPE'),
      dataIndex: 'ratioType',
      key: 'ratioType',
      align: 'center',
      width: 60,
      render: (key, rowData) => (
        <Dropdown
          dropDownData={ratioTypes}
          permanent={lodashIncludes(rowData.disabledList, 'ratioType')}
          selectedKey={key}
          onChange={(value) => handleRatioTypeChange(value, rowData)}
        />
      ),
    },
    {
      title: `${T.translate('FEASIBILITY_COMMUNAL_SPACE_FORM_RATIO')}${T.translate(isImperial ? 'SQF' : 'SQM')}`,
      dataIndex: 'ratio',
      key: 'ratio',
      width: 80,
      align: 'center',
      render: (target, rowData) => (
        <MetricImperialInput
          min={rowData.min || 0}
          height={25}
          width={70}
          value={target}
          isImperial={isImperial}
          onChange={(e) => handleRatioChange(e, rowData)}
        />
      ),
    },
    {
      title: T.translate('FEASIBILITY_COMMUNAL_SPACE_FORM_SPREAD'),
      dataIndex: 'spreadType',
      key: 'spreadType',
      align: 'center',
      width: 60,
      render: (key, rowData) => (
        <Dropdown
          dropDownData={spreadTypes}
          permanent={lodashIncludes(rowData.disabledList, 'spreadType')}
          selectedKey={key}
          onChange={(value) => handleRoomSpreadChange(value, rowData)}
        />
      ),
    },
    {
      title: T.translate(isImperial ? 'SQF' : 'SQM'),
      dataIndex: 'area',
      key: 'area',
      width: 50,
      align: 'center',
      render: (value) => numberWithComma(isImperial ? sqmToSqf(value) : value, { fixed: isImperial ? 0 : 1 }),
    },
    {
      dataIndex: 'delete',
      width: 20,
      align: 'center',
      render: (text, rowData) => (
        <DeleteIcon
          component={deleteIcon}
          disabled={lodashIncludes(rowData.disabledList, 'deleteIcon')}
          onClick={() => (lodashIncludes(rowData.disabledList, 'deleteIcon') ? null : deleteRow(handleOnChange, rowData.key, form, FEASIBILITY_TARGET_FORM.COMMUNAL_SPACE))}
        />
      ),
    },
  ];

  const totalApartmentColumns = [
    { dataIndex: 'communalSpaceType', key: 'communalSpaceType', width: 50 },
    { dataIndex: 'ratioType', key: 'ratioType', width: 60 },
    { dataIndex: 'ratio', key: 'ratio', width: 90 },
    { dataIndex: 'spreadType', key: 'spreadType', width: 80 },
    { dataIndex: 'area', key: 'area', width: 95, align: 'center', render: (value) => numberWithComma(isImperial ? sqmToSqf(value) : value, { fixed: isImperial ? 0 : 1, suffix: ` ${T.translate(isImperial ? 'SQF' : 'SQM')}` }) },
  ];

  const handleCommunalSpaceTypeFreeTextChange = (e, rowData) => {
    const newValues = {
      otherText: e.target.value,
    };

    updateRow(handleOnChange, rowData, FEASIBILITY_TARGET_FORM.COMMUNAL_SPACE, form, newValues);
  };

  const handleCommunalSpaceTypeChange = (key, rowData) => {
    const newValues = {
      communalSpaceType: key,
      otherText: '',
    };

    if (key === STANDARDS.DORMITORIES.communalSpaces.SHARED_AMENITY.key) {
      newValues.disabledList = ['spreadType'];
      newValues.spreadType = COMMUNAL_SPACES_SPREAD.PER_FLOOR;
    } else {
      newValues.disabledList = [];
    }

    updateRow(handleOnChange, rowData, FEASIBILITY_TARGET_FORM.COMMUNAL_SPACE, form, newValues, updateEstimations);
  };

  const handleRatioTypeChange = (key, rowData) => {
    const newValues = {
      ratioType: key,
    };

    updateRow(handleOnChange, rowData, FEASIBILITY_TARGET_FORM.COMMUNAL_SPACE, form, newValues, updateEstimations);
  };

  const handleRatioChange = (e, rowData) => {
    const newValues = {
      ratio: e,
    };

    updateRow(handleOnChange, rowData, FEASIBILITY_TARGET_FORM.COMMUNAL_SPACE, form, newValues, updateEstimations);
  };

  const handleRoomSpreadChange = (key, rowData) => {
    const newValues = {
      spreadType: key,
    };

    updateRow(handleOnChange, rowData, FEASIBILITY_TARGET_FORM.COMMUNAL_SPACE, form, newValues, updateEstimations);
  };

  const updateEstimations = (data) => {
    const totalApartmentsCount = lodashGet(form.getFieldValue(FEASIBILITY_TARGET_FORM.TOTAL_APARTMENTS), '[0].numberOfApartment', 0);
    const area = data.ratioType === COMMUNAL_SPACES_RATIO_TYPE.ROOM_AREA ? data.ratio : data.ratio * totalApartmentsCount;

    return {
      area: lodashIsFinite(area) ? area : 0,
    };
  };

  const updateTotalEstimations = () => {
    const communalSpaceData = form.getFieldValue(FEASIBILITY_TARGET_FORM.COMMUNAL_SPACE);
    const area = communalSpaceData.reduce((total, current) => ({ area: Number(total.area) + Number(current.area) }), { area: 0 });

    form.setFieldsValue({ [FEASIBILITY_TARGET_FORM.TOTAL_COMMUNAL_SPACE]: [
      {
        ...totalTableInitialValue[0],
        area: area.area,
      },
    ],
    });
    handleOnChange();
  };

  const tableInitialValue = (index) => ({
    key: uuidv4(),
    communalSpaceType: ALL_COMMUNAL_SPACES_TYPES_ARRAY[index].key,
    otherText: '',
    ratioType: COMMUNAL_SPACES_RATIO_TYPE.ROOM_AREA,
    ratio: 0,
    spreadType: COMMUNAL_SPACES_SPREAD.PER_BUILDING,
    area: 0,
  });

  const totalTableInitialValue = [{
    key: 'TOTAL',
    communalSpaceType: T.translate('FEASIBILITY_COMMUNAL_SPACE_TOTAL'),
    ratioType: '',
    ratio: '',
    spreadType: '',
    area: 0,
  }];

  const addNewRow = () => {
    const nextAvailableAptIndex = communalSpacesTypes.findIndex((item) => !lodashIncludes(selectedCommunalSpaceList, item.key));
    const isIndexUnknown = nextAvailableAptIndex === -1;

    const currentData = formData[FEASIBILITY_TARGET_FORM.COMMUNAL_SPACE] || [];

    form.setFieldsValue({ [FEASIBILITY_TARGET_FORM.COMMUNAL_SPACE]: [
      ...currentData,
      tableInitialValue(isIndexUnknown ? communalSpacesTypes.length - 1 : nextAvailableAptIndex),
    ] });
    handleOnChange();
  };

  return (
    <>
      {/* ========= Table ========= */}
      <StyledFormItem name={FEASIBILITY_TARGET_FORM.COMMUNAL_SPACE} valuePropName="dataSource" initialValue={[tableInitialValue(0)]}>
        <SmallStyledTable disabled={disabled} pagination={false} columns={communalSpacesColumns} />
      </StyledFormItem>

      {/* ========= New EditPanelButton ========= */}
      <AddNewRowButton small disabled={disabled} onClick={addNewRow} text={T.translate('FEASIBILITY_COMMUNAL_SPACE_ADD_ANOTHER')} />

      {/* ========= Total table ========= */}
      <StyledFormItem name={FEASIBILITY_TARGET_FORM.TOTAL_COMMUNAL_SPACE} valuePropName="dataSource" initialValue={totalTableInitialValue}>
        <SmallStyledTable loading={disabled} pagination={false} columns={totalApartmentColumns} showHeader={false} rowClassName={(row) => row.key === 'TOTAL' && 'ant-table-total-row'} />
      </StyledFormItem>
    </>
  );
};

FeasibilityCommunalSpaceForm.propTypes = {
  handleOnChange: PropTypes.func,
  form: PropTypes.object,
  formData: PropTypes.object,
  disabled: PropTypes.bool,
};

export default React.memo(FeasibilityCommunalSpaceForm);
