import styled from 'styled-components';

export const Title = styled.div`
  font-size: ${({ theme }) => theme.font.size.large};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: 20px;
  text-transform: uppercase;
`;

export const StyledLine = styled.div`    
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  line-height: 200%;
`;

export const Label = styled.div`    
  // font-weight: ${({ theme }) => theme.font.weight.bold}; 
  // font-size: ${({ theme }) => theme.font.size.large};
`;

export const Value = styled.div`    
  font-size: ${({ theme }) => theme.font.size.medium};
`;
