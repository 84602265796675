import styled from 'styled-components';
import Icon, { LockOutlined } from '@ant-design/icons';
import { StyledFormItem } from 'styles/commonComponents.styles';

export const ResultWrapper = styled.div`
   height: calc(100vh - ${({ theme, height }) => `${theme.other.headerHeight}px - ${height || 1}px`} - 48px); // 46px is the height of the action bar
   display: flex;
   padding: ${({ padding }) => (padding || '0 20px 20px')};
`;

export const ResultData = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
  padding: 10px 18px;
  z-index: ${({ isInEdit }) => (isInEdit && 2)};
`;

export const FormItem = styled(StyledFormItem)`
  display: flex;
  .ant-form-item-label {
    display: flex;
    padding-left: 10px;
  
    label {
      color: ${({ theme }) => theme.colors.textColor} !important;
      font-size: ${({ theme }) => theme.font.size.small} !important;
    }
  }
`;

export const CardTypeIcon = styled(Icon)`
  margin-top: 3px;
`;

export const AreaWrapper = styled.div`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  flex-direction: column;
  width: 100%;
`;

export const AreaRow = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: ${({ split }) => (split ? 'space-between' : 'flex-start')};
`;

export const AreaItem = styled.div`
  display: flex;
  justify-content: ${({ flexEnd }) => (flexEnd ? 'flex-end' : 'flex-start')};
  height: 22px;
  line-height: 2.3;
  padding-right: 10px;
  width: ${({ width }) => (width && `${width}px`)};
  min-width: ${({ first }) => (first && '112px')};
  padding-top: ${({ flexEnd }) => (flexEnd && '8px')};
  font-size: ${({ theme }) => theme.font.size.medium};

  &:first-child {
    justify-content: start;
    padding-left: 10px;
    padding-right: 0;
  }
`;

export const TableCell = styled.span`
  display: table-cell;
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BoldCell = styled.span`
  font-weight: ${({ theme }) => theme.font.weight.bold}; 
  height: 22px;
`;

export const StyledLockOutlined = styled(LockOutlined)`
    color: ${({ theme }) => theme.colors.primaryColor};
`;

export const ColorPill = styled.div`
  width: ${({ width }) => (width ? `${width}px` : '5px')};
  height: ${({ height }) => (height ? `${height}px` : '15px')};
  margin: ${({ margin }) => (margin || '0 8px 0 0')};
  border-radius: 5px;
  background-color: ${({ color }) => color || 'transparent'};
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  right: 35px;
  top: 6px;
`;

export const SwitchFormItemWrapper = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  line-height: 2.7;
`;

export const SwitchFormItem = styled(StyledFormItem)`
  margin: 0;
  width: auto;
`;

export const SwitchTitle = styled.div`
  margin-left: 5px;
  font-size: ${({ theme }) => theme.font.size.small};
  color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_07 : theme.colors.textColor)};
`;

export const SwitchHeaderTitle = styled.div`
  margin: 10px 0 10px 0;
  font-size: ${({ theme }) => theme.font.size.medium};
`;
