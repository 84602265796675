import React from 'react';

// currentcolor
export default () => (
  <svg width="32" height="32" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M4.0172 20.684C4.74606 18.4789 7.03402 14.1529 10.3549 14.4894C14.506 14.9101 13.5717 23.8351 16.8201 23.5465C20.0685 23.258 23 15.5 26.3935 15.5382C29.5396 15.5737 29.8835 17.3334 33.1829 23.1455" stroke="currentcolor" strokeWidth="1.5" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="38" height="38" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
