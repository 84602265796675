import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import styled from 'styled-components';
import icons from '../../styles/static/icons/markups';
import { isSharedUrl } from '../../utils/helpers/navigationHelpers';

const MarkupsButtonWrapper = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.gray_01};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  height: 50px;
  width: 50px;
  padding: 4px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.12);
  margin-right: 10px;
  position: relative;
  
  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.primaryColor};
    }
  }
`;

const MarkupsButton = (props) => {
  const { onSelected } = props;

  if (isSharedUrl()) {
    return null;
  }

  return (
    <MarkupsButtonWrapper onClick={onSelected}>
      <Tooltip title={T.translate('MARKUPS_CONTAINER_TOOLTIP')}>
        <Icon component={icons.activateIcon} />
      </Tooltip>
    </MarkupsButtonWrapper>
  );
};

MarkupsButton.propTypes = {
  onSelected: PropTypes.func,
};

export default MarkupsButton;
