import * as actionTypes from '../actions/actionTypes';

const initialState = {
  profileTemplates: null,
};

export const profileTemplatesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_PROFILE_TEMPLATES_REQUEST:
      return { ...state, error: null, loading: true };
    case actionTypes.GET_PROFILE_TEMPLATES_ERROR:
      return { ...state, error: payload, loading: false };
    case actionTypes.GET_PROFILE_TEMPLATES_SUCCESS:
      return { ...state, profileTemplates: payload, loading: false };
    default:
      return state;
  }
};
