import { createSlice } from '@reduxjs/toolkit';
import lodashIsEmpty from 'lodash/isEmpty';

export const PARKING_ENTRANCE_MODES = {
  EDGE: 'Edge',
  POSITION: 'Position',
};
const initialState = {
  isParkingFormOn: false,
  parkingFormMode: PARKING_ENTRANCE_MODES.EDGE,
  parkingEntrancePosition: null,
  parkingEntranceEdges: {},
  parkingEntranceEdgeIndex: {},
  parkingSelectedIndex: undefined,
};

const parkingFormSlice = createSlice({
  name: 'parkingForm',
  initialState,
  reducers: {
    setIsParkingFormOn(state, action) {
      state.isParkingFormOn = action.payload;
    },
    setParkingEntranceMode(state, action) {
      state.parkingFormMode = action.payload;
      state.parkingEntrancePosition = null;
      state.parkingEntranceEdges = {};
      state.parkingEntranceEdgeIndex = {};
    },
    setParkingEntrance(state, action) {
      const { index, points, position } = action.payload;

      state.parkingEntranceEdges[index] = points;
      state.parkingEntranceEdgeIndex[index] = !state.parkingEntranceEdgeIndex[index];
      state.parkingEntrancePosition = position;
      if (state.parkingFormMode === PARKING_ENTRANCE_MODES.POSITION) {
        state.parkingSelectedIndex = index;
      } else {
        state.parkingSelectedIndex = undefined;
      }
    },
    setStateFromBe(state, action) {
      if (lodashIsEmpty(action.payload)) {
        return;
      }

      state.parkingFormMode = action.payload?.parkingFormMode;
      state.parkingEntrancePosition = action.payload?.parkingEntrancePosition;
      state.parkingEntranceEdges = action.payload?.parkingEntranceEdges;
      state.parkingEntranceEdgeIndex = action.payload?.parkingEntranceEdgeIndex;
      state.parkingSelectedIndex = action.payload?.parkingSelectedIndex;
    },
  },
});

export const { setIsParkingFormOn, setParkingEntranceMode, setParkingEntrance, setStateFromBe } = parkingFormSlice.actions;
export default parkingFormSlice.reducer;

// =========== Selectors =========== //
export const parkingFormSelector = ({ parkingForm }) => parkingForm.isParkingFormOn;
export const parkingFormModeSelector = ({ parkingForm }) => parkingForm.parkingFormMode;
export const edgeIndexSelector = ({ parkingForm }) => parkingForm.parkingEntranceEdgeIndex;
export const positionEdgeIndexSelector = ({ parkingForm }) => parkingForm.parkingSelectedIndex;
export const edgeSelector = ({ parkingForm }) => parkingForm.parkingEntranceEdges;
export const entrancePositionSelector = ({ parkingForm }) => parkingForm.parkingEntrancePosition;
