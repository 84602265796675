/* eslint-disable radix */
/* eslint-disable no-restricted-globals */
import React, { useMemo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import lodashIsEmpty from 'lodash/isEmpty';
import { extend, Canvas } from 'react-three-fiber';
import styled from 'styled-components';
import * as meshline from 'threejs-meshline';
import * as THREE from 'three';
import { useDispatch, useSelector } from 'react-redux';
import { vecSub, vecAngle, normalizeRad, vecDistance } from 'utils/algorithms/algorithmHelpers';
import { selectedViewIdSelector, setSelectedViewId, resetToInitialState } from 'store/threeDWalkthrough';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import SphereComponent from './SphereComponent';
import LoadingComponent from './LoadingComponent';

const MAX_WALL_DISTANCE = 750; // Maximum distance to show other poitns in case there is no line of sight
// const ANGLE_THRESHOLD = 20 * (Math.PI / 180); // remove angles which are in the same directions of others

// const isAngleCloseToOtherCloserAngle = (closerNeigbours, angle) => {
//   for (let i = 0; i < closerNeigbours.length; i++) {
//     if (Math.abs(closerNeigbours[i].angle - angle) < ANGLE_THRESHOLD) {
//       return true;
//     }
//   }
//   return false;
// };

export const useWalkThroughLoading = (walkThroughData, profileId) => {
  const [lowResList, setLowResList] = useState(false);
  const [highResList, setHighResList] = useState(false);
  const [isStartedLoading, setIsStartedLoading] = useState(false);
  const images = walkThroughData?.views.map((view) => view.renderURL);
  const lowRes = walkThroughData?.views.map((view) => view.lowResRenderURL);

  useEffect(() => {
    setIsStartedLoading(false);
  }, [profileId]);

  const downloadLowRes = () => {
    if (isStartedLoading) {
      return;
    }
    setIsStartedLoading(true);
    // console.log('START loading Low-res');
    loadTextures(lowRes, (res) => {
      // console.log('Done Loading All Low-Res');
      setLowResList(res);
    });
  };

  useEffect(() => {
    if (lowResList) {
      // console.log('START loading High-res');
      loadTextures(images, (res) => {
        // console.log('Done Loading All High-Res');
        setHighResList(res);
      });
    }
  }, [lowResList]);

  return { lowResList, highResList, downloadLowRes };
};

const getNeigborsWithAnglesAndDistance = (views) => {
  const dictionary = {};
  views.forEach((view) => {
    const neighborsWithAngles = [];
    view.neighbors?.forEach((neighbor) => {
      const { id, isInLineOfSight } = neighbor;
      if (views[id]) {
        const neighborPos = views[id].position;
        const dif = vecSub(neighborPos, view.position);
        let angle = vecAngle(dif);
        angle = normalizeRad(angle);
        const distance = vecDistance(neighborPos, view.position);
        if (isInLineOfSight || distance < MAX_WALL_DISTANCE) {
          neighborsWithAngles.push({ angle, distance, id, ...neighbor });
        }
        neighborsWithAngles.sort((a, b) => a.distance - b.distance);
      }
    });

    // In case we want to hide points that are behind others
    // const filteredNeighbours = neighborsWithAngles.filter((obj, index) => {
    //   const closerNeighbors = neighborsWithAngles.slice(0, Math.max(0, index - 1));
    //   if (!isAngleCloseToOtherCloserAngle(closerNeighbors, obj.angle)) {
    //     return true;
    //   }
    //   return false;
    // });

    dictionary[view.id] = neighborsWithAngles;
  });
  return dictionary;
};

export const StyledCanvas = styled(Canvas)`
  height: 100%;
  width: 100%;
  border: 0;
  border-radius: ${({ radius }) => (radius ? `${radius}px` : 0)};
  z-index: 2;

  canvas {
    &:focus {
      outline: 0;
    }
  }
`;

extend(meshline);

export const loadTextures = (urls, cb) => {
  const lowResDict = {};
  const loader = new THREE.TextureLoader();
  urls?.forEach((url) => {
    // console.log('start load > ', url);
    loader.load(url, (p) => {
      lowResDict[url] = p;
      const entries = Object.entries(lowResDict);
      // console.log('Done loading: > ', url);
      if (entries.length === urls.length) {
        const soretedList = entries.sort((a, b) => urls.indexOf(a[0]) - urls.indexOf(b[0])).map((a) => a[1]);
        cb(soretedList);
      }
    });
  });
};

const WalkThrough = (props) => {
  const { data, lowResList, highResList, onWalkThroughMounted } = props;
  const selectedViewId = useSelector(selectedViewIdSelector);
  const anglesDictionary = useMemo(() => getNeigborsWithAnglesAndDistance(data.views), []);
  const dispatch = useDispatch();
  const locationData = parseLocationUrl(window.location);
  THREE.Object3D.DefaultUp = new THREE.Vector3(0, 0, 1);

  useEffect(() => {
    dispatch(resetToInitialState());
  }, [data, locationData.profileId]);

  useEffect(() => {
    onWalkThroughMounted();
  }, []);

  if (lodashIsEmpty(data)) {
    return null;
  }

  const onViewChanged = useCallback((id) => {
    dispatch(setSelectedViewId(id));
  }, []);

  if (!lowResList) {
    // console.log('loading');
    return <LoadingComponent />;
  }

  return (
    <>
      <ambientLight />
      {/* <React.Suspense fallback={<LoadingComponent />}> */}
      {/* <TextureLoader images={images}> */}
      <SphereComponent
        dumpingFactor={0.1}
        selectedViewId={selectedViewId}
        neighbors={anglesDictionary[selectedViewId]}
        onViewChanged={onViewChanged}
        lowResTextures={lowResList}
        textures={highResList}
      />
      {/* </TextureLoader> */}
      {/* </React.Suspense> */}
    </>
  );
};

WalkThrough.propTypes = {
  data: PropTypes.object,
  lowResList: PropTypes.any,
  highResList: PropTypes.any,
  onWalkThroughMounted: PropTypes.func,
};

export default React.memo(WalkThrough);
