import React from 'react';
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import T from 'i18n-react';
import { Modal } from 'antd';
import lodashGet from 'lodash/get';
import lodashLast from 'lodash/last';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsEqual from 'lodash/isEqual';
import lodashIncludes from 'lodash/includes';
import { editOperations } from 'utils/algorithms/editOperations';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { uuidv4 } from 'utils/helpers/uuidv4';
import { addHintsToEditSession, addOperationsToEditSession, beginEditSession, endEditSession, saveEditSessionResult } from 'utils/network/editSession';
import { FILTER_TYPES } from 'constants/testFitConsts';
import { MailLink } from 'styles/commonComponents.styles';
import { CONTACT_MAIL } from 'constants/globalConst';
import SwpProjectPatch from '@swapp/swappcommonjs/dist/swpProject/SwpProjectPatch';
import { editResultSuccessActionCreator } from './swappProfile/actions/swappProfileActionsCreators';
import { pollSwappProjectAction } from './swappProfile/actions/swappProfileActions';
import { massTransformerInitialState, massTransformerReducer } from './massTransformer';
import { handleReceivedSwpProjectPatch } from './activeProfile';

export const EDIT_TYPES = {
  TEST_FIT: 'TEST_FIT',
  FEASIBILITY: 'FEASIBILITY',
  VIEWS: 'VIEWS',
};

const initialState = {
  editSessionType: null, // the type of edit session.
  profileId: 0, // The profile id that is being edited. 0? Not editing.
  result: {}, // The result object after the current operation set
  operations: [], // The operations that have already been applied
  serverResult: {}, // The last result that was received from the server (as opposed to locally-defined results)
  serverOperations: [], // The last operations that were received from the server
  editorType: '', // The type of editor currently active
  selectedObject: null, // The object currently selected by the editor
  isSelectedObjectInEdit: false, // is The object currently selected by the editor and ready to edit
  isLoading: false, // Are we waiting for a preview update?
  lastOperationTime: 0, // The last time we performed an operation
  initialTypology: null, // the initial typology of the profile
  selectedRowKeys: [], // selected units row keys used mostly for filtering units by color
  selectedHighlightedItems: [], // selected Highlighted units/rooms
  selectedCategoryRowKeys: [], // selected category row keys used mostly for filtering units by color
  selectedDepartmentRowKeys: [], // selected category row keys used mostly for filtering units by color
  massTransformer: massTransformerInitialState,
  massInSketch: null,
};

const isOperationShouldSkipLoading = (operations, buildingInfo) => {
  if (operations.length === 1 && operations[0].name === 'TRANSFORM' && !buildingInfo?.parkingInfo?.parkings?.length) {
    return true;
  }
  return false;
};

const isOperationSetSuitableForLocalPreview = (operationsToAdd) => operationsToAdd.length === 1 && operationsToAdd[0]?.name in editOperations;
let historyForSaveOperation = null;

// the Thunk version of addOperations.
// It let us call the massTransformer slice without extra renders
export const addOperationsAsync = createAsyncThunk(
  'editor/addOperationsAsync',
  async (payload, thunkAPI) => {
    const { operations: operationsToAdd, buildingInfo, selectedObjectAfterAction } = payload;
    // Using the state from a thunk is considered as an anti-pattern, but because we don't
    // use it asynchronious and waiting for server response, it is ok to use it.
    const state = thunkAPI.getState();
    const outSelectedObject = { selected: null };

    addOperationsToEditSession(operationsToAdd, false);
    thunkAPI.dispatch({ type: 'editor/notifySentUpdateToServer' });
    if (isOperationSetSuitableForLocalPreview(operationsToAdd)) {
      const { name, parameters } = operationsToAdd[0];
      const result = editOperations[operationsToAdd[0].name](name, parameters, state.editor.result, { outSelectedObject });
      if (result) {
        thunkAPI.dispatch({ type: 'editor/updateResult', payload: result });
        thunkAPI.dispatch({ type: 'editor/initialize', payload: buildingInfo });
      } else if (!isOperationShouldSkipLoading(operationsToAdd, buildingInfo)) {
        thunkAPI.dispatch({ type: 'editor/updateLoading', payload: true });
      }
    } else if (!isOperationShouldSkipLoading(operationsToAdd, buildingInfo)) {
      thunkAPI.dispatch({ type: 'editor/updateLoading', payload: true });
    }

    const isSelectingObject = selectedObjectAfterAction && !lodashIsEqual(selectedObjectAfterAction, state.editor.selectedObject);
    if (isSelectingObject || outSelectedObject.selected) {
      thunkAPI.dispatch({ type: 'editor/selectObject', payload: selectedObjectAfterAction || [outSelectedObject.selected] });
    }

    const operationsGroupId = uuidv4();
    const newOperations = operationsToAdd.map((operations) => ({ ...operations, groupId: operationsGroupId }));

    thunkAPI.dispatch({ type: 'editor/updateOperations', payload: [...state.editor.operations, ...newOperations] });
    // state.operations = [...state.operations, ...newOperations];
  },
);

export const startEditing = createAsyncThunk(
  'editor/startEditing',
  async (payload, thunkAPI) => {
    const agent = thunkAPI.extra;
    const handleResultArrived = (newResult) => {
      if (newResult) {
        thunkAPI.dispatch({ type: 'editor/updateResultFromServer', payload: newResult });
      } else {
        // TODO TECH DEBT: Probably a code smell to open a modal dialog from the reducer.
        // Perhaps use the await dispatch + unwrap technique? https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-results
        Modal.info({
          width: 400,
          title: T.translate('Ooops, something went wrong'),
          content: (
            <div>
              {T.translate('try to check your parameters again, or contact us at:')}
              <MailLink href={`mailto:${CONTACT_MAIL}`} target="_blank" rel="noopener noreferrer">{CONTACT_MAIL}</MailLink>
            </div>
          ),
        });
        thunkAPI.dispatch({ type: 'editor/resetToLatestServerResult' });
      }
    };

    const handleConnectionFailed = () => {
      Modal.info({
        width: 400,
        title: T.translate('Connections problems'),
        content: (
          <div>
            {T.translate('Try again, or contact us at:')}
            <MailLink href={`mailto:${CONTACT_MAIL}`} target="_blank" rel="noopener noreferrer">{CONTACT_MAIL}</MailLink>
          </div>
        ),
      });
    };

    const handleProfileArrived = (newProfile) => {
      if (newProfile) {
        // Save action succeeded, need to finish editing, update state and browser location
        // Update the state with the updated profile
        thunkAPI.dispatch(editResultSuccessActionCreator(newProfile));

        // Navigate to the project view and poll for updated data
        const history = historyForSaveOperation;
        const locationData = parseLocationUrl(history.location);
        history.replace(`/${locationData.stage}/${locationData.projectId}/${locationData.stageTab}/${locationData.tabSideBar}`);
        thunkAPI.dispatch(pollSwappProjectAction(locationData.projectId, newProfile.id, history));

        // Leave the editor state of this slice
        thunkAPI.dispatch({ type: 'editor/stopEditing', payload: {} });
      } else {
        // TODO TECH DEBT: Probably a code smell to open a modal dialog from the reducer.
        // Perhaps use the await dispatch + unwrap technique? https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-results
        Modal.info({
          width: 400,
          title: T.translate('Ooops, something went wrong'),
          content: (
            <div>
              {T.translate('try to check your parameters again, or contact us at:')}
              <MailLink href={`mailto:${CONTACT_MAIL}`} target="_blank" rel="noopener noreferrer">{CONTACT_MAIL}</MailLink>
            </div>
          ),
        });

        thunkAPI.dispatch({ type: 'editor/resetToLatestServerResult' });
      }
      historyForSaveOperation = null;
    };

    const { profileId } = payload;
    beginEditSession(agent, profileId, handleResultArrived, handleConnectionFailed, handleProfileArrived);
    return payload;
  },
);

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    ...massTransformerReducer,
    stopEditing(state) {
      state.profileId = 0;
      state.editSessionType = null;
      state.editorType = '';
      state.isLoading = false;
      state.operations = [];
      state.selectedObject = null;
      state.lastOperationTime = 0;
      state.initialTypology = null;
      endEditSession();
    },
    updateResult(state, action) {
      state.result = action.payload;
    },
    updateLoading(state, action) {
      state.isLoading = action.payload;
    },
    updateOperations(state, action) {
      state.operations = action.payload;
    },
    setEditorType(state, action) {
      state.editorType = action.payload;
      state.selectedObject = null;
    },
    setEditorTypeAndObject(state, action) {
      state.editorType = action.payload.editorType;
      state.selectedObject = action.payload.object;
    },
    editMassInSketch(state, action) {
      state.massInSketch = action.payload;
    },
    selectObject(state, action) {
      state.selectedObject = action.payload;
    },
    toggleObjectSelection(state, action) {
      const selectedObject = action.payload;
      if (!Array.isArray(state.selectedObject)) {
        state.selectedObject = [selectedObject];
      } else {
        const objectIndex = state.selectedObject.findIndex((obj) => lodashIsEqual(obj, selectedObject));
        if (objectIndex >= 0) {
          state.selectedObject.splice(objectIndex, 1); // unselect
        } else {
          state.selectedObject.push(selectedObject); // select/add
        }
      }
    },
    // This is a workaround to clear "locally defined operations" (like transforms) through our central interface
    // TODO TECH DEBT: Move transforms to a state object (this one? a separate slice? TBD)
    markOperationsDirty(state) {
      state.lastOperationTime = Date.now();
    },
    addOperations(state, action) {
      const operationsToAdd = action.payload;
      state.isSelectedObjectInEdit = false;
      addOperationsToEditSession(operationsToAdd, false);
      if (isOperationSetSuitableForLocalPreview(operationsToAdd)) {
        const { name, parameters, localViewParameters } = operationsToAdd[0];
        const { profileId } = state;
        const result = editOperations[operationsToAdd[0].name](name, parameters, state.result, { ...localViewParameters, profileId });
        if (result) {
          state.result = result;
        } else if (state.editSessionType !== EDIT_TYPES.VIEWS) {
          state.isLoading = true;
        }
      } else if (state.editSessionType !== EDIT_TYPES.VIEWS) {
        state.isLoading = true;
      }

      const operationsGroupId = uuidv4();
      const newOperations = operationsToAdd.map((operations) => ({ ...operations, groupId: operationsGroupId }));

      state.operations = [...state.operations, ...newOperations];
    },
    undoLastOperation(state) {
      if (lodashIsEmpty(state.operations)) {
        return;
      }

      const lastOperationGroupId = lodashLast(state.operations).groupId;
      const newOperations = state.operations.filter((e) => e.groupId !== lastOperationGroupId);
      state.operations = newOperations;
      state.isLoading = true;
      // newOperations comes from the redux state and therefore, because of immerjs, it is a Proxy
      // editSession may use this later, so we need to extract the current state of it as a plain javascript object
      // The current function does this.
      addOperationsToEditSession(newOperations.map(current), true);
    },
    // Private API
    updateResultFromServer(state, action) {
      const serverResult = action.payload;
      // Backward compatibility hack: ideally the websocket protocol would mention what the result type is and we
      // would condition on that. However, to maintain backward compatibility with the previous protocol, we just check if the
      // field names match SwpProjectPatch or not.
      if (SwpProjectPatch.isObjectPatch(serverResult)) {
        const patch = new SwpProjectPatch();
        patch.loadFromObject(serverResult);
        handleReceivedSwpProjectPatch(patch);
      } else {
        state.serverResult = serverResult;
        state.result = serverResult;
      }
      state.serverOperations = [...state.operations];
      state.isLoading = false;
      state.lastOperationTime = Date.now();
    },
    resetToLatestServerResult(state) {
      state.result = state.serverResult;
      state.operations = [...state.serverOperations];
      state.isLoading = false;
      state.selectedObject = null;
      state.lastOperationTime = Date.now();
      state.massInSketch = null;
    },
    addHints(state, action) {
      const hints = action.payload;
      addHintsToEditSession(hints);
    },
    setIsSelectedObjectInEdit(state, action) {
      state.isSelectedObjectInEdit = action.payload;
    },
    saveResult(state, action) {
      const { saveAsCopy, history } = action.payload;
      state.isLoading = true;
      historyForSaveOperation = history;
      saveEditSessionResult(saveAsCopy);
    },
    saveAndContinueEditing() {
      saveEditSessionResult(false, true);
    },
    addSelectedHighlightedItems(state, action) {
      if (!lodashIncludes(state.selectedHighlightedItems, action.payload)) {
        state.selectedHighlightedItems.push(action.payload);
      }
    },
    setSelectedRowKeys(state, action) {
      state.selectedHighlightedItems.length = 0;
      const { list, filterType } = action.payload;
      if (filterType === FILTER_TYPES.CATEGORY) {
        state.selectedRowKeys.length = 0; // deselect list
        state.selectedCategoryRowKeys = list;
      } else if (filterType === FILTER_TYPES.ROOM) {
        state.selectedRowKeys = list;
        state.selectedCategoryRowKeys.length = 0; // deselect list
      } else if (filterType === FILTER_TYPES.DEPARTMENT) {
        state.selectedDepartmentRowKeys = list;
      }
    },
    clearSelectedRowKeys(state) {
      state.selectedHighlightedItems.length = 0;
      state.selectedRowKeys.length = 0;
      state.selectedCategoryRowKeys.length = 0;
      state.selectedDepartmentRowKeys.length = 0;
    },
  },
  extraReducers: {
    [startEditing.fulfilled]: (state, action) => {
      const { profileId, result, editorType = '', editSessionType } = action.payload;
      state.profileId = profileId;
      state.result = result;
      state.serverResult = result;
      state.editSessionType = editSessionType;
      state.editorType = editorType;
      state.operations = [];
      state.serverOperations = [];
      state.selectedObject = null;
      state.initialTypology = lodashGet(result, 'study.typology');
    },
    [startEditing.rejected]: (state) => {
      state.selectedObject = null;
    },
  },
});

export const { stopEditing, setEditorType, selectObject, markOperationsDirty, toggleObjectSelection, addOperations, setIsSelectedObjectInEdit,
  undoLastOperation, addHints, saveResult, setEditorTypeAndObject, setSelectedRowKeys, clearSelectedRowKeys, addSelectedHighlightedItems, editMassInSketch, saveAndContinueSession, saveAndContinueEditing } = editorSlice.actions;

const { ...massTransformerActions } = editorSlice.actions;
export const MassTransformer = {
  ...massTransformerActions,
};

export default editorSlice.reducer;

export const isObjectSelected = (selectedObject, obj) => {
  if (Array.isArray(selectedObject)) {
    return selectedObject.some((o) => lodashIsEqual(o, obj));
  }
  return lodashIsEqual(obj, selectedObject);
};

export const numSelectedObjects = (selectedObject) => {
  if (Array.isArray(selectedObject)) {
    return selectedObject.length;
  }
  return selectedObject ? 1 : 0;
};

export const isEditingSelector = ({ editor }) => editor.profileId !== 0;

export const isTestFitItemSelectedSelector = ({ editor }, id) => lodashIncludes(Array.isArray(editor.selectedObject) ? editor.selectedObject.map((e) => e.id) : editor.selectedObject?.id, id);

export const isEditorResultSyncedWithServerSelector = ({ editor }) => lodashIsEqual(editor.operations, editor.serverOperations);
