export const formInputsData = {
  wwr: {
    defaultValue: 0.5,
  },
  vintage: {
    data:
    [
      { key: '2004', value: 'ASHRAE 90.1 2004' },
      { key: '2007', value: 'ASHRAE 90.1 2007' },
      { key: '2010', value: 'ASHRAE 90.1 2010' },
      { key: '2013', value: 'ASHRAE 90.1 2013' },
      { key: '2016', value: 'ASHRAE 90.1 2016' },
      { key: '2019', value: 'ASHRAE 90.1 2019' },
    ],
    defaultValue: '2013',
  },
  hvacSystem: {
    data:
    [
      { key: 'PSZHP', value: 'PSZHP' },
      { key: 'VRF', value: 'VRF' },
    ],
    defaultValue: 'PSZHP',
  },
  buildingProgram: {
    data:
    [
      { key: 'MidriseApartment', value: 'Midrise Apartment' },
      { key: 'MediumOffice', value: 'Medium Office' },
    ],
    defaultValue: 'MidriseApartment',
  },
  sensibleHeatRecovery: {
    data:
    [
      { key: 0, value: 'None' },
      { key: 0.5, value: '0.5' },
      { key: 0.6, value: '0.6' },
      { key: 0.7, value: '0.7' },
    ],
    defaultValue: 0,
  },
  latentHeatRecovery: {
    data:
    [
      { key: 0, value: 'None' },
      { key: 0.5, value: '0.5' },
      { key: 0.6, value: '0.6' },
      { key: 0.7, value: '0.7' },
    ],
    defaultValue: 0,
  },
  constructionType: {
    data:
    [
      { key: 'SteelFramed', value: 'Steel Framed' },
      { key: 'WoodFramed', value: 'Wood Framed' },
      { key: 'Mass', value: 'Mass' },
      { key: 'Metal Building', value: 'Metal Building' },
    ],
    defaultValue: 'SteedFramed',
  },
  lightingWattsPerArea: {
    description: 'key is a multiplier, not an actual value',
    data:
    [
      { key: 1, value: 'Default' },
      { key: 0.5, value: 'Default / 2' },
    ],
    defaultValue: 1,
  },
  equipmentWattsPerArea: {
    description: 'key is a multiplier, not an actual value',
    data:
    [
      { key: 1, value: 'Default' },
      { key: 0.5, value: 'Default / 2' },
    ],
    defaultValue: 1,
  },
  serviceHotWater: {
    data:
    [
      { key: 'Gas_WaterHeater', value: 'Gas Water Heater' },
      { key: 'Electric_WaterHeater', value: 'Electric Water Heater' },
      { key: 'HeatPump_WaterHeater', value: 'Heat Pump Water Heater' },
      { key: 'Gas_TanklessHeater', value: 'Gas Tankless Heater' },
      { key: 'Electric_TanklessHeater', value: 'Electric Tankless Heater' },
    ],
    defaultValue: 'Electric_WaterHeater',
  },
};
