import React, { useContext, useState } from 'react';
import T from 'i18n-react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Affix } from 'antd';
import Icon from '@ant-design/icons';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIncludes from 'lodash/includes';
import { useHistory } from 'react-router';
import { ColorPill, InputPrefixIcon } from 'styles/commonComponents.styles';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import { PROJECT_STAGES, PROJECT_STATUS } from 'constants/profileConsts';
import { useComDidMount } from 'utils/hooks';
import newProjectPlusIcon from 'styles/static/icons/comon/newProjectPlusIcon';
import { contentRefContexts } from 'utils/hooks/reactContexts';
import { useOverridableSetting } from 'store/userSettings';
import { clearSwappProjectAction } from 'store/swappProfile/actions/swappProfileActions';
import {
  FloorListWrapper,
  SearchInput,
  Header,
  FiltersWrapper,
  FormItem,
  // ButtonWrapper,
  NewProjectButtonCircle,
  StageOption,
  ModalWrapper,
  ModalTitle,
  ModalButtonsWrapper,
  ModalButtonWrapper,
  ModalButton,
  ModalButtonText,
} from './ProjectListContainer.styles';
import ProjectCards from './ProjectCards';
import { userProjectsSelector, isProjectsLoadingSelector } from '../../store/swappProfiles';
import EmptyProjectList from './EmptyProjectList';
import LoadingSpinner from '../common/LoadingSpinner';
import MultiDropdown from '../common/MultiDropdown';
import Modal from '../common/Modal';
import groundUpIcon from '../../styles/static/icons/comon/groundUpIcon';
import renovationIcon from '../../styles/static/icons/comon/renovationIcon';

const { PROJECT_SETTINGS, FEASIBILITY_STUDY, TEST_FIT } = UI_AUTHORIZE_PATH;
const keysToSearch = ['floor', 'name', 'locationName'];
const defaultStages = [
  UI_AUTHORIZE_PATH.TEST_FIT,
  UI_AUTHORIZE_PATH.FEASIBILITY_STUDY,
];

const ProjectListContainer = () => {
  const history = useHistory();
  const [filterValue, setFilterValue] = useState('');
  const [isCreateNewProjectModalOpen, setIsCreateNewProjectModalOpen] = useState(false);
  const [status, setStatus] = useState([PROJECT_STATUS.OPEN, PROJECT_STATUS.REPOSITORY]);
  const [stages, setStages] = useState(defaultStages);
  const [isNewProjectButtonSticky, setIsNewProjectButtonSticky] = useState(false);
  const userProjects = useSelector(userProjectsSelector, shallowEqual);
  const loading = useSelector(isProjectsLoadingSelector);
  const userStages = useOverridableSetting('stages', []);
  const isReadOnlyFlag = useOverridableSetting('readOnly', false);
  const isReadOnly = (userStages.length === 0) || isReadOnlyFlag;
  const contentRef = useContext(contentRefContexts);
  const dispatch = useDispatch();

  useComDidMount(() => {
    dispatch(clearSwappProjectAction());
  });

  const toggleModal = () => {
    setIsCreateNewProjectModalOpen(!isCreateNewProjectModalOpen);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  const getFilteredProjects = () => {
    const filteredProjects = userProjects
      .filter((item) => lodashIncludes(stages.map((stage) => PROJECT_STAGES[stage]).join(' '), item.stage))
      .filter((item) => lodashIncludes(status.join(' '), item.status))
      .filter((item) => {
        if (filterValue === '') {
          return true;
        }
        let stringToSearch = `${T.translate('SWAPP_CART_LIST_FLOOR')} `;
        keysToSearch.forEach((key) => {
          stringToSearch += `${item[key]} `;
        });
        return lodashIncludes(stringToSearch.toLowerCase(), filterValue.toLowerCase());
      });

    filteredProjects.push({ newProjectCard: true });

    return filteredProjects;
  };

  const renderModalButton = (name, onClick, icon) => (
    <ModalButtonWrapper onClick={onClick}>
      <ModalButton><Icon component={icon} /></ModalButton>
      <ModalButtonText>{name}</ModalButtonText>
    </ModalButtonWrapper>
  );

  const renderModal = () => {
    if (userStages.length === 0 || !isCreateNewProjectModalOpen) {
      return;
    }

    if (userStages.length === 1) {
      history.replace(`/${PROJECT_SETTINGS}/${UI_AUTHORIZE_PATH[userStages[0]]}`);
      return;
    }

    return (
      <Modal toggleModal={toggleModal} isModalOpen={isCreateNewProjectModalOpen} width={500} height={235}>
        <ModalWrapper>
          <ModalTitle>{T.translate('SWAPP_CART_LIST_CREATE_NEW_PROJECT')}</ModalTitle>
          <ModalButtonsWrapper>
            {renderModalButton(T.translate('SWAPP_CART_LIST_RENOVATION'), () => history.replace(`/${PROJECT_SETTINGS}/${TEST_FIT}`), renovationIcon)}
            {renderModalButton(T.translate('SWAPP_CART_LIST_GROUND_UP'), () => history.replace(`/${PROJECT_SETTINGS}/${FEASIBILITY_STUDY}`), groundUpIcon)}
          </ModalButtonsWrapper>
        </ModalWrapper>
      </Modal>
    );
  };

  const renderProjectsPage = () => (
    <FloorListWrapper>
      {/* NOTE: <Affix> use as a hack to show <NewProjectButtonCircle> */}
      <Affix
        offsetTop={0}
        target={() => contentRef}
        onChange={(value) => setIsNewProjectButtonSticky(value)}
      >
        <div style={{ position: 'fixed', top: '650px' }} />
      </Affix>
      {/* =========== New Project Button Circle =========== */}
      <NewProjectButtonCircle isHidden={!isNewProjectButtonSticky} onClick={() => (isReadOnly || !isNewProjectButtonSticky ? null : toggleModal())}>
        <Icon component={newProjectPlusIcon} />
      </NewProjectButtonCircle>

      <Header>
        <FiltersWrapper>
          {/* =========== search =========== */}
          <FormItem width={400} label={T.translate('FLOOR_LIST_FILTER_SEARCH_LABEL')}>
            <SearchInput onChange={(e) => setFilterValue(e.target.value)} prefix={<InputPrefixIcon />} placeholder={T.translate('FLOOR_LIST_SEARCH_PLACEHOLDER')} />
          </FormItem>

          {/* =========== stage =========== */}
          <FormItem width={290} label={T.translate('FLOOR_LIST_FILTER_STAGE_LABEL')}>
            <MultiDropdown
              data={defaultStages.map((currentStage) => (
                { title: <StageOption><ColorPill stage={currentStage} margin="3px 8px 0 0" height={16} />{T.translate(currentStage)}</StageOption>, value: currentStage }
              ))}
              defaultValue={defaultStages}
              placeholder={T.translate('FLOOR_LIST_FILTER_STAGE_PLACEHOLDER')}
              maxTagCount={2}
              onChange={(value) => setStages(value)}
            />
          </FormItem>

          {/* =========== status =========== */}
          <FormItem width={200} label={T.translate('FLOOR_LIST_FILTER_STATUS_LABEL')}>
            <MultiDropdown
              data={[
                { title: T.translate(`STATUS.${PROJECT_STATUS.OPEN}`), value: PROJECT_STATUS.OPEN },
                { title: T.translate(`STATUS.${PROJECT_STATUS.REPOSITORY}`), value: PROJECT_STATUS.REPOSITORY },
                { title: T.translate(`STATUS.${PROJECT_STATUS.ARCHIVED}`), value: PROJECT_STATUS.ARCHIVED },
              ]}
              defaultValue={status}
              placeholder={T.translate('FLOOR_LIST_FILTER_STATUS_PLACEHOLDER')}
              maxTagCount={2}
              onChange={(value) => setStatus(value)}
            />
          </FormItem>
        </FiltersWrapper>

        {/* =========== Button =========== */}
        {/* <ButtonWrapper> */}
        {/*  <PrimaryButton onClick={() => (isReadOnly ? null : toggleModal())} disabled={isReadOnly} height={38} width={268}><PlusOutlined /> {T.translate('FLOOR_LIST_NEW_FLOOR_BUTTON')}</PrimaryButton> */}
        {/* </ButtonWrapper> */}
      </Header>

      {/* =========== Cards =========== */}
      <ProjectCards key={Math.random()} userProjects={getFilteredProjects()} toggleModal={toggleModal} isReadOnly={isReadOnly} />
    </FloorListWrapper>
  );

  return (
    <>
      {lodashIsEmpty(userProjects)
        ? <EmptyProjectList toggleModal={toggleModal} />
        : renderProjectsPage()}
      {renderModal()}
    </>
  );
};

ProjectListContainer.defaultProps = {
  userProjects: [],
};

export default ProjectListContainer;
