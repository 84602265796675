import styled from 'styled-components';

export const ForgeWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  border-top-left-radius: 7px;
  overflow: hidden;
`;

export const DisplayToggleWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
  display: flex;
`;

export const ContentWrapper = styled.div`
  display: flex;
  height: calc(100vh -  ${({ theme }) => `${theme.other.headerHeight}px`} - 50px);
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;
