import React from 'react';

// currentcolor
export default () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.375" y="16.235" width="19.25" height="4.53" stroke="currentcolor" strokeWidth="0.75" />
    <rect x="1.375" y="6.99506" width="19.25" height="4.53" stroke="currentcolor" strokeWidth="0.75" />
    <rect x="1.375" y="2.375" width="19.25" height="4.53" stroke="currentcolor" strokeWidth="0.75" />
    <rect x="1.375" y="11.475" width="19.25" height="4.85" stroke="currentcolor" strokeWidth="0.75" />
    <circle cx="11" cy="11.5" r="6" fill="white" />
    <path d="M10.9999 7.25C11.142 7.25 11.2783 7.30644 11.3788 7.40691C11.4792 7.50737 11.5357 7.64363 11.5357 7.78571V11H14.7499C14.892 11 15.0283 11.0564 15.1288 11.1569C15.2292 11.2574 15.2857 11.3936 15.2857 11.5357C15.2857 11.6778 15.2292 11.8141 15.1288 11.9145C15.0283 12.015 14.892 12.0714 14.7499 12.0714H11.5357V15.2857C11.5357 15.4278 11.4792 15.5641 11.3788 15.6645C11.2783 15.765 11.142 15.8214 10.9999 15.8214C10.8579 15.8214 10.7216 15.765 10.6211 15.6645C10.5207 15.5641 10.4642 15.4278 10.4642 15.2857V12.0714H7.24995C7.10787 12.0714 6.97161 12.015 6.87114 11.9145C6.77067 11.8141 6.71423 11.6778 6.71423 11.5357C6.71423 11.3936 6.77067 11.2574 6.87114 11.1569C6.97161 11.0564 7.10787 11 7.24995 11H10.4642V7.78571C10.4642 7.64363 10.5207 7.50737 10.6211 7.40691C10.7216 7.30644 10.8579 7.25 10.9999 7.25V7.25Z" fill="currentcolor" />
  </svg>
);
