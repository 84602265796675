import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashIsEmpty from 'lodash/isEmpty';
import ApexCharts from 'react-apexcharts';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { currentThemeSelector } from 'store/userSettings';

const DonutChartWrapper = styled.div`
  margin-top: 15px;
  .apexcharts-canvas {
    position: absolute;
    left: 0;
  }

  .apexcharts-legend-series {
    margin: 2px !important;
  }
  
  .apexcharts-legend {
      width: 180px;
      padding-left: 20px;
  }

  .apexcharts-legend.apx-legend-position-right, .apexcharts-legend.apx-legend-position-left {
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
    left: 210px !important;
    width: 320px !important;
  }
  
  .apexcharts-legend.position-bottom.apexcharts-align-left, .apexcharts-legend.position-top.apexcharts-align-left, .apexcharts-legend.position-right, .apexcharts-legend.position-left {
    //left: 210px !important;
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
    left: 210px !important;
    width: 320px !important;
  }
  
  .apexcharts-pie-area {
    &:hover {
      filter: none !important;
      fill-opacity: 0.8 !important;
    }
  }
`;

const DonutChart = (props) => {
  const { data, colorSet, colorSetKey, color, type = 'donut', showTotal, disableAnimation } = props;
  const currentTheme = useSelector(currentThemeSelector);

  const colorSets = {
    RSF: [
      currentTheme.colors.layers.LSF.MAIN,
      currentTheme.colors.layers.PSF.MAIN,
      currentTheme.colors.layers.ASF.MAIN,
      currentTheme.colors.layers.FSF.MAIN,
      currentTheme.colors.layers.OTHER.leftover,
      '#9d9d9d',
      '#b5b5b5',
      '#d1d1d1',
    ],
  };

  // if the chart dont have any colors it will crash the system
  if ((!colorSetKey && lodashIsEmpty(colorSet.filter((e) => e))) || !colorSets[colorSetKey]) {
    return null;
  }

  // Set default options
  const options = {
    stroke: {
      width: 1,
    },
    chart: {
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: !disableAnimation,
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    labels: [],
    tooltip: {
      enabled: false,
      y: {
        formatter: (value) => `${Math.round(value * 10) / 10}%`,
      },
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
        },
        legend: {
          position: 'bottom',
        },
      },
    }],
  };

  // Set colors, if no color was pass the default is colorSet.Blue
  if (color) {
    options.theme = {
      monochrome: {
        enabled: true,
        color,
        shadeTo: 'light',
        shadeIntensity: 0.85,
      },
    };
  } else {
    options.colors = colorSet || colorSets[colorSetKey] || colorSets.RSF;
  }

  if (showTotal) {
    options.dataLabels = {
      formatter: (val, opts) => opts.w.globals.series[opts.seriesIndex],
    };
    options.plotOptions = {
      pie: {
        donut: {
          labels: {
            show: true, name: { color: '#000' }, total: { showAlways: true, show: true },
          },
        },
      },
    };
  }

  const series = [];

  // parse the data in to 2 arrays
  data.forEach((item) => {
    series.push(item.value);
    options.labels.push(T.translate(item.label));
  });

  return <DonutChartWrapper><ApexCharts options={options} series={series} type={type} width={570} /></DonutChartWrapper>;
};

DonutChart.propTypes = {
  // Data for the chart, need's to be an array of object with property's of 'value' and 'label'
  data: PropTypes.array.isRequired,
  // If 'color' wasn't passed default is 'DonutChart.colorSets.BLUE', FE: colorSet={DonutChart.colorSets.RED}
  colorSet: PropTypes.array,
  colorSetKey: PropTypes.string,
  // Set the chart with a hex color and changing it to be lighter for each value, FE: '#5772FF'
  color: PropTypes.string,
  // chart Type
  type: PropTypes.string,
  showTotal: PropTypes.bool,
  disableAnimation: PropTypes.bool,
};

export default React.memo(DonutChart);
