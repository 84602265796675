import React from 'react';

export default () => (
  <svg width="116" height="28" viewBox="0 0 116 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M34.7879 1.01387C34.4894 1.06325 33.788 1.21139 33.483 1.29083C33.0278 1.40678 32.1738 1.67946 32.1738 1.70952C32.1738 1.72025 32.2043 1.86625 32.2392 2.03587C32.7315 4.32037 33.8468 6.51255 35.4283 8.29893C35.6897 8.59737 36.3563 9.25653 36.6308 9.49271C37.8354 10.5276 39.1468 11.3392 40.5387 11.9082C40.676 11.964 40.7914 12.0069 40.7936 12.0048C40.7979 12.0026 40.8306 11.8545 40.8655 11.6784C41.0005 11.0214 41.2184 10.2742 41.4536 9.6473C41.5255 9.45836 41.5843 9.29518 41.5843 9.28444C41.5843 9.27371 41.5713 9.26512 41.556 9.26512C41.4928 9.26512 40.4189 8.71546 40.0987 8.52008C38.9463 7.81583 37.9595 6.92909 37.0838 5.81904C36.5066 5.08689 35.9598 4.14217 35.596 3.24898C35.3542 2.65638 35.0972 1.78896 34.9926 1.21783C34.9491 0.977365 34.9556 0.985953 34.7879 1.01387Z" fill="black" />
    <path d="M47.4271 5.91601C47.2375 5.68351 46.7636 5.15272 46.5443 4.92929C46.2191 4.59444 45.5605 3.99339 45.5339 4.00808C45.5244 4.01333 45.41 4.1109 45.2769 4.22376C43.4954 5.76353 42.1 7.79392 41.2916 10.0269C41.1552 10.3975 40.8975 11.2929 40.8225 11.6443C40.4952 13.186 40.4176 14.7103 40.5942 16.1853C40.6119 16.3306 40.6303 16.4509 40.6333 16.4517C40.6373 16.4544 40.7844 16.4101 40.9573 16.354C41.6048 16.1491 42.3727 15.9713 43.0426 15.8672C43.245 15.8367 43.4181 15.8075 43.4276 15.8023C43.4371 15.797 43.4384 15.7816 43.4309 15.7685C43.4 15.7142 43.3618 14.522 43.3782 14.1512C43.4384 12.8161 43.7411 11.5342 44.2957 10.2387C44.6617 9.38445 45.2308 8.45259 45.8436 7.70326C46.25 7.20574 46.8922 6.56079 47.3466 6.19177C47.5382 6.03679 47.5338 6.04661 47.4271 5.91601Z" fill="#FF6814" />
    <path d="M28.3613 3.8027C27.7317 4.318 27.1523 4.85692 26.7036 5.35075L26.4443 5.63631L26.9192 6.10223C27.662 6.83224 28.1718 7.46993 28.6902 8.32017C28.8645 8.60788 29.2413 9.34219 29.3546 9.61272C29.4526 9.85534 29.42 9.84031 29.775 9.78878C30.3218 9.70934 30.9143 9.57837 31.4567 9.41304C31.7247 9.3336 32.217 9.16398 32.2366 9.14465C32.2649 9.11889 31.875 8.22355 31.607 7.69752C30.9034 6.32553 29.9842 5.0931 28.8601 4.01526C28.6793 3.84349 28.5225 3.70393 28.5072 3.70608C28.492 3.70608 28.4266 3.74902 28.3613 3.8027Z" fill="black" />
    <path d="M41.853 1.85589C41.0892 1.56635 40.3291 1.33143 39.6726 1.18687L39.2932 1.10352L39.1128 1.73957C38.8297 2.73509 38.5143 3.48506 38.0151 4.3464C37.8456 4.63686 37.3798 5.31977 37.1957 5.54939C37.0288 5.75225 37.0261 5.71681 37.2453 5.99696C37.5828 6.42833 37.9883 6.87384 38.3997 7.25949C38.6009 7.45107 38.9917 7.79153 39.0183 7.79895C39.055 7.81071 39.657 7.03786 39.9916 6.55038C40.8622 5.27485 41.5039 3.88207 41.9087 2.38874C41.9723 2.14932 42.0192 1.94625 42.0099 1.93419C42.0024 1.92107 41.9325 1.88586 41.853 1.85589Z" fill="#9D9C9C" />
    <path d="M49.5453 9.5566C48.992 9.7477 48.2513 9.92805 47.6544 10.0182C46.6807 10.1642 45.487 10.1857 44.5895 10.0698C44.4152 10.0461 44.3586 10.0461 44.3455 10.0655C44.3085 10.1213 44.0645 10.7546 43.9687 11.0466C43.912 11.2184 43.8183 11.5426 43.7595 11.7702C43.6571 12.1739 43.5461 12.7514 43.5657 12.7729C43.5853 12.7901 44.3499 12.891 44.7638 12.9275C45.2604 12.9747 46.4956 12.9812 46.9639 12.9404C48.1729 12.8373 49.188 12.6398 50.2489 12.3027C50.5321 12.2125 50.5582 12.1996 50.5582 12.1524C50.5582 12.073 50.4123 11.3945 50.3186 11.0381C50.153 10.4111 49.835 9.47716 49.7871 9.48146C49.774 9.48146 49.6651 9.51581 49.5453 9.5566Z" fill="black" />
    <path d="M47.0768 22.7814C46.6373 22.399 46.1157 21.8503 45.7442 21.3811C45.1391 20.6151 44.5367 19.599 44.2008 18.7706C44.1365 18.6092 44.1088 18.5605 44.0854 18.5587C44.0178 18.5541 43.338 18.6539 43.0327 18.7141C42.8529 18.7494 42.5202 18.8273 42.29 18.8879C41.8827 18.9971 41.3171 19.1838 41.3077 19.2112C41.3021 19.2364 41.5864 19.9433 41.7564 20.317C41.9573 20.7672 42.5552 21.8325 42.8201 22.2153C43.5022 23.2046 44.1731 23.981 44.9899 24.7285C45.2081 24.928 45.2323 24.9441 45.2741 24.9211C45.3444 24.8822 45.8737 24.4252 46.1434 24.1704C46.6174 23.7217 47.2887 22.9918 47.2615 22.9527C47.2551 22.9414 47.1715 22.8645 47.0768 22.7814Z" fill="#FF6814" />
    <path d="M32.5649 12.015C31.8809 12.2362 31.199 12.4036 30.5194 12.5174C30.3342 12.5475 30.1774 12.5775 30.1687 12.5818C30.1622 12.5861 30.1709 12.7085 30.1883 12.8545C30.234 13.2582 30.2319 14.5657 30.1839 14.9973C29.953 17.037 29.2146 18.9093 28.0187 20.4896C27.5416 21.1187 26.8271 21.8615 26.2324 22.3489C26.0603 22.4885 25.9209 22.6087 25.9209 22.6152C25.9209 22.6388 26.4459 23.27 26.6855 23.532C26.9404 23.8132 27.7681 24.6227 27.8378 24.6592C27.8662 24.6742 27.9337 24.6291 28.1559 24.4423C29.5021 23.3087 30.6087 21.9582 31.4474 20.4187C31.8787 19.6329 32.2708 18.6924 32.5279 17.8422C32.9984 16.292 33.1879 14.5464 33.0637 12.9039C33.042 12.5861 32.9831 12.0558 32.9614 11.957L32.9461 11.8926L32.5649 12.015Z" fill="black" />
    <path d="M36.638 9.48486C36.108 9.00474 35.6265 8.50026 35.1937 7.97142C35.0766 7.82688 34.9733 7.7067 34.9652 7.7013C34.9583 7.69778 34.8542 7.76508 34.7335 7.85142C34.3985 8.08802 33.24 8.72514 32.8346 8.89483C30.9163 9.69307 28.8981 9.97299 26.9149 9.71684C26.1249 9.61403 25.1182 9.36265 24.3961 9.08944C24.1885 9.00965 24.014 8.94852 24.0082 8.95167C23.9873 8.96321 23.6851 9.72315 23.5704 10.0572C23.446 10.4138 23.134 11.5212 23.1357 11.599C23.1363 11.6307 23.2092 11.6667 23.4831 11.7665C25.1445 12.3701 26.8807 12.6618 28.6532 12.6306C29.5596 12.6175 30.5836 12.4951 31.4619 12.3006C33.064 11.9477 34.7017 11.2576 36.0949 10.3482C36.3656 10.1741 36.8063 9.86446 36.883 9.79746L36.9326 9.75287L36.638 9.48486Z" fill="#9D9C9C" />
    <path d="M44.6447 15.6627C43.072 15.7679 41.7846 16.0492 40.3969 16.5924C39.4385 16.9681 38.5693 17.4104 37.7698 17.9279C37.4126 18.1619 36.7656 18.6278 36.7765 18.6472C36.7787 18.6536 36.8702 18.7352 36.9769 18.8297C37.4888 19.272 38.0879 19.8774 38.5083 20.3799C38.7283 20.6418 38.7675 20.6804 38.8002 20.659C38.8198 20.6439 38.9723 20.5409 39.1379 20.4271C39.5517 20.1415 39.9918 19.886 40.495 19.637C41.7758 19.0079 43.2593 18.6042 44.7035 18.4947C45.1414 18.4625 46.2611 18.4818 46.6706 18.5291C47.6705 18.645 48.6289 18.8769 49.5242 19.2204C49.79 19.3213 49.8314 19.3321 49.8532 19.3042C49.8989 19.2398 50.2279 18.2285 50.3281 17.842C50.4348 17.4212 50.5807 16.7384 50.5807 16.6482C50.5807 16.5945 50.5633 16.5838 50.2126 16.4614C49.0646 16.0556 47.958 15.8152 46.6924 15.6928C46.3025 15.6541 45.0434 15.6369 44.6447 15.6627Z" fill="black" />
    <path d="M39.378 21.6122C38.5163 20.3111 37.6382 19.3415 36.4793 18.4137C35.6783 17.7731 34.862 17.2417 34.0133 16.8071C33.6316 16.6142 32.903 16.2857 32.8912 16.3045C32.8866 16.3095 32.8591 16.4281 32.8276 16.566C32.6863 17.2224 32.4431 18.0334 32.2038 18.6405C32.0795 18.9577 32.0644 19.0103 32.0994 19.0279C32.1223 19.0374 32.288 19.1182 32.4696 19.2049C32.9247 19.4213 33.3659 19.6748 33.8322 19.9858C35.015 20.7799 36.0973 21.8582 36.9 23.0467C37.1425 23.4075 37.6726 24.3798 37.8309 24.7551C38.2169 25.6715 38.48 26.609 38.6135 27.5468C38.654 27.8246 38.6647 27.8656 38.7001 27.8707C38.7795 27.8785 39.8353 27.6672 40.2264 27.5645C40.6511 27.4506 41.3268 27.2425 41.4066 27.1984C41.4541 27.1722 41.4551 27.1519 41.392 26.7905C41.1902 25.605 40.8623 24.5359 40.3521 23.3877C40.1958 23.0335 39.5957 21.9424 39.378 21.6122Z" fill="#9D9C9C" />
    <path d="M34.5347 20.8439C33.452 22.1901 32.6787 23.6136 32.1407 25.239C31.9272 25.8874 31.6636 26.9781 31.7093 27.0232C31.742 27.0554 32.4478 27.3109 32.8225 27.4269C33.4934 27.633 34.4345 27.8541 34.4497 27.8069C34.4541 27.794 34.4824 27.648 34.5129 27.4827C34.8113 25.8552 35.5258 24.2363 36.5344 22.903C36.6281 22.7784 36.7021 22.6646 36.6956 22.6517C36.6803 22.6088 36.3362 22.1901 36.1074 21.9346C35.7393 21.5202 35.0335 20.8525 34.7656 20.6657L34.7089 20.627L34.5347 20.8439Z" fill="black" />
    <path d="M29.8513 15.451C28.1306 15.1779 26.4927 15.2086 24.791 15.5402C24.1127 15.6735 23.0185 15.9798 23.0009 16.0412C22.9884 16.085 23.1071 16.8168 23.1876 17.1957C23.333 17.8734 23.5972 18.7907 23.6464 18.7807C23.6599 18.7782 23.803 18.7312 23.9643 18.6766C25.5507 18.1379 27.3329 17.9612 29.006 18.1769C29.162 18.1966 29.2989 18.2047 29.3071 18.1927C29.3377 18.1586 29.5401 17.6581 29.6545 17.3365C29.8414 16.8174 30.0875 15.8842 30.1219 15.5625L30.1284 15.4949L29.8513 15.451Z" fill="#9D9C9C" />
    <path d="M88.5103 13.0018V2.99902H66V13.456L72.6326 15.9241" stroke="black" strokeWidth="1.67593" strokeMiterlimit="10" />
    <path d="M74.1001 16.3709C74.1155 16.1869 73.9821 16.0249 73.8022 16.0091C73.6223 15.9932 73.4639 16.1296 73.4485 16.3137C73.433 16.4977 73.5663 16.6597 73.7463 16.6755C73.9262 16.6914 74.0846 16.555 74.1001 16.3709Z" fill="black" stroke="black" strokeWidth="0.788673" strokeMiterlimit="10" />
    <path d="M75.7328 16.994C75.7483 16.8099 75.615 16.6479 75.4351 16.6321C75.2551 16.6163 75.0968 16.7527 75.0813 16.9367C75.0658 17.1208 75.1991 17.2828 75.3791 17.2986C75.559 17.3144 75.7174 17.1781 75.7328 16.994Z" fill="black" stroke="black" strokeWidth="0.788673" strokeMiterlimit="10" />
    <path d="M77.3696 17.6229C77.385 17.4388 77.2517 17.2768 77.0718 17.261C76.8919 17.2452 76.7335 17.3816 76.718 17.5656C76.7026 17.7497 76.8359 17.9117 77.0158 17.9275C77.1957 17.9433 77.3541 17.8069 77.3696 17.6229Z" fill="black" stroke="black" strokeWidth="0.788673" strokeMiterlimit="10" />
    <path d="M66 15.9971V25.9999H88.5103V15.5429L81.8777 13.0713" stroke="black" strokeWidth="1.67593" strokeMiterlimit="10" />
    <path d="M81.06 12.6864C81.0755 12.5023 80.9422 12.3403 80.7622 12.3245C80.5823 12.3087 80.4239 12.445 80.4084 12.6291C80.393 12.8132 80.5264 12.9752 80.7062 12.991C80.8862 13.0068 81.0445 12.8703 81.06 12.6864Z" fill="black" stroke="black" strokeWidth="0.788673" strokeMiterlimit="10" />
    <path d="M79.4262 12.0624C79.4417 11.8784 79.3083 11.7163 79.1283 11.7005C78.9485 11.6847 78.7901 11.8211 78.7747 12.0052C78.7592 12.1891 78.8925 12.3511 79.0724 12.367C79.2524 12.3828 79.4107 12.2464 79.4262 12.0624Z" fill="black" stroke="black" strokeWidth="0.788673" strokeMiterlimit="10" />
    <path d="M77.7895 11.4374C77.8049 11.2533 77.6717 11.0913 77.4917 11.0755C77.3118 11.0597 77.1534 11.196 77.1379 11.3801C77.1225 11.5642 77.2558 11.7262 77.4357 11.742C77.6156 11.7578 77.774 11.6214 77.7895 11.4374Z" fill="black" stroke="black" strokeWidth="0.788673" strokeMiterlimit="10" />
  </svg>
);
