import React from 'react';
import { useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import T from 'i18n-react';
import { useHistory } from 'react-router';
import { legendIcons } from 'constants/feasibilityReportConts';
import { feasibilityReportSelector } from 'store/swappProfile/selectors';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import {
  ContentColumnWrapper,
  ContentImage,
  ContentTitle,
  ContentRowWrapper,
  StyledText,
  VerticalTitleContentWrapper,
  LegendWrapper,
  LegendIcon,
} from '../ReportPages.styles';

const SiteLocation = () => {
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const reportData = useSelector((state) => feasibilityReportSelector(state, locationData.profileId));
  const siteLocationData = lodashGet(reportData, 'site.siteLocationData');

  return (
    <VerticalTitleContentWrapper id="site/site-location">
      <ContentTitle>{T.translate('Site Location')}</ContentTitle>

      <ContentRowWrapper>
        <ContentColumnWrapper>

          {/* ======== city map ======== */}
          <ContentColumnWrapper width={240} hasColor marginBottom={15}>

            {/* ======== image ======== */}
            <ContentImage src={siteLocationData.siteMap.url} width={220} marginBottom={10} />

            {/* ======== description ======== */}
            <StyledText size="small">{T.translate(siteLocationData.siteMap.description)}</StyledText>
          </ContentColumnWrapper>

          {/* ======== legend ======== */}
          <ContentColumnWrapper width={240} hasColor>
            <StyledText bold>Site Address</StyledText>
            <StyledText marginBottom={10}>{siteLocationData.siteAddress}</StyledText>
            {siteLocationData.legendItems.map((key, index) => (
              <LegendWrapper key={index}>
                <LegendIcon component={legendIcons[key].icon} />
                <StyledText middle size="small">{legendIcons[key].label}</StyledText>
              </LegendWrapper>
            ))}
          </ContentColumnWrapper>
        </ContentColumnWrapper>

        {/* ======== image ======== */}
        <ContentImage src={siteLocationData.imageUrl} width={800} />
      </ContentRowWrapper>
    </VerticalTitleContentWrapper>
  );
};

export default React.memo(SiteLocation);
