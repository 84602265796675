import React from 'react';
import { useSelector } from 'react-redux';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import { useHistory } from 'react-router';
import { feasibilityReportSelector } from 'store/swappProfile/selectors';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { VerticalTitleContentWrapper, ContentRowWrapper,
  ContentWrapper, ContentColumnWrapper, ContentTitle, StyledText, ContentImage } from '../ReportPages.styles';

const Summary = () => {
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const reportData = useSelector((state) => feasibilityReportSelector(state, locationData.profileId));

  const summaryPageData = lodashGet(reportData, 'summaryPageData');

  return (
    <VerticalTitleContentWrapper id="summary">
      <ContentTitle>{T.translate('Executive Summary')}</ContentTitle>
      <ContentRowWrapper>

        {/* ======== description ======== */}
        <ContentColumnWrapper width={440}>
          <StyledText>{T.translate(summaryPageData.description)}</StyledText>
        </ContentColumnWrapper>

        {/* ======== image ======== */}
        <ContentWrapper width={700}>
          <ContentImage src={summaryPageData.imageUrl} height={480} width={640} background />
        </ContentWrapper>
      </ContentRowWrapper>
    </VerticalTitleContentWrapper>
  );
};

export default React.memo(Summary);
