import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashIsString from 'lodash/isString';
import styled from 'styled-components';
import Icon from '@ant-design/icons';
import emptyIcon from 'styles/static/icons/comon/emptyIcon';

const EmptyMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const StyledIcon = styled(Icon)`
  margin-bottom: 10px;

  svg {
    width: 80px;
    height: 80px;
  }
`;

const Description = styled.div`
  display: flex;
  margin: 0 auto;
  text-align: center;
`;

const EmptyMessage = (props) => {
  const { icon, description } = props;

  return (
    <EmptyMessageWrapper>
      <StyledIcon component={icon || emptyIcon} />
      <Description>
        {lodashIsString(description) ? T.translate(description) : description}
      </Description>
    </EmptyMessageWrapper>
  );
};

EmptyMessage.propTypes = {
  icon: PropTypes.func,
  description: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.array,
  ]),
};

export default EmptyMessage;
