import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import { swappProjectSelector } from 'store/swappProfile';
import { getSwappProjectAction } from 'store/swappProfile/actions/swappProfileActions';
import CompareContainer from './CompareContainer';

const { overall } = UI_AUTHORIZE_PATH;

const ExplorePage = () => {
  const match = useRouteMatch();
  const swappProject = useSelector(swappProjectSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!swappProject || swappProject.id !== Number(match.params.projectId)) {
      dispatch(getSwappProjectAction(match.params.projectId));
    }
  }, [swappProject, match.params.projectId]);

  return (
    <Switch>
      <Route path={`${match.path}/${overall}`} component={CompareContainer} />
      <Redirect to={`${match.path}/${overall}`} />
    </Switch>
  );
};

export default ExplorePage;
