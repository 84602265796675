import React from 'react';
import styled from 'styled-components';
import { StyledFormItem, StyledInput } from '../../styles/commonComponents.styles';

export const NameFormItem = styled(StyledFormItem)`
  .ant-form-explain {
    margin-top: 5px;
  }
  
  .ant-form-item-explain, .ant-form-item-extra {
    line-height: 0.8;
  }
`;

export const NameWrapper = styled.div`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  //max-width: 230px;
`;

export const NameLoadingWrapper = styled.div`
  height: ${({ small }) => (small ? '27px' : '32px')};
  width: 350px;
  
  .ant-skeleton-title {
    margin: 5px 0 0;
  }
`;

export const ReadOnlyName = styled.div`
  width: 100%;
  max-width: 450px;
  color: ${({ theme }) => theme.colors.gray_09};
  height: ${({ small }) => (small ? '27px' : '32px')};
  line-height: ${({ small }) => (small ? '27px' : '32px')};
  font-size: ${({ theme, small }) => (small ? theme.font.size.large : theme.font.size.xLarge)};
  //margin-bottom: 5px;
`;

export const NameInput = styled(({ small, borderless, ...props }) => <StyledInput {...props} />)`
  width: 100%;
  max-width: 450px;
  color: ${({ theme }) => theme.colors.gray_09};
  height: ${({ small }) => (small ? '27px' : '32px')};
  font-size: ${({ theme, small }) => (small ? theme.font.size.large : theme.font.size.xLarge)} !important;
  background-color: ${({ theme }) => theme.colors.inputBackground};
  box-shadow: none;
  padding: ${({ borderless, small }) => {
    if (borderless) {
      return small ? '2px 5px 4px 4px' : '4px 11px 4px 4px';
    }
    return small ? '2px 5px' : '4px 11px';
  }};
  border: 1px solid ${({ theme, borderless }) => (borderless ? 'transparent' : theme.colors.gray_04)} !important;

  &:hover, &:focus {
    color: ${({ theme, disabled }) => (disabled ? 'unset' : theme.colors.primaryColor)};
    border: 1px solid ${({ theme, disabled }) => (disabled ? 'unset' : theme.colors.primaryColor)} !important;
    cursor: ${({ disabled }) => (disabled ? 'unset' : 'text')};
 }

  &:hover, &:focus {
    border: 0;
    
    .anticon {
       color: ${({ theme }) => theme.colors.primaryColor};  
     }
  }
  
  &:focus-within {
    .anticon {
      color: ${({ theme }) => theme.colors.primaryColor};  
    }
  }
  
  .ant-input-prefix {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 10px;
  }

  .anticon {
    color: ${({ theme }) => theme.colors.gray_09};
    font-size: ${({ theme }) => theme.font.size.xLarge};

     &:hover {
       color: ${({ theme }) => theme.colors.primaryColor};  
     }
  }
  
  .ant-input {
    color: ${({ theme }) => theme.colors.gray_09};
    font-size: ${({ theme }) => theme.font.size.xLarge};

    &:hover, &:focus {
        color: ${({ theme }) => theme.colors.primaryColor};
     }
  }
`;
