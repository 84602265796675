/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-bitwise */
/* eslint-disable no-mixed-operators */

import * as THREE from 'three';

const DEFAULT_HORIZONTAL_MARGIN = 50;
const DEFAULT_VERTICAL_SCREEN_MARGIN = 50;

export const fitToView = (boundingBox, camera, size, cameraController, options) => {
  const { visibleBounds, horizontalMargin = DEFAULT_HORIZONTAL_MARGIN, verticalMargin = DEFAULT_VERTICAL_SCREEN_MARGIN } = options;
  const center = boundingBox.getCenter(new THREE.Vector3());
  const boundingBoxWidth = boundingBox.max.x - boundingBox.min.x;
  const boundingBoxHeight = boundingBox.max.y - boundingBox.min.y;
  const visibleSize = visibleBounds || size;

  if (camera instanceof THREE.OrthographicCamera) {
    const widthAspect = (visibleSize.width - horizontalMargin) / boundingBoxWidth;
    const heightAspect = (visibleSize.height - verticalMargin) / boundingBoxHeight;
    const smallestAspect = Math.min(widthAspect, heightAspect);

    const offsetX = visibleBounds ? ((size.width - visibleBounds.width) / 2) / smallestAspect : 0;
    const offsetY = visibleBounds ? ((size.height - visibleBounds.height) / 2) / smallestAspect : 0;

    center.x -= offsetX;
    center.y -= offsetY;

    if (cameraController) {
      cameraController.target.x = center.x;
      cameraController.target.y = center.y;
    }

    camera.zoom = smallestAspect;
    camera.position.x = center.x;
    camera.position.y = center.y;

    camera.updateProjectionMatrix();
    camera.updateMatrix();
  }
};

// From: https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#stackoverflow-archive-begin
export const pSBC = (p, c0, c1, l) => {
  let r; let g; let b; let P; let f; let t; let h; const m = Math.round;
  let a = typeof (c1) === 'string';
  if (typeof (p) !== 'number' || p < -1 || p > 1 || typeof (c0) !== 'string' || (c0[0] != 'r' && c0[0] != '#') || (c1 && !a)) return null;
  h = c0.length > 9, h = a ? c1.length > 9 ? true : c1 == 'c' ? !h : false : h, f = pSBC.pSBCr(c0), P = p < 0, t = c1 && c1 != 'c' ? pSBC.pSBCr(c1) : P ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 }, p = P ? p * -1 : p, P = 1 - p;
  if (!f || !t) return null;
  if (l) r = m(P * f.r + p * t.r), g = m(P * f.g + p * t.g), b = m(P * f.b + p * t.b);
  else r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5), g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5), b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5);
  a = f.a, t = t.a, f = a >= 0 || t >= 0, a = f ? a < 0 ? t : t < 0 ? a : a * P + t * p : 0;
  if (h) return `rgb${f ? 'a(' : '('}${r},${g},${b}${f ? `,${m(a * 1000) / 1000}` : ''})`;
  return `#${(4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2)}`;
};

export const shadeHexColor = (color, percent) => {
  const f = parseInt(color.slice(1), 16); const t = percent < 0 ? 0 : 255; const p = percent < 0 ? percent * -1 : percent; const R = f >> 16; const G = f >> 8 & 0x00FF; const B = f & 0x0000FF;
  return `#${(0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1)}`;
};

export const pointToThreeVector3 = (point) => (point instanceof THREE.Vector3 ? point : new THREE.Vector3(...point));
export const pointToThreeVector2 = (point) => (point instanceof THREE.Vector2 ? point : new THREE.Vector2(...point));

export const poinstToThreeVector3 = (points) => points.map((point) => pointToThreeVector3(point));
export const poinstToThreeVector2 = (points) => points.map((point) => pointToThreeVector2(point));
