import { TEST_FIT_OFFICE_TYPES } from './dropdownKeys';

export const LAYER_NAMES_TO_KEYS = {
  [TEST_FIT_OFFICE_TYPES.LSF.ONE_PERSON]: '0-HA LSF 1 Person',
  [TEST_FIT_OFFICE_TYPES.LSF.TWO_PERSONS]: '0-HA LSF 2 Person',
  [TEST_FIT_OFFICE_TYPES.LSF.THREE_PERSONS]: '0-HA LSF 3 Person',
  [TEST_FIT_OFFICE_TYPES.LSF.FOUR_PERSONS]: '0-HA LSF 4 Person',
  [TEST_FIT_OFFICE_TYPES.LSF.FIVE_PERSONS]: '0-HA LSF 5 Person',
  [TEST_FIT_OFFICE_TYPES.LSF.SIX_PERSONS]: '0-HA LSF 6 Person',
  [TEST_FIT_OFFICE_TYPES.LSF.SEVEN_PERSONS]: '0-HA LSF 7 Person',
  [TEST_FIT_OFFICE_TYPES.LSF.EIGHT_PERSONS]: '0-HA LSF 8 Person',
  [TEST_FIT_OFFICE_TYPES.LSF.EXEC_SUITES]: '0-HA LSF Executive Suite',
  [TEST_FIT_OFFICE_TYPES.LSF.OPEN_SPACE]: '0-HA LSF Open Space',

  [TEST_FIT_OFFICE_TYPES.PSF.SMALL_CONFERENCE]: '0-HA PSF Small Conference Room',
  [TEST_FIT_OFFICE_TYPES.PSF.MEDIUM_CONFERENCE]: '0-HA PSF Medium Conference Room',
  [TEST_FIT_OFFICE_TYPES.PSF.LARGE_CONFERENCE]: '0-HA PSF Large Conference Room',
  [TEST_FIT_OFFICE_TYPES.PSF.PHONE_ROOMS]: '0-HA PSF Phone Room',
  [TEST_FIT_OFFICE_TYPES.PSF.ADA_PHONE_ROOMS]: '0-HA PSF Accessible Phone Room',
  [TEST_FIT_OFFICE_TYPES.PSF.COPY_AREA]: '0-HA PSF Copy Area',

  [TEST_FIT_OFFICE_TYPES.ASF.LOBBY_LOUNGE]: '0-HA ASF Lobby or Lounge',
  [TEST_FIT_OFFICE_TYPES.ASF.CORRIDOR]: '0-HA ASF Corridor',
  [TEST_FIT_OFFICE_TYPES.ASF.LIBRARY_CAFE]: '0-HA ASF Library or Cafe',
  [TEST_FIT_OFFICE_TYPES.ASF.DINING]: '0-HA ASF Dining',
  [TEST_FIT_OFFICE_TYPES.ASF.KITCHEN]: '0-HA ASF Kitchen',

  [TEST_FIT_OFFICE_TYPES.FSF.PACKAGE_ROOM]: '0-HA FSF Package Room',
  [TEST_FIT_OFFICE_TYPES.FSF.MDF_ROOM]: '0-HA FSF MDF Room',
  [TEST_FIT_OFFICE_TYPES.FSF.STORAGE]: '0-HA FSF Storage',
  [TEST_FIT_OFFICE_TYPES.FSF.JANITORS_CLOSET]: '0-HA FSF Janitors Closet',
  [TEST_FIT_OFFICE_TYPES.FSF.SHOWER]: '0-HA FSF Shower',
  [TEST_FIT_OFFICE_TYPES.FSF.BATHROOM]: '0-HA FSF Bathroom',

  [TEST_FIT_OFFICE_TYPES.OTHER]: 'Other',
  [TEST_FIT_OFFICE_TYPES.NONE]: 'None',
};
