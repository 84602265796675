import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import T from 'i18n-react';
import { Tooltip } from 'antd';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { StyledFormItem, StyledInputNumber, StyledSwitch } from 'styles/commonComponents.styles';
import { FEASIBILITY_TARGET_FORM } from 'constants/fieldNames';
import { FORM_INPUT_TYPE } from 'constants/dropdownKeys';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import {
  AreaWrapper,
  AreaRow,
  AreaItem,
} from './FeasibilityTargetFormContainer.Styles';
import MetricImperialInput from '../../../common/MetricImperialInput';

const inputWidth = 90;
const fieldTitleWidth = 240;

const FeasibilityBuildingRightsAreaForm = (props) => {
  const { formData, form, hide, isViewer } = props;
  const isPercentageTarget = formData[FEASIBILITY_TARGET_FORM.INPUT_TOGGLE] === FORM_INPUT_TYPE.PERCENTAGE_TARGET;
  const match = useRouteMatch();
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));

  useEffect(() => {
    if (isPercentageTarget) {
      return;
    }
    updateFields(null, null, lodashGet(formData, `[${FEASIBILITY_TARGET_FORM.TOTAL_APARTMENTS}][0].estimateSF`));
  }, [formData[FEASIBILITY_TARGET_FORM.TOTAL_APARTMENTS]]);

  const updateFields = (giaNiaValue, giaValue, niaValue) => {
    if (isViewer) {
      return;
    }

    const currentFormData = form.getFieldsValue();

    const getIsNiaLastActive = () => {
      if (!giaValue && !niaValue) {
        return currentFormData[FEASIBILITY_TARGET_FORM.IS_NIA_LAST_ACTIVE];
      }
      return !!niaValue;
    };

    const NIA_GIA = giaNiaValue || currentFormData[FEASIBILITY_TARGET_FORM.NIA_GIA];
    const NIA = () => {
      if (niaValue) {
        return niaValue;
      }

      if (getIsNiaLastActive()) {
        return currentFormData[FEASIBILITY_TARGET_FORM.NIA];
      }

      return (currentFormData[FEASIBILITY_TARGET_FORM.GIA] * (NIA_GIA / 100));

      // return isPercentageTarget && giaValue ? (giaValue * (NIA_GIA / 100)) : currentFormData[FEASIBILITY_TARGET_FORM.NIA];
    };

    const circulationPercentage = 100 - NIA_GIA;
    const GIA = () => {
      if (giaValue) {
        return giaValue;
      }

      if (!getIsNiaLastActive()) {
        return currentFormData[FEASIBILITY_TARGET_FORM.GIA];
      }

      return (currentFormData[FEASIBILITY_TARGET_FORM.NIA] / (NIA_GIA / 100));

      // return isPercentageTarget && niaValue ? (NIA() / (NIA_GIA / 100)) : currentFormData[FEASIBILITY_TARGET_FORM.GIA];
    };
    const circulationArea = NIA() * (circulationPercentage / 100);

    form.setFieldsValue({
      [FEASIBILITY_TARGET_FORM.IS_NIA_LAST_ACTIVE]: getIsNiaLastActive(),
      [FEASIBILITY_TARGET_FORM.CIRCULATION_PERCENTAGE]: circulationPercentage,
      [FEASIBILITY_TARGET_FORM.CIRCULATION_AREA]: circulationArea,
      [FEASIBILITY_TARGET_FORM.NIA_GIA]: NIA_GIA,
      [FEASIBILITY_TARGET_FORM.NIA]: NIA(),
      [FEASIBILITY_TARGET_FORM.GIA]: GIA(),
    });
  };

  return (
    <AreaWrapper hide={hide}>
      {/* ============== GIA Row ============== */}
      <AreaRow>
        <AreaItem first width={fieldTitleWidth}>
          <Tooltip title={T.translate('FEASIBILITY_APARTMENTS_FORM_GIA_TOOLTIP')}>
            {`${T.translate('FEASIBILITY_APARTMENTS_FORM_GIA')} (${T.translate(isImperial ? 'SQF' : 'SQM')})`}
          </Tooltip>
        </AreaItem>
        <AreaItem width={350} />
        <AreaItem flexEnd width={fieldTitleWidth}>
          <StyledFormItem name={FEASIBILITY_TARGET_FORM.IS_NIA_LAST_ACTIVE} initialValue valuePropName="checked" hidden>
            <StyledSwitch />
          </StyledFormItem>
        </AreaItem>
        <AreaItem width={fieldTitleWidth}>
          <StyledFormItem name={FEASIBILITY_TARGET_FORM.GIA} initialValue={23529}>
            <MetricImperialInput
              disabled={!isPercentageTarget || isViewer}
              min={0}
              height={22}
              width={inputWidth}
              isImperial={isImperial}
              onChange={(value) => updateFields(null, value)}
            />
          </StyledFormItem>
        </AreaItem>
      </AreaRow>

      {/* ============== NIA Row ============== */}
      <AreaRow>
        <AreaItem first width={fieldTitleWidth}>
          <Tooltip title={T.translate('FEASIBILITY_APARTMENTS_FORM_NIA_TOOLTIP')}>
            {`${T.translate('FEASIBILITY_APARTMENTS_FORM_NIA')} (${T.translate(isImperial ? 'SQF' : 'SQM')})`}
          </Tooltip>
        </AreaItem>
        <AreaItem width={350} />
        <AreaItem flexEnd width={fieldTitleWidth} />
        <AreaItem width={fieldTitleWidth}>
          <StyledFormItem name={FEASIBILITY_TARGET_FORM.NIA} initialValue={20000}>
            <MetricImperialInput
              disabled={!isPercentageTarget || isViewer}
              min={0}
              height={22}
              width={inputWidth}
              isImperial={isImperial}
              onChange={(value) => updateFields(null, null, value)}
            />
          </StyledFormItem>
        </AreaItem>
      </AreaRow>

      {/* ============== NIA / GIA Row ============== */}
      <AreaRow>
        <AreaItem first width={fieldTitleWidth}>{T.translate('FEASIBILITY_APARTMENTS_FORM_NIA_TO_GIA')}</AreaItem>
        <AreaItem width={350} />
        <AreaItem width={fieldTitleWidth} />
        <AreaItem width={fieldTitleWidth}>
          <StyledFormItem name={FEASIBILITY_TARGET_FORM.NIA_GIA} initialValue={85}>
            <StyledInputNumber
              min={0}
              height={22}
              disabled={isViewer}
              width={inputWidth}
              onChange={(value) => updateFields(value)}
              formatter={(value) => numberWithComma(value, { suffix: '%', fixed: 0 })}
            />
          </StyledFormItem>
        </AreaItem>
      </AreaRow>

      {/* ============== Circulation Row ============== */}
      <AreaRow>
        <AreaItem first width={fieldTitleWidth}>{T.translate('FEASIBILITY_APARTMENTS_FORM_CIRCULATION')}</AreaItem>
        <AreaItem width={350} />
        <AreaItem width={fieldTitleWidth}>
          <StyledFormItem name={FEASIBILITY_TARGET_FORM.CIRCULATION_AREA}>
            <MetricImperialInput
              disabled
              min={0}
              height={22}
              width={inputWidth}
              isImperial={isImperial}
            />
          </StyledFormItem>
          {T.translate(isImperial ? 'SQF' : 'SQM')}
        </AreaItem>
        <AreaItem width={fieldTitleWidth}>
          <StyledFormItem name={FEASIBILITY_TARGET_FORM.CIRCULATION_PERCENTAGE}>
            <StyledInputNumber
              min={0}
              disabled
              height={22}
              width={inputWidth}
              formatter={(value) => `${Number(value).toFixed(0)}%`}
              parser={(value) => value.replace('%', '')}
            />
          </StyledFormItem>
        </AreaItem>
      </AreaRow>
    </AreaWrapper>
  );
};

FeasibilityBuildingRightsAreaForm.propTypes = {
  formData: PropTypes.object,
  form: PropTypes.object,
  hide: PropTypes.bool,
  isViewer: PropTypes.bool,
};

export default FeasibilityBuildingRightsAreaForm;
