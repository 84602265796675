import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import { Tooltip } from 'antd';
import { AREA_TARGET_FORM } from 'constants/fieldNames';
import { FormItem, TestFitInputNumber } from './AreaTargetFormContainer.styles';

const formItemWidth = 450;

const AreaTargetFormBathrooms = (props) => {
  const { formData, form: { setFieldsValue }, disabled, handleOnChange } = props;

  useEffect(() => {
    handleChange(AREA_TARGET_FORM.MALE_BATHROOMS);
    handleChange(AREA_TARGET_FORM.FEMALE_BATHROOMS);
  }, [
    formData[AREA_TARGET_FORM.MALE_BATHROOMS],
    formData[AREA_TARGET_FORM.FEMALE_BATHROOMS],
    formData[AREA_TARGET_FORM.TOTAL_LSF_FIELD],
  ]);

  const handleChange = (field) => {
    const totalLsfMembers = lodashGet(formData[AREA_TARGET_FORM.TOTAL_LSF_FIELD], '[0].numberOfMembers');
    const requiredAdditional = Math.ceil(2 + (((totalLsfMembers / 2) - 50)) / 50) - formData[field];
    const newValue = requiredAdditional > 0 ? requiredAdditional : 0;

    const fieldMapping = {
      [AREA_TARGET_FORM.MALE_BATHROOMS]: AREA_TARGET_FORM.REQUIRED_MALE_BATHROOMS,
      [AREA_TARGET_FORM.FEMALE_BATHROOMS]: AREA_TARGET_FORM.REQUIRED_FEMALE_BATHROOMS,
    };

    setFieldsValue({ [fieldMapping[field]]: newValue });
    handleOnChange();
  };

  const formItemLayout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 7, offset: 1 },
  };

  return (
    <React.Fragment>
      <FormItem
        width={formItemWidth}
        name={AREA_TARGET_FORM.MALE_BATHROOMS}
        disabled={disabled}
        {...formItemLayout}
        label={T.translate('AREA_TARGET_FORM_BATHROOMS_EXISTING_M_BATHROOMS')}
      >
        <TestFitInputNumber disabled={disabled} min={0} />
      </FormItem>
      <FormItem
        width={formItemWidth}
        name={AREA_TARGET_FORM.FEMALE_BATHROOMS}
        disabled={disabled}
        {...formItemLayout}
        label={T.translate('AREA_TARGET_FORM_BATHROOMS_EXISTING_F_BATHROOMS')}
      >
        <TestFitInputNumber disabled={disabled} min={0} />
      </FormItem>
      <FormItem
        width={formItemWidth}
        name={AREA_TARGET_FORM.REQUIRED_MALE_BATHROOMS}
        {...formItemLayout}
        disabled={disabled}
        label={<Tooltip title={T.translate('AREA_TARGET_FORM_BATHROOMS_REQUIRED_TOOLTIP')}>{T.translate('AREA_TARGET_FORM_BATHROOMS_REQUIRED_M_BATHROOMS')}</Tooltip>}
      >
        <TestFitInputNumber disabled />
      </FormItem>
      <FormItem
        width={formItemWidth}
        name={AREA_TARGET_FORM.REQUIRED_FEMALE_BATHROOMS}
        {...formItemLayout}
        disabled={disabled}
        label={<Tooltip title={T.translate('AREA_TARGET_FORM_BATHROOMS_REQUIRED_TOOLTIP')}>{T.translate('AREA_TARGET_FORM_BATHROOMS_REQUIRED_F_BATHROOMS')}</Tooltip>}
      >
        <TestFitInputNumber disabled />
      </FormItem>
    </React.Fragment>
  );
};

AreaTargetFormBathrooms.propTypes = {
  formData: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

export default React.memo(AreaTargetFormBathrooms);
