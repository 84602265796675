export const MAIN_COLOR = '#ffffff'; // TODO - delete and connect to Theme
export const WARNING_COLOR = '#F47174';

export const MINIMUM_MIDPOINTS_LENGTH = 40;
export const MINIMUM_EDGES_LENGTH = 15;
export const MINIMUM_HANDLE_LENGTH = 10;

export const NEW_EDGE_DOT_FACTOR = 0.98;
export const POINTS_CLOSING_DIST = 1;

export const MINIMUM_TEXT_LENGTH = 20;
export const MAX_CLICK_TIME = 300;

export const SNAPED_ANGLE = 15;
export const SNAPPED_POINTER_ANGLE = 3;
export const POINTS_EDITOR_HEIGHT = 220; // A bit more then the lot outline

export const MINIMUM_LOT_SNAPPING_LENGTH = 10;
