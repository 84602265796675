import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { currentThemeSelector } from 'store/userSettings';
import { isTestFitItemSelectedSelector } from 'store/editor';
import { isMultiClickActiveSelector, multiClickStatusSelector, setMultiClick } from 'store/testFit';
import * as SwappEditor from 'store/editor';
import ExtrudeMesh from '../../components/ExtrudeMesh';
import MeshLine from '../../components/MeshLine';

const hoveredTileColor = '#ffffff';

const Tile = (props) => {
  const { tile, tile: { id, points }, highlighted, wallDepth = 50 } = props;
  const [hovered, setHover] = useState(false);
  const currentTheme = useSelector(currentThemeSelector);
  const selected = useSelector((state) => (isTestFitItemSelectedSelector(state, id)));
  const dispatch = useDispatch();
  const store = useStore();

  const handleTileClicked = (e, forceClick) => {
    e.stopPropagation();
    const isMultiClickActive = isMultiClickActiveSelector(store.getState());
    const multiClickStatus = multiClickStatusSelector(store.getState());

    if (multiClickStatus === selected && !forceClick) {
      return;
    }

    if (isMultiClickActive || forceClick) {
      dispatch(SwappEditor.toggleObjectSelection(tile));
    }
  };

  const changeCursor = (cursor) => {
    document.body.style.cursor = cursor;
  };

  const getOpacity = () => {
    if (selected) {
      return hovered ? 0.95 : 1;
    }

    if (highlighted) {
      return hovered ? 0.7 : 0.5;
    }

    return hovered ? 0.8 : 0;
  };

  const onPointer = (isOver) => {
    const isMultiClickActive = isMultiClickActiveSelector(store.getState());

    if (!isMultiClickActive) {
      setHover(isOver);
    }
    changeCursor(isOver ? 'pointer' : 'default');
  };

  return (
    <group>
      {/* ============== inside tile ============== */}
      <mesh
        position={[0, 0, 1]}
        onPointerOver={() => onPointer(true)}
        onPointerOut={() => onPointer(false)}
        onPointerDown={(e) => {
          dispatch(setMultiClick({ active: true, isSelected: selected }));
          handleTileClicked(e, true);
        }}
        onPointerUp={() => dispatch(setMultiClick({ active: false, isSelected: false }))}
        onPointerEnter={(e) => handleTileClicked(e)}
      >
        <ExtrudeMesh
          envelope={points}
          color={selected ? currentTheme.colors.selected3dObject : hoveredTileColor}
          extrudeDepth={2}
          transparent
          unlit
          opacity={getOpacity()}
          castShadow={false}
        />
      </mesh>

      {/* ============== walls ============== */}
      {(selected || highlighted) && <MeshLine vertices={points} height={wallDepth + 1} lineWidth={0.1} color={hoveredTileColor} />}
    </group>
  );
};

Tile.propTypes = {
  tile: PropTypes.object,
  highlighted: PropTypes.bool,
  wallDepth: PropTypes.number,
};

export default React.memo(Tile);
