import styled from 'styled-components';
import { Form } from 'antd';
import { StyledFormItem } from 'styles/commonComponents.styles';

export const StyledForm = styled(Form)`
  margin-top: 20px;
  .ant-form-explain {
    position: absolute;
  }
`;

export const FormItem = styled(StyledFormItem)`
  margin-bottom: 12px;
  display: block;
  text-align: left;
`;

export const SelfServeFormWrapper = styled.div`
  position: relative;
  width: 600px;
  left: 20px;
  top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 7px;
  background-color: rgba(251,253,255,0.45);
  backdrop-filter: blur(15px);
  padding: 22px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.22);
  z-index: 999;
`;

export const SelfServeRightSide = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SelfServeButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primaryColor};
  margin: 10px;
  margin-top: 25px;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 3px 4px 10px 0 rgba(0,0,0,0.4);
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  display: block;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  background-color: ${({ disabled, theme }) => (disabled ? theme.colors.gray_03 : theme.colors.primaryColor)};
  color: ${({ theme }) => theme.colors.white};
  border: unset;
  padding: ${({ padding }) => padding || '15px auto'};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	font-weight: ${({ theme }) => theme.font.weight.light};
	line-height: 1;
  //letter-spacing: 1.25px;
  font-size: ${({ theme, fontSize = 'large' }) => theme.font.size[fontSize]};
  :focus {
			outline: unset;
	}
	:hover {
	    background-color: 	${({ disabled, theme }) => (disabled ? theme.colors.gray_03 : theme.colors.primaryColorHover)};
	}
  `;

export const SelfServeMiddle = styled.div`
  flex-grow: 4;
`;

export const SelfServeLeftSide = styled.div`
    // border: 1px dashed #CBCBCB;
    display: flex;
    flex-direction: column;
`;

export const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`;

export const InputData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.textColor};
  font-size: ${({ theme }) => theme.font.size.large};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  text-align: center;
  margin-bottom: 35px;
`;

export const MapElement = styled.div`
   height: 100%;
   border: 1px solid ${({ theme }) => theme.colors.gray_02};
`;

const ContainerElement = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FeasibilityContainerElement = styled(ContainerElement)`
  height: calc(100vh -  ${({ theme }) => `${theme.other.headerHeight}px`});
`;

export const TestfitContainerElement = styled(ContainerElement)`
  height: 360px;
  flex-direction: column-reverse;
`;

export const LocationFormItemTestFit = styled(StyledFormItem)`
  position: relative;
`;

export const SearchResult = styled.div`
  position: absolute;
  border-radius: 20px;
  box-shadow: 3px 4px 10px 0 rgba(0,0,0,0.4);
  width: 100%;
`;

export const Suggestion = styled.div`
  cursor: pointer;
  padding: 4px;
  color: ${({ theme, active }) => (active && theme.colors.primaryColor)};
  background-color: ${({ theme }) => theme.colors.gray_01};
  
  span {
    padding: 10px;
  }
  
  :hover {
    color: ${({ theme }) => theme.colors.primaryColor};
  }
`;
