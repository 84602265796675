import React from 'react';
import { useSelector } from 'react-redux';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import { useHistory } from 'react-router';
import { feasibilityReportSelector } from 'store/swappProfile/selectors';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import {
  VerticalTitleContentWrapper, ContentTitle,
  ContentImagesWrapper, ContentImagesWithLabelWrapper,
  ContentImage, ContentImageLabel, ContentRowWrapper, ContentColumnWrapper, ContentWrapper,
} from '../ReportPages.styles';

const StreetView = () => {
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const reportData = useSelector((state) => feasibilityReportSelector(state, locationData.profileId));
  const designStreetViewData = lodashGet(reportData, 'design.designStreetViewData');

  return (
    <VerticalTitleContentWrapper>
      <ContentTitle>{T.translate('Street View')}</ContentTitle>

      <ContentRowWrapper>

        {/* ======== street maps ======== */}
        <ContentColumnWrapper width={230}>
          {lodashGet(designStreetViewData, 'streetMaps', []).map((map, index) => (
            <ContentImage key={index} src={map} width={180} height={140} marginBottom={10} />
          ))}
        </ContentColumnWrapper>

        {/* ======== images ======== */}
        <ContentWrapper>
          <ContentImagesWrapper width={420}>
            {lodashGet(designStreetViewData, 'renders', []).map((image, index) => (
              <ContentImagesWithLabelWrapper key={index}>
                <ContentImage src={image.url} width={420} height={380} background />
                {lodashGet(image, 'name') && <ContentImageLabel>{image.name}</ContentImageLabel>}
              </ContentImagesWithLabelWrapper>
            ))}
          </ContentImagesWrapper>
        </ContentWrapper>
      </ContentRowWrapper>
    </VerticalTitleContentWrapper>
  );
};

export default React.memo(StreetView);
