import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import lodashKeys from 'lodash/keys';
import { FEASIBILITY_TARGET_FORM } from 'constants/fieldNames';
import { STANDARDS } from 'constants/feasibilityConts';
import { FormItem } from './FeasibilityTargetFormContainer.Styles';
import MultiImageSelect from '../../../common/MultiImageSelect';

const FeasibilityBuildingTypologiesForm = (props) => {
  const { formData, disabled } = props;
  const unitType = lodashGet(formData, `${FEASIBILITY_TARGET_FORM.APARTMENTS_STANDARD}[0]`);
  const additionalBuildingTypologies = unitType && unitType !== STANDARDS.PREFAB.key
    ? lodashKeys(STANDARDS[unitType].buildingTypologies).map((key) => STANDARDS[unitType].buildingTypologies[key])
    : [];
  const baseBuildingTypologies = lodashKeys(STANDARDS.PREFAB.buildingTypologies).map((key) => STANDARDS.PREFAB.buildingTypologies[key]);
  const buildingTypologiesArray = [...baseBuildingTypologies, ...additionalBuildingTypologies];

  return (
    <FormItem name={FEASIBILITY_TARGET_FORM.BUILDING_TYPOLOGIES} valuePropName="selected" initialValue={[STANDARDS.PREFAB.buildingTypologies.MEGA_BLOCK.key, STANDARDS.PREFAB.buildingTypologies.L_SHAPE.key, STANDARDS.PREFAB.buildingTypologies.NUM_OF_MASSES.key]}>
      <MultiImageSelect size={60} data={buildingTypologiesArray} disabled={disabled} min={1} max={3} countText={T.translate('FEASIBILITY_BUILDING_FORM_COUNT_TEXT')} />
    </FormItem>
  );
};

FeasibilityBuildingTypologiesForm.propTypes = {
  formData: PropTypes.object,
  disabled: PropTypes.bool,
};

export default React.memo(FeasibilityBuildingTypologiesForm);
