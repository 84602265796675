import { UI_AUTHORIZE_PATH } from '../constants/routes/ui';
import {
  BUILDING_INFO_KEYS,
  COMMUNAL_SPACES,
  COMMUNAL_SPACES_RATIO_TYPE, COMMUNAL_SPACES_SPREAD, HVAC,
  MODEL_ANALYSIS_TYPES,
} from '../constants/feasibilityConts';

export default {
  // ===============Global===============
  BACK: 'Atrás',
  FRONT: 'Frente',
  LOADING: 'Carregando...',
  UPDATE: 'Atualizar',
  BETA: 'Beta.',
  NO_MOBILE_SUPPORT: 'SWAPP funciona melhor em navegador da Web. No momento, estamos otimizando a experiência para dispositivos móveis ',
  INCHES: 'polegadas',
  METERS: 'metros',
  UNITS: 'Unidades',
  NEXT: 'Próximo',
  PREVIOUS: 'Anterior',
  EMPTY: 'Vazia',
  OTHER: 'outros',
  DUPLICATE: 'Duplicar',

  // ===============Small Components===============
  // ImperialMetricToggle
  IMPERIAL_METRIC_TOGGLE_SWITCH_TO_SQF: 'Ver projeto em pés quadrados',
  IMPERIAL_METRIC_TOGGLE_SWITCH_TO_SQM: 'Ver Projeto em Metro Quadrado',

  STAGE_NOT_READY: 'Conclua as etapas anteriores antes de prosseguir para',

  // MultiImageSelect
  MULTI_IMAGE_SELECT_CANT_SELECT_LESS: (_key, context) => `Você não pode selecionar menos de ${context.min} item`,
  MULTI_IMAGE_SELECT_CANT_SELECT_MORE: (_key, context) => `Você não pode selecionar menos de ${context.max} items`,
  MULTI_IMAGE_SELECT_SELECT_TEXT: 'Selecionar Items',

  // WellbeingAnalysisButton
  WELLBEING_ANALYSIS_BUTTON_TOOLTIP_RUNNING: 'Análise de bem-estar em andamento',
  WELLBEING_ANALYSIS_BUTTON_TOOLTIP_RUN: 'Faça uma análise de bem-estar',
  WELLBEING_ANALYSIS_BUTTON_TOOLTIP_HAS_RESULT: 'A análise de bem-estar está pronta',
  WELLBEING_ANALYSIS_BUTTON_MODAL_TITLE: 'Análise de bem-estar do ocupante',
  WELLBEING_ANALYSIS_BUTTON_MODAL_CONTENT: 'A análise pode demorar alguns minutos. O algoritmo irá gerar estudos ambientais, verificando luz do dia, vista e vento para a opção de projeto selecionada.',
  WELLBEING_ANALYSIS_BUTTON_MODAL_OK_TEXT: 'Continuar',

  // DownloadRevitButton
  DOWNLOAD_REVIT_BUTTON_TOOLTIP_RUNNING: 'Gerando modelo de massa do Revit',
  DOWNLOAD_REVIT_BUTTON_TOOLTIP_RUN: 'Gerar modelo de massa do Revit',
  DOWNLOAD_REVIT_BUTTON_TOOLTIP_HAS_RESULT: 'Baixar modelo de massa do Revit',
  DOWNLOAD_REVIT_BUTTON_MODAL_TITLE: 'Gerar modelo de massa do Revit',
  DOWNLOAD_REVIT_BUTTON_MODAL_CONTENT: 'A geração pode demorar alguns minutos',
  DOWNLOAD_REVIT_BUTTON_MODAL_OK_TEXT: 'Continuar',

  // GenerateReportButton
  GENERATE_REPORT_BUTTON_TOOLTIP_RUNNING: 'Geração de relatório em andamento',
  GENERATE_REPORT_BUTTON_TOOLTIP_RUN: 'Gerar Relatório',
  GENERATE_REPORT_BUTTON_TOOLTIP_HAS_RESULT: 'Relatório está pronto',
  GENERATE_REPORT_BUTTON_MODAL_TITLE: 'Geração de relatório de ocupante',
  GENERATE_REPORT_BUTTON_MODAL_CONTENT: 'A geração pode demorar alguns minutos ',
  GENERATE_REPORT_BUTTON_MODAL_OK_TEXT: 'Continuar',

  // NoReportNotification
  NO_REPORT_NOTIFICATION_TITLE: 'Sem relatórios',
  NO_REPORT_NOTIFICATION_HINT: 'volte para Explorar e crie um relatório de pelo menos uma das opções',

  // FavoriteButton
  FAVORITE_BUTTON_TOOLTIP_REMOVE: 'Remover dos favoritos',
  FAVORITE_BUTTON_TOOLTIP_ADD: 'Adicionar favoritos',
  FAVORITE_BUTTON_MODAL_TITLE: 'Remover dos favoritos?',
  FAVORITE_BUTTON_MODAL_CONTENT: 'O resultado será deletado',
  FAVORITE_BUTTON_MODAL_OK_TEXT: 'Continuar',

  // ResultSplitView
  RESULT_SPLIT_VIEW_RESULT: 'Resultados',
  RESULT_SPLIT_VIEW_FAVORITE: 'Favoritos',
  RESULT_SPLIT_VIEW_RESULT_EMPTY_TITLE: 'Sem Resultado',
  RESULT_SPLIT_VIEW_RESULT_EMPTY_HINT: 'Por favor, verifique e edite o formulário de requisitos na barra esquerda e pressione `Atualizar` ',
  RESULT_SPLIT_VIEW_FAVORITE_EMPTY_TITLE: 'Sem favoritos',

  // FeasibilityEditorOverlay
  FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_TITLE: 'Seu modelo foi modificado',
  FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_CONTENT: 'O que você gostaria de fazer?',
  FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_OK_BUTTON: 'Descartar',
  FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_CANCEL_BUTTON: 'Continuar editando',
  FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_SAVE_BUTTON: 'Salvar',
  FEASIBILITY_EDITOR_OVERLAY_MODAL_EXIT_SAVE_AS_BUTTON: 'Salvar uma cópia',
  FEASIBILITY_EDITOR_OVERLAY_MODAL_ERROR_TITLE: 'Espere um segundo',
  FEASIBILITY_EDITOR_OVERLAY_MODAL_ERROR_CONTENT: 'Os edifícios estão localizados fora da polyline do local ou se sobrepõem. ',
  FEASIBILITY_EDITOR_OVERLAY_SELECT: 'Selecionar',
  FEASIBILITY_EDITOR_OVERLAY_CREATE: 'Criar',
  FEASIBILITY_EDITOR_OVERLAY_PARKING: 'Estacionam',
  FEASIBILITY_EDITOR_OVERLAY_PRECAST_PARKING: 'Pré-fabricados',
  FEASIBILITY_EDITOR_OVERLAY_UNDERGROUND_PARKING: 'Abaixo',
  FEASIBILITY_EDITOR_OVERLAY_BUILDING: 'Edifício',
  FEASIBILITY_EDITOR_OVERLAY_SURFACE_PARKING: 'Superfície',
  FEASIBILITY_EDITOR_OVERLAY_EDIT: 'Editar',
  FEASIBILITY_EDITOR_OVERLAY_MIRROR: 'Espelhos',
  FEASIBILITY_EDITOR_OVERLAY_DUPLICATE: 'Duplicar',
  FEASIBILITY_EDITOR_OVERLAY_DELETE: 'Deletar',
  FEASIBILITY_EDITOR_OVERLAY_OPEN_GF: 'Abrir GF',
  FEASIBILITY_EDITOR_OVERLAY_SETBACK: 'Revés',
  FEASIBILITY_EDITOR_OVERLAY_FLOOR: 'Andar',
  FEASIBILITY_EDITOR_OVERLAY_UNDO: 'Desfazer',
  FEASIBILITY_EDITOR_OVERLAY_CLOSE: 'Fechar',

  // ===============Errors===============
  ERROR_SOMETHING_WENT_WRONG: 'Alguma coisa deu errado. Por favor tente outra vez',
  ERROR: 'Erro',

  // ===============Navigation===============
  NAVIGATION_PROJECT_INFO: 'Configurações do projeto',
  NAVIGATION_FLEXIBILITY: 'Flexibilidade',
  [UI_AUTHORIZE_PATH.TEST_FIT]: 'Teste',
  [UI_AUTHORIZE_PATH.FEASIBILITY_STUDY]: 'Viabilidade',
  [UI_AUTHORIZE_PATH.PROJECT_SETTINGS]: 'Configurações do projeto',
  NAVIGATION_SETUP: 'Instalação',
  NAVIGATION_EXPLORE: 'Explorar',
  NAVIGATION_REPORT: 'Relatório',
  NAVIGATION_STUDIES: 'Explorar',
  NAVIGATION_COMPARE: 'Comparar',
  NAVIGATION_COMPARE_SCHEDULE: 'Planejar',
  NAVIGATION_COMPARE_BUDGET: 'Despesas',
  NAVIGATION_COMPARE_APPROVAL: 'Aprovação',
  NAVIGATION_COMPARE_ARCHITECTURE: 'Arquitetura',
  NAVIGATION_COMPARE_MECHANICAL: 'Mecânica',
  NAVIGATION_COMPARE_ELECTRICAL: 'Elétrica',
  NAVIGATION_COMPARE_PLUMBING: 'Encanamento',
  NAVIGATION_COMPARE_VIEWS: 'Visualizações',
  NAVIGATION_COMPARE_DATA: 'Dados',
  NAVIGATION_COMPARE_EFFICIENCY: 'Eficiência',
  NAVIGATION_COMPARE_TYPE: 'Tipos apt',
  NAVIGATION_COMPARE_STUDIES: 'Explorar',
  NAVIGATION_COST: 'Cons. Custo',
  NAVIGATION_MARKETABILITY: 'Comercialização',
  NAVIGATION_OCCUPANT_WELLBEING: 'Bem estar',
  NAVIGATION_URBAN_CONTEXT: 'Urbanismo',
  NAVIGATION_SUSTAINABILITY: 'Sustentabilidade',
  NAVIGATION_ENERGY_EFFICIENT: 'Energia eficiente',
  NAVIGATION_SUMMARY: 'Resumo',
  NAVIGATION_EXISTING_SITE: 'Site Existente',
  NAVIGATION_FLOODS: 'Inundações',
  NAVIGATION_SITE: 'Informação do site',
  NAVIGATION_SITE_LOCATION: 'Localização do site',
  NAVIGATION_AERIAL_VIEW: 'Vista aérea',
  NAVIGATION_EXISTING_STORE: 'Loja Existente',
  NAVIGATION_STREET_VIEW: 'Vista da rua',
  NAVIGATION_SURROUNDING: 'Em torno da',
  NAVIGATION_STRATEGY: 'Estratégia do Site',
  NAVIGATION_SITE_CONSTRAINTS: 'Restrições do site',
  NAVIGATION_OPPORTUNITIES: 'Oportunidades',
  NAVIGATION_PHASING: 'Faseamento',
  NAVIGATION_DESIGN: 'Design',
  NAVIGATION_OVERALL_FE: 'Design Geral',
  NAVIGATION_APARTMENTS_MIX: 'Mix de Apartamentos',
  NAVIGATION_COMMUNAL: 'comum',
  NAVIGATION_PLANS_FE: 'Planos',
  NAVIGATION_SECTION: 'Seções',
  NAVIGATION_WELLBEING: 'Bem estar',
  NAVIGATION_ENVIRONMENT: 'Análise',
  NAVIGATION_SHADOWS: 'Sombras',
  NAVIGATION_RIGHT_OF_LIGHT: 'Direito de luz',
  NAVIGATION_APARTMENTS: 'Typ. Apartamentos',
  NAVIGATION_FINANCIAL: 'Financeiro',
  NAVIGATION_DEVELOPMENT_APPRAISAL: 'Avaliação de Desenvolvimento',
  NAVIGATION_CONSTRUCTION_COST: 'Custo de construção',

  // GenerateSwappContainer
  GENERATE_SWAPP_CONTAINER_TITLE: 'CRIAR PROJETO',
  GENERATE_SWAPP_CONTAINER_SUBTITLE: 'Insira as informações da propriedade',
  GENERATE_SWAPP_CONTAINER_PROJECT_INFO: 'INFO PROJETO',
  GENERATE_SWAPP_CONTAINER_UPLOADING_FILES: 'Enviando arquivos',
  GENERATE_SWAPP_CONTAINER_RENOVATION_TAB: 'Renovação',
  GENERATE_SWAPP_CONTAINER_GROUND_UP_TAB: 'Ground-up',
  GENERATE_SWAPP_CONTAINER_PREFERRED_FLOORS_LABEL: 'Número preferido de andares',
  GENERATE_SWAPP_CONTAINER_PREFERRED_FLOORS_ERROR: 'Insira o número preferido de andares',
  GENERATE_SWAPP_CONTAINER_PREFERRED_FLOORS_PLACEHOLDER: 'Insira o número preferido de andares, 0 for n/a',

  // ProjectInfoContainer
  PROJECT_INFO_CONTAINER_TITLE: 'INFO PROJETO',
  PROJECT_INFO_CONTAINER_SUBTITLE: 'Editar informações da propriedade',
  // MapComponent
  FLOOR_COL_TENANT_TYPE_PLACEHOLDER: 'Tipo Inquilino',
  MAP_COMPONENT_LOCATION_LABEL: 'Localização',
  MAP_COMPONENT_SEARCH_PLACEHOLDER: 'Insira o local',
  MAP_COMPONENT_SEARCH_ERROR: 'Insira Localização ',

  // PlanUpload
  PLAN_UPLOAD_CREATE_PROJECT: 'CRIAR PROJETO',
  PLAN_UPLOAD_START_TEXT: 'Envie seus planos de andares',
  PLAN_UPLOAD_START_ERROR: 'Envie Planos',
  PLAN_UPLOAD_BUTTON: 'Envie',
  PLAN_UPLOAD_NEXT_BUTTON: 'Próximo',

  // SetLocation
  SET_LOCATION_SELECT_LOCATION: 'Selecione um local para o seu futuro escritório',
  SET_LOCATION_PROJECT_NAME_LABEL: 'Nome do Projeto',
  SET_LOCATION_PROJECT_NAME_PLACEHOLDER: 'Insira o nome do projeto',
  SET_LOCATION_PROJECT_NAME_ERROR: 'Insira o nome do projeto',
  SET_LOCATION_FLOOR_LABEL: 'Pisos',
  SET_LOCATION_FLOOR_TYPE: 'Tipo',
  SET_LOCATION_FLOOR_PLACEHOLDER: 'Insira pisos',
  SET_LOCATION_FLOOR_ERROR: 'Insira pisos',
  SET_LOCATION_TYPE_PLACEHOLDER: 'Insira tipo de nome',
  SET_LOCATION_TYPE_ERROR: 'Tipo não pode estar vazio',
  SET_LOCATION_SELECT_SWAPP_CREATED: 'Criado por Swapp',
  SET_LOCATION_STATUS: 'Status',

  // ===============Feasibility===============

  // unitTypes:
  [BUILDING_INFO_KEYS.CORE]: 'Núcleo',
  [COMMUNAL_SPACES.RETAIL]: 'Retail',
  ONE_BED: '1 qt',
  TWO_BED: '2 qt',
  THREE_BED: '3 qt',
  FOUR_BED: '4 qt',
  FOUR_BED_B: '4 qt grande',
  TOTAL_APARTMENTS: 'Total Apartamentos',
  STUDIO: '',
  BRONZE: 'Bronze',
  SILVER: 'Prata',
  GOLD: 'Ouro',
  ACCESSIBLE: 'Acessível',
  WDC_STUDIO: 'Estúdio',
  WDC_1B2P: '1B2P',
  WDC_2B3P: '2B3P',
  WDC_2B4P: '2B4P',
  WDC_3B4P: '3B4P',
  WDC_3B5P: '3B5P',
  WDC_3B6P: '3B6P',
  RYAN_STUDIO: 'Estúdio',
  RYAN_ONE_BED: 'Um qt Apt.',
  RYAN_ONE_BED_B: 'Dois qt Apt. B',
  RYAN_TWO_BED: 'Tres qt Apt.',
  RYAN_THREE_BED: 'Tres qt Apt.',
  LOBBY_LOUNGE: 'Saguão/Salão',
  MAILROOM: 'sala de correspondência',
  TENNANT_CLUB: 'Inquilino Club',
  FITNESS: 'Ginástica',
  LAUNDRY: 'Lavanderia',
  GAME_ROOM: 'Sala de jogos',
  LIBRARY: 'Biblioteca',
  COMPUTER_ROOM: 'Sala de computadores',
  COMMON_GOOD: 'Bem comum',
  PETS_SHOWER_ROOM: 'Banho de animais de estimação',
  COWORKING_OFFICE: 'Escritório de Coworking',
  MECHANICAL_ROOM: 'Sala Mecânica',
  DUAL_ASPECT: 'Aspecto duplo',

  // FeasibilityApartmentsForm
  FEASIBILITY_APARTMENTS_FORM_APARTMENT_TYPE: 'Tipo de apartamento',
  FEASIBILITY_APARTMENTS_FORM_APARTMENT_SIZE: 'Apt.',
  FEASIBILITY_APARTMENTS_FORM_TARGET: 'Alvo',
  FEASIBILITY_APARTMENTS_FORM_TARGET_TOOLTIP: 'Razão de NIA',
  FEASIBILITY_APARTMENTS_FORM_APT: 'Apt.',
  FEASIBILITY_APARTMENTS_FORM_APT_TOOLTIP: 'Número de apartamentos',
  FEASIBILITY_APARTMENTS_FORM_ONE_BED: '1 Quarto',
  FEASIBILITY_APARTMENTS_FORM_TWO_BED: '2 Quartos',
  FEASIBILITY_APARTMENTS_FORM_THREE_BED: '3 Quartos',
  FEASIBILITY_APARTMENTS_FORM_FOUR_BED: '4 Quartos',
  FEASIBILITY_APARTMENTS_FORM_FOUR_BED_B: '4 Quartos Grande',
  FEASIBILITY_APARTMENTS_FORM_STUDIO: 'Estúdio',
  FEASIBILITY_APARTMENTS_FORM_RYAN_ONE_BED: 'Um Quarto Apt.',
  FEASIBILITY_APARTMENTS_FORM_RYAN_ONE_BED_B: 'Um Quarto Apt. B',
  FEASIBILITY_APARTMENTS_FORM_RYAN_TWO_BED: 'Dois Quartos Apt.',
  FEASIBILITY_APARTMENTS_FORM_RYAN_THREE_BED: 'Três Quartos Apt.',
  FEASIBILITY_APARTMENTS_FORM_RYAN_STUDIO: 'Estúdio',

  FEASIBILITY_APARTMENTS_FORM_BRONZE: 'Bronze',
  FEASIBILITY_APARTMENTS_FORM_SILVER: 'Prata',
  FEASIBILITY_APARTMENTS_FORM_GOLD: 'Ouro',
  FEASIBILITY_APARTMENTS_FORM_ACCESSIBLE: 'Acessível',
  FEASIBILITY_APARTMENTS_FORM_WDC_STUDIO: 'Estúdio',
  FEASIBILITY_APARTMENTS_FORM_WDC_1B2P: '1 qt 2 Pessoas',
  FEASIBILITY_APARTMENTS_FORM_WDC_2B3P: '2 qt 3 Pessoas',
  FEASIBILITY_APARTMENTS_FORM_WDC_2B4P: '2 qt 4 Pessoas',
  FEASIBILITY_APARTMENTS_FORM_WDC_3B4P: '3 qt 4 Pessoas',
  FEASIBILITY_APARTMENTS_FORM_WDC_3B5P: '3 qt 5 Pessoas',
  FEASIBILITY_APARTMENTS_FORM_WDC_3B6P: '3 qt 6 Pessoas',
  FEASIBILITY_APARTMENTS_FORM_TOTAL: 'Total',
  FEASIBILITY_APARTMENTS_FORM_NIA: 'NIA',
  FEASIBILITY_APARTMENTS_FORM_NIA_TOOLTIP: 'Área Interna da Rede',
  FEASIBILITY_APARTMENTS_FORM_GIA: 'GIA',
  FEASIBILITY_APARTMENTS_FORM_GIA_TOOLTIP: 'Área Interna Bruta',
  FEASIBILITY_APARTMENTS_FORM_NIA_TO_GIA: 'NIA / GIA (Alvo)',
  FEASIBILITY_APARTMENTS_FORM_CIRCULATION: 'Circulação (Est.)',

  // FeasibilityParameterForm
  FEASIBILITY_PARAMETER_FORM_TITLE: 'Valor do parâmetro',
  FEASIBILITY_PARAMETER_FORM_DESCRIPTION: 'Valor da pontuação dos parâmetros de eficiência',
  FEASIBILITY_PARAMETER_FORM_CONSTRUCTION: 'Orçamento de construção',
  FEASIBILITY_PARAMETER_FORM_MARKETABILITY: 'Comercialização',
  FEASIBILITY_PARAMETER_FORM_WELLBEING: 'Bem-estar do ocupante',
  FEASIBILITY_PARAMETER_FORM_SUSTAINABILITY: 'Sustentabilidade',
  FEASIBILITY_PARAMETER_FORM_ARCHITECTURE: 'Arquitetura e contexto urbano',
  FEASIBILITY_PARAMETER_FORM_ENERGY_EFFICIENCY: 'Eficiência energética',
  FEASIBILITY_PARAMETER_FORM_ENVIRONMENTAL: 'Condições ambientais e (luz do dia, vento, vista)',
  FEASIBILITY_PARAMETER_FORM_ERROR: 'A prioridade de design deve ter como alvo apenas uma categoria com uma pontuação de 3 ou 4. ',

  // FeasibilityBuildingForm
  FEASIBILITY_BUILDING_FORM_TITLE: 'Construindo Tipologias',
  FEASIBILITY_BUILDING_FORM_DESCRIPTION: 'Tipologias de construção preferidas para testar',
  MEGA_BLOCK: 'Megablock',
  FEASIBILITY_BUILDING_FORM_MEGA_BLOCK_DESCRIPTION: 'Massa retângulo com apartamentos em ambos os lados de um corredor. ',
  COURTYARD: 'Pátio',
  FEASIBILITY_BUILDING_FORM_COURTYARD_DESCRIPTION: 'Área circunscrita aberta para o céu, rodeada por um edifício com apartamentos em ambos os lados de um corredor. ',
  U_SHAPE: 'Forma de U',
  FEASIBILITY_BUILDING_FORM_U_COURTYARD_DESCRIPTION: 'Massa em forma de U com apartamentos em ambos os lados de um corredor e um pátio interno. ',
  NUM_OF_MASSES: 'Tipologias Mistas',
  FEASIBILITY_BUILDING_FORM_NUM_OF_MASSES_DESCRIPTION: 'Tipologias mistas que podem caber no lote ',
  CAMPUS: 'Campus',
  FEASIBILITY_BUILDING_FORM_CAMPUS_DESCRIPTION: 'Série de pequenos edifícios que se espalham e cobrem toda a área do lote. ',
  L_SHAPE: 'Forma de L',
  FEASIBILITY_BUILDING_FORM_L_SHAPE_DESCRIPTION: 'Massa em forma de L com apartamentos em ambos os lados de um corredor. ',
  FINGER_BLOCK: 'Bloco de dedo',
  FEASIBILITY_BUILDING_FORM_FINGER_BLOCK_DESCRIPTION: 'Massa com mais de duas alas, interseção central e apartamentos em ambos os lados de um corredor.',
  WRAP: 'Enrolar',
  FEASIBILITY_BUILDING_FORM_WRAP_DESCRIPTION: 'Estrutura de estacionamento acima do nível cercada por um prédio \n com apartamentos em um lado de um corredor. ',
  FEASIBILITY_BUILDING_FORM_COUNT_TEXT: 'Selecione no máximo três tipologias preferidas para estudar',

  // FeasibilityTargetFormContainer
  FEASIBILITY_TARGET_FORM_CONTAINER_APARTMENT_STANDARD: 'Apartamento Padrão',
  FEASIBILITY_TARGET_FORM_CONTAINER_BUILDING_RIGHTS: 'Direitos de construção',
  FEASIBILITY_TARGET_FORM_CONTAINER_COMMUNAL_SPACE: 'Espaço Comunal',
  FEASIBILITY_TARGET_FORM_CONTAINER_DESIGN_PRIORITY: 'Prioridade de Design',
  FEASIBILITY_TARGET_FORM_CONTAINER_BUILDING_TYPOLOGIES: 'Construindo Tipologias',
  FEASIBILITY_TARGET_FORM_CONTAINER_SITES: 'Site',
  FEASIBILITY_TARGET_FORM_CONTAINER_DESIGN_REQUIREMENTS: 'Requisitos de design',
  FEASIBILITY_TARGET_FORM_CONTAINER_MECHANICAL_ELECTRIC_PLUMBING: 'Mecânico | Elétrico | Encanamento',
  FEASIBILITY_TARGET_FORM_CONTAINER_STEP_ONE: 'Calculando Direitos de Construção',
  FEASIBILITY_TARGET_FORM_CONTAINER_STEP_TWO: 'Organização de mistura de apartamentos',
  FEASIBILITY_TARGET_FORM_CONTAINER_STEP_THREE: 'Verificando Tipologias',
  FEASIBILITY_TARGET_FORM_CONTAINER_STEP_FOUR: 'Colocação no local',
  FEASIBILITY_TARGET_FORM_CONTAINER_STEP_FIVE: 'Otimizando Resultados',
  FEASIBILITY_TARGET_FORM_CONTAINER_ERROR_GENERAL_TITLE: 'Algo deu errado ',
  FEASIBILITY_TARGET_FORM_CONTAINER_ERROR_GENERAL_DESCRIPTION: 'Aguarde alguns segundos e tente enviar uma nova solicitação clicando o botão \'Atualizar \'',
  FEASIBILITY_TARGET_FORM_CONTAINER_ERROR_EMPTY_TITLE: 'Algo deu errado',
  FEASIBILITY_TARGET_FORM_CONTAINER_ERROR_EMPTY_DESCRIPTION: 'O programa requerido não cabe no lote. \n Por favor, tente mudar as Histórias Alvo, a linha de construção, o NIA, etc., e então pressione o botão \'Atualizar \' novamente.',
  FEASIBILITY_TARGET_FORM_PARKING_STEP: 'Planejando um estacionamento',
  FEASIBILITY_TARGET_FORM_SKY_STEP: 'Executando Análise de Sol',
  FEASIBILITY_TARGET_FORM_SUN_STEP: 'Executando Análise VSC',
  FEASIBILITY_TARGET_FORM_VIEW_STEP: 'Executando Análise de Vista',
  FEASIBILITY_TARGET_FORM_WIND_STEP: 'Executando Análise de Ventos',
  FEASIBILITY_TARGET_FORM_REQUEST_GENERATED: 'Sua solicitação está sendo gerada',
  FEASIBILITY_TARGET_FORM_CARD_TYPE_APARTMENT_MIXTURE: 'Apartamento Mistos',
  FEASIBILITY_TARGET_FORM_CARD_TYPE_FINANCIAL_VIABILITY: 'Viabilidade financeira',
  FEASIBILITY_TARGET_FORM_CARD_TYPE_HVAC: 'HVAC Design',
  FEASIBILITY_TARGET_FORM_CARD_FINANCIAL_TOTAL_COST: 'Custo total',
  FEASIBILITY_TARGET_FORM_CARD_FINANCIAL_TOTAL_SALE_REVENUE: 'Renda total de vendas ',
  FEASIBILITY_TARGET_FORM_CARD_FINANCIAL_TOTAL_RENT_REVENUE: 'Renda total de aluguel (anual) ',
  FEASIBILITY_TARGET_FORM_CARD_FINANCIAL_TIME_TO_PROFIT: 'Hora de lucrar',
  FEASIBILITY_TARGET_FORM_CARD_EMPTY_LOT: 'Crie manualmente um design de modelo com as ferramentas do editor ',
  FEASIBILITY_TARGET_FORM_CARD_EMPTY_HVAC: 'Sem dados HVAC',

  // FeasibilityApartmentStandardForm
  FEASIBILITY_APARTMENT_STANDARD_FORM_PREFAB: 'Padrão Pré-fabricado',
  FEASIBILITY_APARTMENT_STANDARD_FORM_PREFAB_DESCRIPTION: 'Apartamentos de tamanho fixo (estúdio a quatro grandes quartos), com núcleos de circulação (elevadores e escadas) adjacentes à fachada exterior. ',
  FEASIBILITY_APARTMENT_STANDARD_FORM_DORMITORIES: 'Dormitórios Padrão',
  FEASIBILITY_APARTMENT_STANDARD_FORM_DORMITORIES_DESCRIPTION: 'Vários tipos de quartos (variados por tamanho com banheiro privativo). Layouts de grupos de quartos com cozinhas compartilhadas e espaços de amenidades. Núcleos de circulação (elevadores e escadas) adjacentes à fachada exterior. ',
  FEASIBILITY_APARTMENT_STANDARD_FORM_UK: 'UK Padrão',
  FEASIBILITY_APARTMENT_STANDARD_FORM_UK_DESCRIPTION: 'apartamentos de tamanho padrão (estúdios a três dormitórios; apartamentos para seis pessoas), com núcleos de circulação interna (elevadores e escadas). Maximize a fachada exterior do apartamento. ',
  FEASIBILITY_APARTMENT_STANDARD_FORM_RYAN: 'Ryan Padrão',
  FEASIBILITY_APARTMENT_STANDARD_FORM_RYAN_DESCRIPTION: 'Com base no padrão de apartamentos da empresa (estúdio a três quartos). \n Núcleos de circulação (elevadores e escadas) adjacentes à fachada exterior.',

  // FeasibilityBuildingRightsForm
  FEASIBILITY_BUILDING_RIGHTS_FORM_PERCENTAGE_TARGET: 'Meta de porcentagem',
  FEASIBILITY_BUILDING_RIGHTS_FORM_APARTMENTS_COUNT: 'Contagem de apartamentos',
  FEASIBILITY_BUILDING_RIGHTS_FORM_TOTAL: 'Total',
  FEASIBILITY_BUILDING_RIGHTS_FORM_ADD_ANOTHER: 'Adicionar outro tipo de apartamento',
  FEASIBILITY_BUILDING_RIGHTS_FORM_ADD_ANOTHER_ERROR: 'Todos os tipos de apartamentos disponíveis foram explorados ',
  FEASIBILITY_BUILDING_RIGHTS_FORM_TOTAL_TOOLTIP: 'A meta total deve ser 100%',

  // FeasibilityDesignRequirementsForm
  FEASIBILITY_DESIGN_REQUIREMENTS_FORM_SPRINKLERS: 'Sprinklers',
  FEASIBILITY_DESIGN_REQUIREMENTS_FORM_DEAD_END_CORRIDOR: 'Corredor sem saída',
  FEASIBILITY_DESIGN_REQUIREMENTS_FORM_MULTIPLE_FLOOR_GROUP: 'Grupo de vários andares ',
  FEASIBILITY_DESIGN_REQUIREMENTS_FORM_NATURAL_LIGHT_CORRIDORS: 'Corredores com luz natural ',
  FEASIBILITY_DESIGN_REQUIREMENTS_FORM_SINGLE_LOAD_CORRIDOR: 'Corredor de carga única ',

  // FeasibilityLotInfoForm
  FEASIBILITY_LOT_INFO_FORM_AREA: 'Área Lote',
  FEASIBILITY_LOT_INFO_FORM_NIA: 'MP-NIA',
  FEASIBILITY_LOT_INFO_FORM_GIA: 'MP-GIA',
  FEASIBILITY_LOT_INFO_FORM_STORIES: 'Max Andares',

  // FeasibilitySitesForm
  FEASIBILITY_SITES_FORM_SELECT: 'R = Área Residencial, S = Área Loja, P = Área Estacionamento. Por favor selecione uma variante',
  FEASIBILITY_SITES_FORM_MIXED_TYPOLOGIES: 'Swapp temporáriamente não pode gerar "Tipologias Mistas" com sites de zonas de vários lotes. Selecione uma tipologia de construção diferente.',
  FEASIBILITY_SITES_FORM_TARGET_STORIES: 'Target Stories',
  FEASIBILITY_SITES_FORM_MAX_STORIES: 'Max Stories',
  FEASIBILITY_SITES_FORM_OPEN_TERRAIN: 'Terreno Aberto Mínimo',
  FEASIBILITY_SITES_FORM_SETBACK_LINE: 'Linha de construção',
  FEASIBILITY_SITES_FORM_OPEN_GROUND_FLOOR: 'Piso térreo aberto',
  FEASIBILITY_SITES_FORM_OPEN_GROUND_FLOOR_TOOLTIP: 'Estacionamento',

  // FeasibilityCommunalSpaceForm
  FEASIBILITY_COMMUNAL_SPACE_FORM_SPACE_TYPE: 'Tipo',
  FEASIBILITY_COMMUNAL_SPACE_FORM_RATIO_TYPE: 'Área',
  FEASIBILITY_COMMUNAL_SPACE_FORM_RATIO: 'Ratio/',
  FEASIBILITY_COMMUNAL_SPACE_FORM_SPREAD: 'Espalhar',
  FEASIBILITY_COMMUNAL_SPACE_FORM_AREA: 'M²',
  FEASIBILITY_COMMUNAL_SPACE_ADD_ANOTHER: 'Adicione outro tipo comum',
  FEASIBILITY_COMMUNAL_SPACE_TOTAL: 'Total',
  FEASIBILITY_COMMUNAL_SPACE_TOO_LARGE_WARNING: 'Verifique novamente a aba Espaço Comunal, parece uma área de área excepcionalmente grande.\n',
  [`FEASIBILITY_COMMUNAL_SPACE_FORM_${COMMUNAL_SPACES.OTHER}`]: 'Outro',
  [`FEASIBILITY_COMMUNAL_SPACE_FORM_${COMMUNAL_SPACES_RATIO_TYPE.ROOM_AREA}`]: 'Espaço',
  [`FEASIBILITY_COMMUNAL_SPACE_FORM_${COMMUNAL_SPACES_RATIO_TYPE.PER_APARTMENT}`]: 'por unidade',
  [`FEASIBILITY_COMMUNAL_SPACE_FORM_${COMMUNAL_SPACES_SPREAD.PER_BUILDING}`]: 'Construção',
  [`FEASIBILITY_COMMUNAL_SPACE_FORM_${COMMUNAL_SPACES_SPREAD.ENTIRE_PROJECT}`]: 'Site',
  [`FEASIBILITY_COMMUNAL_SPACE_FORM_${COMMUNAL_SPACES_SPREAD.PER_FLOOR}`]: 'Andares',

  // FeasibilityResultData
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.BCR]}`]: 'BCR',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.NIA]}`]: 'NIA',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.GIA]}`]: 'GIA',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.NIA_TO_GIA]}`]: 'NIA / GIA',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.FAR]}`]: 'FAR',
  [`FEASIBILITY_RESULT_DATA_TOOLTIP_${[BUILDING_INFO_KEYS.BCR]}`]: 'Taxa de cobertura de edifícios',
  [`FEASIBILITY_RESULT_DATA_TOOLTIP_${[BUILDING_INFO_KEYS.NIA]}`]: 'Área Interna da Rede',
  [`FEASIBILITY_RESULT_DATA_TOOLTIP_${[BUILDING_INFO_KEYS.GIA]}`]: 'Área Interna Bruta',
  [`FEASIBILITY_RESULT_DATA_TOOLTIP_${[BUILDING_INFO_KEYS.NIA_TO_GIA]}`]: 'Rácio entre a área líquida e bruta.',
  [`FEASIBILITY_RESULT_DATA_TOOLTIP_${[BUILDING_INFO_KEYS.FULL_CORE]}`]: 'Dois elevadores e uma escada de escape',
  [`FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP_${[BUILDING_INFO_KEYS.BCR]}`]: 'Uma porcentagem da área do lote',
  [`FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP_${[BUILDING_INFO_KEYS.COMMUNAL_AREA]}`]: 'Porcentagem de GIA',
  [`FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP_${[BUILDING_INFO_KEYS.CORE]}`]: 'Porcentagem de GIA',
  [`FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP_${[BUILDING_INFO_KEYS.ONE_FLAT_ASPECTS]}`]: 'Uma porcentagem do número total de unidades',
  [`FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP_${[BUILDING_INFO_KEYS.TWO_FLAT_ASPECTS]}`]: 'Uma porcentagem do número total de unidades',
  [`FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP_${[BUILDING_INFO_KEYS.THREE_FLAT_ASPECTS]}`]: 'Uma porcentagem do número total de unidadess',
  [`FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP_${[BUILDING_INFO_KEYS.ROOF_APARTMENT]}`]: 'Uma porcentagem do número total de unidadess',
  [`FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP_${[BUILDING_INFO_KEYS.GARDEN_APARTMENT]}`]: 'Uma porcentagem do número total de unidadess',
  [`FEASIBILITY_RESULT_DATA_VALUE_TOOLTIP_${[BUILDING_INFO_KEYS.TOTAL_APARTMENTS]}`]: 'Modelo / Alvo',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.STORIES]}`]: 'Stories',
  [`FEASIBILITY_RESULT_DATA_${[COMMUNAL_SPACES.ELEVATORS_CORE]}`]: 'Núcleo de elevadores',
  [`FEASIBILITY_RESULT_DATA_${[COMMUNAL_SPACES.STAIRCASE_CORE]}`]: 'Núcleo de escadas',
  [`FEASIBILITY_RESULT_DATA_${[COMMUNAL_SPACES.FULL_CORE]}`]: 'Núcleo geral',
  [`FEASIBILITY_RESULT_DATA_${[COMMUNAL_SPACES.CORRIDOR]}`]: 'Corredores',
  [`FEASIBILITY_RESULT_DATA_${[COMMUNAL_SPACES.OTHER]}`]: 'Outro',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.COMMUNAL_AREA]}`]: 'Área Comunal',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.CORE]}`]: 'Circulação',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.ENVELOPE_AREA]}`]: 'Área do Envelope',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.NO_OF_MASSES]}`]: 'Número de Massa',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.FULL_CORE]}`]: 'Núcleo',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.APARTMENT_PER_FLOOR]}`]: 'Apt por andar',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.APARTMENT_PER_BUILDING]}`]: 'Apt por Edificio',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.ONE_FLAT_ASPECTS]}`]: '1 Aspectos planos',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.TWO_FLAT_ASPECTS]}`]: '2 Aspectos planos',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.NON_TYPICAL_APARTMENTS]}`]: 'Apartamentos Não Típicos',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.THREE_FLAT_ASPECTS]}`]: '3+ Aspectos planos',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.ROOF_APARTMENT]}`]: 'Apt Cobertura.',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.GARDEN_APARTMENT]}`]: 'Apt Jardim',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.NORTH]}`]: 'Norte',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.SOUTH]}`]: 'Sul',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.EAST]}`]: 'Este',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.WEST]}`]: 'Oeste',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.PRIORITY_FIT_SCORE]}`]: 'Pontuação de ajuste de prioridade',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.CONST_COST_SCORE]}`]: 'Custo Construção.',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.MARKETABILITY_SCORE]}`]: 'Comercialização',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.WELLBING_SCORE]}`]: 'Bem-estar do ocupante',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.SUFFICIENT_DAYLIGHT]}`]: 'Apt. com luz do dia suficiente',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.SUFFICIENT_VIEW]}`]: 'Apt. com visão suficiente',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.SUFFICIENT_WIND]}`]: 'Apt. com vento suficiente',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.SUFFICIENT_VSC]}`]: 'Apt. com suficiente VSC',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.PH_HLFF]}`]: 'Fator de forma de perda de calor (HLFF)',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.PH_SVR]}`]: 'Proporção de superfície para volume (SVR)',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.PARKING_AREA]}`]: 'Estacionamento',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.PARKING_PERCENTAGE]}`]: 'Porcentagem de estacionamento',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.NUM_OF_PARKING]}`]: 'Nº de vagas de estacionamento',
  [`FEASIBILITY_RESULT_DATA_${[BUILDING_INFO_KEYS.PARKING_RATIO]}`]: 'Vagas de estacionamento por apartamento',
  [`FEASIBILITY_RESULT_DATA_${[MODEL_ANALYSIS_TYPES.AREA_TYPE]}`]: 'Tipo de Área',
  [`FEASIBILITY_RESULT_DATA_${[MODEL_ANALYSIS_TYPES.SUN_ANALYSIS]}`]: 'Análise do Sol',
  [`FEASIBILITY_RESULT_DATA_${[MODEL_ANALYSIS_TYPES.VIEWS_ANALYSIS]}`]: 'Análise de Vista',
  [`FEASIBILITY_RESULT_DATA_${[MODEL_ANALYSIS_TYPES.WIND_ANALYSIS]}`]: 'Análise de Vento',
  [`FEASIBILITY_RESULT_DATA_${[MODEL_ANALYSIS_TYPES.VSC_ANALYSIS]}`]: ' Análise de VSC',
  [`FEASIBILITY_RESULT_DATA_${[MODEL_ANALYSIS_TYPES.VSC_ANALYSIS]}_TOOLTIP`]: 'Componente vertical do céu',
  [`FEASIBILITY_RESULT_DATA_${[HVAC.COOLING_LOAD_PEAK]}`]: 'Pico de carga de resfriamento',
  [`FEASIBILITY_RESULT_DATA_${[HVAC.COOLING_LOAD_DENSITY]}`]: 'Densidade da carga de resfriamento',
  [`FEASIBILITY_RESULT_DATA_${[HVAC.HEATING_LOAD_PEAK]}`]: 'Pico de carga de aquecimento',
  [`FEASIBILITY_RESULT_DATA_${[HVAC.HEATING_LOAD_DENSITY]}`]: 'Densidade de carga de aquecimento',
  [`FEASIBILITY_RESULT_DATA_${[HVAC.ENERGY_USE_INTENSITY]}`]: 'Intensidade de uso de energia(EUI)',
  FEASIBILITY_RESULT_DATA_MODELS_TITLE: 'Modelos',
  FEASIBILITY_RESULT_DATA_MODELS_SUBTITLE: 'Análise programática e de bem-estar do ocupante',
  FEASIBILITY_RESULT_DATA_ENHANCED_APARTMENTS: 'Apartamentos aprimorados',
  FEASIBILITY_RESULT_DATA_APARTMENT_ORIENTATION: 'Orientação do apartamento',
  FEASIBILITY_RESULT_DATA_EFFICIENCY_SCORE: 'Pontuação de Eficiência',
  FEASIBILITY_RESULT_DATA_WELLBEING_ANALYSIS: 'Análise de Bem-Estar',
  FEASIBILITY_RESULT_DATA_PASSIVE_HOUSE: 'Casa Passiva',
  FEASIBILITY_RESULT_DATA_APT_MIX: 'Apt. Mix',
  FEASIBILITY_RESULT_DATA_PERCENTAGE_TOOLTIP: 'Porcentagem de GIA',
  FEASIBILITY_RESULT_DATA_PARKING: 'Estacionamento',
  FEASIBILITY_RESULT_DATA_DEVELOPMENT_APPRAISAL: 'Avaliação de Desenvolvimento',

  FEASIBILITY_RESULT_DATA_AREA_SUMMARY: 'Resumo da Área',
  FEASIBILITY_RESULT_DATA_UNITS_COUNT: 'Contagem de unidades',
  FEASIBILITY_RESULT_DATA_COMMUNAL_SPACE: 'Espaço Comunal',
  FEASIBILITY_RESULT_DATA_PARKING_TABLE_TYPE: 'Tipo',
  FEASIBILITY_RESULT_DATA_PARKING_TABLE_FLOORS: 'Andares',
  FEASIBILITY_RESULT_DATA_PARKING_TABLE_STALL: 'Parar',
  FEASIBILITY_RESULT_DATA_PARKING_TABLE_STALLS: 'Parar',
  FEASIBILITY_RESULT_DATA_PARKING_TABLE_TOTAL_COST: 'Total Custo',
  FEASIBILITY_RESULT_DATA_EXPENDITURE_TABLE_EXPENDITURE: 'Despesa',
  FEASIBILITY_RESULT_DATA_EXPENDITURE_TABLE_INCOME: 'Renda',
  FEASIBILITY_RESULT_DATA_EXPENDITURE_TABLE_AREA: 'Área',
  FEASIBILITY_RESULT_DATA_EXPENDITURE_TABLE_BUILD_COST_PER: 'Custo de construção por',
  FEASIBILITY_RESULT_DATA_EXPENDITURE_TABLE_INCOME_PER: 'Renda por',
  FEASIBILITY_RESULT_DATA_EXPENDITURE_TABLE_TOTAL_COST: 'Total Custo',
  FEASIBILITY_RESULT_DATA_EXPENDITURE_TABLE_TOTAL_INCOME: 'Total Renda',
  FEASIBILITY_RESULT_DATA_SALE: 'Venda',
  FEASIBILITY_RESULT_DATA_RENT: 'Aluguel (mensal)',
  FEASIBILITY_RESULT_DATA_NUM_OF_PARKING: 'Número de vagas de estacionamento',
  FEASIBILITY_RESULT_DATA_PARKING_RATIO: 'Vagas de estacionamento por apt',
  FEASIBILITY_RESULT_DATA_PARKING_TYPE_NUM_OF_BELOW_GROUND_PARKING: 'Estacionamento abaixo do nível',
  FEASIBILITY_RESULT_DATA_PARKING_TYPE_NUM_OF_PRECAST_PARKING: 'Estacionamento acima do nível (precast)',
  FEASIBILITY_RESULT_DATA_PARKING_TYPE_NUM_OF_PODIUM_PARKING: 'Estacionamento acima do nível (podium)',
  FEASIBILITY_RESULT_DATA_PARKING_TYPE_NUM_OF_SURFACE_PARKING: 'Estacionamento de superfície',
  FEASIBILITY_RESULT_DATA_DEVELOPMENT_APPRAISAL_NIA: 'Área de apartamentos',
  FEASIBILITY_RESULT_DATA_DEVELOPMENT_APPRAISAL_COMMUNAL_AREA: 'Comunal',
  FEASIBILITY_RESULT_DATA_DEVELOPMENT_APPRAISAL_CORRIDOR: 'Corredores',
  FEASIBILITY_RESULT_DATA_DEVELOPMENT_APPRAISAL_CORE_AND_MECHANICAL: 'Núcleo e Mecânico',
  FEASIBILITY_RESULT_DATA_DEVELOPMENT_APPRAISAL_PARKING_AREA: 'Estacionamento',
};
