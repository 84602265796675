import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import T from 'i18n-react';
import { useDispatch } from 'react-redux';
import { Tooltip, Modal, Popover } from 'antd';
import Icon from '@ant-design/icons';
import lodashFilter from 'lodash/filter';
import lodashSortBy from 'lodash/sortBy';
import { numberWithComma, renderProjectName } from 'utils/helpers/dataDisplay';
import { ColorPill } from 'styles/commonComponents.styles';
import { navigateToMostRelevant } from 'utils/helpers/navigationHelpers';
import {
  ICON_BY_STATUS,
  PROJECT_STAGES,
  PROJECT_STATUS,
  STATUS_ORDER,
  PROFILE_STATUS } from 'constants/profileConsts';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import { GENERATE_SWAPP_FORM } from 'constants/fieldNames';
import { DATE_OPTIONS } from 'constants/dateConsts';
import { projectionByStage } from 'constants/globalConst';
import { useOverridableSetting } from 'store/userSettings';
import { editProjectAction } from 'store/swappProfile/actions/swappProfileActions';
import {
  StyledCardWrapper,
  CardBody,
  CardImage,
  CardName,
  CardInfo,
  CardInfoIcon,
  CardFooter,
  Percentage,
  DateWrapper,
  StyledArchivedIcon,
  ProfileStatus,
  StyledStatus,
  StatusIconWrapper,
  FooterIcon,
  StageWrapper,
  LocationText,
  LocationWrapper,
  PercentageWrapper,
  LocationTextWrapper,
  CardImageWrapper,
  TopPart,
  StyledCardsWrapper,
} from './ProjectCards.styles';

import NewProjectCard from './NewProjectCard';
import locationIcon from '../../styles/static/icons/comon/locationIcon';
import lsfPercentageIcon from '../../styles/static/icons/comon/lsfPercentageIcon';
import archiveIcon from '../../styles/static/icons/comon/archiveIcon';

const { confirm } = Modal;

const ProjectCards = (props) => {
  const { userProjects, toggleModal, isReadOnly } = props;
  const hideLsf = useOverridableSetting('hideLsfInProjectCard', false);
  const dispatch = useDispatch();
  const history = useHistory();

  const openSwapp = (projectId) => {
    const [userProject] = userProjects.filter((item) => item.id === projectId);
    navigateToMostRelevant(userProject, history);
  };

  const renderPercentage = (percentage) => {
    if (hideLsf) {
      return null;
    }

    if (percentage) {
      return (
        <PercentageWrapper>
          <CardInfoIcon component={lsfPercentageIcon} />
          <Percentage isPositive={percentage > 0}>
            {`${T.translate('AREA_TARGET_FORM_CONTAINER_LSF')} ${numberWithComma(percentage, { suffix: '%', fixed: 2 })}`}
          </Percentage>
        </PercentageWrapper>
      );
    }
  };

  const showArchiveConfirm = (projectId, e) => {
    e.stopPropagation();
    confirm({
      width: 450,
      title: T.translate('SET_LOCATION_ARCHIVE_MODAL_TITLE'),
      content: T.translate('SET_LOCATION_ARCHIVE_MODAL_DESCRIPTION'),
      cancelText: T.translate('SET_LOCATION_ARCHIVE_MODAL_CANCEL'),
      okText: T.translate('SET_LOCATION_ARCHIVE_MODAL_OK'),
      okType: 'danger',
      onOk() { dispatch(editProjectAction(projectId, { [GENERATE_SWAPP_FORM.STATUS]: PROJECT_STATUS.ARCHIVED })); },
    });
  };

  const renderProfileStatusList = (projectProfiles, stage) => {
    const filteredProjects = lodashFilter(projectProfiles, (profile) => profile.stage === PROJECT_STAGES[stage] && profile.status !== PROFILE_STATUS.WAITING_FOR_SUBMISSION);
    const sortedProfiles = lodashSortBy(filteredProjects, [(profile) => STATUS_ORDER[profile.status]], 'id');

    return sortedProfiles.map((profile) => (
      <ProfileStatus key={profile.id}>
        <StatusIconWrapper>{ICON_BY_STATUS[profile.status]}</StatusIconWrapper>
        {T.translate(profile.name)}:
        <StyledStatus>{T.translate(`PROFILE_STATUS.${profile.status}`)}</StyledStatus>
      </ProfileStatus>
    ));
  };

  const renderLocationString = (location) => {
    const locationText = location.split(', ');
    let parsedText = { line1: '', line2: '' };

    if (locationText.length === 2) {
      parsedText = { line1: locationText[0], line2: locationText[1] };
    }

    if (locationText.length > 2) {
      parsedText = {
        line1: [...locationText].splice(0, locationText.length - 2).join(', '),
        line2: [...locationText].splice(locationText.length - 2, 2).join(', ') };
    }

    return (
      <LocationTextWrapper>
        <LocationText>{parsedText.line1}</LocationText>
        <LocationText>{parsedText.line2}</LocationText>
      </LocationTextWrapper>
    );
  };

  return (
    <StyledCardsWrapper>
      {userProjects.reverse().map((item) => {
        if (item.newProjectCard) {
          return <NewProjectCard toggleModal={toggleModal} disabled={isReadOnly} />;
        }

        const createdDate = new Date(item.createdAt).toLocaleDateString('en-US', DATE_OPTIONS);
        const stage = UI_AUTHORIZE_PATH[item.stage];
        const readyTestFitProfiles = item.projectProfiles.map((profile) => ((profile.status === PROFILE_STATUS.READY) && (profile.stage === PROJECT_STAGES[UI_AUTHORIZE_PATH.TEST_FIT]) ? profile.result.RSF : null));
        let largestPercentage;

        if (readyTestFitProfiles) {
          const percentageArray = readyTestFitProfiles.map((rsf) => rsf && ((rsf.sections[0].modelArea * 100) / rsf.totalArea) - (rsf.sections[0].targetRatio * 100));
          // eslint-disable-next-line prefer-spread
          largestPercentage = Math.max.apply(Math, percentageArray);
        }

        return (
          <StyledCardWrapper key={item.id} onClick={() => openSwapp(item.id)}>
            {/* =========== Top Part =========== */}
            <TopPart>
              {/* =========== Image =========== */}
              <CardImageWrapper>
                {item.imageUrl
                  ? <CardImage src={item.imageUrl} />
                  : <Icon component={projectionByStage[stage]} />}
              </CardImageWrapper>

              {/* =========== Card Body =========== */}
              <CardBody>
                {/* =========== Card Name =========== */}
                <CardName>{renderProjectName(item.name)}</CardName>

                <CardInfo>
                  {/* =========== Location =========== */}
                  <Tooltip title={item.locationName} placement="topLeft" mouseEnterDelay={0.5}>
                    <LocationWrapper onClick={(e) => e.stopPropagation()} href={`https://www.google.com/maps/search/?api=1&query=${item.lat},${item.lng}`} target="_blank">
                      <CardInfoIcon component={locationIcon} />
                      {renderLocationString(item.locationName)}
                    </LocationWrapper>
                  </Tooltip>

                  {/* =========== LSF Percentage =========== */}
                  {renderPercentage(largestPercentage)}

                  {/* =========== Stage =========== */}
                  <StageWrapper>
                    <ColorPill margin="0 20px 0 5px" stage={stage} />
                    {`${T.translate('SWAPP_CART_LIST_STAGE')}: ${T.translate(stage)}`}
                  </StageWrapper>
                </CardInfo>
              </CardBody>
            </TopPart>

            {/* =========== Card Footer =========== */}
            <CardFooter>
              {/* =========== Date =========== */}
              <DateWrapper>{`${T.translate('SWAPP_CART_LIST_CREATED')}: ${createdDate}`}</DateWrapper>
              <span>
                {/* =========== Archived =========== */}
                {item.status !== PROJECT_STATUS.ARCHIVED && (
                  <Tooltip title={T.translate('SWAPP_CART_LIST_ARCHIVE')}>
                    <span onClick={(e) => showArchiveConfirm(item.id, e)} aria-hidden="true">
                      <StyledArchivedIcon component={archiveIcon} />
                    </span>
                  </Tooltip>
                )}

                {/* =========== Profiles Status =========== */}
                {stage === UI_AUTHORIZE_PATH.TEST_FIT && (
                  <Popover
                    title={(
                      <StageWrapper>
                        <ColorPill stage={stage} />
                        {`${T.translate('SWAPP_CART_LIST_STAGE')}: ${T.translate(stage)}`}
                      </StageWrapper>
                          )}
                    content={renderProfileStatusList(item.projectProfiles, stage)}
                  >
                    <FooterIcon />
                  </Popover>
                )}
              </span>
            </CardFooter>
          </StyledCardWrapper>
        );
      })}
    </StyledCardsWrapper>
  );
};

ProjectCards.propTypes = {
  userProjects: PropTypes.array,
  toggleModal: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

ProjectCards.defaultProps = {
  userProjects: [],
};

export default ProjectCards;
