import React from 'react';

// currentcolor
export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.3319 23.998C18.1168 23.998 16.8974 23.5387 15.9675 22.6212C14.7615 21.431 14.296 19.7247 14.7238 18.1173L6.93593 10.4331C5.69102 10.847 4.37899 10.7884 3.2155 10.2614C0.875551 9.20157 -0.495654 6.21218 0.158424 3.59722C0.192076 3.46261 0.295352 3.35624 0.428991 3.31872C0.562437 3.28159 0.706133 3.31814 0.804961 3.41542L3.6551 6.21992C3.98523 6.13714 4.7569 5.90932 5.48621 5.68381C5.62313 5.30533 5.93219 4.25517 6.03024 3.83781L3.17546 1.03042C3.08128 0.937777 3.04086 0.803364 3.06832 0.674173C3.09578 0.544982 3.18726 0.438612 3.31104 0.392389C5.3839 -0.382758 7.66331 0.0630292 9.11826 1.52881C10.4274 2.8476 10.9055 4.89764 10.3668 6.80959L18.421 14.7572C19.9452 14.4907 21.4937 14.9713 22.5998 16.0624C23.5028 16.9534 24 18.1349 24 19.3893C24 20.6257 23.5148 21.7853 22.6338 22.6547C21.7256 23.5507 20.5308 23.998 19.3319 23.998ZM7.03457 9.60016C7.13455 9.60016 7.23261 9.63884 7.30629 9.71156L15.4316 17.729C15.5357 17.8315 15.5728 17.9839 15.5281 18.1229C15.0792 19.5167 15.4558 21.0293 16.5108 22.0704C18.0584 23.5975 20.5613 23.6128 22.0904 22.1039C22.823 21.3811 23.2264 20.417 23.2264 19.3893C23.2264 18.3436 22.811 17.3574 22.0565 16.6132C21.0874 15.6571 19.7123 15.2604 18.3781 15.5538C18.2502 15.5822 18.1166 15.5433 18.0234 15.4515L9.65707 7.1962C9.55263 7.09312 9.5155 6.93995 9.56114 6.8005C10.1181 5.09742 9.72882 3.24214 8.5692 2.074C7.46508 0.961566 5.79391 0.542081 4.17979 0.933329L6.71352 3.4249C6.88236 3.59509 6.83594 3.78888 6.77715 4.0343C6.7083 4.32208 6.56576 4.83053 6.42265 5.29953C6.14512 6.20889 6.10006 6.29902 5.88693 6.36942C3.46518 7.1231 3.44893 7.10685 3.25031 6.90668L0.788908 4.48493C0.589126 6.56378 1.7238 8.73644 3.53461 9.55645C4.58225 10.0311 5.77611 10.0554 6.89609 9.62569C6.94115 9.60848 6.98796 9.60016 7.03457 9.60016ZM3.7897 6.3524L3.79589 6.35839C3.79396 6.35646 3.79183 6.35433 3.7897 6.3524Z" fill="currentcolor" />
    <path d="M19.4721 21.935C18.853 21.935 18.2341 21.7026 17.763 21.2378C17.3049 20.7856 17.0525 20.184 17.0525 19.5438C17.0525 18.9037 17.3049 18.3022 17.763 17.8502C18.7055 16.92 20.2387 16.9204 21.1814 17.8502C21.6395 18.3022 21.8919 18.9037 21.8919 19.5438C21.8919 20.184 21.6395 20.7856 21.1814 21.2378C20.7101 21.7026 20.091 21.935 19.4721 21.935ZM18.3063 20.687C18.949 21.3212 19.9951 21.3212 20.6379 20.687C20.9477 20.3814 21.1183 19.9753 21.1181 19.5438C21.1181 19.1125 20.9476 18.7066 20.6379 18.401C19.9953 17.7669 18.9493 17.7665 18.3063 18.401C17.9965 18.7066 17.8261 19.1125 17.8261 19.5438C17.8259 19.9755 17.9967 20.3814 18.3063 20.687Z" fill="currentcolor" />
    <path d="M16.4881 16.6843C16.3898 16.6843 16.2916 16.6472 16.2161 16.5725L8.54028 8.98407C8.38846 8.83399 8.38711 8.58915 8.53719 8.43713C8.68746 8.28531 8.9323 8.28396 9.08431 8.43404L16.76 16.0223C16.9118 16.1724 16.9131 16.4172 16.7631 16.5692C16.6874 16.646 16.5877 16.6843 16.4881 16.6843Z" fill="currentcolor" />
  </svg>
);
