import { createSlice } from '@reduxjs/toolkit';
import { FEASIBILITY_EDITOR_BUTTON_KEYS } from '../constants/feasibilityConts';
import { vecAngle, vecAdd } from '../utils/algorithms/algorithmHelpers';

const initialState = {
  points: [], // The buildings that are currently being transforms
  pointInProgress: null, // The transforms of each building
  isCreating: true,
  isClosed: false,
  parkingEntrancePosition: null,
  globalPosition: [0, 0, 0],
  massIndexInEdit: null,
};

const removeZFromPoints = (points) => points.map((p) => [p[0], p[1]]);

const sketchToolSlice = createSlice({
  name: 'sketchtool',
  initialState,
  reducers: {
    setPoints(state, action) {
      const points = removeZFromPoints(action.payload);
      state.points = points;
      if (points.length <= 0) {
        state.pointInProgress = null;
      }
    },
    setPointInProgress(state, action) {
      state.pointInProgress = action.payload;
    },
    setIsCreating(state, action) {
      state.isCreating = action.payload;
    },
    setIsClosed(state, action) {
      state.isClosed = action.payload;
    },
    setParkingEntrancePosition(state, action) {
      if (action.payload && action.payload.position) {
        action.payload.globalPosition = vecAdd(action.payload.position, state.globalPosition);
      }
      state.parkingEntrancePosition = action.payload;
    },
    setMovingVector(state, action) {
      state.movingVector = action.payload;
    },
    setMovingPoint(state, action) {
      state.movingPoint = action.payload;
    },
    finnishSession(state) {
      state.isCreating = false;
      state.pointInProgress = null;
    },
    setGlobalPosition(state, action) {
      state.globalPosition = action.payload;
      state.isChangedFromInitial = true;
    },
    setMassIndexInEdit(state, action) {
      state.massIndexInEdit = action.payload;
    },
    reset(state, action) {
      const { editorType, initialParking } = action.payload;
      state.movingVector = undefined;
      state.movingPoint = undefined;
      state.globalPosition = initialState.globalPosition;
      switch (editorType) {
        case FEASIBILITY_EDITOR_BUTTON_KEYS.EDIT_SURFACE_PARKING:
          state.isCreating = false;
          state.isClosed = true;
          state.massIndexInEdit = undefined;
          const tmpPoints = initialParking?.boundary?.boundary && [...initialParking?.boundary?.boundary];
          if (tmpPoints) {
            tmpPoints.pop();
            state.points = removeZFromPoints(tmpPoints);
            const entrance = initialParking?.entrances[0];
            if (entrance) {
              const { position, normal } = entrance;
              const angle = vecAngle(normal) + Math.PI / 2;
              state.parkingEntrancePosition = { position, angle, normal };
            }
          }
          break;
        case FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_SURFACE_PARKING:
          state.isCreating = true;
          state.isClosed = true;
          state.parkingEntrancePosition = null;
          state.points = [];
          state.pointInProgress = null;
          state.massIndexInEdit = undefined;
          break;
        case FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_BUILDING:
          state.isCreating = true;
          state.isClosed = false;
          state.pointInProgress = null;
          state.points = [];
          state.massIndexInEdit = undefined;
          break;
        case FEASIBILITY_EDITOR_BUTTON_KEYS.EDIT_BUILDING:
          state.isCreating = false;
          state.isClosed = false;
          state.pointInProgress = null;
          break;
        case FEASIBILITY_EDITOR_BUTTON_KEYS.TRANSFORM:
          break;
        default:
      }
    },
  },
});

export const {
  initialize,
  setPoints,
  setPointInProgress,
  setIsCreating,
  setIsClosed,
  setParkingEntrancePosition,
  reset,
  finnishSession,
  setMovingVector,
  setMovingPoint,
  setGlobalPosition,
  setMassIndexInEdit,
} = sketchToolSlice.actions;

export default sketchToolSlice.reducer;

// export const isSketchOnSelector = (editorType) => {}
