import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router';
import { shallowEqual, useSelector } from 'react-redux';
import T from 'i18n-react';
import lodashGet from 'lodash/get';

import { StyledFormItem } from 'styles/commonComponents.styles';
import { CONSTRUCTION_COST_FORM } from 'constants/feasibilityReportConts';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { supportedCurrencies } from 'constants/currencies';
import { currentCurrencySelector, getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { sqmToSqf } from 'utils/helpers/unitsHelpers';
import CurrencyInput from '../../../../common/CurrencyInput';
import {
  ReportMainColumnDataSummeryTextWrapper,
  ReportMainColumnDataTextWrapper,
  ReportMainColumnWrapper, StyledProgress,
  StyledText,
} from '../report/ReportPages.styles';

const ConstructionCostBar = (props) => {
  const { data, formInstance, onChange, disabled } = props;
  const NIA = lodashGet(data, 'NIA', 0);
  const GIA = lodashGet(data, 'GIA', 0);

  const match = useRouteMatch();
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));
  const currentSelectedObject = useSelector(currentCurrencySelector, shallowEqual);

  const niaGiaRatio = ((NIA * 100) / GIA).toFixed(1);
  const numberOfMasses = lodashGet(data, 'totalUnits', 0);
  const constructionCostValueAreaRatio = (formInstance && formInstance.getFieldValue(CONSTRUCTION_COST_FORM.CONSTRUCTION_COST_VALUE_NIA_RATIO)) || 0;
  const totalConstruction = NIA * constructionCostValueAreaRatio;
  const constructionCostPerUnit = totalConstruction / numberOfMasses;

  useEffect(() => {
    if (constructionCostValueAreaRatio) {
      onRatioChange((constructionCostValueAreaRatio * NIA) / GIA, CONSTRUCTION_COST_FORM.CONSTRUCTION_COST_VALUE_GIA_RATIO);
    }
  }, [constructionCostValueAreaRatio, onChange]);

  const onRatioChange = (value, fieldToChange) => {
    formInstance.setFieldsValue({ [fieldToChange]: value });
    onChange();
  };

  return (
    <ReportMainColumnWrapper>
      {/* ============= Net Internal Area ============= */}
      <StyledText align="center">{T.translate('Net Internal Area (NIA)')}</StyledText>
      <StyledText align="center" bold size="xLarge" marginBottom={10}>
        {numberWithComma(isImperial ? sqmToSqf(NIA) : NIA, { fixed: isImperial ? 0 : 1, suffix: ` ${T.translate(isImperial ? 'SQF' : 'SQM')}` })}
      </StyledText>

      {/* ============= Gross Internal Area ============= */}
      <StyledText align="center">{T.translate('Gross Internal Area (GIA)')}</StyledText>
      <StyledText align="center" bold size="xLarge" marginBottom={10}>
        {numberWithComma(isImperial ? sqmToSqf(GIA) : GIA, { fixed: isImperial ? 0 : 1, suffix: ` ${T.translate(isImperial ? 'SQF' : 'SQM')}` })}
      </StyledText>

      {/* ============= NIA / GIA Ratio chart ============= */}
      <StyledText align="center">{T.translate('NIA / GIA Ratio')}</StyledText>
      <StyledProgress
        align="center"
        strokeLinecap="square"
        type="circle"
        percent={niaGiaRatio}
        format={() => `${niaGiaRatio}%`}
        strokeWidth={12}
        width={72}
        marginBottom={10}
      />

      {/* ============= NIA Input ============= */}
      <ReportMainColumnDataTextWrapper>
        <StyledText>{`${T.translate('NIA Rate')} ${supportedCurrencies[currentSelectedObject.currentCurrencyKey].symbol} / ${T.translate(isImperial ? 'SQF' : 'SQM')}`}</StyledText>
        <StyledFormItem name={CONSTRUCTION_COST_FORM.CONSTRUCTION_COST_VALUE_NIA_RATIO} initialValue={3400} width={120}>
          <CurrencyInput
            min={1}
            height={22}
            readOnly={disabled}
            unitSystemKey={CurrencyInput.unitSystemKey.sqmToSqf}
            onChange={(value) => onRatioChange((value * NIA) / GIA, CONSTRUCTION_COST_FORM.CONSTRUCTION_COST_VALUE_GIA_RATIO)}
          />
        </StyledFormItem>
      </ReportMainColumnDataTextWrapper>

      {/* ============= GIA Input ============= */}
      <ReportMainColumnDataTextWrapper>
        <StyledText>{`${T.translate('GIA Rate')} ${supportedCurrencies[currentSelectedObject.currentCurrencyKey].symbol} / ${T.translate(isImperial ? 'SQF' : 'SQM')}`}</StyledText>
        <StyledFormItem name={CONSTRUCTION_COST_FORM.CONSTRUCTION_COST_VALUE_GIA_RATIO} width={120}>
          <CurrencyInput
            min={1}
            height={22}
            readOnly={disabled}
            unitSystemKey={CurrencyInput.unitSystemKey.sqmToSqf}
            onChange={(value) => onRatioChange((value * GIA) / NIA, CONSTRUCTION_COST_FORM.CONSTRUCTION_COST_VALUE_NIA_RATIO)}
          />
        </StyledFormItem>
      </ReportMainColumnDataTextWrapper>

      {/* ============= Project Construction Cost ============= */}
      <ReportMainColumnDataSummeryTextWrapper marginBottom={5}>
        <StyledText middle>{T.translate('Project Construction Cost')}</StyledText>
        <StyledText align="end" middle size="xLarge">
          <CurrencyInput value={totalConstruction} readOnly />
        </StyledText>
      </ReportMainColumnDataSummeryTextWrapper>

      {/* ============= Number of Units ============= */}
      <ReportMainColumnDataSummeryTextWrapper marginBottom={5}>
        <StyledText middle>{T.translate('Number of Units')}</StyledText>
        <StyledText align="end" middle size="xLarge">{numberWithComma(numberOfMasses, { fixed: 0 })}</StyledText>
      </ReportMainColumnDataSummeryTextWrapper>

      {/* ============= Construction Cost Per Unit ============= */}
      <ReportMainColumnDataSummeryTextWrapper>
        <StyledText middle width={360}>{T.translate('Construction Cost Per Unit')}</StyledText>
        <StyledText align="end" middle size="xLarge">
          <CurrencyInput value={constructionCostPerUnit} readOnly />
        </StyledText>
      </ReportMainColumnDataSummeryTextWrapper>

    </ReportMainColumnWrapper>
  );
};

ConstructionCostBar.propTypes = {
  data: PropTypes.object,
  formInstance: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ConstructionCostBar;
