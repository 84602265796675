import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import { Tooltip } from 'antd';
import lodashIsFinite from 'lodash/isFinite';
import { StyledFormItem, RowText, SmallStyledTable, StyledInputNumber, DeleteIcon } from 'styles/commonComponents.styles';
import { AREA_TARGET_FORM } from 'constants/fieldNames';
import { uuidv4 } from 'utils/helpers/uuidv4';
import { deleteRow, updateRow, updateTable } from 'utils/helpers/tableHelpers';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { TEST_FIT_GENERAL } from 'constants/dropdownKeys';
import { sqfToSqm, sqfToSqmText } from 'utils/helpers/unitsHelpers';
import { useOverridableSetting } from 'store/userSettings';
import { getTestFitStandards } from 'utils/helpers/parseTestFitStandards';
import ImperialMetricInput from '../../../common/ImperialMetricInput';
import deleteIcon from '../../../../styles/static/icons/comon/deleteIcon';
import AddNewRowButton from '../../../common/AddNewRowButton';
import Dropdown from '../../../common/Dropdown';
import { TotalSeparator } from './AreaTargetFormContainer.styles';

const AreaTargetFormAsf = (props) => {
  const { form, form: { setFieldsValue }, disabled, formData, handleOnChange, isImperial } = props;
  const testFitStandard = useOverridableSetting('testFitStandard', 'DEFAULT');
  const amenityTypes = useMemo(() => getTestFitStandards().standards[testFitStandard].roomDefinitions.ASF, [testFitStandard]);

  const [totalAsfData, useTotalAsfData] = useState([
    {
      key: '0',
      roomType: T.translate('AREA_TARGET_FORM_PSF_TOTAL'),
      sfCount: '',
      ratio: '',
      estimateSf: 0,
    },
    {
      key: '1',
      roomType: T.translate('AREA_TARGET_FORM_ASF_PER_MEMBER'),
      sfCount: '',
      ratio: '',
      estimateSf: 0,
    },
  ]);

  // update table when total LSF changes
  useEffect(() => {
    if (!disabled) {
      updateTable(handleOnChange, form, AREA_TARGET_FORM.ASF_FIELD, updateEstimations);
    }
  }, [formData[AREA_TARGET_FORM.TOTAL_LSF_FIELD]]);

  // update total
  useEffect(() => {
    const asfData = formData[AREA_TARGET_FORM.ASF_FIELD];
    if (asfData) {
      updateTotal(useTotalAsfData, asfData);
    }
  }, [formData[AREA_TARGET_FORM.ASF_FIELD], isImperial]);

  const sfCount = [
    { key: TEST_FIT_GENERAL.ASF.TOTAL_SF, value: isImperial ? 'AREA_TARGET_FORM_ASF_SF_COUNT_TOTAL_SQF' : 'AREA_TARGET_FORM_ASF_SF_COUNT_TOTAL_SQM' },
    { key: TEST_FIT_GENERAL.ASF.TOTAL_RATIO, value: isImperial ? 'AREA_TARGET_FORM_ASF_SF_COUNT_RATIO_SQF' : 'AREA_TARGET_FORM_ASF_SF_COUNT_RATIO_SQM' },
  ];

  const columns = [
    {
      title: T.translate('AREA_TARGET_FORM_ASF_AMENITY_TYPE'),
      dataIndex: 'roomType',
      key: 'roomType',
      width: 120,
      align: 'center',
      render: (key, rowData) => (
        <Dropdown
          dropDownData={amenityTypes}
          selectedKey={key}
          onChange={(value) => handleAmenityTypeChange(value, rowData)}
          inputValue={rowData.otherText}
          onInputChange={(e) => handleAmenityTypeFreeTextChange(e, rowData)}
        />
      ),
    },

    {
      title: T.translate('AREA_TARGET_FORM_ASF_TYPE'),
      dataIndex: 'sfCount',
      key: 'sfCount',
      width: 120,
      align: 'center',
      render: (key, rowData) => (
        <Dropdown
          dropDownData={sfCount}
          selectedKey={key}
          onChange={(value) => handleSfCountChange(value, rowData)}
        />
      ),
    },
    {
      title: T.translate('AREA_TARGET_FORM_ASF_RATIO'),
      dataIndex: 'ratio',
      key: 'ratio',
      width: 60,
      align: 'center',
      render: (ratio, rowData) => (rowData.sfCount === TEST_FIT_GENERAL.ASF.TOTAL_RATIO
        ? <ImperialMetricInput isImperial={isImperial} onChange={(e) => handleRatioChange(e, rowData)} min={1} height={25} value={ratio} />
        : sqfToSqmText(ratio, isImperial)
      ),
    },
    {
      title: T.translate('AREA_TARGET_FORM_FSF_NUMBER_OF_ROOMS'),
      dataIndex: 'numberOfRooms',
      key: 'numberOfRooms',
      width: 60,
      align: 'center',
      render: (count, rowData) => (
        <StyledInputNumber
          onChange={(e) => handleRoomCountChange(e, rowData)}
          min={1}
          height={25}
          width={70}
          parser={(value) => value.replace('.', '')}
          value={count}
        />
      ),
    },
    {
      title: T.translate('AREA_TARGET_FORM_ASF_ROOM_SIZE'),
      dataIndex: 'roomSize',
      key: 'roomSize',
      width: 70,
      align: 'center',
      render: (count, rowData) => (rowData.sfCount === TEST_FIT_GENERAL.ASF.TOTAL_SF
        ? (
          <ImperialMetricInput
            width={80}
            isImperial={isImperial}
            onChange={(e) => handlerRoomSizeChange(e, rowData)}
            min={isImperial ? 10 : 0.5}
            max={isImperial ? 5000 : 500}
            height={25}
            value={count}
          />
        )
        : sqfToSqmText(count, isImperial)
      ),
    },
    {
      title: T.translate(isImperial ? 'SQF' : 'SQM'),
      dataIndex: 'estimateSf',
      key: 'estimateSf',
      width: 50,
      align: 'center',
      render: (number) => sqfToSqmText(number, isImperial),
    },
    {
      dataIndex: 'delete',
      width: 20,
      align: 'center',
      render: (text, rowData) => (
        <DeleteIcon component={deleteIcon} onClick={() => deleteRow(handleOnChange, rowData.key, form, AREA_TARGET_FORM.ASF_FIELD)} />
      ),
    },

  ];

  const totalColumns = [
    { dataIndex: 'roomType', width: 220 },
    { dataIndex: 'sfCount', width: 180 },
    { dataIndex: 'roomSize', width: 120 },
    { dataIndex: 'ratio', width: 90 },
    { dataIndex: 'numberOfRooms', width: 150 },
    { dataIndex: 'estimateSf', key: 'estimateSf', width: 150, align: 'center', render: (target, rowData, index) => (index ? numberWithComma(target, { fixed: 1 }) : renderTotalTargetField(target)) },
    { dataIndex: 'delete', width: 2 },
  ];

  const renderTotalTargetField = (target) => {
    if (!formData[AREA_TARGET_FORM.TOTAL_AREA_FIELD]) {
      return 0;
    }
    const percentage = (target * 100) / formData[AREA_TARGET_FORM.TOTAL_AREA_FIELD];
    return (
      <Tooltip title={T.translate('AREA_TARGET_FORM_ASF_ERROR')} placement="bottom">
        <RowText error={percentage >= 20}>{`${sqfToSqmText(target, isImperial)} (${numberWithComma(percentage || 0, { fixed: 1, suffix: '%' })})`}</RowText>
      </Tooltip>
    );
  };

  const addNewRow = () => {
    const newRowData = {
      key: uuidv4(),
      roomType: amenityTypes[0].key,
      otherText: '',
      sfCount: TEST_FIT_GENERAL.ASF.TOTAL_SF,
      ratio: 1,
      roomSize: lodashGet(amenityTypes, '[0].defaultRange[0]', 10),
      numberOfRooms: 1,
      estimateSf: 10,
    };

    const currentData = formData[AREA_TARGET_FORM.ASF_FIELD] || [];

    setFieldsValue({ [AREA_TARGET_FORM.ASF_FIELD]: [...currentData, newRowData] });
    handleOnChange();
  };

  const handleRatioChange = (e, rowData) => {
    const newValues = {
      ratio: e,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.ASF_FIELD, form, newValues, updateEstimations);
  };

  const handlerRoomSizeChange = (e, rowData) => {
    const newValues = {
      roomSize: e,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.ASF_FIELD, form, newValues, updateEstimations);
  };

  const handleRoomCountChange = (e, rowData) => {
    const newValues = {
      numberOfRooms: e,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.ASF_FIELD, form, newValues, updateEstimations);
  };

  const handleAmenityTypeChange = (value, rowData) => {
    const newValues = {
      roomType: value,
      otherText: '',
      roomSize: lodashGet(amenityTypes, `[${amenityTypes.findIndex((unit) => unit.key === value)}].defaultRange[0]`, 10),
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.ASF_FIELD, form, newValues, updateEstimations);
  };

  const handleAmenityTypeFreeTextChange = (e, rowData) => {
    const newValues = {
      otherText: e.target.value,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.ASF_FIELD, form, newValues, updateEstimations);
  };

  const handleSfCountChange = (key, rowData) => {
    const newValues = {
      sfCount: key,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.ASF_FIELD, form, newValues, updateEstimations);
  };

  const updateEstimations = (data) => {
    const totalNumberOfMembers = lodashGet(formData[AREA_TARGET_FORM.TOTAL_LSF_FIELD], '[0].numberOfMembers');
    let roomSize;
    let ratio;
    let estimateSf;

    if (data.sfCount === TEST_FIT_GENERAL.ASF.TOTAL_SF) {
      roomSize = data.roomSize;
      estimateSf = roomSize * data.numberOfRooms;
      ratio = (estimateSf / totalNumberOfMembers);
    } else if (data.sfCount === TEST_FIT_GENERAL.ASF.TOTAL_RATIO) {
      ratio = data.ratio;
      estimateSf = (totalNumberOfMembers * ratio);
      roomSize = estimateSf / data.numberOfRooms;
    }

    return {
      roomSize: lodashIsFinite(roomSize) ? roomSize : 0,
      ratio: lodashIsFinite(ratio) ? ratio : 0,
      estimateSf: lodashIsFinite(estimateSf) ? estimateSf : 0,
    };
  };

  const updateTotal = (updateTotalAsf, asfData) => {
    const lsfTotalMembers = lodashGet(formData[AREA_TARGET_FORM.TOTAL_LSF_FIELD], '[0].numberOfMembers');
    const estimateSf = asfData.reduce((total, current) => ({ estimateSf: Number(total.estimateSf) + Number(current.estimateSf) }), { estimateSf: 0 });

    updateTotalAsf([
      {
        key: 'TOTAL',
        roomType: T.translate('AREA_TARGET_FORM_PSF_TOTAL'),
        sfCount: '',
        ratio: '',
        estimateSf: estimateSf.estimateSf,
      },
      {
        key: '1',
        roomType: T.translate('AREA_TARGET_FORM_ASF_PER_MEMBER'),
        sfCount: '',
        ratio: '',
        estimateSf: lsfTotalMembers ? ((isImperial ? estimateSf.estimateSf : sqfToSqm(estimateSf.estimateSf)) / lsfTotalMembers) : 0,
      },
    ]);
  };

  const isTotalValid = () => {
    if (((totalAsfData[0].estimateSf * 100) / formData[AREA_TARGET_FORM.TOTAL_AREA_FIELD]) >= 20) {
      return Promise.reject(T.translate('AREA_TARGET_FORM_ASF_ERROR'));
    }
    return Promise.resolve();
  };

  return (
    <React.Fragment>
      <StyledFormItem name={AREA_TARGET_FORM.ASF_FIELD} valuePropName="dataSource" rules={[{ validator: isTotalValid }]}>
        <SmallStyledTable loading={disabled} pagination={false} columns={columns} />
      </StyledFormItem>
      <AddNewRowButton disabled={disabled} onClick={addNewRow} text={T.translate('AREA_TARGET_FORM_ASF_ADD_OFFICE_BUTTON')} />
      <TotalSeparator disabled={disabled} />
      <SmallStyledTable loading={disabled} pagination={false} columns={totalColumns} dataSource={totalAsfData} showHeader={false} bold rowClassName={(row) => row.key === 'TOTAL' && 'ant-table-total-row'} />
    </React.Fragment>
  );
};

AreaTargetFormAsf.propTypes = {
  form: PropTypes.object.isRequired,
  formData: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  isImperial: PropTypes.bool,
};

export default React.memo(AreaTargetFormAsf);
