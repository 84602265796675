import React from 'react';

export default () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.0026 9.54908C25.9268 9.55991 25.8509 9.57195 25.7667 9.57195C25.7114 9.57195 25.6632 9.56112 25.6114 9.5563V28.5735C25.6114 29.2523 25.059 29.806 24.3789 29.806H6.61C5.92997 29.806 5.3775 29.2523 5.3775 28.5735V4.43619C5.3775 3.75616 5.92997 3.20369 6.61 3.20369H20.4877C20.0856 2.92445 19.8774 2.45264 19.863 1.97119H6.60991C5.25101 1.97119 4.1449 3.0773 4.1449 4.43619V28.5735C4.1449 29.9324 5.25101 31.0385 6.60991 31.0385H24.3776C25.7377 31.0385 26.8426 29.9324 26.8426 28.5735V10.0305C26.4791 9.96672 26.1987 9.79099 26.0025 9.54906L26.0026 9.54908Z" fill="currentcolor" />
    <path d="M26.2263 10.6646C26.224 10.6646 26.2192 10.6658 26.2131 10.6646H19.9434C19.5967 10.6646 19.3162 10.389 19.3162 10.0484L19.3151 2.57633C19.3151 2.31635 19.4812 2.08404 19.7303 1.99619C19.9783 1.90713 20.2575 1.98295 20.426 2.18396L26.6332 9.57896C26.7692 9.6921 26.8534 9.8606 26.8534 10.0484C26.8547 10.3878 26.5742 10.6646 26.2264 10.6646L26.2263 10.6646ZM20.5707 9.43211H24.8857L20.5707 4.29259V9.43211Z" fill="currentcolor" />
    <path d="M10.3539 13.7847C10.7751 13.7847 11.134 13.8344 11.4361 13.934C11.7363 14.0335 11.9851 14.1703 12.1806 14.3444C12.3761 14.5186 12.52 14.7247 12.6141 14.9628C12.7066 15.2009 12.7528 15.4568 12.7528 15.7322C12.7528 16.0289 12.7048 16.3026 12.6106 16.5514C12.5146 16.8002 12.3689 17.0134 12.1735 17.1893C11.978 17.3652 11.7275 17.5056 11.4271 17.6034C11.1269 17.7029 10.7679 17.7526 10.3557 17.7526H9.63425V19.7463H8.25V13.7812L10.3539 13.7831L10.3539 13.7847ZM10.3539 16.7113C10.7146 16.7113 10.9741 16.6242 11.1321 16.4537C11.2904 16.2813 11.3703 16.0414 11.3703 15.7322C11.3703 15.5954 11.3507 15.471 11.3099 15.359C11.269 15.2471 11.2068 15.1511 11.1233 15.0712C11.0398 14.9913 10.9349 14.929 10.807 14.8846C10.6791 14.8402 10.5298 14.8189 10.3557 14.8189H9.63425V16.7095L10.3539 16.7113Z" fill="currentcolor" />
    <path d="M18.8713 16.7646C18.8713 17.1964 18.7966 17.5927 18.6474 17.9587C18.4999 18.323 18.2884 18.6375 18.0202 18.904C17.75 19.1706 17.4249 19.3767 17.0446 19.526C16.6626 19.6752 16.2415 19.7481 15.7777 19.7481H13.4587V13.7831H15.7777C16.2414 13.7831 16.6643 13.8577 17.0446 14.0087C17.4267 14.158 17.7501 14.3659 18.0202 14.6306C18.2902 14.8954 18.4999 15.2099 18.6474 15.5742C18.7966 15.9402 18.8713 16.3365 18.8713 16.7647V16.7646ZM17.4533 16.7646C17.4533 16.4697 17.4142 16.2031 17.3378 15.965C17.2614 15.7269 17.1512 15.5261 17.0091 15.3609C16.8669 15.1956 16.6892 15.0694 16.4831 14.9806C16.277 14.8918 16.0406 14.8473 15.7758 14.8473H14.85V18.6837H15.7758C16.0406 18.6837 16.277 18.6393 16.4831 18.5505C16.691 18.4616 16.8669 18.3354 17.0091 18.1684C17.1512 18.0031 17.2614 17.8024 17.3378 17.566C17.416 17.3297 17.4533 17.0613 17.4533 16.7646Z" fill="currentcolor" />
    <path d="M21.0802 14.8508V16.3611H23.1184V17.4291H21.0802V19.7516H19.6888V13.7866H23.5288V14.8509L21.0802 14.8508Z" fill="currentcolor" />
    <path d="M15.2469 20.9133H15.1563V21.004V26.0132L13.714 24.5732L13.65 24.5092L13.5859 24.5731L13.0657 25.0925L13.0015 25.1566L13.0657 25.2207L15.5544 27.7046L15.6184 27.7686L15.6824 27.7046L18.1712 25.2207L18.2354 25.1566L18.1712 25.0925L17.6509 24.5731L17.5869 24.5092L17.5228 24.5732L16.0806 26.0132V21.004V20.9133H15.99H15.2469Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.6875" />
  </svg>
);
