import React from 'react';

export default () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3782 22.0292C12.2315 22.1522 12.3122 22.3187 12.3122 22.4562C12.3122 27.7633 12.3122 33.0705 12.3122 38.3776V38.8408H13.7785V38.1171C13.7785 33.0078 13.7785 27.9008 13.7785 22.7963C13.7785 22.5575 13.6905 22.3042 13.8445 22.0726" stroke="currentcolor" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M37.6143 22.0292C37.7682 22.1522 37.6876 22.3187 37.6876 22.4562V38.8118H36.2213V38.0881C36.2213 34.267 36.2213 30.4459 36.2213 26.632C36.286 25.105 36.2664 23.5757 36.1626 22.0509" stroke="currentcolor" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M36.3827 19.3804C36.3827 17.933 36.3827 16.4856 36.3827 15.0382C36.3827 14.5316 36.2288 14.3725 35.7155 14.3797C33.3449 14.3797 30.9768 14.3797 28.611 14.3797C28.1052 14.3797 27.9512 14.5678 27.9512 15.031C27.9512 16.4784 27.9512 17.9258 27.9512 19.3732" stroke="currentcolor" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M28.0462 21.9568C27.8335 21.747 27.5696 21.8121 27.313 21.8121H11.1831V20.3647H38.8165V21.8121H28.4054C28.308 21.8234 28.2121 21.8453 28.1195 21.8772" stroke="currentcolor" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M28.0464 22.0292C28.1417 23.3635 28.1638 24.7019 28.1124 26.0385C28.1124 28.5208 28.1124 31.0031 28.1124 33.5505H36.0893" stroke="currentcolor" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.5662 14.8718C15.2525 15.031 16.9314 14.908 18.6984 14.9514C18.2329 13.7184 17.7018 12.5106 17.1074 11.3329C17.1074 11.3329 17.1074 11.275 17.1074 11.2605C16.8728 11 15.0105 11.2243 14.8639 11.55C14.3433 12.6645 13.8448 13.7935 13.3389 14.9152" stroke="currentcolor" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M27.3208 20.2416C27.3721 19.6627 27.6067 19.4818 28.2666 19.5179C30.9207 19.5831 33.5821 19.5614 36.2362 19.5179C36.8081 19.5179 37.1234 19.6482 37.1087 20.2416" stroke="currentcolor" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M36.0965 29.6859H28.2295" stroke="currentcolor" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M36.0965 25.8214H28.2295" stroke="currentcolor" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.0078 15.1541C16.0909 16.2738 16.1153 17.397 16.0811 18.5193" stroke="currentcolor" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.9653 20.2417V19.7062C17.9653 18.8305 17.7747 18.6423 16.8729 18.6351C16.3963 18.6351 15.9051 18.6712 15.4065 18.6351C14.1528 18.5265 13.8229 19.1923 14.1015 20.22" stroke="currentcolor" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M33.0467 27.7537C32.4734 27.6379 31.8824 27.6379 31.3091 27.7537" stroke="currentcolor" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M33.0465 23.8891C32.5202 23.8107 31.987 23.7864 31.4556 23.8167" stroke="currentcolor" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M33.0465 31.6183C32.5205 31.7003 31.987 31.7245 31.4556 31.6906" stroke="currentcolor" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.459 15.1541C17.5763 15.6002 17.5763 16.0685 17.459 16.5146" stroke="currentcolor" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M32.9001 15.0093C32.5191 14.9332 32.129 14.9113 31.7417 14.9442" stroke="currentcolor" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
