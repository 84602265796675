import * as THREE from 'three';
import { polygonIterEdges, transformPolygonToBox, vecSqrLength, vecSub } from './algorithmHelpers';
import { getStoryUnits, getStoryEnvelope, getStoryHeight } from '../model/feasibilityDataExtractors';

const addPolyToShape = (shape, poly, isHole) => {
  const shapeToSet = isHole ? new THREE.Path() : shape;
  shapeToSet.moveTo(poly[0][0], poly[0][1]);
  for (let i = 1; i < poly.length; i++) {
    shapeToSet.lineTo(poly[i][0], poly[i][1]);
  }
  if (isHole) {
    shape.holes.push(shapeToSet);
  }
};

export const extrudePolygon = (poly, holes, height, threeColor) => {
  const shape = new THREE.Shape();
  addPolyToShape(shape, poly);

  if (holes && holes.length > 0) {
    // addPolyToShape(shape, holes[0], true);
    holes.forEach((hole) => addPolyToShape(shape, hole, true));
  }

  const extrudeSettings = {
    steps: 1,
    // The parameter name is depth, but because we use Z-up, it is actually height for our use case.
    depth: height,
    bevelEnabled: false,
  };

  const geometry = new THREE.ExtrudeBufferGeometry(shape, extrudeSettings);

  if (threeColor !== undefined) {
    const colors = [];
    const { position } = geometry.attributes;

    for (let i = 0; i < position.count; i += 3) {
      colors.push(threeColor.r, threeColor.g, threeColor.b);
      colors.push(threeColor.r, threeColor.g, threeColor.b);
      colors.push(threeColor.r, threeColor.g, threeColor.b);
    }

    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
  }

  return geometry;
};

export const createHorizontalLineSegments = (xyPoints, height, disableCycle) => {
  // Make sure that the point list is cyclic, if not make it so
  if (!disableCycle && vecSqrLength(vecSub(xyPoints[0], xyPoints[xyPoints.length - 1])) > 0.1) {
    xyPoints = [...xyPoints, xyPoints[0]];
  }
  const points = xyPoints.map(([x, y]) => [x, y, height]);
  const segments = [];
  polygonIterEdges(points, (p1, p2) => {
    segments.push([p1, p2]);
  });
  return segments;
};

// Add all render line segments from a floor group to a list
export const collectFloorGroupLineSegments = (floorGroup, currentHeight, segmentsPointList) => {
  const envelope = getStoryEnvelope(floorGroup.typicalStory);
  const storyHeight = getStoryHeight(floorGroup.typicalStory);
  for (let floorGroupFloorNum = 0; floorGroupFloorNum < floorGroup.numStories; floorGroupFloorNum++) {
    const z = currentHeight + floorGroupFloorNum * storyHeight;
    // Envelope segments are the outer envelope of each floor
    const envelopeSegments = createHorizontalLineSegments(envelope, z);
    envelopeSegments.forEach((segment) => segment.forEach((point) => segmentsPointList.push(point)));
  }
  const { height } = floorGroup;
  const facadePoints = new Set();

  const units = getStoryUnits(floorGroup.typicalStory);
  const ceilingHeight = currentHeight + height;
  units.forEach((unit) => {
    unit.facades.forEach(([start, end]) => {
      facadePoints.add(start);
      facadePoints.add(end);
    });

    // Ceiling segments at the top of the floor group make sure you see all unit lines when looking from above
    const ceilingSegments = createHorizontalLineSegments(unit.polygon.boundary, ceilingHeight);
    ceilingSegments.forEach((segment) => segment.forEach((point) => segmentsPointList.push(point)));
    const { holes } = unit.polygon;
    if (holes && holes.length > 0) {
      holes.forEach((hole) => {
        const holeCeilingSegments = createHorizontalLineSegments(hole, ceilingHeight);
        holeCeilingSegments.forEach((segment) => segment.forEach((point) => segmentsPointList.push(point)));
      });
    }
  });

  // Facade segments are the vertical segments for the envelope-touching units
  facadePoints.forEach(([x, y]) => {
    segmentsPointList.push([x, y, currentHeight]);
    segmentsPointList.push([x, y, currentHeight + height]);
  });
  currentHeight += height;
};

const getRampArrow = () => [[-0.34, -0.5], [0.34, 0], [-0.34, 0.5], [-0.34, -0.5]];
const getSurroundingRectangle = () => [[-0.5, -0.5], [-0.5, 0.5], [0.5, 0.5], [0.5, -0.5], [-0.5, -0.5]];
const getInnerSurroundingRectangle = () => [[-0.34, -0.5], [-0.34, 0.5], [0.34, 0.5], [0.34, -0.5], [-0.34, -0.5]];
const getTwoWayUpArrow = () => [[-0.34, 0], [0.34, 0.25], [-0.34, 0.5], [-0.34, 0]];
const getTwoWayDownArrow = () => [[0.34, 0], [-0.34, -0.25], [0.34, -0.5], [0.34, 0]];
const getSeperatingLine = () => [[0.34, 0], [-0.34, 0]];

export const getRampArrowPolygons = (boxPolygon) => {
  const polygons = [];
  polygons.push(transformPolygonToBox(getRampArrow(), boxPolygon));
  polygons.push(transformPolygonToBox(getSurroundingRectangle(), boxPolygon));
  polygons.push(transformPolygonToBox(getInnerSurroundingRectangle(), boxPolygon));

  return polygons;
};

export const getTwoWayRampArrowsPolygons = (boxPolygon) => {
  const polygons = [];
  polygons.push(transformPolygonToBox(getTwoWayUpArrow(), boxPolygon));
  polygons.push(transformPolygonToBox(getTwoWayDownArrow(), boxPolygon));
  polygons.push(transformPolygonToBox(getSurroundingRectangle(), boxPolygon));
  polygons.push(transformPolygonToBox(getInnerSurroundingRectangle(), boxPolygon));
  polygons.push(transformPolygonToBox(getSeperatingLine(), boxPolygon));

  return polygons;
};
