import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import lodashIncludes from 'lodash/includes';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashGet from 'lodash/get';
import lodashKeys from 'lodash/keys';
import { FEASIBILITY_TARGET_FORM } from 'constants/fieldNames';
import {
  COMMUNAL_SPACES_RATIO_TYPE,
  COMMUNAL_SPACES_SPREAD, getApartmentsByUnitType,
  STANDARDS,
} from 'constants/feasibilityConts';
import { uuidv4 } from 'utils/helpers/uuidv4';
import { userSelector } from 'store/user';
import MultiImageSelect from '../../../common/MultiImageSelect';
import { FormItem } from './FeasibilityTargetFormContainer.Styles';

const FeasibilityApartmentStandardForm = (props) => {
  const { disabled, form, handleOnChange } = props;
  const user = useSelector(userSelector);
  const typologies = lodashKeys(STANDARDS).map((key) => STANDARDS[key]).filter((item) => {
    if (item.requiredUserSettingsKey) {
      return !!lodashGet(user, `settings.flags.${item.requiredUserSettingsKey}`);
    }
    return true;
  });

  const onChange = (selected) => {
    const unitType = selected[0];
    const communalSpace = form.getFieldValue(FEASIBILITY_TARGET_FORM.COMMUNAL_SPACE).filter((unit) => lodashIsEmpty(unit.disabledList)) || []; // filter removes custom communal from previous standard
    let buildingTypologies = form.getFieldValue(FEASIBILITY_TARGET_FORM.BUILDING_TYPOLOGIES) || [];
    const additionalUnitTypes = unitType && unitType !== STANDARDS.PREFAB.key
      ? lodashKeys(STANDARDS[unitType].communalSpaces).map((key) => STANDARDS[unitType].communalSpaces[key])
      : [];
    const allCommunalSpacesTypes = lodashKeys(STANDARDS.PREFAB.communalSpaces).map((key) => STANDARDS.PREFAB.communalSpaces[key]);
    const communalSpacesTypes = [...allCommunalSpacesTypes, ...additionalUnitTypes, STANDARDS.PREFAB.extraCommunalSpaces.OTHER];
    let filteredCommunalSpacesTypesList = communalSpace.filter((unit) => lodashIncludes(communalSpacesTypes.map((item) => item.key), unit.communalSpaceType));

    if (unitType === STANDARDS.DORMITORIES.key) { // add SHARED_KITCHEN to the start of the list
      filteredCommunalSpacesTypesList.unshift({
        key: uuidv4(),
        communalSpaceType: STANDARDS.DORMITORIES.communalSpaces.SHARED_KITCHEN.key,
        disabledList: ['communalSpaceType', 'ratioType', 'spreadType', 'deleteIcon'],
        ratioType: COMMUNAL_SPACES_RATIO_TYPE.PER_APARTMENT,
        ratio: 0,
        spreadType: COMMUNAL_SPACES_SPREAD.PER_FLOOR,
        area: 0,
      });
    }

    if (unitType === STANDARDS.IL.key) { // in IL Standard the only Typology allowed is TOWER
      buildingTypologies = [STANDARDS.IL.buildingTypologies.TOWER.key];
      const ilsAddedRoomsList = [STANDARDS.PREFAB.communalSpaces.MAILROOM.key, STANDARDS.PREFAB.communalSpaces.LOBBY_LOUNGE.key];
      filteredCommunalSpacesTypesList = filteredCommunalSpacesTypesList.filter((unit) => !lodashIncludes(ilsAddedRoomsList, unit.communalSpaceType));
      filteredCommunalSpacesTypesList.unshift({
        key: uuidv4(),
        communalSpaceType: STANDARDS.PREFAB.communalSpaces.MAILROOM.key,
        disabledList: ['communalSpaceType', 'ratioType', 'spreadType', 'deleteIcon'],
        ratioType: COMMUNAL_SPACES_RATIO_TYPE.ROOM_AREA,
        ratio: 5,
        min: 5,
        spreadType: COMMUNAL_SPACES_SPREAD.PER_BUILDING,
        area: 5,
      });
      filteredCommunalSpacesTypesList.unshift({
        key: uuidv4(),
        communalSpaceType: STANDARDS.PREFAB.communalSpaces.LOBBY_LOUNGE.key,
        disabledList: ['communalSpaceType', 'ratioType', 'spreadType', 'deleteIcon'],
        ratioType: COMMUNAL_SPACES_RATIO_TYPE.ROOM_AREA,
        ratio: 7,
        min: 7,
        spreadType: COMMUNAL_SPACES_SPREAD.PER_BUILDING,
        area: 7,
      });

      form.setFieldsValue({
        // Design Priority
        [FEASIBILITY_TARGET_FORM.CONSTRUCTION]: 0,
        [FEASIBILITY_TARGET_FORM.MARKETABILITY]: 0,
        [FEASIBILITY_TARGET_FORM.WELLBEING]: 0,
        // Design Requirements
        [FEASIBILITY_TARGET_FORM.SPRINKLERS]: true,
        [FEASIBILITY_TARGET_FORM.DEAD_END_CORRIDOR]: true,
        [FEASIBILITY_TARGET_FORM.NATURAL_LIGHT_CORRIDORS]: false,
        [FEASIBILITY_TARGET_FORM.SINGLE_LOAD_CORRIDOR]: false,
        [FEASIBILITY_TARGET_FORM.MULTIPLE_FLOOR_GROUP]: false,
      });
    } else {
      buildingTypologies = [...buildingTypologies].filter((typology) => typology !== STANDARDS.IL.buildingTypologies.TOWER.key);
    }

    if (unitType === STANDARDS.UK.key) {
      filteredCommunalSpacesTypesList.unshift({
        key: uuidv4(),
        communalSpaceType: STANDARDS.PREFAB.communalSpaces.LOBBY_LOUNGE.key,
        disabledList: ['communalSpaceType', 'ratioType', 'spreadType', 'deleteIcon'],
        ratioType: COMMUNAL_SPACES_RATIO_TYPE.ROOM_AREA,
        ratio: 20,
        min: 20,
        spreadType: COMMUNAL_SPACES_SPREAD.PER_BUILDING,
        area: 20,
      });
    }

    const apartmentTypes = getApartmentsByUnitType(unitType);

    const apartment = {
      key: uuidv4(),
      apartmentType: apartmentTypes[0].key,
      otherText: '',
      apartmentSize: apartmentTypes[0].size,
      apartmentSizeRange: apartmentTypes[0].range || [],
      isPenthouse: false,
      target: 0,
      numberOfApartment: 0,
      estimateSF: 0,
    };

    form.setFieldsValue({
      [FEASIBILITY_TARGET_FORM.BUILDING_TYPOLOGIES]: lodashIsEmpty(buildingTypologies) ? [STANDARDS.PREFAB.buildingTypologies.MEGA_BLOCK.key] : buildingTypologies,
      [FEASIBILITY_TARGET_FORM.COMMUNAL_SPACE]: filteredCommunalSpacesTypesList,
      [FEASIBILITY_TARGET_FORM.APARTMENTS]: [apartment],
    });

    handleOnChange();
  };

  return (
    <FormItem name={FEASIBILITY_TARGET_FORM.APARTMENTS_STANDARD} valuePropName="selected" initialValue={[STANDARDS.PREFAB.key]}>
      <MultiImageSelect size={60} data={typologies} disabled={disabled} min={1} max={1} countText=" " onChange={onChange} />
    </FormItem>
  );
};

FeasibilityApartmentStandardForm.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object,
  handleOnChange: PropTypes.func,
};

export default React.memo(FeasibilityApartmentStandardForm);
