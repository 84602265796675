import React from 'react';
import styled from 'styled-components';
import { Dropdown, Menu } from 'antd';
import Icon, { SettingOutlined } from '@ant-design/icons';

export const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: ${({ theme }) => `${theme.other.headerHeight}px`};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.textColor};
  display: flex;
  z-index: 2;
  justify-content: space-between;
  padding-left: 17px;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
`;

export const HeaderNavigationWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const HeaderNavigationTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const HeaderNavigation = styled.div`
  display: flex;
`;

export const SwappLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryColor} !important;

    svg {
      color: ${({ theme }) => theme.colors.primaryColor} !important;
    }
  }
`;

export const SwappLogo = styled(Icon)`
  color: ${({ theme }) => theme.colors.textColor};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UserIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.gray_09};
`;

export const DropdownIcon = styled(({ marginTop, disabled, ...props }) => <Icon {...props} />)`
  margin-left: 10px;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '5px')};
  display: ${({ disabled }) => (disabled ? 'none' : 'inline-block')};
`;

export const ProjectDropdown = styled(Dropdown)`
  color: ${({ theme }) => theme.colors.textColor};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  margin-top: 4px;

  &:hover {
    color: ${({ theme, disabled }) => (disabled ? theme.colors.textColor : theme.colors.primaryColor)};
  }
`;

export const ProjectDropdownText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${({ theme }) => theme.font.size.large};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const ProjectDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 390px;
`;

export const StageSeparator = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.colors.gray_03};
  height: 40px;
  margin: 0 35px;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.textColor};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  font-size: ${({ theme }) => theme.font.size.large};
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  margin-right: 30px;
  margin-top: 5px;

  span {
    display: flex;
  }
  
  .anticon {
    margin: 0 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  &:hover {
    color: ${({ theme }) => theme.colors.primaryColor} !important;
    
    svg {
      color: ${({ theme }) => theme.colors.primaryColor} !important;
    }
  }
`;

export const StyledProjectsMenu = styled(Menu)`
  max-height: 480px;
  width: 460px;
  left: -35px !important;
  top: 12px !important;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0;
`;

export const StyledProjectsMenuInner = styled.div`
  overflow: auto;
  max-height: 480px;
`;

export const StyledProjectMenuItemWrapper = styled(({ selected, ...props }) => <Menu.Item {...props} />)`
  box-shadow: 0 2px 15px ${({ selected }) => (selected ? 'rgba(0, 0, 0, 0.15)' : 'rgba(0, 0, 0, 0.09)')};
  margin: 10px;
  padding: 5px;
  border: 1px solid ${({ theme, selected }) => (selected ? theme.colors.primaryColor : 'transparent')};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  color: ${({ theme, selected }) => (selected && theme.colors.primaryColor)};

  .ant-dropdown-menu-title-content {
    display: flex;
  }

  :hover {
    background: unset;
    border: 1px solid ${({ theme }) => theme.colors.primaryColorDisabled};
    color: ${({ theme }) => theme.colors.primaryColorDisabled};
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
  }

  svg {
    width: 30px;
    color: ${({ theme }) => theme.colors.gray_08};
  }
`;

export const StyledProjectMenuItemImageWrapper = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.gray_03};
  margin-right: 10px;
  border-radius: ${({ theme }) => theme.other.borderRadius};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledProjectMenuItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.other.borderRadius};
`;

export const StyledProjectMenuItemStage = styled.div`
  display: flex;
`;

export const StyledProjectMenuItemData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledProjectMenuItemText = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SettingsIcon = styled(SettingOutlined)`
  font-size: ${({ theme }) => theme.font.size.large} !important;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 6px;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primaryColor};
  }
`;
