import React from 'react';

export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M35.2435 17.4539C34.6178 16.8282 33.8358 16.5154 33.0537 16.5154C32.2717 16.5154 31.4896 16.8282 30.864 17.4539C29.6127 18.7052 29.6127 20.5821 30.864 21.8334C31.4896 22.459 32.2717 22.7718 33.0537 22.7718C33.8358 22.7718 34.6178 22.459 35.2435 21.8334C36.4948 20.5821 36.4948 18.7052 35.2435 17.4539ZM34.6178 21.2077C34.1486 21.6769 33.6794 21.8334 33.0537 21.8334C32.4281 21.8334 31.8025 21.5205 31.4896 21.2077C30.5512 20.2693 30.5512 18.8616 31.4896 17.9231C31.9589 17.4539 32.4281 17.2975 33.0537 17.2975C33.6794 17.2975 34.305 17.6103 34.6178 17.9231C35.5563 18.8616 35.5563 20.4257 34.6178 21.2077Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.2" strokeMiterlimit="10" />
    <path d="M30.708 23.0846C30.708 23.241 30.5516 23.5538 30.3952 23.5538C30.0824 23.5538 29.7696 23.7103 29.4567 23.8667C28.9875 24.1795 28.5183 24.4923 28.3619 24.8051C28.0491 25.2744 27.8926 25.5872 27.8926 25.5872C27.8926 25.7436 27.7362 25.9 27.5798 25.9H27.4234C27.267 25.9 27.1106 25.5872 27.1106 25.4308C27.1106 25.2744 27.8926 23.241 30.3952 22.9282C30.5516 22.7718 30.708 22.7718 30.708 23.0846Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.2" strokeMiterlimit="10" />
    <path d="M38.8409 25.7436C38.8409 25.7436 38.6845 25.7436 38.8409 25.7436C38.5281 25.7436 38.3717 25.7436 38.3717 25.5872C38.3717 25.5872 38.2153 25.118 37.9025 24.8052C37.5896 24.4923 37.2768 24.1795 36.6512 23.8667C36.3384 23.7103 36.0255 23.7103 35.7127 23.5539C35.5563 23.5539 35.3999 23.2411 35.3999 23.0847C35.3999 22.9282 35.7127 22.7718 35.8691 22.7718C38.2153 23.0846 39.1537 25.2744 39.1537 25.2744C39.1537 25.4308 38.9973 25.7436 38.8409 25.7436Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.2" strokeMiterlimit="10" />
    <path d="M9.43587 17.4539C8.81022 16.8282 8.02817 16.5154 7.24612 16.5154C6.46407 16.5154 5.68202 16.8282 5.05638 17.4539C3.8051 18.7052 3.8051 20.5821 5.05638 21.8334C5.68202 22.459 6.46407 22.7718 7.24612 22.7718C8.02817 22.7718 8.81022 22.459 9.43587 21.8334C10.6871 20.5821 10.6871 18.7052 9.43587 17.4539ZM8.96663 21.2077C8.4974 21.6769 8.02817 21.8334 7.40253 21.8334C6.77689 21.8334 6.15125 21.5205 5.83843 21.2077C4.89997 20.2693 4.89997 18.8616 5.83843 17.9231C6.30766 17.4539 6.77689 17.2975 7.40253 17.2975C8.02817 17.2975 8.65381 17.6103 8.96663 17.9231C9.74869 18.8616 9.74869 20.4257 8.96663 21.2077Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.2" strokeMiterlimit="10" />
    <path d="M4.90008 23.0846C4.90008 23.241 4.74367 23.5538 4.58726 23.5538C4.27444 23.5538 3.96162 23.7103 3.6488 23.8667C3.17957 24.1795 2.71034 24.4923 2.39752 24.8051C2.0847 25.2744 1.92829 25.5872 1.92829 25.5872C1.92829 25.7436 1.77188 25.9 1.61547 25.9H1.45906C1.30265 25.9 1.14624 25.5872 1.14624 25.4308C1.14624 25.2744 1.92829 23.241 4.43085 22.9282C4.74367 22.7718 4.90008 22.7718 4.90008 23.0846Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.2" strokeMiterlimit="10" />
    <path d="M13.0333 25.7436C12.7205 25.7436 12.5641 25.7436 12.5641 25.5872C12.5641 25.5872 12.4077 25.118 12.0948 24.8052C11.782 24.4923 11.4692 24.1795 10.8436 23.8667C10.5307 23.7103 10.2179 23.7103 9.90511 23.5539C9.7487 23.5539 9.59229 23.2411 9.59229 23.0847C9.59229 22.9282 9.90511 22.7718 10.0615 22.7718C12.5641 23.0846 13.3461 25.2744 13.3461 25.2744C13.3461 25.4308 13.3461 25.7436 13.0333 25.7436Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.2" strokeMiterlimit="10" />
    <path d="M27.1101 16.359C27.1101 16.359 26.4845 15.4205 26.1717 14.9513V8.85129C26.1717 8.53847 26.0153 8.38206 25.8588 8.06924C25.546 7.75642 25.2332 7.60001 24.9204 7.60001H14.7537C14.4409 7.60001 13.9717 7.75642 13.8153 8.06924C13.6589 8.22565 13.5024 8.53847 13.5024 8.85129V15.1077C13.5024 15.7333 14.1281 16.2026 14.7537 16.2026H24.2947C24.6076 16.6718 25.3896 17.2974 26.3281 17.2974C26.4845 17.2974 26.4845 17.2974 26.6409 17.2974C26.6409 17.2974 26.9537 17.141 27.1101 16.8282C27.2665 16.8282 27.4229 16.6718 27.1101 16.359ZM24.9204 15.5769L14.9101 15.4205C14.5973 15.4205 14.4409 15.2641 14.4409 15.1077V8.85129C14.4409 8.69488 14.4409 8.69487 14.5973 8.69487C14.7537 8.53846 14.7537 8.53848 14.9101 8.53848H25.0768C25.2332 8.53848 25.3896 8.53846 25.3896 8.69487V8.85129V15.1077C25.546 15.5769 26.0153 16.0461 26.1717 16.5154C25.8588 16.5154 25.3896 16.0462 24.9204 15.5769Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.2" strokeMiterlimit="10" />
    <path d="M25.077 23.3974C24.9206 22.6154 23.5129 22.6154 23.0437 22.6154H17.4129C16.9437 22.6154 15.536 22.6154 15.3796 23.3974L12.5642 31.0615C12.5642 31.8436 14.1283 32 14.5975 32H26.3283C26.7975 32 28.3616 32 28.3616 31.0615V30.9051L25.077 23.3974ZM26.3283 31.2179H14.5975C13.9719 31.2179 13.6591 31.0615 13.5027 31.0615L16.1616 23.7103C16.3181 23.5538 16.7873 23.5538 17.4129 23.5538H23.0437C23.6693 23.5538 24.1386 23.7103 24.295 23.7103L27.4232 31.0615C27.2668 31.0615 26.9539 31.2179 26.3283 31.2179Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.2" strokeMiterlimit="10" />
  </svg>
);
