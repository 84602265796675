import { useSelector } from 'react-redux';
import lodashCloneDeep from 'lodash/cloneDeep';

// The active profile slice is intended to give us app-wide access to information about the profile active under focus
// it may change during time (editor etc) and there are some heavy calculations that can benefit multiple consumers.
// This slice tries to make it easy to maintain - the result of the postprocessing should be stored here, and the reading should be done from here.
// Implementation wise, we also cache enrichments for non-active profiles to save calculation times in the future

const initialState = {
  profileEnrichments: {}, // Dictionary from profileID to { result: {}, enrichments: {}}
  activeProfileId: 0, // The profile ID that we are focusing on
};

// Implementation note: We purposely implement this "slice" outside of redux because redux-toolkit uses immer, which does not support
// js classes. One of the main objects we want to cache with this implementation is the SwpProject loading, which is class based.
// We will provide a similar API to get the same developer experience.
const state = lodashCloneDeep(initialState);

export const updateActiveProfile = (profileId, result) => {
  state.activeProfileId = profileId;
  if (!state.profileEnrichments[profileId]) {
    state.profileEnrichments[profileId] = { result, enrichments: {} };
  } else if (state.profileEnrichments[profileId].result !== result) {
    // This profile ID already had an entry, but the result changed, so we are cleaning the enrichment cache
    state.profileEnrichments[profileId].result = result;
    state.profileEnrichments[profileId].enrichments = {};
  }
};

export const handleReceivedSwpProjectPatch = (patch /* SwpProjectPatch */) => {
  const activeProfileEnrichments = state.profileEnrichments[state.activeProfileId].enrichments;
  Object.keys(activeProfileEnrichments).forEach((enrichedName) => {
    const enrichedValue = activeProfileEnrichments[enrichedName];
    // An enrichment can support patches by implementing a handleSwpProjectPatch(patch) function
    if (enrichedValue.handleSwpProjectPatch) {
      enrichedValue.handleSwpProjectPatch(patch);
      activeProfileEnrichments[enrichedName] = { ...enrichedValue }; // Shallow copy to trigger rerender on users of useActiveProfileEnrichment()
    }
  });
};

const updateEnrichment = (name, enrichmentFunction) => {
  const data = enrichmentFunction(state.profileEnrichments[state.activeProfileId].result);
  state.profileEnrichments[state.activeProfileId].enrichments[name] = data;
  return data;
};

export const useActiveProfileEnrichment = (enrichmentName) => useSelector(() => state.profileEnrichments[state.activeProfileId].enrichments[enrichmentName]);
export const useActiveProfileResult = () => useSelector(() => state.profileEnrichments[state.activeProfileId].result);

// enrichmentFunction is a function that receives a result object and returns an encrichment object.
// if the enriched object contiains a function "handleSwpProjectPatch(patch)" it will be called when a patch arrives
export const useActiveProfileEnrichmentUpdate = (enrichmentName, enrichmentFunction) => {
  let enrichmentValue = useActiveProfileEnrichment(enrichmentName);
  if (!enrichmentValue) {
    enrichmentValue = updateEnrichment(enrichmentName, enrichmentFunction);
  }
  return enrichmentValue;
};
