import React from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import { Text } from '@react-three/drei';
import { vecAngle, vecMoveTowards, vecAdd, vecDistance } from 'utils/algorithms/algorithmHelpers';
import { metersToFitAndInches } from 'utils/helpers/unitsHelpers';
import DashedLine from '../DashedLine';
import TexturedPlane from '../TexturedPlane';
import images from '../../../../styles/static/img/feasibility';
import { WARNING_COLOR } from './constants';
import { isWarningLengthByIndex, getPropertiesBetweenTwoPoints, getColorBuyLengthAndId } from './sketchHelpers';

const LinesInProgress = ({ isClosed, isPolygon, firstPoint, lastPoint, globalLastPoint, pointInProgress, isImperial, color }) => {
  if (isClosed && !isPolygon) {
    return null;
  }

  const lengthToLast = vecDistance([pointInProgress.x, pointInProgress.y], lastPoint);

  let currentColor = color;

  if (!isPolygon) {
    currentColor = isWarningLengthByIndex(lengthToLast) ? WARNING_COLOR : color;
  }

  const pointInProgressProps = pointInProgress && globalLastPoint && getPropertiesBetweenTwoPoints(globalLastPoint, [pointInProgress.x, pointInProgress.y]);

  return (
    <>
      <group name="pointer" position={[0, 0, 10]}>
        <TexturedPlane size={3.5} position={pointInProgress} image={images.pointer} renderOrder={50} minFilter={THREE.NearestFilter} />
      </group>

      {lastPoint && (
      <DashedLine from={lastPoint} to={pointInProgress.toArray()} width={0.75} color={currentColor} zIndex={10} />
      )}
      {isPolygon && (
        <DashedLine from={firstPoint} to={pointInProgress.toArray()} width={0.75} color={color} zIndex={10} />
      )}

      {!isPolygon && (
      <Text
        position={[
          ...vecMoveTowards(pointInProgressProps.textPoint, vecAdd(pointInProgressProps.textPoint, pointInProgressProps.normal), 2.5),
          7,
        ]}
        rotation={[0, 0, vecAngle(pointInProgressProps.normal) < 0 ? vecAngle(pointInProgressProps.normal) + Math.PI / 2 : vecAngle(pointInProgressProps.normal) - Math.PI / 2]}
        fontSize={3}
        letterSpacing={0.05}
        color={getColorBuyLengthAndId(pointInProgressProps.length)}
        anchorX={vecAngle(pointInProgressProps.normal) < 0 ? 'right' : 'left'}
        anchorY="middle"
        font="Roboto"
        outlineWidth={0.1}
        outlineColor={getColorBuyLengthAndId(pointInProgressProps.length)}
      >
        { isImperial ? metersToFitAndInches(pointInProgressProps.length) : pointInProgressProps.length.toFixed(1)}
      </Text>
      )}
    </>
  );
};

LinesInProgress.propTypes = {
  isClosed: PropTypes.bool,
  isPolygon: PropTypes.bool,
  firstPoint: PropTypes.array,
  lastPoint: PropTypes.array,
  globalLastPoint: PropTypes.array,
  pointInProgress: PropTypes.any,
  isImperial: PropTypes.bool,
  color: PropTypes.string,
};

export default React.memo(LinesInProgress);
