import campusIcon from './campusIcon';
import courtyardIcon from './courtyardIcon';
import lShapeIcon from './lShapeIcon';
import megablockIcon from './megablockIcon';
import mixedTypologiesIcon from './mixedTypologiesIcon';
import towerIcon from './towerIcon';
import uShapeIcon from './uShapeIcon';
import wrapIcon from './wrapIcon';

export default {
  campusIcon,
  courtyardIcon,
  lShapeIcon,
  megablockIcon,
  mixedTypologiesIcon,
  towerIcon,
  uShapeIcon,
  wrapIcon,
};
