/* eslint-disable no-restricted-globals */
import PropTypes from 'prop-types';
import React, { useMemo, useEffect, useState } from 'react';
import * as THREE from 'three';

const TextFieldCaret = ({ height, color, fontSize }) => {
  const [hasFill, sethasFill] = useState(true);
  const offsetX = fontSize / 8;
  const vertices = useMemo(() => [new THREE.Vector3(offsetX, 0, 0), new THREE.Vector3(offsetX, height, 0)], [height]);

  useEffect(() => {
    const interval = setInterval(() => {
      sethasFill((state) => !state);
    }, 500);

    // Clean up can be done like this
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!hasFill) {
    return null;
  }

  return (
    <mesh>
      <meshLine attach="geometry" vertices={vertices} />
      <meshLineMaterial attach="material" color={color} lineWidth={offsetX / 3} />
    </mesh>
  );
};

TextFieldCaret.propTypes = {
  height: PropTypes.number,
  color: PropTypes.number,
  fontSize: PropTypes.number,
};

export default React.memo(TextFieldCaret);
