import styled from 'styled-components';

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const InputTitle = styled.div`
  width: 155px;
`;

export const StyledSliderWrapper = styled.div`
  display: flex;
  width: 260px;
`;

export const StyledSliderPercentage = styled.div`
  margin: 0 7px;
`;
