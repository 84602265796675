import React from 'react';

// currentcolor
export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="2.00031" width="2.34142" height="3.51213" rx="1" fill="currentcolor" />
    <rect x="2" y="8.24445" width="2.34142" height="3.51213" rx="1" fill="currentcolor" />
    <rect x="2" y="14.4876" width="2.34142" height="3.51213" rx="1" fill="currentcolor" />
    <rect x="6.29297" y="2.00031" width="11.7071" height="3.51213" rx="1" fill="currentcolor" />
    <rect x="6.29297" y="8.24445" width="11.7071" height="3.51213" rx="1" fill="currentcolor" />
    <rect x="6.29297" y="14.4876" width="11.7071" height="3.51213" rx="1" fill="currentcolor" />
  </svg>
);
