import React, { createContext, useContext, useRef } from 'react';

export const contentRefContexts = createContext(null);
export const BeginFinishedEditContexts = createContext();

// eslint-disable-next-line react/prop-types
export const BeginFinishedEditProvider = ({ children }) => {
  const ref = useRef({
    finish: () => {},
    begin: () => {},
    setBeginEditing: (fn) => {
      ref.current.begin = fn;
    },
    setFinishEditing: (fn) => {
      ref.current.finish = fn;
    },
  });
  return <BeginFinishedEditContexts.Provider value={ref.current}>{children}</BeginFinishedEditContexts.Provider>;
};

export const useBeginFinishedEditContext = () => {
  const context = useContext(BeginFinishedEditContexts);
  if (context === undefined) {
    throw new Error('useBeginFinishedEditContext must be used within a BeginFinishedEditProvider');
  }
  return context;
};
