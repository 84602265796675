import React from 'react';

export default () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1H12.5H24V24H1V1Z" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="4.19434" y="4.83331" width="3.83333" height="3.28571" stroke="currentcolor" strokeWidth="1.5" strokeLinejoin="round" />
    <rect x="4.19434" y="11.4048" width="3.83333" height="3.28571" stroke="currentcolor" strokeWidth="1.5" strokeLinejoin="round" />
    <rect x="4.19434" y="17.9761" width="3.83333" height="3.28571" stroke="currentcolor" strokeWidth="1.5" strokeLinejoin="round" />
    <path d="M11.2223 6.47614H20.1667" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.2223 13.0476H20.1667" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.2223 19.6191H20.1667" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
