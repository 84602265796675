import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import T from 'i18n-react';
import { Modal } from 'antd';
import lodashGet from 'lodash/get';
import lodashIncludes from 'lodash/includes';
import { LoadingOutlined } from '@ant-design/icons';
import { swappProjectSelector, activePollingProfileIdsSelector } from 'store/swappProfile/selectors';
import { PROFILE_STATUS } from 'constants/profileConsts';
import { WAITING_FOR_KEYS } from 'constants/feasibilityConts';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { PrimaryButton } from 'styles/commonComponents.styles';
import { pollSwappProjectAction, generateReportAction } from 'store/swappProfile/actions/swappProfileActions';

const { confirm } = Modal;

const WellbeingAnalysisButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const GenerateReportButton = () => {
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const swappProject = useSelector(swappProjectSelector);
  const activePollingProfileIds = useSelector(activePollingProfileIdsSelector);
  const dispatch = useDispatch();
  const profileIndex = lodashGet(swappProject, 'projectProfiles', []).findIndex((profile) => profile.id === Number(locationData.profileId));
  const profile = lodashGet(swappProject, `projectProfiles[${profileIndex}]`);
  const isProfileLoading = lodashGet(profile, 'status') === PROFILE_STATUS.WAITING_FOR_EXTRA_DATA;
  const isLoading = isProfileLoading && lodashGet(profile, `result.waitingFor[${WAITING_FOR_KEYS.REPORT}]`);

  useEffect(() => {
    if (isLoading && locationData.profileId && !lodashIncludes(activePollingProfileIds, locationData.profileId)) {
      dispatch(pollSwappProjectAction(locationData.projectId, locationData.profileId, history, WAITING_FOR_KEYS.REPORT));
    }
  }, [isLoading, locationData.profileId, activePollingProfileIds]);

  const showConfirmModal = (e) => {
    e.stopPropagation();

    confirm({
      title: T.translate('GENERATE_REPORT_BUTTON_MODAL_TITLE'),
      content: T.translate('GENERATE_REPORT_BUTTON_MODAL_CONTENT'),
      okText: T.translate('GENERATE_REPORT_BUTTON_MODAL_OK_TEXT'),
      width: 500,
      onOk: () => {
        dispatch(generateReportAction(locationData.projectId, locationData.profileId, history));
      },
    });
  };

  return (
    <WellbeingAnalysisButtonWrapper>
      <PrimaryButton onClick={(e) => (isLoading ? null : showConfirmModal(e))} disabled={isLoading} marginTop={16} marginBottom={25} width={300} type="submit">
        {isLoading ? <LoadingOutlined spin /> : T.translate('GENERATE_REPORT_BUTTON')}
      </PrimaryButton>
    </WellbeingAnalysisButtonWrapper>
  );
};

export default GenerateReportButton;
