import React from 'react';
import PropTypes from 'prop-types';
import lodashIsNumber from 'lodash/isNumber';
import * as THREE from 'three';

const MeshLine = (props) => {
  const { vertices, color, lineWidth, height = 200, isDashed = false, dashProperties, meshProps } = props;

  if (!vertices) {
    return null;
  }

  const dashProps = isDashed ? { dashArray: 0.03, dashRatio: 0.4, dashOffset: 0, transparent: true, ...dashProperties } : {};

  return (
    <mesh castShadow position={[0, 0, height]} {...meshProps}>
      <meshLine attach="geometry" vertices={vertices.map((v) => lodashIsNumber(v[0]) && lodashIsNumber(v[1]) && new THREE.Vector3(...v))} />
      <meshLineMaterial attach="material" color={color || 'black'} lineWidth={lineWidth || 0.2} {...dashProps} />
    </mesh>
  );
};

MeshLine.propTypes = {
  vertices: PropTypes.array,
  lineWidth: PropTypes.number,
  color: PropTypes.string,
  height: PropTypes.number,
  isDashed: PropTypes.bool,
  dashProperties: PropTypes.object,
  meshProps: PropTypes.object,
};

export default MeshLine;
