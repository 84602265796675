import React from 'react';

export default () => (
  <svg width="150" height="40" viewBox="0 0 116 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.4832 12.07V1H1V12.5727L8.5086 15.3041" stroke="#5772ff" strokeWidth="1.32482" />
    <path d="M10.1697 15.7981C10.1872 15.5944 10.0362 15.4151 9.83256 15.3976C9.62888 15.3801 9.44958 15.531 9.43208 15.7347C9.41458 15.9384 9.56551 16.1177 9.76919 16.1352C9.97287 16.1527 10.1522 16.0018 10.1697 15.7981Z" fill="#5772ff" stroke="#5772ff" strokeWidth="0.623443" />
    <path d="M12.0188 16.4885C12.0363 16.2848 11.8854 16.1055 11.6817 16.088C11.478 16.0705 11.2987 16.2215 11.2812 16.4251C11.2637 16.6288 11.4146 16.8081 11.6183 16.8256C11.822 16.8431 12.0013 16.6922 12.0188 16.4885Z" fill="#5772ff" stroke="#5772ff" strokeWidth="0.623443" />
    <path d="M13.8718 17.1829C13.8893 16.9792 13.7384 16.7999 13.5347 16.7824C13.331 16.7649 13.1517 16.9158 13.1342 17.1195C13.1167 17.3232 13.2677 17.5025 13.4713 17.52C13.675 17.5375 13.8543 17.3865 13.8718 17.1829Z" fill="#5772ff" stroke="#5772ff" strokeWidth="0.623443" />
    <path d="M1 15.3845V26.4545H26.4832V14.8818L18.9747 12.1465" stroke="#5772ff" strokeWidth="1.32482" />
    <path d="M18.0488 11.72C18.0663 11.5163 17.9154 11.337 17.7117 11.3195C17.508 11.302 17.3287 11.4529 17.3112 11.6566C17.2937 11.8603 17.4447 12.0396 17.6483 12.0571C17.852 12.0746 18.0313 11.9236 18.0488 11.72Z" fill="white" stroke="#5772ff" strokeWidth="0.623443" />
    <path d="M16.1995 11.0295C16.217 10.8259 16.066 10.6465 15.8623 10.629C15.6587 10.6115 15.4794 10.7625 15.4619 10.9662C15.4444 11.1698 15.5953 11.3491 15.799 11.3666C16.0027 11.3841 16.182 11.2332 16.1995 11.0295Z" fill="white" stroke="#5772ff" strokeWidth="0.623443" />
    <path d="M14.3462 10.3372C14.3637 10.1335 14.2128 9.95417 14.0091 9.93667C13.8054 9.91917 13.6261 10.0701 13.6086 10.2738C13.5911 10.4775 13.742 10.6568 13.9457 10.6743C14.1494 10.6918 14.3287 10.5408 14.3462 10.3372Z" fill="white" stroke="#5772ff" strokeWidth="0.623443" />
    <path d="M80.3098 16.6091H72.883L70.8295 21.1758H69.2397L75.8521 6.71191H77.3601L83.9725 21.1758H82.3593L80.3098 16.6091ZM79.7097 15.2648L76.6003 8.30169L73.487 15.2648H79.7097Z" fill="#5772ff" />
    <path d="M34.3543 20.6579C33.3685 20.315 32.9515 20.0773 32.4138 19.5123L33.0139 18.3356C33.536 18.8577 33.8906 19.0642 34.7595 19.3877C35.6284 19.7111 36.5208 19.8747 37.4442 19.8747C38.7379 19.8747 39.712 19.637 40.3588 19.1617C41.0056 18.6863 41.3291 18.0706 41.3291 17.3108C41.3291 16.7302 41.1537 16.2704 40.803 15.9275C40.4523 15.5846 40.0198 15.3197 39.5133 15.1327C39.0028 14.9456 38.2937 14.743 37.3858 14.5248C36.2987 14.2481 35.4297 13.9832 34.7829 13.7299C34.1361 13.4766 33.5828 13.087 33.1191 12.5609C32.6554 12.0388 32.4255 11.3296 32.4255 10.4334C32.4255 9.7048 32.6164 9.03849 33.0061 8.43843C33.3918 7.83836 33.9841 7.35909 34.7829 7.00061C35.5817 6.64213 36.5753 6.46289 37.7598 6.46289C38.5859 6.46289 39.3964 6.57589 40.1874 6.80189C40.9784 7.02789 41.6642 7.3435 42.2447 7.74095L41.7265 8.96056C41.1186 8.55922 40.4718 8.26308 39.7821 8.06046C39.0925 7.86174 38.4184 7.76043 37.7559 7.76043C36.4896 7.76043 35.5349 8.00591 34.8959 8.49298C34.2569 8.98394 33.9335 9.61128 33.9335 10.3828C33.9335 10.9634 34.1088 11.4271 34.4595 11.7777C34.8102 12.1284 35.2505 12.3973 35.7804 12.5843C36.3103 12.7714 37.0234 12.974 37.9196 13.1922C38.9795 13.4532 39.8367 13.7143 40.4913 13.9676C41.1459 14.2209 41.6992 14.6066 42.1551 15.1249C42.611 15.6431 42.837 16.3406 42.837 17.2212C42.837 17.9498 42.6422 18.6161 42.2486 19.2162C41.8551 19.8163 41.255 20.2916 40.4407 20.6423C39.6263 20.993 38.6288 21.1684 37.4442 21.1684C36.3688 21.1762 35.3401 21.0047 34.3543 20.6579Z" fill="#5772ff" />
    <path d="M66.7383 6.71191L62.3314 19.06L57.9244 6.71191H57.8893H56.3541H56.3151L51.9082 19.06L47.5012 6.71191H45.9309L51.0938 21.1758H51.1561H52.6641H52.7264L57.1217 8.86279L61.517 21.1758H61.5793H63.0873H63.1496L68.3125 6.71191H66.7383Z" fill="#5772ff" />
    <path d="M98.0779 8.03283C97.0298 7.15222 95.5842 6.71191 93.7372 6.71191H88.325V21.1758H89.8524V19.2081V16.6091V15.2648V13.4607V8.03283H93.6983C95.1322 8.03283 96.2271 8.34845 96.983 8.9719C97.7389 9.59924 98.1208 10.4993 98.1208 11.6683C98.1208 12.8139 97.7428 13.6984 96.983 14.3218C96.2271 14.9492 95.1322 15.2609 93.6983 15.2609H91.6175V16.6052H93.7411C95.5881 16.6052 97.0337 16.1688 98.0818 15.2921C99.13 14.4192 99.6521 13.2074 99.6521 11.6644C99.6521 10.1214 99.1261 8.91345 98.0779 8.03283Z" fill="#5772ff" />
    <path d="M114.362 8.03283C113.313 7.15222 111.868 6.71191 110.021 6.71191H104.609V21.1758H106.136V19.2081V16.6091V15.2648V13.4607V8.03283H109.982C111.416 8.03283 112.511 8.34845 113.267 8.9719C114.023 9.59924 114.404 10.4993 114.404 11.6683C114.404 12.8139 114.027 13.6984 113.267 14.3218C112.511 14.9492 111.416 15.2609 109.982 15.2609H107.901V16.6052H110.025C111.872 16.6052 113.317 16.1688 114.366 15.2921C115.414 14.4192 115.936 13.2074 115.936 11.6644C115.936 10.1214 115.41 8.91345 114.362 8.03283Z" fill="#5772ff" />
  </svg>
);
