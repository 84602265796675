import React from 'react';

export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.15" y="0.15" width="39.7" height="39.7" rx="3.85" fill="#5772FF" />
    <path d="M27.4303 10.0678C27.4691 10.03 27.5309 10.03 27.5697 10.0678L34.8234 17.12C34.8878 17.1825 34.8435 17.2917 34.7537 17.2917H29.1C29.0448 17.2917 29 17.3364 29 17.3917V28.65C29 28.7052 28.9552 28.75 28.9 28.75H26.1C26.0448 28.75 26 28.7052 26 28.65V17.3917C26 17.3364 25.9552 17.2917 25.9 17.2917H20.2463C20.1565 17.2917 20.1122 17.1825 20.1766 17.12L27.4303 10.0678Z" fill="white" />
    <path d="M12.5697 28.6822C12.5309 28.72 12.4691 28.72 12.4303 28.6822L5.17661 21.63C5.11224 21.5675 5.15654 21.4583 5.24631 21.4583H10.9C10.9552 21.4583 11 21.4136 11 21.3583V10.1C11 10.0448 11.0448 10 11.1 10H13.9C13.9552 10 14 10.0448 14 10.1L14 21.3583C14 21.4136 14.0448 21.4583 14.1 21.4583H19.7537C19.8435 21.4583 19.8878 21.5675 19.8234 21.63L12.5697 28.6822Z" fill="white" />
    <rect x="0.15" y="0.15" width="39.7" height="39.7" rx="3.85" stroke="white" strokeWidth="0.3" />
  </svg>

);
