import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { metricDivideValue } from 'utils/helpers/unitsHelpers';
import { StyledInputNumber } from 'styles/commonComponents.styles';

let debounceTimer = null;

const MetricImperialInput = forwardRef((props, ref) => {
  const { onChange, value, isImperial, unit = metricDivideValue, suffix, decimals } = props;
  const [stringValue, useStringValue] = useState({ value: Number(value), isImperial });

  const currentDecimals = { metric: 1, imperial: 0, ...decimals };

  useEffect(() => {
    if (value) {
      handleUpdate(isImperial ? value * unit : value, useStringValue);
    } else {
      handleUpdate(0, useStringValue);
    }
  }, [value]);

  useEffect(() => {
    if (stringValue.isImperial && !isImperial) {
      handleUpdate(Number(stringValue.value / unit), useStringValue);
    }
    if (!stringValue.isImperial && isImperial) {
      handleUpdate(Number(stringValue.value * unit), useStringValue);
    }
  }, [isImperial]);

  const handleUpdate = (currentValue, update) => {
    update({ value: Number(currentValue), isImperial });
  };

  const handleChange = (currentValue, update) => {
    handleUpdate(currentValue, update);

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    debounceTimer = setTimeout(() => {
      onChange(isImperial ? currentValue / unit : currentValue);
    }, [300]);
  };

  return (
    <StyledInputNumber
      ref={ref}
      {...props}
      value={stringValue.value}
      formatter={(currentValue) => numberWithComma(currentValue, { suffix: suffix || '', fixed: isImperial ? currentDecimals.imperial : currentDecimals.metric })}
      onChange={(currentValue) => handleChange(currentValue, useStringValue)}
    />
  );
});

MetricImperialInput.propTypes = {
  value: PropTypes.number,
  match: PropTypes.object,
  onChange: PropTypes.func,
  isImperial: PropTypes.bool,
  unit: PropTypes.number,
  suffix: PropTypes.string,
  decimals: PropTypes.object, // needs to be an object with one of metric/imperial e.g { metric: 1, imperial: 0 }
};

export default MetricImperialInput;
