import lodashIncludes from 'lodash/includes';
import lodashFlatten from 'lodash/flatten';
import { LAYER_NAMES_TO_KEYS } from '../../constants/autocadConsts';

export const filterLayersByRelevance = (layers = []) => {
  const relevantLayerKeys = ['LSF', 'PSF', 'ASF', 'FSF', 'Leftovers'];
  const layerNames = layers.map((layer) => layer.name);
  return lodashFlatten(relevantLayerKeys.map((key) => {
    const currentLayers = [];
    layerNames.forEach((name) => (lodashIncludes(name, key) && currentLayers.push(name)));
    return currentLayers;
  }));
};

export const getOtherLayers = (layers, type, key, hideType) => {
  if (!layers) {
    return;
  }

  const relevantLayers = filterLayersByRelevance(layers);

  if (!key) { // toggle only by type - show group, e.g all 'LSF' layers.
    return relevantLayers.filter((layer) => !lodashIncludes(layer, type));
  }

  if (hideType) { // show key in type - show 1 layer insane of a group, e.g '0-HA LSF 1 Person' in 'LSF'.
    const filteredRelevantLayers = relevantLayers.filter((layer) => lodashIncludes(layer, type));
    return filteredRelevantLayers.filter((layer) => !lodashIncludes(LAYER_NAMES_TO_KEYS[key] || key, cleanLayerName(layer)));
  }

  // show key in all - show 1 layer from all of the layers.
  return relevantLayers.filter((layer) => !lodashIncludes(LAYER_NAMES_TO_KEYS[key] || key, cleanLayerName(layer)));
};

export const cleanLayerName = (layerName) => layerName && layerName.split(' ').splice(2).join(' ');
