import React from 'react';

export default () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.5718 16.4689H21.3379L24.9544 9.68018L28.5718 16.4689ZM22.1218 15.9978H27.7867L24.9543 10.6821L22.1218 15.9978Z" fill="currentcolor" />
    <path d="M27.55 18.3546H22.45V15.998H27.5512L27.55 18.3546ZM22.921 17.8847H27.0799V16.4691H22.921V17.8847Z" fill="currentcolor" />
    <path d="M30.939 24.4673H19.0608V22.7576L19.1124 22.6931C19.4452 22.2748 19.8237 21.8494 20.1905 21.4381C21.1761 20.3295 22.1944 19.1845 22.4557 18.0667L22.4979 17.885H27.5031L27.5441 18.069C27.7926 19.1905 28.8273 20.3495 29.8293 21.4698C30.1867 21.8706 30.557 22.2842 30.8863 22.6921L30.9391 22.7565L30.939 24.4673ZM19.5321 23.9974H30.4692V22.924C30.1598 22.5455 29.8141 22.1588 29.479 21.7838C28.4887 20.6763 27.468 19.5314 27.1329 18.356L22.866 18.3548C22.5214 19.5243 21.516 20.6552 20.5421 21.7508C20.1988 22.1363 19.8449 22.5348 19.532 22.9226L19.5321 23.9974Z" fill="currentcolor" />
    <path d="M30.939 35.5121H19.0608V25.9331H30.9402L30.939 35.5121ZM19.5321 35.041H30.4692L30.468 26.4043H19.5321L19.5321 35.041Z" fill="currentcolor" />
    <path d="M29.9405 26.4042H26.897V23.9972H29.9405V26.4042ZM27.3682 25.9332H29.4694V24.4671H27.3682V25.9332Z" fill="currentcolor" />
    <path d="M22.9209 26.4042H19.8774V23.9972H22.9209V26.4042ZM20.3487 25.9332H22.4499V24.4671H20.3487V25.9332Z" fill="currentcolor" />
    <path d="M24.9999 34.3777C22.9843 34.3777 21.3447 32.7382 21.3447 30.7225C21.3447 28.7067 22.9842 27.0673 24.9999 27.0673C27.0157 27.0673 28.6551 28.7067 28.6551 30.7225C28.6551 32.7382 27.0157 34.3777 24.9999 34.3777ZM24.9999 27.5386C23.2444 27.5386 21.816 28.9671 21.816 30.7225C21.816 32.4779 23.2445 33.9064 24.9999 33.9064C26.7553 33.9064 28.1838 32.4779 28.1838 30.7225C28.1838 28.967 26.7553 27.5386 24.9999 27.5386Z" fill="currentcolor" />
    <path d="M26.296 31.2005H24.5229V28.5286H24.9929V30.7294H26.296V31.2005Z" fill="currentcolor" />
    <path d="M24.8438 35.2763H25.3148V38.867H24.8438V35.2763Z" fill="currentcolor" />
    <path d="M22.4229 35.2763H22.8939V39.5936H22.4229V35.2763Z" fill="currentcolor" />
    <path d="M20.0032 35.2763H20.4743V40.3199H20.0032V35.2763Z" fill="currentcolor" />
    <path d="M27.2651 35.2763H27.7362V39.5936H27.2651V35.2763Z" fill="currentcolor" />
    <path d="M29.6851 35.2763H30.1561V40.3199H29.6851V35.2763Z" fill="currentcolor" />
  </svg>
);
