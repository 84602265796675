/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MeshLineRaycast } from 'threejs-meshline';
import * as THREE from 'three';
import { useSelector, useDispatch } from 'react-redux';
import { currentThemeSelector } from 'store/userSettings';
import {
  PARKING_ENTRANCE_MODES,
  parkingFormSelector,
  parkingFormModeSelector,
  setParkingEntrance,
  edgeIndexSelector,
  entrancePositionSelector,
  positionEdgeIndexSelector,
} from 'store/parkingForm';
import { shadeHexColor } from 'utils/helpers/threeHelpers';
import { Plane, Html } from '@react-three/drei';
import { vecSub, vecAngle, vecMoveTowards } from 'utils/algorithms/algorithmHelpers';
import Icon from '@ant-design/icons';
import styled from 'styled-components';
import icons from 'styles/static/icons/testFit';
import { changeCursor } from '../helpers/SelectionHelpers';

const StyledIcon = styled(Icon)`
  transform: translateX(-50%) translateY(-50%) rotate(${({ angle }) => -angle}rad) scale(0.7)
`;

const SitePolygon = ({ sitePolygon }) => {
  const currentTheme = useSelector(currentThemeSelector);
  const isParkingFormOn = useSelector(parkingFormSelector);
  const parkingFormMode = useSelector(parkingFormModeSelector);
  const selectedEdgesIndex = useSelector(edgeIndexSelector);
  const selectedEntancePosition = useSelector(entrancePositionSelector);
  const positionEdgeIndex = useSelector(positionEdgeIndexSelector);
  const dispatch = useDispatch();

  const [parkingHoverMap, setParkingHoverMap] = useState({});
  const [hoveredParkingEntrancePositon, setHoveredParkingEntrancePositon] = useState(null);

  const { primaryColor } = currentTheme.colors;
  const PLANE_SIZE = 5;
  const isEdgeMode = parkingFormMode === PARKING_ENTRANCE_MODES.EDGE;

  const onParkClick = (e) => {
    if (!isParkingFormOn) {
      return;
    }
    const { index } = e.object;
    const point = e.point.toArray();
    const points = isEdgeMode
      ? [sitePolygon[index], sitePolygon[(index + 1) % sitePolygon.length]]
      : [vecMoveTowards(point, sitePolygon[index], 0.5), vecMoveTowards(point, sitePolygon[(index + 1) % sitePolygon.length], 0.5)];
    dispatch(setParkingEntrance({
      index,
      points,
      position: point,
      isEdgeMode,
    }));
  };

  const onPointerOut = (e) => {
    if (!isParkingFormOn) {
      return;
    }
    if (isEdgeMode) {
      changeCursor('default');
      setParkingHoverMap({ ...parkingHoverMap, [e.object.index]: false });
    } else {
      setHoveredParkingEntrancePositon(null);
    }
  };

  const onPointerEnter = (e) => {
    if (!isParkingFormOn) {
      return;
    }
    changeCursor('pointer');
    if (isEdgeMode) {
      setParkingHoverMap({ ...parkingHoverMap, [e.object.index]: true });
    } else {
      const { index } = e.object;
      setHoveredParkingEntrancePositon({ position: e.point.toArray(), index });
    }
  };

  let angle = 0;
  if (!isEdgeMode && !isNaN(positionEdgeIndex)) {
    const diff = vecSub(sitePolygon[positionEdgeIndex], sitePolygon[(positionEdgeIndex + 1) % sitePolygon.length]);
    angle = vecAngle(diff);
  }

  let hoverAngle = 0;
  if (!isNaN(hoveredParkingEntrancePositon?.index)) {
    const hoverDiff = vecSub(sitePolygon[hoveredParkingEntrancePositon.index], sitePolygon[(hoveredParkingEntrancePositon.index + 1) % sitePolygon.length]);
    hoverAngle = vecAngle(hoverDiff);
  }

  return (
    sitePolygon.map((v, index) => {
      let color = shadeHexColor(primaryColor, 0.4);
      let lineWidth = 0.75;
      if (isParkingFormOn) {
        if (isEdgeMode) {
          if (parkingHoverMap[index]) {
            color = shadeHexColor(primaryColor, 0.4);
            lineWidth = 1.35;
          }
          if (selectedEdgesIndex[index]) {
            color = shadeHexColor(primaryColor, 0);
            lineWidth = 1.35;
          }
        }
      }
      return (
        <group key={index}>
          <mesh
            index={index}
            position={[0, 0, 5]}
            raycast={MeshLineRaycast}
            onClick={onParkClick}
            onPointerOut={onPointerOut}
            onPointerEnter={onPointerEnter}
            onPointerMove={onPointerEnter}
          >
            <meshLine attach="geometry" vertices={[new THREE.Vector3(...v), new THREE.Vector3(...sitePolygon[(index + 1) % sitePolygon.length])]} />
            <meshLineMaterial attach="material" color={color} lineWidth={lineWidth} />
          </mesh>
          {isParkingFormOn && !isEdgeMode && hoveredParkingEntrancePositon
          && (
            <Plane
              opacity={0.5}
              args={[1, 1]}
              rotation={[0, 0, hoverAngle]}
              scale={[PLANE_SIZE / 2, PLANE_SIZE / 2]}
              position={[...hoveredParkingEntrancePositon.position]}
            >
              <meshStandardMaterial attach="material" color={shadeHexColor(primaryColor, -0)} transparent opacity={0.5} />
            </Plane>
          )}
          {isParkingFormOn && !isEdgeMode && selectedEntancePosition
          && (
            <Html
              position={[selectedEntancePosition[0], selectedEntancePosition[1], 201]}
            >
              <StyledIcon
                component={icons.parkingEntrance}
                angle={angle}
              />
            </Html>
          )}
        </group>
      );
    })
  );
};

SitePolygon.propTypes = {
  from: PropTypes.array,
  to: PropTypes.array,
  color: PropTypes.string,
  width: PropTypes.number,
  dashSize: PropTypes.number,
  zIndex: PropTypes.number,
};

export default React.memo(SitePolygon);
