export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';

export const LOGOUT = 'LOGOUT';

export const RESET_PASS_REQUEST = 'RESET_PASS_REQUEST';
export const RESET_PASS_SUCCESS = 'RESET_PASS_SUCCESS';
export const RESET_PASS_ERROR = 'RESET_PASS_ERROR';

export const CONFIRM_RESET_PASS_REQUEST = 'CONFIRM_RESET_PASS_REQUEST';
export const CONFIRM_RESET_PASS_SUCCESS = 'CONFIRM_RESET_PASS_SUCCESS';
export const CONFIRM_RESET_PASS_ERROR = 'CONFIRM_RESET_PASS_ERROR';

export const CREATE_PASS_REQUEST = 'CREATE_PASS_REQUEST';
export const CREATE_PASS_SUCCESS = 'CREATE_PASS_SUCCESS';
export const CREATE_PASS_ERROR = 'CREATE_PASS_ERROR';

export const SET_AUTH_CHECKED = 'SET_AUTH_CHECKED';
