import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');
  
  * {
    font-family: 'Source Sans Pro', 'Roboto', sans-serif;
  }
  body{
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    color: ${({ theme }) => theme.colors.textColor} !important;
    font-size: ${({ theme }) => theme.font.size.medium};
    
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
       -webkit-appearance: none; 
       margin: 0; 
    }
  }

  .ant-image-preview-img {
    max-width: 90% !important;
    max-height: 90% !important;
  }
  
  .ant-table-empty {
    .ant-table-cell:after {
      background-color: ${({ theme }) => theme.colors.backgroundColor} !important;
      border-radius: ${({ theme }) => theme.other.borderRadius} !important;
    }
  }
  
  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: 0 !important;
  }

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray_02};
    border: 0;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.gray_04};
  }
  ::-webkit-scrollbar-thumb:active {
    background: ${({ theme }) => theme.colors.gray_04};
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border: 0;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  
  .ant-row-table-hide {
    display: none;
  }

  .ant-modal-content,
  .ant-upload,
  .ant-modal-confirm-title,
  .ant-modal-confirm-content,
  .ant-select-selection-item,
  .ant-select-selection-item-remove,
  .ant-select-dropdown,
  .ant-select-tree,
  .ant-select-selector,
  .ant-input,
  .ant-dropdown-menu,
  .ant-table-tbody {
    color: ${({ theme }) => theme.colors.textColor} !important;
  }
    
  .ant-form-item label {
    color: ${({ theme }) => theme.colors.textColor} !important;
  }

  .ant-spin-container {
    .ant-table {
      background: ${({ theme }) => theme.colors.gray_01} !important;
    }
  }
  
  .ant-cascader-menu-item {
    :hover {
      color: ${({ theme }) => theme.colors.primaryColor};
      background-color: transparent;
    }
  }
  
  .ant-cascader-menu-item-active {
    background-color: ${({ theme }) => theme.colors.primaryColor} !important;
    color: ${({ theme }) => theme.colors.white} !important;
    font-weight: ${({ theme }) => theme.font.weight.normal} !important;
    
    .ant-cascader-menu-item-expand-icon {
      color: ${({ theme }) => theme.colors.white} !important;
    }
  }
 
  .ant-input {
    &:focus-within, &:hover {
      border-color: ${({ theme }) => theme.colors.primaryColor};
      box-shadow: none !important;
    }
  }
  
  .ant-list {
    width: 100%;
  }
  
  .ant-modal-content {
    border-radius: ${({ theme }) => theme.other.borderRadius};
  }

  // set the modal buttons to the middle  
  //.ant-modal-confirm .ant-modal-confirm-btns {
  //  width: 100%;
  //  display: flex;
  //  justify-content: center;
  //}
  
  .ant-select-item-option,
  .ant-select-item-option-selected {
    background-color: ${({ theme }) => theme.colors.gray_01} !important;
    
    &:hover {
      background-color: ${({ theme }) => theme.colors.gray_02} !important;
    }
    
    .ant-select-item-option-state {
      color: ${({ theme }) => theme.colors.primaryColor} !important;
    }
  }

  .ant-btn {
    border-radius: ${({ theme }) => theme.other.borderRadius};
    min-width: 60px;
    
    &:hover {
      color: ${({ theme }) => theme.colors.primaryColor};
      border-color: ${({ theme }) => theme.colors.primaryColor};
    }
  }
  
  .ant-btn-primary {
    margin-left: 12px !important;
    &:hover {
      color: ${({ theme }) => theme.colors.gray_01};
      background-color: ${({ theme }) => theme.colors.primaryColorHover} !important;
      border-color: ${({ theme }) => theme.colors.primaryColorHover} !important;
    }
  }
    
  .ant-btn-danger {
    &:hover {
      color: ${({ theme }) => theme.colors.gray_01};
      background-color: #ff7978 !important;
      border-color: #ff7978 !important;
    }
  }
  
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.primaryColor};
    border-color: ${({ theme }) => theme.colors.primaryColor};
  }

  .ant-select-selection {
    &:hover {
      border-color: ${({ theme }) => theme.colors.primaryColor};
    }
    &:focus {
      box-shadow: none;
    }
  }
  
  .ant-select-tree-switcher, .ant-select-tree-indent {
    display: none !important;
  }
  
  .ant-select-dropdown-menu-item-active,
  .ant-select-dropdown-menu-item-selected,
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: ${({ theme }) => theme.colors.gray_01};
    color: ${({ theme }) => theme.colors.primaryColor};
  }

  .ant-select-tree-treenode-switcher-open {

    .ant-select-tree-checkbox-inner {
      :after {
        background-color: ${({ theme }) => theme.colors.primaryColor};
      }
    }

    .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
      background-color: ${({ theme }) => theme.colors.primaryColor};
      border-color: ${({ theme }) => theme.colors.primaryColor};

      :hover {
        border-color: ${({ theme }) => theme.colors.primaryColor};
      }
    }
    
    :first-child {
      padding: 0;
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray_02};
      margin-bottom: 8px;
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.primaryColor} !important;
  }
  
  .ant-checkbox-wrapper-disabled {
      color: ${({ theme }) => theme.colors.gray_01} !important;

      .ant-checkbox-checked .ant-checkbox-inner {
          background-color: ${({ theme }) => `${theme.colors.primaryColor}70`} !important;
          background-color: ${({ theme }) => `${theme.colors.primaryColor}70`} !important;
  
          :hover {
            background-color: ${({ theme }) => `${theme.colors.primaryColor}70`} !important;
        }
      }
  }

  .ant-btn-primary {
    background-color: ${({ theme }) => theme.colors.primaryColor};
    border-color: ${({ theme }) => theme.colors.primaryColor};
    
    :hover{
      background-color: ${({ theme }) => theme.colors.primaryColorHover};
      border-color: ${({ theme }) => theme.colors.primaryColorHover};
    }
    :focus{
      background-color: ${({ theme }) => theme.colors.primaryColor};
      border-color: ${({ theme }) => theme.colors.primaryColor};
    }
  }
  
  .ant-switch-checked {
    background-color: ${({ theme }) => theme.colors.primaryColor} !important;
  }

  .ant-form-item label{
		font-size: ${({ theme }) => theme.font.size.inputLabel};
		font-weight: ${({ theme }) => theme.font.weight.bold};
		font-style: normal;
		font-stretch: normal;
		letter-spacing: normal;
		color: #333333;
			::after{
				content: unset;
			}
	}
	
	button{
	  a{
	    color: ${({ theme }) => theme.colors.gray_01};
	    :hover{
	      color: ${({ theme }) => theme.colors.gray_01};
	    }
	  }
	}
  
  .ant-table-footer {
    padding: 0;
  }

  .ant-radio-button-wrapper {
    &:hover {
      color: ${({ theme }) => theme.colors.primaryColor};
    }
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: ${({ theme }) => theme.colors.primaryColor};
    border-color: ${({ theme }) => theme.colors.primaryColor};

    &:hover, &:before {
      background: ${({ theme }) => theme.colors.primaryColorHover};
      border-color: ${({ theme }) => theme.colors.primaryColorHover};
    }
  }

  .ant-radio-button-wrapper {
    
    &:first-child {
      border-radius: ${({ theme }) => `${theme.other.borderRadius} 0 0 ${theme.other.borderRadius}`} !important;
    }

    &:last-child {
      border-radius: ${({ theme }) => `0 ${theme.other.borderRadius} ${theme.other.borderRadius} 0`};
    }
  }
  
  .ant-popover-inner {
    border-radius: ${({ theme }) => theme.other.borderRadius};
  }

  .ant-modal-header {
    border-radius: ${({ theme }) => `${theme.other.borderRadius} ${theme.other.borderRadius} 0 0`}
  }

  .ant-image-preview-mask {
    background-color: rgba(0, 0, 0, 0.65) !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: ${({ theme }) => theme.other.borderRadius}; !important;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
  }

  .ant-tabs-left {
    .ant-tabs-tab {
      padding: 8px 10px 8px 0 !important;
      margin: 7px 0 0 0 !important;
    }
  }

  .esri-view .esri-view-surface--inset-outline:focus::after {
    outline: none !important;
  }
  
  .ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
  .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    padding-left: 10px !important;
  }

  .dg.a {
    position: absolute;
    top: 115px;
    right: 0;
  }
`;
