import axios from 'axios';
import { API_BASE_URL } from '../../config';
import { UI_UNAUTHORIZED_PATH } from '../../constants/routes/ui';

const agent = axios.create({
  baseURL: API_BASE_URL,
});

agent.interceptors.request.use((config) => {
  if (process.browser) {
    const token = localStorage.getItem('token');
    config = { ...config, headers: { ...config.headers, Authorization: `Bearer ${token}` } };
  }
  return config;
});

agent.interceptors.response.use((res) => res, (err) => {
  if (err.response?.status === 401 && window.location.pathname !== UI_UNAUTHORIZED_PATH.login) {
    localStorage.removeItem('token');
    window.location = '/';
  }

  if (err.response?.status === 403 || err.response?.status === 404) {
    window.location = '/';
  }
  return Promise.reject(err);
});

export { agent };
