import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { Modal, Tooltip } from 'antd';
import lodashGet from 'lodash/get';
import styled, { keyframes } from 'styled-components';
import icon from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFavorite } from 'utils/model/projectProfileData';
import { swappProjectSelector } from 'store/swappProfile';
import { isSharedUrl } from 'utils/helpers/navigationHelpers';
import { useOverridableSetting } from 'store/userSettings';
import { updateProjectProfileAction } from 'store/swappProfile/actions/swappProfileActions';
import heartFill from '../../styles/static/icons/comon/heartFill';
import heartStroke from '../../styles/static/icons/comon/heartStroke';

const fadeIn = keyframes`
  from { transform: scale(0); }
  to { transform: scale(1); }
`;

const fadeOut = keyframes`
  from { transform: scale(1)  }
  to { transform: scale(0); }
`;

export const AddToFavorites = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primaryColor};
  color: ${({ theme }) => theme.colors.white};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 16px;
  overflow: hidden;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.22);
  
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`;

const StyledIcon = styled(icon)`
  position: absolute;
  top: 7px;
  left: 6px;
`;

const AddToFavoritesIcon = styled(StyledIcon)`
  animation: ${({ selected }) => (selected ? fadeIn : fadeOut)} .3s ease-in-out;
  transform: scale(${({ selected }) => (selected ? 1 : 0)});
`;

const { confirm } = Modal;

const FavoriteButton = (props) => {
  const { profileId, hidden } = props;
  const isShared = isSharedUrl();
  const isReadOnly = useOverridableSetting('readOnly', false);
  const swappProject = useSelector(swappProjectSelector);
  const dispatch = useDispatch();

  const profile = lodashGet(swappProject, 'projectProfiles', []).find((item) => item.id === profileId);
  const profileTimestamp = lodashGet(profile, 'result.generatedTimestamp');

  const parentProfile = lodashGet(swappProject, 'projectProfiles', []).find((item) => !item.parentProfileIndex);
  const parentTimestamp = lodashGet(parentProfile, 'result.generatedTimestamp');

  const initialValue = lodashGet(profile, 'profileData.isFavorite');
  const isLastUpdate = profileTimestamp === parentTimestamp;
  const [isFavorite, useIsFavorite] = useState(initialValue);

  useEffect(() => {
    toggleState(useIsFavorite, lodashGet(profile, 'profileData.isFavorite'));
  }, [profile]);

  const toggleCardFavorites = (value, id) => {
    dispatch(updateProjectProfileAction(id, toggleFavorite(value)));
  };

  const toggleState = (update, value) => {
    update(value);
  };

  const toggleIsFavorite = (e, update) => {
    e.stopPropagation();
    if (isShared || isReadOnly) {
      return;
    }

    if (isFavorite && isLastUpdate) {
      confirm({
        title: T.translate('FAVORITE_BUTTON_MODAL_TITLE'),
        content: T.translate('FAVORITE_BUTTON_MODAL_CONTENT'),
        okText: T.translate('FAVORITE_BUTTON_MODAL_OK_TEXT'),
        width: 500,
        onOk: () => {
          toggleState(update, !isFavorite);
          toggleCardFavorites(!isFavorite, profileId);
        },
      });
      return;
    }

    toggleState(update, !isFavorite);
    toggleCardFavorites(!isFavorite, profileId);
  };

  if (isShared || hidden) {
    return null;
  }

  return (
    <Tooltip title={T.translate(isFavorite ? 'FAVORITE_BUTTON_TOOLTIP_REMOVE' : 'FAVORITE_BUTTON_TOOLTIP_ADD')} mouseEnterDelay={0.5}>
      <AddToFavorites selected={isFavorite} onClick={(e) => toggleIsFavorite(e, useIsFavorite)}>
        <StyledIcon component={heartStroke} />
        <AddToFavoritesIcon component={heartFill} selected={isFavorite} />
      </AddToFavorites>
    </Tooltip>
  );
};

FavoriteButton.propTypes = {
  profileId: PropTypes.number,
  hidden: PropTypes.bool,
};

export default FavoriteButton;
