import { UI_AUTHORIZE_PATH } from './routes/ui';

const {
  studies, setup, TEST_FIT, PROJECT_SETTINGS,
  compare, FEASIBILITY_STUDY,
} = UI_AUTHORIZE_PATH;

export const STAGES_TABS = {
  [PROJECT_SETTINGS]: [
    { key: setup, value: 'NAVIGATION_EMPTY', rout: setup },
  ],
  [TEST_FIT]: [
    { key: studies, value: 'Studies', rout: studies },
    { key: compare, value: 'NAVIGATION_COMPARE', rout: compare },
  ],
  [`${TEST_FIT}-share`]: [
    { key: studies, value: 'Studies', rout: studies },
  ],
  [FEASIBILITY_STUDY]: [
    { key: studies, value: 'NAVIGATION_STUDIES', rout: studies },
  ],
  [`${FEASIBILITY_STUDY}-share`]: [
    { key: studies, value: 'NAVIGATION_STUDIES', rout: studies },
  ],
};
