import React from 'react';
import PropTypes from 'prop-types';
import { NotificationWrapper, StyledIcon, Title, SubTitle } from './Notification.Styles';

const Notification = (props) => {
  const { notification, hints, iconComponent, marginTop } = props;
  return (
    <NotificationWrapper marginTop={marginTop}>
      <StyledIcon>{iconComponent}</StyledIcon>
      <Title>{notification}</Title>
      <SubTitle>{hints}</SubTitle>
    </NotificationWrapper>
  );
};

Notification.propTypes = {
  notification: PropTypes.string,
  hints: PropTypes.string,
  iconComponent: PropTypes.object,
  marginTop: PropTypes.number,
};

export default Notification;
