import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';
import styled from 'styled-components';
import { selectedStorySelector, selectStory } from 'store/BuildingStoriesStore';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSelector, useDispatch } from 'react-redux';
import { getSwpProjectBuildings } from '@swapp/swappcommonjs/dist/utils/swpProjectModel';
import SwpBuildingStory from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpBuildingStory';
import treeIcon from 'styles/static/icons/feasibilityIcons/treeIcon';
import { useHotkeys } from 'react-hotkeys-hook';

const BuildingStoryUIWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StoryButtonWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray_08};
  border-radius: ${({ theme }) => `${theme.other.borderRadius} ${theme.other.borderRadius} 0 0`};
  width: 90px;
`;

const StoryButton = styled.div`
  margin: 0 auto;
  position: relative;
  background-color: ${({ theme, isSelected, isUpperFloorSelected }) => {
    if (isUpperFloorSelected) {
      return theme.colors.primaryColorDisabled;
    }
    return isSelected ? theme.colors.primaryColor : theme.colors.gray_02;
  }};
  color: ${({ theme, isSelected, isUpperFloorSelected }) => `${isSelected || isUpperFloorSelected ? theme.colors.white : theme.colors.textColor}`};
  height: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray_08};
  text-align: center;
  cursor: pointer;
  //margin-bottom: 5px;
  // border-radius: ${({ theme }) => theme.other.borderRadius};

  &:first-child {
    border-radius: ${({ theme }) => `${theme.other.borderRadius} ${theme.other.borderRadius} 0 0`};
  }
  
  &:last-child {
    border-bottom: 0;
  }

  ${({ isGroundFloor }) => !isGroundFloor} {
    &:after {
      position: absolute;
      content: ' ';
      bottom: -300px;
      border-top: 3px solid ${({ theme }) => theme.colors.gray_08};
      left: -200px;
      width: 400px;
      height: 300px;
      opacity: .5;
      background-color: ${({ theme }) => theme.colors.gray_05};
    }
  }
  
  &:hover {
    background-color: ${({ theme }) => `${theme.colors.primaryColorHover}`};
  }
`;

const StyledTreeIcon = styled(Icon)`
  position: absolute;
  bottom: 0;
  color: ${({ theme }) => theme.colors.gray_08};
  left: ${({ position = 75 }) => `${position}px`};
  z-index: -1;
`;

const BuildingStoryUI = ({ swpProject, setIsOrthographic }) => {
  const dispatch = useDispatch();
  const selectedStory = useSelector(selectedStorySelector);
  if (!swpProject) {
    return null;
  }

  const buildingsFloors = useMemo(() => {
    const buildings = getSwpProjectBuildings(swpProject);

    let maxAbove = 0;
    let maxBeneath = 0;
    buildings.forEach((building) => {
      let above = 0;
      let beneath = 0;
      building.childObjects.forEach((childObject) => {
        if (!(childObject instanceof SwpBuildingStory)) {
          return;
        }
        const zPosition = childObject.transform.position.z;
        if (zPosition >= 0) {
          above++;
        } else {
          beneath++;
        }

        maxAbove = Math.max(maxAbove, above);
        maxBeneath = Math.max(maxBeneath, beneath);
      });
    });

    return Array(maxAbove + maxBeneath).fill(1).map((floor, index) => {
      let displayName;
      const floorNum = index - maxBeneath;

      if (floorNum < 0) {
        displayName = floorNum >= -9 ? `-0${Math.abs(floorNum)}` : floorNum;
      } else {
        displayName = floorNum <= 9 ? `0${floorNum}` : floorNum;
      }

      return ({
        isGroundFloor: floorNum === 0,
        key: floorNum,
        displayName,
      });
    }).reverse();
  }, [swpProject]);

  useHotkeys('*', (e) => {
    if (selectedStory === null) {
      return;
    }

    if (e.key === 'ArrowUp' && selectedStory < buildingsFloors.length - 1) {
      onClick(selectedStory + 1);
    }
    if (e.key === 'ArrowDown' && selectedStory > 0) {
      onClick(selectedStory - 1);
    }
  }, undefined, [selectedStory, buildingsFloors]);

  const onClick = (e) => {
    if (setIsOrthographic) {
      setIsOrthographic(e !== selectedStory);
    }
    dispatch(selectStory(e === selectedStory ? null : e));
  };

  return (
    <BuildingStoryUIWrapper>
      <StoryButtonWrapper>
        {buildingsFloors.map((floor) => (
          <StoryButton
            key={floor.key}
            isSelected={selectedStory === floor.key}
            isUpperFloorSelected={selectedStory > floor.key}
            onClick={() => onClick(floor.key)}
            isGroundFloor={floor.isGroundFloor}
          >
            {floor.displayName}
            {floor.isGroundFloor && <StyledTreeIcon component={treeIcon} position={90} />}
          </StoryButton>
        ))}
      </StoryButtonWrapper>
    </BuildingStoryUIWrapper>
  );
};

BuildingStoryUI.propTypes = {
  swpProject: PropTypes.object,
  setIsOrthographic: PropTypes.func,
};

export default React.memo(BuildingStoryUI);
