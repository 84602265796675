import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import lodashGet from 'lodash/get';
import lodashKeys from 'lodash/keys';
import lodashMaxBy from 'lodash/maxBy';
import lodashSortBy from 'lodash/sortBy';
import lodashIncludes from 'lodash/includes';
import lodashFilter from 'lodash/filter';
import T from 'i18n-react';
import { feasibilityReportSelector } from 'store/swappProfile/selectors';
import { StyledReportTable } from 'styles/commonComponents.styles';
import { BUILDING_INFO_KEYS, UNIT_SORT_LIST } from 'constants/feasibilityConts';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { userSelector } from 'store/user';
import ReportTitleDropdown from '../ReportTitleDropdown';
import {
  ContentColumnWrapper,
  ContentRowWrapper,
  ContentTitle,
  VerticalTitleContentWrapper,
  TableBoxWrapper,
  StyledText,
  TableHeaderColorCell,
  ContentImage,
} from '../ReportPages.styles';

const ApartmentsMix = () => {
  const [currentBuildingIndex, setCurrentBuildingIndex] = useState(0);
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const reportData = useSelector((state) => feasibilityReportSelector(state, locationData.profileId));
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const user = useSelector(userSelector);
  const hideFinancialByProjectId = lodashIncludes(lodashGet(user, 'settings.flags.hideFinancialByProjectIdList'), Number(locationData.projectId));
  const apartmentsMixData = lodashGet(reportData, 'design.apartmentsMixData');
  const currentSelectedBuilding = lodashGet(apartmentsMixData, `buildings[${currentBuildingIndex}]`);

  const floorDivisionTableData = lodashGet(currentSelectedBuilding, 'floorDivisionTableData', []);
  const tableWithMostKeys = lodashMaxBy(floorDivisionTableData, (o) => Object.keys(o).length);
  const sortedTableKeys = lodashSortBy(lodashKeys(tableWithMostKeys), [(key) => UNIT_SORT_LIST[key]]);

  const updateCurrentBuildingIndex = (key, update) => {
    update(key);
  };

  const floorDivisionColumns = [
    {
      dataIndex: 'NAME',
      key: 'NAME',
      width: 120,
    },
  ];

  lodashFilter(sortedTableKeys, ((unit) => !lodashIncludes(['TOTAL', 'NAME'], unit))).forEach((unit) => {
    floorDivisionColumns.push({
      title: T.translate(unit),
      dataIndex: unit,
      key: unit,
      align: 'center',
      width: 70,
      render: (value) => numberWithComma(value, { fixed: isImperial ? 0 : 1 }),
    });
  });

  floorDivisionColumns.push({
    title: () => <TableHeaderColorCell>{T.translate('Total')}</TableHeaderColorCell>,
    dataIndex: 'TOTAL',
    key: 'TOTAL',
    align: 'center',
    width: 70,
    render: (value) => <StyledText size="small" bold highlight>{numberWithComma(value, { fixed: isImperial ? 0 : 1 })}</StyledText>,
  });

  const areaSummaryColumns = [
    {
      dataIndex: 'NAME',
      key: 'NAME',
    },
    {
      title: T.translate('GIA'),
      dataIndex: BUILDING_INFO_KEYS.GIA,
      key: BUILDING_INFO_KEYS.GIA,
      align: 'center',
      width: 120,
    },
    {
      title: T.translate('NIA'),
      dataIndex: BUILDING_INFO_KEYS.NIA,
      key: BUILDING_INFO_KEYS.NIA,
      align: 'center',
      width: 70,
    },
    {
      title: T.translate('NIA/GIA'),
      dataIndex: BUILDING_INFO_KEYS.NIA_TO_GIA,
      key: BUILDING_INFO_KEYS.NIA_TO_GIA,
      align: 'center',
      width: 70,
    },
  ];

  return (
    <VerticalTitleContentWrapper>
      <ContentTitle>{T.translate('Apartments Mix')}</ContentTitle>
      <ContentRowWrapper>
        <ContentColumnWrapper marginRight={25} width={220}>
          {/* ======== dropdown ======== */}
          <ReportTitleDropdown
            options={apartmentsMixData.buildings.map((item) => item.buildingName)}
            handleChange={(key) => updateCurrentBuildingIndex(key, setCurrentBuildingIndex)}
            selectedIndex={currentBuildingIndex}
          />

          {/* ======== current building image ======== */}
          {lodashGet(apartmentsMixData, `buildingsImages[${currentBuildingIndex}]`) && <ContentImage src={apartmentsMixData.buildingsImages[currentBuildingIndex].url} width={170} height={170} />}
        </ContentColumnWrapper>

        <ContentColumnWrapper marginLeft={15}>
          {/* ======== Floor Division Table ======== */}
          <TableBoxWrapper>
            <ContentTitle>{T.translate('Floor Division')}</ContentTitle>
            <StyledReportTable
              pagination={false}
              hasColor
              columns={floorDivisionColumns}
              dataSource={lodashGet(currentSelectedBuilding, 'floorDivisionTableData')}
            />
          </TableBoxWrapper>

          {/* ======== Occupancy Table ======== */}
          <TableBoxWrapper>
            <StyledReportTable
              pagination={false}
              hasColor
              showHeader={false}
              columns={floorDivisionColumns}
              dataSource={lodashGet(currentSelectedBuilding, 'occupancyTableData')}
            />
          </TableBoxWrapper>

          {/* ======== Area Summary Table ======== */}
          {!hideFinancialByProjectId && (
            <TableBoxWrapper width={470}>
              <ContentTitle>{T.translate('Area Summary')}</ContentTitle>
              <StyledReportTable
                pagination={false}
                hasColor
                columns={areaSummaryColumns}
                dataSource={lodashGet(currentSelectedBuilding, 'areaSummaryTableData')}
              />
            </TableBoxWrapper>
          )}
        </ContentColumnWrapper>
      </ContentRowWrapper>
    </VerticalTitleContentWrapper>
  );
};

export default React.memo(ApartmentsMix);
