import React from 'react';

// currentcolor
export default () => (
  <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M63.0445 37H65.1498M71.3036 76H76V57.5347M65.1498 37H76V57.5347M65.1498 37V45.1184M65.1498 51.8041V57.5347L76 57.5347M55.1093 37H53.6518M36 40.1837V37H53.6518M53.6518 42.0939V37M53.6518 49.2571V67.8816M53.6518 76H65.1498M53.6518 76H47.1741M53.6518 76L53.6518 67.8816M53.6518 67.8816H41.0202M42.3158 76H36L36 46.8694" stroke="currentcolor" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
    <circle cx="10.3136" cy="91.4598" r="7.5404" transform="rotate(90 10.3136 91.4598)" stroke="currentcolor" strokeWidth="2" />
    <circle cx="94.7781" cy="17.0442" r="6.37766" transform="rotate(90 94.7781 17.0442)" stroke="currentcolor" strokeWidth="2" />
    <rect x="20.6748" y="14.3364" width="17.2908" height="17.9024" transform="rotate(90 20.6748 14.3364)" stroke="currentcolor" strokeWidth="2" strokeLinejoin="round" />
    <rect x="106.654" y="84.5005" width="14.4994" height="14.7901" transform="rotate(90 106.654 84.5005)" stroke="currentcolor" strokeWidth="2" strokeLinejoin="round" />
    <path d="M11.3905 32.917L11.3905 47.8146C11.3905 50.5036 13.5704 52.6835 16.2595 52.6835V52.6835C18.9485 52.6835 21.1284 54.8634 21.1284 57.5525L21.1284 65.0314C21.1284 69.4497 17.4802 73.0314 13.0619 73.0314V73.0314C8.64365 73.0314 4.99543 76.6132 4.99543 81.0314L4.99543 85.2402" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20.8983 22.1311L31.3344 22.1311C34.2242 22.1311 36.5668 19.7886 36.5668 16.8988V16.8988C36.5668 14.0091 38.9093 11.6665 41.7991 11.6665L48.0488 11.6665C50.5372 11.6665 52.5544 13.6837 52.5544 16.1721V16.1721C52.5544 18.6605 54.5716 20.6777 57.06 20.6777L66.2166 20.6777L77.9893 20.6777C79.1131 20.6777 80.0241 19.7667 80.0241 18.6429V18.6429C80.0241 17.5191 80.9351 16.6081 82.0589 16.6081L88.3086 16.6081" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M97.3517 84.0051L97.3517 59.7391C97.3517 58.0534 98.7182 56.6869 100.404 56.6869V56.6869C102.09 56.6869 103.456 55.3204 103.456 53.6347L103.456 31.1727C103.456 30.0322 102.531 29.1076 101.391 29.1076V29.1076C100.25 29.1076 99.3258 28.183 99.3258 27.0425L99.3258 21.8579" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M91.9917 88.8433L82.4718 88.8433C80.5854 88.8433 79.0562 90.3725 79.0562 92.2588V92.2588C79.0562 94.1452 77.5271 95.6743 75.6407 95.6743L36.471 95.6743C35.2669 95.6743 34.2908 94.6983 34.2908 93.4942V93.4942C34.2908 92.2902 33.3148 91.3141 32.1107 91.3141L17.5339 91.3141" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
