import React from 'react';

// currentcolor
export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.4174 12.3824H8.16821C7.93513 12.3824 7.74634 12.5782 7.74634 12.8199C7.74634 13.0617 7.93513 13.2574 8.16821 13.2574H14.4174C14.6505 13.2574 14.8392 13.0617 14.8392 12.8199C14.8392 12.5782 14.6505 12.3824 14.4174 12.3824Z" fill="currentcolor" />
    <path d="M14.4174 14.5694H8.16821C7.93513 14.5694 7.74634 14.7652 7.74634 15.0069C7.74634 15.2486 7.93513 15.4444 8.16821 15.4444H14.4174C14.6505 15.4444 14.8392 15.2486 14.8392 15.0069C14.8392 14.7652 14.6505 14.5694 14.4174 14.5694Z" fill="currentcolor" />
    <path d="M8.16843 8.88245H11.2545H11.2535C11.4866 8.88245 11.6754 8.68666 11.6754 8.44495C11.6754 8.20323 11.4866 8.00745 11.2535 8.00745H8.16846C7.93537 8.00745 7.74658 8.20323 7.74658 8.44495C7.74658 8.68666 7.93537 8.88245 8.16846 8.88245H8.16843Z" fill="currentcolor" />
    <path d="M14.4174 10.1944H8.16821C7.93513 10.1944 7.74634 10.3902 7.74634 10.6319C7.74634 10.8736 7.93513 11.0694 8.16821 11.0694H14.4174C14.6505 11.0694 14.8392 10.8736 14.8392 10.6319C14.8392 10.3902 14.6505 10.1944 14.4174 10.1944Z" fill="currentcolor" />
    <path d="M6.65287 12.3824H6.05908C5.826 12.3824 5.63721 12.5782 5.63721 12.8199C5.63721 13.0617 5.826 13.2574 6.05908 13.2574H6.65287C6.8849 13.2574 7.07474 13.0617 7.07474 12.8199C7.07474 12.5782 6.8849 12.3824 6.65287 12.3824Z" fill="currentcolor" />
    <path d="M6.65287 14.5694H6.05908C5.826 14.5694 5.63721 14.7652 5.63721 15.0069C5.63721 15.2486 5.826 15.4444 6.05908 15.4444H6.65287C6.8849 15.4444 7.07474 15.2486 7.07474 15.0069C7.07474 14.7652 6.8849 14.5694 6.65287 14.5694Z" fill="currentcolor" />
    <path d="M6.65287 8.00745H6.05908C5.826 8.00745 5.63721 8.20323 5.63721 8.44495C5.63721 8.68666 5.826 8.88245 6.05908 8.88245H6.65287C6.8849 8.88245 7.07474 8.68666 7.07474 8.44495C7.07474 8.20323 6.8849 8.00745 6.65287 8.00745Z" fill="currentcolor" />
    <path d="M6.65287 10.1944H6.05908C5.826 10.1944 5.63721 10.3902 5.63721 10.6319C5.63721 10.8736 5.826 11.0694 6.05908 11.0694H6.65287C6.8849 11.0694 7.07474 10.8736 7.07474 10.6319C7.07474 10.3902 6.8849 10.1944 6.65287 10.1944Z" fill="currentcolor" />
    <path d="M19.2268 4.56844L14.7065 -0.115957C14.6274 -0.199083 14.5198 -0.248301 14.4059 -0.250488H2.07875C1.83934 -0.246113 1.64526 -0.0481434 1.63684 0.200144V23.7988C1.64528 24.0471 1.83934 24.2451 2.07875 24.2495H18.9254C19.1627 24.244 19.3525 24.0438 19.3557 23.7988V21.2832C20.807 21.2055 22.1178 20.3579 22.8225 19.0399C23.5281 17.722 23.5281 16.1206 22.8225 14.8027C22.118 13.4847 20.807 12.637 19.3557 12.5594V4.87788C19.3536 4.76085 19.3083 4.64928 19.2271 4.56834L19.2268 4.56844ZM14.8204 1.25548L17.9138 4.45252H14.8204V1.25548ZM2.48059 23.3744V0.624363H13.9766V4.8868C13.9798 5.13071 14.1707 5.32649 14.4059 5.32758H18.5118V12.602C17.1713 12.812 16.0101 13.6782 15.3919 14.9284C14.7739 16.1797 14.7739 17.6628 15.3919 18.9139C16.01 20.1641 17.1712 21.0303 18.5118 21.2403V23.3742L2.48059 23.3744ZM22.4997 16.9212C22.4997 18.3168 21.6971 19.579 20.461 20.1303C19.2239 20.6815 17.7895 20.4157 16.8128 19.4543C15.8361 18.4929 15.505 17.0218 15.9711 15.7116C16.4373 14.4024 17.6112 13.5055 18.956 13.4333H18.9613C19.0182 13.43 19.0752 13.4289 19.1321 13.4289H19.1311C20.9905 13.4311 22.4966 14.993 22.4999 16.9214L22.4997 16.9212Z" fill="currentcolor" />
    <path d="M17.8979 16.2665C17.8304 16.1736 17.7302 16.1112 17.6184 16.0937C17.5077 16.0773 17.3948 16.1069 17.3041 16.1769C17.2145 16.2479 17.1554 16.3519 17.1406 16.4678C17.1248 16.5837 17.1544 16.7008 17.2229 16.7927L18.1268 18.0384V18.0395C18.266 18.2309 18.5286 18.2703 18.7143 18.1281L20.9523 16.4175C21.1401 16.2742 21.1801 15.9997 21.0409 15.8061C20.9028 15.6114 20.6391 15.5698 20.4513 15.7131L18.5508 17.1667L17.8979 16.2665Z" fill="currentcolor" />
  </svg>
);
