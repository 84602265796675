import React from 'react';

export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M31.8611 30.8058C31.5952 31.0717 31.5952 31.4689 31.8611 31.7348L32.4361 32.3097H23.5959C23.2419 32.3097 22.9329 32.6188 22.9329 32.9728C22.9329 33.3267 23.2419 33.6358 23.5959 33.6358H32.391L31.8161 34.2107C31.5502 34.4766 31.5502 34.8738 31.8161 35.1397C31.949 35.2726 32.1251 35.3158 32.303 35.3158C32.4791 35.3158 32.6569 35.2709 32.7899 35.1397L34.9551 32.9745L32.7899 30.8094C32.524 30.5417 32.082 30.5417 31.861 30.8059L31.8611 30.8058Z" fill="currentcolor" />
    <path d="M26.7782 23.8667H32.3913L31.8612 24.4417C31.5953 24.7076 31.5953 25.1047 31.8612 25.3706C31.9942 25.5036 32.1703 25.5467 32.3481 25.5467C32.5242 25.5467 32.7021 25.5018 32.8351 25.3706L35.0002 23.2055L32.8351 21.0403C32.5692 20.7744 32.172 20.7744 31.9061 21.0403C31.6402 21.3062 31.6402 21.7033 31.9061 21.9692L32.4811 22.5442L26.7783 22.5408C26.4243 22.5408 26.1152 22.8498 26.1152 23.2038C26.1152 23.5577 26.4243 23.8668 26.7783 23.8668L26.7782 23.8667Z" fill="currentcolor" />
    <path d="M23.905 19.7127L24.48 20.2876C24.613 20.4206 24.7891 20.4637 24.9669 20.4637C25.143 20.4637 25.3209 20.4189 25.4539 20.2876C25.7197 20.0217 25.7197 19.6246 25.4539 19.3587L23.2887 17.1935L21.1235 19.3587C20.8577 19.6246 20.8577 20.0217 21.1235 20.2876C21.3894 20.5535 21.7866 20.5535 22.0525 20.2876L22.6275 19.7127V25.9008C22.6275 29.4369 19.7544 32.2649 16.2634 32.2649H5.87078C5.51683 32.2649 5.20776 32.574 5.20776 32.9279C5.20776 33.2819 5.51683 33.5909 5.87078 33.5909H16.1701C20.4141 33.5909 23.8602 30.1429 23.8602 25.9008V19.7127H23.9051H23.905Z" fill="currentcolor" />
    <path d="M14.3139 23.8667H19.5729C19.9269 23.8667 20.2359 23.5576 20.2359 23.2037C20.2359 22.8497 19.9269 22.5407 19.5729 22.5407H14.3139C9.76071 22.5407 6.04869 18.8285 6.04869 14.2755C6.04869 9.72249 9.76088 6.0103 14.3139 6.0103C18.8669 6.0103 22.5791 9.72249 22.5791 14.2755C22.5791 14.6294 22.8881 14.9385 23.2421 14.9385C23.596 14.9385 23.9051 14.6294 23.9051 14.2755C23.9051 8.97134 19.6179 4.68427 14.3139 4.68427C9.00982 4.68427 4.72266 8.97143 4.72266 14.2755C4.72266 19.5795 9.05473 23.8667 14.3139 23.8667Z" fill="currentcolor" />
  </svg>
);
