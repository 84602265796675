import * as actionTypes from './actionTypes';
import { actionCreator } from '../../../utils/helpers';

export const signUpRequestActionCreator = actionCreator(actionTypes.SIGN_UP_REQUEST);
export const signUpSuccessActionCreator = actionCreator(actionTypes.SIGN_UP_SUCCESS);
export const signUpErrorActionCreator = actionCreator(actionTypes.SIGN_UP_ERROR);

export const loginRequestActionCreator = actionCreator(actionTypes.LOGIN_REQUEST);
export const loginSuccessActionCreator = actionCreator(actionTypes.LOGIN_SUCCESS);
export const loginErrorActionCreator = actionCreator(actionTypes.LOGIN_ERROR);

export const logoutActionCreator = actionCreator(actionTypes.LOGOUT);

export const resetPassRequestActionCreator = actionCreator(actionTypes.RESET_PASS_REQUEST);
export const resetPassSuccessActionCreator = actionCreator(actionTypes.RESET_PASS_SUCCESS);
export const resetPassErrorActionCreator = actionCreator(actionTypes.RESET_PASS_ERROR);

export const confirmPassRequestActionCreator = actionCreator(actionTypes.CONFIRM_RESET_PASS_REQUEST);
export const confirmPassSuccessActionCreator = actionCreator(actionTypes.CONFIRM_RESET_PASS_SUCCESS);
export const confirmPassErrorActionCreator = actionCreator(actionTypes.CONFIRM_RESET_PASS_ERROR);
export const authCheckedActionCreator = actionCreator(actionTypes.SET_AUTH_CHECKED);
