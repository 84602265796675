import React from 'react';

// currentcolor
export default () => (
  <svg width="60" height="60" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M36.8108 20.2377L13.0163 9.88758L16.0045 20.2377H36.8108ZM16.0044 22.3709L13.0162 32.6135L36.8107 22.3709H16.0044ZM11.8438 7.11275L42.3605 20.3439C43.2138 20.6647 43.2138 21.8371 42.3605 22.2637L11.8438 35.3873C11.0963 35.814 10.1372 35.0665 10.3505 34.2132L14.0845 21.3033L10.3505 8.39342C10.1372 7.54013 10.9905 6.68684 11.8438 7.11348V7.11275Z" fill="currentcolor" />
    <path d="M7.04146 28.4528C7.57561 28.4528 8.10808 28.987 8.10808 29.5194C8.10808 30.1594 7.57393 30.5861 7.04146 30.5861H1.06661C0.426646 30.5861 0 30.1594 0 29.5194C0 28.9853 0.426642 28.4528 1.06661 28.4528H7.04146Z" fill="currentcolor" />
    <path d="M8.64131 20.2377C9.28128 20.2377 9.70792 20.6643 9.70792 21.3043C9.70792 21.8384 9.28128 22.3709 8.64131 22.3709H2.77267C2.1327 22.3709 1.70605 21.8368 1.70605 21.3043C1.70605 20.6643 2.1327 20.2377 2.77267 20.2377H8.64131Z" fill="currentcolor" />
    <path d="M7.04146 11.9146C7.57561 11.9146 8.10808 12.3412 8.10808 12.9812C8.10808 13.6212 7.57393 14.0478 7.04146 14.0478H1.06661C0.426646 14.0478 0 13.6212 0 12.9812C0 12.3412 0.426642 11.9146 1.06661 11.9146H7.04146Z" fill="currentcolor" />
  </svg>
);
