import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import { useThree } from 'react-three-fiber';
import { polygonIterEdges, transformPolygonToBox } from '../../algorithms/algorithmHelpers';
import { uuidv4 } from '../../helpers/uuidv4';
import ExtrudeMesh from './ExtrudeMesh';
import { decalProps } from '../helpers/ThreeHelpers';

export const isParkingInfoDtoSupported = (parkingInfoDto) => {
  const parkings = lodashGet(parkingInfoDto, 'parkings');
  if (parkings !== undefined) {
    return true;
  }
  return false;
};

// Build all lines required to render parking for a lot
export const ParkingMesh = (props) => {
  const { parkingInfoDto } = props;
  const extrudedTypes = ['corners_area', 'bay_area', 'core_sidewalk'];
  const { gl } = useThree();

  if (!isParkingInfoDtoSupported(parkingInfoDto)) {
    return null;
  }
  const parkings = lodashGet(parkingInfoDto, 'parkings');

  return useMemo(() => {
    if (lodashIsEmpty(parkings)) {
      return null;
    }

    // lineSegmentsPointList is a 1D list of points: [(p1),(p2),(p3),(p4)] means two segments p1-p2 and p3-p4
    const baseHeight = 0.2;
    const raisedHeight = 0.3;
    const raisedParkingTypes = ['bay_area_tree'];

    let lineSegmentsPointList = [];
    for (let i = 0; i < parkings.length; i++) {
      const height = raisedParkingTypes.includes(parkings[i].parking_type) ? raisedHeight : baseHeight;
      lineSegmentsPointList = lineSegmentsPointList.concat(ParkingToSegments(parkings[i], height));
    }

    const surfaceVertices = lineSegmentsPointList.map((v) => new THREE.Vector3(...v));
    const extrudedParkings = parkings.filter((parkingUnit) => extrudedTypes.includes(parkingUnit.parking_type));

    // Note: our 3D stack somewhere uses key as a refresh identifier. If we do not change from generation to generation,
    // geometry might not update
    return (
      <group key="surface_parking">
        {extrudedParkings.map((parkingUnit, index) => (
          <group key={index}>
            <ExtrudeMesh key={`surface_extrude_${index}`} receiveShadow envelope={[...parkingUnit.parking_polygon.boundary, parkingUnit.parking_polygon.boundary[0]]} extrudeDepth={0.2} color="#eeeeee" />;
          </group>
        ))}
        {lineSegmentsPointList && (
        <lineSegments key={`parkingLinesGeom${uuidv4()}`} {...decalProps(gl)} name="surface-parking">
          <geometry attach="geometry" vertices={surfaceVertices} />
          <lineBasicMaterial attach="material" color="black" depthWrite={false} />
        </lineSegments>
        )}
      </group>
    );
  }, [parkings]);
};

export const ParkingToSegments = (parking, height) => {
  let parkingPoints = parking.parking_polygon.boundary;
  if (parkingPoints && parkingPoints[0] && parkingPoints[0].length === 3) {
    parkingPoints = parkingPoints.map((p) => [p[0], p[1], p[2] + height]);
  } else {
    parkingPoints = parkingPoints.map((p) => [p[0], p[1], height]);
  }

  const out = [];
  if (parking.parking_type === 'entrance') {
    const rightArrow = transformPolygonToBox(getRightLaneArrow(), parkingPoints);
    polygonIterEdges(rightArrow, (p1, p2) => {
      out.push(p1);
      out.push(p2);
    });

    const leftArrow = transformPolygonToBox(getLeftLaneArrow(), parkingPoints);
    polygonIterEdges(leftArrow, (p1, p2) => {
      out.push(p1);
      out.push(p2);
    });

    return out;
  }

  if (parking.parking_type === 'bay_area_tree') {
    const rightArrow = transformPolygonToBox(getTree(), parkingPoints);
    polygonIterEdges(rightArrow, (p1, p2) => {
      out.push(p1);
      out.push(p2);
    });
    return out;
  }

  polygonIterEdges(parkingPoints, (p1, p2) => {
    out.push(p1);
    out.push(p2);
  });

  return out;
};

ParkingMesh.propTypes = {
  parkingInfoDto: PropTypes.object,
};

export const getRightLaneArrow = () => {
  const rightArrow = [[-0.5, 0.375],
    [0.25, 0.375],
    [0.25, 0.5],
    [0.5, 0.25],
    [0.25, 0.0],
    [0.25, 0.125],
    [-0.5, 0.125],
    [-0.5, 0.375]];

  return rightArrow;
};

export const getLeftLaneArrow = () => {
  const leftArrow = [[0.5, -0.375],
    [-0.25, -0.375],
    [-0.25, -0.5],
    [-0.5, -0.25],
    [-0.25, -0.0],
    [-0.25, -0.125],
    [0.5, -0.125],
    [0.5, -0.375]];

  return leftArrow;
};

export const getTree = () => {
  const tree = [[-0.1, -7.874863750390232e-18],
    [-0.09999999999999999, 4.683753385137379e-17],
    [-0.1, 3.469446951953614e-17],
    [-0.12424871130596425, 0.15920508075688777],
    [-0.3266025403784438, 0.36569219381653056],
    [-0.12232050807568873, 0.17186533479473215],
    [-0.2499999999999999, 0.43301270189221935],
    [-0.15499999999999994, 0.268467875173176],
    [-0.06911542731880094, 0.4797114317029974],
    [-0.14999999999999994, 0.2598076211353316],
    [-0.07999999999999996, 0.13856406460551018],
    [-0.0017949192431121475, 0.4031088913245535],
    [-0.07499999999999997, 0.1299038105676658],
    [-0.04999999999999998, 0.08660254037844388],
    [-0.05011020609192037, 0.08641165782793415],
    [-0.04979173779629017, 0.08660254037844389],
    [-0.04999999999999998, 0.08660254037844389],
    [0.07575128869403575, 0.18720508075688774],
    [0.15339745962155618, 0.4656921938165305],
    [0.08767949192431125, 0.1918653347947321],
    [0.25000000000000006, 0.4330127018922193],
    [0.15500000000000003, 0.26846787517317594],
    [0.380884572681199, 0.29971143170299736],
    [0.15000000000000002, 0.25980762113533157],
    [0.08000000000000002, 0.13856406460551018],
    [0.3482050807568877, 0.20310889132455345],
    [0.07500000000000001, 0.12990381056766578],
    [0.05000000000000002, 0.08660254037844387],
    [0.2, 0.028],
    [0.48, 0.1],
    [0.21, 0.02],
    [0.5, 0.0],
    [0.31, 0.0],
    [0.45, -0.18],
    [0.3, 0.0],
    [0.16, 0.0],
    [0.35, -0.2],
    [0.15, 0.0],
    [0.1, 0.0],
    [0.1, -6.938893903907228e-18],
    [0.12424871130596432, -0.15920508075688772],
    [0.32660254037844394, -0.3656921938165305],
    [0.1223205080756888, -0.1718653347947321],
    [0.25000000000000006, -0.4330127018922193],
    [0.15500000000000003, -0.26846787517317594],
    [0.06911542731880113, -0.4797114317029974],
    [0.15000000000000002, -0.25980762113533157],
    [0.08000000000000002, -0.13856406460551018],
    [0.0017949192431122862, -0.4031088913245535],
    [0.07500000000000001, -0.12990381056766578],
    [0.05000000000000002, -0.08660254037844387],
    [0.05011020609192042, -0.08641165782793411],
    [0.04979173779629011, -0.08660254037844392],
    [0.049999999999999954, -0.08660254037844392],
    [-0.07575128869403581, -0.18720508075688772],
    [-0.15339745962155638, -0.4656921938165305],
    [-0.08767949192431132, -0.19186533479473208],
    [-0.2500000000000002, -0.43301270189221924],
    [-0.15500000000000014, -0.26846787517317594],
    [-0.38088457268119913, -0.29971143170299724],
    [-0.15000000000000013, -0.2598076211353315],
    [-0.08000000000000007, -0.13856406460551016],
    [-0.34820508075688783, -0.20310889132455334],
    [-0.07500000000000007, -0.12990381056766576],
    [-0.050000000000000044, -0.08660254037844385],
    [-0.2, -0.028],
    [-0.48, -0.1],
    [-0.21, -0.02],
    [-0.5, 0.0],
    [-0.31, 0.0],
    [-0.45, 0.18],
    [-0.3, 0.0],
    [-0.16, 0.0],
    [-0.35, 0.2],
    [-0.15, 0.0],
    [-0.1, 0.0],
    [-0.1, -7.874863750390232e-18]];
  return tree;
};
