import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ImageWithDescription from './ImageWithDescription';

export const RadioImagesWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
`;

const RadioImages = forwardRef((props, ref) => {
  const { data, selected, onChange, disabled, size, spaced, bordered } = props;

  const handleOnSelect = (type) => {
    onChange(type);
  };

  return (
    <RadioImagesWrapper spaced={spaced} ref={ref}>
      {data.map((item) => (
        <ImageWithDescription
          key={item.key}
          UID={item.key}
          onChange={(key) => handleOnSelect(key)}
          size={size}
          bordered={bordered}
          disabled={disabled}
          image={item.image}
          value={item.value}
          selected={selected === item.key}
          description={item.description}
        />
      ))}
    </RadioImagesWrapper>
  );
});

RadioImages.propTypes = {
  data: PropTypes.array,
  selected: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.number,
  spaced: PropTypes.bool,
  bordered: PropTypes.bool,
};

export default RadioImages;
