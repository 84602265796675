import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import T from 'i18n-react';
import { Tabs } from 'antd';
import { parseLocationUrl, isSharedUrl } from 'utils/helpers/navigationHelpers';
import PropTypes from 'prop-types';
import { StyledTabs } from 'components/common/ResultSplitView.srtyles';
import {
  ContentColumnWrapper,
  ContentRowWrapper,
  ContentTitle,
  VerticalTitleContentWrapper,
} from '../report/ReportPages.styles';
import DevelopmentAppraisalSummeryBar from './DevelopmentAppraisalSummeryBar';

import DevelopmentAppraisalBaseDataTable from './DevelopmentAppraisalBaseDataTable';
import DevelopmentAppraisalIncomeTable from './DevelopmentAppraisalIncomeTable';
import DevelopmentExpenditureTable from './DevelopmentExpenditureTable';

const { TabPane } = Tabs;

const DevelopmentAppraisal = (props) => {
  const { data, form, formData } = props;
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const disabled = isSharedUrl();

  const options = useMemo(() => (
    { data,
      formData,
      formInstance: form,
      currentSelectedProfileId: locationData.profileId,
      disabled,
    }), [formData, locationData.profileId]);

  return (
    <VerticalTitleContentWrapper>
      <ContentTitle>{T.translate('Development Appraisal')}</ContentTitle>
      <ContentRowWrapper>
        <ContentColumnWrapper marginRight={15} width={380}>
          <DevelopmentAppraisalSummeryBar {...options} />
        </ContentColumnWrapper>
        <ContentColumnWrapper width={870}>
          <StyledTabs animated={{ inkBar: true, tabPane: true }}>
            <TabPane tab={T.translate('Base Data')} key="BASE_DATA" forceRender>
              <DevelopmentAppraisalBaseDataTable {...options} />
            </TabPane>
            <TabPane tab={T.translate('Income')} key="INCOME" forceRender>
              <DevelopmentAppraisalIncomeTable {...options} />
            </TabPane>
            <TabPane tab={T.translate('Expenditure')} key="EXPENDITURE" forceRender>
              <DevelopmentExpenditureTable {...options} />
            </TabPane>
          </StyledTabs>
        </ContentColumnWrapper>
      </ContentRowWrapper>
    </VerticalTitleContentWrapper>
  );
};

DevelopmentAppraisal.propTypes = {
  data: PropTypes.object,
  form: PropTypes.object,
  formData: PropTypes.object,
};

export default React.memo(DevelopmentAppraisal);
