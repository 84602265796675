import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reducers from './store/rootReducer';
import { agent } from './utils/helpers';

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false, // Performance issues in dev. In prod its always disabled.
    thunk: {
      extraArgument: agent,
    },
  }),
});

// const objects = data.objects.concat(data2.objects);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
