import { AREA_TARGET_FORM } from '../../constants/fieldNames';
import { uuidv4 } from '../helpers/uuidv4';

export const setProjectProfileData = (formData, siteId) => ({
  profileDataUpdates: JSON.stringify([
    {
      subPath: 'form',
      data: formData,
    },
    {
      subPath: AREA_TARGET_FORM.TIME_STAMP,
      data: new Date(),
    },
    {
      subPath: 'siteId',
      data: siteId,
    },
  ]),
});

export const setMarkupsData = (data) => ({
  markupUpdates: JSON.stringify([
    {
      subPath: uuidv4(),
      data,
    },
  ]),
});

export const setNewMarkupsData = (data) => ({
  markupUpdates: JSON.stringify([
    {
      subPath: 'markups',
      data,
    },
  ]),
});

export const toggleFavorite = (data) => ({
  profileDataUpdates: JSON.stringify([
    {
      subPath: 'isFavorite',
      data,
    },
  ]),
});

export const clearError = () => ({
  profileDataUpdates: JSON.stringify([
    {
      subPath: 'errorData',
      data: {},
    },
  ]),
});

export const setReportData = (data) => ({
  profileDataUpdates: JSON.stringify([
    {
      subPath: 'reportForm',
      data,
    },
  ]),
});

export const setFeasibilityResultData = (data) => ({
  profileDataUpdates: JSON.stringify([
    {
      subPath: 'feasibilityResultForm',
      data,
    },
  ]),
});

export const setFeasibilityEnergyData = (data) => ({
  profileDataUpdates: JSON.stringify([
    {
      subPath: 'feasibilityEnergyForm',
      data,
    },
  ]),
});

export const setFeasibilityParkingData = (data) => ({
  profileDataUpdates: JSON.stringify([
    {
      subPath: 'feasibilityParkingForm',
      data,
    },
  ]),
});

export const setTestFitResultData = (data) => ({
  profileDataUpdates: JSON.stringify([
    {
      subPath: 'testFitResultForm',
      data,
    },
  ]),
});

export const setFeasibilityParkingFormData = (data) => ({
  profileDataUpdates: JSON.stringify([
    {
      subPath: 'feasibilityParkingForm',
      data,
    },
  ]),
});

export const setProjectSiteData = (sitePolygon, area, blockLotData) => ({
  profileDataUpdates: JSON.stringify([
    {
      subPath: 'siteData',
      data: {
        sitePolygon,
        area,
        blockLotData,
      },
    },
  ]),
});

export const setHbEnergyFormData = (data) => ({
  profileDataUpdates: JSON.stringify([
    {
      subPath: 'hbEnergyAnalysisDataForm',
      data,
    },
  ]),
});
