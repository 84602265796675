import styled from 'styled-components';

export const AuthorizedWrapper = styled.div`
  overflow-x: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const AppWrapper = styled.div`
  overflow: hidden;
  display: flex;
  width: 100%;
  height: calc(100vh -  ${({ theme, headless }) => (headless ? 0 : `${theme.other.headerHeight}px`)});
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
`;
