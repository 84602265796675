import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIncludes from 'lodash/includes';
import lodashGet from 'lodash/get';
import { testFitModel } from 'utils/model/testFitModel';
import {
  currentThemeSelector,
  getUnitSystemByProjectIdSelector,
  useOverridableSetting,
} from 'store/userSettings';
import { userSelector } from 'store/user';
import { CURRENT_MULTI_BOUNDARY_FILE_VERSION } from 'constants/testFitConsts';
import { threeJsGlobals } from 'store/threeJsGlobals';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import TestFitViewerContainer from '../../../utils/swappViewer/testFit/TestFitViewerContainer';
import CompareItem from './CompareItem';
import { loadingSelector, swappProjectSelector } from '../../../store/swappProfile/selectors';
import LoadingSpinner from '../../common/LoadingSpinner';
import { PROFILE_STATUS } from '../../../constants';
import LayoutsMissing from './LayoutsMissing';
import { CompareItemContainer } from './CompareItem.styles';
import ForgeViewer from '../../../utils/forgeViewer/ForgeViewer';

const CompareContainer = () => {
  const [profilesList, setProfilesList] = useState([]);
  const [selectedProfilesList, setSelectedProfilesList] = useState([null, null]);
  const forgeA = useRef();
  const forgeB = useRef();
  const locationData = parseLocationUrl(window.location);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const testFitStandard = useOverridableSetting('testFitStandard', 'DEFAULT');
  const currentTheme = useSelector(currentThemeSelector);
  const user = useSelector(userSelector);
  const swappProject = useSelector(swappProjectSelector);
  const isLoading = useSelector(loadingSelector);

  useEffect(() => {
    if (swappProject) {
      updateSwappProfileList();
    }
  }, [swappProject, isImperial]);

  useEffect(() => {
    if (!lodashIsEmpty(profilesList) && selectedProfilesList[0] === null) {
      updateSelectedProfilesList(0, profilesList[0].id, setSelectedProfilesList);
    }
  }, [profilesList]);

  const updateSwappProfileList = () => {
    const filteredProfiles = [];
    swappProject.projectProfiles.filter((profile) => profile.parentProfileId).reverse().forEach((profile) => {
      if (profile.status === PROFILE_STATUS.READY || profile.status === PROFILE_STATUS.APPROVED) {
        filteredProfiles.push({ id: profile.id, projectId: locationData.projectId, name: profile.name, result: testFitModel(profile.result, isImperial, false, currentTheme, testFitStandard) });
      }
    });

    setProfilesList(filteredProfiles);
  };

  const updateSelectedProfilesList = (index, id) => {
    const currentSelectedList = [...selectedProfilesList];
    currentSelectedList[index] = id;
    setSelectedProfilesList(currentSelectedList);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (lodashIsEmpty(profilesList) || profilesList.length <= 1) {
    return <LayoutsMissing />;
  }

  const syncForgeViewerCameras = (sourceViewer, cameraCord) => {
    if (!forgeA.current || !forgeB.current || !cameraCord) {
      return;
    }

    const viewerMap = { A: forgeA, B: forgeB };
    viewerMap[sourceViewer === 'A' ? 'B' : 'A'].current.setCameraPosition(cameraCord);
  };

  const syncSwappViewerCameras = (activeCameraObject, viewerID) => {
    const passiveViewerId = viewerID === 'A' ? 'B' : 'A';
    const activeCamera = activeCameraObject.target.object;
    const passiveObject = threeJsGlobals.getCameraAndControllerById(passiveViewerId);
    if (!passiveObject) {
      return;
    }
    const { controller: passiveController, camera: passiveCamera, invalidate } = passiveObject;

    if (!passiveController || !passiveCamera || !passiveCamera.position || !invalidate || !activeCamera) {
      return;
    }

    passiveController.target.x = activeCameraObject.target.target.x;
    passiveController.target.y = activeCameraObject.target.target.y;

    passiveCamera.zoom = activeCamera.zoom;
    passiveCamera.position.x = activeCamera.position.x;
    passiveCamera.position.y = activeCamera.position.y;

    passiveCamera.updateProjectionMatrix();
    passiveCamera.updateMatrix();
    invalidate(); // making sure to render the other viewer
  };

  const isProfileNewTestFit = (profile) => profile?.result?.multiBoundaryFile?.version >= 7 || (lodashIncludes(lodashGet(user, 'settings.flags.hasNewTestFitProjectIds'), Number(locationData.projectId)) && profile.result && profile.result.multiBoundaryFile && profile.result.multiBoundaryFile.version >= CURRENT_MULTI_BOUNDARY_FILE_VERSION);

  const compareItemOptions = {
    selectedProfilesList,
    profiles: profilesList,
    handleClick: updateSelectedProfilesList,
  };

  const forageViewerOptions = {
    bordered: true,
    hideUi: true,
    isImperial,
    height: 400,
  };

  const viewerA = (profile) => (isProfileNewTestFit(profile)
    ? (
      <TestFitViewerContainer
        viewerId="A"
        onCameraChange={syncSwappViewerCameras}
        plan={profile.result.multiBoundaryFile}
        profileId={profile.id}
      />
    )
    : (
      <ForgeViewer
        {...forageViewerOptions}
        ref={forgeA}
        onCameraChange={(cord) => syncForgeViewerCameras('A', cord)}
        projectId={profile.projectId}
        urn={profile.result.forgeURN}
      />
    )
  );

  const viewerB = (profile) => (isProfileNewTestFit(profile)
    ? (
      <TestFitViewerContainer
        viewerId="B"
        onCameraChange={syncSwappViewerCameras}
        plan={profile.result.multiBoundaryFile}
        profileId={profile.id}
      />
    )
    : (
      <ForgeViewer
        {...forageViewerOptions}
        ref={forgeB}
        onCameraChange={(cord) => syncForgeViewerCameras('B', cord)}
        projectId={profile.projectId}
        urn={profile.result.forgeURN}
      />
    )
  );

  return (
    <CompareItemContainer>
      <CompareItem {...compareItemOptions} index={0} viewer={viewerA} />
      {/* {selectedProfilesList[1] && ( */}
      {/*  <SyncButtonWrapper width={80} isSyncOn={isSyncOn} onClick={() => setIsSyncOn(!isSyncOn)}> */}
      {/*    <SwapOutlined /> */}
      {/*  </SyncButtonWrapper> */}
      {/* )} */}
      <CompareItem {...compareItemOptions} index={1} viewer={viewerB} />
    </CompareItemContainer>
  );
};

export default React.memo(CompareContainer);
