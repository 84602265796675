import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { sqmToSqf } from 'utils/helpers/unitsHelpers';

const FeasibilityLotInfoFormWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  // background-color: ${({ theme }) => theme.colors.gray_04};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  height: fit-content;
  padding: 18px;
  padding-bottom: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.11);
`;

const Cell = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Cell2 = styled.div`
  display: flex;
  margin-bottom: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
`;

const Label = styled.div`
  color: ${({ theme }) => theme.colors.textColor};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.large};
  margin-right: 10px;
  margin-top: 0;
  text-transform: uppercase;
  line-height: ${({ theme }) => theme.font.size.large};
  white-space:nowrap;
`;

const Value = styled.div`
  color: ${({ theme }) => theme.colors.textColor};
  line-height: ${({ theme }) => theme.font.size.large};
  width: fit-content;
  white-space:nowrap;
`;

const FeasibilityLotInfoForm = (props) => {
  const { data, lotArea, blockLotData } = props;
  const match = useRouteMatch();
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));
  const area = lotArea || data?.area || '';

  return (
    <FeasibilityLotInfoFormWrapper>
      <Cell>
        <Label>
          {T.translate('FEASIBILITY_LOT_INFO_FORM_AREA')}
        </Label>
        <Value>
          {numberWithComma(isImperial ? sqmToSqf(area) : area, { suffix: ` ${T.translate(isImperial ? 'SQF' : 'SQM')}`, fixed: 0 })}
        </Value>
      </Cell>
      {blockLotData && !!blockLotData.length && (
      <Cell2>
        <Label>
          {T.translate('FEASIBILITY_BLOCK_LOT')}
        </Label>
        {
          blockLotData.map((blockLot, index) => (
            <Value>
              {`${index ? ', ' : ''}${blockLot.block}-${blockLot.lot}`}
            </Value>
          ))
        }
      </Cell2>
      )}
    </FeasibilityLotInfoFormWrapper>
  );
};

FeasibilityLotInfoForm.propTypes = {
  data: PropTypes.object,
  lotArea: PropTypes.number,
  blockLotData: PropTypes.array,
};

export default React.memo(FeasibilityLotInfoForm);
