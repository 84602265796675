import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { Form, message } from 'antd';
import { StyledInputAuth, StyledFormItem, PrimaryButton } from '../../styles/commonComponents.styles';
import { AUTHORIZATION_FORM } from '../../constants/fieldNames';

const SingUpForm = (props) => {
  const { onSubmit, history } = props;
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { email, password } = values;

    onSubmit({ email, password, firstName: 'peter', lastName: 'parker' }, history)
      .catch((error) => message.error(error));
  };

  const compareToFirstPassword = (rule, value) => {
    if (!value || value === form.getFieldValue(AUTHORIZATION_FORM.PASSWORD)) {
      return Promise.resolve();
    }
    return Promise.reject(T.translate('AUTH_FORM_ERROR_INCONSISTENT_PASSWORD'));
  };

  return (
    <Form form={form} name="singUpForm" onFinish={onFinish}>
      <StyledFormItem
        hasFeedback
        name={AUTHORIZATION_FORM.EMAIL}
        label={T.translate('AUTH_FORM_EMAIL')}
        rules={[{ required: true, message: T.translate('AUTH_FORM_ERROR_NO_EMAIL') }]}
      >
        <StyledInputAuth type="email" placeholder={T.translate('AUTH_FORM_EMAIL_PLACEHOLDER')} />
      </StyledFormItem>

      <StyledFormItem
        hasFeedback
        name={AUTHORIZATION_FORM.PASSWORD}
        label={T.translate('AUTH_FORM_PASSWORD')}
        rules={[{ required: true, message: T.translate('AUTH_FORM_ERROR_NO_PASSWORD') }]}
      >
        <StyledInputAuth type="password" placeholder={T.translate('AUTH_FORM_PASSWORD_PLACEHOLDER')} />
      </StyledFormItem>

      <StyledFormItem
        hasFeedback
        name={AUTHORIZATION_FORM.CONFIRM_PASSWORD}
        dependencies={[AUTHORIZATION_FORM.PASSWORD]}
        label={T.translate('AUTH_FORM_CONFIRM_PASSWORD')}
        rules={[
          { required: true, message: T.translate('AUTH_FORM_ERROR_CONFIRM_PASSWORD') },
          { validator: compareToFirstPassword },
        ]}
      >
        <StyledInputAuth type="password" placeholder={T.translate('AUTH_FORM_CONFIRM_PASSWORD_AGAIN_PLACEHOLDER')} />
      </StyledFormItem>

      <StyledFormItem>
        <PrimaryButton marginTop={16} type="submit">{T.translate('AUTH_FORM_CREAT_ACCOUNT')}</PrimaryButton>
      </StyledFormItem>
    </Form>
  );
};

SingUpForm.propTypes = {
  history: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default SingUpForm;
