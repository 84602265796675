import React from 'react';

export default () => (
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 18.0139V8C1 4.13401 4.13401 1 8 1H18.0139" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 33.9862V44.0001C1 47.8661 4.13401 51.0001 8 51.0001H18.0139" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M51.0005 18.0139V8C51.0005 4.13401 47.8665 1 44.0005 1H33.9866" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M51.0005 33.9862V44.0001C51.0005 47.8661 47.8665 51.0001 44.0005 51.0001H33.9866" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="26.1722" cy="26.5208" r="16.8819" fill="currentColor" fillOpacity="0.9" stroke="white" strokeWidth="2" />
  </svg>
);
