import React from 'react';

// currentcolor
export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.5 5.5V7.5H7.5V5.5H5.5ZM5.2 5C5.08954 5 5 5.08954 5 5.2V7.8C5 7.91046 5.08954 8 5.2 8H7.8C7.91046 8 8 7.91046 8 7.8V5.2C8 5.08954 7.91046 5 7.8 5H5.2Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.5 10.5V12.5H7.5V10.5H5.5ZM5.2 10C5.08954 10 5 10.0895 5 10.2V12.8C5 12.9105 5.08954 13 5.2 13H7.8C7.91046 13 8 12.9105 8 12.8V10.2C8 10.0895 7.91046 10 7.8 10H5.2Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.5 5.5V7.5H12.5V5.5H10.5ZM10.2 5C10.0895 5 10 5.08954 10 5.2V7.8C10 7.91046 10.0895 8 10.2 8H12.8C12.9105 8 13 7.91046 13 7.8V5.2C13 5.08954 12.9105 5 12.8 5H10.2Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.5 10.5V12.5H12.5V10.5H10.5ZM10.2 10C10.0895 10 10 10.0895 10 10.2V12.8C10 12.9105 10.0895 13 10.2 13H12.8C12.9105 13 13 12.9105 13 12.8V10.2C13 10.0895 12.9105 10 12.8 10H10.2Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3.2 2.625C2.88243 2.625 2.625 2.88244 2.625 3.2V21.125H1C0.792893 21.125 0.625 21.2929 0.625 21.5C0.625 21.7071 0.792893 21.875 1 21.875H23C23.2071 21.875 23.375 21.7071 23.375 21.5C23.375 21.2929 23.2071 21.125 23 21.125H21.875V7.2C21.875 6.88244 21.6176 6.625 21.3 6.625H15.375V3.2C15.375 2.88244 15.1176 2.625 14.8 2.625H3.2ZM14.625 3.375H3.375V21.125H7V15.2C7 15.0895 7.08954 15 7.2 15H10.8C10.9105 15 11 15.0895 11 15.2V21.125H14.625V3.375ZM7.5 15.5V21H10.5V15.5H7.5ZM17.25 10.5C17.25 10.3619 17.3619 10.25 17.5 10.25H21.125V7.375H15.375V21.125H21.125V16.75H17.5C17.3619 16.75 17.25 16.6381 17.25 16.5C17.25 16.3619 17.3619 16.25 17.5 16.25H21.125V13.75H17.5C17.3619 13.75 17.25 13.6381 17.25 13.5C17.25 13.3619 17.3619 13.25 17.5 13.25H21.125V10.75H17.5C17.3619 10.75 17.25 10.6381 17.25 10.5Z" fill="currentcolor" />
  </svg>
);
