import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { metricDivideValue } from '../../utils/helpers/unitsHelpers';
import { StyledInputNumber } from '../../styles/commonComponents.styles';

const ImperialMetricInput = forwardRef((props, ref) => {
  const { onChange, value, isImperial, unit = metricDivideValue, min, max } = props;
  const [stringValue, useStringValue] = useState({ value: Number(value).toFixed(0), isImperial: true });

  useEffect(() => {
    if (value) {
      handleChange(isImperial ? value : value / unit, useStringValue);
    } else {
      handleChange(0, useStringValue);
    }
  }, [value]);

  useEffect(() => {
    if (stringValue.isImperial && !isImperial) {
      handleChange(Number(stringValue.value / unit), useStringValue);
    }
    if (!stringValue.isImperial && isImperial) {
      handleChange(Number(stringValue.value * unit), useStringValue);
    }
  }, [isImperial]);

  const handleChange = (currentValue, update) => {
    update({ value: Number(currentValue).toFixed(isImperial ? 0 : 2), isImperial });
    onChange(isImperial ? currentValue : currentValue * unit);
  };

  const numberInputFormatter = (currentValue) => `${currentValue}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

  const setFinalValue = () => {
    if (min && stringValue.value < min) {
      return min;
    }
    if (max && stringValue.value > max) {
      return max;
    }
    return stringValue.value;
  };

  return (
    <StyledInputNumber
      {...props}
      ref={ref}
      min={min}
      max={max}
      value={setFinalValue()}
      formatter={(currentValue) => numberInputFormatter(currentValue)}
      onChange={(currentValue) => handleChange(currentValue, useStringValue)}
    />
  );
});

ImperialMetricInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  isImperial: PropTypes.bool,
  unit: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default ImperialMetricInput;
