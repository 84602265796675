import React from 'react';

// currentcolor
export default () => (
  <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.375" y="11.6151" width="21.25" height="4.53" stroke="currentcolor" strokeWidth="0.75" />
    <rect x="1.375" y="6.99512" width="21.25" height="4.53" stroke="currentcolor" strokeWidth="0.75" />
    <rect x="1.375" y="2.375" width="21.25" height="4.53" stroke="currentcolor" strokeWidth="0.75" />
    <line x1="22.7151" y1="16.08" x2="22.7151" y2="21.36" stroke="currentcolor" strokeWidth="0.75" />
    <line x1="1.375" y1="16.0802" x2="1.375" y2="21.3602" stroke="currentcolor" strokeWidth="0.75" />
  </svg>
);
