import React from 'react';
import T from 'i18n-react';
import { ResultWrapper } from '../../../form/FeasibilityTargetFormContainer.Styles';
import { ContentTitle, VerticalTitleContentWrapper } from '../ReportPages.styles';
import ViewerContainer from '../ViewerContainer';

const Overall = () => (
  <VerticalTitleContentWrapper>
    <ContentTitle>{T.translate('Overall')}</ContentTitle>
    <ResultWrapper padding={1} height={170}>
      <ViewerContainer />
    </ResultWrapper>
  </VerticalTitleContentWrapper>
);

export default React.memo(Overall);
