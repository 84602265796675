import React from 'react';

export default () => (
  <svg width="160" height="152" viewBox="0 0 160 152" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M67.797 151.228C105.24 151.228 135.594 145.556 135.594 138.56C135.594 131.564 105.24 125.892 67.797 125.892C30.3537 125.892 0 131.564 0 138.56C0 145.556 30.3537 151.228 67.797 151.228Z" fill="#A6B0B9" />
    <path fillRule="evenodd" clipRule="evenodd" d="M122.034 101.344L98.1091 71.899C96.9611 70.513 95.2831 69.674 93.5161 69.674H42.0761C40.3101 69.674 38.6321 70.513 37.4841 71.899L13.5601 101.344V116.727H122.035V101.344H122.034Z" fill="#A6B0B9" />
    <path fillRule="evenodd" clipRule="evenodd" d="M115.097 117.884L94.19 92.772C93.189 91.565 91.683 90.905 90.142 90.905H45.284C43.744 90.905 42.237 91.565 41.236 92.772L20.329 117.884V131.676H115.097V117.884Z" fill="#222222" />
    <path fillRule="evenodd" clipRule="evenodd" d="M33.8301 31.67H101.763C102.824 31.67 103.841 32.0914 104.592 32.8416C105.342 33.5917 105.763 34.6091 105.763 35.67V129.014C105.763 130.075 105.342 131.092 104.592 131.842C103.841 132.593 102.824 133.014 101.763 133.014H33.8301C32.7692 133.014 31.7518 132.593 31.0017 131.842C30.2515 131.092 29.8301 130.075 29.8301 129.014V35.67C29.8301 34.6091 30.2515 33.5917 31.0017 32.8416C31.7518 32.0914 32.7692 31.67 33.8301 31.67V31.67Z" fill="#C5CCD3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M42.678 41.623H92.915C93.4454 41.623 93.9541 41.8337 94.3292 42.2088C94.7043 42.5839 94.915 43.0926 94.915 43.623V68.58C94.915 69.1104 94.7043 69.6191 94.3292 69.9942C93.9541 70.3693 93.4454 70.58 92.915 70.58H42.678C42.1476 70.58 41.6388 70.3693 41.2638 69.9942C40.8887 69.6191 40.678 69.1104 40.678 68.58V43.623C40.678 43.0926 40.8887 42.5839 41.2638 42.2088C41.6388 41.8337 42.1476 41.623 42.678 41.623ZM42.94 81.437H92.653C93.2529 81.437 93.8283 81.6753 94.2525 82.0995C94.6767 82.5237 94.915 83.0991 94.915 83.699C94.915 84.2989 94.6767 84.8743 94.2525 85.2985C93.8283 85.7227 93.2529 85.961 92.653 85.961H42.94C42.3401 85.961 41.7647 85.7227 41.3405 85.2985C40.9163 84.8743 40.678 84.2989 40.678 83.699C40.678 83.0991 40.9163 82.5237 41.3405 82.0995C41.7647 81.6753 42.3401 81.437 42.94 81.437ZM42.94 93.2H92.653C93.253 93.2 93.8285 93.4384 94.2528 93.8627C94.6771 94.287 94.9155 94.8624 94.9155 95.4625C94.9155 96.0626 94.6771 96.638 94.2528 97.0623C93.8285 97.4866 93.253 97.725 92.653 97.725H42.94C42.3399 97.725 41.7645 97.4866 41.3402 97.0623C40.9159 96.638 40.6775 96.0626 40.6775 95.4625C40.6775 94.8624 40.9159 94.287 41.3402 93.8627C41.7645 93.4384 42.3399 93.2 42.94 93.2Z" fill="#FBFDFF" />
    <path fillRule="evenodd" clipRule="evenodd" d="M121.813 136.702C121.038 139.773 118.316 142.062 115.078 142.062H20.5151C17.2771 142.062 14.5551 139.772 13.7811 136.702C13.6335 136.117 13.5589 135.516 13.5591 134.912V101.345H39.8771C42.7841 101.345 45.1271 103.793 45.1271 106.765V106.805C45.1271 109.776 47.4971 112.175 50.4041 112.175H85.1891C88.0961 112.175 90.4661 109.754 90.4661 106.782V106.77C90.4661 103.798 92.8091 101.344 95.7161 101.344H122.034V134.913C122.034 135.53 121.957 136.129 121.813 136.702Z" fill="#DCE0E6" />
    <path fillRule="evenodd" clipRule="evenodd" d="M125.121 33.292L118.291 35.942C118.116 36.0102 117.924 36.0275 117.74 35.992C117.555 35.9564 117.384 35.8693 117.246 35.7408C117.108 35.6123 117.01 35.4476 116.962 35.2656C116.914 35.0836 116.918 34.8917 116.974 34.712L118.911 28.505C116.322 25.561 114.802 21.971 114.802 18.097C114.802 8.102 124.92 0 137.402 0C149.881 0 160 8.102 160 18.097C160 28.092 149.882 36.194 137.401 36.194C132.873 36.194 128.657 35.128 125.121 33.292Z" fill="#DCE0E6" />
    <path d="M146.304 21.365C147.877 21.365 149.153 20.1047 149.153 18.55C149.153 16.9953 147.877 15.735 146.304 15.735C144.731 15.735 143.455 16.9953 143.455 18.55C143.455 20.1047 144.731 21.365 146.304 21.365Z" fill="#FBFDFF" />
    <path fillRule="evenodd" clipRule="evenodd" d="M131.348 21.013H125.65L128.548 16.087L131.348 21.013ZM134.909 16.087H139.894V21.013H134.909V16.087Z" fill="#FBFDFF" />
  </svg>
);
