import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashGet from 'lodash/get';
import FeasibilityViewerContainer from 'utils/swappViewer/FeasibilityViewerContainer';
import CameraProjectionToggleButton from 'utils/swappViewer/components/ui/CameraProjectionToggleButton';
import { BUILDING_INFO_TYPES, MODEL_ANALYSIS_TYPES } from 'constants/feasibilityConts';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { useSelector } from 'react-redux';
import { getProfileByIdSelector } from 'store/swappProfile';
import { useProfileResult } from 'store/swappProfile/selectors/swappSelectors';
import {
  ViewerUiPanelWrapper,
  UiButtonsWrapper,
} from 'styles/commonComponents.styles';
import ViewerUiPanel from 'utils/swappViewer/components/ui/ViewerUiPanel';
import { feasibilityResultModel, useActiveProfileBuildingInfo } from 'utils/model/feasibilityResultModel';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import FeasibilityEfficiencyScore from 'components/feasibility/studies/result/program/FeasibilityEfficiencyScore';
import InfoBox from 'components/common/InfoBox';
import FeasibilityLegendPanel from './FeasibilityLegendPanel';

const EnvironmentContainerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const EnvironmentContainer = () => {
  const locationData = parseLocationUrl(window.location);
  const profile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const result = useProfileResult(profile);
  const [isOrthographic, setIsOrthographic] = useState(false);
  const [legendKey, setLegendKey] = useState(MODEL_ANALYSIS_TYPES.SUN_ANALYSIS);
  const buildingInfo = useActiveProfileBuildingInfo();
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const data = useMemo(() => feasibilityResultModel(result, isImperial, buildingInfo), [result, isImperial]);

  return (
    <EnvironmentContainerWrapper>
      {/* ============ UI ============  */}
      <ViewerUiPanelWrapper position="top-left" fitContent>
        <ViewerUiPanel width={260}>
          <div style={{ paddingLeft: '22px' }}>
            <FeasibilityLegendPanel profile={profile} legendKey={legendKey} updateLegendKey={setLegendKey} />

            {!lodashIsEmpty(lodashGet(data, 'passiveHouseTable')) && (
              <FeasibilityEfficiencyScore data={lodashGet(data, 'passiveHouseTable')} />
            )}
            {lodashGet(data, 'passiveHouseTable', []).filter((e) => e.type === BUILDING_INFO_TYPES.LIST).map((item, index) => (
              <InfoBox
                key={item.key}
                marginBottom={!index && 10}
                marginTop={!index && 20}
                data={{ title: item.name, rightInfo: item.value }}
              />
            ))}
          </div>
        </ViewerUiPanel>
        <UiButtonsWrapper>
          <CameraProjectionToggleButton
            toggle={() => setIsOrthographic(!isOrthographic)}
            selected={!isOrthographic}
          />
        </UiButtonsWrapper>
      </ViewerUiPanelWrapper>

      {/* ============ viewer ============ */}
      <FeasibilityViewerContainer
        result={result}
        isOrthographic={isOrthographic}
        profileId={locationData.profileId}
        legendKey={legendKey}
      />
    </EnvironmentContainerWrapper>
  );
};

export default EnvironmentContainer;
