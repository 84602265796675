export default {
  // AreaTargetFormContainer
  'LSF:RSF': 'Offices and workstations',
  'LSF:USF': 'Useable Area',
  'PSF:RSF': 'Conference and collaboration',
  'ASF:RSF': 'Amenity',
  'FSF:RSF': 'Support',

  LSFA: 'Private Offices',
  LSFB: 'Shared Area',

  AREA_TARGET_FORM_CONTAINER_RSF_LSF: 'Floor Area / Offices and workstations',
  AREA_TARGET_FORM_CONTAINER_RATABLE_LEASABLE: 'Floor Area / Offices and workstations',
  AREA_TARGET_FORM_CONTAINER_LSF: 'Offices and workstations',
  AREA_TARGET_FORM_CONTAINER_LEASABLE_AREA: '',
  AREA_TARGET_FORM_CONTAINER_PSF: 'Conference and collaboration',
  AREA_TARGET_FORM_CONTAINER_PRODUCTIVE_AREA: '',
  AREA_TARGET_FORM_CONTAINER_ASF: 'Amenity',
  AREA_TARGET_FORM_CONTAINER_AMENITY_SPACES: '',
  AREA_TARGET_FORM_CONTAINER_FSF: 'Support',
  AREA_TARGET_FORM_CONTAINER_FACILITY_SPACES: '',
  AREA_TARGET_FORM_CONTAINER_BATHROOMS: 'Restrooms',
  AREA_TARGET_FORM_CONTAINER_BATHROOMS_DESCRIPTION: '',
  AREA_TARGET_FORM_CONTAINER_MODAL_WARNING_PSF: 'Conference and collaboration area was not assigned',
  AREA_TARGET_FORM_CONTAINER_MODAL_WARNING_ASF: 'Amenity area was not assigned',
  AREA_TARGET_FORM_CONTAINER_MODAL_WARNING_FSF: 'Support area was not assigned',

  // AreaTargetFormPsf
  AREA_TARGET_FORM_PSF_ERROR: 'It is advised that Conference and collaboration total area estimated area does not exceed 20% of the total building area',

  // AreaTargetFormAsf
  AREA_TARGET_FORM_ASF_PER_MEMBER: 'Amenity per member',
  AREA_TARGET_FORM_ASF_ERROR: 'It is advised that Amenity total area estimated area does not exceed 20% of the total building area',

  // OverallContainer
  OVERALL_CONTAINER_RSF: 'Floor Areas',
  OVERALL_CONTAINER_TOTAL_RSF_AREA: 'Total Floor Area',
  OVERALL_CONTAINER_TOTAL_USF_AREA: 'Total Useable Area',
  OVERALL_CONTAINER_RENTABLE: '',
  OVERALL_CONTAINER_LSF: 'Offices and Workstations area',
  OVERALL_CONTAINER_LEASABLE_AREA: '',
  OVERALL_CONTAINER_PSF: 'Conference and collaboration',
  OVERALL_CONTAINER_PRODUCTIVE_AREA: '',
  OVERALL_CONTAINER_ASF: 'Amenity',
  OVERALL_CONTAINER_AMENITY_SPACES: '',
  OVERALL_CONTAINER_FSF: 'Support',
  OVERALL_CONTAINER_FACILITY_SPACES: '',
  OVERALL_CONTAINER_ASF_PER_MEMBER: 'Amenity per member',

  // AreaTargetFormRsfLsf
  AREA_TARGET_FORM_RSF_LSF_RSF_LSF_LABEL: 'Offices and Workstations / RSF',
  AREA_TARGET_FORM_RSF_LSF_LSF_TOTAL_LABEL: 'Offices and Workstations area:',
  AREA_TARGET_FORM_SIMPLE_FORM_EMPLOYEE_NUMBER: 'Target number of members',

  // AreaTargetFormLsf
  AREA_TARGET_FORM_LSF_TOTAL_TOOLTIP: 'Total Offices and Workstations area target must be 100%',
};
