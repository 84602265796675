import React from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
// import lodashGet from 'lodash/get';

const CustomMesh = (props) => {
  const { child, receiveShadow, castShadow, materialProps, renderOrder, usePhongeMaterial } = props;
  // const meshRef = useRef();

  // useEffect(() => () => {
  //   if (lodashGet(meshRef, 'current')) {
  //     meshRef.current.geometry.dispose();
  //     meshRef.current.material.dispose();
  //   }
  // }, []);

  return (
    <mesh
      key={child.uuid}
      {...child}
      receiveShadow={receiveShadow}
      castShadow={castShadow}
      renderOrder={renderOrder}
      // ref={meshRef}
      // onPointerOver={(e) => intractable && onPointerOver(e.intersections[0].eventObject.uuid)}
      // onPointerOut={() => intractable && onPointerOut()}
    >
      { usePhongeMaterial && <meshPhongMaterial color={child.material.color} map={child.material.map} {...materialProps} encoding={THREE.sRGBEncoding} />}
    </mesh>
  );
};
// map={child.material.map}
CustomMesh.propTypes = {
  index: PropTypes.number,
  child: PropTypes.object,
  intractable: PropTypes.bool,
  castShadow: PropTypes.bool,
  materialProps: PropTypes.object,
  renderOrder: PropTypes.number,
  receiveShadow: PropTypes.bool,
  usePhongeMaterial: PropTypes.bool,
  onPointerOver: PropTypes.func,
  onPointerOut: PropTypes.func,
};

export default CustomMesh;
