import React from 'react';

// currentcolor
export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" />
    <path d="M27.7505 28.3633L27.2688 28.1225L26.9075 29.0859L27.7505 29.4472H27.8709C28.4731 29.3268 30.5204 28.7246 31.4838 26.6773C32.3268 24.8709 31.9655 23.6666 31.6042 22.944C30.8817 21.7397 29.6774 21.3784 29.1956 21.258H29.0752H28.3526V22.3419H28.9548C29.3161 22.4623 30.1591 22.7031 30.6408 23.5462C31.1225 24.2687 31.0021 25.2322 30.5204 26.3161C29.7978 27.7612 28.3526 28.2429 27.7505 28.3633Z" fill="currentcolor" />
    <path d="M13.6602 33.4214H22.2107V34.5052H13.6602V33.4214Z" fill="currentcolor" />
    <path d="M14.2623 32.458H21.2473H21.3677C23.1741 31.7354 24.7397 30.5311 25.8236 28.9655C26.9075 27.2795 27.63 25.3527 27.63 23.4258V19.2108H8V23.3054C8 25.3527 8.60215 27.2795 9.80645 28.9655C10.7699 30.5311 12.3355 31.7354 14.2623 32.458ZM8.96344 20.1742H26.4257V23.3054C26.4257 26.9182 24.258 30.0494 21.0064 31.3741H14.5032C11.2516 30.0494 8.96344 26.7978 8.96344 23.1849V20.1742Z" fill="currentcolor" />
    <path d="M16.5507 13.3096C17.3937 12.7075 18.3571 12.1053 18.4776 11.0215C18.598 9.81716 17.8754 8.7333 16.1894 7.40857L15.5872 8.25159C16.912 9.21503 17.6345 10.1784 17.5141 10.901C17.3937 11.5032 16.7915 11.9849 16.069 12.4666C15.2259 13.0688 14.3829 13.6709 14.2625 14.7548C14.1421 15.8387 14.9851 16.9225 16.7915 18.1268L17.3937 17.2838C16.069 16.4408 15.3464 15.5978 15.3464 14.8752C15.2259 14.2731 15.8281 13.7913 16.5507 13.3096Z" fill="currentcolor" />
    <path d="M22.3311 5.84302L21.7289 5C20.5246 5.84301 19.9225 6.68603 19.8021 7.64947C19.6816 8.61291 20.0429 9.21505 20.4042 9.93763C20.6451 10.4193 20.8859 10.901 20.8859 11.5032C20.8859 12.4666 20.1634 13.3097 18.8386 14.2731L19.4408 15.1161C21.1268 13.9118 21.8494 12.7075 21.9698 11.5032C21.9698 10.6602 21.6085 10.0581 21.3676 9.45591C21.1268 8.9742 20.7655 8.49245 20.8859 7.89031C20.8859 7.0473 21.4881 6.44517 22.3311 5.84302Z" fill="currentcolor" />
  </svg>
);
