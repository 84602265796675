import { Logger } from 'aws-cloudwatch-log';

const LOG_STREAM_NAME = process.env.REACT_APP_CLOUDWATCH_LOG_STREAM_NAME;

const config = {
  logGroupName: 'BrowserLogs',
  logStreamName: LOG_STREAM_NAME || 'NONE',
  region: 'us-east-1',
  accessKeyId: 'AKIAWYC6EYTCSCX2FXNU',
  secretAccessKey: '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', // TODO TECH DEBT This is a security no-no, but this IAM role can only write logs.
  uploadFreq: 1000, 	// Optional. Send logs to AWS LogStream in batches after 1 second intervals.
  local: LOG_STREAM_NAME === undefined || LOG_STREAM_NAME.length === 0, // Optional. If set to true, the log will fall back to the standard 'console.log'.
};

const logger = new Logger(config);

// logger.log('Hello World');
// logger.log('I\'m', 'aws-cloudwatch-log.', 'I can log many things at once, as well as objects as follow:');
// logger.log({ type: 'this-is-important', details: 'something has happened!' });
// logger.log({ category: 'info', details: 'I\'m fast and lean. I don\'t block, and everything happens in the background!' });

export const LOG_LEVELS = {
  DEBUG: 0,
  INFO: 1,
  WARNING: 2,
  ERROR: 3,
};

const LOG_LEVEL_NAMES = {
  [LOG_LEVELS.DEBUG]: 'DEBUG',
  [LOG_LEVELS.INFO]: 'INFO',
  [LOG_LEVELS.WARNING]: 'WARNING',
  [LOG_LEVELS.ERROR]: 'ERROR',
};

export const logMessage = (logLevel, message, props = {}) => {
  logger.log({ ...props, level: LOG_LEVEL_NAMES[logLevel], message });
};
