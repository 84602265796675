import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedMass: null,
};

const viewsSlice = createSlice({
  name: 'views',
  initialState,
  reducers: {
    setSelectedMass(state, action) {
      state.selectedMass = action.payload;
    },
  },
});

// =========== Actions =========== //
export const { setSelectedMass } = viewsSlice.actions;
export default viewsSlice.reducer;

// =========== Selectors =========== //
export const selectedMassSelector = ({ views }) => views.selectedMass;
