import * as $ from 'jquery';

// Mostly taken from https://www.jqueryscript.net/table/jQuery-Plugin-To-Export-Table-Data-To-CSV-File-table2csv.html

const options = {
  /* action='download' options */
  filename: 'table.csv',

  /* action='output' options */
  appendTo: 'body',

  /* general options */
  separator: ',',
  newline: '\n',
  quoteFields: true,
  trimContent: false,
  excludeColumns: '',
  excludeRows: '',
  removeCommas: true,
};

function quote(text) {
  return `"${text.replace(/"/g, '""')}"`;
}

export function convertjQueryTableToCSV(table) {
  let output = '';

  const rows = table.find('tr').not(options.excludeRows);

  // const numCols = rows.first().find('td,th').filter(':visible').not(options.excludeColumns).length;
  // const numCols = rows.first().find('td,th').not(options.excludeColumns).length;

  rows.each((ignore, elem) => {
    // $(elem).find('td,th') /* .filter(':visible') */.not(options.excludeColumns)
    $(elem).find('td,th').not(options.excludeColumns)
      .each((i, col) => {
        const column = $(col);

        // Strip whitespaces
        let content = options.trimContent
          ? $.trim(column.text())
          : column.text();

        if (options.removeCommas) {
          content = content.replaceAll(',', '');
        }
        output += options.quoteFields
          ? quote(content)
          : content;
        output += options.separator;
        // if (i !== numCols - 1) {
        //   output += options.separator;
        // } else {
        //   output += options.newline;
        // }
      });
    output += options.newline;
  });

  return output;
}

// taken from http://stackoverflow.com/questions/3665115/create-a-file-in-memory-for-user-to-download-not-through-server
export function downloadFileWithText(filename, text) {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/csv;charset=utf-8,\ufeff${encodeURIComponent(text)}`);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
