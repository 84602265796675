import areaIcon from './areaIcon';
import roomIcon from './roomIcon';
import roomIconSmall from './roomIconSmall';
import tilesIcon from './tilesIcon';
import tilesIconSmall from './tilesIconSmall';
import undoIcon from '../../viewerIcons/undoIcon';
import modelIcon from './modelIcon';
import clearFilterIcon from './clearFilterIcon';
import threeDwIcon from './3dwIcon';
import fovIndicatorIcon from './fovIndicatorIcon';
import markupsIcon from './markupsIcon';
import closeIcon from './closeIcon';
import saveIcon from './saveIcon';
import revisionIcon from './revisionIcon';
import csvIcon from './csvIcon';
import departmentsIcon from './departmentsIcon';
import dwgIcon from './dwgIcon';
import deleteRoomIcon from './deleteRoomIcon';
import lockIcon from './lockIcon';
import unlockIcon from './unlockIcon';
import gridIcon from './gridIcon';
import listIcon from './listIcon';
import swappRoomIcon from './swappRoomIcon';
import dragIcon from './dragIcon';
import dragIcon2 from './dragIcon2';
import sortIcon from './sortIcon';

export default {
  areaIcon,
  roomIcon,
  roomIconSmall,
  tilesIcon,
  tilesIconSmall,
  undoIcon,
  modelIcon,
  clearFilterIcon,
  threeDwIcon,
  fovIndicatorIcon,
  markupsIcon,
  closeIcon,
  saveIcon,
  revisionIcon,
  csvIcon,
  departmentsIcon,
  dwgIcon,
  deleteRoomIcon,
  lockIcon,
  unlockIcon,
  gridIcon,
  listIcon,
  swappRoomIcon,
  dragIcon,
  dragIcon2,
  sortIcon,
};
