import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import T from 'i18n-react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import { StyledFormItem, StyledInputNumber } from 'styles/commonComponents.styles';
import { DEVELOPMENT_APPRAISAL_FORM } from 'constants/feasibilityReportConts';
import { handleOnChange } from 'utils/helpers/feasibilityReportHelpers';
import { getProfileByIdSelector, loadingSelector } from 'store/swappProfile';
import {
  ReportMainColumnDataSummery,
  ReportMainColumnDataSummeryTextWrapper,
  ReportMainColumnDataTextWrapper,
  ReportMainColumnDataWrapper,
  ReportMainColumnWrapper,
  StyledProgress,
  StyledText,
} from '../report/ReportPages.styles';
import CurrencyInput from '../../../../common/CurrencyInput';

const DevelopmentAppraisalSummeryBar = (props) => {
  const { formData, formInstance, currentSelectedProfileId, disabled } = props;
  const dispatch = useDispatch();
  const profile = useSelector((state) => getProfileByIdSelector(state, currentSelectedProfileId));
  const isLoading = useSelector(loadingSelector);
  const [currentForm, setCurrentForm] = useState({});
  const totalRevenue = lodashGet(formData, `[${DEVELOPMENT_APPRAISAL_FORM.TOTAL_REVENUE}]`, 0);
  const totalCosts = lodashGet(formData, `[${DEVELOPMENT_APPRAISAL_FORM.TOTAL_COSTS}]`, 0);

  useEffect(() => {
    if (!lodashIsEmpty(formData)) {
      updateCurrentForm(setCurrentForm);
    }
  }, [totalRevenue, totalCosts, currentSelectedProfileId]);

  const updateCurrentForm = (update) => {
    const residualCouncilInvestment = totalRevenue - totalCosts;
    const residualCouncilInvestmentPercentage = ((residualCouncilInvestment / totalCosts) * 100).toFixed(1);

    update({ totalRevenue, totalCosts, residualCouncilInvestment, residualCouncilInvestmentPercentage });
  };

  const onPercentageChange = (value, fieldToChange) => {
    if (disabled) {
      return;
    }
    formInstance.setFieldsValue({ [fieldToChange]: 100 - value });
    handleOnChange(dispatch, { profile, formInstance, isLoading, disabled });
  };

  return (
    <ReportMainColumnWrapper>
      <ReportMainColumnDataWrapper>
        <StyledText bold marginBottom={10}>{T.translate('Target')}</StyledText>

        <ReportMainColumnDataTextWrapper>
          <StyledText>{T.translate('Sales Units')}</StyledText>
          <StyledFormItem name={DEVELOPMENT_APPRAISAL_FORM.SALES_UNITS} initialValue={80} width={80}>
            <StyledInputNumber
              min={0}
              max={100}
              height={22}
              disabled={disabled}
              formatter={(value) => `${Number(value).toFixed(0)}%`}
              parser={(value) => value.replace('%', '')}
              onChange={(value) => onPercentageChange(value, DEVELOPMENT_APPRAISAL_FORM.AFFORDABLE_UNITS)}
            />
          </StyledFormItem>
        </ReportMainColumnDataTextWrapper>

        <ReportMainColumnDataTextWrapper underLine marginBottom={5}>
          <StyledText>{T.translate('Affordable Units')}</StyledText>
          <StyledFormItem name={DEVELOPMENT_APPRAISAL_FORM.AFFORDABLE_UNITS} initialValue={20} width={80}>
            <StyledInputNumber
              min={0}
              max={100}
              height={22}
              disabled={disabled}
              formatter={(value) => `${Number(value).toFixed(0)}%`}
              parser={(value) => value.replace('%', '')}
              onChange={(value) => onPercentageChange(value, DEVELOPMENT_APPRAISAL_FORM.SALES_UNITS)}
            />
          </StyledFormItem>
        </ReportMainColumnDataTextWrapper>

        <ReportMainColumnDataTextWrapper>
          <StyledText>{T.translate('Affordable Reduction')}</StyledText>
          <StyledFormItem name={DEVELOPMENT_APPRAISAL_FORM.AFFORDABLE_REDUCTION} initialValue={80} width={80}>
            <StyledInputNumber
              min={0}
              height={22}
              disabled={disabled}
              formatter={(value) => `${Number(value).toFixed(0)}%`}
              parser={(value) => value.replace('%', '')}
            />
          </StyledFormItem>
        </ReportMainColumnDataTextWrapper>
      </ReportMainColumnDataWrapper>

      <ReportMainColumnDataSummery>
        <ReportMainColumnDataSummeryTextWrapper>
          <StyledText middle>{T.translate('Total Costs')}</StyledText>
          <StyledText align="end" middle size="xLarge">
            <CurrencyInput value={currentForm.totalCosts || 0} readOnly />
          </StyledText>
        </ReportMainColumnDataSummeryTextWrapper>

        <ReportMainColumnDataSummeryTextWrapper marginBottom={35}>
          <StyledText middle>{T.translate('Total Revenue')}</StyledText>
          <StyledText align="end" middle size="xLarge">
            <CurrencyInput value={currentForm.totalRevenue || 0} readOnly />
          </StyledText>
        </ReportMainColumnDataSummeryTextWrapper>

        <StyledText marginBottom={10}>{T.translate('Residual Council Investment')}</StyledText>
        <ReportMainColumnDataSummeryTextWrapper>
          <StyledProgress
            strokeLinecap="square"
            type="circle"
            percent={currentForm.residualCouncilInvestmentPercentage || 0}
            format={() => `${currentForm.residualCouncilInvestmentPercentage || 0}%`}
            strokeWidth={12}
            width={72}
          />
          <StyledText highlight={currentForm.residualCouncilInvestment >= 1} align="end" middle size="xLarge">
            <CurrencyInput value={currentForm.residualCouncilInvestment || 0} readOnly />
          </StyledText>
        </ReportMainColumnDataSummeryTextWrapper>
      </ReportMainColumnDataSummery>
    </ReportMainColumnWrapper>
  );
};

DevelopmentAppraisalSummeryBar.propTypes = {
  formData: PropTypes.object,
  formInstance: PropTypes.object,
  currentSelectedProfileId: PropTypes.number,
  disabled: PropTypes.bool,
};

export default DevelopmentAppraisalSummeryBar;
