/* eslint-disable prefer-destructuring */
import React, { useCallback, useState, useEffect } from 'react';
import * as THREE from 'three';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { MeshLineRaycast } from 'threejs-meshline';
import { selectMarkup, useIsMarkupDragged, useMarkupAttachmentId } from 'store/markups';
import { shadeHexColor } from 'utils/helpers/threeHelpers';
import { extendPolygon } from 'utils/algorithms/jstsHelpers';
import { selectedMassSelector } from 'store/views';
import { setEditorType } from 'store/editor';
import { changeCursor } from '../helpers/SelectionHelpers';
import { Z_INDEX, VIEWS_LINE_WIDTH, VIEWS_LINE_COLOR, VIEWS_LINE_DISABLED_COLOR } from './constants';

const ScopeBoxGraphic = ({ points, id, isSelected, isViewOnly, type }) => {
  const [isHovered, setIsHovered] = useState(false);
  const isAnyMarkupBeingDragged = useIsMarkupDragged();
  const selectedMass = useSelector(selectedMassSelector);
  const attachmentId = useMarkupAttachmentId(id);
  const disabled = attachmentId !== selectedMass;
  const color = disabled ? VIEWS_LINE_DISABLED_COLOR : VIEWS_LINE_COLOR;
  const lineWidth = VIEWS_LINE_WIDTH;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isSelected && isHovered) {
      setIsHovered(false);
    }
  }, [isSelected]);

  const onLinesClicked = useCallback((event) => {
    if (isViewOnly || disabled) {
      return;
    }
    event.stopPropagation();
    dispatch(selectMarkup({ id }));
    dispatch(setEditorType(type));
  }, [points, isViewOnly, disabled]);

  const onPointerOver = useCallback((e) => {
    if (isViewOnly || disabled) {
      return;
    }
    if (!isSelected && !isAnyMarkupBeingDragged) {
      e.stopPropagation();
      changeCursor('pointer');
      setIsHovered(true);
    }
  }, [points, isSelected, isAnyMarkupBeingDragged, setIsHovered, isViewOnly, disabled]);

  const onPointerOut = useCallback(() => {
    if (isViewOnly || disabled) {
      return;
    }
    if (!isSelected && !isAnyMarkupBeingDragged) {
      changeCursor('crosshair');
      setIsHovered(false);
    }
  }, [points, isSelected, isAnyMarkupBeingDragged, setIsHovered, isViewOnly, disabled]);

  return (
    <mesh
      raycast={MeshLineRaycast}
      onPointerOver={onPointerOver}
      onPointerOut={onPointerOut}
      onPointerUp={onLinesClicked}
      position={[0, 0, Z_INDEX.GRAPHICS]}
    >
      <meshLine attach="geometry" vertices={extendPolygon([...points, points[0]], -0.8).map((v) => new THREE.Vector3(...v))} />
      <meshLineMaterial attach="material" color={(isHovered && !isSelected) ? shadeHexColor(color, -0.2) : color} lineWidth={lineWidth} />
    </mesh>
  );
};

ScopeBoxGraphic.propTypes = {
  points: PropTypes.array,
  id: PropTypes.string,
  isSelected: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  type: PropTypes.string,
};

export default React.memo(ScopeBoxGraphic);
