/* eslint-disable no-restricted-globals */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// import Icon from '@ant-design/icons';
// import icons from '../../styles/static/icons/testFit';

// const StyledIcon = styled(Icon)`
//   color: #5772ff;
//   opacity: ${({ isInLineOfSight }) => (isInLineOfSight ? 1.0 : 0.3)};
//   transform: scale(${({ scale }) => scale});

//   &:hover {
//     color: #5772ff;
//   }
// `;

const StyledDiv = styled.div`
  width: 60px;
  height: 60px;
  color: black;
  background-color: rgba(255,255,255,0.3);
  border-radius: 50%;
  border: 1px solid;
  opacity: ${({ isInLineOfSight }) => (isInLineOfSight ? 1.0 : 0.3)};
  transform: scale(${({ scale }) => scale});

  &:hover {
    background-color: rgba(255,255,255,0.5);
    cursor: pointer;
  }
`;

const Point3D = ({ children, id, onButtonClicked, scale, isInLineOfSight }) => (
  <StyledDiv
    // component={icons.wtPointIcon}
    onClick={() => onButtonClicked(id)}
    scale={scale}
    isInLineOfSight={isInLineOfSight}
  >
    {children}
  </StyledDiv>
);

Point3D.propTypes = {
  children: PropTypes.array,
  onButtonClicked: PropTypes.func,
  id: PropTypes.number,
  scale: PropTypes.number,
  isInLineOfSight: PropTypes.bool,
};

export default React.memo(Point3D);
