import React from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import { StyledExploreTable, HighlightCell } from 'styles/commonComponents.styles';
import LoadingSpinner from '../../../../common/LoadingSpinner';

const ProfileBathroomsDataPanel = (props) => {
  const { data } = props;

  const columns = [
    {
      dataIndex: 'header',
      key: 'header',
      align: 'center',
      children: [
        {
          dataIndex: 'name',
          key: 'name',
          width: 150,
        },
        {
          dataIndex: 'noOfWaterCloset',
          key: 'noOfWaterCloset',
          align: 'center',
          width: 40,
          render: (value) => <HighlightCell>{value}</HighlightCell>,
        },
      ],
    },
  ];

  if (!lodashGet(data, 'tableData')) {
    return <LoadingSpinner />;
  }

  return <StyledExploreTable ellipsis pagination={false} columns={columns} dataSource={data.tableData} />;
};

ProfileBathroomsDataPanel.propTypes = {
  data: PropTypes.object,
};

export default React.memo(ProfileBathroomsDataPanel);
