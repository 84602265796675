import React from 'react';

export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" />
    <path d="M33.1893 6.28H6.81245C6.56088 6.28 6.33667 6.50422 6.33667 6.75577V14.0358C6.33667 14.2873 6.56089 14.5116 6.81245 14.5116H33.1882C33.4397 14.5116 33.6639 14.2873 33.6639 14.0358V6.75577C33.665 6.50421 33.4408 6.28 33.1893 6.28H33.1893ZM32.7124 13.56H7.28926V7.23144H32.7135L32.7124 13.56Z" fill="currentcolor" />
    <path d="M14.0915 25.4886H6.81147C6.55991 25.4886 6.33569 25.7128 6.33569 25.9643V33.2443C6.33569 33.4959 6.55991 33.7201 6.81147 33.7201H14.0915C14.343 33.7201 14.5672 33.4959 14.5672 33.2443V25.9643C14.5683 25.7117 14.3441 25.4886 14.0915 25.4886ZM13.5883 32.7686H7.28834V26.44H13.5883V32.7686Z" fill="currentcolor" />
    <path d="M23.6401 25.4886H16.3601C16.1085 25.4886 15.8843 25.7128 15.8843 25.9643V33.2443C15.8843 33.4959 16.1085 33.7201 16.3601 33.7201H23.6401C23.8916 33.7201 24.1158 33.4959 24.1158 33.2443V25.9643C24.1158 25.7117 23.8916 25.4886 23.6401 25.4886V25.4886ZM23.1643 32.7686H16.8357V26.44H23.1357L23.1643 32.7686Z" fill="currentcolor" />
    <path d="M33.1886 25.4886H25.9086C25.6571 25.4886 25.4329 25.7128 25.4329 25.9643V33.2443C25.4329 33.4959 25.6571 33.7201 25.9086 33.7201H33.1886C33.4402 33.7201 33.6644 33.4959 33.6644 33.2443V25.9643C33.6644 25.7117 33.4402 25.4886 33.1886 25.4886ZM32.7118 32.7686H26.4118V26.44H32.7118V32.7686Z" fill="currentcolor" />
    <path d="M20.0002 15.3243C19.7486 15.3243 19.5244 15.5485 19.5244 15.8001V20.7558H10.4519C10.2003 20.7558 9.97607 20.98 9.97607 21.2316V24.2001C9.97607 24.4517 10.2003 24.6759 10.4519 24.6759C10.7034 24.6759 10.9276 24.4517 10.9276 24.2001V21.7359H19.5233L19.5244 24.2001C19.5244 24.4517 19.7487 24.6759 20.0002 24.6759C20.2518 24.6759 20.476 24.4517 20.476 24.2001V21.7359H29.0717V24.2001C29.0717 24.4517 29.2959 24.6759 29.5475 24.6759C29.799 24.6759 30.0233 24.4517 30.0233 24.2001L30.0244 21.2316C30.0244 20.98 29.8001 20.7558 29.5486 20.7558H20.476V15.8001C20.476 15.5485 20.2518 15.3243 20.0002 15.3243H20.0002Z" fill="currentcolor" />
  </svg>
);
