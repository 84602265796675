import React from 'react';

export default () => (
  <svg width="150" height="40" viewBox="0 0 202 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 0H0V2.5H6.5V54.5H0V57H21.5V54.5H15V29H40V54.5H33V57H54.5V54.5H48.5V2.5H54.5V0H33V2.5H39.5V26H15V2.5H22V0Z" fill="#B70021" />
    <path d="M64 0V7.5H71.5V0H64Z" fill="#B70021" />
    <path d="M71.5 16L58 17.5V19.5H64V54.5H58V57H77V54.5H71.5V16Z" fill="#B70021" />
    <path d="M81.5 19.5V17L95 16V25.5C95.5 24.5 96.6943 22.5669 98 21C100.211 18.3463 105.029 15.3662 112 16C117.5 16.5 120.667 19.8333 121 22.5V54.5H126.5V57H108V54.5H113.5V24.5C113.5 21 112 17.5 105.5 19C101.602 19.8994 96.8333 27 95 31V54.5H100V56.5H81.5V54.5H86.5V19.5H81.5Z" fill="#B70021" />
    <path d="M173 43.5H170V57H172.5L174.5 53.5C175.5 55.1667 179.789 58.5 187 58.5C194 58.5 198.5 53 199.5 51.5C200.5 50 201.5 49 201.5 44.5C201 39.5 199 36.875 198.5 36.5C196.5 35 196.786 34.9599 194.5 34C192.101 32.9927 189.804 32.512 187.5 32C183 31 174 30.0596 174 25C174 21 179.019 17.0432 184.5 17.5C190.5 18 193 23.5 194.5 28H197V17H195C194.833 17.8333 194.3 19.4 193.5 19C192.5 18.5 190 15.5 183 15.5C177.4 15.5 170.5 20.5 170.5 25.5V31.5C171.5 34.6667 173.5 38 182.5 39.5C192.559 41.1765 195 43.5 196 45.5C197 47.5 195.7 56 186.5 56C177.3 56 173.667 47.6667 173 43.5Z" fill="#B70021" />
    <path fillRule="evenodd" clipRule="evenodd" d="M136.5 34H165.5C165.833 30 164.936 21.077 155 17.5C142.5 13 135.303 20.1972 133.5 22C130.5 25 128.406 29.8034 128 32C127.594 34.1966 127.511 36.6869 127.5 38.5C127.491 40.0369 127.589 43.3549 128 45C128.5 47 129.5 48.5 130.5 50C131.5 51.5 137.5 58.5 146 58.5C152 59 159 56.5 162 52.5C165 48.5 165.5 44.6667 165.5 43.5H163C162.833 47.1667 159.5 55.5 150 56C139.942 56.5294 137 49 136.5 45V34ZM156 31H137V25C137.167 22.6667 139.4 18.5 147 18.5C154.6 18.5 156.167 22.6667 156 25V31Z" fill="#B70021" />
  </svg>
);
