import React from 'react';
import lodashIncludes from 'lodash/includes';
import lodashGet from 'lodash/get';
import T from 'i18n-react';
import { message } from 'antd';
import styled from 'styled-components';
import { WAITING_FOR_KEYS } from 'constants/feasibilityConts';
import { setIn } from 'utils/helpers/immutableHelpers';
import { uuidv4 } from 'utils/helpers/uuidv4';
import { getShareKeyString, isSharedUrl, parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { setCurrentTheme } from 'store/userSettings';
import { API_AUTH_ROUTES, updateProjectProfile } from '../../../constants/routes/api';
import { UI_AUTHORIZE_PATH } from '../../../constants/routes/ui';
import { PROFILE_STATUS } from '../../../constants';
import {
  getSwappErrorActionCreator,
  getSwappRequestActionCreator,
  getSwappSuccessActionCreator,
  pollSwappRequestActionCreator,
  pollSwappSuccessActionCreator,
  pollSwappErrorActionCreator,
  setSwappProfileStageRequestActionCreator,
  setSwappProfileStageSuccessActionCreator,
  setSwappProfileStageErrorActionCreator,
  clearSwappProfileActionCreator,
  editProjectErrorActionCreator,
  editProjectRequestActionCreator,
  editProjectSuccessActionCreator,
  newProjectErrorActionCreator,
  newProjectRequestActionCreator,
  newProjectSuccessActionCreator,
  submitProfileErrorActionCreator,
  submitProfileRequestActionCreator,
  submitProfileSuccessActionCreator,
  submitUpdateProfileRequestActionCreator,
  submitUpdateProfileSuccessActionCreator,
  submitUpdateProfileErrorActionCreator,
  submitWellbeingAnalysisRequestActionCreator,
  submitWellbeingAnalysisSuccessActionCreator,
  submitWellbeingAnalysisErrorActionCreator,
  updateProjectProfileErrorActionCreator,
  updateProjectProfileRequestActionCreator,
  updateProjectProfileSuccessActionCreator,
  createProfileRequestActionCreator,
  createProfileErrorActionCreator,
  createProfileSuccessActionCreator,
  getShareableLinkRequestActionCreator,
  getShareableLinkErrorActionCreator,
  getShareableLinkSuccessActionCreator,
  getProjectFromShareableLinkRequestActionCreator,
  getProjectFromShareableLinkErrorActionCreator,
  getProjectFromShareableLinkSuccessActionCreator,
  deleteProfileRequestActionCreator,
  deleteProfileErrorActionCreator,
  deleteProfileSuccessActionCreator,
  clearActivePollingProfileIdActionCreator,
  changeMassesTransformRequestActionCreator,
  changeMassesTransformErrorActionCreator,
  changeMassesTransformSuccessActionCreator,
  editResultRequestActionCreator,
  editResultErrorActionCreator,
  editResultSuccessActionCreator,
  generateReportRequestActionCreator,
  generateReportSuccessActionCreator,
  generateReportErrorActionCreator,
  generateRevitFileRequestActionCreator,
  generateRevitFileSuccessActionCreator,
  generateRevitFileErrorActionCreator,
  generateEnergyAnalysisRequestActionCreator,
  generateEnergyAnalysisSuccessActionCreator,
  generateEnergyAnalysisErrorActionCreator,
  generateHbEnergyAnalysisRequestActionCreator,
  generateHbEnergyAnalysisSuccessActionCreator,
  generateHbEnergyAnalysisErrorActionCreator,
  generateOcbParkingRequestActionCreator,
  generateOcbParkingSuccessActionCreator,
  generateOcbParkingErrorActionCreator,
} from './swappProfileActionsCreators';
import languages from '../../../locale';

const setTextByProject = (data) => {
  const parentProfile = lodashGet(data, 'projectProfiles').find((profile) => !profile.parentProfileId, {});
  const languageKey = lodashGet(parentProfile, 'profileData.settings.languageKey') || lodashGet(data, 'user.settings.flags.languageKey') || 'en';
  T.setTexts(languages[languageKey]);
};

const MessageWrapper = styled.span`
  display: inline-flex;
  flex-wrap: wrap;
  width: 550px;
`;

const MessageLink = styled.div`
  cursor: pointer;
  color: #5772ff;
  margin-left: 5px;
`;

const MessageSecondRow = styled.div`
  color: #989AAF;
  margin-top: 5px;
`;

const {
  setup, program, studies, officesA,
} = UI_AUTHORIZE_PATH;

export const getSwappProjectAction = (projectId) => async (dispatch, _, agent) => {
  dispatch(getSwappRequestActionCreator());
  try {
    const { data } = await agent.get(`${API_AUTH_ROUTES.newProject}/${projectId}`);
    setTextByProject(data);
    dispatch(setCurrentTheme({ swappProject: data }));
    dispatch(getSwappSuccessActionCreator(data));
  } catch (e) {
    dispatch(getSwappErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};

export const pollSwappProjectAction = (projectId, profileId, history, key) => (dispatch, _, agent) => {
  dispatch(pollSwappRequestActionCreator(profileId));
  const shareKey = getShareKeyString();

  const polling = async () => {
    try {
      const { data } = await agent.get(`${API_AUTH_ROUTES.newProject}/${projectId}${shareKey || ''}`, { uuid: uuidv4() });
      const profileIndex = data.projectProfiles.findIndex((profile) => profile.id === Number(profileId));
      const waitingForResults = lodashIncludes([PROFILE_STATUS.WAITING_FOR_DATA, PROFILE_STATUS.WAITING_FOR_EXTRA_DATA], lodashGet(data, `projectProfiles[${profileIndex}].status`));
      let isPathTheSame;
      if (history) {
        const currentLocationData = parseLocationUrl(history.location);
        isPathTheSame = Number(projectId) === Number(currentLocationData.projectId);

        if (!isPathTheSame) {
          dispatch(clearActivePollingProfileIdActionCreator(profileId));
          return;
        }
      }

      if (waitingForResults) {
        setTimeout(() => polling(projectId), 4000);
        dispatch(pollSwappSuccessActionCreator(data));
        return;
      }

      dispatch(clearActivePollingProfileIdActionCreator(profileId));
      dispatch(pollSwappSuccessActionCreator(data));

      switch (key) { // TODO - move in to a different file
        case WAITING_FOR_KEYS.REVIT_FILE:
          const name = lodashGet(data, `projectProfiles[${profileIndex}].name`);
          const downloadLink = lodashGet(data, `projectProfiles[${profileIndex}].result.revitFile`);
          if (downloadLink) {
            message.info(
              {
                content: (
                  <MessageWrapper>
                    <span>{T.translate(`Revit massing model "${name}" is ready for `)}</span>
                    <a href={downloadLink} target="_blank" rel="noreferrer"><MessageLink>{T.translate('download')}</MessageLink></a>
                    <MessageSecondRow>{T.translate('It is also available in the model study tab')}</MessageSecondRow>
                  </MessageWrapper>
                ),
                key: `${WAITING_FOR_KEYS.REVIT_FILE}_${profileId}`,
                duration: 7,
              },
            );
          }
          break;
        default:
          return null;
      }
    } catch (e) {
      dispatch(clearActivePollingProfileIdActionCreator(profileId));
      dispatch(pollSwappErrorActionCreator(e));
      return Promise.reject(e.message);
    }
  };
  polling();
};

// exporting for testing purposes
export const setProfileWithWaitingKey = (profile, key) => {
  const modifiedProfile = setIn(`result.waitingFor[${key}]`, true, profile);
  return modifiedProfile;
};

export const submitWellbeingAnalysisAction = (projectId, profileId, history) => async (dispatch, _, agent) => {
  dispatch(submitWellbeingAnalysisRequestActionCreator());
  try {
    const { data } = await agent.post(`${API_AUTH_ROUTES.projectProfile}/${profileId}${API_AUTH_ROUTES.analyse}`);
    dispatch(submitWellbeingAnalysisSuccessActionCreator(setProfileWithWaitingKey(data, WAITING_FOR_KEYS.ANALYSIS)));
    dispatch(pollSwappProjectAction(projectId, profileId, history, WAITING_FOR_KEYS.ANALYSIS));
  } catch (e) {
    dispatch(submitWellbeingAnalysisErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};

export const generateReportAction = (projectId, profileId, history) => async (dispatch, _, agent) => {
  dispatch(generateReportRequestActionCreator());
  try {
    const { data } = await agent.post(`${API_AUTH_ROUTES.projectProfile}/${profileId}${API_AUTH_ROUTES.generateReport}`);
    dispatch(generateReportSuccessActionCreator(setProfileWithWaitingKey(data, WAITING_FOR_KEYS.REPORT)));
    dispatch(pollSwappProjectAction(projectId, profileId, history, WAITING_FOR_KEYS.REPORT));
  } catch (e) {
    dispatch(generateReportErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};

export const generateSiteData = (profileId) => async (dispatch, _, agent) => {
  try {
    const { data } = await agent.post(`${API_AUTH_ROUTES.projectProfile}/${profileId}/${API_AUTH_ROUTES.generateSiteData}`);
    dispatch(pollSwappProjectAction(data.projectId, data.id));
  } catch (e) {
    dispatch(generateReportErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};

export const generateDownloadFileAction = (projectId, profileId, history, key, params = {}) => async (dispatch, _, agent) => {
  dispatch(generateRevitFileRequestActionCreator());
  try {
    const { data } = await agent.post(`${API_AUTH_ROUTES.projectProfile}/${profileId}${API_AUTH_ROUTES.exportFile}`, params);
    dispatch(generateRevitFileSuccessActionCreator(setProfileWithWaitingKey(data, key)));
    dispatch(pollSwappProjectAction(projectId, profileId, history, key));
  } catch (e) {
    dispatch(generateRevitFileErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};

export const setSwappProfileStageAction = (projectId, profileId, history) => async (dispatch, _, agent) => {
  dispatch(setSwappProfileStageRequestActionCreator());
  try {
    const { data } = await agent.post(`${API_AUTH_ROUTES.newProject}/${projectId}${API_AUTH_ROUTES.approveProfile}`, { projectProfileId: Number(profileId) });
    dispatch(setSwappProfileStageSuccessActionCreator(data));
    history.push(`/${UI_AUTHORIZE_PATH[data.stage]}/${projectId}/${setup}/${data.id}/${officesA}`);
  } catch (e) {
    dispatch(setSwappProfileStageErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};

export const clearSwappProjectAction = () => (dispatch) => {
  dispatch(clearSwappProfileActionCreator());
};

export const createProjectAction = (payload) => async (dispatch, _, agent) => {
  dispatch(newProjectRequestActionCreator());
  try {
    const { data } = await agent.post(`${API_AUTH_ROUTES.newProject}`, payload);
    dispatch(newProjectSuccessActionCreator(data));
    return Promise.resolve(data);
  } catch (e) {
    dispatch(newProjectErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};

export const editProjectAction = (projectId, payload) => async (dispatch, _, agent) => {
  dispatch(editProjectRequestActionCreator());
  try {
    const { data } = await agent.put(`${API_AUTH_ROUTES.newProject}/${projectId}`, payload);
    dispatch(editProjectSuccessActionCreator(data));
    return Promise.resolve();
  } catch (e) {
    dispatch(editProjectErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};

export const submitProfileAction = (profileId) => async (dispatch, _, agent) => {
  dispatch(submitProfileRequestActionCreator());
  try {
    const { data } = await agent.patch(`${API_AUTH_ROUTES.projectProfile}/${profileId}`);
    dispatch(submitProfileSuccessActionCreator(data));
  } catch (e) {
    dispatch(submitProfileErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};

export const updateProfileAction = (profileId) => async (dispatch, _, agent) => {
  dispatch(submitUpdateProfileRequestActionCreator());
  try {
    const { data } = await agent.patch(`${API_AUTH_ROUTES.projectProfile}/${profileId}`);
    dispatch(submitUpdateProfileSuccessActionCreator(data));
  } catch (e) {
    dispatch(submitUpdateProfileErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};

export const createNewProfileAction = ({ projectId, profileId, stage, history, isChildProfile, isDuplicate, formData }) => async (dispatch, _, agent) => {
  dispatch(createProfileRequestActionCreator(formData ? { disableLoading: true } : {}));
  try {
    const payload = formData || { isDuplicate, stage, sourceProjectProfileId: Number(profileId) || null, isChildProfile };
    const { data } = await agent.post(`${API_AUTH_ROUTES.newProject}/${projectId}${API_AUTH_ROUTES.newProfile}`, payload);
    if (history) {
      history.push(`/${UI_AUTHORIZE_PATH[stage]}/${data.projectId}/${studies}/${program}/`);
    }
    dispatch(createProfileSuccessActionCreator(data));
  } catch (e) {
    dispatch(createProfileErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};

export const updateProjectProfileAction = (id, payload) => async (dispatch, _, agent) => {
  if (isSharedUrl()) {
    return;
  }
  let dataToUpdate = { id };
  if (payload.profileDataUpdates) {
    const profileDataUpdates = JSON.parse(payload.profileDataUpdates);
    const data = {};
    profileDataUpdates.forEach((item) => {
      if (lodashIncludes(item.subPath, '/')) {
        const path = item.subPath.split('/');
        data[path[0]] = { [path[1]]: item.data };
      } else {
        data[item.subPath] = item.data;
      }
    });
    dataToUpdate = { id, key: 'profileData', data };
  }
  if (payload.name) {
    dataToUpdate = { id, key: 'name', data: payload.name };
  }
  if (payload.markupUpdates) {
    const parsedData = JSON.parse(payload.markupUpdates);
    dataToUpdate = { id, key: 'markups', data: { [parsedData[0].subPath]: parsedData[0].data } };
  }

  dispatch(updateProjectProfileRequestActionCreator(dataToUpdate));
  try {
    await agent.put(updateProjectProfile(id), payload);
    dispatch(updateProjectProfileSuccessActionCreator());
  } catch (e) {
    dispatch(updateProjectProfileErrorActionCreator(e));
    return Promise.reject(e);
  }
};

export const getShareableLinkAction = (projectId) => async (dispatch, _, agent) => {
  dispatch(getShareableLinkRequestActionCreator());
  try {
    const { data } = await agent.get(`${API_AUTH_ROUTES.newProject}/${projectId}${API_AUTH_ROUTES.shareKey}`);
    dispatch(getShareableLinkSuccessActionCreator(data));
  } catch (e) {
    dispatch(getShareableLinkErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};

export const getProjectFromShareableLinkAction = (projectId, key) => async (dispatch, _, agent) => {
  dispatch(getProjectFromShareableLinkRequestActionCreator());
  try {
    const { data } = await agent.get(`${API_AUTH_ROUTES.newProject}/${projectId}${API_AUTH_ROUTES.shareKeyGet}=${key}`);
    setTextByProject(data);
    dispatch(setCurrentTheme({ swappProject: data }));
    dispatch(getProjectFromShareableLinkSuccessActionCreator(data));
  } catch (e) {
    dispatch(getProjectFromShareableLinkErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};

export const deleteProfileAction = (profileId, history, relevantProfileIDRoute) => async (dispatch, _, agent) => {
  dispatch(deleteProfileRequestActionCreator());
  try {
    const { data } = await agent.delete(`${API_AUTH_ROUTES.projectProfile}/${profileId}`, {});
    dispatch(deleteProfileSuccessActionCreator(data.id));
    if (history && relevantProfileIDRoute) {
      history.push(`/${UI_AUTHORIZE_PATH[data.stage]}/${data.projectId}/${setup}/${relevantProfileIDRoute}/`);
    }
  } catch (e) {
    dispatch(deleteProfileErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};

export const changeMassesTransform = (projectId, profileId, history, massesTransform) => async (dispatch, _, agent) => {
  dispatch(changeMassesTransformRequestActionCreator());
  try {
    const { data } = await agent.post(`${API_AUTH_ROUTES.projectProfile}/${profileId}${API_AUTH_ROUTES.transformMasses}`, { mass_transforms: massesTransform });
    dispatch(changeMassesTransformSuccessActionCreator(data.id));
    dispatch(pollSwappProjectAction(projectId, profileId, history));
  } catch (e) {
    dispatch(changeMassesTransformErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};

// DEPRECATED: Avoid using this, better to use the websocket directly via editSession.js
export const editProfileResult = (projectId, profileId, history, editOperations, createCopy) => async (dispatch, _, agent) => {
  dispatch(editResultRequestActionCreator());
  try {
    const { data } = await agent.post(`${API_AUTH_ROUTES.projectProfile}/${profileId}${API_AUTH_ROUTES.editResult}`, { operations: editOperations, createCopy });
    dispatch(editResultSuccessActionCreator(data));
    const locationData = parseLocationUrl(history.location);
    history.push(`/${locationData.stage}/${locationData.projectId}/${locationData.stageTab}/${locationData.tabSideBar}`);
    // The poll has to follow the history.push as it stops polling if the URL changes.
    dispatch(pollSwappProjectAction(projectId, data.id, history));
  } catch (e) {
    dispatch(editResultErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};

export const generateEnergyAnalysis = (params, projectId, profileId, history) => async (dispatch, _, agent) => {
  dispatch(generateEnergyAnalysisRequestActionCreator());
  try {
    const { data } = await agent.post(`${API_AUTH_ROUTES.projectProfile}/${profileId}${API_AUTH_ROUTES.generateEnergyAnalysis}`, params);
    dispatch(generateEnergyAnalysisSuccessActionCreator(setProfileWithWaitingKey(data, WAITING_FOR_KEYS.COVE_TOOL)));
    dispatch(pollSwappProjectAction(projectId, profileId, history, WAITING_FOR_KEYS.COVE_TOOL));
  } catch (e) {
    dispatch(generateEnergyAnalysisErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};

export const generateOcbParking = (params, projectId, profileId, history) => async (dispatch, _, agent) => {
  dispatch(generateOcbParkingRequestActionCreator());
  try {
    const { data } = await agent.post(`${API_AUTH_ROUTES.projectProfile}/${profileId}${API_AUTH_ROUTES.generateOcbParking}`, params);
    dispatch(generateOcbParkingSuccessActionCreator(setProfileWithWaitingKey(data, WAITING_FOR_KEYS.OCB_PARKING)));
    dispatch(pollSwappProjectAction(projectId, profileId, history, WAITING_FOR_KEYS.OCB_PARKING));
  } catch (e) {
    dispatch(generateOcbParkingErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};

export const generateHbEnergyAnalysis = (params, projectId, profileId, history) => async (dispatch, _, agent) => {
  dispatch(generateHbEnergyAnalysisRequestActionCreator());
  try {
    const { data } = await agent.post(`${API_AUTH_ROUTES.projectProfile}/${profileId}${API_AUTH_ROUTES.generateHBEnergyAnalysis}`, params);
    dispatch(generateHbEnergyAnalysisSuccessActionCreator(setProfileWithWaitingKey(data, WAITING_FOR_KEYS.HB_ENERGY)));
    dispatch(pollSwappProjectAction(projectId, profileId, history, WAITING_FOR_KEYS.HB_ENERGY));
  } catch (e) {
    dispatch(generateHbEnergyAnalysisErrorActionCreator(e));
    return Promise.reject(e.message);
  }
};
