import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import { contentRefContexts } from 'utils/hooks/reactContexts';
import { useComDidMount } from 'utils/hooks';
import { getMyFloorAction } from 'store/swappProfiles/actions/swappProfilesActions';
import FloorLibraryPage from '../pages/FloorsLibraryPage';
import SuccessNotificationPage from '../pages/SuccessNotificationPage';
import TestFitPage from '../pages/TestFitPage';
import ProjectSettingsPage from '../pages/ProjectSettingsPage';
import FeasibilityPage from '../pages/FeasibilityPage';
import Header from '../components/core/Header';
import { AuthorizedWrapper, AppWrapper, Content } from './authorizedRoutes.styles';

const {
  root,
  TEST_FIT,
  FEASIBILITY_STUDY,
  PROJECT_SETTINGS,
  successNotification,
} = UI_AUTHORIZE_PATH;

const AuthorizeRoutes = () => {
  const [contentRef, setContentRef] = useState(null);
  const dispatch = useDispatch();

  useComDidMount(() => {
    dispatch(getMyFloorAction());
  });

  return (
    <AuthorizedWrapper>
      <Header />
      <AppWrapper>
        <Content ref={setContentRef}>
          <contentRefContexts.Provider value={contentRef}>
            <Switch>
              <Route exact path={successNotification} component={SuccessNotificationPage} />
              <Route exact path={root} component={FloorLibraryPage} />
              <Route path={`/${PROJECT_SETTINGS}`} component={ProjectSettingsPage} />
              <Route path={`/${TEST_FIT}`} component={TestFitPage} />
              <Route path={`/${FEASIBILITY_STUDY}`} component={FeasibilityPage} />
              <Redirect to={root} />
            </Switch>
          </contentRefContexts.Provider>
        </Content>
      </AppWrapper>
    </AuthorizedWrapper>
  );
};

export default AuthorizeRoutes;
