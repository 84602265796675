import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { Form, message } from 'antd';
import { StyledFormItem, StyledInputAuth, PrimaryButton } from '../../styles/commonComponents.styles';
import { AUTHORIZATION_FORM } from '../../constants/fieldNames';

const PasswordResetForm = (props) => {
  const { onSubmit } = props;

  const onFinish = (values) => {
    onSubmit(values)
      .then(() => message.success(T.translate('RESET_PASSWORD_FORM_CHECK_EMAIL')))
      .catch((error) => message.error(error.statusCode === 400 ? T.translate('RESET_PASSWORD_FORM_EMAIL_NOT_FOUND') : error.error));
  };

  return (
    <Form name="resetPasswordForm" onFinish={onFinish}>
      <StyledFormItem
        name={AUTHORIZATION_FORM.EMAIL}
        label={T.translate('AUTH_FORM_EMAIL')}
        rules={[{ required: true, message: T.translate('AUTH_FORM_ERROR_NO_EMAIL') }]}
      >
        <StyledInputAuth type="email" placeholder={T.translate('AUTH_FORM_EMAIL_PLACEHOLDER')} />
      </StyledFormItem>

      <StyledFormItem>
        <PrimaryButton marginTop={16} type="submit"> {T.translate('AUTH_FORM_SEND_EMAIL')}</PrimaryButton>
      </StyledFormItem>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default PasswordResetForm;
