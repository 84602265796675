import React from 'react';
import styled from 'styled-components';
import { shallowEqual, useSelector } from 'react-redux';
import { isSharedUrl, parseLocationUrl } from 'utils/helpers/navigationHelpers';
import TestFitViewerContainer from 'utils/swappViewer/testFit/TestFitViewerContainer';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { swappProfileResultsSelector } from 'store/swappProfile';
import TestFitMarkupsUi from 'utils/swappViewer/markups/ui/TestFitMarkupsUi';
import EditMarkupsButton from 'utils/swappViewer/components/ui/EditMarkupsButton';
import { ViewerUiPanelWrapper } from 'styles/commonComponents.styles';
import ViewerUiPanel from 'utils/swappViewer/components/ui/ViewerUiPanel';

const MarkupsContainerWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const MarkupsContainer = () => {
  const locationData = parseLocationUrl(window.location);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const swappProfileResults = useSelector((state) => swappProfileResultsSelector(state, locationData.profileId, isImperial), shallowEqual);

  return (
    <MarkupsContainerWrapper>
      {/* ============= UI ============= */}
      <ViewerUiPanelWrapper style={{ margin: '50px 0 0 80px' }} position="top-left" fitContent>
        <ViewerUiPanel width={130} notCollapsible>
          <EditMarkupsButton />
        </ViewerUiPanel>
      </ViewerUiPanelWrapper>
      <TestFitMarkupsUi isMarkupsOpen={!isSharedUrl()} />

      {/* ============= Viewer ============= */}
      <TestFitViewerContainer plan={swappProfileResults?.multiBoundaryFile} profileId={locationData.profileId} isMarkupsOpen isOrthographic />
    </MarkupsContainerWrapper>
  );
};

export default MarkupsContainer;
