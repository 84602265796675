import React from 'react';
import PropTypes from 'prop-types';
import { extend } from 'react-three-fiber';
import * as THREE from 'three';
import * as meshline from 'threejs-meshline';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import { useContextBridge } from '@react-three/drei';
import { __RouterContext } from 'react-router';
import { isRoomDragActiveSelector, setMultiClick } from 'store/testFit';
import { isSharedUrl } from 'utils/helpers/navigationHelpers';
import { StyledCanvas } from './TestFitViewerContainer.styles';
import CameraController from '../components/CameraController';
import * as SwappEditor from '../../../store/editor';
import TestFitSceneSetter from './components/TestFitSceneSetter';
import { SwappThreeContextProvider } from '../../hooks/useSwappThree';
import Markups from '../markups/Markups';

extend(meshline);

const TestFitViewerContainer = (props) => {
  const { plan, isOrthographic = true, profileId, hideGrid, excludeFloorIndex,
    onCameraChange, viewerId, visibleBounds, isMarkupsOpen } = props;

  const isRoomDragActive = useSelector(isRoomDragActiveSelector);
  THREE.Object3D.DefaultUp = new THREE.Vector3(0, 0, 1);
  const dispatch = useDispatch();

  const ContextBridge = useContextBridge(ReactReduxContext, __RouterContext);

  return (
    <StyledCanvas
      contexts={[ReactReduxContext]}
      shadowMap
      colorManagement={false}
      style={{ background: '#F2F5FA' }}
      invalidateFrameloop={!isRoomDragActive}
      orthographic={isOrthographic}
      camera={isOrthographic ? CameraController.DEFAULT_ORTHO_PROPS : CameraController.DEFAULT_PERSPECTIVE_PROPS}
      gl={{ preserveDrawingBuffer: true }}
      onPointerMissed={() => dispatch(SwappEditor.selectObject(null))}
    >
      <ContextBridge>
        <SwappThreeContextProvider>
          <group onPointerUp={() => dispatch(setMultiClick({ active: false, isSelected: false }))} name="CanvasGroup">
            {/* ============== floors ============== */}
            <TestFitSceneSetter profileId={profileId} plan={plan} hideGrid={hideGrid} isOrthographic={isOrthographic} visibleBounds={visibleBounds} excludeFloorIndex={excludeFloorIndex} />

            {/* ============== Markups ============== */}
            {isMarkupsOpen && <Markups profileId={profileId} isViewOnly={isSharedUrl()} />}

            {/* ============== controls ============== */}
            <CameraController viewerId={viewerId} onCameraChange={onCameraChange} isOrthographic={isOrthographic} mapControlsMinMax={[1, 60]} minMaxZoom={[1.5, 50]} />
          </group>
        </SwappThreeContextProvider>
      </ContextBridge>
    </StyledCanvas>
  );
};

TestFitViewerContainer.propTypes = {
  plan: PropTypes.object,
  profileId: PropTypes.number,
  isOrthographic: PropTypes.bool,
  visibleBounds: PropTypes.object,
  hideGrid: PropTypes.bool,
  isMarkupsOpen: PropTypes.bool,
  excludeFloorIndex: PropTypes.number,
  onCameraChange: PropTypes.func,
  viewerId: PropTypes.string,
};

export default React.memo(TestFitViewerContainer);
