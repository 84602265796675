import React from 'react';

export default () => (
  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <rect width="35" height="35" />
      <path d="M22.5725 32.7894L22.5726 32.7897L22.5798 32.7859C22.6012 32.7745 22.6216 32.7622 22.6423 32.747C22.6662 32.7342 22.6875 32.719 22.7061 32.7034L22.7062 32.7035L22.7105 32.6995L29.5068 26.3229L29.5077 26.3221C29.5967 26.2365 29.6478 26.119 29.6478 25.9943V6.12478C29.6478 3.94358 27.879 2.17478 25.6978 2.17478L9.30239 2.17503C7.12119 2.17503 5.35239 3.94383 5.35239 6.12503V28.875C5.35239 31.0562 7.12119 32.825 9.30239 32.825H22.4001C22.4587 32.825 22.5188 32.8127 22.5725 32.7894ZM28.0596 26.4375L22.8501 31.3356V27.3875C22.8501 26.8631 23.2757 26.4375 23.8001 26.4375H28.0596ZM28.7478 6.12503V25.5375H23.8001C22.7783 25.5375 21.9501 26.3657 21.9501 27.3875V31.925H9.30239C7.61854 31.925 6.25239 30.5589 6.25239 28.875V6.12503C6.25239 4.44118 7.61854 3.07503 9.30239 3.07503H25.6978C27.3816 3.07503 28.7478 4.44118 28.7478 6.12503Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.2" />
      <path d="M11.025 11.3149H11.375C12.4059 11.3149 13.2426 10.4795 13.2426 9.44868C13.2426 8.41646 12.4059 7.58112 11.375 7.58112H11.025C9.99418 7.58112 9.15747 8.41646 9.15747 9.44868C9.15747 10.4795 9.99418 11.3149 11.025 11.3149ZM11.025 8.27972H11.375V8.28108C12.0204 8.28108 12.5426 8.80335 12.5426 9.44865C12.5426 10.0926 12.0203 10.6148 11.375 10.6148H11.025C10.3797 10.6148 9.85747 10.0926 9.85747 9.44865C9.85747 8.80332 10.3797 8.28108 11.025 8.28108V8.27972Z" fill="currentcolor" />
      <path d="M23.9749 7.58521H17.6749C16.6441 7.58521 15.8074 8.42055 15.8074 9.4514C15.8074 10.4836 16.6441 11.319 17.6749 11.319H23.9749C25.0058 11.319 25.8425 10.4836 25.8425 9.4514C25.8425 8.42055 25.0058 7.58521 23.9749 7.58521ZM23.9749 10.6204H17.6749V10.619C17.0296 10.619 16.5074 10.0967 16.5074 9.45144C16.5074 8.80751 17.0296 8.28524 17.6749 8.28524H23.9749C24.6203 8.28524 25.1425 8.80751 25.1425 9.45144C25.1425 10.0968 24.6202 10.619 23.9749 10.619V10.6204Z" fill="currentcolor" />
      <path d="M11.025 19.0149H11.375C12.4059 19.0149 13.2426 18.1795 13.2426 17.1487C13.2426 16.1165 12.4059 15.2811 11.375 15.2811H11.025C9.99418 15.2811 9.15747 16.1165 9.15747 17.1487C9.15747 18.1795 9.99418 19.0149 11.025 19.0149ZM11.025 15.9797H11.375V15.9811C12.0204 15.9811 12.5426 16.5034 12.5426 17.1487C12.5426 17.7926 12.0203 18.3149 11.375 18.3149H11.025C10.3797 18.3149 9.85747 17.7926 9.85747 17.1487C9.85747 16.5033 10.3797 15.9811 11.025 15.9811V15.9797Z" fill="currentcolor" />
      <path d="M23.9749 15.2852H17.6749C16.6441 15.2852 15.8074 16.1206 15.8074 17.1514C15.8074 18.1836 16.6441 19.019 17.6749 19.019H23.9749C25.0058 19.019 25.8425 18.1836 25.8425 17.1514C25.8425 16.1206 25.0058 15.2852 23.9749 15.2852ZM23.9749 18.3204H17.6749V18.319C17.0296 18.319 16.5074 17.7967 16.5074 17.1515C16.5074 16.5075 17.0296 15.9852 17.6749 15.9852H23.9749C24.6203 15.9852 25.1425 16.5075 25.1425 17.1515C25.1425 17.7968 24.6202 18.319 23.9749 18.319V18.3204Z" fill="currentcolor" />
      <path d="M9.5104 24.2648H12.8956H12.8942C13.087 24.2648 13.2442 24.109 13.2442 23.9148C13.2442 23.722 13.087 23.5648 12.8942 23.5648H9.5104C9.31626 23.5648 9.1604 23.722 9.1604 23.9148C9.1604 24.109 9.31626 24.2648 9.5104 24.2648H9.5104Z" fill="currentcolor" />
      <path d="M17.79 27.4148H9.5104C9.31626 27.4148 9.1604 27.572 9.1604 27.7648C9.1604 27.9589 9.31626 28.1148 9.5104 28.1148H17.79C17.9841 28.1148 18.14 27.9589 18.14 27.7648C18.14 27.572 17.9841 27.4148 17.79 27.4148Z" fill="currentcolor" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="35" height="35" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
