import { FORM_INPUT_TYPE } from 'utils/helpers/testFitContainerHelprs';
import { AREA_TARGET_FORM } from './fieldNames';
import { TEST_FIT_SIMPLE_FORM } from './dropdownKeys';

export const TEST_FIT_INITIAL_VALUES = {
  [AREA_TARGET_FORM.LSF_RSF_FIELD]: 50,
  [AREA_TARGET_FORM.TOTAL_LSF_AVERAGE_FIELD]: 0,
  [AREA_TARGET_FORM.LSF_ROOMS_FIELD]: [],
  [AREA_TARGET_FORM.TOTAL_LSF_FIELD]: [],
  [AREA_TARGET_FORM.TOTAL_AREA_FIELD]: 25000,
  [AREA_TARGET_FORM.NUMBER_OF_EMPLOYEES]: 0,
  [AREA_TARGET_FORM.INPUT_TOGGLE]: FORM_INPUT_TYPE.PERCENTAGE_TARGET,
  [AREA_TARGET_FORM.SPACE_EFFICIENCY]: TEST_FIT_SIMPLE_FORM.SPACE_EFFICIENCY.BASIC,
  [AREA_TARGET_FORM.TYPE]: TEST_FIT_SIMPLE_FORM.OFFICE_TYPES.COWORKING,
  [AREA_TARGET_FORM.OTHER_TYPE]: '',
  [AREA_TARGET_FORM.PSF_A_FIELD]: [],
  [AREA_TARGET_FORM.PSF_B_FIELD]: [],
  [AREA_TARGET_FORM.ASF_FIELD]: [],
  [AREA_TARGET_FORM.FSF_FIELD]: [],
  [AREA_TARGET_FORM.MALE_BATHROOMS]: 0,
  [AREA_TARGET_FORM.FEMALE_BATHROOMS]: 0,
  [AREA_TARGET_FORM.REQUIRED_MALE_BATHROOMS]: 0,
  [AREA_TARGET_FORM.REQUIRED_FEMALE_BATHROOMS]: 0,
  [AREA_TARGET_FORM.POSITIONING]: [],
  [AREA_TARGET_FORM.DEPARTMENTS]: [],
  [AREA_TARGET_FORM.COMMENTS]: '',
};
