import React from 'react';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import T from 'i18n-react';
import { TEST_FIT_OFFICE_TYPES } from 'constants/dropdownKeys';
import { AREA_TARGET_FORM } from 'constants/fieldNames';
import { setIn } from './immutableHelpers';

export const findParentByKey = (data, key) => data.findIndex((item) => item.key === key || (item.children && item.children.find((children) => children.key === key)));

export const updateRow = (handleOnChange, rowData, field, form, newValues, updateEstimations) => {
  const tableData = form.getFieldValue(field);
  const index = findParentByKey(tableData, rowData.key);

  let mutatedData;
  const items = [...tableData];
  if (rowData.isChild) { // Edit the child table row
    const childIndex = tableData[index].children.findIndex((children) => children.key === rowData.key);
    mutatedData = setIn(`children[${childIndex}]`, { ...rowData, ...newValues }, tableData[index]);
  } else {
    mutatedData = { ...rowData, ...newValues };
  }
  const updateEstimationsObject = updateEstimations ? updateEstimations(mutatedData, newValues) : {};

  items[index] = {
    ...mutatedData,
    ...updateEstimationsObject,
  };

  form.setFieldsValue({ [field]: items });
  if (handleOnChange) {
    handleOnChange();
  }
};

export const updateTable = (handleOnChange, form, field, updateEstimations) => {
  const newTableData = [];
  const tableData = form.getFieldValue(field);
  if (lodashIsEmpty(tableData)) {
    return;
  }
  tableData.forEach((rowData) => {
    newTableData.push({
      ...rowData,
      ...updateEstimations(rowData),
    });
  });

  form.setFieldsValue({ [field]: newTableData });
  if (handleOnChange) {
    handleOnChange();
  }
};

export const deleteRow = (handleOnChange, key, form, field, isChild) => {
  const tableData = form.getFieldValue(field);
  if (isChild) {
    const index = findParentByKey(tableData, key);
    tableData[index].children = tableData[index].children.filter((row) => row.key !== key);
  }

  form.setFieldsValue({ [field]: tableData.filter((row) => row.key !== key) });
  if (handleOnChange) {
    handleOnChange();
  }
};

export const getRoomTypes = (formData, fieldsToGet, dropdownNoneOptions) => { // test-fit
  if (lodashGet(formData, `[${AREA_TARGET_FORM.LSF_ROOMS_FIELD}]`)) {
    const allRoomsArray = fieldsToGet.map((key) => lodashGet(formData, `[${key}]`, []).map((item) => ({
      key: item.roomType === TEST_FIT_OFFICE_TYPES.OTHER ? item.otherText : item.roomType,
      value: item.roomType === TEST_FIT_OFFICE_TYPES.OTHER ? item.otherText : T.translate(item.roomType),
    }))).flat();
    return dropdownNoneOptions ? [dropdownNoneOptions, ...allRoomsArray] : allRoomsArray;
  }
  return [];
};

export const renderDescriptionBullets = (bullets) => (
  <ul style={{ padding: '0 0 0 10px' }}>
    {bullets.map((bullet, index) => <li key={`${index}_${bullet}`}>{T.translate(bullet)}</li>)}
  </ul>
);
