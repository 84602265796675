import React from 'react';

export default () => (
  <svg width="28" height="32" viewBox="0 0 31 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29.484 25.0762L30.0085 25.3786L29.4036 26.4261L28.8791 26.1237L29.484 25.0762Z" fill="currentcolor" />
    <path d="M27.8412 25.523L26.8031 24.9228L27.408 23.8753L28.446 24.4754L27.8412 25.523ZM25.7651 24.3227L24.7271 23.7225L25.3319 22.675L26.37 23.2751L25.7651 24.3227ZM23.6875 23.1239L22.6494 22.5238L23.2543 21.4763L24.2923 22.0764L23.6875 23.1239ZM21.6098 21.9236L20.5718 21.3235L21.1767 20.276L22.2147 20.8761L21.6098 21.9236ZM19.5322 20.7233L18.4942 20.1232L19.099 19.0757L20.1371 19.6758L19.5322 20.7233ZM17.4561 19.5246L16.4181 18.9245L17.023 17.877L18.061 18.4771L17.4561 19.5246Z" fill="currentcolor" />
    <path d="M15.3785 18.3259L15.1564 18.1984L14.9359 18.3259L14.331 17.28L15.1564 16.8027L15.9834 17.28L15.3785 18.3259Z" fill="currentcolor" />
    <path d="M2.47144 25.5214L1.86658 24.4739L2.90461 23.8737L3.50948 24.9212L2.47144 25.5214ZM4.54751 24.3227L3.94264 23.2751L4.98067 22.675L5.58554 23.7225L4.54751 24.3227ZM6.62514 23.1224L6.02028 22.0748L7.05831 21.4747L7.66318 22.5222L6.62514 23.1224ZM8.70278 21.9236L8.09791 20.8761L9.13595 20.276L9.74081 21.3235L8.70278 21.9236ZM10.7804 20.7233L10.1755 19.6758L11.2136 19.0757L11.8184 20.1232L10.7804 20.7233ZM12.8581 19.5246L12.2532 18.4771L13.2912 17.877L13.8961 18.9245L12.8581 19.5246Z" fill="currentcolor" />
    <path d="M0.827031 25.0762L1.4319 26.1237L0.907357 26.4261L0.30249 25.3786L0.827031 25.0762Z" fill="currentcolor" />
    <path d="M14.5515 16.8965H15.7612V17.5014H14.5515V16.8965Z" fill="currentcolor" />
    <path d="M15.7612 15.6917H14.5515V14.4914H15.7612V15.6917ZM15.7612 13.2943H14.5515V12.094H15.7612V13.2943ZM15.7612 10.8953H14.5515V9.69499H15.7612V10.8953ZM15.7612 8.49631H14.5515V7.29758H15.7612V8.49631ZM15.7612 6.09733H14.5515V4.89703H15.7612V6.09733ZM15.7612 3.69834H14.5515V2.49805H15.7612V3.69834Z" fill="currentcolor" />
    <path d="M14.5515 0.697266H15.7612V1.30213H14.5515V0.697266Z" fill="currentcolor" />
    <path d="M15.1563 35L0 26.25V8.75L15.1563 0L30.3111 8.75V26.25L15.1563 35ZM1.20973 25.552L15.1563 33.6044L29.1013 25.5536L29.1029 9.44882L15.1563 1.39644L1.2097 9.44721L1.20973 25.552Z" fill="currentcolor" />
    <path d="M15.1563 18.1971L0.302368 9.62173L0.907235 8.57422L15.1563 16.8012L29.4053 8.57583L30.0102 9.62177L15.1563 18.1971Z" fill="currentcolor" />
    <path d="M14.5515 17.5H15.7612V34.3024H14.5515V17.5Z" fill="currentcolor" />
  </svg>
);
