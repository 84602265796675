import styled from 'styled-components';

export const FeasibilitySitesItemWrapper = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
`;

export const SiteHeader = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray_06};
  padding: 2px 0;
  margin-bottom: 4px;
`;

export const SiteBody = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

export const SiteImage = styled.img`
  width: 120px;
  height: 120px;
  margin-right: 22px;
`;

export const SiteDataWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const SiteDataItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3px 7px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.small};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  background-color: ${({ theme }) => `${theme.colors.gray_04}60`};
  color: ${({ theme }) => theme.colors.textColor};
  margin-bottom: 8px;
  &:last-child {
    border: 0;
  }
`;

export const SiteDataItemLabel = styled.div`
  line-height: 1.9;
`;
