import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router';
import lodashIsEmpty from 'lodash/isEmpty';
import { getUrlParams, parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import { contentRefContexts } from 'utils/hooks/reactContexts';
import { getProjectFromShareableLinkAction } from 'store/swappProfile/actions/swappProfileActions';
import ShareTestFitPage from '../pages/ShareTestFitPage';
import FeasibilityPage from '../pages/FeasibilityPage';
import Header from '../components/core/Header';
import { AuthorizedWrapper, AppWrapper, Content } from './authorizedRoutes.styles';
import { loadingSelector, swappProjectSelector } from '../store/swappProfile';
import LoadingSpinner from '../components/common/LoadingSpinner';
import TestTitThumbnail from '../components/testFit/TestTitThumbnail';

const { TEST_FIT, FEASIBILITY_STUDY, thumbnail } = UI_AUTHORIZE_PATH;

const ShareRoutes = () => {
  const [contentRef, setContentRef] = useState(null);
  const swappProject = useSelector(swappProjectSelector);
  const history = useHistory();
  const isLoading = useSelector(loadingSelector);
  const locationData = parseLocationUrl(history.location);
  const params = getUrlParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (lodashIsEmpty(swappProject)) {
      dispatch(getProjectFromShareableLinkAction(locationData.projectId, params['share-key']));
    }
  }, [swappProject]);

  if (isLoading || lodashIsEmpty(swappProject)) {
    return <LoadingSpinner />;
  }

  return (
    <AuthorizedWrapper>
      {locationData.stage !== thumbnail && <Header shared />}
      <AppWrapper headless={locationData.stage === thumbnail}>
        <Content ref={setContentRef}>
          <contentRefContexts.Provider value={contentRef}>
            <Switch>
              <Route path={`/${thumbnail}/:projectId/:profileId`} component={TestTitThumbnail} />
              <Route path={`/${TEST_FIT}`} component={ShareTestFitPage} />
              <Route path={`/${FEASIBILITY_STUDY}`} component={FeasibilityPage} />
            </Switch>
          </contentRefContexts.Provider>
        </Content>
      </AppWrapper>
    </AuthorizedWrapper>
  );
};

export default ShareRoutes;
