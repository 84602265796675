import React, { useCallback, useState, useEffect, useMemo } from 'react';
import * as THREE from 'three';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { selectMarkup, useIsMarkupDragged } from 'store/markups';
import { vecAngle, vecSub, vecLength, vecMoveTowards, vecAdd, vecNormalize, vecScale } from 'utils/algorithms/algorithmHelpers';
import { MeshLineRaycast } from 'threejs-meshline';
import { shadeHexColor } from 'utils/helpers/threeHelpers';
import { Circle } from '@react-three/drei';
import { setEditorType } from 'store/editor';
import { changeCursor } from '../helpers/SelectionHelpers';
import { Z_INDEX } from './constants';

const SectionLineGraphic = ({ id, transform, color, lineWidth, triangleSize, isSelected, isViewOnly, type }) => {
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();
  const { points } = transform;
  const isAnyMarkupBeingDragged = useIsMarkupDragged();

  const onLinesClicked = useCallback((event) => {
    if (isViewOnly) {
      return;
    }
    event.stopPropagation();
    dispatch(selectMarkup({ id }));
    dispatch(setEditorType(type));
  }, [points, isViewOnly]);

  const onPointerOver = useCallback((event) => {
    if (isViewOnly) {
      return;
    }
    if (!isSelected && !isAnyMarkupBeingDragged) {
      event.stopPropagation();
      changeCursor('pointer');
      setIsHovered(true);
    }
  }, [points, isSelected, isAnyMarkupBeingDragged, setIsHovered, isViewOnly]);

  const onPointerOut = useCallback(() => {
    if (!isSelected && !isAnyMarkupBeingDragged) {
      changeCursor('crosshair');
      setIsHovered(false);
    }
  }, [points, isSelected, isAnyMarkupBeingDragged, setIsHovered]);

  useEffect(() => {
    if (!isSelected && isHovered) {
      setIsHovered(false);
    }
  }, [isSelected]);

  const wightedColor = (isHovered && !isSelected) ? shadeHexColor(color, -0.2) : color;
  const length = vecLength(vecSub(points[0], points[1]));
  const dashProps = { dashArray: 0.4 / length, dashRatio: 0.4, transparent: true };

  const innerPoints = useMemo(() => [
    vecMoveTowards(points[0], points[1], triangleSize + 0.8),
    vecMoveTowards(points[1], points[0], triangleSize + 0.8),
  ], [points]);

  const arrowPoints = useMemo(() => {
    const direction = vecNormalize(vecSub(points[1], points[0]));
    const perpen = [-direction[1], direction[0]];
    return innerPoints.map((point) => vecAdd(point, vecScale(perpen, 0.2)));
  }, [innerPoints]);

  return (
    <>
      <mesh
        raycast={MeshLineRaycast}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        onPointerUp={onLinesClicked}
        position={[0, 0, 1]}
      >
        <meshLine attach="geometry" vertices={innerPoints.map((v) => new THREE.Vector3(...v))} />
        <meshLineMaterial attach="material" color={wightedColor} lineWidth={lineWidth} {...dashProps} />
      </mesh>
      <Circle
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        onPointerUp={onLinesClicked}
        args={[triangleSize, 3]}
        position={[...arrowPoints[0], Z_INDEX.GRAPHICS]}
        rotation={[0, 0, vecAngle(vecSub(points[1], points[0])) + (Math.PI / 2)]}
      >
        <meshBasicMaterial attach="material" color={wightedColor} />
      </Circle>
      <Circle
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        onPointerUp={onLinesClicked}
        args={[triangleSize, 3]}
        position={[...arrowPoints[1], Z_INDEX.GRAPHICS]}
        rotation={[0, 0, vecAngle(vecSub(points[0], points[1])) + -(Math.PI / 2)]}
      >
        <meshBasicMaterial attach="material" color={wightedColor} />
      </Circle>
    </>
  );
};

SectionLineGraphic.propTypes = {
  id: PropTypes.string,
  isSelected: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  transform: PropTypes.object,
  color: PropTypes.string,
  lineWidth: PropTypes.number,
  triangleSize: PropTypes.number,
  type: PropTypes.string,
};

export default React.memo(SectionLineGraphic);
