import React from 'react';

export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" />
    <path d="M31.716 7.59259H8.12674C5.46618 7.59259 3.29639 9.76231 3.29639 12.4229V23.2729C3.29639 25.9335 5.46611 28.1033 8.12674 28.1033H15.091C15.091 29.1889 14.916 30.6586 14.2858 32.1979C14.0055 32.8979 14.216 33.7032 14.7752 34.1927C15.1252 34.4729 15.5108 34.6124 15.9305 34.6124C16.2449 34.6124 16.5252 34.5427 16.8055 34.3677C19.6055 32.7571 21.9858 30.098 23.5608 28.0677H31.7162C34.3767 28.0677 36.5465 25.8979 36.5465 23.2373V12.3873C36.5465 9.7623 34.3768 7.59265 31.7162 7.59265L31.716 7.59259ZM35.1108 23.2729C35.1108 25.1282 33.6055 26.6677 31.716 26.6677H23.2107C22.966 26.6677 22.7554 26.7729 22.616 26.9479C21.4265 28.5585 18.9767 31.5336 16.1053 33.1426C15.9303 33.2479 15.7908 33.1426 15.7197 33.107C15.65 33.0715 15.5447 32.932 15.6144 32.7215C16.4539 30.5859 16.5592 28.6268 16.4894 27.3322C16.4894 26.9466 16.1394 26.6322 15.7539 26.6322H8.12632C6.27104 26.6322 4.7316 25.1269 4.7316 23.2375V12.3875C4.7316 10.5322 6.23688 8.99274 8.12632 8.99274H31.7156C33.5709 8.99274 35.1103 10.498 35.1103 12.3875L35.1108 23.2729Z" fill="currentcolor" />
    <path d="M28.9856 14.3479H10.8567C10.4711 14.3479 10.1211 14.6624 10.1211 15.0835C10.1211 15.5032 10.4355 15.8191 10.8567 15.8191H29.021C29.4065 15.8191 29.7565 15.5046 29.7565 15.0835C29.7565 14.6624 29.4065 14.3479 28.9854 14.3479H28.9856Z" fill="currentcolor" />
    <path d="M28.9856 19.7729H10.8567C10.4711 19.7729 10.1211 20.0874 10.1211 20.5085C10.1211 20.8941 10.4355 21.2441 10.8567 21.2441H29.021C29.4065 21.2441 29.7565 20.9296 29.7565 20.5085C29.721 20.0874 29.4065 19.7729 28.9854 19.7729H28.9856Z" fill="currentcolor" />
  </svg>
);
