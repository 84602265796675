import styled from 'styled-components';

export const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 0)};
  width: 100%;
`;

export const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primaryColor};
  font-size: 50px;
  margin-bottom: 15px;
`;

export const Title = styled.div`
  justify-content: center;
  display: flex;
  margin-bottom: 4px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primaryColor};
  font-weight: ${({ theme }) => theme.font.weight.bold}; 
  font-size: ${({ theme }) => theme.font.size.large};
`;

export const SubTitle = styled.div`
  font-size: ${({ theme }) => theme.font.size.medium};
  justify-content: center;
  display: flex;
  text-align: center;
  margin-bottom: 30px;
`;
