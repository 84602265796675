import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const HeatLegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HeatLegendRow = styled.div`
  width: 100%;
  min-height: 20px;
  display: flex;
  flex-direction: column;
  margin: 0;
`;

const ColorCell = styled.div`
  display: flex;
  margin-bottom: 5px;
  line-height: 12px;
  font-size: ${({ theme }) => theme.font.size.small};
`;

export const ColorPill = styled.div`
  height: 12px;
  width: 20px;
  border-radius: ${({ theme }) => theme.other.borderRadius};
  background-color: ${({ color }) => color};
  margin-right: 5px;
  border: 1px solid ${({ theme }) => theme.colors.gray_04};
`;

const HeatLegend = (props) => {
  const { activeKey, colorSets } = props;
  if (!activeKey) {
    return null;
  }

  const renderColorCells = (colorSet) => colorSet.map((cell, index) => (
    <ColorCell key={index}>
      <ColorPill color={cell.color} />
      {cell.value}
    </ColorCell>
  ));

  return (
    <HeatLegendWrapper>
      {colorSets[activeKey].map((colorSet, index) => <HeatLegendRow key={index}>{renderColorCells(colorSet)}</HeatLegendRow>)}
    </HeatLegendWrapper>
  );
};

HeatLegend.propTypes = {
  activeKey: PropTypes.string,
  colorSets: PropTypes.object,
};

export default HeatLegend;
