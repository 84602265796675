import lodashIsNumber from 'lodash/isNumber';
import lodashIsFinite from 'lodash/isFinite';
import { inverseLerp } from '../algorithms/algorithmHelpers';

export const numberWithComma = (number, options) => {
  const currentNumber = Number(number);

  // eslint-disable-next-line no-restricted-globals
  if (!lodashIsNumber(currentNumber) || number === undefined || number === null || isNaN(currentNumber) || !lodashIsFinite(currentNumber)) {
    return 0;
  }

  const newOptions = { fixed: 3, suffix: '', prefix: '', ...options };
  return newOptions.prefix + parseFloat(Number(currentNumber).toFixed(newOptions.fixed)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + newOptions.suffix;
};

export const numFormatter = (num, digits = 2) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'K' },
    { value: 1E6, symbol: 'M' },
    { value: 1E9, symbol: 'G' },
    { value: 1E12, symbol: 'T' },
    { value: 1E15, symbol: 'P' },
    { value: 1E18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  // for negative value is work
  for (i = si.length - 1; i > 0; i--) {
    if (Math.abs(num) >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
};

export const areSliderValuesClose = (minRange, maxRange, currentMinRange, currentMaxRange, gapRange) => {
  const t1 = inverseLerp(minRange, maxRange, currentMinRange);
  const t2 = inverseLerp(minRange, maxRange, currentMaxRange);
  return (t2 - t1) < gapRange;
};

export const isValidURL = (str) => {
  const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
      + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
      + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
      + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
      + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
      + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
};

export const renderProjectName = (name, showFullName) => {
  if (showFullName) {
    return name;
  }

  // if the project name has the string '_jobqueuesuffix' in the project will work with the Demo Algo server
  // this will split the text so everything after this string will not get rendered to the user. e.g. 'project name_jobqueuesuffix_demo' = 'project name'
  return name.split('_jobqueuesuffix')[0];
};
