import React from 'react';

export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.9407 28.1188C19.1626 28.1188 18.3876 28.0032 17.6376 27.775C14.7626 26.9032 12.5532 24.4313 12.0096 21.4782C11.4565 18.472 12.6752 15.3422 15.1158 13.5046C16.5049 12.4593 18.244 11.8828 20.0158 11.8828C21.2971 11.8828 22.5658 12.1843 23.6877 12.7562C26.383 14.1328 28.1253 16.975 28.1253 19.9998C28.1253 22.1795 27.2472 24.231 25.6503 25.7762C24.1128 27.2653 22.0316 28.1184 19.9411 28.1184L19.9407 28.1188ZM20.0157 13.1344C18.5142 13.1344 17.0407 13.6219 15.8673 14.5047C13.8017 16.061 12.7704 18.7095 13.2376 21.2531C13.697 23.7516 15.5673 25.8423 18 26.5795C20.3422 27.2904 22.9892 26.6108 24.7796 24.878C26.1312 23.5717 26.875 21.8389 26.875 20C26.875 17.4406 25.4 15.0344 23.1187 13.872C22.1734 13.3892 21.1 13.1344 20.0156 13.1344L20.0157 13.1344Z" fill="currentcolor" />
    <path d="M6.25 20.6248H3.75C3.40469 20.6248 3.125 20.3451 3.125 19.9998C3.125 19.6545 3.40469 19.3748 3.75 19.3748H6.25C6.59531 19.3748 6.875 19.6545 6.875 19.9998C6.875 20.3451 6.59531 20.6248 6.25 20.6248Z" fill="currentcolor" />
    <path d="M20 36.8752C19.6547 36.8752 19.375 36.5955 19.375 36.2502V33.7502C19.375 33.4049 19.6547 33.1252 20 33.1252C20.3453 33.1252 20.625 33.4049 20.625 33.7502V36.2502C20.625 36.5955 20.3453 36.8752 20 36.8752Z" fill="currentcolor" />
    <path d="M36.25 20.6248H33.75C33.4047 20.6248 33.125 20.3451 33.125 19.9998C33.125 19.6545 33.4047 19.3748 33.75 19.3748H36.25C36.5953 19.3748 36.875 19.6545 36.875 19.9998C36.875 20.3451 36.5953 20.6248 36.25 20.6248Z" fill="currentcolor" />
    <path d="M20 6.8752C19.6547 6.8752 19.375 6.59551 19.375 6.2502V3.7502C19.375 3.40489 19.6547 3.1252 20 3.1252C20.3453 3.1252 20.625 3.40489 20.625 3.7502V6.2502C20.625 6.59551 20.3453 6.8752 20 6.8752Z" fill="currentcolor" />
    <path d="M19.925 31.8656C18.8625 31.8656 17.8062 31.7203 16.7875 31.4344C10.9343 29.7922 7.21546 23.8156 8.32346 17.8264C9.3469 12.2968 14.3827 8.12636 20.0375 8.12636C20.3906 8.12636 20.7484 8.14198 21.0999 8.17479C27.1419 8.73103 31.8751 13.9248 31.8751 19.9996C31.8751 21.4527 31.6158 22.8699 31.1048 24.2152C29.3657 28.7916 24.8736 31.8652 19.9252 31.8652L19.925 31.8656ZM20.0375 9.3764C14.9751 9.3764 10.4671 13.1076 9.55307 18.0544C8.56087 23.4136 11.8874 28.7624 17.1251 30.2308C18.0344 30.4855 18.9766 30.6152 19.9251 30.6152C24.3579 30.6152 28.3815 27.8652 29.9375 23.7716C30.3937 22.5685 30.625 21.2997 30.625 19.9997C30.625 14.5637 26.3906 9.91689 20.9858 9.42009C20.6717 9.39041 20.353 9.37634 20.0374 9.37634L20.0375 9.3764Z" fill="currentcolor" />
  </svg>
);
