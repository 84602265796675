import React from 'react';
import { useSelector } from 'react-redux';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import { useHistory } from 'react-router';
import { feasibilityReportSelector } from 'store/swappProfile/selectors';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import {
  VerticalTitleContentWrapper, ContentTitle,
  ContentImagesWrapper, ContentImagesWithLabelWrapper,
  ContentImage, ContentImageLabel,
} from '../ReportPages.styles';

const AerialView = () => {
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const reportData = useSelector((state) => feasibilityReportSelector(state, locationData.profileId));
  const siteAerialViewData = lodashGet(reportData, 'site.siteAerialViewData');

  return (
    <VerticalTitleContentWrapper id="site/aerial-view">
      <ContentTitle>{T.translate('Aerial View')}</ContentTitle>
      <ContentImagesWrapper width={500}>

        {/* ======== images ======== */}
        {lodashGet(siteAerialViewData, 'images', []).map((image, index) => (
          <ContentImagesWithLabelWrapper key={index}>
            <ContentImage src={image.url} width={520} height={230} />
            {lodashGet(image, 'label') && <ContentImageLabel>{image.label}</ContentImageLabel>}
          </ContentImagesWithLabelWrapper>
        ))}
      </ContentImagesWrapper>
    </VerticalTitleContentWrapper>
  );
};

export default React.memo(AerialView);
