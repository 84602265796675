import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PropTypes from 'prop-types';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import ComparePage from '../components/testFit/compare/ComparePage';
import StudyPage from '../components/testFit/study/StudyPage';

const {
  compare, studies,
} = UI_AUTHORIZE_PATH;

const TestFitPage = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:projectId/${studies}`} component={StudyPage} />
      <Route path={`${match.url}/:projectId/${compare}`} component={ComparePage} />
      <Redirect to={`${match.url}/:projectId/${studies}`} />
    </Switch>
  );
};

TestFitPage.propTypes = {
  match: PropTypes.object,
};

export default TestFitPage;
