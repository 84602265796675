import React from 'react';
import PropTypes from 'prop-types';
import lodashIsEmpty from 'lodash/isEmpty';
import ExtrudeMesh from './ExtrudeMesh';

const GeneralPolygons = (props) => {
  const { list } = props;

  if (lodashIsEmpty(list)) {
    return null;
  }

  return (
    list.map((polygon, index) => (
      <ExtrudeMesh
        key={polygon.label || index}
        envelope={polygon.poly.boundary}
        holes={polygon.poly.holes}
        color={`#${polygon.color}`}
        extrudeDepth={0.2}
        meshProps={{ position: [0, 0, polygon.height || 0] }}
      />
    )));
};

GeneralPolygons.propTypes = {
  list: PropTypes.array.isRequired,
};

export default GeneralPolygons;
