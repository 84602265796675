import React from 'react';
import * as THREE from 'three';
import PropTypes from 'prop-types';
// import { MeshLineRaycast } from 'threejs-meshline';

const PolyLines = ({ points, isClosed = false, color = 'white', width = 1.0, height = 1, renderOrder, onPointerOver, onClick, onPointerOut, onPointerEnter }) => {
  const vertices = points.flatMap((v) => {
    const x = v.x || v[0];
    const y = v.y || v[1];
    const z = height || v.z || v[2];

    return [new THREE.Vector3(x, y, z), new THREE.Vector3(x, y, z)];
  });
  if (isClosed) {
    const z = height || points[0].z || points[0][2];
    vertices.push(new THREE.Vector3(...points[0], z), new THREE.Vector3(...points[0], z));
  }
  // BuildingMeshes
  return (
    <mesh key="line" renderOrder={renderOrder} onPointerOver={onPointerOver} onClick={onClick} onPointerOut={onPointerOut} onPointerEnter={onPointerEnter}>
      <meshLine attach="geometry" vertices={vertices} />
      <meshLineMaterial attach="material" color={color} lineWidth={width} />
    </mesh>
  );
};

const areEqual = (prev, next) => {
  const isEqual = prev.intractable === next.intractable && prev.color === next.color && prev.isClosed === next.isClosed && prev.width === next.width && prev.height === next.height;
  // A suggestion of Dan Abramov:  https://twitter.com/dan_abramov/status/1104414272753487872
  const isPointsEqual = JSON.stringify(prev.points) === JSON.stringify(next.points);
  return (isEqual && isPointsEqual);
};

PolyLines.propTypes = {
  points: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.object,
  ])),
  isClosed: PropTypes.bool,
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  renderOrder: PropTypes.number,
  onPointerOver: PropTypes.func,
  onClick: PropTypes.func,
  onPointerOut: PropTypes.func,
  onPointerEnter: PropTypes.func,
};

export default React.memo(PolyLines, areEqual);
