import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { Form } from 'antd';
import { StyledFormItem, StyledInputAuth, PrimaryButton } from '../../styles/commonComponents.styles';
import { AUTHORIZATION_FORM } from '../../constants/fieldNames';

const SetNewPasswordForm = (props) => {
  const { onSubmit, location, history } = props;
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { password } = values;
    onSubmit({ password, resetToken: location.search.slice(12) }, history);
  };

  const compareToFirstPassword = (rule, value) => {
    if (!value || value === form.getFieldValue(AUTHORIZATION_FORM.PASSWORD)) {
      return Promise.resolve();
    }
    return Promise.reject(T.translate('AUTH_FORM_ERROR_INCONSISTENT_PASSWORD'));
  };

  return (
    <Form form={form} name="setNewPasswordForm" onFinish={onFinish}>
      <StyledFormItem
        hasFeedback
        name={AUTHORIZATION_FORM.PASSWORD}
        label={T.translate('AUTH_FORM_PASSWORD')}
        rules={[{ required: true, message: T.translate('AUTH_FORM_ERROR_NO_PASSWORD') }]}
      >
        <StyledInputAuth type="password" placeholder={T.translate('AUTH_FORM_ENTER_NEW_PASSWORD_PLACEHOLDER')} />
      </StyledFormItem>

      <StyledFormItem
        hasFeedback
        name={AUTHORIZATION_FORM.CONFIRM_PASSWORD}
        dependencies={[AUTHORIZATION_FORM.PASSWORD]}
        label={T.translate('AUTH_FORM_CONFIRM_PASSWORD')}
        rules={[
          { required: true, message: T.translate('AUTH_FORM_ERROR_CONFIRM_PASSWORD') },
          { validator: compareToFirstPassword },
        ]}
      >
        <StyledInputAuth type="password" placeholder={T.translate('AUTH_FORM_CONFIRM_NEW_PASSWORD_PLACEHOLDER')} />
      </StyledFormItem>

      <StyledFormItem>
        <PrimaryButton marginTop={16} marginBottom={20} type="submit">{T.translate('AUTH_FORM_PASSWORD_RESET')}</PrimaryButton>
      </StyledFormItem>
    </Form>
  );
};

SetNewPasswordForm.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default SetNewPasswordForm;
