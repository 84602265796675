import React, { useCallback, useState, useMemo, useEffect } from 'react';
import * as THREE from 'three';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { selectMarkup, useIsMarkupDragged } from 'store/markups';
import { vecAngle, vecSub, vecMoveTowards } from 'utils/algorithms/algorithmHelpers';
import { MeshLineRaycast } from 'threejs-meshline';
import { shadeHexColor } from 'utils/helpers/threeHelpers';
import { Circle } from '@react-three/drei';
import { changeCursor } from '../helpers/SelectionHelpers';
import { Z_INDEX } from './constants';

const ArrowMarkupGraphic = ({ id, transform, color, lineWidth, arrowHeadSize, isSelected }) => {
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();
  const { points } = transform;
  const isAnyMarkupBeingDragged = useIsMarkupDragged();

  const onLinesClicked = useCallback((event) => {
    event.stopPropagation();
    dispatch(selectMarkup({ id }));
  }, [points]);

  const onPointerOver = useCallback((event) => {
    if (!isSelected && !isAnyMarkupBeingDragged) {
      event.stopPropagation();
      changeCursor('pointer');
      setIsHovered(true);
    }
  }, [points, isSelected, isAnyMarkupBeingDragged, setIsHovered]);

  const onPointerOut = useCallback(() => {
    if (!isSelected && !isAnyMarkupBeingDragged) {
      changeCursor('crosshair');
      setIsHovered(false);
    }
  }, [points, isSelected, isAnyMarkupBeingDragged, setIsHovered]);

  const innerPoints = useMemo(() => [
    points[0],
    vecMoveTowards(points[1], points[0], arrowHeadSize + 1),
  ], [points]);

  useEffect(() => {
    if (!isSelected && isHovered) {
      setIsHovered(false);
    }
  }, [isSelected]);

  const arrowHeadPosition = useMemo(() => vecMoveTowards(innerPoints[1], innerPoints[0], Z_INDEX.GRAPHICS), [innerPoints]);

  const wightedColor = (isHovered && !isSelected) ? shadeHexColor(color, -0.2) : color;

  return (
    <>
      <mesh
        raycast={MeshLineRaycast}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        onPointerUp={onLinesClicked}
        position={[0, 0, 1]}
      >
        <meshLine attach="geometry" vertices={innerPoints.map((v) => new THREE.Vector3(...v))} />
        <meshLineMaterial attach="material" color={wightedColor} lineWidth={lineWidth} />
      </mesh>
      <Circle
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        onPointerUp={onLinesClicked}
        args={[arrowHeadSize, 3]}
        position={[...arrowHeadPosition, Z_INDEX.GRAPHICS]}
        rotation={[0, 0, vecAngle(vecSub(points[0], points[1])) + Math.PI]}
      >
        <meshBasicMaterial attach="material" color={wightedColor} />
      </Circle>
    </>
  );
};

ArrowMarkupGraphic.propTypes = {
  id: PropTypes.string,
  isSelected: PropTypes.bool,
  transform: PropTypes.object,
  color: PropTypes.string,
  lineWidth: PropTypes.number,
  arrowHeadSize: PropTypes.number,
};

export default React.memo(ArrowMarkupGraphic);
