import { camelizeKeys } from 'humps';
import lodashGet from 'lodash/get';
import lodashUniq from 'lodash/uniq';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIncludes from 'lodash/includes';
import testFitStandard from '../../constants/testFitStandards.json';

const divideSqfToFeetValue = 144;

const getParseData = () => {
  const parsedJson = JSON.parse(JSON.stringify(testFitStandard));
  return camelizeKeys(parsedJson, {
    // Don't convert keys that are only uppercase and underscore - enum values such as COMMON_DESK or ONE_PERSON
    process: (key, convert, options) => (/^[A-Z0-9_]+$/.test(key) ? key : convert(key, options)),
  });
};

export const getTestFitStandards = () => {
  const parseData = getParseData();
  const standardsKey = Object.keys(parseData.standards);
  const standardsObject = {};

  standardsKey.forEach((standardKey) => {
    // all standard categories
    const categories = lodashUniq(parseData.standards[standardKey].roomDefinitions.map((room) => room.category));

    const roomDefinitions = {};
    categories.forEach((categoryKey) => {
      const filteredRoomsByCategory = parseData.standards[standardKey].roomDefinitions.filter((room) => room.category === categoryKey);
      const regularRooms = filteredRoomsByCategory.filter((room) => !lodashIncludes(room.roomType, 'OTHER'));
      const otherRooms = filteredRoomsByCategory.filter((room) => lodashIncludes(room.roomType, 'OTHER'));

      const mergedOtherRooms = [];

      // we get several 'OTHER' type rooms and need to combine them to one object with the min 'minArea' and max 'maxArea'
      if (!lodashIsEmpty(otherRooms)) {
        // we need to split the 'OTHER' rooms by subcategory before we marge them, e.g. 'LSFA'/'LSFB'
        const otherSubCategoryTypes = lodashUniq(otherRooms.map((room) => room.subcategory));

        const margeOtherRooms = (list) => {
          let tempMergedOtherRooms = {};
          for (let i = 0; i < list.length; i++) {
            const currentOtherRoom = list[i];
            if (lodashIncludes(currentOtherRoom.tags, 'UNREQUESTABLE')) {
              // eslint-disable-next-line no-continue
              continue;
            }

            if (lodashIsEmpty(tempMergedOtherRooms)) { // if tempMergedOtherRooms is empty set the first object to it
              tempMergedOtherRooms = currentOtherRoom;
            } else { // else see if the other OTHER room has lower min or bigger max
              tempMergedOtherRooms.minArea = Math.min(tempMergedOtherRooms.minArea, currentOtherRoom.minArea);
              tempMergedOtherRooms.maxArea = Math.max(tempMergedOtherRooms.maxArea, currentOtherRoom.maxArea);
            }
          }

          mergedOtherRooms.push(tempMergedOtherRooms);
        };

        if (otherSubCategoryTypes.length >= 2) {
          for (let i = 0; i < otherSubCategoryTypes.length; i++) {
            margeOtherRooms(otherRooms.filter((room) => room.subcategory === otherSubCategoryTypes[i]));
          }
        } else {
          margeOtherRooms(otherRooms);
        }
      }

      roomDefinitions[categoryKey] = [...regularRooms, ...mergedOtherRooms].map((room, index) => {
        if (lodashIncludes(room.tags, 'UNREQUESTABLE')) {
          return;
        }

        return ({
          index,
          key: room.roomType,
          value: room.roomType,
          color: room.color,
          seats: room.seats,
          subCategory: room.subcategory,
          defaultRange: [room.minFormArea, room.maxFormArea].map((num) => num / divideSqfToFeetValue),
          minMaxRange: [room.minArea, room.maxArea].map((num) => num / divideSqfToFeetValue),
          step: room.step || 1,
        });
      }).filter((e) => e);
    });

    standardsObject[standardKey] = { roomDefinitions };
  });
  return { standards: standardsObject };
};

export const getTestFitColors = () => {
  const parseData = getParseData();
  const standardsKey = Object.keys(parseData.standards);
  const standardsObject = {};

  standardsKey.forEach((standardKey) => {
    // all standard categories
    const categories = lodashUniq(parseData.standards[standardKey].roomDefinitions.map((room) => room.category));
    const roomDefinitions = {};
    categories.forEach((categoryKey) => {
      const filteredRoomsByCategory = parseData.standards[standardKey].roomDefinitions.filter((room) => room.category === categoryKey);
      const rooms = {
        MAIN: parseData.standards[standardKey].categoryColors[categoryKey],
      };
      filteredRoomsByCategory.forEach((room) => {
        if (lodashIncludes(room.roomType, 'OTHER')) {
          rooms.OTHER = room.color.split(',');
          return;
        }

        rooms[room.roomType] = room.color;
      });
      roomDefinitions[categoryKey] = rooms;
    });

    standardsObject[standardKey] = { ...roomDefinitions };
  });
  return standardsObject;
};

export const getRoomDisplayNamesByRoomKeys = () => {
  const parseData = getParseData();
  const standardsKey = Object.keys(parseData.standards);
  const rooms = {};

  standardsKey.forEach((standardKey) => {
    parseData.standards[standardKey].roomDefinitions.forEach((room) => {
      if (room.roomType === 'OTHER') {
        return;
      }

      rooms[room.roomType] = room.displayName;
    });
  });

  return rooms;
};

export const getCirculationData = (standard) => lodashGet(getParseData(), `standards.[${standard}].miscDefinitions`);
