/* eslint-disable no-restricted-globals */
import React from 'react';
// import { useFrame } from 'react-three-fiber';
import { Text } from '@react-three/drei';

const LoadingComponent = () => {
  console.log('LoadingComponent');
  return (
    <Text
      scale={[0.2, 0.2, 0.2]}
      position={[0, 0, 0]}
      rotation={[0, 0, Math.PI / 2]}
      fontSize={2}
      letterSpacing={0.05}
      color="black"
      anchorY="middle"
      font="Roboto"
      outlineWidth={0}
    >
      Loading High Resolution...
    </Text>
  );
};

LoadingComponent.propTypes = {
};

export default React.memo(LoadingComponent);
