import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '@ant-design/icons';
import smallDropdownIcon from '../../../../styles/static/icons/comon/smallDropdownIcon';

const closedTabWidth = 15;

export const ViewerOpenPanel = styled.div`
  display: flex;
  flex-flow: ${({ isRight }) => (isRight ? 'row' : 'row-reverse')};
  background-color: rgba(251, 253, 255, 0.85);
  border-radius: ${({ theme, isOpen, isRight }) => {
    if (isOpen) {
      return theme.other.borderRadius;
    }

    return isRight ? `${theme.other.borderRadius} 0 0 ${theme.other.borderRadius}` : `0 ${theme.other.borderRadius} ${theme.other.borderRadius} 0`;
  }};
  backdrop-filter: blur(15px);
  width: ${({ isOpen, isHidden, width }) => {
    if (isHidden) {
      return 0;
    }

    if (isOpen) {
      return width ? `${width}px` : '100%';
    }

    return '20px';
  }};
  max-height: ${({ isOpen, isHidden, height }) => {
    if (isHidden) {
      return 0;
    }

    if (isOpen) {
      return height ? `${height}px` : 'unset';
    }

    return 'unset';
  }};
  overflow-x: hidden;
  flex: 1;
  margin: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
  transition: width .6s ease-in-out;
  overflow-y: hidden;
`;

const ChildrenWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ wrap }) => (wrap ? 'row' : 'column')};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'nowrap')};
  max-height: ${({ height }) => `${height}px`};
  padding: ${({ isOpen, isHidden, isRight }) => {
    if (isOpen && !isHidden) {
      return (isRight ? '15px 15px 15px 0' : '15px 0 15px 0');
    }

    return '15px 0 0 0';
  }};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity .4s ease-in-out;
`;

const CloseWrapper = styled.div`
  width: 22px;
  height: 40px;
  padding: 7px;
  z-index: 3;
`;

const CloseIcon = styled(({ isRight, isOpen, ...props }) => <Icon {...props} />)`
  cursor: pointer;
  transform: rotate(${({ isRight, isOpen }) => {
    if (isRight) {
      return `${(isOpen ? -90 : 90)}deg`;
    }
    return `${(isOpen ? 90 : -90)}deg`;
  }});
  transition: transform .6s ease-in-out;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primaryColor};
  }
`;

const ViewerUiPanel = (props) => {
  const { children, isClose, width, height, isRight, isHidden, notCollapsible, wrap } = props;
  const [isStateOpen, setStateIsOpen] = useState(true);
  const ref = useRef();

  useEffect(() => {
    setStateIsOpen(!isClose);
  }, [isClose]);

  return (
    <ViewerOpenPanel
      isHidden={isHidden}
      isRight={isRight}
      isOpen={isStateOpen}
      maxHeight={height}
      height={height}
      width={isStateOpen ? width + closedTabWidth : closedTabWidth}
      ref={ref}
    >
      {!notCollapsible && (
        <CloseWrapper onClick={() => setStateIsOpen(!isStateOpen)}>
          <CloseIcon component={smallDropdownIcon} isOpen={isStateOpen} isRight={isRight} />
        </CloseWrapper>
      )}
      <ChildrenWrapper
        isHidden={isHidden}
        isRight={isRight}
        isOpen={isStateOpen}
        height={height}
        wrap={wrap}
      >
        {children}
      </ChildrenWrapper>
    </ViewerOpenPanel>
  );
};

ViewerUiPanel.propTypes = {
  isClose: PropTypes.bool,
  isRight: PropTypes.bool,
  isHidden: PropTypes.bool,
  wrap: PropTypes.bool,
  notCollapsible: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default ViewerUiPanel;
