import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  useMarkupTransform,
  useIsMarkupSelected,
  changePoints,
  useMarkupLineWidth,
  useMarkupColor,
} from 'store/markups';
import {
  vecAdd,
  vecAngle,
  vecSub,
  vecMoveTowards,
  getPointsCenter,
  vecNormalize,
  vecDistance,
} from 'utils/algorithms/algorithmHelpers';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashGet from 'lodash/get';
import MarkupFrame from './MarkupFrame';
import TansformPoint from './TansformPoint';
import ArrowMarkupGraphic from './ArrowMarkupGraphic';
import { FRAME_OFFSET } from './constants';

const ArrowMarkup = ({ id, isViewOnly }) => {
  const dispatch = useDispatch();
  const transform = useMarkupTransform(id);
  const isSelected = useIsMarkupSelected(id);
  const lineWidth = useMarkupLineWidth(id);
  const color = useMarkupColor(id);
  const points = lodashGet(transform, 'points');
  const center = useMemo(() => getPointsCenter(points), [points]);

  const arrowHeadSize = useMemo(() => lineWidth * 2, [lineWidth]);

  const isTooClose = lodashIsEmpty(points) ? false : vecDistance(points[0], points[1]) < 1;

  const framePoints = useMemo(() => {
    if (isTooClose || lodashIsEmpty(transform)) {
      return [];
    }
    const dir = vecSub(points[0], points[1]);
    const norm = vecNormalize(dir);
    const perpen = [norm[1], -norm[0]];
    const offset = arrowHeadSize / 2 + FRAME_OFFSET;
    return [
      vecMoveTowards(points[0], vecAdd(points[0], perpen), offset),
      vecMoveTowards(points[1], vecAdd(points[1], perpen), offset),
      vecMoveTowards(points[1], vecAdd(points[1], perpen), -offset),
      vecMoveTowards(points[0], vecAdd(points[0], perpen), -offset),
    ];
  }, [points, isTooClose]);

  const onPointMoved = useCallback((event, pointIndex, newPoint) => {
    if (isViewOnly || lodashIsEmpty(transform)) {
      return;
    }
    const newPoints = [...points];
    newPoints[pointIndex] = newPoint;
    dispatch(changePoints({ id, points: newPoints, isDragged: true }));
  });

  const onFrameMoved = useCallback((event, delta) => {
    if (isViewOnly || lodashIsEmpty(transform)) {
      return;
    }
    const newPoints = points.map((point) => vecAdd(point, delta));
    dispatch(changePoints({ id, points: newPoints, isDragged: true }));
  }, [transform]);

  if (isTooClose || lodashIsEmpty(transform)) {
    return null;
  }

  return (
    <>
      <ArrowMarkupGraphic
        color={color}
        lineWidth={lineWidth}
        isSelected={isSelected}
        transform={transform}
        arrowHeadSize={arrowHeadSize}
        id={id}
      />
      {isSelected && (
      <MarkupFrame
        points={framePoints}
        center={center}
        rotation={vecAngle(vecSub(points[0], points[1]))}
        onFrameMoved={onFrameMoved}
      />
      )}
      {isSelected && points.map((p, index) => (
        <TansformPoint
          key={index}
          pointIndex={index}
          position={[...p, 20]}
          onPointMoved={onPointMoved}
          transform={transform}
          center={center}
          cursor="crosshair"
        />
      ))}
    </>
  );
};

ArrowMarkup.propTypes = {
  id: PropTypes.string,
  isViewOnly: PropTypes.bool,
};

export default React.memo(ArrowMarkup);
