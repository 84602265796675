/* eslint-disable no-restricted-globals */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Polygon, OverlayView } from 'react-google-maps';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { currentThemeSelector } from 'store/userSettings';

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
});

const LengthLabel = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  font-size: 18px;
  transform: rotate(${({ deg }) => deg}deg) translateY(${({ translate }) => (translate)}px);
  pointer: none;
  padding: 1px;
`;

const AreaLabel = styled.div`
  background-color: rgba(255,255,255,0.5);
  color: black;
  font-size: 18px;
  border: 1px solid #ccc;
  pointer: none;
  padding: 1px;
`;

const GoogleMapsPolygon = ({ setPoints, points, hideLables, polyObject, polygonRef, isEdit }) => {
  const mapPolygonDown = useRef(0);
  const currentTheme = useSelector(currentThemeSelector);

  return (
    <>
      <Polygon
        ref={polygonRef}
        path={points}
        options={{
          fillColor: currentTheme.colors.primaryColor,
          strokeColor: currentTheme.colors.primaryColor,
        }}
        onClick={(event) => {
          if (isEdit) {
            return;
          }
          if (!isNaN(event.edge)) {
            setPoints({ type: GoogleMapsPolygon.POLYGON_CLICK, event });
          }
        }}
        onMouseDown={(event) => {
          mapPolygonDown.current = event.domEvent.timeStamp;
        }}
        onMouseUp={(event) => {
          if (isEdit) {
            return;
          }
          const isDeleting = event.domEvent.timeStamp - mapPolygonDown.current < 300 && event.domEvent.button === 2;

          if (!isNaN(event.vertex) && !isDeleting) {
            setPoints({ type: GoogleMapsPolygon.POLYGON_MOUSE_UP, event });
          }
        }}
        onRightClick={(event) => {
          if (isEdit) {
            return;
          }
          if (!isNaN(event.vertex)) {
            setPoints({ type: GoogleMapsPolygon.POLYGON_RIGHT_CLICK, event });
          }
        }}
        editable={!isEdit}
        clickable={!isEdit}
      />
      {
      points?.length > 1 && !hideLables && points.map((point, index) => {
        const nextPoint = points[(index + 1) % points.length];
        const pos = window.google?.maps.geometry.spherical.interpolate(point, nextPoint, 0.5);
        const distance = Math.round(window.google?.maps.geometry.spherical.computeDistanceBetween(point, nextPoint) * 100) / 100;
        let deg = window.google?.maps.geometry.spherical.computeHeading(point, nextPoint);
        let translate;
        if (deg > 0) {
          deg -= 90;
          translate = -14;
        } else {
          deg += 90;
          translate = 14;
        }

        return (
          <OverlayView
            position={pos}
            mapPaneName={OverlayView.MAP_PANE}
            getPixelPositionOffset={getPixelPositionOffset}
          >
            <LengthLabel deg={deg} translate={translate}>
              {`${distance}m`}
            </LengthLabel>
          </OverlayView>
        );
      })
    }
      {polyObject?.area > 0 && !hideLables && (
      <OverlayView
        position={polyObject.center}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={getPixelPositionOffset}
      >
        <AreaLabel>
          {`${polyObject.area}\u33A1`}
        </AreaLabel>
      </OverlayView>
      )}
    </>
  );
};

GoogleMapsPolygon.POLYGON_CLICK = 'POLYGON_CLICK';
GoogleMapsPolygon.POLYGON_RIGHT_CLICK = 'POLYGON_RIGHT_CLICK';
GoogleMapsPolygon.POLYGON_MOUSE_UP = 'POLYGON_MOUSE_UP';

GoogleMapsPolygon.propTypes = {
  points: PropTypes.array,
  setPoints: PropTypes.func,
  hideLables: PropTypes.bool,
  isEdit: PropTypes.bool,
  polyObject: PropTypes.object,
  polygonRef: PropTypes.object,
};

export default GoogleMapsPolygon;
