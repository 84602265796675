import React from 'react';

export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.4382 2C7.29213 2 7.14607 2 7.14607 2.14575C7.14607 2.2915 7 2.43725 7 2.43725V37.5628C7 37.7085 7 37.8543 7.14607 37.8543C7.29213 37.8543 7.29213 38 7.4382 38H32.5618C32.7079 38 32.8539 38 32.8539 37.8543C33 37.7085 33 37.7085 33 37.5628V2.43725C33 2.2915 33 2.14575 32.8539 2.14575C32.7079 2.14575 32.7079 2 32.5618 2H7.4382ZM7.8764 2.87449H32.1236V37.1255H25.8427V31.004C25.8427 30.8583 25.8427 30.7126 25.6966 30.7126C25.5506 30.5668 25.5506 30.5668 25.4045 30.5668H14.7416C14.5955 30.5668 14.4494 30.5668 14.4494 30.7126C14.3034 30.8583 14.3034 30.8583 14.3034 31.004V37.2713H7.8764V2.87449ZM15.0337 31.4413H19.5618V37.2713H15.0337V31.4413ZM20.4382 31.4413H24.9663V37.2713H20.4382V31.4413Z" fill="currentcolor" />
    <path d="M12.214 12.3758C12.214 11.5123 12.4803 10.7983 13.0128 10.2337C13.552 9.66247 14.2343 9.37685 15.0597 9.37685C15.8852 9.37685 16.5642 9.66247 17.0967 10.2337C17.6359 10.7983 17.9055 11.5322 17.9055 12.4356V13.143C17.9055 13.9932 17.6392 14.7039 17.1067 15.2751C16.5808 15.8397 15.9051 16.122 15.0797 16.122C14.2609 16.122 13.5786 15.843 13.0328 15.2851C12.4869 14.7272 12.214 13.9899 12.214 13.0732V12.3758ZM13.1825 13.143C13.1825 13.7607 13.3523 14.2688 13.6918 14.6674C14.0379 15.0659 14.5006 15.2652 15.0797 15.2652C15.6455 15.2652 16.0949 15.0659 16.4277 14.6674C16.7672 14.2622 16.9369 13.7408 16.9369 13.1031V12.3758C16.9369 11.7581 16.7639 11.25 16.4177 10.8514C16.0716 10.4462 15.6189 10.2437 15.0597 10.2437C14.5072 10.2437 14.0546 10.4429 13.7018 10.8415C13.3556 11.24 13.1825 11.7714 13.1825 12.4356V13.143ZM19.5331 20.5358C19.5331 19.6723 19.7993 18.9582 20.3319 18.3936C20.8711 17.8291 21.55 17.5468 22.3688 17.5468C23.1876 17.5468 23.8666 17.8291 24.4058 18.3936C24.9516 18.9516 25.2245 19.6889 25.2245 20.6055V21.3129C25.2245 22.1764 24.955 22.8904 24.4158 23.455C23.8832 24.0196 23.2076 24.3019 22.3888 24.3019C21.57 24.3019 20.8877 24.0229 20.3418 23.465C19.8027 22.9004 19.5331 22.1664 19.5331 21.2631V20.5358ZM20.4916 21.3129C20.4916 21.9373 20.6614 22.4487 21.0009 22.8472C21.347 23.2458 21.8097 23.445 22.3888 23.445C22.9613 23.445 23.4139 23.2458 23.7468 22.8472C24.0863 22.4421 24.256 21.914 24.256 21.2631V20.5358C24.256 19.8981 24.0829 19.3867 23.7368 19.0014C23.3973 18.6095 22.9413 18.4136 22.3688 18.4136C21.8097 18.4136 21.357 18.6095 21.0108 19.0014C20.6647 19.3933 20.4916 19.918 20.4916 20.5756V21.3129ZM15.4591 22.8871L14.7103 22.4089L21.8097 11.0706L22.5585 11.5489L15.4591 22.8871Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.4382 2C7.29213 2 7.14607 2 7.14607 2.14575C7.14607 2.2915 7 2.43725 7 2.43725V37.5628C7 37.7085 7 37.8543 7.14607 37.8543C7.29213 37.8543 7.29213 38 7.4382 38H32.5618C32.7079 38 32.8539 38 32.8539 37.8543C33 37.7085 33 37.7085 33 37.5628V2.43725C33 2.2915 33 2.14575 32.8539 2.14575C32.7079 2.14575 32.7079 2 32.5618 2H7.4382ZM7.8764 2.87449H32.1236V37.1255H25.8427V31.004C25.8427 30.8583 25.8427 30.7126 25.6966 30.7126C25.5506 30.5668 25.5506 30.5668 25.4045 30.5668H14.7416C14.5955 30.5668 14.4494 30.5668 14.4494 30.7126C14.3034 30.8583 14.3034 30.8583 14.3034 31.004V37.2713H7.8764V2.87449ZM15.0337 31.4413H19.5618V37.2713H15.0337V31.4413ZM20.4382 31.4413H24.9663V37.2713H20.4382V31.4413Z" stroke="currentcolor" strokeWidth="0.2" />
    <path d="M12.214 12.3758C12.214 11.5123 12.4803 10.7983 13.0128 10.2337C13.552 9.66247 14.2343 9.37685 15.0597 9.37685C15.8852 9.37685 16.5642 9.66247 17.0967 10.2337C17.6359 10.7983 17.9055 11.5322 17.9055 12.4356V13.143C17.9055 13.9932 17.6392 14.7039 17.1067 15.2751C16.5808 15.8397 15.9051 16.122 15.0797 16.122C14.2609 16.122 13.5786 15.843 13.0328 15.2851C12.4869 14.7272 12.214 13.9899 12.214 13.0732V12.3758ZM13.1825 13.143C13.1825 13.7607 13.3523 14.2688 13.6918 14.6674C14.0379 15.0659 14.5006 15.2652 15.0797 15.2652C15.6455 15.2652 16.0949 15.0659 16.4277 14.6674C16.7672 14.2622 16.9369 13.7408 16.9369 13.1031V12.3758C16.9369 11.7581 16.7639 11.25 16.4177 10.8514C16.0716 10.4462 15.6189 10.2437 15.0597 10.2437C14.5072 10.2437 14.0546 10.4429 13.7018 10.8415C13.3556 11.24 13.1825 11.7714 13.1825 12.4356V13.143ZM19.5331 20.5358C19.5331 19.6723 19.7993 18.9582 20.3319 18.3936C20.8711 17.8291 21.55 17.5468 22.3688 17.5468C23.1876 17.5468 23.8666 17.8291 24.4058 18.3936C24.9516 18.9516 25.2245 19.6889 25.2245 20.6055V21.3129C25.2245 22.1764 24.955 22.8904 24.4158 23.455C23.8832 24.0196 23.2076 24.3019 22.3888 24.3019C21.57 24.3019 20.8877 24.0229 20.3418 23.465C19.8027 22.9004 19.5331 22.1664 19.5331 21.2631V20.5358ZM20.4916 21.3129C20.4916 21.9373 20.6614 22.4487 21.0009 22.8472C21.347 23.2458 21.8097 23.445 22.3888 23.445C22.9613 23.445 23.4139 23.2458 23.7468 22.8472C24.0863 22.4421 24.256 21.914 24.256 21.2631V20.5358C24.256 19.8981 24.0829 19.3867 23.7368 19.0014C23.3973 18.6095 22.9413 18.4136 22.3688 18.4136C21.8097 18.4136 21.357 18.6095 21.0108 19.0014C20.6647 19.3933 20.4916 19.918 20.4916 20.5756V21.3129ZM15.4591 22.8871L14.7103 22.4089L21.8097 11.0706L22.5585 11.5489L15.4591 22.8871Z" stroke="currentcolor" strokeWidth="0.2" />
  </svg>
);
