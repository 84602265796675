import React from 'react';

import PropTypes from 'prop-types';
import ApexCharts from 'react-apexcharts';
import styled from 'styled-components';

const BarChartWrapper = styled.div``;

const BarChart = (props) => {
  const { data, width, categories, yTitle } = props;

  const options = {
    chart: {
      type: 'bar',
      // height: 350,
      toolbar: {
        show: false,
      },
    },
    distributed: true,
    colors: ['#82b0ff'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '35%',
        endingShape: 'rounded',
        // distributed: true, // to have different colors for different values
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories,
    },
    yaxis: {
      title: {
        text: yTitle,
      },
    },
    // fill: {
    //   opacity: 1,
    // },
    // tooltip: {
    //   y: {
    //     formatter(val) {
    //       return `${yTitle}`;
    //     },
    //   },
    // },
  };

  return (
    <BarChartWrapper>
      <ApexCharts options={options} series={data} type="bar" width={width} />
    </BarChartWrapper>
  );
};

BarChart.propTypes = {
  data: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  width: PropTypes.string,
  yTitle: PropTypes.string,
};

export default BarChart;
