import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { Tooltip, Tabs, message, Checkbox } from 'antd';
import lodashInRange from 'lodash/inRange';
import lodashIsFinite from 'lodash/isFinite';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIncludes from 'lodash/includes';
import lodashSum from 'lodash/sum';
import lodashGet from 'lodash/get';
import {
  StyledFormItem,
  StyledTab,
  StyledInputNumber,
  RowText,
  SmallStyledTable,
  DeleteIcon,
} from 'styles/commonComponents.styles';
import { FEASIBILITY_TARGET_FORM } from 'constants/fieldNames';
import { uuidv4 } from 'utils/helpers/uuidv4';
import { FORM_INPUT_TYPE } from 'constants/dropdownKeys';
import {
  ilsMmdAreaSize,
  STANDARDS,
  getApartmentsByUnitType,
} from 'constants/feasibilityConts';
import { deleteRow, updateRow, updateTable } from 'utils/helpers/tableHelpers';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { sqmToSqf } from 'utils/helpers/unitsHelpers';
import deleteIcon from 'styles/static/icons/comon/deleteIcon';
import FeasibilityBuildingRightsAreaForm from './FeasibilityBuildingRightsAreaForm';
import Dropdown from '../../../common/Dropdown';
import AddNewRowButton from '../../../common/AddNewRowButton';
import FromToNumInput, { unitTypes } from '../../../common/FromToNumInput';
import FeasibilityBuildingRightsAreaFormIlStandard from './FeasibilityBuildingRightsAreaFormIlStandard';

const { TabPane } = Tabs;

const FeasibilityBuildingRightsForm = (props) => {
  const { form, formData, handleOnChange, disabled, unitType, isViewer } = props;
  const isPercentageTarget = lodashIsEmpty(formData[FEASIBILITY_TARGET_FORM.INPUT_TOGGLE]) || formData[FEASIBILITY_TARGET_FORM.INPUT_TOGGLE] === FORM_INPUT_TYPE.PERCENTAGE_TARGET;
  const apartmentTypes = getApartmentsByUnitType(unitType);
  const apartments = formData[FEASIBILITY_TARGET_FORM.APARTMENTS] || [];
  const isIlStandard = unitType === STANDARDS.IL.key;
  const canSelectApartmentOnes = !isIlStandard;
  const selectedApartmentsList = apartments.map((unit) => unit.apartmentType);
  const match = useRouteMatch();
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));

  useEffect(() => {
    if (lodashIsEmpty(formData)) {
      return;
    }
    updateTable(handleOnChange, form, FEASIBILITY_TARGET_FORM.APARTMENTS, updateEstimations);
  }, [
    formData[FEASIBILITY_TARGET_FORM.NIA],
    formData[FEASIBILITY_TARGET_FORM.PRIMARY_AREA],
  ]);

  // update total
  useEffect(() => {
    if (lodashIsEmpty(formData) || lodashIsEmpty(formData[FEASIBILITY_TARGET_FORM.APARTMENTS])) {
      return;
    }

    updateApartmentTotal();
  }, [formData[FEASIBILITY_TARGET_FORM.APARTMENTS]]);

  useEffect(() => {
    if (isViewer && formData[FEASIBILITY_TARGET_FORM.INPUT_TOGGLE] === FORM_INPUT_TYPE.ROOM_COUNT) {
      form.setFieldsValue({ [FEASIBILITY_TARGET_FORM.INPUT_TOGGLE]: FORM_INPUT_TYPE.PERCENTAGE_TARGET });
      handleOnChange();
    }
  }, [isViewer]);

  const getApartmentTypeIndex = (key) => apartmentTypes.findIndex((apartment) => apartment.key === key);

  const renderTotalTargetField = (target) => {
    const roundTarget = Math.round(target);

    return (
      <Tooltip title={T.translate('FEASIBILITY_BUILDING_RIGHTS_FORM_TOTAL_TOOLTIP')} placement="bottom">
        <RowText success={roundTarget === 100} error={roundTarget > 100} warning={lodashInRange(roundTarget, 1, 100)}>{numberWithComma(roundTarget, { suffix: '%' })}</RowText>
      </Tooltip>
    );
  };

  const apartmentColumns = [
    {
      title: T.translate('FEASIBILITY_APARTMENTS_FORM_APARTMENT_TYPE'),
      dataIndex: 'apartmentType',
      key: 'apartmentType',
      width: 50,
      // align: 'center',
      render: (key, rowData) => (
        <Dropdown
          dropDownData={apartmentTypes}
          selectedKey={key}
          selectedList={canSelectApartmentOnes ? selectedApartmentsList : []}
          onChange={(value) => handleTypeChange(value, rowData)}
          // replaceToInput={key === BUILDING_INFO_KEYS.OTHER}
          // inputValue={rowData.otherText}
          // onInputChange={(e) => handleTypeFreeTextChange(e, rowData)}
        />
      ),
    },
    {
      title: <Tooltip title={T.translate('GIA')}>{`${T.translate('FEASIBILITY_APARTMENTS_FORM_APARTMENT_SIZE')} ${T.translate(isImperial ? 'SQF' : 'SQM')}`}</Tooltip>,
      dataIndex: 'apartmentSize',
      key: 'apartmentSize',
      width: isIlStandard ? 80 : 40,
      align: 'center',
      render: (value, rowData) => {
        if (Array.isArray(value) && !lodashIsEmpty(rowData.apartmentSizeRange)) {
          return (
            <FromToNumInput
              value={value}
              unitType={unitTypes.metric}
              onChange={(e) => handleAreaChange(e, rowData)}
              range={rowData.apartmentSizeRange}
              step={1}
            />
          );
        }
        const apartmentSize = lodashGet(apartmentTypes, `[${getApartmentTypeIndex(rowData.apartmentType)}].size`, 0);
        return numberWithComma(isImperial ? sqmToSqf(apartmentSize) : apartmentSize, { fixed: isImperial ? 0 : 1 });
      },
    },
    {
      title: <Tooltip title={T.translate('FEASIBILITY_APARTMENTS_FORM_TARGET_TOOLTIP')}>{T.translate('FEASIBILITY_APARTMENTS_FORM_TARGET')}</Tooltip>,
      dataIndex: 'target',
      key: 'target',
      width: 40,
      align: 'center',
      render: (target, rowData) => (isPercentageTarget
        ? (
          <StyledInputNumber
            formatter={(value) => `${Number(value).toFixed(0)}%`}
            parser={(value) => value.replace('%', '')}
            min={0}
            max={100}
            height={25}
            value={target}
            onChange={(e) => handleTargetChange(e, rowData)}
          />
        )
        : `${Number(target).toFixed(0)}%`),
    },
    {
      title: <Tooltip title={T.translate('FEASIBILITY_APARTMENTS_FORM_APT_TOOLTIP')}>{T.translate('FEASIBILITY_APARTMENTS_FORM_APT')}</Tooltip>,
      dataIndex: 'numberOfApartment',
      key: 'numberOfApartment',
      width: 40,
      align: 'center',
      render: (value, rowData) => (isPercentageTarget
        ? numberWithComma(value)
        : (
          <StyledInputNumber
            min={0}
            height={25}
            value={value}
            onChange={(e) => handleNumberOfApartmentChange(e, rowData)}
          />
        )),
    },
    {
      title: T.translate(isImperial ? 'SQF' : 'SQM'),
      dataIndex: 'estimateSF',
      key: 'estimateSF',
      width: 30,
      align: 'center',
      render: (value) => numberWithComma(isImperial ? sqmToSqf(value) : value, { fixed: isImperial ? 0 : 1 }),
    },
    isIlStandard
      ? {
        title: <Tooltip title={T.translate('FEASIBILITY_BUILDING_RIGHTS_FORM_PENTHOUSE_TOOLTIP')}>{T.translate('FEASIBILITY_BUILDING_RIGHTS_FORM_PENTHOUSE')}</Tooltip>,
        dataIndex: 'isPenthouse',
        width: 10,
        align: 'center',
        render: (value, rowData) => <Checkbox onClick={(e) => handleIsPenthouseChange(e, rowData, 2)} checked={value} />,
      }
      : { width: 0 },
    {
      dataIndex: 'delete',
      width: 20,
      align: 'center',
      render: (text, rowData) => <DeleteIcon component={deleteIcon} onClick={() => deleteRow(handleOnChange, rowData.key, form, FEASIBILITY_TARGET_FORM.APARTMENTS)} />,
    },
  ];

  const totalApartmentColumns = [
    { dataIndex: 'apartmentType', key: 'apartmentType', width: 70 },
    { dataIndex: 'apartmentSize', key: 'apartmentSize', width: 80, align: 'center' },
    { dataIndex: 'target', key: 'target', width: 40, align: 'center', render: (target) => renderTotalTargetField(target) },
    { dataIndex: 'numberOfApartment', key: 'numberOfRooms', width: 40, align: 'center', render: (number) => numberWithComma(number) },
    { dataIndex: 'estimateSF', key: 'estimateSF', width: 30, align: 'center', render: (value) => numberWithComma(isImperial ? sqmToSqf(value) : value, { fixed: isImperial ? 0 : 1 }) },
    isIlStandard ? { dataIndex: 'isPenthouse', width: 40, align: 'center' } : { width: 0 },
    { dataIndex: 'delete', width: 20, align: 'center' },
  ];

  const tableInitialValue = (key) => ({
    key: uuidv4(),
    apartmentType: apartmentTypes[key].key,
    otherText: '',
    apartmentSize: apartmentTypes[key].size,
    apartmentSizeRange: apartmentTypes[key].range || [],
    isPenthouse: false,
    target: 0,
    numberOfApartment: 0,
    estimateSF: 0,
  });

  const totalTableInitialValue = [{
    key: '0',
    apartmentType: T.translate('FEASIBILITY_BUILDING_RIGHTS_FORM_TOTAL'),
    apartmentSize: '',
    target: 0,
    numberOfApartment: 0,
    estimateSF: 0,
  }];

  const handleTypeChange = (key, rowData) => {
    const newValues = {
      apartmentType: key,
      otherText: '',
      apartmentSize: apartmentTypes[getApartmentTypeIndex(key)].size,
      apartmentSizeRange: apartmentTypes[getApartmentTypeIndex(key)].range || [],
    };

    updateRow(handleOnChange, rowData, FEASIBILITY_TARGET_FORM.APARTMENTS, form, newValues, updateEstimations);
  };

  // const handleTypeFreeTextChange = (e, rowData) => {
  //   const newValues = {
  //     otherText: e.target.value,
  //   };
  //
  //   updateRow(handleOnChange, rowData, FEASIBILITY_TARGET_FORM.APARTMENTS, form, newValues);
  // };

  const handleAreaChange = (e, rowData) => {
    const newValues = {
      apartmentSize: e,
    };

    updateRow(handleOnChange, rowData, FEASIBILITY_TARGET_FORM.APARTMENTS, form, newValues, updateEstimations);
  };

  const handleIsPenthouseChange = (e, rowData, maxSelection) => {
    const tableData = form.getFieldValue(FEASIBILITY_TARGET_FORM.APARTMENTS);
    const amountOfAptWithPenthouseChecked = tableData.map((r) => r.isPenthouse).filter((f) => f).length;

    if (amountOfAptWithPenthouseChecked >= maxSelection && !rowData.isPenthouse) {
      message.warning({ content: T.translate('FEASIBILITY_BUILDING_RIGHTS_FORM_PENTHOUSE_WARNING'), key: 'isPenthouse' });
      return;
    }

    const newValues = {
      isPenthouse: !rowData.isPenthouse,
    };

    updateRow(handleOnChange, rowData, FEASIBILITY_TARGET_FORM.APARTMENTS, form, newValues, updateEstimations);
  };

  const handleTargetChange = (e, rowData) => {
    const newValues = {
      target: e,
    };

    updateRow(handleOnChange, rowData, FEASIBILITY_TARGET_FORM.APARTMENTS, form, newValues, updateEstimations);
  };

  const handleNumberOfApartmentChange = (e, rowData) => {
    const newValues = {
      numberOfApartment: e,
    };

    updateRow(handleOnChange, rowData, FEASIBILITY_TARGET_FORM.APARTMENTS, form, newValues, updateEstimations);
  };

  const addNewRow = () => {
    const nextAvailableAptIndex = apartmentTypes.findIndex((item) => !lodashIncludes(selectedApartmentsList, item.key));
    const isIndexUnknown = nextAvailableAptIndex === -1;

    if (isIndexUnknown && canSelectApartmentOnes) {
      message.warning({
        content: T.translate('FEASIBILITY_BUILDING_RIGHTS_FORM_ADD_ANOTHER_ERROR'),
        key: 'AllAvailableApartments',
      });
      return;
    }

    const currentData = form.getFieldValue(FEASIBILITY_TARGET_FORM.APARTMENTS) || [];

    form.setFieldsValue({ [FEASIBILITY_TARGET_FORM.APARTMENTS]: [
      ...currentData,
      tableInitialValue(isIndexUnknown ? apartmentTypes.length - 1 : nextAvailableAptIndex),
    ] });
    handleOnChange();
  };

  const updateEstimations = (data) => {
    const getFiniteValue = (value) => (lodashIsFinite(value) ? value : 0);
    const allRows = formData[FEASIBILITY_TARGET_FORM.APARTMENTS] || [];
    const totalNumOfApartments = lodashSum(allRows.map((item) => item.numberOfApartment));

    switch (unitType) {
      case STANDARDS.IL.key: {
        const buildableArea = isPercentageTarget ? formData[FEASIBILITY_TARGET_FORM.PRIMARY_AREA] : formData[FEASIBILITY_TARGET_FORM.PRIMARY_AREA] + (totalNumOfApartments * ilsMmdAreaSize);
        const apartmentSize = (data.apartmentSize[0] + data.apartmentSize[1]) / 2;
        const target = isPercentageTarget ? data.target : ((data.numberOfApartment * apartmentSize) * 100) / buildableArea;
        const buildableAreaByTarget = ((target / 100) * buildableArea);
        const numberOfApartment = isPercentageTarget ? Math.round(buildableAreaByTarget / (apartmentSize - ilsMmdAreaSize)) : data.numberOfApartment;
        const estimateSF = numberOfApartment * apartmentSize;

        return {
          target: getFiniteValue(target),
          estimateSF: getFiniteValue(estimateSF),
          numberOfApartment: getFiniteValue(numberOfApartment),
        };
      }
      default: {
        const buildableArea = formData[FEASIBILITY_TARGET_FORM.NIA];
        const apartmentSize = Array.isArray(data.apartmentSize) ? (data.apartmentSize[0] + data.apartmentSize[1]) / 2 : data.apartmentSize;
        const estimateSF = isPercentageTarget ? ((data.target / 100) * buildableArea) : data.numberOfApartment * apartmentSize;
        const target = isPercentageTarget ? data.target : (estimateSF * 100) / buildableArea;
        const numberOfApartment = isPercentageTarget ? Math.round(estimateSF / apartmentSize) : data.numberOfApartment;

        return {
          target: getFiniteValue(target),
          estimateSF: getFiniteValue(estimateSF),
          numberOfApartment: getFiniteValue(numberOfApartment),
        };
      }
    }
  };

  const updateApartmentTotal = () => {
    const aptData = form.getFieldValue(FEASIBILITY_TARGET_FORM.APARTMENTS);

    const target = aptData.reduce((total, current) => ({ target: Number(total.target) + Number(current.target) }), { target: 0 });
    const numberOfApartment = aptData.reduce((total, current) => ({ numberOfApartment: Number(total.numberOfApartment) + Number(current.numberOfApartment) }), { numberOfApartment: 0 });
    const estimateSF = aptData.reduce((total, current) => ({ estimateSF: Number(total.estimateSF) + Number(current.estimateSF) }), { estimateSF: 0 });

    form.setFieldsValue({ [FEASIBILITY_TARGET_FORM.TOTAL_APARTMENTS]: [
      {
        key: 'TOTAL',
        apartmentType: T.translate('FEASIBILITY_BUILDING_RIGHTS_FORM_TOTAL'),
        apartmentSize: '',
        target: Math.round(target.target) || 0,
        numberOfApartment: lodashIsFinite(numberOfApartment.numberOfApartment) ? numberOfApartment.numberOfApartment : 0,
        estimateSF: lodashIsFinite(estimateSF.estimateSF) ? Math.round(estimateSF.estimateSF) : 0,
        delete: '',
      },
    ],
    });
    handleOnChange();
  };

  const validator = () => {
    if (!lodashInRange(Math.round(lodashGet(formData, `[${FEASIBILITY_TARGET_FORM.TOTAL_APARTMENTS}][0].target`)), 99.01, 100.99)) {
      return Promise.reject(T.translate('FEASIBILITY_BUILDING_RIGHTS_FORM_TOTAL_TOOLTIP'));
    }
    return Promise.resolve();
  };

  return (
    <>
      {/* ========= Tabs ========= */}
      {!isViewer && (
        <StyledFormItem name={FEASIBILITY_TARGET_FORM.INPUT_TOGGLE} valuePropName="activeKey" initialValue={FORM_INPUT_TYPE.PERCENTAGE_TARGET}>
          <StyledTab>
            <TabPane tab={T.translate('FEASIBILITY_BUILDING_RIGHTS_FORM_PERCENTAGE_TARGET')} key={FORM_INPUT_TYPE.PERCENTAGE_TARGET} />
            <TabPane tab={T.translate('FEASIBILITY_BUILDING_RIGHTS_FORM_APARTMENTS_COUNT')} key={FORM_INPUT_TYPE.ROOM_COUNT} />
          </StyledTab>
        </StyledFormItem>
      )}

      {/* ========= Table ========= */}
      <StyledFormItem name={FEASIBILITY_TARGET_FORM.APARTMENTS} valuePropName="dataSource" initialValue={[tableInitialValue(0)]}>
        <SmallStyledTable loading={disabled} pagination={false} columns={apartmentColumns} />
      </StyledFormItem>

      {/* ========= New Unit ========= */}
      <AddNewRowButton small disabled={disabled} onClick={addNewRow} text={T.translate('FEASIBILITY_BUILDING_RIGHTS_FORM_ADD_ANOTHER')} />

      {/* ========= Total Table ========= */}
      <StyledFormItem hideError rules={[{ validator }]} name={FEASIBILITY_TARGET_FORM.TOTAL_APARTMENTS} valuePropName="dataSource" initialValue={totalTableInitialValue}>
        <SmallStyledTable loading={disabled} pagination={false} columns={totalApartmentColumns} showHeader={false} rowClassName={(row) => row.key === 'TOTAL' && 'ant-table-total-row'} />
      </StyledFormItem>

      {/* ========= Area ========= */}
      <FeasibilityBuildingRightsAreaForm formData={formData} form={form} hide={isIlStandard} isViewer={isViewer} />
      {isIlStandard && <FeasibilityBuildingRightsAreaFormIlStandard formData={formData} handleOnChange={handleOnChange} form={form} isViewer={isViewer} />}
    </>
  );
};

FeasibilityBuildingRightsForm.propTypes = {
  handleOnChange: PropTypes.func,
  form: PropTypes.object,
  formData: PropTypes.object,
  disabled: PropTypes.bool,
  isViewer: PropTypes.bool,
  unitType: PropTypes.string,
};

export default React.memo(FeasibilityBuildingRightsForm);
