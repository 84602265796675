import React from 'react';

export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.12273 12.1618C6.35896 12.1618 4.92334 10.7262 4.92334 8.96239C4.92334 7.19862 6.35896 5.763 8.12273 5.763C9.88651 5.763 11.3221 7.19862 11.3221 8.96239C11.3221 10.7262 9.88651 12.1618 8.12273 12.1618V12.1618ZM8.12273 6.89325C6.98183 6.89325 6.05211 7.82145 6.05211 8.96387C6.05211 10.1048 6.98031 11.0345 8.12273 11.0345C9.26363 11.0345 10.1934 10.1063 10.1934 8.96387C10.1934 7.82145 9.26363 6.89325 8.12273 6.89325Z" fill="currentcolor" />
    <path d="M8.46627 33.2384H7.76895C5.74239 33.2384 4.0485 31.7846 3.74025 29.7823L2.04788 18.8077C1.8671 17.6319 2.20587 16.4394 2.97912 15.5369C3.75239 14.633 4.87808 14.1135 6.06608 14.1089L10.1527 14.0983H10.1634C11.3529 14.0983 12.4801 14.6148 13.2549 15.5172C14.0327 16.4226 14.3745 17.6182 14.1922 18.7986L12.4953 29.7837C12.1869 31.7844 10.493 33.2383 8.4666 33.2383L8.46627 33.2384ZM10.1632 15.2273H10.1556L6.06895 15.238C5.20911 15.241 4.39481 15.6162 3.83727 16.2695C3.27822 16.9227 3.03363 17.7856 3.16427 18.6348L4.85664 29.6094C5.07996 31.0572 6.30595 32.1084 7.77042 32.1084H8.46773C9.93221 32.1084 11.1582 31.0587 11.3815 29.6109L13.0784 18.6258C13.2106 17.7736 12.963 16.9091 12.4009 16.2544C11.8388 15.6011 11.0245 15.2274 10.1631 15.2274L10.1632 15.2273Z" fill="currentcolor" />
    <path d="M30.8776 12.1679C29.1138 12.1679 27.6782 10.7323 27.6782 8.9685C27.6782 7.20472 29.1138 5.7691 30.8776 5.7691C32.6414 5.7691 34.077 7.20472 34.077 8.9685C34.077 10.7323 32.6414 12.1679 30.8776 12.1679V12.1679ZM30.8776 6.89935C29.7367 6.89935 28.807 7.82756 28.807 8.96997C28.807 10.1109 29.7352 11.0406 30.8776 11.0406C32.0185 11.0406 32.9482 10.1124 32.9482 8.96997C32.9482 7.82756 32.0185 6.89935 30.8776 6.89935Z" fill="currentcolor" />
    <path d="M28.8357 33.2328C27.6462 33.2328 26.5205 32.7163 25.7442 31.8139C24.9663 30.9085 24.6245 29.7129 24.8068 28.5325L26.5037 17.5474C26.8136 15.5452 28.5075 14.0928 30.5324 14.0928H31.2297C33.2563 14.0928 34.9502 15.5467 35.26 17.5489L36.9524 28.5235C37.1332 29.6994 36.7944 30.8919 36.0211 31.7943C35.2479 32.6982 34.1222 33.2178 32.9342 33.2224L28.8475 33.233H28.8354L28.8357 33.2328ZM30.5341 15.2217C29.0696 15.2217 27.8436 16.2715 27.6203 17.7208L25.9234 28.7059C25.7912 29.5581 26.0389 30.4226 26.601 31.0773C27.1615 31.729 27.9758 32.1043 28.8357 32.1043H28.8433L32.9299 32.0936C33.7897 32.0906 34.604 31.7154 35.1616 31.0621C35.7206 30.4089 35.9652 29.546 35.8346 28.6968L34.1422 17.7222C33.9189 16.2744 32.6929 15.2231 31.2284 15.2231L30.5341 15.2217Z" fill="currentcolor" />
    <path d="M19.5004 37.0017C19.189 37.0017 18.9353 36.7495 18.9353 36.4366V2.565C18.9353 2.25205 19.189 1.99988 19.5004 1.99988C19.8118 1.99988 20.0655 2.25206 20.0655 2.565V36.4381C20.064 36.7496 19.8118 37.0017 19.5004 37.0017V37.0017Z" fill="currentcolor" />
  </svg>
);
