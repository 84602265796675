import React from 'react';

// currentcolor
export default () => (
  <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M62.7405 56.7168H34.2143C33.1504 56.7168 32.2886 57.5958 32.2886 58.6811C32.2886 59.7664 33.1504 60.6454 34.2143 60.6454H62.7405C63.8045 60.6454 64.6662 59.7664 64.6662 58.6811C64.6662 57.5958 63.8045 56.7168 62.7405 56.7168V56.7168Z" fill="currentcolor" />
    <path d="M62.7405 66.5391H34.2143C33.1504 66.5391 32.2886 67.4181 32.2886 68.5034C32.2886 69.5886 33.1504 70.4676 34.2143 70.4676H62.7405C63.8045 70.4676 64.6662 69.5886 64.6662 68.5034C64.6662 67.4181 63.8045 66.5391 62.7405 66.5391V66.5391Z" fill="currentcolor" />
    <path d="M34.2142 41.0038H48.3017H48.2968C49.3608 41.0038 50.2226 40.1247 50.2226 39.0395C50.2226 37.9542 49.3608 37.0752 48.2968 37.0752H34.2143C33.1504 37.0752 32.2886 37.9542 32.2886 39.0395C32.2886 40.1247 33.1504 41.0038 34.2143 41.0038H34.2142Z" fill="currentcolor" />
    <path d="M62.7405 46.8965H34.2143C33.1504 46.8965 32.2886 47.7755 32.2886 48.8608C32.2886 49.946 33.1504 50.8251 34.2143 50.8251H62.7405C63.8045 50.8251 64.6662 49.946 64.6662 48.8608C64.6662 47.7755 63.8045 46.8965 62.7405 46.8965V46.8965Z" fill="currentcolor" />
    <path d="M27.2969 56.7168H24.5864C23.5224 56.7168 22.6606 57.5958 22.6606 58.6811C22.6606 59.7664 23.5224 60.6454 24.5864 60.6454H27.2969C28.3561 60.6454 29.2227 59.7664 29.2227 58.6811C29.2227 57.5958 28.3561 56.7168 27.2969 56.7168Z" fill="currentcolor" />
    <path d="M27.2969 66.5391H24.5864C23.5224 66.5391 22.6606 67.4181 22.6606 68.5034C22.6606 69.5886 23.5224 70.4676 24.5864 70.4676H27.2969C28.3561 70.4676 29.2227 69.5886 29.2227 68.5034C29.2227 67.4181 28.3561 66.5391 27.2969 66.5391Z" fill="currentcolor" />
    <path d="M27.2969 37.0752H24.5864C23.5224 37.0752 22.6606 37.9542 22.6606 39.0395C22.6606 40.1247 23.5224 41.0038 24.5864 41.0038H27.2969C28.3561 41.0038 29.2227 40.1247 29.2227 39.0395C29.2227 37.9542 28.3561 37.0752 27.2969 37.0752Z" fill="currentcolor" />
    <path d="M27.2969 46.8965H24.5864C23.5224 46.8965 22.6606 47.7755 22.6606 48.8608C22.6606 49.946 23.5224 50.8251 24.5864 50.8251H27.2969C28.3561 50.8251 29.2227 49.946 29.2227 48.8608C29.2227 47.7755 28.3561 46.8965 27.2969 46.8965Z" fill="currentcolor" />
    <path d="M84.6945 21.6361L64.0601 0.604021C63.699 0.2308 63.2079 0.00981831 62.6879 0H6.41713C5.32425 0.0196429 4.43836 0.908489 4.3999 2.02325V107.977C4.43842 109.091 5.32427 109.98 6.41713 110H83.3187C84.4019 109.975 85.2685 109.077 85.283 107.977V96.6821C91.9077 96.3334 97.8915 92.5276 101.108 86.6102C104.329 80.6928 104.329 73.5032 101.108 67.5858C97.8922 61.6684 91.9079 57.8625 85.283 57.5139V23.0254C85.2734 22.4999 85.0664 21.999 84.6957 21.6356L84.6945 21.6361ZM64.5802 6.76152L78.7009 21.1156H64.5802V6.76152ZM8.25145 106.071V3.92791H60.7286V23.0654C60.7431 24.1605 61.6145 25.0395 62.6881 25.0444H81.4306V57.7051C75.3115 58.6479 70.0108 62.5373 67.1891 68.1502C64.3678 73.7681 64.3678 80.427 67.1891 86.0444C70.0104 91.6573 75.311 95.5467 81.4306 96.4896V106.07L8.25145 106.071ZM99.6348 77.0976C99.6348 83.3637 95.971 89.0307 90.3285 91.5057C84.6812 93.9808 78.1336 92.7874 73.675 88.4708C69.2168 84.1543 67.7051 77.5494 69.8331 71.6666C71.9611 65.7884 77.3195 61.7616 83.4583 61.4376H83.4824C83.7424 61.4228 84.0023 61.4179 84.2623 61.4179H84.2575C92.7453 61.4277 99.6206 68.4402 99.6354 77.0983L99.6348 77.0976Z" fill="currentcolor" />
    <path d="M78.6302 74.1566C78.3221 73.7392 77.8647 73.4592 77.3543 73.3807C76.8488 73.307 76.3337 73.4396 75.9196 73.7539C75.5104 74.0731 75.2408 74.5396 75.1733 75.0602C75.1011 75.5807 75.2359 76.1062 75.5489 76.5187L79.6749 82.112V82.1169C80.3104 82.9763 81.5092 83.1531 82.3566 82.5147L92.5729 74.8343C93.4298 74.191 93.6128 72.9584 92.9773 72.0892C92.3466 71.2151 91.1429 71.0285 90.286 71.6718L81.6103 78.1981L78.6302 74.1566Z" fill="currentcolor" />
  </svg>
);
