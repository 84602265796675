import SwpPolyline from '@swapp/swappcommonjs/dist/swpProject/geometries/SwpPolyline';
import SwpPolygon from '@swapp/swappcommonjs/dist/swpProject/geometries/SwpPolygon';
import { transformGeometry } from '@swapp/swappcommonjs/dist/utils/swpProjectModel';
import SwpBuildingStory from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpBuildingStory';
import * as THREE from 'three';
import lodashGet from 'lodash/get';
import { extrudePolygon } from 'utils/algorithms/geometryBuilders';
import { sqmToSqfText } from 'utils/helpers/unitsHelpers';
import { useIsImperial } from 'store/storeHooks/useIsImperial';
import T from 'i18n-react';
import { getPolygonCentroid } from 'utils/algorithms/algorithmHelpers';

export const addStroke = (matrix, geometryData, options, list) => {
  const transformedData = transformGeometry(matrix, geometryData);
  let data;

  if (transformedData instanceof SwpPolygon) {
    // eslint-disable-next-line no-unused-vars
    const { boundary, holes } = transformedData;
    data = boundary.saveToObject();
  } else if (transformedData instanceof SwpPolyline) {
    data = transformedData.saveToObject();
  } else {
    console.log('unknown geometry data was supplied to addStroke', data);
    return;
  }

  list.push({
    boundary: data.map((element) => new THREE.Vector3(...element)),
    color: options.color || 'black',
    width: options.width || 0.1,
  });
};

export const addFill = (matrix, geometryData, options, list) => {
  const { boundary, holes } = transformGeometry(matrix, geometryData);
  if (!boundary) {
    return;
  }
  const geom = extrudePolygon(boundary.saveToObject(), holes?.map((hole) => hole.saveToObject()), options.height || 0.2, new THREE.Color(options.color || 'black'));
  list.push(geom);
};

export const addAppartmentLabel = (matrix, geometryData, appartment, labels) => {
  const transformedGeometry = transformGeometry(matrix, geometryData);
  const isImperial = !!useIsImperial();

  const mmdArea = sqmToSqfText(appartment.mmd_area, isImperial, true);
  const area = sqmToSqfText(appartment.primary_area, isImperial, true);

  const areaString = `${T.translate('FEASIBILITY_SWP_STORY_APT_AREA')}${area}`;
  const mmdAreaString = `${T.translate('FEASIBILITY_SWP_STORY_MMD_AREA')}${mmdArea}`;
  const type = `${T.translate(appartment.variant)}`;

  const string = `${type} \n${areaString} \n${mmdAreaString}`;
  const polygon = transformedGeometry.saveToObject().boundary;
  labels.push({ string, pos: getPolygonCentroid(polygon) });
};

export const getFloorByFloorNumber = (buildingData, selectedStory) => {
  const floors = buildingData.childObjects.filter((childObject) => (childObject instanceof SwpBuildingStory));
  const amountOfUndergroundFloors = floors.filter((floor) => lodashGet(floor, 'transform.position.data[2]', -3) < 0).length;

  let selectedObject = null;
  floors.forEach((floor, index) => {
    const floorNum = index - amountOfUndergroundFloors;
    if (selectedStory === floorNum) {
      selectedObject = floor;
    } else if (selectedStory < 0 || floorNum === 0) {
      // if we show underground parking floors we want to show the core of the first floor
      // see how we render in BuildingStory.js
      selectedObject = floor;
    }
  });

  return selectedObject;
};
