import React from 'react';
import PropTypes from 'prop-types';
import {
  ActionBarIconWrapper,
  ActionBarItem,
  ActionBarWrapper, StyledTabs,
  StyledText,
} from 'components/common/ResultSplitView.srtyles';
import { Tabs, Tooltip } from 'antd';
import T from 'i18n-react';
import { ArrowLeftOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import ProfileRename from 'components/common/ProfileRename';
import { PrimaryButton, SecondaryButton } from 'styles/commonComponents.styles';
import FavoriteButton from 'components/common/FavoriteButton';
import { getShareKeyString, isSharedUrl, parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import { useBeginFinishedEditContext } from 'utils/hooks/reactContexts';
import { PROJECT_STAGES } from 'constants/profileConsts';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import lodashIsEmpty from 'lodash/isEmpty';
import { useOverridableSetting } from 'store/userSettings';
import { TAB_NAMES } from 'constants/globalConst';
import { swappProjectSelector } from 'store/swappProfile';
import { useHotkeys } from 'react-hotkeys-hook';

const { TabPane } = Tabs;

const ResultSplitViewActionBar = (props) => {
  const { isExpandDataOpen, isEditModeOn, getFilteredProfilesByType, currentTab, filterTab, setCurrentTab } = props;
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const operations = useSelector((state) => state.editor.operations);
  const swappProject = useSelector((state) => swappProjectSelector(state));
  const isShared = isSharedUrl();
  const beginFinishedEditContext = useBeginFinishedEditContext();
  const isReadOnly = useOverridableSetting('readOnly', false);
  const isTestFitEditDisabled = useOverridableSetting('disabledTestFitEdit', false);
  const isEditDisabled = isReadOnly || isShared;
  const profileIndex = lodashGet(swappProject, 'projectProfiles', []).findIndex((profile) => !profile.parentProfileId);
  const parentProfile = lodashGet(swappProject, `projectProfiles[${profileIndex}]`);

  useHotkeys('*', (e) => {
    if (isEditModeOn) {
      return;
    }

    if (e.key === 'ArrowRight') {
      goToNextProfile(true);
    }
    if (e.key === 'ArrowLeft') {
      goToNextProfile(false);
    }
  }, undefined, [locationData, isEditModeOn]);

  const getProfileIndex = (id, list) => list.findIndex((profile) => profile.id === id);

  const isGoToNextProfileDisabled = (isLeft) => {
    const filteredProfilesByType = getFilteredProfilesByType(currentTab === TAB_NAMES.RESULT, true);
    const currentProfileIndex = getProfileIndex(locationData.profileId, filteredProfilesByType);

    if (isLeft && currentProfileIndex <= 0) {
      return true;
    }

    return !isLeft && currentProfileIndex >= (filteredProfilesByType.length - 1);
  };

  const handleProfileChange = (profileId) => {
    if (Number(locationData.profileId) !== Number(profileId)) {
      history.push(`/${locationData.stage}/${locationData.projectId}/${locationData.stageTab}/${profileId}/${locationData.tabSideBar}${getShareKeyString()}`);
    }
  };

  const goToNextProfile = (isLeft) => {
    if (isShared) {
      return;
    }

    const filteredProfilesByType = getFilteredProfilesByType(currentTab === TAB_NAMES.RESULT, true);
    const currentProfileIndex = getProfileIndex(locationData.profileId, filteredProfilesByType);

    if (isGoToNextProfileDisabled(isLeft)) {
      return null;
    }

    handleProfileChange(lodashGet(filteredProfilesByType, `[${isLeft ? currentProfileIndex - 1 : currentProfileIndex + 1}].id`));
  };

  let result = getFilteredProfilesByType(true);
  const favorites = getFilteredProfilesByType(false);

  if (isExpandDataOpen) {
    return (
      <ActionBarWrapper>
        <ActionBarItem>
          {!isShared && !isEditModeOn && (
          <Tooltip title={T.translate('BACK')}>
            <ActionBarIconWrapper marginRight={18} onClick={() => history.push(`/${locationData.stage}/${locationData.projectId}/${locationData.stageTab}`)}>
              <ArrowLeftOutlined />
              <StyledText size="small" marginLeft={3}>{T.translate('BACK')}</StyledText>
            </ActionBarIconWrapper>
          </Tooltip>
          )}
          <ProfileRename borderless profileId={locationData.profileId} disabled={isShared || isEditModeOn} width={330} />
        </ActionBarItem>

        <ActionBarItem>
          <PrimaryButton
            hide={isTestFitEditDisabled || isEditModeOn}
            disabled={isEditDisabled}
            marginRight={22}
            height={30}
            width={100}
            boxy
            onClick={() => beginFinishedEditContext.begin()}
          >
            {T.translate('EDIT')}
          </PrimaryButton>
          {isEditModeOn && (
          <div style={{ display: 'flex' }}>
            {/* =========== Save =========== */}
            <PrimaryButton disabled={operations?.length === 0} height={30} width={100} boxy onClick={() => beginFinishedEditContext.finish(true)}>
              {T.translate('SAVE')}
            </PrimaryButton>
            {/* =========== discard =========== */}
            <SecondaryButton height={30} width={100} marginLeft={10} boxy onClick={() => beginFinishedEditContext.finish()}>
              {T.translate('DISCARD')}
            </SecondaryButton>
          </div>
          )}
          <FavoriteButton hidden={isEditModeOn} profileId={locationData.profileId} />
          {!isShared && !isEditModeOn && (
          <>
            <Tooltip title={T.translate('PREVIOUS')}>
              <ActionBarIconWrapper disabled={isGoToNextProfileDisabled(false)} marginRight={10} marginLeft={16}>
                <LeftOutlined onClick={() => goToNextProfile(false)} />
              </ActionBarIconWrapper>
            </Tooltip>
            <Tooltip title={T.translate('NEXT')}>
              <ActionBarIconWrapper disabled={isGoToNextProfileDisabled(true)}>
                <RightOutlined onClick={() => goToNextProfile(true)} />
              </ActionBarIconWrapper>
            </Tooltip>
          </>
          )}
        </ActionBarItem>
      </ActionBarWrapper>
    );
  }

  if (lodashGet(parentProfile, 'stage') === PROJECT_STAGES[UI_AUTHORIZE_PATH.FEASIBILITY_STUDY]) {
    result = result.filter((profile) => !lodashIsEmpty(lodashGet(profile, 'result.singleOptionMassingOptions.massing_options[0].masses')));
  }

  return (
    <ActionBarWrapper>
      <ActionBarItem>
        <StyledTabs defaultActiveKey={currentTab} animated={{ inkBar: true, tabPane: true }} onChange={(key) => setCurrentTab(key, setCurrentTab)}>
          <TabPane tab={`${T.translate('RESULT_SPLIT_VIEW_RESULT')} (${result.length})`} key={TAB_NAMES.RESULT} />
          <TabPane tab={`${T.translate('RESULT_SPLIT_VIEW_FAVORITE')} (${favorites.length})`} key={TAB_NAMES.FAVORITES} />
        </StyledTabs>
      </ActionBarItem>

      <ActionBarItem marginRight={5}>
        {filterTab && filterTab()}
      </ActionBarItem>
    </ActionBarWrapper>
  );
};

ResultSplitViewActionBar.propTypes = {
  isExpandDataOpen: PropTypes.bool,
  isEditModeOn: PropTypes.bool,
  getFilteredProfilesByType: PropTypes.func,
  currentTab: PropTypes.string,
  setCurrentTab: PropTypes.func,
  filterTab: PropTypes.func,
};

export default ResultSplitViewActionBar;
