import React from 'react';

// currentcolor
export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.22828 6.42194C2.04843 6.30146 1.90806 6.13035 1.82536 5.93002C1.74275 5.7304 1.72007 5.51115 1.76249 5.29811C1.80478 5.08566 1.90918 4.89027 2.06258 4.73711L4.75122 2.04847C5.02942 1.77978 5.42873 1.67758 5.80187 1.77979C6.17469 1.88224 6.46625 2.17366 6.56869 2.5465C6.67088 2.91956 6.56899 3.31899 6.30024 3.59727L6.29782 3.59978L5.48134 4.41621H17.1636C17.555 4.41621 17.9167 4.62502 18.1123 4.96394C18.3079 5.30284 18.3079 5.72044 18.1123 6.05934C17.9167 6.39827 17.555 6.60707 17.1636 6.60707H2.83667C2.61996 6.60683 2.40811 6.54215 2.22828 6.42194Z" fill="currentcolor" />
    <path d="M2.22828 4.61343C2.04843 4.73391 1.90806 4.90502 1.82536 5.10535C1.74275 5.30497 1.72007 5.52422 1.76249 5.73726C1.80478 5.94971 1.90918 6.1451 2.06258 6.29826L4.75122 8.9869C5.02942 9.25559 5.42873 9.35779 5.80187 9.25558C6.17469 9.15313 6.46625 8.86171 6.56869 8.48887C6.67088 8.11581 6.56899 7.71638 6.30024 7.4381L6.29782 7.43559L5.48134 6.61916H17.1636C17.555 6.61916 17.9167 6.41035 18.1123 6.07143C18.3079 5.73253 18.3079 5.31493 18.1123 4.97603C17.9167 4.6371 17.555 4.4283 17.1636 4.4283H2.83667C2.61996 4.42854 2.40811 4.49322 2.22828 4.61343Z" fill="currentcolor" />
    <path d="M17.7723 14.0712C17.9522 14.1917 18.0925 14.3628 18.1752 14.5631C18.2579 14.7627 18.2805 14.982 18.2381 15.195C18.1958 15.4075 18.0914 15.6029 17.938 15.756L15.2494 18.4447C14.9712 18.7134 14.5719 18.8156 14.1987 18.7133C13.8259 18.6109 13.5344 18.3195 13.4319 17.9466C13.3297 17.5736 13.4316 17.1741 13.7004 16.8959L13.7028 16.8934L14.5193 16.0769H2.83701C2.44558 16.0769 2.08393 15.8681 1.8883 15.5292C1.69269 15.1903 1.69269 14.7727 1.8883 14.4338C2.08393 14.0949 2.44557 13.8861 2.83701 13.8861H17.1639C17.3807 13.8863 17.5925 13.951 17.7723 14.0712Z" fill="currentcolor" />
    <path d="M17.7723 15.8799C17.9522 15.7594 18.0925 15.5883 18.1752 15.388C18.2579 15.1883 18.2805 14.9691 18.2381 14.7561C18.1958 14.5436 18.0914 14.3482 17.938 14.1951L15.2494 11.5064C14.9712 11.2377 14.5719 11.1355 14.1987 11.2377C13.8259 11.3402 13.5344 11.6316 13.4319 12.0044C13.3297 12.3775 13.4316 12.7769 13.7004 13.0552L13.7028 13.0577L14.5193 13.8742H2.83701C2.44558 13.8742 2.08393 14.083 1.8883 14.4219C1.69269 14.7608 1.69269 15.1784 1.8883 15.5173C2.08393 15.8562 2.44557 16.065 2.83701 16.065H17.1639C17.3807 16.0648 17.5925 16.0001 17.7723 15.8799Z" fill="currentcolor" />
  </svg>
);
