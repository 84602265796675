export const TEST_FIT_OFFICE_TYPES = {
  LSF: {
    ONE_PERSON: 'ONE_PERSON',
    TWO_PERSONS: 'TWO_PERSONS',
    THREE_PERSONS: 'THREE_PERSONS',
    FOUR_PERSONS: 'FOUR_PERSONS',
    FIVE_PERSONS: 'FIVE_PERSONS',
    SIX_PERSONS: 'SIX_PERSONS',
    SEVEN_PERSONS: 'SEVEN_PERSONS',
    EIGHT_PERSONS: 'EIGHT_PERSONS',
    EXEC_SUITES: 'EXEC_SUITES',
    OPEN_SPACE: 'OPEN_SPACE',
    OTHER: 'OTHER',
  },
  PSF: {
    SMALL_CONFERENCE: 'SMALL_CONFERENCE',
    MEDIUM_CONFERENCE: 'MEDIUM_CONFERENCE',
    LARGE_CONFERENCE: 'LARGE_CONFERENCE',
    PHONE_ROOMS: 'PHONE_ROOMS',
    ADA_PHONE_ROOMS: 'ADA_PHONE_ROOMS',
    COPY_AREA: 'COPY_AREA',
    OTHER: 'OTHER',
  },
  ASF: {
    LOBBY_LOUNGE: 'LOBBY_LOUNGE',
    CORRIDOR: 'CORRIDOR',
    LIBRARY_CAFE: 'LIBRARY_CAFE',
    DINING: 'DINING',
    KITCHEN: 'KITCHEN',
    OTHER: 'OTHER',
  },
  FSF: {
    PACKAGE_ROOM: 'PACKAGE_ROOM',
    MDF_ROOM: 'MDF_ROOM',
    STORAGE: 'STORAGE',
    JANITORS_CLOSET: 'JANITORS_CLOSET',
    SHOWER: 'SHOWER',
    BATHROOM: 'BATHROOM',
    OTHER: 'OTHER',
  },
  OTHER: 'OTHER',
  NONE: 'NONE',
};

export const TEST_FIT_GENERAL = {
  PSF: {
    RATIO_PER_MEMBERS: 'RATIO_PER_MEMBERS',
    RATIO_PER_OFFICES: 'RATIO_PER_OFFICES',
  },
  ASF: {
    TOTAL_SF: 'TOTAL_SF',
    TOTAL_RATIO: 'RATIO',
  },
  POSITIONING: {
    CLOSE_TO: 'CLOSE_TO',
    ADJACENCY: 'ADJACENCY',
    EXTERIOR_WINDOWS: 'EXTERIOR_WINDOWS',
    FLOOR_CORNERS: 'FLOOR_CORNERS',
    BUILDING_CORE: 'BUILDING_CORE',
    ELEVATOR_ENTRANCE: 'ELEVATOR_ENTRANCE',
    AMENITY_AREA: 'AMENITY_AREA',
    RESTROOMS: 'RESTROOMS',
    FIRE_STAIRS: 'FIRE_STAIRS',
    LOCATION: 'LOCATION',
    NORTH: 'NORTH',
    SOUTH: 'SOUTH',
    EAST: 'EAST',
    WEST: 'WEST',
    SPREAD: 'SPREAD',
    EQUALLY: 'EQUALLY',
    TWO_SIDES: 'TWO_SIDES',
    CENTER: 'CENTER',
    OUTER_RING: 'OUTER_RING',
    FLOOR_SIDE: 'FLOOR_SIDE',
  },
};

export const TEST_FIT_SIMPLE_FORM = {
  OFFICE_TYPES: {
    HI_TECH: 'HI_TECH',
    COWORKING: 'COWORKING',
    FINANCE: 'FINANCE',
    BANKING: 'BANKING',
    LAW: 'LAW',
    OPEN_SPACE: 'OPEN_SPACE',
    OTHER: 'OTHER',
  },
  SPACE_EFFICIENCY: {
    TIGHT: 'TIGHT',
    BASIC: 'BASIC',
    SPACIOUS: 'SPACIOUS',
  },
};

export const FORM_INPUT_TYPE = {
  PERCENTAGE_TARGET: 'PERCENTAGE_TARGET',
  ROOM_COUNT: 'ROOM_COUNT',
};
