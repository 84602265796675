import React from 'react';

export default () => (
  <svg width="207" height="43" viewBox="0 0 207 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 30.6508V42.9112H2.592V28.6963L0 30.6508Z" fill="#EE1B2C" />
    <path d="M5.00522 27.0083V42.9112H7.59721V25.0537L5.00522 27.0083Z" fill="#EE1B2C" />
    <path d="M10.0105 23.0992V42.9112H12.6025V21.1446L10.0105 23.0992Z" fill="#EE1B2C" />
    <path d="M15.1945 11.5496V42.9112H17.7865V9.59503L15.1945 11.5496Z" fill="#EE1B2C" />
    <path d="M20.1997 7.81816V42.9111H22.7917V5.86362L20.1997 7.81816Z" fill="#EE1B2C" />
    <path d="M25.205 3.90904V42.9111H27.797V1.9545L25.205 3.90904Z" fill="#EE1B2C" />
    <path d="M43.0808 9.37846V42.9112H30.5677V0L43.0808 9.37846Z" fill="#EE1B2C" />
    <path d="M58.2752 28.7462V42.9111H45.7621V19.3677L58.2752 28.7462Z" fill="#EE1B2C" />
    <path d="M193.417 29.4387V28.8602C193.417 28.8602 197.952 28.7069 199.873 28.8383C204.315 29.1363 207.119 32.6856 206.578 36.879C206.134 40.2749 203.294 42.79 199.522 42.9565C197.645 43.0354 193.882 42.9828 193.882 42.9828H193.449C193.427 38.4681 193.426 33.9534 193.417 29.4387ZM196.452 40.1697C196.452 40.1697 198.835 40.2924 199.798 40.1697C201.932 39.898 203.37 38.2636 203.432 36.156C203.503 33.7723 202.238 32.0151 200.033 31.6252C199.065 31.4543 196.452 31.6252 196.452 31.6252C196.425 34.4719 196.424 37.323 196.452 40.1697Z" fill="#656766" />
    <path d="M195.269 15.3532C194.961 14.8799 194.645 14.4742 194.418 14.0305C193.56 12.3316 194.346 10.3201 196.153 9.65663C198.143 8.92976 200.442 9.89751 200.89 12.0358C201.181 13.4135 200.728 14.5841 199.613 15.4462L199.126 15.8857C199.126 15.8857 200.433 17.3267 200.933 17.9057C201.428 17.3436 201.881 16.8323 202.377 16.2702C202.996 16.921 203.599 17.5549 204.231 18.2184C203.709 18.8084 203.166 19.3855 202.672 19.9975C203.732 21.1471 204.751 22.3433 205.754 23.5434C204.588 23.5434 202.354 23.5434 202.354 23.5434C202.354 23.5434 201.37 22.3901 200.933 21.9457C200.933 21.9457 200.412 22.3472 200.138 22.5373C198.451 23.6952 196.678 24.0587 194.807 23.0191C192.103 21.5189 191.539 17.8719 194.538 15.7969C194.764 15.6448 195.004 15.5138 195.269 15.3532ZM197.08 21.2315C198.272 21.2315 199.34 19.9003 199.34 19.9003C198.566 18.9772 197.776 18.0666 196.99 17.1535C196.597 17.1535 195.922 17.7874 195.76 17.9691C195.136 18.5861 194.952 19.3003 195.243 19.9975C195.551 20.7413 196.285 21.2357 197.08 21.2315ZM197.644 11.7273C196.986 11.7189 196.418 12.2176 196.597 12.7881C196.751 13.2825 197.498 14.1235 197.498 14.1235C197.498 14.1235 198.404 13.6206 198.601 13.1388C198.887 12.45 198.4 11.7316 197.644 11.7273Z" fill="#656766" />
    <path d="M101.177 21.4534C101.782 20.7476 102.344 20.0886 102.915 19.4253C103.485 19.7697 104.008 20.1311 104.575 20.4117C105.746 20.9942 106.978 21.2705 108.279 20.8666C108.914 20.6668 109.264 20.2076 109.268 19.6379C109.268 19.166 109.052 18.7918 108.629 18.6132C107.95 18.3284 107.246 18.0988 106.55 17.8564C105.59 17.5248 104.592 17.2782 103.68 16.853C102.081 16.109 101.609 14.808 101.769 13.2306C101.998 10.956 103.676 9.53164 106.001 9.42109C108.101 9.23964 111.265 11.007 111.265 11.007L110.289 13.2519C109.532 12.9032 108.832 12.5376 108.101 12.2527C107.388 11.9721 106.641 11.8573 105.871 12.0486C105.223 12.2102 104.773 12.6184 104.709 13.1371C104.635 13.7196 104.903 14.2383 105.586 14.4891C106.714 14.9015 107.881 15.2161 109.026 15.5903C109.869 15.8624 110.673 16.2153 111.304 16.853C112.843 18.4049 112.471 21.4321 110.569 22.6949C109.377 23.4899 108.024 23.7025 106.615 23.6132C104.799 23.4942 103.131 22.95 101.674 21.8488C101.523 21.734 101.38 21.6149 101.177 21.4534Z" fill="#656766" />
    <path d="M85.089 9.41735L88.0138 9.43458C88.0138 9.43458 88.0138 14.7742 88.0138 17.1875C88.0138 17.9891 88.1051 18.7735 88.5179 19.4802C89.2133 20.674 90.3345 20.9627 91.6079 20.8378C92.803 20.7214 93.6591 20.1051 94.0285 18.9458C94.1676 18.5106 94.2458 18.0365 94.2502 17.5797C94.2757 14.8948 94.2502 9.41735 94.2502 9.41735H97.2011C97.2011 9.41735 97.2446 15.1103 97.2446 18.127C97.1403 20.8938 95.3323 23.0313 92.577 23.4925C90.8213 23.7855 89.1003 23.6562 87.5314 22.734C85.8886 21.7686 85.2107 19.9198 85.089 18.127C85.089 15.1103 85.089 9.41735 85.089 9.41735Z" fill="#656766" />
    <path d="M80.9284 11.417C80.3093 12.1427 79.7031 12.8556 79.0969 13.5686C76.7623 11.3194 73.8645 11.905 72.4328 13.5686C70.971 15.2703 71.1043 18.1561 72.7338 19.756C74.2042 21.1989 77.175 21.5808 79.2774 19.268C79.9052 19.913 80.5286 20.5539 81.1563 21.1989C79.7504 23.0068 76.5817 24.2247 73.4733 23.3335C70.425 22.4636 68.443 19.9682 68.2925 16.7344C68.1592 13.8232 69.9951 11.0181 72.7295 9.96135C75.5972 8.85372 79.2689 9.48604 80.9284 11.417Z" fill="#656766" />
    <path d="M112.26 28.8739H109.4V42.9111H112.26V38.7355L114.048 36.8698L118.517 42.9111H122.092L116.014 34.8264L121.824 28.8739H118.159L112.26 35.0041V28.8739Z" fill="#656766" />
    <path d="M136.303 28.8739H125.757V42.9111H136.303V40.3347H128.706V37.1363H135.32V34.471H128.706V31.5392H136.303V28.8739Z" fill="#656766" />
    <path d="M140.951 28.8739H151.676V31.5392H143.99V34.8264H150.693V37.4029H143.99V42.9111H140.951V28.8739Z" fill="#656766" />
    <path d="M159.095 28.8739H156.145V42.9111H159.095V28.8739Z" fill="#656766" />
    <path d="M182.334 28.8739H179.384V42.9111H189.484V40.157H182.334V28.8739Z" fill="#656766" />
    <path d="M85.4465 42.9111L90.3624 28.8739H87.1447L83.9271 38.7355L80.4413 28.8739H77.5812L74.2741 38.7355L70.8777 28.8739H67.6601L72.7547 42.9111H75.7936L79.1006 33.2272L82.3182 42.9111H85.4465Z" fill="#656766" />
    <path d="M119.5 9.50615H116.461V23.5433H119.5V17.8574H125.488V23.5433H128.438V9.50615H125.488V15.1921H119.5V9.50615Z" fill="#656766" />
    <path d="M133.711 9.50615V23.5433H136.661V14.4814L140.683 20.5227L144.884 14.4814V23.5433H147.744V9.50615H144.437L140.683 15.4586L137.018 9.50615H133.711Z" fill="#656766" />
    <path d="M170.089 9.50615V23.5433H173.038V14.659L179.831 23.5433H182.602V9.50615H179.563V18.4793L172.859 9.50615H170.089Z" fill="#656766" />
    <path d="M174.558 28.8739H164.011V42.9111H174.558V40.3347H166.96V37.1363H173.574V34.471H166.96V31.5392H174.558V28.8739Z" fill="#656766" />
    <path fillRule="evenodd" clipRule="evenodd" d="M90.8092 42.9111L96.7976 28.874H99.6578L105.646 42.9111H102.607L101.177 39.6924H95.0994L93.7588 42.9111H90.8092ZM98.1384 32.5165L96.2614 37.0475H100.105L98.1384 32.5165Z" fill="#656766" />
    <path fillRule="evenodd" clipRule="evenodd" d="M151.408 23.5433L157.397 9.50615H160.257L166.245 23.5433H163.206L161.776 20.3246H155.699L154.358 23.5433H151.408ZM158.737 13.1487L156.86 17.6797H160.704L158.737 13.1487Z" fill="#656766" />
  </svg>
);
