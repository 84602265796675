import lodashSum from 'lodash/sum';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashFlatten from 'lodash/flatten';
import SwpProject from '@swapp/swappcommonjs/dist/swpProject/SwpProject';
import SwpBuilding from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpBuilding';
import SwpBuildingStory from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpBuildingStory';
import SwpStoryCore from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpStoryCore';
import SwpCorridor from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpCorridor';
import SwpApartment from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpApartment';
import SwpRoom from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpRoom';
import SwpBalcony from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpBalcony';
import { getPolygonArea } from '../algorithms/algorithmHelpers';

const getObjectsTotalAreaInSqf = (array) => lodashSum(array.map((i) => getPolygonArea(i?.geom?.data.saveToObject()?.boundary)).filter((e) => e));
const getObjectAreaInSqf = (obj) => getPolygonArea(obj.geom?.data.saveToObject()?.boundary);

const UNIT_TYPES = { // mock data for now
  1: { key: 'ONE_ROOM', value: '1 Room', color: '#bce8ff' },
  2: { key: 'TWO_ROOM', value: '2 Room', color: '#a3d0ff' },
  3: { key: 'THREE_ROOM', value: '3 Room', color: '#8ab8ff' },
  4: { key: 'FOUR_ROOM', value: '4 Room', color: '#719ef8' },
  5: { key: 'FIVE_ROOM', value: '5 Room', color: '#5C89FC' },
  6: { key: 'SIX_ROOM', value: '6 Room', color: '#527ce9' },
  7: { key: 'SEVEN_ROOM', value: '7 Room', color: '#3465bf' },
};

const getCoresArea = (swpProjectData) => {
  const cores = swpProjectData.site.childrenByType(SwpStoryCore, true);
  return lodashSum(cores.map((core) => {
    const rooms = core.childrenByType(SwpRoom);
    const corridors = core.childrenByType(SwpCorridor);
    const roomsArea = getObjectsTotalAreaInSqf(rooms);
    const corridorsArea = getObjectsTotalAreaInSqf(corridors);

    return roomsArea + corridorsArea;
  }));
};

const getPrimaryArea = (swpProjectData) => {
  const stories = swpProjectData.site.childrenByType(SwpBuildingStory, true);
  const storiesArea = getObjectsTotalAreaInSqf(stories);
  const coreArea = getCoresArea(swpProjectData);

  const allMmdRooms = swpProjectData.site.childrenByType(SwpRoom, true).filter((room) => room.name === 'MMD');
  const allMmdRoomsAreaUpToThreshold = allMmdRooms.map((room) => {
    const area = getObjectAreaInSqf(room);
    // if mmd area is bigger than 9SQM(96.875SQF) we don't want to subtract it from total area
    return area >= 96.875 ? 96.875 : area;
  });

  const allBalconies = swpProjectData.site.childrenByType(SwpBalcony, true);
  const allBalconiesAreaUpToThreshold = allBalconies.map((balcony) => {
    const area = getObjectAreaInSqf(balcony);
    // if balcony area is bigger than 14SQM(150.694SQF) we don't want to subtract it from total area
    const threshold = 150.694;
    return area >= threshold ? threshold : area;
  });

  return storiesArea - coreArea - lodashSum(allMmdRoomsAreaUpToThreshold) - lodashSum(allBalconiesAreaUpToThreshold);
};

const getStoriesAmountArray = (swpBuildings) => swpBuildings.map((building) => building.childrenByType(SwpBuildingStory)?.length);

const getAllApartmentTypes = (swpProjectData) => {
  const apartments = swpProjectData.site.childrenByType(SwpApartment, true);
  const apartmentsTypes = {};
  apartments.forEach((apartment) => { // sort each apt to room count in to `apartmentsTypes`
    const units = apartment.childrenByType(SwpRoom).filter((room) => room.properties?.ddl_entry?.room_type === 'private');
    if (lodashIsEmpty(units)) {
      return;
    }

    const unitLength = 1 + units.length; // 1 is for IL-Standard as living room
    apartmentsTypes[unitLength] = [...lodashGet(apartmentsTypes, `[${unitLength}]`, []), apartment];
  });

  return apartmentsTypes;
};

const paresApartmentTypesToTableData = (swpProjectData, primaryArea) => {
  const apartmentsTypes = getAllApartmentTypes(swpProjectData);

  // unit data as the FE need it to render
  const unitData = Object.keys(apartmentsTypes).map((key) => {
    const apartments = apartmentsTypes[key];
    // we need to subtract all balconies from apartment area
    const balconies = lodashFlatten(apartments.map((apartment) => apartment.childrenByType(SwpBalcony)));
    // we need to subtract all laundryHangs rooms from apartment area
    const laundryHangs = lodashFlatten(apartments.map((apartment) => apartment.childrenByType(SwpRoom))).filter((apartment) => apartment.name === 'LaundryHang');
    const apartmentsArea = getObjectsTotalAreaInSqf(apartments);
    const balconiesArea = getObjectsTotalAreaInSqf(balconies);
    const laundryHangsArea = getObjectsTotalAreaInSqf(laundryHangs);
    const area = apartmentsArea - balconiesArea - laundryHangsArea;
    return ({
      ...UNIT_TYPES[key],
      area,
      unitAmount: apartmentsTypes[key].length,
      percentage: (area / primaryArea) * 100,
    });
  });

  // total units
  const unitDataTotal = {
    key: 'TOTAL',
    value: 'TOTAL',
    area: lodashSum(unitData.map((unit) => unit.area)),
    unitAmount: lodashSum(unitData.map((unit) => unit.unitAmount)),
    percentage: lodashSum(unitData.map((unit) => unit.percentage)),
  };

  return { unitData, unitDataTotal };
};

export const feasibilitySwpProjectModel = (data) => {
  const swpProjectData = SwpProject.loadFromSimpleObject(data);
  const swpBuildings = swpProjectData.site.childrenByType(SwpBuilding);

  // =============== storiesAmount =============== //
  const storiesAmount = getStoriesAmountArray(swpBuildings);

  // =============== core area =============== //
  const coresArea = getCoresArea(swpProjectData);

  // =============== total aria =============== //
  const primaryArea = getPrimaryArea(swpProjectData);

  // =============== units table Data =============== //
  const unitsTableData = paresApartmentTypesToTableData(swpProjectData, primaryArea);

  // =============== return object =============== //
  return {
    swpProjectData,
    coresArea,
    storiesAmount,
    storiesArea: primaryArea,
    ...unitsTableData,
  };
};
