import styled from 'styled-components';
import { PrimaryButton } from 'styles/commonComponents.styles';

export const ViewerWrapper = styled.div`
  position: relative;
  margin: 10px 18px;
  border-radius: ${({ theme }) => theme.other.borderRadius};
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.11);
  height: 400px;
`;

export const CompareItemContainer = styled.div`
  display: flex;
  position: relative;
`;

export const CompareItemWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray_01};
  border-radius: ${({ theme }) => `${theme.other.borderRadius} ${theme.other.borderRadius} 0 0`};
  margin: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 2px 2px 5px 2px rgba(0,0,0,0.15);
`;

export const Title = styled.div`
  padding: 10px 25px;
  font-size: ${({ theme }) => theme.font.size.xLarge};
  border-radius: ${({ theme, isSticky }) => (isSticky ? 0 : `${theme.other.borderRadius} ${theme.other.borderRadius} 0 0`)};
  background-color: ${({ theme }) => theme.colors.gray_01};
  box-shadow: 0 4px 10px rgba(0,0,0,${({ isSticky }) => (isSticky ? '.10' : 0)});
`;

export const SyncButtonWrapper = styled(PrimaryButton)`
  position: absolute;
  left: calc(50% - 40px);
  top: 280px;
  z-index: 5;
  background-color: ${({ theme, isSyncOn }) => (isSyncOn ? 'inherent' : theme.colors.gray_07)};
  
  &:hover {
      background-color: ${({ theme, isSyncOn }) => (isSyncOn ? 'inherent' : theme.colors.gray_06)};
  }
`;
