export const Z_INDEX = {
  POINTS: 0.4,
  DASHED_FRAME: 0.3,
  BACKGROUND: 0,
  GRAPHICS: 0.2,
  TEXT_BORDER: 0.1,
  TEXT: 0.4,
};

export const POINT_SIZE = 0.6;
export const FRAME_OFFSET = 2;

export const MARKUPS_LAYER_HEIGHT = 200;
export const ONE_MARKUP_LAYER = 1;

export const VIEWS_LINE_WIDTH = 0.175;
export const VIEWS_LINE_COLOR = '#ffffff';
export const VIEWS_LINE_DISABLED_COLOR = '#3b3b3b';
