import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashKeys from 'lodash/keys';
import { useDispatch, useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import icons from 'styles/static/icons/markups';
import { MARKUPS_TOOLS_ICONS, MARKUPS_COLORS, MAIN_TOOLS, MARKUPS_TOOLS_NAME } from 'constants/markupsConts';
import { swappProjectSelector } from 'store/swappProfile/selectors';
import {
  selectTool,
  useMarkupsConfigTool,
  useMarkupsConfigColor,
  changeColor,
  changeLineWidth,
  changeFontSize,
  useMarkupsConfigLineWidth,
  deleteMarkup,
  useSelectedMarkupsIds,
  duplicateMarkup,
} from 'store/markups';

import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import MarkupsMultiButton from './MarkupsMultiButton';
import MarkupsButton from './MarkupsButton';
import { MarkupsContainerWrapper, MarkupsSection } from './MarkupsStyles';

export const MARKUPS_WIDTH = {
  THIN: 0.1,
  NORMAL: 0.3,
  THICK: 0.5,
};

export const MARKUPS_WIDTH_ICON = {
  [MARKUPS_WIDTH.THIN]: icons.thinIcon,
  [MARKUPS_WIDTH.NORMAL]: icons.normalIcon,
  [MARKUPS_WIDTH.THICK]: icons.thickIcon,
};

const TestFitMarkupsUi = (props) => {
  const { width = 0, isMarkupsOpen } = props;

  const currentSelectedTool = useMarkupsConfigTool();
  const selectedColor = useMarkupsConfigColor(MARKUPS_COLORS.RED);
  const selectedWidth = useMarkupsConfigLineWidth(MARKUPS_WIDTH.NORMAL);
  const selectedMarkupsIds = useSelectedMarkupsIds();
  // const allMarkups = useAllMarkups();
  const locationData = parseLocationUrl(window.location);
  const swappProject = useSelector(swappProjectSelector);
  const dispatch = useDispatch();

  const profileIndex = lodashGet(swappProject, 'projectProfiles', []).findIndex((profile) => profile.id === Number(locationData.profileId));

  useEffect(() => {
    if (isMarkupsOpen) {
      dispatch(selectTool({ tool: MARKUPS_TOOLS_NAME.ARROW }));
    }
  }, [isMarkupsOpen, profileIndex]);

  const deleteSelectedMarkup = () => {
    selectedMarkupsIds.forEach((markupID) => {
      dispatch(deleteMarkup({ id: markupID }));
    });
  };

  // const deleteAllMarkup = () => {
  //   lodashKeys(allMarkups).forEach((markupID) => {
  //     dispatch(deleteMarkup({ id: markupID }));
  //   });
  // };

  const duplicateSelectedMarkup = () => {
    selectedMarkupsIds.forEach((markupID) => {
      dispatch(duplicateMarkup({ id: markupID }));
    });
  };

  const renderToolButton = (list) => list.map((tool) => (
    <MarkupsButton
      key={tool}
      icon={MARKUPS_TOOLS_ICONS[tool]}
      name={T.translate(`MARKUPS_CONTAINER_${tool}`)}
      isSelected={currentSelectedTool === tool}
      handleClick={() => {
        dispatch(selectTool({ tool }));
      }}
    />
  ));

  const renderColorsButton = () => lodashKeys(MARKUPS_COLORS).map((colorKey) => (
    <MarkupsButton
      key={colorKey}
      name={T.translate(`MARKUPS_CONTAINER_COLOR_${colorKey}`)}
      color={MARKUPS_COLORS[colorKey]}
      isSelected={MARKUPS_COLORS[colorKey] === selectedColor}
      handleClick={() => {
        dispatch(changeColor({ color: MARKUPS_COLORS[colorKey] }));
      }}
    />
  ));

  const renderWeightButton = () => {
    const buttons = lodashKeys(MARKUPS_WIDTH).map((widthKey) => (
      <MarkupsButton
        key={widthKey}
        name={T.translate(`MARKUPS_CONTAINER_WIDTH_${widthKey}`)}
        icon={MARKUPS_WIDTH_ICON[MARKUPS_WIDTH[widthKey]]}
        isSelected={MARKUPS_WIDTH[widthKey] === selectedWidth}
        handleClick={() => {
          dispatch(changeLineWidth({ lineWidth: MARKUPS_WIDTH[widthKey] }));
        }}
      />
    ));

    return (
      <MarkupsMultiButton name={T.translate('MARKUPS_CONTAINER_WEIGHT')} icon={MARKUPS_WIDTH_ICON[selectedWidth]}>
        {buttons}
      </MarkupsMultiButton>
    );
  };

  const renderFontSizeButton = () => (
    <MarkupsMultiButton name={T.translate('MARKUPS_CONTAINER_FONT_SIZE')} icon={icons.fontSizeIcon}>
      <MarkupsButton
        name={T.translate('MARKUPS_CONTAINER_FONT_SIZE_BIG')}
        icon={icons.fontSizeBigIcon}
        handleClick={() => dispatch(changeFontSize({ increase: true }))}
      />
      <MarkupsButton
        name={T.translate('MARKUPS_CONTAINER_FONT_SIZE_SMALL')}
        icon={icons.fontSizeSmallIcon}
        handleClick={() => dispatch(changeFontSize({ decrease: true }))}
      />
    </MarkupsMultiButton>
  );

  if (!isMarkupsOpen) {
    return null;
  }

  return (
    <MarkupsContainerWrapper width={width}>
      <MarkupsSection>
        {renderToolButton(MAIN_TOOLS)}
      </MarkupsSection>

      <MarkupsSection>
        <MarkupsMultiButton name={T.translate('MARKUPS_CONTAINER_COLORS')} color={selectedColor}>
          {renderColorsButton()}
        </MarkupsMultiButton>

        {currentSelectedTool === MARKUPS_TOOLS_NAME.TEXT ? renderFontSizeButton() : renderWeightButton()}

        <MarkupsButton name={T.translate('MARKUPS_CONTAINER_DELETE')} icon={icons.deleteIcon} handleClick={deleteSelectedMarkup} />
        {/* <MarkupsButton name={T.translate('MARKUPS_CONTAINER_DELETE_All')} icon={icons.deleteIcon} handleClick={deleteAllMarkup} /> */}
        <MarkupsButton name={T.translate('MARKUPS_CONTAINER_DUPLICATE')} icon={icons.duplicateIcon} handleClick={duplicateSelectedMarkup} />
        {/* <MarkupsButton name={T.translate('MARKUPS_CONTAINER_UNDO')} icon={icons.undoIcon} handleClick={() => markupsExtension.undo()} /> */}
      </MarkupsSection>
    </MarkupsContainerWrapper>
  );
};

TestFitMarkupsUi.propTypes = {
  width: PropTypes.number,
  isMarkupsOpen: PropTypes.bool,
};

export default TestFitMarkupsUi;
