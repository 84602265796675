import React from 'react';

// currentcolor
export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2.00061" y="2" width="7.02426" height="7.02426" rx="1" fill="currentcolor" />
    <rect x="2.00073" y="10.9757" width="7.02426" height="7.02426" rx="1" fill="currentcolor" />
    <rect x="10.9752" y="2" width="7.02426" height="7.02426" rx="1" fill="currentcolor" />
    <rect x="10.9752" y="10.9756" width="7.02426" height="7.02426" rx="1" fill="currentcolor" />
  </svg>
);
