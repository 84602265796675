import React, { useState } from 'react';
import T from 'i18n-react';
import { message } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { PROJECT_STAGES } from 'constants/profileConsts';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { PrimaryButton, StyledDragger } from 'styles/commonComponents.styles';
import { createNewProfileAction } from 'store/swappProfile/actions/swappProfileActions';
import { useHistory } from 'react-router';
import {
  CreateNewProfileCardIcon,
  CreateNewProfileCardItem,
  CreateNewProfileCardWrapper,
  ResultSplitViewCardWrapper,
} from './ResultSplitViewCard.styles';
import { UI_AUTHORIZE_PATH } from '../../constants/routes/ui';

const ResultSplitViewUploadCard = (props) => {
  const { height } = props;

  const [uploadingPercent, setUploadingPercent] = useState(0);
  const [isDragOver, setIsDragOver] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);

  const onChange = (info) => {
    const { status, percent } = info.file;
    setIsDragOver(false);
    if (status === 'uploading') {
      setUploadingPercent(percent);
    }

    if (status === 'done') {
      setUploadingPercent(0);
      // message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      setUploadingPercent(0);
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const request = (e) => {
    const { onProgress, onSuccess, onError, file } = e;
    const stage = PROJECT_STAGES[UI_AUTHORIZE_PATH.ONE_CLICK_BUILDING];
    const formData = new FormData();
    onProgress({ percent: 1 });
    formData.append('file', file, file.name);
    formData.append('stage', stage);
    dispatch(createNewProfileAction({ projectId: locationData.projectId, formData, stage }))
      .then(() => {
        onSuccess('ok');
      })
      .catch((error) => {
        onError(error);
      });
  };

  return (
    <ResultSplitViewCardWrapper
      onDragOver={() => setIsDragOver(true)}
      onDragLeave={() => setIsDragOver(false)}
    >
      <StyledDragger
        name="file"
        multiple={false}
        accept=".rvt, .RVT"
        showUploadList={false}
        customRequest={request}
        onChange={onChange}
      >
        <CreateNewProfileCardWrapper height={height} isUploading={isDragOver || uploadingPercent}>
          <CreateNewProfileCardIcon isDragOver={isDragOver} isUploading={uploadingPercent} />
          <CreateNewProfileCardItem isHidden={isDragOver || uploadingPercent}>{T.translate('Drop .rvt file here')}</CreateNewProfileCardItem>
          <CreateNewProfileCardItem isHidden={isDragOver || uploadingPercent}>{T.translate('or')}</CreateNewProfileCardItem>
          <CreateNewProfileCardItem isHidden={isDragOver || uploadingPercent}>
            <PrimaryButton width={200} height={32}>{T.translate('click to browse')}</PrimaryButton>
          </CreateNewProfileCardItem>
        </CreateNewProfileCardWrapper>
      </StyledDragger>
    </ResultSplitViewCardWrapper>
  );
};

ResultSplitViewUploadCard.propTypes = {
  height: PropTypes.number,
};

export default ResultSplitViewUploadCard;
