import React from 'react';
import styled from 'styled-components';
import { Form } from 'antd';
import lodashGet from 'lodash/get';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { ViewerUiPanelWrapper } from 'styles/commonComponents.styles';
import { handleOnChange } from 'utils/helpers/feasibilityReportHelpers';
import ViewerUiPanel from 'utils/swappViewer/components/ui/ViewerUiPanel';
import TestFitViewerContainer from 'utils/swappViewer/testFit/TestFitViewerContainer';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { getProfileByIdSelector, loadingSelector, swappProfileResultsSelector } from 'store/swappProfile';
import ProfileDevelopmentAppraisalPanel from './ProfileDevelopmentAppraisalPanel';

const FinancialContainerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const UiWrapper = styled.div`
  padding: 0 5px 0 27px;
`;

const FinancialContainer = () => {
  const locationData = parseLocationUrl(window.location);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const swappProfileResults = useSelector((state) => swappProfileResultsSelector(state, locationData.profileId, isImperial), shallowEqual);
  const profile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const isLoading = useSelector(loadingSelector);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleChange = () => {
    handleOnChange(dispatch, { profile, formInstance: form, isLoading, settingsType: 'testFitResult' });
  };

  return (
    <FinancialContainerWrapper>
      {/* ============= UI ============= */}
      <ViewerUiPanelWrapper position="top-left" fitContent>
        <Form name="testFitResultDataForm" form={form} onFieldsChange={handleChange}>
          <ViewerUiPanel width={550}>
            <UiWrapper>
              <ProfileDevelopmentAppraisalPanel handleChange={handleChange} data={lodashGet(swappProfileResults, 'developmentAppraisalData')} isImperial={isImperial} profile={profile} form={form} />
            </UiWrapper>
          </ViewerUiPanel>
        </Form>
      </ViewerUiPanelWrapper>

      {/* ============= Viewer ============= */}
      <TestFitViewerContainer plan={swappProfileResults?.multiBoundaryFile} profileId={locationData.profileId} />
    </FinancialContainerWrapper>
  );
};

export default FinancialContainer;
