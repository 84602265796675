import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { Form } from 'antd';
import { StyledInputAuth, StyledFormItem, PrimaryButton } from '../../styles/commonComponents.styles';
import { AUTHORIZATION_FORM } from '../../constants/fieldNames';

const LoginForm = (props) => {
  const { onSubmit, history } = props;

  const onFinish = (values) => {
    onSubmit(values, history);
  };

  return (
    <Form name="loginForm" onFinish={onFinish}>
      <StyledFormItem
        name={AUTHORIZATION_FORM.EMAIL}
        label={T.translate('AUTH_FORM_EMAIL')}
        rules={[{ required: true, message: T.translate('AUTH_FORM_ERROR_NO_EMAIL') }]}
      >
        <StyledInputAuth type="email" placeholder={T.translate('AUTH_FORM_EMAIL_PLACEHOLDER')} />
      </StyledFormItem>

      <StyledFormItem
        name={AUTHORIZATION_FORM.PASSWORD}
        label={T.translate('AUTH_FORM_PASSWORD')}
        rules={[{ required: true, message: T.translate('AUTH_FORM_ERROR_NO_PASSWORD') }]}
      >
        <StyledInputAuth type="password" placeholder={T.translate('AUTH_FORM_PASSWORD_PLACEHOLDER')} />
      </StyledFormItem>

      <StyledFormItem>
        <PrimaryButton marginTop={16} marginBottom={25} type="submit">{T.translate('AUTH_FORM_LOG_IN')}</PrimaryButton>
      </StyledFormItem>
    </Form>
  );
};

LoginForm.propTypes = {
  history: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default LoginForm;
