import styled from 'styled-components';
import { Upload } from 'antd';
import imagePlaceholder from '../../../styles/static/img/ProjectEmptyImage.png';

const imageHeight = '140px';

export const StyledPlaceholderImage = styled.div`
  width: 100%;
  height: ${imageHeight};
  background: url(${imagePlaceholder}) center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding-top: 98px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.small};
  background-color: ${({ theme }) => theme.colors.gray_02}00 !important;
`;

export const StyledUpload = styled(Upload)`
  width: auto;
  background-color: ${({ theme }) => theme.colors.gray_02}00 !important;

  &:hover {
    ${StyledPlaceholderImage} {
      color: ${({ theme }) => theme.colors.primaryColor};
    }
  }
  
  .ant-upload-select-picture-card {
    height: ${imageHeight};
    width: 123px;
    object-fit: cover;
    border: 1px solid ${({ theme }) => theme.colors.gray_06};
    border-radius: ${({ theme }) => theme.other.borderRadius};
    background-color: ${({ theme }) => theme.colors.gray_02}00 !important;

    margin-bottom: 0;
    // margin-right: 20px;
    
    .ant-upload {
      border-radius: ${({ theme }) => theme.other.borderRadius};
      padding: 0;
    }
    
    &:hover {
      border-color: ${({ theme }) => theme.colors.primaryColor};
    }
  }

  .ant-upload {
    background-color: rgba(1,1,1,0) !important;
  }
`;

export const StyledImageWrapper = styled.div`
  position: relative;
`;

export const EditPhotoIcons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50px;
  
  svg {
    transform: scale(1.2);
  }
`;

export const EditPhotoPopup = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  width: 100%;
  transition: opacity .3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.2);
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${StyledImageWrapper}:hover & {
    opacity: 1;
  }
`;

export const StyledImage = styled.img`
  height: ${imageHeight};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  width: 100%;
  object-fit: cover;
`;
