import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import reportIcon from 'styles/static/icons/feasibilityIcons/study/reportIcon';
import { getShareKeyString, parseLocationUrl } from 'utils/helpers/navigationHelpers';

const ReportCardIndicatorWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primaryColor};
  color: ${({ theme }) => theme.colors.white};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: ${({ theme }) => theme.font.size.small};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 7px;

  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`;

const ReportCardIndicator = (props) => {
  const { hidden, profileId } = props;
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);

  if (hidden) {
    return null;
  }

  const handleClick = (e) => {
    e.stopPropagation();
    history.push(`/${locationData.stage}/${locationData.projectId}/${locationData.stageTab}/${profileId}/${UI_AUTHORIZE_PATH.report}${getShareKeyString()}`);
  };

  return (
    <ReportCardIndicatorWrapper onClick={(e) => handleClick(e)}>
      <Icon component={reportIcon} />
    </ReportCardIndicatorWrapper>
  );
};

ReportCardIndicator.propTypes = {
  hidden: PropTypes.bool,
  profileId: PropTypes.number,
};

export default ReportCardIndicator;
