/* eslint-disable prefer-destructuring */
import { agent } from 'utils/helpers';
import { ESPG_PROJECTION_WKID } from './ArcGisConsts';

const blocksData = {};
const blocksParcells = {};

export const getBlockData = async (blockId, outWkid = ESPG_PROJECTION_WKID) => {
  if (!blocksData[blockId]) {
    const { data } = await agent.get(`gis/blocks/${blockId}/${outWkid}`);
    if (!data || !data[0]) {
      console.warn('The block ID might not exist');
      return;
    }
    blocksData[blockId] = data[0];
    blocksData[blockId].wkid = outWkid;
    blocksData[blockId].id = blockId;
  }
  return blocksData[blockId];
};

export const getBlockParcells = async (blockId, outWkid = ESPG_PROJECTION_WKID) => {
  if (!blocksParcells[blockId]) {
    const { data } = await agent.get(`gis/parcelsInBlock/${blockId}/${outWkid}`);
    blocksParcells[blockId] = data;
  }
  return blocksParcells[blockId];
};

export const getBlocksInBounds = async (bounds, { outputWkid, inputWkid }) => {
  const { data } = await agent.post('gis/blocksInBounds', { bounds, inputWkid, outputWkid });
  return data;
};

export const getParcellsInBounds = async (bounds, { outputWkid, inputWkid }) => {
  const { data } = await agent.post('gis/parcelsInBounds', { bounds, inputWkid, outputWkid });
  return data;
};
