import React from 'react';

// currentcolor
export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 11.25L12 3L23 11.25" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.5 13V21.5C2.5 22.6046 3.39543 23.5 4.5 23.5H11.5" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 14.5V21.5C12 22.6046 12.8954 23.5 14 23.5H19.5C20.6046 23.5 21.5 22.6046 21.5 21.5V13" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.429 18.0683C13.9758 18.3426 14.5937 18.4422 15.199 18.3535C15.8042 18.2648 16.3676 17.9921 16.8127 17.5725C17.2578 17.1528 17.5631 16.6065 17.6872 16.0075" stroke="currentcolor" strokeWidth="0.7" strokeLinecap="round" />
    <path d="M17.6317 15.7516C17.1681 15.3526 16.593 15.1057 15.9843 15.0444C15.3756 14.9832 14.7629 15.1106 14.229 15.4094C13.6952 15.7081 13.2662 16.1638 13 16.7146" stroke="currentcolor" strokeWidth="0.7" strokeLinecap="round" />
    <path d="M10.571 18.0683C10.0242 18.3426 9.4063 18.4422 8.80103 18.3535C8.19577 18.2648 7.63241 17.9921 7.18731 17.5725C6.74221 17.1528 6.4369 16.6065 6.31275 16.0075" stroke="currentcolor" strokeWidth="0.7" strokeLinecap="round" />
    <path d="M6.36831 15.7516C6.83193 15.3526 7.40705 15.1057 8.01571 15.0444C8.62437 14.9832 9.23713 15.1106 9.77095 15.4094C10.3048 15.7081 10.7338 16.1638 11 16.7146" stroke="currentcolor" strokeWidth="0.7" strokeLinecap="round" />
    <path d="M9.91445 14.7858C9.8348 14.0151 9.99404 13.2384 10.3706 12.5612C10.7472 11.884 11.3228 11.339 12.0196 11" stroke="currentcolor" strokeWidth="0.7" strokeLinecap="round" />
    <path d="M14.0335 14.7858C14.1132 14.0151 13.954 13.2384 13.5774 12.5612C13.2008 11.884 12.6251 11.339 11.9284 11" stroke="currentcolor" strokeWidth="0.7" strokeLinecap="round" />
  </svg>
);
