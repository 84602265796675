import archaeologicalIcon from './archaeologicalIcon';
import brownfieldSitesIcon from './brownfieldSitesIcon';
import buildingHeightIcon from './buildingHeightIcon';
import conservationAreaIcon from './conservationAreaIcon';
import conservationBLDGIcon from './conservationBLDGIcon';
import educationIcon from './educationIcon';
import electricalStationIcon from './electricalStationIcon';
import fiveHundredMRadiusIcon from './fiveHundredMRadiusIcon';
import healthCareIcon from './healthCareIcon';
import highQualityDesignIcon from './highQualityDesignIcon';
import highwayIcon from './highwayIcon';
import lightConstrainsIcon from './lightConstrainsIcon';
import lotBoundaryIcon from './lotBoundaryIcon';
import newAccessIcon from './newAccessIcon';
import newSupermarketEntranceIcon from './newSupermarketEntranceIcon';
import parkingAccessIcon from './parkingAccessIcon';
import parksIcon from './parksIcon';
import privateGreenIcon from './privateGreenIcon';
import railLineIcon from './railLineIcon';
import residentialHousesIcon from './residentialHousesIcon';
import retailAreaIcon from './retailAreaIcon';
import serviceAccessIcon from './serviceAccessIcon';
import southerlyAspectIcon from './southerlyAspectIcon';
import siteBoundaryIcon from './siteBoundaryIcon';
import sportIcon from './sportIcon';
import storeIcon from './storeIcon';
import strategicHousingIcon from './strategicHousingIcon';
import supermarketEntranceIcon from './supermarketEntranceIcon';
import townCentresIcon from './townCentresIcon';
import undergroundStation from './undergroundStation';
import urbanFrontageIcon from './urbanFrontageIcon';
import landmarkIcon from './landmarkIcon';
import trainStationIcon from './trainstationIcon';
import heightIcon from './heightIcon';
import publicBuildingIcon from './publicbuildingIcon';
import retailOfficesIcon from './retailOfficesIcon';
import topographySlopeIcon from './topographyslopeIcon';
import attenuationTankIcon from './attenuationTankIcon';
import floodDefencesIcon from './floodDefencesIcon';
import listedBuildingIcon from './listedBuildingIcon';
import pedestrianRouteIcon from './pedestrianRouteIcon';
import publicCycleRouteIcon from './publicCycleRouteIcon';

export default {
  archaeologicalIcon,
  brownfieldSitesIcon,
  buildingHeightIcon,
  fiveHundredMRadiusIcon,
  conservationAreaIcon,
  conservationBLDGIcon,
  educationIcon,
  electricalStationIcon,
  healthCareIcon,
  highQualityDesignIcon,
  highwayIcon,
  lightConstrainsIcon,
  lotBoundaryIcon,
  newAccessIcon,
  newSupermarketEntranceIcon,
  parkingAccessIcon,
  parksIcon,
  privateGreenIcon,
  railLineIcon,
  residentialHousesIcon,
  retailAreaIcon,
  serviceAccessIcon,
  siteBoundaryIcon,
  southerlyAspectIcon,
  sportIcon,
  storeIcon,
  strategicHousingIcon,
  supermarketEntranceIcon,
  townCentresIcon,
  undergroundStation,
  urbanFrontageIcon,
  landmarkIcon,
  trainStationIcon,
  heightIcon,
  publicBuildingIcon,
  retailOfficesIcon,
  topographySlopeIcon,
  attenuationTankIcon,
  floodDefencesIcon,
  listedBuildingIcon,
  pedestrianRouteIcon,
  publicCycleRouteIcon,
};
