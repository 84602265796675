import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import lodashIncludes from 'lodash/includes';
import { LoadingOutlined } from '@ant-design/icons';
import { BUILDING_INFO_KEYS, WAITING_FOR_KEYS } from 'constants/feasibilityConts';
import { PROFILE_STATUS } from 'constants/profileConsts';
import { PrimaryButton, StyledSlider, StyledFormItem } from 'styles/commonComponents.styles';
import { isSharedUrl, parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { swappProjectSelector, activePollingProfileIdsSelector } from 'store/swappProfile/selectors';
import { generateEnergyAnalysis, pollSwappProjectAction } from 'store/swappProfile/actions/swappProfileActions';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalContentTitle = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.xxLarge};
  margin-bottom: 20px;
`;

const ModalContentInput = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  margin-bottom: 5px;
`;

const ModalContentInputText = styled.div`
  width: 180px;
  margin-right: 30px;
  line-height: 1.9;
  font-size: ${({ theme }) => theme.font.size.large};
`;

const ModalContentPercentageText = styled.div`
  margin-left: 25px;
  line-height: 2;
`;

const sliderOptions = {
  min: 1,
  max: 99,
  step: 1,
  tooltipVisible: false,
};

const GenerateEnergyAnalysis = (props) => {
  const { form, handleChange, formData } = props;

  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const { profileId } = locationData;
  const swappProject = useSelector(swappProjectSelector);
  const activePollingProfileIds = useSelector((state) => activePollingProfileIdsSelector(state));
  const profileIndex = lodashGet(swappProject, 'projectProfiles', []).findIndex((profile) => profile.id === Number(profileId));
  const profile = lodashGet(swappProject, `projectProfiles[${profileIndex}]`);
  const isProfileLoading = lodashGet(profile, 'status') === PROFILE_STATUS.WAITING_FOR_EXTRA_DATA;
  const isWaitingFor = lodashGet(profile, `result.waitingFor[${WAITING_FOR_KEYS.COVE_TOOL}]`);
  const isLoading = isProfileLoading && isWaitingFor;
  const isShare = isSharedUrl();
  const dispatch = useDispatch();

  const minSlider = form.getFieldValue(BUILDING_INFO_KEYS.ENERGY_FORM_MIN_WWR) || lodashGet(formData, `${BUILDING_INFO_KEYS.ENERGY_FORM_MIN_WWR}`) || 20;
  const maxSlider = form.getFieldValue(BUILDING_INFO_KEYS.ENERGY_FORM_MAX_WWR) || lodashGet(formData, `${BUILDING_INFO_KEYS.ENERGY_FORM_MAX_WWR}`) || 80;

  useEffect(() => {
    if (isLoading && profileId && !lodashIncludes(activePollingProfileIds, profileId)) {
      dispatch(pollSwappProjectAction(profileId, profileId, history, WAITING_FOR_KEYS.COVE_TOOL));
    }
  }, [isLoading, profileId, activePollingProfileIds]);

  const handleAnalysisGenerate = () => {
    const data = { maxWindowToWallRatio: maxSlider / 100, minWindowToWallRatio: minSlider / 100 };
    dispatch(generateEnergyAnalysis(data, locationData.projectId, profileId, history));
  };

  return (
    <FormWrapper>
      <ModalContentTitle>{T.translate('GENERATE_ENERGY_ANALYSIS_TITLE')}</ModalContentTitle>

      {/* ================ MIN ================ */}
      <ModalContentInput>
        <ModalContentInputText>
          {T.translate('GENERATE_ENERGY_ANALYSIS_MIN')}
        </ModalContentInputText>
        <StyledFormItem name={BUILDING_INFO_KEYS.ENERGY_FORM_MIN_WWR} initialValue={20}>
          <StyledSlider
            {...sliderOptions}
            disabled={isShare}
            onChange={(e) => {
              if (e > maxSlider) {
                form.setFieldsValue({ [BUILDING_INFO_KEYS.ENERGY_FORM_MAX_WWR]: e });
                handleChange();
              }
            }}
          />
        </StyledFormItem>
        <ModalContentPercentageText>{`${minSlider}%`}</ModalContentPercentageText>
      </ModalContentInput>

      {/* ================ MAX ================ */}
      <ModalContentInput>
        <ModalContentInputText>
          {T.translate('GENERATE_ENERGY_ANALYSIS_MAX')}
        </ModalContentInputText>
        <StyledFormItem name={BUILDING_INFO_KEYS.ENERGY_FORM_MAX_WWR} initialValue={80}>
          <StyledSlider
            {...sliderOptions}
            disabled={isShare}
            onChange={(e) => {
              if (e < minSlider) {
                form.setFieldsValue({ [BUILDING_INFO_KEYS.ENERGY_FORM_MIN_WWR]: e });
                handleChange();
              }
            }}
          />
        </StyledFormItem>
        <ModalContentPercentageText>{`${maxSlider}%`}</ModalContentPercentageText>
      </ModalContentInput>
      <div>
        <PrimaryButton
          width={250}
          center
          marginTop={15}
          marginBottom={15}
          disabled={isLoading || isShare}
          onClick={handleAnalysisGenerate}
        >
          {isLoading ? <LoadingOutlined spin /> : T.translate('GENERATE_ENERGY_ANALYSIS_GENERATE')}
        </PrimaryButton>
      </div>
    </FormWrapper>
  );
};

GenerateEnergyAnalysis.propTypes = {
  form: PropTypes.object,
  handleChange: PropTypes.func,
  formData: PropTypes.object,
};

export default GenerateEnergyAnalysis;
