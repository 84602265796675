import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setEditorType } from 'store/editor';
import EditPanelButton from './EditPanelButton';
import EditPanelMultiButtons from './EditPanelMultiButtons';

const EditPanelContainerWrapper = styled.div`
  position: absolute;
  top: ${({ landscape }) => (landscape ? 'unset' : '15px')};
  bottom: ${({ landscape }) => (landscape ? '15px' : 'unset')};
  right: ${({ landscape, offset }) => (landscape ? `calc(50% - ${offset}px)` : '15px')}; // the 172px is half of the <BoxWrapper /> width in 'TestFitViewerContainerUi'
  width: 58px;
  display: flex;
  flex-direction: ${({ landscape }) => (landscape ? 'row' : 'column')};
  z-index: 3;
  cursor: default;
`;

const Section = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray_01};
  display: flex;
  flex-direction: ${({ landscape }) => (landscape ? 'row' : 'column')};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  padding: 8px 5px;
  margin-bottom: ${({ landscape }) => (landscape ? '0' : '5px')};
  margin-right: ${({ landscape }) => (landscape ? '5px' : '0')};
  box-shadow: 0 0 8px 5px rgba(0, 0, 0, 0.12);
`;

const EditPanelContainer = (props) => {
  const { buttonsArray, landscape, wrapperStyle, offset = 0, selected, setSelected } = props;
  const selectedButton = selected || useSelector((state) => state.editor.editorType);
  const dispatch = useDispatch();

  const setSelectedButton = (id) => {
    if (setSelected) {
      setSelected(id);
      return;
    }
    dispatch(setEditorType(id));
  };

  const buttonsOptions = { selectedButton, setSelectedButton, landscape };
  const filteredButtonsArray = buttonsArray.map((buttonGroup) => buttonGroup.filter((button) => !button.hidden));

  return (
    <EditPanelContainerWrapper offset={offset} width={0} landscape={landscape} style={wrapperStyle}>
      {filteredButtonsArray.map((section, sectionIdx) => (
        <Section key={`Section${sectionIdx}`} landscape={landscape}>
          {section.map((item) => {
            if (item.subButtons && item.subButtons.find((e) => !e.hidden)) {
              const filteredSubItems = item.subButtons.filter((button) => !button.hidden);
              return (
                <EditPanelMultiButtons key={item.id} {...item} {...buttonsOptions}>
                  {filteredSubItems.map((subItem) => <EditPanelButton key={subItem.id} {...buttonsOptions} {...subItem} landscape={!landscape} />)}
                </EditPanelMultiButtons>
              );
            }

            return <EditPanelButton key={item.id} {...buttonsOptions} {...item} />;
          })}
        </Section>
      ))}
    </EditPanelContainerWrapper>
  );
};

EditPanelContainer.propTypes = {
  buttonsArray: PropTypes.array.isRequired,
  landscape: PropTypes.bool,
  wrapperStyle: PropTypes.object,
  offset: PropTypes.number,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
};

export default EditPanelContainer;
