import React from 'react';

export default () => (
  <svg width="90" height="90" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.4832 12.07V1H1V12.5727L8.5086 15.3041" stroke="#FFFFFF" strokeWidth="1.32482" strokeMiterlimit="10" />
    <path d="M10.1697 15.7984C10.1872 15.5947 10.0362 15.4154 9.83252 15.3979C9.62884 15.3804 9.44954 15.5313 9.43204 15.735C9.41454 15.9387 9.56547 16.118 9.76915 16.1355C9.97283 16.153 10.1522 16.0021 10.1697 15.7984Z" fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="0.623443" strokeMiterlimit="10" />
    <path d="M12.0188 16.4888C12.0363 16.2851 11.8854 16.1058 11.6817 16.0883C11.478 16.0708 11.2987 16.2218 11.2812 16.4254C11.2637 16.6291 11.4146 16.8084 11.6183 16.8259C11.822 16.8434 12.0013 16.6925 12.0188 16.4888Z" fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="0.623443" strokeMiterlimit="10" />
    <path d="M13.8718 17.1831C13.8893 16.9794 13.7384 16.8001 13.5347 16.7826C13.331 16.7651 13.1517 16.916 13.1342 17.1197C13.1167 17.3234 13.2677 17.5027 13.4713 17.5202C13.675 17.5377 13.8543 17.3867 13.8718 17.1831Z" fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="0.623443" strokeMiterlimit="10" />
    <path d="M1 15.3845V26.4545H26.4832V14.8818L18.9747 12.1465" stroke="#FFFFFF" strokeWidth="1.32482" strokeMiterlimit="10" />
    <path d="M18.049 11.7202C18.0665 11.5165 17.9156 11.3372 17.7119 11.3197C17.5082 11.3022 17.3289 11.4531 17.3114 11.6568C17.2939 11.8605 17.4449 12.0398 17.6485 12.0573C17.8522 12.0748 18.0315 11.9238 18.049 11.7202Z" fill="white" stroke="#FFFFFF" strokeWidth="0.623443" strokeMiterlimit="10" />
    <path d="M16.1994 11.0298C16.2169 10.8262 16.0659 10.6468 15.8622 10.6293C15.6586 10.6118 15.4793 10.7628 15.4618 10.9665C15.4443 11.1701 15.5952 11.3494 15.7989 11.3669C16.0026 11.3844 16.1819 11.2335 16.1994 11.0298Z" fill="white" stroke="#FFFFFF" strokeWidth="0.623443" strokeMiterlimit="10" />
    <path d="M14.3464 10.3375C14.3639 10.1338 14.213 9.95443 14.0093 9.93693C13.8056 9.91943 13.6263 10.0704 13.6088 10.2741C13.5913 10.4778 13.7422 10.6571 13.9459 10.6746C14.1496 10.6921 14.3289 10.5411 14.3464 10.3375Z" fill="white" stroke="#FFFFFF" strokeWidth="0.623443" strokeMiterlimit="10" />
  </svg>
);
