import PropTypes from 'prop-types';
import React, { useMemo, useState, useRef } from 'react';
import { useDrag } from 'react-use-gesture';
import * as THREE from 'three';
import { useThree } from 'react-three-fiber';
import { useSelector } from 'react-redux';
import { currentThemeSelector } from 'store/userSettings';
import { getOrientedBoundingBox, projectPointToLine, vecAdd, vecDistance, vecLerp, vecMoveTowards, vecSub } from '../../algorithms/algorithmHelpers';
import { notifyDragFinished } from '../helpers/ThreeHelpers';
import { changeCursor } from '../helpers/SelectionHelpers';

const POINTS_EDITOR_HEIGHT = 50;
export const POINTS_EDITOR_SHAPE_TYPE = {
  NONE: -1,
  RECTANGLE: 0,
};

const EditablePoint = (props) => {
  const { uuid, color, point, onPointMoved } = props;
  const { camera } = useThree();

  const position = [...point, POINTS_EDITOR_HEIGHT + 1];
  const mesh = useRef();

  const bindDrag = useDrag(
    ({ delta: [mx, my], event }) => {
      if (event) event.stopPropagation();
      if (mesh.current) {
        // The object could be rotated. Therefore we need to answer the question:
        // "what movement in local coordinates would cause the object to move in global coordinates like the mouse delta?"
        // This is achieved by multiplying the mouse movement by the inverse of the global rotation.
        if (event.buttons === 1) {
          const globalQuaternion = new THREE.Quaternion();
          mesh.current.getWorldQuaternion(globalQuaternion);
          globalQuaternion.invert();
          const deltaVector = new THREE.Vector3(mx / camera.zoom, -my / camera.zoom, 0);
          deltaVector.applyQuaternion(globalQuaternion);
          mx = deltaVector.x;
          my = deltaVector.y;
          const newPoint = [point[0] + mx, point[1] + my];
          onPointMoved(uuid, newPoint);
        }
        if (event.buttons === 0) {
          notifyDragFinished();
        }
      }
    },
    { pointerEvents: true },
  );

  return (
    <mesh ref={mesh} position={position} {...bindDrag()} onPointerOver={() => changeCursor('all-scroll')} onPointerOut={() => changeCursor('default')}>
      <planeBufferGeometry attach="geometry" args={[3, 3]} />
      <meshBasicMaterial attach="material" color={color} />
    </mesh>
  );
};

EditablePoint.propTypes = {
  uuid: PropTypes.number,
  color: PropTypes.string,
  point: PropTypes.array,
  onPointMoved: PropTypes.func,
};

const normalizeRectangleEditPoints = (points, rectangleDepth) => {
  const [v1, v2, pointOnLine2] = points;
  const pointOnLine1 = projectPointToLine(pointOnLine2, v1, v2, false).point;
  const distanceToLine2 = vecSub(pointOnLine2, pointOnLine1);
  const midPoint = vecLerp(v1, v2, 0.5);
  const v3 = vecAdd(midPoint, distanceToLine2);
  if (rectangleDepth) {
    points[2] = vecMoveTowards(midPoint, v3, rectangleDepth);
  } else {
    points[2] = v3;
  }
};

const getEditablePoints = (points, shapeType) => {
  switch (shapeType) {
    case POINTS_EDITOR_SHAPE_TYPE.RECTANGLE:
      const rectangleEditPoints = getOrientedBoundingBox(points).slice(0, 3);
      normalizeRectangleEditPoints(rectangleEditPoints);
      return rectangleEditPoints.map((p, idx) => ({ point: p, color: idx === 2 ? 'orange' : 'red' }));
    default:
      return [...points].map((p) => ({ point: p, color: 'red' }));
  }
};

const getRenderPoints = (editablePoints, shapeType) => {
  switch (shapeType) {
    case POINTS_EDITOR_SHAPE_TYPE.RECTANGLE:
    {
      // The geometric idea : two points define a segment of the rectangle, the 3rd points defines a point on the opposite segment.
      // This is well-defined, and if a triangle exists with those three points on it, it will be the one that this calculation results in.
      const [v1, v2, pointOnLine2] = editablePoints;
      const pointOnLine1 = projectPointToLine(pointOnLine2, v1, v2, false).point;
      const distanceToLine2 = vecSub(pointOnLine2, pointOnLine1);
      const v3 = vecAdd(v2, distanceToLine2);
      const v4 = vecAdd(v1, distanceToLine2);
      const polygon = [v1, v2, v3, v4, v1];
      return polygon;
    }
    default:
      return [...editablePoints];
  }
};

const handlePointMoved = (points, shapeType, idx, newPoint) => {
  const newPoints = [...points.slice(0, idx), newPoint, ...points.slice(idx + 1)];
  switch (shapeType) {
    case POINTS_EDITOR_SHAPE_TYPE.RECTANGLE:
    {
      let rectangleDepth;
      if (idx !== 2) {
        const oldMidpoint = vecLerp(points[0], points[1], 0.5);
        rectangleDepth = vecDistance(oldMidpoint, points[2]);
      }
      normalizeRectangleEditPoints(newPoints, rectangleDepth);
      break;
    }
    default:
      break;
  }
  return newPoints;
};

const PointsEditor = (props) => {
  const { initialPoints, onPointsChanged, shapeType } = props;

  const pointObjects = useMemo(() => getEditablePoints(initialPoints, shapeType), [initialPoints]);
  const [points, setPoints] = useState(pointObjects.map((p) => (p.point)));
  const currentTheme = useSelector(currentThemeSelector);

  const handleMovePoint = (idx, point) => {
    const newPoints = handlePointMoved(points, shapeType, idx, point);
    setPoints(newPoints);
    const pointsForHandler = getRenderPoints(newPoints, shapeType);
    onPointsChanged(pointsForHandler);
  };

  const editablePoints = [];
  points.forEach((point, idx) => {
    editablePoints.push(<EditablePoint key={idx.toString()} uuid={idx} color={pointObjects[idx].color} point={point} onPointMoved={handleMovePoint} />);
  });

  const pointsForLine = getRenderPoints(points, shapeType);
  const lineObject = (
    <mesh key="line">
      <meshLine attach="geometry" vertices={pointsForLine.map((v) => new THREE.Vector3(...v, POINTS_EDITOR_HEIGHT))} />
      <meshLineMaterial attach="material" color={currentTheme.colors.primaryColor} lineWidth={0.5} />
    </mesh>
  );
  return [...editablePoints, lineObject];
};

PointsEditor.propTypes = {
  onPointsChanged: PropTypes.func,
  initialPoints: PropTypes.array,
  shapeType: PropTypes.number,
};

export default PointsEditor;
