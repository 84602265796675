/* eslint-disable no-continue */
/* eslint-disable no-restricted-globals */
import * as THREE from 'three';
import { Modal } from 'antd';
import { geom } from 'jsts';
import { MINIMUM_EDGES_LENGTH, MINIMUM_MIDPOINTS_LENGTH, WARNING_COLOR, MAIN_COLOR, SNAPPED_POINTER_ANGLE } from './constants';
import {
  vecLerp,
  vecSub,
  roundNumber,
  vecNormalize,
  vecMoveTowards,
  vecAdd,
  vecLength,
  vecClone,
  getPolygonSignedArea,
  projectPointToLine,
  vecSqrLength,
  vecAngle,
  normalizeRad,
  normalizeAngle,
  vecDistance,
  doPointInPoly,
} from '../../../algorithms/algorithmHelpers';
import { getBuildingStoriesData } from '../../../model/feasibilityDataExtractors';

const CHAMFER_ANGLE = 89;

export const projectPointToPolygon = (point, polygon, buffer = 0) => {
  const poly = [...polygon];
  // Make sure the last point is equal to the first, and add it if not.
  if (
    polygon[0][0] !== polygon[polygon.length - 1][0]
      || polygon[0][1] !== polygon[polygon.length - 1][1]
  ) {
    poly.push(vecClone(polygon[0]));
  }

  const sign = Math.sign(getPolygonSignedArea(poly));
  let minLength = Number.MAX_VALUE;
  let angle;
  let position;
  let index;
  let normal;

  for (let i = 0; i < poly.length - 1; i++) {
    let point1 = poly[i];
    let point2 = poly[(i + 1) % poly.length];

    if (buffer) {
      point1 = vecMoveTowards(point1, point2, buffer);
      point2 = vecMoveTowards(point2, point1, buffer);
    }

    const pointOnLine = projectPointToLine(point, point1, point2, true);
    const diff = vecSub(pointOnLine.point, point);
    const sqrLength = vecSqrLength(diff);

    if (sqrLength < minLength) {
      minLength = sqrLength;
      const sub = vecSub(point1, point2);
      const dir = vecNormalize(sub);
      normal = [sign * dir[1], sign * -dir[0]];
      angle = vecAngle(sub);
      position = pointOnLine.point;
      index = i;
      // if (sqrLength < 50) {
      //   break;
      // }
    }
  }

  return { position, angle, normal, index, length: minLength };
};

export const chamfCoordinates = (coords, tubeWidth, isClosed) => {
  if (!coords.length) {
    return [];
  }

  const coordinates = [...coords];
  // removing the last point which equal to the first, so the for loop will work better
  if (!isClosed) {
    coordinates.pop();
  }

  const origLength = coordinates.length;

  for (let i = 0; i < coordinates.length; i++) {
    const currPoint = [coordinates[i].x, coordinates[i].y];
    const nextIndex = (i + 1) % coordinates.length;
    const nextPoint = [coordinates[nextIndex].x, coordinates[nextIndex].y];

    const nextNextIndex = (i + 2) % coordinates.length;
    const nextNextPoint = [coordinates[nextNextIndex].x, coordinates[nextNextIndex].y];

    const line = vecNormalize(vecSub(currPoint, nextPoint));
    const nextLine = vecNormalize(vecSub(nextPoint, nextNextPoint));

    if (isNaN(line[0]) || isNaN(nextLine[0])) {
      continue;
    }

    const angle = vecAngle(line);
    const nextAngle = vecAngle(nextLine);

    const finalAngle = normalizeRad(angle - nextAngle);
    const realAngle = Math.PI - finalAngle;

    // This following lnes are a way to hadle the way jsts generate the buffered lines
    const corner1 = nextPoint[0] === coordinates[0].x && nextPoint[1] === coordinates[0].y;
    const corner2 = nextPoint[0] === coordinates[coordinates.length - 1].x && nextPoint[1] === coordinates[coordinates.length - 1].y;
    const isInCorner = (isClosed) && (corner1 || corner2);
    const chmferAngle = isInCorner ? CHAMFER_ANGLE / 2 : CHAMFER_ANGLE;
    const chamferWidth = isInCorner ? tubeWidth / 2 : tubeWidth;

    if (!isNaN(realAngle) && realAngle > 0 && realAngle * (180 / Math.PI) < chmferAngle) {
      const isOtherEdge = !isClosed ? nextIndex >= origLength / 2 - 1 : nextIndex < origLength / 2 - 1;
      const firstPoint = vecMoveTowards(nextPoint, currPoint, chamferWidth / (isOtherEdge ? Math.tan(realAngle) : Math.sin(realAngle)));
      const secPoint = vecMoveTowards(nextPoint, nextNextPoint, chamferWidth / (isOtherEdge ? Math.sin(realAngle) : Math.tan(realAngle)));
      coordinates.splice(nextIndex, 1, new geom.Coordinate(...firstPoint, 0), new geom.Coordinate(...secPoint, 0));
      if (coordinates[i].x === coordinates[nextIndex].x && coordinates[i].y === coordinates[nextIndex].y) {
        coordinates.splice(i, 1);
      }
    }
  }

  // return the first point to be last as well
  if ((!isClosed) && coordinates[0]) {
    coordinates.push(coordinates[0]);
  }

  return coordinates;
};

// Check if the length of a line should have a warning, depending on it's id (place in line)
export const isWarningLengthByIndex = (length, index, isClosed, points) => {
  if (!isClosed && (!index || (index === 0 || index === points.length - 2))) {
    return length < MINIMUM_EDGES_LENGTH;
  }
  return length < MINIMUM_MIDPOINTS_LENGTH;
};

export const getColorBuyLengthAndId = (length, index, isClosed, points) => (isWarningLengthByIndex(length, index, isClosed, points) ? WARNING_COLOR : MAIN_COLOR);

export const getPropertiesBetweenTwoPoints = (prevPoint, nextPoint) => {
  const point = vecLerp(prevPoint, nextPoint, 0.5);
  const subVec = vecSub(prevPoint, nextPoint);
  const length = roundNumber(vecLength(subVec), 1);
  const normalized = vecNormalize(subVec);
  const textPoint = vecMoveTowards(nextPoint, vecAdd(nextPoint, normalized), 3); // Move right
  const normal = [-normalized[1], normalized[0]];
  return { point, subVec, length, normalized, textPoint, normal, prevPoint, nextPoint };
};

export const showWarningModal = () => {
  Modal.warning({
    title: "Building can't be created",
    content: 'The line sketched is too short',
    onOk: () => {},
  });
};

const getLotSnappedAngle = (currentAngle, anglesToBeSnapped) => {
  let snappedAngle;
  for (let i = 0; i < anglesToBeSnapped.length; i++) {
    const destAng = anglesToBeSnapped[i];
    const diffAng = currentAngle - destAng;
    let minAngle = Number.MAX_VALUE;
    if (Math.abs(diffAng) < SNAPPED_POINTER_ANGLE) {
      if (Math.abs(diffAng) < minAngle) {
        minAngle = diffAng;
        snappedAngle = destAng;
      }
    }
  }

  return snappedAngle;
};

const DEFAULT_LOCAL_ANGLE_JUMPS = 15;

const getLocalCoordinatesSnapedAngle = (currentAngle, lastPoint, oneBeforeLastPoint, snappingAnglesFactor) => {
  const snappingAngle = snappingAnglesFactor || DEFAULT_LOCAL_ANGLE_JUMPS;
  if (!oneBeforeLastPoint) {
    return;
  }
  const steadyLocalAngle = normalizeAngle(vecAngle(vecSub(oneBeforeLastPoint, lastPoint)) * (180 / Math.PI));

  // When using angle snappings, when getting close to reverse angle (180) -
  // we want the sketch to snap to the last relevant snaping angle instead to the 180 one.
  const reverseAngle = reverseAngleAlternative(currentAngle, lastPoint, oneBeforeLastPoint, snappingAngle);
  if (reverseAngle) {
    return reverseAngle;
  }

  for (let i = -180; i <= 180; i += snappingAngle) {
    const angleToCheck = normalizeAngle(steadyLocalAngle + i);
    const normalized = normalizeAngle(currentAngle);
    const diffAng = (normalized - angleToCheck);
    if (Math.abs(diffAng) < snappingAngle / 2 || Math.abs(360 - diffAng) < snappingAngle / 2) {
      return angleToCheck;
    }
  }

  return false;
};

const reverseAngleAlternative = (currentAngle, lastPoint, oneBeforeLastPoint, snappingAngle) => {
  const steadyLocalAngle2 = normalizeAngle(vecAngle(vecSub(lastPoint, oneBeforeLastPoint)) * (180 / Math.PI));
  const steadyLocalAngle = normalizeAngle(vecAngle(vecSub(oneBeforeLastPoint, lastPoint)) * (180 / Math.PI));

  const angleDiff = normalizeAngle(currentAngle - steadyLocalAngle2);
  if (angleDiff < snappingAngle) {
    return steadyLocalAngle - snappingAngle;
  }

  if (angleDiff > (360 - snappingAngle)) {
    return steadyLocalAngle + snappingAngle;
  }

  return null;
};

export const getSnappedPosition = (point, points, lotAngles, isShiftPressed, sketchSettings) => {
  const isLocalAngleSnapped = isShiftPressed || sketchSettings?.isDefaultSnappingAngle;
  const lastPoint = points[points.length - 1];
  const oneBeforeLastPoint = points[points.length - 2];
  const pointerPoint = [point.x, point.y];
  const lastVec3 = new THREE.Vector3(...lastPoint, 0);
  const diff = lastVec3.clone().sub(point);
  const rad = Math.atan2(diff.y, diff.x);
  const currentAngle = normalizeRad(rad) * (180 / Math.PI);
  let snappedAngle;
  if (isLocalAngleSnapped) {
    snappedAngle = getLocalCoordinatesSnapedAngle(currentAngle, lastPoint, oneBeforeLastPoint, sketchSettings?.snappingAngle);
  } else {
    snappedAngle = getLotSnappedAngle(currentAngle, lotAngles);
  }

  if (snappedAngle) {
    const dx = -Math.cos(snappedAngle * (Math.PI / 180));
    const dy = -Math.sin(snappedAngle * (Math.PI / 180));
    const dist = vecDistance(pointerPoint, lastPoint);
    const newSnappedPoint = vecMoveTowards(lastPoint, vecAdd(lastPoint, [dx, dy]), dist);
    const newSnappedPointThree = new THREE.Vector3(...newSnappedPoint, 0);
    return newSnappedPointThree;
  }

  return point;
};

export const isIlligalToSend = (midPoints, isClosed, points) => {
  for (let i = 0; i < midPoints.length; i++) {
    if (isWarningLengthByIndex(midPoints[i].length, i, isClosed, points)) {
      return true;
    }
  }
  return false;
};

// For performance we now just check if 2 points are intersectiong, which is good enough
export const isSelfIntersecting = (outlinePoints, points, point, tubeWidth) => {
  const lastPoint = points[points.length - 1];

  if (!lastPoint) {
    return;
  }

  const dir = lastPoint && vecNormalize(vecSub(point, lastPoint));
  const perpen = vecNormalize([-dir[1], dir[0]]);

  const rP = vecMoveTowards(point, vecAdd(point, perpen), tubeWidth);
  const lP = vecMoveTowards(point, vecAdd(point, perpen), -tubeWidth);
  const outlinePoly = outlinePoints.map((v) => [v.x, v.y]);

  const isRightPointInPoly = doPointInPoly(outlinePoly, rP);
  const isLeftPointInPoly = doPointInPoly(outlinePoly, lP);

  return isRightPointInPoly || isLeftPointInPoly;
};

export const getAverageFloorCount = (buildingInfo) => {
  let totalHeight = 0;
  const keys = Object.keys(buildingInfo.masses);
  const massKeys = keys.filter((key) => buildingInfo.masses[key].name.indexOf('Mass') > -1);
  massKeys.forEach((key) => {
    const item = buildingInfo.masses[key];
    const { storyGroups } = getBuildingStoriesData(item);
    storyGroups.forEach((storyGroup) => {
      totalHeight += storyGroup.height;
    });
  });
  return Math.round(totalHeight / massKeys.length);
};
