import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useMarkupTransform, useIsMarkupSelected, changePoints, useMarkupAttachmentId } from 'store/markups';
import {
  vecAdd,
  vecAngle,
  vecSub,
  vecMoveTowards,
  getPointsCenter,
  vecNormalize,
  vecDistance,
} from 'utils/algorithms/algorithmHelpers';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashGet from 'lodash/get';
import { selectedMassSelector } from 'store/views';
import MarkupFrame from './MarkupFrame';
import TansformPoint from './TansformPoint';
import SectionLineGraphic from './SectionLineGraphic';
import { FRAME_OFFSET, VIEWS_LINE_COLOR, VIEWS_LINE_WIDTH, VIEWS_LINE_DISABLED_COLOR, Z_INDEX } from './constants';

const SectionLine = ({ id, isViewOnly, markup }) => {
  const dispatch = useDispatch();
  const transform = useMarkupTransform(id);
  const isSelected = useIsMarkupSelected(id);
  const attachmentId = useMarkupAttachmentId(id);
  const selectedMass = useSelector(selectedMassSelector);
  const disabled = attachmentId !== selectedMass;
  const color = disabled ? VIEWS_LINE_DISABLED_COLOR : VIEWS_LINE_COLOR;
  const lineWidth = VIEWS_LINE_WIDTH;
  const points = lodashGet(transform, 'points');
  const center = useMemo(() => getPointsCenter(points), [points]);

  const arrowHeadSize = useMemo(() => lineWidth * 3, [lineWidth]);

  const isTooClose = lodashIsEmpty(points) ? false : vecDistance(points[0], points[1]) < 1;

  const framePoints = useMemo(() => {
    if (isTooClose || lodashIsEmpty(transform)) {
      return [];
    }
    const dir = vecSub(points[0], points[1]);
    const norm = vecNormalize(dir);
    const perpen = [norm[1], -norm[0]];
    const offset = arrowHeadSize / 2 + FRAME_OFFSET;
    return [
      vecMoveTowards(points[0], vecAdd(points[0], perpen), offset),
      vecMoveTowards(points[1], vecAdd(points[1], perpen), offset),
      vecMoveTowards(points[1], vecAdd(points[1], perpen), -offset),
      vecMoveTowards(points[0], vecAdd(points[0], perpen), -offset),
    ];
  }, [points, isTooClose]);

  const onPointMoved = useCallback((event, pointIndex, newPoint) => {
    if (isViewOnly || disabled || lodashIsEmpty(transform)) {
      return;
    }
    const newPoints = [...points];
    newPoints[pointIndex] = newPoint;
    dispatch(changePoints({ id, points: newPoints, isDragged: true }));
  }, [disabled, isViewOnly, transform]);

  const onFrameMoved = useCallback((event, delta) => {
    if (isViewOnly || disabled || lodashIsEmpty(transform)) {
      return;
    }
    const newPoints = points.map((point) => vecAdd(point, delta));
    dispatch(changePoints({ id, points: newPoints, isDragged: true }));
  }, [disabled, isViewOnly, transform]);

  if (isTooClose || lodashIsEmpty(transform)) {
    return null;
  }

  return (
    <>
      <SectionLineGraphic
        color={color}
        lineWidth={lineWidth}
        isSelected={isSelected}
        transform={transform}
        id={id}
        type={markup.type}
        isViewOnly={isViewOnly || disabled}
        triangleSize={arrowHeadSize}
      />
      {isSelected && (
      <MarkupFrame
        points={framePoints}
        center={center}
        rotation={vecAngle(vecSub(points[0], points[1]))}
        onFrameMoved={onFrameMoved}
      />
      )}
      {isSelected && points.map((p, index) => (
        <TansformPoint
          key={index}
          pointIndex={index}
          position={[...p, Z_INDEX.POINTS]}
          onPointMoved={onPointMoved}
          transform={transform}
          center={center}
          cursor="crosshair"
        />
      ))}
    </>
  );
};

SectionLine.propTypes = {
  id: PropTypes.string,
  isViewOnly: PropTypes.bool,
  markup: PropTypes.object,
};

export default React.memo(SectionLine);
