import React from 'react';

export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.9125 9.77553C19.5432 9.55399 19.0632 9.55399 18.694 9.77553L6.98947 16.7909C6.91563 16.8278 6.8787 16.8278 6.80486 16.8278H2.55873C1.89412 16.8278 1.3772 17.3816 1.3772 18.0093V24.2862C1.3772 24.9508 1.93104 25.4677 2.55873 25.4677H6.91562L18.694 32.8154C18.9156 32.9262 19.1371 33 19.3586 33C19.5432 33 19.7648 32.9631 19.9494 32.8523C20.3186 32.6308 20.5771 32.2615 20.5771 31.7815V10.8094C20.5032 10.4032 20.2817 9.99706 19.9125 9.77553V9.77553Z" stroke="currentcolor" strokeMiterlimit="10" />
    <path d="M33.2048 13.9109V28.8646" stroke="currentcolor" strokeMiterlimit="10" />
    <path d="M29.7341 16.717V26.0954" stroke="currentcolor" strokeMiterlimit="10" />
    <path d="M26.374 19.0432V23.7324" stroke="currentcolor" strokeMiterlimit="10" />
    <path d="M23.1245 20.114V22.6247" stroke="currentcolor" strokeMiterlimit="10" />
    <path d="M36.823 10.8463V31.9292" stroke="currentcolor" strokeMiterlimit="10" />
  </svg>
);
