import React from 'react';
import styled from 'styled-components';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { useSelector } from 'react-redux';
import { getProfileByIdSelector } from 'store/swappProfile';
import { useProfileResult } from 'store/swappProfile/selectors/swappSelectors';
import { MODEL_ANALYSIS_TYPES } from 'constants/feasibilityConts';
import FeasibilityViewerContainer from 'utils/swappViewer/FeasibilityViewerContainer';
import ViewsUi from './ViewsUi';

const ViewsContainerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const ViewsContainer = () => {
  const locationData = parseLocationUrl(window.location);
  const profile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const result = useProfileResult(profile);

  return (
    <ViewsContainerWrapper>
      {/* ============== UI ============== */}
      <ViewsUi />

      {/* ============ viewer ============ */}
      <FeasibilityViewerContainer
        result={result}
        isOrthographic
        isViewsOpen
        profileId={locationData.profileId}
        legendKey={MODEL_ANALYSIS_TYPES.AREA_TYPE}
      />
    </ViewsContainerWrapper>
  );
};

export default ViewsContainer;
