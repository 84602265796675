import lodashGet from 'lodash/get';
import lodashSum from 'lodash/sum';
import T from 'i18n-react';
import { BUILDING_INFO_KEYS, INCOME_KEYS } from '../../constants/feasibilityConts';
import { numberWithComma } from './dataDisplay';

export const getParkingData = (data, swappProfile, form) => {
  const currentFormData = form && form.getFieldsValue();

  const unitPrice = (parkingUnit) => (
    lodashGet(currentFormData, `[${BUILDING_INFO_KEYS.PARKING_FINANCIAL}][${parkingUnit.key}].coast`)
    || lodashGet(swappProfile, `profileData.feasibilityResultForm[${BUILDING_INFO_KEYS.FINANCIAL_DATA}].parking[${parkingUnit.key}].coast`)
    || parkingUnit.default);

  const unitFloor = (parkingUnit) => (
    lodashGet(currentFormData, `[${BUILDING_INFO_KEYS.PARKING_FINANCIAL}][${parkingUnit.key}].floors`)
    || lodashGet(swappProfile, `profileData.feasibilityResultForm[${BUILDING_INFO_KEYS.FINANCIAL_DATA}].parking[${parkingUnit.key}].floors`)
    || 1);

  const parkingTableData = data.parking.map((unit) => {
    if (!unit.value) {
      return;
    }

    const isBelowGround = unit.key === BUILDING_INFO_KEYS.NUM_OF_BELOW_GROUND_PARKING;
    const unitTotalCoast = unit.value * unitPrice(unit);

    return ({
      key: unit.key,
      NAME: unit.name,
      FLOORS: isBelowGround ? unitFloor(unit) : unit.floors,
      AMOUNT_TO_STALL: unitPrice(unit),
      STALLS: isBelowGround ? unit.value * unitFloor(unit) : unit.value,
      TOTAL_COST: isBelowGround ? unitTotalCoast * unitFloor(unit) : unitTotalCoast,
    });
  }).filter((e) => e);

  const totalParkingStalls = lodashSum(parkingTableData.map((parkingUnit) => (parkingUnit.STALLS || 0)));

  parkingTableData.push((
    {
      key: BUILDING_INFO_KEYS.NUM_OF_PARKING,
      NAME: T.translate('FEASIBILITY_RESULT_DATA_NUM_OF_PARKING'),
      AMOUNT_TO_STALL: null,
      STALLS: totalParkingStalls,
      TOTAL_COST: lodashSum(parkingTableData.map((parkingUnit) => (parkingUnit.TOTAL_COST || 0))) || '',
      TYPE: 'TOTAL',
    }
  ));

  parkingTableData.push((
    {
      key: BUILDING_INFO_KEYS.PARKING_RATIO,
      NAME: T.translate('FEASIBILITY_RESULT_DATA_PARKING_RATIO'),
      AMOUNT_TO_STALL: null,
      STALLS: totalParkingStalls / data.totalApartments,
      TOTAL_COST: null,
      TYPE: 'TOTAL',
    }
  ));

  return parkingTableData;
};

export const getExpenditureData = (data, swappProfile, form) => {
  const currentFormData = form && form.getFieldsValue();
  const totalParkingRow = getParkingData(data, swappProfile, form).find((item) => item.key === BUILDING_INFO_KEYS.NUM_OF_PARKING);
  const totalParkingCost = lodashGet(totalParkingRow, 'TOTAL_COST');

  const expenditureData = data.expenditure.map((unit) => {
    if (!unit.area || (unit.key === BUILDING_INFO_KEYS.PARKING_AREA && !totalParkingCost)) {
      return;
    }

    const unitPrice = (item) => {
      if (unit.key === BUILDING_INFO_KEYS.PARKING_AREA) {
        return totalParkingCost / unit.area;
      }

      return (
        lodashGet(currentFormData, `[${BUILDING_INFO_KEYS.FINANCIAL_DATA}].expenditure[${item.key}]`)
        || lodashGet(swappProfile, `profileData.feasibilityResultForm[${BUILDING_INFO_KEYS.FINANCIAL_DATA}].expenditure[${item.key}]`)
        || item.default);
    };

    return ({
      key: unit.key,
      NAME: unit.name,
      PERCENTAGE: '',
      AREA: unit.area,
      BUILD_COST: unitPrice(unit),
      TOTAL_COST: unit.area * unitPrice(unit),
    });
  }).filter((e) => e);

  expenditureData.push({
    key: 'TOTAL',
    NAME: T.translate('Total'),
    PERCENTAGE: null,
    AREA: lodashSum(expenditureData.map((item) => item.AREA)),
    BUILD_COST: lodashSum(expenditureData.map((item) => item.BUILD_COST)) / expenditureData.length,
    TOTAL_COST: lodashSum(expenditureData.map((item) => item.TOTAL_COST)),
  });

  return expenditureData.filter((e) => e);
};

export const getIncomeData = (data, swappProfile, form) => {
  const currentFormData = form && form.getFieldsValue();
  const expenditure = getExpenditureData(data, swappProfile, form);

  const rowData = (key) => (
    lodashGet(currentFormData, `[${BUILDING_INFO_KEYS.FINANCIAL_DATA}].income[${key}]`)
    || lodashGet(swappProfile, `profileData.feasibilityResultForm[${BUILDING_INFO_KEYS.FINANCIAL_DATA}].income[${key}]`));

  const rows = [
    { key: INCOME_KEYS.SALE, name: T.translate('FEASIBILITY_RESULT_DATA_SALE'), percentageDefault: 0, incomePerAreaDefault: 6500 },
    { key: INCOME_KEYS.RENT, name: T.translate('FEASIBILITY_RESULT_DATA_RENT'), percentageDefault: 100, incomePerAreaDefault: 24.8 },
  ];

  const updateIncomeTableData = rows.map((item) => {
    const rowBeData = rowData(item.key);
    const percentage = lodashGet(rowBeData, 'percentage') ?? item.percentageDefault;
    const incomePerArea = lodashGet(rowBeData, 'incomePerArea') ?? item.incomePerAreaDefault;
    const area = data.NIA * (percentage / 100);

    return (
      {
        key: item.key,
        NAME: item.name,
        PERCENTAGE: percentage,
        AREA: area,
        BUILD_COST: incomePerArea,
        TOTAL_COST: area * incomePerArea,
      }
    );
  });
  const totalExpenditureRow = expenditure.find((item) => item.key === 'TOTAL');
  const totalExpenditureCoast = lodashGet(totalExpenditureRow, 'TOTAL_COST');
  const totalYearlyRentIncomeCoast = updateIncomeTableData.find((item) => item.key === INCOME_KEYS.RENT).TOTAL_COST * 12;
  const totalSalesIncomeCoast = updateIncomeTableData.find((item) => item.key === INCOME_KEYS.SALE).TOTAL_COST;
  const returnOnInvestment = (totalExpenditureCoast - totalSalesIncomeCoast) / totalYearlyRentIncomeCoast;
  const returnOnInvestmentDisplay = !Number.isFinite(returnOnInvestment) || returnOnInvestment <= 0 ? 0 : returnOnInvestment;
  // const totalIncomeCoast = lodashSum(updateIncomeTableData.map((item) => item.TOTAL_COST));

  // updateIncomeTableData.push(({
  //   key: 'TOTAL',
  //   NAME: T.translate('Total'),
  //   PERCENTAGE: null,
  //   AREA: null,
  //   BUILD_COST: null,
  //   TOTAL_COST: totalIncomeCoast,
  // }));

  updateIncomeTableData.push(({
    key: 'TOTAL',
    type: 'YEARS',
    NAME: T.translate('FEASIBILITY_TARGET_FORM_CARD_FINANCIAL_TIME_TO_PROFIT'),
    PERCENTAGE: null,
    AREA: null,
    BUILD_COST: null,
    TOTAL_COST: numberWithComma(returnOnInvestmentDisplay, { fixed: 1, suffix: ` ${T.translate('Years')}` }),
  }));

  return updateIncomeTableData;
};

export const getAllFinancialData = (data, swappProfile, form) => ({
  parking: getParkingData(data, swappProfile, form),
  expenditure: getExpenditureData(data, swappProfile, form),
  income: getIncomeData(data, swappProfile, form),
});

export const isFinancialsDataDisabled = (user, projectId) => {
  const disabledFinancialsList = lodashGet(user, 'settings.flags.disabledFinancialsProjectIds');
  return projectId && disabledFinancialsList?.includes(projectId);
};
