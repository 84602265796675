import React from 'react';

export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" />
    <path d="M14.2001 11.399C14.453 11.8351 15.0108 11.9842 15.4456 11.7312C15.8818 11.4797 16.0308 10.9219 15.7792 10.4857L13.8706 7.19901C13.604 6.79571 13.0694 6.66992 12.6511 6.9119C12.2327 7.1539 12.0755 7.68026 12.2929 8.1123L14.2001 11.399Z" fill="currentcolor" />
    <path d="M7.20022 13.87L10.4897 15.7704C10.6988 15.9249 10.9641 15.9823 11.2184 15.9304C11.4727 15.8771 11.6928 15.7185 11.8227 15.4929C11.9525 15.2687 11.9813 14.9993 11.9006 14.7519C11.8199 14.5058 11.6381 14.3048 11.4002 14.1995L8.11076 12.2991C7.90158 12.1446 7.63634 12.0872 7.38206 12.1405C7.12777 12.1939 6.90764 12.3525 6.77775 12.5767C6.64787 12.8009 6.61916 13.0716 6.69982 13.3177C6.78048 13.5652 6.96232 13.7661 7.20022 13.87V13.87Z" fill="currentcolor" />
    <path d="M5.66069 21.4098H9.46134C9.94259 21.3797 10.3186 20.9805 10.3186 20.4979C10.3186 20.0152 9.94259 19.616 9.46134 19.5859H5.66069C5.17807 19.616 4.80347 20.0152 4.80347 20.4979C4.80347 20.9805 5.17807 21.3797 5.66069 21.4098V21.4098Z" fill="currentcolor" />
    <path d="M11.7295 25.5612C11.6105 25.3493 11.4123 25.1934 11.1785 25.1278C10.9447 25.0622 10.6945 25.0922 10.4826 25.2112L7.19997 27.1266C6.97165 27.236 6.79803 27.437 6.72418 27.679C6.64899 27.9223 6.6777 28.1848 6.80485 28.4049C6.93199 28.625 7.14528 28.7823 7.39274 28.8397C7.6402 28.8958 7.89996 28.8479 8.11052 28.7043L11.4 26.7971C11.8279 26.5428 11.9742 25.9946 11.7295 25.5612L11.7295 25.5612Z" fill="currentcolor" />
    <path d="M12.6251 34.1501C12.837 34.2691 13.0872 34.2991 13.321 34.2335C13.5548 34.1679 13.7531 34.012 13.8706 33.8001L15.771 30.5107C15.9255 30.3015 15.9829 30.0362 15.931 29.782C15.8777 29.5277 15.7191 29.3075 15.4935 29.1777C15.2693 29.0478 14.9999 29.0191 14.7525 29.0997C14.5064 29.1804 14.3054 29.3622 14.2001 29.6001L12.2997 32.8896C12.174 33.1001 12.137 33.3517 12.1986 33.5882C12.2601 33.8247 12.4132 34.0271 12.6252 34.1501L12.6251 34.1501Z" fill="currentcolor" />
    <path d="M19.5894 31.5369V35.3351C19.5894 35.8382 19.9982 36.247 20.5013 36.247C21.0058 36.247 21.4132 35.8382 21.4132 35.3351V31.5369C21.4132 31.0338 21.0058 30.625 20.5013 30.625C19.9982 30.625 19.5894 31.0338 19.5894 31.5369Z" fill="currentcolor" />
    <path d="M27.1294 33.7973C27.2415 34.0229 27.4425 34.1911 27.6831 34.2622C27.9251 34.3346 28.1848 34.3045 28.4022 34.1788C28.621 34.0516 28.7768 33.8411 28.8343 33.5963C28.8917 33.3516 28.8466 33.0932 28.7071 32.884L26.7999 29.5973C26.6864 29.3717 26.4868 29.2035 26.2448 29.1325C26.0042 29.06 25.7444 29.0901 25.5257 29.2159C25.3083 29.343 25.1524 29.5536 25.0936 29.7983C25.0362 30.043 25.0827 30.3014 25.2208 30.5106L27.1294 33.7973Z" fill="currentcolor" />
    <path d="M29.6001 26.8003L32.8896 28.7007C33.0987 28.8552 33.364 28.9126 33.6183 28.8593C33.8726 28.806 34.0927 28.6474 34.2226 28.4232C34.3524 28.199 34.3812 27.9282 34.3005 27.6822C34.2198 27.4347 34.038 27.2337 33.8001 27.1298L30.5107 25.2294C30.3015 25.0749 30.0362 25.0175 29.782 25.0695C29.5277 25.1228 29.3075 25.2814 29.1777 25.507C29.0478 25.7312 29.0191 26.0005 29.0997 26.248C29.1804 26.4941 29.3622 26.695 29.6001 26.8003V26.8003Z" fill="currentcolor" />
    <path d="M30.6294 20.5004C30.6294 21.0035 31.0355 21.4123 31.5386 21.4137H35.3392C35.8219 21.3836 36.1965 20.9844 36.1965 20.5018C36.1965 20.0192 35.8219 19.6199 35.3392 19.5898H31.5386C31.0368 19.5926 30.6308 19.9986 30.6294 20.5004Z" fill="currentcolor" />
    <path d="M30.5175 15.7691L33.8001 13.8687C34.0284 13.7594 34.202 13.5584 34.2759 13.3164C34.3511 13.073 34.3224 12.8105 34.1952 12.5904C34.0681 12.3703 33.8548 12.2131 33.6073 12.1557C33.3599 12.0996 33.1001 12.1475 32.8896 12.291L29.6001 14.1982C29.3718 14.309 29.1982 14.5086 29.1243 14.7519C29.0491 14.9939 29.0778 15.2578 29.205 15.4779C29.3321 15.698 29.5454 15.8552 29.7929 15.9113C30.0403 15.9687 30.3001 15.9195 30.5107 15.7773L30.5175 15.7691Z" fill="currentcolor" />
    <path d="M25.5644 11.7257C25.9978 11.9704 26.546 11.8241 26.8003 11.3962L28.7007 8.10672C28.888 7.68014 28.7253 7.18114 28.322 6.94734C27.9201 6.71355 27.406 6.82156 27.1298 7.19617L25.2294 10.4856C24.9833 10.9217 25.1324 11.4741 25.5644 11.7257L25.5644 11.7257Z" fill="currentcolor" />
    <path d="M20.5002 10.3692C21.0033 10.3678 21.4108 9.95905 21.4108 9.45593V5.66193C21.4108 5.1588 21.002 4.75 20.4988 4.75C19.9944 4.75 19.5869 5.1588 19.5869 5.66193V9.46013C19.5883 9.96325 19.9971 10.3693 20.5002 10.3693V10.3692Z" fill="currentcolor" />
    <path d="M28.7252 20.4984C28.7252 18.3163 27.8584 16.2245 26.3163 14.6824C24.7741 13.1403 22.6822 12.2734 20.5003 12.2734C18.3184 12.2734 16.2265 13.1402 14.6844 14.6824C13.1423 16.2246 12.2754 18.3165 12.2754 20.4984C12.2754 22.6803 13.1422 24.7722 14.6844 26.3143C16.2265 27.8564 18.3184 28.7233 20.5003 28.7233C22.681 28.7206 24.7714 27.8524 26.3135 26.3116C27.8543 24.7694 28.7224 22.6789 28.7252 20.4984L28.7252 20.4984ZM20.5002 27.3234C18.6901 27.3234 16.9537 26.6042 15.6741 25.3246C14.3945 24.0449 13.6753 22.3085 13.6753 20.4984C13.6753 18.6883 14.3944 16.9519 15.6741 15.6723C16.9538 14.3927 18.6901 13.6734 20.5002 13.6734C22.3104 13.6734 24.0468 14.3926 25.3264 15.6723C26.606 16.9519 27.3252 18.6883 27.3252 20.4984C27.3238 22.3086 26.6033 24.0422 25.3236 25.3218C24.0439 26.6014 22.3103 27.3219 20.5003 27.3233L20.5002 27.3234Z" fill="currentcolor" />
  </svg>
);
