import React from 'react';

export default () => (
  <svg width="170" height="42" viewBox="0 0 170 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_359_138)">
      <path d="M46.0643 41.524C46.0643 27.6679 46.0643 13.8566 46.0643 0.0249563C46.1765 0.0157299 46.2734 0.000572559 46.3702 0.000572559C54.0281 0.00650381 61.6859 -0.0178803 69.3431 0.035501C72.1682 0.0552718 74.9492 0.487594 77.5753 1.60333C79.6296 2.47588 81.3447 3.75308 82.4548 5.71896C83.1487 6.94871 83.5013 8.27665 83.5614 9.67774C83.6396 11.5118 83.488 13.3195 82.8187 15.0528C81.8851 17.4721 80.1225 19.0551 77.6962 19.9592C77.5739 20.0047 77.4524 20.0528 77.2941 20.1141C77.785 20.358 78.2465 20.5603 78.6826 20.8081C81.2072 22.2401 83.0044 24.2693 83.6669 27.1071C84.7082 31.5641 83.0999 36.4527 78.4716 39.1363C76.5955 40.2237 74.5578 40.8412 72.424 41.1859C70.8832 41.435 69.331 41.5259 67.7709 41.5253C60.6701 41.522 53.5686 41.524 46.4677 41.524C46.3455 41.524 46.224 41.524 46.0643 41.524ZM56.4023 31.858C56.5346 31.8672 56.6428 31.8811 56.7516 31.8811C61.2578 31.8824 65.7646 31.8817 70.2707 31.8817C71.0361 31.8817 71.7414 31.6906 72.3759 31.2589C73.6769 30.3745 74.2693 28.7711 73.8252 27.3252C73.3757 25.8622 72.0052 24.8868 70.3983 24.8868C65.8588 24.8868 61.3186 24.8888 56.779 24.8815C56.4898 24.8809 56.375 24.9356 56.377 25.2558C56.3923 27.3417 56.3843 29.4282 56.3856 31.514C56.3856 31.6214 56.3957 31.7282 56.4023 31.858ZM56.385 9.00421C56.385 9.18347 56.385 9.3021 56.385 9.42138C56.3863 11.4308 56.3877 13.4395 56.3897 15.4489C56.3903 15.8818 56.391 15.8832 56.8278 15.8818C61.4334 15.8693 66.0398 15.8574 70.6454 15.8423C70.8778 15.8416 71.1169 15.841 71.3407 15.7902C73.1747 15.3724 74.3855 13.5093 74.0242 11.6792C73.7236 10.1562 72.3138 9.00487 70.7489 9.00487C66.1206 9.00487 61.4922 9.00487 56.8638 9.00487C56.7216 9.00421 56.5793 9.00421 56.385 9.00421Z" fill="white" />
      <path d="M133.027 0.0289154C145.336 0.0289154 157.629 0.0289154 169.93 0.0289154C169.93 3.02486 169.93 6.00564 169.93 9.01806C161.037 9.01806 152.181 9.01806 143.295 9.01806C143.295 11.27 143.295 13.4909 143.295 15.7579C151.296 15.7579 159.29 15.7579 167.307 15.7579C167.307 18.8053 167.307 21.8072 167.307 24.8505C167.134 24.8505 166.982 24.8505 166.829 24.8505C159.137 24.8505 151.446 24.8505 143.755 24.8505C143.275 24.8505 143.275 24.8512 143.275 25.3362C143.275 27.4669 143.275 29.5975 143.275 31.7288C143.275 32.1519 143.275 32.1532 143.719 32.1532C152.29 32.1532 160.86 32.1532 169.431 32.1532C169.596 32.1532 169.762 32.1532 169.976 32.1532C169.976 32.3022 169.976 32.4188 169.976 32.5348C169.974 35.4345 169.968 38.3343 169.978 41.2333C169.979 41.5562 169.88 41.632 169.564 41.632C157.51 41.6241 145.455 41.6254 133.401 41.6248C133.281 41.6248 133.16 41.6136 133.027 41.607C133.027 27.7377 133.027 13.8942 133.027 0.0289154Z" fill="white" />
      <path d="M89.1602 0.0506469C89.3105 0.0506469 89.442 0.0506469 89.573 0.0506469C96.7846 0.0506469 103.997 0.0499879 111.209 0.0506469C113.729 0.0506469 116.232 0.206837 118.675 0.879045C120.584 1.40429 122.349 2.20765 123.798 3.58106C125.228 4.93602 126.055 6.60798 126.39 8.52113C126.753 10.5971 126.716 12.6579 125.965 14.654C125.014 17.1801 123.221 18.9173 120.722 19.9658C120.641 19.9994 120.56 20.0317 120.399 20.097C120.564 20.1688 120.666 20.2136 120.769 20.2571C123.053 21.218 124.856 22.6823 125.852 24.9883C126.299 26.0243 126.464 27.113 126.466 28.232C126.474 32.5809 126.485 36.9299 126.494 41.2788C126.494 41.3552 126.488 41.4317 126.482 41.5404C123.169 41.5404 119.86 41.5404 116.51 41.5404C116.502 41.4086 116.484 41.261 116.484 41.1134C116.482 37.6976 116.48 34.2825 116.483 30.8668C116.484 29.4762 116.138 28.1911 115.273 27.0774C114.105 25.5715 112.553 24.7794 110.622 24.7747C107.005 24.7662 103.388 24.7741 99.7707 24.7747C99.6518 24.7747 99.5323 24.7747 99.38 24.7747C99.38 30.3877 99.38 35.9617 99.38 41.5668C95.9592 41.5668 92.5724 41.5668 89.1582 41.5668C89.1602 27.7536 89.1602 13.935 89.1602 0.0506469ZM106.751 9.10371C104.404 9.10371 102.056 9.10899 99.7086 9.09713C99.396 9.09581 99.3186 9.19071 99.3199 9.48859C99.3326 11.4644 99.3326 13.4408 99.3192 15.4165C99.3172 15.7263 99.3927 15.8304 99.7226 15.8278C101.469 15.8106 103.216 15.8166 104.963 15.8159C107.845 15.8153 110.726 15.8232 113.607 15.8133C115.892 15.8047 117.533 13.821 117.043 11.6832C116.685 10.1285 115.363 9.11162 113.659 9.10569C111.358 9.09778 109.054 9.10371 106.751 9.10371Z" fill="white" />
      <path d="M39.602 0.00358582C39.602 3.15638 39.602 6.2604 39.602 9.41451C39.3997 9.41451 39.216 9.41516 39.0323 9.41451C35.0812 9.40198 31.1307 9.37694 27.1796 9.38353C25.4104 9.38617 23.6412 9.44153 21.8727 9.4903C19.1952 9.56477 16.6753 10.1803 14.4606 11.7316C11.9547 13.4866 10.5275 15.896 10.1582 18.8986C9.78749 21.9064 10.4233 24.6888 12.2539 27.1496C13.8702 29.3218 16.1096 30.5423 18.7397 31.1189C20.4548 31.4952 22.2039 31.5671 23.9484 31.5763C29.0563 31.6033 34.1642 31.5888 39.2721 31.5901C39.393 31.5901 39.5139 31.5901 39.6635 31.5901C39.6702 31.714 39.6815 31.8201 39.6808 31.9256C39.6702 35.0006 39.6528 38.0756 39.6528 41.1513C39.6528 41.4802 39.5693 41.5757 39.2247 41.5738C34.3499 41.5441 29.4751 41.5586 24.6003 41.5019C21.1554 41.4617 17.7372 41.1091 14.4252 40.1213C9.4048 38.624 5.5672 35.5918 2.83627 31.1954C1.51588 29.07 0.634289 26.7654 0.260948 24.2954C-1.1329 15.0802 3.15217 6.86275 10.8427 2.52964C12.8724 1.38623 15.0737 0.78717 17.3558 0.410206C19.9258 -0.0148671 22.5185 0.0161073 25.1105 0.0174253C29.8183 0.0200615 34.5269 0.00951721 39.2347 0.00424498C39.3449 0.00358596 39.4551 0.00358582 39.602 0.00358582Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_359_138">
        <rect width="170" height="41.5827" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
