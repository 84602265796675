import styled from 'styled-components';
import Icon, { LoadingOutlined } from '@ant-design/icons';
import { StyledTab } from 'styles/commonComponents.styles';

export const ResultSplitViewWrapper = styled.div`
  width: 100%;
  height: calc(100vh -  ${({ theme }) => `${theme.other.headerHeight}px`} - 2px);
  position: relative;
  display: flex;
  overflow: hidden;
`;

export const FormWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding-right: 6px;
  min-width: ${({ width, collapsed }) => {
    if (collapsed) {
      return '0';
    }

    return width ? `${width}px` : '450px';
  }};
  width: ${({ width, collapsed }) => {
    if (collapsed) {
      return '0';
    }

    return width ? `${width}px` : '450px';
  }};
  height: 100%;
  //border-right: 2px solid #ccc;
  display: flex;
  flex-direction: column;
  
  //transition: min-width .3s ease-in-out;
`;

export const FormButtonWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray_01};
  width: 100%;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -7px 7px -2px rgba(0,0,0,0.12);
  z-index: 1;
`;

export const FormContent = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
  padding: 25px 18px 0 18px;
`;

export const StyledIcon = styled(LoadingOutlined)`
  font-size: 25px;
  color: ${({ theme }) => theme.colors.gray_01};
`;

export const StyledTabs = styled(StyledTab)`
  .ant-tabs-tab {
    padding: 0 4px;
  }
  
  .ant-tabs-nav {
    margin: 0;
  }
  
  .ant-tabs-nav::before {
    border: 0;
  }
  
  .ant-tabs-content-holder {
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

export const CornerIcon = styled(Icon)`
  position: absolute;
  top: 45px;
  left: 0;
  z-index: 4;
   color: ${({ theme }) => theme.colors.white};
`;

export const ResultActionBar = styled.div`
  position: relative;
  z-index: 1;
  padding: 0 15px;
  min-height: 46px;
  height: 46px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ResultContentWrapper = styled.div`
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`;

export const ResultContent = styled.div`
  padding: 15px;
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  display: grid;
  height: fit-content;
  place-items: start;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
`;

export const ResultWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ActionBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActionBarItem = styled.div`
  display: flex;
  padding-top: 1px;
  margin-right: ${({ marginRight }) => (marginRight && `${marginRight}px`)};
`;

export const ActionBarIconWrapper = styled.div`
  display: flex;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_07 : theme.colors.textColor)};
  margin-top: 8px;
  margin-left: ${({ marginLeft }) => (marginLeft && `${marginLeft}px`)};
  margin-right: ${({ marginRight }) => (marginRight && `${marginRight}px`)};
  font-size: 16px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &:hover {
    color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_07 : theme.colors.primaryColor)};
  }
  
  svg {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
`;

export const StyledText = styled.div`
  text-align: ${({ align }) => (align || 'left')};
  font-size: ${({ theme, size = 'medium' }) => theme.font.size[size]};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : 0)};
`;
