import React, { useState, useMemo } from 'react';
import T from 'i18n-react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashSum from 'lodash/sum';
import { StyledExploreTable, MainHeaderCell, StyledFormItem } from 'styles/commonComponents.styles';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import CurrencyInput from 'components/common/CurrencyInput';
import { metricDivideValue } from 'utils/helpers/unitsHelpers';
import { isSharedUrl } from 'utils/helpers/navigationHelpers';

const FORM_KEY = 'DEVELOPMENT_APPRAISAL';

const ProfileDevelopmentAppraisalPanel = (props) => {
  const { data, profile, form, handleChange, isImperial } = props;
  const [currentCurrencyInput, setCurrentCurrencyInput] = useState(lodashGet(profile, `profileData.testFitResultForm.${FORM_KEY}`));

  const updateCurrencyInputValue = (value, key) => {
    const newData = { ...currentCurrencyInput, [key]: value };
    setCurrentCurrencyInput(newData);
    form.setFieldsValue({ [FORM_KEY]: newData });
    handleChange();
  };

  const columns = [
    {
      title: () => <MainHeaderCell highlight first>{T.translate('PROFILE_DEVELOPMENT_APPRAISAL_PANEL_AREA_TYPE')}</MainHeaderCell>,
      dataIndex: 'areaType',
      key: 'areaType',
      width: 140,
      align: 'left',
      render: (value) => T.translate(value),
    },
    {
      title: () => <MainHeaderCell highlight>{T.translate(isImperial ? 'SQF' : 'SQM')}</MainHeaderCell>,
      dataIndex: 'area',
      key: 'area',
      width: 50,
      align: 'center',
      render: (value) => numberWithComma(value, { suffix: ` ${T.translate(isImperial ? 'SQF' : 'SQM')}`, fixed: 0 }),
    },
    {
      title: () => <MainHeaderCell highlight>{T.translate('PROFILE_DEVELOPMENT_APPRAISAL_PANEL_PRICE_PER_AREA', { context: { areaType: T.translate(isImperial ? 'SQF' : 'SQM') } })}</MainHeaderCell>,
      dataIndex: 'pricePerArea',
      key: 'pricePerArea',
      width: 80,
      align: 'right',
      render: (value, rowData) => (
        <CurrencyInput
          unitSystemKey={CurrencyInput.unitSystemKey.sqmToSqf}
          min={1}
          height={22}
          width={90}
          value={value}
          readOnly={rowData.rowType === 'TOTAL' || isSharedUrl()}
          onChange={(e) => updateCurrencyInputValue(e, rowData.areaType)}
        />
      ),
    },
    {
      title: () => <MainHeaderCell highlight lastRound>{T.translate('PROFILE_DEVELOPMENT_APPRAISAL_PANEL_TOTAL')}</MainHeaderCell>,
      dataIndex: 'total',
      key: 'total',
      width: 80,
      align: 'right',
      render: (value) => <CurrencyInput value={value} readOnly />,
    },
  ];

  const tableData = useMemo(() => (lodashIsEmpty(data) ? [] : data.map((item) => {
    const area = isImperial ? item.modelArea * metricDivideValue : item.modelArea;
    const pricePerArea = lodashGet(currentCurrencyInput, `[${item.name}]`, 1000);
    const total = item.modelArea * pricePerArea;

    return (
      {
        areaType: item.name,
        area,
        pricePerArea,
        total,
      });
  }).filter((e) => e)), [data, currentCurrencyInput]);

  tableData.push({
    areaType: 'PROFILE_DEVELOPMENT_APPRAISAL_PANEL_TOTAL',
    rowType: 'TOTAL',
    area: lodashSum(tableData.map((e) => e.area)),
    pricePerArea: lodashSum(tableData.map((e) => e.pricePerArea)),
    total: lodashSum(tableData.map((e) => e.total)),
  });

  if (lodashIsEmpty(data)) {
    return null;
  }

  return (
    <>
      <StyledFormItem hidden noStyle name={FORM_KEY} initialValue={{}} />
      <StyledExploreTable rowClassName={(row) => row.rowType === 'TOTAL' && ' ant-table-total-row'} ellipsis pagination={false} columns={columns} dataSource={tableData} />
    </>
  );
};

ProfileDevelopmentAppraisalPanel.propTypes = {
  data: PropTypes.object,
  profile: PropTypes.object,
  form: PropTypes.object,
  handleChange: PropTypes.func,
  isImperial: PropTypes.bool,
};

export default React.memo(ProfileDevelopmentAppraisalPanel);
