import React, { useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
// import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useThree } from 'react-three-fiber';
import { useDrag } from 'react-use-gesture';
import * as THREE from 'three';
import lodashGet from 'lodash/get';
import T from 'i18n-react';
import { BUILDING_INFO_KEYS, MODEL_ANALYSIS_TYPES, FEASIBILITY_EDITOR_BUTTON_KEYS, FEASIBILITY_MASS_TYPES } from 'constants/feasibilityConts';
import { currentThemeSelector } from 'store/userSettings';
import * as SwappEditor from '../../../store/editor';
import { MassTransformer } from '../../../store/editor';
import PlaneMesh from './PlaneMesh';
import {
  vecAdd,
  vecScale,
  // vecSub,
  vecTo3d,
  // vecTransform,
} from '../../algorithms/algorithmHelpers';
import MassMesh, { MASS_MESH_SELECTION_TYPES } from './MassMesh';
import { SELECTION_CATEGORY_TYPES } from '../../model/feasibilityResultModel';
import PointsEditor, { POINTS_EDITOR_SHAPE_TYPE } from './PointsEditor';
import { uuidv4 } from '../../helpers/uuidv4';
import ParkingMassFloor from './ParkingMassFloor';
import UndergroundParkingMass from './UndergroundParkingMass';

import { didJustFinishDragging, notifyDragFinished } from '../helpers/ThreeHelpers';
import { changeCursor } from '../helpers/SelectionHelpers';
import { getBuildingStoriesData, getStoryHeight, isBuildingUnderground } from '../../model/feasibilityDataExtractors';
import { getRenderPropsByName } from '../helpers/FeasibilityRenderProps';

const INTERACTIVE_TYPES = [FEASIBILITY_EDITOR_BUTTON_KEYS.TRANSFORM, FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_BUILDING];

// let debounceTimer = null;
const defaultTransform = { delta_x: 0, delta_y: 0, delta_angle: 0, points: null, isValid: true };

const getPointEditorShapeType = (buildingData) => {
  // const poly = buildingData.polygon;
  // const obb = getOrientedBoundingBox(poly);
  // if (getPolygonArea(poly) > getPolygonArea(obb) * 0.95) {
  if (buildingData.type === FEASIBILITY_MASS_TYPES.PARKING) {
    return POINTS_EDITOR_SHAPE_TYPE.RECTANGLE;
  }
  return POINTS_EDITOR_SHAPE_TYPE.NONE;
};

const isObjectInMassSelected = (buildingData, selectedObject) => {
  if (Array.isArray(selectedObject)) {
    return selectedObject.some((so) => so?.id === buildingData?.massIndex);
  }
  return selectedObject?.massIndex === buildingData?.massIndex;
};

const DraggableMesh = (props) => {
  const { intractable, children, buildingData, legendKey, isOrthographic, selectedObject, selectedRowKeys } = props;
  const { size, viewport, camera } = useThree();
  const aspect = (size.width / viewport.width) * (camera.zoom / 4);
  const transform = useSelector((state) => state.editor.massTransformer.transforms[buildingData.name], (prev, next) => JSON.stringify(prev) === JSON.stringify(next)) || defaultTransform;
  const massInSketch = useSelector((state) => state.editor.massInSketch);
  const currentTheme = useSelector(currentThemeSelector);
  const dispatch = useDispatch();
  const { editorType, isEditorLoading } = useSelector(({ editor }) => ({
    editorType: editor.editorType,
    isEditorLoading: editor.isLoading,
  }), shallowEqual);
  const { massIndex } = buildingData;

  const selectionObjectId = { type: 'Mass', id: massIndex };
  const isSelected = SwappEditor.isObjectSelected(selectedObject, selectionObjectId);
  const numSelectedObjects = SwappEditor.numSelectedObjects(selectedObject);
  const { isValid } = transform;
  const pointEditorShapeType = getPointEditorShapeType(buildingData);
  const isEditingControlPoints = editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.TRANSFORM && pointEditorShapeType !== POINTS_EDITOR_SHAPE_TYPE.NONE && isSelected && numSelectedObjects === 1;

  const handleUnitSelected = (unitProps) => {
    if (massMeshSelectionType === MASS_MESH_SELECTION_TYPES.UNIT) {
      const massUnitId = {
        mass_index: unitProps.massIndex,
        floor_group_index: unitProps.floorGroupIndex,
        floor_index: unitProps.floorIndex,
        unit_index: unitProps.unitIndex,
      };
      const operation = { name: 'DELETE_UNITS', parameters: { units: [massUnitId] } };
      dispatch(SwappEditor.addOperations([operation]));
    }
    if (massMeshSelectionType === MASS_MESH_SELECTION_TYPES.FLOOR) {
      dispatch(SwappEditor.selectObject({ ...unitProps, type: 'Floor' }));
    }
  };

  const dragProps = useDrag(({ event, buttons, delta: [x, y], _dragIsTap }) => {
    if (isSelected) {
      event.stopPropagation(); // This makes the click / drag exclusive to this object, preventing multiple move with one click
    }
    if (buttons === 1 && intractable) {
      if (!isSelected) {
        return;
      }
      dispatch(MassTransformer.moveSelection({ dx: x / aspect, dy: -y / aspect }));
    }
    if (buttons === 0 && intractable && isSelected) {
      dispatch(MassTransformer.finnishMove());
      // Is using _dragIsTap a code smell? It is the only way to detect whether this was a drag or a click
      if (!_dragIsTap) {
        notifyDragFinished();
      } else {
        dispatch(SwappEditor.toggleObjectSelection(selectionObjectId));
      }
    }
    // Right click:
    if (buttons === 2 && buildingData.swpBuilding?.curve?.data) {
      console.log('Right click:');
      dispatch(SwappEditor.editMassInSketch(buildingData));
      dispatch(SwappEditor.setEditorType(FEASIBILITY_EDITOR_BUTTON_KEYS.EDIT_BUILDING));
      // dispatch(SketchToolReducer.setPoints(buildingData.swpBuilding.midline));
    }
  });

  let massMeshSelectionType = MASS_MESH_SELECTION_TYPES.NONE;
  if (!isEditorLoading && editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.SETBACK) {
    massMeshSelectionType = MASS_MESH_SELECTION_TYPES.UNIT;
  }
  if (!isEditorLoading && editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.FLOOR) {
    massMeshSelectionType = MASS_MESH_SELECTION_TYPES.FLOOR;
  }

  const getHeighestMassIndex = (intersections) => {
    let maxHeight = Number.MIN_VALUE;
    let heighestIndex;
    for (let i = 0; i < intersections.length; i++) {
      const height = Number(intersections[i].object.massHeight);
      if (!Number.isNaN(height)) {
        if (maxHeight < height) {
          maxHeight = height;
          heighestIndex = intersections[i].object.massIndex;
        }
      }
    }
    return heighestIndex;
  };

  const handleMassClicked = (e) => {
    const heighestMass = getHeighestMassIndex(e.intersections);
    if (heighestMass === massIndex) {
      if (intractable && INTERACTIVE_TYPES.includes(editorType) && (!didJustFinishDragging())) {
        dispatch(SwappEditor.toggleObjectSelection(selectionObjectId));
      }
    }
  };

  const massGeometries = useMemo(() => {
    if (buildingData.type === FEASIBILITY_MASS_TYPES.PARKING) {
      const parkingMassMeshes = [];
      if (isBuildingUnderground(buildingData)) {
        parkingMassMeshes.push(<UndergroundParkingMass
          key={uuidv4()}
          buildingData={buildingData}
        />);
      } else {
        const { stories: storiesData } = getBuildingStoriesData(buildingData);
        storiesData.forEach((story, storyIdx) => {
          const isSelectingFloors = (massMeshSelectionType === MASS_MESH_SELECTION_TYPES.FLOOR);
          const isTopFloor = storyIdx === storiesData.length - 1;
          const isBottomFloor = storyIdx === 0;
          const isFloorSelectable = isSelectingFloors;
          // When selecting floors, we raise the ground floor by a bit to make it selectable.
          const heightDelta = isSelectingFloors && isBottomFloor ? 0.5 : 0;
          const floorSelectionId = isFloorSelectable ? { type: 'Floor', massIndex, floorIndex: storyIdx, floorGroupIndex: story.floorGroupIndex } : undefined;
          parkingMassMeshes.push(<ParkingMassFloor
            key={uuidv4()}
            data={story}
            height={story.startHeight + heightDelta}
            selectionObject={floorSelectionId}
            onSelected={handleUnitSelected}
            isTopFloor={isTopFloor}
            floorHeight={getStoryHeight(story)}
          />);
        });
      }
      return parkingMassMeshes;
    }

    if (legendKey !== MODEL_ANALYSIS_TYPES.AREA_TYPE) {
      return null;
    }

    const resolveColor = (unit) => {
      const { key, name, aspects, isGroundFloor, isTopFloor, isCore, unitType, isEnhanced, tags } = unit;

      let baseColor = 0x777777;
      const colorList = lodashGet(buildingData, 'unitColors.AREA_TYPE[0]');
      const renderName = T.translate(name);
      if (colorList !== undefined) {
        const entry = colorList.find((e) => e.value === renderName);
        if (entry) {
          baseColor = entry.color;
        }
      }

      const selectionCategoryMatches = Object.entries(selectedRowKeys).map(([categoryName, selectedKeys]) => {
        if (categoryName === SELECTION_CATEGORY_TYPES.UNIT_TYPE) {
          return selectedKeys.includes(key);
        }
        if (categoryName === SELECTION_CATEGORY_TYPES.UNIT_POSITION) {
          if (tags && tags.some((tag) => selectedKeys.includes(tag))) {
            return true;
          }
          if (isCore || unitType === 'COMMUNAL') {
            return false;
          }
          if (isGroundFloor && selectedKeys.includes(BUILDING_INFO_KEYS.GARDEN_APARTMENT)) {
            return true;
          }
          if (isTopFloor && selectedKeys.includes(BUILDING_INFO_KEYS.ROOF_APARTMENT)) {
            return true;
          }
          if (isEnhanced && selectedKeys.includes(BUILDING_INFO_KEYS.NON_TYPICAL_APARTMENTS)) {
            return true;
          }
          return false;
        }
        if (categoryName === SELECTION_CATEGORY_TYPES.UNIT_ASPECTS) {
          if (aspects === 1 && selectedKeys.includes(BUILDING_INFO_KEYS.ONE_FLAT_ASPECTS)) {
            return true;
          }
          return !!(aspects === 2 && selectedKeys.includes(BUILDING_INFO_KEYS.TWO_FLAT_ASPECTS));
        }
      });
      const allCategoriesMatch = selectionCategoryMatches.every(Boolean);
      const anyCategoriesMatch = selectionCategoryMatches.includes(true);
      if (allCategoriesMatch) {
        return new THREE.Color(baseColor);
      }
      if (anyCategoriesMatch) {
        return new THREE.Color('#848484');
      }
      return new THREE.Color('#c9c9c9');
    };

    return (
      <MassMesh
        buildingData={buildingData}
        colorCallback={resolveColor}
        selectionType={massMeshSelectionType}
        onUnitSelected={handleUnitSelected}
        selectedObject={selectedObject}
      />
    );
  }, [legendKey, selectedRowKeys, buildingData.massDto, massMeshSelectionType, selectedObject]);

  const handleEditPointsChanged = (newPoints) => {
    dispatch(MassTransformer.updateObjectPoints(newPoints));
  };

  const massHeight = useMemo(() => {
    const { storyGroups } = getBuildingStoriesData(buildingData);
    return storyGroups.reduce((totalHeight, story) => totalHeight + story.height, 0);
  }, [buildingData]);

  const planeOpacity = ((!isValid) || isSelected || isEditingControlPoints) ? 0.5 : 0;
  let planeColor = '#aaaaaa';
  if (!isValid) {
    planeColor = currentTheme.colors.danger;
  } else if (isSelected) {
    planeColor = currentTheme.colors.primaryColor;
  }

  const customPoints = lodashGet(transform, 'points', null);
  const planePoints = customPoints || buildingData.polygon;
  const didEditPoints = customPoints !== null;
  const planeProps = getRenderPropsByName('draggableMassPlane');
  const renderChildren = useMemo(() => (
    <group>
      {/* ======== building mesh ======== */}
      {(!didEditPoints) && (massGeometries || children)}
      {intractable && isOrthographic && (
        <group position={[0, 0, 200]}>

          {/* ======== the polygon that has the draggable props and colors red if its gets out lot polygon ======== */}
          <PlaneMesh
            key={uuidv4()}
            {...dragProps()}
            onPointerOver={() => changeCursor(isSelected ? 'all-scroll' : 'crosshair')}
            onPointerOut={() => changeCursor('default')}
            onClick={handleMassClicked}
            vertices={planePoints}
            opacity={planeOpacity}
            transparent
            color={planeColor}
            massHeight={massHeight}
            massIndex={massIndex}
            {...planeProps}
          />

          { isEditingControlPoints && <PointsEditor initialPoints={buildingData.polygon} onPointsChanged={handleEditPointsChanged} shapeType={pointEditorShapeType} /> }
        </group>
      )}
    </group>
  ), [children, isValid, intractable, planePoints]);

  // If this mass is being edited by sketch tool, it shouldn't be rendered:
  if (massInSketch?.massIndex === massIndex) {
    return null;
  }

  return (
    <group position={vecTo3d(vecAdd(buildingData.pivotPoint, [transform.delta_x, transform.delta_y]))}>
      <group rotation={[0, 0, transform.delta_angle]}>
        <group position={vecTo3d(vecScale(buildingData.pivotPoint, -1))}>
          {renderChildren}
        </group>
      </group>
    </group>
  );
};

DraggableMesh.propTypes = {
  children: PropTypes.object, // need to be array but right now it return <group />
  intractable: PropTypes.bool,
  selectedRowKeys: PropTypes.any,
  buildingData: PropTypes.object,
  selectedObject: PropTypes.any,
  legendKey: PropTypes.string,
  profileId: PropTypes.number,
  isOrthographic: PropTypes.bool,
};

// This logic is meant to prevent re-renders of the component for buildings that haven't changed
export default React.memo(DraggableMesh, (prev, next) => {
  const { children: prevChild, buildingData: prevBD, selectedObject: prevSO, selectedRowKeys: prevSRK, ...prevProps } = prev;
  const { children: nextChild, buildingData: nextBD, selectedObject: nextSO, selectedRowKeys: nextSRK, ...nextProps } = next;
  const hasChanged = isObjectInMassSelected(prevBD, prevSO) || isObjectInMassSelected(nextBD, nextSO);
  const isBuildingdataEq = JSON.stringify(prevBD.massDto) === JSON.stringify(nextBD.massDto);
  const isRestEq = JSON.stringify(prevProps) === JSON.stringify(nextProps);
  const isSameSelectedRowKeys = prevSRK === nextSRK;
  return !hasChanged && isRestEq && isBuildingdataEq && isSameSelectedRowKeys;
});
