"use strict";
// A collection of general utils to enrich how we work with typescript
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyMixins = exports.filterByType = void 0;
function filterByType(array, filterType) {
    return array === null || array === void 0 ? void 0 : array.filter((e) => e instanceof filterType);
}
exports.filterByType = filterByType;
function applyMixins(derivedCtor, constructors) {
    constructors.forEach((baseCtor) => {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
            Object.defineProperty(derivedCtor.prototype, name, Object.getOwnPropertyDescriptor(baseCtor.prototype, name) ||
                Object.create(null));
        });
    });
}
exports.applyMixins = applyMixins;
