import React from 'react';
import PropTypes from 'prop-types';
import lodashIncludes from 'lodash/includes';
import styled from 'styled-components';
import EditPanelButton from './EditPanelButton';

const MultiButtonsWrapper = styled.div`
  position: relative;
`;

const ButtonsBarWrapper = styled.div`
  position: absolute;
  padding: ${({ landscape }) => (landscape ? '5px 2px' : '2px 5px')};
  right: ${({ landscape }) => (landscape ? '0px' : '51px')};
  top: ${({ landscape }) => (landscape ? 'unset' : '-6px')};
  bottom: ${({ landscape }) => (landscape ? '51px' : 'unset')};
  display: flex;
  flex-direction:  ${({ landscape }) => (landscape ? 'column' : 'row-reverse')};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  background-color: ${({ theme }) => theme.colors.gray_01};
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.12);
  max-width: ${({ landscape, isSelected }) => {
    if (!isSelected) {
      return 0;
    }
    return landscape ? '51px' : 'fit-content';
  }};
  max-height: ${({ landscape, isSelected }) => {
    if (!isSelected) {
      return 0;
    }
    return landscape ? 'fit-content' : '51px';
  }};
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
  overflow: hidden;
  transition: opacity .4s ease-in-out;
`;

const ArrowLeft = styled.div`
  position: absolute;
  top: ${({ landscape }) => (landscape ? '0px' : '4px')};
  left: ${({ landscape }) => (landscape ? 'unset' : '-8px')};
  right: ${({ landscape }) => (landscape ? '2px' : 'unset')};
  transform: rotate(${({ landscape }) => (landscape ? '90deg' : '0')});
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;

  border-right: 5px solid ${({ theme }) => theme.colors.gray_06};
`;

const EditPanelMultiButtons = (props) => {
  const { children, selectedButton, id, landscape } = props;
  const isSelected = lodashIncludes(selectedButton, id);

  return (
    <MultiButtonsWrapper>
      <ArrowLeft landscape={landscape} />
      <EditPanelButton isSelected={isSelected} {...props} />
      <ButtonsBarWrapper isSelected={isSelected} landscape={landscape}>
        {children}
      </ButtonsBarWrapper>
    </MultiButtonsWrapper>
  );
};

EditPanelMultiButtons.propTypes = {
  selectedButton: PropTypes.string,
  id: PropTypes.string,
  landscape: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
};

export default EditPanelMultiButtons;
