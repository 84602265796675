import React, { forwardRef, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIncludes from 'lodash/includes';
import dropdownThinIcon from 'styles/static/icons/comon/dropdownThinIcon';
import { DropDownInLineText, StyledMenu, OtherInput, StyledDropDown, DropdownIcon,
  DropdownIconWrapper, MenuItem, DropDownWrapper, DropDownInLineTextWrapper, StyledProjectsMenuInner } from './Dropdown.styles';

const Dropdown = forwardRef((props, ref) => {
  const { selectedKey, dropDownData, inputValue, onChange, onInputChange,
    icon, ellipsisWidth, disabled, permanent, inputPlaceholder = 'OTHER', placeholderText = '', selectedList, width, defaultValue, trigger } = props;
  const dropDownIndex = dropDownData.findIndex((item) => item.key === selectedKey);
  const replaceToInput = lodashIncludes(selectedKey, 'OTHER');
  const overlayData = (
    <StyledMenu>
      <StyledProjectsMenuInner>
        {dropDownData.map((item) => (
          <MenuItem
            onClick={() => onChange(item.key)}
            hidden={item.hidden}
            disabled={lodashIncludes(selectedList, item.key)}
            selected={item.key === selectedKey}
            key={item.key}
          >
            {icon}{T.translate(item.value)}
          </MenuItem>
        ))}
      </StyledProjectsMenuInner>
    </StyledMenu>
  );

  const inputRef = useRef(null);

  useEffect(() => {
    if (replaceToInput && inputRef && !inputValue) {
      inputRef.current.input.focus();
    }
  }, [replaceToInput, inputRef]);

  if (lodashIsEmpty(dropDownData)) {
    return null;
  }

  return (
    <DropDownWrapper width={width} ref={ref} disabled={disabled} permanent={permanent} other={replaceToInput}>
      {replaceToInput
        ? (
          <OtherInput
            ref={inputRef}
            disabled={disabled || permanent}
            onChange={onInputChange}
            value={inputValue === 'null' ? null : inputValue}
            placeholder={T.translate(inputPlaceholder)}
            // prefix={<Icon component={EditIcon} />}
          />
        )
        : null}
      <StyledDropDown disabled={disabled || permanent} other={replaceToInput} overlay={overlayData} trigger={trigger || 'click'} defaultValue={defaultValue}>
        <DropDownInLineTextWrapper>
          {replaceToInput
            ? null
            : (
              <DropDownInLineText width={ellipsisWidth}>
                {icon}
                {selectedKey == null ? placeholderText : T.translate(dropDownData[dropDownIndex === -1 ? 0 : dropDownIndex].value)}
              </DropDownInLineText>
            )}
          <DropdownIconWrapper disabled={disabled} isOther={replaceToInput}>
            {permanent ? null : <DropdownIcon component={dropdownThinIcon} />}
          </DropdownIconWrapper>
        </DropDownInLineTextWrapper>
      </StyledDropDown>
    </DropDownWrapper>
  );
});

Dropdown.propTypes = {
  // array of objects that must include `value` & `key`
  dropDownData: PropTypes.array.isRequired,
  selectedKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  inputValue: PropTypes.string,
  icon: PropTypes.object,
  ellipsisWidth: PropTypes.number,
  disabled: PropTypes.bool,
  permanent: PropTypes.bool,
  inputPlaceholder: PropTypes.string,
  placeholderText: PropTypes.string,
  selectedList: PropTypes.array,
  width: PropTypes.number,
  defaultValue: PropTypes.string,
  trigger: PropTypes.string,
};

export default Dropdown;
