/* eslint-disable no-continue */
/* eslint-disable no-restricted-globals */
import React from 'react';
import { useStore } from 'react-redux';
import { MINIMUM_EDGES_LENGTH, MINIMUM_MIDPOINTS_LENGTH, MINIMUM_LOT_SNAPPING_LENGTH } from './constants';
import {
  vecSub,
  vecAngle,
  normalizeRad,
  vecDistance,
  normalizeAngle,
} from '../../../algorithms/algorithmHelpers';

const TUBE_WIDTH_BY_STANDART = {
  UK: 8.35,
  Prefab: 7.5,
  Dormitories: 6,
  Ryan: 9.55,
};

const getHalfTubeFromWidthAndCorridor = (width, isSingleLoadCorridor) => {
  if (!isSingleLoadCorridor) {
    return width + 0.75;
  }
  return (width + 1.5) / 2;
};

const getHalfTubeWidthFromRequirements = (requirements, isSingleLoadCorridor) => {
  const unitStandart = requirements?.units_standard;
  const width = TUBE_WIDTH_BY_STANDART[unitStandart];
  return getHalfTubeFromWidthAndCorridor(width, isSingleLoadCorridor);
};

const getHalfTubeWidthByStandart = (sketchSettings, isSingleLoadCorridor) => {
  const { tubeWidth } = sketchSettings;
  return getHalfTubeFromWidthAndCorridor(tubeWidth, isSingleLoadCorridor);
};

export const useHalfTubeWidth = (sketchSettings) => {
  const state = useStore().getState();
  const requirements = state.editor.result.singleOptionMassingOptions?.requirements;
  const reqSingleLoadCorridor = requirements?.design_requirements?.single_load_corridor;
  const byReqs = React.useMemo(() => getHalfTubeWidthFromRequirements(requirements, reqSingleLoadCorridor), [requirements?.units_standard, requirements?.design_requirements?.single_load_corridor]);
  if (sketchSettings) {
    const isSingleLoadCorridor = sketchSettings.isSingleLoadCorridor ?? requirements?.design_requirements?.single_load_corridor;
    const bySettings = getHalfTubeWidthByStandart(sketchSettings, isSingleLoadCorridor);
    if (bySettings) {
      return bySettings;
    }
  }
  return byReqs;
};

// Check if the length of a line should have a warning, depending on it's id (place in line)
export const isWarningLengthByIndex = (length, index, isClosed, points) => {
  if (!isClosed && (!index || (index === 0 || index === points.length - 2))) {
    return length < MINIMUM_EDGES_LENGTH;
  }
  return length < MINIMUM_MIDPOINTS_LENGTH;
};

export const useLotAngles = (lotPolygon) => React.useMemo(() => {
  const snappingAnglesCandidates = {};
  const snappingAngles = [];
  for (let i = 0; i < lotPolygon.length; i++) {
    const p1 = lotPolygon[i];
    const p2 = lotPolygon[(i + 1) % lotPolygon.length];
    const len = vecDistance(p1, p2);
    let angle = vecAngle(vecSub(p1, p2));
    angle = normalizeRad(angle);
    angle = Math.round(angle * (180 / Math.PI));

    if (!snappingAnglesCandidates[angle]) {
      snappingAnglesCandidates[angle] = len;
    } else {
      snappingAnglesCandidates[angle] += len;
    }
  }
  Object.keys(snappingAnglesCandidates).forEach((key) => {
    if (snappingAnglesCandidates[key] > MINIMUM_LOT_SNAPPING_LENGTH) {
      key = parseFloat(key);
      let oppoAngle = normalizeAngle(key + 180);
      oppoAngle = Math.round(oppoAngle);
      snappingAngles.push(key, oppoAngle);
    }
  });
  return snappingAngles;
}, [lotPolygon]);

export const useAnglesFromLastLine = (points) => React.useMemo(() => {
  const localSnappings = [];
  const lastPoint = points[points.length - 1];
  const oneBeforeLastPoint = points[points.length - 2];
  if (oneBeforeLastPoint) {
    const lastLineRad = vecAngle(oneBeforeLastPoint, lastPoint);
    const lastLineAngle = normalizeRad(lastLineRad) * (180 / Math.PI);
    for (let i = 0; i < 4; i++) {
      localSnappings.push(Math.round(normalizeAngle(lastLineAngle + (90 * i))));
    }
  }
  return localSnappings;
}, [points[points.length - 1], points[points.length - 2]]);
