import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsFinite from 'lodash/isFinite';
import { Tooltip } from 'antd';
import { StyledFormItem, RowText, SmallStyledTable, StyledInputNumber, DeleteIcon } from 'styles/commonComponents.styles';
import { AREA_TARGET_FORM } from 'constants/fieldNames';
import { uuidv4 } from 'utils/helpers/uuidv4';
import { deleteRow, updateRow, updateTable } from 'utils/helpers/tableHelpers';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { TEST_FIT_GENERAL } from 'constants/dropdownKeys';
import { sqfToSqmText } from 'utils/helpers/unitsHelpers';
import { FORM_INPUT_TYPE } from 'utils/helpers/testFitContainerHelprs';
import { useOverridableSetting } from 'store/userSettings';
import { getTestFitStandards } from 'utils/helpers/parseTestFitStandards';
import FromToNumInput from '../../../common/FromToNumInput';
import deleteIcon from '../../../../styles/static/icons/comon/deleteIcon';
import AddNewRowButton from '../../../common/AddNewRowButton';
import Dropdown from '../../../common/Dropdown';

const AreaTargetFormPsf = (props) => {
  const { form, form: { setFieldsValue }, disabled, formData, handleOnChange, isImperial } = props;
  const isRoomCount = formData[AREA_TARGET_FORM.INPUT_TOGGLE] === FORM_INPUT_TYPE.ROOM_COUNT;
  const testFitStandard = useOverridableSetting('testFitStandard', 'DEFAULT');
  const roomTypes = useMemo(() => {
    const fullRoomList = getTestFitStandards().standards[testFitStandard].roomDefinitions.PSF;
    return fullRoomList.filter((room) => room.subCategory === 'PSFA');
  }, [testFitStandard]);

  const [totalRsfData, useTotalRsfData] = useState([]);

  // update table when total LSF changes
  useEffect(() => {
    if (!disabled) {
      updateTable(handleOnChange, form, AREA_TARGET_FORM.PSF_A_FIELD, updateEstimations);
    }
  }, [formData[AREA_TARGET_FORM.TOTAL_LSF_FIELD], formData[AREA_TARGET_FORM.INPUT_TOGGLE]]);

  useEffect(() => {
    const psfData = formData[AREA_TARGET_FORM.PSF_A_FIELD];

    if (psfData) {
      updateTotal(useTotalRsfData, psfData);
    }
  }, [formData[AREA_TARGET_FORM.PSF_A_FIELD], isImperial]);

  const getRoomTypeIndex = (key) => {
    const unitIndex = roomTypes.findIndex((office) => office.key === key);
    return !unitIndex || unitIndex === -1 ? 0 : unitIndex;
  };

  const roomCount = [
    { key: TEST_FIT_GENERAL.PSF.RATIO_PER_MEMBERS, value: 'AREA_TARGET_FORM_PSF_ROOM_COUNT_RATIO_PER_MEMBERS', totalLsfKey: 'numberOfMembers' },
    { key: TEST_FIT_GENERAL.PSF.RATIO_PER_OFFICES, value: 'AREA_TARGET_FORM_PSF_ROOM_COUNT_RATIO_PER_OFFICES', totalLsfKey: 'numberOfRooms' },
  ];

  const getRoomCountIndex = (key) => roomCount.findIndex((room) => room.key === key);

  const columns = [
    {
      title: T.translate('AREA_TARGET_FORM_PSF_ROOM_TYPE'),
      dataIndex: 'roomType',
      key: 'roomType',
      width: 135,
      ellipsis: true,
      align: 'center',
      render: (key, rowData) => (
        <Dropdown
          dropDownData={roomTypes}
          selectedKey={key}
          onChange={(value) => handleRoomTypeChange(value, rowData)}
          inputValue={rowData.otherText}
          onInputChange={(e) => handleRoomTypeFreeTextChange(e, rowData)}
          ellipsisWidth={148}
        />
      ),
    },
    {
      title: T.translate('AREA_TARGET_FORM_PSF_ROOM_SIZE'),
      dataIndex: 'roomSize',
      key: 'roomSize',
      align: 'center',
      width: 95,
      render: (officeRange, rowData) => (
        <FromToNumInput
          value={officeRange}
          defaultValue={roomTypes[getRoomTypeIndex(rowData.roomType)].defaultRange}
          range={roomTypes[getRoomTypeIndex(rowData.roomType)].minMaxRange}
          overwriteMax={5000}
          onChange={(e) => handleRoomSizeChange(e, rowData)}
          step={roomTypes[getRoomTypeIndex(rowData.roomType)].step}
        />
      ),
    },
    {
      title: T.translate('AREA_TARGET_FORM_PSF_ROOM_COUNT'),
      dataIndex: 'roomCount',
      key: 'roomCount',
      width: 130,
      ellipsis: true,
      align: 'center',
      render: (key, rowData) => (isRoomCount
        ? T.translate('AREA_TARGET_FORM_PSF_ROOM_COUNT_RATIO_PER_MEMBERS')
        : <Dropdown dropDownData={roomCount} selectedKey={key} onChange={(value) => handleRoomCountChange(value, rowData)} />),
    },
    {
      title: T.translate('AREA_TARGET_FORM_PSF_RATIO'),
      dataIndex: 'ratio',
      key: 'ratio',
      width: 50,
      align: 'center',
      render: (ratio, rowData) => (isRoomCount
        ? numberWithComma(ratio)
        : <StyledInputNumber onChange={(e) => handleRatioChange(e, rowData)} min={1} height={25} value={ratio} />
      ),
    },
    {
      title: `${T.translate('AREA_TARGET_FORM_PSF_ESTIMATED_ROOMS')} ${isRoomCount ? '' : T.translate('AREA_TARGET_FORM_LSF_ESTIMATE')}`,
      dataIndex: 'numberOfRooms',
      key: 'numberOfRooms',
      width: 40,
      align: 'center',
      render: (number, rowData) => (isRoomCount
        ? <StyledInputNumber onChange={(e) => handleRoomsChange(e, rowData)} min={1} height={25} value={number} parser={(value) => value.replace('.', '')} />
        : numberWithComma(number)
      ),
    },
    {
      title: T.translate('AREA_TARGET_FORM_PSF_ESTIMATED_SEATS'),
      dataIndex: 'numberOfSeats',
      key: 'numberOfSeats',
      width: 40,
      align: 'center',
      render: (number) => numberWithComma(number),
    },
    {
      dataIndex: 'delete',
      width: 20,
      align: 'center',
      render: (text, rowData) => <DeleteIcon component={deleteIcon} onClick={() => deleteRow(handleOnChange, rowData.key, form, AREA_TARGET_FORM.PSF_A_FIELD)} />,
    },
  ];

  const totalColumns = [
    { dataIndex: 'roomType', key: 'roomType', width: 200 },
    { dataIndex: 'roomSize', key: 'roomSize', width: 95 },
    { dataIndex: 'roomCount', key: 'roomCount', width: 130, align: 'center' },
    { dataIndex: 'ratio', key: 'ratio', width: 50, align: 'center' },
    { dataIndex: 'numberOfRooms', key: 'numberOfRooms', width: 100, align: 'right', render: (number, data, index) => (index === 1 ? renderTotalTargetField(number) : numberWithComma(number, { fixed: 1 })) },
    { dataIndex: 'numberOfSeats', key: 'numberOfSeats', width: 40, align: 'right', render: (number) => (number ? numberWithComma(number, { fixed: 1 }) : '') },
    { dataIndex: 'delete', width: 30 },
  ];

  const renderTotalTargetField = (target) => {
    if (!formData[AREA_TARGET_FORM.TOTAL_AREA_FIELD]) {
      return 0;
    }
    const percentage = (target * 100) / formData[AREA_TARGET_FORM.TOTAL_AREA_FIELD];

    return (
      <Tooltip title={T.translate('AREA_TARGET_FORM_PSF_ERROR')} placement="bottom">
        <RowText warning={percentage >= 20}>{`${sqfToSqmText(target, isImperial)} (${numberWithComma(percentage || 0, { fixed: 1, suffix: '%' })})`}</RowText>
      </Tooltip>
    );
  };

  const addNewRow = () => {
    const newRowData = {
      key: uuidv4(),
      roomType: roomTypes[0].key,
      otherText: '',
      roomSize: roomTypes[0].defaultRange,
      roomCount: TEST_FIT_GENERAL.PSF.RATIO_PER_MEMBERS,
      ratio: 1,
      numberOfRooms: 1,
      numberOfSeats: 0,
    };

    const currentData = formData[AREA_TARGET_FORM.PSF_A_FIELD] || [];

    setFieldsValue({ [AREA_TARGET_FORM.PSF_A_FIELD]: [...currentData, newRowData] });
    handleOnChange();
  };

  const handleRatioChange = (e, rowData) => {
    const newValues = {
      ratio: e,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.PSF_A_FIELD, form, newValues, updateEstimations);
  };

  const handleRoomsChange = (e, rowData) => {
    const newValues = {
      numberOfRooms: e,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.PSF_A_FIELD, form, newValues, updateEstimations);
  };

  const handleRoomSizeChange = (e, rowData) => {
    const newValues = {
      roomSize: e,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.PSF_A_FIELD, form, newValues, updateEstimations);
  };

  const handleRoomTypeChange = (value, rowData) => {
    const newValues = {
      roomType: value,
      otherText: '',
      roomSize: roomTypes[getRoomTypeIndex(value)].defaultRange,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.PSF_A_FIELD, form, newValues, updateEstimations);
  };

  const handleRoomTypeFreeTextChange = (e, rowData) => {
    const newValues = {
      otherText: e.target.value,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.PSF_A_FIELD, form, newValues, updateEstimations);
  };

  const handleRoomCountChange = (key, rowData) => {
    const newValues = {
      roomCount: key,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.PSF_A_FIELD, form, newValues, updateEstimations);
  };

  const updateEstimations = (data) => {
    const totalLsf = lodashGet(formData[AREA_TARGET_FORM.TOTAL_LSF_FIELD], '[0]');
    const estimateBy = lodashGet(totalLsf, `${roomCount[getRoomCountIndex(data.roomCount)].totalLsfKey}`);
    const numberOfSeatsInPreset = roomTypes[getRoomTypeIndex(data.roomType)].seats;

    const numberOfRooms = isRoomCount ? data.numberOfRooms : Math.round(estimateBy / data.ratio);
    const ratio = isRoomCount ? Math.round(totalLsf.numberOfMembers / data.numberOfRooms) : data.ratio;
    const averageRange = (data.roomSize[0] + data.roomSize[1]) / 2;
    const numberOfSeats = numberOfSeatsInPreset ? Math.round(numberOfRooms * numberOfSeatsInPreset) : (Math.round(averageRange / 25) * numberOfRooms);

    return {
      roomCount: isRoomCount ? TEST_FIT_GENERAL.PSF.RATIO_PER_MEMBERS : data.roomCount,
      ratio: lodashIsFinite(ratio) ? ratio : 0,
      numberOfRooms: lodashIsFinite(numberOfRooms) ? numberOfRooms : 0,
      numberOfSeats: lodashIsFinite(numberOfSeats) ? numberOfSeats : 0,
    };
  };

  const updateTotal = (updateTotalPsf, psfData) => {
    const numberOfRooms = psfData.reduce((total, current) => ({ numberOfRooms: total.numberOfRooms + current.numberOfRooms }), { numberOfRooms: 0 });
    const numberOfSeats = psfData.reduce((total, current) => ({ numberOfSeats: total.numberOfSeats + current.numberOfSeats }), { numberOfSeats: 0 });
    const psfPerMember = numberOfSeats.numberOfSeats ? lodashGet(formData[AREA_TARGET_FORM.TOTAL_LSF_FIELD], '[0].numberOfMembers') / numberOfSeats.numberOfSeats : 0;
    const psfPerOffice = numberOfSeats.numberOfSeats ? lodashGet(formData[AREA_TARGET_FORM.TOTAL_LSF_FIELD], '[0].numberOfRooms') / numberOfSeats.numberOfSeats : 0;

    const averageSfForEachOffice = psfData.map((office) => ((office.roomSize[0] + office.roomSize[1]) / 2) * office.numberOfRooms);
    const totalSF = lodashIsEmpty(averageSfForEachOffice) ? 0 : averageSfForEachOffice.reduce((total, current) => (total + current));

    updateTotalPsf([
      {
        key: 'TOTAL',
        roomType: T.translate('AREA_TARGET_FORM_PSF_TOTAL'),
        roomSize: '',
        roomCount: '',
        ratio: '',
        numberOfRooms: numberOfRooms.numberOfRooms,
        numberOfSeats: numberOfSeats.numberOfSeats,
      },
      {
        key: '1',
        roomType: T.translate(isImperial ? 'AREA_TARGET_FORM_PSF_TOTAL_SF' : 'AREA_TARGET_FORM_PSF_TOTAL_SM'),
        roomSize: '',
        roomCount: '',
        ratio: '',
        numberOfRooms: totalSF,
        numberOfSeats: '',
      },
      {
        key: '2',
        roomType: T.translate('AREA_TARGET_FORM_PSF_PSF_PER_MEMBER'),
        roomSize: '',
        roomCount: '',
        ratio: '',
        numberOfRooms: psfPerMember,
        numberOfSeats: '',
      },
      {
        key: '3',
        roomType: T.translate('AREA_TARGET_FORM_PSF_PSF_PER_OFFICE'),
        roomSize: '',
        roomCount: '',
        ratio: '',
        numberOfRooms: psfPerOffice,
        numberOfSeats: '',
      },
    ]);
  };

  return (
    <React.Fragment>
      <StyledFormItem name={AREA_TARGET_FORM.PSF_A_FIELD} valuePropName="dataSource">
        <SmallStyledTable loading={disabled} pagination={false} columns={columns} />
      </StyledFormItem>
      <AddNewRowButton disabled={disabled} onClick={addNewRow} text={T.translate('AREA_TARGET_FORM_PSF_ADD_ROOM_BUTTON')} />
      <SmallStyledTable loading={disabled} pagination={false} columns={totalColumns} dataSource={totalRsfData} showHeader={false} bold rowClassName={(row) => row.key === 'TOTAL' && 'ant-table-total-row'} />
    </React.Fragment>
  );
};

AreaTargetFormPsf.propTypes = {
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  isImperial: PropTypes.bool,
};

export default React.memo(AreaTargetFormPsf);
