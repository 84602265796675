import React from 'react';

// currentcolor
export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.0078 20.32V29.2547H17.8723V20.32H11.0078ZM11.8823 21.1945H16.9962V28.3786H11.8839L11.8823 21.1945Z" fill="currentcolor" />
    <path d="M10.9504 5.20465V16.876H29.0482V5.20465H10.9504ZM11.8249 6.07909H28.1738V15.9999L11.8249 16.0016V6.07909Z" fill="currentcolor" />
    <path d="M8.26779 0.750557C7.30146 0.752197 6.52051 1.53478 6.52051 2.49948V37.5006C6.52051 38.4653 7.30146 39.2479 8.26779 39.2495H31.7286C32.6949 39.2495 33.4791 38.4669 33.4791 37.5006V2.49948C33.4791 1.53314 32.6949 0.750557 31.7286 0.750557H8.26779ZM8.26779 1.625H31.7286C32.2125 1.625 32.6047 2.0171 32.6047 2.49944V37.5006C32.6047 37.9829 32.2125 38.375 31.7286 38.375H8.26779C7.78546 38.3734 7.39499 37.9829 7.39499 37.5006V2.49944C7.39499 2.01875 7.78546 1.62663 8.26779 1.625V1.625Z" fill="currentcolor" />
    <path d="M23.1303 21.475H21.1665V19.8689H23.1303V21.475Z" fill="currentcolor" />
    <path d="M28.8974 21.475H26.9319V19.8689H28.8974V21.475Z" fill="currentcolor" />
    <path d="M23.1303 25.3731H21.1665V23.7669H23.1303V25.3731Z" fill="currentcolor" />
    <path d="M28.8974 25.3731H26.9319V23.7669H28.8974V25.3731Z" fill="currentcolor" />
    <path d="M23.1303 29.2711H21.1665V27.6649H23.1303V29.2711Z" fill="currentcolor" />
    <path d="M28.8974 29.2711H26.9319V27.6649H28.8974V29.2711Z" fill="currentcolor" />
    <path d="M23.1303 33.1691H21.1665V31.5629H23.1303V33.1691Z" fill="currentcolor" />
    <path d="M28.8974 33.1691H26.9319V31.5629H28.8974V33.1691Z" fill="currentcolor" />
    <path d="M16.4562 34.0914H12.4268V32.6116H16.4545L16.4562 34.0914Z" fill="currentcolor" />
    <path d="M20.3428 11.7508C20.4954 11.8312 20.5922 11.9887 20.5971 12.1609C20.6021 12.3316 20.5135 12.494 20.3658 12.5826C20.2181 12.6712 20.0344 12.6745 19.8835 12.5891C19.7342 12.5038 19.6423 12.3447 19.6407 12.1724H18.5579C18.5579 12.8533 18.9992 13.4554 19.6489 13.6605V14.3151H20.7481V13.6014C21.2961 13.3603 21.657 12.8287 21.6783 12.2299C21.6997 11.6327 21.3781 11.0749 20.8498 10.7943L20.0016 10.3432C19.8408 10.271 19.7342 10.1184 19.7211 9.94284C19.7079 9.76894 19.79 9.60159 19.9376 9.50642C20.0853 9.41126 20.2723 9.4047 20.4249 9.48837C20.5791 9.57204 20.6743 9.73283 20.6743 9.90837H21.7587C21.7587 9.25867 21.3568 8.67626 20.7481 8.44656V7.7657H19.6489V8.4482V8.44656C19.0747 8.66312 18.6793 9.19634 18.6416 9.80828C18.6022 10.4202 18.9254 10.9994 19.4668 11.2865L20.3428 11.7508Z" fill="currentcolor" />
    <path d="M14.8912 10.3434C14.732 10.2712 14.6254 10.1169 14.6123 9.94304C14.6008 9.76914 14.6845 9.60179 14.8305 9.50662C14.9781 9.4131 15.1652 9.4049 15.3178 9.48857C15.472 9.57224 15.5671 9.73302 15.5671 9.90857H16.6499C16.6499 9.25724 16.248 8.67646 15.6393 8.44676V7.7659H14.5385V8.4484V8.44676C13.9643 8.66332 13.5705 9.19654 13.5311 9.80848C13.4918 10.4204 13.815 10.9996 14.358 11.2867L15.2325 11.751C15.3851 11.8314 15.4819 11.9889 15.4868 12.1595C15.4901 12.3318 15.4031 12.4942 15.2555 12.5828C15.1078 12.6714 14.924 12.673 14.7731 12.5893C14.6238 12.504 14.5303 12.3449 14.5303 12.1726H13.4475C13.4475 12.8535 13.8888 13.4556 14.5385 13.6607V14.3153H15.6378V13.6016C16.1857 13.3621 16.5467 12.8289 16.5697 12.2317C16.5926 11.6345 16.2711 11.0751 15.7428 10.7945L14.8912 10.3434Z" fill="currentcolor" />
    <path d="M25.1105 10.3434C24.9497 10.2712 24.8431 10.1186 24.83 9.94304C24.8169 9.76914 24.8989 9.60179 25.0465 9.50662C25.1942 9.41145 25.3812 9.4049 25.5338 9.48857C25.688 9.57224 25.7832 9.73302 25.7832 9.90857H26.866C26.866 9.25724 26.4641 8.67646 25.8554 8.44676V7.7659H24.7562V8.4484V8.44676C24.1803 8.66332 23.7866 9.19654 23.7472 9.80848C23.7079 10.4204 24.031 10.9996 24.5741 11.2867L25.4485 11.751C25.6011 11.8314 25.6979 11.9889 25.7028 12.1595C25.7078 12.3318 25.6192 12.4942 25.4715 12.5828C25.3239 12.6714 25.1401 12.673 24.9892 12.5893C24.8399 12.504 24.748 12.3449 24.748 12.1726H23.6636C23.6636 12.8535 24.1049 13.4556 24.7546 13.6607V14.3153H25.8538V13.6016C26.4018 13.3621 26.7644 12.8289 26.7857 12.2317C26.8087 11.6345 26.4871 11.0751 25.9605 10.7945L25.1105 10.3434Z" fill="currentcolor" />
  </svg>
);
