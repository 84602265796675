import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import T from 'i18n-react';
import styled from 'styled-components';

export const GridTileWrapper = styled.div`
  height: 63px;
  margin: 4px;
  width: ${({ size }) => `${(size || 3) * 21}px`};
  max-width: 500px;
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.colors.textColor};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  border: 3px solid ${({ theme, selected }) => (selected ? theme.colors.primaryColor : 'transparent')};
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.18);

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? 0.3 : 0.8)};
  }
`;

export const GridTileTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const GridTileText = styled.div`
  font-weight: ${({ theme, bold }) => (bold ? theme.font.weight.bold : theme.font.weight.normal)};
  height: 20px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 5px;
`;

const GridTile = (props) => {
  const { color, onClick, selected, disabled, size, title, subtitle, tooltip } = props;

  return (
    <Tooltip title={T.translate(tooltip)} mouseEnterDelay={1.2} color={color}>
      <GridTileWrapper
        disabled={disabled}
        size={size < 3 ? 3 : size}
        color={color}
        onClick={() => !disabled && onClick()}
        selected={selected}
      >
        <GridTileTextWrapper>
          <GridTileText bold>{T.translate(title)}</GridTileText>
          <GridTileText>{T.translate(`${subtitle}`)}</GridTileText>
        </GridTileTextWrapper>
      </GridTileWrapper>
    </Tooltip>
  );
};

GridTile.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  tooltip: PropTypes.string,
};

export default GridTile;
