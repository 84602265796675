import lodashWithout from 'lodash/without';
import lodashIncludes from 'lodash/includes';
import lodashSum from 'lodash/sum';
import { allUnitKeyTypes } from 'constants/feasibilityConts';
import { getPolygonArea } from 'utils/algorithms/algorithmHelpers';
import { getBuildingStoriesData, getStoryUnits } from '../model/feasibilityDataExtractors';

export const getRoofUnits = (buildingInfo) => {
  // get the last floor that has an apartments from each mass
  const lastFloorsWithApartments = Object.values(buildingInfo.masses).map((buildingData) => {
    // loop that start's from last floor and going down
    const { storyGroups: sortedFloors } = getBuildingStoriesData(buildingData);

    for (let i = sortedFloors.length - 1; i !== 0; i--) {
      const units = getStoryUnits(sortedFloors[i].typicalStory);
      const allFloorUnits = units.map((unit) => unit.name);
      if (lodashWithout(allUnitKeyTypes, ...allFloorUnits).length !== allUnitKeyTypes.length) {
        return sortedFloors[i];
      }
    }
  }).filter((f) => f);

  const allApartmentsFromLastFloors = [];
  // filter each unit in floor if its an apartment by pushing it to allApartmentsFromLastFloors
  lastFloorsWithApartments.forEach((floor) => {
    const units = getStoryUnits(floor);
    units.forEach((unit) => {
      if (lodashIncludes(allUnitKeyTypes, unit.name)) {
        allApartmentsFromLastFloors.push(unit);
      }
    });
  });

  return allApartmentsFromLastFloors;
};

export const getRoofArea = (buildingInfo) => {
  const allApartmentsFromLastFloors = getRoofUnits(buildingInfo);

  // returns sum of all units polygon in square area, in feasibility the data is in square meters
  return lodashSum(allApartmentsFromLastFloors.map((unit) => getPolygonArea(unit.polygon.boundary)));
};
