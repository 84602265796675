import React from 'react';
import { useSelector } from 'react-redux';
import T from 'i18n-react';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashGet from 'lodash/get';
import { useHistory } from 'react-router';
import { feasibilityReportSelector } from 'store/swappProfile/selectors';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import {
  VerticalTitleContentWrapper,
  ContentWrapper,
  ContentImage,
  ContentColumnWrapper,
  ContentImagesWrapper,
  ContentRowWrapper,
  ContentImagesWithLabelWrapper,
  ContentImageLabel,
  ContentTitle,
  LegendWrapper,
  LegendColor,
  StyledText,
} from '../ReportPages.styles';

const Section = () => {
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const reportData = useSelector((state) => feasibilityReportSelector(state, locationData.profileId));
  const sectionData = lodashGet(reportData, 'design.sectionData');
  const unitsColors = lodashGet(reportData, 'unitsColors');

  if (lodashIsEmpty(sectionData)) {
    return null;
  }

  return (
    <VerticalTitleContentWrapper>
      <ContentTitle>{T.translate('Section')}</ContentTitle>
      <ContentRowWrapper>
        <ContentColumnWrapper width={230}>

          {/* ======== legend ======== */}
          <ContentColumnWrapper hasColor marginBottom={20}>
            {unitsColors.map((item, index) => (
              <LegendWrapper key={index}>
                {item.colors.map((color) => <LegendColor key={color} color={color} />)}
                <StyledText middle>{item.label}</StyledText>
              </LegendWrapper>
            ))}
          </ContentColumnWrapper>

          {/* ======== street maps ======== */}
          {lodashGet(sectionData, 'streetMaps', []).map((map, index) => (
            <ContentImage key={index} src={map} width={180} height={140} marginBottom={10} />
          ))}
        </ContentColumnWrapper>

        {/* ======== images ======== */}
        <ContentWrapper>
          <ContentImagesWrapper width={830}>
            {lodashGet(sectionData, 'renders', []).map((image, index) => (
              <ContentImagesWithLabelWrapper key={index}>
                <ContentImage src={image.url} width={860} height={525} />
                {lodashGet(image, 'name') && <ContentImageLabel>{image.name}</ContentImageLabel>}
              </ContentImagesWithLabelWrapper>
            ))}
          </ContentImagesWrapper>
        </ContentWrapper>
      </ContentRowWrapper>
    </VerticalTitleContentWrapper>
  );
};

export default React.memo(Section);
