import React, { useEffect } from 'react';
import T from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Dropdown from 'components/common/Dropdown';
import { getUnitSystemByProjectIdAndUserSelector, setUnitSystem, changeUnitSystem } from 'store/userSettings';
import { saveToLocalStorage, getFromLocalStorage } from 'utils/helpers/localStoragehelpers';
import { imperialToggleStorageKey } from 'constants/localStorageKeys';
import { swappProjectSelector } from 'store/swappProfile';

const ImperialMetricToggleWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 15px;
  font-size: ${({ theme }) => theme.font.size.large};
  margin-top: 2px;
`;

const ImperialMetricToggle = () => {
  const dispatch = useDispatch();
  const swappProject = useSelector(swappProjectSelector);
  const projectId = swappProject && swappProject.id;
  const isImperial = useSelector((state) => getUnitSystemByProjectIdAndUserSelector(state, projectId));

  // on mount get settings from localStorage and set them in store
  useEffect(() => {
    const localStorageObject = getFromLocalStorage(imperialToggleStorageKey) || {};
    dispatch(setUnitSystem({ unitSystemObject: localStorageObject }));
  }, []);

  useEffect(() => {
    dispatch(changeUnitSystem({ isImperial, projectId }));
  }, [projectId, isImperial]);

  const handleChange = (value) => {
    const newValue = { [projectId]: value };
    saveToLocalStorage(imperialToggleStorageKey, newValue);
    dispatch(changeUnitSystem({ isImperial: value, projectId }));
  };

  if (!projectId) {
    return null;
  }
  const dropDownData = [
    { key: 'SQF', value: T.translate('SQF') },
    { key: 'SQM', value: T.translate('SQM') },
  ];

  return (
    <ImperialMetricToggleWrapper>
      <Dropdown
        defaultValue={isImperial ? 'SQF' : 'SQM'}
        selectedKey={isImperial ? 'SQF' : 'SQM'}
        dropDownData={dropDownData}
        onChange={(key) => handleChange(key === 'SQF')}
        trigger="hover"
      />
    </ImperialMetricToggleWrapper>
  );
};

export default ImperialMetricToggle;
