import React from 'react';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ForgeViewer from 'utils/forgeViewer/ForgeViewer';
import EmptyMessage from 'components/common/EmptyMessage';
import { getProfileByIdSelector } from 'store/swappProfile';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { ViewerUiPanelWrapper } from 'styles/commonComponents.styles';
import ViewerUiPanel from 'utils/swappViewer/components/ui/ViewerUiPanel';
import { WAITING_FOR_KEYS } from 'constants/feasibilityConts';
import { PROFILE_STATUS } from 'constants/profileConsts';
import GenerateRevitForm from './GenerateRevitForm';

const BuildContainerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const BuildContainer = () => {
  const locationData = parseLocationUrl(window.location);
  const profile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const forgeURN = lodashGet(profile, 'result.forgeURN');

  const isProfileLoading = lodashGet(profile, 'status') === PROFILE_STATUS.WAITING_FOR_EXTRA_DATA;
  const isWaitingFor = lodashGet(profile, `result.waitingFor[${WAITING_FOR_KEYS.REVIT_FILE}]`);
  const isLoading = isProfileLoading && isWaitingFor;

  return (
    <BuildContainerWrapper>
      {/* ============== UI ============== */}
      <ViewerUiPanelWrapper fitContent isColumn>
        <ViewerUiPanel width={515}>
          <GenerateRevitForm />
        </ViewerUiPanel>
      </ViewerUiPanelWrapper>

      {!forgeURN && (
        <div style={{ marginTop: '230px', marginLeft: '530px', width: 'calc(100% - 530px)' }}>
          <EmptyMessage description={T.translate(isLoading ? 'BUILD_CONTAINER_NO_REVIT_LOADING' : 'BUILD_CONTAINER_NO_REVIT')} />
        </div>
      )}

      {/* ============== viewer ============== */}
      {forgeURN && (
        <ForgeViewer
          isExternal={false}
          heightOffset={50}
          isImperial={isImperial}
          projectId={locationData.projectId}
          urn={forgeURN}
          sheetView
          backgroundColor={[242, 245, 250, 242, 245, 250]}
        />
      )}
    </BuildContainerWrapper>
  );
};

export default BuildContainer;
