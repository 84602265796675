import React, { memo } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';

const Mesh = (props) => {
  const { contour, holes = [], color, unlit, meshProps } = props;

  const faces = new THREE.Shape(contour.map((v) => new THREE.Vector2(...v)));
  holes.forEach((hole) => faces.holes.push(new THREE.Path([...hole].map((v) => new THREE.Vector2(...v)))));

  return (
    <mesh receiveShadow {...meshProps}>
      <extrudeGeometry attach="geometry" args={[faces, { depth: 1, bevelEnabled: false }]} />
      {unlit
        ? <meshBasicMaterial attach="material" color={color || 'hotpink'} />
        : <meshStandardMaterial attach="material" color={color || 'hotpink'} />}
    </mesh>
  );
};

Mesh.propTypes = {
  contour: PropTypes.array,
  holes: PropTypes.array,
  color: PropTypes.string,
  unlit: PropTypes.bool,
  meshProps: PropTypes.object,
};

export default memo(Mesh, (prevProps, nextProps) => prevProps.contour === nextProps.contour);
