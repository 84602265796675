import styled from 'styled-components';
import { Progress } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0,0,0,0.35);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledIcon = styled(LoadingOutlined)`
  font-size: 110px;
  color: ${({ theme }) => theme.colors.primaryColor};
`;

export const StyledProgress = styled(Progress)`
  background-color: rgba(255, 255, 255, .45);
  border-radius: 50%;
  
    .ant-progress-bg {
    background-color: ${({ theme }) => theme.colors.primaryColor};
  }
`;

export const Text = styled.div`
  margin-top: 10px;
  font-size: ${({ theme }) => theme.font.size.xxLarge};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.colors.gray_01};
`;
