/* eslint-disable no-restricted-globals */
import PropTypes from 'prop-types';
import React, { useRef, useCallback } from 'react';
import { useDrag } from 'react-use-gesture';
import * as THREE from 'three';
import { useThree } from 'react-three-fiber';
import { Circle } from '@react-three/drei';
import { vecAngle, vecSub } from 'utils/algorithms/algorithmHelpers';
import { notifyDragFinished } from '../helpers/ThreeHelpers';
import { changeCursor, changeToResizeCursorByAngle } from '../helpers/SelectionHelpers';
import { POINT_SIZE } from './constants';

const changeColor = (mesh, color) => {
  mesh.current.material.color = new THREE.Color(color);
  mesh.current.material.needsUpdate = true;
};

const TansformPoint = (props) => {
  const { pointIndex, onPointMoved, position, center, cursor, onPointerUp } = props;

  const mesh = useRef();
  const { camera } = useThree();

  const bindDrag = useDrag(
    ({ delta: [mx, my], event }) => {
      if (event) event.stopPropagation();
      if (mesh.current) {
        if (event.buttons === 1) {
          const globalQuaternion = new THREE.Quaternion();
          mesh.current.getWorldQuaternion(globalQuaternion);
          globalQuaternion.invert();
          const deltaVector = new THREE.Vector3(mx / camera.zoom, -my / camera.zoom, 0);
          deltaVector.applyQuaternion(globalQuaternion);
          mx = deltaVector.x;
          my = deltaVector.y;
          const newPoint = [position[0] + mx, position[1] + my];
          changeColor(mesh, 0x0000ff);
          onPointMoved(event, pointIndex, newPoint, [mx, my]);
        }
        if (event.type === 'pointerup') {
          changeColor(mesh, 0xffffff);
          if (onPointerUp) {
            onPointerUp(event);
          }
          notifyDragFinished();
        }
      }
    },
    { pointerEvents: true },
  );

  const onPointerOver = useCallback((e) => {
    e.stopPropagation();
    if (cursor) {
      changeCursor(cursor);
      return;
    }
    const ang = vecAngle(vecSub(center, [position[0], position[1]]));
    changeToResizeCursorByAngle(ang);
  }, [center, position]);

  const onPointerOut = useCallback(() => {
    changeCursor('default');
  }, []);

  return (
    <>
      <Circle
        {...props}
        args={[POINT_SIZE, 20]}
        position={position}
        {...bindDrag()}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
      >
        <meshBasicMaterial attach="material" color={0x000000} />
      </Circle>
      <Circle
        {...props}
        args={[POINT_SIZE * 0.8, 20]}
        ref={mesh}
        position={position}
        {...bindDrag()}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
      >
        <meshBasicMaterial attach="material" color={0xffffff} />
      </Circle>
    </>
  );
};

TansformPoint.propTypes = {
  pointIndex: PropTypes.number,
  center: PropTypes.number,
  onPointMoved: PropTypes.func,
  onPointerUp: PropTypes.func,
  position: PropTypes.array,
  withRotation: PropTypes.bool,
  cursor: PropTypes.string,
};

export default React.memo(TansformPoint);
