import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import styled from 'styled-components';
import { AREA_TARGET_FORM } from 'constants/fieldNames';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import ImperialMetricInput from '../../../common/ImperialMetricInput';
import { ColumnFormItem, TestFitInputNumber } from './AreaTargetFormContainer.styles';

const AreaTargetFormLayoutInfoWrapper = styled.div`
  display: flex;
`;

const AreaTargetFormLayoutInfo = (props) => {
  const { disabled, isImperial } = props;

  return (
    <AreaTargetFormLayoutInfoWrapper>
      {/* ========= Total Area ========= */}
      <ColumnFormItem
        name={AREA_TARGET_FORM.TOTAL_AREA_FIELD}
        disabled={disabled}
        label={`${T.translate('AREA_TARGET_FORM_RSF_LSF_TOTAL_AREA_LABEL')} (${T.translate(isImperial ? 'SQF' : 'SQM')})`}
        rules={[{ required: true, type: 'number', min: 0.001, message: T.translate('AREA_TARGET_FORM_RSF_LSF_TOTAL_AREA_ERROR') }]}
      >
        <ImperialMetricInput
          isImperial={isImperial}
          width={100}
          disabled={disabled}
          min={1}
          max={300000}
        />
      </ColumnFormItem>

      {/* ========= Number of employees ========= */}
      <ColumnFormItem
        name={AREA_TARGET_FORM.NUMBER_OF_EMPLOYEES}
        disabled={disabled}
        label={T.translate('AREA_TARGET_FORM_SIMPLE_FORM_EMPLOYEE_NUMBER')}
      >
        <TestFitInputNumber
          width={100}
          disabled={disabled}
          min={0}
          formatter={(value) => numberWithComma(value)}
        />
      </ColumnFormItem>
    </AreaTargetFormLayoutInfoWrapper>
  );
};

AreaTargetFormLayoutInfo.propTypes = {
  disabled: PropTypes.bool,
  isImperial: PropTypes.bool,
};

export default React.memo(AreaTargetFormLayoutInfo);
