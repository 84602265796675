import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIncludes from 'lodash/includes';
import { StyledTable, StyledCascader } from './AreaTargetFormContainer.styles';
import { StyledFormItem, DeleteIcon } from '../../../../styles/commonComponents.styles';
import AddNewRowButton from '../../../common/AddNewRowButton';
import { AREA_TARGET_FORM } from '../../../../constants/fieldNames';
import { uuidv4 } from '../../../../utils/helpers/uuidv4';
import { useComDidMount } from '../../../../utils/hooks';
import { deleteRow, updateRow, getRoomTypes } from '../../../../utils/helpers/tableHelpers';
import { TEST_FIT_OFFICE_TYPES, TEST_FIT_GENERAL } from '../../../../constants/dropdownKeys';
import deleteIcon from '../../../../styles/static/icons/comon/deleteIcon';

const fieldsWithRooms = [AREA_TARGET_FORM.LSF_ROOMS_FIELD, AREA_TARGET_FORM.PSF_A_FIELD, AREA_TARGET_FORM.PSF_B_FIELD, AREA_TARGET_FORM.ASF_FIELD, AREA_TARGET_FORM.FSF_FIELD];

const AreaTargetFormPositioning = (props) => {
  const { formData, form, form: { setFieldsValue }, disabled, handleOnChange } = props;
  const dropdownNoneOptions = { value: TEST_FIT_OFFICE_TYPES.NONE, label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_OFFICE_TYPES.NONE}`) };
  const availableRooms = getRoomTypes(formData, fieldsWithRooms);
  const [roomTypes, setRoomTypes] = useState([dropdownNoneOptions]);

  useComDidMount(() => {
    if (lodashIsEmpty(formData[AREA_TARGET_FORM.POSITIONING])) {
      addNewRow();
    }
  });

  useEffect(() => {
    updateRoomTypeList(getRoomTypes(formData, fieldsWithRooms));
  }, [
    formData[AREA_TARGET_FORM.LSF_ROOMS_FIELD], formData[AREA_TARGET_FORM.PSF_A_FIELD], formData[AREA_TARGET_FORM.PSF_B_FIELD],
    formData[AREA_TARGET_FORM.ASF_FIELD], formData[AREA_TARGET_FORM.FSF_FIELD], formData[AREA_TARGET_FORM.POSITIONING],
  ]);

  const updateRoomTypeList = (roomsArray) => {
    const allRoomsArrayWithOptions = roomsArray.map((item) => ({
      value: item.key,
      label: item.value,
      children: requirementTypeOptions,
    }));

    setRoomTypes(allRoomsArrayWithOptions);
  };

  const requirementTypeOptions = [
    {
      value: TEST_FIT_GENERAL.POSITIONING.CLOSE_TO,
      label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.CLOSE_TO}`),
      children: getRoomTypes(formData, fieldsWithRooms).map((item) => ({ value: item.key, label: item.value })),
    },
    {
      value: TEST_FIT_GENERAL.POSITIONING.ADJACENCY,
      label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.ADJACENCY}`),
      children: [
        { value: TEST_FIT_GENERAL.POSITIONING.EXTERIOR_WINDOWS, label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.EXTERIOR_WINDOWS}`) },
        { value: TEST_FIT_GENERAL.POSITIONING.FLOOR_CORNERS, label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.FLOOR_CORNERS}`) },
        { value: TEST_FIT_GENERAL.POSITIONING.BUILDING_CORE, label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.BUILDING_CORE}`) },
        { value: TEST_FIT_GENERAL.POSITIONING.ELEVATOR_ENTRANCE, label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.ELEVATOR_ENTRANCE}`) },
        { value: TEST_FIT_GENERAL.POSITIONING.AMENITY_AREA, label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.AMENITY_AREA}`) },
        { value: TEST_FIT_GENERAL.POSITIONING.RESTROOMS, label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.RESTROOMS}`) },
        { value: TEST_FIT_GENERAL.POSITIONING.FIRE_STAIRS, label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.FIRE_STAIRS}`) },
      ],
    },
    {
      value: TEST_FIT_GENERAL.POSITIONING.LOCATION,
      label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.LOCATION}`),
      children: [
        { value: TEST_FIT_GENERAL.POSITIONING.NORTH, label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.NORTH}`) },
        { value: TEST_FIT_GENERAL.POSITIONING.SOUTH, label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.SOUTH}`) },
        { value: TEST_FIT_GENERAL.POSITIONING.EAST, label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.EAST}`) },
        { value: TEST_FIT_GENERAL.POSITIONING.WEST, label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.WEST}`) },
      ],
    },
    {
      value: TEST_FIT_GENERAL.POSITIONING.SPREAD,
      label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.SPREAD}`),
      children: [
        { value: TEST_FIT_GENERAL.POSITIONING.EQUALLY, label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.EQUALLY}`) },
        { value: TEST_FIT_GENERAL.POSITIONING.TWO_SIDES, label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.TWO_SIDES}`) },
        { value: TEST_FIT_GENERAL.POSITIONING.CENTER, label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.CENTER}`) },
        { value: TEST_FIT_GENERAL.POSITIONING.OUTER_RING, label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.OUTER_RING}`) },
        { value: TEST_FIT_GENERAL.POSITIONING.FLOOR_SIDE, label: T.translate(`AREA_TARGET_FORM_DROPDOWNS.${TEST_FIT_GENERAL.POSITIONING.FLOOR_SIDE}`) },
      ],
    },
  ];

  const columns = [
    {
      dataIndex: 'positioning',
      key: 'positioning',
      render: (key, rowData) => (
        <StyledCascader
          value={key}
          allowClear={false}
          options={roomTypes}
          defaultValue={key}
          size="small"
          displayRender={(label) => {
            if (!lodashIsEmpty(label) && !lodashIncludes(availableRooms.map((r) => r.value), label[0])) {
              return T.translate('AREA_TARGET_FORM_POSITIONING_EMPTY_ROOM');
            }

            return label.join(' > ');
          }}
          placeholder={T.translate('AREA_TARGET_FORM_POSITIONING_PLACEHOLDER')}
          onChange={(e) => handlePositioningChange(e, rowData)}
        />
      ),
    },
    {
      dataIndex: 'delete',
      width: 50,
      align: 'center',
      render: (text, rowData) => (
        <DeleteIcon component={deleteIcon} onClick={() => deleteRow(handleOnChange, rowData.key, form, AREA_TARGET_FORM.POSITIONING)} />
      ),
    },
  ];

  const addNewRow = () => {
    const newRowData = {
      key: uuidv4(),
      positioning: [],
    };

    const currentData = formData[AREA_TARGET_FORM.POSITIONING] || [];

    setFieldsValue({ [AREA_TARGET_FORM.POSITIONING]: [...currentData, newRowData] });
    handleOnChange();
  };

  const handlePositioningChange = (e, rowData) => {
    const newValues = {
      positioning: e,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.POSITIONING, form, newValues);
  };

  return (
    <React.Fragment>
      <StyledFormItem name={AREA_TARGET_FORM.POSITIONING} valuePropName="dataSource">
        <StyledTable loading={disabled} showHeader={false} pagination={false} columns={columns} />
      </StyledFormItem>
      <AddNewRowButton disabled={disabled} onClick={addNewRow} text={T.translate('AREA_TARGET_FORM_POSITIONING_OFFICE_BUTTON')} />
    </React.Fragment>
  );
};

AreaTargetFormPositioning.propTypes = {
  form: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

export default React.memo(AreaTargetFormPositioning);
