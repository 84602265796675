/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// import esriConfig from '@arcgis/core/config';
import '@arcgis/core/assets/esri/themes/light/main.css';
import styled from 'styled-components';
import { ARCGIS_TOKEN, TOPO_VECTOR_MAP_ID, DEFAULT_CENTER, ZOOM_LEVELS } from './ArcGisConsts';
import { getEsriModules, loadEsriModules } from './ArcGisMapHelpers';

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  z-index: 20;
  background-color: ${({ theme }) => theme.colors.white};
  
  canvas {
    border-radius: ${({ theme }) => theme.other.borderRadius};
  }
`;

export const MAP_CLICK = 'MAP_CLICK';

const CLICK_EVENT = 'click';
const POINTER_MOVE = 'pointer-move';
const DRAG = 'drag';
const MOUSE_WHEEL = 'mouse-wheel';

const ArcGisMapImpl = React.forwardRef(({ onPointerMove, onMapReady, onMapClicked, onMapViewChanged, mapRef, baseMapLayerRef }, viewRef) => {
  const listenerRef = useRef({});
  const initialaize = async () => {
    await loadEsriModules();
    const { Basemap, VectorTileLayer, MapView, Map, esriConfig } = getEsriModules();
    esriConfig.apiKey = ARCGIS_TOKEN;
    baseMapLayerRef.current = new VectorTileLayer({
      portalItem: {
        id: TOPO_VECTOR_MAP_ID,
      },
    });
    const basemap = new Basemap({
      baseLayers: [
        baseMapLayerRef.current,
      ],
      title: 'Just the topo without hillshade',
    });

    mapRef.current = new Map({
      basemap,
    });

    viewRef.current = new MapView({
      center: DEFAULT_CENTER,
      container: 'arcgis-map',
      map: mapRef.current,
      zoom: ZOOM_LEVELS.DEFAULT,
      constraints: {
        rotationEnabled: false,
      },
    });

    viewRef.current.when(() => {
      onMapReady();
      setListeners();
    }, (error) => {
      console.log("The view's resources failed to load: ", error);
    });
  };

  const setListeners = () => {
    Object.values(listenerRef.current).forEach((listener) => {
      listener.remove();
    });

    // const { watchUtils } = getEsriModules();
    // watchUtils.watch(viewRef.current, 'extent', onMapClicked);

    listenerRef.current[CLICK_EVENT] = viewRef.current.on(CLICK_EVENT, onMapClicked);
    listenerRef.current[POINTER_MOVE] = viewRef.current.on(POINTER_MOVE, onPointerMove);
    listenerRef.current[DRAG] = viewRef.current.on(DRAG, onMapViewChanged);
    listenerRef.current[MOUSE_WHEEL] = viewRef.current.on(MOUSE_WHEEL, onMapViewChanged);
  };

  useEffect(() => {
    if (viewRef.current) {
      setListeners();
    }
  });

  useEffect(() => {
    initialaize();
  }, []);

  return (
    <MapWrapper>
      <div id="arcgis-map" style={{ width: '100%', height: '100%', border: '0 solid', position: 'relative' }} />
    </MapWrapper>
  );
});

ArcGisMapImpl.propTypes = {
  onPointerMove: PropTypes.func,
  onMapClicked: PropTypes.func,
  onMapReady: PropTypes.func,
  onMapViewChanged: PropTypes.func,
  mapRef: PropTypes.any,
  baseMapLayerRef: PropTypes.any,
};

export default ArcGisMapImpl;
