import React from 'react';
import PropTypes from 'prop-types';
import DashedLine from './DashedLine';

const DashedShape = ({ points, lineWidth, color, dashSize = 1, zIndex = 0, meshProps }) => (
  points.map((point, index) => {
    const from = point;
    const to = points[(index + 1) % points.length];
    return (
      <DashedLine
        from={from}
        to={to}
        width={lineWidth}
        color={color}
        zIndex={zIndex}
        dashSize={dashSize}
        meshProps={meshProps}
      />
    );
  })
);

DashedShape.propTypes = {
  color: PropTypes.string,
  lineWidth: PropTypes.number,
  dashSize: PropTypes.number,
  zIndex: PropTypes.number,
  meshProps: PropTypes.object,
};

export default React.memo(DashedShape);
