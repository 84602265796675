import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import lodashIsFinite from 'lodash/isFinite';
import { useOverridableSetting } from 'store/userSettings';
import { getTestFitStandards } from 'utils/helpers/parseTestFitStandards';
import { SmallStyledTable, StyledFormItem, StyledInputNumber, DeleteIcon } from 'styles/commonComponents.styles';
import { AREA_TARGET_FORM } from 'constants/fieldNames';
import { uuidv4 } from 'utils/helpers/uuidv4';
import { updateRow, deleteRow, updateTable } from 'utils/helpers/tableHelpers';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { TEST_FIT_GENERAL } from 'constants/dropdownKeys';
import { FORM_INPUT_TYPE } from 'utils/helpers/testFitContainerHelprs';
import deleteIcon from '../../../../styles/static/icons/comon/deleteIcon';
import AddNewRowButton from '../../../common/AddNewRowButton';
import Dropdown from '../../../common/Dropdown';
import FromToNumInput from '../../../common/FromToNumInput';

const AreaTargetFormPsfB = (props) => {
  const { formData, form, form: { setFieldsValue }, disabled, handleOnChange } = props;
  const isRoomCount = formData[AREA_TARGET_FORM.INPUT_TOGGLE] === FORM_INPUT_TYPE.ROOM_COUNT;
  const testFitStandard = useOverridableSetting('testFitStandard', 'DEFAULT');
  const roomTypes = useMemo(() => {
    const fullRoomList = getTestFitStandards().standards[testFitStandard].roomDefinitions.PSF;
    return fullRoomList.filter((room) => room.subCategory === 'PSFB');
  }, [testFitStandard]);

  // update table when total LSF changes
  useEffect(() => {
    if (!disabled) {
      updateTable(handleOnChange, form, AREA_TARGET_FORM.PSF_B_FIELD, updateEstimations);
    }
  }, [formData[AREA_TARGET_FORM.TOTAL_LSF_FIELD]]);

  const getRoomTypeIndex = (key) => {
    const unitIndex = roomTypes.findIndex((office) => office.key === key);
    return !unitIndex || unitIndex === -1 ? 0 : unitIndex;
  };

  const roomCount = [
    { key: TEST_FIT_GENERAL.PSF.RATIO_PER_MEMBERS, value: 'AREA_TARGET_FORM_PSF_ROOM_COUNT_RATIO_PER_MEMBERS', totalLsfKey: 'numberOfMembers' },
    { key: TEST_FIT_GENERAL.PSF.RATIO_PER_OFFICES, value: 'AREA_TARGET_FORM_PSF_ROOM_COUNT_RATIO_PER_OFFICES', totalLsfKey: 'numberOfRooms' },
  ];
  const getRoomCountIndex = (key) => roomCount.findIndex((room) => room.key === key);

  const columns = [
    {
      dataIndex: 'roomType',
      key: 'roomType',
      width: 135,
      ellipsis: true,
      align: 'center',
      render: (key, rowData) => (
        <Dropdown
          dropDownData={roomTypes}
          selectedKey={key}
          onChange={(value) => handleRoomTypeChange(value, rowData)}
          inputValue={rowData.otherText}
          onInputChange={(e) => handleRoomTypeFreeTextChange(e, rowData)}
          ellipsisWidth={148}
        />
      ),
    },
    {
      dataIndex: 'roomSize',
      key: 'roomSize',
      align: 'center',
      width: 95,
      render: (officeRange, rowData) => (
        <FromToNumInput
          value={officeRange}
          defaultValue={roomTypes[getRoomTypeIndex(rowData.roomType)].defaultRange}
          range={roomTypes[getRoomTypeIndex(rowData.roomType)].minMaxRange}
          overwriteMax={5000}
          onChange={(e) => handleRoomSizeChange(e, rowData)}
          step={roomTypes[getRoomTypeIndex(rowData.roomType)].step}
        />
      ),
    },
    {
      dataIndex: 'roomCount',
      key: 'roomCount',
      width: 130,
      align: 'center',
      ellipsis: true,
      render: (key, rowData) => (isRoomCount
        ? T.translate('AREA_TARGET_FORM_PSF_ROOM_COUNT_RATIO_PER_MEMBERS')
        : <Dropdown dropDownData={roomCount} selectedKey={key} onChange={(value) => handleRoomCountChange(value, rowData)} />),
    },
    {
      dataIndex: 'ratio',
      key: 'ratio',
      width: 50,
      align: 'center',
      render: (ratio, rowData) => (isRoomCount
        ? numberWithComma(ratio)
        : <StyledInputNumber onChange={(e) => handleRatioChange(e, rowData)} min={1} height={25} value={ratio} />
      ),
    },
    {
      dataIndex: 'numberOfRooms',
      key: 'numberOfRooms',
      width: 40,
      align: 'center',
      render: (number, rowData) => (isRoomCount
        ? <StyledInputNumber onChange={(e) => handleRoomsChange(e, rowData)} height={25} min={1} value={number} parser={(value) => value.replace('.', '')} />
        : numberWithComma(number)
      ),
    },
    {
      title: T.translate('AREA_TARGET_FORM_PSF_ESTIMATED_SEATS'),
      dataIndex: 'numberOfSeats',
      key: 'numberOfSeats',
      width: 40,
      align: 'center',
    },
    {
      dataIndex: 'delete',
      width: 20,
      align: 'center',
      render: (text, rowData) => (
        <DeleteIcon component={deleteIcon} onClick={() => deleteRow(handleOnChange, rowData.key, form, AREA_TARGET_FORM.PSF_B_FIELD)} />
      ),
    },
  ];

  const addNewRow = () => {
    const newRowData = {
      key: uuidv4(),
      otherText: '',
      roomType: roomTypes[0].key,
      roomSize: roomTypes[0].defaultRange,
      roomCount: TEST_FIT_GENERAL.PSF.RATIO_PER_MEMBERS,
      ratio: 1,
      numberOfRooms: 1,
      numberOfSeats: roomTypes[0].seats,
    };

    const currentData = formData[AREA_TARGET_FORM.PSF_B_FIELD] || [];

    setFieldsValue({ [AREA_TARGET_FORM.PSF_B_FIELD]: [...currentData, newRowData] });
    handleOnChange();
  };

  const handleRatioChange = (e, rowData) => {
    const newValues = {
      ratio: e,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.PSF_B_FIELD, form, newValues, updateEstimations);
  };

  const handleRoomsChange = (e, rowData) => {
    const newValues = {
      numberOfRooms: e,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.PSF_B_FIELD, form, newValues, updateEstimations);
  };

  const handleRoomSizeChange = (e, rowData) => {
    const newValues = {
      roomSize: e,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.PSF_B_FIELD, form, newValues, updateEstimations);
  };

  const handleRoomTypeChange = (key, rowData) => {
    const newValues = {
      roomType: key,
      otherText: '',
      roomSize: roomTypes[getRoomTypeIndex(key)].defaultRange,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.PSF_B_FIELD, form, newValues, updateEstimations);
  };

  const handleRoomTypeFreeTextChange = (e, rowData) => {
    const newValues = {
      otherText: e.target.value,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.PSF_B_FIELD, form, newValues, updateEstimations);
  };

  const handleRoomCountChange = (key, rowData) => {
    const newValues = {
      roomCount: key,
    };

    updateRow(handleOnChange, rowData, AREA_TARGET_FORM.PSF_B_FIELD, form, newValues, updateEstimations);
  };

  const updateEstimations = (data) => {
    const totalLsf = lodashGet(formData[AREA_TARGET_FORM.TOTAL_LSF_FIELD], '[0]');
    const estimateBy = lodashGet(totalLsf, `${roomCount[getRoomCountIndex(data.roomCount)].totalLsfKey}`);
    const numberOfRooms = isRoomCount ? data.numberOfRooms : Math.round(estimateBy / data.ratio);
    const ratio = isRoomCount ? Math.round(totalLsf.numberOfMembers / data.numberOfRooms) : data.ratio;
    const numberOfSeatsInPreset = roomTypes[getRoomTypeIndex(data.roomType)].seats * (numberOfRooms || 1);

    return {
      roomCount: isRoomCount ? TEST_FIT_GENERAL.PSF.RATIO_PER_MEMBERS : data.roomCount,
      ratio: lodashIsFinite(ratio) ? ratio : 0,
      numberOfRooms: lodashIsFinite(numberOfRooms) ? numberOfRooms : 0,
      numberOfSeats: numberOfSeatsInPreset,
    };
  };

  return (
    <React.Fragment>
      <StyledFormItem name={AREA_TARGET_FORM.PSF_B_FIELD} valuePropName="dataSource">
        <SmallStyledTable loading={disabled} showHeader={false} pagination={false} columns={columns} />
      </StyledFormItem>
      <AddNewRowButton disabled={disabled} onClick={addNewRow} text={T.translate('AREA_TARGET_FORM_PSF_ADD_ROOM_BUTTON')} />
    </React.Fragment>
  );
};

AreaTargetFormPsfB.propTypes = {
  formData: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

export default React.memo(AreaTargetFormPsfB);
