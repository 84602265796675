import * as actionTypes from '../actions/actionTypes';

const initialState = {
  error: null,
  loading: false,
  isAuthChecked: false,
};

export const AuthReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SIGN_UP_REQUEST:
    case actionTypes.LOGIN_REQUEST:
    case actionTypes.RESET_PASS_REQUEST:
    case actionTypes.CREATE_PASS_REQUEST:
      return { ...state, error: null, loading: true };
    case actionTypes.SIGN_UP_ERROR:
    case actionTypes.LOGIN_ERROR:
    case actionTypes.RESET_PASS_ERROR:
    case actionTypes.CREATE_PASS_ERROR:
      return { ...state, error: payload, loading: false };
    case actionTypes.SIGN_UP_SUCCESS:
    case actionTypes.RESET_PASS_SUCCESS:
    case actionTypes.CREATE_PASS_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.LOGIN_SUCCESS:
    case actionTypes.CONFIRM_RESET_PASS_SUCCESS:
      return { ...state, loading: false, isAuthChecked: true };
    case actionTypes.SET_AUTH_CHECKED:
      return { ...state, isAuthChecked: true };
    default:
      return state;
  }
};
