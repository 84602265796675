import React from 'react';
// import T from 'i18n-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SingleAnalyisResult from './results';

export const EnergyResultsContainer = styled.div`
overflow-y: auto;
height: 100%;
padding-left: 18px;
`;

const FeasibilityResultEnergyAnalysis = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { profile, data, isImperial } = props;

  return (
    <EnergyResultsContainer>
      <SingleAnalyisResult data={data} isImperial={isImperial} />
    </EnergyResultsContainer>
  );
};

FeasibilityResultEnergyAnalysis.propTypes = {
  profile: PropTypes.object,
  data: PropTypes.object,
  isImperial: PropTypes.bool,
};

export default FeasibilityResultEnergyAnalysis;
