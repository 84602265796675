import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import T from 'i18n-react';
import { useHistory } from 'react-router';
import lodashIsEmpty from 'lodash/isEmpty';
import { feasibilityReportSelector } from 'store/swappProfile/selectors';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import {
  ContentImage,
  ContentImageLabel,
  ContentImagesWithLabelWrapper,
  ContentImagesWrapper,
  ContentTitle,
  ContentWrapper,
  VerticalTitleContentWrapper,
  ContentColumnWrapper,
  StyledText,
  ContentRowWrapper,
} from '../ReportPages.styles';
import ReportTitleDropdown from '../ReportTitleDropdown';

const description = 'The images show the shadows created during the equinox and Solstice of 2021.\n\nNote: 3D model and associated shadow and solar studies are indicative and subject to receiving accurate survey/Zmap with AOD levels.';

const Shadows = () => {
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const reportData = useSelector((state) => feasibilityReportSelector(state, locationData.profileId));
  const shadowData = lodashGet(reportData, 'environment.shadowData');
  const [currentDateIndex, setCurrentDateIndex] = useState(0);

  if (lodashIsEmpty(shadowData)) {
    return null;
  }

  const updateCurrentDateIndex = (key, update) => {
    update(key);
  };

  return (
    <VerticalTitleContentWrapper>
      <ContentTitle>{T.translate('Shadow Study')}</ContentTitle>
      <ContentRowWrapper>
        <ContentColumnWrapper width={250}>

          {/* ======== dropdown ======== */}
          <ReportTitleDropdown
            options={shadowData.map((item) => item.name)}
            handleChange={(key) => updateCurrentDateIndex(key, setCurrentDateIndex)}
            selectedIndex={currentDateIndex}
          />

          {/* ======== description ======== */}
          <StyledText size="xSmall">{T.translate(description)}</StyledText>
        </ContentColumnWrapper>

        {/* ======== images ======== */}
        <ContentWrapper>
          <ContentImagesWrapper width={270}>
            {shadowData[currentDateIndex].images.map((image, index) => (
              <ContentImagesWithLabelWrapper key={index}>
                <ContentImage src={image.url} width={270} height={190} />
                {lodashGet(image, 'label') && <ContentImageLabel>{`0${index + 1} - ${image.label}`}</ContentImageLabel>}
              </ContentImagesWithLabelWrapper>
            ))}
          </ContentImagesWrapper>
        </ContentWrapper>
      </ContentRowWrapper>
    </VerticalTitleContentWrapper>
  );
};

export default React.memo(Shadows);
