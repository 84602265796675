/* eslint-disable no-restricted-globals */
import React, { useEffect, useState, useRef, useLayoutEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as THREE from 'three';
import { geom } from 'jsts';
import { Text } from '@react-three/drei';
import { doPointInPoly, vecLerp, vecSub, vecAdd, vecDistance, vecLength, roundVecList, vecClone, getPolygonCentroid, getPolygonArea, transformPolygon } from 'utils/algorithms/algorithmHelpers';
import { changeCursor } from 'utils/swappViewer/helpers/SelectionHelpers';
import { bufferStraight } from 'utils/algorithms/jstsHelpers';
import { useKeyPress, useDebounce, useHistory } from 'utils/hooks';
import { sqfToSqmText } from 'utils/helpers/unitsHelpers';
import { FEASIBILITY_EDITOR_BUTTON_KEYS, SKETCH_SETTINGS } from 'constants/feasibilityConts';
import * as SketchToolReducer from 'store/sketchToolStore';
import { currentThemeSelector, useOverridableSetting } from 'store/userSettings';
import { useIsImperial } from 'store/storeHooks/useIsImperial';
import { buildLocalToGlobalMatrix, transformGeometry } from '@swapp/swappcommonjs/dist/utils/swpProjectModel';
import PlaneMesh from '../PlaneMesh';
import DragObject from '../DragObject';
import { LinesInProgress, BufferedOutline, ItemsBetweenTwoPoints, PolyLines, EditablePoint, ParkingEntrance, InteractionPlane } from '.';
import { MAIN_COLOR, MAX_CLICK_TIME } from './constants';
import { isIlligalToSend, chamfCoordinates, isSelfIntersecting, getColorBuyLengthAndId, getPropertiesBetweenTwoPoints, showWarningModal, getSnappedPosition, projectPointToPolygon } from './sketchHelpers';
import { useHalfTubeWidth, useLotAngles, useAnglesFromLastLine } from './sketchHooks';

const POINTS_EDITOR_HEIGHT = 220; // A bit more then the lot outline

// A component that is used for mass creation and sketch parking
const SketchTool = ({ updateServerReducer, type, editorType, initialParking, sitePolygon, isOrthographic }) => {
  const dispatch = useDispatch();
  const isImperial = useIsImperial();
  const parkingEntrancePosition = useSelector(({ sketchTool }) => sketchTool.parkingEntrancePosition);
  const setParkingEntrancePosition = (p) => dispatch(SketchToolReducer.setParkingEntrancePosition(p));
  const points = useSelector(({ sketchTool }) => sketchTool.points);
  const setPoints = (p) => dispatch(SketchToolReducer.setPoints(p));
  const pointInProgress = useSelector(({ sketchTool }) => sketchTool.pointInProgress);
  const setPointInProgress = (p) => dispatch(SketchToolReducer.setPointInProgress(p));
  const isClosed = useSelector(({ sketchTool }) => sketchTool.isClosed);
  const setIsClosed = (p) => dispatch(SketchToolReducer.setIsClosed(p));
  const isCreating = useSelector(({ sketchTool }) => sketchTool.isCreating);
  const movingVector = useSelector(({ sketchTool }) => sketchTool.movingVector);
  const movingPoint = useSelector(({ sketchTool }) => sketchTool.movingPoint);
  const massInSketch = useSelector(({ editor }) => editor.massInSketch);
  const transforms = useSelector(({ editor }) => editor.massTransformer.transforms);
  const sketchSettingsKey = useOverridableSetting('sketchSettingsKey', 'default');
  const sketchSettings = SKETCH_SETTINGS[sketchSettingsKey];

  const currentTheme = useSelector(currentThemeSelector);
  const middlePointJustCreated = useRef(null);
  const setGlobalPosition = (p) => dispatch(SketchToolReducer.setGlobalPosition(p));
  const globalPosition = useSelector(({ sketchTool }) => sketchTool.globalPosition);

  const isEnterPressed = useKeyPress('Enter');
  const isShiftPressed = useKeyPress('Shift');

  const planeClickedTime = useRef(0);
  const [isParkingEntranceInteracting, setIsParkingEntranceInteracting] = useState(false); // It is used to disable the dashed lines while hovering
  const isUpdatingServer = useRef(false);
  const isDirty = useRef(false);

  const debouncedPoints = useDebounce(points, 500);
  const isElementMoving = !isNaN(movingVector) || !isNaN(movingPoint);
  const isPolygon = type === SketchTool.Types.Polygon;

  const { updateHistory, setStateWithHistory: setPoinsWithHistory } = useHistory(setPoints, { useKeyboardshortcut: true });

  const halfTubeWidth = useHalfTubeWidth(sketchSettings);

  const midPoints = useMemo(() => {
    const list = [];
    const len = isClosed ? points.length : points.length - 1;
    for (let i = 0; i < len; i++) {
      const secondPoint = points[(i + 1) % points.length];
      list.push(getPropertiesBetweenTwoPoints(points[i], secondPoint));
    }
    return list;
  }, [points, isClosed]);

  const polygonCentroid = useMemo(() => isPolygon && getPolygonCentroid(points), [points]);

  const jstsCoords = useMemo(() => points.map((v) => new geom.Coordinate(...v, 0)), [points]);

  const anglesFromLotPolygon = useLotAngles(sitePolygon);

  const anglesFromLastLine = useAnglesFromLastLine(points);

  const allSnapingAngles = useMemo(() => [...anglesFromLastLine, ...anglesFromLotPolygon], [anglesFromLastLine, anglesFromLotPolygon]);

  const outlinePoints = useMemo(() => {
    if (isPolygon) {
      return [];
    }
    if (points.length > 1) {
      let boundaryLine;
      if (!isClosed) {
        boundaryLine = new geom.GeometryFactory().createLineString(jstsCoords);
      } else {
        jstsCoords.push(new geom.Coordinate(...points[0], 0));
        boundaryLine = new geom.GeometryFactory().createLineString(jstsCoords);
      }
      const isClosedTmp = (pointInProgress?.x === points[0][0]) && (pointInProgress?.y === points[0][1]);
      const newStuff = bufferStraight(boundaryLine, halfTubeWidth);
      let coordinates = newStuff.getCoordinates();
      if (isClosed || isClosedTmp) {
        coordinates = chamfCoordinates(coordinates, halfTubeWidth, isClosed || isClosedTmp);
      } else {
        coordinates = chamfCoordinates(coordinates, halfTubeWidth);
      }

      return coordinates;
    }
    return [];
  }, [points, isClosed, halfTubeWidth, pointInProgress]);

  const outlinePointsArrayFormat = useMemo(() => outlinePoints.map((v) => [v.x, v.y]), [outlinePoints]);

  const progressOutlinePoints = useMemo(() => {
    if (isPolygon) {
      return [];
    }
    if (pointInProgress && isCreating) {
      // progress outlines which is almost identical to the regular + one progress point. Should find a more efficient way
      const progressJstsCoords = [...jstsCoords, new geom.Coordinate(pointInProgress.x, pointInProgress.y, 0)];
      const progressOutLine = new geom.GeometryFactory().createLineString(progressJstsCoords);
      const outlinePoly = bufferStraight(progressOutLine, halfTubeWidth);
      let coordinates = outlinePoly.getCoordinates();
      const isClosedTmp = (pointInProgress?.x === points[0][0]) && (pointInProgress?.y === points[0][1]);

      if (isClosed || isClosedTmp) {
        coordinates = chamfCoordinates(coordinates, halfTubeWidth, isClosed || isClosedTmp);
      } else {
        coordinates = chamfCoordinates(coordinates, halfTubeWidth);
      }

      return coordinates;
    }
    return outlinePoints;
  }, [points, isClosed, pointInProgress, isPolygon]);

  const progressOutlinePointsArrayFormat = useMemo(() => progressOutlinePoints?.map((v) => [v.x, v.y]), [progressOutlinePoints]);

  const globalPoints = useMemo(() => points.map((point) => vecAdd(point, globalPosition)), [JSON.stringify(points), globalPosition]);

  useLayoutEffect(() => {
    if (massInSketch?.swpBuilding?.curve?.data) {
      const massTransform = transforms[massInSketch.name];
      if (!massTransform) {
        return;
      }
      const matrix = buildLocalToGlobalMatrix(massInSketch?.swpBuilding);
      const newPoints = transformGeometry(matrix, massInSketch?.swpBuilding?.curve?.data).saveToObject();
      const transformTriplet = [massTransform.delta_x, massTransform.delta_y, massTransform.delta_angle];
      const transformedPoints = transformPolygon(newPoints, transformTriplet, massInSketch.pivotPoint);
      setPoints(transformedPoints);
    } else if (massInSketch) {
      console.log('should not happen. Should avoid start sketch if no curve data exist (in DraggableMesh)');
    }
  }, [massInSketch]);

  useEffect(() => {
    changeCursor('crosshair');
  }, []);

  useEffect(() => {
    if (!isOrthographic && isDirty.current) {
      finishSketchSession();
    }
  }, [isOrthographic]);

  useEffect(() => {
    isDirty.current = true;
  }, [points, parkingEntrancePosition]);

  useLayoutEffect(() => {
    if (editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.CREATE_SURFACE_PARKING) {
      changeCursor('crosshair');
    } else if (editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.TRANSFORM) {
      isUpdatingServer.current = false;
    } else if (editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.EDIT_SURFACE_PARKING) {
      isDirty.current = false;
    }

    if (editorType !== FEASIBILITY_EDITOR_BUTTON_KEYS.WAITING_FOR_SKETCH) {
      dispatch(SketchToolReducer.reset({ editorType, initialParking }));
    }
  }, [editorType]);

  useEffect(() => {
    if (points.length > 1 && !isPolygon) {
      // const pointsToSend = isClosed ? [...debouncedPoints, debouncedPoints[0]] : debouncedPoints;
      // updateServerReducer('hint', { points: roundVecList(pointsToSend) });
    }
  }, [debouncedPoints]);

  const finishSketchSession = () => {
    dispatch(SketchToolReducer.finnishSession());
    if (isUpdatingServer.current) {
      return;
    }
    if (!isPolygon && isIlligalToSend(midPoints, isClosed, points)) {
      showWarningModal();
      return;
    }
    if (points.length > 0) {
      const pointsToSend = isClosed ? [...globalPoints, globalPoints[0]] : globalPoints;
      if (isPolygon) {
        const globalParkingEntrance = { ...parkingEntrancePosition };
        globalParkingEntrance.position = vecAdd(parkingEntrancePosition.position, globalPosition);
        updateServerReducer('parking', { points: roundVecList(pointsToSend), entrance: globalParkingEntrance });
      } else if (editorType === FEASIBILITY_EDITOR_BUTTON_KEYS.EDIT_BUILDING) {
        updateServerReducer('editMass', { points: roundVecList(pointsToSend), outerPoints: outlinePointsArrayFormat, massInSketch });
        // dispatch(SwappEditor.editMassInSketch(undefined));
      } else {
        updateServerReducer('createMass', { points: roundVecList(pointsToSend), outerPoints: outlinePointsArrayFormat });
      }
      isUpdatingServer.current = true;
    }
  };

  useEffect(() => {
    if (isEnterPressed) {
      finishSketchSession();
    }
  }, [isEnterPressed]);

  const handlePointerMove = useCallback((event) => {
    if (points.length < 1) {
      return;
    }
    const { point } = event;
    if (type === SketchTool.Types.Polygon) {
      setPointInProgress(isElementMoving ? null : point.clone());
    } else {
      bufferedLinesPointerMove(point);
    }
  });

  const bufferedLinesPointerMove = (point) => {
    if (isClosed) {
      return;
    }
    const arrayPoint = [point.x, point.y];
    const firstPoint = points[0];
    const isIntersect = isSelfIntersecting(outlinePoints, points, arrayPoint, halfTubeWidth);
    const isCloseToFirst = vecDistance(arrayPoint, firstPoint) < halfTubeWidth;
    if (isCloseToFirst && !sketchSettings?.isDefaultSnappingAngle) {
      setPointInProgress(new THREE.Vector3(...firstPoint, 0));
      return;
    }
    if (isIntersect || isElementMoving) {
      setPointInProgress(null);
      return;
    }
    const snappedPoint = getSnappedPosition(point, points, allSnapingAngles, isShiftPressed, sketchSettings);
    setPointInProgress(snappedPoint);
  };

  const handleMouseClick = useCallback((e, id, middlePoint) => {
    // eslint-disable-next-line no-underscore-dangle
    if (e.timeStamp - planeClickedTime.current < MAX_CLICK_TIME && isNaN(id) && e._reactName === 'onPointerUp') {
      finishSketchSession();
      return;
    }

    if (isPolygon && isParkingEntranceInteracting) {
      return;
    }

    // If the sketch is closed we don't want to create new points, unless it has id, which means
    // It was created from a mid point.
    if (isClosed && isNaN(id) && !isPolygon) {
      return;
    }

    if (e.button !== 0) {
      return;
    }

    const { point } = e;
    const pointToUse = isClosed ? point : (pointInProgress || point); // Not sure the: || point is needed
    const pointToUseThinFormat = [pointToUse.x, pointToUse.y];
    const firstPoint = points[0];
    const isCloseToFirst = !isClosed && firstPoint && vecLength(vecSub(pointToUseThinFormat, firstPoint)) < halfTubeWidth;

    // Don't create new points inside the polygon, unless:
    // 1. It is a midle point tha has id.
    // 2. It is close to the first point and closing a courtyard building
    if (!isCloseToFirst && doPointInPoly(outlinePointsArrayFormat, pointToUseThinFormat) && isNaN(id)) {
      return;
    }

    if (isPolygon && (isElementMoving || middlePointJustCreated.current)) {
      middlePointJustCreated.current = null;
      return;
    }

    if (e.type === 'pointerup') {
      planeClickedTime.current = e.timeStamp; // For detecting double clicks
    }

    const newPoints = [...points];

    if (!isCloseToFirst) {
      if (!isNaN(id)) {
        newPoints.splice(id + 1, 0, middlePoint);
        middlePointJustCreated.current = isPolygon && e.type === 'pointerdown' && vecClone(middlePoint);
      } else {
        newPoints.push(pointToUseThinFormat);
      }
    }

    const isPolygonThirdPoint = isPolygon && points.length === 1;
    // When adding a new point to a polygon, if the entrance is on the last edge, we should re-locate it.
    if (isNaN(id) && isPolygon && parkingEntrancePosition?.index === points.length - 1) {
      setParkingEntrancePosition(projectPointToPolygon(parkingEntrancePosition?.position, newPoints, ParkingEntrance.PLANE_SIZE));
    }
    setPoinsWithHistory(newPoints);
    setIsClosed(isCloseToFirst || isClosed || isPolygonThirdPoint);
  });

  const onDoubleClickInteractionPlane = useCallback((e) => finishSketchSession(e), [isClosed, points, globalPoints, parkingEntrancePosition, outlinePointsArrayFormat]);

  const onPointerUpInteractionPlane = useCallback((e) => {
    if (isCreating) {
      handleMouseClick(e);
      middlePointJustCreated.current = null;
    }
  }, [middlePointJustCreated.current, handleMouseClick]);

  const FillPlane = () => {
    const planeMeshVertices = isPolygon ? points : progressOutlinePointsArrayFormat;
    return (
      planeMeshVertices.length > 2 && <PlaneMesh vertices={planeMeshVertices} opacity={isPolygon ? 0.2 : 0.5} color={currentTheme.colors.primaryColor} key="outline-plane" />
    );
  };

  const interactiveParkingCallback = useCallback((isInter) => setIsParkingEntranceInteracting(isInter), []);

  if (!parkingEntrancePosition && points.length > 2 && isPolygon) {
    setParkingEntrancePosition(projectPointToPolygon(vecLerp(points[0], points[1], 0.7), points, ParkingEntrance.PLANE_SIZE));
  }

  const renderAreaText = () => (
    <Text
      position={[...polygonCentroid, 5]}
      fontSize={4}
      letterSpacing={0.05}
      color={MAIN_COLOR}
      anchorX="center"
      anchorY="middle"
      font="Roboto"
      outlineWidth={0.1}
      outlineColor={MAIN_COLOR}
      onPointerOver={(e) => e.stopPropagation()}
    >
      {sqfToSqmText(getPolygonArea(points), isImperial, true)}
    </Text>
  );

  return (
    <>
      <InteractionPlane
        onPointerMove={handlePointerMove}
        onPointerUp={onPointerUpInteractionPlane}
        onDoubleClick={onDoubleClickInteractionPlane}
      />
      <DragObject
        point={isPolygon ? globalPosition : [0, 0, 0]}
        onPointMoved={(p) => setGlobalPosition(p)}
        active={isPolygon && !isCreating && !isParkingEntranceInteracting}
      >
        <group position={[0, 0, POINTS_EDITOR_HEIGHT]} name="SketchTool">
          {points.map((point, id) => (
            <EditablePoint
              key={`p${id}`}
              uuid={id}
              color={isPolygon ? MAIN_COLOR : getColorBuyLengthAndId(midPoints[id]?.length, id, isClosed, points)}
              point={[...point, 5]}
              points={points}
              parkingEntrancePosition={parkingEntrancePosition}
              isClosed={isClosed}
              isPolygon={isPolygon}
              finishSketchSession={finishSketchSession}
              updateHistory={updateHistory}
              planeClickedTime={planeClickedTime.current}
              isDisabled={sketchSettings?.isDefaultSnappingAngle && points.length > 2}
            />
          ))}
          {points.length > 1 && (
            <>
              <PolyLines points={points} color={MAIN_COLOR} width={1} isClosed={isClosed} />
              <ItemsBetweenTwoPoints
                points={points}
                isPolygon={isPolygon}
                isElementMoving={isElementMoving}
                movingVector={movingVector}
                middlePoints={midPoints}
                isClosed={isClosed}
                isImperial={isImperial}
                onMidPointClicked={handleMouseClick}
                updateHistory={updateHistory}
              />
            </>
          )}
          {progressOutlinePoints?.length && progressOutlinePoints[0] && (
          <BufferedOutline
            isPolygon={isPolygon}
            bufferedOutline={progressOutlinePoints}
            color={currentTheme.colors.primaryColor}
          />
          )}
          <FillPlane />
          {isPolygon && parkingEntrancePosition && !isElementMoving && (
          <ParkingEntrance
            points={points}
            position={parkingEntrancePosition?.position}
            angle={parkingEntrancePosition?.angle}
            interactiveParkingCallback={interactiveParkingCallback}
            globalPointInProgress={pointInProgress && vecSub([pointInProgress.x, pointInProgress.y], globalPosition)}
            isParkingEntranceInteracting={isParkingEntranceInteracting}
          />
          )}
          {isPolygon && polygonCentroid && renderAreaText()}
          {isCreating && pointInProgress && !isParkingEntranceInteracting && (
          <LinesInProgress
            isClosed={isClosed}
            isPolygon={isPolygon}
            color={MAIN_COLOR}
            firstPoint={points[0]}
            lastPoint={points[points.length - 1]}
            globalLastPoint={globalPoints?.length && globalPoints[globalPoints.length - 1]}
            pointInProgress={pointInProgress}
            isImperial={isImperial}
          />
          )}
        </group>
      </DragObject>
    </>
  );
};

SketchTool.Types = {
  Polygon: 'Polygon',
  BufferedLines: 'BufferedLines',
};

SketchTool.propTypes = {
  updateServerReducer: PropTypes.func,
  type: PropTypes.string,
  editorType: PropTypes.string,
  initialParking: PropTypes.object,
  sitePolygon: PropTypes.array,
  isOrthographic: PropTypes.bool,
};

export default SketchTool;
