import React from 'react';
import PropTypes from 'prop-types';
import { difference, union } from 'polygon-clipping';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashFlatten from 'lodash/flattenDepth';
import { extendPolygon } from '../../../algorithms/jstsHelpers';
import { extendRectanglePolygon, shorterVertexCoordinates } from '../../../algorithms/algorithmHelpers';
import MeshLine from '../../components/MeshLine';
import ExtrudeMesh from '../../components/ExtrudeMesh';

// const getExternalWalls = () => {
//   const wallWidth = 3;
//   const polygon = [...floor.boundaryPoints, floor.boundaryPoints[0]];
//
//   const jstsArray = polygon.map((v) => new geom.Coordinate(...v, 0));
//   const boundaryLine = new geom.GeometryFactory().createLinearRing(jstsArray);
//   const boundaryPoly = boundaryLine.buffer(wallWidth, -2);
//
//   const externalLine = lodashGet(boundaryPoly, '_shell._points._coordinates', []);
//   const holeLine = lodashGet(boundaryPoly, '_holes[0]._points._coordinates', []);
//
//   const envelope = externalLine.map((v) => [v.x, v.y]);
//   const holes = [holeLine.map((v) => [v.x, v.y])];
//
//   return [envelope, ...holes];
// };

const FloorWalls = (props) => {
  const { floor, wallSettings } = props;
  const { wallHeightPosition, planLineColor, planLineThickness, wallDepth, wallsColor } = wallSettings;
  const getRoomDoorPolygon = (room, extendBy = 0.06) => { // TODO - extend door by non clearance side axis
    if (!room.furniture) {
      return [];
    }
    const allDoors = room.furniture.filter((f) => f.furniture_type === 'door');
    // we use extendRectanglePolygon to extend the polygon just a bit so the door cutout will work for sure
    const allDoorsPolygons = allDoors.map((door) => (door.boundingBox ? shorterVertexCoordinates(extendRectanglePolygon(door.boundingBox, extendBy)) : null));

    return allDoorsPolygons;
  };

  // get the wall shape using 2 contour of the walls and inner walls
  const getRoomWalls = (room) => {
    if (lodashIsEmpty(room.points) || lodashIsEmpty(room.innerPoints)) {
      return;
    }

    const otherPoints = shorterVertexCoordinates(extendPolygon(room.points, 0.25));
    const doorPolygons = getRoomDoorPolygon(room);
    const innerPoints = shorterVertexCoordinates(room.innerPoints);

    return difference([otherPoints, ...doorPolygons], [innerPoints]);
  };

  const getExtraRoomWalls = (room) => {
    if (lodashIsEmpty(room.extraWalls)) {
      return;
    }

    const doorPolygons = getRoomDoorPolygon(room);
    const extraWalls = room.extraWalls.map((wall) => shorterVertexCoordinates(lodashFlatten([wall])));

    const wallsWithoutTheDoors = extraWalls.map((wall) => difference([wall, ...doorPolygons], []));
    return lodashFlatten(wallsWithoutTheDoors);
  };

  // get all of the room walls of this floor
  const combinedRooms = [...floor.extraRooms, ...lodashFlatten(floor.tubes.map((tube) => tube.rooms), 2)];
  const wallMultiPolygon = combinedRooms.map((room) => getRoomWalls(room)).filter((e) => e && !e.localPreview);
  const extraWallMultiPolygon = combinedRooms.map((room) => getExtraRoomWalls(room)).filter((e) => e);

  // union the walls and return array of viewes that the first item is the wall and all other are the holes
  const unionInput = [
    ...lodashFlatten(wallMultiPolygon, 1),
    ...lodashFlatten(extraWallMultiPolygon),
    // getExternalWalls(), // remove External Walls for now
    ...floor.structures.map((item) => [[...item, item[0]]]),
  ];
  const unitedWallPolygons = union(unionInput);

  return (
    <group position={[0, 0, wallHeightPosition]} name="walls">
      {unitedWallPolygons.map((polygon, index) => {
        const [envelope, ...holes] = polygon;
        const fixedHoles = holes.filter((hole) => Array.isArray(hole[0]));

        return (
          <group key={index} name={`wall_${index}`}>
            {polygon.map((line, lineIndex) => <MeshLine key={lineIndex} vertices={line} color={planLineColor} lineWidth={planLineThickness} wallsColor={wallsColor} height={60} />)}
            <ExtrudeMesh holes={polygon.length >= 2 ? fixedHoles : []} envelope={envelope} extrudeDepth={wallDepth} color={wallsColor} unlit meshProps={{ name: `wall_mesh_${index}` }} />
          </group>
        );
      })}
    </group>
  );
};

FloorWalls.propTypes = {
  floor: PropTypes.object,
  wallSettings: PropTypes.object,
};

export default React.memo(FloorWalls);
