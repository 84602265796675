import React from 'react';
import T from 'i18n-react';
import SuccessNotification from '../components/auth/SuccessNotification';
import { UI_AUTHORIZE_PATH } from '../constants/routes/ui';

const SuccessNotificationPage = () => (
  <SuccessNotification
    route={UI_AUTHORIZE_PATH.root}
    notification={T.translate('SUCCESS_NOTIFICATION_PAGE_NOTIFICATION')}
    hints={T.translate('SUCCESS_NOTIFICATION_PAGE_HINTS')}
    buttonText={T.translate('AUTH_FORM_LOG_IN')}
  />
);

export default SuccessNotificationPage;
