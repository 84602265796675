import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { Form, message } from 'antd';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsEqual from 'lodash/isEqual';
import { StyledSubMenu, StyledMenu, FormSubMenuWrapper } from 'styles/commonComponents.styles';
import { setProjectProfileData } from 'utils/model/projectProfileData';
import { handleSubmit } from 'utils/helpers/testFitContainerHelprs';
import { TEST_FIT_INITIAL_VALUES } from 'constants/formsInitialValues';
import { getUnitSystemByProjectIdSelector, useOverridableSetting } from 'store/userSettings';
import { isProfileTemplatesLoadingSelector } from 'store/swappProfileTemplates';
import { isSharedUrl } from 'utils/helpers/navigationHelpers';
import LoadingSpinner from 'components/common/LoadingSpinner';
import icons from 'styles/static/icons/testFit';
import { PROFILE_STATUS } from 'constants/index';
import {
  loadingSelector as loadingProject,
  swappProfileResultsSelector,
  swappProjectSelector,
} from 'store/swappProfile';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import sidebarIcons from 'styles/static/icons/sidebarIcons';
import lodashIncludes from 'lodash/includes';
import { CURRENT_MULTI_BOUNDARY_FILE_VERSION } from 'constants/testFitConsts';
import StudiesRoute from 'components/testFit/study/StudiesRoute';
import {
  pollSwappProjectAction,
  updateProjectProfileAction,
} from 'store/swappProfile/actions/swappProfileActions';
import ResultSplitView from '../../../common/ResultSplitView';
import ResultSplitViewCard from '../../../common/ResultSplitViewCard';
import RenderTitle from '../../../common/RenderTitle';
import EditorRsfPanel from '../result/program/EditorRsfPanel';
import AreaTargetFormLayoutInfo from './AreaTargetFormLayoutInfo';
import AreaTargetFormOfficeComfort from './AreaTargetFormOfficeComfort';
import AreaTargetFormOfficeType from './AreaTargetFormOfficeType';
import AreaTargetFormRsfLsf from './AreaTargetFormRsfLsf';
import AreaTargetFormLsf from './AreaTargetFormLsf';
import AreaTargetFormPsf from './AreaTargetFormPsf';
import AreaTargetFormPsfB from './AreaTargetFormPsfB';
import AreaTargetFormAsf from './AreaTargetFormAsf';
import AreaTargetFormFsf from './AreaTargetFormFsf';
import AreaTargetFormBathrooms from './AreaTargetFormBathrooms';
import AreaTargetFormCirculation from './AreaTargetFormCirculation';
import AreaTargetFormPositioning from './AreaTargetFormPositioning';
import AreaTargetFormDepartments from './AreaTargetFormDepartments';
import AreaTargetFormComments from './AreaTargetFormComments';

let debounceTimer = null;

const sidebarButtons = [
  { id: UI_AUTHORIZE_PATH.program, icon: sidebarIcons.programIcon, text: 'TEST_FIT_TARGET_FORM_CONTAINER_PROGRAM' },
  { id: UI_AUTHORIZE_PATH.markups, icon: sidebarIcons.markupsIcon, text: 'TEST_FIT_TARGET_FORM_CONTAINER_MARKUPS' },
  { id: UI_AUTHORIZE_PATH.financial, icon: sidebarIcons.financialIcon, text: 'TEST_FIT_TARGET_FORM_CONTAINER_FINANCIAL' },
  { id: UI_AUTHORIZE_PATH.design, icon: sidebarIcons.designIcon, text: 'TEST_FIT_TARGET_FORM_CONTAINER_DESIGN' },
  { id: UI_AUTHORIZE_PATH.build, icon: sidebarIcons.cdIcon, text: 'TEST_FIT_TARGET_FORM_CONTAINER_BUILD' },
  { id: UI_AUTHORIZE_PATH.exportFiles, icon: sidebarIcons.exportIcon, text: 'TEST_FIT_TARGET_FORM_CONTAINER_EXPORT_FILES' },
];

const TestFitTargetFormContainer = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const match = useRouteMatch();
  const isProjectLoading = useSelector(loadingProject);
  const swappProject = useSelector(swappProjectSelector);
  const isProfileTemplatesLoading = useSelector(isProfileTemplatesLoadingSelector);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));
  const isLoadingProject = useSelector(loadingProject);
  const parentProfileIndex = lodashGet(swappProject, 'projectProfiles', []).findIndex((profile) => !profile.parentProfileId);
  const testFitStandard = useOverridableSetting('testFitStandard', 'DEFAULT');
  const swappProfileResults = useSelector((state) => swappProfileResultsSelector(state, match.params.profileId, isImperial), shallowEqual);
  const dispatch = useDispatch();

  const isProjectHasNewTestFitById = lodashIncludes(lodashGet(swappProject, 'user.settings.flags.hasNewTestFitProjectIds'), Number(match.params.projectId));
  const boundaryVersion = swappProfileResults?.multiBoundaryFile?.version;
  const isNewViewer = (isProjectHasNewTestFitById && swappProfileResults && swappProfileResults.multiBoundaryFile && boundaryVersion >= CURRENT_MULTI_BOUNDARY_FILE_VERSION) || boundaryVersion >= 7;
  const parentProfile = lodashGet(swappProject, `projectProfiles[${parentProfileIndex}]`);
  const parentProfileId = lodashGet(parentProfile, 'id');
  const formData = lodashGet(parentProfile, 'profileData.form', {});
  const isWaitingForResults = lodashGet(parentProfile, 'status') === PROFILE_STATUS.WAITING_FOR_DATA;
  const isShare = isSharedUrl();

  useEffect(() => {
    if (isWaitingForResults && !isShare) {
      dispatch(pollSwappProjectAction(match.params.projectId, parentProfileId, history));
    }
  }, [isWaitingForResults]);

  // will Mount
  useEffect(() => {
    clearTimeout(debounceTimer);
  }, []);

  // will unMount
  useEffect(() => () => {
    clearTimeout(debounceTimer);
  }, []);

  useEffect(() => {
    if (swappProject) {
      if (formData && !isProjectLoading && !isProfileTemplatesLoading) {
        form.setFieldsValue(formData);
      }
    }
  }, [isProjectLoading, isProfileTemplatesLoading]);

  const renderCard = (profile, onClick) => <ResultSplitViewCard key={profile.id} height={324} onClick={onClick} body={<EditorRsfPanel profileId={profile.id} />} profile={profile} />;

  const handleOnChange = () => {
    if (isShare) {
      return;
    }

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const currentFormData = form.getFieldsValue();

    debounceTimer = setTimeout(() => {
      if (lodashIsEmpty(currentFormData)
          || !swappProject
          || isProjectLoading
          || parentProfileIndex === -1
          || lodashIsEqual(currentFormData, formData)
          || lodashGet(parentProfile, 'status') !== PROFILE_STATUS.WAITING_FOR_SUBMISSION) {
        return;
      }

      dispatch(updateProjectProfileAction(parentProfileId, setProjectProfileData(currentFormData, lodashGet(swappProject, 'siteId'))))
        .catch((err) => message.error(err));
    }, 700);
  };

  return (
    <>
      {isLoadingProject && <LoadingSpinner />}
      <ResultSplitView
        disabled={isShare}
        loading={isProjectLoading || isWaitingForResults}
        handleUpdate={(e) => handleSubmit(e, form, isProjectLoading, dispatch, parentProfileId)}
        width={630}
        renderCard={renderCard}
        sidebarButtons={isNewViewer ? sidebarButtons : []}
        renderExpandData={<StudiesRoute isNewViewer={isNewViewer} />}
      >
        <Form name="areaTargetForm" form={form} onFieldsChange={handleOnChange} initialValues={TEST_FIT_INITIAL_VALUES}>
          <StyledMenu mode="inline" defaultOpenKeys={['LAYOUT_INFO', 'OFFICE_COMFORT', 'OFFICE_TYPE']} forceSubMenuRender>
            {/* ========= Layout information ========= */}
            <StyledSubMenu key="LAYOUT_INFO" title={RenderTitle('Layout Information', '', icons.layoutInfoIcon)}>
              <FormSubMenuWrapper>
                <AreaTargetFormLayoutInfo isImperial={isImperial} form={form} formData={formData} handleOnChange={handleOnChange} isProjectLoading={isProjectLoading} />
              </FormSubMenuWrapper>
            </StyledSubMenu>

            {/* ========= Office comfort level ========= */}
            <StyledSubMenu key="OFFICE_COMFORT" title={RenderTitle('Office Comfort Level', '', icons.roomComfortIcon)}>
              <FormSubMenuWrapper>
                <AreaTargetFormOfficeComfort isImperial={isImperial} form={form} formData={formData} handleOnChange={handleOnChange} isProjectLoading={isProjectLoading} />
              </FormSubMenuWrapper>
            </StyledSubMenu>

            {/* ========= Office type ========= */}
            {testFitStandard === 'DEFAULT' && (
              <StyledSubMenu key="OFFICE_TYPE" title={RenderTitle('Office Type', '', icons.officeTypeIcon)}>
                <FormSubMenuWrapper>
                  <AreaTargetFormOfficeType isImperial={isImperial} form={form} formData={formData} handleOnChange={handleOnChange} isProjectLoading={isProjectLoading} isJustCreated={lodashGet(history, 'location.tate.isJustCreated')} />
                </FormSubMenuWrapper>
              </StyledSubMenu>
            )}

            {/* ========= LSF ========= */}
            <StyledSubMenu key="LSF" title={RenderTitle('AREA_TARGET_FORM_CONTAINER_LSF', 'AREA_TARGET_FORM_CONTAINER_LEASABLE_AREA', icons.lsfIcon)}>
              <FormSubMenuWrapper>
                <AreaTargetFormRsfLsf isImperial={isImperial} form={form} formData={formData} handleOnChange={handleOnChange} isProjectLoading={isProjectLoading} />
              </FormSubMenuWrapper>
              <FormSubMenuWrapper>
                <AreaTargetFormLsf isImperial={isImperial} form={form} formData={formData} handleOnChange={handleOnChange} isProjectLoading={isProjectLoading} />
              </FormSubMenuWrapper>
            </StyledSubMenu>

            {/* ========= PSF ========= */}
            <StyledSubMenu key="PSF_A" title={RenderTitle('AREA_TARGET_FORM_CONTAINER_PSF', 'AREA_TARGET_FORM_CONTAINER_PRODUCTIVE_AREA', icons.psfIcon)}>
              <FormSubMenuWrapper>
                <AreaTargetFormPsf isImperial={isImperial} form={form} formData={formData} handleOnChange={handleOnChange} isProjectLoading={isProjectLoading} />
              </FormSubMenuWrapper>
              <FormSubMenuWrapper>
                <AreaTargetFormPsfB isImperial={isImperial} form={form} formData={formData} handleOnChange={handleOnChange} isProjectLoading={isProjectLoading} />
              </FormSubMenuWrapper>
            </StyledSubMenu>

            {/* ========= ASF ========= */}
            <StyledSubMenu key="ASF" title={RenderTitle('AREA_TARGET_FORM_CONTAINER_ASF', 'AREA_TARGET_FORM_CONTAINER_AMENITY_SPACES', icons.asfIcon)}>
              <FormSubMenuWrapper>
                <AreaTargetFormAsf isImperial={isImperial} form={form} formData={formData} handleOnChange={handleOnChange} isProjectLoading={isProjectLoading} />
              </FormSubMenuWrapper>
            </StyledSubMenu>

            {/* ========= FSF ========= */}
            <StyledSubMenu key="FSF" title={RenderTitle('AREA_TARGET_FORM_CONTAINER_FSF', 'AREA_TARGET_FORM_CONTAINER_FACILITY_SPACES', icons.fsfIcon)}>
              <FormSubMenuWrapper>
                <AreaTargetFormFsf isImperial={isImperial} form={form} formData={formData} handleOnChange={handleOnChange} isProjectLoading={isProjectLoading} />
              </FormSubMenuWrapper>
            </StyledSubMenu>

            {/* ========= RESTROOMS ========= */}
            <StyledSubMenu key="RESTROOMS" title={RenderTitle('AREA_TARGET_FORM_CONTAINER_BATHROOMS', 'AREA_TARGET_FORM_CONTAINER_BATHROOMS_DESCRIPTION', icons.restroomIcon)}>
              <FormSubMenuWrapper>
                <AreaTargetFormBathrooms isImperial={isImperial} form={form} formData={formData} handleOnChange={handleOnChange} isProjectLoading={isProjectLoading} />
              </FormSubMenuWrapper>
            </StyledSubMenu>

            {/* ========= CIRCULATION ========= */}
            <StyledSubMenu key="CIRCULATION" title={RenderTitle('AREA_TARGET_FORM_CONTAINER_CIRCULATION', 'AREA_TARGET_FORM_CONTAINER_CIRCULATION_DESCRIPTION', icons.circulationIcon)}>
              <FormSubMenuWrapper>
                <AreaTargetFormCirculation isImperial={isImperial} form={form} formData={formData} handleOnChange={handleOnChange} isProjectLoading={isProjectLoading} />
              </FormSubMenuWrapper>
            </StyledSubMenu>

            {/* ========= POSITIONING ========= */}
            <StyledSubMenu key="POSITIONING" title={RenderTitle('AREA_TARGET_FORM_CONTAINER_POSITIONING', 'AREA_TARGET_FORM_CONTAINER_POSITIONING_DESCRIPTION', icons.positioningIcon)}>
              <FormSubMenuWrapper>
                <AreaTargetFormPositioning isImperial={isImperial} form={form} formData={formData} handleOnChange={handleOnChange} isProjectLoading={isProjectLoading} />
              </FormSubMenuWrapper>
            </StyledSubMenu>

            {/* ========= DEPARTMENTS ========= */}
            <StyledSubMenu key="DEPARTMENTS" title={RenderTitle('AREA_TARGET_FORM_CONTAINER_DEPARTMENTS', 'AREA_TARGET_FORM_CONTAINER_DEPARTMENTS_DESCRIPTION', icons.departmentsIcon)}>
              <FormSubMenuWrapper>
                <AreaTargetFormDepartments isImperial={isImperial} form={form} formData={formData} handleOnChange={handleOnChange} isProjectLoading={isProjectLoading} />
              </FormSubMenuWrapper>
            </StyledSubMenu>

            {/* ========= COMMENTS ========= */}
            <StyledSubMenu key="COMMENTS" title={RenderTitle('AREA_TARGET_FORM_CONTAINER_COMMENTS', 'AREA_TARGET_FORM_CONTAINER_COMMENTS_DESCRIPTION', icons.commentsIcon)}>
              <FormSubMenuWrapper>
                <AreaTargetFormComments isImperial={isImperial} form={form} formData={formData} handleOnChange={handleOnChange} isProjectLoading={isProjectLoading} />
              </FormSubMenuWrapper>
            </StyledSubMenu>
          </StyledMenu>
        </Form>

      </ResultSplitView>
    </>
  );
};

export default TestFitTargetFormContainer;
