import React from 'react';

// currentcolor
export default () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.0078 11.3049L10.4724 15M10.4724 15L6.39061 11.3049M10.4724 15V1" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 1H1" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M7 4H2.5" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M7 7H4" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);
