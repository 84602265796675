import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { message } from 'antd';
import { FEASIBILITY_TARGET_FORM } from 'constants/fieldNames';
import { FormItem } from './FeasibilityTargetFormContainer.Styles';
import { StyledSlider } from '../../../common/TableSlider.styles';

const formItemOptions = {
  labelCol: { span: 9 },
  wrapperCol: { span: 12, offset: 1 },
  initialValue: 0,
};

// if new sliders are added plz add them to IL standard at 'FeasibilityApartmentStandardForm.js'
const allSliderKeys = [
  { key: FEASIBILITY_TARGET_FORM.CONSTRUCTION, label: 'FEASIBILITY_PARAMETER_FORM_CONSTRUCTION' },
  { key: FEASIBILITY_TARGET_FORM.MARKETABILITY, label: 'FEASIBILITY_PARAMETER_FORM_MARKETABILITY' },
  { key: FEASIBILITY_TARGET_FORM.WELLBEING, label: 'FEASIBILITY_PARAMETER_FORM_WELLBEING' },
  // { key: FEASIBILITY_TARGET_FORM.ARCHITECTURE, label: 'FEASIBILITY_PARAMETER_FORM_ARCHITECTURE', disabled: true },
  // { key: FEASIBILITY_TARGET_FORM.SUSTAINABILITY, label: 'FEASIBILITY_PARAMETER_FORM_SUSTAINABILITY', disabled: true },
  // { key: FEASIBILITY_TARGET_FORM.ENERGY_EFFICIENCY, label: 'FEASIBILITY_PARAMETER_FORM_ENERGY_EFFICIENCY', disabled: true },
];

const FeasibilityParameterForm = (props) => {
  const { form, disabled } = props;
  const [invalidFields, setInvalidFields] = useState({});

  useEffect(() => {
    setTimeout(() => onSliderChange(setInvalidFields), 2500); // TODO - find a better trigger for this
  }, []);

  const onSliderChange = (update) => {
    const formData = form.getFieldsValue();
    const availableSliderFields = allSliderKeys.map((field) => (formData[field.key] && { value: formData[field.key], key: field.key })).filter((e) => e);

    const validSliderFields = {};
    availableSliderFields.forEach((field) => {
      if (field.value >= 3) {
        let isInvalid = false;
        availableSliderFields.filter((e) => e.key !== field.key).forEach((e) => {
          if (e.value === field.value) {
            isInvalid = true;
          }
        });

        if (isInvalid) {
          message.warning({
            content: T.translate('FEASIBILITY_PARAMETER_FORM_ERROR'),
            key: 'validSliderFields',
          });
        }
        validSliderFields[field.key] = isInvalid;
        return;
      }
      validSliderFields[field.key] = false;
    });

    update(validSliderFields);
  };

  const sliderOptions = {
    marks: [0, 1, 2, 3, 4],
    min: 0,
    max: 4,
    step: 1,
    tipFormatter: null,
    onChange: () => onSliderChange(setInvalidFields),
  };

  const validator = (key) => {
    if (invalidFields[key]) {
      return Promise.reject(T.translate('FEASIBILITY_PARAMETER_FORM_ERROR'));
    }
    return Promise.resolve();
  };

  return (
    <>
      {allSliderKeys.map((field) => (
        <FormItem initialValue={0} key={field.key} {...formItemOptions} hideError rules={[{ validator: () => validator(field.key) }]} name={field.key} label={T.translate(field.label)}>
          <StyledSlider {...sliderOptions} isInvalid={invalidFields[field.key]} disabled={field.disabled || disabled} />
        </FormItem>
      ))}
    </>
  );
};

FeasibilityParameterForm.propTypes = {
  form: PropTypes.object,
  disabled: PropTypes.bool,
};

export default React.memo(FeasibilityParameterForm);
