import React from 'react';

export default () => (
  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <line x1="31.5" y1="13" x2="2.5" y2="13" stroke="currentcolor" />
      <line x1="31.5" y1="21" x2="2.5" y2="21" stroke="currentcolor" />
      <line x1="23" y1="5.5" x2="23" y2="29.5" stroke="currentcolor" />
      <line x1="12" y1="5.5" x2="12" y2="29.5" stroke="currentcolor" />
      <rect x="32" y="5" width="24" height="29" rx="2.5" transform="rotate(90 32 5)" stroke="currentcolor" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="35" height="35" fill="currentcolor" />
      </clipPath>
    </defs>
  </svg>
);
