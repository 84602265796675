import React from 'react';

// currentcolor
export default () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="22" height="22" fill="white" />
    <path d="M1 6.5H21M1 11.5H21M1 16.5H21M1 2H21V21H1V2Z" stroke="currentColor" strokeWidth="0.75" strokeLinejoin="round" />
    <rect x="5" y="5" width="12" height="13" fill="white" />
    <path d="M9.46924 12.4385V15.5H8.43799V7.67969H11.3223C12.1781 7.67969 12.8477 7.89811 13.3311 8.33496C13.818 8.77181 14.0615 9.3501 14.0615 10.0698C14.0615 10.8289 13.8234 11.4144 13.3472 11.8262C12.8745 12.2344 12.196 12.4385 11.3115 12.4385H9.46924ZM9.46924 11.5952H11.3223C11.8737 11.5952 12.2962 11.4663 12.5898 11.2085C12.8835 10.9471 13.0303 10.5711 13.0303 10.0806C13.0303 9.61507 12.8835 9.24268 12.5898 8.96338C12.2962 8.68408 11.8934 8.53906 11.3813 8.52832H9.46924V11.5952Z" fill="currentColor" />
  </svg>
);
