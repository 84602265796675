import React from 'react';
import lodashGet from 'lodash/get';
import T from 'i18n-react';
import styled from 'styled-components';
import { __HistoryContext } from 'react-router';
import { useContextBridge } from '@react-three/drei';
import { ReactReduxContext, useSelector } from 'react-redux';
import MiniMap from 'components/threeDWalk/MiniMap';
import EmptyMessage from 'components/common/EmptyMessage';
import { getProfileByIdSelector } from 'store/swappProfile';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { ViewerUiPanelWrapper, MailLink } from 'styles/commonComponents.styles';
import ViewerUiPanel from 'utils/swappViewer/components/ui/ViewerUiPanel';
import { useProfileResult } from 'store/swappProfile/selectors/swappSelectors';
import WalkThrough, { useWalkThroughLoading } from 'components/threeDWalk/WalkThrough';
import { WalkStyledCanvas } from 'utils/swappViewer/FeasibilityViewerContainer.styles';
import { CONTACT_MEETING } from 'constants/globalConst';

const DesignContainerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const DesignContainer = () => {
  const ContextBridge = useContextBridge(ReactReduxContext, __HistoryContext);
  const locationData = parseLocationUrl(window.location);
  const profile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const result = useProfileResult(profile);
  const walkThroughData = lodashGet(result, '3DW');

  const { lowResList, highResList, downloadLowRes } = useWalkThroughLoading(walkThroughData, locationData.profileId);

  if (!walkThroughData) {
    const description = (
      <div>
        <span>{T.translate('Want to find out more about ‘Design’?')}</span>
        <MailLink href={CONTACT_MEETING} target="_blank" rel="noopener noreferrer">{T.translate('contact our sales ')}</MailLink>
        <span>{T.translate(' department for further information')}</span>
      </div>
    );

    return (
      <div style={{ marginTop: '230px', width: '100%' }}>
        <EmptyMessage description={description} />
      </div>
    );
  }

  return (
    <DesignContainerWrapper>
      {/* ============== mini map ============== */}
      <ViewerUiPanelWrapper position="top-right" fitContent>
        <ViewerUiPanel width={370} isRight>
          <MiniMap data={walkThroughData} size={350} />
        </ViewerUiPanel>
      </ViewerUiPanelWrapper>

      {/* ============== viewer ============== */}
      <WalkStyledCanvas shadowMap contexts={[ReactReduxContext]} gl={{ preserveDrawingBuffer: true }}>
        <ContextBridge>
          <WalkThrough
            data={walkThroughData}
            lowResList={lowResList}
            highResList={highResList}
            onWalkThroughMounted={() => downloadLowRes(true)}
          />
        </ContextBridge>
      </WalkStyledCanvas>
    </DesignContainerWrapper>
  );
};

export default DesignContainer;
