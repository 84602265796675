import styled from 'styled-components';
import Icon from '@ant-design/icons';
import { Progress } from 'antd';

export const VerticalTitleContentWrapper = styled.div`
  display: flex;
  width: 1100px;
  flex-direction: column;
  margin: 0 auto ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '40px')};
  border-radius: 7px;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.gray_01};
  box-shadow: 0 0 10px rgba(38, 42, 83, 0.07);
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => (justifyContent || 'unset')};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : '25px')};
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}px` : 0)};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  overflow-y: ${({ height }) => (height ? 'auto' : 'hidden')};
  overflow-x: hidden;
`;

export const ContentTitle = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.light};
  font-size: ${({ theme }) => theme.font.size.xxLarge};
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
`;

export const ContentImagesWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 30px;
  grid-template-columns: repeat(auto-fit, minmax(${({ width }) => (width ? `${width}px` : '500px')}, 1fr));

  &:last-child {
    margin: 0;
  }
`;

export const ContentImagesWithLabelWrapper = styled.div`
  position: relative;
`;

export const ImageCardWrapper = styled.div`
  width: ${({ width }) => (width ? `${width}px` : '600px')};
  height: 215px;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.gray_02};
  display: flex;
  overflow: hidden;
`;

export const ImageCardContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export const ImageCard = styled.img`
  height: 100%;
  width: 280px;
`;

export const ContentImage = styled.img`
  background: ${({ theme, background }) => (background ? 'linear-gradient(#aad2f8, #f5f5f5)' : theme.colors.gray_02)};
  height: ${({ height }) => (height ? `${height}px` : 'inherit')};
  max-height: ${({ height }) => (height ? `${height}px` : 'inherit')};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  max-width: ${({ width }) => (width ? `${width}px` : '100%')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
  border-radius: 7px;
`;

export const ContentImageLabel = styled.div`
  position: absolute;
  opacity: 0.8;
  bottom: 0;
  left: 0;
  height: ${({ height }) => (height ? `${height}px` : '26px')};
  font-size: ${({ theme }) => theme.font.size.xSmall};
  padding: 6px;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.gray_01};
`;

export const ContentColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => (justifyContent || 'unset')};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  border-radius: 7px;
  height: ${({ theme, scroll }) => (scroll ? `calc(100vh - ${theme.other.headerHeight}px - 220px)` : 'fit-content')};
  overflow-y: auto;
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : 0)};
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}px` : 0)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
  padding: ${({ hasColor }) => (hasColor && '10px')};
  background-color: ${({ theme, hasColor }) => (hasColor && theme.colors.gray_02)};
`;

export const ContentRowWrapper = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => (justifyContent || 'unset')};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  border-radius: 7px;
  flex: 1;
  padding: ${({ hasColor }) => (hasColor && '10px')};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : 0)};
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}px` : 0)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
  background-color: ${({ theme, hasColor }) => (hasColor && theme.colors.gray_02)};
`;

export const StyledText = styled.div`
  position: relative;
  color: ${({ theme, highlight, total }) => {
    if (total) {
      return theme.colors.white;
    }

    return highlight ? theme.colors.primaryColor : theme.colors.textColor;
  }};
  text-align: ${({ align }) => (align || 'left')};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  font-weight: ${({ theme, bold, total }) => ((bold || total) ? theme.font.weight.bold : theme.font.weight.normal)};
  font-size: ${({ theme, size = 'medium' }) => theme.font.size[size]};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : 0)};
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}px` : 0)};
  display: ${({ middle }) => (middle ? 'flex' : 'unset')};
  justify-content: ${({ middle }) => (middle ? 'center' : 'unset')};
  flex-direction: ${({ middle }) => (middle ? 'column' : 'unset')};
`;

const BulletStyledTextMarginLeft = 16;

export const BulletStyledText = styled(StyledText)`
  margin-left: ${`${BulletStyledTextMarginLeft}px`};
  margin-bottom: 7px;
  width: fit-content;
  
  &:before {
    content: '';
    position: absolute;
    top: 4px;
    left: ${`-${BulletStyledTextMarginLeft}px`};
    margin-right: 5px;
    width: 8px;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.gray_09}
    border-radius: 50%;
  }
`;

export const LegendWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

export const LegendIcon = styled(Icon)`
  margin-right: 7px;
  font-size: 24px;
`;

export const LegendColor = styled(Icon)`
  margin-right: 7px;
  min-width: 24px;
  min-height: 24px;
  background-color: ${({ color }) => color};
  border-radius: ${({ theme }) => theme.other.borderRadius};
`;

export const RowLegendWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const RowLegendColor = styled.div`
  flex: 1;
  min-height: 22px;
  background-color: ${({ color }) => color};
  color: ${({ theme, highlight }) => (highlight ? theme.colors.white : theme.colors.black)};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const TableBoxWrapper = styled.div`
  display: flex;
  width: ${({ width }) => (width ? `${width}px` : '99%')};
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray_01};
  box-shadow: 0 0 10px rgba(38, 42, 83, 0.07);
  border-radius: 7px;
  padding: 16px;
  margin: ${({ margin }) => (margin || '0 0 26px 0')} !important;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

export const TableBoxHeader = styled.div`
  width: 155px;
  height: 40px;
  display: flex;
  margin: 0 auto 10px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 7px;
  background-color: ${({ theme, hide }) => (hide ? 'transparent' : theme.colors.gray_03)};
`;

export const TableHeaderColorCell = styled.div`
  color: ${({ theme }) => theme.colors.primaryColor};
  border-bottom: 3px solid ${({ color }) => color};
  margin: 0 5px;
`;

export const TableTotalCell = styled.div`
  color: ${({ theme, target, value, hideColor }) => {
    if (value === target || hideColor) {
      return 'unset';
    }

    return value > target ? theme.colors.danger : theme.colors.danger; // was `target ? theme.colors.danger : theme.colors.warning`
  }
};
`;

export const TableTotalRowText = styled.span`
  color: ${({ theme }) => theme.colors.primaryColor};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const TableTEmptyRowText = styled.div`
  min-height: 18px;
`;

export const ReportMainColumnWrapper = styled.div`
  width: 100%;
  //height: calc(100vh - ${({ theme }) => `${theme.other.headerHeight}px - 220px`});
  background-color: ${({ theme }) => theme.colors.gray_02};
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const ReportMainColumnDataWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.gray_01};
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const ReportMainColumnDataTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 2.2;
  border-bottom: ${({ theme, underLine }) => (underLine ? `1px solid ${theme.colors.gray_03}` : 0)};
  color: ${({ theme, bold }) => (bold && `${theme.colors.white}`)};
  background-color: ${({ theme, bold }) => (bold && `${theme.colors.primaryColor}`)};
  font-weight: ${({ theme, bold }) => (bold && theme.font.weight.bold)};
  border-radius: ${({ theme, bold }) => (bold && theme.other.borderRadius)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
  padding-left: ${({ bold }) => (bold && '5px')};
`;

export const ReportMainColumnDataText = styled.div`
  display: flex;
`;

export const ReportMainColumnDataSummery = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ReportMainColumnDataSummeryTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
`;

export const StyledProgress = styled(Progress)`
  align-items: ${({ align }) => (align || 'left')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};

  .ant-progress-circle-path {
    stroke: ${({ theme }) => theme.colors.primaryColor} !important;
  }
  .ant-progress-circle-trail {
    stroke: ${({ theme }) => `${theme.colors.primaryColor}75`} !important;
  }
  
  .ant-progress-text {
    color: ${({ theme }) => theme.colors.textColor} !important;
  }
`;

export const BulletRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const Bullet = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.gray_01};
  border: 1px solid ${({ theme }) => theme.colors.gray_02};
  border-radius: 7px;
  padding: 11px;
`;

export const TableHeaderSelectableCell = styled.div`
  position: relative;
  color: ${({ theme, selected }) => (selected ? theme.colors.primaryColor : 'unset')};
  // border-bottom: 3px solid ${({ theme, selected }) => (selected ? theme.colors.primaryColor : 'transparent')};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  :after {
    content: "";
    position: absolute;
    width: 80px;
    top: -6px;
    right: 0;
    border-bottom: 2px solid ${({ theme, selected }) => (selected ? theme.colors.primaryColor : 'transparent')};
  }
`;

export const ClickableTableCell = styled.div`
  cursor: pointer;
`;
