import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import lodashIsObject from 'lodash/isObject';
import T from 'i18n-react';
import { message } from 'antd';
import EditIcon from 'styles/static/icons/comon/editIcon';
import deleteIcon from 'styles/static/icons/comon/deleteIcon';
import Icon from '@ant-design/icons';
import {
  StyledUpload,
  StyledImage,
  StyledPlaceholderImage,
  StyledImageWrapper,
  EditPhotoPopup,
  EditPhotoIcons,
} from './UploadThumbnail.styles';

const UploadThumbnail = forwardRef((props, ref) => {
  const { onUpload, uploadedImageUrl } = props;
  const [imageUrl, setImageUrl] = useState(uploadedImageUrl);

  useEffect(() => {
    if (uploadedImageUrl) {
      setImageFromServer();
    }
  }, [uploadedImageUrl]);

  const setImageFromServer = () => {
    if (lodashIsObject(uploadedImageUrl)) {
      getBase64(uploadedImageUrl, (responseImageUrl) => {
        setImageUrl(responseImageUrl);
      });
    } else {
      setImageUrl(uploadedImageUrl);
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = React.useCallback((file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(T.translate('UPLOAD_THUMBNAIL_FILE_TYPE_ERROR'));
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(T.translate('UPLOAD_THUMBNAIL_LARGE_IMAGE_ERROR'));
    }
    return isJpgOrPng && isLt2M;
  }, []);

  const handleChange = React.useCallback((info) => {
    const isLt2M = info.file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      return;
    }
    getBase64(info.file.originFileObj, (responseImageUrl) => {
      setImageUrl(responseImageUrl);
    });
  }, []);

  const uploadButton = (
    <StyledPlaceholderImage>
      {T.translate('UPLOAD_THUMBNAIL_UPLOAD')}
    </StyledPlaceholderImage>
  );

  const renderImage = (
    <StyledImageWrapper>
      <EditPhotoPopup>
        <EditPhotoIcons>
          <Icon component={EditIcon} />
          <Icon
            component={deleteIcon}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setImageUrl(null);
            }}
          />
        </EditPhotoIcons>
      </EditPhotoPopup>
      <StyledImage src={imageUrl} alt="thumbnail" />
    </StyledImageWrapper>
  );

  return (
    <StyledUpload
      ref={ref}
      name="thumbnail"
      accept=".png, .jpeg, .jpg"
      listType="picture-card"
      // className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      customRequest={onUpload}
    >
      {imageUrl ? renderImage : uploadButton}
    </StyledUpload>
  );
});

UploadThumbnail.propTypes = {
  onUpload: PropTypes.func,
  uploadedImageUrl: PropTypes.string,
};

export default React.memo(UploadThumbnail);
