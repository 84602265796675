import React from 'react';

// currentcolor
export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.5344 38V15.9875C22.9161 15.7606 24.7228 13.9512 24.7228 11.7961C24.7228 9.63908 22.9161 7.83157 20.5344 7.6047V1H19.494V7.6047C17.1123 7.83156 15.3057 9.63913 15.3057 11.7961C15.3057 13.9512 17.1123 15.7606 19.494 15.9875V38H20.5344ZM16.3726 11.7723C16.3726 10.4459 17.2606 9.25124 18.6201 8.74261C19.9817 8.23585 21.5485 8.51575 22.589 9.45427C23.6315 10.391 23.9424 11.8015 23.3795 13.0273C22.8145 14.2512 21.4875 15.0507 20.0142 15.0507C18.0023 15.0507 16.3725 13.5835 16.3725 11.7722L16.3726 11.7723Z" fill="currentcolor" />
    <path d="M34.8411 38V31.3953C37.2228 31.1684 39.0294 29.3609 39.0294 27.2039C39.0294 25.0488 37.2228 23.2394 34.8411 23.0125V1H33.8006V23.0125C31.4189 23.2394 29.6123 25.0488 29.6123 27.2039C29.6123 29.3609 31.4189 31.1684 33.8006 31.3953V38H34.8411ZM30.6792 27.2279C30.6792 25.9015 31.5672 24.7069 32.9268 24.1982C34.2883 23.6915 35.8551 23.9714 36.8956 24.9099C37.9381 25.8466 38.249 27.2571 37.6861 28.4829C37.1212 29.7068 35.7942 30.5063 34.3208 30.5063C32.3089 30.5063 30.6792 29.0391 30.6792 27.2279L30.6792 27.2279Z" fill="currentcolor" />
    <path d="M6.22878 38V31.3953C8.61047 31.1684 10.4171 29.3609 10.4171 27.2039C10.4171 25.0488 8.61047 23.2394 6.22878 23.0125V1H5.18831V23.0125C2.80662 23.2394 1 25.0488 1 27.2039C1 29.3609 2.80662 31.1684 5.18831 31.3953V38H6.22878ZM2.0669 27.2279C2.0669 25.9015 2.95494 24.7069 4.31447 24.1982C5.67603 23.6915 7.24282 23.9714 8.2833 24.9099C9.32579 25.8466 9.63674 27.2571 9.07379 28.4829C8.50887 29.7068 7.18185 30.5063 5.70849 30.5063C3.69664 30.5063 2.06685 29.0391 2.06685 27.2279L2.0669 27.2279Z" fill="currentcolor" />
  </svg>
);
