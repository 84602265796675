import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { PDFDocument } from 'pdf-lib';
import download from 'downloadjs';
import lodashGet from 'lodash/get';
import { renderProjectName } from '../../utils/helpers/dataDisplay';
import { TestFitPdfExportContainerWrapper } from './PdfExportContainer.styles';
import { pageWidthInPx, pdfPageIdName } from '../../constants/pdfConsts';

const PdfExportContainer = (props) => {
  const { onPdfDoneLoading, isImperial, children, swappProfileResults, swappProject } = props;

  useEffect(() => {
    handlePdfDownload();
  }, []);

  const handlePdfDownload = async () => {
    const numberOfPages = document.getElementById('pagesWrapper').childElementCount;

    // eslint-disable-next-line new-cap
    const doc = new jsPDF('landscape', 'px', 'tabloid');
    for (let i = 0; i < numberOfPages; i++) {
      // html2canvas convert each page to canvas then we convert to PNG.
      // 'scale' property controls the quality of the img but make the process slower
      await html2canvas(document.getElementById(`${pdfPageIdName}${i}`), { scale: 2, width: pageWidthInPx })
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          if (i) {
            doc.addPage();
          }
          doc.addImage(imgData, 'PNG', 0, 0, 918, 594, i, 'FAST');
        });
    }
    await embedPlans(doc.output('arraybuffer'));
  };

  const embedPlans = async (basePdfBytes) => {
    // Empty page index - if changed it need to updated
    const emptyPageIndex = 3;

    // Plans PDF document from BE
    const planPdfUrl = lodashGet(swappProfileResults, 'pdfURL');
    const planPdfUrlBytes = await fetch(planPdfUrl).then((res) => res.arrayBuffer());

    // Load a PDFDocument from each of the PDFs
    const basePdfDoc = await PDFDocument.load(basePdfBytes);
    const planPdf = await PDFDocument.load(planPdfUrlBytes);

    // Embed each page of the planPdf inside a duplication of the empty page index
    const planPages = planPdf.getPages();
    if (isImperial) { // split the pdf in half, the first half is in Metric the the second is in Imperial
      planPages.splice(planPages.length / 2);
    } else {
      planPages.splice(0, planPages.length / 2);
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const planPage of planPages) {
      const plan = await basePdfDoc.embedPage(planPage);
      const [emptyBasePage] = await basePdfDoc.copyPages(basePdfDoc, [emptyPageIndex]);
      const page = basePdfDoc.addPage(emptyBasePage);
      page.drawPage(plan);
    }

    // Delete the empty page that as used fot the plans
    basePdfDoc.removePage(emptyPageIndex);

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await basePdfDoc.save();

    // Trigger the browser to download the PDF document
    download(pdfBytes, `${renderProjectName(swappProject.name)}.pdf`, 'application/pdf');
    onPdfDoneLoading();
  };

  return (
    <TestFitPdfExportContainerWrapper id="pagesWrapper">
      {children}
    </TestFitPdfExportContainerWrapper>
  );
};

PdfExportContainer.propTypes = {
  swappProfileResults: PropTypes.object,
  swappProject: PropTypes.object,
  onPdfDoneLoading: PropTypes.func,
  isImperial: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
};

export default PdfExportContainer;
