import React from 'react';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import EmptyMessage from 'components/common/EmptyMessage';
import { isSharedUrl, parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { getProfileByIdSelector, swappProjectSelector } from 'store/swappProfile';
import GenerateReportButton from 'components/feasibility/studies/result/report/GenerateReportButton';
import ReportOnePageContainer from 'components/feasibility/studies/result/report/ReportOnePageContainer';
import T from 'i18n-react';
import { MailLink } from 'styles/commonComponents.styles';
import { CONTACT_MEETING } from 'constants/globalConst';

const ReportContainer = () => {
  const locationData = parseLocationUrl(window.location);
  const swappProject = useSelector(swappProjectSelector);
  const profile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const hasSiteReportData = lodashGet(swappProject, 'site.siteData.hasReport');
  const hasReportData = !lodashIsEmpty(lodashGet(profile, 'result.reportData'));

  if (!hasReportData) {
    if (hasSiteReportData && !isSharedUrl()) {
      return (
        <div style={{ marginTop: '230px', width: '100%' }}>
          <EmptyMessage description={<GenerateReportButton />} />
        </div>
      );
    }

    const description = (
      <div>
        <span>{T.translate('Want to find out more about ‘Report’?')}</span>
        <MailLink href={CONTACT_MEETING} target="_blank" rel="noopener noreferrer">{T.translate('contact our sales ')}</MailLink>
        <span>{T.translate(' department for further information')}</span>
      </div>
    );

    return (
      <div style={{ marginTop: '230px', width: '100%' }}>
        <EmptyMessage description={description} />
      </div>
    );
  }

  return <ReportOnePageContainer />;
};

export default ReportContainer;
