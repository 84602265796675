import React from 'react';
import PropTypes from 'prop-types';
import { Plane } from '@react-three/drei';

const InteractionPlane = ({ onPointerMove, onPointerUp, onDoubleClick }) => (
  <Plane
    args={[1, 1]}
    scale={[1000, 1000, 1000]}
    position={[0, 0, 0]}
    onPointerMove={onPointerMove}
    onPointerUp={onPointerUp}
    onDoubleClick={onDoubleClick}
  >
    <meshStandardMaterial attach="material" opacity={0} transparent />
  </Plane>
);

InteractionPlane.propTypes = {
  onPointerMove: PropTypes.func,
  onPointerUp: PropTypes.func,
  onDoubleClick: PropTypes.func,
};

export default React.memo(InteractionPlane);
