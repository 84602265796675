import styled from 'styled-components';
import { Slider } from 'antd';

export const StyledSlider = styled(Slider)`

  .ant-slider-track {
    background-color: ${({ theme, disabled, isInvalid }) => {
    if (isInvalid) {
      return theme.colors.danger;
    }

    return disabled ? theme.colors.gray_06 : theme.colors.primaryColor;
  }} !important;
  }

  .ant-slider-dot {
    background-color: ${({ theme }) => theme.colors.gray_02};
    border-color: ${({ theme }) => theme.colors.gray_02};
  }
  
  .ant-slider-handle {
    border-color: ${({ theme, disabled, isInvalid }) => {
    if (isInvalid) {
      return theme.colors.danger;
    }

    return disabled ? theme.colors.gray_06 : theme.colors.primaryColor;
  }} !important;
    background-color: ${({ theme }) => theme.colors.gray_02};
  }
  
  .ant-slider-rail {
    background-color: ${({ theme }) => theme.colors.gray_02};
  }
  
  .ant-slider-mark-text {
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
