import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AREA_TARGET_FORM } from 'constants/fieldNames';
import { TEST_FIT_SIMPLE_FORM } from 'constants/dropdownKeys';
import { renderDescriptionBullets } from 'utils/helpers/tableHelpers';
import { FormItem } from './AreaTargetFormContainer.styles';
import RadioImages from '../../../common/RadioImages';
import comfortTypesIcons from '../../../../styles/static/icons/testFit/comfortTypes';

const AreaTargetFormOfficeComfortWrapper = styled.div`
  margin: 10px 0;
`;

const AreaTargetFormOfficeComfort = (props) => {
  const { disabled, isImperial } = props;

  const spaceEfficiencyArray = [
    {
      key: TEST_FIT_SIMPLE_FORM.SPACE_EFFICIENCY.TIGHT,
      image: comfortTypesIcons.tightIcon,
      value: 'AREA_TARGET_FORM_SIMPLE_FORM_TIGHT',
      description: renderDescriptionBullets([
        'AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_TIGHT_A',
        isImperial ? 'AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_TIGHT_B_SQF' : 'AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_TIGHT_B_SQM',
      ]),
    },
    {
      key: TEST_FIT_SIMPLE_FORM.SPACE_EFFICIENCY.BASIC,
      image: comfortTypesIcons.comfortIcon,
      value: 'AREA_TARGET_FORM_SIMPLE_FORM_BASIC',
      description: renderDescriptionBullets([
        'AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_BASIC_A',
        isImperial ? 'AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_BASIC_B_SQF' : 'AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_BASIC_B_SQM',
        'AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_BASIC_C',
      ]),
    },
    {
      key: TEST_FIT_SIMPLE_FORM.SPACE_EFFICIENCY.SPACIOUS,
      image: comfortTypesIcons.spaciousIcon,
      value: 'AREA_TARGET_FORM_SIMPLE_FORM_SPACIOUS',
      description: renderDescriptionBullets([
        'AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_SPACIOUS_A',
        isImperial ? 'AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_SPACIOUS_B_SQF' : 'AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_SPACIOUS_B_SQM',
        'AREA_TARGET_FORM_SIMPLE_FORM_SPACE_EFFICIENCY_SPACIOUS_C',
      ]),
    },
  ];

  return (
    <AreaTargetFormOfficeComfortWrapper>
      <FormItem
        name={AREA_TARGET_FORM.SPACE_EFFICIENCY}
        disabled={disabled}
        valuePropName="selected"
        noStyle
      >
        <RadioImages size={80} disabled={disabled} data={spaceEfficiencyArray} />
      </FormItem>
    </AreaTargetFormOfficeComfortWrapper>
  );
};

AreaTargetFormOfficeComfort.propTypes = {
  disabled: PropTypes.bool,
  isImperial: PropTypes.bool,
};

export default React.memo(AreaTargetFormOfficeComfort);
