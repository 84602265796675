import styled, { keyframes } from 'styled-components';
import Icon from '@ant-design/icons';

const fadeIn = keyframes`
  from { opacity: 1; max-height: 1000%; }
  to { opacity: 0; max-height: 0; }
`;

const fadeOut = keyframes`
  from { opacity: 0; max-height: 0; }
  to { opacity: 1; max-height: 100%; }
`;

export const FormWithLabelWrapper = styled.div`
  animation: ${({ isLoading, disableAnimation }) => {
    if (disableAnimation) {
      return;
    }
    return isLoading ? fadeIn : fadeOut;
  }} .6s ease-in-out;
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  max-height: ${({ isHidden }) => (isHidden ? '0px' : '1000%')};
  transition: all .6s ease-in-out;
  overflow: hidden;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  //justify-content: space-between;
`;

export const SuffixWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledIcon = styled(Icon)`
  margin-right: 10px;
  font-size: 40px;
  color: ${({ theme }) => theme.colors.textColor}
`;

export const TitleText = styled.div`
  font-size: ${({ theme, small }) => (small ? theme.font.size.medium : theme.font.size.large)};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.colors.textColor};
  text-transform: ${({ small }) => (small ? 'capitalize' : 'uppercase')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '10px')};
`;

export const SubTitleText = styled.div`
  color: ${({ theme }) => theme.colors.gray_07};
  font-size: ${({ theme }) => theme.font.size.small};
  margin-bottom: 4px;
`;

export const FormArea = styled.div`
  background-color: ${({ theme, explore }) => (explore ? 'transparent' : theme.colors.gray_01)};
  padding: ${({ borderLess }) => (borderLess ? 0 : '20px')};;
  margin: 0 auto ${({ explore, marginBottom }) => {
    if (explore) {
      return marginBottom ? `${marginBottom}px` : 0;
    }
    return marginBottom ? `${marginBottom}px` : '30px';
  }};
  max-width: ${({ width }) => (width ? `${width}px` : '100%')};
  min-width: 400px;
  box-shadow: ${({ explore }) => (explore ? 0 : '0 0 12px 0 rgba(0,0,0,0.10)')};
  border-radius: ${({ theme }) => theme.other.borderRadius};
`;

export const Separator = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray_03};
  margin-bottom: 15px;
`;

export const ChildrenWrapper = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  transition: all .3s ease-in-out;
`;
