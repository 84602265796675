import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import { themes, THEME_KEYS } from 'styles/themes';
import { API_AUTH_ROUTES } from 'constants/routes/api';
import { userSelector } from 'store/user';
import { swappProjectSelector } from './swappProfile';

const initialState = {
  unitSystemObject: {},
  currentCurrencyKey: 'USD',
  currencyTransferRate: 1,
  currencyTransferRates: {},
  currentTheme: themes[THEME_KEYS.DEFAULT],
  testFitStandard: 'DEFAULT',
};

export const getExchangeRates = createAsyncThunk(
  'userSettings/getExchangeRates',
  async (_, thunkAPI) => {
    const agent = thunkAPI.extra;
    const { data } = await agent.get(`${API_AUTH_ROUTES.exchangeRates}`);
    if (data === undefined || data === '') {
      return thunkAPI.rejectWithValue();
    }
    return { result: data };
  },
);

const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    changeUnitSystem(state, action) {
      const { isImperial, projectId } = action.payload;
      state.unitSystemObject[projectId] = isImperial;
    },
    setUnitSystem(state, action) {
      const { unitSystemObject } = action.payload;
      state.unitSystemObject = unitSystemObject;
    },
    setCurrencyTransferRates(state, action) {
      state.currencyTransferRates = action;
    },
    setCurrentCurrency(state, action) {
      const { currentCurrencyKey, currencyTransferRate } = action.payload;
      state.currentCurrencyKey = currentCurrencyKey;
      state.currencyTransferRate = currencyTransferRate;
    },
    setCurrentTheme(state, action) {
      const { swappProject } = action.payload;
      const parentProfile = lodashGet(swappProject, 'projectProfiles', []).find((profile) => !profile.parentProfileId, {});
      const projectTheme = lodashGet(parentProfile, 'profileData.settings.testFitStandard');
      const userTheme = lodashGet(swappProject, 'user.settings.flags.testFitStandard');

      state.currentTheme = { ...state.currentTheme, ...themes[projectTheme || userTheme || THEME_KEYS.DEFAULT] };
    },
  },
  extraReducers: {
    [getExchangeRates.fulfilled]: (state, action) => {
      const { result } = action.payload;
      state.currencyTransferRates = result.rates;
    },
  },
});

export const { changeUnitSystem, setUnitSystem, setCurrentCurrency, setCurrencyTransferRates, setCurrentTheme } = userSettingsSlice.actions;
export default userSettingsSlice.reducer;

// =========== Selectors =========== //
export const unitSystemObjectSelector = ({ userSettings }) => userSettings.unitSystemObject;
export const currencyTransferRatesSelector = ({ userSettings }) => userSettings.currencyTransferRates;
export const currentCurrencySelector = ({ userSettings }) => ({ currentCurrencyKey: userSettings.currentCurrencyKey, currencyTransferRate: userSettings.currencyTransferRate });
export const getUnitSystemByProjectIdSelector = (state, projectId) => lodashGet(state, `userSettings.unitSystemObject[${Number(projectId)}]`);

export const getUnitSystemByProjectIdAndUserSelector = (state, projectId) => {
  const user = userSelector(state);
  const swappProject = swappProjectSelector(state);
  const userDefaultUnit = lodashGet(user, 'settings.flags.isDefaultUnitImperial') || lodashGet(swappProject, 'user.settings.flags.isDefaultUnitImperial');
  const imperialDisplayObject = unitSystemObjectSelector(state);
  const isImperial = lodashGet(imperialDisplayObject, `[${projectId}]`, userDefaultUnit); // default false
  return isImperial;
};

export const currentThemeSelector = ({ userSettings }) => userSettings.currentTheme;

export const overridableSettingSelector = (state, settingName, defaultValue) => {
  const swappProject = swappProjectSelector(state);
  if (swappProject) {
    const parentProfile = lodashGet(swappProject, 'projectProfiles', []).find((profile) => !profile.parentProfileId, {});
    const projectValue = lodashGet(parentProfile, `profileData.settings.${settingName}`);
    if (projectValue != null) {
      return projectValue;
    }

    return lodashGet(swappProject, `user.settings.flags[${settingName}]`, defaultValue);
  }

  const user = userSelector(state);
  return lodashGet(user, `settings.flags[${settingName}]`, defaultValue);
};

export const useOverridableSetting = (settingName, defaultValue) => useSelector((state) => overridableSettingSelector(state, settingName, defaultValue));
