import React, { useEffect, useState } from 'react';
import { Form, message, notification, Radio, Tooltip } from 'antd';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsEqual from 'lodash/isEqual';
import lodashSortBy from 'lodash/sortBy';
import lodashIncludes from 'lodash/includes';
import { useDispatch, useSelector } from 'react-redux';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';
import { isFinancialsDataDisabled } from 'utils/helpers/feasibilityFinancialHelper';
import { clearError, setProjectProfileData } from 'utils/model/projectProfileData';
import { isSharedUrl, parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { userSelector } from 'store/user';
import { CARD_INFO_TYPE } from 'constants/feasibilityConts';
import FeasibilityStudiesRoute from 'components/feasibility/studies/FeasibilityStudiesRoute';
import { PROFILE_STATUS, PROJECT_STAGES } from 'constants/profileConsts';
import {
  getProfileByIdSelector,
  loadingSelector as loadingProject,
  swappProjectSelector,
} from 'store/swappProfile/selectors';
import icons from 'styles/static/icons/feasibilityIcons/study';
import sidebarIcons from 'styles/static/icons/sidebarIcons';
import { updateActiveProfile } from 'store/activeProfile';
import { useProfileResult } from 'store/swappProfile/selectors/swappSelectors';
import { useOverridableSetting } from 'store/userSettings';
import { useHistory } from 'react-router';
import {
  pollSwappProjectAction,
  updateProfileAction,
  updateProjectProfileAction,
} from 'store/swappProfile/actions/swappProfileActions';
import FeasibilityTargetForm from 'components/feasibility/studies/form/FeasibilityTargetForm';
import LoadingSpinner from 'components/common/LoadingSpinner';
import ResultSplitViewCard from 'components/common/ResultSplitViewCard';
import FeasibilityResultCard from '../FeasibilityResultCard';
import FeasibilitySwpProjectResultCard from '../FeasibilitySwpProjectResultCard';
import ResultSplitView from '../../../common/ResultSplitView';
import { CardTypeIcon } from './FeasibilityTargetFormContainer.Styles';
import { IS_DEVELOPMENT_STAGE } from '../../../../constants/featureFlags';

let debounceTimer = null;
const width = 630;

const FeasibilityTargetFormContainer = () => {
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const swappProject = useSelector(swappProjectSelector);
  const isLoadingProject = useSelector(loadingProject);
  const user = useSelector(userSelector);
  const currentProfile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const result = useProfileResult(currentProfile);
  updateActiveProfile(locationData.profileId, result);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [cardInfoType, setCardInfoType] = useState(CARD_INFO_TYPE.APARTMENT_MIXTURE);
  const hideFinancialByProjectId = lodashIncludes(lodashGet(user, 'settings.flags.hideFinancialByProjectIdList'), Number(locationData.projectId));
  const profileIndex = lodashGet(swappProject, 'projectProfiles', []).findIndex((profile) => !profile.parentProfileId);
  const parentProfile = lodashGet(swappProject, `projectProfiles[${profileIndex}]`);
  const parentProfileId = lodashGet(parentProfile, 'id');
  const formData = lodashGet(parentProfile, 'profileData.form', {});
  const resultProfiles = lodashGet(swappProject, 'projectProfiles', []).filter((profile) => profile.parentProfileId);
  const sortedProfiles = lodashSortBy(resultProfiles, ['id']);
  const isWaitingForResults = lodashGet(parentProfile, 'status') === PROFILE_STATUS.WAITING_FOR_DATA;

  useEffect(() => {
    if (isWaitingForResults) {
      dispatch(pollSwappProjectAction(locationData.projectId, parentProfileId, history));
    }
  }, [isWaitingForResults]);

  useEffect(() => {
    const error = lodashGet(parentProfile, 'profileData.errorData.type');
    if (error) {
      errorNotification(error);
    }
  }, [swappProject]);

  // will Mount
  useEffect(() => {
    clearTimeout(debounceTimer);
  }, []);

  // will unMount
  useEffect(() => () => {
    clearTimeout(debounceTimer);
  }, []);

  // set form data from BE
  // we added window.location.href to deps se when the user press back in the browser we make sure to load the data from formData
  useEffect(() => {
    if (swappProject && parentProfileId) {
      if (!lodashIsEmpty(formData) && !isLoadingProject) {
        form.setFieldsValue(formData);
      }
    }
  }, [parentProfileId, window.location.href]);

  const isHBEnergyEnabled = useOverridableSetting('isHBEnergyEnabled', false);
  const isSketchCentric = useOverridableSetting('isSketchCentric', false);

  const handleOnChange = () => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const currentFormData = form.getFieldsValue();

    debounceTimer = setTimeout(() => {
      if (lodashIsEmpty(currentFormData)
          || lodashIsEmpty(swappProject)
          || lodashIsEqual(currentFormData, formData)
          || isLoadingProject
          || profileIndex === -1
      ) {
        return;
      }

      dispatch(updateProjectProfileAction(parentProfileId, setProjectProfileData(currentFormData, lodashGet(swappProject, 'siteId'))))
        .catch((err) => message.error(err));
    }, 700);
  };

  const renderCard = (profile, onClick) => (profile.stage === PROJECT_STAGES[UI_AUTHORIZE_PATH.ONE_CLICK_BUILDING]
    ? (
      <ResultSplitViewCard
        key={profile.id}
        height={480}
        onClick={onClick}
        body={<FeasibilitySwpProjectResultCard profile={profile} infoType={cardInfoType} />}
        profile={profile}
      />
    )
    : (
      <ResultSplitViewCard
        key={profile.id}
        height={480}
        onClick={onClick}
        hideFavoriteButton={lodashGet(profile, 'result.singleOptionMassingOptions.massing_options[0].masses', []).length === 0}
        body={<FeasibilityResultCard profile={profile} infoType={cardInfoType} />}
        score={lodashGet(profile, 'result.study.buildingInfo.PRIORITY_FIT_SCORE.value')}
        profile={profile}
      />
    )
  );

  const handleUpdate = (e) => {
    e.preventDefault();

    form.validateFields()
      .then(() => {
        dispatch(updateProfileAction(parentProfileId));
      })
      .catch((error) => {
        error.errorFields.forEach((errorField) => {
          message.warning({
            content: errorField.errors[0],
            key: errorField.errors[0],
          });
        });
      });
  };

  const renderFilters = () => {
    const disableFinancials = isFinancialsDataDisabled(swappProject?.user, parentProfile?.projectId);
    if (disableFinancials) {
      return null;
    }

    const cardInfoTypeArray = [
      { value: CARD_INFO_TYPE.APARTMENT_MIXTURE, icon: icons.apartmentMixtureIcon, tooltip: T.translate('FEASIBILITY_TARGET_FORM_CARD_TYPE_APARTMENT_MIXTURE') },
    ];

    if (!hideFinancialByProjectId) {
      cardInfoTypeArray.push({ value: CARD_INFO_TYPE.FINANCIAL, icon: icons.financialViabilityIcon, tooltip: T.translate('FEASIBILITY_TARGET_FORM_CARD_TYPE_FINANCIAL_VIABILITY') });
    }

    if (sortedProfiles.find((profile) => lodashGet(profile, 'result.energyStudy.metadata'))) {
      cardInfoTypeArray.push({ value: CARD_INFO_TYPE.HVAC, icon: icons.hvacDesignIcon, tooltip: T.translate('FEASIBILITY_TARGET_FORM_CARD_TYPE_HVAC') });
    }

    if (cardInfoTypeArray.length === 1) {
      return null;
    }

    return (
      <>
        <Radio.Group defaultValue={CARD_INFO_TYPE.APARTMENT_MIXTURE} buttonStyle="solid" onChange={(e) => setCardInfoType(e.target.value)}>
          {cardInfoTypeArray.map((item, index) => (
            <Tooltip title={item.tooltip} key={index}>
              <Radio.Button key={item.value} value={item.value} disabled={item.disabled}>
                <CardTypeIcon component={item.icon} />
              </Radio.Button>
            </Tooltip>
          ))}
        </Radio.Group>
      </>
    );
  };

  const errorNotification = (errorKey) => {
    const errors = {
      EMPTY: {
        title: 'FEASIBILITY_TARGET_FORM_CONTAINER_ERROR_EMPTY_TITLE',
        description: 'FEASIBILITY_TARGET_FORM_CONTAINER_ERROR_EMPTY_DESCRIPTION',
      },
      ERROR: {
        title: 'FEASIBILITY_TARGET_FORM_CONTAINER_ERROR_GENERAL_TITLE',
        description: 'FEASIBILITY_TARGET_FORM_CONTAINER_ERROR_GENERAL_DESCRIPTION',
      },
    };

    notification.error({
      duration: 20,
      key: 'generalError',
      message: T.translate(errors[errorKey].title),
      description: T.translate(errors[errorKey].description),
      onClose: () => updateProjectProfileAction(Number(parentProfileId), clearError()),
    });
  };

  if (!swappProject) {
    return <LoadingSpinner />;
  }

  const sidebarButtons = [
    { id: UI_AUTHORIZE_PATH.program, icon: sidebarIcons.buildingIcon, text: 'FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_BUILDING' },
    { id: UI_AUTHORIZE_PATH.parking, icon: sidebarIcons.parkingIcon, text: 'FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_PARKING' },
    { id: UI_AUTHORIZE_PATH.environment, icon: sidebarIcons.envIcon, text: 'FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_ENV' },
    { id: UI_AUTHORIZE_PATH.energy, icon: sidebarIcons.energyIcon, text: 'FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_ENERGY' },
    { id: UI_AUTHORIZE_PATH.hbenergy, icon: sidebarIcons.energyIcon, text: 'FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_HB_ENERGY', hidden: !isHBEnergyEnabled },
    { id: UI_AUTHORIZE_PATH.financial, icon: sidebarIcons.financialIcon, text: 'FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_FINANCIAL', hidden: hideFinancialByProjectId },
    { id: UI_AUTHORIZE_PATH.design, icon: sidebarIcons.designIcon, text: 'FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_DESIGN' },
    { id: UI_AUTHORIZE_PATH.views, icon: sidebarIcons.markupsIcon, text: 'FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_VIEWS', hidden: !IS_DEVELOPMENT_STAGE },
    { id: UI_AUTHORIZE_PATH.build, icon: sidebarIcons.cdIcon, text: 'FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_CD' },
    { id: UI_AUTHORIZE_PATH.report, icon: sidebarIcons.reportIcon, text: 'FEASIBILITY_TARGET_FORM_CONTAINER_SIDEBAR_REPORT' },
  ];

  return (
    <>
      {isLoadingProject && <LoadingSpinner />}
      <ResultSplitView
        disabled={isSharedUrl()}
        loading={isWaitingForResults}
        handleUpdate={handleUpdate}
        width={width}
        renderCard={renderCard}
        filterTab={renderFilters}
        sidebarButtons={sidebarButtons}
        renderExpandData={<FeasibilityStudiesRoute />}
      >
        {!isSketchCentric && (
        <Form name="FeasibilityTargetForm" form={form} onFieldsChange={() => handleOnChange()} scrollToFirstError>
          <FeasibilityTargetForm width={width} form={form} formData={formData} handleOnChange={handleOnChange} />
        </Form>
        )}
      </ResultSplitView>
    </>
  );
};

export default FeasibilityTargetFormContainer;
