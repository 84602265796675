import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  useMarkupTransform,
  useIsMarkupSelected,
  changePoints,
  rotate,
  setIsDragged,
} from 'store/markups';
import {
  vecLerp,
  vecAdd,
  vecSub,
  vecMoveTowards,
  rotatePolygon,
  getPointsCenter,
  vecNormalize,
  getPolygonSignedArea,
} from 'utils/algorithms/algorithmHelpers';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashGet from 'lodash/get';
import MarkupFrame from './MarkupFrame';
import TansformPoint from './TansformPoint';
import RotationPoint from './RotationPoint';
import CloudMarkupGraphic from './CloudMarkupGraphic';
import { Z_INDEX } from './constants';

const ROTATION_POINT_DISTANCE = 2;
const EPS = 0.01;

const movePointsToKeepRectangleShape = (id, newPoint, rotation, newPoints) => {
  const oppositePointIndex = (id + 2) % 4;
  const secondPoint = newPoints[oppositePointIndex];
  const newCenter = vecLerp(newPoint, secondPoint, 0.5);
  const [unrotatedNewPoint, unrotatedOppositePoint] = rotatePolygon([newPoint, secondPoint], newCenter, -rotation);

  const unrotatedPoly = [];
  unrotatedPoly[id] = unrotatedNewPoint;
  unrotatedPoly[oppositePointIndex] = unrotatedOppositePoint;

  if (id % 2 === 0) {
    unrotatedPoly[(id + 1) % 4] = [unrotatedNewPoint[0], unrotatedOppositePoint[1]];
    unrotatedPoly[(id + 3) % 4] = [unrotatedOppositePoint[0], unrotatedNewPoint[1]];
  } else {
    unrotatedPoly[(id + 1) % 4] = [unrotatedOppositePoint[0], unrotatedNewPoint[1]];
    unrotatedPoly[(id + 3) % 4] = [unrotatedNewPoint[0], unrotatedOppositePoint[1]];
  }

  return rotatePolygon(unrotatedPoly, newCenter, rotation);
};

const moveMidPoints = (id, newPoint, rotation, newPoints, center) => {
  const unrotatedPoints = rotatePolygon(newPoints, center, -rotation);
  const [newPointUnrot] = rotatePolygon([newPoint], center, -rotation);

  const prevPoint = unrotatedPoints[id];
  const nextPoint = unrotatedPoints[(id + 1) % unrotatedPoints.length];

  const delta = vecSub(newPointUnrot, vecLerp(nextPoint, prevPoint, 0.5));

  const movingAxis = Math.abs(prevPoint[0] - nextPoint[0]) < EPS ? 0 : 1;

  prevPoint[movingAxis] += delta[movingAxis];
  nextPoint[movingAxis] += delta[movingAxis];

  return rotatePolygon(unrotatedPoints, center, rotation);
};

const CloudMarkup = ({ id, isViewOnly }) => {
  const dispatch = useDispatch();
  const transform = useMarkupTransform(id);
  const isSelected = useIsMarkupSelected(id);
  const points = lodashGet(transform, 'points');
  const rotation = lodashGet(transform, 'rotation');

  const center = useMemo(() => getPointsCenter(points), [points]);

  const onPointMoved = useCallback((event, pointIndex, newPoint) => {
    if (isViewOnly || lodashIsEmpty(transform)) {
      return;
    }
    let newPoints = [...points];
    newPoints[pointIndex] = newPoint;
    newPoints = movePointsToKeepRectangleShape(pointIndex, newPoint, rotation, newPoints);
    dispatch(changePoints({ id, points: newPoints, isDragged: true }));
  });

  const onFrameMoved = useCallback((event, delta) => {
    if (lodashIsEmpty(transform) || isViewOnly) {
      return;
    }
    const newPoints = points.map((point) => vecAdd(point, delta));
    dispatch(changePoints({ id, points: newPoints, isDragged: true }));
  }, [transform]);

  const onRotation = useCallback((event, deltaAngle) => {
    if (lodashIsEmpty(transform)) {
      return;
    }
    dispatch(rotate({ id, deltaAngle, isDragged: true }));
  }, [transform]);

  const polygonSign = useMemo(() => !!points && -Math.sign(getPolygonSignedArea(points)), [points]);

  const rotationPointPosition = useMemo(() => {
    if (lodashIsEmpty(transform)) {
      return;
    }
    const basePos = vecLerp(points[0], points[1], 0.5);
    const dir = vecNormalize(vecSub(points[1], points[0]));
    const perpDir = [-dir[1], dir[0]];
    const newPos = vecMoveTowards(basePos, vecAdd(basePos, perpDir), ROTATION_POINT_DISTANCE * polygonSign);
    return newPos;
  }, [transform]);

  const onMidPointMoved = (event, pointIndex, newPoint) => {
    let newPoints = [...points];
    newPoints = moveMidPoints(pointIndex, newPoint, rotation, newPoints, center);
    dispatch(changePoints({ id, points: newPoints, isDragged: true }));
  };

  const onPointerUp = (event) => {
    if (isViewOnly || lodashIsEmpty(transform)) {
      return;
    }
    event.stopPropagation();
    dispatch(setIsDragged({ isDragged: false }));
  };

  if (lodashIsEmpty(transform)) {
    return null;
  }

  return (
    <>
      {isSelected && (
      <MarkupFrame
        points={points}
        center={center}
        rotation={rotation}
        onFrameMoved={onFrameMoved}
        isViewOnly={isViewOnly}
      />
      )}
      {isSelected && (
      <RotationPoint
        position={[...rotationPointPosition, Z_INDEX.POINTS]}
        onPointMoved={onRotation}
        onPointerUp={onPointerUp}
        center={center}
      />
      )}
      <CloudMarkupGraphic
        points={points}
        center={center}
        rotation={rotation}
        isSelected={isSelected}
        polygonSign={polygonSign}
        id={id}
        isViewOnly={isViewOnly}
      />
      {isSelected && points.map((p, index) => (
        <TansformPoint // corner points
          key={index}
          pointIndex={index}
          position={[...p, Z_INDEX.POINTS]}
          onPointMoved={onPointMoved}
          transform={transform}
          center={center}
          onPointerUp={onPointerUp}
        />
      ))}
      {/* Mid points */}
      {isSelected && points.map((p, index) => (
        <TansformPoint
          key={index}
          pointIndex={index}
          position={[...vecLerp(p, points[(index + 1) % points.length], 0.5), Z_INDEX.POINTS]}
          onPointMoved={onMidPointMoved}
          transform={transform}
          center={center}
          onPointerUp={onPointerUp}
        />
      ))}
    </>
  );
};

CloudMarkup.propTypes = {
  id: PropTypes.string,
  isViewOnly: PropTypes.bool,
};

export default React.memo(CloudMarkup);
