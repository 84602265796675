import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import { useThree, useFrame } from 'react-three-fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import { threeJsGlobals } from 'store/threeJsGlobals';
import { useSwappThree } from '../../hooks/useSwappThree';

const CameraController = (props) => {
  const { isOrthographic, orbitControlsMinMax, minMaxZoom, onCameraChange, viewerId } = props;
  const { setDefaultCamera, camera, invalidate } = useThree();
  const orthographicCameraRef = useRef(isOrthographic ? camera : new THREE.OrthographicCamera());
  const perspectiveCameraRef = useRef(isOrthographic ? new THREE.PerspectiveCamera() : camera);
  const controllerRef = useRef();
  const { setDefaultController } = useSwappThree();
  const onCameraChangeCallback = (e) => onCameraChange(e, viewerId);

  const resetBothCameras = () => {
    orthographicCameraRef.current.zoom = CameraController.DEFAULT_ORTHO_PROPS.zoom;
    orthographicCameraRef.current.position.set(...CameraController.DEFAULT_ORTHO_PROPS.position);
    perspectiveCameraRef.current.position.set(...CameraController.DEFAULT_PERSPECTIVE_PROPS.position);
  };

  useFrame(() => {
    threeJsGlobals.setCameraRotation(camera.rotation);
  });

  // add camera/camera controller to threeJsGlobals
  useEffect(() => {
    if (!controllerRef.current || !orthographicCameraRef.current || !onCameraChange) {
      return;
    }
    controllerRef.current.addEventListener('change', onCameraChangeCallback);
    const obj = {
      viewerId,
      camera: orthographicCameraRef.current,
      controller: controllerRef.current,
      invalidate,
    };

    threeJsGlobals.addCameraAndController(obj);
  }, [orthographicCameraRef.current, onCameraChange]);

  // unmount
  useEffect(() => () => {
    if (!controllerRef.current || !onCameraChange) {
      return;
    }

    controllerRef.current.removeEventListener('change', onCameraChangeCallback);
  }, []);

  // When changing isOrthographic flag:
  // 1. Changing the defaultcamera
  useEffect(() => {
    resetBothCameras();
    window.cam = isOrthographic ? orthographicCameraRef.current : perspectiveCameraRef.current;
    setDefaultCamera(isOrthographic ? orthographicCameraRef.current : perspectiveCameraRef.current);
  }, [setDefaultCamera, isOrthographic]);

  // 2. Changing the default controller (holding the default camera)
  useEffect(() => {
    if (controllerRef.current) {
      setDefaultController(controllerRef.current);
    }
  }, [camera]);

  // Reset cameras to default props
  useEffect(() => {
    resetBothCameras();
  }, []);

  return (
    <>
      {/* {isOrthographic ? (
        <MapControls
          minZoom={lodashGet(mapControlsMinMax, '[0]') || 1}
          maxZoom={lodashGet(mapControlsMinMax, '[1]') || 10}
          enableRotate={false}
          dampingFactor={0.3}
          zoomSpeed={4}
          mouseButtons={{ LEFT: THREE.MOUSE.ROTATE, MIDDLE: THREE.MOUSE.PAN, RIGHT: THREE.MOUSE.PAN }}
        />
      ) : ( */}
      <OrbitControls
        // camera={isOrthographic ? orthographicCameraRef.current : perspectiveCameraRef.current}
        enableRotate={!isOrthographic}
        ref={controllerRef}
        minDistance={lodashGet(orbitControlsMinMax, '[0]') || 50}
        maxDistance={lodashGet(orbitControlsMinMax, '[1]') || 900}
        minZoom={lodashGet(minMaxZoom, '[0]')}
        maxZoom={lodashGet(minMaxZoom, '[1]')}
        dampingFactor={0.6}
        maxPolarAngle={Math.PI / 2.1}
        mouseButtons={{ LEFT: THREE.MOUSE.ROTATE, MIDDLE: THREE.MOUSE.PAN, RIGHT: THREE.MOUSE.PAN }}
      />
      {/* {isOrthographic ? (
        <OrthographicCamera ref={orthographicCameraRef} position={[0, 0, 270]} zoom={4} />
      ) : (
        <PerspectiveCamera ref={perspectiveCameraRef} position={[-32, -118.7, 115]} near={1} far={2000} />
      )} */}
    </>
  );
};

CameraController.DEFAULT_PERSPECTIVE_PROPS = {
  position: [-32, -118.7, 115], near: 1, far: 2000,
};

CameraController.DEFAULT_ORTHO_PROPS = {
  position: [0, 0, 270], zoom: 4,
};

CameraController.propTypes = {
  isOrthographic: PropTypes.bool,
  viewerId: PropTypes.string,
  orbitControlsMinMax: PropTypes.array,
  minMaxZoom: PropTypes.array,
  onCameraChange: PropTypes.func,
};

export default CameraController;
