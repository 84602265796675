/* eslint-disable prefer-destructuring */
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import * as THREE from 'three';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { rotatePolygon, vecAdd, vecDistance } from 'utils/algorithms/algorithmHelpers';
import { MeshLineRaycast } from 'threejs-meshline';
import { selectMarkup, useMarkupColor, useIsMarkupDragged, useMarkupLineWidth } from 'store/markups';
import { shadeHexColor } from 'utils/helpers/threeHelpers';
import { changeCursor } from '../helpers/SelectionHelpers';
import { Z_INDEX } from './constants';

const CIRCLE_SEGMENTS = 16;
const INNER_RECT_OFFSET = 1.5;

const EPS = 0.1;
const abs = Math.abs;

const getCircle = (point, radius, fromAngle, toAngle) => {
  const vertices = [];
  const direction = Math.sign(toAngle - fromAngle);
  const condition = (i) => (direction > 0 ? i < toAngle : i > toAngle);
  for (let i = fromAngle; condition(i); i += (toAngle - fromAngle) / CIRCLE_SEGMENTS) {
    const x = direction * Math.sin(i) * radius;
    const y = direction * Math.cos(i) * radius;
    vertices.push(vecAdd(point, [x, y]), vecAdd(point, [x, y]));
  }
  return vertices;
};

const getInnerRectangle = (unrotatedPoints, radius) => {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;
  unrotatedPoints.forEach((point) => {
    if (point[0] > maxX) maxX = point[0];
    if (point[1] > maxY) maxY = point[1];
    if (point[0] < minX) minX = point[0];
    if (point[1] < minY) minY = point[1];
  });
  const edge = radius + INNER_RECT_OFFSET;
  unrotatedPoints.forEach((point) => {
    if (abs(point[0] - maxX) < EPS) point[0] -= edge;
    if (abs(point[1] - maxY) < EPS) point[1] -= edge;
    if (abs(point[0] - minX) < EPS) point[0] += edge;
    if (abs(point[1] - minY) < EPS) point[1] += edge;
  });
  return unrotatedPoints;
};

const CloudMarkupGraphic = ({ points, center, rotation, id, isSelected, polygonSign, isViewOnly }) => {
  const [isHovered, setIsHovered] = useState(false);
  const isAnyMarkupBeingDragged = useIsMarkupDragged();
  const color = useMarkupColor(id);
  const lineWidth = useMarkupLineWidth(id);

  const height = vecDistance(points[0], points[1]);
  const width = vecDistance(points[1], points[2]);

  const circleRadius = lineWidth;

  const dispatch = useDispatch();

  const circles = useMemo(() => {
    if (height < 4 && width < 4) {
      return getCircle(center, Math.max(height, width) / 4, -Math.PI / 10, Math.PI * 2);
    }
    const unrotatedPoints = rotatePolygon([...points, points[0]], center, -rotation);
    const innerRect = getInnerRectangle(unrotatedPoints, circleRadius);
    const inCCW = polygonSign < 0;

    const vertices = [];
    innerRect.forEach((point, index) => {
      if (!innerRect[index + 1]) {
        return;
      }
      const currentAxis = abs(point[0] - innerRect[index + 1][0]) < EPS ? 1 : 0;
      const diff = innerRect[index + 1][currentAxis] - point[currentAxis];
      const direction = Math.sign(diff);
      const numCircles = Math.ceil(abs(diff) / (circleRadius * 2));
      const newTmpRadius = abs(diff) / numCircles;
      const loopCondition = (i) => (direction > 0 ? i < innerRect[index + 1][currentAxis] : i > innerRect[index + 1][currentAxis]);
      for (let i = point[currentAxis]; loopCondition(i); i += (direction * (newTmpRadius * 2))) {
        const pp = [];
        pp[currentAxis] = i;
        pp[+!currentAxis] = point[+!currentAxis];
        let fromAngle = currentAxis ? 0 : Math.PI / 2;
        let toAngle = currentAxis ? Math.PI : Math.PI * (3 / 2);
        if (direction > 0) {
          fromAngle = currentAxis ? -Math.PI : -Math.PI / 2;
          toAngle = currentAxis ? 0 : Math.PI / 2;
        }
        const dist = vecDistance(pp, innerRect[index + 1]);
        const isFirstCircleInLine = i === point[currentAxis];
        if (inCCW) {
          [fromAngle, toAngle] = [toAngle, fromAngle];
        }
        if (isFirstCircleInLine) {
          fromAngle -= polygonSign * (Math.PI / 4.5);
        }
        const isLastCircleInLine = dist < circleRadius * 2;
        if (isLastCircleInLine) {
          toAngle -= polygonSign * (Math.PI / 3);
        }
        if (dist > circleRadius / 2) { // dist > circleRadius / 2 && index === 2
          vertices.push(...getCircle(pp, newTmpRadius, fromAngle, toAngle));
        }
      }
    });
    if (!vertices.length) {
      return [center, center];
    }
    vertices.push(vertices[0]);
    return rotatePolygon(vertices, center, rotation);
  }, [points, center, rotation, lineWidth]);

  useEffect(() => {
    if (!isSelected && isHovered) {
      setIsHovered(false);
    }
  }, [isSelected]);

  const onLinesClicked = useCallback((event) => {
    if (isViewOnly) {
      return;
    }
    event.stopPropagation();
    dispatch(selectMarkup({ id }));
  }, [points]);

  const onPointerOver = useCallback((e) => {
    if (isViewOnly) {
      return;
    }
    if (!isSelected && !isAnyMarkupBeingDragged) {
      e.stopPropagation();
      changeCursor('pointer');
      setIsHovered(true);
    }
  }, [points, isSelected, isAnyMarkupBeingDragged, setIsHovered]);

  const onPointerOut = useCallback(() => {
    if (isViewOnly) {
      return;
    }
    if (!isSelected && !isAnyMarkupBeingDragged) {
      changeCursor('crosshair');
      setIsHovered(false);
    }
  }, [points, isSelected, isAnyMarkupBeingDragged, setIsHovered]);

  return (
    <mesh
      raycast={MeshLineRaycast}
      onPointerOver={onPointerOver}
      onPointerOut={onPointerOut}
      onPointerUp={onLinesClicked}
      position={[0, 0, Z_INDEX.GRAPHICS]}
    >
      <meshLine attach="geometry" vertices={circles.map((v) => new THREE.Vector3(...v))} />
      <meshLineMaterial attach="material" color={(isHovered && !isSelected) ? shadeHexColor(color, -0.2) : color} lineWidth={lineWidth} />
    </mesh>
  );
};

CloudMarkupGraphic.propTypes = {
  points: PropTypes.array,
  center: PropTypes.array,
  rotation: PropTypes.number,
  polygonSign: PropTypes.number,
  id: PropTypes.string,
  isSelected: PropTypes.bool,
  isViewOnly: PropTypes.bool,
};

export default React.memo(CloudMarkupGraphic);
