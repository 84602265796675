import React from 'react';
import { useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import T from 'i18n-react';
import { useHistory } from 'react-router';
import { legendIcons } from 'constants/feasibilityReportConts';
import { feasibilityReportSelector } from 'store/swappProfile/selectors';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import {
  ContentColumnWrapper, ContentImage, ContentWrapper, StyledText,
  ContentTitle, VerticalTitleContentWrapper, LegendWrapper, LegendIcon, ContentRowWrapper, BulletStyledText,
} from '../ReportPages.styles';

const SiteConstraints = () => {
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const reportData = useSelector((state) => feasibilityReportSelector(state, locationData.profileId));
  const siteConstraintsData = lodashGet(reportData, 'strategy.siteConstraintsData');
  const descriptionBullets = lodashGet(siteConstraintsData, 'descriptionBullets', []);

  return (
    <VerticalTitleContentWrapper>
      <ContentTitle>{T.translate('Site Constraints')}</ContentTitle>
      <ContentRowWrapper>
        <ContentColumnWrapper width={250}>
          <ContentColumnWrapper>

            {/* ======== description ======== */}
            <StyledText size="small" marginBottom={10}>{T.translate(siteConstraintsData.description)}</StyledText>
            {descriptionBullets.map((row, index) => <BulletStyledText key={index} size="small">{T.translate(row)}</BulletStyledText>)}

            {/* ======== legend ======== */}
            <span>
              {siteConstraintsData.legendItems.map((item, index) => (
                <LegendWrapper key={index}>
                  <LegendIcon component={legendIcons[item].icon} />
                  <StyledText middle size="small">{legendIcons[item].label}</StyledText>
                </LegendWrapper>
              ))}
            </span>
          </ContentColumnWrapper>
        </ContentColumnWrapper>

        {/* ======== image ======== */}
        <ContentWrapper width={800}>
          <ContentImage src={siteConstraintsData.areaMainImage} width={790} />
        </ContentWrapper>
      </ContentRowWrapper>
    </VerticalTitleContentWrapper>
  );
};

export default React.memo(SiteConstraints);
