/* eslint-disable import/no-cycle */
/* eslint-disable no-restricted-globals */
import PropTypes from 'prop-types';
import React, { useRef, useCallback } from 'react';
import { useDrag } from 'react-use-gesture';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';
import * as THREE from 'three';
import { useThree } from 'react-three-fiber';
import { Circle } from '@react-three/drei';
import { vecDistance } from 'utils/algorithms/algorithmHelpers';
import { projectPointToPolygon } from './sketchHelpers';
import { notifyDragFinished } from '../../helpers/ThreeHelpers';
import { changeCursor } from '../../helpers/SelectionHelpers';
import * as SketchToolReducer from '../../../../store/sketchToolStore';
import { MAX_CLICK_TIME } from './constants';
import { ParkingEntrance } from '.';

const CLICK_TIME = 300;

const EditablePoint = (props) => {
  const {
    uuid,
    color,
    point,
    points,
    isClosed,
    isPolygon,
    parkingEntrancePosition,
    finishSketchSession,
    updateHistory,
    planeClickedTime = 0,
    halfTubeWidth,
    isDisabled,
  } = props;
  const dispatch = useDispatch();
  const setPoints = (p) => dispatch(SketchToolReducer.setPoints(p));
  const setMovingPoint = (p) => dispatch(SketchToolReducer.setMovingPoint(p));
  const setPointInProgress = (p) => dispatch(SketchToolReducer.setPointInProgress(p));
  const setIsClosed = (p) => dispatch(SketchToolReducer.setIsClosed(p));
  const setParkingEntrancePosition = (p) => dispatch(SketchToolReducer.setParkingEntrancePosition(p));

  const position = [...point, 1];
  const mesh = useRef();
  const downTime = useRef();
  const upTime = useRef();
  const lastButton = useRef();
  const { camera } = useThree();

  const onPointMoved = (id, newPoint) => {
    const newPoints = [...points];
    newPoints[id] = newPoint;
    setMovingPoint(id);
    setPoints(newPoints);
    if (id !== 0) {
      setPointInProgress(null);
    } else {
      setPointInProgress(new THREE.Vector3(newPoint[0], newPoint[1], 0));
    }
  };

  const onFinishDrag = (event, id) => {
    let isCloseToFirst = false;
    const newPoints = [...points];

    const firstPoint = points[0];
    isCloseToFirst = !isClosed && firstPoint && vecDistance(points[id], firstPoint) < halfTubeWidth;

    // If the last was moved very close to the first one - close the shape (in a not closed one)
    if (!isClosed && id === points.length - 1 && event.button === 0 && points.length > 1) {
      setIsClosed(isClosed || isCloseToFirst);
      if (isCloseToFirst) {
        newPoints.pop();
        setPoints(newPoints);
      }
    }

    if (isPolygon && parkingEntrancePosition) {
      setParkingEntrancePosition(projectPointToPolygon(parkingEntrancePosition.position, newPoints, ParkingEntrance.PLANE_SIZE));
    }

    if (!isClosed && id === 0 && event.button === 0 && points.length > 2 && event.timeStamp - downTime.current < MAX_CLICK_TIME) {
      setIsClosed(isClosed || isCloseToFirst);
    }

    updateHistory(newPoints);
    setMovingPoint(undefined);
  };

  const onRightClickUp = (id) => {
    if (!isNaN(id)) {
      const newPoints = [...points];
      newPoints.splice(id, 1);
      if (newPoints.length < 3) {
        setIsClosed(false);
        if (isPolygon) {
          updateHistory([]);
          setPoints([]);
          setParkingEntrancePosition(null);
          return;
        }
      }
      updateHistory(newPoints);
      if (isPolygon && parkingEntrancePosition) {
        setParkingEntrancePosition(projectPointToPolygon(parkingEntrancePosition.position, newPoints, ParkingEntrance.PLANE_SIZE));
      }
      setPoints(newPoints);
    }
  };

  const bindDrag = useDrag(
    ({ delta: [mx, my], event }) => {
      if (isDisabled) {
        return;
      }
      if (event) event.stopPropagation();
      if (mesh.current) {
        if (event.type === 'pointerdown') {
          downTime.current = event.timeStamp;
        }
        if (event.buttons === 1) {
          const globalQuaternion = new THREE.Quaternion();
          mesh.current.getWorldQuaternion(globalQuaternion);
          globalQuaternion.invert();
          const deltaVector = new THREE.Vector3(mx / camera.zoom, -my / camera.zoom, 0);
          deltaVector.applyQuaternion(globalQuaternion);
          mx = deltaVector.x;
          my = deltaVector.y;
          const newPoint = [point[0] + mx, point[1] + my];
          onPointMoved(uuid, newPoint);
        }
        if (event.type === 'pointerup') {
          if (event.timeStamp - Math.max(upTime.current, planeClickedTime) < CLICK_TIME && event.button === 0) {
            finishSketchSession();
            return;
          }
          if (event.button === 2) {
            onRightClickUp(uuid);
            return;
          }
          onFinishDrag(event, uuid);
          notifyDragFinished();
          upTime.current = event.timeStamp;
        }
      }
      lastButton.current = event.type;
    },
    { pointerEvents: true },
  );

  const onPointerOver = useCallback((e) => {
    if (isDisabled) {
      return;
    }
    e.stopPropagation();
    changeCursor('all-scroll');
    if (isPolygon) {
      setPointInProgress(null);
    }
  }, [isDisabled]);

  const onPointerOut = useCallback(() => {
    if (isDisabled) {
      return;
    }
    changeCursor('default');
  }, [isDisabled]);

  return (
    <Circle
      {...props}
      args={[1.5, 10]}
      ref={mesh}
      position={position}
      {...bindDrag()}
      onPointerOver={onPointerOver}
      onPointerOut={onPointerOut}
    >
      <meshStandardMaterial attach="material" color={color} />
    </Circle>
  );
};

EditablePoint.propTypes = {
  uuid: PropTypes.number,
  color: PropTypes.string,
  point: PropTypes.array,
  points: PropTypes.array,
  isClosed: PropTypes.bool,
  isPolygon: PropTypes.bool,
  isDisabled: PropTypes.bool,
  parkingEntrancePosition: PropTypes.object,
  finishSketchSession: PropTypes.func,
  updateHistory: PropTypes.func,
  planeClickedTime: PropTypes.number,
  halfTubeWidth: PropTypes.number,
};

export default React.memo(EditablePoint);
