import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import { Tooltip } from 'antd';
import T from 'i18n-react';
import lodashMaxBy from 'lodash/maxBy';
import lodashSortBy from 'lodash/sortBy';
import lodashKeys from 'lodash/keys';
import lodashFilter from 'lodash/filter';
import lodashIncludes from 'lodash/includes';
import { useHistory } from 'react-router';
import { StyledReportTable } from 'styles/commonComponents.styles';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { feasibilityReportSelector } from 'store/swappProfile/selectors';
import { UNIT_SORT_LIST } from 'constants/feasibilityConts';
import EnvironmentContainer from 'components/feasibility/studies/result/environment/EnvironmentContainer';
import {
  ContentColumnWrapper, ContentImage,
  ContentRowWrapper,
  ContentTitle, TableBoxWrapper,
  VerticalTitleContentWrapper,
} from '../ReportPages.styles';
import { ResultWrapper } from '../../../form/FeasibilityTargetFormContainer.Styles';
import ReportTitleDropdown from '../ReportTitleDropdown';

const Wellbeing = () => {
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const reportData = useSelector((state) => feasibilityReportSelector(state, locationData.profileId));
  const wellbeingData = lodashGet(reportData, 'wellbeingData');
  const [currentBuildingIndex, setCurrentBuildingIndex] = useState(0);
  const selectedBuilding = lodashGet(wellbeingData, `buildings[${currentBuildingIndex}]`);

  const apartmentDirectionData = lodashGet(selectedBuilding, 'apartmentDirectionData', []);
  const tableWithMostKeys = lodashMaxBy(apartmentDirectionData, (o) => Object.keys(o).length);
  const sortedTableKeys = lodashSortBy(lodashKeys(tableWithMostKeys), [(key) => UNIT_SORT_LIST[key]]);

  const renderValues = (values) => values && <Tooltip title={`${values.value}/${values.total}`}>{values.value}</Tooltip>;

  const updateCurrentBuildingIndex = (key, update) => {
    update(key);
  };

  const columns = [
    {
      title: T.translate('Unit'),
      dataIndex: 'NAME',
      key: 'NAME',
      width: 200,
    },
  ];

  lodashFilter(sortedTableKeys, ((unit) => !lodashIncludes(['TOTAL', 'NAME'], unit))).forEach((unit) => {
    columns.push(
      {
        title: T.translate(unit),
        dataIndex: unit,
        key: unit,
        align: 'center',
        width: 70,
        render: renderValues,
      },
    );
  });

  columns.push({
    title: T.translate('Total'),
    dataIndex: 'TOTAL',
    key: 'TOTAL',
    align: 'center',
    width: 70,
    render: renderValues,
  });

  if (lodashIsEmpty(wellbeingData)) {
    return null;
  }

  return (
    <VerticalTitleContentWrapper>
      <ContentTitle>{T.translate('Wellbeing')}</ContentTitle>
      <ContentRowWrapper>
        <ContentColumnWrapper marginRight={25} width={900}>

          {/* ======== dropdown ======== */}
          <ReportTitleDropdown
            options={wellbeingData.buildings.map((item) => item.buildingName)}
            handleChange={(key) => updateCurrentBuildingIndex(key, setCurrentBuildingIndex)}
            selectedIndex={currentBuildingIndex}
          />

          {/* ======== current building image ======== */}
          {lodashGet(wellbeingData, `buildingsImages[${currentBuildingIndex}]`) && <ContentImage src={wellbeingData.buildingsImages[currentBuildingIndex].url} width={160} height={160} marginBottom={10} />}

          {/* ======== Wellbeing Table ======== */}
          <TableBoxWrapper>
            <StyledReportTable
              pagination={false}
              columns={columns}
              dataSource={lodashGet(selectedBuilding, 'wellbeingData')}
            />
          </TableBoxWrapper>

          {/* ======== Apartment Direction Table ======== */}
          <TableBoxWrapper>
            <StyledReportTable
              pagination={false}
              showHeader={false}
              columns={columns}
              dataSource={lodashGet(selectedBuilding, 'apartmentDirectionData')}
            />
          </TableBoxWrapper>

          {/* ======== Enhance Apartment Table ======== */}
          <TableBoxWrapper>
            <StyledReportTable
              pagination={false}
              showHeader={false}
              columns={columns}
              dataSource={lodashGet(selectedBuilding, 'enhanceApartmentData')}
            />
          </TableBoxWrapper>
        </ContentColumnWrapper>

        {/* ======== viewer ======== */}
        <ContentColumnWrapper>
          <ResultWrapper padding={1} height={170}>
            <EnvironmentContainer />
          </ResultWrapper>
        </ContentColumnWrapper>
      </ContentRowWrapper>
    </VerticalTitleContentWrapper>
  );
};

export default React.memo(Wellbeing);
