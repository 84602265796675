/* eslint-disable no-restricted-globals */
import PropTypes from 'prop-types';
import React, { useRef, useCallback } from 'react';
import { useDrag } from 'react-use-gesture';
import * as THREE from 'three';
import { useThree } from 'react-three-fiber';
import { Circle } from '@react-three/drei';
import { vecAngle, vecSub } from 'utils/algorithms/algorithmHelpers';
import rotationIcon from 'styles/static/icons/feasibilityIcons/setup/rotateIcon.png';
import { notifyDragFinished } from '../helpers/ThreeHelpers';
import { changeCursor } from '../helpers/SelectionHelpers';
import { POINT_SIZE } from './constants';

const changeColor = (mesh, color) => {
  mesh.current.material.color = new THREE.Color(color);
  mesh.current.material.needsUpdate = true;
};

const RotationPoint = (props) => {
  const { onPointMoved, onPointerUp, position, center } = props;
  const mesh = useRef();
  const { camera } = useThree();

  const bindDrag = useDrag(
    ({ delta: [mx, my], event }) => {
      if (event) event.stopPropagation();
      if (mesh.current) {
        if (event.buttons === 1) {
          const globalQuaternion = new THREE.Quaternion();
          mesh.current.getWorldQuaternion(globalQuaternion);
          globalQuaternion.invert();
          const deltaVector = new THREE.Vector3(mx / camera.zoom, -my / camera.zoom, 0);
          deltaVector.applyQuaternion(globalQuaternion);
          mx = deltaVector.x;
          my = deltaVector.y;
          const newPoint = [position[0] + mx, position[1] + my];

          const prevAngle = vecAngle(vecSub(position, center));
          const nextAngle = vecAngle(vecSub(newPoint, center));
          changeColor(mesh, 0x0000ff);
          onPointMoved(event, nextAngle - prevAngle);
        }
        if (event.type === 'pointerup') {
          if (onPointerUp) {
            onPointerUp(event);
          }
          changeColor(mesh, 0xffffff);
          notifyDragFinished();
        }
      }
    },
    { pointerEvents: true },
  );

  const onPointerOver = useCallback((e) => {
    e.stopPropagation();
    changeCursor(`url(${rotationIcon}), auto`);
  }, []);

  const onPointerOut = useCallback(() => {
    changeCursor('default');
  }, []);

  return (
    <>
      <Circle
        {...props}
        args={[POINT_SIZE, 10]}
        position={position}
      >
        <meshBasicMaterial attach="material" color={0x000000} />
      </Circle>
      <Circle
        {...props}
        args={[POINT_SIZE * 0.8, 10]}
        ref={mesh}
        position={position}
        {...bindDrag()}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
      >
        <meshBasicMaterial attach="material" color={0xffffff} />
      </Circle>
    </>

  );
};

RotationPoint.propTypes = {
  pointIndex: PropTypes.number,
  onPointMoved: PropTypes.func,
  onPointerUp: PropTypes.func,
  position: PropTypes.array,
  center: PropTypes.array,
};

export default RotationPoint;
