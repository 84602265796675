import T from 'i18n-react';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashFilter from 'lodash/filter';
import React from 'react';
import { message, Modal } from 'antd';
import lodashIsArray from 'lodash/isArray';
import lodashUniqBy from 'lodash/uniqBy';
import { StyledAlert, SuccessIcon } from 'components/testFit/study/form/AreaTargetFormContainer.styles';
import { AREA_TARGET_FORM } from 'constants/fieldNames';
import { submitProfileAction } from 'store/swappProfile/actions/swappProfileActions';
import { uuidv4 } from './uuidv4';

const STATUS_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

export const FORM_INPUT_TYPE = {
  PERCENTAGE_TARGET: 'PERCENTAGE_TARGET',
  ROOM_COUNT: 'ROOM_COUNT',
};

export const showFormSubmittedModal = () => {
  Modal.info({
    title: T.translate('AREA_TARGET_FORM_CONTAINER_SUBMITTED_MESSAGE'),
    content: T.translate('AREA_TARGET_FORM_CONTAINER_SUBMITTED_DESCRIPTION'),
  });
};

const showModal = (dispatch, profileId, status, content) => {
  const statusInfo = {
    [STATUS_TYPES.SUCCESS]: {
      width: '500px',
      onOk() {
        dispatch(submitProfileAction(profileId))
          .catch((error) => message.error(error));
      },
      icon: <SuccessIcon />,
      title: T.translate('AREA_TARGET_FORM_CONTAINER_MODAL_TITLE'),
      okText: T.translate('AREA_TARGET_FORM_CONTAINER_MODAL_OK_TEXT'),
      content: T.translate('AREA_TARGET_FORM_CONTAINER_MODAL_CONTENT'),
      cancelText: T.translate('AREA_TARGET_FORM_CONTAINER_MODAL_CANCEL_TEXT'),
    },
    [STATUS_TYPES.WARNING]: {
      width: '500px',
      onOk() {
        dispatch(submitProfileAction(profileId))
          .catch((error) => message.error(error));
      },
      title: T.translate('AREA_TARGET_FORM_CONTAINER_MODAL_TITLE_WARNINGS'),
      okText: T.translate('AREA_TARGET_FORM_CONTAINER_MODAL_OK_TEXT'),
      cancelText: T.translate('AREA_TARGET_FORM_CONTAINER_MODAL_CANCEL_TEXT'),
      content,
    },
    [STATUS_TYPES.ERROR]: {
      width: '500px',
      title: T.translate('AREA_TARGET_FORM_CONTAINER_MODAL_TITLE_ERROR'),
      okText: T.translate('AREA_TARGET_FORM_CONTAINER_MODAL_CANCEL_TEXT'),
      content,
    },
  };

  switch (status) {
    case STATUS_TYPES.SUCCESS:
      Modal.confirm(statusInfo[status]);
      break;
    case STATUS_TYPES.WARNING:
      Modal.confirm(statusInfo[status]);
      break;
    case STATUS_TYPES.ERROR:
      Modal.error(statusInfo[status]);
      break;
    default:
      break;
  }
};

const setWarnings = (fieldsToValidate) => fieldsToValidate.map((item) => {
  if (lodashIsArray(item.field)) {
    let isFieldValid = false;
    item.field.forEach((row) => {
      if (row[item.valueToValidate]) {
        isFieldValid = true;
      }
    });
    return isFieldValid ? null : <StyledAlert key={uuidv4()}>{item.errorText}</StyledAlert>;
  }
  return item.field ? null : <StyledAlert key={uuidv4()}>{item.errorText}</StyledAlert>;
}).filter((item) => item !== null);

export const handleSubmit = (e, form, isLoadingProject, dispatch, profileId) => {
  e.preventDefault();
  if (isLoadingProject) {
    return;
  }

  form.validateFields()
    .then((values) => {
      const averageSfForEachOffice = values[AREA_TARGET_FORM.PSF_A_FIELD].map((office) => ((office.roomSize[0] + office.roomSize[1]) / 2) * office.numberOfRooms);
      const totalSF = averageSfForEachOffice.reduce((total, current) => (total + current));
      const totalSFPercentage = (totalSF * 100) / values[AREA_TARGET_FORM.TOTAL_AREA_FIELD];

      const fieldsToValidate = [
        { field: values[AREA_TARGET_FORM.PSF_A_FIELD], valueToValidate: 'ratio', errorText: T.translate('AREA_TARGET_FORM_CONTAINER_MODAL_WARNING_PSF') },
        { field: values[AREA_TARGET_FORM.ASF_FIELD], valueToValidate: 'ratio', errorText: T.translate('AREA_TARGET_FORM_CONTAINER_MODAL_WARNING_ASF') },
        { field: values[AREA_TARGET_FORM.FSF_FIELD], valueToValidate: 'totalSqFt', errorText: T.translate('AREA_TARGET_FORM_CONTAINER_MODAL_WARNING_FSF') },
        { field: values[AREA_TARGET_FORM.MALE_BATHROOMS], valueToValidate: '', errorText: T.translate('AREA_TARGET_FORM_CONTAINER_MODAL_WARNING_M_BATHROOMS') },
        { field: values[AREA_TARGET_FORM.FEMALE_BATHROOMS], valueToValidate: '', errorText: T.translate('AREA_TARGET_FORM_CONTAINER_MODAL_WARNING_F_BATHROOMS') },
        { field: totalSFPercentage < 20, valueToValidate: '', errorText: T.translate('AREA_TARGET_FORM_PSF_ERROR') },
      ];

      const content = setWarnings(fieldsToValidate);
      const status = lodashIsEmpty(content) ? STATUS_TYPES.SUCCESS : STATUS_TYPES.WARNING;
      showModal(dispatch, profileId, status, content);
    })
    .catch((errorInfo) => {
      const errorsToValidate = [AREA_TARGET_FORM.LSF_RSF_FIELD, AREA_TARGET_FORM.TOTAL_AREA_FIELD, AREA_TARGET_FORM.TOTAL_LSF_FIELD, AREA_TARGET_FORM.ASF_FIELD];
      const errorsArray = lodashFilter(errorInfo.errorFields, (field) => errorsToValidate.find((item) => field.name[0] === item));
      const uniqErrorsArray = lodashUniqBy(errorsArray, 'name[0]');

      if (!lodashIsEmpty(uniqErrorsArray)) {
        const content = uniqErrorsArray.map((error) => <StyledAlert key={uuidv4()}>{error.errors[0]}</StyledAlert>);
        showModal(dispatch, profileId, STATUS_TYPES.ERROR, content);
      } else {
        showModal(dispatch, profileId, STATUS_TYPES.SUCCESS);
      }
    });
};
