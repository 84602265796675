import React, { useState, useEffect, useMemo } from 'react';
import { Form } from 'antd';
import T from 'i18n-react';
import styled from 'styled-components';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { StyledExploreTable, StyledFormItem, StyledInputNumber } from 'styles/commonComponents.styles';
import { supportedCurrencies } from 'constants/currencies';
import { currentCurrencySelector, getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { BUILDING_INFO_KEYS } from 'constants/feasibilityConts';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { getParkingData } from 'utils/helpers/feasibilityFinancialHelper';
import { feasibilityResultModel, useActiveProfileBuildingInfo } from 'utils/model/feasibilityResultModel';
import { getProfileByIdSelector, loadingSelector } from 'store/swappProfile';
import { handleOnChange } from 'utils/helpers/feasibilityReportHelpers';
import { useProfileResult } from 'store/swappProfile/selectors/swappSelectors';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import CurrencyInput from 'components/common/CurrencyInput';
import EmptyMessage from 'components/common/EmptyMessage';
import { StyledText } from '../report/ReportPages.styles';

const inputHeight = 22;

const ParkingFinancialFormWrapper = styled.div`
  margin: 10px 0 30px 15px;
`;

const ParkingFinancialForm = () => {
  const locationData = parseLocationUrl(window.location);

  const currentSelectedObject = useSelector(currentCurrencySelector, shallowEqual);
  const profile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const isLoading = useSelector(loadingSelector);
  const [parkingData, setParkingData] = useState([]);
  const [form] = Form.useForm();
  const result = useProfileResult(profile);
  const buildingInfo = useActiveProfileBuildingInfo();
  const data = useMemo(() => feasibilityResultModel(result, isImperial, buildingInfo), [result, isImperial]);
  const formData = lodashGet(profile, 'profileData.feasibilityParkingForm');

  const dispatch = useDispatch();

  useEffect(() => {
    if (!lodashIsEmpty(data.formData)) {
      form.setFieldsValue({
        [BUILDING_INFO_KEYS.PARKING_FINANCIAL]: lodashGet(formData, `${BUILDING_INFO_KEYS.PARKING_FINANCIAL}`),
      });
      setParkingData(getParkingData(data.formData, profile, form));
    }
  }, [data.formData, lodashGet(profile, 'id')]);

  const handleParkingChange = (e, unitKey) => {
    const currentInput = form.getFieldValue(BUILDING_INFO_KEYS.PARKING_FINANCIAL) || {};
    form.setFieldsValue({
      [BUILDING_INFO_KEYS.PARKING_FINANCIAL]: {
        ...currentInput,
        [unitKey]: {
          ...lodashGet(currentInput, `[${unitKey}]`, {}),
          ...e,
        },
      },
    });

    setParkingData(getParkingData(data.formData, profile, form));
    handleChange();
  };

  const parkingColumns = [
    {
      dataIndex: 'colorPill',
      key: 'colorPill',
      width: 10,
    },
    {
      title: T.translate('FEASIBILITY_RESULT_DATA_PARKING_TABLE_TYPE'),
      dataIndex: 'NAME',
      key: 'NAME',
      align: 'left',
      width: 120,
      render: (value, rowData) => <StyledText size="small" bold={rowData.TYPE === 'TOTAL'}>{T.translate(value)}</StyledText>,
    },
    {
      title: T.translate('FEASIBILITY_RESULT_DATA_PARKING_TABLE_FLOORS'),
      dataIndex: 'FLOORS',
      key: 'FLOORS',
      align: 'center',
      width: 50,
      render: (value, rowData) => (rowData.key === BUILDING_INFO_KEYS.NUM_OF_BELOW_GROUND_PARKING
        ? (
          <StyledInputNumber
            height={inputHeight}
            width={70}
            min={1}
            value={value || 1}
            onChange={(e) => handleParkingChange({ floors: e }, rowData.key)}
          />
        )
        : value),
    },
    {
      title: `${supportedCurrencies[currentSelectedObject.currentCurrencyKey].symbol} / ${T.translate('FEASIBILITY_RESULT_DATA_PARKING_TABLE_STALL')}`,
      dataIndex: 'AMOUNT_TO_STALL',
      key: 'AMOUNT_TO_STALL',
      align: 'center',
      width: 120,
      render: (amount, rowData) => (rowData.TYPE === 'TOTAL' ? '' : <CurrencyInput width={100} height={inputHeight} value={amount} onChange={(e) => handleParkingChange({ coast: e }, rowData.key)} />),
    },
    {
      title: T.translate('FEASIBILITY_RESULT_DATA_PARKING_TABLE_STALLS'),
      dataIndex: 'STALLS',
      key: 'STALLS',
      align: 'center',
      width: 30,
      render: (value, rowData) => <StyledText size="small" bold={rowData.TYPE === 'TOTAL'}>{numberWithComma(value, { fixed: 1 })}</StyledText>,
    },
    {
      title: T.translate('FEASIBILITY_RESULT_DATA_PARKING_TABLE_TOTAL_COST'),
      dataIndex: 'TOTAL_COST',
      key: 'TOTAL_COST',
      align: 'right',
      width: 70,
      render: (amount, rowData) => amount && <StyledText size="small" bold={rowData.TYPE === 'TOTAL'}><CurrencyInput value={amount} readOnly /></StyledText>,
    },
    {
      dataIndex: 'margin',
      key: 'margin',
      width: 10,
    },
  ];

  const handleChange = () => {
    handleOnChange(dispatch, { profile, formInstance: form, isLoading, settingsType: 'feasibilityParking' });
  };

  if (!lodashGet(data, 'formData.totalParking')) {
    return (
      <div style={{ minHeight: '120px', width: '100%' }}>
        <EmptyMessage description="FEASIBILITY_RESULT_DATA_PARKING_EMPTY" />
      </div>
    );
  }

  return (
    <ParkingFinancialFormWrapper>
      <Form name="parkingFinancialForm" form={form} onFieldsChange={handleChange}>
        {lodashGet(data, 'formData.totalParking') && (
          <StyledExploreTable
            pagination={false}
            columns={parkingColumns}
            dataSource={parkingData}
          />
        )}
        <StyledFormItem hidden name={BUILDING_INFO_KEYS.PARKING_FINANCIAL} initialValue={{}} />
      </Form>
    </ParkingFinancialFormWrapper>
  );
};

export default React.memo(ParkingFinancialForm);
