import React from 'react';
import T from 'i18n-react';
import { Radio, Space } from 'antd';
import { FEASIBILITY_TARGET_FORM } from 'constants/fieldNames';
import { StyledFormItem, StyledRadio } from 'styles/commonComponents.styles';
import { SwitchHeaderTitle } from './FeasibilityTargetFormContainer.Styles';

const IL_HVAC_TYPES = {
  HVAC_CENTRAL: 'HVAC_CENTRAL',
  HVAC_VRF: 'HVAC_VRF',
};

const FeasibilityMechanicalElectricPlumbingForm = () => (
  <>
    <SwitchHeaderTitle>
      {T.translate('FEASIBILITY_MECHANICAL_ELECTRIC_PLUMBING_FORM_HVAC')}
    </SwitchHeaderTitle>
    {/* ============HVAC_VRF============ */}
    <StyledFormItem name={FEASIBILITY_TARGET_FORM.IL_HVAC} initialValue={IL_HVAC_TYPES.HVAC_CENTRAL}>
      <Radio.Group>
        <Space direction="vertical">
          <StyledRadio value={IL_HVAC_TYPES.HVAC_CENTRAL}>{T.translate('FEASIBILITY_MECHANICAL_ELECTRIC_PLUMBING_FORM_CENTRAL')}</StyledRadio>
          <StyledRadio value={IL_HVAC_TYPES.HVAC_VRF}>{T.translate('FEASIBILITY_MECHANICAL_ELECTRIC_PLUMBING_FORM_VRF')}</StyledRadio>
        </Space>
      </Radio.Group>
    </StyledFormItem>
  </>
);

export default React.memo(FeasibilityMechanicalElectricPlumbingForm);
