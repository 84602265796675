import rsfIcon from './rsfIcon';
import lsfIcon from './lsfIcon';
import psfIcon from './psfIcon';
import asfIcon from './asfIcon';
import fsfIcon from './fsfIcon';
import restroomIcon from './restroomIcon';
import circulationIcon from './circulationIcon';
import positioningIcon from './positioningIcon';
import departmentsIcon from './departmentsIcon';
import commentsIcon from './commentsIcon';
import flexibilityIcon from './flexibilityIcon';
import noiseIcon from './noiseIcon';
import covidIcon from './covidIcon';
import costAreaIcon from './costAreaIcon';
import costItemIcon from './costItemIcon';
import layoutInfoIcon from './layoutInfoIcon';
import officeTypeIcon from './officeTypeIcon';
import roomComfortIcon from './roomComfortIcon';
import renderIcon from './renderIcon';
import wtPointIcon from './wtPointIcon';
import parkingEntrance from './parkingEntrance';

export default {
  rsfIcon,
  lsfIcon,
  psfIcon,
  asfIcon,
  fsfIcon,
  restroomIcon,
  circulationIcon,
  positioningIcon,
  departmentsIcon,
  commentsIcon,
  flexibilityIcon,
  noiseIcon,
  covidIcon,
  costAreaIcon,
  costItemIcon,
  layoutInfoIcon,
  officeTypeIcon,
  roomComfortIcon,
  renderIcon,
  wtPointIcon,
  parkingEntrance,
};
