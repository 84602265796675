import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { useRouteMatch } from 'react-router';
import { shallowEqual, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import lodashGet from 'lodash/get';
import lodashSum from 'lodash/sum';
import lodashValues from 'lodash/values';
import lodashIsEmpty from 'lodash/isEmpty';

import { StyledFormItem, StyledInputNumber, StyledReportTable, SmallStyledTable } from 'styles/commonComponents.styles';
import {
  DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE,
  DEVELOPMENT_APPRAISAL_FORM,
  DEVELOPMENT_APPRAISAL_INCOME_TABLE,
} from 'constants/feasibilityReportConts';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { currentCurrencySelector, getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { sqmToSqf } from 'utils/helpers/unitsHelpers';
import { supportedCurrencies } from 'constants/currencies';
import CurrencyInput from '../../../../common/CurrencyInput';
import {
  TableBoxWrapper,
  ContentTitle,
  ReportMainColumnDataTextWrapper,
  StyledText,
} from '../report/ReportPages.styles';

const DevelopmentAppraisalIncomeTable = (props) => {
  const { data, formData, formInstance, disabled } = props;

  const baseDataTable = lodashGet(formData, `[${DEVELOPMENT_APPRAISAL_FORM.BASE_DATA_TABLE}]`);
  const affordableReduction = lodashGet(formData, `[${DEVELOPMENT_APPRAISAL_FORM.AFFORDABLE_REDUCTION}]`);
  const salesUnits = lodashGet(formData, `[${DEVELOPMENT_APPRAISAL_FORM.SALES_UNITS}]`);
  const affordableUnits = lodashGet(formData, `[${DEVELOPMENT_APPRAISAL_FORM.AFFORDABLE_UNITS}]`);
  const modelData = lodashGet(data, 'baseDataTable', []);
  const match = useRouteMatch();
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, match.params.projectId));
  const currentSelectedObject = useSelector(currentCurrencySelector, shallowEqual);

  const [salesUnitsDataSource, setSalesUnitsDataSource] = useState([]);
  const [affordableUnitsDataSource, setAffordableUnitsDataSource] = useState([]);
  const [totalSalesRevenue, setTotalSalesRevenue] = useState([]);
  const [otherRevenue, setOtherRevenue] = useState([]);

  // on load
  useEffect(() => {
    updateDataSource(setSalesUnitsDataSource);
    updateAffordableDataSource(setAffordableUnitsDataSource);
    updateTotalSalesRevenue(setTotalSalesRevenue);
    updateOtherRevenue(setOtherRevenue);
  }, []);

  useEffect(() => {
    updateDataSource(setSalesUnitsDataSource);
  }, [formInstance, baseDataTable, affordableUnits, formData]);

  useEffect(() => {
    updateAffordableDataSource(setAffordableUnitsDataSource);
  }, [salesUnitsDataSource, affordableReduction]);

  useEffect(() => {
    updateTotalSalesRevenue(setTotalSalesRevenue);
  }, [affordableUnitsDataSource]);

  useEffect(() => {
    updateOtherRevenue(setOtherRevenue);
  }, [totalSalesRevenue]);

  const getSum = (objectData) => lodashSum(lodashValues(objectData).filter((e) => e));
  const getAverage = (objectData) => getSum(objectData) / lodashValues(objectData).filter((e) => e || e === 0).length;
  const populateCells = (numOfUnits, totalNia, unitValue, totalValue, valueAreaRatio) => modelData.map((unit) => {
    if (unit[DEVELOPMENT_APPRAISAL_BASE_DATA_TABLE.KEY] === 'TOTAL') {
      return {
        ...unit,
        [DEVELOPMENT_APPRAISAL_INCOME_TABLE.NUM_OF_UNITS]: getSum(numOfUnits),
        [DEVELOPMENT_APPRAISAL_INCOME_TABLE.TOTAL_NIA]: getSum(totalNia),
        [DEVELOPMENT_APPRAISAL_INCOME_TABLE.UNIT_VALUE]: getAverage(unitValue),
        [DEVELOPMENT_APPRAISAL_INCOME_TABLE.VALUE_AREA_RATIO]: getAverage(valueAreaRatio),
        [DEVELOPMENT_APPRAISAL_INCOME_TABLE.TOTAL_VALUE]: getSum(totalValue),
      };
    }

    return {
      ...unit,
      [DEVELOPMENT_APPRAISAL_INCOME_TABLE.NUM_OF_UNITS]: numOfUnits[unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY]],
      [DEVELOPMENT_APPRAISAL_INCOME_TABLE.TOTAL_NIA]: totalNia[unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY]],
      [DEVELOPMENT_APPRAISAL_INCOME_TABLE.UNIT_VALUE]: unitValue[unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY]],
      [DEVELOPMENT_APPRAISAL_INCOME_TABLE.VALUE_AREA_RATIO]: valueAreaRatio[unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY]],
      [DEVELOPMENT_APPRAISAL_INCOME_TABLE.TOTAL_VALUE]: totalValue[unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY]],
    };
  });

  const margeSalesUnitsDataToTable = () => {
    if (!formInstance) {
      return [];
    }

    const numOfUnits = {};
    const totalNia = {};
    const unitValue = {};
    const totalValue = {};
    const valueAreaRatio = {};

    // eslint-disable-next-line no-return-assign
    modelData.forEach((unit) => {
      const numOfUnitsValue = unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY] === 'TOTAL' ? 0 : Math.ceil(unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.NUM_OF_UNITS] * (salesUnits / 100));
      const totalNiaValue = numOfUnitsValue * unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.APARTMENT_AREA];
      const unitValueValue = lodashGet(baseDataTable, `[${unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY]}]`, 0) * unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.APARTMENT_AREA];
      const totalValueValue = unitValueValue * numOfUnitsValue;
      const valueAreaRatioValue = totalValueValue / totalNiaValue;

      numOfUnits[unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY]] = numOfUnitsValue;
      totalNia[unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY]] = totalNiaValue;
      unitValue[unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY]] = unitValueValue;
      totalValue[unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY]] = totalValueValue;
      valueAreaRatio[unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY]] = valueAreaRatioValue;
    });

    return populateCells(numOfUnits, totalNia, unitValue, totalValue, valueAreaRatio);
  };

  const margeAffordableUnitsDataToTable = () => {
    if (!formInstance || lodashIsEmpty(salesUnitsDataSource)) {
      return [];
    }

    const numOfUnits = {};
    const totalNia = {};
    const unitValue = {};
    const totalValue = {};
    const valueAreaRatio = {};

    // eslint-disable-next-line no-return-assign
    modelData.forEach((unit) => {
      const numOfUnitsValue = unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY] === 'TOTAL' ? 0 : Math.floor(unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.NUM_OF_UNITS] * (affordableUnits / 100));
      const totalNiaValue = numOfUnitsValue * unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.APARTMENT_AREA];
      const salesUnit = salesUnitsDataSource.find((currentUnit) => currentUnit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY] === unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY]);
      const unitValueValue = lodashGet(salesUnit, `[${DEVELOPMENT_APPRAISAL_INCOME_TABLE.UNIT_VALUE}]`) * (affordableReduction / 100);
      const totalValueValue = affordableUnits ? (unitValueValue * numOfUnitsValue) : 0;
      const valueAreaRatioValue = affordableUnits ? (totalValueValue / totalNiaValue) : 0;

      numOfUnits[unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY]] = numOfUnitsValue;
      totalNia[unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY]] = totalNiaValue;
      unitValue[unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY]] = unitValueValue;
      totalValue[unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY]] = totalValueValue;
      valueAreaRatio[unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY]] = valueAreaRatioValue;
    });

    return populateCells(numOfUnits, totalNia, unitValue, totalValue, valueAreaRatio);
  };

  const updateTotalSalesRevenue = (update) => {
    const salesUnitsTotal = salesUnitsDataSource.find((unit) => unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY] === 'TOTAL');
    const affordableUnitsTotal = affordableUnitsDataSource.find((unit) => unit[DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY] === 'TOTAL');
    if (lodashIsEmpty(salesUnitsTotal) || lodashIsEmpty(affordableUnitsTotal)) {
      return;
    }

    const totalNumOfUnits = salesUnitsTotal[DEVELOPMENT_APPRAISAL_INCOME_TABLE.NUM_OF_UNITS] + affordableUnitsTotal[DEVELOPMENT_APPRAISAL_INCOME_TABLE.NUM_OF_UNITS];
    const totalNia = salesUnitsTotal[DEVELOPMENT_APPRAISAL_INCOME_TABLE.TOTAL_NIA] + affordableUnitsTotal[DEVELOPMENT_APPRAISAL_INCOME_TABLE.TOTAL_NIA];
    const totalValue = salesUnitsTotal[DEVELOPMENT_APPRAISAL_INCOME_TABLE.TOTAL_VALUE] + affordableUnitsTotal[DEVELOPMENT_APPRAISAL_INCOME_TABLE.TOTAL_VALUE];
    const valueAreaRatio = totalValue / totalNia;

    const totalTable = {
      [DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY]: T.translate('Total Sales Revenue'),
      [DEVELOPMENT_APPRAISAL_INCOME_TABLE.NUM_OF_UNITS]: totalNumOfUnits,
      [DEVELOPMENT_APPRAISAL_INCOME_TABLE.APARTMENT_AREA]: '',
      [DEVELOPMENT_APPRAISAL_INCOME_TABLE.TOTAL_NIA]: totalNia,
      [DEVELOPMENT_APPRAISAL_INCOME_TABLE.UNIT_VALUE]: '',
      [DEVELOPMENT_APPRAISAL_INCOME_TABLE.TOTAL_VALUE]: totalValue,
      [DEVELOPMENT_APPRAISAL_INCOME_TABLE.VALUE_AREA_RATIO]: valueAreaRatio,
    };

    update([totalTable]);
  };

  const updateOtherRevenue = (update) => {
    if (!formInstance || lodashIsEmpty(totalSalesRevenue)) {
      return [];
    }

    const totalValue = totalSalesRevenue[0][DEVELOPMENT_APPRAISAL_INCOME_TABLE.TOTAL_VALUE];
    const salesUnitsInflationAssessment = totalValue * (formData[DEVELOPMENT_APPRAISAL_FORM.INFLATION_ASSESSMENT] / 100);
    const totalOtherIncome = salesUnitsInflationAssessment + formData[DEVELOPMENT_APPRAISAL_FORM.GRANT_MONIES] + formData[DEVELOPMENT_APPRAISAL_FORM.OTHER];
    const totalRevenue = totalValue + totalOtherIncome;

    formInstance.setFieldsValue({ [DEVELOPMENT_APPRAISAL_FORM.TOTAL_REVENUE]: totalRevenue });
    update({ salesUnitsInflationAssessment, totalOtherIncome, totalRevenue });
  };

  const updateDataSource = (update) => {
    update(margeSalesUnitsDataToTable());
  };

  const updateAffordableDataSource = (update) => {
    update(margeAffordableUnitsDataToTable());
  };

  const columns = [
    {
      title: T.translate('Apt. Type'),
      dataIndex: DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY,
      key: DEVELOPMENT_APPRAISAL_INCOME_TABLE.KEY,
      width: 120,
      render: (value) => (value === 'TOTAL' ? T.translate('Total / Average') : T.translate(value)),
    },
    {
      title: T.translate('No. of Units'),
      dataIndex: DEVELOPMENT_APPRAISAL_INCOME_TABLE.NUM_OF_UNITS,
      key: DEVELOPMENT_APPRAISAL_INCOME_TABLE.NUM_OF_UNITS,
      align: 'center',
      width: 70,
    },
    {
      title: `${T.translate('Apt. NIA ')} (${T.translate(isImperial ? 'SQF' : 'SQM')})`,
      dataIndex: DEVELOPMENT_APPRAISAL_INCOME_TABLE.APARTMENT_AREA,
      key: DEVELOPMENT_APPRAISAL_INCOME_TABLE.APARTMENT_AREA,
      align: 'center',
      width: 70,
      render: (value) => value && numberWithComma(isImperial ? sqmToSqf(value) : value, { fixed: isImperial ? 0 : 1 }),
    },
    {
      title: `${T.translate('Total. NIA ')} (${T.translate(isImperial ? 'SQF' : 'SQM')})`,
      dataIndex: DEVELOPMENT_APPRAISAL_INCOME_TABLE.TOTAL_NIA,
      key: DEVELOPMENT_APPRAISAL_INCOME_TABLE.TOTAL_NIA,
      align: 'center',
      width: 70,
      render: (value) => numberWithComma(isImperial ? sqmToSqf(value) : value, { fixed: isImperial ? 0 : 1 }),
    },
    {
      title: T.translate('Unit Value'),
      dataIndex: DEVELOPMENT_APPRAISAL_INCOME_TABLE.UNIT_VALUE,
      key: DEVELOPMENT_APPRAISAL_INCOME_TABLE.UNIT_VALUE,
      align: 'center',
      width: 70,
      render: (value, rowData) => value && (
        <Tooltip title={rowData.key === 'TOTAL' ? T.translate('Average') : ''}>
          <CurrencyInput value={value} readOnly />
        </Tooltip>
      ),
    },
    {
      title: `${supportedCurrencies[currentSelectedObject.currentCurrencyKey].symbol} / ${T.translate(isImperial ? 'SQF' : 'SQM')}`,
      dataIndex: DEVELOPMENT_APPRAISAL_INCOME_TABLE.VALUE_AREA_RATIO,
      key: DEVELOPMENT_APPRAISAL_INCOME_TABLE.VALUE_AREA_RATIO,
      align: 'center',
      width: 70,
      render: (value, rowData) => (
        <Tooltip title={rowData.key === 'TOTAL' ? T.translate('Average') : ''}>
          <CurrencyInput value={value} readOnly unitSystemKey={CurrencyInput.unitSystemKey.sqmToSqf} />
        </Tooltip>
      ),
    },
    {
      title: T.translate('Total Value'),
      dataIndex: DEVELOPMENT_APPRAISAL_INCOME_TABLE.TOTAL_VALUE,
      key: DEVELOPMENT_APPRAISAL_INCOME_TABLE.TOTAL_VALUE,
      align: 'center',
      width: 70,
      render: (value, rowData) => (
        <Tooltip title={rowData.key === 'TOTAL' ? T.translate('Average') : ''}>
          <CurrencyInput value={value} readOnly />
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <TableBoxWrapper margin="10px 5px">
        <ContentTitle>{T.translate('Sales Units')}</ContentTitle>
        <StyledReportTable
          pagination={false}
          columns={columns}
          dataSource={salesUnitsDataSource.filter((unit) => unit.key !== 'TOTAL')}
        />
        <SmallStyledTable
          pagination={false}
          columns={columns}
          showHeader={false}
          total
          dataSource={salesUnitsDataSource.filter((unit) => unit.key === 'TOTAL')}
        />
      </TableBoxWrapper>

      <TableBoxWrapper margin="10px 5px">
        <ContentTitle>{T.translate('Affordable Units')}</ContentTitle>
        <StyledReportTable
          pagination={false}
          columns={columns}
          dataSource={affordableUnitsDataSource.filter((unit) => unit.key !== 'TOTAL')}
        />
        <SmallStyledTable
          pagination={false}
          columns={columns}
          showHeader={false}
          total
          dataSource={affordableUnitsDataSource.filter((unit) => unit.key === 'TOTAL')}
        />
        <SmallStyledTable
          marginTop={20}
          pagination={false}
          columns={columns}
          showHeader={false}
          total
          dataSource={totalSalesRevenue}
        />
      </TableBoxWrapper>

      <TableBoxWrapper margin="10px 5px">
        <ContentTitle>{T.translate('Other Revenue')}</ContentTitle>

        <ReportMainColumnDataTextWrapper underLine>
          <StyledText>{T.translate('Grant Monies')}</StyledText>
          <StyledFormItem name={DEVELOPMENT_APPRAISAL_FORM.GRANT_MONIES} initialValue={0} width={90}>
            <CurrencyInput
              min={0}
              height={22}
              readOnly={disabled}
            />
          </StyledFormItem>
        </ReportMainColumnDataTextWrapper>

        <ReportMainColumnDataTextWrapper underLine>
          <StyledText>{T.translate('Other')}</StyledText>
          <StyledFormItem name={DEVELOPMENT_APPRAISAL_FORM.OTHER} initialValue={0} width={90}>
            <CurrencyInput
              min={0}
              height={22}
              readOnly={disabled}
            />
          </StyledFormItem>
        </ReportMainColumnDataTextWrapper>

        <ReportMainColumnDataTextWrapper underLine marginBottom={10}>
          <StyledText>{T.translate('Sales Units Inflation Assessment')}</StyledText>
          <div>
            <StyledFormItem name={DEVELOPMENT_APPRAISAL_FORM.INFLATION_ASSESSMENT} initialValue={1} width={80}>
              <StyledInputNumber
                min={0}
                max={100}
                disabled={disabled}
                formatter={(value) => numberWithComma(value, { suffix: '%', fixed: 1 })}
                width={65}
                height={22}
              />
            </StyledFormItem>
          </div>
          <CurrencyInput value={otherRevenue.salesUnitsInflationAssessment} readOnly />
        </ReportMainColumnDataTextWrapper>

        <ReportMainColumnDataTextWrapper bold marginBottom={10}>
          <StyledText bold total>{T.translate('Total Other Income')}</StyledText>
          <CurrencyInput value={otherRevenue.totalOtherIncome} readOnly />
        </ReportMainColumnDataTextWrapper>

        <ReportMainColumnDataTextWrapper bold>
          <StyledText bold total>{T.translate('Total Revenue')}</StyledText>
          <StyledFormItem hidden noStyle name={DEVELOPMENT_APPRAISAL_FORM.TOTAL_REVENUE} initialValue={0} />
          <CurrencyInput value={otherRevenue.totalRevenue} readOnly />
        </ReportMainColumnDataTextWrapper>
      </TableBoxWrapper>
    </>
  );
};

DevelopmentAppraisalIncomeTable.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  formInstance: PropTypes.object,
  disabled: PropTypes.bool,
};

export default DevelopmentAppraisalIncomeTable;
