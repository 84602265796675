import React from 'react';

// currentcolor
export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="5" cy="16" r="1.625" stroke="currentcolor" strokeWidth="0.75" />
    <circle cx="12" cy="9" r="1.625" stroke="currentcolor" strokeWidth="0.75" />
    <circle cx="19" cy="16" r="1.625" stroke="currentcolor" strokeWidth="0.75" />
    <path d="M5 14V3.5" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5 18V21.5" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19 14V3.5" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19 18V21.5" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 11L12 21.5" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 7V3.5" stroke="currentcolor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
