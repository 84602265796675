import React from 'react';

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 12.5V18.5M10 18.5L6.5 15.4099M10 18.5L13.5 15.4099" stroke="currentcolor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19.0999 5.90178C19.2656 5.90178 19.3999 6.0361 19.3999 6.20178V8.54317C19.3003 8.66829 19.1465 8.8041 18.9385 8.95059C18.7305 9.09403 18.4624 9.21763 18.1343 9.32139C17.8062 9.42516 17.4092 9.47704 16.9434 9.47704C16.5303 9.47704 16.1538 9.40685 15.814 9.26646C15.4741 9.12302 15.1812 8.91397 14.9351 8.6393C14.6919 8.36463 14.5044 8.02893 14.3726 7.63219C14.2407 7.23239 14.1748 6.77614 14.1748 6.26343V5.84685C14.1748 5.33414 14.2378 4.87788 14.3638 4.47809C14.4927 4.0783 14.6758 3.74107 14.9131 3.4664C15.1504 3.19173 15.4331 2.98268 15.7612 2.83924C16.0894 2.6958 16.4541 2.62408 16.8555 2.62408C17.4121 2.62408 17.8691 2.71869 18.2266 2.90791C18.584 3.09407 18.8564 3.35348 19.0439 3.68613C19.184 3.92863 19.2845 4.19755 19.3453 4.49291C19.3812 4.66708 19.2409 4.82142 19.0631 4.82142H18.3769C18.2276 4.82142 18.104 4.71065 18.0646 4.56662C18.0311 4.44414 17.987 4.33223 17.9321 4.23089C17.8413 4.06304 17.7109 3.93181 17.541 3.8372C17.374 3.74259 17.1572 3.69529 16.8906 3.69529C16.6709 3.69529 16.4746 3.74259 16.3018 3.8372C16.1318 3.92875 15.9883 4.06456 15.8711 4.24462C15.7539 4.42468 15.6646 4.64747 15.603 4.91298C15.5415 5.17849 15.5107 5.48673 15.5107 5.83769V6.26343C15.5107 6.61134 15.543 6.91958 15.6074 7.18814C15.6719 7.45365 15.7671 7.67797 15.8931 7.86108C16.022 8.04114 16.1802 8.17694 16.3677 8.2685C16.5552 8.36006 16.7734 8.40583 17.0225 8.40583C17.2305 8.40583 17.4033 8.38752 17.541 8.3509C17.6816 8.31428 17.7944 8.27003 17.8794 8.21815C17.9673 8.16321 18.0347 8.11133 18.0815 8.0625V6.88143H17.1774C17.0118 6.88143 16.8774 6.74712 16.8774 6.58143V6.20178C16.8774 6.0361 17.0118 5.90178 17.1774 5.90178H19.0999Z" fill="currentcolor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2.57764 2.72022C3.00537 2.72022 3.39648 2.79651 3.75098 2.94911C4.1084 3.09865 4.41748 3.3138 4.67822 3.59458C4.93896 3.87535 5.13965 4.21105 5.28027 4.60169C5.42383 4.98928 5.49561 5.42111 5.49561 5.89721V6.21307C5.49561 6.68611 5.42383 7.11795 5.28027 7.50859C5.13965 7.89923 4.93896 8.23493 4.67822 8.5157C4.42041 8.79342 4.11133 9.00858 3.75098 9.16117C3.39355 9.31071 2.99805 9.38548 2.56445 9.38548H1.91846H0.900098C0.734412 9.38548 0.600098 9.25117 0.600098 9.08548V3.02022C0.600098 2.85453 0.734412 2.72022 0.900098 2.72022H1.91846H2.57764ZM3.43896 8.07166C3.20166 8.2334 2.91016 8.31428 2.56445 8.31428H2.21846C2.05277 8.31428 1.91846 8.17996 1.91846 8.01428V4.096C1.91846 3.93031 2.05277 3.796 2.21846 3.796H2.57764C2.83252 3.796 3.05811 3.84178 3.25439 3.93333C3.45068 4.02184 3.61621 4.15459 3.75098 4.3316C3.88574 4.50861 3.98682 4.72682 4.0542 4.98622C4.12451 5.24563 4.15967 5.54624 4.15967 5.88805V6.21307C4.15967 6.65864 4.09814 7.0386 3.9751 7.35294C3.85498 7.66728 3.67627 7.90686 3.43896 8.07166Z" fill="currentcolor" />
    <path d="M7.69841 9.38548C7.5568 9.38548 7.43448 9.28647 7.40499 9.14797L6.11333 3.0827C6.07358 2.89605 6.21591 2.72022 6.40675 2.72022H7.09247C7.23677 2.72022 7.36063 2.82295 7.3873 2.96477L8.21491 7.36516L9.21744 2.95374C9.24848 2.81715 9.36991 2.72022 9.50998 2.72022H10.143C10.283 2.72022 10.4044 2.81706 10.4355 2.95356L11.4409 7.36524L12.2614 2.96522C12.2879 2.82319 12.4119 2.72022 12.5564 2.72022H13.2419C13.4327 2.72022 13.575 2.89592 13.5354 3.08252L12.2477 9.14778C12.2183 9.28637 12.0959 9.38548 11.9543 9.38548H11.1026C10.9637 9.38548 10.843 9.29013 10.8108 9.15502L9.82348 5.01123L8.8418 9.15464C8.80975 9.28994 8.68892 9.38548 8.54988 9.38548H7.69841Z" fill="currentcolor" />
  </svg>
);
