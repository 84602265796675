import React from 'react';

export default () => (
  <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.11188 7.68421C8.11188 5.64948 6.64149 4 4.82767 4C3.01385 4 1.54346 5.64948 1.54346 7.68421" stroke="currentcolor" strokeWidth="1.5" />
    <path d="M8.11188 41.3684C8.11188 39.3337 6.64149 37.6842 4.82767 37.6842C3.01385 37.6842 1.54346 39.3337 1.54346 41.3684" stroke="currentcolor" strokeWidth="1.5" />
    <path d="M7.10135 46.6316C4.03181 46.6316 1.54346 44.2752 1.54346 41.3684" stroke="currentcolor" strokeWidth="1.5" />
    <path d="M1.54346 7.42108V41.1053" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.11194 7.42108V41.1053" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M42.975 7.68421C42.975 5.64948 44.4454 4 46.2592 4C48.0731 4 49.5435 5.64948 49.5435 7.68421" stroke="currentcolor" strokeWidth="1.5" />
    <path d="M42.975 41.3684C42.975 39.3337 44.4454 37.6842 46.2592 37.6842C48.0731 37.6842 49.5435 39.3337 49.5435 41.3684" stroke="currentcolor" strokeWidth="1.5" />
    <path d="M43.9856 46.6316C47.0551 46.6316 49.5435 44.2752 49.5435 41.3684" stroke="currentcolor" strokeWidth="1.5" />
    <path d="M49.5435 7.42108V41.1053" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M42.975 7.42108V41.1053" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.84888 46.6316H44.2384" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.11194 7.1579H42.9751" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.1751 43.2105H24.533M36.9119 43.2105H24.533M24.533 43.2105V34.5263M34.8909 33.4737H42.7225M30.5961 7.1579V16.1053M30.5961 29.5263V24.7895M18.9751 24.7895H30.5961M30.5961 24.7895V16.1053M42.7225 16.1053H30.5961M23.5225 7.1579V12.1579M23.5225 17.1579V12.1579M23.5225 12.1579H15.9435M15.9435 17.1579V21.6316M15.9435 36.6316V21.6316M15.9435 21.6316H8.11194" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
