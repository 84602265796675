import styled from 'styled-components';

export const MarkupsContainerWrapper = styled.div`
  position: absolute;
  top: 60px;
  right: ${({ width }) => `${width + 20}px`};
  width: 60px;
  display: flex;
  flex-direction: column;
  z-index: 50;
`;

export const MarkupsSection = styled.div`
  background-color: ${({ theme }) => theme.colors.gray_01};
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.other.borderRadius};
  padding: 5px;
  margin-bottom: 20px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.12);
`;
