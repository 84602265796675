import * as THREE from 'three';

const handleSelectionEvent = (e, isSelectable, color, invalidate, unitCallback) => {
  // https://github.com/pmndrs/react-three-fiber/blob/master/markdown/api.md#events
  // In react-three-fiber, the closest object to the camera is guaranteed to be the first event handler
  // stopPropagation causes further events to not count as "below the pointer". This includes correctly
  // sending onPointerOut when an object stops being the closest one. Neat!
  e.stopPropagation();
  // We need to handle an event with an early return (rather than not handle an event) so that we still capture the mouse and prevent
  // objects behind us from getting clicked.
  if (!isSelectable) {
    return;
  }
  if (e.eventObject.material === undefined || e.eventObject.material === null) {
    // This is a bug that happens in firefox (but not on chrome) that a destroyed object fires onPointerOut one last time
    return;
  }
  if (color !== null) {
    e.eventObject.material.color = new THREE.Color(color);
    e.eventObject.material.needsUpdate = true;
    invalidate();
  }

  if (unitCallback) {
    unitCallback(e.eventObject.userData);
  }
};

// Create props that will make a ThreeJS object (with a material) selectable
// objectProps: an object that defines the properties of the object. Must contain color.
// invalidate: a function pointer for ThreeJS invalidate, can be obtained from useThree
// onSelected: a callback that will be called with objectProps when the object is selected
export const selectionProps = (objectProps, isSelectable, hoverColor, invalidate, onSelected) => ({
  onClick: (e) => handleSelectionEvent(e, isSelectable, null, invalidate, onSelected),
  onPointerOver: (e) => handleSelectionEvent(e, isSelectable, hoverColor, invalidate),
  onPointerOut: (e) => handleSelectionEvent(e, isSelectable, e.eventObject.userData.color, invalidate),
  userData: objectProps,
});

export const unselectableSelectionProps = {
  onClick: (e) => handleSelectionEvent(e, false),
  onPointerOver: (e) => handleSelectionEvent(e, false),
  onPointerOut: (e) => handleSelectionEvent(e, false),
};

export const changeCursor = (cursor) => {
  document.body.style.cursor = cursor;
};

export const changeToResizeCursorByAngle = (rad) => {
  const angle = rad * (180 / Math.PI);
  let cursor = '';
  if (angle >= -22.5 && angle < 22.5) {
    cursor = 'w-resize';
  } else if (angle >= 22.5 && angle < 67.5) {
    cursor = 'sw-resize';
  } else if (angle >= 67.5 && angle < 112.5) {
    cursor = 'n-resize';
  } else if (angle >= 112.5 && angle < 157.5) {
    cursor = 'nw-resize';
  } else if (angle >= 157.5 || angle < -157.5) {
    cursor = 'w-resize';
  } else if (angle >= -157.5 && angle < -112.5) {
    cursor = 'sw-resize';
  } else if (angle >= -112.5 && angle < -67.5) {
    cursor = 'n-resize';
  } else if (angle >= -67.5 && angle < 22.5) {
    cursor = 'nw-resize';
  }

  changeCursor(cursor);
};
