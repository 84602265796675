import styled from 'styled-components';
import { Table, Cascader, Input } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { StyledFormItem, StyledInputNumber } from 'styles/commonComponents.styles';

const { TextArea } = Input;

export const TestFitInputNumber = styled(StyledInputNumber)`
  padding-top: 5px;
  width: ${({ width }) => (width ? `${width}%` : '90%')};
`;

export const SuccessIcon = styled(CheckCircleOutlined)`
  color: #5ac725 !important;
`;

export const FormItem = styled(StyledFormItem)`
  display: flex;
`;

export const ColumnFormItem = styled(FormItem)`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledTable = styled(Table)`
  font-weight: ${({ theme, bold }) => (!bold || theme.font.weight.bold)};
  font-size: ${({ theme }) => theme.font.size.small};

  .ant-table-thead {
    .ant-table-cell {
      font-weight: ${({ theme }) => theme.font.weight.bold} !important;
    }
  }
  
  .ant-table-thead > tr:first-child > th:first-child,
  .ant-table-thead > tr:first-child > th:last-child {
    border-radius: 0;
  }
  
  .ant-spin-blur {
    opacity: 0.6;
  }
  
  .ant-spin-container {
  
  :after {
    background: transparent;
    }
  }
    
  .ant-table-thead {
    font-size: ${({ theme }) => theme.font.size.small};
    th {
      padding: 5px;
      font-weight: ${({ theme }) => theme.font.weight.xBold} !important;
      background-color: ${({ theme }) => theme.colors.gray_02};
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
  
  .ant-table-tbody {
    font-size: ${({ theme }) => theme.font.size.small};
     td {
        padding: 4px 8px;
        border:${({ bold }) => (!bold || 0)};
     }
  }
  
  .ant-table-row-expand-icon {
    display: none;
  }
  
    // overwrite the hover effect for ANTD table
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background-color: unset;
  }
`;

export const StyledAlert = styled.div`
  margin-bottom: 3px;
`;

export const TotalSeparator = styled.div`
  height: 1px;
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_02 : theme.colors.gray_10)};
  width: ${({ width }) => (width || '100%')};
  margin-bottom: 15px;
`;

export const StyledCascader = styled(Cascader)`
  color: ${({ theme }) => theme.colors.primaryColor};
  background-color: ${({ theme }) => theme.colors.gray_01};
  text-align: left;

  .ant-select-selector {
    box-shadow: none !important;

    &:focus-within, &:focus, &:hover {
      box-shadow: none;
      border-color: ${({ theme }) => theme.colors.primaryColor} !important;
    }
  }
  
  .anticon, anticon-close-circle, ant-cascader-picker-clear{
    color: ${({ theme }) => theme.colors.primaryColor};
  }

  .ant-cascader-input {
    border: 0;
  }
`;

export const StyledTextArea = styled(TextArea)`
  border-color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_06 : theme.colors.primaryColor)};
  border-radius: ${({ theme }) => theme.other.borderRadius};
  background: ${({ theme }) => theme.colors.gray_01};
  
  &:hover {
    border-color: ${({ theme, disabled }) => (disabled ? theme.colors.gray_06 : theme.colors.primaryColor)} !important;
  } 
`;
