import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import lodashGet from 'lodash/get';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import lodashIsEmpty from 'lodash/isEmpty';
import { Image } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { StyledSubMenu, StyledMenu } from 'styles/commonComponents.styles';
import { swappProfileResultsSelector } from 'store/swappProfile';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import { clearSelectedRowKeys } from 'store/editor';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import TestFitDataFilterHeader from 'components/testFit/TestFitDataFilterHeader';
import EmptyMessage from 'components/common/EmptyMessage';
import { testFitRoomsFilterSelector } from 'store/testFit';
import { ROOM_FILTER_TYPES, FILTER_TYPES } from 'constants/testFitConsts';
import icons from 'styles/static/icons/testFit';
import lodashSum from 'lodash/sum';
import StyledSubMenuTitle from '../../../../common/StyledSubMenuTitle';
import ProfileDataTablesContainer from './ProfileDataTablesContainer';
import DonutChart from '../../../../common/DonutChart';
import RenderTitle from '../../../../common/RenderTitle';
import ProfileBathroomsDataPanel from './ProfileBathroomsDataPanel';

const imageWidth = 120;

const StyledImage = styled(Image)`
  border-radius: ${({ theme }) => theme.other.borderRadius};
  filter: grayscale(50%);
  height: 90px !important;
`;

const ImagesWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  margin-bottom: 10px;

  .ant-image-mask {
    border-radius: ${({ theme }) => theme.other.borderRadius};
  }
`;

const ResultDataWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 18px 0 18px;
`;

const AllDepartmentsContainer = styled.div`
  width: 100%;
  padding: 0 !important;
  border-radius: 7px;
  margin-bottom: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.11);
`;

const ProfileDataPanel = (props) => {
  const { profileId } = props;

  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const dispatch = useDispatch();
  const currentProfileId = profileId || locationData.profileId;
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));
  const swappProfileResults = useSelector((state) => swappProfileResultsSelector(state, currentProfileId, isImperial), shallowEqual);
  const roomFilterType = useSelector(testFitRoomsFilterSelector);

  // clear list on unmount
  useEffect(() => () => {
    dispatch(clearSelectedRowKeys());
  }, []);

  // clear list on profileId change
  useEffect(() => {
    dispatch(clearSelectedRowKeys());
  }, [currentProfileId]);

  const renderProgram = () => (
    <StyledMenu
      mode="inline"
      defaultOpenKeys={['RSF']}
      overflow
    >
      {/* ============ LSF ============ */}
      <StyledSubMenu
        key="LSF"
        padding={10}
        title={RenderTitle('AREA_TARGET_FORM_CONTAINER_LSF', 'AREA_TARGET_FORM_CONTAINER_LEASABLE_AREA', icons.lsfIcon)}
      >
        <ProfileDataTablesContainer type="LSF" data={lodashGet(swappProfileResults, 'LSF')} />
      </StyledSubMenu>

      {/* ========= PSF A/B ========= */}
      <StyledSubMenu
        key="PSF"
        padding={10}
        title={RenderTitle('AREA_TARGET_FORM_CONTAINER_PSF', 'AREA_TARGET_FORM_CONTAINER_PRODUCTIVE_AREA', icons.psfIcon)}
      >
        {!lodashIsEmpty(lodashGet(swappProfileResults, 'PSF_A.tableData', []).filter((e) => e.color)) && (
          <ProfileDataTablesContainer type="PSF" data={lodashGet(swappProfileResults, 'PSF_A')} />
        )}

        {/* ========= PSF B ========= */}
        {!lodashIsEmpty(lodashGet(swappProfileResults, 'PSF_B.tableData', []).filter((e) => e.color)) && (
          <ProfileDataTablesContainer type="PSF" hideHeader data={lodashGet(swappProfileResults, 'PSF_B')} />
        )}
      </StyledSubMenu>

      {/* ========= ASF ========= */}
      {!lodashIsEmpty(lodashGet(swappProfileResults, 'ASF.tableData', []).filter((e) => e.color)) && (
        <StyledSubMenu
          key="ASF"
          padding={10}
          title={RenderTitle('AREA_TARGET_FORM_CONTAINER_ASF', 'AREA_TARGET_FORM_CONTAINER_AMENITY_SPACES', icons.asfIcon)}
        >
          <ProfileDataTablesContainer type="ASF" data={lodashGet(swappProfileResults, 'ASF')} />
        </StyledSubMenu>
      )}

      {/* ========= FSF ========= */}
      {!lodashIsEmpty(lodashGet(swappProfileResults, 'FSF.tableData', []).filter((e) => e.color)) && (
        <StyledSubMenu
          key="FSF"
          padding={10}
          title={RenderTitle('AREA_TARGET_FORM_CONTAINER_FSF', 'AREA_TARGET_FORM_CONTAINER_FACILITY_SPACES', icons.fsfIcon)}
        >
          <ProfileDataTablesContainer type="FSF" data={lodashGet(swappProfileResults, 'FSF')} />
        </StyledSubMenu>
      )}
    </StyledMenu>
  );

  const renderFloorDepartment = (data) => (
    <>
      <ProfileDataTablesContainer type="LSF" data={{ tableData: lodashGet(data, 'LSF', []) }} />
      <ProfileDataTablesContainer type="PSF" data={{ tableData: lodashGet(data, 'PSF', []) }} />
      <ProfileDataTablesContainer type="ASF" data={{ tableData: lodashGet(data, 'ASF', []) }} />
      <ProfileDataTablesContainer type="FSF" data={{ tableData: lodashGet(data, 'FSF', []) }} />
    </>
  );

  const renderFloors = () => (
    <StyledMenu
      mode="inline"
      defaultOpenKeys={['RSF']}
      overflow
    >
      {swappProfileResults.floors.map((floor, index) => (
        <StyledSubMenu
          key={`Floor ${index + 1}`}
          padding={10}
          title={RenderTitle(lodashGet(swappProfileResults, `multiBoundaryFile.floors[${index}].name`) || `Floor ${index + 1}`)}
        >
          {renderFloorDepartment(floor)}
        </StyledSubMenu>
      ))}
    </StyledMenu>
  );

  const renderDepartments = () => {
    if (lodashIsEmpty(lodashGet(swappProfileResults, 'departments'))) {
      return <EmptyMessage description="TEST_FIT_VIEWER_CONTAINER_UI_DEPARTMENTS_EMPTY" />;
    }

    const departments = lodashGet(swappProfileResults, 'departments', []);
    const targetRooms = lodashSum(departments.map((item) => item.targetRooms));
    const departmentTotal = {
      key: 'ALL',
      departmentsKey: 'all',
      filterType: FILTER_TYPES.DEPARTMENT,
      name: 'All',
      color: '#8a8a8a',
      tileAmount: targetRooms ? 4 : 3,
      modelArea: lodashSum(departments.map((item) => item.modelArea)),
      modelRooms: lodashSum(departments.map((item) => item.modelRooms)),
      targetRooms,
      modelMembers: lodashSum(departments.map((item) => item.modelMembers)),
      rooms: lodashSum(departments.map((item) => item.rooms)),
    };

    return (
      <StyledMenu
        mode="inline"
        defaultOpenKeys={['RSF']}
        overflow
      >
        <AllDepartmentsContainer>
          <ProfileDataTablesContainer type="DEPARTMENTS" data={{ tableData: [departmentTotal, ...departments] }} />
        </AllDepartmentsContainer>
        {swappProfileResults.departments.map((department) => (
          <StyledSubMenu
            key={department.departmentsKey}
            padding={10}
            title={RenderTitle(department.name || T.translate('UNTITLED'), '', null, department.color)}
          >
            {renderFloorDepartment(department.rooms)}
          </StyledSubMenu>
        ))}
      </StyledMenu>
    );
  };

  const dataRenders = useMemo(() => ({
    [ROOM_FILTER_TYPES.ROOMS]: renderProgram(),
    [ROOM_FILTER_TYPES.FLOORS]: renderFloors(),
    [ROOM_FILTER_TYPES.DEPARTMENTS]: renderDepartments(),
  }), [isImperial, swappProfileResults]);

  return (
    <ResultDataWrapper>
      <StyledMenu
        mode="inline"
        defaultOpenKeys={['RSF']}
        overflow
      >
        {/* ============ RSF ============ */}
        <StyledSubMenu
          key="RSF"
          padding={10}
          title={RenderTitle('OVERALL_CONTAINER_RSF', 'OVERALL_CONTAINER_RENTABLE', icons.rsfIcon)}
        >
          <ProfileDataTablesContainer type="RSF" data={lodashGet(swappProfileResults, 'RSF')} />
          {lodashGet(swappProfileResults, 'RSF.chartData') && <DonutChart data={swappProfileResults.RSF.chartData} colorSetKey="RSF" width="410" />}
        </StyledSubMenu>

        {/* ============ DATA ============ */}
        <StyledSubMenu
          key="DATA"
          padding={10}
          title={RenderTitle('AREA_TARGET_FORM_CONTAINER_ROOMS_DATA')}
        >
          <TestFitDataFilterHeader />
          {dataRenders[roomFilterType]}
        </StyledSubMenu>

        {/* ========= BATHROOMS ========= */}
        <StyledSubMenu
          key="BATHROOMS"
          padding={10}
          title={RenderTitle('AREA_TARGET_FORM_CONTAINER_BATHROOMS', 'AREA_TARGET_FORM_CONTAINER_BATHROOMS_DESCRIPTION', icons.restroomIcon)}
        >
          <ProfileBathroomsDataPanel data={lodashGet(swappProfileResults, 'bathrooms')} />
        </StyledSubMenu>

        {/* ============ Renders ============ */}
        {swappProfileResults.renders && (
        <StyledSubMenu
          key="RENDERS"
          padding={10}
          title={<StyledSubMenuTitle icon={icons.renderIcon} title={T.translate('Renders')} />}
        >
          <ImagesWrapper>
            <Image.PreviewGroup>
              {swappProfileResults.renders.map((image) => <StyledImage width={imageWidth} src={image} />)}
            </Image.PreviewGroup>
          </ImagesWrapper>
        </StyledSubMenu>
        )}
      </StyledMenu>
    </ResultDataWrapper>
  );
};

ProfileDataPanel.propTypes = {
  profileId: PropTypes.string,
};

export default React.memo(ProfileDataPanel);
