import styled from 'styled-components';
import { Canvas } from 'react-three-fiber';

export const StyledCanvas = styled(Canvas)`
  height: 100%;
  width: 100%;
  border: 0;
  z-index: 2;

  canvas {
    &:focus {
      outline: 0;
    }
  }
`;

export const IconWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme, selected }) => (selected ? theme.colors.primaryColor : theme.colors.gray_07)};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : 0)};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 22px;
  overflow: hidden;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.22);
  cursor: pointer;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primaryColor};
  }
  
  .anticon {
    position: absolute;
    top: 5px;
    left: 5px;
  }
`;

export const IconsWrapper = styled.div`
    position: absolute;
    z-index: 3;
    top: 7px;
    left: 7px;
    display: flex;
    flex-direction: row;
    width: 200px;
    padding: 4px;
`;

export const ExitModalContentWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;

export const WalkStyledCanvas = styled(Canvas)`
  height: 100%;
  width: 100%;
  border: 0;
  border-radius: ${({ radius }) => (radius ? `${radius}px` : 0)};
  z-index: 2;

  canvas {
    &:focus {
      outline: 0;
    }
  }
`;
