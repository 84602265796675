import React from 'react';

// currentcolor
export default () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.85715 8.99407C3.48621 8.99407 3.18652 9.29376 3.18652 9.6647V17.7122C3.18652 18.0832 3.48621 18.3829 3.85715 18.3829H17.2697C17.6407 18.3829 17.9404 18.0832 17.9404 17.7122V9.6647C17.9404 9.29376 17.6407 8.99407 17.2697 8.99407H3.85715ZM5.01884 16.5506H16.108V10.794H5.01884V16.5506Z" fill="currentcolor" />
    <path d="M9.97664 13.9609V15.0716C9.97664 15.1639 10.0521 15.2393 10.1443 15.2393H10.9826C11.0748 15.2393 11.1502 15.1639 11.1502 15.0716V13.9609C11.3232 13.8367 11.4524 13.6608 11.519 13.4586C11.5857 13.2563 11.5865 13.0381 11.5212 12.8354C11.456 12.6327 11.3281 12.4559 11.156 12.3304C10.9839 12.205 10.7764 12.1374 10.5634 12.1374C10.3505 12.1374 10.143 12.205 9.97089 12.3304C9.79878 12.4559 9.6709 12.6327 9.60566 12.8354C9.54042 13.0381 9.54119 13.2563 9.60786 13.4586C9.67452 13.6608 9.80365 13.8367 9.97664 13.9609Z" fill="currentcolor" />
    <path d="M15.9212 5.13017L14.6648 9.65333L12.8545 9.15048L14.0252 4.93585C14.1985 4.31189 13.8324 3.66428 13.2085 3.49096L8.92635 2.30153C8.30239 2.12821 7.65478 2.49427 7.48146 3.11822L6.97353 4.94686L5.15047 4.44048L5.74411 2.30332C6.14065 0.875698 7.61907 0.0400448 9.0467 0.43659L14.0545 1.82758C15.4821 2.22413 16.3177 3.70255 15.9212 5.13017Z" fill="currentcolor" />
  </svg>
);
